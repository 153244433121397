import { ExtractEmitsHandlers } from "src/helpers/utils"
import * as iltypes from "src/interfaces/InleagueApiV1";

import * as ClearOnLogout from "src/store/ClearOnLogout"

import { ref } from "vue";

import * as ilapi from "src/composables/InleagueApiV1"
import { AxiosInstance } from "axios";

export function emitsDef() {
  return {
    "season-selected": (seasonUID: iltypes.Guid) => true,
    "seasons-loaded": (seasonUIDs: iltypes.Guid[]) => true
  }
}

export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;

interface FlowState {
  volunteerDetails: {
    userID: iltypes.Guid,
    firstName: string,
    lastName: string
  }
}

export const flowState = (() => {
  const flowState = ref<FlowState | null>(null);

  const configureFlowState = (obj: {userID: iltypes.Guid, firstName: string, lastName: string}) => {
    flowState.value = {volunteerDetails: obj};
  }

  /**
   * could race if there are many callers at once
   */
  const hydrateFlowState = async (axios: AxiosInstance, volunteerID: iltypes.Guid) => {
    const user = await ilapi.getUser(axios, volunteerID);
    configureFlowState({userID: user.ID, firstName: user.firstName, lastName: user.lastName});
  }

  const getFlowState = () => {
    return flowState.value; // does this remain reactive ... ?
  }

  const clear = () => {
    flowState.value = null;
  }

  return {
    configureFlowState,
    hydrateFlowState,
    getFlowState,
    clear
  }
})();

ClearOnLogout.register(flowState);
