import { Ref, ref } from "vue";
import type { AxiosInstance } from "axios";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as Modal from "src/components/UserInterface/Modal"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";

import { SimpleFileUploadDialog } from "./Modal.photoUpload.common";
import * as ilplayer from "src/composables/InleagueApiV1.Player"
import { Client } from "src/store/Client";

export function playerPhotoUploadModalController(
  nonReactiveProplike: {
    maxSize: {
      bytes: number,
      humanReadable: string
    },
    accept?: string
  },
  handlers: {
    onSubmit: (_: {player: iltypes.Child, file: File, busyFlag: Ref<boolean>}) => void | Promise<void>,
  }
) {
  const isBusy = ref(false);
  const onCloseCB = (cb: () => void) : void => {
    if (isBusy.value) {
      return;
    }
    else {
      cb();
    }
  }

  const handleSubmit = async (payload: {entity: iltypes.Child, file: File}) : Promise<void> => {
    if (isBusy.value) {
      // shouldn't happen
      return;
    }

    await handlers.onSubmit({player: payload.entity, file: payload.file, busyFlag: isBusy})
  }

  return Modal.DefaultModalController<iltypes.Child>({
    title: () => (
      <>
        <div>Upload player photo</div>
        <div>File types accepted: PNG, JPG, GIF, BMP, and TIF</div>
        <div class="my-2 border-b border-slate-200"/>
      </>
    ),
    content: player => !!player
      ? (
        <>
          {/* remount everytime for fresh Impl state -- can we get a notification for every "open" event? and bump a key counter or something? */}
          {/* a full close/open cycle should perform a complete mount/dispose lifecycle though */}
          <SimpleFileUploadDialog loopThrough={player} maxSize={nonReactiveProplike.maxSize} accept={nonReactiveProplike.accept} onSubmit={handleSubmit}/>
          {
            isBusy.value
              ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
              : null
          }
        </>
      )
      : null
  }, {onCloseCB})
}

/**
 * @axios generally should be "axios auth background instance with toasts on failures";
 * for finer control over this, instantiate a "non-default" controller manually
 *
 * @success optional, mostly as a provision for the caller to issue a success toast
 */
export function defaultPlayerPhotoUploadModalController(axios: AxiosInstance, success?: () => void) {
  const controller = playerPhotoUploadModalController(
    {
      maxSize: {
        bytes: ilplayer.uploadPhotoMeta.maxSizeBytes,
        humanReadable: `${ilplayer.uploadPhotoMeta.maxSizeBytes / 1000 / 1000} MB`,
      },
      accept: ilplayer.uploadPhotoMeta.accept,
    },
    {
      onSubmit: async ({player, file, busyFlag}) => {
        try {
          try {
            busyFlag.value = true;
            await ilplayer.uploadPhoto(axios, {playerID: player.childID, photo: file})
          }
          finally {
            busyFlag.value = false;
          }
          player.isPhotoLocked = 1; // could re-request from backend? is this always true, assuming success?
          controller.close();
          success?.();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      },
  })

  return controller;
}
