/**
 * Utils, but importable via node (no transitive imports to things that will fail to resolve when run in a node context)
 * This is `export *`'d from utils for use on frontend, but this file itself can be used in tests or etc.
 */

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 *
 * escape regex metachars to support compiling a user-provided string as a regex
 */
export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * shorthand support for the common pattern of `new RegExp(escapeRegExp(pattern), flags)`
 */
export function EscapedRegExp(pattern: string, flags?: string) {
  return new RegExp(escapeRegExp(pattern), flags);
}
