import { ref } from "vue";

import { RegionDef } from "src/composables/InleagueApiV1.Public"
import * as ilpublic from "src/composables/InleagueApiV1.Public"

import { axiosNoAuthInstance } from "src/boot/AxiosInstances";
import { UiOption, parseIntOrFail, sortBy } from "src/helpers/utils";

/**
 * n.b. we don't register with "clearOnLogout" here because all this stuff is public and doesn't need to be invalidated
 * on logout.
 */
export const Public = (() => {
  const regions = ref<null | Promise<RegionDef[]>>(null)

  const getMasterRegionList = () : Promise<RegionDef[]> => {
    if (!regions.value) {
      regions.value = ilpublic
        .getMasterRegionList(axiosNoAuthInstance)
        .then(list => list.sort(sortBy(_ => parseIntOrFail(_.Region))));
    }

    return regions.value;
  }

  const buildStandardRegionOptions = (regions: RegionDef[], opts?: {includeNilOption?: boolean}) : UiOption[] => {
    const partitionedRegions = {
      hasName: regions
        .filter(region => !!region.RegionName)
        .sort(sortBy(_ => parseIntOrFail(_.Region))),
      noName: regions
        .filter(region => !region.RegionName)
        .sort(sortBy(_ => parseIntOrFail(_.Region))),
    }

    const result : UiOption[] = [];

    if (opts?.includeNilOption) {
      result.push({label: "", value: ""});
    }

    partitionedRegions.hasName.forEach(region => result.push({label: `${region.RegionName} (#${region.Region})`, value: region.Region.toString()}))
    partitionedRegions.noName.forEach(region => result.push({label: `Region #${region.Region}`, value: region.Region.toString()}))

    return result;
  }

  return {
    getMasterRegionList,
    buildStandardRegionOptions
  }
})()
