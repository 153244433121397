import { rangeExc, parseIntOrFail, parseIntOr, vReqT, useWindowSize, vOptT } from "src/helpers/utils";
import { computed, defineComponent, ref, shallowRef } from "vue";
import { EdgeDrawer, RoundSlotElemRefTracker, BracketRoundSlotID, EdgeLayoutInfo } from "./BracketBuilderElems";
import { Bracket, BracketRound, BracketRoundSlot } from "./Bracket.io";

export type BracketElementSlots = {
  /**
   * Note that roundHeader must not return a fragment (unless that fragment has exactly 1 child)
   */
  roundHeader?: (_: BracketElementRoundHeaderArgs) => JSX.Element | null
  roundSlot?: (_: BracketElementSlotArgs) => JSX.Element | null,
}

export type BracketElementRoundHeaderArgs = {
  bracketRound: BracketRound,
  bracketRoundIdx: number,
}

export type BracketElementSlotArgs = {
  bracketRound: BracketRound,
  bracketRoundIdx: number,
  bracketRounds: BracketRound[],
  bracketRoundSlot: BracketRoundSlot,
  bracketRoundSlotIdx: number,
  bracketRoundSlots: BracketRoundSlot[],
}

/**
 * Lays out a bracket, and draws appropriate lines between each bracket node.
 * Each node in the bracket is drawn from a call to the `roundSlot` slot.
 */
export const BracketElement = defineComponent({
  props: {
    bracket: vReqT<Bracket>(),
    roundSlotElemRefTracker: vReqT<RoundSlotElemRefTracker>(),
    mode: vReqT<"new" | "existing">(),
    debugBracketRoundSlotIDs: vOptT<boolean>(),
  },
  setup(props, ctx) {
    const windowSize = useWindowSize()
    const svgRedrawKey = computed(() => `{x=${windowSize.width}, y=${windowSize.height}}`)
    let forceSvgRedraw = 0
    const edgeLayoutInfo = shallowRef(new Map<BracketRoundSlotID, EdgeLayoutInfo>)

    const bracketContainerRef = ref<HTMLElement | null>(null);
    const cssUnits_svgWidth = () => {
      if (bracketContainerRef.value) {
        return bracketContainerRef.value.scrollWidth + "px";
      }
      else {
        // fallback, but not as good as exact for when the bracket container would cause scrolling,
        // where 100% will mean "visible container width" rather than
        return "100%";
      }
    }

    const slotRows = computed(() => {
      const treeWidth = (() => {
        return bracketBinaryTreeWidth(props.bracket)
      })();

      // e.g in a 2 round tournament with 3 games
      // round: 1     | 2
      // -------------+-------
      // row 0: game1 |
      // row 1: game1 | game3
      // row 2: game2 | game3
      // row 3: game2 |
      //
      // so game1 takes up 2 rows, game2 takes up 2 rows, and game3 takes up 2 rows and is able to stradle game1/2
      //
      return treeWidth * 2
    })

    const roundCols = computed(() => {
      return bracketBinaryTreeDepth(props.bracket)
    })

    return () => {
      return (
        <div
          class="p-2"
          ref={bracketContainerRef}
          style={{
            gridGap: "2em 6em",
            display: "grid",
            gridTemplateColumns: `repeat(${roundCols.value}, 20em)`,
            // 1 auto row for header, the rest for each leaf element in tree.
            // It looks a lot better to use the auto-height row (as opposed to hardcoded) values, but in doing so we become responsible for determing when
            // a node's height has changed, in which case we probably will want to redraw the svg lines connecting the nodes.
            // Note that we __do not__ have a mechanism for watching each element and determining if it has changed size since
            // some prior point time. We could poll, if it comes down to it. Or just revert back to using hardcoded sizes
            // and guarantee that they will never change. Another idea is to make the svg elements plain ol' functional components and
            // just re-render every time our containing render function is invoked; there may be perf implications to this.
            gridTemplateRows: `auto repeat(${(slotRows.value)}, auto)`,
            gridAutoFlow: "column",
            position: "relative",
            zIndex: 1,
          }}>

          {(() => {
            // Vue has decided to re-evaluate this portion of the render function, which is to say it is re-evaluating the bracket grid.
            // We want to say that if we get here, then we always want to redraw the svg bracket lines (in case the height of an element changed).
            // We do this non-reactively so there isn't any tracked dependency that might go funky, and also we wouldn't know when to trigger
            // it (basically anytime anything in a bracket changes, which we identify by simply "being here" in the render function).
            // I guess this could be a functional component or something like that, instead, which would accomplish the same thing
            // but be a little more standard. Ultimately we are saying "vue please do not reuse any part of the current svg tree".
            // Investigate: probably can do this via onAfterUpdate or whatever lifecycle method (but then would it go infinite?...)
            forceSvgRedraw++
          })()}

          {/*
            svg sits on top of full width of container, and draws the lines between bracket slots
          */}
          <svg style={`position:absolute; width:${cssUnits_svgWidth()}; height:100%; pointer-events:none;`} key={forceSvgRedraw + svgRedrawKey.value}>
            <EdgeDrawer
              bracketContainerRef={bracketContainerRef}
              roundSlotElemRefTracker={props.roundSlotElemRefTracker}
              bracket={props.bracket}
              onEdgeLayoutInfo={(m) => {
                edgeLayoutInfo.value = m
              }}
              dir="lr"
            />
          </svg>

          {props
            .bracket
            .bracketRounds
            .map((bracketRound, bracketRoundIdx, bracketRounds) => ({bracketRound, bracketRoundIdx, bracketRounds}))
            //.reverse() // draw backwards via reverse...and set dir="rl" for the edges
            .flatMap(({bracketRound, bracketRoundIdx, bracketRounds}) => {
            const leadingPadding = bracketRoundIdx === 0 ? 0 : ((2 ** bracketRoundIdx)-1)
            const interNodePadding = leadingPadding * 2;
            const trailingPadding = leadingPadding

            const slots = ctx.slots as BracketElementSlots

            return <>
              {/*
                For the grid to be properly shaped, we need a single element here.
                If a roundHeader is not provided by caller, then we fallback to a 'dummy' cell.
              */}
              {slots.roundHeader?.({bracketRound, bracketRoundIdx}) || <div>&nbsp;</div>}

              {/*grid padding to offset the first element of each successive column*/}
              <>{rangeExc(0, leadingPadding).map(() => <div>&nbsp;</div>)}</>

              {bracketRound.bracketRoundSlots.map((bracketRoundSlot, bracketRoundSlotIdx, bracketRoundSlots) => {
                const isLast = bracketRoundSlotIdx === bracketRoundSlots.length - 1

                return <>
                  <div
                    data-test={`round=${bracketRoundIdx+1}/slot=${bracketRoundSlotIdx+1}`}
                    style="grid-row:span 2;"
                    class="relative bg-white border border-black rounded-md"
                    key={bracketRoundSlot.bracketRoundSlotID}
                    ref={props.roundSlotElemRefTracker.getOrFail(bracketRoundSlot)}
                  >
                    {slots.roundSlot?.({
                      bracketRound,
                      bracketRoundIdx,
                      bracketRounds,
                      bracketRoundSlot,
                      bracketRoundSlotIdx,
                      bracketRoundSlots,
                    })}
                  </div>

                  {/*skip rows for empty space*/}
                  <>{rangeExc(0, isLast ? trailingPadding : interNodePadding).map(() => <div>&nbsp;</div>)}</>
                </>
              })}
            </>
          })}
          {props.debugBracketRoundSlotIDs
            ? props.bracket.bracketRounds.flatMap((bracketRound, _roundIdx) => {
              return bracketRound.bracketRoundSlots.map((roundSlot, _slotIdx) => {
                if (roundSlot.type !== "game") {
                  return null;
                }

                const edgeInfo = edgeLayoutInfo.value.get(parseIntOrFail(roundSlot.bracketRoundSlotID))
                if (!edgeInfo) {
                  return null;
                }

                const homeEdge = edgeInfo.centerOfEdgeTo.get(parseIntOr(roundSlot.sourceLeft?.source_bracketRoundSlotID, Infinity))
                const visitorEdge = edgeInfo.centerOfEdgeTo.get(parseIntOr(roundSlot.sourceRight?.source_bracketRoundSlotID, Infinity))

                return <>
                  {homeEdge
                    ? <div class="bg-black text-white text-xs ml-2 px-2 py-1" style={`position:absolute; top: ${homeEdge.y}px; left:${homeEdge.x}px;`}>H {roundSlot.bracketRoundSlotID} -&gt; {roundSlot.sourceLeft?.source_bracketRoundSlotID}</div>
                    : null
                  }
                  {visitorEdge
                    ? <div class="bg-black text-white text-xs ml-2 px-2 py-1" style={`transform: translateY(-100%); position:absolute; top: ${visitorEdge.y}px; left:${visitorEdge.x}px;`}>V {roundSlot.bracketRoundSlotID} -&gt; {roundSlot.sourceRight?.source_bracketRoundSlotID}</div>
                    : null
                  }
                </>
              })
            })
            : null
          }

        </div>
      )
    }
  }
})

const bracketBinaryTreeDepth = (bracket: Bracket) => bracket.bracketRounds.length
const bracketBinaryTreeWidth = (bracket: Bracket) => 2 ** (bracketBinaryTreeDepth(bracket) - 1)
export const slotsOnLevelN = (zi_n: number, numRounds: number) => 2 ** (numRounds - (zi_n + 1))
