import { PropType, defineComponent, ref, watch } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export interface TabDef {
  label: string,
  ["data-test"]?: string,
  render: () => JSX.Element | null,
  /**
   * keep the tab element alive when navigating away from its tab
   * Not providing this is the same as keepAlive=false
   */
  keepAlive?: boolean
}

function isKeepAliveOnNavAway(tabDef: TabDef) : boolean {
  return tabDef.keepAlive === true;
}

function isUnmountOnNavAway(tabDef: TabDef) : boolean {
  return !isKeepAliveOnNavAway(tabDef); // unmount if we're not keepalive
}

export const Tabs = defineComponent({
  props: {
    tabDefs: {
      required: true,
      type: Array as PropType<TabDef[]>
    },
    selectedIndex: {
      required: false,
      type: Number
    }
  },
  emits: {
    changeSelectedIndex: (idx: number) => true,
  },
  setup(props, {emit}) {
    const selectedIndex = ref(props.selectedIndex ?? 0);

    watch(() => props.selectedIndex, () => {
      if (typeof props.selectedIndex === "number") {
        selectedIndex.value = props.selectedIndex;
      }
    })

    const handleLocalSelectedIndexChange = (idx: number) => {
      selectedIndex.value = idx;
      emit("changeSelectedIndex", idx);
    }

    return () => (
      <div>
        <TabGroup selectedIndex={selectedIndex.value} onChange={handleLocalSelectedIndexChange}>
          <TabList class="flex space-x-1 rounded-xl bg-green-700 p-1">
            {
              props.tabDefs.map(tabDef => (
                <Tab
                  class="focus:outline-none"
                  data-test={tabDef["data-test"]}
                  key={tabDef.label}
                >
                  {
                    ({selected}: {selected:boolean}) => (
                      <div
                        class={`
                          w-full rounded-lg p-2.5 text-sm font-medium leading-5 text-blue-700

                          ${selected ? 'bg-white shadow' : 'text-white hover:bg-white/[0.12]'}`
                        }
                      >
                        {tabDef.label}
                      </div>
                    )
                  }
                </Tab>
              ))
            }
          </TabList>
          <TabPanels class="mt-2" key={`panelsForIdx/${selectedIndex.value}`}>
            {
              props.tabDefs.map(tabDef => {
                return (
                  <TabPanel key={tabDef.label} unmount={isUnmountOnNavAway(tabDef)}>
                    {tabDef.render()}
                  </TabPanel>
                )
              })
            }
          </TabPanels>
        </TabGroup>
      </div>
    )
  }
})
