import { AxiosInstance } from "axios"
import { DateTimelike, Guid, Integerlike, Numbool, UserID } from "src/interfaces/InleagueApiV1"

export interface VolunteerCertEditorCert {
  addedByUser: "" | {
    firstName: string,
    lastName: string,
  },
  vCertID: Integerlike,
  certDate: DateTimelike,
  certificationDesc: string,
  provisional: Numbool,
  stackRecordKey: string,
  code: string,
  category: string,
  dateAdded: DateTimelike,
  addedBy: UserID,
  clientID: Guid,
  validated: Numbool
}

export interface CreateProvisionalCertOption {
  category: string
  code: string
  description: string
}

export interface GetUserVolunteerCertEditorViewDataResponse {
  userData: {
    stackSID: string,
    stackRecordKey: string,
    firstName: string,
    lastName: string
  },
  certs: VolunteerCertEditorCert[],
  createProvisionalCertOptions: CreateProvisionalCertOption[]
}

export async function getUserVolunteerCertEditorViewData(ax: AxiosInstance, args: {userID: Guid}) : Promise<GetUserVolunteerCertEditorViewDataResponse> {
  const response = await ax.get(`v1/user/${args.userID}/volunteerCerts`)
  return response.data.data;
}

export async function createProvisionalCert(ax: AxiosInstance, args: {userID: Guid, code: string, category: string}) : Promise<VolunteerCertEditorCert> {
  const response = await ax.put(`v1/user/${args.userID}/volunteerCerts`, args);
  return response.data.data;
}

export async function deleteProvisionalCerts(ax: AxiosInstance, args: {userID: Guid, vCertIDs: Integerlike[]}) : Promise<void> {
  await ax.delete(`v1/user/${args.userID}/volunteerCerts`, {params: {vCertIDs: args.vCertIDs.join(",")}});
}

export async function resyncCerts(ax: AxiosInstance, args: {userID: Guid}) : Promise<void> {
  await ax.post(`v1/user/${args.userID}/volunteerCerts`);
}

export interface UserLogEntry {
  date: DateTimelike,
  entityName: string,
  type: string,
  category: string,
  submitterName: string,
  extraText: string,
  oldValue: string,
  newValue: string,
  system: boolean,
  expirationDate: DateTimelike,
}

export async function getUserLog(ax: AxiosInstance, args: {userID: Guid}) : Promise<UserLogEntry[]> {
  const response = await ax.get(`v1/user/${args.userID}/log`);
  return response.data.data;
}

export interface VolunteerPrefSeasonalViewData {
  seasonUID: Guid,
  seasonID: Integerlike,
  seasonName: string,
  /**
   * if true, `headCoachVolunteerPrefsForSeason` should be non-empty
   */
  isHeadCoach: boolean,
  /**
   * if true, `asstCoachVolunteerPrefsForSeason` should be non-empty
   */
  isAsstCoach: boolean,
  /**
   * if true, `refVolunteerPrefsForSeason` should be non-empty
   */
  isRef: boolean,
  coachComments: string,
  headCoachVolunteerPrefsForSeason: {
    clientID: Guid,
    dateUpdated: DateTimelike,
    divID: Guid,
    division: string,
    headCoach: Numbool,
    seasonID: Integerlike
    seasonUID: Guid,
    submitterID: Guid,
    userID: Guid,
    vsCodeID: Guid,
  }[],
  asstCoachVolunteerPrefsForSeason: VolunteerPrefSeasonalViewData["headCoachVolunteerPrefsForSeason"],
  refVolunteerPrefsForSeason: {
    clientID: Guid,
    dateUpdated: DateTimelike,
    divID: Guid,
    division: string,
    seasonID: Integerlike
    seasonUID: Guid,
    submitterID: Guid,
    userID: Guid,
    vsCodeID: Guid,
  }[],
  flexCodes: {
    clientID: Guid,
    codeID: Guid,
    code: string,
    dateUpdated: DateTimelike,
    divID: string,
    division: string,
    seasonID: Integerlike
    seasonUID: Guid,
    submitterID: Guid,
    userID: Guid,
    vsCodeID: Guid,
  }[],
}

export interface GetActiveOrRegSeasonVolunteerPrefsResponse {
  user: {
    userID: string,
    firstName: string,
    lastName: string,
    riskStatus: string,
    formattedRiskStatusExpiration: string,
  },
  volunteerPrefsSeasonalViewData: VolunteerPrefSeasonalViewData[]
}

export async function getActiveOrRegSeasonVolunteerPrefs(ax: AxiosInstance, args: {userID: Guid, seasonUID: undefined | Guid}) : Promise<GetActiveOrRegSeasonVolunteerPrefsResponse> {
  const response = await ax.get(`v1/user/${args.userID}/activeOrRegSeasonVolunteerPrefs`, {params: args});
  return response.data.data;
}
