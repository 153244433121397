import * as TeamChooser from "src/composables/InleagueApiV1.TeamChooserMenu"
import { ExtractEmitsHandlers } from "src/helpers/utils";
import { ExtractPropTypes, PropType, Ref, ComputedRef, computed, ref, watch } from "vue"
import { exhaustiveCaseGuard, VueNeverUnwrappable } from "src/helpers/utils";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";

export interface TeamChooserSelection {
  seasonUID: string,
  competitionUID: string,
  divID: string,
  teamID: string,
}

export function propsDef() {
  return {
    modelValue: {
      required: false as const,
      type: Object as PropType<TeamChooserSelection>,
    },
    menuDef: {
      required: true as const,
      type: Object as PropType<TeamChooser.Menu>
    }
  }
}

export function emitsDef() {
  return {
    "update:modelValue": (_: TeamChooserSelection) => true
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;

export function defaultEmptyTeamChooserSelection() : TeamChooserSelection {
  return {
    seasonUID: "",
    competitionUID: "",
    divID: "",
    teamID: "",
  }
}

interface SelectOption {
  value: string,
  label: string,
  [key: string]: any
}

export interface State {
  /**
   * Active selections, empty string means "no selection"
   */
  selected: Ref<TeamChooserSelection>,
  /**
   * computed options lists
   */
  options: {
    seasons: ComputedRef<SelectOption[]>,
    competitions: ComputedRef<SelectOption[]>,
    divisions: ComputedRef<SelectOption[]>,
    teams: ComputedRef<SelectOption[]>,
  },
  /**
   * empty string means "no singular option available"
   * Otherwise, values here are valid select values (i.e. `selected.value.FOO = maybeGetSingularOption("FOO")` makes sense),
   * and represents an option that is the only available option
   */
  maybeGetSingularOption: (k: (keyof TeamChooserSelection)) => string
}

/**
 * Creates a `state` object and manages it
 */
export function DefaultSelectionManager(
  menuDef: TeamChooser.Menu,
  onChange?: (v: TeamChooserSelection) => void
) {
  const state = State(menuDef);

  watch(() => state.selected.value.seasonUID, () => {
    clearOtherSelections("competitionUID", "divID", "teamID");
    maybeAutoSelectSingularOption("competitionUID");
    onChange?.(getEmittableSelection())
  })

  watch(() => state.selected.value.competitionUID, () => {
    clearOtherSelections("divID", "teamID");
    maybeAutoSelectSingularOption("divID");
    onChange?.(getEmittableSelection())
  })

  watch(() => state.selected.value.divID, () => {
    clearOtherSelections("teamID");
    maybeAutoSelectSingularOption("teamID");
    onChange?.(getEmittableSelection())
  })

  watch(() => state.selected.value.teamID, () => {
    onChange?.(getEmittableSelection())
  })

  /**
   * reset some selections to `""`
   */
  const clearOtherSelections = (...ks : (keyof TeamChooserSelection)[]) => {
    for (const k of ks) {
      state.selected.value[k] = "";
    }
  }

  /**
   * State tells us if there is a "singular" option available, but doesn't force us to select it
   * Here we opt to select the singular option, if it is available
   */
  const maybeAutoSelectSingularOption = (k: keyof TeamChooserSelection) : void => {
    const maybeSingularOptionValue = state.maybeGetSingularOption(k);
    if (maybeSingularOptionValue) {
      state.selected.value[k] = maybeSingularOptionValue;
    }
  }

  const getEmittableSelection = () : TeamChooserSelection => {
    return { ...state.selected.value }
  }

  //
  // run "auto select" logic once prior to return to caller
  // (does each subsequent call respect selections done in prior calls?...)
  //
  maybeAutoSelectSingularOption("seasonUID")
  maybeAutoSelectSingularOption("competitionUID")
  maybeAutoSelectSingularOption("divID")
  maybeAutoSelectSingularOption("teamID")

  return {
    state,
    forceUpdate: (v: TeamChooserSelection) => {
      state.selected.value = {...v};
    },
    alternateKeyBiMap: buildAlternateKeyBiMap(menuDef)
  }
}

/**
 * Maps "modern" UID keys to their "legacy" IDs, and vice-versa
 */
interface AlternateKeyBiMap {
  uidById: {[id: Integerlike]: Guid},
  idByUid: {[uid: Guid]: Integerlike}
}

/**
 * Bimap "modern" Guid keys to their legacy integer keys
 * seasonUID <--> seasonID
 * competitionUID <--> competitionID
 *
 * Note that the "legacy" key is not uniquely identifying on its own, and requires the tuple (id, clientID)
 * to be a full identifier; but, clientID is implied by application context
 * (i.e. we are only ever interacting with a single clientID at once, a menuDef implies a single client)
 */
function buildAlternateKeyBiMap(menuDef: TeamChooser.Menu) {
  const season : AlternateKeyBiMap = {uidById: {}, idByUid: {}};
  const competition : AlternateKeyBiMap = {uidById: {}, idByUid: {}};

  for (const seasonUID of Object.keys(menuDef)) {
    const seasonDef = menuDef[seasonUID];
    if (!seasonDef) {
      // weird, shouldn't happen
      continue;
    }
    season.uidById[seasonDef.seasonID] = seasonDef.seasonUID;
    season.idByUid[seasonDef.seasonUID] = seasonDef.seasonID;
    for (const competitionUID of Object.keys(seasonDef.competitions)) {
      const competitionDef = seasonDef.competitions[competitionUID];
      if (!competitionDef) {
        // shouldn't happen
        continue;
      }
      competition.uidById[competitionDef.competitionID] = competitionDef.competitionUID;
      competition.idByUid[competitionDef.competitionUID] = competitionDef.competitionID;

      // we could further descend into division, and then team
      // but, `divID` and `teamID` have no alternate keys
    }
  }

  return { season, competition }
}

function State(menuDef: TeamChooser.Menu) {
  const selected : State["selected"] = ref({
    seasonUID: "",
    competitionUID: "",
    divID: "",
    teamID: "",
  })

  //
  // All the menu accesses are safe-nav, so as to mimic no-unchecked-indexed-access,
  // to handle cases where a change to a selection ends up producing an invalid selection
  // in a sub-selection, e.g. a change to selected competition renders the currently selected teamID invalid
  // This maybe could be fixed by enforcing the run-order of (selected-value-updaters, menu-option-updaters),
  // to always run the selected-value-updater first, but, that requires knowing the new options ... so, it seems
  // reasonable to assume that selected.X may become invalid.
  //
  // Without the safe nav we can reproduce toggling through selections as producing an error in the browser (esp. change-to-comp-invalidates-team),
  // (see https://inleague-llc.sentry.io/issues/4381659782/?alert_rule_id=4761582&alert_type=issue&project=5661592&referrer=slack)
  // but poking at it with a jest test seems to run the updaters in a different order (or something!) and the problem doesn't reproduce there.
  //

  const options : State["options"] = {
    seasons: computed(() => {
      const options : SelectOption[] = [];
      options.push({label: "", value: ""});
      for (const seasonUID of Object.keys(menuDef)) {
        const season = menuDef[seasonUID];
        if (!season) {
          // shouldn't happen on direct iteration
          continue;
        }
        options.push({label: season.name, value: seasonUID})
      }
      if (options.length === 1) {
        return [{value: "", label: "No seasons available"}]
      }
      return options;
    }),
    competitions: computed(() => {
      const options : SelectOption[] = [];
      options.push({label: "", value: ""});
      if (!selected.value.seasonUID) {
        // nothing to select, need to choose a season first
        return options;
      }

      for (const competitionUID of Object.keys(menuDef[selected.value.seasonUID]?.competitions ?? {})) {
        const comp = menuDef[selected.value.seasonUID]?.competitions[competitionUID];
        if (!comp) {
          continue;
        }
        options.push({value: competitionUID, label: comp.name});
      }

      if (options.length === 1) {
        // there were no options added, all we have is the nil/empty option
        return [{value: "", label: "No competitions available"}]
      }

      return options;
    }),
    divisions: computed(() => {
      const options : SelectOption[] = [];
      options.push({label: "", value: ""});
      if (!selected.value.seasonUID || !selected.value.competitionUID) {
        return options;
      }

      for (const divID of Object.keys(menuDef[selected.value.seasonUID]?.competitions[selected.value.competitionUID]?.divisions ?? {})) {
        const division = menuDef[selected.value.seasonUID]?.competitions[selected.value.competitionUID]?.divisions[divID]
        if (!division) {
          continue;
        }
        options.push({
          value: divID,
          label: division.name
        })
      }

      if (options.length === 1) {
        // there were no options added, all we have is the nil/empty option
        return [{value: "", label: "No divisions available"}]
      }

      return options;
    }),
    teams: computed(() => {
      const options : SelectOption[] = [];
      options.push({label: "", value: ""});
      if (!selected.value.seasonUID || !selected.value.competitionUID || !selected.value.divID) {
        return options;
      }

      for (const teamID of Object.keys(menuDef[selected.value.seasonUID]?.competitions[selected.value.competitionUID]?.divisions[selected.value.divID]?.teams ?? {})) {
        const team = menuDef[selected.value.seasonUID]?.competitions[selected.value.competitionUID]?.divisions[selected.value.divID]?.teams?.[teamID];
        if (!team) {
          continue;
        }
        options.push({
          value: teamID,
          label: team.displayName
        })
      }

      if (options.length === 1) {
        // there were no options added, all we have is the nil/empty option
        return [{value: "", label: "No teams available"}]
      }

      return options;
    })
  }

  const maybeGetSingularOption = (k: keyof TeamChooserSelection) : string => {
    const mappedOptionKey : keyof State["options"] = (() => {
      switch (k) {
        case "seasonUID": return "seasons"
        case "competitionUID": return "competitions"
        case "divID": return "divisions"
        case "teamID": return "teams"
        default: exhaustiveCaseGuard(k);
      }
    })();

    const availableOptions = options[mappedOptionKey].value;

    // if there are 2 "available options", really there is just 1, since the first is always the nil/empty option,
    if (availableOptions.length === 2) {
      return availableOptions[1].value;
    }
    else {
      return "";
    }
  }

  return VueNeverUnwrappable<State>({
    selected,
    options,
    maybeGetSingularOption
  })
}
