<template lang="pug">
div
  .smx-auto.w-full.flex.items-end(
    class='md:mx-2',
  )
.-my-2.mt-6.mb-2.max-w-3xl(v-if='Object.keys(volunteerDetails).length > 0')
  .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
    .shadow.border-b.border-gray-200.mb-4(class='sm:rounded-lg')
      table.min-w-full
        tbody.bg-white
          tr.border-b-2.border-gray-200
            td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
              | Name:
            td.px-6.py-4.text-sm.text-gray-500.flex.justify-between
              | {{ volunteerDetails.firstName }} {{ volunteerDetails.middleName }} {{ volunteerDetails.lastName }}
          tr.border-b-2.border-gray-200
            td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
              | Season:
            td.px-6.py-4.text-sm.text-gray-500.flex.justify-between
              | {{ season.name }}
          tr.border-b-2.border-gray-200
            td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
              | Volunteer Roles:
            td.px-6.py-4.text-sm.text-gray-500.flex.justify-between
              div(v-if='volunteerDetails.volunteerPrefs.length > 0')
                .mt-2(v-for='role in volunteerDetails.volunteerPrefs')
                  | {{ role.code }}
                  span.ml-2(v-if='role.divID') ({{ role.divName }})
          tr.border-b-2.border-gray-200
            td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
              | Background Check Status:
            td.px-6.py-4.text-sm.text-gray-500.flex.justify-between
              BackgroundCheck(
                :volunteerID='volunteerID',
                :seasonUID='seasonUID'
              )
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { onMounted, ref } from 'vue'
import BackgroundCheck from 'src/components/Registration/backgroundCheck/backgroundCheck.vue'
import { Client } from "src/store/Client"
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BackgroundCheck,
  },
  props: {
    seasonUID: { required: true, type: String },
    volunteerID: { required: true, type: String },
    title: { type: String, default: '' },
  },
  setup(props) {
    const volunteerDetails = ref({})
    const season = ref({})
    const backgroundStatus = ref({})

    const getDivName = async (divID: string) => {
      if (divID) {
        const division = await Client.getDivisionByID(divID)
        return division?.division ?? divID
      } else {
        return divID
      }
    }

    const addDivName = async (details: any) => {
      const updatedDetails = { ...details }
      for (let i = 0; i < updatedDetails.volunteerPrefs.length; i++) {
        updatedDetails.volunteerPrefs[i].divName = await getDivName(
          updatedDetails.volunteerPrefs[i].divID
        )
      }
      return updatedDetails
    }

    const getVolunteerDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/user/${props.volunteerID}/season/${props.seasonUID}`
        )
        return response.data.data
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    const getBackgroundStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/volunteer/${props.volunteerID}/season/${props.seasonUID}`
        )
        return response.data.data
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    onMounted(async () => {
      season.value = await Client.getSeasonByUidOrFail(props.seasonUID)
      const details = await getVolunteerDetails()
      volunteerDetails.value = await addDivName(details)
      backgroundStatus.value = await getBackgroundStatus()
    })

    return {
      volunteerDetails,
      season,
    }
  }
})
</script>
