<template lang="pug">
TDialog
  .text-xl.mb-6 Override Program Assignment
  ul
    li.text-left.mb-3
      | This program override function will transfer {{ targetPlayerUiName }}'s {{ targetCompRegCompetitionUiName }} registration to the selected program.
    li.text-left.mb-3
      | No automatic refunds or fee collections are performed. The player's division will be re-calculated based on the target program.
  .text-base.text-gray-500.text-left
    .mt-2 Available programs:
    FormKit(type="select" v-model="selectedCompetitionUID")
      option(v-for="x in competitionReassignmentOptions" :value="x.value" :key="x.key") {{ x.label }}
    .flex.justify-between.items-end.-my-2
      FormKit(
        type='button'
        label=`Cancel`
        wrapper-class='mt-2',
        @click="close()"
        :classes="{ input: 'cancelBtn' }"
      )
      FormKit(
        :disabled="!selectedCompetitionUID"
        type='button'
        @click="save(selectedCompetitionUID)"
        :label="selectedCompetitionUID ? 'Override Program' : 'Choose a program'"
      )
</template>

<script lang="ts">
import { defineComponent,ref, computed } from 'vue'
import { Guid } from "src/interfaces/InleagueApiV1"
import { propsDef, emitsDef } from "./ChangeCompetitionModal";

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, {emit}) {
    const competitionReassignmentOptions = computed(() => {
      const result : {key: string, value: string, label: string}[] = [];
      for (const competitionUID of Object.keys(props.availableCompetitionReassignmentTargetsMap)) {
        result.push({
          key: competitionUID,
          value: competitionUID,
          label: props.availableCompetitionReassignmentTargetsMap[competitionUID].competition
        })
      }
      return result;
    });

    const targetCompRegCompetitionUiName = computed(() => {
      return props.target.competition.competition;
    });

    const targetPlayerUiName = computed(() => `${props.target.player.playerFirstName} ${props.target.player.playerLastName}`);

    const selectedCompetitionUID = ref("");

    return {
      save: (newCompetitionUID: Guid | undefined) : void => {
        if (!newCompetitionUID) {
          return;
        }
        emit("save", {targetCompetitionRegistration: props.target.competitionRegistration, newCompetitionUID});
      },
      close: () : void => {
        emit("close");
      },
      selectedCompetitionUID,
      competitionReassignmentOptions,
      targetCompRegCompetitionUiName,
      targetPlayerUiName,
    }
  }
})
</script>