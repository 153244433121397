<template lang="pug">
.px-12.max-w-6xl
  .mb-5.bg-white.shadow(class='sm:rounded-lg')
    .px-4.py-5
      h3.text-lg.leading-6.font-medium.text-gray-900
        | Add Players by Last Name &amp; DOB
      p
        | Any player whose last name and DOB matches an entry on this list may register.
      .flex.min-w-full.items-end
        FormKit(
          type='text',
          placeholder='Last Name',
          label='Player\'s Last Name',
          v-model='form.playerLastName'
        )
        .ml-2
          FormKit(
            type='text',
            placeholder='MM/DD/YYYY',
            label='Date of Birth',
            v-model='form.playerDOB'
          )
        .ml-2.-mb-2
          FormKit(
            @click='addPlayerByNameAndDOB',
            type='button',
            label='Add Player'
          )
  .mb-5.bg-white.shadow(class='sm:rounded-lg')
    .px-4.py-5
      h3.mb-1.text-lg.leading-6.font-medium.text-gray-900
        | Search for players to make eligible
      div
        form.flex.flex-wrap.gap-2(@submit.prevent="playerSearch.doSearch")
          span.grow
            input(type="text", style="width:100%;", v-model="playerSearch.nameQuery" ref="playerSearchInputElement")
          input.cursor-pointer.bg-green-700.text-white.font-bold.py-2.px-4.rounded(class='hover:bg-green-900' type="submit" value="Search")
      .m-1
        .quasar-style-wrap.mt-8(v-if='playerSearch.results.length')
          .q-py-md
            q-table(
              :rows='playerSearch.results',
              :columns='playerSearch.columns',
              row-key='childID',
              :pagination='playerSearch.pagination',
              :rows-per-page-options='[]'
            )
              template(v-slot:body-cell-Player='props')
                q-td(
                  @click='isAlreadyEligible.has(props.row.childID) ? () => {} : addPlayerByID(props.row.childID)'
                  :class="{'text-grey-13' : isAlreadyEligible.has(props.row.childID), underline: !isAlreadyEligible.has(props.row.childID), 'cursor-pointer': !isAlreadyEligible.has(props.row.childID)}"
                )
                  div {{ props.row.playerFirstName }} {{ props.row.playerLastName }}
                  div.text-xs(v-if="isAlreadyEligible.has(props.row.childID)") already eligible
              template(v-slot:body-cell-familyProfile='cellProperties')
                q-td
                  router-link(
                    :to='{ name: "family-profile", params: { familyIDs: cellProperties.row.familyID } }'
                  )
                    FormKit(type='button', label='Family Profile' outer-class='noMargin')
        div(v-else-if='playerSearch.hasSearched', data-cy='noResults') Couldn't find any players by that name

  .q-pa-md.quasar-style-wrap.mt-8(data-cy='currentlyEligible')
    q-table(
      title=`Currently Eligible`,
      :filter="currentlyEligibleFilter"
      :filterMethod="currentlyEligibleFilter.doFilter"
      :rows='eligibilityList',
      :columns='eligibleColumns',
      row-key='id',
      :rows-per-page-options='[25]',
    )
      template(v-slot:header='{cols}')
        q-tr(style="position:sticky; top:0; background-color:white; z-index:1;")
          q-td(valign="top" v-for="col in cols")
            div.text-sm {{col.label}}
            div.text-sm(v-if="col.name === 'playerName'")
              input.p-0(style="width:100%" v-model="currentlyEligibleFilter.name" type="text")
            div.text-sm(v-if="col.name === 'DOB'")
              input.p-0(style="width:100%" v-model="currentlyEligibleFilter.DOB" type="date")
            div.text-sm(v-if="col.name === 'type'")
              select.p-0(style="width:100%" v-model="currentlyEligibleFilter.type")
                option(v-for="option in currentlyEligibleFilter.typeOptions" :key="option.key" :value="option.value" :selected="currentlyEligibleFilter.typeOptions === option.value") {{ option.label }}
      template(v-slot:body='{row: competitionEligibility, cols}')
        q-tr(:style="{backgroundColor: competitionEligibilityRowHighlightState[competitionEligibilityUnifiedKey(competitionEligibility)]}")
          q-td {{ getColumnDataFromColumnsListing(cols, eligibleColumnsColnames.playerName) }}
          q-td {{ getColumnDataFromColumnsListing(cols, eligibleColumnsColnames.DOB) }}
          q-td {{ getColumnDataFromColumnsListing(cols, eligibleColumnsColnames.type) }}
          q-td
            .text-green-600.cursor-pointer(
              @click='deleteEligibility(competitionEligibility)',
              class='hover:text-green-900'
            ) Delete
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import ChildLookup from 'src/components/Lookup/Player/playerLookup.vue'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'


import { dayJSDate } from 'src/helpers/formatDate'
import { useIziToast, escapeRegExp, exhaustiveCaseGuard } from 'src/helpers/utils'
import * as ilapi from "src/composables/InleagueApiV1"
import { CompetitionEligibility, CompetitionEligibleChild, CompetitionEligibleNameAndDOB, Datelike, Guid, isCfNull, isCompetitionEligibility, isCompetitionEligibleChild, isCompetitionEligibleNameAndDob, Child } from 'src/interfaces/InleagueApiV1'
import dayjs, { Dayjs } from "dayjs"

type EligibilityID = CompetitionEligibility["eligibilityID"]

export default defineComponent({
  name: 'competition-eligibility',
  components: {
    ChildLookup,
  },
  props: {
    competitionUID: {
      required: true,
      type: String,
    },
    seasonUID: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const iziToast = useIziToast()
    const competitions: any = ref([])
    const seasons: any = ref([])
    const eligibilityList = ref<CompetitionEligibility[]>([])

    /**
     * Answers the question: Should some row be highlighted?
     */
    const competitionEligibilityRowHighlightState = ref<{[key: CompetitionEligibilityUnifiedKey]: undefined | "yellow"}>({});

    interface CurrentlyEligibleDisplayFilter {
      name: string,
      DOB: Datelike,
      type: CompetitionEligibility["type"] | "",
      typeOptions: {key: number, label: string, value: CompetitionEligibility["type"] | ""}[],
      dayjsOrNull: (v: string) => Dayjs | null,
      dobsAreEquivalent: (l: Dayjs, r: Dayjs) => boolean,
      // matches the signature that q-table will call `filterMethod` with
      doFilter: (rows: CompetitionEligibility[], filter: CurrentlyEligibleDisplayFilter) => CompetitionEligibility[],
      filterResultLength: number,
      isFiltering: boolean,
    }
    const currentlyEligibleFilter = reactive<CurrentlyEligibleDisplayFilter>({
      name: "",
      DOB: "",
      type: "",
      typeOptions: [
        {key: 1, label: "", value: ""},
        {key: 2, label: "Exact match", value: "childID"},
        {key: 3, label: "Name/DOB", value: "nameDOB"}
      ],
      dayjsOrNull: (v: string) => {
        const r = dayjs(v);
        return r.isValid() ? r : null;
      },
      dobsAreEquivalent: (l: Dayjs, r: Dayjs) => {
        const y = l.year() === r.year();
        const m = l.month() === r.month();
        const d = l.date() === r.date();
        return y && m && d;
      },
      doFilter: (rows: CompetitionEligibility[], filter: CurrentlyEligibleDisplayFilter) => {
        const name = filter.name.trim();
        const namePattern = name === "" ? null : new RegExp(escapeRegExp(name), "i")

        const DOB = filter.dayjsOrNull(filter.DOB.trim());

        const type = filter.type;

        if (!namePattern && !DOB && !type) {
          filter.isFiltering = false;
          filter.filterResultLength = rows.length;
          return rows;
        }

        const result : CompetitionEligibility[] = [];
        for (const row of rows) {
          if (namePattern && !namePattern.test(`${row.firstName} ${row.lastName}`)) {
            continue;
          }

          if (DOB) {
            const rowDOB = filter.dayjsOrNull(row.DOB);
            if (rowDOB && !filter.dobsAreEquivalent(DOB, rowDOB)) {
              continue;
            }
          }

          if (type && row.type !== type) {
            continue;
          }

          result.push(row);
        }

        filter.filterResultLength = result.length;
        filter.isFiltering = true;
        return result;
      },
      filterResultLength: 0,
      isFiltering: false,
    })

    // maintain an ordered list of what we added during this page visit
    const addedThisSession = (() => {
      const insertionMap = new Map<EligibilityID, number>();
      let idx = 0;
      const add = (v: CompetitionEligibleChild | CompetitionEligibleNameAndDOB) : void => {
        if (isCompetitionEligibleChild(v)) {
          insertionMap.set(`c-${v.childEligibilityID}`, idx++);
        }
        else {
          insertionMap.set(`n-${v.nameDOBID}`, idx++);
        }
      }
      const has = (id: EligibilityID) : boolean => insertionMap.has(id);
      const get = (id: EligibilityID) : number | undefined => insertionMap.get(id);
      const _delete = (id: EligibilityID) : void => { insertionMap.delete(id); }
      return {
        add,
        has,
        get,
        delete: _delete,
        // just for peering into in a debugger; typed as never because it should never be used in any logic
        __debugview__: insertionMap as never,
      } as const;
    })();

    const isAlreadyEligible = ref(new Set<Guid>());
    const initialPagination = {
      sortBy: 'asc',
      rowsPerPage : 100
    };
    const eligibleColumnsColnames = {
      playerName: "playerName",
      DOB: "DOB",
      type: "type",
      delete: "delete"
    } as const;
    type EligibleColumnsColnames = (typeof eligibleColumnsColnames)[keyof typeof eligibleColumnsColnames];
    const eligibleColumns = ref([{
        name: eligibleColumnsColnames.playerName,
        required: true,
        label: 'Name',
        align: 'left',
        field: (data: CompetitionEligibility) => {
          const fname = isCfNull(data.firstName) ? "" : ( data.firstName + ' ');
          const lname = data.lastName;
          return fname + lname;
        },
        sortable: true,
      },{
        name: eligibleColumnsColnames.DOB,
        required: true,
        label: 'Birthdate',
        align: 'left',
        field: (data: CompetitionEligibility) => {
          return `${dayJSDate(data.DOB)}`
        },
        sortable: true,
      },{
        name: eligibleColumnsColnames.type,
        required: true,
        label: 'Type',
        align: 'left',
        field: (data: CompetitionEligibility) => {
          return `${data.type === 'childID' ? 'Exact Match' : 'Name/DOB'}`
        },
        sortable: true,
      },{
        name: eligibleColumnsColnames.delete,
        required: true,
        label: 'Delete',
        align: 'left',
        field: (data: CompetitionEligibility) => {
          return data
        },
        sortable: false,
    }] as const)

    const colIndexByColname = (() => {
      const map : Record<EligibleColumnsColnames, number> = {} as any;
      eligibleColumns.value.forEach((e,i) => { map[e.name] = i;});
      return map;
    })();

    // seems that manually lay out a table (in order to give test and selector attributes to the <tr> and <td> elements)
    // we must also manually scan through provided column listing definitions per row
    const getColumnDataFromColumnsListing = (columnsListing: {name: EligibleColumnsColnames, value: any}[], target: EligibleColumnsColnames) => {
        return columnsListing[colIndexByColname[target]].value;
    }

    const form = ref({
      ignoreDatesIfEligible: false,
      registrationStart: '',
      registrationEnd: '',
      competitionUID: '',
      seasonUID: '',
      playerLastName: '',
      playerDOB: '',
    })
    const formatDate = (date: string): string => {
      const incomingDate = new Date(date)
      return incomingDate.toISOString().split('.')[0]
    }
    const getSeasonalCompetitions = async () => {
      if (form.value.competitionUID.length && form.value.seasonUID.length) {
        const response = await axiosInstance.get(
          `v1/competition/${form.value.competitionUID}/season/${form.value.seasonUID}`
        )
        form.value.ignoreDatesIfEligible =
          response.data.data.ignoreDatesIfEligible
        form.value.registrationStart = formatDate(
          response.data.data.registrationStart
        )
        form.value.registrationEnd = formatDate(
          response.data.data.registrationEnd
        )
      }
    }

    /**
     * this is called a lot, to paper over the difference between what the get endpoint returns,
     * and what the "create" endpoints return
     */
    const rebuildEligibilityList = async () => {
      if (form.value.competitionUID.length && form.value.seasonUID.length) {
        const baseList = await ilapi.getCompetitionEligibility(
          axiosInstance, {
            competitionUID: form.value.competitionUID,
            seasonUID: form.value.seasonUID
          }
        );

        const freshThisSession : CompetitionEligibility[] = [];
        const notFreshThisSession : CompetitionEligibility[] = [];

        for (const competitionEligibility of baseList) {
          if (addedThisSession.has(competitionEligibility.eligibilityID)) {
            freshThisSession.push(competitionEligibility);
          }
          else {
            notFreshThisSession.push(competitionEligibility);
          }
        }

        freshThisSession.sort((l: CompetitionEligibility, r: CompetitionEligibility) => {
          // these are definitely non-null,
          // because the list we're sorting is populated with exactly a subset of values from the map we're indexing into

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const lv = addedThisSession.get(l.eligibilityID)!;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const lr = addedThisSession.get(r.eligibilityID)!;

          // sort backwards, for "most recently added first"
          return lv < lr ? 1 : -1;
        })

        // "fresh this session" first, then the rest
        eligibilityList.value = [...freshThisSession, ...notFreshThisSession];
      }

      await getSeasonalCompetitions()

      isAlreadyEligible.value = (() => {
        const t : Guid[] = [];
        for (const {type, childID} of eligibilityList.value) {
          if (type === "childID") {
            t.push(childID);
          }
        }
        return new Set(t);
      })();
    }
    const addPlayerByID = async (childID: Guid) => {
      const v = await ilapi.addCompetitionEligibility(axiosInstance, {
        type: "childID",
        competitionUID: props.competitionUID,
        seasonUID: props.seasonUID,
        childID: childID
      });

      addedThisSession.add(v);

      await rebuildEligibilityList()
      iziToast.info({message: `Player added to eligibility list.`})

      playerSearchInputElement.value.setSelectionRange(0, playerSearchInputElement.value.value.length);
      playerSearchInputElement.value.focus({preventScroll: true});

      flashCompetitionEligibilityRowOnce(v);
    }

    const addPlayerByNameAndDOB = async () => {
      const v = await ilapi.addCompetitionEligibility(axiosInstance, {
        type: "nameDOB",
        competitionUID: props.competitionUID,
        seasonUID: props.seasonUID,
        lastName: form.value.playerLastName,
        DOB: form.value.playerDOB,
      });

      addedThisSession.add(v);

      await rebuildEligibilityList()

      playerSearchInputElement.value.setSelectionRange(0, playerSearchInputElement.value.value.length);
      playerSearchInputElement.value.focus({preventScroll: true});

      flashCompetitionEligibilityRowOnce(v);
    }

    const deleteEligibility = async (data: CompetitionEligibility) => {
      let requestBody: object
      if (data.type == 'childID') {
        // Exact Match
        requestBody = { seasonUID: data.seasonUID, childID: data.childID }
      } else {
        // Name/DOB
        requestBody = {
          seasonUID: data.seasonUID,
          lastName: data.lastName,
          DOB: data.DOB,
        }
      }
      await axiosInstance.delete(
        `v1/competitionEligibility/${data.competitionUID}`,
        {
          data: requestBody,
        }
      )
      addedThisSession.delete(data.eligibilityID);
      await rebuildEligibilityList()
    }

    const initValues = async () => {
      form.value = {
        ignoreDatesIfEligible: false,
        registrationStart: '',
        registrationEnd: '',
        competitionUID: props.competitionUID,
        seasonUID: props.seasonUID,
        playerLastName: '',
        playerDOB: '',
      }
      await rebuildEligibilityList()
    }

    watch(
      () => [props.seasonUID, props.competitionUID],
      async () => {
        await initValues()
      }
    )

    /**
     * template ref
     */
    const playerSearchInputElement = ref<HTMLInputElement>(/*definitely assigned on DOM mount*/ null as any);

    onMounted(async () => {
      await initValues()
    })

    const playerSearch = reactive({
      nameQuery: "",
      results: [] as ilapi.PlayerSearchResult[],
      pagination: {
        sortBy: 'asc',
        rowsPerPage : 25
      },
      hasSearched: false,
      doSearch: async () => {
        playerSearch.results = await ilapi.playerSearch(axiosInstance, {nameQuery: playerSearch.nameQuery});
        playerSearch.hasSearched = true;
      },
      columns: [
        {
          name: 'Player',
          required: false,
          label: 'Player',
          align: 'left',
          sortable: true,
          field: (player: Child) => {
            return `${player.playerFirstName} ${player.playerLastName}`
          },
        },
        {
          name: 'Parent',
          align: 'left',
          label: 'Parent',
          field: (player: Child) => {
            return `${player.parent1FirstName} ${player.parent1LastName}`
          },
          sortable: true,
        },
        {
          name: 'Email',
          align: 'left',
          label: 'Email',
          field: (player: Child) => {
            return player.parent1Email
          },
          sortable: true,
        },
        {
          name: 'DOB',
          align: 'left',
          label: 'Birthdate',
          field: (player: Child) => {
            return `${dayJSDate(player.playerBirthDate)}`
          },
          sortable: true,
        },
        {
          name: 'familyProfile',
          align: 'left',
          label: 'Family Profile',
          field: (player: Child) => {
            return player.familyID
          },
          sortable: true,
        },
      ],
    });

    const flashCompetitionEligibilityRowOnce = (v: CompetitionEligibility | CompetitionEligibleChild | CompetitionEligibleNameAndDOB) => {
      const key = competitionEligibilityUnifiedKey(v);

      // currently, we only need/want a single row to be highlighted at a time; so we can clear all the row highlight state
      competitionEligibilityRowHighlightState.value = {[key]: "yellow"} as const

      // unhighlight after some timeout
      setTimeout(() => {
        delete competitionEligibilityRowHighlightState.value[key];
      }, 2500)
    }

    return {
      competitions,
      seasons,
      eligibilityList,
      form,
      formatDate,
      getSeasonalCompetitions,
      rebuildEligibilityList,
      addPlayerByID,
      deleteEligibility,
      addPlayerByNameAndDOB,
      dayJSDate,
      eligibleColumns,
      initialPagination,
      isAlreadyEligible,
      playerSearch,
      currentlyEligibleFilter,
      playerSearchInputElement,
      eligibleColumnsColnames,
      getColumnDataFromColumnsListing,
      competitionEligibilityUnifiedKey,
      competitionEligibilityRowHighlightState,
    }
  },
})

/**
 * create a synthetic key, which can help map from
 * {CompetitionEligibleChild, CompetitionEligibleNameAndDob} -> CompetitionEligibility
 */
function competitionEligibilityUnifiedKey(
  v: CompetitionEligibility | CompetitionEligibleChild | CompetitionEligibleNameAndDOB
) : CompetitionEligibilityUnifiedKey {
  const unified = partialUnifyDisjointEligibilityShapes(v);

  switch (unified.type) {
    case "childID":
      return `childID/${unified.childID}` as const
    case "nameDOB":
      return `nameDOB/${dayjs(unified.DOB).format("MM/DD/YYYY")}` as const
    default: exhaustiveCaseGuard(unified);
  }

  function partialUnifyDisjointEligibilityShapes(
    v: CompetitionEligibility | CompetitionEligibleChild | CompetitionEligibleNameAndDOB
  ) : {type: "childID", childID: Guid} | {type: "nameDOB", DOB: Datelike} {
    if (isCompetitionEligibility(v)) {
      switch (v.type) {
        case "childID":
          return {type: v.type, childID: v.childID}
        case "nameDOB":
          return {type: v.type, DOB: v.DOB};
        default: exhaustiveCaseGuard(v);
      }
    }
    else if (isCompetitionEligibleChild(v)) {
      return {type: "childID", childID: v.childID}
    }
    else if (isCompetitionEligibleNameAndDob(v)) {
      return {type: "nameDOB", DOB: v.DOB}
    }
    else {
      throw "unreachable";
    }
  }
}

type CompetitionEligibilityUnifiedKey = `childID/${string}` | `nameDOB/${string}`;

</script>
