import { Step, RegistrationJourneyBreadcrumbElement } from 'src/components/Registration/RegistrationJourneyBreadcrumb'
import SeasonJustEmit from 'src/components/Registration/selections/season-just-emit.vue'
import { useRouter } from 'vue-router'
import { defineComponent } from 'vue'
import { type Emits } from "./season-just-emit.ilx"
import { type Guid } from 'src/interfaces/InleagueApiV1'
import * as R_SelectSeason from "./R_SelectSeason.route"

export default defineComponent({
  name: 'Select Season',
  components: {
    SeasonJustEmit,
  },
  props: R_SelectSeason.propsDef,
  setup(props) {
    const router = useRouter()

    const proceedToSelection = async (seasonUID: string) => {
      if(props.role==='player'){
        await router.push({name: 'select-player', params: {seasonUID: seasonUID}})
      } else {
        await router.push({name: 'select-volunteer', params: {seasonUID: seasonUID}})
      }
    }

    const handleSeasonSelected : Emits["season-selected"] = async (seasonUID: string) => {
      await proceedToSelection(seasonUID);
    }

    const maybeAutoSelectSingleSeason : Emits["seasons-loaded"] = async (seasonUIDs: Guid[]) => {
      if (props.role === "player" && seasonUIDs.length === 1) {
        await proceedToSelection(seasonUIDs[0]);
      }
      else {
        // no-op
      }
    }

    return () => (
      <div>
        <div class="mb-6">
          <RegistrationJourneyBreadcrumbElement
            detail={{step: Step.selectSeason}}
          />
        </div>
        <SeasonJustEmit
          onSeason-selected={handleSeasonSelected}
          onSeasons-loaded={maybeAutoSelectSingleSeason}
          role={props.role}
        />
      </div>
    )
  },
})
