import { Filterable } from "src/composables/InleagueApiV1.ReportBuilder"
import { UiOption, exhaustiveCaseGuard, forceCheckedIndexedAccess } from "src/helpers/utils"
import { maybeGetOptionsFromArgDefOptions } from "./ReportBuilder.node"
import { Connective, NodeType, UiQNode } from "./UiQNode"
import { DAYJS_FORMAT_HTML_DATE, dayjsFormatOr } from "src/helpers/formatDate"

export type FormValue = string | number

export interface FilterableFormData {
  filterableDef: Filterable
  values: {
    /**
     * the name of the associated argument (see schema `argsDef`)
     */
    name: string,
    value: FormValue
  }[]
}

export type TextOp = (typeof TextOp)[keyof typeof TextOp]
const TextOp = {
  "is" : "is",
  "isNot" : "isNot",
  "contains" : "contains",
  "startsWith" : "startsWith",
  "endsWith" : "endsWith",
  "isNotNull" : "isNotNull",
  "isNull" : "isNull"
} as const;

export function FilterableFormData(
  filterableDef: Filterable,
  values: null | Record<string, any>,
  getSharedOptionsByName: (name: string) => UiOption[],
) : FilterableFormData {
  switch (filterableDef.type) {
    case "functionlike": {
      return {
        filterableDef,
        values: filterableDef.argsDef.map(def => {
          let value : string;
          if (def.type === "date") {
            value = dayjsFormatOr(values?.[def.name], DAYJS_FORMAT_HTML_DATE, "")
          }
          else {
            value = values?.[def.name] ?? firstOfOptionsOrEmptyString(maybeGetOptionsFromArgDefOptions(def.options, getSharedOptionsByName))
          }

          return {
            name: def.name,
            value,
          }
        })
      }
    }
    case "binop-implicit-lhs": {
      return {
        filterableDef,
        values: [
          {
            name: "op",
            value: filterableDef.opDef.ops[0] || ""
          },
          {
            name: filterableDef.opDef.rhs.name,
            value: values?.[filterableDef.opDef.rhs.name] ?? firstOfOptionsOrEmptyString(
              maybeGetOptionsFromArgDefOptions(filterableDef.opDef.rhs.options, getSharedOptionsByName)
            )
          }
        ]
      }
    }
    case "boolean": {
      return {
        filterableDef,
        values: [
          {
            name: "value",
            value: values?.["value"] ?? "0"
          },
        ]
      }
    }
    default: exhaustiveCaseGuard(filterableDef)
  }

  function firstOfOptionsOrEmptyString(options: UiOption[] | undefined) {
    return forceCheckedIndexedAccess(options ?? [], 0)?.value || "";
  }
}

export function fixupAllRedundantNodes(root: Connective) : Connective {
  const workedNodeIDs = new Set<number>();

  return worker(root);

  function worker(root: Connective) : Connective {
    for (let i = 0; i < root.children.length; ++i) {
      const child = root.children[i];

      if (workedNodeIDs.has(child.nodeID)) {
        continue;
      }

      switch (child.type) {
        case NodeType.connective:
          // This can mutate the children list we are iterating over.
          // We go depth first, and on the next iteration, the "current" node (root.children[i])
          // will be either:
          //  - unchanged
          //    - meaning iteration proceeds normally
          //  - replaced with 1-or-more nodes that have been lifted up
          //    - root.children[i]     points to the first lifted-up node (which has already been processed)
          //    - root.children[i+1]   points to the next lifted-up node (which has already been processed)
          //    - root.children[i+n]   points to the Nth lifted-up node (which has already been processed)
          //    - root.children[i+n+1] points to conceptually the "next" node ("next" being the i+1 NOW, had no merging taken place)
          //    - we don't "double work" nodes because we will recognize the i+1...i+n in the above examples by checking the
          //      `worked` Set for already worked nodeIDs
          worker(child);
          break;
        case NodeType.predicate:
          break;
        default: exhaustiveCaseGuard(child);
      }

      workedNodeIDs.add(child.nodeID)
    }

    root.mergeWithParentIfRedundant();

    return root;
  }
}

/**
 * return true if there is any node in the tree considered redundant
 */
export function containsAnyRedundantDescendantNodes(root: Connective) : boolean {
  for (const node of root.children) {
    switch (node.type) {
      case NodeType.connective: {
        if (node.isRedundant() || containsAnyRedundantDescendantNodes(node)) {
          return true;
        }
        continue;
      }
      case NodeType.predicate:
        continue;
      default: exhaustiveCaseGuard(node)
    }
  }
  return false;
}
