import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  detail: vReqT<RoutePropsVariant>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "select-payment-option.main",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | Main

export interface Main extends RoutePropsBase {
  routeName: typeof RouteNames.main,
  playerID: Guid,
  seasonUID: Guid,
  registrationID: Guid,
  competitionUIDs: Guid[],
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteNames.main:
      return {name: v.routeName, params: {
        playerID: v.playerID,
        seasonUID: v.seasonUID,
        registrationID: v.registrationID,
        competitionUIDs: v.competitionUIDs,
      }}
    default: exhaustiveCaseGuard(v.routeName);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.main:
      return {
        detail: {
          routeName: name,
          ...route.params as any,
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}

export function hasSomeRoutePermission() : boolean {
  return true;
}
