<template lang="pug">
Portlet(
  label='Team Assignments',
  @expand='toggleExpand',
  :icon='children.fontAwesome',
  prefix='fas'
)
  div(v-if='(isMobile && show && loaded) || (!isMobile && loaded)')
    .flex.flex-col
      .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg' v-if="Object.keys(childrenWithSeasonName).length && Object.keys(childrenWithSeasonName.teamAssignments).length")
            //- fixme: name refactor `child` -> `teamAssignment`
            .font-bold(v-for='child in childrenWithSeasonName.teamAssignments')
              .px-2.w-full.bg-gray-100 {{ child.firstName }} {{ child.lastName }} - {{ child.seasonName }}
              table.min-w-full.divide-y.divide-gray-200.font-normal.text-sm.border-b.border-t
                thead.bg-white.divide-y.divide-gray-200
                  tr.italic.mt-4.text-sm
                    td.w-20
                    td.w-24 Team
                    td Coach
                    td Practice Time / Place
                tbody.bg-white.divide-y.divide-gray-200
                  tr
                    td.text-xs.text-center.whitespace-nowrap.text-red-600.cursor-pointer
                      div(
                        v-if="child.hasPayments"
                        @click='teamPayment(child.teamID, child.registrationID)'
                      )
                        font-awesome-icon.mr-1.text-center(
                          :icon='["fas", "dollar-sign"]'
                        )
                        | Team Payment
                      div(v-else)
                        //- nothing to do
                    td.ml-4.whitespace-nowrap.font-bold.max-w-xs
                      div {{ child.teamDesignation }}
                      span(v-if='child.teamName') ({{ child.teamName }})
                      template(v-if="child.showFamilyRosters")
                        .cursor-pointer.font-normal.italic(
                          @click='viewRoster(child.teamID)'
                        ) View Roster
                      template(v-else)
                        //- nothing to do
                    td
                      div(v-if='child.headCoaches.length')
                        .mb-2.cursor-pointer(
                          v-for='coach in child.headCoaches'
                        )
                          a(:href='`mailto:${coach.email}`')
                            font-awesome-icon.mr-2(:icon='["fas", "comments"]')
                            | {{ coach.firstName }} {{ coach.lastName }}
                      div(v-else)
                        | TBD
                    td
                      div(v-if='child.practiceLoc.length') {{ child.practiceLoc }}
                      div(v-if='child.practiceTime.length') {{ child.practiceTime }}
                    td
                      .mb-2.cursor-pointer(v-for='coach in child.headCoaches')
                        .italic.font-medium(
                          v-if='canProvideFeedback(coach.userID)',
                          @click='provideFeedback(coach.userID, child.teamID)'
                        )
                          | Coach Feedback
          div(v-else) No Team Assignments
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, Ref } from 'vue'
import Portlet from 'src/components/Portlets/Portlet.vue'
import { PortletI } from 'src/interfaces/portlets'
import { getCurrentInstance } from 'vue'
import { copyViaJsonRoundTrip, vReqT } from 'src/helpers/utils'
import { UserData } from 'src/interfaces/Store/user'
import { System } from 'src/store/System'
import { User } from "src/store/User"
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'TeamAssignments',
  components: {
    Portlet,
  },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
    children: vReqT<PortletI>(),
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const show = ref(props.showContent)
    const loaded = ref(false)
    const childrenWithSeasonName = ref({}) as Ref<PortletI>
    const localInstance = getCurrentInstance()

    const userID = computed(() => {
      return User.value.userID
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const clientUrl = computed(() => {
      return Client.value.instanceConfig.appdomain
    })

    const clientHasCoachFeedback = computed(() => {
      return Client.value.instanceConfig.hascoachfeedback
    })

    const clientFamily = computed(() => {
      return (User.value.userData as UserData).familyIDs
    })

    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    const viewRoster = (id: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(
        `https://${clientUrl.value}/rosters/tournament-roster.cfm?team=${id}`
      )
    }

    const teamPayment = (teamID: string, registrationID: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(
        `https://${clientUrl.value}/invoices/teamPayment?teamID=${teamID}&registrationID=${registrationID}`
      )
    }

    const provideFeedback = (coachID: string, teamID: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(
        `https://${clientUrl.value}/players/coach-feedback.cfm?coachID=${coachID}&teamID=${teamID}`
      )
    }

    const getSeason = async (UID: string) => {
      const seasonDetails = await Client.getSeasonByUID(UID);
      // the failure case shouldn't really happen but we could possibly look up a UID that
      // doesn't exist or etc. Ugly but better than crashing.
      return seasonDetails?.name || "(no season name)";
    }

    const canProvideFeedback = (coachUID: string) => {
      if (clientHasCoachFeedback.value) {
        if (clientFamily.value) {
          let isFamily = false
          for (let i = 0; i < clientFamily.value.length; i++) {
            if (coachUID === clientFamily.value[i]) {
              isFamily = true
            }
          }
          return !isFamily
        }
      } else {
        return clientHasCoachFeedback.value
      }
    }

    onMounted(async () => {
      const children = copyViaJsonRoundTrip(props.children)

      for (let i = 0; i < children.teamAssignments.length; i++) {
        children.teamAssignments[i].seasonName = await getSeason(
          children.teamAssignments[i].seasonUID
        )
      }

      childrenWithSeasonName.value = children
      loaded.value = true
    })

    return {
      show,
      userID,
      toggleExpand,
      isMobile,
      viewRoster,
      teamPayment,
      getSeason,
      childrenWithSeasonName,
      loaded,
      provideFeedback,
      clientHasCoachFeedback,
      canProvideFeedback,
    }
  },
})
</script>
