import { PropType, ExtractPropTypes } from "vue"
import { Competition, CompetitionRegistration } from "src/interfaces/InleagueApiV1"
import { PlayerDetailsI } from "src/interfaces/Store/registration";
import { ExtractEmitsHandlers } from "src/helpers/utils";

export function propsDef() {
  return {
    target: {
      required: true as const,
      type: Object as PropType<{
        player: PlayerDetailsI,
        competition: Competition,
        competitionRegistration: CompetitionRegistration,
      }>
    },
  }
}

export function emitsDef() {
  return {
    commitToggle: (_namedArgs: {competitionRegistration: CompetitionRegistration, freshWaitlistValue: boolean}) => true,
    cancel: () => true,
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
