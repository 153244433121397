import { exhaustiveCaseGuard } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RouteDetail>
  },
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteName = {
  new: "event-editor.new",
  edit: "event-editor.edit",
  clone: "event-editor.clone"
} as const;

type RouteName = typeof RouteName;

export type RouteDetail =
  | R_New
  | R_Edit
  | R_Clone

export interface R_New {
  name: RouteName["new"]
}

export interface R_Edit {
  name: RouteName["edit"],
  eventID: iltypes.Guid
}

export interface R_Clone {
  name: RouteName["clone"],
  sourceEventID: iltypes.Guid
}

/**
 * for strongly typed router pushes / router-link
 */
export function routeDetailToRoutePath(detail: RouteDetail) : RouteLocationRaw {
  switch(detail.name) {
    case RouteName.new:
      return {name: detail.name}
    case RouteName.edit:
      return {name: detail.name, params: {eventID: detail.eventID}};
    case RouteName.clone:
      return {name: detail.name, params: {sourceEventID: detail.sourceEventID}};
    default:
      exhaustiveCaseGuard(detail);
  }
}
