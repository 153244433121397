import type { AxiosInstance } from "axios";
import * as iltypes from "src/interfaces/InleagueApiV1"
import type { CheckedOmit } from "src/helpers/utils"
import type { EventChild } from "src/interfaces/Store/events";

/**
 * todo: move into api, clarify shape contract; see definition of EventChild for how it is a (subtype of?) a Child entity
 */
export async function getChildrenBelongingToSomeUserWithAdhocSeasonalInformation(
  axios: AxiosInstance,
  args: {userID: iltypes.Guid, seasonID: iltypes.Integerlike, competitionUID: iltypes.Guid}
) : Promise<CheckedOmit<EventChild, "divisionName">[]> {
  const response = await axios.get(
    `v1/children/?userid=${args.userID}&seasonid=${args.seasonID}&competitionUID=${args.competitionUID}`
  )
  return response.data.data;
}
