import { RouteLocationRaw, Router } from "vue-router"

import { System, type Redirection } from "src/store/System";

/**
 * Route to the login modal with a pre-populated email addr,
 * optionally configuring the "on success nav to <some-route>" behavior.
 * If no redirect value is provided then the default is used, via the login modal's own logic,
 * which will probably the dashboard.
 */
export async function routeToLoginForEmail(
  router: Router,
  email: string,
  redirectOnLogin?: Redirection,
) : Promise<void> {
  if (redirectOnLogin) {
    System.setRedirectOnLogin(redirectOnLogin);
  }

  await router.push({name: "login", query: {email}});
}
