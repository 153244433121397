import { defineComponent, ref, watch } from "vue"

import { axiosInstance } from "src/boot/axios";
import { events } from "src/store/EventuallyPinia"

import { ExpandedEvent } from "./R_EventRosterImpl.ilx"

import EventRosterImpl from "./R_EventRosterImpl.vue";
import { vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";

export default defineComponent({
  props: {
    eventID: vReqT<Guid>(),
  },
  setup(props) {
    const expandedEvent = ref<ExpandedEvent | null>(null);
    const includeCanceledSignups = ref(false)

    const rehydrateFromCurrentConfig = async () : Promise<void> => {
      expandedEvent.value = null;

      expandedEvent.value = (await events.get(axiosInstance, props.eventID, {
        includeCanceledSignups: includeCanceledSignups.value,
        expand: ["signups","rosterLayoutDetail"]
      })) as ExpandedEvent
    };

    watch(() => props.eventID, rehydrateFromCurrentConfig, {immediate: true});

    return () => {
      if (!expandedEvent.value) {
        return null;
      }
      else {
        return <EventRosterImpl
          expandedEvent={expandedEvent.value}
          includeCanceled={includeCanceledSignups.value}
          onUpdate:includeCanceled={v => {
            includeCanceledSignups.value = v;
            rehydrateFromCurrentConfig()
          }}
        ></EventRosterImpl>
      }
    }
  },
})
