import { exhaustiveCaseGuard } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import * as iltypes from "src/interfaces/InleagueApiV1"
import { UserData } from "src/interfaces/Store/user";
import authService from "src/helpers/authService";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RouteDetail>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

export const RouteName = {
  main: "program-registrations-blocked-manager",
} as const;

type RouteName = typeof RouteName;

export type RouteDetail = R_Main

export interface R_Main {
  name: RouteName["main"],
  query?: {
    /**
     * optional; if provided, focus on a single registration rather than some user selected (comp,season,division)
     */
    registrationID?: iltypes.Guid
  }
}

export function routeDetailToRoutePath(detail: RouteDetail) : RouteLocationRaw {
  switch(detail.name) {
    case RouteName.main:
      return {
        name: detail.name,
        query: detail.query ?? undefined
      };
    default:
      exhaustiveCaseGuard(detail.name);
  }
}

/**
 * this serves to guard access to this route.
 * This is an AUTHZ check that should be kept in sync with how the backend determines if a user
 * has access to see some or all of the relevant blocked payments. It should be the case, that if
 * this is out of sync, we can access this route, but we won't be able to receive any displayable data,
 * due to equivalent backend AUTHZ checks.
 */
export function hasSomeRelevantAccessPermission(userData: UserData, allOfTheseCompetitions: "any" | iltypes.Guid[]) : boolean {
  if (authService(userData.roles, "registrar")) {
    return true;
  }
  else {
    if (allOfTheseCompetitions === "any") {
      return userData.competitionsMemento.some(cm => cm.isPaymentAdmin);
    }
    else {
      return userData.competitionsMemento.every(cm => {
        return cm.isPaymentAdmin && allOfTheseCompetitions.find(competitionUID => cm.competitionUID === competitionUID);
      });
    }
  }
}
