interface Clearable {
  clear: () => void
}

const registry = new Set<Clearable>();

export function register(clearable: Clearable) : void {
  registry.add(clearable);
}

export function unregister(clearable: Clearable) : void {
  registry.delete(clearable);
}

/**
 * note that until we drop vuex there is the expectation that this runs inside a vuex action in response to
 * the vuex logout action.
 */
export function runAll() : void {
  registry.forEach(clearable => clearable.clear());
}
