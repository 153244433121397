<template lang="pug">
FormKit(type='form', :actions='false', v-model='volunteerDetails')
  .shadow.border-b.border-gray-200.mb-4(class='sm:rounded-lg')
    table.min-w-full
      tbody.bg-white
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Name:
          td.px-6.py-4.text-sm.text-gray-500
            | {{ volunteerDetails.firstName }} {{ volunteerDetails.middleName }} {{ volunteerDetails.lastName }}
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Email:
          td.px-6.py-4.text-sm.text-gray-500
            | {{ volunteerDetails.email }}
        tr.border-b-2.border-gray-200(v-if='volunteerDetails.dob')
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | DOB:
          td.px-6.py-4.text-sm.text-gray-500
            | {{ volunteerDetails.dob ? dayJSDate(volunteerDetails.dob): 'N/A'}}
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Address:
          td.px-6.py-4.text-sm.text-gray-500
            | {{ volunteerDetails.street }} {{ volunteerDetails.street2 }}, {{ volunteerDetails.city }}, {{ volunteerDetails.state }} {{ volunteerDetails.zip }}
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Phone:
          td.px-6.py-4.text-sm.text-gray-500
            | {{ volunteerDetails.primaryPhone }}
.flex.flex-row.justify-between.px-8
  FormKit(
    type='button',
    label='Edit Profile',
    @click='goToVolunteerEditor',
    :classes='{ input: { redBackground: true } }'
  )
  FormKit(type='button', @click='$emit("confirmed")', label='Confirm Details')
</template>

<script lang="ts">
import { VolunteerDetails } from 'src/interfaces/volunteer'
import { dayJSDate } from 'src/helpers/formatDate'
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    volunteerDetails: {
      required: true,
      type: Object as PropType<VolunteerDetails>,
    },
  },
  inheritAttrs: false,
  emits: ['confirmed', 'user-contact-verification-impl-goto-user-editor'],
  setup(props, context) {
    const goToVolunteerEditor = () => {
      // not yet implemented
      // should probably emit "next-step" event or something, caller does routing
      context.emit("user-contact-verification-impl-goto-user-editor")
    }

    return {
      goToVolunteerEditor,
      dayJSDate,
    }
  },
})
</script>
