import { FormKit } from "@formkit/vue"
import { DirtyPristinePrimitive, CheckedOmit, parseFloatOrFail, vReqT } from "src/helpers/utils";
import { CompetitionRegistration, CompetitionSeason, Division, Floatlike, Guid, Registration, WaitlistIsFree_t } from "src/interfaces/InleagueApiV1";
import { ExtractPropTypes, computed, defineComponent } from "vue"
import ContentChunk from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { Client } from "src/store/Client";

const propsDef = {
  seasonName: vReqT<string>(),
  registration: vReqT<Registration>(),
  /**
   * `compReg.fee` isn't safe to use, we might be an "invoice template" based compreg
   */
  competitionRegistration: vReqT<CheckedOmit<CompetitionRegistration, "fee">>(),
  feeUiString: vReqT<string>(),
  competitionSeason: vReqT<CompetitionSeason>(),
  division: vReqT<Division>(),
  donation: vReqT<null | DirtyPristinePrimitive<Floatlike>>(),
  payWhatYouCan: vReqT<null | FeeOverride>(),
} as const;

export type ConfirmOneProps = ExtractPropTypes<typeof propsDef>

export const ConfirmOneRegistrationElement = defineComponent({
  props: propsDef,
  setup(props) {
    const waitlistRequiresConfirmation = computed(()=> {
      return Client.value.instanceConfig.confirmwaitlist
    })

    const isWaitlisted = computed(() => props.competitionRegistration.waitlist)

    const maybeWaitlistedTentativePaymentMessage = computed(() => {
      if (isWaitlisted.value && props.competitionSeason.waitListIsFree === WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT) {
        return "collected upon removal of waitlist"
      }
      else {
        return "";
      }
    })

    const transType = computed(() => props.competitionRegistration.transtype.trim())

    return () => {
      return (
        <div class="-my-2 mt-6 mb-2 sm:-mx-6 lg:-mx-8" data-test={`ConfirmOneRegistrationElement/${props.competitionRegistration.competitionUID}`}>
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full">
                <thead class="bg-gray-50">
                  <tr class="border-b-2 border-gray-200">
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-sm"
                      scope="col">Item</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                      Amount</th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr class="border-b-2 border-gray-200">
                    <td class="px-6 py-4 text-sm font-medium text-gray-900 w-1/2">
                      Registration for {props.seasonName} for {props.registration.playerFirstName} {props.registration.playerLastName}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500">
                      <span>{props.feeUiString}</span>
                      {
                        transType.value
                          ? <span> ({transType.value})</span>
                          : null
                      }
                      {
                        maybeWaitlistedTentativePaymentMessage.value
                          ? <div> ({maybeWaitlistedTentativePaymentMessage.value})</div>
                          : null
                      }
                    </td>
                  </tr>
                  <tr class="border-b-2 border-gray-200">
                    <td class="px-6 py-4 text-sm font-medium text-gray-900">Division: {props.division.displayName}</td>
                  </tr>
                  {
                    props.donation
                      ? (
                        <tr class="border-b-2 border-gray-200">
                          <td class="px-6 py-2 text-sm font-medium text-gray-900" style="--fk-margin-outer:none;" colspan="2">
                            <div class="flex flex-col gap-1 -mt-[4px]">
                              <ContentChunk id="1062"></ContentChunk>
                              <ContentChunk id="1063"></ContentChunk>
                              <div>Donation:</div>
                              <FormKit
                                type="number"
                                validation={[['number'], ['min', 0]]}
                                v-model={props.donation.dirty}
                                step="0.01"
                                min="0"
                                validationMessages={{ min: `Cannot be less than zero.`}}
                                data-test="donation"
                              />
                            </div>
                          </td>
                        </tr>
                      )
                      : null
                  }
                  {
                    props.payWhatYouCan
                      ? (
                        <tr>
                          <td class="px-6 py-2 text-sm font-medium text-gray-900" style="--fk-margin-outer:none;" colspan="2">
                            <div class="flex flex-col gap-1 -mt-[4px]">
                              <ContentChunk id="PayWhatYouCanText" interpolationArgs={props.payWhatYouCan.contentChunkArgs}></ContentChunk>
                              <div>Pay what you can:</div>
                              <FormKit
                                type="number"
                                v-model={props.payWhatYouCan.fee.dirty}
                                step="0.01"
                                validation={[['number'],['min',props.payWhatYouCan.floor]]}
                                validationMessages={{ min: `Must be at least $${props.payWhatYouCan.floor.toFixed(2)}` }}
                                data-test="payWhatYouCan"/>
                            </div>
                          </td>
                        </tr>
                      )
                      : null
                  }
                  {
                    isWaitlisted.value
                      ? (
                        <tr>
                          <td class="px-6 pb-4 text-sm font-medium text-gray-900" colspan="2">
                            <ContentChunk id="1622"></ContentChunk>
                          </td>
                        </tr>
                      )
                      : null
                  }
                  {
                    isWaitlisted.value && waitlistRequiresConfirmation.value
                      ? (
                        <tr>
                          <td class="px-6 pb-4 text-sm font-medium text-gray-900 mt-4" colspan="2">
                            <div class="flex items-center md:items-start">
                              {/*n.b. no v-model here, just relying on validation to prevent submission until checked*/}
                              <FormKit type="checkbox" name="checkbox" validation="accepted"/>
                              <ContentChunk class="ml-2" id="WaitlistRegConfirm"></ContentChunk>
                            </div>
                          </td>
                        </tr>
                      )
                      : null
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }
})

export interface FeeOverride {
  fee: DirtyPristinePrimitive<Floatlike>,
  floor: number,
  contentChunkArgs: {
    competitionSeasonUID: Guid
  }
}