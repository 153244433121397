import type { ExtractPropTypes, PropType } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard } from "src/helpers/utils"
import authService from "src/helpers/authService";
import { User } from "src/store/User";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RoutePropsVariant>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "DivisionEditor.main"
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | Main

export interface Main extends RoutePropsBase {
  routeName: typeof RouteNames.main,
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteNames.main:
      return {name: v.routeName}
    default: exhaustiveCaseGuard(v.routeName);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.main:
      return {
        detail: {
          routeName: name
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}

export function hasSomeRoutePermission() : boolean {
  return authService(User.value.roles, "webmaster", "inLeague");
}
