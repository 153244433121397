<template lang="pug">
t-dialog
  h1 Delete event {{ eventName }}?
  div.mt-4.flex.justify-center.gap-4
    //- need to override weird margins on bottom/right?
    t-btn.mr-0.mb-0(type="button" color="green" @click="confirmDeletion")
      div Yes
    t-btn.mr-0.mb-0(type="button" color="red" @click="doNotDelete")
      div No
</template>

<script lang="ts">
import { copyViaJsonRoundTrip } from 'src/helpers/utils'
import { defineComponent } from 'vue'
import { propsDef, emitsDef } from "./ConfirmEventDeletionModal.ilx"

export default defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    // copy it in; if parent wants to change our target, they need to remount
    const eventID = copyViaJsonRoundTrip(props.event.eventID);
    const eventName = copyViaJsonRoundTrip(props.event.eventName);

    return {
      eventID,
      eventName,
      confirmDeletion: () : void => void emit("confirmDeletion", eventID),
      doNotDelete: () : void => void emit("doNotDelete")
    }
  },
})
</script>
