import { boot } from 'quasar/wrappers'
import { plugin, defaultConfig, createInput } from '@formkit/vue'
import { phoneNumber } from 'src/helpers/validationHelpers'
import TVhtml from 'src/components/UserInterface/t-vhtml.vue'
import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  checkboxes,
  $if,
  $extend,
  $attrs,
} from '@formkit/inputs'

export const inleagueDefaultFormKitConfig = () => defaultConfig({
  rules: { phoneNumber },
  config: {
    color: '#3c8b55'
  },
  inputs: {
    tvhtml: createInput(TVhtml, {props: ['customHTML']}),
    toggle: toggle
  }
})

export default boot(({ app }) => {
  app.use(plugin, inleagueDefaultFormKitConfig());
})

/**
 * Input definition for a checkbox(ess).
 * @public
 */
const toggle: FormKitTypeDefinition = {
  schema: outer(
    $if(
      '$options == undefined',
      $attrs({ class: 'inline-flex relative items-center cursor-pointer' }, boxWrapper(
        prefix(), $extend(box(), { attrs: { type: 'checkbox', class: 'sr-only peer' } }),
        $attrs({
            class: `
              w-11 h-6 bg-gray-200 peer-focus:outline-0 peer-focus:ring-0
              peer-focus:ring-green-600 dark:peer-focus:0 rounded-full peer
              peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
              after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border
              after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-700
            `
        }, decorator()), suffix(),
        $if('$label', $attrs({ class: 'ml-3 text-sm font-medium text-gray-900 dark:text-gray-300' }, boxLabel('$label')))
      )),
      fieldset(
        legend('$label'),
        help('$help'),
        boxOptions(
          boxOption(
            $attrs({ class: 'inline-flex relative items-center cursor-pointer' }, boxWrapper(
              prefix(),
              $extend(box(), {
                bind: '$option.attrs',
                attrs: {
                  type: 'checkbox',
                  id: '$option.attrs.id',
                  value: '$option.value',
                  checked: '$fns.isChecked($option.value)',
                  class: 'sr-only peer'
                },
              }),
              $attrs({
                  class: `
                w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4
                peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700
                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600
              `
              }, decorator()),
              suffix()
            ),
            ),
            boxHelp('$option.help')
          )
        )
      )
    ),
    $if('$options.length === 0 && $help', help('$help')),
    messages(message('$message.value'))
  ),
  type: 'input',
  props: ['options', 'onValue', 'offValue'],
  features: [options, checkboxes],
}




