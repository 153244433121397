import { DeepReadonly, ref, Ref } from "vue";
import Axios, { type AxiosInstance } from "axios"

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as ilseason from "src/composables/InleagueApiV1.Seasons"
import { AxiosErrorWrapper } from "src/boot/axios";
import { parseIntOr, sortBy, sortByMany } from "src/helpers/utils";

import { isInleagueApiError2 } from "src/composables/InleagueApiV1";

import { tournamentTeamStore } from "./TournTeamStore";
import { tournTeamRegPageItemStore } from "./TournTeamRegPageItemStore";
import * as ClearOnLogout from "src/store/ClearOnLogout"

export const tournamentStore = (() => {
  // the refs stored here across the 2 differently-keyed maps must be the same; e.g both must point to the "same" tournament ref object
  let tournamentsByCompSeason : {[key: `${iltypes.CompetitionUID}/${iltypes.SeasonUID}`]: undefined | Ref<iltournament.TournamentForAdminPage>} = {}
  let tournamentsByTournamentID : {[key: iltypes.Integerlike]: undefined | Ref<iltournament.TournamentForAdminPage>} = {}

  let compSeasonDivListingByCompSeason : {[key: `${iltypes.CompetitionUID}/${iltypes.SeasonUID}`]: undefined | Ref<iltypes.CompetitionSeasonDivision[]>} = {}

  const compSeasonKey = (v: {competitionUID: iltypes.CompetitionUID, seasonUID: iltypes.SeasonUID}) => `${v.competitionUID}/${v.seasonUID}` as const

  const clear = () => {
    tournamentsByCompSeason = {}
    tournamentsByTournamentID = {}
    compSeasonDivListingByCompSeason = {}
  }

  /**
   * We handle a 404 here by assuming it means "no such tournament exists".
   * The caller probably wants to supply an axios instance that will not issue a UI toast when/if a 404 happens.
   * A tournament not existing is not a failure mode, it means "the user can create one".
   */
  async function maybeGetTournamentIfExists(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike} | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}) : Promise<Ref<DeepReadonly<iltournament.TournamentForAdminPage>> | null> {
    let maybeExists : Ref<iltournament.TournamentForAdminPage> | undefined = undefined;

    if ("tournamentID" in args) {
      maybeExists = tournamentsByTournamentID[args.tournamentID]
    }
    else {
      maybeExists = tournamentsByCompSeason[compSeasonKey(args)]
    }

    if (maybeExists) {
      return maybeExists;
    }

    try {
      const fresh = ref(await iltournament.getTournament(axios, args));
      tournamentsByCompSeason[compSeasonKey(fresh.value)] = fresh;
      tournamentsByTournamentID[fresh.value.tournamentID] = fresh;
      return fresh;
    }
    catch (err) {
      if (Axios.isAxiosError(err) && isInleagueApiError2(err) && err.response.status === 404) {
        return null;
      }
      else {
        throw err;
      }
    }
  }

  async function reloadTournament(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike} | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}) : Promise<void> {
    const {existingByCompSeason, existingByTournament} = getExistingOrFail();

    const fresh = await iltournament.getTournament(axios, args);

    existingByCompSeason.value = fresh;
    existingByTournament.value = fresh;

    function getExistingOrFail() {
      if ("tournamentID" in args) {
        const existingByTournament = tournamentsByTournamentID[args.tournamentID];
        if (!existingByTournament) {
          throw Error("reloading a tournament for which we don't already have a copy (a)");
        }
        const existingByCompSeason = tournamentsByCompSeason[compSeasonKey(existingByTournament.value)];
        if (!existingByCompSeason) {
          throw Error("reloading a tournament for which we don't already have a copy (b)");
        }

        return {existingByCompSeason, existingByTournament}
      }
      else {
        const existingByCompSeason = tournamentsByCompSeason[compSeasonKey(args)]
        if (!existingByCompSeason) {
          throw Error("reloading a tournament for which we don't already have a copy (c)");
        }
        const existingByTournament = tournamentsByTournamentID[existingByCompSeason.value.tournamentID];
        if (!existingByTournament) {
          throw Error("reloading a tournament for which we don't already have a copy (d)");
        }

        return {existingByCompSeason, existingByTournament}
      }
    }
  }

  // any other places in the app that invalidate this?
  async function getCompSeasonDivListing(axios: AxiosInstance, args: {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}) : Promise<Ref<DeepReadonly<iltypes.CompetitionSeasonDivision[]>>> {
    const key = compSeasonKey(args)
    const maybeExists = compSeasonDivListingByCompSeason[key]

    if (maybeExists) {
      return maybeExists;
    }

    const listing = await ilseason
      .listCompetitionSeasonDivisions(axios, args)
      .then(listing => {
        return listing
          .filter(v => !!v.isActive)
          .sort(sortByMany(
            sortBy(_ => parseIntOr(_.divNum, -1), "asc"),
            sortBy(_ => _.gender, "asc")
          ));
      })
      .then(listing => ref(listing))

    compSeasonDivListingByCompSeason[key] = listing

    return listing;
  }

  /**
   * intended to merge the 3-state "success, or http error, or non-http error" cases
   */
  type MaybeOK<T> =
    // ok -- happy path
    | {ok: true, result: T}
    // http error case, associated axios handler semantics apply (axios response interceptors issue error toasts based on received error messages)
    | {ok: false, error: any}
    // 3rd case can't be modeled and represents an uncaught exception, which is entirely unexpected and __should__ crash

  async function createTournament(axios: AxiosInstance, args: iltournament.TournamentCrudArgs["create"]) : Promise<MaybeOK<Ref<DeepReadonly<iltournament.TournamentForAdminPage>>>> {
    try {
      const key = compSeasonKey(args)

      // n.b. all errors are fatal here
      const fresh = ref(await iltournament.createTournament(axios, args))

      invalidateOtherTournamentTeamStoreCaches();

      tournamentsByCompSeason[key] = fresh;
      tournamentsByTournamentID[fresh.value.tournamentID] = fresh;

      return {ok: true, result: fresh}
    }
    catch (error) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      return {ok: false, error}
    }
  }

  async function createOrUpdateTournamentDivisions(axios: AxiosInstance, args: iltournament.CreateOrUpdateTournamentDivisionsArgs) : Promise<MaybeOK<iltournament.TournamentDivision[]>> {
    try {
      const result = await iltournament.createOrUpdateTournamentDivisions(axios, args)
      invalidateOtherTournamentTeamStoreCaches();
      await reloadTournament(axios, args);
      return {ok: true, result};
    }
    catch (error) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      return {ok: false, error}
    }
  }

  async function deleteTournamentDivisions(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike, divIDs: iltypes.Guid[]}) : Promise<MaybeOK<void>> {
    try {
      await iltournament.deleteTournamentDivisions(axios, args)
      invalidateOtherTournamentTeamStoreCaches();
      await reloadTournament(axios, args);
      return {ok: true, result: void 0}
    }
    catch (error) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      return {ok: false, error}
    }
  }

  async function updateTournament(axios: AxiosInstance, args: iltournament.TournamentCrudArgs["update"]) : Promise<MaybeOK<void>> {
    try {
      await iltournament.updateTournament(axios, args);
      invalidateOtherTournamentTeamStoreCaches();
      await reloadTournament(axios, args)
      return {ok: true, result: void 0}
    }
    catch (error) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      return {ok: false, error}
    }
  }

  // it would be better to participate directly in the other stores, but for now it's easiest to just invalidate
  // all other tournament team store cache stuff when we do something that alters the "tournament team universe"
  /**
   * @noexport
   */
  function invalidateOtherTournamentTeamStoreCaches() {
    tournamentTeamStore.clear();
    tournTeamRegPageItemStore.clear();
  }

  return {
    clear,
    maybeGetTournamentIfExists,
    getCompSeasonDivListing,
    createTournament,
    updateTournament,
    createOrUpdateTournamentDivisions,
    deleteTournamentDivisions,
  }
})();

ClearOnLogout.register(tournamentStore)
