import { defineComponent, onMounted, Ref, ref, watch } from "vue";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { AxiosErrorWrapper, axiosInstance, freshAxiosInstance } from "src/boot/axios";

import { CouponManagerImpl, ExpandedCoupon, V_CouponSearcher } from "./CouponManagerImpl";
import { propsDef } from "./R_CouponManager.route"
import { datePickerFormat } from "src/helpers/formatDate";
import dayjs from "dayjs";
import { exhaustiveCaseGuard, VueNeverUnwrappable } from "src/helpers/utils";

import * as AutoSearch2 from "src/components/UserInterface/AutoSearch2";

type State =
  | {ready: false}
  | {
    ready: true,
    data: ExpandedCoupon[],
    couponSearcher: V_CouponSearcher
  }

export default defineComponent({
  props: propsDef,
  setup(props) {
    const state = ref<State>({ready: false})

    onMounted(async () => {
      // We abuse linkage candidates to populate the "try to find by event or competition season" search boxes
      // this will probably need to be improved (how far back does it look, etc.)
      // n.b. It is possible that the current url query params represent entities that we don't get from this call (e.g. an old event or something)
      // which might be ugly.
      const linkageCandidates = await ilapi.coupon.getCouponEntityLinkageCandidates(axiosInstance);

      const couponSearcher = CouponSearcher(
        props.detail.eventID,
        linkageCandidates.events,
        linkageCandidates.competitionSeasons
      );

      const data = await getFromSearcher(couponSearcher);

      state.value = {
        ready: true,
        data,
        couponSearcher,
      }
    })

    return () => {
      if (!state.value.ready) {
        return <div></div>
      }
      return (
        <div>
          <CouponManagerImpl
            coupons={state.value.data}
            searcher={state.value.couponSearcher}
            onDoSearch={async () => {
              if (!state.value.ready) {
                // shouldn't ever happen
                return;
              }
              state.value.data = await getFromSearcher(state.value.couponSearcher);
            }}
          />
        </div>
      )
    }
  }
})

function CouponSearcher(
  query_initialEventID: iltypes.Guid | undefined,
  narrowToEventsOptionsSource: ilapi.coupon.CouponEntityLinkageCandidate_Event[],
  narrowToCompSeasonsOptionsSource: ilapi.coupon.CouponEntityLinkageCandidate_CompetitionSeason[],
) : V_CouponSearcher {

  const couponCode = ref("");

  const events = {
    options: [{label: "", value: ""}, ...collectEvents(narrowToEventsOptionsSource).map(v => ({label: v.eventName, value: v.eventID}))],
    selectedEventID: ref(query_initialEventID ?? ""),
  }

  const compSeasons = (() => {
    const {uniqueSeasons, uniqueCompetitions} = collectCompSeasons(narrowToCompSeasonsOptionsSource);
    return {
      seasonOptions: [{label: "", value: ""}, ...uniqueSeasons.map(v => ({label: v.seasonName, value: v.seasonUID}))],
      selectedSeasonUID: ref(""),
      competitionOptions: [{label: "", value: ""}, ...uniqueCompetitions.map(v => ({label: v.competitionName, value: v.competitionUID}))],
      selectedCompetitionUID: ref(""),
    }
  })()

  const expiresOnOrAfter = ref(datePickerFormat(dayjs()))

  const includeNeverExpires = ref(true);

  const searcher = VueNeverUnwrappable({
    couponCode,
    events,
    compSeasons,
    expiresOnOrAfter,
    includeNeverExpires,
    userLookup: UserOrChildLookupAutoCompleteProps("user"),
    childLookup: UserOrChildLookupAutoCompleteProps("child"),
    eventLookup: EventsLookupProps(),
    reset: () : void => {
      searcher.couponCode.value = "";

      searcher.compSeasons.selectedCompetitionUID.value = "";
      searcher.compSeasons.selectedSeasonUID.value = "";

      searcher.expiresOnOrAfter.value = datePickerFormat(dayjs())
      searcher.includeNeverExpires.value = true;

      resetAutoSearch2(searcher.userLookup);
      resetAutoSearch2(searcher.childLookup);
      resetAutoSearch2(searcher.eventLookup);

      function resetAutoSearch2(v: Ref<AutoSearch2.Props<iltypes.Guid>>) {
        v.value.formData.input = "";
        v.value.formData.selected = null;
      }
    }
  })

  return searcher;

  function collectEvents(vs: ilapi.coupon.CouponEntityLinkageCandidate_Event[]) {
    const result : {eventID: iltypes.Guid, eventName: string}[] = [...vs];
    return result.sort((l,r) => l.eventName.toLowerCase() < r.eventName.toLowerCase() ? -1 : 1)
  }

  function collectCompSeasons(vs: ilapi.coupon.CouponEntityLinkageCandidate_CompetitionSeason[]) {
    const uniqueSeasons = [
      ...new Map(vs.map(v => [v.seasonUID, {seasonUID: v.seasonUID, seasonName: v.seasonName, seasonID: v.seasonID}])).values()
    ];
    const uniqueCompetitions = [
      ...new Map(vs.map(v => [v.competitionUID, {competitionName: v.competition, ...v}])).values()
    ];

    // most recent first
    uniqueSeasons.sort((l,r) => l.seasonID > r.seasonID ? -1 : 1);
    // ascending by compID
    uniqueCompetitions.sort((l,r) => l.competitionID < r.competitionID ? -1 : 1);

    return {
      uniqueSeasons,
      uniqueCompetitions,
    }
  }
}

async function getFromSearcher(searcher: V_CouponSearcher) {
  //
  // If there's a coupon code, we currently search for it as an exact string match for coupon code.
  // Probably it will be more comfortable if we don't consider any other constraints like linked users or children or etc.,
  // so here we only consider coupon code.
  //
  // If coupon code were a fuzzy match, we would maybe run it with the rest of the constraints.
  //
  // It might be more correct to have the form deal with and represent this, and make it clear to the user what is happening.
  //
  if (searcher.couponCode.value.trim()) {
    return await doit({couponCode: searcher.couponCode.value.trim()});
  }

  const competitionSeason = (() => {
    const hasBoth = searcher.compSeasons.selectedCompetitionUID.value && searcher.compSeasons.selectedSeasonUID.value;
    if (hasBoth) {
      return {
        type: "competitionUID+seasonUID" as const,
        competitionUID: searcher.compSeasons.selectedCompetitionUID.value,
        seasonUID: searcher.compSeasons.selectedSeasonUID.value,
      }
    }
    else {
      return undefined;
    }
  })();

  return await doit({
    eventID: searcher.eventLookup.value.formData.selected || undefined,
    competitionSeason: competitionSeason,
    userID: searcher.userLookup.value.formData.selected || undefined,
    childID: searcher.userLookup.value.formData.selected || undefined,
    expiresOnOrAfter: searcher.expiresOnOrAfter.value,
    includeNeverExpires: searcher.includeNeverExpires.value
  });

  async function doit(args: Parameters<typeof ilapi.coupon.listCoupons>[1]) {
    return await ilapi.coupon.listCoupons(
      axiosInstance, {
        ...args,
        expand: [
          "couponEventLinks",
          "couponCompetitionSeasonLinks",
          "shallowInvoiceDetail",
          "definiteRedemptionCount",
          "pendingRedemptionCount",
        ]
      }
    ) as ExpandedCoupon[]

  }
}

function EventsLookupProps() : Ref<AutoSearch2.Props<iltypes.Guid>> {
  const formData = ref<AutoSearch2.Props<iltypes.Guid>["formData"]>({
    input: "",
    selected: null,
    options: []
  })
  const noOptionsElement = ref<AutoSearch2.Props<iltypes.Guid>["noOptionsElement"]>(<div>No options</div>);

  // we need an instance with auth but that has no "auto toast on failure" behavior
  const axiosAuthNoToast = freshAxiosInstance({
    useCurrentBearerToken: true,
    responseInterceptors: [{error: v => {throw new AxiosErrorWrapper(v as any);}}]
  })

  const mostRecentRequest = UseMostRecentRequest(async () => {
    try {
      return await ilapi.coupon.findLinkedEvents(axiosAuthNoToast, {search: formData.value.input});
    }
    catch (e) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(e);
    }
    return [];
  });

  const debouncedRunner = DebouncedRunner(async () => {
    noOptionsElement.value = <div>...searching...</div>
    formData.value.options = [];

    const result = await mostRecentRequest.run();

    if (result.wasFreshest) {
      noOptionsElement.value = <div>No options</div>
      formData.value.options = result.value.map(v => ({label: v.eventName, value: v.eventID, key: v.eventID}))
    }
    else {
      // Some other request got pushed more recently;
      // that async frame is the one which will do the work
    }
  }, 250);

  watch(() => formData.value.input, (old,new_) => {
    if (old === new_) {
      return;
    }
    debouncedRunner.run();
  })

  return ref({
    formData,
    noOptionsElement,
  })
}

function UserOrChildLookupAutoCompleteProps(which: "user" | "child") : Ref<AutoSearch2.Props<iltypes.Guid>> {
  const formData = ref<AutoSearch2.Props<iltypes.Guid>["formData"]>({
    input: "",
    selected: null,
    options: []
  })
  const noOptionsElement = ref<AutoSearch2.Props<iltypes.Guid>["noOptionsElement"]>(<div>No options</div>);

  // we need an instance with auth but that has no "auto toast on failure" behavior
  const axiosAuthNoToast = freshAxiosInstance({
    useCurrentBearerToken: true,
    responseInterceptors: [{error: v => {throw new AxiosErrorWrapper(v as any);}}]
  })

  const mostRecentRequest = UseMostRecentRequest(async () => {
    try {
      if (which === "user") {
        return (await ilapi.findVolunteers(axiosAuthNoToast, {search: formData.value.input}))
          .map(v => ({type: "user", firstName: v.firstName, lastName: v.lastName, ID: v.ID}))
      }
      else if (which === "child") {
        return (await ilapi.findPlayers(axiosAuthNoToast, {search: formData.value.input}))
          .map(v => ({type: "user", firstName: v.playerFirstName, lastName: v.playerLastName, ID: v.childID}))
      }
      else {
        exhaustiveCaseGuard(which);
      }
    }
    catch (e) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(e);
    }
    return [];
  });

  const debouncedRunner = DebouncedRunner(async () => {
    noOptionsElement.value = <div>...searching...</div>
    formData.value.options = [];

    const result = await mostRecentRequest.run();

    if (result.wasFreshest) {
      noOptionsElement.value = <div>No options</div>
      formData.value.options = result.value.map(v => ({label: `${v.firstName} ${v.lastName}`, value: v.ID, key: v.ID}))
    }
    else {
      // Some other request got pushed more recently;
      // that async frame is the one that will do the work
    }
  }, 250);

  watch(() => formData.value.input, (old,new_) => {
    if (old === new_) {
      return;
    }
    debouncedRunner.run();
  })

  return ref({
    formData,
    noOptionsElement,
  })
}

function UseMostRecentRequest<T>(f: () => Promise<T>) {
  type R = {wasFreshest: true, value: T} | {wasFreshest: false}
  let current_pState : 0 | {p: Promise<R>} = 0;

  return {
    run: () => {
      const thisRun = current_pState = {p: null as any}
      return current_pState.p = new Promise<R>(async (resolve, _reject) => {
        const fresh = await f()
        if (thisRun === current_pState) {
          // success case, no change to state while we waited
          current_pState = 0
          resolve({wasFreshest: true, value: fresh});
        }
        else {
          // someone else made a request after this was resolving but before we were resolved.
          return resolve({wasFreshest: false})
        }
      });
    }
  }
}

function DebouncedRunner(f: () => void, timeout_ms: number) {
  let timeoutID : number | NodeJS.Timeout | null = null;
  return {
    run: () => {
      if (timeoutID) {
        clearTimeout(timeoutID)
      }
      timeoutID = setTimeout(f, timeout_ms);
    }
  }
}
