import { defineComponent, ref } from "vue"
import dayjs from "dayjs"
import { EventSigninSheetViewData, EventSigninSheetViewData_ChildSignup, EventSigninSheetViewData_UserSignup } from "src/composables/InleagueApiV1.Event"
import { dayjsFormatOr } from "src/helpers/formatDate"
import { exhaustiveCaseGuard, maybePrettifyUSPhoneNumber, useSimplePrintIsolator, vReqT } from "src/helpers/utils"

const style_td = {
  "text-align": `left`,
  "vertical-align": `top`,
  "border": `1px solid black`,
  "border-collapse": `collapse`,
  "padding": `4px`,
  "font-size": `.875rem`,
} as const

const style_th = style_td

export const EventSigninSheet = defineComponent({
  props: {
    data: vReqT<EventSigninSheetViewData>(),
  },
  setup(props) {
    /**
     * all HTML inside the printRoot will get pushed into the print output
     */
    const printRootRef = ref<HTMLElement | null>(null)

    useSimplePrintIsolator(printRootRef, "landscape", {useShadowRoot: true});

    return () => {
      return (
        <div>
          <div>
            {/*n.b. using classes to do styling here won't show up in the print output (the iframe or shadowRoot we use to do the printing won't have the stylesheets)*/}
            <div ref={printRootRef}>
              <div style="margin: auto; text-align: center;">
                <h1 style="display:flex; flex-direction: column; justify-content: center; align-items: center;">
                  <span>{props.data.eventName}</span>
                  <span>Sign-in Sheet</span>
                </h1>
                <h4>
                  <EventDateTime/>
                  <div>
                    at
                    <EventLocation/>
                  </div>
                </h4>
                <h4 style="text-decoration: underline; margin-bottom:.35em;">{props.data.signups.length > 0 ? `${props.data.signups.length} Signed Up` : ""}</h4>
              </div>

              <div style="display:flex; width:100%; justify-content:center;">
                <table style="table-layout:fixed; max-width:2048px; width: 100%; border: 1px solid black; border-collapse: collapse;">
                  <tr>
                    <th style={{...style_th, width: "10%"}}>AYSO ID</th>
                    <th style={{...style_th, width: "15%"}}>Name</th>
                    <th style={{...style_th, width: "5%"}}>Region</th>
                    <th style={{...style_th, width: "10%"}}>Date Registered</th>
                    <th style={{...style_th, width: "10%"}}>Phone</th>
                    <th style={{...style_th, width: "20%"}}>Email</th>
                    <th style={{...style_th, width: "30%"}}>Signature</th>
                  </tr>
                  {
                    props.data.signups.map((signup,i) => {
                      switch (signup.type) {
                        case "user": return <UserSignupRow signup={signup}/>
                        case "child": return <ChildSignupRow signup={signup}/>
                        default: exhaustiveCaseGuard(signup)
                      }
                    })
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      )
    }

    function EventDateTime() {
      const start = dayjs(props.data.eventStart)
      const end = dayjs(props.data.eventEnd)

      const justTime = "h:mm a"
      const fullDateTime = "dddd, MMMM D, YYYY h:mm a"

      if (props.data.allDay) {
        return <div>{start.format(fullDateTime)} (all day)</div>
      }

      if (start.isSame(end, "day")) {
        return <div>{start.format(fullDateTime)} to {end.format(justTime)}</div>
      }

      return (
        <div>
          <div>{start.format(fullDateTime)}</div>
          <div>to</div>
          <div>{end.format(fullDateTime)}</div>
        </div>
      )
    }

    function EventLocation() {
      const venue = props.data.venue
      if (venue) {
        return (
          <div>
            <div>{venue.name}</div>
            <div>{venue.street}</div>
            <div>{venue.city} {venue.state}, {venue.zip}</div>
          </div>
        )
      }
      else {
        return <div>{props.data.eventLoc}</div>
      }
    }

    function UserSignupRow({signup}: {signup: EventSigninSheetViewData_UserSignup}) {
      const user = signup.user;
      return (
        <tr>
          <td style={style_td}>{user.stackSID}</td>
          <td style={{...style_td, "white-space": "break-spaces"}}>{user.firstName} {user.lastName}</td>
          <td style={style_td}>{user.region}</td>
          <td style={style_td}>{dayjsFormatOr(signup.signupDate, "MM/DD/YYYY")}</td>
          <td style={style_td}>{maybePrettifyUSPhoneNumber(user.primaryPhone)}</td>
          <td style={{...style_td, "word-break": "break-word"}}>{user.email}</td>
          <td style={style_td}>&nbsp;</td>
        </tr>
      )
    }

    function ChildSignupRow({signup}: {signup: EventSigninSheetViewData_ChildSignup}) {
      const child = signup.child;
      return (
        <tr>
          <td style={style_td}>{child.stackSID}</td>
          <td style={{...style_td, "white-space": `break-spaces`}}>{child.playerFirstName} {child.playerLastName}</td>
          <td style={style_td}>{child.instanceConfig.region}</td>
          <td style={style_td}>{dayjsFormatOr(signup.signupDate, "MM/DD/YYYY")}</td>
          <td style={style_td}>{maybePrettifyUSPhoneNumber(child.parent1.primaryPhone)}</td>
          <td style={{...style_td, "word-break": "break-word"}}>{child.parent1.email}</td>
          <td style={style_td}>&nbsp;</td>
        </tr>
      )
    }
  }
})
