import { ref } from "vue"
import type { AxiosInstance } from "axios"
import * as ClearOnLogout from "src/store/ClearOnLogout"

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { parseIntOr, sortBy } from "src/helpers/utils"

type MenuSeason = iltournament.TournTeamRegPageItemMenuSeason;
type MenuCompetition = iltournament.TournTeamRegPageItemMenuCompetition;

export const tournTeamRegPageItemStore = (() => {
  const menuSeasons = ref<null | Promise<MenuSeason[]>>(null)
  const menuCompetitionsBySeason = ref<{[seasonUID: iltypes.Guid]: undefined | Promise<MenuCompetition[]>}>({})

  const pageItemsByTournamentID = ref<{[tournamentID: iltypes.Integerlike]: undefined | iltournament.TournTeamRegPageItem[]}>({});

  const clear = () : void => {
    menuSeasons.value = null;
    menuCompetitionsBySeason.value = {}
    pageItemsByTournamentID.value = {}
  }

  const getMenuSeasons = (axios: AxiosInstance) : Promise<MenuSeason[]> => {
    const maybeExists = menuSeasons.value;
    if (maybeExists) {
      return maybeExists;
    }
    else {
      return menuSeasons.value = iltournament
        .getTournamentTeamRegistrationPageItemMenuSeasons(axios)
        .then(seasons => seasons.sort(sortBy(_ => parseIntOr(_.seasonID, -1), "desc")))
    }
  }

  const getMenuCompetitions = (axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<MenuCompetition[]> => {
    const maybeExists = menuCompetitionsBySeason.value[args.seasonUID]
    if (maybeExists) {
      return maybeExists;
    }
    else {
      return menuCompetitionsBySeason.value[args.seasonUID] = iltournament
        .getTournamentTeamRegistrationPageItemMenuCompetitions(axios, args)
        .then(v => v.sort(sortBy(_ => parseIntOr(_.competitionID, -1), "asc")))
    }
  }

  // can throw on lookup failure or etc ... we should handle that ...
  const getByTournamentID = async (axios: AxiosInstance, tournamentID: iltypes.Integerlike) : Promise<iltournament.TournTeamRegPageItem[]> => {
    const maybeExists = pageItemsByTournamentID.value[tournamentID];
    if (maybeExists) {
      return maybeExists;
    }
    else {
      const fresh = await iltournament.getTournamentRegistrationPageItems(axios, {tournamentID, includeDisabled: true})
      return pageItemsByTournamentID.value[tournamentID] = fresh;
    }
  }

  /**
   * Can't find it or some error occurs while finding it --> return null
   */
  const getByTournamentPageItemIdOrNull = async (
    axios: AxiosInstance,
    args: {tournamentID: iltypes.Integerlike, pageItemID: iltypes.Integerlike}
  ) : Promise<iltournament.TournTeamRegPageItem | null> => {
    try {
      const cachedListForTournament = pageItemsByTournamentID.value[args.tournamentID]
      if (cachedListForTournament) {
        return cachedListForTournament.find(pageItem => pageItem.id /*not strict*/ == args.pageItemID) ?? null;
      }
      else {
        const fresh = await getByTournamentID(axios, args.tournamentID);
        return fresh.find(pageItem => pageItem.id /*not strict*/ == args.pageItemID) ?? null;
      }
    }
    catch {
      // maybe log it ... ?
      return null;
    }
  }

  const delete_ = async (
    axios: AxiosInstance,
    args: {tournamentID: iltypes.Integerlike, pageItemID: iltypes.Integerlike}
  ) : Promise<void> => {
    await iltournament.deleteTournTeamRegQuestionPageItem(axios, args.pageItemID);
    const cachedListForTournament = pageItemsByTournamentID.value[args.tournamentID]
    if (!cachedListForTournament) {
      return;
    }
    const targetIdx = cachedListForTournament.findIndex(pageItem => pageItem.id === args.pageItemID)
    if (targetIdx === -1) {
      return;
    }
    cachedListForTournament.splice(targetIdx, 1);
  }

  const createQuestionPageItem = async (
    axios: AxiosInstance,
    args: iltournament.CreateTournTeamRegQuestionPageItemArgs
  ) : Promise<void> => {
    const fresh = await iltournament.createTournTeamRegQuestionPageItem(axios, args);
    const cachedListForTournament = pageItemsByTournamentID.value[fresh.tournamentID]
    if (!cachedListForTournament) {
      return;
    }
    cachedListForTournament.push(fresh);
  }

  const updateQuestionPageItem = async (
    axios: AxiosInstance,
    args: iltournament.UpdateTournTeamRegQuestionPageItemArgs
  ) : Promise<void> => {
    const fresh = await iltournament.updateTournTeamRegQuestionPageItem(axios, args);
    const cachedListForTournament = pageItemsByTournamentID.value[fresh.tournamentID]
    if (!cachedListForTournament) {
      return;
    }
    const targetIdx = cachedListForTournament.findIndex(v => v.id /*not strict*/ == fresh.id)
    if (targetIdx === -1) {
      return;
    }
    else {
      cachedListForTournament[targetIdx] = fresh;
    }
  }

  const createContentPageItem = async (
    axios: AxiosInstance,
    args: iltournament.CreateTournTeamRegContentPageItemArgs
  ) : Promise<void> => {
    const fresh = await iltournament.createTournTeamRegContentChunkPageItem(axios, args);
    const cachedListForTournament = pageItemsByTournamentID.value[fresh.tournamentID]
    if (!cachedListForTournament) {
      return;
    }
    cachedListForTournament.push(fresh);
  }

  const updateContentPageItem = async (
    axios: AxiosInstance,
    args: iltournament.UpdateTournTeamRegContentPageItemArgs
  ) : Promise<void> => {
    const fresh = await iltournament.updateTournTeamRegContentChunkPageItem(axios, args);
    const cachedListForTournament = pageItemsByTournamentID.value[fresh.tournamentID]
    if (!cachedListForTournament) {
      return;
    }
    const targetIdx = cachedListForTournament.findIndex(v => v.id /*not strict*/ == fresh.id)
    if (targetIdx === -1) {
      return;
    }
    else {
      cachedListForTournament[targetIdx] = fresh;
    }
  }

  const movePageItem = async (
    axios: AxiosInstance,
    tournamentID: iltypes.Integerlike,
    args: {moveThisIndex: number, priorToThisIndex: number}
  ) : Promise<void> => {
    const fresh = await iltournament.reorderTournTeamRegPageItems(axios, {tournamentID: tournamentID, moveThisIndex: args.moveThisIndex, priorToThisIndex: args.priorToThisIndex});
    const cachedListForTournament = pageItemsByTournamentID.value[tournamentID]
    if (!cachedListForTournament) {
      pageItemsByTournamentID.value[tournamentID] = fresh;
    }
    else {
      // in-place "assignment" so sites with a live-ref see the update
      cachedListForTournament.splice(0, cachedListForTournament.length, ...fresh)
    }
  }

  return {
    getMenuSeasons,
    getMenuCompetitions,
    getByTournamentID,
    getByTournamentPageItemIdOrNull,
    delete: delete_,
    createQuestionPageItem,
    updateQuestionPageItem,
    createContentPageItem,
    updateContentPageItem,
    movePageItem,
    clear
  }
})()

ClearOnLogout.register(tournTeamRegPageItemStore);
