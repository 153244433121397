import { Guid } from "src/interfaces/InleagueApiV1";
import { TabID_t } from "./AdminPage.ilx";
import {RouteLocationRaw } from "vue-router";
import authService from "src/helpers/authService";
import { User } from "src/store/User";

export const RouteNames = {
  /**
   * "main" is truly the "main" entry for this set of routes, but it is also
   * the correct catch-all for when we are focused on a tab which does not yet support
   * route-based navigation.
   */
  main: "season-competition-admin.main",
  tournaments: "season-competition-admin.tournaments",
  seasonalSettings: "season-competition-admin.seasonal",
  playerEligibility: "season-competition-admin.playerEligibility",
  invitationStatus: "season-competition-admin.invitationStatus"
} as const

export enum RouterQueryParams {
  competitionUID = "cuid",
  seasonUID = "suid"
}

export interface TabMapping {
  tab: TabID_t
  routeName: (typeof RouteNames)[keyof typeof RouteNames]
}

const mappings : Partial<Record<TabID_t, TabMapping>> = {
  [TabID_t.seasonalSettings]: {
    tab: TabID_t.seasonalSettings,
    routeName: RouteNames.seasonalSettings
  },
  [TabID_t.tournaments]: {
    tab: TabID_t.tournaments,
    routeName: RouteNames.tournaments
  },
  [TabID_t.playerEligibility]: {
    tab: TabID_t.playerEligibility,
    routeName: RouteNames.playerEligibility,
  },
  [TabID_t.invitationStatus]: {
    tab: TabID_t.invitationStatus,
    routeName: RouteNames.invitationStatus,
  }
}

//
// We need to bimap from (tabID <-> routename)
//
export function getTabMappingByTabID(tabID: TabID_t) : TabMapping | undefined {
  return mappings[tabID];
}

export function getTabMappingByRouteName(routeName: string) : TabMapping | undefined {
  switch (routeName) {
    case RouteNames.tournaments: return mappings[TabID_t.tournaments];
    case RouteNames.seasonalSettings: return mappings[TabID_t.seasonalSettings];
    case RouteNames.playerEligibility: return mappings[TabID_t.playerEligibility];
    case RouteNames.invitationStatus: return mappings[TabID_t.invitationStatus];
    default: return undefined;
  }
}

export function routeDetailToRouteLocation(tab: typeof RouteNames["main"], args: {competitionUID?: Guid}) : RouteLocationRaw;
export function routeDetailToRouteLocation(tab: typeof RouteNames["seasonalSettings"], args: {competitionUID: Guid, seasonUID: Guid}) : RouteLocationRaw;
export function routeDetailToRouteLocation(
  tab: typeof RouteNames["seasonalSettings"]
    | typeof RouteNames["playerEligibility"]
    | typeof RouteNames["invitationStatus"]
    | typeof RouteNames["main"],
  args: {competitionUID?: Guid, seasonUID?: Guid}
) : RouteLocationRaw {
  return {name: tab, query: {
    [RouterQueryParams.competitionUID]: args.competitionUID,
    [RouterQueryParams.seasonUID]: args.seasonUID
  }}
}

export function hasSomeRoutePermission() : boolean {
  const hasSomeSuperUserRole = authService(User.value.roles, 'registrar', 'webmaster', 'inLeague');
  const hasSomeManagedComp = !!User.userData?.competitionsMemento.find(v => v.isSuperAdmin)
  return hasSomeSuperUserRole || hasSomeManagedComp;
}

export function hasSpecificRoutePermission(args: {competitionUID: Guid}) : boolean {
  const hasSomeSuperUserRole = authService(User.value.roles, 'registrar', 'webmaster', 'inLeague');
  const hasSomeManagedComp = !!User.userData?.competitionsMemento.find(v => v.competitionUID === args.competitionUID && v.isSuperAdmin)
  return hasSomeSuperUserRole || hasSomeManagedComp;
}
