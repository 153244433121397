import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { vReqT } from "src/helpers/utils"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  registrationID: vReqT<Guid>(),
  seasonUID: vReqT<Guid>(),
  competitionUIDs: vReqT<Guid[]>(),
  playerID: vReqT<Guid>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "confirm-registration",
} as const;

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {name: RouteNames.main, params: v}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {
    ...route.params as any,
    competitionRegistrationID: parseInt(route.params.competitionRegistrationID as string)
  }
}
