import { AllScoresComponentState } from "src/components/Score/AllScores.ilx";
import { ref } from "vue";
import { Game } from "src/interfaces/InleagueApiV1"

export const Games = (() => {
  const state = ref(freshState());

  const setAllScoresComponentState = (payload: AllScoresComponentState) => {
    state.value.allScoresComponentState = payload;
  }

  /**
   * Update a game, given its gameID, and some portion of game object which should be updated.
   * For example, `putGame({gameID: ID, gameStart: ..., genderNeutral: ...})` updates
   * the game identified by gameID, by updating its gameStart and genderNeutral fields.
   *
   * Note this is not ActiveRecord; these changes are local to the client store only, they are not
   * persisted to the database.
   */
  const updateGame = (payload: Pick<Game, "gameID"> & Partial<Game>) : void => {
    if (state.value.allScoresComponentState?.gamesListing) {
      const targetIdx = state.value.allScoresComponentState.gamesListing.findIndex(game => game.gameID === payload.gameID);
      if (targetIdx !== -1) {
        const target = state.value.allScoresComponentState.gamesListing[targetIdx];
        state.value.allScoresComponentState.gamesListing[targetIdx] = {...target, ...payload};
      }
    }
  }

  return {
    get value() { return state.value },
    setAllScoresComponentState,
    updateGame,
  }
})()

function freshState(): GameI {
  return {
    allScoresComponentState: null,
  }
}

interface GameI {
  allScoresComponentState: AllScoresComponentState | null
}
