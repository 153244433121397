import { arrayFindOrFail, assertIs, assertNonNull, assertTruthy, copyViaJsonRoundTrip, exhaustiveCaseGuard, FK_nodeRef, FK_validation_strlen, forceCheckedIndexedAccess, noAvailableOptions, parseIntOr, parseIntOrFail, requireNonNull, UiOption, UiOptions, unreachable, useIziToast } from "src/helpers/utils";
import { Datelike, DateTimelike, Division, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref, toRaw } from "vue";
import { BracketRoundSlotID, BracketNameTitleBarElem, EditBracketNameModal, RoundSlotElemRefTracker, EditBracketRoundNameModal, BracketRoundNameColHeaderElem, BracketRoundSlotUiData, EditBracketRoundSlot, STRLEN_MAX_BRACKET_NAME, NeedsAGame, CreateAGame, mungeGameTeamForUiData, TeamDragEventBus, BracketBuilderTeamWrapper, FKNodeTracker, EditBracketRoundSlotTeamsModal } from "./BracketBuilderElems";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { FormKit, FormKitMessages } from "@formkit/vue";
import dayjs, { Dayjs } from "dayjs";
import { Client } from "src/store/Client";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { Bracket, BracketPoolOption, BracketRoundSlot, BracketRoundSlotTeamOption, createBracket, CreateBracketArgs, CreateBracketRoundArgs, CreateBracketRoundSlotArgs, findGamesForTentativeBracket, GameForBracketBuilderView, getBracketForBracketBuilder, getBracketRoundSlotTeamOptions, getFlatSeasonCompDivOptions, getPoolOptionsForBracket, listGamesForFields, swapTeams, updateBracketName, updateBracketRoundName, updateBracketRoundSlot } from "./Bracket.io";
import { ReactiveReifiedPromise, ReifiedPromise, RP_Idle, RP_Resolved } from "src/helpers/ReifiedPromise";
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";

import { withNoScroll } from "src/router/RouterScrollBehavior";
import { RouterLink, useRouter } from "vue-router";
import { mungeQueryParams, QueryParams } from "./R_BracketBuilder.route";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { DAYJS_FORMAT_HTML_DATE, DAYJS_FORMAT_HTML_DATETIME, DAYJS_FORMAT_IL_API_LOCALDATETIME, dayjsOr } from "src/helpers/formatDate";
import { User } from "src/store/User";
import * as R_TeamPools from "src/components/Team/TeamPools/R_TeamPools.route"

import * as R_GameSchedulerCalendar from "src/components/GameScheduler/calendar/R_GameSchedulerCalendar.route";
import * as R_BracketListing from "./R_BracketListing.route";
import { coachBlurbForTeamName } from "../calendar/GameScheduler.shared";
import { bracketInfoMessage, poolToBracket } from "./BracketUtils";
import { getDivision } from "src/composables/InleagueApiV1";
import { updateGame } from "src/composables/InleagueApiV1.GameScheduler";
import { r122 } from "./HardcodedBrackets";
import { BracketElement, BracketElementSlots, slotsOnLevelN } from "./Bracket";

export default defineComponent({
  setup() {
    const router = useRouter()

    const poolSelection = (() => {
      const v = ref<Guid | "">("")
      const sourceObj = computed(() => {
        return poolOptions.source.find(pool => pool.pool.poolUID === v.value) || null
      });

      return {
        get selectedPoolUID() { return v.value },
        set selectedPoolUID(fresh) { v.value = fresh },
        get selectedPool() { return sourceObj.value },
      }
    })()

    const poolOptions = (() => {
      const loader = ReactiveReifiedPromise<BracketPoolOption[]>()

      const poolOptions = computed(() => {
        const p = loader.underlying;
        return p.status === "pending" ? noAvailableOptions("Loading...")
          : p.status === "resolved" ? makeOpts(p.data)
          : noAvailableOptions()
      })

      const optionsSource = computed(() => {
        return loader.underlying.status === "resolved" ? loader.underlying.data : []
      })

      function makeOpts(vs: BracketPoolOption[]) : UiOptions {
        return vs.length === 0
          ? noAvailableOptions("No available pools")
          : {disabled: false, options: vs.map(v => ({label: v.pool.poolName, value: v.pool.poolUID}))}
      }

      return {
        get options() { return poolOptions.value },
        get source() { return optionsSource.value },
        load: async ({competitionUID, divID, seasonUID}: {competitionUID: Guid, divID: Guid, seasonUID: Guid}) => {
          await loader.run(() => getPoolOptionsForBracket(axiosAuthBackgroundInstance, {competitionUID, divID, seasonUID})).getResolvedOrFail()

          if (!poolOptions.value.options.find(v => v.value === poolSelection.selectedPoolUID)) {
            poolSelection.selectedPoolUID = forceCheckedIndexedAccess(poolOptions.value.options, 0)?.value || ""
          }
        }
      }
    })();

    const maybeLoadPoolOptions = () => {
      const seasonUID = selectedSeasonUID.value
      const divID = selectedDivID.value
      const competitionUID = selectedCompetitionUID.value

      if (!seasonUID || !divID || !competitionUID) {
        return
      }

      poolOptions.load({competitionUID,divID,seasonUID})
    }

    // offered to inleague=1 users, to visually output bracketRoundSlotNums for debugging
    const debugBracketRoundSlotIDs = ref(false)



    type BracketBuilderInfo = {
      readonly mode: "new" | "existing",
      readonly bracket: Bracket,
      readonly division: Division,
      /**
       * Probably should be part of BracketBuilderInfo
       * This is augmented client-only data per slot.
       * In general, the information in BracketBuilderInfo["bracket"] should be considered "pristine", and any values in uiDataByRoundslotID to be 'dirty'.
       */
      readonly uiDataByRoundSlotID: Map<BracketRoundSlotID, BracketRoundSlotUiData>
      readonly fkNodeTracker_teamInputs: FKNodeTracker,
    }

    const getUiDataOrFail = ({bracketRoundSlotID} : {bracketRoundSlotID: Integerlike}) : BracketRoundSlotUiData => {
      return requireNonNull(bracketInfo.value?.uiDataByRoundSlotID.get(bracketRoundSlotID))
    }

    const iziToast = useIziToast()
    const bracketInfo = ref<BracketBuilderInfo | null>(null)
    const teamDragEventBus = TeamDragEventBus()
    const ready = ref(false)

    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const competitionOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const divisionOptions = ref<UiOptions>(noAvailableOptions("Loading..."))

    const selectedSeasonUID = ref("")
    const selectedDivID = ref("")
    const selectedCompetitionUID = ref("")
    enum TeamCountOrPool {
      teamCount = "teamCount",
      pool = "pool"
    }

    const teamCountOrPool = ref(TeamCountOrPool.pool)
    const teamCount = ref<Integerlike | "">(2)

    // "gameSearch" is "when creating a bracket try to find defaulted games using these params"
    const gameSearchStartDateTime = ref<Datelike>(dayjs().hour(8).minute(0).second(0).millisecond(0).format(DAYJS_FORMAT_HTML_DATETIME))
    const gameSearchDaysBetweenRounds = ref(1)
    const gameSearchFieldUIDs = ref<Guid[]>([])
    const fk_gameSearchFieldNode = FK_nodeRef()

    const fieldOptions = ref<UiOption[]>([])
    const createDefaultGamesOnFieldUID = ref<Guid | "">("")

    const gameSchedulerCalendarHREF = computed(() => {
      const seasonUID = selectedSeasonUID.value
      const competitionUID = selectedCompetitionUID.value
      const divID = selectedDivID.value
      if (!seasonUID || !competitionUID || !divID) {
        return null
      }

      if (!bracketInfo.value) {
        return null
      }

      const distinctFieldUIDs = new Set<Guid>()

      let minDate : Dayjs | undefined = undefined
      let maxDate : Dayjs | undefined = undefined

      for (const round of bracketInfo.value.bracket.bracketRounds) {
        for (const slot of round.bracketRoundSlots) {
          const game = slot.game

          if (!game) {
            continue;
          }

          distinctFieldUIDs.add(game.fieldUID)

          const gameStart = dayjsOr(game.gameStart);

          if (!gameStart) {
            continue
          }
          if (!minDate || gameStart.isBefore(minDate, "days")) {
            minDate = gameStart
          }
          if (!maxDate || gameStart.isAfter(maxDate, "days")) {
            maxDate = gameStart
          }
        }
      }

      if (minDate && maxDate) {
        if (maxDate.diff(minDate, "days") > 90) {
          maxDate = minDate.add(90, "days")
        }
        if (maxDate.diff(minDate, "days") < 7) {
          maxDate = minDate.add(7, "days")
        }
      }

      const routeLoc = R_GameSchedulerCalendar.routeDetailToRouteLocation({routeName: "GameSchedulerCalendar.main", queryParams: {
        competitionUIDs: [competitionUID],
        divIDs: [divID],
        dateFrom: minDate,
        dateTo: maxDate,
        fieldUIDs: distinctFieldUIDs.size > 0 ? [...distinctFieldUIDs] : undefined,
        focusOnBracketGames: "1",
      }})

      return router.resolve(routeLoc).href
    })

    const loader_gamesForField_optionsListIntent = (() => {
      type GameOpts = {uiOptions: UiOptions, sourceGames: readonly GameForBracketBuilderView[]}
      const defaultAlwaysIdleGameOptionsResolver = new RP_Idle<GameOpts>()
      const __gamesByFieldUID_optionsListIntent_loader = ref(new Map<Guid, ReactiveReifiedPromise<GameForBracketBuilderView[]>>())

      // This starts a load. Loading ensures that for each requested fieldUID,
      // there is an associated reified promise in our gamesByFieldUID mapping.
      const load = async (args: {competitionUID: Guid, divID: Guid, seasonUID: Guid, fieldUIDs: Guid[]}) : Promise<void> => {
        const fieldUIDs_needToLoad = args.fieldUIDs.filter(fieldUID => !__gamesByFieldUID_optionsListIntent_loader.value.get(fieldUID))

        if (fieldUIDs_needToLoad.length === 0) {
          return;
        }

        const bulkLoad = listGamesForFields(axiosAuthBackgroundInstance, {
          competitionUID: args.competitionUID,
          seasonUID: args.seasonUID,
          divID: args.divID,
          fieldUIDs: fieldUIDs_needToLoad
        })

        const ps : Promise<any>[] = []

        for (const fieldUID of fieldUIDs_needToLoad) {
          const eventual_filteredResult = ReactiveReifiedPromise(async () => {
            const resolved = await bulkLoad
            return resolved.filter(v => v.fieldUID === fieldUID)
          })

          ps.push(eventual_filteredResult.awaiter())

          __gamesByFieldUID_optionsListIntent_loader.value.set(fieldUID, eventual_filteredResult)
        }

        // just so caller can see we're done
        await Promise.all(ps);
      }

      /**
       * For those fields we already have info for, reload using some new comp/div/season
       */
      const refreshAlreadyLoaded = async (args: {competitionUID: Guid, divID: Guid, seasonUID: Guid}) : Promise<void> => {
        if (!args.competitionUID || !args.divID || !args.seasonUID) {
          // shouldn't happen
          return
        }

        const fieldUIDs = [...__gamesByFieldUID_optionsListIntent_loader.value.keys()]
        __gamesByFieldUID_optionsListIntent_loader.value = new Map()
        await load({...args, fieldUIDs})
      }

      const reset = () => {
        __gamesByFieldUID_optionsListIntent_loader.value = new Map()
      }

      const waitAll = async () : Promise<void> => {
        await Promise.all([...__gamesByFieldUID_optionsListIntent_loader.value.values()].map(v => v.getResolvedOrFail()))
      }

      return {
        reset,
        load,
        waitAll,
        refreshAlreadyLoaded,
        alwaysIdle: defaultAlwaysIdleGameOptionsResolver,
        for({
          fieldUID,
          mode,
          bracketID,
          bracketRoundSlot,
          bracketRoundSlotUiData,
          selectedGameIDsThisBracket
        }: {
          fieldUID: Guid,
          mode: "new" | "existing",
          bracketID: Integerlike,
          bracketRoundSlot: BracketRoundSlot,
          bracketRoundSlotUiData: () => BracketRoundSlotUiData,
          selectedGameIDsThisBracket: Set<Guid>
        }) {
          // investigate: record fieldUID somewhere, and offer a way to "batchLoadFieldUidsNotYetLoaded"
          return (() => {
            const v = computed<ReifiedPromise<GameOpts>>(() => {
              const p = __gamesByFieldUID_optionsListIntent_loader.value.get(fieldUID)
              if (p?.underlying.status === "resolved") {
                const opts = gameOptionsForBracketRoundSlot({
                  mode,
                  bracketID: bracketID,
                  bracketRoundSlot,
                  bracketRoundSlotUiData: bracketRoundSlotUiData(),
                  gamesForSelectedField: p.underlying.data,
                  selectedGameIDsThisBracket: selectedGameIDsThisBracket
                })
                return new RP_Resolved(opts)
              }
              else {
                // cast of p to different promise type here is OK because it is not resolved
                // and will never be observed as resolved, so the type of the contained data is
                // not relevant
                return (p || defaultAlwaysIdleGameOptionsResolver) as ReifiedPromise<GameOpts>
              }
            });

            return {
              // ref unwrapping nonsense -- `.value` is part of the type dangit
              get value() {
                return v.value
              }
            }
          })()
        },
      }

    })()

    const selectedGameIDsThisBracket = computed(() => {
      const result = new Set<Guid>()
      if (!bracketInfo.value) {
        return result;
      }

      for (const round of bracketInfo.value.bracket.bracketRounds) {
        for (const slot of round.bracketRoundSlots) {
          const uiData = getUiDataOrFail(slot)
          if (uiData?.form.type === "game") {
            if (typeof uiData.form.gameID === "string" && uiData.form.gameID) {
              result.add(uiData.form.gameID)
            }
          }
        }
      }

      return result;
    })

    /**
     * For those slots that are "type=team", which teamIDs have been selected, and how many times.
     * Enables reporting "duplicate selections" across slots.
     */
    const selectedTeamIDsThisBracket = computed(() => {
      const result = new Map<Guid, number>()
      if (!bracketInfo.value) {
        return result;
      }

      for (const round of bracketInfo.value.bracket.bracketRounds) {
        for (const slot of round.bracketRoundSlots) {
          const uiData = getUiDataOrFail(slot)
          if (uiData?.form.type === "team") {
            if (uiData.form.teamID) {
              const v = result.get(uiData.form.teamID)
              result.set(uiData.form.teamID, v === undefined ? 1 : v + 1)
            }
          }
        }
      }

      return result;
    })

    const roundSlotElemRefTracker = RoundSlotElemRefTracker()

    const hydrateBracketInfo = async (bracketID: Integerlike) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const bracket = await getBracketForBracketBuilder(axiosAuthBackgroundInstance, {bracketID})

        selectedSeasonUID.value = bracket.bracket.seasonUID
        selectedCompetitionUID.value = bracket.bracket.competitionUID
        selectedDivID.value = bracket.bracket.divID

        await fullReinit("existing", bracket.bracket)
      })
    }

    // team options are "global" per bracket, just one set of them per (comp, season, div)
    const bracketGlobalTeamOptions = (() => {
      const p = ReactiveReifiedPromise<UiOptions & {source: BracketRoundSlotTeamOption[]}>()

      const kickoff = async ({competitionUID, divID, seasonUID}: {competitionUID: Guid, seasonUID: Guid, divID: Guid}) : Promise<void> => {
        if (p.underlying.status === "idle") {
          await p.run(async () => {
            const vs = await getBracketRoundSlotTeamOptions(axiosAuthBackgroundInstance, {competitionUID, divID, seasonUID});
            return vs.length === 0
              ? {
                ...noAvailableOptions(),
                source: vs,
              }
              : {
                disabled: false,
                options: [
                  {label: "TBD", value: ""},
                  ...vs.map(v => {
                    const coachBlurb = coachBlurbForTeamName(v.coaches)
                    return {
                      label: v.teamDesignation + (coachBlurb ? ` (${coachBlurb})` : ""),
                      value: v.teamID
                    }
                  })
                ],
                source: vs
              }
          }).getResolvedOrFail()
        }
      }

      return {
        get value() { return p },
        reset: () => p.reset(),
        kickoff,
      }
    })();

    // poolUID or teamCount should
    type GameLookupArgs = {
      startDateTime: DateTimelike,
      daysBetweenRounds: Integerlike,
      fieldUIDs: Guid[]
    }
    type ReinitForNewBracketArgs = {gameLookupArgs: GameLookupArgs, generatedUiFormData: Map<Integerlike, BracketRoundSlotUiData["form"]>}

    // TODO: imperative soup should be transformed to a more disciplined functional-esque form
    async function fullReinit(mode: "new", bracket: Bracket, newArgs: ReinitForNewBracketArgs) : Promise<void>;
    async function fullReinit(mode: "existing", bracket: Bracket) : Promise<void>;
    async function fullReinit(mode: "new" | "existing", bracket: Bracket, newArgs?: ReinitForNewBracketArgs) : Promise<void> {
      if (mode === "new") {
        assertNonNull(newArgs?.generatedUiFormData)
      }

      bracketInfo.value = null

      const gamesByGameID = new Map(
        bracket
          .bracketRounds
          .flatMap(v => v.bracketRoundSlots.map(v => v.game))
          .filter(v => !!v)
          .map(v => [v.gameID, v])
      );

      const competitionUID = bracket.competitionUID
      const seasonUID = bracket.seasonUID
      const divID = bracket.divID
      const division = await getDivision(axiosInstance, {divID})
      const defaultGameLengthMinutes = defaultDivisionGameLengthMinutes(division)

      if (!competitionUID || !seasonUID || !divID) {
        // shouldn't happen
        return;
      }

      roundSlotElemRefTracker.reinitFrom(bracket)
      bracketGlobalTeamOptions.reset()

      const uiDataByRoundSlotID = new Map<Integerlike, BracketRoundSlotUiData>()

      const distinctSelectedFieldUIDs = new Set<Guid>()


      for (const round of bracket.bracketRounds) {
        for (const slot of round.bracketRoundSlots) {
          if (slot.type === "game") {
            const currentlyLinkedGame = slot.gameID ? gamesByGameID.get(slot.gameID) : undefined;

            const selectedFieldUID = currentlyLinkedGame?.fieldUID || forceCheckedIndexedAccess(fieldOptions.value, 0)?.value || ""
            if (selectedFieldUID) {
              distinctSelectedFieldUIDs.add(selectedFieldUID)
            }


            const uiData : BracketRoundSlotUiData = {
              busy: false,
              hadGameSchedulingConflict: false,
              selectedFieldUID,
              gameOptionsForSelectedField: loader_gamesForField_optionsListIntent.for({
                fieldUID: selectedFieldUID,
                mode,
                bracketID: slot.bracketID,
                bracketRoundSlot: slot,
                bracketRoundSlotUiData: () => uiData,
                selectedGameIDsThisBracket: selectedGameIDsThisBracket.value
              }),
              teamOptions: bracketGlobalTeamOptions.value,
              form: mode === "new"
                ? requireNonNull(newArgs?.generatedUiFormData?.get(slot.bracketRoundSlotID))
                : {
                  type: "game",
                  gameID: slot.gameID,
                  homeTeam: slot.game ? mungeGameTeamForUiData(slot.game.homeTeam, slot.game.coaches) : null,
                  visitorTeam: slot.game ? mungeGameTeamForUiData(slot.game.visitorTeam, slot.game.coaches) : null,
                  disabled: !!slot.disabled,
                }
            }

            uiDataByRoundSlotID.set(parseIntOrFail(slot.bracketRoundSlotID), uiData)

          }
          else {
            // we have at least one slot that is a type="team", so kick off the team options loader
            bracketGlobalTeamOptions.kickoff({competitionUID, divID, seasonUID})

            const uiData : BracketRoundSlotUiData = {
              busy: false,
              hadGameSchedulingConflict: false,
              selectedFieldUID: "",
              gameOptionsForSelectedField: {value: loader_gamesForField_optionsListIntent.alwaysIdle},
              teamOptions: bracketGlobalTeamOptions.value,
              form: mode === "new"
                ? requireNonNull(newArgs?.generatedUiFormData?.get(slot.bracketRoundSlotID))
                : {
                  type: "team",
                  teamID: slot.teamID,
                  disabled: !!slot.disabled,
                }
            }

            uiDataByRoundSlotID.set(parseIntOrFail(slot.bracketRoundSlotID), uiData)
          }
        }
      }

      loader_gamesForField_optionsListIntent.reset()

      if (distinctSelectedFieldUIDs.size > 0) {
        // if there were no fieldUIDs, then we cannot do this.
        loader_gamesForField_optionsListIntent.load({
          competitionUID: bracket.competitionUID,
          seasonUID: bracket.seasonUID,
          divID: bracket.divID,
          fieldUIDs: [...distinctSelectedFieldUIDs]
        })

      }

      if (mode === "new") {
        const gameLookupArgs = requireNonNull(newArgs?.gameLookupArgs)

        // produces a a list of rounds where each element contains a list of bracket slots needing a game
        const needsAGameSlotsPerRound = bracket
          .bracketRounds
          .map(v => v
            .bracketRoundSlots
            // "sortOfApiData" because it is data created locally that mimics acutal api data.
            // If form.gameID === NeedsAGame then it was created locally.
            .map(v => ({sortOfApiData: v, uiData: requireNonNull(uiDataByRoundSlotID.get(v.bracketRoundSlotID))}))
            .filter(v => {
              return !v.uiData.form.disabled && v.uiData.form.type === "game" && v.uiData.form.gameID === NeedsAGame
            })
          );

        const slotsNeedingOneGameEach = needsAGameSlotsPerRound.flat()

        if (slotsNeedingOneGameEach.length > 0) { // always > 0 in the "new" case yeah?
          const {gamesByRound, proposedGameTimesByRound} = await findGamesForTentativeBracket(axiosInstance, {
            competitionUID,
            divID,
            seasonUID,
            startDateTime: gameLookupArgs.startDateTime,
            daysBetweenRounds: gameLookupArgs.daysBetweenRounds,
            fieldUIDs: gameLookupArgs.fieldUIDs,
            countsByRound: needsAGameSlotsPerRound.map(v => v.length)
          })

          const distinctFieldUIDs = new Set<Guid>()

          // We have all rounds accounted for.
          // Note however that we might not have found all or any of the games per round we need.
          assertTruthy(gamesByRound.length === needsAGameSlotsPerRound.length)

          for (let i = 0; i < gamesByRound.length; i++) {
            const bracketSlotsForRound = needsAGameSlotsPerRound[i]
            const gamesForRound = gamesByRound[i]

            // we found anywhere from zero up to the full amount of games we need for this round, but no more than that.
            assertTruthy(gamesForRound.length <= bracketSlotsForRound.length);

            let slotIdx = 0
            while (gamesForRound.length) {
              const bracketRoundSlot = bracketSlotsForRound[slotIdx++]

              assertIs(bracketRoundSlot.uiData.form.type, "game")

              if (bracketRoundSlot.uiData.form.disabled) {
                continue;
              }

              const game = requireNonNull(gamesForRound.shift())

              bracketRoundSlot.sortOfApiData.gameID = game.gameID
              bracketRoundSlot.sortOfApiData.game = game

              bracketRoundSlot.uiData.selectedFieldUID = game.fieldUID
              bracketRoundSlot.uiData.form = {
                type: "game",
                gameID: game.gameID,
                homeTeam: bracketRoundSlot.uiData.form.homeTeam,
                visitorTeam: bracketRoundSlot.uiData.form.visitorTeam,
                disabled: false,
              }

              distinctFieldUIDs.add(game.fieldUID)

              bracketRoundSlot.uiData.gameOptionsForSelectedField = loader_gamesForField_optionsListIntent.for({
                fieldUID: game.fieldUID,
                mode,
                bracketID: bracketRoundSlot.sortOfApiData.bracketID,
                bracketRoundSlot: bracketRoundSlot.sortOfApiData,
                bracketRoundSlotUiData: () => bracketRoundSlot.uiData,
                selectedGameIDsThisBracket: selectedGameIDsThisBracket.value
              })
            }
          }

          // the remaiing bracketRoundSlots, if any, are adjusted to "create" mode.
          // When we're done here, no bracketRoundSlot should be tagged "NeedsAGame"
          // TODO: make "needsAGame" a separate subtype rather than writing it into gameID
          {
            // We have all rounds accounted for.
            assertTruthy(proposedGameTimesByRound.length === needsAGameSlotsPerRound.length)

            for (let i = 0; i < proposedGameTimesByRound.length; i++) {
              const bracketSlotsForRound = needsAGameSlotsPerRound[i]
              const proposedTimesForRound = proposedGameTimesByRound[i]

              assertTruthy(proposedTimesForRound.length <= bracketSlotsForRound.length)

              for (let slotIdx = 0; slotIdx < bracketSlotsForRound.length; slotIdx++) {
                const uiData = bracketSlotsForRound[slotIdx].uiData
                const sortOfApiData = bracketSlotsForRound[slotIdx].sortOfApiData

                assertIs(uiData.form.type, "game")

                if (uiData.form.gameID !== NeedsAGame) {
                  // already got linked in prior phase
                  continue;
                }

                // pop front, might not exist, that's ok, we'll just use a default value
                // (backend wasn't able to find a "good" default value -- we should probably allow backend
                // to provide "not good" default and then we don't need to worry about it here)
                const maybeProposed = proposedTimesForRound.shift()
                const proposedFrom = dayjsOr(maybeProposed?.gameStart) ?? dayjs(newArgs?.gameLookupArgs.startDateTime)

                if (maybeProposed) {
                  distinctFieldUIDs.add(maybeProposed.fieldUID)
                }

                const fieldUID = uiData.selectedFieldUID = maybeProposed?.fieldUID || gameLookupArgs.fieldUIDs[0] || ""

                if (fieldUID) {
                  distinctFieldUIDs.add(fieldUID)
                }

                uiData.form = {
                  type: "game",
                  gameID: CreateAGame,
                  homeTeam: uiData.form.homeTeam,
                  visitorTeam: uiData.form.visitorTeam,
                  disabled: false,
                  create: {
                    startDate: proposedFrom.format(DAYJS_FORMAT_HTML_DATE),
                    startHr24: proposedFrom.hour(),
                    startMin: proposedFrom.minute(),
                    gameLengthMinutes: maybeProposed?.gameLengthMinutes || defaultGameLengthMinutes,
                    fieldUID: "use-the-outer-fieldUID/read-the-comment",
                  }
                }

                uiData.gameOptionsForSelectedField = loader_gamesForField_optionsListIntent.for({
                  fieldUID,
                  mode,
                  bracketID: sortOfApiData.bracketID,
                  bracketRoundSlot: sortOfApiData,
                  bracketRoundSlotUiData: () => uiData,
                  selectedGameIDsThisBracket: selectedGameIDsThisBracket.value
                })
              }
            }
          }

          loader_gamesForField_optionsListIntent.load({competitionUID, divID, seasonUID, fieldUIDs: [...distinctFieldUIDs]})
        }

        // sanity checks
        // all the games got changed, if necessary, from "NeedsAGame" to something other than NeedsAGame
        {
          const vs = bracket
            .bracketRounds
            .flatMap(round => round.bracketRoundSlots.map(slot => {
              const uiData = requireNonNull(uiDataByRoundSlotID.get(slot.bracketRoundSlotID))
              return {slot, uiData}
            }))
            .filter(v => !v.uiData.form.disabled)

          vs.forEach(v => {
            const uiData = v.uiData
            const b = uiData.form.disabled || (uiData.form.type === "game" ? uiData.form.gameID !== NeedsAGame : true)
            assertTruthy(b)
          })
        }
      }

      //
      // Make sure we have awaited the field load so nothing that will be relied upon is pending.
      // Also, trickytricky: We don't properly redraw the svg bracket lines when elements resize on their accord,
      // that is, when their content changes we might need to adjust the svg lines. If we don't wait here prior to
      // making the assignment to bracketInfo, we will draw the bracket info in a "pending" state, where it is very
      // likely that the non-pending state, when it arrives to it, will change the sizes of the elements significantly.
      // Then you end up with weird looking lines that don't quite connect the bracket slots.
      //
      await loader_gamesForField_optionsListIntent.waitAll()
      await bracketGlobalTeamOptions.kickoff({
        competitionUID,
        divID,
        seasonUID
      })

      bracketInfo.value = {
        mode,
        bracket,
        division,
        uiDataByRoundSlotID,
        fkNodeTracker_teamInputs: FKNodeTracker(),
      }

      // Do this here to update "oh, game X is not avaiable because it is selected on bracket slot Y".
      rebuildAllGamesOptionsLists()
    }

    const saveFreshBracket = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        assertIs(bracketInfo.value?.mode, "new")
        const tentativeBracket = requireNonNull(bracketInfo.value?.bracket)

        // clear prior errors (maybe there is a better places to do this?)
        for (const round of tentativeBracket.bracketRounds) {
          for (const slot of round.bracketRoundSlots) {
            const uiData = getUiDataOrFail(slot)
            uiData.hadGameSchedulingConflict = false
          }
        }

        // We don't currently "save the whole thing" for existing brackets
        // (existing brackets get updated piecemeal, like "update bracket name", or "update this bracketRoundSlot's game")

        try {
          // copy the working bracket, and merge in the separate uiState values as appropriate
          // (i.e. things we keep off to the side to maintain a "dirty/pristine" copies, we want to send
          // the dirty values here).
          const mungedBracket = (() => {
            return {
              ...tentativeBracket,
              bracketRounds: bracketInfo.value.bracket.bracketRounds.map(round => {
                return {
                  ...round,
                  bracketRoundSlots: round.bracketRoundSlots.map(slot => {
                    const uiData = getUiDataOrFail(slot)
                    return {
                      ...slot,
                      // these should be getting updated as appropriate to remain consistent w.r.t
                      // constraints that the values of `gameID` and `teamID` are dependent on the value of `type`.
                      // We should not have to worry about their validity here.
                      type: uiData.form.type,
                      gameID: uiData.form.type === "game" ? uiData.form.gameID : "",
                      // teamID probably will become unused?...
                      teamID: uiData.form.type === "team" ? uiData.form.teamID : "",
                      teams: {
                        home: uiData.form.type === "game" && uiData.form.homeTeam
                          ? {
                            teamID: uiData.form.homeTeam.value?.teamID || "",
                            seed: parseIntOr(uiData.form.homeTeam.value?.seed, "")
                          }
                          : undefined,
                        visitor: uiData.form.type === "game" && uiData.form.visitorTeam
                          ? {
                            teamID: uiData.form.visitorTeam.value?.teamID || "",
                            seed: parseIntOr(uiData.form.visitorTeam.value?.seed, "")
                          }
                          : undefined,
                      },
                      createGame: uiData.form.type === "game" && uiData.form.gameID === CreateAGame
                        ? (() => {
                          const args = requireNonNull(uiData.form.create)
                          return {
                            fieldUID: uiData.selectedFieldUID,
                            gameStart: dayjs(args.startDate).hour(parseIntOrFail(args.startHr24)).minute(parseIntOrFail(args.startMin)).format(DAYJS_FORMAT_IL_API_LOCALDATETIME),
                            gameLengthMinutes: parseIntOrFail(args.gameLengthMinutes),
                          }
                        })()
                        : undefined
                    } satisfies CreateBracketRoundSlotArgs
                  })
                } satisfies CreateBracketRoundArgs
              })
            } satisfies CreateBracketArgs
          })();

          const b = await createBracket(axiosInstance, mungedBracket)

          if (b.ok) {
            await hydrateBracketInfo(b.data.bracketID)
            await withNoScroll(async () => {
              await router.replace({
                ...router.currentRoute.value,
                query: {
                  ...router.currentRoute.value.query,
                  bracketID: parseIntOrFail(b.data.bracketID)
                } satisfies QueryParams
              })
            })
          }
          else {
            const affectedBracketRoundSlotIDs = new Set(b.data.affectedBracketRoundIDs)
            for (const round of tentativeBracket.bracketRounds) {
              for (const slot of round.bracketRoundSlots) {
                if (affectedBracketRoundSlotIDs.has(slot.bracketRoundSlotID)) {
                  const uiData = getUiDataOrFail(slot)
                  uiData.hadGameSchedulingConflict = true
                }
              }
            }
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    onMounted(async () => {
      const q = mungeQueryParams(router.currentRoute.value.query);
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const opts = await getFlatSeasonCompDivOptions(axiosInstance)

        seasonOptions.value = opts.seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))}

        competitionOptions.value = opts.competitions.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.competitions.map(v => ({label: v.competition, value: v.competitionUID}))}

        divisionOptions.value = opts.divisions.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.divisions.map(v => ({label: v.division, value: v.divID}))}

        fieldOptions.value = (await Client.loadFields()).map(v => ({label: `${v.fieldAbbrev} (${v.fieldName})`, value: v.fieldUID}))

        selectedCompetitionUID.value = competitionOptions.value.options.find(v => v.value === q?.competitionUID)?.value
          || (forceCheckedIndexedAccess(competitionOptions.value.options, 0)?.value ?? "")

        selectedDivID.value = divisionOptions.value.options.find(v => v.value === q?.divID)?.value
          || (forceCheckedIndexedAccess(divisionOptions.value.options, 0)?.value ?? "")

        selectedSeasonUID.value = seasonOptions.value.options.find(v => v.value === q?.seasonUID)?.value
          || (forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value ?? "")

        if (q?.bracketID !== undefined) {
          hydrateBracketInfo(q.bracketID)
        }

        maybeLoadPoolOptions();

        createDefaultGamesOnFieldUID.value = forceCheckedIndexedAccess(fieldOptions.value, 0)?.value || ""

        ready.value = true;
      })
    })

    const updateRoundSlotGameID = (roundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData, gameIdLike: Guid | "" | typeof CreateAGame) => {
      if (slotUiData.form.type === "game" && slotUiData.form.gameID === gameIdLike) {
        // really shouldn't hit this ... but if we do, avoid infinite update cycle
        return
      }

      assertNonNull(bracketInfo.value)
      assertIs(slotUiData.gameOptionsForSelectedField.value.status, "resolved")
      const game = slotUiData.gameOptionsForSelectedField.value.data.sourceGames.find(v => v.gameID === gameIdLike)

      slotUiData.form = {
        type: "game",
        gameID: gameIdLike,
        homeTeam: bracketInfo.value.mode === "new"
          ? (slotUiData.form.type === "game" ? slotUiData.form.homeTeam : unreachable("'new' mode shouldn't have non-game type bracket round slots"))
          : game ? mungeGameTeamForUiData(game.homeTeam, game.coaches) : null,
        visitorTeam: bracketInfo.value.mode === "new"
          ? (slotUiData.form.type === "game" ? slotUiData.form.visitorTeam : unreachable("'new' mode shouldn't have non-game type bracket round slots"))
          : game ? mungeGameTeamForUiData(game.visitorTeam, game.coaches) : null,
        disabled: false,
        create: gameIdLike === CreateAGame
          ? {
            // todo: scan backwards from "this slot" to nearest slot with a meaningful time, and then use that as a baseline
            startDate: dayjs().add(1, "week").format(DAYJS_FORMAT_HTML_DATE),
            startHr24: 8,
            startMin: 0,
            gameLengthMinutes: defaultDivisionGameLengthMinutes(bracketInfo.value.division),
            fieldUID: "use-the-outer-fieldUID/read-the-comment",
          }
          : undefined
      }
      rebuildAllGamesOptionsLists()
    }

    const rebuildAllGamesOptionsLists = () => {
      assertNonNull(bracketInfo.value)
      for (const bracketRound of bracketInfo.value.bracket.bracketRounds) {
        for (const slot of bracketRound.bracketRoundSlots) {
          const uiData = getUiDataOrFail(slot)
          if (uiData.form.type !== "game" || uiData.form.disabled) {
            continue;
          }
          rebuildGameOptionsListAndUpdateSelectedValueAsNecessary(slot)
        }
      }
    }

    const rebuildGameOptionsListAndUpdateSelectedValueAsNecessary = async (bracketRoundSlot: BracketRoundSlot) : Promise<void> => {
      const uiData = getUiDataOrFail(bracketRoundSlot)

      assertTruthy(uiData.form.type === "game")

      assertNonNull(bracketInfo.value)
      const mode = bracketInfo.value.mode
      const competitionUID = bracketInfo.value.bracket.competitionUID
      const divID = bracketInfo.value.bracket.divID
      const seasonUID = bracketInfo.value.bracket.seasonUID

      const fieldUID = uiData.selectedFieldUID

      await loader_gamesForField_optionsListIntent.load({
        competitionUID,
        seasonUID,
        divID,
        fieldUIDs: [fieldUID]
      })

      uiData.gameOptionsForSelectedField = loader_gamesForField_optionsListIntent.for({
        fieldUID,
        mode,
        bracketID: bracketRoundSlot.bracketID,
        bracketRoundSlot,
        bracketRoundSlotUiData: () => uiData,
        selectedGameIDsThisBracket: selectedGameIDsThisBracket.value
      })

      // must be true, because waited the load for the required field, and _then_ created the
      // lookup for that field (so the underlying promise is definitely resolved)
      assertIs(uiData.gameOptionsForSelectedField.value.status, "resolved")

      const opts = uiData.gameOptionsForSelectedField.value.data

      if (uiData.form.type === "team" && bracketRoundSlot.game) {
        const pristineGame = bracketRoundSlot.game
        if (opts.uiOptions.options.find(v => v.value === pristineGame.fieldUID)) {
          if (!selectedGameIDsThisBracket.value.has(pristineGame.gameID)) {
            updateRoundSlotGameID(bracketRoundSlot, uiData, pristineGame.gameID)
          }
          else {
            updateRoundSlotGameID(bracketRoundSlot, uiData, "")
          }
        }
      }

      if (uiData.form.type === "game") {
        const gameID = uiData.form.gameID
        if (!opts.uiOptions.options.find(v => v.value === gameID)) {
          updateRoundSlotGameID(bracketRoundSlot, uiData, bracketInfo.value.mode === "new" ? CreateAGame : "")
        }
      }
    }

    const persistChangesToExistingRoundSlot = async (roundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData) : Promise<void> => {
      assertNonNull(bracketInfo.value)
      assertIs(bracketInfo.value.mode, "existing")

      try {
        try {
          slotUiData.busy = true
          const result = await updateBracketRoundSlot(axiosAuthBackgroundInstance, {
            bracketRoundSlotID: roundSlot.bracketRoundSlotID,
            type: slotUiData.form.type,
            gameID: slotUiData.form.type === "game" ? slotUiData.form.gameID : "",
            teamID: slotUiData.form.type === "team" ? slotUiData.form.teamID : "",
          })

          if (slotUiData.form.type === "game") {
            roundSlot.type = "game"
            roundSlot.gameID = slotUiData.form.gameID
            roundSlot.game = requireNonNull(result.game)
            roundSlot.teamID = ""
          }
          else if (slotUiData.form.type === "team") {
            roundSlot.type = "team"
            roundSlot.gameID = ""
            roundSlot.game = null
            roundSlot.teamID = slotUiData.form.teamID
          }
          else {
            exhaustiveCaseGuard(slotUiData.form)
          }
        }
        finally {
          slotUiData.busy = false
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const discardChangesForExistingRoundSlot = (roundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData) => {
      assertNonNull(bracketInfo.value)
      assertIs(bracketInfo.value.mode, "existing")

      slotUiData.selectedFieldUID = roundSlot.game?.fieldUID || forceCheckedIndexedAccess(fieldOptions.value, 0)?.value || ""

      if (roundSlot.type === "game") {
        // check if some other slot consumed in the interim
        if (!selectedGameIDsThisBracket.value.has(roundSlot.gameID)) {
          slotUiData.form = {
            type: "game",
            gameID: roundSlot.gameID,
            homeTeam: roundSlot.game ? mungeGameTeamForUiData(roundSlot.game.homeTeam, roundSlot.game.coaches) : null,
            visitorTeam: roundSlot.game ? mungeGameTeamForUiData(roundSlot.game.visitorTeam, roundSlot.game.coaches) : null,
            disabled: !!roundSlot.disabled,
          }
        }
        else {
          slotUiData.form = {
            type: "game",
            gameID: "",
            homeTeam: null,
            visitorTeam: null,
            disabled: !!roundSlot.disabled,
          }
          // TOOD: which (round,slot) specifically
          iziToast.warning({message: "Some other slot has been assigned the game this is attempting to reset to."})
        }
      }
      else if (roundSlot.type === "team") {
        slotUiData.form = {
          type: "team",
          teamID: roundSlot.teamID,
          disabled: !!roundSlot.disabled,
        }
      }
      else {
        exhaustiveCaseGuard(roundSlot.type)
      }

      rebuildAllGamesOptionsLists()
    }

    const updateRoundSlotFieldUID = (roundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData, fieldUID: Guid) => {
      if (slotUiData.form.type !== "game") {
        // shouldn't happen, yeah? (changing fieldUID only makes sense for a 'game' type slot)
        return;
      }

      slotUiData.selectedFieldUID = fieldUID;
      rebuildGameOptionsListAndUpdateSelectedValueAsNecessary(roundSlot)
    }

    const updateRoundSlotType = (bracketRoundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData, type: BracketRoundSlotUiData["form"]["type"]) => {
      assertNonNull(bracketInfo.value)

      const mode = bracketInfo.value.mode
      const bracketID = bracketInfo.value.bracket.bracketID
      const competitionUID = bracketInfo.value.bracket.competitionUID
      const seasonUID = bracketInfo.value.bracket.seasonUID
      const divID = bracketInfo.value.bracket.divID

      if (!bracketID || !competitionUID || !seasonUID || !divID) {
        // shouldn't happen
        return;
      }

      switch (slotUiData.form.type) {
        case "game": {
          const selectedFieldUID = slotUiData.selectedFieldUID || forceCheckedIndexedAccess(fieldOptions.value, 0)?.value || "";
          if (!selectedFieldUID) {
            // shouldn't happen
            return
          }

          // maybe it changed, this is effectively a no-op if the selected value didn't actually change
          slotUiData.selectedFieldUID = selectedFieldUID
          slotUiData.form = {
            type: "game",
            gameID: bracketRoundSlot.gameID,
            homeTeam: bracketRoundSlot.game ? mungeGameTeamForUiData(bracketRoundSlot.game.homeTeam, bracketRoundSlot.game.coaches) : null,
            visitorTeam: bracketRoundSlot.game ? mungeGameTeamForUiData(bracketRoundSlot.game.visitorTeam, bracketRoundSlot.game.coaches) : null,
            disabled: false,
          }

          slotUiData.gameOptionsForSelectedField = loader_gamesForField_optionsListIntent.for({
            fieldUID: selectedFieldUID,
            mode,
            bracketID,
            bracketRoundSlot,
            bracketRoundSlotUiData: () => slotUiData,
            selectedGameIDsThisBracket: selectedGameIDsThisBracket.value,
          })

          loader_gamesForField_optionsListIntent.load({
            competitionUID,
            seasonUID,
            divID,
            fieldUIDs: [selectedFieldUID]
          })

          return;
        }
        case "team": {
          slotUiData.form = {
            type: "team",
            teamID: bracketRoundSlot.teamID,
            disabled: false,
          }

          // At this time, we store refs to the "bracketGlobalTeamOptions" on each slot's uiData.
          // We might change this in the future to have some uniquely transformed version per each slot's uiData,
          // and we rely on that fact here for the logic to do what we want.
          assertTruthy(
            // n.b. unwrap vue-proxyified object, because proxy(obj-x) !== obj-x
            toRaw(slotUiData.teamOptions) === bracketGlobalTeamOptions.value
          )
          bracketGlobalTeamOptions.kickoff({competitionUID, divID, seasonUID})
          return;
        }
        default: exhaustiveCaseGuard(slotUiData.form)
      }
    }

    const updateRoundSlotTeamID = (_roundSlot: BracketRoundSlot, slotUiData: BracketRoundSlotUiData, teamID: Guid) => {
      slotUiData.form = {
        type: "team",
        teamID,
        disabled: false,
      }
    }

    const doSwapTeams = async ({source, sourceWhich, target, targetWhich} : {source: BracketRoundSlot, sourceWhich: "home" | "visitor", target: BracketRoundSlot, targetWhich: "home" | "visitor"}) : Promise<void> => {
      assertNonNull(bracketInfo.value)

      const uiDataSource = getUiDataOrFail(source)
      const uiDataTarget = getUiDataOrFail(target)

      assertIs(uiDataSource.form.type, "game")
      assertIs(uiDataTarget.form.type, "game")

      const sourceForm = uiDataSource.form
      const targetForm = uiDataTarget.form

      const swapLocally = () => {
        const sourceFresh = targetForm[`${targetWhich}Team`]
        const targetFresh = sourceForm[`${sourceWhich}Team`]
        sourceForm[`${sourceWhich}Team`] = sourceFresh
        targetForm[`${targetWhich}Team`] = targetFresh
      }

      if (bracketInfo.value.mode === "new") {
        swapLocally()
      }
      else if (bracketInfo.value.mode === "existing") {
        try {
          await swapTeams(axiosInstance, {
            game1: {gameID: source.gameID, which: sourceWhich},
            game2: {gameID: target.gameID, which: targetWhich}
          })
          swapLocally()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }
      else {
        exhaustiveCaseGuard(bracketInfo.value.mode)
      }
    }

    const editBracketNameModalController = (() => {
      const {onCloseCB, busy} = useDefaultNoCloseModalIfBusy()

      return DefaultModalController_r<Bracket>({
        title: () => <>
          <div>Edit Bracket Name</div>
          <div class="my-2 border-b"></div>
        </>,
        content: bracket => {
          if (!bracket) {
            return null;
          }
          else {
            return <div>
              <EditBracketNameModal
                class="mt-4"
                initialValue={bracket.bracketName}
                onSaveChanges={async newVal => {
                  try {
                    try {
                      busy.value = true
                      await updateBracketName(axiosAuthBackgroundInstance, {bracketID: bracket.bracketID, bracketName: newVal})
                      bracket.bracketName = newVal
                    }
                    finally {
                      busy.value = false
                    }
                    editBracketNameModalController.close()
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err)
                  }
                }}
                onCancel={() => editBracketNameModalController.close()}
              />
              {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
            </div>
          }
        }
      }, {onCloseCB})
    })();

    const editBracketRoundNameModalController = (() => {
      const {onCloseCB, busy} = useDefaultNoCloseModalIfBusy()

      return DefaultModalController_r<{bracket: Bracket, zi_roundIdx: number}>({
        title: () => <>
          <div>Edit Bracket Round Name</div>
          <div class="my-2 border-b"></div>
        </>,
        content: data => {
          if (!data) {
            return null;
          }
          else {
            return <div>
              <EditBracketRoundNameModal
                class="mt-4"
                zi_roundNum={data.zi_roundIdx}
                initialValue={data.bracket.bracketRounds[data.zi_roundIdx].bracketRoundName}
                onSaveChanges={async newVal => {
                  try {
                    try {
                      busy.value = true
                      await updateBracketRoundName(axiosAuthBackgroundInstance, {
                        bracketID: data.bracket.bracketID,
                        // the resulting value should always just be zi_roundIdx+1 (a 1-indexed version of zi_roundIdx),
                        // but we'll get it from the canonical place
                        bracketRoundNum: data.bracket.bracketRounds[data.zi_roundIdx].bracketRoundNum,
                        bracketRoundName: newVal
                      })
                      data.bracket.bracketRounds[data.zi_roundIdx].bracketRoundName = newVal
                    }
                    finally {
                      busy.value = false
                    }
                    editBracketRoundNameModalController.close()
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err)
                  }
                }}
                onCancel={() => editBracketRoundNameModalController.close()}
              />
              {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
            </div>
          }
        }
      }, {onCloseCB})
    })();

    const editBracketRoundSlotTeamModalController = (() => {
      const {onCloseCB, busy} = useDefaultNoCloseModalIfBusy()

      return DefaultModalController_r<{slot: BracketRoundSlot, uiData: BracketRoundSlotUiData}>({
        title: () => <>
          <div>Edit Teams</div>
          <div class="my-2 border-b"></div>
        </>,
        content: data => {
          if (!data || data.uiData.form.type !== "game") {
            return null;
          }
          else {
            assertNonNull(bracketInfo.value)
            assertIs(bracketInfo.value.mode, "existing")
            const gameID = requireNonNull(data.slot.game).gameID
            const consumedTeamIDs = (() => {
              assertNonNull(bracketInfo.value)
              const teamIDs = new Set<Guid>()
              for (const round of bracketInfo.value.bracket.bracketRounds) {
                for (const slot of round.bracketRoundSlots) {
                  if (slot.bracketRoundSlotID === data.slot.bracketRoundSlotID) {
                    continue;
                  }

                  const uiData = getUiDataOrFail(slot)
                  if (uiData.form.type !== "game") {
                    continue
                  }

                  {
                    const home = uiData.form.homeTeam?.value?.teamID || ""
                    if (home && home !== Client.value.instanceConfig.byeteam) {
                      teamIDs.add(home)
                    }
                  }

                  {
                    const visitor = uiData.form.visitorTeam?.value?.teamID || ""
                    if (visitor && visitor !== Client.value.instanceConfig.byeteam) {
                      teamIDs.add(visitor)
                    }
                  }
                }
              }
              return teamIDs
            })();

            const form = data.uiData.form
            if (form.type !== "game") {
              return null
            }

            return <div>
              <EditBracketRoundSlotTeamsModal
                class="mt-4"
                bracketRoundSlot={data.slot}
                uiData={data.uiData}
                initialHomeTeamID={data.uiData.form.homeTeam?.value?.teamID || ""}
                initialVisitorTeamID={data.uiData.form.visitorTeam?.value?.teamID || ""}
                consumedTeamIDs={consumedTeamIDs}
                onSaveChanges={async args => {
                  try {
                    try {
                      busy.value = true
                      await updateGame(axiosAuthBackgroundInstance, {gameID, homeTeamID: args.home?.teamID || "TBD", visitorTeamID: args.visitor?.teamID || "TBD"})

                      // Update local state
                      // This is a little tenuous ... where should we be pulling 'seed' value from?
                      // And are we updating everything ...
                      form.homeTeam = args.home ? {
                        type: "team",
                        value: {...args.home, seed: parseIntOr(form.homeTeam?.value?.seed, null)}
                      } : null;
                      form.visitorTeam = args.visitor ? {
                        type: "team",
                        value: {...args.visitor, seed: parseIntOr(form.homeTeam?.value?.seed, null)}
                      } : null;

                      if (data.slot.game) {
                        data.slot.game.homeTeam = copyViaJsonRoundTrip(form.homeTeam)?.value || null
                        data.slot.game.visitorTeam = copyViaJsonRoundTrip(form.visitorTeam)?.value || null
                      }
                    }
                    finally {
                      busy.value = false
                    }

                    editBracketRoundSlotTeamModalController.close()
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err)
                  }
                }}
                onCancel={() => editBracketRoundSlotTeamModalController.close()}
              />
              {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
            </div>
          }
        }
      }, {onCloseCB})
    })();

    const fkRoot = FK_nodeRef();

    const generateBracket = async () : Promise<void> => {
      const seasonUID = selectedSeasonUID.value
      const competitionUID = selectedCompetitionUID.value
      const divID = selectedDivID.value

      const startDateTime = gameSearchStartDateTime.value
      const fieldUIDs = gameSearchFieldUIDs.value
      const daysBetweenRounds = gameSearchDaysBetweenRounds.value

      if (!seasonUID || !competitionUID || !divID) {
        return
      }

      const bracket = (() => {
        const teams = (() => {
          if (teamCountOrPool.value === TeamCountOrPool.pool) {
            return poolSelection.selectedPool?.teams.map((team) : BracketBuilderTeamWrapper => {
              return {
                type: "team",
                value: team
              }
            }) ?? []
          }
          else if (teamCountOrPool.value === TeamCountOrPool.teamCount) {
            return [...new Array(parseIntOrFail(teamCount.value))].map((_,i) : BracketBuilderTeamWrapper => {
              return {
                type: "placeholder",
                seed: i,
                value: null,
              }
            })
          }
          else {
            exhaustiveCaseGuard(teamCountOrPool.value)
          }
        })();

        return poolToBracket(teams, {seasonUID, competitionUID, divID, staticBracketConfig: r122})
      })();

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await fullReinit("new", bracket.freshBracket, {
          gameLookupArgs: {
            startDateTime,
            fieldUIDs,
            daysBetweenRounds,
          },
          generatedUiFormData: bracket.uiDataByBracketRoundSlotID
        })
      })
    }

    return () => {
      if (!ready.value) {
        return null
      }

      return (
        <div style="--fk-padding-input:.4em; --fk-bg-input:white; --fk-margin-outer:none; min-width: 800px; width: max-content;">
          <AutoModal controller={editBracketNameModalController}/>
          <AutoModal controller={editBracketRoundNameModalController}/>
          <AutoModal controller={editBracketRoundSlotTeamModalController}/>

          <div class="flex gap-2">
            <RouterLink to={{name: R_BracketListing.RouteName}} class="il-link text-sm">
              <button type="button" class="flex gap-1 items-center">
                <FontAwesomeIcon icon={faArrowLeft}/>
                Brackets Home
              </button>
            </RouterLink>
            |
            <RouterLink {...{target:"_blank"}} to={R_TeamPools.routeDetailToRouteLocation({routeName: "TeamPools.main"}, {
              seasonUID: selectedSeasonUID.value || undefined,
              competitionUID: selectedCompetitionUID.value || undefined,
              divID: selectedDivID.value || undefined,
            })} class="il-link text-sm">
              <button type="button" class="flex gap-1 items-center">
                Pools
                <FontAwesomeIcon icon={faCircleArrowUp} class="transform rotate-45"/>
              </button>
            </RouterLink>
            |
            <a href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Game-Scheduling-Overview#tournament-bracket-scheduling" class="text-sm il-link">Bracket Scheduling Help <FontAwesomeIcon icon={faCircleArrowUp} class="transform rotate-45" /></a>

            {gameSchedulerCalendarHREF.value
              ? <a href={gameSchedulerCalendarHREF.value} target="_blank" class="il-link text-sm">
                <button type="button" class="flex gap-1 items-center">
                  Game Scheduler
                  <FontAwesomeIcon icon={faCircleArrowUp} class="transform rotate-45"/>
                </button>
              </a>
              : null
            }
          </div>

          <h2 class="mt-2 mb-2">
            {!bracketInfo.value || bracketInfo.value.mode === "new"
              ? "Bracket Scheduling: Create a New Bracket"
              : "Bracket Scheduling" // view existing / edit mode
            }
          </h2>
          <p>Select the division, optional pool, start date, and field(s) for the new bracket below.</p>

          <FormKit type="form" actions={false} onSubmit={() => generateBracket()}>
            <div style="display:inline-grid; grid-template-columns: max-content 20em; grid-gap: .5em;" class="mt-2">
              <div class="self-center font-bold text-sm">Season</div>
              {!bracketInfo.value || bracketInfo.value.mode === "new"
                ? <FormKit
                  type="select"
                  disabled={seasonOptions.value.disabled}
                  options={seasonOptions.value.options}
                  v-model={selectedSeasonUID.value}
                  onInput={(value: any) => {
                    if (selectedSeasonUID.value === value) {
                      return;
                    }
                    selectedSeasonUID.value = value
                    bracketInfo.value = null
                    maybeLoadPoolOptions()
                  }}
                />
                : <div>{seasonOptions.value.options.find(v => v.value === selectedSeasonUID.value)?.label}</div>
              }


              <div class="self-center font-bold text-sm">Program</div>
              {!bracketInfo.value || bracketInfo.value.mode === "new"
                ? <FormKit
                  type="select"
                  disabled={competitionOptions.value.disabled}
                  options={competitionOptions.value.options}
                  v-model={selectedCompetitionUID.value}
                  onInput={(value: any) => {
                    if (selectedCompetitionUID.value === value) {
                      return;
                    }
                    selectedCompetitionUID.value = value
                    bracketInfo.value = null
                    maybeLoadPoolOptions()
                  }}
                />
                : <div>{competitionOptions.value.options.find(v => v.value === selectedCompetitionUID.value)?.label}</div>
              }

              <div class="self-center font-bold text-sm">Division</div>
              {!bracketInfo.value || bracketInfo.value.mode === "new"
                ? <FormKit
                  type="select"
                  disabled={divisionOptions.value.disabled}
                  options={divisionOptions.value.options}
                  v-model={selectedDivID.value}
                  onInput={(value: any) => {
                    if (selectedDivID.value === value) {
                      return;
                    }
                    selectedDivID.value = value
                    bracketInfo.value = null
                    maybeLoadPoolOptions()
                  }}
                />
                : <div>{divisionOptions.value.options.find(v => v.value === selectedDivID.value)?.label}</div>
              }

              {!bracketInfo.value || bracketInfo.value.mode === "new"
                ? <>
                  <div class="self-center font-bold text-sm">Team Source</div>
                  <FormKit type="select" options={[{label: "Manual", value: TeamCountOrPool.teamCount}, {label: "Pool", value: TeamCountOrPool.pool}]} v-model={teamCountOrPool.value}/>
                  {teamCountOrPool.value === TeamCountOrPool.pool
                    ? <>
                      <div class="self-center font-bold text-sm">Pool</div>
                      <FormKit type="select"
                        key={teamCountOrPool.value}
                        disabled={poolOptions.options.disabled}
                        options={poolOptions.options.options}
                        v-model={poolSelection.selectedPoolUID}
                        data-test="poolUID"
                        onInput={(value: any) => {
                          if (poolSelection.selectedPoolUID === value) {
                            return
                          }
                          bracketInfo.value = null
                        }}
                      />
                    </>
                    : teamCountOrPool.value === TeamCountOrPool.teamCount
                    ? <>
                      <div class="self-center font-bold text-sm">TeamCount</div>
                      <FormKit key={teamCountOrPool.value} type="number" validation={[["required"], ["min", 2], ["max", 32]]} validationLabel="Team Count" v-model={teamCount.value}/>
                    </>
                    : exhaustiveCaseGuard(teamCountOrPool.value)
                  }
                </>
                : null
              }

              {(!bracketInfo.value || bracketInfo.value.mode === "new") && teamCountOrPool.value === TeamCountOrPool.pool && poolSelection.selectedPool
                ? <>
                  <div style="grid-column:-1/1;" class="max-h-64 overflow-y-auto">
                    <div style="display:grid; grid-template-columns: min-content auto; grid-gap:.5em;" class="rounded-md border bg-white px-1 py-1">
                      <div class="text-sm">Seed</div>
                      <div class="text-sm">Team</div>
                      {poolSelection.selectedPool
                        ?  poolSelection.selectedPool.teams.map(v => <>
                            <div class="text-sm">{v.seed}</div>
                            <div class="text-sm">{v.teamDesignation}</div>
                          </>
                        )
                        : null
                      }
                    </div>
                  </div>
                </>
                : null
              }



              {!bracketInfo.value || bracketInfo.value.mode === "new"
                ? <div style="grid-column:-1/1;">
                  <div class="my-2 text-sm">
                    <div>Start Date/Time of Games for this Bracket:</div>
                    <FormKit type="datetime-local" v-model={gameSearchStartDateTime.value} data-test="gameSearchStartDateTime"/>
                  </div>
                  <div class="my-2 text-sm">
                    <div>Days Between Rounds:</div>
                    <FormKit type="number" step="1" v-model={gameSearchDaysBetweenRounds.value} data-test="gameSearchDaysBetweenRounds" validation={[["required"], ["min", 0]]} validationLabel="Value"/>
                  </div>
                  <div class="my-2 text-sm bg-white rounded-md border p-1" style="--fk-border:none;">
                    <div style="--fk-padding-messages: none;">
                      <div>Playing Fields for this Bracket:</div>
                      <FormKitMessages node={fk_gameSearchFieldNode.value?.node}/>
                    </div>
                    <div class="overflow-y-auto max-h-64" data-test="gameSearchFieldUIDs">
                      <FormKit ref={fk_gameSearchFieldNode} type="checkbox" options={fieldOptions.value} v-model={gameSearchFieldUIDs.value} validation={[["required"]]} validationLabel="Fields"/>
                    </div>
                  </div>
                  <div style="margin-top:8px; margin-right:8px;">
                    {teamCountOrPool.value === TeamCountOrPool.pool && poolSelection.selectedPool
                      ? <div class="text-sm" style="max-width: var(--fk-max-width-input);">{bracketInfoMessage(poolSelection.selectedPool.teams.length)}</div>
                      : teamCountOrPool.value === TeamCountOrPool.teamCount && parseIntOr(teamCount.value, -1) >= 2
                      ? <div class="text-sm" style="max-width: var(--fk-max-width-input);">{bracketInfoMessage(parseIntOrFail(teamCount.value))}</div>
                      : null
                    }
                    <Btn2
                      type="submit"
                      class="px-2 py-1"
                      disabled={teamCountOrPool.value === TeamCountOrPool.pool && !poolSelection.selectedPool || teamCountOrPool.value === TeamCountOrPool.teamCount && parseIntOr(teamCount.value, -1) < 2}
                      data-test="generateBracket"
                    >
                      Generate Bracket
                    </Btn2>
                  </div>
                </div>
                : bracketInfo.value?.mode === "existing"
                ? <div>
                  <Btn2
                    class="px-2 py-1"
                    disabled={
                      bracketInfo.value.mode === "existing"
                        ? false // in "existing" mode, always show the button to jump into the "create new" state
                        : !poolSelection.selectedPool // in "new mode" this isn't visible anyway
                    }
                    onClick={() => {
                      bracketInfo.value = null
                    }}
                  >New bracket...</Btn2>
                </div>
                : null
              }
            </div>
          </FormKit>


          <div class="border-b my-4"></div>

          <FormKit type="form" actions={false} onSubmit={() => saveFreshBracket()} ref={fkRoot}>
            {bracketInfo.value
              ? <div class="text-xs my-2 il-new-stacking-context rounded-md bg-white shadow-md" style={`width: max-content; min-width:100%; box-sizing:content-box;`}>
                  <div class="bg-green-800 px-2 py-1 text-white rounded-t-md w-full relative z-1 flex items-center">
                    <BracketNameTitleBarElem
                      mode={bracketInfo.value.mode}
                      bracket={bracketInfo.value.bracket}
                      onOpenEditModal={() => {
                        editBracketNameModalController.open(requireNonNull(bracketInfo.value?.bracket))
                      }}
                    />
                    {User.isInleague
                      ? <div class="text-xs ml-auto">
                        <div class="flex items-center gap-2">
                          <input type="checkbox" class="rounded-sm outline-none" v-model={debugBracketRoundSlotIDs.value}/>
                          <span>il=1: Debug bracketRoundSlotIDs</span>
                        </div>
                      </div>
                      : null
                    }
                  </div>
                  <div>
                    {bracketInfo.value.mode === "new"
                      ? <div class="mt-2 px-2" style="position:relative; z-index:1;">
                        <div class="text-sm font-medium">Bracket Name</div>
                        <div style="display:grid; grid-template-columns: 20em auto; grid-gap: 0 .5em;">
                          <div class="p-1">
                            <FormKit type="text" v-model={bracketInfo.value.bracket.bracketName} class="w-full" validation={[["required"], FK_validation_strlen(0, STRLEN_MAX_BRACKET_NAME)]} validationLabel={"Bracket name"}/>
                          </div>
                          <div>
                            <Btn2 type="submit" class="px-2 py-1" data-test="saveFreshBracket">Save</Btn2>
                          </div>
                        </div>
                      </div>
                      : null
                    }
                    <FormKitMessages node={fkRoot.value?.node}/>
                  </div>
                  <BracketElement
                    bracket={bracketInfo.value.bracket}
                    roundSlotElemRefTracker={roundSlotElemRefTracker}
                    mode={bracketInfo.value.mode}
                    debugBracketRoundSlotIDs={debugBracketRoundSlotIDs.value}
                  >
                    {{
                      roundHeader: ({bracketRound, bracketRoundIdx}) => {
                        assertNonNull(bracketInfo.value)
                        return <div class="p-1">
                          <BracketRoundNameColHeaderElem
                            mode={bracketInfo.value.mode}
                            bracketRound={bracketRound}
                            onOpenEditModal={() => {
                              editBracketRoundNameModalController.open({
                                bracket: requireNonNull(bracketInfo.value?.bracket),
                                zi_roundIdx: bracketRoundIdx
                              })
                            }}
                          />
                        </div>
                      },
                      roundSlot: ({
                        bracketRound,
                        bracketRoundIdx,
                        bracketRounds,
                        bracketRoundSlot,
                        bracketRoundSlotIdx,
                        bracketRoundSlots
                      }) => {
                        const slotUiData = getUiDataOrFail(bracketRoundSlot)

                        assertNonNull(bracketInfo.value)

                        return <>
                          <div
                            class={`p-1 ${slotUiData.hadGameSchedulingConflict ? "bg-red-800 text-white" : "bg-green-800 text-white"}`}
                            {...slotUiData.hadGameSchedulingConflict ? {"data-conflict": ""} : {}}
                          >
                            <div class="text-xs">
                              {slotUiData.hadGameSchedulingConflict ? "Conflict | " : ""}
                              {bracketRound.bracketRoundName}, Slot {bracketRoundSlotIdx+1} of {slotsOnLevelN(bracketRoundIdx, bracketRounds.length)}
                            </div>
                            {debugBracketRoundSlotIDs.value
                              ? <div class="text-xs">bracketRoundSlotID='{bracketRoundSlot.bracketRoundSlotID}'</div>
                              : null
                            }
                          </div>
                          <EditBracketRoundSlot
                            key={bracketRoundSlot.bracketRoundSlotID}
                            class="p-1"
                            mode={bracketInfo.value.mode}
                            bracketRoundSlot={bracketRoundSlot}
                            bracketRoundNum={bracketRoundIdx + 1}
                            fieldOptions={fieldOptions.value}
                            selectedTeamIDsThisBracket={selectedTeamIDsThisBracket.value}
                            bracketRoundSlotUiData={slotUiData}
                            teamDragEventBus={teamDragEventBus}
                            allowDragAndDrop={true}
                            fkNodeTracker_teamInputs={bracketInfo.value.fkNodeTracker_teamInputs}
                            onDiscardChangesForExisting={() => discardChangesForExistingRoundSlot(bracketRoundSlot, slotUiData)}
                            onPersistChangesToExisting={async () => persistChangesToExistingRoundSlot(bracketRoundSlot, slotUiData)}
                            onShowEditTeamModal={() => editBracketRoundSlotTeamModalController.open({slot: bracketRoundSlot, uiData: slotUiData})}
                            onUpdateGameID={async gameID => updateRoundSlotGameID(bracketRoundSlot, slotUiData, gameID)}
                            onUpdateFieldUID={async fieldUID => updateRoundSlotFieldUID(bracketRoundSlot, slotUiData, fieldUID)}
                            onUpdateType={type => updateRoundSlotType(bracketRoundSlot, slotUiData, type)}
                            onUpdateTeamID={teamID => updateRoundSlotTeamID(bracketRoundSlot, slotUiData, teamID)}
                            onSwapTeams={args => doSwapTeams(args)}
                            onUpdateTeam={args => {
                              const uiData = slotUiData
                              if (uiData.form.type !== "game") {
                                return
                              }
                              const form = uiData.form[`${args.which}Team`]
                              if (form?.type !== "placeholder") {
                                return
                              }

                              if (form.value?.teamID === args.teamID) {
                                return
                              }

                              assertIs(uiData.teamOptions.underlying.status, "resolved")
                              const teamOptions =  uiData.teamOptions.underlying.data

                              const team = arrayFindOrFail(teamOptions.source, vs => vs.teamID === args.teamID)

                              form.value = {
                                ...team,
                                seed: form.seed
                              }
                            }}
                          />
                          <DefaultTinySoccerballBusyOverlay if={slotUiData.busy}/>
                        </>
                      }
                    } satisfies BracketElementSlots}
                  </BracketElement>
                </div>
              : null
            }
          </FormKit>
        </div>
      )
    }
  }
})

function defaultDivisionGameLengthMinutes(d: Division) : number {
  const hours = parseIntOr(d.slotHours, 0)
  const minutes = parseIntOr(d.slotMinutes, 0)
  const total = (hours * 60) + minutes
  return total === 0 ? 60 : total
}

function gameOptionsForBracketRoundSlot({
  mode,
  bracketID,
  bracketRoundSlot,
  bracketRoundSlotUiData,
  gamesForSelectedField,
  selectedGameIDsThisBracket
}: {
  mode: "new" | "existing",
  bracketID: Integerlike,
  bracketRoundSlot: BracketRoundSlot,
  bracketRoundSlotUiData: BracketRoundSlotUiData,
  // typically we loop this through, so the whole app has refs to the
  // same source list for some particular field. We could make copies though.
  // This should be considered deeply readonly.
  gamesForSelectedField: readonly GameForBracketBuilderView[],
  selectedGameIDsThisBracket: Set<Guid>
}) : {uiOptions: UiOptions, sourceGames: readonly GameForBracketBuilderView[]} {
  const currentPristineGame = bracketRoundSlot.game

  const result : UiOptions = {disabled: false, options: []}

  for (const game of gamesForSelectedField) {
    result.options.push(game2UiOption(game))
  }

  if (currentPristineGame
    && currentPristineGame.fieldUID === bracketRoundSlotUiData.selectedFieldUID
    && !result.options.find(v => v.value === currentPristineGame.gameID)
  ) {
    // is there any guarantee that "gamesForSelectedField" has the "currentPristineGame" in it?
    // It probably doesn't which is why we have had to add it ... this may be an issue,
    // if later on the "selectedGame" is this one, but the game itself is not in the
    // resulting "sourceGames" list.
    result.options.unshift(game2UiOption(currentPristineGame))
  }

  // we might want to only offer "create" in "new" mode?
  if (mode === "new") {
    result
      .options
      .unshift(
        // we once had TBD as an option, but we decided we don't want to create brackets that have missing games.
        // However, a game may be deleted elsewhere in the system, and produce this state.
        // {label: "TBD", value: ""},
        {label: "Create", value: CreateAGame}
      )
  }

  if (result.options.length === 0) {
    return {uiOptions: noAvailableOptions("No available games."), sourceGames: []}
  }
  else {
    // If we've conditionally pushed "currentPristineGame" into the resulting options list,
    // do we want to set sourceGames to a copy, like [currentPristineGame, ...gamesForSelectedField]
    return {uiOptions: result, sourceGames: gamesForSelectedField};
  }


  function game2UiOption(game: GameForBracketBuilderView) : UiOption {
    const bracketRoundSlot = game.bracketRoundSlot
    const onSomeOtherBracket = bracketRoundSlot !== null && bracketRoundSlot.bracketID /*weakeq*/ != bracketID

    if (onSomeOtherBracket) {
      return {
        label: `${dayjs(game.gameStart).format("MMM DD, YYYY @ h:mm a")}, #${game.gameNum}, assigned to bracket '${bracketRoundSlot.bracketName}'`,
        value: game.gameID,
        attrs: {disabled: true}
      }
    }
    else {
      const alreadySelected = selectedGameIDsThisBracket.has(game.gameID)
      const selectedByThisSlot = bracketRoundSlotUiData.form.type === "game" && bracketRoundSlotUiData.form.gameID === game.gameID
      const alreadyOnThisBracket = alreadySelected && !selectedByThisSlot

      if (alreadyOnThisBracket) {
        return {
          label: `${dayjs(game.gameStart).format("MMM DD, YYYY @ h:mm a")}, #${game.gameNum}, assigned elsewhere on this bracket`,
          value: game.gameID,
          attrs: {disabled: true}
        }
      }
      else {
        return {
          label: `${dayjs(game.gameStart).format("MMM DD, YYYY @ h:mm a")}, #${game.gameNum} (${game.fieldAbbrev})`,
          value: game.gameID,
        }
      }
    }
  }
}
