<!-- this component intentionally does nothing -->
<template>
<div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  setup() { return {}; }
});
</script>
