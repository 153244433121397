import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { User } from "src/store/User";
import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { ExtractPropTypes } from "vue";
import authService from "src/helpers/authService";

export const propsDef = {
  routeDetail: vReqT<RouteDetail>()
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

export const RouteName = {
  home: "SeasonManager.home",
  new: "SeasonManager.new",
  edit: "SeasonManager.edit"
} as const;

type RouteName = (typeof RouteName)[keyof typeof RouteName]

interface PropsBase {
  routeName: RouteName
}

type RouteDetail =
  | P_Home
  | P_New
  | P_Edit

interface P_Home extends PropsBase {
  routeName: "SeasonManager.home"
}

interface P_New extends PropsBase {
  routeName: "SeasonManager.new"
}

interface P_Edit extends PropsBase {
  routeName: "SeasonManager.edit"
  seasonUID: Guid
}

export function routeDetailToRouteLocation(v: RouteDetail) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteName.home:
      return {name: v.routeName}
    case RouteName.new:
      return {name: v.routeName}
    case RouteName.edit:
      return {name: v.routeName, params: {seasonUID: v.seasonUID}}
    default:
      exhaustiveCaseGuard(v);
  }
}

export function routeLocationToRouteProps(route: RouteLocationNormalized) : Props {
  const unsafe_routeName = route.name as any as RouteName
  switch (unsafe_routeName) {
    case RouteName.home:
      return {
        routeDetail: {
          routeName: unsafe_routeName
        }
      }
    case RouteName.new:
      return {
        routeDetail: {
          routeName: unsafe_routeName
        }
      }
    case RouteName.edit:
      return {
        routeDetail: {
          routeName: unsafe_routeName,
          seasonUID: route.params.seasonUID as Guid
        }
      }
    default:
      exhaustiveCaseGuard(unsafe_routeName);
  }
}

export function hasRoutePermission() : boolean {
  return authService(User.value.roles, "inLeague", "webmaster");
}
