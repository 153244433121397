import { defineComponent,ref, onMounted, computed, watch, PropType } from 'vue'
import type { UserData } from 'src/interfaces/Store/user'
import * as CompetitionUtils from "src/modules/CompetitionUtils"
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { UiOption } from 'src/helpers/utils';
import { FormKit } from '@formkit/vue';
import { Competition, Guid } from 'src/interfaces/InleagueApiV1';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: false
    },
    /**
     * if true, only display those competitions for which the current user has superAdmin permission
     */
    requirePerCompetitionSuperAdmin: {
      type: Boolean,
      default: false,
    },
    /**
     * if true, include competitions which have been disabled completely (only done on Competition Admin to re-enable them)
     */
    includeDisabled: {
      type: Boolean,
      default: false
    },
    /**
     * if provided, will use this as the list of comps from which to build options,
     * otherwise, this component will reach out to the store.
     * It is non-sensical to provide both "includeDisabled" and "competitions", becuase if the caller is providing providing competitions,
     * they are responsible for filtering/not-filtering disabled comps.
     * Really, all callers should provide this
     */
    competitions: {
      required: false,
      type: null as any as PropType<readonly Competition[]>,
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const selectedCompetitionUID = computed<Guid>({
      get() { return props.modelValue as Guid },
      set(v: string) { emit("update:modelValue", v); }
    });

    const competitionOptions = ref<UiOption[]>([])

    watch(() => competitionOptions.value, () => {
      // when available options changes, IF there is a current selection,
      // check to see if the current selection remains valid. If not, try to select
      // "the first" valid option, or if that does not exist, select nothing.
      if (selectedCompetitionUID.value) {
        if (!competitionOptions.value.find(v => v.value === selectedCompetitionUID.value)) {
          // set to first truthy value or nothing (i.e. skip the initial nil option, if there is one)
          selectedCompetitionUID.value = competitionOptions.value.find(opt => !!opt.value)?.value || "";
        }
      }
    }, {deep: true})

    const compsToOptions = (comps: readonly Competition[]) : UiOption[] => {
      const filtered = props.requirePerCompetitionSuperAdmin
        ? CompetitionUtils.filterCompetitionsListingBySuperAdmin(User.value.userData as UserData, comps)
        : comps;

      return filtered.map(v => {
        return {
          label: v.competition,
          value: v.competitionUID
        }
      })
    }

    watch(() => props.competitions, () => {
      competitionOptions.value = compsToOptions(props.competitions ?? [])
    })

    onMounted(async () => {
      const comps = props.competitions ?? (await getCompetitionsOrFail({ includeDisabled : props.includeDisabled })).value;
      competitionOptions.value = compsToOptions(comps);
    })

    return () => {
      if (competitionOptions.value.length === 0) {
        return null;
      }
      return <FormKit
        v-model={selectedCompetitionUID.value}
        options={competitionOptions.value}
        type='select'
        label='Program'
        data-test='competitions-rootlike-and-select'
        inner-class='bg-white'
        wrapper-class='mt-6'
        {...{placeholder:'Select a Program'}}
      />
    }
  },
})
