<template lang="pug">
div(v-if="ready")
  div(class='sm:hidden')
    label.sr-only(for='tabs') Select a Category
    select#tabs.block.w-full.pl-3.pr-10.py-2.text-base.border-gray-300.rounded-md(name='tabs' class='focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm' v-model="selectedTab")
      option(v-for='(tab, key) in tabs' :key='key' :value='key') {{ tab }}
  .hidden(class='sm:block')
    .border-b.border-gray-200
      nav.-mb-px.flex.space-x-8(aria-label='Tabs')
        div(
          v-for='(tab, key) in tabs',
          :key='key',
          @click='changeTab(key)',
          :class='["cursor-pointer", key === selectedTab ? "border-green-500 text-green-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300", "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base"]',
          :aria-current='key === selectedTab ? "page" : undefined'
          :data-test="key"
        )
          | {{ tab }}
  template(v-if="selectedTab === TabID_t['registration-data']")
    .py-4.border-gray-200.min-w-full(
      v-if='Object.keys(playerDetails).length > 0'
    )
      div(class="w-full flex items-center justify-center my-2")
        t-btn(
          v-if='offerSubmitButton'
          type='button'
          @click='updateRegistration'
          label='Save Record'
        )
      FamilyData(
        @updateCoreQuestionAnswers='val => updateCoreQuestionAnswers(val)',
        @updateCustomQuestions='val => updateCustomQuestions(val)',
        @player-data-invalidated="refreshPlayerData()"
        v-model:playerDetails='playerDetails',
        :hasSomeSuperEditPermission='hasSomeSuperEditPermission'
        :granularEditPermissions='granularEditPermissions'
        :coreQuestionEditPermissionMap="coreQuestionEditPermissionMap"
        :lockedDueToSomeSeasonCompetitionLockDate="lockedDueToSomeSeasonCompetitionLockDate"
        :coreQuestions="coreQuestions"
      )
      Transactions.mt-4(
        v-model:playerDetails='playerDetails',
        :canEdit='hasSomeSuperEditPermission',
        v-if='!!playerDetails.registrationID && (authService(userData.roles, "PlayerAdmin", "Registrar", "Webmaster") || isChildRelated({childID: playerDetails.childID, childList: userData.belongingChildrenIDs}))'
      )
    t-btn(
      v-if='offerSubmitButton'
      type="button"
      @click='updateRegistration',
      label='Save Record'
    )
  template(v-else-if="selectedTab === TabID_t['player-merge']")
    MergePlayers(
      class="mt-2"
      v-if="Object.keys(playerDetails).length > 0"
      v-bind="mergePlayersBindings.props"
      v-on="mergePlayersBindings.handlers"
    )
  template(v-else="selectedTab === TabID_t['ratings-history']")
    //- on platforms that don't support window.open (ios safari), there needs to be a link
    //- we'll auto-click this on mount, which will happen on every change to the tab value
    div.p-2
      a.underline.text-blue-700.cursor-pointer(
        :href="legacyUrlFromMagicCurrentTabIndexOffset" target="_blank"
        :key="selectedTab"
        v-onMountClickIt
      ) To legacy {{ tabs[selectedTab] }}

</template>

<script lang='ts'>
import {
  defineComponent,
  ref,
  onMounted,
  Ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
} from 'vue'
import FamilyData from 'src/components/PlayerEditor/FamilyData/FamilyData.vue'
import Transactions from 'src/components/PlayerEditor/TransactionData/Transactions.vue'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { useRoute } from 'vue-router'

import { UserData } from 'src/interfaces/Store/user'
import authService from 'src/helpers/authService'

import familyAuth from 'src/composables/familyAuth'
import * as ilapi from 'src/composables/InleagueApiV1'
import type { __fixme__CoreQuestionAnswerRecord } from 'src/interfaces/InleagueApiV1'
import { CoreQuestion, isCfNull } from "src/interfaces/InleagueApiV1"
import { copyViaJsonRoundTrip, useIziToast, vueDirective_onMountClickIt, __FIXME__collapseLeagueCommentPropertyToString } from 'src/helpers/utils';
import { assertNonNull } from 'src/helpers/utils';
import { datePickerFormat } from "src/helpers/formatDate"

import dayjs from 'dayjs'
import { ExpandedChild, ExpandedCompetitionRegistration, ExpandedRegistration, getExpandedRegistration, TabID_t } from './PlayerEditor.ilx'
import { MergePlayers, Props as MergePlayers_Props, Emits as MergePlayers_Emits } from "./MergePlayers"
import { buildLegacyLink } from 'src/boot/auth'

import { answerMappingToApiV1SubmittableAnswerMapping, ClientSideRegistrationQuestionAnswerMap } from 'src/composables/customQuestions'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  components: {
    FamilyData,
    Transactions,
    MergePlayers,
  },
  directives: {
    onMountClickIt: vueDirective_onMountClickIt
  },
  props: {
    playerID: {
      required: true,
      type: String
    },
    /**
     * optional
     *   - we can mount this component targeting a registration, in which case we will display {player, parent, registration} data
     *     - in this case we retrieve data from an api /registration get endpoint
     *   - we can mount this component targeting only a child, in which case we will display {player, parent} data
     *     - in this case we retrieve data from an api /child get endpoint
     */
    registrationID: {
      required: false,
      type: String
    }
  },
  setup(props) {
    const iziToast = useIziToast()

    /** map (questionName -> "question is editable based on current user perms?") */
    const coreQuestionEditPermissionMap = ref<
      {[questionName: string]: boolean }
    >({});

    const selectedTab = ref<TabID_t>(TabID_t['registration-data'])

    const tabs = ref<{[tabID in TabID_t]?: /*ui label*/ string}>({
      'registration-data': 'Registration Data',
    })
    const localInstance = getCurrentInstance()

    // this object is either an ExpandedChild, or is a merged (ExpandedChild & Registration)
    const playerDetails = ref<ExpandedChild | (ExpandedChild & ExpandedRegistration)>(/*definitely assigned in onMounted*/ null as any)

    /**
     * Map of (questionID -> answer)
     * Null means "there isn't an answer and there never was an answer, ever"; otherwise, there's some kind of answer present.
     * There's not currently a way to indicate the transition from "had an answer to no longer has an answer"
     */
    const customQuestions = ref<ClientSideRegistrationQuestionAnswerMap>({})
    const { isChildRelated } = familyAuth()

    const route = useRoute()


    const userData = computed(() => {
      return User.value.userData
    })

    const changeTab = (tabID: TabID_t) => {
      selectedTab.value = tabID
    }

    const hasSomeSuperEditPermission = computed(() => {
      return authService(
        User.value.roles,
        'playerAdmin',
        'registrar',
        'webmaster'
      )
    });

    const k_registrationData = "registrationData";
    const k_playerData = "playerData";

    const granularEditPermissions = computed<Record<string, boolean>>(() => {
      if (!playerDetails.value) {
        return {} as Record<string, boolean>
      }

      const isParentOfPlayer : boolean = isChildRelated({childID: props.playerID, childList: User.userData?.belongingChildrenIDs ?? []});
      const managesSomeRelevantDivision : boolean = !!maybeGetPlayerDetailsAsExpandedReg(playerDetails.value)
        ?.competitions
        .find(compReg => !!User.userData?.divisionsMemento.find(div => compReg.divID === div.divID));

      return {
        [`${k_registrationData}/submitterComments`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_registrationData}/leagueComment`]: authService(User.value.roles, "registrar", "webmaster") || managesSomeRelevantDivision,
        [`${k_playerData}/gender`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_playerData}/nickName`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_playerData}/firstName`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_playerData}/lastName`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_playerData}/birthDate`]: isParentOfPlayer || hasSomeSuperEditPermission.value,
        [`${k_playerData}/permLeagueComment`]: authService(User.value.roles, "registrar", "webmaster"),
        [`${k_playerData}/blockFromRegistration`]: authService(User.value.roles, "registrar", "webmaster"),
        ["customQuestions/*"]: hasSomeSuperEditPermission.value,
      }
    })

    const canEditSomeRegistrationField = computed(() => {
      for (const [field, canEdit] of (Object.entries(granularEditPermissions.value) satisfies Iterable<[string, boolean]>)) {
        if (field.startsWith(k_registrationData + "/") && canEdit) {
          return true;
        }
      }
    })

    const canEditSomePlayerDataField = computed(() => {
      for (const [field, canEdit] of (Object.entries(granularEditPermissions.value) satisfies Iterable<[string, boolean]>)) {
        if (field.startsWith(k_playerData + "/") && canEdit) {
          return true;
        }
      }
    })

    const offerSubmitButton = computed(() => {
      return canEditSomeRegistrationField.value || canEditSomePlayerDataField.value;
    })

    /**
     * Effectively constant for one mount lifecycle, but computed in onMounted()
     * Should only be assigned exactly once in onMounted
     */
    const lockedDueToSomeSeasonCompetitionLockDate = ref(false);

    /**
     * warn: the order of these is dependent on their permission-based layout on the legacy platform,
     * and we later compute redirect-to-legacy URLs with a "tab index" param based on the order of
     * insertion here (where maintaining isn't guaranteed ecmascript behavior, though most implementations return Object.keys in insertion order).
     *
     * Hence, blocks here should not be reordered.
     */
    const addPermissionBasedTabs = () => {
      if (
        authService(
          User.value.roles,
          'webmaster',
          'playerAdmin',
          'registrar',
          'dataReader',
          'scholarshipAdmin'
        )
      ) {
        tabs.value['ratings-history'] = 'Player History'
        tabs.value['player-loan'] = 'Player Loans'
      }
      if (
        authService(
          User.value.roles,
          'webmaster',
          'registrar',
          'scholarshipAdmin'
        )
      ) {
        tabs.value['scholarship-data'] = 'Scholarship Data'
      }
      if (
        authService(
          User.value.roles,
          'registrar',
          'webmaster',
          'inLeague'
        )
      ) {
        tabs.value['player-logs'] = 'System Log'
      }
      if (authService(User.value.roles, "registrar")) {
        tabs.value['player-merge'] = 'Player Merge';
      }
    }

    /**
     * fixme: unify resulting object shapes of `getRegistrationDetails` and `getPlayerDetails`
     * or rather, clarify that this component can be mounted **maybe** focused on a registration;
     * i.e. `props.registrationID === undefined`, so the shape of `playerDetails` can differ (in some way we should be explicit about)
     */
    const getRegistrationDetails = async () => {
      assertNonNull(props.registrationID, "this method should only be called when props.registrationID is truthy");

      // n.b. no local try/catch -- this whole function (subsequent assignments) must succeed or the caller must know
      const registration = await getExpandedRegistration(axiosInstance, route.params.registrationID as string);

      // merge the returned registration with its own player property,
      // so we end up with an object that is (Child & Registration)
      playerDetails.value = {
        ...registration as any,
        ...registration.player as any,
        // can we reverse the spread order? player stomps the previous value
        registrationID: registration.registrationID
      }

      mungePlayerDetailsResponseInPlace(playerDetails.value);
    }

    /**
     * fixme:
     * @see getRegistrationDetails
     */
    const getPlayerDetails = async () : Promise<void> => {
      // n.b. no local try/catch -- this whole function must succeed or the caller must know
      playerDetails.value = await ilapi.getPlayer(
        axiosInstance, {
          childID: route.params.playerID as string,
          expand: [
            "parent1ID",
            "parent2ID",
            "mostRecentRegistration",
            "permLeagueComment",
            "contactPhone",
            "contactName",
            "contact2Phone",
            "contact2Name"
          ]
      }) as ExpandedChild

      mungePlayerDetailsResponseInPlace(playerDetails.value);
    }

    const updateRegistration = async () : Promise<void> => {
      try {
        // we shouldn't be here unless we can do at least one thing
        if (canEditSomeRegistrationField.value) {
          await updateSeasonalRegistration()
        }

        if (canEditSomePlayerDataField.value) {
          await updatePlayerRecord()
        }

        iziToast.success({message: `Your changes were successfully saved.`})
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }

      return;

      /**
       * Update a top-level registration
       * We do not have focus on any particular competition registration here
       * This component also may have been mounted not focused on a particular top-level registration, either
       * In which case, there's no registration to update
       *
       * We must not perform this api request if the user is non-super (registrar, webmaster) and the
       * registration profile is locked (see CompetitionSeason.profileLockDate)
       *
       * @throws AxiosError
       */
      async function updateSeasonalRegistration() : Promise<void> {
        if (!playerDetails.value.registrationID) {
          return;
        }

        if (lockedDueToSomeSeasonCompetitionLockDate.value && !hasSomeSuperEditPermission) {
          // the form elements should have prevented the user from making edits in the UI
          // It makes no sense to submit "no changes", and it would 400 due to a similar check on the server anyway
          return;
        }

        await ilapi.updateRegistration_targetingSeasonOnly(
          axiosInstance,
          playerDetails.value.registrationID,
          playerDetails.value,
          answerMappingToApiV1SubmittableAnswerMapping(
            customQuestions.value,
            // cast assumed safe due to earlier truthiness check against `registrationID`
            // TODO: use checkably typed variant
            (playerDetails.value as (ExpandedChild & ExpandedRegistration)).registrationAnswers
          )
        )
      }

      /**
       * @throws AxiosError
       */
      async function updatePlayerRecord() : Promise<void> {
        if (playerDetails.value.hasSomeCompleteProgramRegistration.seasonUID !== "across-all") {
          throw "bad runtime type, seasonUID here should be 'across-all'"
        }

        const PLAYER_IS_STACKSPORTS_LOCKED = !!playerDetails.value.stackRecordKey;
        const PLAYER_IS_EFFECTIVELY_HAS_SOME_REGISTRATION_LOCKED = playerDetails.value.hasSomeCompleteProgramRegistration.value && !authService(User.value.roles, "registrar");

        let argpack1: ilapi.UpdateChildArgPack1;

        if (PLAYER_IS_STACKSPORTS_LOCKED) {
          argpack1 = {
            playerNickName: playerDetails.value.playerNickName,
            playerGender: playerDetails.value.playerGender,
            permLeagueComment: __FIXME__collapseLeagueCommentPropertyToString(playerDetails.value.permLeagueComment),
          }
        }
        else {
          argpack1 = {
            playerNickName: playerDetails.value.playerNickName,
            playerGender: playerDetails.value.playerGender,
            permLeagueComment: __FIXME__collapseLeagueCommentPropertyToString(playerDetails.value.permLeagueComment),
          }

          if (!PLAYER_IS_EFFECTIVELY_HAS_SOME_REGISTRATION_LOCKED) {
            // additional fields are mutable based on player registration history and current user permissions
            // the UI should also prevent such updates
            argpack1 = {
              ...argpack1,
              playerFirstName: playerDetails.value.playerFirstName,
              playerLastName: playerDetails.value.playerLastName,
              playerBirthDate: playerDetails.value.playerBirthDate,
            }
          }
        }

        const argpack2: ilapi.UpdateChildArgPack2 = {
          // The form inputs may have transformed these values into strings (well, do they even arrive as numbers?)
          // Do an "explicit loose equality with 1" check
          birthCertificate: (() => {
            const value: any = playerDetails.value.birthCertificate;
            return value === 1 || value === "1";
          })(),
          blockFromRegistration: (() => {
            const value: any = playerDetails.value.blockFromRegistration;
            return value === 1 || value === "1";
          })()
        }

        await ilapi.updateChild(axiosInstance, playerDetails.value.childID, argpack1, argpack2);
      }
    }

    const updateCoreQuestionAnswers = (
      val: __fixme__CoreQuestionAnswerRecord
    ) => {
      Object.assign(playerDetails.value, val)
    }

    const updateCustomQuestions = (questions: { [key: string]: string }) => {
      customQuestions.value = questions
    }

    const clientUrl = computed(() => {
      return Client.value.instanceConfig.appdomain
    })

    const toLegacyApp = (legacyUrl: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(
        `https://${clientUrl.value}/${legacyUrl}`
      )
    }

    const legacyUrlFromMagicCurrentTabIndexOffset = computed(() => {
      switch (selectedTab.value) {
        case 'registration-data':
          // fallthrough
        case 'player-merge':
          return "";
        default: {
          // legacy redirects
          const tabKeys = Object.keys(tabs.value)
          const tabIndex = tabKeys.indexOf(selectedTab.value);
          const urlTarget = `https://${Client.value.instanceConfig.appdomain}/registration/new/registration-edit.cfm?registrationID=${route.params.registrationID}#tab-${tabIndex + 2}`
          return buildLegacyLink(Client.value.instanceConfig.appdomain, urlTarget, "");
        }
      }
    })

    const coreQuestions = ref<CoreQuestion[]>([])
    const ready = ref(false);

    onMounted(async () => {
      try {
        addPermissionBasedTabs()

        await refreshPlayerData();

        // Is this necessary in the player-only case
        // (i.e. we did not mount targeting a registration, and (typeof playerDetails.value) represents only a Child)
        // Probably this is passed into child components who themselves v-if="playerDetails.registrationID",
        // which should be false in the player-only case
        // (or maybe we can be defer this to some child component that is registration-focused?)
        coreQuestionEditPermissionMap.value = await (async () => {
          coreQuestions.value = await ilapi.getCoreQuestions(axiosInstance, { asPreview: true })
          const result: Record<string, boolean> = {};
          const userIsParentOfTargetChild = isChildRelated({childID: playerDetails.value.childID, childList: (User.value.userData as UserData).belongingChildrenIDs})

          for (const coreQuestion of coreQuestions.value) {
            result[coreQuestion.name] = hasSomeSuperEditPermission.value || (!lockedDueToSomeSeasonCompetitionLockDate.value && userIsParentOfTargetChild && coreQuestion.update);
          }

          return result;
        })();

        ready.value = true; // n.b. not set to true if we've jumped to catch block
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    })

    /**
     * loads player data as per route configuration (i.e. are we focused on a registration or just a player)
     * mutates component state, including:
     *   - playerDetails
     *   - lockedDueToSomeSeasonCompetitionLockDate
     */
    const refreshPlayerData = async () : Promise<void> => {
      if (route.params.registrationID) {
        await getRegistrationDetails()
        // In this case, playerDetails now represents a (Child & ExpandedRegistration)
        // This type assertion is therefore safe here (fixme: not desireable to have to do so though)
        const definite_expandedRegistration = playerDetails.value as ExpandedRegistration;
        lockedDueToSomeSeasonCompetitionLockDate.value = isOnOrAfterAnySeasonalProfileLockDate(
          definite_expandedRegistration.competitions
        );
      } else {
        await getPlayerDetails()
        lockedDueToSomeSeasonCompetitionLockDate.value = false;
      }
    }

    const mergePlayersBindings = (() => {
      const props : MergePlayers_Props = reactive({
        keepPlayer: computed(() => playerDetails.value)
      })
      const handlers : MergePlayers_Emits = {
        doMerge: async cbRunner => {
          cbRunner(async ({keepPlayer, mergePlayer}) => {
            try {
              await ilapi.mergePlayers(
                axiosInstance, {
                  keepID: keepPlayer.childID,
                  dupID: mergePlayer.childID,
                  dryRun: false
                }
              );
              await refreshPlayerData();
              iziToast.success({message: `Merged ${mergePlayer.playerFirstName} ${mergePlayer.playerLastName} into ${keepPlayer.playerFirstName} ${keepPlayer.playerLastName}.`})
              return true;
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err);
              return false;
            }
          })
        }
      };

      return {
        props,
        handlers,
      }
    })();

    return {
      ready,
      tabs,
      TabID_t,
      changeTab,
      selectedTab,
      playerDetails,
      hasSomeSuperEditPermission,
      granularEditPermissions,
      updateCoreQuestionAnswers,
      updateCustomQuestions,
      customQuestions,
      updateRegistration,
      authService,
      userData,
      isChildRelated,
      coreQuestionEditPermissionMap,
      lockedDueToSomeSeasonCompetitionLockDate,
      refreshPlayerData,
      mergePlayersBindings,
      legacyUrlFromMagicCurrentTabIndexOffset,
      offerSubmitButton,
      coreQuestions,
    }
  },
})

/**
 * answers: Is "today" on-or-after any of the listed competition registration's profile lock date, where that compreg is also active?
 * "profile lock date" is determined by a CompetitionSeason's `profileLockDate` property,
 * and one season may have many competitions, so there may be many competing profile lock dates.
 * We check all that are provided here, effectively scanning for "are we on-or-after the chronologically least lock date".
 * Note that all the competition registrations here are expected to be for the same season, because a toplevel registration targets a season
 */
function isOnOrAfterAnySeasonalProfileLockDate(compRegs: ExpandedCompetitionRegistration[]) : boolean {
  const today = dayjs();
  for (const compReg of compRegs) {
    if (compReg.canceled || !compReg.paid) {
      // we don't consider non-active competition registrations
      continue;
    }

    const raw_profileLockDate = compReg.competitionSeason.profileLockDate;

    if (isCfNull(raw_profileLockDate)) {
      continue;
    }

    const profileLockDate = dayjs(raw_profileLockDate);

    if (!profileLockDate.isValid()) {
      // not much we can do to recover here; this is unexpected though
      continue;
    }

    if (today.isSame(profileLockDate, "day") || today.isAfter(profileLockDate, "day")) {
      return true;
    }
  }
  return false;
}

/**
 * The API requests made on this page need some post processing before pushing into vue templates
 * This mutates the passed-in object
 */
function mungePlayerDetailsResponseInPlace(playerDetails: ExpandedChild | (ExpandedChild & ExpandedRegistration)) : void {
  playerDetails.playerBirthDate = datePickerFormat(playerDetails.playerBirthDate);
  playerDetails.permLeagueComment = __FIXME__collapseLeagueCommentPropertyToString(playerDetails.permLeagueComment);

  const reglike = maybeGetPlayerDetailsAsExpandedReg(playerDetails);
  if (reglike) {
    reglike.leagueComment = __FIXME__collapseLeagueCommentPropertyToString(reglike.leagueComment)
  }
}

function maybeGetPlayerDetailsAsExpandedReg(v: ExpandedChild | (ExpandedChild & ExpandedRegistration)) {
  if (("competitions" satisfies keyof ExpandedRegistration) in v) {
    return v
  }

  return null;
}
</script>

<style>
  .custom {
    max-width: 350px;
    background-color: white;
    border-radius: 6%;
  }
</style>
