
import { defineComponent, ref, PropType, computed, Fragment, watch } from 'vue'

import /**/ C_Portlet from 'src/components/Portlets/Portlet.vue'
import * as M_Portlet from "src/components/Portlets/Portlet.ilx"
import { RouterLink } from "vue-router";


import * as VolunteerRegistrationFlow from 'src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route'

import * as iltypes from 'src/interfaces/InleagueApiV1'
import { TailwindBreakpoint, copyViaJsonRoundTrip, useWindowSize, vReqT } from 'src/helpers/utils';
import { Client } from 'src/store/Client';

// shim SFC<->JSX
declare const Portlet : M_Portlet.jsx_sfc_shim__Portlet;

function ActionResponseCell( props: { actionStepResponse: iltypes.VolunteerStatusPortlet_actionStepResponse}) : JSX.Element {
   if ( !props.actionStepResponse.actionIndicator ) {
    return  <span id={props.actionStepResponse.statusItemID} class="font-bold text-green-900">{props.actionStepResponse.status}</span>;
   }
   else if ( props.actionStepResponse.actionURL.includes("https" ) ) {
    return <a id={props.actionStepResponse.statusItemID} class="underline text-orange-700" target="_blank" href={props.actionStepResponse.actionURL}>{ props.actionStepResponse.status }</a>;
   }
   else {
    return <RouterLink {...{target: "_blank" }} to={{name: props.actionStepResponse.actionURL}} class="underline text-orange-700">{ props.actionStepResponse.status }</RouterLink>
   }
}

//
// we've gotten some cryptic errors about the portlet not being the type we say it is, but
// there's no obvious reason why it **wouldn't** be. In particular, props.portlet.volunteerRoleTitles.join( ", ") fails with
// "TypeError: e.portlet.volunteerRoleTitles.join is not a function", which is extremely unexpected. Something making a bad write into the store?
//
let __debughunt__lastKnownPortlet : any = null;

export default defineComponent({
  name: 'VolunteerStatus',
  components: {
    Portlet: C_Portlet
  },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
    portlet: {
      required: true,
      type: Object as PropType<iltypes.VolunteerStatusPortlet>
    },
  },
  // this only to aid hunting down a weird failure. can be removed when that's complete.
  errorCaptured(err, instance, info) {
    if (typeof err === "object" && err !== null) {
      (err as any).maybeBadPortlet = __debughunt__lastKnownPortlet;
    }
    else {
      throw err; // ??? this shouldn't ever get hit
    }
    return undefined; // keep bubbling the error
  },
  setup(props) {
    // debug hunt stuff
    watch(() => props.portlet, () => {
      __debughunt__lastKnownPortlet = copyViaJsonRoundTrip(props.portlet);
    }, {deep: true, immediate: true})

    const show = ref(props.showContent)

    const preparedIcon = computed(() => {
      return props.portlet?.fontAwesome.replace('-o', '-alt')
    })

    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    const portletLabel = "Volunteer Status: " + ( props.portlet.isAnyActionIndicated ? 'Action Required' : 'Complete!');

    const windowSize = useWindowSize();

    return () => (
      <Portlet
        label={portletLabel}
        prefix="fas"
        icon={preparedIcon.value}
        onExpand={(expand) => toggleExpand(expand) }>
          {
            windowSize.width <= TailwindBreakpoint.lg
              ? <VolStatusSmall portlet={props.portlet}></VolStatusSmall>
              : <VolStatusBig portlet={props.portlet}></VolStatusBig>
          }
      </Portlet>
    )
  }
})

const VolStatusSmall = defineComponent({
  props: {
    portlet: vReqT<iltypes.VolunteerStatusPortlet>(),
  },
  setup(props) {
    return () => (
      <div class="flex flex-col" data-test="VolunteerStatus">
        <div class="max-h-96 overflow-y-auto">
          <div data-test="VolunteerStatusRoles" class="rounded-md border m-2 p-1">
            <RouterLink {...{target:"_blank"}} to={{name: VolunteerRegistrationFlow.FlowStepRouteName.season}} class="font-semibold underline text-blue-600/100">Volunteer Roles:</RouterLink>
            <span> </span>
            {
              props.portlet.volunteerRoleTitles.join( ", ")
            }
          </div>
          <div data-test="VolunteerStatusTable">
            {
              props.portlet.actionStepResponses.map( actionStepResponse => (
                <div class="rounded-md border m-2 p-1" data-test="each-action">
                  <div>
                    <div class="text-xs font-medium">Requirement</div>
                    <div class="ml-1">
                      <ActionStatusCell actionStepResponse={ actionStepResponse } />
                    </div>
                  </div>
                  <div>
                    <div class="text-xs font-medium">Status</div>
                    <div class="ml-1">
                      <ActionResponseCell actionStepResponse={ actionStepResponse } />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
})

const VolStatusBig = defineComponent({
  props: {
    portlet: vReqT<iltypes.VolunteerStatusPortlet>(),
  },
  setup(props) {
    return () => (
      <div class="flex flex-col" data-test="VolunteerStatus">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="border-b border-gray-200 sm:rounded-lg">
              <div data-test="VolunteerStatusRoles">
                <RouterLink {...{target:"_blank"}} to={{name: VolunteerRegistrationFlow.FlowStepRouteName.season}} class="ml-1 font-semibold pl-3 pr-2 underline text-blue-600/100">Volunteer Roles:</RouterLink>
                {
                  props.portlet.volunteerRoleTitles.join( ", ")
                }

              </div>
              <table class="min-w-full divide-y divide-gray-200" data-test="VolunteerStatusTable">
                <tbody class="bg-white divide-y divide-gray-200 text-sm">
                  <tr>
                    <th class="px-6 py-4 text-left">Requirement</th>
                    <th class="px-6 py-4 text-center">Status</th>
                  </tr>
                  {
                    props.portlet.actionStepResponses.map( actionStepResponse => (
                      <tr data-test="each-action">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <ActionStatusCell actionStepResponse={ actionStepResponse } />
                        </td>
                        <td class="px-6 py-4">
                          <ActionResponseCell actionStepResponse={ actionStepResponse } />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

function ActionStatusCell( props: { actionStepResponse: iltypes.VolunteerStatusPortlet_actionStepResponse}) : JSX.Element {
  const hasCoachURL = Client.value.instanceConfig.coachweburl && Client.value.instanceConfig.coachweburl.length > 0;
  const hasRefURL = Client.value.instanceConfig.refweburl && Client.value.instanceConfig.refweburl.length > 0;

  if ( hasCoachURL && props.actionStepResponse?.category == 'Coach' ) {
    return <a id={props.actionStepResponse.statusItemID} class="underline text-blue-600" href={Client.value.instanceConfig.coachweburl} target="_blank">{props.actionStepResponse.requirement}</a>
  }
  else if ( hasRefURL && props.actionStepResponse?.category == 'Referee') {
    return <a id={props.actionStepResponse.statusItemID} class="underline text-blue-600" href={Client.value.instanceConfig.refweburl} target="_blank">{props.actionStepResponse.requirement}</a>
  }
  else {
    return <span id={props.actionStepResponse.statusItemID}>{props.actionStepResponse.requirement}</span>
  }
}
