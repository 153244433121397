<!--
  This component is intended to {mount, be-routed-to} only under the following conditions:
    - the user has no existing stackRecordKey (meaning, we are not barred from editing their name or date-of-birth)

  If the user has an existing stackRecordKey there is no reason to be here and there is a bug in the caller
-->
<template lang="pug">
h2 AYSO VOLUNTEER REGISTRATION - {{ formData.firstName }} {{ formData.lastName}}
div.mb-2 AYSO Volunteers are required to undergo background checks every year.
div.mb-2
  |  Please confirm your full, legal first name, last name, and date of birth.
  |  Volunteer registration and the associated background check rely on these values and they cannot be changed once the process is underway without assistance from your league registrar.
FormKit(type='form', v-model='formData', submit-label="OK", @submit="handleConfirmed")
  .shadow.border-b.border-gray-200.mb-4(class='sm:rounded-lg')
    table.min-w-full
      tbody.bg-white
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Name:
          td.px-6.py-4.text-sm.text-gray-500.flex.justify-between
            FormKit(
              label='First Name',
              validation='required',
              name='firstName',
              value='firstName',
              validation-visibility="blur"
            )
            .mx-2
              FormKit(label='Middle Name', name='middleName', value='middleName')
            FormKit(label='Last Name', validation='required', name='lastName', validation-visibility="blur")
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | Gender:
          td.px-6.py-4.text-sm.text-gray-500
            FormKit(
              validation='required',
              name='gender',
              type='radio',
              :options='{ M: "Male", F: "Female" }',
              label='Gender',
              value='gender',
              validation-visibility="blur"
            )
        tr.border-b-2.border-gray-200
          td.px-6.py-4.text-sm.font-medium.text-gray-900(class='w-1/6')
            | DOB: (mm/dd/yyyy)
          td.px-6.py-4.text-sm.text-gray-500
            FormKit(validation='required', name='dob', type='date', validation-visibility="blur")
</template>

<script lang="ts">
  import { defineComponent, ref, watch, onMounted } from 'vue'

  import { dayJSDate, datePickerFormat } from 'src/helpers/formatDate'
  import { axiosInstance } from 'src/boot/axios'
  import { HasNoStackKeyNeedsNameAndDobEdit } from './R_VolunteerRegistrationFlow.route'

  export default defineComponent({
    props: {
      volunteerID: {
        type: String,
        required: true,
      },
      seasonUID: {
        type: String,
        required: true,
      },
    },
    inheritAttrs: false,
    emits: ['confirmed' /* HasNoStackKeyNeedsNameAndDobEdit */],
    setup(props, context) {
      const freshFormData : () => HasNoStackKeyNeedsNameAndDobEdit = () => {
        return {
          firstName: '',
          middleName: '',
          lastName: '',
          gender: '',
          dob: '',
        }
      }

      const formData = ref<HasNoStackKeyNeedsNameAndDobEdit>(freshFormData())

      const verifyUserInfo = async () => {
        const response = await axiosInstance.get(`/v1/user/${props.volunteerID}`)

        /**
         * Disgusting hack to work around <FormKit v-model="foo"/> setting `foo` to undefined when it is unmounted.
         * (n.b. this the primary hypothesis, it's not 100% this is the case)
         *
         * The problem is when we get here, formData.value has been set to `undefined`. There's not many other reasons why this would happen.
         *
         * If this component got unmounted while waiting on the above HTTP response, then we can hit this error,
         * - because `formData.value.firstName = ...` is an error
         *   - because `formData.value` is not an object
         *     - because `formData.value` was set to `undefined`
         *       - because the <FormKit v-model="formData"/> element was unmounted
         *         - because the component was unmounted
         *
         * This means the user navigated away while waiting? Or we pushed a route nav while waiting? Or we mounted this one too many times?
         * see: https://inleague-llc.sentry.io/issues/3773815646/events/5b6bfe2b178243f284ff77fd791316b5/?project=5661592
         */
        if (typeof formData.value !== "object" || formData.value === null) {
          // We could probably "just" return here;
          // if we're unmounted, there's no reason to run the rest of the containing block.
          // but we're not 100% certain that the reason we got here is because the component was unmounted.
          // We don't ever **want** to get here, but we do.
          formData.value = freshFormData();
        }

        formData.value.firstName = response.data.data.firstName
        formData.value.middleName = response.data.data.middleName
        formData.value.lastName = response.data.data.lastName
        formData.value.gender = response.data.data.gender
        formData.value.dob = response.data.data.dob
      }

      const handleConfirmed = () => {
        context.emit('confirmed', formData.value)
      }

      onMounted(async () => {
        await verifyUserInfo()
      })

      return {
        formData,
        dayJSDate,
        handleConfirmed,
      }
    },
  })
  </script>

