<template lang="pug">
t-dialog(v-if='show')
  p.text-red-600.mb-6(v-if="selectionReason==='addPlayer'") You have multiple families associated with your account. Into which family would you like to add this new player?
  p.text-red-600.mb-6(v-else-if="selectionReason==='viewProfile'") There are multiple families associated with this account. Please select which family profile you would like to view.
  t-btn.flex.flex-col(
    v-for="(family, key) in families"
    @click='$emit("familyIDSelected", key)',
  )
    .flex.flex-col
      div(v-for="person in family") {{ person.firstName }} {{person.lastName}}
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'


export default defineComponent({
  props: {
    selectionReason: {
      // is this required?
      // required: true
      type: String
    },
    families: {
      type: Object,
      required: true
    },
  },
  emits: ['familyIDSelected'],
  setup() {
    // Data
    const show = ref(true)

    // Methods
    return {
      show
    }
  },
})
</script>
