import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue"
import { propsDef, routeDetailToRouteLocation } from "./R_SeasonManager.route"
import { Router, useRouter } from "vue-router";
import { UiOption, arrayFindOrFail, copyViaJsonRoundTrip, exhaustiveCaseGuard, forceCheckedIndexedAccess, parseIntOrFail, useIziToast } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { axiosInstance } from "src/boot/AxiosInstances";
import { CalendarSeason, DobCutoff, SeasonForManagerView, createSeason, getSeasonForManagerView, listSeasonOptionsForManagerView, updateSeason } from "src/composables/InleagueApiV1.Seasons";
import { EditSeasonElement, SeasonFormData } from "./SeasonManager.impl";
import { FormKit } from "@formkit/vue";
import dayjs from "dayjs";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { DAYJS_FORMAT_HTML_DATE, dayjsFormatOr } from "src/helpers/formatDate";

export default defineComponent({
  props: propsDef,
  setup(props) {
    const ready = ref(false);
    const router = useRouter();
    const iziToast = useIziToast();

    const seasonOptions = ref<UiOption<Guid | "new">[]>([])
    const selectedSeasonUID = ref<Guid | "new">("new")

    type FormElementAndData = {
      data: SeasonFormData,
      render: () => JSX.Element
    }
    const formElement = ref<null | FormElementAndData>()

    const doSubmitNew = async () => {
      if (formElement.value?.data.type !== "new") {
        throw Error("AssertionFailure");
      }

      try {
        const season = await createSeason(axiosInstance, formElement.value.data.data)

        // insert season into season options as the 2nd option (immediately after the "new" season")
        seasonOptions.value.splice(1, 0, {label: season.season.seasonName, value: season.season.seasonUID});

        // FormKit bug -- Vue is properly reactive WRT to array member methods (e.g. `array.splice`), but FK chooses to only be reactive WRT assignment.
        seasonOptions.value = [...seasonOptions.value]

        selectedSeasonUID.value = season.season.seasonUID;
        await updateRouteLocation(router, season.season.seasonUID);
        iziToast.success({message: "Season created."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doSubmitEdit = async () => {
      if (formElement.value?.data.type !== "edit") {
        throw Error("AssertionFailure");
      }

      try {
        await updateSeason(axiosInstance, formElement.value.data)
        iziToast.success({message: "Season updated."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      const freshSeasonOptions = await listSeasonOptionsForManagerView(axiosInstance);
      seasonOptions.value = freshSeasonOptions
        .options
        .map(season => {
          return {
            label: season.seasonName,
            value: season.seasonUID
          }
        })

      // most recent season or "new" if no seasons
      const routeDetail = props.routeDetail;
      selectedSeasonUID.value = routeDetail.routeName === "SeasonManager.new"
        ? "new"
        : routeDetail.routeName === "SeasonManager.edit"
        ? seasonOptions.value.find(opt => opt.value === routeDetail.seasonUID)?.value || freshSeasonOptions.defaultSeasonUID
        : freshSeasonOptions.defaultSeasonUID;

      // place "new" option at start of list
      seasonOptions.value.unshift({label: "New", value: "new"})

      await updateRouteLocation(router, selectedSeasonUID.value);

      watch(() => props.routeDetail, async () => {
        switch (props.routeDetail.routeName) {
          case "SeasonManager.home":
            formElement.value = null;
            return;
          case "SeasonManager.new":
            const {mostRecentSeason} = await getSeasonForManagerView(axiosInstance, {seasonUID: "new"})
            const formData = reactive(freshFormData(mostRecentSeason));
            formElement.value = {
              data: formData,
              render: () => <EditSeasonElement seasonName={"New Season"} formData={formData} onSubmit={doSubmitNew}/>
            }
            return;
          case "SeasonManager.edit": {
            const routeDetail = props.routeDetail;

            // this stuff shouldn't fail, with the exception that it's driven by user input from the URL and so we
            // might get garbage seasonUIDs.
            const seasonName = arrayFindOrFail(seasonOptions.value, opt => opt.value === routeDetail.seasonUID).label;
            const season = await getSeasonForManagerView(axiosInstance, {seasonUID: routeDetail.seasonUID})
            const formData = reactive(freshFormDataFromExisting(season))

            formElement.value = {
              data: formData,
              render: () => <EditSeasonElement seasonName={seasonName} formData={formData} onSubmit={doSubmitEdit}/>
            }
            return;
          }
          default: exhaustiveCaseGuard(props.routeDetail)
        }
      }, {immediate: true, deep: true})

      ready.value = true;
    })

    const offerCommitSelectedSeasonUidButton = computed(() => {
      return !formElement.value
        || formElement.value.data.type === "new" && selectedSeasonUID.value !== "new"
        || formElement.value.data.type === "edit" && formElement.value.data.seasonUID !== selectedSeasonUID.value
    })

    const formRenderKey = computed(() => {
      return !formElement.value
        ? "<nil>"
        : formElement.value.data.type === "edit"
        ? formElement.value.data.seasonUID
        : formElement.value.data.type === "new"
        ? "new"
        : exhaustiveCaseGuard(formElement.value.data)
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div data-test="R_SeasonManager" class="mx-auto" style="max-width:1024px;">
          <h2>Season Manager</h2>
          <div class="mt-2 mb-2">
            Edit existing seasons or add new seasons below. Seasonal settings apply to all programs.
          </div>
          <FormKit type="select" options={seasonOptions.value} v-model={selectedSeasonUID.value} label="Select Season" data-test="season"/>
          {
            offerCommitSelectedSeasonUidButton.value
              ? (
                <t-btn type="button" margin={false} onClick={async () => updateRouteLocation(router, selectedSeasonUID.value)} data-test="commitSeasonSelect">
                  {
                    selectedSeasonUID.value === "new"
                      ? "Create a New Season - Start Here"
                      : "Get season"
                  }
                </t-btn>
              )
              : null
          }
          <div class="my-4" key={formRenderKey.value}>
            {
              formElement.value?.render()
            }
          </div>
        </div>
      )
    }
  }
})

function freshFormData(mostRecentSeason: SeasonForManagerView["mostRecentSeason"]) : SeasonFormData {
  const month = dayjs().month();
  const currentCalendarSeasonID = 0 <= month && month <= 2
    ? CalendarSeason.winter
    : 3 <= month && month <= 5
    ? CalendarSeason.spring
    : 6 <= month && month <= 8
    ? CalendarSeason.summer
    : CalendarSeason.fall;

  //
  // try to find nice defaults for some values
  //
  const calendarSeasonID : CalendarSeason = (mostRecentSeason.calendarSeasonID % 4) + 1; // cycle to next season w/ wrap-around
  const registrationYear = calendarSeasonID < mostRecentSeason.calendarSeasonID
    ? parseIntOrFail(mostRecentSeason.registrationYear) + 1
    : mostRecentSeason.registrationYear;

  return {
    type: "new",
    mostRecentSeason,
    data: {
      seasonName: "",
      seasonYear: dayjs().year(),
      registrationYear: registrationYear,
      calendarSeasonID: calendarSeasonID,
      fullYearCalendar: DobCutoff.noAgeCuttof,
      algorithmSplitBirthYear: "",
      disabled: false,
      inPersonDate: "",
      requireRefDiv: false,
      hasThreeYearOlds: false,
      pointsLockDate: "",
      ratingsPromptDate: "",
      ratingsDate: "",
      profileLockDate: "",
      rosterDate: "",
    }
  }
}

function freshFormDataFromExisting(v: SeasonForManagerView) : SeasonFormData {
  return {
    type: "edit",
    seasonUID: v.season.seasonUID,
    mostRecentSeason: v.mostRecentSeason,
    data: {
      seasonName: v.season.seasonName,
      seasonYear: v.season.seasonYear,
      registrationYear: v.season.registrationYear,
      calendarSeasonID: v.season.calendarSeasonID,
      fullYearCalendar: v.season.fullYearCalendar,
      algorithmSplitBirthYear: v.season.algorithmSplitBirthYear,
      disabled: v.season.disabled,
      inPersonDate: dayjsFormatOr(v.season.inPersonDate, DAYJS_FORMAT_HTML_DATE, ""),
      requireRefDiv: v.season.requireRefDiv,
      hasThreeYearOlds: v.season.hasThreeYearOlds,
      pointsLockDate: dayjsFormatOr(v.season.pointsLockDate, DAYJS_FORMAT_HTML_DATE, ""),
      ratingsPromptDate: dayjsFormatOr(v.season.ratingsPromptDate, DAYJS_FORMAT_HTML_DATE, ""),
      ratingsDate: dayjsFormatOr(v.season.ratingsDate, DAYJS_FORMAT_HTML_DATE, ""),
      profileLockDate: dayjsFormatOr(v.season.profileLockDate, DAYJS_FORMAT_HTML_DATE, ""),
      rosterDate: dayjsFormatOr(v.season.rosterDate, DAYJS_FORMAT_HTML_DATE, ""),
    }
  }
}

async function updateRouteLocation(router: Router, seasonUID: Guid | "new") {
  if (seasonUID === "new") {
    await router.replace(routeDetailToRouteLocation({routeName: "SeasonManager.new"}))
  }
  else if (seasonUID) {
    await router.replace(routeDetailToRouteLocation({routeName: "SeasonManager.edit", seasonUID}))
  }
  else {
    // no options ... nothing to do?
    // shouldn't happen
  }
}
