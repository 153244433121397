import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { parseIntOr, routerGetQueryParamAsStringArrayOrNull, vOptT, vReqT } from "src/helpers/utils"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  playerID: vReqT<Guid>(),
  seasonUID: vReqT<Guid>(),
  competitionUIDs: vReqT<Guid[]>(),
  /**
   * Optional, but do we ever _not_ generate invoices now?
   * At one time, at least the "free" registrations were "without invoice".
   */
  invoiceInstanceIDs: vOptT<Integerlike[]>(),
  wasDeferredForCashOrCheckPayment: vOptT<boolean>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "registration-complete",
} as const;

const QueryParams = {
  competitionUIDs: "cuids",
  invoiceInstanceID: "invoiceInstanceID",
  wasDeferredForCashOrCheckPayment: "wasDeferredForCashOrCheckPayment",
}

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {
    name: RouteNames.main,
    params: {
      playerID: v.playerID,
      seasonUID: v.seasonUID,
    },
    query: {
      [QueryParams.competitionUIDs]: v.competitionUIDs,
      [QueryParams.invoiceInstanceID]: v.invoiceInstanceIDs,
      [QueryParams.wasDeferredForCashOrCheckPayment]: v.wasDeferredForCashOrCheckPayment ? "1" : "",
    }
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {
    ...route.params as Pick<Props, "seasonUID" | "playerID">,
    competitionUIDs: routerGetQueryParamAsStringArrayOrNull(route, QueryParams.competitionUIDs) ?? [],
    invoiceInstanceIDs: routerGetQueryParamAsStringArrayOrNull(route, QueryParams.invoiceInstanceID)
      ?.map(v => parseIntOr(v, null))
      .filter((v) => v !== null) as Integerlike[]
      ?? [],
    wasDeferredForCashOrCheckPayment: !!route.query[QueryParams.wasDeferredForCashOrCheckPayment],
  }
}
