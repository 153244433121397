import { FormKit } from "@formkit/vue"
import dayjs from "dayjs"
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate"
import { vOptT, vReqT } from "src/helpers/utils"
import { Datelike } from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, ref, watch } from "vue"
import { Btn2 } from "../UserInterface/Btn2"

export const LeagueGamesDatePicker = defineComponent({
  props: {
    initialValue: vReqT<Datelike>(),
    minDate: {
      required: false,
      default: "1900-01-01T00:00:00"
    },
    maxDate: vOptT<Datelike>(),
  },
  inheritAttrs: false,
  setup(props, {emit}) {
    const localValue = ref(dayjs(props.initialValue).format(DAYJS_FORMAT_HTML_DATE));
    watch(() => props.initialValue, () => {
      localValue.value = dayjs(props.initialValue).format(DAYJS_FORMAT_HTML_DATE)
    })

    const min = computed(() => {
      const min = dayjs(props.minDate);
      if (!min.isValid()) {
        throw Error("provided min/max not valid dates");
      }
      return min;
    })

    const max = computed(() => {
      if (!props.maxDate) {
        return null;
      }

      const max = dayjs(props.maxDate);

      if (!max.isValid()) {
        throw Error("provided min/max not valid dates");
      }
      return max;
    })

    const isValid = computed<boolean>(() => {
      const parsed = dayjs(localValue.value);

      if (!parsed.isValid()) {
        return false;
      }

      const minOnOrBefore = min.value.isBefore(parsed, "day") || min.value.isSame(parsed, "day")
      const maxOnOrAfter = max.value === null || (max.value.isSame(parsed, "day") || max.value.isAfter(parsed, "day"));

      return minOnOrBefore && maxOnOrAfter;
    })

    const commit = () => {
      if (!isValid.value) {
        throw Error("illegal state");
      }
      emit("commit", localValue.value)
    }

    return () => {
      return (
        <>
          <div class="font-medium text-sm mb-2">Games on or after</div>
          <div class="flex gap-2 items-center" style="--fk-margin-outer: none;">
            <FormKit
                outer-class='flex-grow'
                type="date"
                v-model={localValue.value}
            />
            <Btn2
              class="px-2 py-1"
              disabled={!isValid.value}
              onClick={commit}
              data-test="updateGames"
            >
              Update
            </Btn2>
          </div>
          <div class="text-xs text-red-600 my-2">
            {
              !isValid.value
                ? (
                  max.value
                    ? `Please enter a valid date between ${min.value.format("MMM/DD/YYYY")} and ${max.value.format("MMM/DD/YYYY")}`
                    : `Please enter a valid date on or after ${min.value.format("MMM/DD/YYYY")}`
                )
                : null
            }
          </div>
        </>
      )
    }
  }
});
