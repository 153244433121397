import { defineComponent, ref, computed, watch } from "vue";
import { FormKit } from "@formkit/vue";
import { faFileExport, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { vReqT, UiOption, sortBy, sortByMany, parseIntOr, exhaustiveCaseGuard, downloadFromObjectURL } from "src/helpers/utils";
import { EscapedRegExp } from "src/helpers/utils-backend";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import { Paginated } from "src/modules/PaginationUtils";
import { inferColIDs, freshSortState, rowsToXlsxBuffer, rowsToCsv, SortArrow, getColDefHtml } from "src/modules/TableUtils";
import { CompleteTeamChooserSelection } from "../UserInterface/TeamChooser/TeamChooserUtils";
import { PlayerRosterSort, medicalReleaseByCompSeasonDiv_authz } from "./R_TeamSeason.route";
import { MedicalReleaseListingEntry } from "src/composables/InleagueApiV1.Teams";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { RosterExcludedPlayerIDs } from "./RosterExcludedPlayerIDs";

export const MedicalReleaseListingTable = defineComponent({
  props: {
    mergedPdfByTeamsURL: vReqT<string>(),
    mergedPdfByCompSeasonDivURL: vReqT<string>(),
    selection: vReqT<CompleteTeamChooserSelection>(),
    listing: vReqT<readonly MedicalReleaseListingEntry[]>(),
    sort: vReqT<PlayerRosterSort>(),
    rosterExcludedPlayerIDs: vReqT<RosterExcludedPlayerIDs>(),
  },
  setup(props) {
    const itemsPerPage = ref<Integerlike | "ALL">(25)
    const itemsPerPageOptions : UiOption[] = [
      {label: "25", value: "25"},
      {label: "50", value: "50"},
      {label: "All", value: "ALL"}
    ]

    const colDefs = ref(inferColIDs<MedicalReleaseListingEntry>()([
      {
        id: "player",
        label: "Player",
        html: v => (
          <div>
            <div>{v.playerFirstName} {v.playerLastName}</div>
            {
              props.rosterExcludedPlayerIDs.has(v.playerID)
                ? <div class="text-xs italic">on exclusion list</div>
                : null
            }
          </div>
        ),
        sort: {
          cb: sortByMany(sortBy(_ => _.playerLastName), sortBy(_ => _.playerFirstName)),
          dir: "asc",
        }
      },
      {
        id: "team",
        label: "Team",
        html: v => `${v.teamName}`,
        sort: {
          cb: sortBy(_ => _.teamName),
          dir: "not-sorted",
        }
      },
      {
        id: "uniform",
        label: "Uniform",
        html: v => v.uniform,
        sort: {
          cb: sortBy(_ => {
            // try to sort by integer uniform IDs ("jersey number"),
            // but if it's not an int just do the default on whatever stringly-typed value we have
            return parseIntOr(_.uniform, _.uniform)
          }),
          dir: "not-sorted",
        }
      },
      {
        id: "medicalRelease",
        label: "Medical release URL",
        html: v => <a class="il-link" target="_blank" href={v.releaseURL}>{v.releaseURL}</a>,
        xlsx: v => v.releaseURL,
      }
    ]));

    const sortState = freshSortState(colDefs.value)

    watch(() => props.sort, () => {
      switch (props.sort) {
        case "name":
          sortState.reconfigure([{colID: "player", dir: "asc"}])
          return;
        case "uniform":
          sortState.reconfigure([{colID: "uniform", dir: "asc"}])
          return;
        default: exhaustiveCaseGuard(props.sort);
      }
    }, {immediate: true})

    const filters = ref({playerName: ""})

    const applyFilters = (vs: readonly MedicalReleaseListingEntry[]) => {
      return vs.filter(filterByName())

      function filterByName() {
        const s = filters.value.playerName.trim()
        const pattern = s ? EscapedRegExp(s, "i") : null;
        if (pattern) {
          return (v: MedicalReleaseListingEntry) => pattern.test(`${v.playerFirstName} ${v.playerLastName}`)
        }
        else {
          return () => true
        }
      }
    }

    const applySort = (vs: readonly MedicalReleaseListingEntry[]) => {
      return [...vs].sort(sortState.asSorter())
    }

    const filteredSortedRows = computed(() => {
      return applySort(applyFilters(props.listing))
    })

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), filteredSortedRows))

    const downloadAsXlsx = async () : Promise<void> => {
      const source = applySort(props.listing); // n.b. not filtered
      const buffer = await rowsToXlsxBuffer(colDefs.value, source)
      downloadFromObjectURL(buffer, "TeamSeason.xlsx")
    }

    const downloadAsCsv = () : void => {
      const source = applySort(props.listing); // n.b. not filtered
      const csv = rowsToCsv(colDefs.value, source)
      downloadFromObjectURL(csv, "TeamSeason.csv")
    }

    const copyToClipboard = () : void => {
      const source = applySort(props.listing); // n.b. not filtered
      const csv = rowsToCsv(colDefs.value, source)
      navigator.clipboard.writeText(csv)
    }

    return () => (
      <div>
        <div class="rounded-md shadow-md border border-slate-100 my-4 p-2">
          <div>Medical Release Links by Team</div>
          <div class="text-sm">Links to each player's electronically signed release form are below.</div>
          <div class="text-sm">
            <span>To show all the medical releases for {props.selection.teamIDs.length === 1 ? "this team" : "these teams"} in a single document, </span>
            <a class="il-link" target="_blank" href={props.mergedPdfByTeamsURL}>generate a merged release form</a>
            <span>.</span>
          </div>
          {
            medicalReleaseByCompSeasonDiv_authz(props.selection.competitionUID)
              ? (
                <div class="text-sm">
                  <span>To retrieve medical releases for all players in this division, </span>
                  <a class="il-link" target="_blank" href={props.mergedPdfByCompSeasonDivURL}>generate a division-wide merged release form</a>
                  <span>.</span>
                </div>
              )
              : null
          }
        </div>
        <div class="rounded-md shadow-md border border-slate-100 m-auto overflow-auto" style="min-width:32em;">
          <div class="p-2 flex items-start">
            <div style="--fk-margin-outer: none; --fk-padding-input: .325em;">
              <FormKit type="text" {...{placeholder:"Filter by player name"}} v-model={filters.value.playerName}/>
            </div>
            <div class="flex gap-2 ml-auto">
              <t-btn onClick={downloadAsCsv} margin={false}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As CSV</span>
              </t-btn>
              <t-btn onClick={downloadAsXlsx} margin={false}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As Excel</span>
              </t-btn>
              <t-btn onClick={copyToClipboard} margin={false}>
                <FontAwesomeIcon icon={faClipboard}/>
                <span class="ml-1 text-xs">Copy</span>
              </t-btn>
            </div>
          </div>
          <table class="w-full">
              <tr>
                {
                  colDefs.value.map(colDef => {
                    return (
                      <th class="p-2 text-left whitespace-nowrap">
                        <div class="flex items-center text-sm">
                          <span>{colDef.label}</span>
                          {
                            ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                              return sorter
                                ? (
                                  <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                    <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                  </span>
                                )
                                : null
                            })(sortState.sortersByColID[colDef.id])
                          }
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
              {
                pagination.value.pageData.itemsThisPage.map((entry, i) => {
                  const rowBgColor = i % 2 ? "bg-gray-100" : ""
                  return (
                    <tr key={entry.assignmentID}>
                      {
                        colDefs.value.map(colDef => {
                          return (
                            <td class={`${rowBgColor} p-2`}>
                              {
                                getColDefHtml(colDef, entry)
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </table>
          {
            props.listing.length === 0
              ? <div class="p-4 flex items-center justify-center">Nothing found</div>
              : null
          }
          {
            props.listing.length > 0
              ? (
                <div class="flex">
                  <div class="ml-auto p-2">
                    <SimplePaginationNav mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                  </div>
                </div>
              )
              : null
          }
        </div>
      </div>
    )
  }
})
