import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { vReqT } from "src/helpers/utils"

export const propsDef = {
  // required?
  // mar/7/2023 -- this might always be "player" now, the "user" value
  // is for selecting a volunteer in the volunteer registration flow, and we do that as part of
  // the volunteer registration child routes (so this component is "just" select player)
  role: vReqT<"player" | "user">(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "select-season",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {name: RouteNames.main, params: v}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {...route.params as any}
}
