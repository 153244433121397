import { exhaustiveCaseGuard, unreachable, vReqT } from "src/helpers/utils";
import { ExtractPropTypes, computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import { Guid } from "src/interfaces/InleagueApiV1";

import * as R_SelectSeason from 'src/components/Registration/selections/R_SelectSeason.route'
import * as R_SelectPlayer from 'src/components/Registration/selections/R_SelectPlayer.route'
import * as R_ContactAndVolunteerDetailsUpdateFlow from "src/components/Registration/selections/R_ContactAndVolunteerDetailsUpdateFlow.route"
import * as R_SelectCompetitions from 'src/components/Registration/selections/R_SelectCompetitions.route'
import * as R_PlayerRegistration from 'src/components/Registration/registrationForm/R_PlayerRegistration.route'

export enum Step {
  selectSeason = 1,
  selectPlayer = 2,
  contactInfoAndVolunteerPrefs = 3,
  selectProgram = 4,
  registrationForm = 5,
  reviewAndPay = 6,
  end
}

/**
 * We exploit that as steps progress, the types become increasingly wider subtypes of prior steps.
 */
export type RegistrationJourneyDetail =
  | {step: Step.selectSeason}
  | {step: Step.selectPlayer, seasonUID: Guid}
  | {step: Step.contactInfoAndVolunteerPrefs, seasonUID: Guid, playerID: Guid}
  | {step: Step.selectProgram, seasonUID: Guid, playerID: Guid}
  | {step: Step.registrationForm, seasonUID: Guid, playerID: Guid, competitionUIDs: Guid[]}
  | {step: Step.reviewAndPay, seasonUID: Guid, playerID: Guid, competitionUIDs: Guid[]}

function assertIsAtLeastSomeStep<T extends Step>(step: T, obj: RegistrationJourneyDetail) : asserts obj is Extract<RegistrationJourneyDetail, {step: T}> {
  if (obj.step >= step) {
    return;
  }
  throw Error(`Expected object.step to be at least '${Step[step]}' (${step}) but object.step was '${Step[obj.step]}' (${obj.step})`)
}

const propsDef = {
  detail: vReqT<RegistrationJourneyDetail>(),
} as const

export type RegistrationJourneyBreadcrumbElementProps = ExtractPropTypes<typeof propsDef>

export const RegistrationJourneyBreadcrumbElement = defineComponent({
  props: propsDef,
  setup(props) {
    const router = useRouter()
    const currentStep = computed<number>(() => props.detail.step)
    const totalSteps = computed(() => Step.end - 1)

    const getLabelForStep = (step: Step) => {
        switch (step) {
          case Step.selectSeason: {
            return "Select season"
          }
          case Step.selectPlayer: {
            return "Select player"
          }
          case Step.selectProgram: {
            return "Select program"
          }
          case Step.contactInfoAndVolunteerPrefs: {
            return "Contact info and volunteer preferences"
          }
          case Step.registrationForm: {
            return "Registration form"
          }
          case Step.reviewAndPay: {
            return "Review and pay"
          }
          case Step.end: {
            unreachable()
          }
          default: exhaustiveCaseGuard(step)
        }
    }

    const seasonConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.selectSeason)
      if (props.detail.step > Step.selectSeason) {
        return {
          type: "completed",
          url: router.resolve(R_SelectSeason.routeDetailToRouteLocation({role: "player"})).href,
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.selectSeason) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    const playerConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.selectPlayer)
      if (props.detail.step > Step.selectPlayer) {
        assertIsAtLeastSomeStep(Step.contactInfoAndVolunteerPrefs, props.detail);
        return {
          type: "completed",
          url: router.resolve(R_SelectPlayer.routeDetailToRouteLocation({seasonUID: props.detail.seasonUID})).href,
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.selectPlayer) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    const contactInfoConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.contactInfoAndVolunteerPrefs)
      if (props.detail.step > Step.contactInfoAndVolunteerPrefs) {
        assertIsAtLeastSomeStep(Step.selectProgram, props.detail);
        const route = R_ContactAndVolunteerDetailsUpdateFlow.routeDetailToRouteLocation({
          childID: props.detail.playerID,
          seasonUID: props.detail.seasonUID,
          step: 1,
          userListIndex: 0,
        });
        return {
          type: "completed",
          url: router.resolve(route).href,
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.contactInfoAndVolunteerPrefs) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    const competitionConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.selectProgram)
      if (props.detail.step > Step.selectProgram) {
        assertIsAtLeastSomeStep(Step.registrationForm, props.detail)
        const route = R_SelectCompetitions.routeDetailToRouteLocation(R_SelectCompetitions.RouteNames.selectCompetitions, {
            playerID: props.detail.playerID,
            seasonUID: props.detail.seasonUID
        });
        return {
          type: "completed",
          url: router.resolve(route).href,
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.selectProgram) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    const registrationFormConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.registrationForm)
      if (props.detail.step > Step.registrationForm) {
        assertIsAtLeastSomeStep(Step.reviewAndPay, props.detail)
        const route = R_PlayerRegistration.routeDetailToRouteLocation({
          competitionUIDs: props.detail.competitionUIDs,
          playerID: props.detail.playerID,
          seasonUID: props.detail.seasonUID,
        })
        return {
          type: "completed",
          url: router.resolve(route).href,
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.registrationForm) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    const reviewAndPayConfig = computed<OneBreadcrumbProps>(() => {
      const tooltip = getLabelForStep(Step.reviewAndPay)
      if (props.detail.step > Step.reviewAndPay) {
        return {
          type: "completed",
          url: "www.google.com",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else if (props.detail.step === Step.reviewAndPay) {
        return {
          type: "current",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
      else {
        return {
          type: "future",
          tooltip,
        } satisfies OneBreadcrumbProps
      }
    })

    return () => {
      return (
        <div>
          <nav class="m-auto grid" style="grid-template-columns: min-content;" aria-label="Progress">
            <div class="flex items-center justify-center">
              <p class="text-sm font-medium" style="white-space: nowrap;">Step {currentStep.value} of {totalSteps.value}</p>
              <ol role="list" class="ml-8 flex items-center space-x-5">
                <OneBreadCrumbElement v={seasonConfig.value}/>
                <OneBreadCrumbElement v={playerConfig.value}/>
                <OneBreadCrumbElement v={contactInfoConfig.value}/>
                <OneBreadCrumbElement v={competitionConfig.value}/>
                <OneBreadCrumbElement v={registrationFormConfig.value}/>
                <OneBreadCrumbElement v={reviewAndPayConfig.value}/>
              </ol>
            </div>
            <div class="border-b my-1 border-green-300 w-full"></div>
            <div class="text-sm flex justify-start font-medium">
              {getLabelForStep(props.detail.step)}
            </div>
          </nav>
        </div>
      )
    }
  }
})

type OneBreadcrumbProps =
  | {type: "completed", url: string, tooltip: string | undefined}
  | {type: "current", tooltip: string | undefined}
  | {type: "future", tooltip: string | undefined}

const OneBreadCrumbElement = defineComponent({
  props: {
    v: vReqT<OneBreadcrumbProps>(),
  },
  setup(props) {
    const isHovered = ref(false)
    return () => {
      switch (props.v.type) {
        case "completed": {
          return (
            <li>
              <a href={props.v.url} class="block h-3.5 w-3.5 rounded-full bg-green-600 hover:bg-green-900" v-tooltip={props.v.tooltip}></a>
            </li>
          )
        }
        case "current": {
          return (
            <li>
              <span class="relative flex items-center justify-center" aria-current="step" v-tooltip={props.v.tooltip}
                onMouseenter={() => { isHovered.value = true}}
                onMouseleave={() => { isHovered.value = false}}
              >
                <span class="absolute flex h-6 w-6 p-px" aria-hidden="true">
                  <span class={["h-full w-full rounded-full bg-green-200"]}></span>
                </span>
                <span class="relative block h-3.5 w-3.5 rounded-full bg-green-600" aria-hidden="true"></span>
              </span>
            </li>
          )
        }
        case "future": {
          return (
            <li v-tooltip={{content: props.v.tooltip}}>
              <span class="relative flex items-center justify-center" aria-current="step"
                onMouseenter={() => { isHovered.value = true}}
                onMouseleave={() => { isHovered.value = false}}
              >
                <span class="absolute flex h-6 w-6 p-px" aria-hidden="true">
                  <span class={["h-full w-full rounded-full", isHovered.value ? "bg-green-100" : "bg-white"]}></span>
                </span>
                <span class="relative block h-3.5 w-3.5 rounded-full bg-green-600" aria-hidden="true"></span>
              </span>
            </li>
          )
        }
        default: exhaustiveCaseGuard(props.v)
      }
    }
  }
})
