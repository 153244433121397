<template lang="pug">
TDialog(data-test="cancel-competition-registration-modal")
  .text-xl Cancel program registration
  div.divide-y
    div.p-2.text-left
      | This will cancel {{ targetPlayerUiName }}'s {{ targetCompRegCompetitionUiName }} registration.
    div.p-2.text-left
      | No automatic refunds or fee collections are performed.
    div.p-2.text-left
      div.pb-2 Cancel this program registration?
      FormKit(type="checkbox" v-model="notifyDivisionDirectorAndHeadCoaches" label="Notify division director and head coaches")
      FormKit(type="checkbox" v-model="notifyCompetitionManagers" label="Notify competition managers")
      FormKit(type="checkbox" v-model="markPlayerAsNeverPlayed" label="Mark player as never played")
      FormKit(
        type='button'
        @click="doNotCancel()"
        label="No, do not cancel this program registration"
        style="width:100%"
      )
      //- the red, slightly smaller button is the "dangerous" button
      div.flex.justify-center.text-base
        FormKit(
          type='button'
          label=`Yes, cancel this program registration`
          wrapper-class='mt-2',
          @click="confirmCancellation()"
          :classes="{ input: 'cancelBtn' }"
        )
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { propsDef, emitsDef } from "./CancelCompetitionRegistrationModal";

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, { emit }) {

    const targetCompRegCompetitionUiName = computed(() => {
      return props.target.competition.competition;
    });

    const targetPlayerUiName = computed(() => `${props.target.player.playerFirstName} ${props.target.player.playerLastName}`);

    const notifyDivisionDirectorAndHeadCoaches = ref(false);
    const notifyCompetitionManagers = ref(false);
    const markPlayerAsNeverPlayed = ref(false);

    return {
      confirmCancellation: (): void => {
        emit("confirmCancellation", {
          competitionRegistration: props.target.competitionRegistration,
          notifyDivisionDirectorAndHeadCoaches : notifyDivisionDirectorAndHeadCoaches.value,
          notifyCompetitionManagers : notifyCompetitionManagers.value,
          markPlayerAsNeverPlayed : markPlayerAsNeverPlayed.value,
        });
      },
      doNotCancel: (): void => {
        emit("doNotCancel")
      },
      targetCompRegCompetitionUiName,
      targetPlayerUiName,
      notifyDivisionDirectorAndHeadCoaches,
      notifyCompetitionManagers,
      markPlayerAsNeverPlayed,
    }
  }
})
</script>
