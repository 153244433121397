import { TailwindBreakpoint, requireNonNull, useWindowSize, vReqT } from "src/helpers/utils"
import { Teleport, Transition, computed, defineComponent, nextTick, onUnmounted, reactive, ref, watch } from "vue"
import { GameCalendarUiElement } from "./GameScheduler.shared"
import { ilDraggable, vueDirective_ilDraggable } from "src/modules/ilDraggable"
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside"
import { AutoModal, DefaultModalController, DefaultTinySoccerballBusyOverlay } from "src/components/UserInterface/Modal"
import { Client } from "src/store/Client"
import { LayoutNode } from "./CalendarLayout"

export const GameEditorPaneOrModal = defineComponent({
  props: {
    uiState: vReqT<
      | {active: false}
      | {active: true, node: LayoutNode<GameCalendarUiElement>, calendarElement: HTMLElement}
    >(),
    label: vReqT<string>(),
    busyUpdating: vReqT<boolean>(),
  },
  directives: {
    ilOnClickOutside: vueDirective_ilOnClickOutside,
    ilDraggable: vueDirective_ilDraggable,
  },
  emits: {
    cancel: () => true,
  },
  setup(props, {slots, emit}) {
    const overlay_x = ref(0)
    const overlay_y = ref(0)
    const overlay_dragging = ref(false)
    const overlay_dragStartOffset = ref({x:0,y:0})
    const overlay_ref = ref<HTMLElement | null>()
    const overlay_render = ref(false) // render it
    const overlay_invisible = ref(false) // if rendering, is visibility=hidden
    const overlay_animate = ref(false) // enable/disable Transition animations
    const ellipses = useEllipses(4, 400)

    const onEsc = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        emit("cancel")
      }
    }

    watch(() => props.busyUpdating, () => {
      if (props.busyUpdating) {
        ellipses.start()
      }
      else {
        ellipses.stop()
      }
    })

    // oof, window/global escape key handler ...
    // assumes there's only one thing on the screen at a time that wants to handle escape...
    watch(() => props.uiState.active, () => {
      if (props.uiState.active) {
        window.addEventListener("keyup", onEsc)
      }
      else {
        window.removeEventListener("keyup", onEsc)
      }
    }, {immediate: true})

    const windowSize = useWindowSize()

    const asModal = computed(() => windowSize.width < TailwindBreakpoint.md);
    const asOverlay = computed(() => !asModal.value)

    const onDragOverlayCB = (evt: DragEvent) => {
      evt.stopImmediatePropagation()
      evt.preventDefault()
      overlay_x.value = evt.clientX - overlay_dragStartOffset.value.x
      overlay_y.value = evt.clientY - overlay_dragStartOffset.value.y
    }

    const modalController = reactive((() => {
      // shallow copy state in, so that as it animates on close we still have form data,
      // even if props to the outer component is passing itis no
      return DefaultModalController<void>({
        title: () => <>
          <div>Edit Game</div>
          <div class="border-b my-2"></div>
        </>,
        content: state => {
          return <div style="--fk-margin-outer:none; --fk-max-width-input:none;">
            {slots.default?.()}
            {props.busyUpdating
              ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
              : null
            }
          </div>
        }
      }, {onCloseCB: (close) => {
        if (props.busyUpdating) {
          return
        }
        emit("cancel")
        close()
      }})
    })());

    watch(() => props.uiState.active, async () => {
      if (props.uiState.active) {
        const {node, calendarElement} = props.uiState

        if (asOverlay.value) {
          const targetBox = calendarElement.getBoundingClientRect()
          overlay_animate.value = false
          overlay_render.value = true
          overlay_invisible.value = true

          await nextTick() // render it invisible to get size info, wait for DOM to update

          // try a little bit to find a good inital place for the overlay.
          // Currently we just nudge a little to the left of the target calendar element.
          const paneBox = requireNonNull(overlay_ref.value).getBoundingClientRect()
          overlay_x.value = Math.max(4, (targetBox.left - paneBox.width) - 12);
          overlay_y.value = (window.innerHeight / 2) - (paneBox.height / 2)
          overlay_render.value = false
          overlay_invisible.value = false

          await nextTick() // stop rendering it, wait for DOM to update
        }

        // Always open, even though it won't be displayed if we're not in modal mode.
        // This way window resizes will appropriately swap between modal/non-modal ui.
        modalController.open()

        // keep state consistent even if not rendering the overlay,
        // because window can resize and then we need to be in the right configuration
        node.data.uiState.isModalOrOverlayFocus = true
        overlay_render.value = true
        overlay_animate.value = true

        // TODO: focus first focusable thing in overlay/modal?
      }
      else {
        overlay_render.value = false
        modalController.close()
      }
    }, {immediate: true})

    return () => {
      if (asModal.value) {
        return <AutoModal controller={modalController}/>
      }
      else {
        return (
          <Teleport to="body">
            <Transition
              enterActiveClass="ease-out duration-75"
              enterFromClass="opacity-0 scale-95"
              enterToClass="opacity-100 scale-100"
              leaveActiveClass="ease-in duration-75"
              leaveFromClass="opacity-100 scale-100"
              leaveToClass="opacity-0 scale-95"
              css={overlay_animate.value}
            >
              {overlay_render.value
                ? (
                  <div
                    ref={overlay_ref}
                    style={`${overlay_invisible.value ? "visibility:hidden;" : ""} min-width:300px; max-height: 100vh; overflow:auto; position:fixed; top:${overlay_y.value}px; left:${overlay_x.value}px; height:auto; width:auto; z-index:999; --fk-margin-outer:none;`}
                    class="relative rounded-md bg-white il-box-shadow-1 border"
                    v-ilOnClickOutside={() => {
                      if (props.busyUpdating) {
                        return
                      }
                      emit("cancel")
                    }}
                  >
                    <div>
                      <div style="z-index:1;" class="p-1 bg-green-800 text-white text-sm sticky top-0 flex items-center gap-2"
                          v-ilDraggable={
                            {
                              dragHandleJsxFunc: () => null,
                              onDragStart: (dataTransfer, evt) => {
                                evt.stopPropagation()
                                overlay_dragging.value = true
                                dataTransfer.dropEffect = "none"
                                overlay_dragStartOffset.value = {x: evt.offsetX, y: evt.offsetY}
                                window.addEventListener("dragover", onDragOverlayCB, {capture: true})
                                return true
                              },
                              onLeaveOrEnd: () => {
                                window.removeEventListener("dragover", onDragOverlayCB, {capture: true})
                                overlay_dragging.value = false
                              }
                            } satisfies ilDraggable
                          }
                        >
                          <div>{
                            props.busyUpdating ? `Saving${ellipses.value}` : props.label}</div>
                      </div>
                      <div class="m-2">
                        {slots.default?.()}
                      </div>
                    </div>
                    {props.busyUpdating
                      ? <div class="top-0 left-0 w-full h-full bg-white opacity-50 absolute"></div>
                      : null
                    }
                  </div>
                )
                : null
              }
            </Transition>
          </Teleport>
        )
      }
    }
  }
})

function useEllipses(ellipsesCount: number, delayMilliseconds: number) {
  const v = ref("")

  let intervalHandle : number = -1

  const updater = () => {
    const len = ((v.value.length + 1) % (ellipsesCount + 1))
    v.value = ".".repeat(len)
  }

  onUnmounted(() => {
    window.clearInterval(intervalHandle)
  })

  return {
    get value() { return v.value },
    start() {
      clearTimeout(intervalHandle)
      v.value = ""
      intervalHandle = window.setInterval(updater, delayMilliseconds)
    },
    stop() {
      clearTimeout(intervalHandle)
      v.value = ""
      intervalHandle = -1
    }
  }
}
