import { Guid, Invoice } from "src/interfaces/InleagueApiV1";
import { RouteLocationRaw, RouterLink } from "vue-router";
import * as R_Eventsignup from "src/components/Events/R_EventSignup.route"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard, parseFloatOrFail } from "src/helpers/utils";
import { PropType, defineComponent } from "vue";
import ContentChunkDisplay from "src/components/Admin/ContentChunks/ContentChunkDisplay";
import * as R_PlayerRegistration from "src/components/Registration/registrationForm/R_PlayerRegistration.route"
import { LineItemSpecialization } from "src/interfaces/Store/checkout";

const registrationRouteDetailToRoutePath = (seasonUID: iltypes.Guid, competitionUIDs: Guid[], playerID: iltypes.Guid) : RouteLocationRaw => {
  return R_PlayerRegistration.routeDetailToRouteLocation({seasonUID, competitionUIDs, playerID})
}

/**
 * An "expired invoice" is intended to mean "re-run the flow that generated this invoice, because all the resulting
 * data may have changed (available registration slots, prices, etc.)"
 *
 * We need to know where to go to restart the checkout flow for some invoice.
 */
export function ExpiredInvoiceNotification(props: {invoice: Invoice}) {
  //
  // The design is such that invoices "can be heterogenous" as in contain many different entity types (aside from donations),
  // e.g. 1 compreg and an event signup. But no invoices like that are generated at this time.
  // This is being slowly relaxed, and we now generate invoices having many line items but which remain homogenous
  // (that is, many program registrations on one invoice).
  //
  let lineItem_having_assumed_homogenous_type : undefined | iltypes.InvoiceLineItem = undefined;
  for (const lineItem of props.invoice.lineItems) {
    if (lineItem.entity_type === "qCompetitionRegistration" || lineItem.entity_type === "qEventSignup") {
      lineItem_having_assumed_homogenous_type = lineItem;
      break; // found one, we assume the rest are homogenous (or ignorable, like donations and etc.)
    }
  }

  if (lineItem_having_assumed_homogenous_type === undefined) {
    // at this time there's only 2 entity_types that makes sense to possibly be expired
    throw "expected a lineItem of type qCompetitionRegistration or qEventSignup but found neither";
  }

  let routerLink : JSX.Element;

  if (lineItem_having_assumed_homogenous_type.entity_type === "qCompetitionRegistration") {
    // competition registrations are "1 ore more compreg per invoice"
    const isCompRegLineItem = (v: iltypes.InvoiceLineItem) : v is LineItemSpecialization<"qCompetitionRegistration"> => v.entity_type === "qCompetitionRegistration";
    const competitionRegistrations = props.invoice.lineItems.filter(isCompRegLineItem).map(v => v.entity!)

    // all (season, childID) expected to be same across invoice
    const seasonUID = competitionRegistrations[0].seasonUID
    const childID = competitionRegistrations[0].childID
    // many unique competitionUIDs
    const competitionUIDs = competitionRegistrations.map(v => v.competitionUID)
    routerLink = <RouterLink data-test="competitionRegistration"
      class="text-blue-700 underline cursor-pointer"
      to={registrationRouteDetailToRoutePath(seasonUID, competitionUIDs, childID)}
    >
      program registration
    </RouterLink>
  }
  else if (lineItem_having_assumed_homogenous_type.entity_type === "qEventSignup") {
    // event signups are currently only "one event signup per invoice"
    const entity = lineItem_having_assumed_homogenous_type.entity!;
    const eventID = entity.eventID!; // we do expect this to be definitely truthy, see comments about why the type (somewhat accurately) indicates why it's a little wonky
    routerLink = <RouterLink data-test="eventSignup"
      to={R_Eventsignup.routeDetailToRoutePath({name: "event-signup", eventID})}
      class="text-blue-700 underline cursor-pointer"
    >
      event signup
    </RouterLink>
  }
  else {
    exhaustiveCaseGuard(lineItem_having_assumed_homogenous_type);
  }

  return (
    <div class="bg-yellow-200 p-2" data-test="ExpiredInvoiceNotification">
      This invoice has expired. Please re-run {routerLink}, as capacities and fees may have changed.
    </div>
  )
}

export const TournamentTeamRegContentChunks = defineComponent({
  props: {
    tournamentTeamRegInvoice: {
      required: true,
      type: null as any as PropType<iltypes.Invoice>
    },
    tournamentTeamHoldPaymentInvoice: {
      required: true,
      type: null as any as PropType<iltypes.Invoice>
    }
  },
  setup(props) {
    return () => (
      <>
        {
          parseFloatOrFail(props.tournamentTeamRegInvoice.lineItemSum) >= 0.01
            ? <ContentChunkDisplay id="TournamentTeamRegistrationInvoice"/>
            : null
        }
        {
          parseFloatOrFail(props.tournamentTeamHoldPaymentInvoice.lineItemSum) >= 0.01
            ? <ContentChunkDisplay
              id="TournamentTeamRefDepositInvoice"
              interpolationArgs={{amount: `$${parseFloatOrFail(props.tournamentTeamHoldPaymentInvoice.lineItemSum).toFixed(2)}`}}
            />
            : null
        }
      </>
    )
  }
})
