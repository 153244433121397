import { clearDragImage, getDummyHandle, setDragHandleJsxFunc } from "src/GlobalDragHandle";
import { clamp } from "src/helpers/utils";
import { Directive } from "vue";

export interface ilDraggable {
  /**
   * Custom drag handle render function.
   */
  dragHandleJsxFunc: () => JSX.Element | null,
  /**
   * Returning true means "yes make this draggable"
   */
  onDragStart: (_: DataTransfer, event: DragEvent) => boolean,
  /**
   * The draggable was dropped, somewhere.
  */
 onDrop?: () => void,
 /**
  * The drag was canceled.
  * TODO: What is the behavior here, do we always fire this after drop, before drop, or if there was a drop does this not fire, and etc.
  */
 onLeaveOrEnd?: (_: DataTransfer) => void,
}

let currentDraggable : ilDraggable | null = null
let currentDropTarget : ilDropTarget | null = null

/**
 * It seems we need to manually introduce "on drag the draggable near the top or bottom, scroll the page if possible".
 * Though, it natively works without this, sometimes, for scroll down; but never for scroll up?
 */
const globalMaybeVerticaScrollDragOverHandler = (() => {
  let rafHandle = 0
  let currentScrollDir : "up" | "down" | null = null;

  const pxPerInch = 96
  const scrollPxPerSecond = pxPerInch * 12
  let active = false

  const cancelCurrentRaf = () => {
    active = false
    cancelAnimationFrame(rafHandle)
    rafHandle = 0;
    currentScrollDir = null
  }

  const handler = (ev: DragEvent) => {
    // how do we get "the initial" time? For now we leave it undefined, and when it is undefined,
    // immediately schedule the next callback using prior frame time.
    const scrollCallback = (pixelsPerSecond: number, lastTime?: number) => {
      const cb = (time: number) => {
        if (!lastTime) {
          rafHandle = requestAnimationFrame(scrollCallback(pixelsPerSecond, time))
        }
        else {
          const deltaMs = time - lastTime;
          const px = pixelsPerSecond * (deltaMs / 1000)
          document.documentElement.scrollTop = clamp(document.documentElement.scrollTop + px, {min: 0, max: document.documentElement.offsetHeight});
          rafHandle = requestAnimationFrame(scrollCallback(pixelsPerSecond, time))
        }
      }
      return cb;
    }

    const viewportHeight = window.innerHeight
    const activeRegionSizePx = Math.floor(pxPerInch / 2);
    const activeRegionTop = {from: 0, to: 0 + activeRegionSizePx} as const
    const activeRegionBottom = {from: viewportHeight - activeRegionSizePx, to: viewportHeight} as const;

    if (activeRegionTop.from <= ev.clientY && ev.clientY <= activeRegionTop.to) {
      if (currentScrollDir === "up") {
        return; // already scrolling up
      }
      cancelCurrentRaf();
      rafHandle = requestAnimationFrame(scrollCallback(-scrollPxPerSecond))
      currentScrollDir = "up"
    }
    else if (activeRegionBottom.from <= ev.clientY && ev.clientY <= activeRegionBottom.to) {
      if (currentScrollDir === "down") {
        return; // already scrolling down
      }
      cancelCurrentRaf()
      rafHandle = requestAnimationFrame(scrollCallback(+scrollPxPerSecond))
      currentScrollDir = "down"
    }
    else {
      cancelCurrentRaf();
    }
  }

  return {
    listen: () => {
      if (active) {
        return;
      }
      window.addEventListener("dragover", handler, {capture: true})
      active = true
    },
    cancel: () => {
      cancelCurrentRaf();
      window.removeEventListener("dragover", handler, {capture: true})
    }
  }
})()

/**
 * Support nullable binding for "conditional" directives
 * TODO: jsx for truly "conditional" directives (`<div {directives: [v ? directiveDef : null]}>...</div>` or something)
 */
export const vueDirective_ilDraggable : Directive<any, ilDraggable | null | undefined> = {
  mounted: (dragRoot: HTMLElement, binding, vnode) => {
    if (!binding.value) {
      return;
    }

    dragRoot.setAttribute("draggable", "true");

    dragRoot.addEventListener("dragstart", (ev) => {
      if (!binding.value) {
        return
      }
      if (!ev.dataTransfer) {
        return;
      }

      if (binding.value.onDragStart(ev.dataTransfer, ev)) {
        ev.dataTransfer.setData("webkit-requires-we-put-at-something-here-even-if-the-value-is-empty-string", "")
        ev.dataTransfer.setDragImage(getDummyHandle(), 0, 0)
        setDragHandleJsxFunc(binding.value.dragHandleJsxFunc)
        currentDraggable = binding.value;
        globalMaybeVerticaScrollDragOverHandler.listen()
      }
    })
    dragRoot.addEventListener("dragend", (ev) => {
      if (!binding.value) {
        return;
      }

      globalMaybeVerticaScrollDragOverHandler.cancel()

      if (!ev.dataTransfer) {
        return;
      }

      currentDraggable = null;

      clearDragImage();

      binding.value.onLeaveOrEnd?.(ev.dataTransfer);
      currentDropTarget?.onLeaveOrEnd?.();
    })
  },
  updated: (el: HTMLElement, binding) => {
    if (!binding.value) {
      el.removeAttribute("draggable");
    }
    else {
      el.setAttribute("draggable", "true")
    }
  },
}

const xDepth = Symbol("dropTargetElementMouseDepth")

export interface ilDropTarget {
  /**
   * Tracks element mouse in/out counts, which would be nice if we didn't need to do, but seems necessary on Webkit.
   * This is _not_ to be provided by directive use-sites, but rather is an internal-use-only thing.
   * It is lazily initialized just before use.
   */
  [xDepth]?: number,
  /**
   * Return `true` to positvely state that the element is currently droppable
   */
  onEnter: (dataTransfer: DataTransfer, evt: DragEvent) => boolean,
  /**
   * Return `true` to positively state that the element is currently droppable
   * It seems like often the callbacks are the same as onEnter, in which case "sameAsOnEnter" means "use onEnter as onDragOver"
   */
  onDragOver: ((dataTransfer: DataTransfer) => boolean) | "sameAsOnEnter",
  /**
   * It seems we can't reliably distinguish between a leave and an end event across browsers,
   * especially considering an escape keypress. So we explicitly treat them as a single event.
   * TODO: do we fire this after `onDrop`, or before, or if there was a drop we don't fire it?
   */
  onLeaveOrEnd?: () => void,
  onDrop?: (dataTransfer: DataTransfer, event: DragEvent) => void,
}

/**
 * related reading:
 * https://bugs.webkit.org/show_bug.cgi?id=66547https://bugs.webkit.org/show_bug.cgi?id=66547
 */
export const vueDirective_ilDropTarget : Directive<any, ilDropTarget> = {
  mounted: (root: HTMLElement, binding, vnode) => {
    root.addEventListener("dragenter", (ev) => {
      if (!ev.dataTransfer) {
        ev.stopPropagation()
        return;
      }

      binding.value[xDepth] ??= 0
      binding.value[xDepth] += 1

      // need to sync with draggable source?
      ev.dataTransfer.dropEffect = "move"
      ev.dataTransfer.effectAllowed = "move"

      if (binding.value.onEnter(ev.dataTransfer, ev)) {
        currentDropTarget = binding.value;
        ev.preventDefault()
      }
    });

    root.addEventListener("dragover", (ev) => {
      if (!ev.dataTransfer) {
        return;
      }

      const droppable = binding.value.onDragOver === "sameAsOnEnter"
        ? binding.value.onEnter(ev.dataTransfer, ev)
        : binding.value.onDragOver?.(ev.dataTransfer)

      if (droppable) {
        ev.preventDefault();
      }
    });

    root.addEventListener("dragleave", ev => {
      binding.value[xDepth] ??= 0

      const hasCurrentTarget = ev.currentTarget instanceof HTMLElement;
      const hasRelatedTarget = ev.relatedTarget instanceof HTMLElement;

      const wasPressEscape = (() => {
        const chrome = hasCurrentTarget
          && !hasRelatedTarget
          && ev.screenX === 0
          && ev.screenY === 0;

        // No way to tell on firefox? But it seems we will get the right answer later
        // when we test for depth and/or `didLeaveDropTarget`
        const firefox = false;

        const webkit = ev.dataTransfer?.effectAllowed === "none";

        return chrome || firefox || webkit;
      })();

      if (wasPressEscape) {
        onDidLeave();
        return;
      }

      // on webkit this can go negative; have not observed as such on chrome/firefox but it might there, too.
      binding.value[xDepth] = Math.max(0, binding.value[xDepth] - 1)

      if (hasRelatedTarget) {
        // hasRelatedTarget=true <==seems-to-imply==> this browser is chrome/firefox
        const didLeaveDropTarget = (() => {
          // We don't support recursive drop targets, so if we "left" to some
          // contained descendant, it's the same as not leaving.
          const departedToDescendantOfDropTarget = hasCurrentTarget
            && hasRelatedTarget
            && ev.currentTarget.contains(ev.relatedTarget);

          return !departedToDescendantOfDropTarget
        })();

        if (didLeaveDropTarget) {
          onDidLeave();
          return;
        }
        else {
          return;
        }
      }

      // this case handles webkit, sometimes firefox if the user pressed escape
      if (binding.value[xDepth] === 0) {
        onDidLeave();
        return;
      }

      function onDidLeave() : void {
        binding.value[xDepth] = 0
        currentDropTarget = null;
        binding.value.onLeaveOrEnd?.();
      }
    })

    root.addEventListener("drop", (ev) => {
      if (!ev.dataTransfer) {
        return;
      }

      ev.preventDefault();
      clearDragImage();

      binding.value.onDrop?.(ev.dataTransfer, ev);
      currentDraggable?.onDrop?.()
    });
  },
  unmounted: (el, binding) => {}
}
