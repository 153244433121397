import { FormKit, FormKitMessages } from "@formkit/vue"
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2"
import { vReqT, UiOption, requireNonNull, arrayFindOrFail, forceCheckedIndexedAccess, SetEx, useAutoFocusOnMount, FK_useSomeNonFkBoolAsValidation, parseIntOr, FK_indeterminate, FK_valid, sortBy, identity } from "src/helpers/utils"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { defineComponent, computed, watch, ref } from "vue"
import { NewPoolForm, PoolData, TeamPoolUiData, TeamPoolUiDataCollection } from "./Shared"
import { TeamForTeamPoolEditor, TeamPool, TeamPoolForTeamPoolEditor } from "src/composables/InleagueApiV1.Teams"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faGripDotsVertical, faGripVertical, faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { teamDesignationAndMaybeName } from "src/components/GameScheduler/calendar/GameScheduler.shared"

import type { FormKitNode } from '@formkit/core';

export const PoolListingElement = defineComponent({
  props: {
    poolData: vReqT<PoolData>(),
    newPoolForm: vReqT<NewPoolForm>(),
  },
  emits: {
    createNewPool: () => true,
    addSelectedTeamsToPool: (_: TeamPool) => true,
    removeSelectedTeamsFromPool: (_: TeamPool) => true,
    deleteTeamPool: (_: TeamPool) => true,
    editTeamPoolName: (_: TeamPool) => true,
    saveSeedChanges: (_: {pool: TeamPool, updates: {teamID: Guid, seed: "" | Integerlike}[]}) => true,
  },
  setup(props, {emit}) {
    return () => {
      return (
        <div style="--fk-bg-input: white; --fk-padding-input:.5em;">
          <div class="mb-4 rounded-md" data-test="createNewPool">
            <FormKit type="form" actions={false} onSubmit={() => emit("createNewPool")} key={props.newPoolForm.renderKey}>
              <div class="flex gap-2 items-start" style="--fk-margin-outer:none;">
                <FormKit
                  type="text"
                  v-model={props.newPoolForm.name} validation={[["required"], ["length", STRLEN_POOL_NAME_MIN, STRLEN_POOL_NAME_MAX]]}
                  name="Name"
                  data-test="name"
                />
                <Btn2 type="submit" class="p-2">Create new pool</Btn2>
              </div>
              <div class="hidden">
                {/*don't show global "sorry form is not filled out properly" message*/}
                <FormKitMessages/>
              </div>
            </FormKit>
          </div>
          <PoolAvailableTeamsElement
            data-test="PoolAvailableTeamsElement"
            class="my-6 bg-white"
            availableTeams={props.poolData.apiData.availableTeams}
            selectedAddTheseTeamIDs={props.poolData.selectedAddTheseTeamIDs}
            availableTargetPools={props.poolData.apiData.pools}
            onAddSelectedTeams={pool => emit("addSelectedTeamsToPool", pool)}
          />
          {
            props.poolData.apiData.pools.length === 0
              ? (
                <div class="shadow-md rounded-md">
                  <div class="p-2 bg-gray-200 rounded-t-md">
                    Pools
                  </div>
                  <div class="p-2">
                    No current pools
                  </div>
                </div>
              )
              : props.poolData.apiData.pools.map(pool => {
                  return (
                    <PoolEditorElement
                      data-test={pool.poolName}
                      key={pool.poolUID}
                      class="my-6 bg-white"
                      pool={pool}
                      uiData={props.poolData.uiData}
                      selectedRemoveTheseTeamIDs={requireNonNull(props.poolData.selectedRemoveTheseTeamIDs[pool.poolUID])}
                      onAddSelectedTeamsToPool={() => emit("addSelectedTeamsToPool", pool)}
                      onRemoveSelectedTeamsFromPool={() => emit("removeSelectedTeamsFromPool", pool)}
                      onDeleteTeamPool={() => emit("deleteTeamPool", pool)}
                      onEditTeamPoolName={() => emit("editTeamPoolName", pool)}
                      onSaveSeedChanges={updates => emit("saveSeedChanges", {pool, updates})}
                    />
                  )
              })
          }
        </div>
      )
    }
  }
})

const PoolEditorElement = defineComponent({
  props: {
    pool: vReqT<TeamPoolForTeamPoolEditor>(),
    uiData: vReqT<TeamPoolUiDataCollection>(),
    selectedRemoveTheseTeamIDs: vReqT<SetEx<Guid>>(),
  },
  emits: {
    addSelectedTeamsToPool: () => true,
    removeSelectedTeamsFromPool: () => true,
    deleteTeamPool: () => true,
    editTeamPoolName: () => true,
    saveSeedChanges: (_changed: {teamID: Guid, seed: "" | Integerlike}[]) => true,
  },
  setup(props, ctx) {
    const teamHasDirtySeedValue = (team: TeamForTeamPoolEditor) : boolean => {
      const uiData = props.uiData.getOrFail(team)
      const pristine = parseIntOr(team.seed, null)
      const dirty = parseIntOr(uiData.dirty.seed, null)
      return pristine !== dirty
    }

    const hasSomeDirtySeedValue = computed<boolean>(() => {
      for (const team of props.pool.teams) {
        if (teamHasDirtySeedValue(team)) {
          return true
        }
      }
      return false;
    })

    const saveDirtySeedValues = () => {
      const seedChanges = props
        .pool
        .teams
        .filter(team => teamHasDirtySeedValue(team))
        .map(team => {
          const uiData = props.uiData.getOrFail(team)
          return {teamID: team.teamID, seed: uiData.dirty.seed}
        })

      ctx.emit("saveSeedChanges", seedChanges)
    }

    // Map of input values to the counts of times those input values have been observed
    // If an input value has been observed more than once then it is a duplicate.
    const seedCounts = computed(() => {
      const m = new Map<number, number>()
      for (const team of props.pool.teams) {
        const seed = parseIntOr(props.uiData.getOrFail(team).dirty.seed, null)
        if (seed === null) {
          continue
        }
        const current = m.get(seed)
        m.set(seed, current === undefined ? 1 : current + 1)
      }
      return m
    })

    const noDupeSeedsValidator = (node: FormKitNode) => {
      // touch every node because that's how FK works if we want dependencies across
      // inputs to re-evalute (as a whole-form-uniqueness constraint should do).
      // And touch it fk-reactively (n.b. different than "vue-reactively" because FK introduces its own
      // reactivity subsystem on top of vue's') via FKs `at` syntax
      node.parent?.children.forEach(v => { node.at("$parent")?.at(v.name)?.value; })

      const seedVal = parseIntOr(node.value, null)

      if (seedVal === null) {
        return FK_valid
      }

      const count = seedCounts.value.get(seedVal)

      if (count === undefined) {
        // shouldn't happen though ... well, depending on asynchronicity of fk updates ...
        return FK_valid
      }

      return count === 1;
    }

    // seed options are just a list of indices, from 1 to <<num-teams>>
    const seedOptions = computed(() => {
      // need to accomodate those without seed values
      const result : UiOption[] = [{label: "", value: ""}]
      for (let i = 0; i < props.pool.teams.length; i++) {
        result.push({label: `${i+1}`, value: `${i+1}`})
      }
      return result
    })

    return () => {
      return (
        <div class="rounded-md shadow-md">
          <div class="px-2 py-1 bg-green-800 text-white rounded-t-md flex items-center gap-2">
            <button
              type="button"
              class="rounded-md py-1 px-2 cursor-pointer il-buttonlike-2"
              onClick={() => ctx.emit("editTeamPoolName")}
              data-test="editTeamPoolName"
            >
              <FontAwesomeIcon icon={faPencil}/>
            </button>
            <button
              type="button"
              class="rounded-md py-1 px-2 cursor-pointer il-buttonlike-2"
              onClick={() => ctx.emit("deleteTeamPool")}
              data-test="deleteTeamPool"
            >
              <FontAwesomeIcon icon={faTrash}/>
            </button>
            <span>{props.pool.poolName}</span>
          </div>
          <div class="p-2">
            <FormKit type="form" actions={false} onSubmit={() => saveDirtySeedValues()}>
              {
                props.pool.teams.length === 0
                  ? <div>No teams currently assigned to this pool</div>
                  : <>
                    <table>
                      <tr>
                        <th class="px-2 border font-normal text-left">Del.</th>
                        <th class="px-2 border font-normal text-left">Name</th>
                        <th class="px-2 border font-normal text-left">Seed</th>
                      </tr>
                      {
                        props.pool.teams.map((team, idx) => {
                          const uiData = props.uiData.getOrFail(team)
                          const isFirst = idx === 0
                          return (
                            <tr key={`committed/${team.teamID}`}>
                              <td class={`align-top px-2 ${isFirst ? "pt-1" : ""}`}>
                                <input
                                  type="checkbox"
                                  checked={props.selectedRemoveTheseTeamIDs.has(team.teamID)}
                                  onInput={() => props.selectedRemoveTheseTeamIDs.invert(team.teamID)}
                                  data-test={team.teamID}
                                />
                              </td>
                              <td class={`align-top px-2 ${isFirst ? "pt-1" : ""}`}>
                                <div>{teamNameText(team)}</div>
                              </td>
                              <td class={`align-top px-2 ${isFirst ? "pt-1" : ""}`}>
                                <div class="max-w-32">
                                  <FormKit
                                    type="select"
                                    options={seedOptions.value}
                                    v-model={uiData.dirty.seed}
                                    validation={[["noDupeSeedsValidator"]]}
                                    validationLabel="Seed"
                                    validationRules={{noDupeSeedsValidator}}
                                    validationMessages={{noDupeSeedsValidator: "Seed values must be unique within a pool."}}
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </table>
                    <div>
                      <div class="flex items-center gap-2">
                        <Btn2
                          class="mt-2 p-2"
                          disabled={props.selectedRemoveTheseTeamIDs.size === 0}
                          onClick={() => ctx.emit("removeSelectedTeamsFromPool")}
                          data-test="removeSelectedTeamsFromPool"
                        >Remove selected teams</Btn2>
                        <Btn2
                          class="mt-2 p-2"
                          disabled={!hasSomeDirtySeedValue.value}
                          type="submit"
                        >Save Seed Changes</Btn2>
                      </div>
                    </div>
                  </>
              }
            </FormKit>
          </div>
        </div>
      )
    }
  }
})

const PoolAvailableTeamsElement = defineComponent({
  props: {
    availableTeams: vReqT<TeamForTeamPoolEditor[]>(),
    selectedAddTheseTeamIDs: vReqT<SetEx<Guid>>(),
    availableTargetPools: vReqT<TeamPoolForTeamPoolEditor[]>(),
  },
  emits: {
    addSelectedTeams: (_: TeamPoolForTeamPoolEditor) => true,
  },
  setup(props, {emit}) {
    const selectedTargetPoolUID = ref<"" | Guid>("")
    const poolOptions = computed<UiOption[]>(() => {
      if (props.availableTargetPools.length === 0) {
        return [{label: "No available pools", value: "", attrs: {disabled: true}}]
      }
      else {
        return props.availableTargetPools.map(pool => {
          return {
            label: pool.poolName,
            value: pool.poolUID
          }
        })
      }
    })

    watch(() => poolOptions.value, () => {
      if (!poolOptions.value.find(opt => opt.value === selectedTargetPoolUID.value)) {
        selectedTargetPoolUID.value = ""
      }

      selectedTargetPoolUID.value ||= forceCheckedIndexedAccess(poolOptions.value, 0)?.value || "";
    }, {immediate: true})

    /**
     * If there is no selected pool this will throw
     */
    const getSelectedPool = () => {
      return arrayFindOrFail(props.availableTargetPools, pool => pool.poolUID === selectedTargetPoolUID.value)
    }

    return () => {
      return (
        <div class="rounded-md shadow-md">
          <div class="px-2 py-1 bg-green-800 text-white rounded-t-md flex items-center gap-2">
            <span>Available Teams</span>
          </div>
          <div class="p-2">
            {
              props.availableTeams.length === 0
                ? <div>No other teams are available for assignment to pools for the selected season, program, and division.</div>
                : (
                  <div>
                    {
                      props.availableTeams.map(v => {
                        return (
                          <div key={`available/${v.teamID}`} class="flex items-center gap-2">
                              <input
                                type="checkbox"
                                checked={props.selectedAddTheseTeamIDs.has(v.teamID)}
                                onInput={() => props.selectedAddTheseTeamIDs.invert(v.teamID)}
                                data-test={v.teamID}
                              />
                            <div>{teamNameText(v)}</div>
                          </div>
                        )
                      })
                    }
                    <div class="my-2" style="--fk-margin-outer:none; --fk-padding-input:.25em;">
                      <div class="text-sm font-medium">Assign to...</div>
                      <FormKit type="select" v-model={selectedTargetPoolUID.value} options={poolOptions.value} data-test="targetTeam"/>
                    </div>
                    <Btn2
                      class="mt-2 p-2"
                      disabled={props.selectedAddTheseTeamIDs.size === 0 || !selectedTargetPoolUID.value}
                      onClick={() => emit("addSelectedTeams", getSelectedPool())}
                      data-test="commit"
                    >Assign selected teams</Btn2>
                  </div>
                )
            }
          </div>
        </div>
      )
    }
  }
})

const STRLEN_POOL_NAME_MIN = 1
const STRLEN_POOL_NAME_MAX = 150

const teamNameText = (v: TeamForTeamPoolEditor) => {
  return teamDesignationAndMaybeName({teamDesignation: v.team, teamName: v.teamName, teamID: v.teamID})
}

export const PoolNameEditorModal = defineComponent({
  props: {
    initialValue: vReqT<string>()
  },
  emits: {
    commit: (_newName: string) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const name = ref(props.initialValue)
    const focusAttr = useAutoFocusOnMount()
    return () => {
      return (
        <div>
          <FormKit type="form" actions={false} onSubmit={() => ctx.emit("commit", name.value)}>
            <FormKit type="text" v-model={name.value} validation={[["required"]]} validationLabel="Pool Name" {...focusAttr}/>
            <div class="flex gap-2">
              <Btn2 class="px-2 py-1" type="submit">Submit</Btn2>
              <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})
