import { axiosInstance } from "src/boot/axios"
import dayjs from "dayjs";

import * as ilapi from "src/composables/InleagueApiV1";

type Awaited<T> = T extends Promise<infer U> ? Awaited<U> : T;

/**
 * loaded once per application instance, should not change often
 */
let __cached__registrationUnavailabilityListing: Awaited<ReturnType<typeof ilapi.getVolunteerRegistrationUnavailabilityListing>> | undefined = undefined;

export async function getVolunteerRegistrationUnavailabilityListing() {
  if (__cached__registrationUnavailabilityListing === undefined) {
    __cached__registrationUnavailabilityListing = await ilapi.getVolunteerRegistrationUnavailabilityListing(axiosInstance);
    return __cached__registrationUnavailabilityListing;
  }
  else {
    return __cached__registrationUnavailabilityListing;
  }
}

export enum Availability { AVAILABLE = 1, NOT_AVAILABLE = 2 }

export async function volunteerRegistrationAvailability(): Promise<Availability> {
  // we compare just times, which is sufficient for current use case

  const v = await getVolunteerRegistrationUnavailabilityListing();
  const from = dayjs(v.from);
  const to = dayjs(v.to);
  const now = dayjs()
    .set("year", 1970)
    .set("month", 0)
    .set("date", 1);

  const isOnOrAfter_start = now.isAfter(from, "s") || now.isSame(from, "s");
  const isBefore_end = now.isBefore(to, "s");

  if (isOnOrAfter_start && isBefore_end) {
    return Availability.NOT_AVAILABLE;
  }
  else {
    return Availability.AVAILABLE;
  }
}
