<template lang="pug">
//-
//- formkit todo:
//-  - don't show the "global" "sorry not all fields are filled out correctly"
//-  - customize the above message, clear it after a user enters more information
//-  - validation rule at group level that checks if 2 things are equal and if so issue an error;
//-    display the above duplicate error in the same place as "sorry, not all fields are filled out correctly"
//-
FormKit(type="form" @submit="doSubmit" :actions="false")
  FormKit(
    type="text" v-model="keepID" label="KeepID" data-test="keepID"
    autocomplete="off" :name="freshNameForTryEvadeIgnoreAutocompleteOffHeuristics()"
    :validation="formKitValidation.guidInput.validation"
    :validation-messages="formKitValidation.guidInput.validationMessages"
  )
  FormKit(
    type="text" v-model="dupID" label="DupID" data-test="dupID"
    autocomplete="off" :name="freshNameForTryEvadeIgnoreAutocompleteOffHeuristics()"
    :validation="formKitValidation.guidInput.validation"
    :validation-messages="formKitValidation.guidInput.validationMessages"
  )
  FormKit(type="checkbox" v-model="dryRun" label="Dry run" data-test="dryRun")
  TBtn(type="submit" label="Submit" data-test="submit")
template(v-if="maybeHtml")
  div.m-2.shadow.flex-col.items-center
    div.py-1.shadow.flex.justify-center(style="width:100%; position:sticky; top:0; background-color:white;") Before (keep)
    div.p-1(v-html="maybeHtml.before.keep")
  div.m-2.shadow.flex-col.items-center
    div.py-1.shadow.flex.justify-center(style="width:100%; position:sticky; top:0; background-color:white;") Before (dup)
    div.p-1(v-html="maybeHtml.before.dup")
  div.m-2.shadow.flex-col.items-center
    div.py-1.shadow.flex.justify-center(style="width:100%; position:sticky; top:0; background-color:white;") After
    div.p-1(v-html="maybeHtml.after")
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from "vue";
import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import * as ilapi from "src/composables/InleagueApiV1"
import { FormKitNode } from "@formkit/core";
import { useIziToast } from "src/helpers/utils";

type MaybeHtmlResults =
  | null
  | {before: {keep: string, dup: string}, after: string}

const guidPatternNoCase = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;

export default defineComponent({
  setup() {
    const keepID = ref("");
    const dupID = ref("");
    const dryRun = ref(true);
    const maybeHtml = ref<MaybeHtmlResults>(null);

    const doSubmit = async (_data: unknown, node: FormKitNode) => {
      if (keepID.value.toUpperCase() === dupID.value.toUpperCase()) {
        node.setErrors(["Cannot merge a player into theirself."])
        return;
      }

      const savedIDs = {
        keepID: keepID.value,
        dupID: dupID.value
      }

      try {
        const result = await ilapi.mergePlayers(
          axiosInstance, {
            keepID: keepID.value,
            dupID: dupID.value,
            dryRun: dryRun.value
          });

        maybeHtml.value = {
          before: {
            keep: result.html.before[savedIDs.keepID],
            dup: result.html.before[savedIDs.dupID]
          },
          after: result.html.after
        }
      }
      catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    return {
      keepID,
      dupID,
      dryRun,
      doSubmit,
      freshNameForTryEvadeIgnoreAutocompleteOffHeuristics,
      maybeHtml,
      formKitValidation: {
        guidInput: {
          validation: [["required"], ["matches", guidPatternNoCase]],
          validationMessages: {
            matches: "Must be a GUID"
          }
        }
      },
    }
  }
})

/**
 * randomish 32 char hex string
 * chrome is awful with ignoring autocomplete=off.
 * Evading it's ML-powered-ignore-the-autocomplete-off-attribute is not super feasible but we'll try.
 * We're attempting to "evade the ignore"
 */
function freshNameForTryEvadeIgnoreAutocompleteOffHeuristics() {
  const result : string[] = [];
  for (let i = 0; i < 32; i++) {
    result.push(Math.floor(Math.random() * 16).toString(16));
  }
  return result.join("");
}

</script>
