<template lang="pug">
TDialog
  .text-xl Toggle competition registration waitlist
  div.divide-y
    div.p-2.text-left
      | This will change {{ targetPlayerUiName }}'s {{ targetCompRegCompetitionUiName }} registration's waitlist status.
    div.p-2.text-left
      div.flex.flex-col.items-center.text-base
        FormKit(
          type="toggle"
          outer-class="$reset mb-0"
          v-model="waitlistToggleState.waitlistValue.value"
        )
        div(v-if="!waitlistToggleState.changeInfo.value.didChange") Is: {{ waitlistToggleState.is }}
        div(v-else) Change to: {{ waitlistToggleState.changeInfo.value.willBe }}
      div.flex.justify-center.text-base
        FormKit(
          :disabled="!waitlistToggleState.changeInfo.value.didChange"
          type='button'
          label=`Save change`
          wrapper-class='mt-2',
          @click="commitToggle()"
          :classes="{ input: 'cancelBtn' }"
        )
      div.flex.justify-center.text-base
        FormKit(
          type='button'
          @click="cancel()"
          label="Cancel"
          style="width:100%"
        )
</template>

<script lang="ts">
import { defineComponent, ref, computed, Ref, ComputedRef } from 'vue'
import { propsDef, emitsDef } from "./ToggleCompetitionRegistrationWaitlistModal";
import { VueNeverUnwrappable } from "src/helpers/utils"

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, { emit }) {
    const targetCompRegCompetitionUiName = computed(() => {
      return props.target.competition.competition;
    });

    // probably don't really need computed, we assume if the caller changes props then they will remount
    // so this is effectively static
    const targetPlayerUiName = computed(() => `${props.target.player.playerFirstName} ${props.target.player.playerLastName}`);

    const waitlistToggleState = ref(WaitlistToggleState(!!props.target.competitionRegistration.waitlist));

    const commitToggle = () => {
      const freshWaitlistValue = waitlistToggleState.value.waitlistValue.value;
      emit("commitToggle", {competitionRegistration: props.target.competitionRegistration, freshWaitlistValue});
    }

    const cancel = () => emit("cancel");

    return {
      waitlistToggleState,
      commitToggle,
      cancel,
      targetCompRegCompetitionUiName,
      targetPlayerUiName,
    }
  }
})

type WaitlistChangeInfo =
  | {didChange: false}
  | {didChange: true, willBe: string};

interface WaitlistToggleState {
  waitlistValue: Ref<boolean>,
  readonly is: string,
  changeInfo: ComputedRef<WaitlistChangeInfo>
}

function WaitlistToggleState(initialWaitlistValue: boolean) : VueNeverUnwrappable<WaitlistToggleState> {
  const waitlistValue = ref(initialWaitlistValue);
  const is = initialWaitlistValue ? "Waitlisted" : "Not waitlisted";
  const changeInfo = computed<WaitlistChangeInfo>(() => {
    const didChange = waitlistValue.value !== initialWaitlistValue;
    if (didChange) {
      return {
        didChange: true,
        willBe: waitlistValue.value ? "Waitlisted" : "Not waitlisted"
      }
    }
    else {
      return {didChange: false};
    }
  });

  return VueNeverUnwrappable<WaitlistToggleState>({
    waitlistValue,
    is,
    changeInfo
  })
}
</script>
