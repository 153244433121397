import { computed, defineComponent } from "vue";
import * as ilteam from "src/composables/InleagueApiV1.Teams"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { FormKit } from "@formkit/vue";
import { arrayFindOrFail, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { GetTeamSeasonPlayersResponse } from "src/composables/InleagueApiV1.Teams";

export const EditTeamSeasonPlayersElement = defineComponent({
  props: {
    teamID: vReqT<Guid>(),
    seasonUID: vReqT<Guid>(),
    form: vReqT<TeamSeasonPlayersForm>(),
    doUpdate: vReqT<() => Promise<void>>(),
  },
  setup(props) {
    const submit = async () => {
      await props.doUpdate();
    }

    /**
     * map of (assignmentID -> player assignment) and (loanID -> playerloan)
     * It is a bug/error if the form contains references to assignmentIDs or loanIDs that do not exist in the resulting mappings.
     */
    const lookup = computed(() => {
      const result = {
        playerAssignments: {} as {[assignmentID: iltypes.Guid]: ilteam.GetTeamSeasonPlayersResponse["playerAssignments"][number]},
        playerLoans: {} as {[loanID: iltypes.Guid]: ilteam.GetTeamSeasonPlayersResponse["playerLoans"][number]},
      } as const;

      props
        .form
        .playerAssignments
        .forEach(pa => {
          result.playerAssignments[pa.pristine.assignmentID] = arrayFindOrFail(props.form.playerAssignments, (v) => v.assignmentID === pa.assignmentID).pristine
        })

      props
        .form
        .playerLoans
        .forEach(pl => {
          result.playerLoans[pl.loanID] = arrayFindOrFail(props.form.playerLoans, (v) => v.loanID === pl.loanID).pristine
        })

      return result;
    })

    return () => {
      return (
        <div data-test="editPlayers">
          <div class="text-lg">Team uniform numbers</div>
          <div class="p-2">
            <FormKit type="form" actions={false} onSubmit={submit} style="--fk-margin-outer: none;">
              <table class="w-full">
                <tr class="bg-black text-white">
                  <td colspan="9999" class="p-2">
                    Player assignments
                  </td>
                </tr>
                {
                  props.form.playerAssignments.length === 0
                    ? <tr>
                      <td colspan="9999" class="p-2">
                        N/A
                      </td>
                    </tr>
                    : null
                }
                {
                  props.form.playerAssignments.map((entry, i) => {
                    const {playerFirstName, playerLastName} = lookup.value.playerAssignments[entry.assignmentID].child
                    const key = `assignment/${entry.assignmentID}`
                    return <tr class={i%2 ? "bg-gray-100" : ""} key={key} data-test={key}>
                      <td class="p-2">
                        {playerFirstName} {playerLastName}
                      </td>
                      <td class="p-2">
                        <FormKit
                          type="text"
                          label="Uniform"
                          v-model={entry.uniform}
                          data-test="uniform"
                          validation={[["matches", /^[0-9]*$/]]}
                          validationMessages={{matches: "Uniform values can only contain numbers, e.g. '9' or '09' or etc."}}
                        />
                      </td>
                    </tr>
                  })
                }
                {
                  props.form.playerLoans.length === 0
                    ? null
                    : (
                      <>
                        <tr class="bg-black text-white">
                          <td colspan="9999" class="p-2">
                            Player Loans
                          </td>
                        </tr>
                        {
                          props.form.playerLoans.map((entry,i) => {
                            const {playerFirstName, playerLastName} = lookup.value.playerLoans[entry.loanID].child
                            const key = `loan/${entry.loanID}`
                            return <tr class={i%2 ? "bg-gray-100" : ""} key={key} data-test={key}>
                              <td class="p-2">
                                {playerFirstName} {playerLastName}
                              </td>
                              <td class="p-2">
                                <FormKit type="text" v-model={entry.uniform} label="Uniform" data-test="uniform"/>
                              </td>
                            </tr>
                          })
                        }
                      </>
                    )
                }
                <tr>
                  <td colspan="9999">
                    <div class="border-t border-gray-700">
                      <div class="p-2 flex">
                        {
                          props.form.playerAssignments.length > 0 || props.form.playerLoans.length > 0
                            ? <t-btn type="submit" class="ml-auto" margin={false}>Save changes</t-btn>
                            : null
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </FormKit>
          </div>
        </div>
      )
    }
  }
})

export interface TeamSeasonPlayersForm {
  playerAssignments: PlayerAssignmentForm[],
  playerLoans: PlayerLoanForm[]
}

export interface PlayerAssignmentForm {
  readonly pristine: GetTeamSeasonPlayersResponse["playerAssignments"][number],
  readonly type: "playerAssignment",
  readonly assignmentID: iltypes.Guid,
  uniform: string,
}

export interface PlayerLoanForm {
  readonly pristine: GetTeamSeasonPlayersResponse["playerLoans"][number],
  readonly type: "playerLoan",
  readonly loanID: iltypes.Guid,
  uniform: string,
}
