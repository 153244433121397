import { boot } from 'quasar/wrappers'

import { LoggerConfig } from 'src/modules/LoggerService'

import { Client } from 'src/store/Client'

import { System } from "src/store/System"

import "./TypeBoxSetup" // side-effects only

/**
 * In devmode, return hooks for use with playwright
 * In production, return undefined
 */
const $iltest = (() => {
  if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
    const v = {
      LoggerConfig,
      System,
      Client,
    } as const;

    return v;
  }

  return undefined;
})()

// the type of $iltest is the return type of $iltest, omitting undefined
export type $iltest = Exclude<typeof $iltest, undefined>;

export default boot(({ app, store }) => {
  const $publicPath = process.env.MODE === 'capacitor' ? '' : '/app'
  Client.directCommit_setPublicPath($publicPath)

  System.directCommit_setIsMobile(process.env.isMobile === true)

  if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
    (window as any).$iltest = $iltest;
    // Run the "playwright init hook", if it exists.
    // This can be installed via `page.addInitScript` in a test.
    (window as any).$iltest_onInit?.($iltest);
  }
})
