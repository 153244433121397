import { FormKit } from "@formkit/vue";
import { axiosInstance, getGlobalApiURL } from "src/boot/AxiosInstances";
import { computed, defineComponent, onMounted, ref } from "vue";
import { formkitSingleFileFormRef } from "../../UserInterface/Modal.photoUpload.common";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { UiOption, arrayFindOrFail, checkFileSnapshotConsistency, downloadFromObjectURL, exhaustiveCaseGuard, fileSnapshotInconsistencyWarning, forceCheckedIndexedAccess, sortBy, sortByDayJS, sortByMany, useIziToast } from "src/helpers/utils";
import { UiCentricGameInfo, updateGameTeamAssignmentsDryRunFromXlsx, updateGameTeamAssignmentsFromXlsx, xlsxDownloadURLs } from "src/composables/InleagueApiV1.GameSchedulerXlsx";
import { DryRunResults } from "./GameTeamAssignments.impl";
import { Guid } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { getCompetitionsOrFail } from "src/store/Competitions";
import { buildLegacyLink } from "src/boot/auth";
import type { FormKitNode } from "@formkit/core"
import dayjs from "dayjs";
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate";
import { SoccerBall } from "../../SVGs";

export default defineComponent({
  setup() {
    const fkFileRef = formkitSingleFileFormRef()
    const fileUploadInputRef = ref<null | {node: FormKitNode}>(null);

    const iziToast = useIziToast();

    type Data =
      | {mode: "pending"}
      | {mode: "dry-run", data: UiCentricGameInfo[], file: File}
      | {mode: "complete", data: UiCentricGameInfo[]}

    const data = ref<Readonly<Data>>({mode: "pending"});

    const doSubmitDryRun = async () => {
      const file = fkFileRef.file.value;
      if (!file) {
        throw Error("form should have prevented this")
      }

      try {
        if (!await checkFileSnapshotConsistency(file)) {
          // this is a better error than not performing this check and getting a cryptic network failure because FromData can't read it's source file.
          iziToast.warning({message: fileSnapshotInconsistencyWarning})
          data.value = {mode: "pending"}
          fileUploadInputRef.value?.node.reset();
          return;
        }

        const results = await updateGameTeamAssignmentsDryRunFromXlsx(axiosInstance, {file});

        results.sort(sortByDayJS(_ => _.gameStart, "seconds", "desc"))

        data.value = {
          mode: "dry-run",
          data: results,
          file
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doSubmitActual = async () => {
      if (data.value.mode !== "dry-run") {
        throw Error("illegal state");
      }

      try {
        if (!await checkFileSnapshotConsistency(data.value.file)) {
          // this is a better error than not performing this check and getting a cryptic network failure because FromData can't read it's source file.
          iziToast.warning({message: fileSnapshotInconsistencyWarning})
          data.value = {mode: "pending"}
          fileUploadInputRef.value?.node.reset();
          return;
        }

        const created = await updateGameTeamAssignmentsFromXlsx(axiosInstance, {file: data.value.file});
        data.value = {
          mode: "complete",
          data: created
        }

        const gameStr = created.length === 1 ? "game" : "games";

        iziToast.success({message: `Updated ${created.length} ${gameStr}`})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const selectedCompetitionUID = ref<"" | Guid>("");
    const selectedDivIDs = ref<Guid[]>([])

    const minDate = dayjs().subtract(maxGameLookback.qty, maxGameLookback.unit);
    const dateFromInclusive = ref(dayjs().subtract(1, "week").format(DAYJS_FORMAT_HTML_DATE))
    const dateToInclusive = ref(dayjs().add(1, "month").format(DAYJS_FORMAT_HTML_DATE))

    const competitionOptions = ref<UiOption[]>([])
    const divisionOptions = ref<UiOption[]>([])
    const ready = ref(false);
    const downloadIsBusy = ref(false)

    const xlsxTemplateUrlConfig = computed(() => {
      if (selectedCompetitionUID.value && selectedDivIDs.value.length) {
        return {
          url: xlsxDownloadURLs.xlsxUpdateGameTeamAssignmentsTemplateUrl(
            getGlobalApiURL(), {
              competitionUID: selectedCompetitionUID.value,
              divIDs: selectedDivIDs.value,
              dateFromInclusive: dateFromInclusive.value,
              dateToInclusive: dateToInclusive.value,

            }),
          disabled: false,
        }
      }
      return {
        disabled: true,
      }
    })

    const doDownloadXlsxTemplate = async () => {
      if (!xlsxTemplateUrlConfig.value.url) {
        throw Error("illegal state");
      }
      try {
        const filename = arrayFindOrFail(competitionOptions.value, opt => opt.value === selectedCompetitionUID.value).label + " Match-Ups Template.xlsx";
        let bytes : Blob;
        try {
          downloadIsBusy.value = true;
          bytes = (await axiosInstance.get(xlsxTemplateUrlConfig.value.url, {responseType: "blob"})).data;
        }
        finally {
          downloadIsBusy.value = false;
        }
        await downloadFromObjectURL(bytes, filename);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      const competitions = [...(await getCompetitionsOrFail({includeDisabled: false})).value].sort(sortBy(_ => _.competitionID))
      const divisions = [...(await Client.getDivisions())]
        .sort(
          sortByMany(
            sortBy(_ => _.divNum),
            sortBy(_ => _.gender),
          )
        );
      competitionOptions.value = competitions.map(v => ({label: v.competition, value: v.competitionUID}))
      divisionOptions.value = divisions.map(v => ({label: v.displayName || v.division || "?", value: v.divID}))

      selectedCompetitionUID.value = forceCheckedIndexedAccess(competitionOptions.value, 0)?.value || "";

      ready.value = true;
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div data-test="R_GameTeamAssignments">
           <h2>Game Schedule Upload - Assign Match-Ups</h2>
           <div>
              This tool will generate a template from existing game slots that can be populated with team match-ups and then processed below to assign teams to games.
           </div>
           <ul class="list-disc ml-10 mt-2 mb-2 underline">
            <li><a href={buildLegacyLink(Client.value.instanceConfig.appdomain, '/gameScheduler', '')} target="_blank">Game Scheduler Tool (inLeague Classic)</a></li>
            <li><a href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Game-Scheduling-Overview" target="_blank">Game Scheduling Documentation</a></li>
          </ul>
          <div>Select the program, division(s), and date range below to generate the template. </div>
          <div class="mt-2">
            <div>
              <div>Program</div>
              <FormKit type="select" options={competitionOptions.value} v-model={selectedCompetitionUID.value}/>
              <div>Divisions</div>
              <FormKit type="select" multiple {...{size:5}} options={divisionOptions.value} v-model={selectedDivIDs.value}/>
              <div>Date range</div>
              <div>
                <FormKit type="date" min={minDate.format(DAYJS_FORMAT_HTML_DATE)} v-model={dateFromInclusive.value} label="From"/>
                <FormKit type="date" min={minDate.format(DAYJS_FORMAT_HTML_DATE)} v-model={dateToInclusive.value} label="To"/>
              </div>
            </div>
            <t-btn
              onClick={() => doDownloadXlsxTemplate()}
              style="padding: .5em 1em .5em .75em"
              disable={xlsxTemplateUrlConfig.value.disabled || downloadIsBusy.value}
              class={`${xlsxTemplateUrlConfig.value.disabled || downloadIsBusy.value ? "bg-gray-200" : ""}`}
              type="button"
              margin={false}
            >
              {
                downloadIsBusy.value
                  ? <div class="flex items-center gap-2 text-black">
                    <SoccerBall color="black" width="1em" height="1em"/>
                    <span>Downloading...</span>
                  </div>
                  : "Download Excel template"
              }
            </t-btn>
            <div class="text-xs">
              {
                xlsxTemplateUrlConfig.value.disabled
                  ? <div class="my-2">(First, Select a Program and One or More Divisions)</div>
                  : null
              }
            </div>
          </div>

          <div class="border-b my-2"/>

          <div>
            <div>
            Upload populated template (XLSX) file here:
            </div>
            <FormKit type="form" actions={false} onSubmit={doSubmitDryRun}>
              <FormKit type="file" accept=".xlsx" v-model={fkFileRef.fk_file} ref={fileUploadInputRef} data-test="xlsxUpload"/>
              <t-btn
                margin={false}
                disable={!fkFileRef.file.value}
                class={!fkFileRef.file.value ? "bg-gray-200" : ""}
                data-test="xlsxUploadSubmit"
                type="submit">Preview Match-Ups
              </t-btn>
            </FormKit>
          </div>
          {
            data.value.mode === "pending"
              ? null
              : data.value.mode === "dry-run"
              ? (
                <FormKit type="form" actions={false} onSubmit={doSubmitActual}>
                  <div class="my-4">
                    <DryRunResults dryRunResults={data.value.data}/>
                  </div>
                  <t-btn type="submit" data-test="doSubmitActual">Looks good, save changes</t-btn>
                </FormKit>
              )
              : data.value.mode === "complete"
              ? (
                <div class="my-4">
                  <div>Updated</div>
                  <DryRunResults dryRunResults={data.value.data}/>
                </div>
              )
              : exhaustiveCaseGuard(data.value)
          }
        </div>
      )
    }
  }
})

const maxGameLookback = {qty: 1, unit: "month"} as const
