<template lang="pug">
div(data-cy='dashboard')
  AutoModal(
    data-test="CoachPhotoUploadModalController"
    :controller="coachPhotoUploadModalController"
  )
  h1.text-4xl.self-end.font-medium
    font-awesome-icon.mr-2(:icon='["fas", "home"]')
    | Dashboard
  div(class="il-new-stacking-context")
    #notifications
  Portlets
    template(#bonusPortlets)
      Portlet(v-if="coachPhotoUploadButton.shouldShow" prefix="fas" icon="cog" label="Coach photo")
        slot
          div(class="p-2")
            t-btn(
              :margin="false" data-test="coachPhotoUploadButton"
              @click="coachPhotoUploadModalController.open(coachPhotoUploadButton.userID)"
            ) Upload photo
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  Ref,
  onMounted,
  watch,
  onBeforeMount,
reactive,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getCurrentInstance } from 'vue'

import authService from 'src/helpers/authService'
import { axiosAuthBackgroundInstance, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'boot/axios'
import { clientsThemes } from 'src/helpers/clientsThemes'
import TVhtml from 'src/components/UserInterface/t-vhtml.vue'
import { Plugins } from '@capacitor/core'
import Portlets from 'src/components/Portlets/Portlets.vue'
import { Role, UserData } from 'src/interfaces/Store/user'
import iziToast from 'izitoast'
import { type Portlet as Portlet_t } from 'src/interfaces/InleagueApiV1'
import Portlet from 'src/components/Portlets/Portlet.vue'


import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { defaultCoachPhotoUploadModalController } from '../UserInterface/Modal.photoUpload.coach'
import { getLogger, maybeLog } from 'src/modules/LoggerService'
import { LoggedinLogWriter } from 'src/modules/Loggers'
import { AutoModal } from "src/components/UserInterface/Modal"

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

import * as ilapi from 'src/composables/InleagueApiV1'

export default defineComponent({
  name: 'Dashboard',
  components: {
    TVhtml,
    Portlets,
    Portlet,
    AutoModal,
  },
  setup() {
    const errors = ref(false)
    const themes = ref(clientsThemes)
    const notifications = ref([]) as Ref<ilapi.Notification[]>
    const route = useRoute()
    const router = useRouter()

    const localInstance = getCurrentInstance()

    // Computed
    const userRoles = computed(() => {
      const userData = User.value.userData as UserData
      return userData.roles
    })

    const portlets = computed<Portlet_t[]>(() => {
      return User.value.portlets
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const isImpersonating = computed(() => {
      return (User.value.userData as UserData).isImpersonating
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const jwt = computed(() => {
      return User.jwtToken
    })

    // Methods
    const testPushPayload: any = {
      additionalData: {},
      subtitle: 'Test Subtitle',
      title: 'Test Title2',
      sound: 'default',
      body: 'Test Message',
      notificationID: 'de1a26fc-c8b1-4b82-bd86-e64b7c9f35ac',
      actionButtons: [{ id: 'test', text: 'Open Message' }],
    }
    const openMessagePushTest = async () => {
      testPushPayload.additionalData.appPage = 'messages'
      testPushPayload.additionalData.conversationID =
        'B3985884-741D-458C-BA48-D2756AC80785'
      await User.notificationOpenedCallback(testPushPayload)
    }
    const openEventPushTest = async () => {
      testPushPayload.additionalData.appPage = 'teamEvent'
      testPushPayload.additionalData.eventID =
        '5C6DF70C-D8A9-46C3-89EA-457C29D9099C'
      await User.notificationOpenedCallback(testPushPayload)
    }

    const localAuthService = (
      userRoles: string[],
      ...requiredRoles: Role[]
    ) => {
      authService(userRoles, ...requiredRoles)
    }

    const doGetNotifications = async () : Promise<void> => {
      try {
        notifications.value = await ilapi.getNotifications(axiosInstance);
      } catch (err) {
        // after logging, discard the error. If getNotifications fails the rest of the app is still fine.
        void maybeLog(getLogger(LoggedinLogWriter), "warning", "dashboard/doGetNotifications", err, getCurrentInstance());
      }
    }

    const toLink = (url: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(url)
    }

    const displayNotifications = () => {
      const TOAST_CONTAINER_SELECTOR = "#notifications";
      if (!document.querySelector(TOAST_CONTAINER_SELECTOR)) {
        //
        // iziToast makes direct writes into DOM, meaning it's not aware of component lifecycle.
        // If the component was unmounted, #notifications won't exist, and asking iziToast to do
        // work against the element will fail.
        //
        // Here, the component has unmounted (generally, during the await for the notifications that we intend to mount),
        // so there's nothing to do.
        //
        return;
      }

      for (let i = 0; i < notifications.value.length; i++) {
        const options = {
          message: notifications.value[i].text,
          close: notifications.value[i].dismissible,
          icon: `fas fa-${notifications.value[i].fontAwesome} fontAwesomeOpacity`,
          imageWidth: 75,
          layout: 1,
          target: TOAST_CONTAINER_SELECTOR,
          timeout: false,
          buttons: [
            [
              `<button> ${notifications.value[i].buttonText}</button>`,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              (instance: never, toast: never) => {
                toLink(notifications.value[i].buttonURL)
              },
            ],
          ],
        }
        if (notifications.value[i].dismissible) {
          localInstance?.appContext.config.globalProperties.$toast.warning(
            options
          )
        } else {
          localInstance?.appContext.config.globalProperties.$toast.error(
            options
          )
        }
      }
    }

    const openHTTPSLink = async () => {
      const { Browser } = Plugins
      await Browser.open({
        url: `https://inleague-test-quasar-linnae.loca.lt/?${jwt.value}`,
        presentationStyle: 'fullscreen',
      })
    }

    const logOut = async () => {
      localInstance?.appContext.config.globalProperties.$toast.info({
        message: 'in logOut',
      })
      await User.logoutUser({ route: route })
    }

    const invalidateToken = () => {
      // just for testing
      User.jwtToken = 'NOT_A_VALID_TOKEN!'
    }

    const updatePortlets = async () => {
      const portlets = await User.getPortlets()
      User.directCommit_setPortlets(portlets)
      User.directCommit_setPortletTimestamp(new Date())
    }

    watch([isLoggedIn, isImpersonating], async () => {
      if (isLoggedIn.value) {
        await updatePortlets()
        await doGetNotifications()
        displayNotifications()
      } else {
        iziToast.destroy()
      }
    })

    // Lifecycles
    onBeforeMount(async () => {
      if (isMobile.value && !isLoggedIn.value) {
        // console.log('in dashboard mounting, pushing to mobile landing')
        await router.push({ name: 'mobile-landing' })
      }
    })

    const needsCoachPhotoInfo = ref<ilvolunteer.NeedsCoachPhotoInfo[]>([]);
    const coachPhotoUploadButton = computed<{shouldShow: false} | {shouldShow: true, userID: iltypes.Guid}>(() => {
      return needsCoachPhotoInfo.value.find(v => v.userID === User.value.userID && !v.isPhotoLocked && v.hasAtLeastOneCoachAssignmentForCompRequiringPhoto)
        ? {shouldShow: true, userID: User.value.userID}
        : {shouldShow: false}
    })

    const coachPhotoUploadModalController = reactive(
      defaultCoachPhotoUploadModalController(
        axiosAuthBackgroundInstance,
        (userID) => {
          iziToast.success({message: "Photo uploaded"})
          // on success, simulate receiving an update from the backend that this user's photo status is now locked
          const localUpdateable = needsCoachPhotoInfo.value.find(_ => _.userID === userID);
          if (localUpdateable) {
            localUpdateable.isPhotoLocked = true;
          }
        }
      ));

    //
    // marker for "most recent request" for the coach photo, because
    // login/logout can cycle through them
    //
    let __coachPhotosRequestID = 0;
    watch(() => User.value.userID, () => {
      //  - waiting is non-blocking, there is no spinner
      //    - hence, we don't wait on it
      //  - the user doesn't know this is happening
      //  - on failure, don't show an error, though we do try to silently log it

      __coachPhotosRequestID += 1;
      const saved__coachPhotosRequestID = __coachPhotosRequestID;

      requestOrImmediatelyNothing()
        .then(result => {
          if (__coachPhotosRequestID === saved__coachPhotosRequestID) {
            // ok, this request is the most recent
            needsCoachPhotoInfo.value = result;
          }
          else {
            // some other request is more recent
          }
        })
        .catch(err => {
          // try once to log in the background
          maybeLog(getLogger(LoggedinLogWriter), "warning", "dashboard/who-needs-coach-photos", err);
        })

      //
      // this component can mount for both logged in / logged out users
      // for logged out users, we synchronously materialize "nothing", otherwise,
      // hit the api.
      //
      function requestOrImmediatelyNothing() {
        return !User.value.userID
          ? Promise.resolve([])
          : ilvolunteer.getWhoNeedsCoachPhotos(
              freshNoToastLoggedInAxiosInstance(), {
              seasonUID: Client.value.instanceConfig.currentseasonuid,
              userIDs: [User.value.userID]
          })
      }
    }, {immediate: true})

    onMounted(async () => {
      iziToast.destroy()
      if (isLoggedIn.value) {
        System.directCommit_setMultipleCalls(true)
        await updatePortlets()
        System.directCommit_setMultipleCalls(false)
        await doGetNotifications()
        displayNotifications()
      }
    })

    return {
      openMessagePushTest,
      openEventPushTest,
      localAuthService,
      errors,
      themes,
      route,
      userRoles,
      isLoggedIn,
      jwt,
      toLink,
      openHTTPSLink,
      logOut,
      invalidateToken,
      portlets,
      coachPhotoUploadButton,
      coachPhotoUploadModalController
    }
  },
})
</script>

<style>
.text {
  background-color: var(--one);
}

.fontAwesomeOpacity {
  opacity: 1 !important;
}
</style>
