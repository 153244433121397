import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { vOptT, vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  seasonUID: vReqT<Guid>(),
  preselectedPlayerID: vOptT<Guid>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "select-player",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {name: RouteNames.main, params: v}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {...route.params as any}
}
