import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1";

export const propsDef_competitionsSubRoute = {
  seasonUID: vReqT<Guid>(),
  playerID: vReqT<Guid>(),
} as const;

export const propsDef_competitionsVolunteerSubroute = {
  // same as "competitions" subroute
  seasonUID: vReqT<Guid>(),
  playerID: vReqT<Guid>(),
  // unique to this subroute
  familyID: vReqT<Guid>(),
  userID: vReqT<Guid>(),
  competitionUIDs: vReqT<Guid[]>(),
} as const;

export type Props_CompetitionsSubRoute = ExtractPropTypes<typeof propsDef_competitionsSubRoute>
export type Props_CompetitionsVolunteerSubRoute = ExtractPropTypes<typeof propsDef_competitionsVolunteerSubroute>

export const RouteNames = {
  main: "select-competitions.root",
  selectCompetitions: "select-competitions.select-competitions",
  postSelectCompUpdateVolInfo: "select-competitions.after-select-comp-update-vol-info"
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

export function routeDetailToRouteLocation(
  name: typeof RouteNames.selectCompetitions,
  v: Props_CompetitionsSubRoute) : RouteLocationRaw;
export function routeDetailToRouteLocation(
  name: typeof RouteNames.postSelectCompUpdateVolInfo,
  v: Props_CompetitionsVolunteerSubRoute) : RouteLocationRaw;
export function routeDetailToRouteLocation(
  name: typeof RouteNames.selectCompetitions | typeof RouteNames.postSelectCompUpdateVolInfo,
  v: Props_CompetitionsSubRoute | Props_CompetitionsVolunteerSubRoute) : RouteLocationRaw {
  return {name, params: v}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props_CompetitionsSubRoute | Props_CompetitionsVolunteerSubRoute {
  return {...route.params as any}
}
