<template lang="pug">
div(v-if="ready" style="--fk-bg-input: white;")
  .flex(class="flex-col sm:flex-row")
    t-btn(label="All Games" @click="view='allGames'" width="w-1/2" :selected="view==='allGames' || view===''")
    template(v-if="isLoggedIn")
      t-btn(label="My Games" @click="view='userGames'" width="w-1/2" :selected="view==='userGames' || view===''")
    t-btn(label="All Ref Assignments" @click="view='allRefAssignments'" width="w-1/2" :selected="view==='allRefAssignments' || view===''" data-cy="allRefAssignments")
    template(v-if="isLoggedIn")
      t-btn(label="My Ref Assignments" @click="view='userRefAssignments'" width="w-1/2" :selected="view==='userRefAssignments' || view===''" data-cy="userRefAssignments")

  leagueGames(v-show="view==='allGames'" v-bind="props")

  template(v-if="isLoggedIn")
    userGames(v-show="view==='userGames'" )

  allRefAssignments(v-show="view==='allRefAssignments'" )

  template(v-if="isLoggedIn")
    refAssignments(v-show="view==='userRefAssignments'" )

</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import allRefAssignments from '../allRefAssignments.vue'
import refAssignments from '../refAssignments.vue'
import leagueGames from '../leagueGames.vue'
import userGames from '../userGames.vue'

import { propsDef } from './schedules.ilx'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'gameSchedule',
  props: propsDef(),
  components: {
    allRefAssignments,
    leagueGames,
    refAssignments,
    userGames
  },
  setup(props) {

    const isLoggedIn = computed(() => {
      return User.isLoggedIn;
    });
    const ready = ref(false);

    const view = ref(
      (() => {
        if (User.isLoggedIn) {
          if (props.competitionID) {
            return "allGames"
          }
          else {
            return "userGames"
          }
        }
        else {
          return "allGames";
        }
      })()
    );

    onMounted(async () => {
      // side-effects only

      await getCompetitionsOrFail();
      await Client.loadDivisions();

      ready.value = true;
    })

    return {
      view,
      isLoggedIn,
      ready,
      // we'll forward props to some components, almost as if they were child routes receiving path params as props
      props
    }
  },
})
</script>
