import { User } from "src/interfaces/InleagueApiV1";
import { RouterLink } from "vue-router";

export function MaybeLinkToUserEditor(props: {user: User}) : JSX.Element {
  const user = props.user;
  const nameSpan = <span>{user.firstName} {user.lastName}</span>;
  return user.objectType === "private-profile"
    ? nameSpan
    : (
      // TODO: well typed route path getter for user editor
      <RouterLink to={{name: 'user-editor', params: {userID: user.ID}}}>
        {nameSpan}
      </RouterLink>
    );
}
