import type { AxiosInstance } from "axios"
import type { User, Child, Datelike, Guid } from "src/interfaces/InleagueApiV1"
import { UncheckedAutocomplete } from "src/helpers/utils"

export interface FamilyListingEntry {
  /**
   * Unique user ID of the family's primary parent
   */
  parent1ID: Guid
  /**
   * Unique user ID of the family's secondary parent, if one is defined
   */
  parent2ID: Guid
  /**
   * Unique ID of this family object
   */
  familyID: Guid
  /**
   * Unique integer ID of this family object
   */
  familyNum: number,
  /**
   * Unique ID of the league to which this family belongs
   */
  clientID: Guid,
  /**
   * Abbreviated User record mementos for users attached to the family
   * (is this a subset of some other record we can Pick<T, ...> from?)
   */
  familyParents: {
    /**
     * Unique ID of the user
     */
    userID: Guid,
    /**
     * Unique ID of the user's membership in the parent family
     */
    relationshipID: Guid
    /**
     * Unique integer ID of the user's membership in the parent family
     */
    familyRelationshipNum: number,
    /**
     * User's first name
     */
    firstName: string,
    /**
     * User's last name
     */
    lastName: string
    /**
     * Unique ID of the family (duplicative of the parent familyID)
     */
    familyID: Guid
  }[],
  /**
   * Abbreviated player record mementos for players attached to the family
   * (is there another record we can pick these from?)
   */
  familyChildren: {
    playerFirstName: string
    playerLastName: string
    /**
    * format MM/dd/yyyy
    */
    playerBirthDate: Datelike,
    childID: Guid
    playerGender: "B" | "G"
  }[]
}

export async function getFamilyListingForUser(axios: AxiosInstance, args: {userID: Guid}) : Promise<FamilyListingEntry[]> {
  const response = await axios.get("/v1/family", { params: { userID: args.userID } } );
  return response.data.data;
}

/**
 * todo: are some of these optional, only present when expanded?
 */
export interface FamilyData {
  /** expandable */
  children?:  Child[];
  clientID:  string;
  familyID:  string;
  familyNum: number;
  /** expandable */
  parent1?:   User;
  parent1ID: string;
  /** expandable */
  parent2?:   User;
  parent2ID: string;
  /** expandable */
  parents?:   User[];
}

type FamilyData_CommonExpandables =
  | "parent1"
  | "parent2"
  | "children.registrations"
  | "parents"
  | "parents.volunteerStatus"

/**
 * Expanding children.registrations will return that entity
 * as an array of exactly the requested season's registrations, or an empty array if the particular player has no registration for that season
 */
export async function getFamilySeason(
  axios: AxiosInstance,
  args: {
    familyID: Guid,
    seasonUID: Guid,
    expand?: UncheckedAutocomplete<FamilyData_CommonExpandables>[]
}) : Promise<FamilyData> {
  const params : Record<string, any> = { seasonUID: args.seasonUID };
  if (args.expand && args.expand.length > 0) {
    params.expand = args.expand.join(",");
  }

  const response = await axios.get(`v1/familySeason/${args.familyID}`, { params: params })
  return response.data.data;
}

export async function getFamily(
  axios: AxiosInstance,
  args: {
    familyID: Guid,
    expand?: UncheckedAutocomplete<FamilyData_CommonExpandables>[]
  }
) : Promise<FamilyData> {
  const params : Record<string, any> = {};
  if (args.expand && args.expand.length > 0) {
    params.expand = args.expand.join(",");
  }
  const response = await axios.get(`v1/family/${args.familyID}`, { params: params })
  return response.data.data
}

export async function addUserToFamily(axios: AxiosInstance, args: {userID: Guid, familyID: Guid}) : Promise<void> {
  await axios.post(`v1/user/${args.userID}/family/${args.familyID}`);
}

export async function removeUserFromFamily(axios: AxiosInstance, args: {userID: Guid, familyID: Guid}) : Promise<void> {
  await axios.delete(`v1/user/${args.userID}/family/${args.familyID}`);
}
