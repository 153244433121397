<template lang="pug">
TDialog(data-test="DivisionOverride")
  template(v-if="ready")
    .text-xl Override Division Assignment
    .text-base.text-gray-500.text-left
      .mt-2 Current Division: {{ currentDivName }}
      div(class="my-2")
        template(v-if="automaticDivision")
          | Automatic Assignment Division: {{ automaticDivision.displayName }}
        template(v-else)
          | No automatic assignment division was found
      .mt-2.border-t-2.border-gray-400.pt-2
        div Assign Player to:
        Divisions(
          v-model='newDivision',
        )
      FormKit(
        type='form',
        :value='options'
        outerClass='mt-2'
        :actions='false'
      )
        FormKit(
          type='radio',
          :options='[{value: true, label: "Update overall league division and division for (this competition)"}, {value: false, label: "Update division for (this competition) only"}]'
          v-model="options.updatebasedivision"
          label='Update League Division'
          name='updatebasedivision'
        )
          template(#legend='context')
            legend(class='formkit-legend') {{ context.label }}
              button.ml-1.cursor-pointer(
                v-tooltip="{content: `Players belong to a division as part of each program in which they are registered; it is possible, but uncommon, for a player to belong to different divisions if they are registered for more than one program. They also have an 'overall' division as part of their seasonal league registration; this division membership is used for reporting only. A player's overall league division is determined by the first program for which they register in a season. The default behavior when moving a player's program registration to another division is to also update their league-wide, overall division; you may elect not to and to change only the division for this program.`}"
              )
                font-awesome-icon.mr-2.text-gray-400(
                  :icon='["fas", "question"]'
                )
        FormKit(
          type='checkbox',
          name='removeteams',
          v-model="options.removeteams"
          label='Remove all existing team assignments in all competitions'
          outerClass='mt-2'
        )
        .flex.justify-between.items-end.my-2
          t-btn(
            :margin="false"
            type='button'
            @click="initiateDivisionOverride"
            label='Override Division'
            data-test="submit"
          )
          t-btn(
            :margin="false"
            type='button'
            label=`Cancel`
            @click='closeDialog'
            data-test="cancel"
            color="red"
          )

        div.mt-2.border-t-2.border-gray-400.flex.flex-col.items-center.justify-center.w-full.pt-2(v-if="currentDivName != automaticDivName")
          t-btn(
            :margin="false"
            type='button'
            :disable="!automaticDivision"
            :class="!automaticDivision ? 'bg-gray-200' : ''"
            label=`Restore Automatic Assignment`
            @click='restoreAutomaticAssigment'
            wrapper-class='mt-1'
          )
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios"
import { onMounted, type PropType, ref } from 'vue'
import type { CompetitionRegistration, Division, Guid } from "src/interfaces/InleagueApiV1"
import Divisions from 'src/components/Selectors/Divisions.vue'
import * as ilapi from "src/composables/InleagueApiV1"
import { Client } from "src/store/Client"
import { useIziToast } from "src/helpers/utils"
import { type AxiosInstance } from "axios"

export default defineComponent({
  components: {
    Divisions,
  },
  props: {
    competitionRegistration: {
      required: true,
      type: Object as PropType<CompetitionRegistration>
    },
    newDivID: {
      required: true,
      type: String
    }
  },
  emits: {
    cancel: () => true,
    committed: (_: {divID: string}) => true
  },
  setup(props, {emit}) {
    const ready = ref(false);
    const automaticDivision = ref<null | Division>(null)
    const currentDivName = ref('')
    const automaticDivName = ref('')
    const newDivision = ref('')
    const options = ref({
      registrationid: props.competitionRegistration.registrationID,
      removeteams: true,
      updatebasedivision: true,
      competitionuid: props.competitionRegistration.competitionUID
    })

    const iziToast = useIziToast();

    const saveDivisionOverride = async () : Promise<void> => {
      await ilapi.overrideDivision(
        axiosInstance, {
          registrationID: props.competitionRegistration.registrationID,
          competitionUID: props.competitionRegistration.competitionUID,
          freshDivID: newDivision.value,
          removeTeams: options.value.removeteams,
          updateBaseDivision: options.value.updatebasedivision,
        }
      );
    }

    const restoreAutomaticAssigment = async () : Promise<void> => {
      if (!automaticDivision.value) {
        // caller shouldn't have called
        return;
      }

      try {
        newDivision.value = automaticDivision.value.divID
        await saveDivisionOverride()
        iziToast.info({
          message: `Division Successfully Restored to Automatic Assignment`,
        })
        emit('committed', {divID: newDivision.value}, )
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    const initiateDivisionOverride = async () : Promise<void> => {
      try {
        await saveDivisionOverride()
        iziToast.info({
          message: `Division Successfully Changed.`,
        })
        emit('committed', {divID: newDivision.value}, )
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      }
    }

    const getDivName = async (divID: string) => {
      try {
        const division = await Client.getDivisionByID(divID)
        return division?.displayName ?? ""
      } catch (error) {
        return ''
      }
    }

    const closeDialog = () =>{
      emit('cancel')
    }

    onMounted(async ()=> {
      currentDivName.value = await getDivName(props.competitionRegistration.divID)
      newDivision.value = props.newDivID

      automaticDivision.value = await getAutomaticDivisionAssignment(axiosInstance, {
        registrationID: props.competitionRegistration.registrationID,
        competitionUID: props.competitionRegistration.competitionUID
      })

      automaticDivName.value = automaticDivision.value ? await getDivName(automaticDivision.value.divID) : "";

      ready.value = true;
    })

    return {
      ready,
      currentDivName,
      automaticDivision,
      automaticDivName,
      newDivision,
      options,
      restoreAutomaticAssigment,
      initiateDivisionOverride,
      closeDialog,
    }
  }
})

/**
 * Get the "auto division assignment" for some (reg, competition) (i.e. some  compreg), or null if no such thing could be computed.
 */
async function getAutomaticDivisionAssignment(ax: AxiosInstance, args: {registrationID: Guid, competitionUID: Guid}) : Promise<Division | null> {
  const response = await axiosInstance.get(`/v1/registration/${args.registrationID}/competition/${args.competitionUID}/division`)

  // Endpoint returns either object type or falsy value.
  // Falsy value means "nothing found", which we map to null.
  return response.data.data || null;
}
</script>

<style>
.cancelBtn {
  background: rgb(153 27 27) !important
}
</style>
