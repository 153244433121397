import { defineComponent, onMounted, ref, watch } from "vue"
import AdminPage from "./AdminPage.vue"
import { Competition, Guid } from "src/interfaces/InleagueApiV1"
import { useRouter } from "vue-router";
import { TabID_t } from "./AdminPage.ilx";
import { RouterQueryParams, RouteNames, getTabMappingByRouteName, getTabMappingByTabID } from "./R_AdminPage.route";
import { filterCompetitionsListingBySuperAdmin } from "src/modules/CompetitionUtils";
import { User } from "src/store/User";
import { getCompetitionsOrFail } from "src/store/Competitions";
import { forceCheckedIndexedAccess, refRef } from "src/helpers/utils";

export default defineComponent({
  setup() {
    const competitions = refRef<readonly Competition[]>(ref([]))

    const selectedTabID = ref<TabID_t>(TabID_t.competitionAdministrator)
    const selectedSeasonUID = ref<"" | Guid>("")
    const selectedCompetitionUID = ref<"" | Guid>("");
    const hasSomeCompetitionsWithSuperAdminAccess = ref(false);
    const isHandlingTabChange = ref(false);
    const ready = ref(false)

    const router = useRouter()

    const changeTab = (tab: TabID_t) => {
      const tabRouteMapping = getTabMappingByTabID(tab);
      if (tabRouteMapping) {
        router.push({name: tabRouteMapping.routeName, query: router.currentRoute.value.query})
      }
      else {
        //
        // No specific route for the particular tab;
        // fallback to the behavior from before tabs could possibly be route based,
        // where all tabs hang of the "main" route path.
        //
        selectedTabID.value = tab
        router.push({name: RouteNames.main, query: router.currentRoute.value.query})
      }
    }

    const updateSelectionsBasedOnRouteQueryParams = () : void => {
      const coerceToString = (v: any) : string => typeof v === "string" ? v : "";
      const fresh = {
        seasonUID: coerceToString(router.currentRoute.value.query[RouterQueryParams.seasonUID]),
        competitionUID: coerceToString(router.currentRoute.value.query[RouterQueryParams.competitionUID]),
      };

      if (selectedSeasonUID.value !== fresh.seasonUID) {
        selectedSeasonUID.value = fresh.seasonUID;
      }
      if (selectedCompetitionUID.value !== fresh.competitionUID) {
        selectedCompetitionUID.value = fresh.competitionUID;
      }
    }

    const updateSelectedTabBasedOnRouteName = () : void => {
      if (typeof router.currentRoute.value.name !== "string") {
        // unexpected
        return;
      }

      const maybeSomeTabMapping = getTabMappingByRouteName(router.currentRoute.value.name);
      if (maybeSomeTabMapping) {
        selectedTabID.value = maybeSomeTabMapping.tab;
      }
      else {
        // no particular mapping for route->tab, the component should have made provisions for this elsewhere,
        // e.g. in `changeTab`
      }
    }

    const maybeFixupSelectedCompetition = () : void => {
      const comps = competitions.value.value;
      if (!comps.find(comp => comp.competitionUID === selectedCompetitionUID.value)) {
        // if the "currently selected" competition is not in the new list, force set to whatever the "first" competition is
        // If that's not available (should always have at least one though) fallback to empty string.
        selectedCompetitionUID.value = forceCheckedIndexedAccess(comps, 0)?.competitionUID ?? "";
      }
    }

    const reconfigureForSelectedTab = async () : Promise<void> => {
      isHandlingTabChange.value = true;
      try {
        if (selectedTabID.value === TabID_t.competitionAdministrator) {
          competitions.value = await getCompetitionsOrFail({includeDisabled: true})
        }
        else {
          competitions.value = await getCompetitionsOrFail({includeDisabled: false})
        }

        hasSomeCompetitionsWithSuperAdminAccess.value = checkHasSomeCompetitionsWithSuperAdminAccess(competitions.value.value)

        maybeFixupSelectedCompetition()
      }
      finally {
        isHandlingTabChange.value = false;
      }
    }

    watch(() => router.currentRoute.value.query, () => {
      updateSelectionsBasedOnRouteQueryParams()
    })

    watch(() => [selectedCompetitionUID.value, selectedSeasonUID.value], () => {
      router.replace({
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          [RouterQueryParams.competitionUID]: selectedCompetitionUID.value || undefined,
          [RouterQueryParams.seasonUID]: selectedSeasonUID.value || undefined
        }
      })
    })

    watch(() => router.currentRoute.value.name, () => {
      updateSelectedTabBasedOnRouteName();
    });

    watch(() => selectedTabID.value, async () => {
      await reconfigureForSelectedTab()
    })

    onMounted(async () => {
      updateSelectionsBasedOnRouteQueryParams()
      updateSelectedTabBasedOnRouteName()
      await reconfigureForSelectedTab()
      ready.value = true
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <AdminPage
          inSeasonUID={selectedSeasonUID.value}
          onSetSelectedSeasonUID={v => selectedSeasonUID.value = v}

          inCompetitionUID={selectedCompetitionUID.value}
          onSetSelectedCompetitionUID={v => selectedCompetitionUID.value = v}

          inTabID={selectedTabID.value}
          onSetSelectedTabID={v => changeTab(v)}

          competitions={competitions.value.value}
          hasSomeCompetitionsWithSuperAdminAccess={hasSomeCompetitionsWithSuperAdminAccess.value}
          isHandlingTabChange={isHandlingTabChange.value}
        />
      )
    }
  }
})

function checkHasSomeCompetitionsWithSuperAdminAccess(comps: readonly Competition[]) : boolean {
  if (!User.userData) {
    return false;
  }
  return filterCompetitionsListingBySuperAdmin(User.userData, comps).length > 0;
}
