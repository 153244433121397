<template lang="pug">
template(v-if='ready')
  .smx-auto.w-full.flex.items-end
    h1.ml-2.mr-4.text-2xl.self-start.font-medium(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "ballot-check"]')
      | {{ season.name }} Volunteer Preferences - {{ volunteerDetails.firstName }} {{ volunteerDetails.lastName }}
  p.mb-4.mt-2.font-lg.ml-2 Select volunteer preferences for the {{ season.name }} season below.
  FormKit(
    type='form',
    @submit='submit',
    submit-label='Submit Volunteer Preferences'
  )
    SelectRolesImpl(
      name='selectRolesImpl',
      :volunteerID='volunteerID',
      :seasonUID='seasonUID',
      :volunteerDetails='volunteerDetails'
    )
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { axiosInstance } from 'src/boot/axios'
import SelectRolesImpl from 'src/components/VolunteerRegistration/SelectRolesImpl.vue'
import { VolunteerRoleSelection } from 'src/components/VolunteerRegistration/SelectRolesUtils'

import * as ilapi from 'src/composables/InleagueApiV1'

import { Client } from 'src/store/Client'

export default defineComponent({
  components: {
    SelectRolesImpl: SelectRolesImpl,
  },
  props: {
    seasonUID: { required: true, type: String },
    volunteerID: { required: true, type: String },
  },
  emits: ['roles-selected'],
  setup(props, context) {
    const router = useRouter()
    const volunteerDetails =
      ref</*typeof ilapi.getVolunteerDetails()*/ any>(undefined)
    const season = ref</*season memento*/ any>(undefined)
    const ready = ref(false)
    const formValues = ref<VolunteerRoleSelection | undefined>(undefined)

    onMounted(async () => {
      volunteerDetails.value = await ilapi.getVolunteerDetails(
        axiosInstance,
        props.volunteerID,
        props.seasonUID
      )
      season.value = await Client.getSeasonByUidOrFail(
        props.seasonUID
      )
      ready.value = true
    })

    function submit({selectRolesImpl} : {selectRolesImpl: VolunteerRoleSelection}) {
      // data's just an alias, trying to be clear that we're picking this out of a nested form
      const data : VolunteerRoleSelection = selectRolesImpl;
      context.emit('roles-selected', data)
    }

    return {
      volunteerDetails,
      season,
      submit,
      ready,
    }
  },
})
</script>
