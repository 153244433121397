import { PropType, computed, defineComponent } from "vue";
import { Paginated } from "./PaginationUtils"
import { Reflike, UiOption } from "src/helpers/utils";

export const SimplePaginationNav = defineComponent({
  props: {
    mut_pagination: {
      required: true,
      type: Object as PropType<Paginated</*T*/ any>>
    },
    mut_itemsPerPage: {
      required: true,
      type: null as any as PropType<Reflike<string | number>>
    },
    itemsPerPageOptions: {
      required: true,
      type: null as any as PropType<readonly UiOption[]>
    },
    itemsLabel: {
      required: false,
      default: "Items"
    }
  },
  setup(props) {
    const hasPrevOrNext = computed(() => props.mut_pagination.pageData.hasPrev || props.mut_pagination.pageData.hasNext);
    return () => props.mut_pagination.pageData.count_totalItems === 0
        ? <div>No items</div>
        : (
          <>
            <div>{props.itemsLabel} {props.mut_pagination.pageData.zi_currentPageFirstIndex + 1} - {props.mut_pagination.pageData.zi_currentPageLastIndex} of {props.mut_pagination.pageData.count_totalItems}</div>
            {
              // don't take up vertical space if there aren't any forward/back links to show
              hasPrevOrNext.value
                ? (
                  <div class="flex justify-end">
                    <span class={`${props.mut_pagination.pageData.hasPrev ? '' : "invisible"} cursor-pointer text-blue-700 underline mr-1`} onClick={() => props.mut_pagination.zi_currentPage -= 1}>Prev {props.mut_pagination.pageData.count_itemsPerPage}</span>
                    <span class={`${props.mut_pagination.pageData.hasNext ? '' : "invisible"} cursor-pointer text-blue-700 underline`} onClick={() => props.mut_pagination.zi_currentPage += 1}>Next {props.mut_pagination.pageData.count_itemsNextPage}</span>
                  </div>
                )
                : null
            }
            <div>
              <span class="mr-2">Page size:</span>
              <select v-model={props.mut_itemsPerPage.value} style="all: revert; padding:.25em; outline: none;">
                {
                  props.itemsPerPageOptions.map(o => <option value={o.value}>{o.label}</option>)
                }
              </select>
            </div>
          </>
        )
  }
})
