<template lang="pug">
div(v-if="ready")
  template(v-if="backgroundStatus")
    div(v-if="backgroundStatus.nextStep") {{ backgroundStatus.nextStep.detail }}
    a.underline.text-sky-500(v-if="backgroundStatus.registrationStatusCode" :href="backgroundStatus.bgCheck.vendorSubmitLink" target="_blank") {{ backgroundStatus.bgCheck.vendorSubmitLink }}
  template(v-else)
    div Background check information is temporarily unavailable.
</template>

<script lang="ts">
import { onMounted, ref } from 'vue'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import type { BackgroundCheck, Guid } from 'src/interfaces/InleagueApiV1'
import { defineComponent } from 'vue'
import { vReqT } from 'src/helpers/utils'

export default defineComponent({
  props: {
    volunteerID: vReqT<Guid>(),
    seasonUID: vReqT<Guid>(),
  },
  setup(props) {
    const ready = ref(false);
    const backgroundStatus = ref<BackgroundCheck | null>(null)

    /**
     * A common failure mode here is that a 3rd party API the backend talks to is down;
     * failure here is anticipated and expected.
     */
    const getBackgroundStatus = async () : Promise<BackgroundCheck | null> => {
      try {
        const response = await axiosInstance.get(`/v1/volunteer/${props.volunteerID}/season/${props.seasonUID}`)
        return response.data.data
      }
      catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
        return null;
      }
    }

    onMounted(async ()=> {
      backgroundStatus.value = await getBackgroundStatus()
      ready.value = true;
    })

    return {
      ready,
      backgroundStatus
    }
  }

})
</script>
