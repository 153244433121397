<template lang="pug">
span.cursor-pointer(@click="toLegacyApp")
  slot
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue'

import { Client } from 'src/store/Client';

const props = defineProps({
  url: {
    required: true,
    type: String
  }
})

const localInstance = getCurrentInstance()

const clientUrl = computed(() => {
  return Client.value.instanceConfig.appdomain
})

const toLegacyApp = () => {
  localInstance?.appContext.config.globalProperties.$openLegacyLink(
    `https://${clientUrl.value}/${props.url}`,
  )
}
</script>
