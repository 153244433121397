<template lang="pug">
TDialog
  div(v-if="item.pageItem.hasAnswers")
    div This question has historical answer data and may not be delted. Would you like to disable it instead?
    TBtn(label="Cancel" color="red" @click="closeDialog")
    TBtn(label="Disable" @click="disableItem")
  div(v-else)
    div.mb-4 Are you sure you want to delete this item, "{{item.pageItem.shortLabel}}"?
    TBtn(label="Cancel" color="red" @click="closeDialog")
    TBtn(label="Delete" @click="deleteItem")
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['cancel', 'close'],
  setup(props, { emit }) {
    const show=ref(true)
    const localInstance = getCurrentInstance()
    const $toast = localInstance?.appContext.config.globalProperties.$toast

    const router = useRouter()

    const closeDialog= () => {
      // console.log('closeDialog, cancel')
      emit('cancel', true)
    }

    const disableItem = async () => {
      const options = {
        order: props.item.order,
        isDisabled: 1
      }
      try {
        const response = await axiosInstance.put(`/v1/registration/pageItem/${props.item.id}`, options)
        emit('close')
        // console.log('response', response)
      } catch (error) {
        // console.log('error', error)
      }
    }

    const deleteQuestion = async () => {
      // console.log('delete question')
      try {
        // console.log('id', props.item.pageItem)
        // console.log('after id')
        const response = await axiosInstance.delete(`/v1/registration/customQuestion/${props.item.pageItem.id as string}`)
        // console.log('sucessfully deleted custom question', response.data.data)
      } catch (error: any) {
        // console.log('error deleting custom question', error)
        if(error.response.status===403) {
          $toast.error({ message: 'This question already has answers. We canot delete it.' })
        } else {
          $toast.error({ message: 'There was a problem deleting your question' })
        }
      }
      emit('close')
    }

    const deleteContentChunk = async () => {
      // console.log('delete content chunk')
      try {
        const response = await axiosInstance.delete(`/v1/registration/contentChunk/${props.item.pageItem.id}`)
        $toast.success({message: `Content Chunk ${props.item.value.pageItem.label} has been deleted`})
        await router.push({name: 'registration-admin'})
      } catch (error) {
        // console.log('deleteContentChunk', error)
      }
      emit('close')
    }

    const deleteItem = async () => {
      // console.log('checking for question vs. contentChunk')
      if(props.item.pageItemType==="Question") {
        await deleteQuestion()
      } else {
        await deleteContentChunk()
      }
    }

    return {
      closeDialog,
      disableItem,
      deleteItem,
      show
    }
  },
})
</script>

