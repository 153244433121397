<template lang="pug">
.flex.items-center
  switch(@click="toggleSwitch" :class="[enabled ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']")
    span.sr-only {{ label }}
    span(aria-hidden='true' :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']")
  span.ml-2.font-medium.text-sm.t-label
    slot
div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { Switch } from '@headlessui/vue'

export default defineComponent({
  components: {
    Switch
  },
  props: {
    label: {
      type: String,
      required: false
    },
    enabled: {
      type: Boolean,
      required: true
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['enable'],
  setup(props, {emit}) {
    const toggleSwitch = () => {
      // console.log('in toggleSwitch', props.enabled)
      emit('enable', !props.enabled)
    }

    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })

    return {
      toggleSwitch,
      hasValidationErrors
    }
  }
})
</script>

