import { PropType, computed, defineComponent, ref } from "vue";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { FK_nodeRef, exhaustiveCaseGuard, parseIntOrFail, useIziToast, vReqT } from "src/helpers/utils";

import type { ExpandedTournamentTeam } from "./TournamentTeamListing.utils";
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { Tabs } from "src/components/UserInterface/Tabs";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { LastStatus_t } from "src/interfaces/Store/checkout";
import { RouterLink } from "vue-router";
import * as R_MasterInvoice from 'src/components/Payment/pages/MasterInvoice.route'

export const HoldPayment = defineComponent({
  props: {
    tournamentTeam: vReqT<ExpandedTournamentTeam>(),
  },
  emits: {
    requestInFlight: () => true,
    requestComplete: () => true,
    complete: () => true,
    cancel: () => true,
  },
  setup(props, {emit}) {
    const iziToast = useIziToast();
    const result = ref<"no-request-yet" | Awaited<ReturnType<typeof ilapi.bulkPayInvoicesHavingAttachedStripePaymentMethods>>[iltypes.Integerlike]>("no-request-yet");

    const doUpdateFee = async (freshFee: number | string) => {
      const fee : number = parseIntOrFail(freshFee);
      try {
        try {
          emit("requestInFlight")
          await iltournament.updateTournamentTeamHoldPaymentInvoiceFee(axiosAuthBackgroundInstance, {tournamentTeamID: props.tournamentTeam.tournamentTeamID, fee});
        }
        finally {
          emit("requestComplete")
        }

        // client-side updates
        props.tournamentTeam.invoice_holdPayment_amount = fee;

        iziToast.success({message: "Fee updated."});
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doPay = async () : Promise<void> => {
      if (props.tournamentTeam.invoiceInstanceID_holdPayment === "") {
        throw Error("Illegal state");
      }

      let resultMapping : Awaited<ReturnType<typeof ilapi.bulkPayInvoicesHavingAttachedStripePaymentMethods>>;

      try {
        try {
          emit("requestInFlight")
          resultMapping = await ilapi.bulkPayInvoicesHavingAttachedStripePaymentMethods(axiosAuthBackgroundInstance, {invoiceInstanceID: props.tournamentTeam.invoiceInstanceID_holdPayment})
        }
        finally {
          emit("requestComplete")
        }

        result.value = resultMapping[props.tournamentTeam.invoiceInstanceID_holdPayment]

        if (result.value.ok) {
          // client-side updates
          props.tournamentTeam.invoice_holdPayment_lastStatus = LastStatus_t.PAID_AND_PROCESSED
          iziToast.success({message: "Payment completed."});
          emit("complete")
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    // we shouldn't ever be mounted if these checked things wouldn't pass these checks
    const checked = {
      fee: computed<number>(() => {
        if (typeof props.tournamentTeam.invoice_holdPayment_amount !== "number") {
          throw Error("bad invoice_holdPayment_amount value")
        }
        return props.tournamentTeam.invoice_holdPayment_amount
      }),
      invoiceInstanceID: computed<iltypes.Integerlike>(() => {
        if (props.tournamentTeam.invoiceInstanceID_holdPayment === "") {
          throw Error("bad invoiceInstanceID_holdPayment")
        }
        else {
          return props.tournamentTeam.invoiceInstanceID_holdPayment;
        }
      })
    } as const;

    return () => (
      <div data-test="CollectHoldPayment">
        <Tabs
          tabDefs={[
            {
              label: "Collect fee",
              "data-test": "collect",
              render: () => {
                return (
                  <div data-test="TournTeamHoldPaymentCollectFeeForm">
                    {
                      result.value === "no-request-yet"
                        ? (
                          <div>
                            <div class="flex justify-center">Fee: ${checked.fee.value.toFixed(2)}</div>
                            <div class="flex justify-center">
                              <RouterLink to={R_MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: checked.invoiceInstanceID.value})} {...{target:"_blank"}}>
                                <span class="il-link">Invoice {props.tournamentTeam.invoiceInstanceID_holdPayment}</span>
                              </RouterLink>
                            </div>
                            <div class="flex gap-4 mt-2">
                              <t-btn data-test="chargeIt" margin={false} onClick={() => doPay()}>Charge it</t-btn>
                              <t-btn data-test="cancel" margin={false} color="red" onClick={() => emit("cancel")}>Cancel</t-btn>
                            </div>
                          </div>
                        )
                        : result.value.ok
                          ? <div>Ok</div>
                          : result.value.type === "inLeague"
                          ? (
                            <div>
                              <div class="text-sm">Something went wrong:</div>
                              <div>{result.value.detail}</div>
                            </div>
                          )
                          : result.value.type === "stripe"
                          ? (
                            <div>
                              <div class="text-sm">Something went wrong:</div>
                              <div class="p-1">
                                <div>{result.value.detail.message}</div>
                                <a class="text-blue-700 underline cursor-pointer break-all" href={result.value.detail.request_log_url}>{result.value.detail.request_log_url}</a>
                              </div>
                            </div>
                          )
                          : exhaustiveCaseGuard(result.value)
                      }
                  </div>
                )
              }
            },
            {
              label: "Adjust fee",
              "data-test": "update",
              render: () => <FeeForm tournamentTeam={props.tournamentTeam} onDoUpdate={doUpdateFee}/>
            },
          ]}
        />
      </div>
    )
  }
})

const FeeForm = defineComponent({
  props: {
    tournamentTeam: vReqT<ExpandedTournamentTeam>(),
  },
  emits: {
    doUpdate: (freshFee: number | string) => true
  },
  setup(props, {emit}) {
    // copy from props; parent should remount this when tournamentTeam changes
    // if there is no fee_holdPayment then something is broken, we expect to always have one here
    const maxFee : number = (() => {
      if (typeof props.tournamentTeam.fee_holdPayment === "string") {
        throw Error("tournamentTeam has no 'initial' hold payment fee?");
      }
      else {
        return props.tournamentTeam.fee_holdPayment;
      }
    })();

    const fee = ref<number | string>((() => {
      if (typeof props.tournamentTeam.invoice_holdPayment_amount === "string") {
        throw Error("tournamentTeam has no 'current' hold payment fee?");
      }
      else {
        return props.tournamentTeam.invoice_holdPayment_amount;
      }
    })());

    const fkRef = FK_nodeRef();

    return () => (
      <div style="--fk-margin-outer:none;" data-test="TournTeamHoldPaymentFeeUpdateForm">
        <FormKit type="form" onSubmit={() => { emit("doUpdate", fee.value)}} actions={false}>
          <div class="mt-2 text-xs">Max as per initial value at time of invoice creation: ${maxFee.toFixed(2)}</div>
          <div class="font-medium text-sm">Fee</div>
          <div class="flex gap-2 items-center">
            <FormKit data-test="fee" ref={fkRef} outer-class="w-full" type="number" {...{scale:".01"}} name="Fee" v-model={fee.value} validation={[["min", 0], ["max", maxFee]]}/>
            <t-btn data-test="ok" margin={false}>Ok</t-btn>
          </div>
          <div>
            <FormKitMessages node={fkRef.value?.node}/>
          </div>
        </FormKit>
      </div>
    )
  }
})
