<template lang="pug">
.flex.flex-col.items-center(data-test="leagueGames" v-if="ready")
  h1.mt-4 All Games
  div(class='sm:mx-auto sm:w-full sm:max-w-md' style="--fk-margin-outer: none;")
    div(class="mt-2")
      FormKit(
        v-model='selectedCompetitionID',
        :options='competitionSelectOptions',
        type='select',
        label='Competition',
        placeholder='Select a Competition',
        v-if='Object.keys(competitionSelectOptions).length > 0',
        data-cy='competitions'
      )
    div(class="mt-2")
      FormKit.m-2(
        v-model='selectedDivID',
        :options='divisionSelectOptions',
        type='select',
        placeholder='Select a Division',
        label='Division',
        :disabled='!selectedCompetitionID',
        data-test='divID',
        label-class='pb-4'
      )
    div(style="width:100%; max-width: var(--fk-max-width-input);" class="mt-2")
      //- we could probably pull more reasonable min/max dates from compSeason or season.seasonStart
      LeagueGamesDatePicker(
        :initialValue="selectedOnOrAfterDate"
        :minDate="minLeagueGamesDatePickerDate"
        @commit="(freshDate) => { selectedOnOrAfterDate = freshDate; getGamesAndUpdateGameDisplay(); }"
      )

    div(v-if="divisionStandingsURL" class="mt-2")
      a(
        :href="divisionStandingsURL"
        target="_blank"
        class="text-blue-700 underline cursor-pointer"
      )
        | View Division Standings
    div(:class="[`mt-2 flex gap-1 items-center`, offerUnpublishedCheckbox ? '' : 'invisible']")
      FormKit(type="checkbox" id="elem-unpublishedCheckbox" v-model="showUnpublishedGames" data-test="showUnpublishedGames")
      label(for="elem-unpublishedCheckbox" class="text-sm") Include unpublished games in results

  .quasar-style-wrap.mt-8(
    v-if='games.length',
    data-cy='fieldKeys',
  )
    .q-pa-md
      q-table(
        :rows='keys',
        :columns='keyColumns',
        row-key='fieldID',
        @row-click='toMap',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-fieldID='props')
          q-td(:auto-width='true')
            div {{ props.row.fieldAbbrev }}
        template(v-slot:body-cell-fieldName='props')
          q-td.row.full-width(:props='props')
            .col-auto.q-pr-sm
              font-awesome-icon(:icon='["fas", "map-marked-alt"]')
            .col {{ props.row.fieldName }}

  div(v-if="selectedDivID !== 'ALL' && games.length" class="max-w-6xl w-full")
    MultiGameCoachInfo(:games="games")

  div(class="w-full")
    .quasar-style-wrap(
      v-if='games.length',
      data-cy='allGamesTable',
    )
      GamelikeScheduleTable(:columns="columns" :rows="games")
    div(v-else-if='isDataLoaded', data-cy='noGames')
      h4.text-center.italic.mt-2 Sorry, no games matched your query
</template>

<script lang="ts">
import { axiosAuthBackgroundInstance, axiosInstance } from 'src/boot/axios'
import { useRouter } from 'vue-router'
import { defineComponent, ref, Ref, watch, computed, onMounted } from 'vue'
import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { Competition, Division, RefFieldDetails } from 'src/interfaces/InleagueApiV1'
import { propsDef, Props, JsxQuasarColumnRenderShim, GamelikeColDefs, AugmentedGamelikeForSchedule, augmentGamelikesForSchedule, getAreaCoaches, maybeComputeDivisionStandingsURL } from "./page/schedules.ilx"

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import GamelikeScheduleTable from './GamelikeScheduleTable.vue'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client, RefSlotConfigLookup } from 'src/store/Client'
import dayjs from 'dayjs'
import { DAYJS_FORMAT_HTML_DATE } from 'src/helpers/formatDate'
import { UiOption } from 'src/helpers/utils'
import { initInOnMountedRef } from './utils'

import { MultiGameCoachInfo } from './MultiGameCoachInfo'
import { LeagueGamesDatePicker } from "./LeagueGamesDatePicker"
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { areaCoachCamelToLower, canViewUnpublishedGames } from 'src/composables/InleagueApiV1.Game'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'

export default defineComponent({
  name: 'leagueGames',
  props: propsDef(),
  components: {
    GamelikeScheduleTable,
    MultiGameCoachInfo,
    LeagueGamesDatePicker,
  },
  setup(props) {
    /**
     * defaults to "1" (the "core" program) if not provided
     */
    const selectedCompetitionID = ref<iltypes.Integerlike>(props.competitionID || "1")

    /**
     * Will only contain a competition if a single competition is selected. `null` can mean either "no selection" or "the current selection is ALL"
     */
    const selectedCompetition = ref<Competition | null>(null)
    const competitionSelectOptions = ref<UiOption<"ALL" | iltypes.Guid>[]>([])
    const refSlotConfigLookup = initInOnMountedRef<RefSlotConfigLookup>()

    /**
     * defaults to "no selection"
     */
    const selectedDivID = ref</*"" | "ALL" | DivID*/ string>(props.divID || "")
    /**
     * Will only contain a division if a single division is selected. `null` can mean either "no selection" or "the current selection is ALL"
     */
    const selectedDivision = ref<Division | null>(null)
    const divisionSelectOptions = ref<UiOption[]>([])

    const selectedOnOrAfterDate = ref(dayjs().format(DAYJS_FORMAT_HTML_DATE))

    const ready = ref(false);

    const games = ref([]) as Ref<AugmentedGamelikeForSchedule<ilapi.LoggedOutGame>[]>
    const isDataLoaded = ref(false)

    const offerUnpublishedCheckbox = ReactiveReifiedPromise<boolean>({
      deps: () => [User.isLoggedIn, User.userData?.userID],
      f: async () => {
        if (User.isLoggedIn) {
          return await canViewUnpublishedGames(axiosAuthBackgroundInstance)
        }
        else {
          return false
        }
      }
    }).map(p => {
      switch (p.status) {
        case "resolved": return p.data;
        case "error": throw p.error;
        default: return false;
      }
    })

    const showUnpublishedGames = (() => {
      const val = ref(false)
      return computed({
        get() {
          return offerUnpublishedCheckbox.value ? val.value : false
        },
        set(v) {
          val.value = v;
        }
      })
    })();

    /**
     * a url to "division standings" if such a link exists as per current selections and listed games,
     * or null if nothing
     */
    const divisionStandingsURL = computed<string | null>(() => {
      return maybeComputeDivisionStandingsURL({
        competition: selectedCompetition.value,
        division: selectedDivision.value,
        gamelikes: games.value,
      })
    })

    // todo: clarify what this is (keys are usually strings into a map?...)
    const keys = ref([]) as Ref<ilapi.LoggedOutGame[]>

    const columns = ref(GamelikeColDefs());

    const keyColumns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefFieldDetails) => ref.fieldAbbrev,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
        classes: 'q-table',
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        sortable: true,
        field: (ref: RefFieldDetails) => {
          return ref.fieldName
        },
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
        classes: 'q-table',
      },
    ])

    const router = useRouter()

    const userAddress = computed(() => {
      return User.value.userAddress
    })

    const configureSelectOptions = async () => {
      const comps = (await getCompetitionsOrFail()).value;
      const divs = await Client.loadDivisions();

      competitionSelectOptions.value = comps.map(comp => ({label: comp.competition, value: comp.competitionID.toString()}))

      divisionSelectOptions.value = [
        {label: "Choose a division", value: "", attrs: {disabled: true}},
        {label: "All", value: "ALL"},
        ...divs.map(div => ({label: div.displayName, value: div.divID}))
      ]
    }

    // this is really "get one random game per unique fieldID out of the current games list"
    // see `oneRandomGamePerField` in "userGames.vue". We might be able to have only one implementation
    // if they are exactly the same.
    const createKeys = () => {
      const gameKeys: { [fieldID: string]: ilapi.LoggedOutGame } = {}
      for (let i = 0; i < games.value.length; i++) {
        const game = games.value[i]
        gameKeys[game.fieldAbbrev] = game
      }
      keys.value = []
      for (const key in gameKeys) {
        keys.value.push(gameKeys[key])
      }
    }

    const toMap = (evt: Event, row: ilapi.LoggedOutGame) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }

    const doUpdatePageUrl = (competitionID: iltypes.Integerlike, divID?: iltypes.Guid) : void => {
      const sameName = router.currentRoute.value.name;
      if (typeof sameName !== "string") {
        // name can be many things; we expect it to be a string
        // if it isn't a string, just bail
        return;
      }

      const freshParams : Props = {competitionID, divID};

      // same route name, updated params but same param shape
      router.replace({name: sameName, params: freshParams})
    }

    /**
     * gets games from a network request
     * mutates component state, in particular the list of displayed games
     */
    const getGamesAndUpdateGameDisplay = async () : Promise<void> => {
      if (!selectedCompetitionID.value || !selectedDivID.value) {
        // probably shouldn't have been called
        return
      }

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const competitionID = selectedCompetitionID.value;
        const divID = selectedDivID.value === "ALL" ? undefined : selectedDivID.value;
        const onOrAfter = selectedOnOrAfterDate.value;

        const gameScheduleInfo = await ilapi.getGamesForPublicView(axiosInstance, {
          competitionID,
          divID,
          onOrAfter,
          showUnpublished: showUnpublishedGames.value,
          expand: ["refereeDetails"],
        });

        doUpdatePageUrl(competitionID, divID)

        games.value = augmentGamelikesForSchedule(
          gameScheduleInfo,
          (await getAreaCoaches(axiosInstance, gameScheduleInfo))?.map(areaCoachCamelToLower) ?? [],
          refSlotConfigLookup.value,
        );

        isDataLoaded.value = true

        createKeys()
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        refSlotConfigLookup.value = await Client.getRefSlotConfigLookup()

        await configureSelectOptions()
        await getGamesAndUpdateGameDisplay()

        selectedCompetition.value = (await Client.getCompetitionByID(selectedCompetitionID.value)) ?? null
        selectedDivision.value = (await Client.getDivisionByID(selectedDivID.value)) ?? null
        
        ready.value = true;
      })
    })

    watch(() => selectedCompetitionID.value, async () => {
      selectedCompetition.value = (await Client.getCompetitionByID(selectedCompetitionID.value)) ?? null
      await getGamesAndUpdateGameDisplay();
    })

    watch(() => selectedDivID.value, async () => {
      selectedDivision.value = (await Client.getDivisionByID(selectedDivID.value)) ?? null
      await getGamesAndUpdateGameDisplay();
    })

    return {
      ready,
      selectedCompetitionID,
      competitionSelectOptions,
      selectedDivID,
      divisionSelectOptions,
      selectedOnOrAfterDate,
      games,
      isDataLoaded,
      keys,
      columns,
      keyColumns,
      createKeys,
      toMap,
      userAddress,
      getGamesAndUpdateGameDisplay,
      formatDateAsNums,
      formatTime,
      divisionStandingsURL,
      JsxQuasarColumnRenderShim,
      // this tracks a backend value in the api that we have static knowledge of
      minLeagueGamesDatePickerDate: dayjs().subtract(2, "years").toISOString(),
      offerUnpublishedCheckbox,
      showUnpublishedGames,
    }
  },
})

</script>

<style scoped>
@media (min-width: 640px) {
  .tableWidth {
    width: 75vw !important;
  }
}

@media (min-width: 768px) {
  .tableWidth {
    width: 50vw !important;
  }
}

@media (min-width: 1024px) {
  .tableWidth {
    width: 600px !important;
  }
}
</style>
