import { PropType, Ref, computed, defineComponent, ref, watch, WritableComputedRef } from "vue"
import { FormKit, FormKitMessages } from "@formkit/vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { axiosInstance } from "src/boot/AxiosInstances"

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { TabDef, Tabs } from "src/components/UserInterface/Tabs"

import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FK_nodeRef, vReqT } from "src/helpers/utils"
import { User } from "src/store/User"
import { Client } from "src/store/Client"
import { Btn2 } from "../UserInterface/Btn2"
import { Integerlike } from "src/interfaces/InleagueApiV1"

interface MutableAdminUserBase {
  type:
    | "persisted" // exists on server
    | "tentative" // entry exists only on client, with intent to push to server
  userID: string,
  firstName: string,
  lastName: string,
  title: string,
}

interface MutableAdminUser_Persisted extends MutableAdminUserBase {
  type: "persisted",
  markedForDelete: boolean,
  ifSelfHasAcknowledgedSelfDeletion: boolean,
}

interface MutableAdminUser_Tentative extends MutableAdminUserBase {
  type: "tentative"
}

const CurrentTournamentAdminUsers = defineComponent({
  name: "CurrentTournamentAdminUsers",
  props: {
    existingAdminUserListing: vReqT<readonly iltournament.TournamentTeamAdmin[]>(),
    canMakeEdits: vReqT<boolean>(),
  },
  emits: {
    removeSelectedUsers: (_: MutableAdminUser_Persisted[]) => true,
  },
  setup(props, {emit}) {
    const currentUserID = computed(() => User.value.userID);

    /**
     * our local working set, represents a derived version of `existingAdminUserListing`
     * Should never hold live refs to parent state.
     */
    const mutableCurrentAdminUsers = ref<MutableAdminUser_Persisted[]>([])

    const mutableCurrentAdminUsers_byUserID = computed(() => {
      const ret : {[userID: iltypes.Guid]: undefined | MutableAdminUser_Persisted} = {}
      mutableCurrentAdminUsers.value.forEach(v => {ret[v.userID] = v;})
      return ret;
    })

    const hasAtLeastOneDeletionTarget = computed<boolean>(() => {
      return !!mutableCurrentAdminUsers.value.find(v => v.markedForDelete)
    })

    watch(() => props.existingAdminUserListing, () => {
      //
      // We read the current values of mutableCurrentAdminUsers to perform the mapping,
      // so we want to be clear that assignment to mutableCurrentAdminUsers happens definitely after the mapping operation;
      // so, assign to temporary, then assign to target from temporary
      //
      const temp = props
        .existingAdminUserListing
        .map((adminUser: iltournament.TournamentTeamAdmin) : MutableAdminUser_Persisted => {
          //
          // map an "application-tier admin user" into a mutable representation thereof. Because we rebuild the world on
          // change to the primary listing, we want to consult with the prior derived list to carry over any mutated info.
          //
          return {
            type: "persisted",
            markedForDelete: mutableCurrentAdminUsers_byUserID.value[adminUser.userID]?.markedForDelete ?? false,
            ifSelfHasAcknowledgedSelfDeletion: mutableCurrentAdminUsers_byUserID.value[adminUser.userID]?.ifSelfHasAcknowledgedSelfDeletion ?? false,
            userID: adminUser.userID,
            firstName: adminUser.firstName,
            lastName: adminUser.lastName,
            title: adminUser.title,
          }
        })
        .sort((l,r) => l.lastName < r.lastName ? -1 : 1)

      mutableCurrentAdminUsers.value = temp;
    }, {immediate: true})

    const FK_deleteSelf = FK_nodeRef();

    return () => (
      <div>
        <FormKit type="form" onSubmit={() => { emit("removeSelectedUsers", mutableCurrentAdminUsers.value)}} actions={false}>
          <div style="display: grid; grid-template-columns: auto auto min-content;" class="w-full max-h-96">
            <>
              <div>User</div>
              <div>Title</div>
              {props.canMakeEdits
                ? <div>Remove</div>
                : <div></div>
              }
            </>
            {
              mutableCurrentAdminUsers.value.map((adminUser, idx) => {
                const commonRowClasses = `pl-1 box-border flex items-center ${adminUser.markedForDelete ? 'text-white bg-red-700 border-b border-white' : idx % 2 ? 'bg-white' : 'bg-slate-100'}`;
                return (
                  <>
                    <div data-test={adminUser.userID} class={commonRowClasses}>{adminUser.firstName} {adminUser.lastName}</div>
                    <div class={commonRowClasses}>{adminUser.title}</div>
                    {props.canMakeEdits
                      ? (
                        <div class={commonRowClasses}>
                          <div class="flex items-center ml-auto">
                            <span onClick={() => { adminUser.markedForDelete = !adminUser.markedForDelete }} class="cursor-pointer p-1 rounded-sm hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]">
                              <FontAwesomeIcon icon={["fas", "trash-alt"]}/>
                            </span>
                          </div>
                        </div>
                      )
                      : <div></div>
                    }

                    {
                      adminUser.markedForDelete && currentUserID.value === adminUser.userID
                        ? (
                          <div class="text-sm" style="grid-column: 1/4;">
                            <div class="pl-2 my-1">
                              <div class="flex items-center">
                                <FontAwesomeIcon icon={faTriangleExclamation}/>
                                <FormKit
                                  type="checkbox" v-model={adminUser.ifSelfHasAcknowledgedSelfDeletion}
                                  ref={FK_deleteSelf} validation={[["accepted"]]} validationMessages={{accepted: "Please confirm your intent to remove yourself from this list."}}
                                  outer-class="$reset ml-2"
                                />
                                <span>Yes, remove myself from this list.</span>
                              </div>
                              <div>
                                <FormKitMessages node={FK_deleteSelf.value?.node}/>
                              </div>
                            </div>
                          </div>
                        )
                        : null
                    }
                  </>
                )
              })
            }
          </div>
          {props.canMakeEdits
            ? <Btn2
              class="text-sm mt-4 px-2 py-1"
              type="submit"
              disabled={!hasAtLeastOneDeletionTarget.value}
            >Remove selected users</Btn2>
            : null
          }
        </FormKit>
      </div>
    )
  }
})

const AddAdminTournamentUsers = defineComponent({
  name: "AddAdminTournamentUsers",
  props: {
    existingAdminUserListing: {
      required: true,
      type: Array as PropType<readonly iltournament.TournamentTeamAdmin[]>
    }
  },
  emits: {
    addSelectedUsers: (_: MutableAdminUser_Tentative[]) => true,
  },
  setup(props, {emit}) {
    const search = ref("")
    const fk_searchNode = ref<undefined | {node?: any}>()

    const volunteerSearchResults = ref<null | iltypes.VolunteerSearchResult[]>(null)
    const tentativeUsers = ref<MutableAdminUser_Tentative[]>([])

    // TODO: after a search (in response to a form submit) we lose focus on the single form element? Would be nice to retain that.
    const doSearch = async () : Promise<void> => {
      try {
        volunteerSearchResults.value = await ilapi.findVolunteers(axiosInstance, {search: search.value, localOnly: false})
        tentativeUsers.value = [];
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const localLookup = computed(() => {
      const tentative : {[userID: iltypes.Guid]: undefined | MutableAdminUser_Tentative} = {}
      const persisted : {[userID: iltypes.Guid]: undefined | iltournament.TournamentTeamAdmin} = {}

      props.existingAdminUserListing.forEach(v => {
        persisted[v.userID] = v;
      });
      tentativeUsers.value.forEach(v => {
        tentative[v.userID] = v;
      })

      return {tentative, persisted}
    })

    watch(() => props.existingAdminUserListing, () => {
      // In the most common case, this will set tentativeUsers.value = [],
      // because the new listing is usually in response to a successful completion of an "add the users" request.
      const definitelyPersisted = new Set(props.existingAdminUserListing.map(v => v.userID));
      tentativeUsers.value = tentativeUsers.value.filter(tentative => !definitelyPersisted.has(tentative.userID));
    })

    /**
     * Add user to tentative listing, or remove them from that list if they are already present
     */
    const pushOrPopUserAsAppropriate = (userlike: iltypes.VolunteerSearchResult) : void => {
      if (localLookup.value.persisted[userlike.ID]) {
        // sanity check
        throw "this method should only be called against users who are not already on the 'persisted' list"
      }

      const targetIdx = tentativeUsers.value.findIndex(v => v.userID === userlike.ID);

      if (targetIdx === -1) {
        tentativeUsers.value.push({
          type: "tentative",
          userID: userlike.ID,
          firstName: userlike.firstName,
          lastName: userlike.lastName,
          title: "",
        })
      }
      else {
        tentativeUsers.value.splice(targetIdx, 1);
      }
    }

    function Listing({searchResults}: {searchResults: iltypes.VolunteerSearchResult[]}) : JSX.Element {
      if (searchResults.length === 0) {
        return <div class="col-span-2 flex items-center justify-center m-2">No results.</div>
      }
      return (
        <>
          <>
            <div>User</div>
            <div>{/*placeholder*/}</div>
          </>
          {
            searchResults.map((result, idx) => {
              // search result might be for a user who is already an admin; the UI should make it clear that this user was found,
              // but that they are already an admin and so no action can be performed against this particular search result.
              const searchResultRepresentsAlreadyPersistedAdmin : boolean = !!localLookup.value.persisted[result.ID]
              const maybeExistingTentative : MutableAdminUser_Tentative | undefined = localLookup.value.tentative[result.ID]

              const commonRowClasses = `pl-1 flex flex-col justify-center items-start ${searchResultRepresentsAlreadyPersistedAdmin ? 'italic' : ''} ${idx % 2 ? 'bg-white' : 'bg-slate-100'}`;

              return (
                <>
                  <div class={commonRowClasses}>
                    <div>
                      <div>{result.firstName} {result.lastName}</div>
                      {
                        searchResultRepresentsAlreadyPersistedAdmin
                          ? <div class="text-xs">is already an admin for this tournament team.</div>
                          : null
                      }
                    </div>
                    <div>
                      {
                        maybeExistingTentative
                          ? (
                            <div style="margin:.25em; --fk-bg-input: white;" class="text-sm">
                              <FormKit type="group" /*FK_GROUP_TO_NOT_STOMP_VMODEL*/>
                                <FormKit
                                  type="text" v-model={maybeExistingTentative.title}
                                  name="title" key={`${maybeExistingTentative.userID}/title`}
                                  outer-class="$reset"
                                  style="--fk-padding-input: .25em;"
                                  validation={[["required"]]}
                                  {...{placeholder: "Title ('main contact' or etc.)"}}
                                  data-test={`title/${result.ID}`}
                                />
                              </FormKit>
                            </div>
                          )
                          : null
                      }
                    </div>
                  </div>
                  <div class={commonRowClasses}>
                    {
                      searchResultRepresentsAlreadyPersistedAdmin
                        ? <div>&nbsp;</div>
                        : (
                          <div class="flex items-center justify-end">
                            <span data-test={`enqueue/${result.ID}`} onClick={() => { pushOrPopUserAsAppropriate(result) }} class="cursor-pointer p-1 rounded-sm hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]">
                              <FontAwesomeIcon icon={["fas", `${maybeExistingTentative ? "square-minus" : "square-plus"}`]}/>
                            </span>
                          </div>
                        )
                    }
                  </div>
                </>
              )
            })
          }
        </>
      )
    }

    return () => (
      <div>
        <div>
          <FormKit type="form" actions={false} onSubmit={doSearch}>
            <div class="flex gap-2 items-center">
              <FormKit
                type="text" v-model={search.value} ref={fk_searchNode} name="search text" data-test="userSearch"
                outer-class="$reset flex-grow" {...{placeholder: "Search by name"}} validation={[["required"], ["length", 3]]}/>
              <t-btn type="submit" margin={false}>Search</t-btn>
            </div>
            <div class="mb-2">
              <FormKitMessages node={fk_searchNode.value?.node} />
            </div>
          </FormKit>
        </div>
        <div>
          <FormKit type="form" actions={false} onSubmit={() => emit("addSelectedUsers", tentativeUsers.value)}>
            <div style="display: grid; grid-template-columns: auto min-content;" class="w-full max-h-96 overflow-y-auto">
              {
                volunteerSearchResults.value
                  ? <Listing searchResults={volunteerSearchResults.value} />
                  : null
              }
            </div>
            {
              volunteerSearchResults.value
                ? (
                  <t-btn
                    margin={false}
                    disable={tentativeUsers.value.length === 0}
                    class={`text-sm mt-4 ${tentativeUsers.value.length === 0 ? 'bg-gray-200' : ''}`}
                  >Add selected users as managers</t-btn>
                )
                : null
            }
          </FormKit>
        </div>
      </div>
    )
  }
})

export const AdminRoster = defineComponent({
  name: "AdminRoster",
  props: {
    tournamentTeamID: vReqT<Integerlike>(),
    mut_existingAdmins: vReqT<Ref<iltournament.TournamentTeamAdmin[]>>(),
    canMakeEdits: vReqT<boolean>(),
  },
  emits: {
    close: () => true,
  },
  setup(props) {
    const removeSelectedUsers = async (selected: MutableAdminUser_Persisted[]) : Promise<void> => {
      try {
        // filter is a bit redundant, caller shouldn't pass anything not marked for delete
        const userIDs_toRemove = selected.filter(v => v.markedForDelete).map(v => v.userID)
        await iltournament.deleteTournamentTeamAdmins(axiosInstance, {
          tournamentTeamID: props.tournamentTeamID,
          userIDs: userIDs_toRemove,
        })
        props.mut_existingAdmins.value = props.mut_existingAdmins.value.filter(v => !userIDs_toRemove.includes(v.userID))
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const addSelectedUsers = async (selected: MutableAdminUser_Tentative[]) : Promise<void> => {
      try {
        const result = await iltournament.createTournamentTeamAdmins(axiosInstance, {
          tournamentTeamID: props.tournamentTeamID,
          each: selected
        })
        props.mut_existingAdmins.value = [...result, ...props.mut_existingAdmins.value];
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const tabDefs = computed(() => {
      const currentManagers = {
        label: "Current managers",
        keepAlive: true,
        render: () => (
          <div class="p-2">
            <CurrentTournamentAdminUsers
              existingAdminUserListing={props.mut_existingAdmins.value}
              onRemoveSelectedUsers={targets => removeSelectedUsers(targets)}
              canMakeEdits={props.canMakeEdits}
            />
          </div>
        )
      }
      const addManagers = {
        label: "Add managers",
        keepAlive: true,
        render: () => (
          <div class="p-2">
            <AddAdminTournamentUsers
              existingAdminUserListing={props.mut_existingAdmins.value}
              onAddSelectedUsers={targets => addSelectedUsers(targets)}
            />
          </div>
        )
      }

      const result : TabDef[] = [currentManagers]

      if (props.canMakeEdits) {
        result.push(addManagers)
      }

      return result
    })

    return () => (
      <div data-test="TournamentTeamAdmin">
        {!props.canMakeEdits
          ? <div class="text-sm">
              This team's manager list is locked. This view is readonly.
              <div class="border-b my-2"/>
          </div>
          : null
        }
        <Tabs tabDefs={tabDefs.value}/>
      </div>
    )
  }
})
