import { parseIntOr } from "src/helpers/utils";
import { Directive, ExtractPropTypes, PropType, SetupContext, UnwrapNestedRefs, defineComponent, reactive, ref } from "vue";

export const vueDirective_ilDraggable : Directive = {
  mounted: (root: HTMLElement, binding) => {
    // strongly typed getter for binding.instance
    const instance = (() => binding.instance as any as Instance)

    const thisHandle = root.querySelector<HTMLElement>(".il-drag-handle");
    const isSentinel = root.querySelector<HTMLElement>(".il-drag-sentinel-end")

    if (!thisHandle) {
      if (!isSentinel) {
        throw Error("no drag handle?");
      }
    }

    thisHandle?.setAttribute("draggable", "true");
    thisHandle?.addEventListener("dragstart", (evt) => {
      if (!evt.dataTransfer) {
        throw Error("no dataTransfer object?")
      }
      const startIdx = root.getAttribute("data-ildrag-idx")!;
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("text/plain", startIdx)
      evt.dataTransfer.dropEffect = "move";
    })

    thisHandle?.addEventListener("dragend", (evt) => {
      if (!evt.dataTransfer) {
        throw Error("no DataTransfer object?");
      }

      if (evt.dataTransfer.dropEffect === "none") {
        // cancelled
        instance().hoverIdx = null;
        return;
      }
      else {
        // should be handled by 'drop' handler on some node
      }
    })

    root.addEventListener("dragenter", (evt) => {
      evt.preventDefault();
    });

    root.addEventListener("dragover", (evt) => {
      evt.preventDefault();
      if (!evt.dataTransfer) {
        throw Error("no dataTransfer object?")
      }
      evt.dataTransfer.dropEffect = "move";
      const currentIDX = parseIntOr((evt.currentTarget as HTMLElement).getAttribute("data-ildrag-idx"), null);
      instance().hoverIdx = currentIDX;
    })

    root.addEventListener("drop", async (evt) => {
      evt.preventDefault(); // necessary for drop event?

      if (!evt.dataTransfer) {
        throw Error("no dataTransfer object?")
      }

      const originalIDX = parseIntOr(evt.dataTransfer.getData("text/plain"), null);
      const currentIDX = parseIntOr((evt.currentTarget as HTMLElement).getAttribute("data-ildrag-idx"), null);
      if (
        originalIDX === null // bug?
        || currentIDX === null // bug?
        || originalIDX === currentIDX // dropped on self, no-op
        || currentIDX === originalIDX + 1 // also a no-op ("move X immediately prior to X+1", so X wouldn't change)
      ) {
        instance().hoverIdx = null;
        return;
      }
      else {
        await instance().move({moveThisIndex: originalIDX, priorToThisIndex: currentIDX})
        instance().hoverIdx = null;
      }
    })
  },
}

const propsDef = {
  list: {
    required: true,
    type: Array as PropType<readonly any[]>
  },
  render: {
    required: true,
    type: Function as PropType<(_: any, index: number) => null | JSX.Element>
  },
  doMove: {
    required: true,
    type: null as any as PropType<(_: {moveThisIndex: number, priorToThisIndex: number}) => Promise<void>>,
  }
} as const;

type Props = ExtractPropTypes<typeof propsDef>

type Instance = UnwrapNestedRefs<ReturnType<typeof setup>>

const setup = (props: Props) => {
  const move = async (what: {moveThisIndex: number, priorToThisIndex: number}) => {
    await props.doMove(what)
  }

  return {
    hoverIdx: ref<null | number>(null),
    move,
  }
}

/**
 * The provided per-item renderer in props should render an element having an `.il-drag-handle` which will be the "grabble" thing
 * The entire rendered element then becomes draggable.
 */
export const Draglist = defineComponent({
  props: propsDef,
  directives: {
    ilDraggable: vueDirective_ilDraggable
  },
  setup,
  render() {
    return (
      <div>
        {
          this.list.map((e,i) => (
            <div v-ilDraggable data-ildrag-idx={i} class={`${this.hoverIdx === null ? '' : this.hoverIdx == i ? 'border-t-2 border-blue-700' : ''}`}>
              {this.render(e, i)}
            </div>
          ))
        }
        <div v-ilDraggable data-ildrag-idx={this.list.length} class={`${this.hoverIdx === null ? '' : this.hoverIdx == this.list.length ? 'border-t-2 border-blue-700' : ''}`}>
          {/*dummy div to be able to move "past" the last item (by moving it "prior to" a sentinel beyond all items)*/}
          <span class="il-drag-sentinel-end">&nbsp;</span>
        </div>
      </div>
    )
  }
})
