<template lang="pug">
.bg-white.shadow.overflow-hidden.mt-4.w-full(class='sm:rounded-lg')
  .bg-white.px-4.py-5.border-b.border-gray-200.flex.justify-between.items-center(
    class='sm:px-6',
    @click='toggleIcon'
  )
    h3.text-xl.leading-6.font-medium.text-gray-900.h-full
      font-awesome-icon.mr-2(:icon='[prefix, preparedIcon]')
      | {{ label }}
    font-awesome-icon.m-2.text-green-600.text-xl(
      v-if='isMobile && isExpanded',
      :icon='["fas", "minus-square"]'
    )
    font-awesome-icon.m-2.text-green-600.text-xl(
      v-else-if='isMobile && !isExpanded',
      :icon='["fas", "plus-square"]'
    )
  slot
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'

import { emitsDef, propsDef } from './Portlet.ilx'
import { System } from 'src/store/System'

export default defineComponent({
  name: 'Portlet',
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    const isExpanded = ref(true)



    const preparedIcon = computed(() => {
      return props?.icon
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const toggleIcon = () => {
      if (isExpanded.value === false && isMobile.value) {
        emit('expand', true)
        isExpanded.value = true
      } else if (isMobile.value) {
        emit('expand', false)
        isExpanded.value = false
      }
    }

    return {
      isExpanded,
      preparedIcon,
      isMobile,
      toggleIcon,
    }
  },
})
</script>
