
import { defineComponent, onMounted, ref, computed, Ref } from 'vue'
import { useRouter } from 'vue-router'
import authService from 'src/helpers/authService'
import type { ContentChunk } from 'src/interfaces/InleagueApiV1'
import { ContentChunkRequestInterface } from 'src/interfaces/Store/contentChunks'
import { propsDef } from './ContentChunkDisplay.ilx'
import { User } from 'src/store/User'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons'
import { ContentChunkStore } from "src/store/ContentChunkStore"

export default defineComponent({
  name: 'Content Chunk Display',
  props: propsDef,
  setup(props) {
    const contentChunk = ref({}) as Ref<ContentChunk>

    const router = useRouter()

    const hasToEditorPermissions = computed(() => authService(User.value.roles, "webmaster", "inLeague"))

    const editorURL = computed(() => {
      return router.resolve({name: 'content-chunk-result', params: { id: props.id}}).href
    })

    onMounted(async () => {
      if(props.contentChunkObj) {
        contentChunk.value=props.contentChunkObj
      } else {
        const args : ContentChunkRequestInterface = {id: props.id, interpolationArgs: props.interpolationArgs};
        contentChunk.value = await ContentChunkStore.getContentChunk(args);
      }
    })

    return () => {
      return (
        <div class="flex" data-test={`ContentChunkDisplay/${props.id}`}>
          <div class="classes" v-html={contentChunk.value.defaultText}></div>
          {
            props.showPencil
              ? (
                <a href={editorURL.value} target="_blank">
                  <span class={`ml-2 mt-2 cursor-pointer ${hasToEditorPermissions.value ? "" : "invisible"}`}>
                    <FontAwesomeIcon icon={faPencilAlt}/>
                  </span>
                </a>
              )
              : null
          }
        </div>
      )
    }
  }
})
