import { defineComponent, reactive, ref } from "vue"
import { useWindowEventListener } from "./helpers/utils"

let __dummyHandle : HTMLElement | undefined;

/**
 * 1x1 white image
 * This is intended for use in dragstart handlers, to set the dragImage to "basically nothing".
 * TODO: imported transitively by some things in a node environment, but will fail if we
 * run this as an IIFE (which we'd like to) something that refs `document`
 */
const dummyHandle = (() => {
  const img = document.createElement("img");
  img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
  return img;
})

export function getDummyHandle() {
  return __dummyHandle || (__dummyHandle = dummyHandle());
}

export const GlobalDragHandleElement = defineComponent({
  setup() {
    const mousePos = reactive({x: 0, y: 0})

    useWindowEventListener("mousemove", (ev) => {
      mousePos.x = ev.clientX
      mousePos.y = ev.clientY;
    })

    useWindowEventListener("dragover", (ev) => {
      mousePos.x = ev.clientX
      mousePos.y = ev.clientY;
    })

    return () => {
      if (!element.value) {
        return null
      }

      return (
        <div
          style={{
            position: "fixed",
            // We don't want any mouse/drag events randomly firing on the drag handle.
            // Luckily, "pointerEvents:none" appears to allow events to transmit through the element, as if this element
            // didn't exist for purposes of hit testing. This is important because:
            //  a) we'd like the drag handle to be oriented exactly at cursor pos (or, in a world without pointerEvents:none, at (cursorPos.x+1, cursorPos.y +1))
            //  b) without pointerEvents:none, when dragging the mouse reasonably fast, the actual position of the mouse cursor
            //     can get slightly out of sync with our updates here, meaning that ocassionaly you get events that fire on the handle instead
            //     of the underyling "actual" dom element the handle is hovering over. This is annoying in the case of hovering over a legit drop target,
            //     because it will appear as if you rapidly exited-and-then-reentered the drop target, which to the user is nonsense ("hey the handle was
            //     above the drop target the whole time")
            // Without pointer events none we had a working approach where we'd identify this element with an id and then check in drag handlers
            // if the event was fired on a descendant of the handle, and if so just ignore such events.
            pointerEvents: "none",
            // For {x,y} pos, we offset a little bit, which isn't too necessary on chrome/firefox, but on webkit the mousePos offset
            // by itself seems to be a little bit "before and above" the cursor. Ideally we could test for an appropriate value for the offset.
            // This might depend on os config, such as configured cursor sizes or etc. There doesn't seem to be a one-size fits all, so we try
            // to settle on a reasonable value for all browsers. The drag handle itself should be configured to have sufficient padding so the mouse
            // cursor does not overlap any text.
            top: (mousePos.y + 5) + "px",
            left: (mousePos.x + 5) + "px",
            zIndex: 99999,
          }}>
          {element.value()}
        </div>
      )
    }
  }
})

const element = ref<(() => JSX.Element | null) | null>(null);

export function setDragHandleJsxFunc(v: (() => JSX.Element | null) | null) {
  element.value = v;
}

export function clearDragImage() {
  setDragHandleJsxFunc(null);
}
