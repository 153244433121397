import { exhaustiveCaseGuard } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import * as iltypes from "src/interfaces/InleagueApiV1"

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RouteDetail>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

export const RouteName = {
  main: "master-invoice",
} as const;

type RouteName = typeof RouteName;

export type RouteDetail = R_Main

export interface R_Main {
  name: RouteName["main"]
  invoiceID: iltypes.Integerlike
}

export function routeDetailToRoutePath(detail: RouteDetail) : RouteLocationRaw {
  switch(detail.name) {
    case RouteName.main:
      return {name: detail.name, params: {invoiceID: detail.invoiceID}}
    default:
      exhaustiveCaseGuard(detail.name);
  }
}
