export class ExplodedPromise<T> {
  readonly promise: Promise<T>;
  readonly resolve: (v: T | Promise<T>) => void;
  readonly reject: (v?: any) => void;
  constructor() {
    let resolve!: (v: T | Promise<T>) => void;
    let reject!: (v?: any) => void;
    const promise = new Promise<T>((res_, rej_) => {
      resolve = res_;
      reject = rej_;
    });

    this.promise = promise;
    this.resolve = resolve;
    this.reject = reject;
  }
}
