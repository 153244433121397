<template lang="pug">
.t-page.flex.flex-center
FormKit(
  type='form'
  :actions="false"
)
  div
    .px-4.text-sm.mt-5.mb-5.italic
      | This page allows you to associate specific eligibility rules with specific competitions and site functionality. Eligibility rules may be set up in the
      span
        TLegacyAppLink(url='querybuilderv3')
          span.ml-1.underline report builder
      | .
    .grid.grid-cols-1(class="lg:grid-cols-2")
      .mb-5.bg-white.shadow(class='sm:rounded-lg')
        .px-4.py-5(class='sm:p-6')
          h3.mb-5.text-lg.leading-6.font-medium.text-gray-900
            | Current Eligibility Rule Assignments
          .max-w-md(v-if="competitionSeasonRules.length>0")
            .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
              .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
                .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
                  table.min-w-full.divide-y.divide-gray-200
                    thead.bg-gray-50
                      tr
                        th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                          scope='col'
                        )
                          | Rule Name
                        th.relative.px-6.py-3(scope='col')
                          span.sr-only Delete
                    tbody
                      tr.bg-white(v-for="rule in competitionSeasonRules")
                        td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                          | {{ rule.groupLabel }}
                        td.px-6.py-4.whitespace-nowrap.text-right.text-sm.font-medium
                          FormKit(
                            type='button',
                            label="Delete",
                            @click="deletePlayerRuleAssociation(rule.ruleID)"
                          )
          .italic(v-else) No Rules Assigned
      .mb-5.bg-white.shadow(class='sm:rounded-lg')
        .px-4.py-5(class='sm:p-6')
          h3.text-lg.leading-6.font-medium.text-gray-900
            | Player Rules for Team Assignment Pages
          .mt-2.text-sm.text-gray-500
            p
              | Players will not appear in the 'unassigned players' group for any of the team assignment functions if they are ineligible.
          div
            div.mt-2
              div
                div.text-red-500.italic.mb-1(v-if="Object.keys(rules).length===0") No Eligibility Rules Created
                FormKit(
                  :options='rules',
                  v-model='selectedPlayerRule'
                  type='select',
                  placeholder='Select a Rule',
                  name='rules'
                  :disabled="Object.keys(rules).length===0"
                )
              FormKit(
                type='button'
                @click="savePlayerRuleAssociation"
                label="Save Rule"
                :disabled='Object.keys(rules).length===0'
              )
</template>

<script lang='ts' setup>
import { axiosInstance } from "src/boot/axios";
import { createSelect } from "src/helpers/schemaService";
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  competitionUID: {
    required: true,
    type: String
  },
  seasonUID: {
    required: true,
    type: String
  }
})

const rules = ref({})
const competitionSeasonRules = ref({})
const selectedPlayerRule = ref('')
const selectedCoachRule = ref('')

const filterRules = (apiRules: any[]) => {
  // console.log('rules', apiRules)
  const validRules: any[] = []
  for(let i=0; i<apiRules.length; i++) {
    // console.log('rules[i]', apiRules[i])
    if(apiRules[i]['memberIDType']==='childID') {
      validRules.push(apiRules[i])
    }
  }
  // console.log('validRules', validRules)
  return validRules
}

const getRules = async () => {
  try {
    const response = await axiosInstance.get('v1/eligibilityRules')
    return response.data.data
  } catch (error) {
    // console.log('getRules error', error)
  }
}

const getCompetitonSeasonRules = async () => {
  try {
    const response = await axiosInstance.get(`v1/competitionEligibility/${props.competitionUID}/rules`, {params: {seasonUID: props.seasonUID}})
    return response.data.data
  } catch (error) {

  }
}

const deletePlayerRuleAssociation = async (ruleID: string) => {
  const options = {
    ruleID: ruleID,
    seasonUID: props.seasonUID
  }
  try {
    const response = await axiosInstance.delete(`/v1/competitionEligibility/${props.competitionUID}/`, {params: {seasonUID: props.seasonUID, ruleID: ruleID}})
    await initValues()
  } catch (error) {

  }
}

const savePlayerRuleAssociation = async () => {
  // console.log('savePlayerRuleAssociation')
  const options = {
    ruleID: selectedPlayerRule.value,
    seasonUID: props.seasonUID
  }
  try {
    const response = await axiosInstance.put(`/v1/competitionEligibility/${props.competitionUID}`, options)
    competitionSeasonRules.value =  await getCompetitonSeasonRules()
    // console.log('response.data.data', response.data.data)
    return response.data.data
  } catch (error) {

  }
}

const initValues = async () => {
  const validRules = filterRules(await getRules())
  rules.value = createSelect(validRules, 'ruleID', 'groupLabel')
  competitionSeasonRules.value =  await getCompetitonSeasonRules()
}

watch(()=> [props.competitionUID, props.seasonUID], async () => {
  await initValues()
})

onMounted(async () => {
  await initValues()
})
</script>
