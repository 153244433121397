<template lang="pug">
.flex.flex-col(v-if='volunteers.length > 0')
  .overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
    h3.bg-gray-200.min-w-full.rounded-t-lg.px-4.py-2.font-medium Volunteers
    .py-2.align-middle.inline-block.min-w-full
      .shadow.overflow-hidden.border-b.border-gray-200
        table.min-w-full.divide-y.divide-gray-200
          thead.bg-gray-50
            tr
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Name
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Email/Login
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Last Login
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Remove
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='volunteer in volunteers', :key='volunteer.ID')
              td.px-6.py-4.text-sm.font-medium.text-gray-900(
                v-if='volunteer.familyIDs.includes(familyID)'
              )
                | {{ volunteer.firstName }} {{ volunteer.lastName }}
              td.px-6.py-4.text-sm.text-gray-500(
                v-if='volunteer.familyIDs.includes(familyID)'
              )
                | {{ volunteer.email }}
              td.px-6.py-4.text-sm.text-gray-500(
                v-if='volunteer.familyIDs.includes(familyID)'
              )
                | {{ volunteer.lastLogin }}
                //-| last login
              template(v-if='authService(userRoles, "registrar", "webmaster")')
                td.px-6.py-4.text-sm.text-gray-500
                  FormKit(
                    type='button',
                    @click='removeFromFamily(volunteer.ID)'
                  )
                    | Remove from Family
              template(v-else)
                td
                  //- empty
        h3.bg-gray-200.min-w-full.px-4.py-2.font-medium Add User to Family
        p.px-4.py-2 Enter part of a user's name or email address below to add them to this family profile.
        .flex.px-4.items-center
          FormKit(type='text', v-model='searchValue')
          .ml-2
            FormKit(type='button', label='Search Users', @click='lookupUsers')

        div(v-if='volunteerSearchValues.length > 0 && searched')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                  scope='col'
                )
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                  scope='col'
                ) Name
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                  scope='col'
                ) Email
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                  scope='col'
                ) Last Login
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
                  scope='col'
                ) Family Members
            tbody.bg-white.divide-y.divide-gray-200
              tr(
                v-for='volunteer in volunteerSearchValues',
                :key='volunteer.ID'
              )
                td.px-6.py-4.text-sm.font-medium.text-gray-900.flex.items-center
                  FormKit(
                    type='radio',
                    v-model='moveUserID',
                    :options='{ [volunteer.ID]: "" }',
                    fieldsetClass='noBorder'
                  )
                td.px-6.py-4.text-sm.font-medium.text-gray-900
                  | {{ volunteer.firstName }} {{ volunteer.lastName }}
                td.px-6.py-4.text-sm.text-gray-500
                  | {{ volunteer.email }}
                td.px-6.py-4.text-sm.text-gray-500
                  | {{ volunteer.lastLogin ? dayJSDate(volunteer.lastLogin) : "" }}
                td.px-6.py-4.text-sm.text-gray-500
                  | volunteer family
          .px-4.my-2
            FormKit(type='button', label='Move User', @click='moveUser')
</template>

<script lang="ts" setup>
import { dayJSDate } from 'src/helpers/formatDate'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { ref, onMounted, computed } from 'vue'
import * as ilapi from "src/composables/InleagueApiV1"
import type { User as IUser, VolunteerSearchResult } from 'src/interfaces/InleagueApiV1'
import { User } from "src/store/User"
import authService from 'src/helpers/authService'

import type * as iltypes from "src/interfaces/InleagueApiV1"
import { propsDef } from './AdminVolunteers.ilx'

const props = defineProps(propsDef);


const userRoles = computed(() => User.value.roles);

const volunteers = ref<IUser[]>([])
const volunteerSearchValues = ref<VolunteerSearchResult[]>([])
const searchValue = ref('')
const searched = ref(false)
const moveUserID = ref('')

const removeFromFamily = async (userID: string) : Promise<void> => {
  try {
    await axiosInstance.delete(
      `/v1/user/${userID}/family/${props.familyID}`
    )
    volunteers.value = await getVolunteers()
  }
  catch (err) {
    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
  }
}

const getVolunteers = () : Promise<iltypes.User[]> => {
  return ilapi.getAllUsersOfFamily(axiosInstance, {familyID: props.familyID});
}

const lookupUsers = async () : Promise<void> => {
  volunteerSearchValues.value = await ilapi.findVolunteers(axiosInstance, {search: searchValue.value});
  searched.value = true;
}

const moveUser = async () : Promise<void> => {
  try {
    await axiosInstance.post(
      `v1/user/${moveUserID.value}/family/${props.familyID}`
    )
    volunteers.value = await getVolunteers()
    volunteerSearchValues.value = []
    searchValue.value = ''
  }
  catch (err) {
    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
  }
}

onMounted(async () => {
  volunteers.value = await getVolunteers()
})
</script>

<style>
.noBorder {
  border-color: rgb(255 255 255) !important;
}
</style>
