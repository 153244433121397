<template lang="pug">
FormKit(
  v-if='ready && userRelationshipForm && Object.keys(userRelationshipForm).length > 0',
  type='form',
  v-model='userRelationshipForm',
  :actions='false'
)
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                  scope='col'
                ) User
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                  scope='col'
                ) Relationship
            tbody.bg-white.divide-y.divide-gray-200
              tr(v-for='user in users')
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900(data-test="username")
                  | {{ user.firstName }} {{ user.lastName }} {{ user.ID === player.parent1ID ? "(Parent 1)" : user.ID === player.parent2ID ? "(Parent 2)" : "" }}
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  FormKit(
                    type='select',
                    placeholder='Select Relationship',
                    :options='relationshipTypes',
                    :name='user.ID'
                    data-test="relationship-select"
                  )
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue'
import type { PropType } from 'vue'
import relatedUserFunctions, { type PartialRelatedUser } from 'src/composables/relatedUsers'

import type { WithDefinite, Child, User, Guid, Integerlike } from 'src/interfaces/InleagueApiV1'
import * as ilapi from "src/composables/InleagueApiV1"
import { UiOption, useIziToast } from 'src/helpers/utils'

type ExpandedChild = WithDefinite<Child, "familyMembers" | "parent1ID" | "parent2ID">;

export default defineComponent({
  props: {
    relatedUsers: {
      required: true,
      type: Object as PropType<PartialRelatedUser[]>,
    },
    triggerSave: {
      required: true,
      type: Boolean,
    },
    player: {
      required: true,
      type: Object as PropType<ExpandedChild>,
    },
  },
  emits: {
    saved: () => true,
    error: () => true,
  },
  setup(props, {emit}) {
    const relationshipTypes = ref<UiOption[]>([])
    const users = ref([] as User[])
    const userRelationshipForm = ref<{[userID: Guid]: /*api sourced relationshipID as number or form-induced string or empty string*/ string | number}>({})
    const iziToast = useIziToast();

    const { processRelatedUsers } = relatedUserFunctions()

    watch(
      () => props.triggerSave,
      async val => {
        if (val && props.player.childID) {
          if (validRelationships(props.player, userRelationshipForm.value)) {
            const submittables = createApiSubmittables(
              props.player,
              // By the time we get here, we should have guaranteed that there are only integer values in the map.
              // Empty strings are naturally part of a form's life but they should not be present at this point.
              userRelationshipForm.value as {[userID:Guid]: Integerlike}
            )
            for (const submittable of submittables) {
              try {
                await ilapi.setSingleUserPlayerRelationship(axiosInstance, submittable);
              } catch (error) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(error);
              }
            }
            emit('saved')
          } else {
            iziToast.error({
              message: `Please define relationships for Parent 1${
                props.player.parent2ID ? ' and Parent 2' : ''
              }.`,
            })
            emit('error')
          }
        }
      }
    )

    const ready = ref(false);

    onMounted(async () => {
      relationshipTypes.value = (await ilapi.getRelationshipTypes(axiosInstance)).map(v => ({label: v.typename, value: v.typeID.toString()}))
      users.value = props.player.familyMembers;
      userRelationshipForm.value = processRelatedUsers(
        props.relatedUsers,
        users.value.map(v => v.ID),
      )

      ready.value = true;
    })

    return {
      ready,
      userRelationshipForm,
      users,
      relationshipTypes
    }
  }
})

function createApiSubmittables(player: ExpandedChild, userRelationshipForm: {[userID: Guid]: Integerlike}) : ilapi.SetSingleUserPlayerRelationshipArgs[] {
  const result : ilapi.SetSingleUserPlayerRelationshipArgs[] = [];
  for (const userID of Object.keys(userRelationshipForm)) {
    if (userRelationshipForm[userID]) {
      result.push({
        userID: userID,
        relationship_type_id: userRelationshipForm[userID],
        childID: player.childID
      })
    }
  }
  return result
}

// verifies that parent1's relationship is defined and if parent2 is present, it is defined as well
function validRelationships(player: ExpandedChild, userRelationshipForm: {[userID: Guid]: string | number}) : boolean {
  let definedParent1 = false
  let definedParent2 = false
  for (const userID of Object.keys(userRelationshipForm)) {
    if (
      userID === player.parent1ID &&
      userRelationshipForm[userID]
    ) {
      definedParent1 = true
    } else if (
      userID === player.parent2ID &&
      userRelationshipForm[userID]
    ) {
      definedParent2 = true
    }
  }
  return definedParent1 && (definedParent2 || !player.parent2ID)
}
</script>
