<template lang="pug">
.flex.flex-col.w-full.m-4
  i.fas.fa-tools.fa-5x.mx-auto.my-2.m-4
  h1.text-center This is a secure area for Webmasters only
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SecureTest',
})
</script>
