import { accentAwareCaseInsensitiveCompare, forceCheckedIndexedAccess, maybeParseJSON, noAvailableOptions, parseIntOr, sortBy, sortByDayJS, sortByMany, UiOption, UiOptions } from "src/helpers/utils";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { FormKit } from "@formkit/vue";
import { Client } from "src/store/Client";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { BracketForListing, deleteBracket, getBracketsForListing, getFlatSeasonCompDivOptions } from "./Bracket.io";
import { RouterLink, useRouter } from "vue-router";

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { queryGuid } from "src/boot/TypeBoxSetup"
import { BasicTable, ColDef, freshSortState, typedBasicTableProps } from "src/modules/TableUtils";
import { DAYJS_FORMAT_IL_FULL_1, dayjsOr } from "src/helpers/formatDate";
import { Paginated } from "src/modules/PaginationUtils";
import * as R_BracketBuilder from "./R_BracketBuilder.route"
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { SoccerBall } from "src/components/SVGs";
import { User } from "src/store/User";
import { withNoScroll } from "src/router/RouterScrollBehavior";

export default defineComponent({
  setup() {
    const router = useRouter()

    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const competitionOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const divisionOptions = ref<UiOptions>(noAvailableOptions("Loading..."))

    const selectedSeasonUID = ref("")
    const selectedDivID = ref("")
    const selectedCompetitionUID = ref("")

    const tableData = (() => {
      const loader = ReactiveReifiedPromise<BracketForListing[]>(undefined, {defaultDebounce_ms: 200})
      const data = computed(() => {
        return loader.underlying.status === "pending" ? "pending"
          : loader.underlying.status === "resolved" ? loader.underlying.data
          : []
      })

      return {
        get loader() { return loader },
        get data() { return data.value },
      }
    })()

    const tryRehydrateFromCurrentSelection = async () : Promise<void> => {
      const competitionUID = selectedCompetitionUID.value
      const divID = selectedDivID.value
      const seasonUID = selectedSeasonUID.value
      if (!competitionUID || !divID || !seasonUID) {
        return
      }

      tableData.loader.run(() => getBracketsForListing(axiosAuthBackgroundInstance, {competitionUID, divID, seasonUID}))
    }

    onMounted(async () => {
      // if there are some query params, use that; then try stored state; we may get neither, which is fine.
      const q = mungeQueryParamsLike(router.currentRoute.value.query) || mountState_load();
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const opts = await getFlatSeasonCompDivOptions(axiosInstance)

        seasonOptions.value = opts.seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))}

        competitionOptions.value = opts.competitions.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.competitions.map(v => ({label: v.competition, value: v.competitionUID}))}

        divisionOptions.value = opts.divisions.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: opts.divisions.map(v => ({label: v.division, value: v.divID}))}

        selectedCompetitionUID.value = competitionOptions.value.options.find(v => v.value === q?.competitionUID)?.value
          || (forceCheckedIndexedAccess(competitionOptions.value.options, 0)?.value ?? "")

        selectedDivID.value = divisionOptions.value.options.find(v => v.value === q?.divID)?.value
          || (forceCheckedIndexedAccess(divisionOptions.value.options, 0)?.value ?? "")

        selectedSeasonUID.value = seasonOptions.value.options.find(v => v.value === q?.seasonUID)?.value
          || (forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value ?? "")

        await tryRehydrateFromCurrentSelection()
      })
    })

    const deleteBracketModalController = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()
      const doDeleteBracket = async (bracket: BracketForListing) : Promise<void> => {
        const data = tableData.data;
        if (data === "pending") {
          // shouldn't happen
          return
        }

        try {
          try {
            busy.value = true
            await deleteBracket(axiosAuthBackgroundInstance, {bracketID: bracket.bracketID})
          }
          finally {
            busy.value = false
          }

          deleteBracketModalController.close()
          const deleteIdx = data.findIndex(v => v.bracketID === bracket.bracketID)
          if (deleteIdx !== -1) {
            data.splice(deleteIdx, 1)
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController_r<BracketForListing>({
        title: () => <>
          <div>Delete Bracket</div>
          <div class="my-2 border-b"></div>
        </>,
        content: bracket => {
          if (!bracket) {
            return null
          }
          return (
            <div>
              <div class="my-2">Delete bracket "{bracket.bracketName}"?</div>
              <div class="my-2">This cannot be undone.</div>
              <div class="flex gap-2 items-center mt-4">
                <Btn2 class="px-2 py-1" onClick={() => doDeleteBracket(bracket)}>Yes, delete</Btn2>
                <Btn2 class="px-2 py-1" onClick={() => deleteBracketModalController.close()} enabledClasses={btn2_redEnabledClasses}>No, cancel</Btn2>
              </div>
              {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
            </div>
          )
        }
      }, {onCloseCB})
    })();

    const colDefs = computed<ColDef<BracketForListing>[]>(() => {
      return [
        {
          id: "name",
          label: "Bracket Name",
          headerClass: "border p-1",
          html: v => v.bracketName,
          sort: sortBy(v => v.bracketName),
        },
        {
          id: "competition",
          label: "Program",
          headerClass: "border p-1",
          html: v => v.competition,
          sort: sortBy(v => parseIntOr(v.competitionID, Infinity), "asc")
        },
        {
          id: "season",
          label: "Season",
          headerClass: "border p-1",
          html: v => v.seasonName,
          sort: sortBy(v => parseIntOr(v.seasonID, -Infinity), "desc")
        },
        {
          id: "division",
          label: "Division",
          headerClass: "border p-1",
          html: v => `${v.gender}${v.divNum}`,
          sort: sortByMany(
            sortBy(v => parseIntOr(v.divNum, Infinity), "asc"),
            sortBy(v => v.gender, "asc")
          )
        },
        {
          id: "numRounds",
          label: "No. of Rounds",
          headerClass: "border p-1",
          html: v => v.numRounds,
          sort: sortByMany(
            sortBy(v => parseIntOr(v.numRounds, Infinity))
          )
        },
        {
          id: "firstGameStart",
          label: "Earliest Game",
          headerClass: "border p-1",
          html: v => dayjsOr(v.firstGameStart)?.format(DAYJS_FORMAT_IL_FULL_1) || "n/a",
          sort: sortByDayJS(v => v.firstGameStart)
        },
        {
          id: "createdBy",
          label: "Created By",
          headerClass: "border p-1",
          html: v => `${v.createdBy.firstName} ${v.createdBy.lastName}`,
          sort: sortByMany(
            (l,r) => accentAwareCaseInsensitiveCompare(l.createdBy.lastName, r.createdBy.lastName),
            (l,r) => accentAwareCaseInsensitiveCompare(l.createdBy.firstName, r.createdBy.firstName)
          )
        },
        {
          id: "createdOn",
          label: "Created On",
          headerClass: "border p-1",
          html: v => {
            const d = dayjsOr(v.createdOn)
            return <>
              <div class="text-sm">{d?.format("MMM DD, YY")}</div>
              <div class="text-xs">{d?.format("h:mm a")}</div>
            </>
          },
          sort: sortByDayJS(v => v.createdOn)
        },
        {
          id: "buttons1",
          label: "",
          headerClass: "border p-1",
          html: v => {
            return <div class="flex items-center gap-1">
              <RouterLink to={{name: R_BracketBuilder.RouteName, query: {bracketID: v.bracketID} satisfies R_BracketBuilder.QueryParams}}>
                <Btn2 class="text-xs px-2 py-1">View/Edit</Btn2>
              </RouterLink>
              <Btn2 class="text-xs px-2 py-1 flex items-center gap-1" onClick={() => deleteBracketModalController.open(v)}>
                <FontAwesomeIcon icon={faTrash}/>
                <span>Delete</span>
              </Btn2>
            </div>
          },
          xlsx: "never",
        }
      ]
    })
    const sortState = ref(freshSortState<BracketForListing, string | number>([]))
    watch(() => colDefs.value, () => {
      sortState.value = freshSortState(colDefs.value)
    }, {immediate: true})

    const itemsPerPageOptions = computed<UiOption<Integerlike | "ALL">[]>(() => {
      return [
        {label: "50", value: "50"},
        {label: "100", value: "100"},
        {label: "All", value: "ALL"}
      ]
    });
    const selectedItemsPerPageOption = ref<Integerlike | "ALL">(forceCheckedIndexedAccess(itemsPerPageOptions.value, 0)?.value ?? "ALL")

    const sortedData = computed(() => tableData.data === "pending" ? [] : [...tableData.data].sort(sortState.value.asSorter()))
    const pagination = computed(() => Paginated(parseIntOr(selectedItemsPerPageOption.value, "ALL"), sortedData))

    const persistQueryParamsLike = () => {
      const args = {
        competitionUID: selectedCompetitionUID.value || undefined,
        divID: selectedDivID.value || undefined,
        seasonUID: selectedSeasonUID.value || undefined
      } as const;

      mountState_store(args)

      withNoScroll(async () => {
        await router.replace({...router.currentRoute.value, query: {...router.currentRoute.value.query, ...args}})
      })
    }

    return () => {
      return (
        <div style="--fk-padding-input:.5em; --fk-bg-input:white; --fk-margin-outer:none; max-width:2000px;">
          <AutoModal controller={deleteBracketModalController}/>
          <div style="display:inline-grid; grid-template-columns: max-content 20em; grid-gap: .5em;">
            <div class="self-center">Season</div>
            <FormKit
              type="select"
              disabled={seasonOptions.value.disabled}
              options={seasonOptions.value.options}
              v-model={selectedSeasonUID.value}
              onInput={(value: any) => {
                if (value === selectedSeasonUID.value) {
                  return
                }
                selectedSeasonUID.value = value
                persistQueryParamsLike()
                tryRehydrateFromCurrentSelection()
              }}
            />

            <div class="self-center">Program</div>
            <FormKit
              type="select"
              disabled={competitionOptions.value.disabled}
              options={competitionOptions.value.options}
              v-model={selectedCompetitionUID.value}
              onInput={(value: any) => {
                if (value === selectedCompetitionUID.value) {
                  return
                }
                selectedCompetitionUID.value = value
                persistQueryParamsLike()
                tryRehydrateFromCurrentSelection()
              }}
            />

            <div class="self-center">Division</div>
            <FormKit
              type="select"
              disabled={divisionOptions.value.disabled}
              options={divisionOptions.value.options}
              v-model={selectedDivID.value}
              onInput={(value: any) => {
                if (value === selectedDivID.value) {
                  return
                }
                selectedDivID.value = value
                persistQueryParamsLike()
                tryRehydrateFromCurrentSelection()
              }}
            />

            <div style="grid-column:-1/1;" class="flex gap-2 items-center">
              <Btn2 class="px-2 py-1" onClick={() => tryRehydrateFromCurrentSelection()}>Refresh Listing</Btn2>
              <RouterLink
                class="ml-auto"
                to={{
                  name: R_BracketBuilder.RouteName,
                  query: {
                    seasonUID: selectedSeasonUID.value,
                    competitionUID: selectedCompetitionUID.value,
                    divID: selectedDivID.value
                  } satisfies R_BracketBuilder.QueryParams
                }
              }>
                <Btn2 class="px-2 py-1">Create...</Btn2>
              </RouterLink>
            </div>
          </div>

          <div class="border-b my-4"></div>

          <div class="rounded-md shadow-md w-full bg-white">
            <div class="rounded-t-md bg-green-800 text-white px-2 py-1">Brackets</div>
            <div class="p-2 w-full overflow-auto">
              <BasicTable
                style="width:100%; min-width:1000px;"
                {...typedBasicTableProps({
                  rowData: pagination.value.pageData.itemsThisPage,
                  colDefs: colDefs.value,
                  sortState: sortState.value,
                  rowKey: row => row.bracketID,
                  rowAttrs: row => ({"data-test": row.bracketID}),
                  noData: () => <div class="flex items-center p-4 gap-2">
                    {tableData.data === "pending"
                      ? <>
                        <SoccerBall width="1.25em" height="1.25em" timeForOneRotation="1.5s" color={Client.value.clientTheme.color}/>
                        <div class="">Loading...</div>
                      </>
                      : <div>No data.</div>
                    }
                  </div>
                })}
                class="w-full"
              />
              <div class="mt-2 text-sm">
                {pagination.value.pageData.count_itemsThisPage > 0
                  ? <div>Showing {pagination.value.pageData.zi_currentPageFirstIndex + 1} to {pagination.value.pageData.zi_currentPageLastIndex} of {pagination.value.pageData.count_totalItems} entries</div>
                  : null
                }
                <div class="flex items-center gap-2">
                  <a class={pagination.value.pageData.hasPrev ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasPrev ? (pagination.value.prevPage()) : void 0}>Previous</a>
                  <a class={pagination.value.pageData.hasNext ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasNext ? (pagination.value.nextPage()) : void 0}>Next</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
})

function mungeQueryParamsLike(q: Record<string, any>) {
  const shape = t.Object({
    competitionUID: t.Optional(queryGuid()),
    seasonUID: t.Optional(queryGuid()),
    divID: t.Optional(queryGuid()),
  })

  if (Value.Check(shape, q)) {
    const {competitionUID, seasonUID, divID} = q;
    if (!competitionUID && !seasonUID && !divID) {
      // if nothing was set then we get here, because everything is optional,
      // but really we want to say "no query params provided"
      return undefined
    }
    return {
      competitionUID,
      seasonUID,
      divID,
    }
  }
  else {
    return undefined
  }
}

// we conflate here the valid query params and the things which we store to rehydrate on mount.
type QueryParamsLike = Partial<ReturnType<typeof mungeQueryParamsLike>>

function mountState_key() {
  return `bracketListing/${User.userData?.userID || "<nil>"}`;
}
function mountState_store(v: QueryParamsLike) {
  sessionStorage.setItem(mountState_key(), JSON.stringify(v));
}
function mountState_load() : QueryParamsLike | null {
  const v = sessionStorage.getItem(mountState_key());
  const o = maybeParseJSON(v || "")
  return mungeQueryParamsLike(o)
}
