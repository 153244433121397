import authService from "src/helpers/authService";
import { Guid } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";
import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

export const propsDef = {

} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "TeamPools.main",
} as const;

export const QueryParams = {
  seasonUID: "suid",
  competitionUID: "cuid",
  divID: "divID",
  poolUID: "poolUID",
} as const

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | R_Main

export interface R_Main extends RoutePropsBase {
  routeName: typeof RouteNames.main,
}

export function routeDetailToRouteLocation(v: RoutePropsVariant, q?: {seasonUID?: Guid, competitionUID?: Guid, divID?: Guid}) : RouteLocationRaw {
  const {routeName, ...params} = v
  return {name: v.routeName, params, query: {
    [QueryParams.seasonUID]: q?.seasonUID,
    [QueryParams.competitionUID]: q?.competitionUID,
    [QueryParams.divID]: q?.divID
  }}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {}
}

export function hasSomeRoutePermission() : boolean {
  const hasSomeRole = authService(User.value.roles, "gameScheduler", "webmaster")
  const isSomeCompetitionManager = !!User.userData?.competitionsMemento.find(v => v.isGameScheduler)
  const isSomeDivisionHead = !!User.userData?.divisionsMemento.find(v => v.writeAccess)
  return hasSomeRole || isSomeCompetitionManager || isSomeDivisionHead
}
