import { FormKit } from "@formkit/vue";
import { faFileExport, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import { vReqT, UiOption, sortBy, sortByMany, maybePrettifyUSPhoneNumber, parseIntOr, downloadFromObjectURL, exhaustiveCaseGuard, accentAwareCaseInsensitiveCompare } from "src/helpers/utils";
import { EscapedRegExp } from "src/helpers/utils-backend";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import { Paginated } from "src/modules/PaginationUtils";
import { inferColIDs, freshSortState, rowsToXlsxBuffer, rowsToCsv, SortArrow, getColDefHtml } from "src/modules/TableUtils";
import { defineComponent, ref, computed, watch } from "vue";
import { PlayerRosterSort } from "./R_TeamSeason.route";
import { TeamSeasonReportEntry } from "src/composables/InleagueApiV1.Teams";
import { Integerlike } from "src/interfaces/InleagueApiV1";

export const ContactInfoListingTable = defineComponent({
  props: {
    data: vReqT<TeamSeasonReportEntry[]>(),
    sort: vReqT<PlayerRosterSort>(),
  },
  setup(props) {
    const itemsPerPage = ref<Integerlike | "ALL">(25)
    const itemsPerPageOptions : UiOption[] = [
      {label: "25", value: "25"},
      {label: "50", value: "50"},
      {label: "All", value: "ALL"}
    ]

    const colDefs = ref(inferColIDs<TeamSeasonReportEntry>()([
      {
        id: "teamName",
        label: "Team name",
        html: v => v.teamDesignation,
        sort: sortBy(_ => _.teamDesignation)
      },
      {
        id: "player",
        label: "Player",
        html: (v) => `${v.registration.child.playerFirstName} ${v.registration.child.playerLastName}`,
        sort: {
          cb: sortByMany(
            (l,r) => accentAwareCaseInsensitiveCompare(l.registration.child.playerLastName, r.registration.child.playerLastName),
            (l,r) => accentAwareCaseInsensitiveCompare(l.registration.child.playerFirstName, r.registration.child.playerFirstName)
          ),
          dir: "not-sorted"
        }
      },
      {
        id: "uniform",
        label: "Uniform",
        html: (v) => v.uniform,
        sort: {
          cb: sortBy(_ => parseIntOr(_.uniform, -1)),
          dir: "not-sorted"
        }
      },
      {
        id: "playerBirthDate",
        label: "Player birth date",
        html: (v) => dayjs(v.registration.child.playerBirthDate).format("MMM/DD/YYYY"),
        sort: {
          cb: (l,r) => {
            const xl = dayjs(l.registration.child.playerBirthDate)
            const xr = dayjs(r.registration.child.playerBirthDate)
            return xl.isBefore(xr, "day") ? -1 : xl.isSame(xr, "day") ? 0 : 1
          },
          dir: "not-sorted",
        },
      },
      {
        id: "aysoID",
        label: "AYSOID",
        html: v => v.registration.child.AYSOID,
        sort: {
          cb: sortBy(_ => _.registration.child.AYSOID),
          dir: "not-sorted",
        }
      },
      {
        id: "school",
        label: "School",
        html: v => v.registration.playerSchool,
        sort: {
          cb: sortBy(_ => _.registration.playerSchool),
          dir: "not-sorted",
        }
      },
      {
        id: "weight",
        label: "Weight",
        html: v => v.registration.playerWeight,
        sort: {
          cb: sortBy(_ => _.registration.playerWeight),
          dir: "not-sorted",
        }
      },
      {
        id: "parent1",
        label: "Parent1",
        html: v => `${v.registration.child.family.parent1.firstName} ${v.registration.child.family.parent1.lastName}`,
        sort: {
          cb: sortByMany(
            sortBy(_ => _.registration.child.family.parent1.lastName),
            sortBy(_ => _.registration.child.family.parent1.firstName),
          ),
          dir: "not-sorted",
        }
      },
      {
        id: "parent1email",
        label: "Parent1 Email",
        html: v => v.registration.child.family.parent1.email,
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.email),
          dir: "not-sorted",
        }
      },
      {
        id: "parent2",
        label: "Parent2",
        html: v => {
          if (v.registration.child.family.parent2) {
            return `${v.registration.child.family.parent2.firstName} ${v.registration.child.family.parent2.lastName}`
          }
          else {
            return ""
          }
        },
        sort: {
          cb: sortByMany(
            sortBy(_ => _.registration.child.family.parent2?.lastName ?? 9999999999),
            sortBy(_ => _.registration.child.family.parent2?.firstName ?? 9999999999),
          ),
          dir: "not-sorted",
        }
      },
      {
        id: "parent2email",
        label: "Parent2 Email",
        html: v => v.registration.child.family.parent2?.email || "",
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent2?.email),
          dir: "not-sorted",
        }
      },
      {
        id: "parent1CellPhone",
        label: "Parent 1 cellphone",
        html: v => maybePrettifyUSPhoneNumber(v.registration.child.family.parent1.primaryPhone),
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.primaryPhone),
          dir: "not-sorted",
        }
      },
      {
        id: "parent2CellPhone",
        label: "Parent2 cellphone",
        html: v => maybePrettifyUSPhoneNumber(v.registration.child.family.parent2?.primaryPhone || ""),
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent2?.primaryPhone ?? 9999999999),
          dir: "not-sorted",
        }
      },
      {
        id: "streetAddr",
        label: "Street address",
        html: v => v.registration.child.family.parent1.street,
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.street),
          dir: "not-sorted",
        }
      },
      {
        id: "city",
        label: "City",
        html: v => v.registration.child.family.parent1.city,
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.city),
          dir: "not-sorted",
        }
      },
      {
        id: "state",
        label: "State",
        html: v => v.registration.child.family.parent1.state,
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.state),
          dir: "not-sorted",
        }
      },
      {
        id: "zip",
        label: "Zip",
        html: v => v.registration.child.family.parent1.zip,
        sort: {
          cb: sortBy(_ => _.registration.child.family.parent1.zip),
          dir: "not-sorted",
        }
      }
    ]));

    const sortState = freshSortState(colDefs.value)
    watch(() => props.sort, () => {
      if (props.sort === "name") {
        sortState.reconfigure([{colID: "player", dir: "asc"}])
      }
      else if (props.sort === "uniform") {
        sortState.reconfigure([{colID: "uniform", dir: "asc"}])
      }
      else {
        exhaustiveCaseGuard(props.sort)
      }
    }, {immediate: true})

    const filters = ref({playerName: ""})

    const applyFilters = (vs: readonly TeamSeasonReportEntry[]) => {
      return vs.filter(filterByName())

      function filterByName() {
        const s = filters.value.playerName.trim()
        const pattern = s ? EscapedRegExp(s, "i") : null;
        if (pattern) {
          return (v: TeamSeasonReportEntry) => pattern.test(`${v.registration.child.playerFirstName} ${v.registration.child.playerLastName}`)
        }
        else {
          return () => true
        }
      }
    }

    const applySort = (vs: readonly TeamSeasonReportEntry[]) => {
      return [...vs].sort(sortState.asSorter())
    }

    const filteredSortedRows = computed(() => {
      return applySort(applyFilters(props.data))
    })

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), filteredSortedRows))

    const downloadAsXlsx = async () : Promise<void> => {
      const source = applySort(props.data); // n.b. not filtered
      const buffer = await rowsToXlsxBuffer(colDefs.value, source)
      downloadFromObjectURL(buffer, "TeamSeason.xlsx")
    }

    const downloadAsCsv = () : void => {
      const source = applySort(props.data); // n.b. not filtered
      const csv = rowsToCsv(colDefs.value, source)
      downloadFromObjectURL(csv, "TeamSeason.csv")
    }

    const copyToClipboard = () : void => {
      const source = applySort(props.data); // n.b. not filtered
      const csv = rowsToCsv(colDefs.value, source)
      navigator.clipboard.writeText(csv)
    }

    return () => {
      return (
        <div class="border border-slate-200 m-auto overflow-auto" style="min-width:32em;">
          <div class="p-2 flex items-start">
            <div style="--fk-margin-outer: none; --fk-padding-input: .325em;">
              <FormKit type="text" {...{placeholder:"Filter by player name"}} v-model={filters.value.playerName}/>
            </div>
            <div class="flex gap-2 ml-auto">
              <t-btn onClick={downloadAsCsv} margin={false}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As CSV</span>
              </t-btn>
              <t-btn onClick={downloadAsXlsx} margin={false}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As Excel</span>
              </t-btn>
              <t-btn onClick={copyToClipboard} margin={false}>
                <FontAwesomeIcon icon={faClipboard}/>
                <span class="ml-1 text-xs">Copy</span>
              </t-btn>
            </div>
          </div>
          <table class="w-full">
            <tr>
              {
                colDefs.value.map(colDef => {
                  return (
                    <th class="p-2 text-left whitespace-nowrap">
                      <div class="flex items-center text-sm">
                        <span>{colDef.label}</span>
                        {
                          ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                            return sorter
                              ? (
                                <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                  <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                </span>
                              )
                              : null
                          })(sortState.sortersByColID[colDef.id])
                        }
                      </div>
                    </th>
                  )
                })
              }
            </tr>
            {
              pagination.value.pageData.itemsThisPage.map((entry, i) => {
                const rowBgColor = i % 2 ? "bg-gray-100" : ""
                return (
                  <tr key={entry.assignmentID}>
                    {
                      colDefs.value.map(colDef => {
                        return (
                          <td class={`${rowBgColor} p-2`}>
                            {
                              getColDefHtml(colDef, entry)
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </table>
          {
            props.data.length === 0
              ? <div class="p-4 flex items-center justify-center">Nothing found</div>
              : null
          }
          {
            props.data.length > 0
              ? (
                <div class="flex">
                  <div class="ml-auto p-2">
                    <SimplePaginationNav mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                  </div>
                </div>
              )
              : null
          }
        </div>
      )
    }
  }
})
