import type { AxiosInstance } from 'axios'
import { parseFloatOrFail, parseIntOr } from 'src/helpers/utils'

import { LastStatus_t as FIXME_MoveToIltypesOrSimilar_LastStatus_t } from 'src/interfaces/Store/checkout'

import * as iltypes from "src/interfaces/InleagueApiV1"
import { type CheckedOmit } from "src/helpers/utils"

export interface CouponCompetitionSeasonLink {
  competitionSeasonCouponLinkID: iltypes.Guid,
  couponID: iltypes.Guid,
  competitionSeasonUID: iltypes.Guid,
  seasonUID: iltypes.Guid,
  seasonName: string,
  competitionUID: iltypes.Guid,
  competitionName: string,
  competitionID: number,
  redemptionInfo: {
    invoice: {
      instanceID: iltypes.Integerlike,
      lastStatus: FIXME_MoveToIltypesOrSimilar_LastStatus_t,
    }
    invoiceLineItem: {
      lineItemID: iltypes.Integerlike,
      competitionRegistrationID: iltypes.Guid,
    }
  }[]
}

export interface CouponEventLink {
  eventCouponLinkID: iltypes.Guid,
  eventID: iltypes.Guid,
  couponID: iltypes.Guid,
  eventName: string,
  redemptionInfo: {
    invoice: {
      instanceID: iltypes.Integerlike,
      lastStatus: FIXME_MoveToIltypesOrSimilar_LastStatus_t,
    }
    invoiceLineItem: {
      lineItemID: iltypes.Integerlike,
      eventSignupID: iltypes.Guid
    }
  }[]
}

export interface CouponChildLink {
  childCouponLinkID: iltypes.Guid,
  childID: iltypes.Guid,
  couponID: iltypes.Guid,
  playerFirstName: string,
  playerLastName: string,
  redemptionInfo: {
    invoice: {
      instanceID: iltypes.Integerlike,
      lastStatus: FIXME_MoveToIltypesOrSimilar_LastStatus_t,
    }
  }[]
}

export interface CouponUserLink {
  userCouponLinkID: iltypes.Guid,
  userID: iltypes.Guid,
  couponID: iltypes.Guid,
  firstName: string,
  lastName: string,
  redemptionInfo: {
    invoice: {
      instanceID: iltypes.Integerlike,
      lastStatus: FIXME_MoveToIltypesOrSimilar_LastStatus_t,
    }
  }[]
}

// if a field is added here, it probably also needs to be added to `mungeCoupon`
export interface Coupon {
  /**
   * primary key
   */
  couponID: iltypes.Guid,
  /**
   * arbitrary code, like 'SOMECOUPON500'
   * serves as an alternate key, users will type this in to redeem
  */
  couponCode: string,
  appliesTo_wildcard: boolean,
  appliesTo_compSeason_competitionRegistration: boolean,
  appliesTo_compSeason_tournamentTeamRegistration: boolean,
  clientID: iltypes.Guid,
  couponsRedeemed: number,
  /**
   * expandable
   */
  couponCompetitionSeasonLinks?: CouponCompetitionSeasonLink[]
  /**
   * expandable
   */
  couponEventLinks?: CouponEventLink[],
  /**
   * expandable
   */
  couponUserLinks?: CouponUserLink[],
  /**
   * expandable
   */
  couponChildLinks?: CouponChildLink[],
  couponQuantity: {type: "int", value: number} | {type: "unlimited"},
  /**
   * ID of user who created the coupon; can be null, but that is generally from legacy records
   */
  createdBy: iltypes.Guid | null,
  /**
   * when the coupon was first entered into the db; can be null, but that is generally from legacy records
   */
  createdOn: iltypes.DateTimelike | null,
  /**
   * expandable
   */
  definiteRedemptionCount?: number,
  discount: CouponDiscount,
  /**
   * probably "null" means unlimited here? as with expiration_date?
   */
  duration_in_months: iltypes.DateTimelike | null,
  expiration_date: {type: "date", value: iltypes.DateTimelike} | {type: "unlimited"},
  /**
   * expandable
   * Does the user who made the request for this coupon have delete permission for this coupon?
   * This is not the same as "can this coupon be deleted" but rather is "if this coupon can be deleted,
   * is this user permissioned to do so?"
   */
  hasDeletePermission?: boolean
  /**
   * expandable
   */
  pendingRedemptionCount?: number,
  /**
   * expandable
   * list of adhoc subset of invoice
   * the following should always hold:
   * definiteRedemptionCount + pendingRedemptionCount === shallowInvoiceDetail.flatMap(v => v.invoiceLineItems).length
   */
  shallowInvoiceDetail?: ShallowInvoiceDetail[]
}

function mungeCoupon(v: any) : Coupon {
  return {
    couponID: v.couponID,
    couponCode: v.couponCode,
    appliesTo_wildcard: !!v.appliesTo_wildcard,
    appliesTo_compSeason_competitionRegistration: !!v.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: !!v.appliesTo_compSeason_tournamentTeamRegistration,
    clientID: v.clientID,
    couponsRedeemed: v.couponsRedeemed,
    couponChildLinks: v.couponChildLinks ?? undefined,
    couponEventLinks: v.couponEventLinks ?? undefined,
    couponCompetitionSeasonLinks: v.couponCompetitionSeasonLinks ?? undefined,
    couponUserLinks: v.couponUserLinks ?? undefined,
    couponQuantity: v.couponQuantity === "" ? {type: "unlimited"} : {type: "int", value: parseIntOr(v.couponQuantity, -1)},
    createdBy: v.createdBy || null,
    createdOn: v.createdOn || null,
    definiteRedemptionCount: v.definiteRedemptionCount ?? undefined,
    discount: mungeDiscount(),
    duration_in_months: v.duration_in_months || null,
    expiration_date: v.expiration_date === "" ? {type: "unlimited"} : {type: "date", value: v.expiration_date},
    hasDeletePermission: !!v.hasDeletePermission,
    pendingRedemptionCount: v.pendingRedemptionCount ?? undefined,
    shallowInvoiceDetail: v.shallowInvoiceDetail ?? undefined,
  }

  function mungeDiscount() : CouponDiscount {
    return v.discount
      ? {type: "absolute", value: parseFloatOrFail(v.discount)}
      : {type: "percentage", value: parseFloatOrFail(v.percentage)}

  }
}

interface ShallowInvoiceDetail {
  /**
   * invoice id
   */
  instanceID: number,
  lastStatus: FIXME_MoveToIltypesOrSimilar_LastStatus_t,
  /**
   * this could probably be inferred from the `lastStatus` field,
   * but the api calculates it so we don't drift
   */
  redemptionStatus: "redeemed" | "pending-redemption"
  /**
   * A line item contains one entity
   */
  invoiceLineItems: [
    {
      lineItemID: number
      /**
       * cost in dollars prior to any discounts
       */
      amount: number,
      /**
       * the discount applied, if any (and "if any" should be true, because this is a line item, with an associated coupon application)
       */
      discount: number,
    }
    & (
        {
          entity_type: "qCompetitionRegistration",
          competitionRegistration: {
            competitionRegistrationID: number,
            competitionUID: iltypes.Guid,
            seasonUID: iltypes.Guid,
            childID: iltypes.Guid,
            divID: iltypes.Guid,
            childDisplayName: string,
            competitionDisplayName: string,
            divisionDisplayName: string,
            seasonDisplayName: string
          }
        } |
        {
          entity_type: "qEventSignup"
          eventSignup: {
            eventID: iltypes.Guid,
            eventSignupID: iltypes.Guid,
            eventName: string,
          } & (
            | {entityType: 'user', userID: iltypes.Guid, userDisplayName: string}
            | {entityType: 'child', childID: iltypes.Guid, childDisplayName: string}
          )
        } | {
          entity_type: "qTournamentTeam_teamReg",
          tournamentTeamRegistration: {
            tournamentTeamID: iltypes.Integerlike,
            seasonName: string,
            competitionName: string,
            submitterName: string,
          }
        }
      )
  ]
}

type CouponDiscount<Value_t = number> =
  | {type: "absolute", /** non-negative real */ value: Value_t}
  | {type: "percentage", /** real, in range [0,100] */ value: Value_t}

export interface CreateCouponArgs {
  appliesTo_wildcard: boolean,
  appliesTo_compSeason_competitionRegistration: boolean,
  appliesTo_compSeason_tournamentTeamRegistration: boolean,
  couponCode: string,
  /**
   * where empty string means "unlimited"
   */
  couponQuantity: iltypes.Integerlike<number> | "",
  /**
   * where empty string means "no expiration date"
   */
  expiration_date: iltypes.DateTimelike | "",
  entityTargets: {
    events: iltypes.Guid[]
    competitionSeasons: iltypes.Guid[] | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}[],
    users: iltypes.Guid[],
    children: iltypes.Guid[]
  },
  discount: CouponDiscount<iltypes.Numeric>
}

interface WireFormatCreateCouponArgs {
  appliesTo_wildcard: boolean,
  appliesTo_compSeason_competitionRegistration: boolean,
  appliesTo_compSeason_tournamentTeamRegistration: boolean,
  couponCode: string,
  couponQuantity: iltypes.Integerlike<number> | "",
  expiration_date: iltypes.DateTimelike | "",
  entityTargets: {
    events: iltypes.Guid[]
    competitionSeasons: iltypes.Guid[] | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}[],
    users: iltypes.Guid[],
    children: iltypes.Guid[]
  },
  percentage: undefined | iltypes.Numeric,
  discount: undefined | iltypes.Numeric,
}

export async function createCoupon(axios: AxiosInstance, args: CreateCouponArgs) : Promise<Coupon> {
  const submittable : WireFormatCreateCouponArgs = {
    appliesTo_wildcard: args.appliesTo_wildcard,
    appliesTo_compSeason_competitionRegistration: args.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: args.appliesTo_compSeason_tournamentTeamRegistration,
    couponCode: args.couponCode,
    couponQuantity: args.couponQuantity,
    expiration_date: args.expiration_date,
    entityTargets: args.entityTargets,
    percentage: args.discount.type === "percentage" ? args.discount.value : undefined,
    discount: args.discount.type === "absolute" ? args.discount.value : undefined,
  }
  const response = await axios.post(`v1/coupon`, submittable);
  return mungeCoupon(response.data.data);
}

export type CouponExpandables =
  | "couponEventLinks"
  | "couponCompetitionSeasonLinks"
  | "couponUserLinks"
  | "couponChildLinks"
  | "definiteRedemptionCount"
  | "pendingRedemptionCount"
  | "shallowInvoiceDetail"
  | "hasDeletePermission" // expensive on bulk gets

export async function listCoupons(
  axios: AxiosInstance,
  args?: {
    couponCode?: string,
    eventID?: iltypes.Guid,
    competitionSeason?: {
      type: "competitionUID+seasonUID",
      competitionUID: iltypes.Guid,
      seasonUID: iltypes.Guid,
    },
    userID?: iltypes.Guid,
    childID?: iltypes.Guid,
    expiresOnOrAfter?: iltypes.Datelike,
    includeNeverExpires?: boolean,
    expand?: CouponExpandables[]
}) : Promise<Coupon[]> {
  const params : Record<string, string | boolean | number | undefined> = {
    couponCode: args?.couponCode,
    eventID: args?.eventID,
    userID: args?.userID,
    childID: args?.childID,
    expiresOnOrAfter: args?.expiresOnOrAfter,
    includeNeverExpires: args?.includeNeverExpires
  }
  if ( args?.competitionSeason?.type === "competitionUID+seasonUID" ) {
    params.competitionUID = args.competitionSeason.competitionUID;
    params.seasonUID = args.competitionSeason.seasonUID;
  }
  if (args?.expand?.length) {
    params.expand = args.expand.join(",");
  }
  const response = await axios.get(`v1/coupon/list`, {params});
  return response.data.data.map(mungeCoupon);
}

export async function getCoupon(axios: AxiosInstance, args: {couponID: iltypes.Guid, expand?: CouponExpandables[]}) : Promise<Coupon> {
  const params : Record<string, string> = {}
  if (args?.expand?.length) {
    params.expand = args.expand.join(",");
  }
  const response = await axios.get(`v1/coupon/${args.couponID}`, {params});
  return mungeCoupon(response.data.data);
}

export interface CouponEntityLinkageCandidate_CompetitionSeason {
  competitionSeasonUID: iltypes.Guid,
  seasonUID: iltypes.Guid,
  seasonID: number,
  seasonName: string,
  competitionUID: iltypes.Guid,
  competition: string,
  competitionID: number,
  mutableLinkage: boolean,
}

export interface CouponEntityLinkageCandidate_Event {
  eventID: iltypes.Guid,
  eventName: string,
  mutableLinkage: boolean
}

export interface CouponEntityLinkageCandidates {
  events: CouponEntityLinkageCandidate_Event[],
  competitionSeasons: CouponEntityLinkageCandidate_CompetitionSeason[]
}

export async function getCouponEntityLinkageCandidates(axios: AxiosInstance, args?: {couponID?: iltypes.Guid}) : Promise<CouponEntityLinkageCandidates> {
  const params : Record<string, string> = {}
  if (args?.couponID) {
    params.couponID = args.couponID;
  }
  const response = await axios.get(`v1/coupon/linkageCandidates`, {params});
  return response.data.data;
}

export async function redeemCoupon(axios: AxiosInstance, args: {invoiceID: iltypes.Integerlike, couponCode: iltypes.Guid}) : Promise<void> {
  await axios.post(`v1/invoice/${args.invoiceID}/coupon/${args.couponCode}`);
}

export type UpdateCouponArgs = CheckedOmit<CreateCouponArgs, "appliesTo_wildcard"> & {couponID: iltypes.Guid}
type WireFormatUpdateCouponArgs = CheckedOmit<WireFormatCreateCouponArgs, "appliesTo_wildcard">

export async function updateCoupon(axios: AxiosInstance, args: UpdateCouponArgs) : Promise<void> {
  const submittable : WireFormatUpdateCouponArgs = {
    couponCode: args.couponCode,
    couponQuantity: args.couponQuantity,
    appliesTo_compSeason_competitionRegistration: args.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: args.appliesTo_compSeason_tournamentTeamRegistration,
    discount: args.discount.type === "absolute" ? args.discount.value : undefined,
    expiration_date: args.expiration_date,
    entityTargets: args.entityTargets,
    percentage: args.discount.type === "percentage" ? args.discount.value : undefined,
  }
  await axios.post(`v1/coupon/${args.couponID}`, submittable);
}

export async function findLinkedEvents(axios: AxiosInstance, args: {search: string}) : Promise<{eventID: iltypes.Guid, eventName: string}[]> {
  const response = await axios.get(`v1/coupon/linkedEvents`, {params: {search: args.search}});
  return response.data.data;
}

export async function deleteCoupon(axios: AxiosInstance, args: {couponID: iltypes.Guid}) : Promise<void> {
  await axios.delete(`v1/coupon/${args.couponID}`);
}
