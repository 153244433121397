<template lang="pug">
FormKit(type='form', v-model='roleForm', :actions='false')
  FormKitSchema(:schema='schema', :library='library', :data='roleForm')
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { markRaw, onMounted, onBeforeUnmount, ref, watch, computed } from 'vue'
import { Ref, PropType, defineComponent } from 'vue'
import C_Division from 'src/components/VolunteerRegistration/Divisions.vue'

import { handleGUID } from 'src/components/VolunteerRegistration/SelectRolesUtils'
import { VolunteerRoleSelection } from 'src/components/VolunteerRegistration/SelectRolesUtils'
import { copyViaJsonRoundTrip } from 'src/helpers/utils'
import * as ilapi from 'src/composables/InleagueApiV1'

import type {
  ChildDivisionsForUserSeason,
  VolunteerCode,
  VolunteerDetails,
  VolunteerPref,
  Division,
  WithDefinite,
} from 'src/interfaces/InleagueApiV1'
import type { Season } from 'src/interfaces/InleagueApiV1'
import { parseIntOr } from "src/helpers/utils"
import dayjs from 'dayjs'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    seasonUID: { type: String, required: true },
    volunteerID: { type: String, required: true },
    volunteerDetails: {
      type: Object as PropType<VolunteerDetails>,
      required: true,
    },
    inferIsUsersFirstRoleSelectionForSeason: { type: Boolean, default: true },
  },
  emits: {
    update: (_: VolunteerRoleSelection) => true
  },
  setup(props, {emit}) {
    const selectionSupport = computed(() => {
      const maybeUserDob = (() => {
        const v = dayjs(props.volunteerDetails.dob);
        return v.isValid() ? v : null;
      })();

      // check to see if the user meets some age requirement
      // if we didn't get a good date parse (why?), fallback to default-allow
      const meetsMinAgeRequirementForCoach = maybeUserDob
        ? maybeUserDob.isBefore(dayjs().subtract(18, 'year'))
        : true;

      return {
        allowHeadCoach : meetsMinAgeRequirementForCoach,
        allowAsstCoach: meetsMinAgeRequirementForCoach,
        /**
         * there currently no rules prohibiting referee selection
         */
        allowReferee: true,
        /**
         * there currently no rules prohibiting flex selection
         */
        allowFlex: true,
        offerDivisionOptionsForRefs: Client.value.instanceConfig.refdivisions
      }
    });

    const roleForm = ref(VolunteerRoleSelection())
    const roleFormInitialized = ref(false)
    const router = useRouter();

    const library = markRaw({
      DivList: C_Division,
    })
    const schema = ref([
      {
        label: 'Coach Positions',
        $formkit: 'group',
        children: [],
        name: 'coach',
      },
      {
        label: 'Referee Positions',
        $formkit: 'group',
        children: [],
        name: 'referee',
      },
      {
        label: 'Additional Positions',
        $formkit: 'group',
        children: [],
        name: 'flex',
      },
      {
        label: 'Add coach, referee, or other preferences or comments here:',
        $formkit: 'textarea',
        name: 'volunteerComments',
        validation: 'length:0,500',
      },
    ]) as Ref<any>

    /**
     * A volunteer code may or may not have a capacity limit.
     * If there is no limit, that's all we need to know. If there *is* a limit, we'd like to know what that limit is,
     * and if we are at (perhaps beyond, but that shouldn't happen) capacity.
     */
    type MaxAllowedVolunteersDetail =
      | {hasLimit: false}
      | {hasLimit: true, limit: number, isAtCapacity: boolean}

    function getMaxAllowedVolunteersDetail(volcode: WithDefinite<VolunteerCode, "countForSeason">) : MaxAllowedVolunteersDetail {
      const maxAllowedVolunteers = parseIntOr(volcode.maxCount, null);
      if (maxAllowedVolunteers === null) {
        return {hasLimit: false}
      }
      else {
        const isAtCapacity = volcode.countForSeason >= maxAllowedVolunteers;
        return {
          hasLimit: true,
          limit: maxAllowedVolunteers,
          isAtCapacity
        }
      }
    }

    const createSchema = (roles: WithDefinite<VolunteerCode, "countForSeason">[], existingSelectionsThisUser: VolunteerRoleSelection) => {
      for (let i = 0; i < roles.length; i++) {
        const maxAllowedVolunteersDetail = getMaxAllowedVolunteersDetail(roles[i]);
        const roleSchema = {
          $formkit: 'checkbox',
          label: roles[i].codeName,
          subLabel: roles[i].codeDesc,
          name: handleGUID(roles[i].codeID, true),
          "data-test": `codeID=${roles[i].codeID}`,
        }

        if (roles[i].codeField === 'VolunteerCoach') {
          if (!selectionSupport.value.allowHeadCoach) {
            continue;
          }
          roleSchema.name = 'headCoach'
          schema.value[0].children.unshift({
            $cmp: 'DivList',
            name: `headCoach`,
            props: {
              groupName: `divCoach`,
              divisionToChildUiNameMap,
              availableDivisions,
              season
            },
            if: `$coach.headCoach`,
          })
          schema.value[0].children.unshift(roleSchema)
        } else if (roles[i].codeField === 'VolunteerACoach') {
          if (!selectionSupport.value.allowAsstCoach) {
            continue;
          }
          roleSchema.name = 'asstCoach'
          schema.value[0].children.push(roleSchema)
          schema.value[0].children.push({
            $cmp: 'DivList',
            name: `asstCoach`,
            props: {
              groupName: `divAsstCoach`,
              divisionToChildUiNameMap,
              availableDivisions,
              season
            },
            if: `$coach.asstCoach`,
          })
        } else if (roles[i].codeField === 'VolunteerRef') {
          if (!selectionSupport.value.allowReferee) {
            continue;
          }
          roleSchema.name = 'ref'
          schema.value[1].children.push(roleSchema)
          if (selectionSupport.value.offerDivisionOptionsForRefs) {
            schema.value[1].children.push({
              $cmp: 'DivList',
              name: `ref`,
              props: {
                groupName: `divRef`,
                divisionToChildUiNameMap,
                availableDivisions,
                season
              },
              if: `$referee.ref`, // only when the root ref checkbox is checked
            })
          }
          else {
            // empty div, just helps tests see we really got "no ref divs"
            schema.value[1].children.push({
              $el: "div",
              attrs: {
                "data-test": "noRefDivs"
              },
              if: `$referee.ref`, // only when the root ref checkbox is checked
            })
          }
        } else {
          if (!selectionSupport.value.allowFlex) {
            continue;
          }

          //
          // This is not good. Manually construct some DOM tree.
          // Also note that binding directives to elements using FormKitSchema is not supported (as per chat with support team on discord)
          // so it's not clear how we'd get tooltips working here (at least currently we rely on the v-tooltip directive).
          // There is a kludgy css workaround for force-discarding the "defaulted" margin bottom of the FormKit checkbox element,
          // which would normally be "$reset" on the attribute (prop?, when does it put what where?) "outer-class", but that
          // doesn't work here because "$reset" destroys stylings required to make checkbox checks look good ($reset
          // cuts short the left leg of the checkmark).
          //
          const formKitDomNode = {
            // this is the checkbox input
            $cmp: 'FormKit',
            type: "checkbox",
            props: {
              type: "checkbox",
              "outer-class": "FORMKIT-WORKAROUND-SELECT-ROLES-IMPL-ZERO-MARGIN-BOTTOM",
              label: roles[i].codeName,
              name: handleGUID(roles[i].codeID, true),
              "data-test": `codeID=${roles[i].codeID}`,
            }
          };
          const subLabel = {
            // this is the "help" label underneath the checkbox
            $el: "div",
            attrs: {
              // we default to gray-700 but switch to gray-400 if the element is determined to be disabled.
              class: {"mb-4": true, "mt-1": true, "ml-1": true, "text-xs": true, "text-gray-700": true, "text-gray-400": false},
            },
            children: [
              roles[i].codeDesc
            ]
          };

          // actual DOM element to place into schema
          const manuallyConstructedDomTree = {
            $el: "div",
            children: [
              formKitDomNode,
              subLabel,
            ]
          };

          if (maxAllowedVolunteersDetail.hasLimit && maxAllowedVolunteersDetail.isAtCapacity) {
            // it's not clear why existing code wants to strip hyphens out of GUIDs but it does and we need to account for that
            const GUID_CODEID_BUT_WITHOUT_HYPHENS = handleGUID(roles[i].codeID, /*removeDashes*/ true);
            const alreadySignedUp = !!existingSelectionsThisUser.flex[GUID_CODEID_BUT_WITHOUT_HYPHENS];

            if (alreadySignedUp) {
              // This code is at capacity, but they're already signed up for it, so we don't want to disable it, otherwise they wouldn't be able to un-signup for it.
              // If they un-signup for it, presumably the "atCapacity" state changes to false, so it will remain enabled ("oops I unsigned up for that, let me click it again")

              // no-op
            }
            else {
              (formKitDomNode.props as any).disabled = true;
              subLabel.attrs.class['text-gray-400'] = true;
              subLabel.attrs.class['text-gray-700'] = false;
              formKitDomNode.props.label = formKitDomNode.props.label + " (at capacity)"
            }
          }

          schema.value[2].children.push(manuallyConstructedDomTree)
        }
      }
    }

    type VolunteerPrefSubset = Pick<VolunteerPref, 'code' | 'divID' | 'codeID'>

    const populateFormWithUsersPreferences = (
      volunteerPrefs: VolunteerPrefSubset[],
      volunteerComments: string
    ) => {
      const userPreferences = VolunteerRoleSelection()

      for (let i = 0; i < volunteerPrefs.length; i++) {
        if (volunteerPrefs[i].code === 'Asst. Coach') {
          userPreferences.coach.asstCoach = true
          userPreferences.coach.divAsstCoach ??= []
          userPreferences.coach.divAsstCoach.push(volunteerPrefs[i].divID)
        } else if (volunteerPrefs[i].code === 'Referee') {
          userPreferences.referee.ref = true
          userPreferences.referee.divRef ??= []
          userPreferences.referee.divRef.push(volunteerPrefs[i].divID)
        } else if (volunteerPrefs[i].code === 'Head Coach') {
          userPreferences.coach.headCoach = true
          userPreferences.coach.divCoach ??= []
          userPreferences.coach.divCoach.push(volunteerPrefs[i].divID)
        } else {
          userPreferences.flex[handleGUID(volunteerPrefs[i].codeID, true)] = true
        }
      }
      if (volunteerComments) {
        userPreferences.volunteerComments = volunteerComments
      }

      roleForm.value = userPreferences
    }

    /**
     * We might set watchers on data that formkit dynamically creates/updates.
     * If we watch "a.b.c" and formkit deletes "a.b", we get a bunch of hard errors.
     * Formkit may perform such deletions of "a.b" (e.g. `roleForm.value.coach`) during unmount, where
     * its logic is "oh I'm being unmounted, delete things because I am the form UI and the form state all in one package".
     * If we have watchers still enabled during that process, they will fire against (now possibly) invalid property access paths and crash.
     * So, if such watchers are registered, we need to clear them before we unmount.
     */
    let watcherTeardown : null | (() => void) = null;

    const registerAutoDivisionSelectWatchers = () => {
      const lazyInitRoleSelections = (() => {
        const MAX_DIVISIONS_PER_GROUP = 3;

        const assignDivisionsToTargetArray = async (target: string[]) : Promise<void> => {
          const childDivisionsForUserSeason: ChildDivisionsForUserSeason =
            await Client.getChildDivisionsForUserSeason({
              userID: User.value.userID,
              seasonUID: props.seasonUID,
            })

          for (const childID of Object.keys(childDivisionsForUserSeason.result)) {
            for (const entry of childDivisionsForUserSeason.result[childID]) {
              if (target.length >= MAX_DIVISIONS_PER_GROUP) {
                return;
              }
              else {
                target.push(entry.divID)
              }
            }
          }
        }

        const headCoach = async () => {
          await assignDivisionsToTargetArray((roleForm.value.coach.divCoach ??= []))
        }

        const asstCoach = async () => {
          await assignDivisionsToTargetArray(
            (roleForm.value.coach.divAsstCoach ??= [])
          )
        }

        const ref = async () => {
          if (selectionSupport.value.offerDivisionOptionsForRefs) {
            await assignDivisionsToTargetArray((roleForm.value.referee.divRef ??= []))
          }
          else {
            return;
          }
        }

        return {
          headCoach,
          asstCoach,
          ref,
        }
      })()

      const stop1 = watch(
        () => roleForm.value.coach.headCoach,
        async (newVal_isSelected: boolean, _oldVal_isSelected: boolean) => {
          if (newVal_isSelected) {
            await lazyInitRoleSelections.headCoach()
          }
        }
      )

      const stop2 = watch(
        () => roleForm.value.coach.asstCoach,
        async (newVal_isSelected: boolean, _oldVal_isSelected: boolean) => {
          if (newVal_isSelected) {
            await lazyInitRoleSelections.asstCoach()
          }
        }
      )

      const stop3 = watch(
        () => roleForm.value.referee.ref,
        async (newVal_isSelected: boolean, _oldVal_isSelected: boolean) => {
          if (newVal_isSelected) {
            await lazyInitRoleSelections.ref()
          }
          else {
            roleForm.value.referee.divRef = []
          }
        }
      )

      watcherTeardown = () => {
        stop1();
        stop2();
        stop3();
      }
    }

    watch(
      roleForm,
      () => {
        if (roleFormInitialized.value) {
          if (
            roleForm.value.referee.ref &&
            !roleForm.value.referee.divRef?.length
          ) {
            roleForm.value.referee.divRef = ['']
          }
          if (
            roleForm.value.coach.headCoach &&
            !roleForm.value.coach.divCoach?.length
          ) {
            roleForm.value.coach.divCoach = ['']
          }
          if (
            roleForm.value.coach.asstCoach &&
            !roleForm.value.coach.divAsstCoach?.length
          ) {
            roleForm.value.coach.divAsstCoach = ['']
          }

          emit('update', copyViaJsonRoundTrip(roleForm.value))
        }
      },
      { deep: true }
    )

    const availableDivisions = ref<Division[]>([])
    const season = ref<Season>(/*definitely assigned in onMounted*/void 0 as any)
    const divisionToChildUiNameMap = ref<
      Record</*divID:guid*/ string, /*childUiName*/ string[]>
    >({})

    /**
     * build a map of (divID -> (names of children in that division)[])
     * where presumably the names we have available are for the current user
     * This supports listing the user's childrens' names next to their assigned (or "would-be-assigned-if-registered") divisions, to help
     * the user spot divisions that are probably interesting to them
     */
    function buildDivisionToChildUiNameMap(childDivisionsForUserSeason: ChildDivisionsForUserSeason): Record</*divID:guid*/string, /*uiNames*/string[]> {
      const result: Record<string, string[]> = {}
      for (const childID of Object.keys(childDivisionsForUserSeason.result)) {
        const partialChildInfo = childDivisionsForUserSeason.clientSupport[childID]
        if (!partialChildInfo) {
          // error case, we expected one
          continue
        }
        const childUiName = `${partialChildInfo.firstName} ${partialChildInfo.lastName}`
        for (const entry of childDivisionsForUserSeason.result[childID]) {
          ;(result[entry.divID] ??= []).push(childUiName)
        }
      }
      return result
    }

    onBeforeUnmount(() => {
      if (watcherTeardown) {
        watcherTeardown();
      }
    })

    onMounted(async () => {
      //
      // treat this as the first role selection for some season if both:
      //  - (a) the caller said to try to do so (or caller didn't specify, so we default to "yes, try to do so")
      //  - (b) the user has not "submitted a season preferences for the season"
      //
      //  - where (b) means "no user_seasonal table row exists for this (user, season)"
      //  -- where the above means they haven't walked through the "choose volunteer roles" flow at least once
      //
      const isUsersFirstRoleSelectionForSeason = (() => {
        if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
          if (router.currentRoute.value.query.forceIsFirstRoleSelectionForSeason == "1") {
            return true;
          }
        }
        return props.inferIsUsersFirstRoleSelectionForSeason && !props.volunteerDetails.userSeasonPrefsSubmitted;
      })();

      const volunteerCodes = await ilapi.getVolunteerCodes(
        axiosInstance,
        props.seasonUID
      )

      // sort for desired ui layout
      volunteerCodes.sort((l,r) => l.codeNum < r.codeNum ? -1 : 1);

      availableDivisions.value = await Client.getActiveDivisionsForSeason(
        props.seasonUID
      )

      const childDivisionsForUserSeason = await Client.getChildDivisionsForUserSeason({
        userID: props.volunteerID,
        seasonUID: props.seasonUID,
      });

      season.value = await Client.getSeasonByUidOrFail(props.seasonUID)

      divisionToChildUiNameMap.value = buildDivisionToChildUiNameMap(childDivisionsForUserSeason);

      if (isUsersFirstRoleSelectionForSeason) {
        populateFormWithUsersPreferences([], '')
        registerAutoDivisionSelectWatchers()
      } else {
        populateFormWithUsersPreferences(
          props.volunteerDetails.volunteerPrefs,
          props.volunteerDetails.volunteerComments
        )
      }

      createSchema(volunteerCodes, roleForm.value)

      roleFormInitialized.value = true;
    })
    return {
      roleForm,
      schema,
      library,
    }
  }
})

</script>

<style>
/*
* this should be just an inline "outer-class: '$reset'", but that breaks the checkbox checks sizing
* all we want is no margin.
*
* We namespace manually (using the name of this current component file) because FormKit cannot participate in scoped styles.
*
* There doesn't seem to be a way to force set an inline style using a FormKit schema, otherwise this would be something like `style=...`.
*/
.FORMKIT-WORKAROUND-SELECT-ROLES-IMPL-ZERO-MARGIN-BOTTOM.formkit-outer {
  margin-bottom: 0px !important;
}
</style>
