<template lang="pug">
div(v-if="ready")
  EventForm(
    v-bind="formProps"
    v-on="formEventHandlers"
    :key="formProps.__vueKey"
  )
</template>

<script lang="ts">
import { defineComponent, watch, computed, ref, onMounted, PropType, getCurrentInstance } from "vue"
import { nextOpaqueVueKey, useIziToast, exhaustiveCaseGuard } from "src/helpers/utils";
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";

import C_EventForm from "./EventForm.vue"
import * as M_EventForm from "./EventForm.ilx"
import { useRouter } from "vue-router";

import { propsDef, RouteName, RouteDetail } from "./R_EventEditor.ilx"

import authService, {hasSomeCoachAssignmentTitle} from "src/helpers/authService";
import { UserData } from "src/interfaces/Store/user";
import { EventFormUserType } from "./EventForm.ilx";
import { User } from "src/store/User";

export default defineComponent({
  components: {
    EventForm: C_EventForm
  },
  props: propsDef,
  setup(props) {
    const ready = ref(false);
    const router = useRouter();


    //
    // we need a new __vueKey atomically along with new form props;
    // otherwise, we might get out of sync with changes to formProps and the "onMounted" event calls in EventForm
    //
    const formProps = ref<M_EventForm.Props & {__vueKey: string}>(/* definitely assigned in onMounted */ null as any);
    const iziToast = useIziToast();
    const formEventHandlers = ref<M_EventForm.Emits>({
      doSubmit: async (detail) => {
        // these should be object identical, it's just being passed in/out
        // if it's not the same, then we're out of sync somehow (some kind of route change?)
        if (detail.data !== formProps.value.detail.data) {
          throw "AssertionFailure; object identity precondition doesn't hold";
        }

        try {
          const data = M_EventForm.eventFormToApiShape(detail.data, userType.value);
          switch (detail.type) {
            case "make-new":
              // fallthrough
            case "clone-existing":
              await ilapi.event.createEvent(axiosInstance, {data});
              iziToast.success({message: "Event successfully created."});
              break;
            case "edit-existing": {
              await ilapi.event.updateEvent(axiosInstance, {eventID: detail.eventID, data});
              iziToast.success({message: "Event successfully updated."});
              break;
            }
            default:
              exhaustiveCaseGuard(detail);
          }
          await router.push({name: "event-list"})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
    })

    // todo: put this mapping somewhere so it statys in sync across all the places we need to compute it
    const userType = computed<EventFormUserType>(() => {
      const userData = User.value.userData as UserData;
      if (authService(User.value.roles, "registrar", "eventAdmin")) {
        return "Registrar";
      }
      else if (hasSomeCoachAssignmentTitle(userData.coachAssignmentsMemento, "Head Coach", "Co-Coach")) {
        return "Coach";
      }
      else {
        return "EventContact";
      }
    })

    /**
     * our props are bound to the route; so this is effectively "on route changes, rebuild and remount the form"
     */
    watch(() => props.detail, async () => {
      // route could change this, we effectively need to remount
      formProps.value = await initFormPropsFromRouteProps(props.detail, userType.value);
    }, {deep: true})

    onMounted(async () => {
      formProps.value = await initFormPropsFromRouteProps(props.detail, userType.value);
      ready.value = true;
    })

    return {
      ready,
      formProps,
      formEventHandlers,
    }
  }
})

async function initFormPropsFromRouteProps(route: RouteDetail, userType: EventFormUserType) : Promise<M_EventForm.Props & {__vueKey: string}> {
  switch (route.name) {
    case RouteName.new: {
      return {
        __vueKey: nextOpaqueVueKey(),
        detail: {
          type: "make-new",
          eventFormUserType: userType,
          data: M_EventForm.freshFormData(userType)
        }
      }
    }
    case RouteName.edit: {
      const existingEvent = await ilapi.event.getEvent(axiosInstance, route.eventID);
      return {
        __vueKey: nextOpaqueVueKey(),
        detail: {
          type: "edit-existing",
          eventFormUserType: userType,
          eventID: route.eventID,
          data: M_EventForm.formDataFromExistingEvent(existingEvent)
        }
      }
    }
    case RouteName.clone: {
      const existingEvent = await ilapi.event.getEvent(axiosInstance, route.sourceEventID);
      return {
        __vueKey: nextOpaqueVueKey(),
        detail: {
          type: "clone-existing",
          eventFormUserType: userType,
          sourceEventID: route.sourceEventID,
          data: M_EventForm.formDataFromExistingEvent(existingEvent)
        }
      };
    }
    default:
      exhaustiveCaseGuard(route);
  }
}

</script>