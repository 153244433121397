import { arrayFindIndexOrFail } from "src/helpers/utils";
import { FilterableFormData } from "./QueryData";

/**
 * A UI-centric "query node"
 */
export type UiQNode =
  | Connective
  | Predicate

export enum NodeType {
  /**
   * an "and" or "or", with a list of child nodes
   */
  connective = "connective",
  /**
   * a leaf, containing some filter predicate
   */
  predicate = "predicate"
}

abstract class UiQNode_Base {
  readonly abstract type : NodeType;
  readonly nodeID = UiQNode_Base.nextNodeID();

  /**
   * Only a root node should have a null parent.
   * Mutable because nodes can change parents.
   */
  parent: Connective | null;

  /**
   * null is disallowed as parent type here, intentionally requiring a cast at usesites like `null as any`
   */
  constructor(parent: Connective) {
    this.parent = parent;
  }

  private static nextNodeID_ = 0;
  private static nextNodeID() {
    return UiQNode_Base.nextNodeID_++;
  }

  /**
   * All nodes except root should be able to find themselves within their parent's children list.
   */
  indexOfSelfInParentChildrenList() : number {
    if (this.parent === null) {
      throw Error("cannot be called against root");
    }
    return arrayFindIndexOrFail(this.parent.children, sibling => sibling.nodeID === this.nodeID);
  }

  deleteSelfFromParentChildrenList() : void {
    if (this.parent === null) {
      throw Error("cannot be called against root");
    }
    const idx = this.indexOfSelfInParentChildrenList();
    this.parent.children.splice(idx, 1);
  }
}

export class Connective extends UiQNode_Base {
  readonly type = NodeType.connective;

  which: "and" | "or";
  children: UiQNode[] = [];

  static freshRootNode(which: "and" | "or") : Connective {
    // root node has a null parent
    return new Connective(null as any, which);
  }

  /**
   * `parent` can be null for the root node, but we disallow passing it because the general
   * case is that nodes will not be root.
   */
  constructor(parent: Connective, which: "and" | "or") {
    super(parent)
    this.which = which;
  }

  pushFreshPredicate() : Predicate {
    const p = new Predicate(this)
    this.children.push(p);
    return p;
  }

  /**
   * Pushes a fresh connective into the child list.
   * The generated connective itself has no child nodes.
   */
  pushFreshConnective(which: "and" | "or") : Connective {
    const c = new Connective(this, which)
    this.children.push(c);
    return c;
  }

  /**
   * we are redudant if:
   * - (and a (and b c)) --> redundant and, can become (and a b c)
   * - (and a (or b)     --> redundant (ambiguous...) or, can become (and a b)
   * - (and a (or )      --> redundant or, can become (and a)
   *
   * Typically the UI won't display nodes having children length 0, such nodes
   * exist only very temporarily, while their child list is being populated.
   */
  isRedundant() {
    if (this.parent === null) {
      // a root node is never redundant
      return false;
    }
    return this.which === this.parent?.which || this.children.length <= 1;
  }

  /**
   * merge children into parent's children list, if this node is considered redundant
   */
  mergeWithParentIfRedundant() : void {
    // root node has no parent to merge into
    if (this.parent === null) {
      return;
    }

    if (!this.isRedundant()) {
      return;
    }

    const idx = this.indexOfSelfInParentChildrenList();
    this.children.forEach(node => {node.parent = this.parent});
    this.parent.children.splice(idx, 1, ...this.children);
  }
}

export class Predicate extends UiQNode_Base {
  readonly type = NodeType.predicate;

  /**
   * override base prop, definitely non-null here
   */
  declare parent: Connective;

  /**
   * null if node exists but no schema has been selected yet
   * We might want to be generic over the datatype here but would also need to consider the zero value (null, or whatever)
   */
  data: null | FilterableFormData

  constructor(parent: Connective) {
    super(parent);
    this.data = null;
  }

  /**
   * Replace this predicate with a subclause Connective "C" where C
   * has 2 child predicates P0 and P1, where P0 is "this" and P1 is a fresh predicate.
   */
  replaceWithSubClause(which: "and" | "or") {
    // do this first, prior to re-parenting
    const idx = this.indexOfSelfInParentChildrenList();

    const freshConnective = new Connective(this.parent, which);
    const freshPredicate = new Predicate(freshConnective);

    // push both `this` and a freshPredicate, because it doesn't make too much sense
    // to create a subclause that has only 1 initial member (e.g. "X && <nothing>")
    freshConnective.children.push(this, freshPredicate);
    this.parent.children.splice(idx, 1, freshConnective);

    this.parent = freshConnective;
  }
}
