import type { ComponentInternalInstance, ComponentOptions, ComponentPublicInstance, ConcreteComponent, onErrorCaptured } from "vue";
import axios from "axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { RouterHistoryTracker } from "src/store/EventuallyPinia.RouterHistoryTracker";

/**
 * https://github.com/vuejs/core/blob/620327d527593c6263a21500baddbae1ebc30db8/packages/runtime-core/src/component.ts#L984
 *
 * Doesn't seem this is exported, but there isn't any other obvious way to get component name info
 *
 * @return component name or "??" if we couldn't find it
 */
function maybeGetComponentName(instance: ComponentInternalInstance, Component: ConcreteComponent) : string {
  return formatComponentName(instance, Component);

  function isFunction(v: any) : v is Function {
    return typeof v === "function";
  }

  function getComponentName(
    Component: ConcreteComponent,
    includeInferred = true
  ): string | false | undefined {
    return isFunction(Component)
      ? Component.displayName || Component.name
      : Component.name || (includeInferred && Component.__name)
  }


  function formatComponentName(
    instance: ComponentInternalInstance | null,
    Component: ConcreteComponent,
    isRoot = false
  ): string {
    let name = getComponentName(Component)
    if (!name && Component.__file) {
      const match = Component.__file.match(/([^/\\]+)\.\w+$/)
      if (match) {
        name = match[1]
      }
    }

    if (!name && instance && instance.parent) {
      // try to infer the name based on reverse resolution
      const inferFromRegistry = (registry: Record<string, any> | undefined) => {
        for (const key in registry) {
          if (registry[key] === Component) {
            return key
          }
        }
      }
      name =
        inferFromRegistry(
          (instance as any).components ||
            (instance.parent.type as ComponentOptions).components
        ) || inferFromRegistry(instance.appContext.components)
    }

    return name
      /* internal stuff that is available at runtime but isn't in the type signatures, only for root route-entry components? */
      || (instance?.vnode.component as any)?.ctx?.wrapper?.name
      /* explicit `name` attribute of component */
      || instance?.type.name
      /* inferred from single file component, maybe not available in prod? */
      || instance?.type.__name
      /* couldn't find it */
      || "??";
  }
}

type VueErrorCallback = Parameters<typeof onErrorCaptured>[0]

function buildVueTagStack(instance: ComponentPublicInstance) : string[] {
  const vueTagStack : string[] = [];

  let workingNode : (typeof instance.$) | null = instance.$;

  // probably unnecessary, assuming that the vue node tree has no cycles, and that we'll always reach root
  const MAX_ITERS = 100;

  for (let i = 0; i < MAX_ITERS; i++) {
    const componentName = maybeGetComponentName(workingNode, workingNode.type);

    vueTagStack.push(componentName);

    if (!workingNode.parent || workingNode.parent === workingNode) {
      // at app root (or climbing from here would go infinite?)
      // stop climbing
      break;
    }
    else {
      workingNode = workingNode.parent;
    }
  }

  return vueTagStack;
}

function miscAppErrorDetail() {
  // utils candidate
  // https://stackoverflow.com/a/45964244/21268509
  const __browser_or_node__window = typeof globalThis === "object"
    ? globalThis
    : typeof window === "object"
        ? window
        : typeof global === "object"
            ? global
            : null;

  return {
    commit_hash: process.env.CURRENT_COMMIT_HASH,
    // this is "vue" related, but it's a global that we manage, and is always available even
    // outside of vue contexts
    vueRouterHistory: RouterHistoryTracker.getHistory().map(v => v.fullPath),
    // sometimes helpful
    "document.referrer": __browser_or_node__window?.document?.referrer ?? "<<no window.document.referrer>>",
    url: __browser_or_node__window?.location?.href,
  }
}

function vueAppErrorDetail(instance: ComponentPublicInstance) {
  return {
    vueContext: buildVueTagStack(instance),
  }
}

export const tryGetVueErrorCallbackLogger = (writer: LogWriter) : VueErrorCallback => (error, instance, _info) => {
  if (!instance || AxiosErrorWrapper.isAxiosErrorNoise(error)) {
    return;
  }

  const breadcrumb = instance.$route?.fullPath || "<unknown-route>";

  //
  // We could test for `!error.response` but that would also improperly identify local timeouts?
  // And we probably do want to log timeouts -- currently the global timeout is 10 minutes, and anything stuck
  // for 10 minutes is probably a bug we can fix.
  // related: https://github.com/axios/axios/issues/383
  //
  const isAxiosError_networkLayerFailure = axios.isAxiosError(error) && error.message === "Network Error";
  const isAxiosError_hasSome400StatusCode = axios.isAxiosError(error) && (Math.floor((error.response?.status ?? 0) / 100) === 4);

  if (isAxiosError_networkLayerFailure) {
    // some OS level network failure? wifi cut out?
    // this is different than a timeout, where we'd see a different stack trace
    // Anyway, there's nothing we can do with this.
    return false;
  }

  const gatheredErrorDetail = {
    ...miscAppErrorDetail(),
    ...vueAppErrorDetail(instance),
    // log this for a while, if we become confident that this does detect "spurious" network failures
    // we can just not log when this is true.
    transitional_wasUndiagnosableNetworkLayerFailure: isAxiosError_networkLayerFailure,
    error
  }

  // don't await it, return immediately, this can run in the background
  void getLogger(writer).log(
    /*see notes on errorlevel type for why we only use "warning" for now*/
    isAxiosError_hasSome400StatusCode ? "warning" : "warning", breadcrumb, gatheredErrorDetail
  );

  // stop propagation of this error (for vue error handling contexts)
  return false;
}

/**
 * Intended use is via caller passing the result of getCurrentInstance(),
 * which might yield a ComponentInternalInstance, and might yield null.
 */
function maybeWithGatheredVueAppDetail(instance: ComponentInternalInstance | null, error: any) {
  return instance?.proxy
    ? {...miscAppErrorDetail(), ...vueAppErrorDetail(instance.proxy), error}
    : {...miscAppErrorDetail(), error};
}

export function maybeLog(logWriter: Logger, level: LogLevel, breadcrumb: string, error: any, vueInstance?: ComponentInternalInstance | null) : void {
  if (AxiosErrorWrapper.isAxiosErrorNoise(error)) {
    return;
  }
  void logWriter.log(level, breadcrumb, maybeWithGatheredVueAppDetail(vueInstance || null, error));
}

export function getLogger(writer: LogWriter) {
  // try to turn some object into json,
  // which is harder than it sounds, for example for Error objects:
  //
  // JSON.stringify(new Error("some error message")) === "{}"
  // Object.getOwnPropertyNames(new Error("...")).includes("stack") === false, but `new Error("").stack` is a thing
  //
  // Also we want to not fail in case of circularities
  function jsonifyOne(error: any) : any {
    try {
      const seen = new Set<any>();
      return recurseWorker(error);
      function recurseWorker(v: any) : any {
        if (v instanceof AxiosErrorWrapper) {
          return v.toJSON();
        }
        if (Array.isArray(v)) {
          if (seen.has(v)) {
            return "<<circular>>"
          }
          else {
            seen.add(v);
          }
          return v.map(recurseWorker);
        }
        else if (typeof v === "object" && v !== null) {
          if (seen.has(v)) {
            return "<<circular>>"
          }
          else {
            seen.add(v);
          }

          const result : any = {};
          // this "ownPropertyNames + keys" is just "we don't understand why things get serialized the way they do on different error objects"
          // Axios seems to configure a custom toJSON function for its errors, which don't serialize what we want? And "default js Error objects"
          // also serialize weirdly.
          const keys = [...new Set([...Object.getOwnPropertyNames(v), ...Object.keys(v)])]; // make sure to dedupe them, because there will be overlap ... is keys always a subset of ownPropertyNames?
          for (const key of keys) {
            result[key] = recurseWorker(v[key]);
          }
          if (v instanceof Error) {
            // is there no way to automagically pull this out, we have to "just know" to do it?
            result.stack = recurseWorker(v.stack);
          }
          return result;
        }
        else if (v === undefined) {
          return "<<undefined>>"
        }
        else if (v === null || typeof v === "string" || typeof v === "number" || typeof v === "boolean") {
          return v;
        }
        else {
          try {
            return `(warn: raw JSON.stringify call): ${JSON.stringify(v)}`
          }
          catch {
            // not great
            return `(warn: raw JSON.stringify call): failed, typeof v == "${typeof v}"`;
          }
        }
      }
    }
    catch (err) {
      // some unhandled error
      if (err === "circular") {
        return err;
      }
      else {
        return undefined;
      }
    }
  }

  async function logOne(level: LogLevel, breadcrumb: string, error: any) : Promise<void> {
    const detail = jsonifyOne(error);
    await writer.log(level, breadcrumb, detail, error);
  }

  return {
    log: logOne
  };
}

type Logger = ReturnType<typeof getLogger>

/**
 * We only support "warning" right now, because of the overly-broad grouping which produces a lot of noise in the logs.
 *
 * We need to refine our grouping approach, generally we use "breadcrumb" which is just "current route",
 * but then when it gets to sentry that doesn't allow use to use stacktrace info. For the meantime we log everything as warning.
 *
 * Maybe we should transition to using sentry clientside loggers that are more aware of js semantics? Right now we push
 * stuff over the wire and use the "backend" logger as-if it were our frontend logger.
 */
export type LogLevel = "warning"

export interface LogWriter {
  log: (level: LogLevel, breadcrumb: string, jsonifiedError: any, rawError: any) => void | Promise<void>
}

export const LoggerConfig = {
  liveLogs: () => process.env.LOGGER === "sentry",
}

export const TEST_EXPORTS = {
  buildVueTagStack
}
