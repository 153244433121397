import { AxiosInstance } from "axios";
import { isAxiosInleagueApiError } from "src/composables/InleagueApiV1";
import { GameForGameSchedulerView } from "src/composables/InleagueApiV1.GameScheduler";
import { rpc } from "src/composables/Rpc";
import { CoachTitle, Competition, DateTimelike, Division, Guid, int, Integerlike, Numbool, Season } from "src/interfaces/InleagueApiV1"

// TODO: name refactor "BracketForBracketBuilder"
export interface Bracket {
  bracketID: Integerlike,
  bracketRounds: BracketRound[],
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  clientID: Guid,
  bracketName: string,
}

export interface BracketRound {
  bracketID: Integerlike,
  bracketRoundNum: Integerlike,
  bracketRoundSlots: BracketRoundSlot[]
  bracketRoundName: string,
}

export interface BracketRoundSlot {
  bracketRoundSlotID: Integerlike,
  bracketID: Integerlike,
  bracketRoundNum: Integerlike,
  intraBracketRoundOrdinal: Integerlike,
  // type "team" seems to be becoming deprecated ... we were using it to represent "place holder" slots, where the team auto-advances forward,
  // but now we just write the "placeholder" team directly into the appropriate game
  type: "game" | "team"
  gameID: "" | Guid, // always nullish if type is not game, otherwise may be null ("don't know yet") or non-null
  game: GameForBracketBuilderView | null,  // if gameID was non-null at time of last load, this will be the game
  // if type=team becomes unused, the teamID field can be deleted
  teamID: "" | Guid, // always nullish if type is not team, otherwise may be null ("don't know yet") or non-null
  sourceLeft: BracketRoundSlotTeamSource | null,
  sourceRight: BracketRoundSlotTeamSource | null,
  disabled: Numbool,
  // seeds we might want to store here instead of on games (where it is homeSeed, visitorSeed)
  // it sort of made sense initially to store it on game but we might move it ...
  // this is here for exploratory dev purposes and is not part of any api response
  __dev__seeds?: string
}

export interface BracketRoundSlotTeamSource {
  source_bracketRoundSlotID: Integerlike,
  target_bracketRoundSlotID: Integerlike,
  sourceDir: "left" | "right",
  sourceType: "winner" | "loser",
}

export interface BracketForListing {
  bracketID: int,
  bracketName: string,
  seasonName: string,
  seasonID: Integerlike,
  competition: string,
  competitionID: Integerlike,
  ivision: string,
  divNum: Integerlike,
  gender: string,
  numRounds: Integerlike,
  firstGameStart: "" | DateTimelike,
  createdBy: {
    userID: Guid,
    firstName: string
    lastName: string,
  }
  createdOn: DateTimelike,
}

// These 2 types are incidentally the same.
export type GameForBracketBuilderView = GameForGameSchedulerView

export type CreateBracketArgs = {
  bracketName: string,
  competitionUID: Guid,
  divID: Guid,
  seasonUID: Guid,
  bracketRounds: CreateBracketRoundArgs[]
}

export type CreateBracketRoundArgs = {
  bracketRoundName: string,
  bracketRoundSlots: CreateBracketRoundSlotArgs[]
}

export type CreateBracketRoundSlotArgs = {
  bracketRoundSlotID: Integerlike,
  // type "team" seems to be becoming deprecated ... we were using it to represent "place holder" slots, where the team auto-advances forward,
  // but now we just write the "placeholder" team directly into the appropriate game
  type: "game" | "team"
  gameID: "" | Guid, // always nullish if type is not game, otherwise may be null ("don't know yet") or non-null
  // if type=team becomes unused, the teamID field can be deleted
  teamID: "" | Guid, // always nullish if type is not team, otherwise may be null ("don't know yet") or non-null
  sourceLeft: {source_bracketRoundSlotID: Integerlike} | null,
  sourceRight: {source_bracketRoundSlotID: Integerlike} | null,
  disabled: Numbool,
  teams: undefined | {
    home: undefined | {
      teamID: "" | Guid,
      seed: "" | number
    },
    visitor: undefined | {
      teamID: "" | Guid,
      seed: "" | number
    },
  },
  createGame: undefined | {
    fieldUID: Guid,
    gameStart: DateTimelike,
    gameLengthMinutes: number
  }
}

// a property name in some error responses for the createBracket call, representing which bracketRoundSlots contained errors
// We assume at this time that all errors were just scheduling conflicts, so we don't need specific "what was wrong with each one"
// info
export const kErrAffectedBracketRoundSlotIDs = "affectedBracketRoundSlotIDs"
export async function createBracket(ax: AxiosInstance, args: CreateBracketArgs) : Promise<{ok: true, data: Bracket} | {ok: false, data: {affectedBracketRoundIDs: Integerlike[]}}> {
  try {
    const response = await ax.post("v1/brackets/createBracket", args);
    return {ok: true, data: response.data.data}
  }
  catch (err) {
    if (isAxiosInleagueApiError(err)) {
      if (kErrAffectedBracketRoundSlotIDs in err.response.data.data) {
        return {ok: false, data: {affectedBracketRoundIDs: err.response.data.data[kErrAffectedBracketRoundSlotIDs]}}
      }
    }
    throw err
  }
}

export const listGamesForFields = rpc<{competitionUID: Guid, divID: Guid, seasonUID: Guid, fieldUIDs: Guid[]}, GameForBracketBuilderView[]>("get", "v1/brackets/listGamesForFields")
export const updateBracketName = rpc<{bracketID: Integerlike, bracketName: string}, void[]>("post", "v1/brackets/updateBracketName")
export const updateBracketRoundName = rpc<{bracketID: Integerlike, bracketRoundNum: Integerlike, bracketRoundName: string}, void[]>("post", "v1/brackets/updateBracketRoundName")
export const deleteBracket = rpc<{bracketID: Integerlike}, void>("delete", "v1/brackets/deleteBracket")

/**
 * supply zero-or-one of gameID or teamID
 * If supplying a gameID or teamID, the appropriate type tag must also be supplied.
 * Note that it is OK set the type but not supply a gameID or teamID, which means "we know the type but not the associated entity".
 */
export const updateBracketRoundSlot = rpc<{bracketRoundSlotID: Integerlike, type: "game" | "team", gameID?: Guid, teamID?: Guid}, {bracketRoundSlot: BracketRoundSlot, game?: GameForBracketBuilderView | null}>("post", "v1/brackets/updateBracketRoundSlot")

export const getBracketForBracketBuilder = rpc<{bracketID: Integerlike}, {bracket: Bracket}>("get", "v1/brackets/getBracketForBracketBuilder")
export const getBracketsForListing = rpc<{competitionUID: Guid, divID: Guid, seasonUID: Guid}, BracketForListing[]>("get", "v1/brackets/getBracketsForListing")
export const getFlatSeasonCompDivOptions = rpc<void, {seasons: Season[], competitions: Competition[], divisions: Division[]}>("get", "v1/brackets/getFlatSeasonCompDivOptions")
export const getBracketForInfoView = rpc<{bracketID: Integerlike}, {bracket: Bracket}>("get", "v1/brackets/getBracketForInfoView")

export const createGamesForBracketSlots = rpc<{bracketID: Integerlike, fieldUID: Guid, initialDateTime: DateTimelike, gameLengthMinutes: Integerlike, bracketRoundSlotIDs: Integerlike[]}, void>("post", "v1/brackets/createGamesForBracketSlots")

export interface BracketPoolOption {
  pool: {
    poolID: Integerlike,
    poolUID: Guid,
    poolName: string,
  },
  teams: {
    teamID: Guid,
    teamDesignation: string,
    teamName: string,
    coaches: {firstName: string, lastName: string, title: CoachTitle}[],
    seed: number,
  }[]
}

export const getPoolOptionsForBracket = rpc<{bracketID: Integerlike} | {competitionUID: Guid, divID: Guid, seasonUID: Guid}, BracketPoolOption[]>("get", "v1/brackets/getPoolOptionsForBracket")

export const seedFirstRoundGames = rpc<{bracketID: Integerlike, bracketRoundSlots: {bracketRoundSlotID: Integerlike, homeTeamID: "TBD" | Guid, visitorTeamID: "TBD" | Guid}[]}, void>("post", "v1/brackets/seedFirstRoundGames");

export interface BracketRoundSlotTeamOption {
  teamID: Guid,
  teamDesignation: string,
  teamName: string,
  coaches: {firstName: string, lastName: string, title: CoachTitle}[]
}

export const getBracketRoundSlotTeamOptions = rpc<{competitionUID: Guid, divID: Guid, seasonUID: Guid}, BracketRoundSlotTeamOption[]>("get", "v1/brackets/getBracketRoundSlotTeamOptions")
export const findGamesForTentativeBracket = rpc<{
  competitionUID: Guid,
  divID: Guid,
  seasonUID: Guid,
  startDateTime: DateTimelike,
  daysBetweenRounds: Integerlike,
  fieldUIDs: Guid[],
  countsByRound: number[]
}, {gamesByRound: GameForBracketBuilderView[][], proposedGameTimesByRound: {gameStart: DateTimelike, gameLengthMinutes: number, fieldUID: Guid}[][]}>("get", "v1/brackets/findGamesForTentativeBracket")

export const swapTeams = rpc<{game1: {gameID: Guid, which: "home" | "visitor"}, game2: {gameID: Guid, which: "home" | "visitor"}}, void>("post", "v1/brackets/swapTeams");
