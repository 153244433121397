import { exhaustiveCaseGuard } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import * as iltypes from "src/interfaces/InleagueApiV1"

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RouteDetail>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

export const RouteName = {
  new: "coupon-editor.new",
  edit: "coupon-editor.edit",
} as const;

type RouteName = typeof RouteName;

export type RouteDetail =
  | R_New
  | R_Edit

export interface R_New {
  name: RouteName["new"]
}

export interface R_Edit {
  name: RouteName["edit"],
  couponID: iltypes.Guid
}


export function routeDetailToRoutePath(detail: RouteDetail) : RouteLocationRaw {
  switch(detail.name) {
    case RouteName.new:
      return {name: detail.name}
    case RouteName.edit:
      return {name: detail.name, params: {couponID: detail.couponID}};
    default:
      exhaustiveCaseGuard(detail);
  }
}
