<!-- this just resolves anything that might be temporarily undefined or need awaiting before handing off to impl -->
<template lang="pug">
CompetitionsVolunteerImpl(
  v-if="ready"
  v-bind="resolvedProps"
  @volunteer-roles-submit="$emit('volunteer-roles-submit', $event)"
)
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { axiosInstance } from 'src/boot/axios'
import { CompetitionsVolunteerImplProps, StaticVolunteerRequirementsCheck } from './common'
import { RegistrationVolunteerRequirementsFlow } from 'src/composables/registration'
import CompetitionsVolunteerImpl from './competitions-volunteer-impl.vue'
import { vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"

export default defineComponent({
  props: {
    playerID: { required: true, type: String},
    familyID: {required: true, type: String},
    userID: {required: true, type: String},
    seasonUID: { required: true, type: String},
    competitionUIDs: vReqT<Guid[]>(),
  },
  components: {
    CompetitionsVolunteerImpl: CompetitionsVolunteerImpl
  },
  emits: ["volunteer-roles-submit"],
  setup(props, context) {
    const router = useRouter()


    const ready = ref(false);
    const resolvedProps = ref<CompetitionsVolunteerImplProps | undefined>();

    onMounted(async () => {
      const state = await RegistrationVolunteerRequirementsFlow.getFlowState(axiosInstance, props.familyID, props.userID, props.seasonUID, props.competitionUIDs);

      if (!state) {
        // failure case, but really unexpected; maybe a network error, or the user manually typed in a bad url
        // otherwise, getting here is a bug
        await router.push({path: "/"});
        return;
      }

      resolvedProps.value = {
        userID: props.userID,
        seasonUID: props.seasonUID,
        playerID: props.playerID,
        // loop over this?...
        staticVolunteerRequirementsCheck: state.staticVolunteerRequirementsCheck,
        volunteerDetails: state.volunteerDetails
      };

      ready.value = true;
    })

    return {
      ready,
      resolvedProps
    }
  },
})
</script>
