import { type AxiosInstance } from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"

export async function uploadPhoto(axios: AxiosInstance, args: {userID: iltypes.Guid, photo: Blob}) : Promise<void> {
  const form = new FormData();
  form.append("uploadDocument", args.photo);
  await axios.post(
    `v1/volunteer/${args.userID}/photo`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

// as opposed to {K,M}iB
const KB = 1000;
const MB = KB * 1000;

export const uploadPhotoMeta = {
  maxSizeBytes: 5 * MB,
  accept: [".jpg",".png",".gif",".bmp",".tif",".tiff"].join(","),
} as const;

export interface NeedsCoachPhotoInfo {
  userID: iltypes.Guid,
  hasStackRecordKey: boolean,
  isPhotoLocked: boolean,
  hasAtLeastOneCoachAssignmentForCompRequiringPhoto: boolean
}

export async function getWhoNeedsCoachPhotos(axios: AxiosInstance, args: {seasonUID: iltypes.Guid | "ALL", userIDs: iltypes.Guid[]}) : Promise<NeedsCoachPhotoInfo[]> {
  const response = await axios.get(`v1/volunteers/whoNeedsCoachPhotos`, {params: args});
  return response.data.data;
}
