import { defineComponent, ref, computed, onMounted, getCurrentInstance, PropType } from 'vue'
import { useRouter } from 'vue-router'


import { datePickerFormat } from 'src/helpers/formatDate'

import C_EventListing from "./EventListing.vue";
import * as M_EventListing from "./EventListing.ilx";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios';
import { UserData } from 'src/interfaces/Store/user';
import { FormKitValidationRule, useIziToast, UiOption } from 'src/helpers/utils';
import dayjs from 'dayjs';

import C_ConfirmEventDeletionModal from "./ConfirmEventDeletionModal.vue"
import * as M_ConfirmEventDeletionModal from "./ConfirmEventDeletionModal.ilx"
import * as AuthService from 'src/helpers/authService';
import { FormKit } from '@formkit/vue';
import { User } from "src/store/User"

const defaultSearch = () => ({
  endingOnOrAfter: {
    disabled: false,
    date: datePickerFormat(dayjs().subtract(1, "day")),
  },
  targetDivID: "",
  targetVenueID: "",
  hasSomeSignupWithUserOrPlayerName: "",
  justMyEvents: false,
  eventName: {
    value: "",
    validation: [["length", 2]] as FormKitValidationRule[] // 2 or more chars
  }
});

// sfc<->jsx shim
declare function EventListing(props: M_EventListing.Props & M_EventListing.OnEmits) : JSX.Element;
declare function ConfirmEventDeletionModal(props: M_ConfirmEventDeletionModal.Props & M_ConfirmEventDeletionModal.OnEmits) : JSX.Element;

// will persist across user logins/logout for the same browser page load cycle (what's the name for *that*?)
// which is OK because we don't store security sensitive stuff here, it's just some search prefs
// this would probably be a good starter use case for introducing pinia
const moduleGlobal_search = ref(defaultSearch());

// TODO: pull this from the server to keep in sync, or maybe part of shared build file with server.
const MAX_LOOKBACK_YEARS = 2
const oldestAllowedEndsOnOrAfterDate = datePickerFormat(dayjs().subtract(MAX_LOOKBACK_YEARS, "years"))

export default defineComponent({
  components: {
    EventListing: C_EventListing,
    ConfirmEventDeletionModal: C_ConfirmEventDeletionModal,
  },
  setup() {
    const divisionOptions = ref<UiOption[]>([]);
    const venueOptions = ref<UiOption[]>([]);

    const search = ref(moduleGlobal_search);
    const iziToast = useIziToast();

    const router = useRouter()


    const eventListingState = ref<null | M_EventListing.Props>(null);

    const confirmEventDeletionModalController = ref(M_ConfirmEventDeletionModal.DefaultController({
      handlers: {
        confirmDeletion: async (eventIdToDelete) => {
          if (!eventListingState.value) {
            // shouldn't happen
            return;
          }

          const index = eventListingState.value.events.findIndex(v => v.eventID === eventIdToDelete);
          if (index === -1) {
            // weird, couldn't find it
            // nothing we can do
            return;
          }

          try {
            await ilapi.event.deleteEvent(axiosInstance, eventIdToDelete);
            // remove element from local list
            eventListingState.value.events.splice(index, 1);
            iziToast.success({message: "Event deleted."});
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          }

          confirmEventDeletionModalController.value.hide();
        },
        doNotDelete: () => {
          confirmEventDeletionModalController.value.hide();
        }
      }
    }))

    onMounted(async () => {
      divisionOptions.value = await (async () => {
        const divisions = await ilapi.getDivisions(axiosInstance);
        const result : UiOption[] = [{label: "Any", value: ""}];
        for (const v of divisions) {
          result.push({label: v.displayName, value: v.divID})
        }
        return result;
      })();

      venueOptions.value = await (async () => {
        const divisions = await ilapi.event.listEventVenues(axiosInstance);
        const result : UiOption[] = [{label: "Any", value: ""}];
        for (const v of divisions) {
          result.push({label: v.name, value: v.venueID})
        }
        return result;
      })();

      await refreshEventListing();
    });

    let refreshDepth = 0;
    const refreshEventListing = async () => {
      // try to prevent weird bugs from quick successive async refresh requests
      refreshDepth++;
      let maybeAssignable : M_EventListing.Props | null = null;
      try {
        eventListingState.value = null;

        const events = await ilapi.event.findEligibleEventsForUser(
          axiosInstance, {
            userID: (User.value.userData as UserData).userID,
            endingOnOrAfter: search.value.endingOnOrAfter.disabled ? undefined : search.value.endingOnOrAfter.date,
            divID: search.value.targetDivID,
            venueID: search.value.targetVenueID,
            hasSomeSignupWithUserOrPlayerName: search.value.hasSomeSignupWithUserOrPlayerName,
            eventName: search.value.eventName.value
          }
        );

        const currentUserIsCoachForEvent : M_EventListing.Props["currentUserIsCoachForEvent"] = {};

        for (const event of events) {
          if (event.teamID) {
            currentUserIsCoachForEvent[event.eventID] = AuthService.isHeadCoachForTeam(
              (User.value.userData as UserData).coachAssignmentsMemento,
              {
                teamID: event.teamID,
                seasonUID: event.seasonUID
              }
            );
          }
        }

        maybeAssignable = {
          events,
          currentUserIsCoachForEvent
        };
      }
      finally {
        refreshDepth--;
        if (refreshDepth === 0) {
          eventListingState.value = maybeAssignable;
        }
      }
    }

    const resetSearch = () => {
      search.value = defaultSearch();
    }

    const eventListingHandlers : M_EventListing.OnEmits = {
      onRequestSignup: async (eventID) => {
        // this should probably become a router link for nicer link semantics
        await router.push({ name: 'event-signup', params: { eventID: eventID } })
      },
      onDoDelete: async (eventIdToDelete) => {
        const event = eventListingState.value?.events.find(v => v.eventID === eventIdToDelete);
        if (!event) {
          // shouldn't happen
          return;
        }
        confirmEventDeletionModalController.value.show(event);
      }
    }

    return () => (
      <div>
        {
          confirmEventDeletionModalController.value.visible
            ? <ConfirmEventDeletionModal
              event={confirmEventDeletionModalController.value.props!.event}
              onConfirmDeletion={confirmEventDeletionModalController.value.handlers.confirmDeletion}
              onDoNotDelete={confirmEventDeletionModalController.value.handlers.doNotDelete}
            />
            : null
        }
        <h1 class="text-4xl self-end font-medium">
          <font-awesome-icon class="mr-2" icon={["fas", "calendar-day"]}>Events</font-awesome-icon>
        </h1>
        <FormKit type="form" actions={false} onSubmit={() => refreshEventListing()}>
          <div class="grid mt-4 grid-cols-1 lg:grid-cols-2 lg:justify-items-start">
            <div class="w-full">
              {
                search.value.endingOnOrAfter.disabled
                  ? <FormKit key="endingOnOrAfter-disabled" outer-class="$reset w-1/2" wrapper-class="$reset" min={oldestAllowedEndsOnOrAfterDate} type="text" disabled label="Events ending on or after" {...{placeholder:`Max lookback: ${MAX_LOOKBACK_YEARS} years`}} data-test="endingOnOrAfter-disabled" />
                  : <FormKit key="endingOnOrAfter-enabled" outer-class="$reset w-1/2" wrapper-class="$reset" min={oldestAllowedEndsOnOrAfterDate} type="date" label="Events ending on or after" v-model={search.value.endingOnOrAfter.date} data-test="endingOnOrAfter" />
              }
              <div class="mt-1 flex items-center text-sm mb-2">
                <FormKit outer-class="$reset" type="checkbox" v-model={search.value.endingOnOrAfter.disabled} />
                <span>No date restriction</span>
              </div>
            </div>
            <FormKit outer-class="w-1/2" wrapper-class="$reset" type="select" label="Events limited to division" data-test="targetDivID" v-model={search.value.targetDivID} options={divisionOptions.value} />
            <FormKit outer-class="w-1/2" wrapper-class="$reset" type="select" label="Events happening at" v-model={search.value.targetVenueID} options={venueOptions.value} />
            <div class="w-1/2">
              <FormKit outer-class="$reset mb-1 w-full" wrapper-class="$reset" type="text" label="Including user or player" {...{autocomplete: "off"}} v-model={search.value.hasSomeSignupWithUserOrPlayerName} />
              <div class="text-xs">(*lookup by exact first or last name)</div>
            </div>
            <div class="w-1/2">
              <FormKit wrapper-class="$reset" type="text" label="Event name" v-model={search.value.eventName.value} validation={search.value.eventName.validation}/>
            </div>
            <div class="w-1/2">
              {/*no content -- grid cell placeholder*/}
            </div>
            <div class="justify-self-start">
              <t-btn type="submit" data-test="run-search">
                <div>Run search</div>
              </t-btn>
              <t-btn type="button" onClick={() => resetSearch()} data-test="reset-search">
                <div>Clear search</div>
              </t-btn>
            </div>
          </div>
        </FormKit>
        {
          eventListingState.value
            ? (
              <div class="mt-4 shadow-md border">
                <EventListing
                  events={eventListingState.value.events}
                  currentUserIsCoachForEvent={eventListingState.value.currentUserIsCoachForEvent}
                  onDoDelete={eventListingHandlers.onDoDelete}
                  onRequestSignup={eventListingHandlers.onRequestSignup}
                />
              </div>
            )
            : null
        }
      </div>
    )
  },
})
