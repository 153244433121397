/**
 * Performs direct DOM manipulations for global theming purposes.
 */

import type { ClientTheme } from "src/interfaces/Store/client"

const colorCreator = (p: number, col: string): string => {
  const i = parseInt,
    r = Math.round,
    [a, b, c, d] = col.split(','),
    P = p < 0,
    t = P ? 0 : 255 * p,
    Pnum = P ? 1 + p : 1 - p
  return (
    'rgb' +
    (d ? 'a(' : '(') +
    r(i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * Pnum + t) +
    ',' +
    r(i(b) * Pnum + t) +
    ',' +
    r(i(c) * Pnum + t) +
    (d ? ',' + d : ')')
  )
}

const initColorTheme = (color: string): void => {
  const colorShades: string[] = [color]

  for (let i = 0; i < 20; i++) {
    colorShades.push(colorCreator(0.05 * i, color))
  }
  const bodyStyles = document.body.style
  bodyStyles.setProperty('--one', colorShades[18])
  bodyStyles.setProperty('--two', colorShades[15])
  bodyStyles.setProperty('--three', colorShades[12])
  bodyStyles.setProperty('--four', colorShades[10])
  bodyStyles.setProperty('--five', colorShades[9])
  bodyStyles.setProperty('--six', colorShades[7])
  bodyStyles.setProperty('--seven', colorShades[6])
  bodyStyles.setProperty('--eight', colorShades[4])
  bodyStyles.setProperty('--nine', colorShades[1])
}

const initFavicon = (url: string): void => {
  const favicon = document.getElementById('favicon')
  if (favicon) {
    favicon.setAttribute('href', url)
  }
}

const initTitle = (title: string): void => {
  const domTitle = document.getElementById('title')
  if (domTitle) {
    domTitle.innerText = title
  }
}

/**
 * Intent is to be called from Client store, which wraps this to invoke with its current theme.
 * Application code should not call this directly.
 */
export function updateDOMThemingAttrs_(theme: ClientTheme) : void {
  initColorTheme(theme.color)
  initFavicon(theme.favicon)
  initTitle(theme.name || "inLeague")
}
