<template lang="pug">
Modal
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Modal from 'src/components/Navigational/LoginModal.vue'
import { setLoginModalDismissed } from "src/store/Misc"

export default defineComponent({
  name: 'LoginModalPage',
  components: {
    Modal
  },
  setup() {
    onMounted(() => {
      setLoginModalDismissed(false);
    })
    return {}
  }
})
</script>
