<template lang="pug">
TDialog(:close="true" @close="close()" data-test="BirthCertificateUploadModal")
  .text-xl Upload Birth Certificate
  div.divide-y
    div.p-2.text-left
      | For {{ targetPlayerUiName }}
    div
      FormKit(
        type='form',
        @submit='uploadCertificate'
        :actions="false"
      )
        FormKit(
          type='file',
          accept='.pdf,.jpg,.png'
          v-model="filesInput"
          validation='max:1'
          data-test="fileInput"
        )
        FormKit(
          type="submit"
          :label="uploadSuccesfullyCompleted ? '✓OK' : !file ? 'Choose a file' : 'Submit'"
          :disabled="uploadSuccesfullyCompleted || !file"
        )
</template>

<script lang="ts">
import { defineComponent, ref, computed, getCurrentInstance } from 'vue'
import { FormKitFileValue } from "@formkit/inputs"
import { propsDef, emitsDef, getInjection } from "./BirthCertificateUploadModal.ilx"
import * as ilapi from "src/composables/InleagueApiV1"
import { axiosInstance, FALLBACK_ERROR_MESSAGE, freshAxiosInstance } from 'src/boot/axios'
import axios from "axios"
import { useIziToast } from 'src/helpers/utils'

import { Client } from 'src/store/Client'
import { addBirthCertificateToChild } from 'src/composables/InLeagueApiV1.Child'

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, { emit }) {
    const iziToast = useIziToast();
    const regionName = Client.value.instanceConfig.shortname || "inLeague";

    const uploadSuccesfullyCompleted = ref(false);
    const filesInput = ref<FormKitFileValue | null>(null)
    const file = computed<File | null>(() => filesInput.value?.[0]?.file ?? null);

    const targetPlayerUiName = computed(() => `${props.player.playerFirstName} ${props.player.playerLastName}`)

    const uploadCertificate = async () => {
      if (!file.value) {
        // form shouldn't have let us get here, nothing to upload
        return;
      }
      try {
        const axiosWithExtendedTimeout = freshAxiosInstance({
          useCurrentBearerToken: true,
          responseInterceptors: [],
          // Bumped up from earlier timeout of 45 seconds, we were getting a lot of axios timeouts (didn't seem to be server errors, just client timeouts)
          // Presumably 2 minutes will be enough for 99% but we will see
          timeout_ms: getInjection("uploadTimeout_ms")
        });
        await addBirthCertificateToChild(axiosWithExtendedTimeout, {childID: props.player.childID, data: file.value})
        uploadSuccesfullyCompleted.value = true;
        iziToast.success({
          message: `${regionName} has received your birth certificate submission for ${targetPlayerUiName.value}. If any further action is required, we will contact you. Thank you!`
        });
        emit("successfulUpload", props.player);
      }
      catch (err) {
        if (axios.isAxiosError(err)) {
          // we might want to log these, but for now if we catch and handle it it's not getting logged
          // if we rethrow "expected" errors to glob onto application error logging, formkit dies because we are invoked via their submit handler
          if (err.code === "ECONNABORTED") {
            // timeout on client side (backend may or may not have succeeded, probably not though)
            if (file.value.size > getInjection("uploadWarning_if_gt_bytes")) {
              iziToast.error({message: `We weren't able to process your upload quickly enough. Please use a file size smaller than ${getInjection("uploadWarning_uiSize")} and try again on a faster connection.`});
            }
            else {
              iziToast.error({message: `We weren't able to process your upload quickly enough. Please try again on a faster connection.`});
            }
          }
          else {
            if (err.response && ilapi.isInleagueApiError(err.response.data)) {
              iziToast.error({message: err.response.data.messages.join(", ")})
            }
            else {
              iziToast.error({message: FALLBACK_ERROR_MESSAGE});
            }
          }
          return;
        }
        else {
          // rethrow, intent is to send it to app error logger
          throw err;
        }
      }
    }

    return {
      close: (): void => {
        emit("close")
      },
      targetPlayerUiName,
      file,
      uploadCertificate,
      filesInput,
      uploadSuccesfullyCompleted
    }
  }
})

</script>
