import { ExtractPropTypes, Fragment, PropType, defineComponent, ref } from "vue";
import { DeepConst, assertTruthy, downloadFromObjectURL, exhaustiveCaseGuard, sortBy, sortByMany, vOptT, vReqT } from "src/helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowDown, faArrowUp, faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import * as XlsxUtils from "./XlsxUtils"

export type SortDir = "asc" | "desc" | "not-sorted"

// probably could do without the `dir` property, because that is stored on a generated SortState object
// Subsequently this wouldn't need to be a structured thing and could be inlined into ColDef<T>
interface ColDefSorter<T> {
  cb: (l:T, r:T) => number,
  dir: SortDir
}

export interface ColDef<T, Ids extends string | number = string | number> {
  id: Ids,
  label: string,
  filterElem?: () => JSX.Element | null
  filterableValue?: (v: T) => any,
  /**
   * "html" means "sort by using the results of the html function"
   * If the "html" generating function produces something other than a sortable primitive (string, number, boolean),
   * (i.e. a JSX fragment) then sorting will succeed but not produce desired results. This is for simple cases
   * where the column is a single field like `{html: (row) => row.someStringCol, sort: "html"}`,
   * whereas the following doesn't make sense: `{html: (row) => <div>...complex html...</div>, sort: "html"}`
   *
   * TODO: remove the ColDefSorter<T> union constituent
   */
  sort?: ((l:T,r:T) => number) | ColDefSorter<T> | "html",
  cellStyle?: string,
  cellClass?: string,
  headerClass?: string,
  headerStyle?: string,
  html: ((_: T) => JSX.Element | string | number) | "never" | {
    cellValue: (_: T) => JSX.Element | string | number,
    /** support for `if` is not implemented */
    __if?: () => boolean,
  }
  /**
   * if not present (or undefined), means "same as html"
   * if "never", means "not ever part of xlsx output"
   * TODO: handle cases where `html` returns JSX and we rely on that to emit the xlsx cell string.
   * Investigate ways to extract element text out of JSX (without it having been rendered to DOM first).
   */
  xlsx?:
    | "never"
    | ((_: T) => string | number) | {
        cellValue: (_: T) => string | number
        /** support for `if` is not implemented */
        __if?: () => boolean
      }
}

export function requireColDefListingHasUniqueIDs(colDefs: ColDef<any>[]) : void {
  const unique = new Set(colDefs.map(v => v.id))
  if (unique.size != colDefs.length) {
    throw Error("colDefs not unique by ID")
  }
}

export function sortDefsAsSorter<T>(vs: readonly ColDefSorter<T>[]) : (l:T,r:T) => number {
  const sorters = vs
    // drop non-sorting sorters
    .filter(_ => _.dir !== "not-sorted")
    // keep asc unchanged, invert the output of a descending sorter
    .map(_ => _.dir === "asc" ? _.cb : ((l,r) => _.cb(l,r) * -1) satisfies typeof _.cb)

  return sortByMany(...sorters)
}

function colDef2XlsxCell<T>(colDef: ColDef<T>, row: T) : string | number {
  if (colDef.xlsx === "never") {
    throw Error("xlsx mst not be 'never' here")
  }

  if (colDef.xlsx) {
    return typeof colDef.xlsx === "function"
      ? colDef.xlsx(row)
      : colDef.xlsx.cellValue(row)
  }


  const jsxOrAtom = typeof colDef.html === "function"
    ? colDef.html(row)
    : colDef.html === "never"
    ? ""
    : colDef.html.cellValue(row)

  return (typeof jsxOrAtom === "string" || typeof jsxOrAtom === "number")
    ? jsxOrAtom // ok, it's a string
    : "" // can't return a JSX for a csv/xlsx row, so probably there's a bug in the coldef
}

export function getColDefHtml<T>(colDef: ColDef<T>, rowData: T) : any {
  if (colDef.html === "never") {
    return null
  }
  if (typeof colDef.html === "function") {
    return colDef.html(rowData)
  }
  return colDef.html.cellValue(rowData)
}

function dropNoXlsx<T>(colDefs: ColDef<T>[]) {
  return colDefs.filter(v => v.xlsx !== "never");
}

export function rowsToCsv<T>(colDefs: ColDef<T>[], rows: T[]) : string {
  // good enough?
  const escapeCsv = (s: string) => `"` + s.replaceAll(/"/g, `""`) + `"`

  const filteredColDefs = dropNoXlsx(colDefs);

  const csvHeader = filteredColDefs.map(v => escapeCsv(v.label)).join(",")

  const csvRows = rows
    .map(row => filteredColDefs.map(colDef => colDef2XlsxCell(colDef, row)).map(v => escapeCsv(v.toString())).join(","))
    .join("\n")

  return csvHeader + "\n" + csvRows
}

export function rowsToXlsxSimpleSheetDef<T>(colDefs: ColDef<T>[], rows: T[], name = "Sheet 1") : XlsxUtils.SimpleSheetDef {
  const xlsxHeaders = colDefs.map(v => v.label)
  const xlsxRows = rows
    .map(row => {
      return colDefs.map(colDef => colDef2XlsxCell(colDef, row))
    })

  const builder = XlsxUtils.builderWithKludgyAutoWidths(xlsxHeaders);

  xlsxRows.forEach(row => builder.pushRow(row));
  return builder.getAsSimpleSheetDef(name);
}

export function rowsToXlsxBuffer<T>(colDefs: ColDef<T>[], rows: T[], name = "Sheet 1") : Promise<ArrayBuffer> {
  const sheetDef = rowsToXlsxSimpleSheetDef(colDefs, rows, name)
  return XlsxUtils.xlsxBufferFromSheetDef(sheetDef)
}

export type SortState<T, ColID extends string | number = string | number> = ReturnType<typeof freshSortState<T, ColID>>
export function freshSortState<T, ColID extends string | number>(colDefs: (Pick<ColDef<T, ColID>, "sort" | "id"> & {html?: ColDef<T>["html"]})[]) {
  // Conceptually these are each views into the other; one a lookup by name, the other
  // an ordered list itself sorted by the sort priority.
  // Note the entries of the map contain methods that the entries in the list don't.
  const sortMap = ref<SortMap>({})
  const sortList = ref<(ColDefSorter<T> & {readonly colID: string | number})[]>([])

  const addSorter = (colID: ColID, sorter: ColDefSorter<T> | ((l: T, r: T) => number)) : void => {
    assertTruthy(!sortMap.value[colID], "do not double-add sorters by ID");

    // TODO: refactor to only keep the "func" signature
    const unifyFuncOrObj : ColDefSorter<T> = typeof sorter === "function" ? {cb: sorter, dir: "not-sorted"} : sorter;
    sortList.value.push({...unifyFuncOrObj, colID})
    sortMap.value[colID] = {
      dir: unifyFuncOrObj.dir,
      sortAndPrioritize: () => {
        const freshDir = advanceAndPrioritizeSorter(colID)
        sortMap.value[colID]!.dir = freshDir
      }
    }
  }

  colDefs.forEach(colDef => {
    const sorter = colDef.sort;
    if (!sorter) {
      return
    }

    if (sorter === "html") {
      if (typeof colDef.html === "function") {
        addSorter(colDef.id, sortBy(colDef.html));
      }
      else {
        console.warn(`Column definition '${colDef.id}' specifies a sorter of type 'html' but does not supply an html generating function.`)
      }
    }
    else {
      addSorter(colDef.id, sorter);
    }
  })

  return {
    addSorter,
    get sortList() : DeepConst<typeof sortList.value> { return sortList.value },
    get sortersByColID() : DeepConst<typeof sortMap.value> { return sortMap.value },
    sort: (v: T[]) => {
      return v.sort(sortDefsAsSorter(sortList.value))
    },
    asSorter: () => {
      return sortDefsAsSorter(sortList.value)
    },
    reconfigure: (xs: {colID: ColID, dir: SortDir}[]) : void => {
      if (xs.length > sortList.value.length) {
        throw Error("Reconfigure source list is longer than the list being reconfigured");
      }
      sortList.value = sortList
        .value
        .map((e,oldIdx) => {
          const newIdx = xs.findIndex(v => v.colID === e.colID)
          const freshDir : SortDir = newIdx === -1 ? "not-sorted" : xs[newIdx].dir
          return [
            // sort by newIndex, grouping items not reconfigured together at the end of the list
            newIdx === -1 ? 99999 : newIdx,
            // then sort by old index
            oldIdx,
            {...e, dir: freshDir}
          ] as const
        })
        .sort(
          sortByMany(
            sortBy(_ => _[0]),
            sortBy(_ => _[1]),
          )
        )
        .map(_ => _[2])

      sortList.value.forEach(v => {
        const target = sortMap.value[v.colID];
        if (!target) {
          // shouldn't happen
          throw Error("missing sortDef?")
        }
        target.dir = v.dir;
      })
    }
  };

  function advanceAndPrioritizeSorter(colID: string | number) : "asc" | "desc" | "not-sorted" {
    const idx = sortList.value.findIndex(v => v.colID === colID);
    if (idx === -1) {
      throw Error(`Couldn't find sorter having id=${colID}`)
    }

    const target = sortList.value[idx];

    // asc -> desc -> none -> (wrap-around)
    target.dir = target.dir === "asc"
      ? "desc"
      : target.dir === "desc"
      ? "not-sorted"
      : "asc"

    sortList.value.splice(idx, 1);
    sortList.value.unshift(target);

    return target.dir
  }

  interface SortMap {
    [colDefColID: string]:
      | undefined // might possibly not be present for some colDefID
      | {
        /**
         * for use in UI: "which way is this column sorted right now"
         * Is this duplicative w/ sortlist's ColDefSorter<T>.dir ?
         */
        dir: SortDir
        /**
         * update the UI dir, shift the sort priority to "first", and advance to next sort for this column (e.g. from asc to desc or whatever the next is)
         * This is intended to be in response to a user clicking a sort arrow.
         */
        sortAndPrioritize: () => void
      }
  }
}

/**
 * @deprecated doesn't compose well with various needs of the "class" property
 */
export const SortArrow = defineComponent({
  props: {
    dir: vReqT<"asc" | "desc" | "not-sorted">(),
    class: vOptT<string>()
  },
  emits: {
    click: () => true
  },
  setup(props, {emit}) {
    return () => (
      <span class={props.class ?? "hover:bg-[rgb(0,0,0,.06125)] active:bg-[rgb(0,0,0,.125)] cursor-pointer"} onClick={() => emit("click")}>
        <FontAwesomeSortArrow dir={props.dir}/>
      </span>
    )
  }
})

const sortArrow_commonStyle = "hover:bg-[rgb(0,0,0,.06125)] active:bg-[rgb(0,0,0,.125)] cursor-pointer"

export function FontAwesomeSortArrow({dir}: {dir: "asc" | "desc" | "not-sorted"}) {
  switch (dir) {
    case "asc": return <FontAwesomeIcon icon={faArrowUp}/>
    case "desc": return <FontAwesomeIcon icon={faArrowDown}/>
    case "not-sorted": return <FontAwesomeIcon icon={faArrowUpArrowDown} class="text-gray-400"/>
    default: exhaustiveCaseGuard(dir);
  }
}

//
// These factory methods might not be super helpful because we'll often want to customize behavior in particular ways,
// but they do serve as examples of "here's how the most basic case works" for 3 common cases.
//

export function downloadAsXlsxFactory<T>(colDefs: ColDef<T>[]) : ((rows: T[], filename: string) => Promise<void>) {
  return async (rows: T[], filename: string) => {
    const buffer = await rowsToXlsxBuffer(colDefs, rows)
    downloadFromObjectURL(buffer, filename)
  }
}

export function downloadAsCsvFactory<T>(colDefs: ColDef<T>[]) : ((rows: T[], filename: string) => void) {
  return (rows: T[], filename: string) => {
    const csv = rowsToCsv(colDefs, rows)
    downloadFromObjectURL(csv, filename)
  }
}

export function copyToClipboardAsCsvFactory<T>(colDefs: ColDef<T>[]) : ((rows: T[]) => void) {
  return (rows: T[]) => {
    const csv = rowsToCsv(colDefs, rows)
    navigator.clipboard.writeText(csv)
  }
}

function basicTableProps<T, ColIDs extends string | number>() {
  return {
    colDefs: vReqT<ColDef<T>[]>(),
    rowData: vReqT<T[]>(),
    sortState: vReqT<SortState<T, ColIDs>>(),
    rowKey: vReqT<(e: T, i: number, a: T[]) => string | number>(),
    rowAttrs: vOptT<(e: T, i: number, a: T[]) => Record<string, string | number> | undefined>(),
    noData: vOptT<() => JSX.Element>(),
  }
}

export function typedBasicTableProps<T, ColIDs extends string | number>(props: ExtractPropTypes<ReturnType<typeof basicTableProps<T, ColIDs>>>) {
  return props;
}

export const BasicTable = defineComponent({
  props: basicTableProps<any, any>(),
  setup(props) {
    return () => {
      return (
        <table class="il-BasicTable">
          {
            props.colDefs.map(colDef => {
              const sorter = props.sortState.sortersByColID[colDef.id]
              return (
                <th class={colDef.headerClass ?? "p-1 align-top text-left"} style={colDef.headerStyle}>
                  <div>
                    <div class="flex items-start text-sm">
                      {sorter
                        ? (
                          <span class={["p-1 rounded-md", sortArrow_commonStyle]} onClick={() => sorter.sortAndPrioritize()}>
                            <FontAwesomeSortArrow dir={sorter.dir}/>
                          </span>
                        )
                        : null
                      }
                      {/*margintop adjusts for visual niceness against the sort arrows*/}
                      <span style={"margin-top:2px;"}>{colDef.label}</span>
                    </div>
                    {colDef.filterElem?.()}
                  </div>
                </th>
              )
            })
          }
          {
            props.rowData.length === 0 && props.noData
              ? <tr><td colspan="9999">{props.noData()}</td></tr>
              : null
          }
          {
            props
              .rowData
              .map((row, i, a) => {
                const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                return (
                  <Fragment key={props.rowKey(row, i, a)}>
                    <tr style={styles} {...props.rowAttrs?.(row, i, a)}>
                      {
                        props.colDefs.map((colDef) => {
                          return <td class={colDef.cellClass ?? "p-1 align-top text-left"} style={colDef.cellStyle ?? ""}>{getColDefHtml(colDef, row)}</td>
                        })
                      }
                    </tr>
                  </Fragment>
                )
              })
          }
        </table>
      )
    }
  }
})

/**
 * specify T explicitly; IDs will be inferred
 * e.g. `inferColIDs<SomeType>()({...colDefs...})`
 */
export function inferColIDs<T>() {
  return function<IDs extends string | number>(defs: ColDef<T, IDs>[]) : ColDef<T,IDs>[] {
    return defs;
  }
}

/**
 * Tries to get "the filterable value" for some cell.
 * Attempts, in order, returning the first thing that returns a string or number:
 *  - colDef.filterableValue(row)
 *  - colDef.html(row)
 *  - colDef.xlsx(row)
 */
export function getFilterableStringForCell<T>(row: T, colDef: ColDef<T, any>) : string | undefined {
  const explicitFilterable = colDef.filterableValue?.(row);
  if (typeof explicitFilterable !== "undefined") {
    return explicitFilterable
  }

  const html = typeof colDef.html === "function" ? colDef.html(row) : colDef.html === "never" ? {} : colDef.html.cellValue(row);
  if (typeof html === "string" || typeof html === "number") {
    return html.toString();
  }

  const xlsx = typeof colDef.xlsx === "function" ? colDef.xlsx(row) : colDef.xlsx === "never" ? {} : colDef.xlsx?.cellValue(row);

  if (typeof xlsx === "string" || typeof xlsx === "number") {
    return xlsx.toString();
  }

  return undefined;
}
