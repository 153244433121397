import { PropType, computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { axiosNoAuthInstance } from "src/boot/AxiosInstances";
import { exhaustiveCaseGuard, FormKitValidationRule, FK_useSomeNonFkBoolAsValidation, flowCapture } from "src/helpers/utils";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { FormKit } from "@formkit/vue";

import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { beginMfaInitJourney } from "./Mfa.route";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { User } from "src/store/User";

type JwtStatus =
  | {status: "not-provided"}
  | {status: "malformed"}
  | {status: "already-claimed"}
  | {status: "expired", jwt: string, email: string, userFullName: string}
  | {status: "ok", jwt: string, email: string, userFullName: string}

const PASSWORD_LENGTH = {
  min: 8,
  max: 90
} as const;

export default defineComponent({
  props: {

  },
  setup() {
    const router = useRouter();
    const jwtQueryParam = computed(() => router.currentRoute.value.query["jwt"])


    type LocalState =
      | {ready: false}
      | {ready: true, jwtStatus: JwtStatus}

    const localState = ref<LocalState>({ready: false});

    watch(() => jwtQueryParam.value, async () => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (typeof jwtQueryParam.value !== "string" || !jwtQueryParam.value) {
          localState.value = {ready: true, jwtStatus: {status: "not-provided"}};
        }
        else {
          const precheck = await ilauth.public_.claimUnclaimedAccount_tokenExpirationPrecheck(axiosNoAuthInstance, {jwt: jwtQueryParam.value});
          if (precheck.status === "malformed" || precheck.status === "already-claimed") {
            localState.value = {
              ready: true,
              jwtStatus: {...precheck}
            }
          }
          else {
            localState.value = {
              ready: true,
              jwtStatus: {
                jwt: jwtQueryParam.value,
                ...precheck
              }
            }
          }
        }
      })
    }, {immediate: true})

    const doRequestNewToken = async () : Promise<{success: boolean}> => {
      if (!localState.value.ready || (localState.value.jwtStatus.status !== "expired" && localState.value.jwtStatus.status !== "ok")) {
        throw Error("invalid call")
      }

      const {jwt} = flowCapture(localState.value.jwtStatus);

      return GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          await ilauth.public_.claimUnclaimedAccount_refreshNewToken(axiosNoAuthInstance, {jwt})
          return {success: true}
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          return {success: false};
        }
      });
    }

    const doSubmitClaim = async (newPassword: string) : Promise<{success:boolean}> => {
      if (!localState.value.ready || localState.value.jwtStatus.status !== "ok") {
        throw Error("invalid call")
      }

      const {jwt, email, userFullName} = flowCapture(localState.value.jwtStatus);

      return GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const result = await ilauth.public_.claimUnclaimedAccount_claim(axiosNoAuthInstance, {jwt, newPassword})
          if (result.status === "expired-token") {
            localState.value = {
              ready: true,
              jwtStatus: {
                status: "expired",
                jwt,
                email,
                userFullName
              }
            }
            return {success: false}
          }

          const authResult = await User.loginWeb(axiosNoAuthInstance, {password: newPassword, username: email});
          if (authResult.ok) {
            switch (authResult.data.status) {
              case "complete": {
                await User.loginUser(authResult.data);
                await router.push(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default}));
                return {success: true}
              }
              case "needs-mfa-init": {
                await beginMfaInitJourney(router, authResult.data)
                return {success: true}
              }
              case "needs-mfa-challenge": {
                throw Error("unreachable here (might need mfa-init, but never will have a challenge immediately after claiming an account)")
              }
              default: {
                exhaustiveCaseGuard(authResult.data);
              }
            }
          }
          else {
            // any common expected failure modes to handle?
            return {success:false}
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          return {success:false}
        }
      })
    }

    return () => {
      if (!localState.value.ready) {
        return null
      }

      return (
        <>
          <h1>
            Claim your user account
          </h1>
          <Dispatch
            jwtStatus={localState.value.jwtStatus}
            doRequestNewToken={doRequestNewToken}
            doSubmitClaim={doSubmitClaim}
          />
        </>
      )
    }
  }
})

const Dispatch = defineComponent({
  props: {
    jwtStatus: {
      required: true,
      type: null as any as PropType<JwtStatus>
    },
    doRequestNewToken: {
      required: true,
      type: null as any as PropType<() => Promise<{success: boolean}>>
    },
    doSubmitClaim: {
      required: true,
      type: null as any as PropType<(freshPassword: string) => Promise<{success: boolean}>>
    }
  },
  setup(props) {
    return () => {
      return (
        <div>
          {
            props.jwtStatus.status === "not-provided" || props.jwtStatus.status === "malformed"
              ? <div>The required claim token was missing or invalid.</div>
              : props.jwtStatus.status === "already-claimed"
              ? <div>This account is already claimed.</div>
              : props.jwtStatus.status === "expired"
              ? <ExpiredRequestAnother email={props.jwtStatus.email} doRequestNewToken={props.doRequestNewToken}/>
              : props.jwtStatus.status === "ok"
              ? <ClaimForm email={props.jwtStatus.email} userFullName={props.jwtStatus.userFullName} doSubmitClaim={props.doSubmitClaim}/>
              : exhaustiveCaseGuard(props.jwtStatus)
          }
        </div>
      )
    }
  }
})

const ClaimForm = defineComponent({
  props: {
    email: {
      required: true,
      type: String
    },
    userFullName: {
      required: true,
      type: String,
    },
    doSubmitClaim: {
      required: true,
      type: null as any as PropType<(freshPassword: string) => Promise<{success: boolean}>>
    }
  },
  setup(props) {
    type Status =
      | {mode: "initial"}
      | {mode: "request-in-progress"}
      | {mode: "success"}
      | {mode: "failure"}

    const status = ref<Status>({mode: "initial"})

    const password = ref({x1: "", x2: ""})

    const handleSubmit = async () : Promise<void> => {
      if (password.value.x1 !== password.value.x2) {
        throw Error("illegal call");
      }

      status.value = {mode: "request-in-progress"}

      const result = await props.doSubmitClaim(password.value.x1);

      if (result.success) {
        status.value = {
          mode: "success",
        }
      }
      else {
        status.value = {
          mode: "failure"
        }
      }
    }

    const fkv_passwordLength : FormKitValidationRule = ["length", PASSWORD_LENGTH.min, PASSWORD_LENGTH.max]
    const passwordsAreSame = computed(() => password.value.x1 === password.value.x2);
    const fk_passwordsAreSame = FK_useSomeNonFkBoolAsValidation(() => passwordsAreSame.value)

    return () => {
      return (
        <>
          <div>Welcome to inLeague, {props.userFullName}!</div>
          <div>Provide a password for your account and you'll be all set.</div>
          {
            status.value.mode === "initial" || status.value.mode === "request-in-progress"
              ? (
                <div>
                  <FormKit type="form" actions={false} onSubmit={handleSubmit} key={props.email}>
                    <FormKit type="text" {...{name: "email", autocomplete: "username"}} label="Username" v-model={props.email} disabled={true}/>
                    <FormKit
                      type="password" {...{name: "password", autocomplete:"new-password"}}
                      label="Password" v-model={password.value.x1}
                      validation={[["required"], fkv_passwordLength]}
                    />
                    <FormKit
                      type="password" {...{name: "password2", autocomplete:"new-password"}}
                      label="Confirm password" v-model={password.value.x2}
                      validation={[["required"], fkv_passwordLength, ["fk_passwordsAreSame"]]}
                      validationRules={{fk_passwordsAreSame}}
                      validationMessages={{fk_passwordsAreSame: "Passwords must be the same"}}
                    />
                    <t-btn type="submit">Submit</t-btn>
                  </FormKit>
                </div>
              )
              : status.value.mode === "success"
              ? (
                <div>
                  <div>OK!</div>
                </div>
              )
              : status.value.mode === "failure"
              ? (
                <div>
                  <div>Sorry, something went wrong.</div>
                </div>
              )
              : exhaustiveCaseGuard(status.value)
          }
        </>
      )
    }
  }
})

const ExpiredRequestAnother = defineComponent({
  props: {
    email: {
      required: true,
      type: String
    },
    doRequestNewToken: {
      required: true,
      type: null as any as PropType<() => Promise<{success: boolean}>>
    }
  },
  setup(props) {
    type Status =
      | {mode: "initial"}
      | {mode: "request-in-progress"}
      | {mode: "success"}
      | {mode: "failure"}

    const status = ref<Status>({mode: "initial"})

    const doRequestNewToken = async () : Promise<void> => {
      status.value = {mode: "request-in-progress"}
      const result = await props.doRequestNewToken();
      if (result.success) {
        status.value = {
          mode: "success",
        }
      }
      else {
        status.value = {
          mode: "failure"
        }
      }
    }

    return () => {
      return (
        status.value.mode === "initial"
          ? (
            <div>
              <div>The provided claim code is expired.</div>
              <t-btn margin={false} onClick={doRequestNewToken}>Request another</t-btn>
            </div>
          )
          : status.value.mode === "request-in-progress"
          ? (
            <div>
              <div>Requesting new code</div>
            </div>
          )
          : status.value.mode === "success"
          ? (
            <div>
              <div>Check your mail! We sent a new link with a fresh claim code to {props.email}.</div>
            </div>
          )
          : status.value.mode === "failure"
          ? (
            <div>
              <div>Sorry, something went wrong.</div>
            </div>
          )
          : exhaustiveCaseGuard(status.value)
      )
    }
  }
})
