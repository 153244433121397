import { computed, reactive, ref } from "vue"

export interface PaginationData<T = any> {
  count_itemsPerPage: number,
  count_itemsFinalPage: number,
  count_itemsThisPage: number,
  count_itemsNextPage: number,

  count_totalPages: number,
  count_totalItems: number,

  /** zero indexed */
  zi_currentPage: number,
  /** zero indexed, inclusive */
  zi_currentPageFirstIndex: number
  /** zero indexed, exclusive */
  zi_currentPageLastIndex: number

  hasNext: boolean,
  hasPrev: boolean,

  allItems: T[],
  itemsThisPage: T[]
}

export function paginationData<T>(list: T[], zi_currentPage: number, itemsPerPage: number | "ALL") : PaginationData<T> {
  if (itemsPerPage === 0 || list.length === 0) {
    return {
      count_itemsPerPage: 0,
      count_itemsFinalPage: 0,
      count_itemsThisPage: 0,
      count_itemsNextPage: 0,
      count_totalPages: 0,
      count_totalItems: 0,

      zi_currentPage: 0,
      zi_currentPageFirstIndex: 0,
      zi_currentPageLastIndex: 0,

      hasNext: false,
      hasPrev: false,

      allItems: list,
      itemsThisPage: []
    }
  }

  if (itemsPerPage === "ALL") {
    return {
      count_itemsPerPage: list.length,
      count_itemsFinalPage: list.length,
      count_totalPages: 1,
      count_totalItems: list.length,

      count_itemsThisPage: list.length,
      count_itemsNextPage: 0,

      zi_currentPage: zi_currentPage,
      zi_currentPageFirstIndex: 0,
      zi_currentPageLastIndex: list.length,

      hasNext: false,
      hasPrev: false,

      allItems: list,
      itemsThisPage: list,
    }
  }

  const fullPages = Math.floor(list.length / itemsPerPage);
  const totalPages = Math.ceil(list.length / itemsPerPage);
  const itemsLastPage = fullPages === totalPages ? itemsPerPage : (list.length - (fullPages * itemsPerPage));
  const hasPrev = zi_currentPage > 0;
  const hasNext = zi_currentPage + 1 < totalPages
  const isLast = !hasNext;
  const zi_currentPageFirstIndex = zi_currentPage * itemsPerPage;
  const zi_currentPageLastIndex = zi_currentPageFirstIndex + (isLast ? itemsLastPage : itemsPerPage);

  const nextIsLast = zi_currentPage + 1 === totalPages - 1;

  return {
    count_itemsPerPage: itemsPerPage,
    count_itemsFinalPage: itemsLastPage,
    count_totalPages: totalPages,
    count_totalItems: list.length,

    count_itemsThisPage: isLast ? itemsLastPage : itemsPerPage,
    count_itemsNextPage: isLast ? 0 : nextIsLast ? itemsLastPage : itemsPerPage,

    zi_currentPage,
    zi_currentPageFirstIndex,
    zi_currentPageLastIndex,

    hasNext: hasNext,
    hasPrev: hasPrev,

    allItems: list,
    itemsThisPage: list.slice(zi_currentPageFirstIndex, zi_currentPageLastIndex)
  }
}

/**
 * intent here is that mutating `zi_currentPage` updates the computed `pageData` value.
 * We should probably add `decPage()` and `incPage()` or something like that
 */
export function Paginated<T>(itemsPerPage: number | "ALL", itemsRef: {value: T[]}) {
  const zi_currentPage = ref(0);
  return reactive({
    zi_currentPage, // TODO: should just expose this as a getter
    nextPage: () => { zi_currentPage.value++ },
    prevPage: () => { zi_currentPage.value-- },
    pageData: computed(() => paginationData(itemsRef.value, zi_currentPage.value, itemsPerPage))
  });
}

export type Paginated<T> = ReturnType<typeof Paginated<T>>
