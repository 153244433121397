import { computed, Ref, ref } from "vue"
import * as ilcoupon from "src/composables/InleagueApiV1.Coupon"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { UiOption, VueNeverUnwrappable } from "src/helpers/utils";

export function SeasonGroupedCompetitionSelector(current: ilcoupon.CouponCompetitionSeasonLink[] | null, candidates: ilcoupon.CouponEntityLinkageCandidate_CompetitionSeason[]) {
  interface XGroup {
    seasonDetail: {
      seasonID: number,
      seasonUID: iltypes.Guid,
      seasonName: string,
      readonly mutable: boolean
    },
    competitionDetail: CompGroup
  }

  const seasonGroups = {
    available: ref<XGroup[]>([]),
    engaged: ref<XGroup[]>([])
  }

  const uniqueSeasonInfo = new Map(candidates.map(v => [v.seasonUID, {seasonUID: v.seasonUID, seasonID: v.seasonID, seasonName: v.seasonName}]));
  const currentSeasonUIDs = new Set(current?.map(v => v.seasonUID));

  for (const [seasonUID, seasonInfo] of uniqueSeasonInfo.entries()) {
    const currentPerSeason = current?.filter(v => v.seasonUID === seasonUID) ?? [];
    const candidatesPerSeason = candidates.filter(v => v.seasonUID === seasonUID);
    const competitionDetail = CompGroup(currentPerSeason, candidatesPerSeason);
    const seasonDetail = {
      ...seasonInfo,
      mutable: competitionDetail.options.committed.value.every(v => v.value.mutable)
    };
    if (currentSeasonUIDs.has(seasonUID)) {
      seasonGroups.engaged.value.push({seasonDetail, competitionDetail})
    }
    else {
      seasonGroups.available.value.push({seasonDetail, competitionDetail})
    }
  }

  // "newest season" first
  function sortXGroupBySeason(l: XGroup, r: XGroup) {
    return l.seasonDetail.seasonID > r.seasonDetail.seasonID ? -1 : 1;
  }

  seasonGroups.available.value.sort(sortXGroupBySeason);
  seasonGroups.engaged.value.sort(sortXGroupBySeason);

  const selected_availableSeasonGroup = ref("");

  return VueNeverUnwrappable({
    engagedSeasonGroups: computed(() => seasonGroups.engaged.value),
    availableSeasonGroups: {
      selected: selected_availableSeasonGroup,
      options: computed<UiOption[]>(() => {
        const ret = seasonGroups.available.value.map(v => {
          return {label: v.seasonDetail.seasonName, value: v.seasonDetail.seasonUID}
        })
        return ret.length === 0
          ? [{label: "No options", value: "", attrs: {disabled: true}}]
          : [{label: "", value: ""}, ...ret];
      }),
    },
    /**
     * Note that this does not clear or otherwise mutate in anyway the state of the target season's competition group.
     * So a selection in the compgroup remains selected, and the committed values remain committed, etc., but,
     * "effectivelySelected()" should do the right thing and not descend into an unengaged season's comp group.
     */
    unengageSeason: (seasonUID: iltypes.Guid) => {
      const targetIdx = seasonGroups.engaged.value.findIndex(v => v.seasonDetail.seasonUID === seasonUID);
      if (targetIdx === -1 || !seasonGroups.engaged.value[targetIdx].seasonDetail.mutable) {
        return; // couldn't find it, or target is immutable? shouldn't happen
      }
      const target = seasonGroups.engaged.value[targetIdx];
      seasonGroups.engaged.value.splice(targetIdx, 1);
      seasonGroups.available.value.push(target);
      seasonGroups.available.value.sort(sortXGroupBySeason);
    },
    engageSelectedSeason: () => {
      const seasonUID = selected_availableSeasonGroup.value;
      if (!seasonUID) {
        return;
      }
      const targetIdx = seasonGroups.available.value.findIndex(v => v.seasonDetail.seasonUID === seasonUID);
      if (targetIdx === -1) {
        return; // couldn't find it? shouldn't happen
      }
      const target = seasonGroups.available.value[targetIdx];
      seasonGroups.available.value.splice(targetIdx, 1);
      seasonGroups.engaged.value.push(target);
      seasonGroups.engaged.value.sort(sortXGroupBySeason);
      selected_availableSeasonGroup.value = "";
    },
    effectivelySelected: () => {
      return seasonGroups.engaged.value.flatMap(v => v.competitionDetail.effectivelySelected())
    }
  });

  interface CompetitionOption {
    competitionSeasonUID: iltypes.Guid,
    competitionName: string,
    competitionID: number,
    mutable: boolean
  }

  function sortByCompetitionIDAsc(l: {competitionID: number}, r: {competitionID: number}) {
    return l.competitionID < r.competitionID ? -1 : l.competitionID === r.competitionID ? 0 : 1;
  }

  function CompGroup(current: ilcoupon.CouponCompetitionSeasonLink[] | null, candidates: ilcoupon.CouponEntityLinkageCandidate_CompetitionSeason[]) {
    const committed = {
      current: ref<CompetitionOption[]>(
        (current?.map(v => ({...v, mutable: v.redemptionInfo.length === 0})) ?? []).sort(sortByCompetitionIDAsc)
      ),
      selected: ref<CompetitionOption[]>([])
    }

    type SelectGroup = typeof committed;

    const uncommitted : SelectGroup = {
      current: ref<CompetitionOption[]>((() => {
        const initiallyCommitted = new Set(committed.current.value.map(v => v.competitionSeasonUID));
        return candidates
          .filter(v => !initiallyCommitted.has(v.competitionSeasonUID))
          .map(v => ({competitionSeasonUID: v.competitionSeasonUID, competitionName: v.competition, competitionID: v.competitionID, mutable: true}))
          .sort(sortByCompetitionIDAsc)
      })()),
      selected: ref<CompetitionOption[]>([])
    }

    type Option = UiOption<CompetitionOption | "NIL-OPTION!">
    type OptionWithFormKitAttrs = UiOption<CompetitionOption> & {attrs: {readonly disabled: boolean}}

    const options = {
      uncommitted: computed<Option[]>(() => {
        const ret = uncommitted.current.value.map(v => ({label: v.competitionName, value: v}));
        return ret.length === 0
          ? [{label: "No options", value: "NIL-OPTION!", attrs: {disabled: true}}]
          : ret;
      }),
      committed: computed<OptionWithFormKitAttrs[]>(() => {
        return committed.current.value.map(v => ({label: v.competitionName, value: v, attrs: {disabled: !v.mutable}}))
      }),
    }

    const effectivelySelected = () : iltypes.Guid[]  => {
      return committed.current.value.map(v => v.competitionSeasonUID);
    }

    const moveSelectedToOther = (from: SelectGroup, to: SelectGroup) : void => {
      const wasSelected = new Set(from.selected.value.map(v => v.competitionSeasonUID));

      to.current.value.push(...from.selected.value);
      from.current.value = from.current.value.filter(v => !wasSelected.has(v.competitionSeasonUID));
      from.selected.value = [];

      to.current.value.sort(sortByCompetitionIDAsc);
    }

    const commit = () : void => {
      moveSelectedToOther(uncommitted, committed);
    }

    const uncommit = () : void => {
      moveSelectedToOther(committed, uncommitted);
    }

    return VueNeverUnwrappable({
      commit,
      uncommit,
      options: {
        uncommitted: options.uncommitted,
        committed: options.committed
      },
      selected: {
        uncommitted: uncommitted.selected,
        committed: committed.selected,
      },
      effectivelySelected,
    })
  }

  type CompGroup = ReturnType<typeof CompGroup>
}

export type V_SeasonGroupedCompetitionSelector = ReturnType<typeof SeasonGroupedCompetitionSelector>;

export interface EventOption {
  eventID: iltypes.Guid,
  eventName: string,
  mutable: boolean
}

export function EventSelector(current: ilcoupon.CouponEventLink[] | null, candidates: ilcoupon.CouponEntityLinkageCandidate_Event[]) {
  const committed = {
    current: ref<EventOption[]>(current?.map(v => ({...v, mutable: v.redemptionInfo.length === 0})) ?? []),
    selected: ref<EventOption[]>([])
  }

  type SelectGroup = typeof committed;

  const uncommitted : SelectGroup = {
    current: ref<EventOption[]>((() => {
      const initiallyCommitted = new Set(committed.current.value.map(v => v.eventID));
      return candidates.filter(v => !initiallyCommitted.has(v.eventID)).map(v => ({...v, mutable: true}))
    })()),
    selected: ref<EventOption[]>([])
  }

  type Option = UiOption<EventOption>
  type OptionWithFormKitAttrs = UiOption<EventOption> & {attrs: {readonly disabled: boolean}}

  const options = {
    uncommitted: computed<Option[]>(() => {
      return uncommitted.current.value.map(v => ({label: v.eventName, value: v}));
    }),
    committed: computed<OptionWithFormKitAttrs[]>(() => {
      return committed.current.value.map(v => ({label: v.eventName, value: v, attrs: {disabled: !v.mutable}}))
    }),
  }

  const effectivelySelected = () : iltypes.Guid[]  => {
    return committed.current.value.map(v => v.eventID);
  }

  const moveSelectedToOther = (from: SelectGroup, to: SelectGroup) : void => {
    const wasSelected = new Set(from.selected.value.map(v => v.eventID));

    to.current.value.push(...from.selected.value);
    from.current.value = from.current.value.filter(v => !wasSelected.has(v.eventID));
    from.selected.value = [];

    to.current.value.sort((l,r) => l.eventName < r.eventName ? -1 : 1)
  }

  const commit = () : void => {
    moveSelectedToOther(uncommitted, committed);
  }

  const uncommit = () : void => {
    moveSelectedToOther(committed, uncommitted);
  }

  return VueNeverUnwrappable({
    commit,
    uncommit,
    options: {
      uncommitted: options.uncommitted,
      committed: options.committed
    },
    selected: {
      uncommitted: uncommitted.selected,
      committed: committed.selected,
    },
    effectivelySelected,
  })
}

export type V_EventSelector = ReturnType<typeof EventSelector>

export interface UserOrPlayerLinkOption {
  type: "user" | "player",
  firstName: string,
  lastName: string,
  displayName: string,
  /**
   * id of user or player
   */
  id: iltypes.Guid,
  /**
   * mutable is true if it can be removed (meaning, there are no current redemptions for this coupon for this link)
   */
  mutable: boolean,
}

export function UserOrPlayerLinkSelector(finder: (query: string) => Promise<UserOrPlayerLinkOption[]>, initiallyCommitted: UserOrPlayerLinkOption[]) {
  const uncommitted = {
    current: ref<UserOrPlayerLinkOption[]>([]),
    selected: ref<UserOrPlayerLinkOption[]>([])
  }

  type SelectGroup = typeof uncommitted;

  const committed : SelectGroup = {
    current: ref<UserOrPlayerLinkOption[]>(initiallyCommitted),
    selected: ref<UserOrPlayerLinkOption[]>([])
  }

  const idSet = (vs: UserOrPlayerLinkOption[]) => new Set(vs.map(v => v.id));
  const sort = (vs: UserOrPlayerLinkOption[]) => vs.sort((l,r) => l.lastName < r.lastName ? -1 : (l.lastName === r.lastName) ? l.firstName < r.firstName ? -1 : 1 : 1);

  const doSearch = async (search: string) : Promise<void> => {
    const freshes = await finder(search);

    const alreadyCommitted = idSet(committed.current.value);
    uncommitted.selected.value = [];
    uncommitted.current.value = freshes.filter(fresh => !alreadyCommitted.has(fresh.id));
  }

  const moveSelectedToOther = (from: SelectGroup, to: SelectGroup) : void => {
    const wasSelected = idSet(from.selected.value);

    to.current.value.push(...from.selected.value);
    from.current.value = from.current.value.filter(v => !wasSelected.has(v.id));
    from.selected.value = [];

    sort(to.current.value);
  }

  const commit = () : void => {
    moveSelectedToOther(uncommitted, committed);
  }

  const uncommit = () : void => {
    moveSelectedToOther(committed, uncommitted);
  }

  type Option = UiOption<UserOrPlayerLinkOption>
  type OptionWithFormKitAttrs = UiOption<UserOrPlayerLinkOption> & {attrs: {readonly disabled: boolean}}

  return VueNeverUnwrappable({
    doSearch,
    commit,
    uncommit,
    options: {
      uncommitted: computed<Option[]>(() => {
        // the uncommitted things are never disabled
        return uncommitted.current.value.map(v => ({label: v.displayName, value: v}));
      }),
      committed: computed<OptionWithFormKitAttrs[]>(() => {
        // the committed things may be auto-defaulted, depending on their mutability
        // a disabled option shouldn't ever get pushed back into uncommitted so it is OK that the uncommitted values don't have such an attr
        return committed.current.value.map(v => ({label: v.displayName, value: v, attrs: {disabled: !v.mutable}}))
      })
    },
    selected: {
      uncommitted: uncommitted.selected,
      committed: committed.selected
    },
    effectivelySelected: () => committed.current.value.map(v => v.id)
  })
}

export type V_UserOrPlayerLinkSelector = ReturnType<typeof UserOrPlayerLinkSelector>