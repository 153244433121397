import { Directive, defineComponent, onUnmounted, reactive, ref } from "vue"

/**
 * basically `let x : bool` in a vue template
 */
export const ScopedBool = defineComponent({
  setup(_, {slots}) {
    const bool = ref(false)
    return () => slots
      .default
      ?.(reactive({
        toggle: () => {bool.value = !bool.value},
        set: (v: boolean) => {bool.value = v},
        setTrue: () => {bool.value = true},
        setFalse: () => {bool.value = false},
        value: bool
      }))
  }
})

// this might be useful in a top-level store, as a reactive var that always has the most-up-to-date window width/height
export const ScopedWindowSize = defineComponent({
  setup(_, {slots}) {
    const innerWidth = ref(window.innerWidth);
    const innerHeight = ref(window.innerHeight);

    {
      const resizeHandler = () => {
        innerWidth.value = window.innerWidth
        innerHeight.value = window.innerHeight
      }
      window.addEventListener("resize", resizeHandler)
      onUnmounted(() => window.removeEventListener("resize", resizeHandler))
    }

    return () => slots.default?.(reactive({innerWidth: innerWidth, innerHeight: innerHeight}))
  }
})
