import { defineComponent, ref, PropType } from 'vue'

import /**/ C_Portlet from 'src/components/Portlets/Portlet.vue'
import * as M_Portlet from "src/components/Portlets/Portlet.ilx"

import /**/ C_BirthCertificateUploadModal from 'src/components/PlayerEditor/BirthCertificateUploadModal.vue'
import * as M_BirthCertificateUploadModal from 'src/components/PlayerEditor/BirthCertificateUploadModal.ilx'

import * as iltypes from 'src/interfaces/InleagueApiV1'

// shim SFC<->JSX
declare function BirthCertificateUploadModal(props: M_BirthCertificateUploadModal.Props & M_BirthCertificateUploadModal.OnEmits) : JSX.Element;

declare const Portlet : M_Portlet.jsx_sfc_shim__Portlet;

export default defineComponent({
  name: 'PlayersWithUnmetBirthCertificateRequirement',
  components: {
    Portlet: C_Portlet,
    BirthCertificateUploadModal: C_BirthCertificateUploadModal,
  },
  props: {
    showContent: {
      type: Boolean,
      default: true,
    },
    portlet: {
      required: true,
      type: Object as PropType<iltypes.PlayersWithUnmetBirthCertificateRequirementPortlet>
    },
  },
  setup(props) {
    const show = ref(props.showContent)

    // playerIDs that had their birth certs uploaded while this component was mounted
    const uploadedDuringThisLifecycle = ref(new Set<iltypes.Guid>())

    // This controller made more sense when the surrounding component was an SFC.
    const birthCertificateUploadModalController = ref(M_BirthCertificateUploadModal.DefaultController({
      close: () => {
        birthCertificateUploadModalController.value.hide()
      },
      successfulUpload: (player: iltypes.Child) => {
        uploadedDuringThisLifecycle.value.add(player.childID)
        birthCertificateUploadModalController.value.hide()
      }
    }))

    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    return () => (
      <div data-test="PlayersWithUnmetBirthCertificateRequirements">
        {
          birthCertificateUploadModalController.value.visible
            ? (
              //
              // if controller.value is "visible", then props is non-null; this flow analysis could be improved to consider that we're using jsx now rather than sfc
              //
              <BirthCertificateUploadModal
                player={birthCertificateUploadModalController.value.props!.player}
                onClose={() => birthCertificateUploadModalController.value.handlers.close()}
                onSuccessfulUpload={player => birthCertificateUploadModalController.value.handlers.successfulUpload(player)}
              />
            )
            : null
        }
        <Portlet
          label="Players needing birth certificate uploads"
          prefix="fas"
          icon={props.portlet.fontAwesome}
          onExpand={(expand) => toggleExpand(expand)}
        >
          <div class="m-2 divide-y" data-test="per-child-container">
            {
              props.portlet.players.map(player => {
                return (
                  <div class="py-2 flex flex-row items-center" data-test="each-child">
                      <span data-test={`childID=${player.childID}`} class="text-blue-500 underline cursor-pointer" onClick={() => birthCertificateUploadModalController.value.show(player)}>
                        {player.playerFirstName } { player.playerLastName }
                      </span>
                      {
                        uploadedDuringThisLifecycle.value.has(player.childID) || player.birthCertificate
                          ? (
                            <span class="ml-2 text-xs">
                              (on-file but not yet approved)
                            </span>
                          )
                          : null
                      }
                  </div>
                )
              })
            }
          </div>
        </Portlet>
      </div>
    )
  }
})
