<template lang="pug">
.flex.flex-col(v-if='Object.keys(family).length > 0' data-test="Volunteers")
  AutoModal(:controller="coachPhotoUploadModalController" data-test="CoachPhotoUploadModalController")
  .overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
    h3.bg-gray-200.min-w-full.rounded-t-lg.px-4.py-2.font-medium Volunteers
    .py-2.align-middle.inline-block.min-w-full
      .shadow.overflow-hidden.border-b.border-gray-200
        table.min-w-full.divide-y.divide-gray-200
          thead.bg-gray-50
            tr
              th.px-2.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Name
              th.px-2.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) Email/Login
              th.px-2.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase(
                scope='col'
              ) AYSO Registration
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='parent in family.parents', :key='parent.ID' :data-test="parent.ID")
              td.px-2.py-1.text-sm.font-medium.text-gray-900
                MaybeLinkToUserEditor(:user="parent")
                span.ml-2.cursor-pointer.underline(v-if='parent.ID === family.parent1ID' @click='viewParentOrdinals=!viewParentOrdinals') (Parent 1)
                span.ml-2.cursor-pointer.underline(v-else-if='parent.ID === family.parent2ID' @click='viewParentOrdinals=!viewParentOrdinals') (Parent 2)
              td.px-2.py-1.text-sm.text-gray-500.align-middle
                | {{ parent.email }}
              td.px-2.py-1.text-sm.text-gray-500.align-middle(v-if='parent.ID')
                div
                  BackgroundCheckStatus(
                    :user='parent'
                    :seasonUID='seasonUID'
                  )
                div(v-if="shouldShowCoachPhotoUploadButton[parent.ID]")
                  FormKit(
                    outer-class="$reset my-2"
                    type='button'
                    data-test="uploadCoachPhoto"
                    @click="coachPhotoUploadModalController.open(parent.ID)"
                  )
                    div Upload coach photo

        .my-2.ml-2
          ParentOrdinals(:family='family' v-if="viewParentOrdinals" @parentOrdinalsUpdated='emit("parentOrdinalsUpdated")')
        .my-2.ml-2(class="sm:flex")
          div
            FormKit(
              type='button'
              @click="registerVolunteers()"
            )
              | Register Volunteers
          div
            FormKit(
              type='button'
              @click="addUser"
            )
              | Add New User
</template>

<script lang="ts" setup>
import { AxiosErrorWrapper, axiosAuthBackgroundInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import { ref, onMounted, watch, computed, reactive, getCurrentInstance } from 'vue'
import type { PropType } from 'vue'

import { useRouter } from 'vue-router'
import BackgroundCheckStatus from 'src/components/FamilyProfile/BackgroundCheckStatus.vue'
import ParentOrdinals from 'src/components/FamilyProfile/ParentOrdinals.vue'
import type { FamilyData } from 'src/composables/InleagueApiV1.Family'

import * as R_VolunteerRegistrationFlowDetail from "src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route";
import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { MaybeLinkToUserEditor } from "./Volunteers.ilx"
import { AutoModal } from '../UserInterface/Modal'
import { defaultCoachPhotoUploadModalController } from '../UserInterface/Modal.photoUpload.coach'
import { useIziToast } from 'src/helpers/utils'

import { Client } from 'src/store/Client'

const props = defineProps({
  seasonUID: {
    type: String,
    required: true,
  },
  family: {
    required: true,
    type: Object as PropType<iltypes.WithDefinite<FamilyData, "parents">>
  },
})

const iziToast = useIziToast()

const emit = defineEmits(['parentOrdinalsUpdated'])

const season = ref({})
const viewParentOrdinals = ref(false)


const router = useRouter()


const addUser = async () => {
  await router.push({ name: "new-user", query: { familyID: props.family.familyID }})
}

watch(
  () => props.seasonUID,
  async (seasonUID, oldSeasonUID) => {
    if (seasonUID != oldSeasonUID) {
      season.value = await Client.getSeasonByUidOrFail(seasonUID)
    }
  }
)

const needsCoachPhotoInfo = ref<ilvolunteer.NeedsCoachPhotoInfo[]>([]);
const shouldShowCoachPhotoUploadButton = computed<{[userID: iltypes.Guid]: boolean}>(() => {
  const result : {[userID: iltypes.Guid]: boolean} = {}
  needsCoachPhotoInfo.value.forEach(v => {
    result[v.userID] = !v.isPhotoLocked && v.hasAtLeastOneCoachAssignmentForCompRequiringPhoto;
  })
  return result;
})

watch(() => [props.seasonUID, props.family], async () => {
  const userIDs : iltypes.Guid[] = props.family.parents.map(v => v.ID);
  const seasonUID = props.seasonUID;
  try {
    needsCoachPhotoInfo.value = await ilvolunteer.getWhoNeedsCoachPhotos(freshNoToastLoggedInAxiosInstance(), {userIDs, seasonUID});
  }
  catch (err) {
    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
  }
}, {immediate: true})

const coachPhotoUploadModalController = reactive(
  defaultCoachPhotoUploadModalController(
    axiosAuthBackgroundInstance,
    (userID) => {
      iziToast.success({message: "Photo uploaded"})
      // on success, simulate receiving an update from the backend that this user's photo status is now locked
      const localUpdateable = needsCoachPhotoInfo.value.find(_ => _.userID === userID);
      if (!localUpdateable) {
        // sanity check, but shouldn't happen
        return;
      }
      localUpdateable.isPhotoLocked = true;
    }
  ));

const registerVolunteers = async () => {
  await router.push({name: R_VolunteerRegistrationFlowDetail.FlowStepRouteName.user})
}

onMounted(() => {
  season.value = Client.getSeasonByUID(props.seasonUID)
})
</script>
