import type { ExtractPropTypes, PropType } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard } from "src/helpers/utils"
import authService from "src/helpers/authService";
import { User } from "src/store/User";
import { Role } from "src/interfaces/Store/user";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RoutePropsVariant>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "TeamEditor.main",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | RoutePropsVariant1

export const QueryParams = {
  competitionUID: "cuid",
  divID: "divID",
}

export interface RoutePropsVariant1 extends RoutePropsBase {
  routeName: typeof RouteNames.main,
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteNames.main:
      // fallthrough
      return {name: v.routeName}
    default: exhaustiveCaseGuard(v.routeName);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.main:
      return {
        detail: {
          routeName: name
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}

export function hasSomeRoutePermission() : boolean {
  const roles : Role[] = [
    "webmaster",
    "playerAdmin",
    "registrar",
    "DD",
    "director",
    "compManager"
  ]

  const hasSomeRole = authService(User.value.roles, ...roles);
  const isPlayerAdminForSomeCompetition = !!User.userData?.competitionsMemento.find(v => v.isPlayerAdmin)
  const isDivisionDirectorForSomeDivision = !!User.userData?.divisionsMemento.find(v => v.writeAccess)

  return hasSomeRole
    || isPlayerAdminForSomeCompetition
    || isDivisionDirectorForSomeDivision
}
