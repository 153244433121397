import { ComputedRef, ExtractPropTypes, Transition, computed, defineComponent, ref, watch } from "vue";
import { SelectMany, propsDef as selectManyPropsDef, emitsDef as selectManyEmitsDef } from "./SelectMany"
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside"
import { ExtractOnEmitsHandlers, TailwindBreakpoint, useWindowEventListener, useWindowSize, vOptT } from "src/helpers/utils";
import { Modal } from "../UserInterface/Modal";

const propsDef = { ...selectManyPropsDef, useModalOnSmallScreens: vOptT<boolean>(), modalTitle: vOptT<() => JSX.Element>() }
const emitsDef = { ...selectManyEmitsDef }

export type Props = ExtractPropTypes<typeof propsDef>
export type OnEmits = ExtractOnEmitsHandlers<typeof emitsDef>

export interface SlotProps {
  default: {
    readonly open: () => void,
    readonly close: () => void,
    readonly toggle: () => void,
    readonly isOpen: ComputedRef<boolean>
  }
}

export const SelectManyPane = defineComponent({
  props: propsDef,
  directives: {
    onClickOutside: vueDirective_ilOnClickOutside
  },
  emits: emitsDef,
  setup(props, {emit, slots}) {
    const isVisible = ref(false);
    const modalIsOpen = ref(false);

    const main : SlotProps["default"] = {
      open: () => { isVisible.value = true },
      close: () => { isVisible.value = false },
      toggle: () => { isVisible.value = !isVisible.value },
      // can we use a getter here? You can't destructure that, right (which probalby means no we don't want to use a getter)?
      isOpen: computed(() => isVisible.value)
    }

    watch(() => isVisible.value, () => {
      if (isVisible.value) {
        // On transition to visible, mark the modal open, too.
        // But, note that we want some animation on modal close; so when the modal closes,
        // modalIsOpen will be set to false _prior_ to isVisible being set to false
        // (where the delay between modalIsOpen=false and isVisible=false is the time it takes to animate the modal closing).
        modalIsOpen.value = true;
      }
    })

    useWindowEventListener("keyup", evt => {
      if (evt.key !== "Escape") {
        return;
      }

      if (useModal.value) {
        // modal is expected to have it's own esc keypress handler
        return;
      }

      main.close()
    })

    const windowSize = useWindowSize();

    const useModal = computed(() => {
      return props.useModalOnSmallScreens && windowSize.width < TailwindBreakpoint.sm
    })

    return () => {
      return (
        <div class="relative">
          <div>
            {slots.default?.(main)}
          </div>
          <Transition
            enter-active-class = "transition duration-100 ease-out"
            enter-from-class = "translate-y-1 opacity-0"
            enter-to-class = "translate-y-0 opacity-100"
            leave-active-class = "transition duration-100 ease-in"
            leave-from-class = "translate-y-0 opacity-100"
            leave-to-class = "translate-y-1 opacity-0"
          >
            {
              (() => {
                if (!isVisible.value) {
                  return null;
                }

                const TheMainThing = () => <SelectMany
                  {...props}
                  onCheckedAll={(bool) => emit("checkedAll", bool)}
                  onCheckedOne={(key, bool) => emit("checkedOne", key, bool)}
                />;

                if (useModal.value) {
                  return (
                    <Modal isOpen={modalIsOpen.value} onOnAfterLeave={() => main.close()} onClose={() => {modalIsOpen.value = false;}}>
                      {{
                        title: () => props.modalTitle?.(),
                        content: () => (
                          <div style="max-height: 75vh; overflow-y: auto;">
                            <TheMainThing/>
                          </div>
                        )
                      }}
                    </Modal>
                  )
                }
                else {
                  return (
                    <div
                      data-il-selectManyPane
                      v-onClickOutside={() => { main.close() }}
                      class="absolute bg-white overflow-auto z-10 top-full rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-2 max-h-96"
                      style="left:-1px; min-width:102%"
                    >
                      <TheMainThing/>
                    </div>
                  )
                }
              })()
            }
          </Transition>
        </div>
      )
    }
  }
})
