import { Directive } from "vue";

/**
 * HTML elements having ilOnClickOutside directives bound to them, mapped to functions
 * which dispose any listeners or other resources, for use in `unmounted`
 */
const __cleanup__vueDirective_ilOnClickOutside = new WeakMap<HTMLElement, () => void>()

export const vueDirective_ilOnClickOutside : Directive = {
  mounted: (root: HTMLElement, binding, vnode) => {
    const f = (event: MouseEvent) : void => {
      if (!event.target) {
        return;
      }

      const target = event.target as HTMLElement;

      if (!target.isConnected) {
        // not sure if this is always right;
        // sometimes it seems like child elements that would normally pass the "root.contains(target)" check
        // can get unmounted between the event firing and this handler running; in which case we fire the
        // callback erroneously. So the failure case we're trying to avoid here is:
        // - click on something that is contained inside the root
        // - updates run which unmount that element
        // - this event handler runs, and it appears that we clicked outside because the target is not contained in the root
        // When this happens, it seems like we get 2 events, one "coherent" with the elements in the states you'd expect (where
        // the contained item is definitely contained by the root) and then the subsequent junk event where the child element has
        // been disconnected.
        return;
      }

      if (!root.contains(target)) {
        if (typeof binding.value === "function") {
          binding.value();
        }
      }
    }

    // do this next tick, or we respond to the mouse click that probably triggered
    // mounting our current root. We might want an option to not perform this delay.
    setTimeout(() => {
      if (__cleanup__vueDirective_ilOnClickOutside.has(root)) {
        // already registered (somehow?) for this element,
        // we don't want to add additional listeners
        return;
      }
      if (!root.isConnected) {
        // If we became disconnected during the setTimeout wait, we're done
        return;
      }
      window.addEventListener("click", f, {capture: true})
      window.addEventListener("dragstart", f, {capture: true})
      __cleanup__vueDirective_ilOnClickOutside.set(root, () => {
        window.removeEventListener("click", f, {capture: true})
        window.removeEventListener("dragstart", f, {capture: true})
      })
    }, 0);
  },
  unmounted: (el) => {
    // we might not have registered it yet from the setTimeout,
    // if the element was mounted and unmounted very quickly.
    __cleanup__vueDirective_ilOnClickOutside.get(el)?.();
  }
}
