<template lang="pug">
//- <div>
div
  h1.mt-4.text-center Main Menu
  Links(background='bg-white')
</template>

<script lang='ts'>
import { RouteRecordRaw } from 'vue-router'
import routes from 'src/router/routes'
import authService from 'src/helpers/authService'
import { defineComponent, watch, computed, ref, Ref, onMounted } from 'vue'
import Links from 'src/components/Navigational/Links.vue'
import { UserData } from 'src/interfaces/Store/user'

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'mobile-landing',
  components: { Links },
  setup() {
    // Data

    const openMobile = ref(false)
    const userRoutes = ref([]) as Ref<RouteRecordRaw[]>

    const getRoles = computed(() => {
      return User.value.roles
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const isImpersonating = computed(() => {
      return (User.value.userData as UserData).isImpersonating
    })

    const clientTheme = computed(() => {
      return Client.value.clientTheme
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    // RouteConfig[]
    const createLinks = () => {
      const displayRoutes: RouteRecordRaw[] = []
      for (const key in routes) {
        //// console.log('makingRoutes', key, routes[key].meta?.auth, displayRoutes)
        const auth = routes[key]?.meta?.auth
        if (key === 'home') {
          // Needed because default '/' route must come last but home comes first
          displayRoutes.unshift(routes[key])
        } else if (isLoggedIn.value && routes[key].meta?.requiresNoAuth) {
          // Skip: Only logged out users should get this nav item
        } else if (!isLoggedIn.value && !routes[key].meta?.requiresAuth) {
          displayRoutes.push(routes[key])
        } else if (isLoggedIn.value && auth?.length === 0) {
          displayRoutes.push(routes[key])
        } else if (
          !auth || auth !== "DEFER-TO-BEFORE-ENTER" && authService(getRoles.value, ...auth)
        ) {
          displayRoutes.push(routes[key])
        }
      }
      return displayRoutes
    }
    userRoutes.value = createLinks()

    // onCreated

    watch(openMobile, (bool: boolean) => {
      System.directCommit_setOpenSidebar(bool)
    })

    // onLoginChanged
    watch([isLoggedIn, isImpersonating], () => {
      userRoutes.value = createLinks()
    })

    onMounted(() => {
      // console.log('mobileLanding.vue')
    })

    return {
      isMobile,
      openMobile,
      clientTheme,
      userRoutes,
      getRoles,
      isLoggedIn,
    }
  },
})
</script>
