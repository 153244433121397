<template lang="pug">.flex.flex-col.items-end.mb-5(class="md:flex-row")
.max-w-xl
  .flex.flex-col.items-end.mb-5(class="md:flex-row")
    h1.text-4xl.font-medium.mb-4
      font-awesome-icon.mx-4.mt-2.cursor-pointer(:icon='["fas", "child"]')
      | Registration Preview

  SeasonSelector(
    v-model="seasonUID",
  )

  CompetitionSelector(
    v-model="competitionUID"
  )

div.max-w-xl.FORCE_DEFAULT_BROWSER_ANCHOR_STYLE_IN_ALL_DESCENDANTS(v-if="seasonUID && competitionUID")
  h1.text-2xl.font-medium.mb-4.w-full.border-b-2.border-gray-400.pb-2.my-6 Registration Form
  RegistrationCore(
    :key="`${seasonUID}/${competitionUID}`"
    v-bind="coreProps"
  )
  RegistrationCustomQuestions(
    :key="`${seasonUID}/${competitionUID}`"
    :seasonUID="seasonUID"
    :competitionUIDs="[competitionUID]"
    :registrationPreview="true"
  )
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import SeasonSelector from 'src/components/Selectors/Seasons'
import CompetitionSelector from 'src/components/Selectors/Competitions'
import RegistrationCustomQuestions from 'src/components/Registration/registrationForm/CustomQuestions'
import RegistrationCore from 'src/components/Registration/registrationForm/core.vue'
import * as M_RegistrationCore from 'src/components/Registration/registrationForm/core.ilx'
import { Client } from 'src/store/Client'
import { Guid } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  name: 'Registration Preview',
  components: {
    SeasonSelector,
    CompetitionSelector,
    RegistrationCustomQuestions,
    RegistrationCore
  },
  setup() {
    // try to initialize to sane value
    const seasonUID = ref<Guid | "">(Client.value.instanceConfig.currentseasonuid)
    // nothing to default initialize to
    const competitionUID = ref<Guid | "">("")

    const coreProps = computed<M_RegistrationCore.Props>(() => {
      return {
        variant: {
          // Unchanging type and playerID for "preview mode".
          // The all-zero playerID is (was at one time?) significant for the backend.
          type: "child-season-centric-preview",
          playerID: "00000000-0000-0000-0000-000000000000",
          // seasonUID tracks form input
          seasonUID: seasonUID.value
        }
      };
    })

    return {
      seasonUID,
      competitionUID,
      coreProps
    }
  },
})
</script>
