<template lang="pug">
DeletePageItem(:item="item" v-if="deleteItem" @cancel="toggleDelete" @close="updateItems")
div.max-w-4xl
  div.w-full.items-center.rounded-md.p-2.mt-4
    div
      h1.font-bold(:class="[item.isDisabled ? 'text-gray-400': 'text-gray-600']" v-html="item.pageItem.label ? item.pageItem.label : '(No label provided)'")
      div.ml-3.italic.font-normal.text-xl(v-if="item.isDisabled") (Disabled)
      div.text-green-800.mt-2.cursor-pointer(@click='editItem(item)')
        div.flex.items-center.justify-start
          font-awesome-icon(:icon='["fas", "pencil-alt"]')
          span.ml-2.flex.items-center.justify-center Edit

      div.font-semibold.my-1 Type:
        span.font-normal.ml-2 {{ type }}
      div.font-semibold.my-1 Display Restrictions:
        span.font-normal.ml-2 {{ seasonNames.length > 0 ? seasonNames.join(', ') : 'All Seasons'}},
        span.font-normal.ml-2 {{ competitionNames.length > 0 ? competitionNames.join(', ') : 'All Competitions' }}
      div.font-semibold.my-1 Gate Functions:
        span.font-normal.ml-2.italic(v-if="item.pageItem.gateFunctionName") {{ ` - "${builtInFunctions[item.pageItem.gateFunctionName]}"` }}
        span.font-normal.ml-2.italic(:class='{"block ml-32": item.pageItem.gateFunction && item.pageItem.gateFunctionName}' v-if="item.pageItem.gateFunction") {{`- ${item.pageItem.gateFunction?.customField?.name }`}} {{item.pageItem.gateFunction ? item.pageItem.gateFunction?.matchType===1 ? 'equals':'does not equal': ''}} {{ getTargetValue(item.pageItem.gateFunction?.targetValue)}}
        span.font-normal.ml-2.italic(v-if="!item.pageItem.gateFunctionName && !item.pageItem.gateFunction") - No Gate Function Selected
      div.flex.items-center.my-1
        div.text-red-600.cursor-pointer(@click='toggleDelete(item)' color='white')
          font-awesome-icon(:icon='["fas", "trash-alt"]')
          span.font-extrabold.ml-2 Delete
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted, computed } from 'vue'

import { useRouter } from 'vue-router'
import DeletePageItem from 'src/components/Registration/admin/pageItem/DeletePageItem.vue'
import { PageItemContainer } from 'src/interfaces/Store/registration'
import { Client } from 'src/store/Client'
import { RegistrationStore } from "src/store/Registration"

export default defineComponent({
  components: {
    DeletePageItem
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['refreshItems'],
  setup(props, {emit}) {

    const val = ref('')
    const seasonNames = ref([]) as Ref<any[]>
    const options = ref({})
    const deleteItem = ref(false)
    const router = useRouter()
    const competitionNames = ref([]) as Ref<string[]>

    const type = computed(()=> {
      switch(props.item.pageItem.type) {
        case 1:
          return 'Text Input'
        case 2:
          return 'Radio Buttons'
        case 3:
          return 'Select (Drop-down)'
        case 4:
          return 'Checkbox'
      }
      return 'Text/HTML'
    })

    const builtInFunctions = computed(()=> {
      return RegistrationStore.value.gateFunctionsOptionFormat
    })

    const getTargetValue = (val: string) => {
      if(props.item.pageItem.gateFunction.customField.inputType===4) {
        return val == '1' ? 'Yes' : 'No'
      } else {
        return val
      }
    }

    const getSeasonNames = async () => {
      const seasonNames_fresh: string[] = []
      for(let i = 0; i < props.item.itemSeasons.length; i++) {
        const seasonUID = props.item.itemSeasons[i].seasonUID;
        const season = await Client.getSeasonByUID(seasonUID);
        seasonNames_fresh.push(season?.seasonName ?? seasonUID)
      }
      seasonNames.value = seasonNames_fresh
    }

    const getCompetitionNames = async () => {
      const compsNames: string[] = []
      for(let i = 0; i<props.item.itemCompetitions.length; i++) {
        const competitionUID = props.item.itemCompetitions[i].competitionUID;
        const comp = await Client.getCompetitionByUID(competitionUID)
        compsNames.push(comp?.competition ?? competitionUID)
      }
      competitionNames.value=compsNames
    }

    const editItem = async (item: PageItemContainer) => {
      RegistrationStore.directCommit_setPageItem(item)
      if(props.item.pageItemType==='Question') {
        await router.push({name: 'edit-question', params: { id: props.item.id }})
      } else {
        await router.push({name: 'edit-content-chunk', params:{ id: props.item.id }})
      }
    }

    const setBuiltInFunctions = async () => {
      const builtInFunctions = await RegistrationStore.getGateFunctions()
      RegistrationStore.directCommit_setGateFunctions(builtInFunctions)
      await RegistrationStore.createGateFunctionsOptionFormat(builtInFunctions)
    }


    const toggleDelete = () => {
      // console.log('deleteItem - Yes!!')
      deleteItem.value=!deleteItem.value
    }

    const updateItems = () => {
      // console.log('refresh items')
      deleteItem.value=false
      emit('refreshItems')
    }

    onMounted(async () => {
      await setBuiltInFunctions()
      await getSeasonNames()
      await getCompetitionNames()
    })


    return {
      getSeasonNames,
      getCompetitionNames,
      editItem,
      updateItems,
      toggleDelete,
      deleteItem,
      val,
      seasonNames,
      competitionNames,
      options,
      type,
      builtInFunctions,
      getTargetValue
    }
  },
})
</script>

