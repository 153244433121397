<template lang="pug">
t-dialog(v-if='show')
  h1 Invoice in Process Found
  p A portion of what you have selected is already on an invoice in process.
  .flex.justify-center
    t-btn(@click='voidInvoices', data-cy='closePopup')
      .flex.flex-col.w-full
        .w-full Void Invoice
        .w-full And Continue
    t-btn(@click='reviewInvoices', data-cy='reviewInvoices')
      .flex.flex-col.w-full
        .w-full Review Invoice
        .w-full In Process
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'


import * as iltypes from "src/interfaces/InleagueApiV1"

import { CheckoutStore } from 'src/store/CheckoutStore'

export default defineComponent({
  name: 'VoidInvoiceModal',
  props: {
    /**
     * More specifically "invoiceInstanceID"
     */
    invoice: {
      required: true,
      type: [String,Number] as PropType<iltypes.Integerlike>
    }
  },
  setup(props, { emit }) {
    const show = ref(true)


    const route = useRoute()
    const router = useRouter()

    // Methods
    const voidInvoices = async () => {
      await CheckoutStore.voidInvoice(props.invoice.toString())
      // TODO: reload signups once invoice is voided
      // FIXME/WARN: "getRegistrants" no longer unconditionally reloads relevant flowstate; do we need to force clear it now?
      await CheckoutStore.getRegistrants(
        route.query.eventID as string
      )
      // console.log('after check get regs', store.state.checkout.registrants)
      emit('popupClosed')
      show.value = false
    }

    const reviewInvoices = async () => {
      // console.log('reviewInvoices')
      await router.push({
        name: 'master-invoice',
        params: { invoiceID: props.invoice as number },
        query: { eventID: route.query.eventID },
      })
    }

    return {
      show,
      voidInvoices,
      reviewInvoices,
    }
  },
})
</script>
