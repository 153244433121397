import { ExtractOnEmitsHandlers } from "src/helpers/utils";
import { ExtractPropTypes, PropType, defineComponent } from "vue";

/**
 * todo: clarify what it means for things to be not required,
 * do we get auto-defaults of some kind?
 */
export const propsDef = {
  icon: {
    required: false,
    type: null as any as PropType<string[] | string>
  },
  prefix: {
    required: false,
    type: String,
  },
  label: {
    required: false,
    type: String,
  }
} as const;

export const emitsDef = {
  expand: (_freshValue: boolean) => true
} as const;

export type Props = ExtractPropTypes<typeof propsDef>
export type OnEmits = ExtractOnEmitsHandlers<typeof emitsDef>


const NOEXPORT_jsx_sfc_shim__Portlet = defineComponent({
  props: propsDef,
  emits: emitsDef
})
export type jsx_sfc_shim__Portlet = typeof NOEXPORT_jsx_sfc_shim__Portlet;
