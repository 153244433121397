/**
 * all these are candidates for Pinia after we enable Pinia
 */

import { computed, reactive, Ref, ref } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import { AxiosInstance } from "axios";
import { exhaustiveCaseGuard, VueNeverUnwrappable, DeepConst } from "src/helpers/utils";
import { RouteLocationNormalized } from "vue-router";
import { LeagueDomainDetails } from "src/composables/InLeagueApiV1.Authenticate.Common";
import * as Modal from "components/UserInterface/Modal"
import * as GlobalModal_UpdateUserInfo from "src/components/User/GlobalModal.UpdateUserInfo"
import * as ilinvoice from "src/composables/InleagueApiV1.Invoice"
import { getLogger, maybeLog } from "src/modules/LoggerService";
import { LoggedinLogWriter } from "src/modules/Loggers";
import { ReifiedPromise, ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { User } from "src/store/User"

import * as ClearOnLogout from "./ClearOnLogout"

export const events = (() => {
  // eventID -> Event
  const eventMap : {[eventID: iltypes.Guid]: ilapi.event.GetEventResult} = reactive({});

  const put = (event: ilapi.event.GetEventResult) : void => {
    eventMap[event.eventID] = event;
  }

  const remove = (eventID: iltypes.Guid) : void => {
    delete eventMap[eventID];
  }

  const get = async(axios: AxiosInstance, eventID: iltypes.Guid, opts?: {includeCanceledSignups?: boolean, expand?: ilapi.event.EventExpandable[]}) : Promise<ilapi.event.GetEventResult> => {
    const v = await ilapi.event.getEvent(axios, eventID, opts);

    // there were some effort at caching but it's more prone to coherency issues than it does save perf
    eventMap[v.eventID] = v

    return v;
  }

  const clear = () => {
    const keys = Object.keys(eventMap);
    for (const key of keys) {
      delete eventMap[key];
    }
  }

  return {
    events: eventMap,
    put,
    remove,
    get,
    clear
  } as const;
})();

ClearOnLogout.register(events);

export interface GlobalTopLevelModalState {
  props: Modal.Props,
  emitsHandlers: Modal.Emits,
  slots: Modal.Slots
}

export const globalTopLevelModalState = (() => {
  /**
   * default constructor for a "nil" modal state, that is always closed.
   * If there is old state, we can repurpose it so that the close animation is smoother and doesn't exhibit a jarring "slot content deleted" behavior
   */
  function freshState(oldState?: GlobalTopLevelModalState) : GlobalTopLevelModalState {
    return {
      props: {
        isOpen: false,
        closeOnClickOutsideOrPressEscape: oldState?.props.closeOnClickOutsideOrPressEscape ?? true,
        withXButton: oldState?.props.withXButton ?? true,
      },
      emitsHandlers: {
        close: () : void => void 0,
        onAfterLeave: () : void => void 0
      },
      slots: oldState?.slots ?? {}
    }
  }

  const state = ref(freshState())

  const reset = () => {state.value = freshState()}

  return {
    reset,
    get state() : Readonly<GlobalTopLevelModalState> {
      return state.value;
    },
    set state(v: GlobalTopLevelModalState) {
      state.value = v;
    },
    clear: () => {
      reset()
    }
  }
})()

ClearOnLogout.register(globalTopLevelModalState);

export const accountSetupBlockadeState = (() => {
  const requiresCollectGender = ref(false);

  const enterGlobalCollectGenderState = (userID: iltypes.Guid) => {
    requiresCollectGender.value = true;
    globalTopLevelModalState.state = GlobalModal_UpdateUserInfo.freshGlobalTopLevelModalToUpdateUserInfoState({userID, onComplete: () => completeGlobalCollectGenderState()});
  }

  /**
   * flow is complete, tear down the modal, reset any pertinent flags
   */
  const completeGlobalCollectGenderState = () => {
    requiresCollectGender.value = false;
    globalTopLevelModalState.reset();
  }

  /**
   * Basically the same as "completeGlobalCollectGenderState", but only incidentally.
   */
  const clear = () => {
    requiresCollectGender.value = false;
    globalTopLevelModalState.reset();
  }

  return {
    get requiresCollectGender() : boolean { return requiresCollectGender.value },
    enterGlobalCollectGenderState,
    completeGlobalCollectGenderState,
    clear
  }
})();

ClearOnLogout.register(accountSetupBlockadeState)

export const PayableInvoicesResolver = (() => {
  // Need to make vue aware of these requests, they're performed outside of its lifecycle so
  // errors don't bubble to the vue root, and if we want to log them, we're responsible for doing so.
  // we could push throwable errors into a queue vue is aware of, and when we get one, drain the queue by just throwing each thing in it?...
  const errorHandler = ({error}: {error: any}) => {
    if (User.isLoggedIn) {
      maybeLog(getLogger(LoggedinLogWriter), "warning", "PayableInvoicesResolver", error);
    }
  }

  const payableInvoices = ReactiveReifiedPromise<ilinvoice.Invoice[]>(undefined, {onError: errorHandler});

  const getOnce = (axios: AxiosInstance) : ReifiedPromise<DeepConst<ilinvoice.Invoice[]>> => {
    if (payableInvoices.underlying.status === "idle") {
      return payableInvoices.run(() => ilinvoice.getMyOutstandingPayableInvoices(axios)).underlying
    }
    else {
      return payableInvoices.underlying;
    }
  }

  const removeInvoice = (instanceID: iltypes.Integerlike) : void => {
    if (payableInvoices.underlying.status === "resolved") {
      payableInvoices.forceResolve(
        payableInvoices
          .underlying
          .data
          .filter(invoice => invoice.instanceID /*not strict*/!= instanceID)
      )
    }
    else {
      // nothing we can do here
    }
  }

  const refresh = (axios: AxiosInstance) : ReifiedPromise<DeepConst<ilinvoice.Invoice[]>> => {
    return payableInvoices
      .run(() => ilinvoice.getMyOutstandingPayableInvoices(axios))
      .underlying;
  }

  const clear = () : void => {
    payableInvoices.reset();
  }

  return {
    getOnce,
    refresh,
    removeInvoice,
    clear
  }
})()

ClearOnLogout.register(PayableInvoicesResolver);

/**
 * This should eventually replace the global "system" store's "loading" value, which is a boolean,
 * and which doesn't work properly when there are multiple requests in flight
 *
 * Can never be less than zero.
 * When non-zero, the entire page should indicate that it is busy, and an overlay should prevent all user interaction.
 */
export const GlobalInteractionBlockingRequestsInFlight = (() => {
  const count = ref(0)
  const customGlobalSpinner = ref<(() => JSX.Element) | null>(null);
  return {
    get count() : number { return count.value },
    get customGlobalSpinner() {
      return customGlobalSpinner.value;
    },
    increment() : void { count.value += 1 },
    decrement() : void {
      if (count.value === 0) {
        throw Error("Cannot set requests in flight to less than zero");
      }
      count.value -= 1;
    },
    /**
     * Run some callback, bumping the global counter before running, and decrementing it after the callback completes or throws.
     * The decrement can be delayed by `delayDec_ms` if it makes sense to do so, to help keep the counter above zero at all times
     * in cases of multiple successive requests where there might be very short delays between each request.
     */
    async withSpinner<T>(f: () => T | Promise<T>, delayDec_ms: number = 0, element?: () => JSX.Element) : Promise<T> {
      try {
        customGlobalSpinner.value = element ?? null // doesn't nest! we need a stack of these; but we don't use it like that right now
        this.increment();
        return await f();
      }
      finally {
        if (delayDec_ms === 0) {
          this.decrement();
          customGlobalSpinner.value = null
        }
        else {
          setTimeout(() => {
            this.decrement()
            customGlobalSpinner.value = null
          }, delayDec_ms)
        }
      }
    }
  }
})()
