import C_AdminVolunteers from 'src/components/FamilyProfile/AdminVolunteers.vue'
import C_AdminPlayers from 'src/components/FamilyProfile/AdminPlayers.vue'
import { defineComponent } from 'vue'
import { vReqT } from 'src/helpers/utils'
import { Guid } from 'src/interfaces/InleagueApiV1'

import type { jsx_sfc_shim__AdminVolunteers } from "./AdminVolunteers.ilx"
import type { jsx_sfc_shim__AdminPlayers } from "./AdminPlayers.ilx"

declare const AdminVolunteers : jsx_sfc_shim__AdminVolunteers
declare const AdminPlayers : jsx_sfc_shim__AdminPlayers

export const Admin = defineComponent({
  components: {
    AdminVolunteers: C_AdminVolunteers,
    AdminPlayers: C_AdminPlayers,
  },
  props: {
    familyIDs: vReqT<Guid[]>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="mb-8">
          {
            props.familyIDs.map(familyID => {
              return (
                <>
                  <AdminVolunteers familyID={familyID}/>
                  <AdminPlayers familyID={familyID}/>
                </>
              )
            })
          }
        </div>
      )
    }
  }
})
