import { ExtractEmitsHandlers, parseFloatOr } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"

import { DeleteDetails } from "src/interfaces/Store/events";

export const propsDef = {
  eventID: {
    required: true,
    type: String,
  },
  eventSignupIDs: {
    required: true,
    type: Array as PropType<iltypes.Guid[]>
  },
} as const;

export const emitsDef = {
  updateTotal: (delta: number) => true,
  deleteItem: (args: DeleteDetails) => true
} as const;

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export function FeeBeforeAndAfterVisibleDiscounts(props: {eventSignup: iltypes.WithDefinite<ilapi.event.EventSignup, "computed_feeAfterVisibleDiscounts">}) {
  const baseFee = props.eventSignup.fee;
  if (!baseFee) {
    return <div>FREE</div>
  }

  const realFee = props.eventSignup.computed_feeAfterVisibleDiscounts;

  const delta = Math.abs(realFee - baseFee);
  if (delta < 0.01) {
    return <div>${baseFee.toFixed(2)}</div>
  }

  return (
    <div>
      <div>
        <div class="text-xs underline">Base fee:</div>
        <div class="text-right">${baseFee.toFixed(2)}</div>
      </div>
      <div>
        <div class="text-xs underline">After discovered discounts:</div>
        <div class="text-right">{realFee ? `$${realFee.toFixed(2)}` : "FREE"}</div>
      </div>
    </div>
  )
}
