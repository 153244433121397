<template lang="pug">
div(data-cy='messageThreadPage', class='md:mx-1/6', v-if='loaded')
  .grid.grid-cols-10.grid-gap-2(class='md:grid-cols-12 md:mr-16 md:auto-cols-min')
    font-awesome-icon.col-span-1.text-xl.font-medium.text-gray-700(
      :icon='["fas", "envelope-open-text"]',
      class='md:mb-2'
    )
    h1.col-span-9.text-xl.font-medium.text-gray-700(class='md:col-span-11 md:mb-2') {{ messageThread.topic ? messageThread.topic : "Message Thread" }}
    font-awesome-icon.col-span-1.text-xl(:icon='["fas", "users"]')
    p.col-span-9.text-sm.italic {{ messageThread.extendedInfo.recipientNames.join(", ") }}
  router-link(:to='{ name: "messages" }')
    p.mt-2.text-lg.semi-bold(data-cy='backArrow', class='md:mt-2')
      font-awesome-icon(:icon='["fas", "arrow-left"]')
      span.ml-2 All Messages
  .flex.flex-col.justify-between.items-between(class="h-5/6")
    .flex.flex-col.w-full.overflow-y-auto.scrolling-auto.mb-20(:class="{'mb-32': isIos}")
      .scrolling-auto(ref='msgs' id="msgs")
        div(v-for='(message, idx) in messages')
          .createdAt(v-if='displayDate(idx)')
            | {{ formatDateWithoutTime(message.created_at) }}
          .flex.mt-2.justify-end(v-if='message.authorID === userID')
            .bg-green-400.p-2.text-white.text-sm(class='w-2/3')
              .flex.justify-between
                p.text-gray-700.text-sm.font-medium Me
                p.text-gray-700.text-sm.font-medium {{ formatTime(message.created_at) }}
              | {{ message.message }}
            .user
          .flex.mt-2.justify-start(v-if='message.authorID != userID')
            .other
            .bg-green-400.p-2.text-white(class='w-2/3')
              .flex.justify-between.text-sm
                p.text-gray-700.text-sm.font-medium {{ message.authorName }}
                p.text-gray-700.text-sm.font-medium {{ formatTime(message.created_at) }}
                p
              | {{ message.message }}
    .h-2.scroll(ref='end')
    .bg-gray-700.w-full.fixed.left-0.bottom-0(:class="{'bottom-10' : isIos }")
      .p-3.text-gray-600.flex.flex-col.items-center(class='sm:px-10 md:ml-64')
        textarea.w-full.m-2.text-sm.overflow-hidden.align-center.p-1.rounded-sm.resize-y(
          v-model='newMessage',
          placeholder='Type a message',
          @keydown.enter.exact.prevent,
          @keyup.enter.shift.exact='newMessage += "/n"',
          @keyup.enter.exact='sendMessage',
          data-cy='messageTextArea'
        )
        TBtn.w-full(@click='sendMessage', label='Send')
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { MessageThreadMessage } from 'src/interfaces/messages'
import {
  formatDateWithoutTime,
  formatTime,
  sameDay,
} from 'src/helpers/formatDate'
import { defineComponent, ref, Ref, onMounted, computed, watch } from 'vue'


import { useRoute } from 'vue-router'
import { System } from 'src/store/System'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'MessageThread',
  setup() {
    const $route = useRoute()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const messageThread: any = ref({})
    const messages = ref([]) as Ref<MessageThreadMessage[]>
    const newMessage = ref('')
    const end = ref(null) as Ref<HTMLElement> | Ref<null>
    const loaded = ref(false)
    const msgs = ref(null)

    const isIos = computed(() => {
      return System.value.devicePlatform === "ios"
    })

    const scroll = () => {
      if(end.value) {
        end.value.scrollIntoView({ behavior: "smooth" })
      }
    }

    const getMessages = async () => {
      const response = await axiosInstance.get(
        `v1/teamMessage/conversation/${$route.params.id as string}`
      )
      messages.value = response.data.data.messages
      messageThread.value = response.data.data.conversation
    }

    const displayDate = (idx: number) => {
      if (idx === 0) {
        return true
      } else if (
        !sameDay(
          messages.value[idx - 1].created_at,
          messages.value[idx].created_at
        )
      ) {
        return true
      } else {
        return false
      }
    }

    const sendMessage = async () => {
      const options = {
        message: newMessage.value,
        isPrivate: false,
      }
      await axiosInstance.post(
        `v1/teamMessage/conversation/${$route.params.id as string}`,
        options
      )
      await getMessages()
      scroll()
      newMessage.value = ''
    }

    const userID = computed(() => {
      return User.value.userID
    })

    watch(end, (element: HTMLElement | null) => {
      scroll()
    }, {flush: 'post'})

    onMounted(async () => {
      await getMessages()
      loaded.value = true
    })

    return {
      loaded,
      messageThread,
      messages,
      newMessage,
      displayDate,
      sendMessage,
      userID,
      formatDateWithoutTime,
      formatTime,
      end,
      msgs,
      isIos
    }
  },
})
</script>

<style scoped>
.user {
  width: 0;
  height: 0;
  border-top: 15px solid var(--four);
  border-right: 15px solid transparent;
}

.other {
  width: 0;
  height: 0;
  border-top: 15px solid var(--four);
  border-left: 15px solid transparent;
}

.createdAt {
  width: 70%;
  margin: 0.7em auto;
  overflow: hidden;
  text-align: center;
  font-weight: 300;
  color: black;
}

.createdAt:before,
.createdAt:after {
  content: '';
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}

.createdAt:after {
  margin: 0 -55% 0 0.5em;
}

.scroll {
  scroll-margin-bottom: 15em;
}
</style>
