<template lang="pug">
div(data-cy='messageList')
  .flex.justify-between.items-center
    h1.text-2xl.font-medium.text-gray-700(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "comments"]')
      | My Messages
    router-link.hidden(:to='{ name: "new-message" }', class='md:flex')
      h1.text-2xl.font-medium.text-gray-700.h-full.items-center.flex
        h2.text-green-300.text-4xl
          font-awesome-icon.bg-white.text-green-300(
            :icon='["fas", "plus-circle"]',
            data-cy='newMessageLink'
          )
        span.ml-2 New Message
  .my-2.z-40.flex.flex-col.items-center(class='md:flex-row md:justify-between')
    .mt-1.relative.shadow.border-gray-.w-full
      .absolute.inset-y-0.left-0.pl-3.flex.items-center
        div(v-show='isSearch')
          font-awesome-icon.pointer.text-gray-500(
            :icon='["fas", "arrow-left"]',
            data-cy='backArrow',
            @click='clearSearchResults'
          )
        div(v-show='!isSearch')
          font-awesome-icon.text-gray-500(:icon='["fas", "search"]')
      input#email.form-input.rounded-md.block.w-full.pl-10(
        class='sm:text-sm sm:leading-5',
        placeholder='Search Messages',
        v-model='searchParam',
        @keyup.enter.prevent='search',
        data-cy='searchMessages',
        @focus='searchHasFocus = true',
        @blur='searchHasFocus = false'
      )
    TBtn.w-full.mt-2(class='md:w-20 md:ml-2', @click='search', label='Search')
  .bg-white.shadow.overflow-hidden(
    class='sm:rounded-md',
    v-if='isSearch ? searchResults.length : messages.length'
  )
    ul(v-if='isSearch ? searchResults.length : messages.length')
      li.border-t.border-gray-200.bg-gray-200.uppercase.hidden(class='md:flex')
        .px-4.py-4.w-full(class='sm:px-6')
          .mt-2(class='sm:flex sm:justify-between')
            .w-full(class='md:flex md:justify-between')
              .mr-6.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10.mt-2(
                class='md:w-1/3 md:mr-0 md:flex'
              )
                font-awesome-icon.col-span-1(:icon='["fas", "users"]')
                .col-span-9.text-start(class='md:ml-2')
                  | Recipients
              .mt-2.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10(
                class='md:w-1/3 md:mr-0 md:flex'
              )
                font-awesome-icon.col-span-1(
                  :icon='["fas", "envelope-open-text"]'
                )
                .col-span-9.text-start(class='md:ml-2') Messages
              .mt-2.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10(
                class='md:w-1/3 md:mr-0 md:flex'
              )
                font-awesome-icon.col-span-1(:icon='["fas", "calendar-alt"]')
                .col-span-9.text-start(class='md:ml-2')
                  | Date
      li.border-t.border-gray-200(
        v-for='message in isSearch ? searchResults : messages'
      )
        router-link.block.transition.duration-150.ease-in-out(
          class='hover:bg-gray-50 focus:outline-none focus:bg-gray-50',
          :to='{ name: "message-thread", params: { id: message.conversationID } }',
          data-cy='teamMessage'
        )
          .px-4.py-4(class='sm:px-6')
            .flex.items-center.justify-between
              .text-sm.leading-5.font-medium.text-green-600.truncate
                | {{ message.latestMessage.author }}
            .mt-2(class='sm:flex sm:justify-between')
              div(class='md:flex md:justify-between md:w-full')
                .flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10.mt-2(
                  class='md:w-1/4 md:mr-0 md:hidden'
                )
                  font-awesome-icon.col-span-1.col-span-1(
                    :icon='["fas", "file-alt"]'
                  )
                  .flex.justify-between.w-full.col-span-9
                    .text-gray-700.font-semibold {{ message.topic }}
                    .ml-2.flex-shrink-0.flex(class='md:hidden')
                      .px-2.inline-flex.text-xs.leading-5.font-semibold.rounded-full.bg-green-100.text-green-800 {{ message.extendedInfo.convLength }}
                .mr-6.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10.mt-2(
                  class='md:w-1/3 md:mr-0'
                )
                  font-awesome-icon.col-span-1(
                    :icon='["fas", "users"]',
                    class='md:hidden'
                  )
                  .col-span-9.ml-1
                    | {{ message.extendedInfo.recipientNames.slice(0, 3).join(', ') }}{{ message.extendedInfo.recipientNames.length > 3 ? '...' : '' }}
                .mt-2.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10(
                  class='md:w-1/3 md:mr-0'
                )
                  font-awesome-icon.col-span-1(
                    :icon='["fas", "envelope-open-text"]',
                    class='md:hidden'
                  )
                  .col-span-9.text-gray-700.font-semibold.hidden(class='md:flex') {{ message.topic }}
                  .col-span-9(class='md:italic') {{ message.latestMessage.message.slice(0, 30) }}{{ message.latestMessage.message.length > 30 ? '...' : '' }}
                .mt-2.flex.items-center.text-sm.leading-5.text-gray-500.grid.grid-cols-10(
                  class='md:w-1/3 md:mr-0'
                )
                  font-awesome-icon.col-span-1(
                    :icon='["fas", "calendar-alt"]',
                    class='md:hidden'
                  )
                  .col-span-9.flex.justify-between
                    div {{ formatDate(message.updated_at) }}
                    font-awesome-icon.col-span-1(
                      :icon='["fas", "angle-double-right"]',
                      class='md:hidden'
                    )
                .ml-2.flex-shrink-0.flex.hidden(class='md:flex')
                  .px-2.inline-flex.text-xs.leading-5.font-semibold.rounded-full.bg-green-100.text-green-800(
                    class='h-1/2 md:h-1/3'
                  )
                    | {{ message.extendedInfo.convLength }}
  .mt-2.-text-lg(v-else-if='!isLoading')
    | {{ isSearch && searchedValue ? `There are not any results for ${searchedValue}` : 'You have no messages.' }}
  router-link(
    :to='{ name: "new-message" }',
    class='md:hidden',
    :class='{ hidden: searchHasFocus }'
  )
    .fixed.right-6.inline-block.text-5xl.text-green-300(
      :class='[isIos ? "bottom-12" : "bottom-6"]'
    )
      font-awesome-icon.bg-white(
        :icon='["fas", "plus-circle"]',
        data-cy='newMessageLink'
      )
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance } from 'boot/axios'
import { defineComponent, ref, Ref, onMounted, computed } from 'vue'
import { formatDate } from 'src/helpers/formatDate'
import { ConversationInterface } from 'src/interfaces/messages'

import { System } from 'src/store/System'
export default defineComponent({
  name: 'MessageList',
  setup() {
    const messages = ref([]) as Ref<ConversationInterface[]>
    const searchParam = ref('')
    const isSearch = ref(false)
    const searchResults = ref([]) as Ref<any[]>
    const clicked = ref('')
    const isLoading = ref(false) as Ref<boolean>
    const searchedValue = ref('')

    const searchHasFocus = ref(false)

    const isIos = computed(() => {
      return System.value.devicePlatform === 'ios'
    })

    const sortMessages = (msgs: ConversationInterface[]) => {
      // console.log('sortMessages')
      return msgs.sort((msg1, msg2) => {
        const date1 = new Date(msg1.updated_at)
        const date2 = new Date(msg2.updated_at)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (date2 as any) - (date1 as any)
      })
    }

    const getMessagesFromAPI = async () => {
      isLoading.value = true
      try {
        // https://inleague.localtest.me/api/v1/teamMessage/conversation
        const response = await axiosInstance.get('v1/teamMessage/conversation')
        // console.log('messages response', response.data.data)
        messages.value = sortMessages(response.data.data)
        isLoading.value = false
      } catch (err) {
        // console.error(err)
      }
    }

    const search = () => {
      isLoading.value = true
      isSearch.value = true
      const srchRslts: ConversationInterface[] = []
      // console.log('in search')
      for (let i = 0; i < messages.value.length; i++) {
        const result = JSON.stringify(messages.value[i]).search(
          new RegExp(searchParam.value, 'i')
        )
        // console.log('result', result)
        if (result !== -1) {
          srchRslts.push(messages.value[i])
        }
      }
      // console.log('srchRslts', srchRslts)
      searchResults.value = srchRslts
      searchedValue.value = searchParam.value
      isLoading.value = false
    }

    const clearSearchResults = () => {
      // console.log('cleared search results')
      isSearch.value = false
      searchParam.value = ''
      searchResults.value = []
    }

    onMounted(async () => {
      await getMessagesFromAPI()
    })

    return {
      formatDate,
      messages,
      searchParam,
      isSearch,
      searchResults,
      clicked,
      getMessagesFromAPI,
      sortMessages,
      search,
      clearSearchResults,
      isLoading,
      searchedValue,
      isIos,
      searchHasFocus,
    }
  },
})
</script>
