import { rpc } from "src/composables/Rpc";
import { exhaustiveCaseGuard, NoUncheckedIndexedAccess } from "src/helpers/utils";
import { DateTimelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1";

export interface ClientRating {
  type: "numeric" | "text"
  ratingID: Guid,
  enteredOn: DateTimelike,
  ratingText: string,
  // always empty in the type="numeric" case
  textItems: (ClientRatingItem | NoUncheckedIndexedAccess)[],
  // empty string for form purposes
  rangeHigh: "" | Integerlike,
  // empty string for form purposes
  rangeLow: "" | Integerlike,
  overallComponent: boolean,
}

export interface ClientRatingItem {
  itemID: Guid,
  ratingID: Guid,
  text: string,
}

export const listClientRatings = rpc<void, {core: ClientRating, custom: ClientRating[], hasSomeExistingCoreRating: boolean}>("get", "v1/clientRatingsConfigurator/list")
export const crudClientRatings = rpc<{
  rangeLow1: Integerlike,
  rangeHigh1: Integerlike,
  2?: ClientRatingCrudRequest,
  3?: ClientRatingCrudRequest,
  4?: ClientRatingCrudRequest,
  5?: ClientRatingCrudRequest,
  6?: ClientRatingCrudRequest,
  7?: ClientRatingCrudRequest,
  8?: ClientRatingCrudRequest,
  9?: ClientRatingCrudRequest,
  10?: ClientRatingCrudRequest,
}, void>("post", "v1/clientRatingsConfigurator/updateClientRatings");


export type ClientRatingCrudNumericRatingRequest = {
  type: "numeric"
  ratingID: Guid | undefined,
  ratingText: string,
  rangeLow: "" | Integerlike,
  rangeHigh: "" | Integerlike,
  overallComponent: boolean,
}

export type ClientRatingCrudTextRatingRequest = {
  type: "text",
  ratingID: Guid | undefined,
  ratingText: string,
  textOpt_1: undefined | string,
  textOpt_2: undefined | string,
  textOpt_3: undefined | string,
  textOpt_4: undefined | string,
  textOpt_5: undefined | string,
  textOpt_6: undefined | string,
  textOpt_7: undefined | string,
  textOpt_8: undefined | string,
}

export type ClientRatingCrudRequest = ClientRatingCrudNumericRatingRequest | ClientRatingCrudTextRatingRequest
