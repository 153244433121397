<template lang="pug">
h1.ml-2.text-2xl.font-medium(class='md:text-4xl') Volunteer Requirements
.mb-4 {{ minimumRequirementMsg }}

FormKit(
  :actions="false"
  type='form',
)
  SelectRolesImpl(
    @update='handleVolunteerRoleSelectionUpdate',
    :volunteerID='userID',
    :seasonUID='seasonUID',
    :volunteerDetails='volunteerDetails'
  )
  FormKit(
    type="button" label="Submit"
    @click='handleVolunteerSubmit'
    :disabled="!satisfiesMinimumRequirement")
  div.text-xs(v-if="!satisfiesMinimumRequirement") {{ minimumRequirementMsg }}
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import SelectRolesImpl from 'src/components/VolunteerRegistration/SelectRolesImpl.vue'
import {
  CompetitionsVolunteerImplProps,
  hasRequirementType,
} from './common'
import { VolunteerRoleSelection } from 'src/components/VolunteerRegistration/SelectRolesUtils'
import { VolunteerRequirementKey } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  // props are defined elsewhere to share their def but it's rough to not be able to read them here
  // can we export from .vue files? or GetPropTypeOf<SomeComponent>?
  props: CompetitionsVolunteerImplProps,
  emits: ['volunteer-roles-submit'],
  components: {
    SelectRolesImpl: SelectRolesImpl,
  },
  // multi-root component, which root inherits?
  inheritAttrs: false,
  setup(props, context) {
    const currentVolunteerRoleSelection = ref<
      VolunteerRoleSelection | undefined
    >(/* filled in by SelectRolesImpl after it is mounted */)

    const handleVolunteerRoleSelectionUpdate = (v: VolunteerRoleSelection) => {
      currentVolunteerRoleSelection.value = v
    }

    // we should be in this component because the sum of `familyAlreadyHas` + (whatever this user has) )does not satisfy some requirement
    const familyAlreadyHas = (() => {
      return props.staticVolunteerRequirementsCheck.staticCountsOmittingTargetUser.headCoach
        + props.staticVolunteerRequirementsCheck.staticCountsOmittingTargetUser.asstCoach
        + props.staticVolunteerRequirementsCheck.staticCountsOmittingTargetUser.referee
        + props.staticVolunteerRequirementsCheck.staticCountsOmittingTargetUser.flex;
    })();

    // The smallest requirement count, minus "familyAlreadyHas"
    // If we're in this component, this *should* be greater-than-zero
    const minimumRequirementAmountForThisUser = (() => {
      let min : number | undefined = undefined;
      for (const requirement of props.staticVolunteerRequirementsCheck.volunteerRequirements) {
        let required = 0;
        for (const roletype of (Object.keys(requirement) as (keyof typeof requirement)[])) {
          required += requirement[roletype] ?? 0;
        }
        min = (min === undefined) ? required : Math.min(min, required);
      }

      return Math.max(0, (min ?? 0) - familyAlreadyHas);
    })();

    const minimumRequirementMsg = (() => {
      const plural = minimumRequirementAmountForThisUser === 1 ? "role" : "roles";
      return `Please select at least ${minimumRequirementAmountForThisUser} volunteer ${plural}`;
    })();

    const currentSelectionCount = computed(() => {
      if (currentVolunteerRoleSelection.value === undefined) {
        return 0;
      }

      // can have many divisions selected but only counts as 1 total (each) {"head coach", "asst coach", "referee"}
      const headCoachCount = currentVolunteerRoleSelection.value.coach.headCoach ? 1 : 0;
      const asstCoachCount = currentVolunteerRoleSelection.value.coach.asstCoach ? 1 : 0;
      const refereeCount = currentVolunteerRoleSelection.value.referee.ref ? 1 : 0;
      const flexCodeCount = (() => {
        let result = 0;
        for (const key of Object.keys(currentVolunteerRoleSelection.value.flex)) {
          // flex-codes may be present but map to falsy, in which case we treat them as not present
          if (!!currentVolunteerRoleSelection.value.flex[key]) {
            result += 1
          }
        }
        return result
      })();

      return headCoachCount + asstCoachCount + refereeCount + flexCodeCount;
    });

    const satisfiesMinimumRequirement = computed(() => {
      return currentSelectionCount.value + familyAlreadyHas >= minimumRequirementAmountForThisUser;
    })

    const handleVolunteerSubmit = () => {
      if (currentVolunteerRoleSelection.value === undefined) {
        return
      }
      context.emit(
        'volunteer-roles-submit',
        currentVolunteerRoleSelection.value
      )
    }

    return {
      handleVolunteerRoleSelectionUpdate,
      handleVolunteerSubmit,
      VolunteerRequirementKey,
      hasRequirementType,
      minimumRequirementAmountForThisUser,
      minimumRequirementMsg,
      satisfiesMinimumRequirement,
    }
  },
})
</script>
