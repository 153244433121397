<template lang="pug">
div(class="mb-6")
  RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")

.t-page.flex.flex-col(class='md:mx-2')
  h1.ml-2.text-2xl.font-medium(class='md:text-4xl')
    font-awesome-icon.mr-2(:icon='["fas", "child"]')
    | Player Registration: Confirm Family Members - {{ player.playerFirstName }} {{ player.playerLastName }}

PlayerUserRelations(
  v-if="Object.keys(player).length>0"
  :relatedUsers='player.relatedUsers',
  :triggerSave='triggerSave',
  :player='player'
  @saved="toRegistrationForm"
  @error='()=>triggerSave=false'
)
.mt-4
  t-btn(
    :margin="false"
    type='button',
    @click="()=>triggerSave=true"
    data-test="submit"
  )
    | Submit
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import PlayerUserRelations from 'src/components/Registration/playerUserRelations/playerUserRelations.vue'
import { type WithDefinite, type Child, Guid } from 'src/interfaces/InleagueApiV1'
import { PropType, computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as ilapi from "src/composables/InleagueApiV1"
import { propsDef } from './R_RegistrationRelationshipCheck.route'
import * as R_PlayerRegistration from "src/components/Registration/registrationForm/R_PlayerRegistration.route"
import * as RegistrationJourneyBreadcrumb from "src/components/Registration/RegistrationJourneyBreadcrumb"

type ExpandedChild = WithDefinite<Child, "relatedUsers" | "familyMembers" | "parent1ID" | "parent2ID">;

export default defineComponent({
  components: {
    PlayerUserRelations,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
  },
  props: propsDef,
  setup(props) {
    const triggerSave = ref(false)
    /**
     * maybe assigned in onMounted, assuming network request succeeds
     */
    const player = ref({} as ExpandedChild)

    const router = useRouter()

    const parentsDefined = (player: ExpandedChild) => {
      let parent1Defined = false
      let parent2Defined = false

      for(let i=0; i<player.relatedUsers.length; i++) {
        if(player.parent1ID && player.relatedUsers[i].userID===player.parent1ID) {
          parent1Defined = true
        } else if(player.parent2ID && player.relatedUsers[i].userID===player.parent2ID) {
          parent2Defined=true
        }
      }

      return parent1Defined && (parent2Defined || !player.parent2ID)
    }

    const toRegistrationForm = async () => {
      await router.push(R_PlayerRegistration.routeDetailToRouteLocation({
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs,
        playerID: props.playerID
      }))
    }

    const getExpandedChild = async () : Promise<ExpandedChild | undefined> => {
      try {
        return await ilapi.getPlayer(
          axiosInstance, {
            childID: props.playerID,
            expand: ["relatedUsers", "parent1ID", "parent2ID", "familyMembers"]
          }) as ExpandedChild;
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      }

      return undefined;
    }

    onMounted(async ()=> {
      const expandedChild = await getExpandedChild();
      if (!expandedChild) {
        // network error or something else we caught, there's nothing we can do to recover
        // the page should be in a useless but valid state, so the user can navigate elsewhere
        return;
      }

      if(parentsDefined(expandedChild)) {
        await toRegistrationForm()
      }
      else {
        player.value = expandedChild;
      }
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          // gets globbed into select program but it could be it's own thing if necessary
          step: RegistrationJourneyBreadcrumb.Step.selectProgram,
          seasonUID: props.seasonUID,
          playerID: props.playerID,
        }
      } satisfies RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps
    })

    return {
      player,
      triggerSave,
      toRegistrationForm,
      registrationJourneyBreadcrumbProps
    }
  }
});
</script>
