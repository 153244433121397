import { type AxiosInstance } from "axios"
import { CheckedOmit } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Datelike, Guid, Integerlike, Numbool, Season, SeasonTriple } from "src/interfaces/InleagueApiV1";

export async function listCompetitionSeasonDivisions(
  axios: AxiosInstance,
  args: {seasonUID: iltypes.Guid, competitionUID?: iltypes.Guid, isActive?: boolean}
) : Promise<iltypes.CompetitionSeasonDivision[]> {
  const response = await axios.get(`v1/season/competitionSeasonDivisions`, {params: args});
  return response.data.data;
}

export async function listSeasonOptionsForManagerView(ax: AxiosInstance) : Promise<{options: SeasonTriple[], defaultSeasonUID: Guid}> {
  const response = await ax.get(`v1/season/managerView/options`)
  return response.data.data;
}

export enum CalendarSeason {
  fall = 1,
  winter = 2,
  spring = 3,
  summer = 4,
}

export enum DobCutoff {
  lastDayOfJulyCutoff = 0,
  noAgeCuttof = 1
}
export interface SeasonForManagerView {
  season: {
    seasonUID: Guid,
    seasonName: string,
    seasonYear: Integerlike,
    registrationYear: Integerlike,
    calendarSeasonID: CalendarSeason,
    fullYearCalendar: DobCutoff,
    algorithmSplitBirthYear: Integerlike,
    disabled: Numbool,
    inPersonDate: "" | Datelike,
    requireRefDiv: Numbool,
    hasThreeYearOlds: Numbool,
    pointsLockDate: "" | Datelike,
    ratingsPromptDate: "" | Datelike,
    ratingsDate: "" | Datelike,
    profileLockDate: "" | Datelike,
    rosterDate : "" | Datelike,
  },
  // misc. info for some helpful validation checks
  mostRecentSeason: {
    seasonUID: Guid,
    seasonName: string,
    seasonID: Integerlike,
    registrationYear: Integerlike,
    calendarSeasonID: CalendarSeason,
  }
}

export async function getSeasonForManagerView(ax: AxiosInstance, args: {seasonUID: "new"}) : Promise<CheckedOmit<SeasonForManagerView, "season">>;
export async function getSeasonForManagerView(ax: AxiosInstance, args: {seasonUID: Guid}) : Promise<SeasonForManagerView>;
export async function getSeasonForManagerView(ax: AxiosInstance, args: {seasonUID: Guid | "new"}) : Promise<SeasonForManagerView> {
  const response = await ax.get(`v1/season/managerView`, {params: {seasonUID: args.seasonUID}});
  return response.data.data;
}

export interface SeasonCreateOrUpdateData {
  seasonName: string,
  seasonYear: Integerlike,
  registrationYear: Integerlike,
  calendarSeasonID: 1 | 2 | 3 | 4,
  fullYearCalendar: Integerlike,
  algorithmSplitBirthYear: "" | Integerlike,
  disabled: Numbool,
  inPersonDate: Datelike,
  requireRefDiv: Numbool,
  hasThreeYearOlds: Numbool,
  pointsLockDate: "" | Datelike,
  ratingsPromptDate: "" | Datelike,
  ratingsDate: "" | Datelike,
  profileLockDate: "" | Datelike,
  rosterDate : "" | Datelike,
}

export type CreateSeasonArgs = SeasonCreateOrUpdateData

export async function createSeason(ax: AxiosInstance, args: CreateSeasonArgs) : Promise<SeasonForManagerView> {
  const response = await ax.post(`v1/season`, args)
  return response.data.data;
}

export interface UpdateSeasonArgs {
  seasonUID: Guid,
  data: SeasonCreateOrUpdateData
}

export async function updateSeason(ax: AxiosInstance, args: UpdateSeasonArgs) : Promise<SeasonForManagerView> {
  const response = await ax.put(`v1/season/${args.seasonUID}`, args)
  return response.data.data;
}
