<template lang="pug">
.flex.flex-col.items-start(class="md:flex-row md:items-center")
  h1.text-4xl.font-medium.mb-5
    font-awesome-icon.mx-4.mt-2.cursor-pointer(:icon='["fas", "pencil-alt"]')
    | Edit Registration Item: Question
  TBtn.ml-4(type='button', color="red" label='Delete Question' @click="deleteQuestion" v-if="Object.keys(item).length>0 && !item.pageItem.hasAnswers")
  TBtn.ml-4(:label="`${item.isDisabled ? 'Enable ' : 'Disable '} Registration Item`", color="green", @click="disableItem")
.max-w-md.pt-4(v-if="Object.keys(item).length>0")
  p.tracking-tight.text-gray-900
    div.border-2.border-gray-300.border-opacity-100.rounded-md.mt-2.p-2
      PageItem_Question_PreviewRender(:inputType='item.pageItem.type', :label="item.pageItem.label", :selectOptions="item.pageItem.questionOptions")
  div
    div.flex
      FormKit(
        label='Label',
        type="text"
        name='label',
        v-model.lazy='item.pageItem.label',
        validations='required',
        data-cy='label',
        wrapper-class='mt-4',
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Question label displayed on the registration form.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
    div(v-if="errors.missingLabel" class="text-red-700 text-sm") Label is required
  div
    div.flex.no-wrap
      FormKit.flex.wrap(
        label='Short Label',
        type="text"
        name='shortLabel',
        v-model.lazy='item.pageItem.shortLabel',
        validations='required',
        data-cy='label',
        wrapper-class='mt-4'
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Shorthand reference to the registration field used in administration and reporting.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
    div(v-if="errors.missingShortLabel" class="text-red-700 text-sm") Short label is required
  .flex.flex-wrap.pt-5
    FormKit.flex-auto.gap-4.h-full(
      type="checkbox"
      name='isEditable',
      v-model.lazy='item.pageItem.isEditable',
      validations='required',
      data-cy='isEditable',
    )
    label.font-medium.text-smt-label.block.flex-auto User May Edit Answer
      button.cursor-pointer.ml-3(
          v-tooltip='{content: `If true, the value of this field may be edited by a user after the registration is submitted.`}'
        )
        font-awesome-icon.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
  .flex.flex-wrap.pt-5
    FormKit.flex-auto.gap-4.h-full(
      type="checkbox"
      name='isRequired',
      v-model.lazy='item.pageItem.isRequired',
      validations='required',
      data-cy='isRequired'
    )
    label.font-medium.text-smt-label Response Required
      button.ml-3.cursor-pointer(
        v-tooltip='{content: `If true, a response is required for players who have this question in their form.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
  .flex.flex-wrap.pt-5
    FormKit.flex-auto.gap-4.h-full(
      label='Order',
      type="number"
      name='order',
      v-model.lazy='item.order',
      validations='required',
    )
    button.ml-2.cursor-pointer(
      v-tooltip='{content: `Order in which the text/HTML is displayed on the registration form.`}'
    )
      font-awesome-icon.mr-2.text-gray-400(
        :icon='["fas", "info-circle"]'
      )
div(v-if="shouldShowQuestionOptionsEditor")
  label.font-medium.text-smt-label.block.mt-6.ml-2.mb-4 Question Options
  div(v-if="errors.questionOptions.hasNoneButNeedsOne" class="text-red-700") This question type requires at least one question option.
  Options.pt-4(
    :triggerSave="triggerSave"
    :options="item.pageItem.questionOptions"
    :questionID="item.pageItem.id"
    :errors="errors.questionOptions.each"
    mode="edit"
    @apiError="apiError=true"
    @updateStatus="status=>updateStatus(status, 'options')"
  )
div(v-if="Object.keys(item).length>0")
  GateFunctionEditor(@apiError="apiError=true" labelItem="Registration Item" :item="item.pageItem", :gateQuestionType='1', v-model:gateFunction="item.pageItem.gateFunctionName" :triggerSave="triggerSave" :itemID="item.pageItem.id" @updateStatus="status=>updateStatus(status, 'gateFunction')")
  SeasonSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedSeasonsObjects='item.itemSeasons' @update:seasonUIDs="(val)=>item.seasonUIDs=val" :triggerSave="triggerSave" :pageItemID="id" @updateStatus="status=>updateStatus(status, 'season')")
  CompetitionSelector(
    data-test="CompetitionSelector"
    @apiError="apiError=true"
    @update:competitionUIDs="val => { item.itemCompetitions = val; }"
    @updateStatus="status=>updateStatus(status, 'competition')"
    labelItem="Registration Item"
    :selectedCompetitionsObjects="item.itemCompetitions"
    :triggerSave="triggerSave"
    :pageItemID="id"
  )
div(v-if="Object.keys(item).length>0" class="flex flex-col mt-4 items-start")
  TBtn.mt-2(
    type='button',
    :class="`${hasSomeSubmitBlockingError ? 'bg-gray-300' : ''}`"
    :disable="hasSomeSubmitBlockingError"
    @click="saveQuestion"
    data-test='submit'
  )
    div Save Registration Item
  div(v-if="hasSomeSubmitBlockingError" class="text-sm text-red-600") Sorry, not all fields are filled out correctly.
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  Ref,
  getCurrentInstance,
computed,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { PageItemContainer } from 'src/interfaces/Store/registration'
import { PageItem_ambiguously_QuestionOrContentChunk } from 'src/interfaces/Store/registration'
import { PageItem_Question_PreviewRender } from 'src/components/Registration/admin/PageItem_Question_PreviewRender'
import GateFunctionEditor from 'src/components/Registration/admin/GateFunctionEditor.vue'
import Options from 'src/components/Registration/admin/Options.vue'
import SeasonSelector from 'src/components/Registration/admin/SeasonSelector.vue'
import CompetitionSelector from 'src/components/Registration/admin/CompetitionSelector.vue'
import { axiosInstance } from 'src/boot/axios'

import * as iltypes from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard } from 'src/helpers/utils'
import { QuestionFormErrors, questionFormErrorsFromQuestion, questionformErrorsHasAtLeastOneSingleError } from '../pageItem/PageItem'

import { RegistrationStore } from "src/store/Registration"

export default defineComponent({
  components: {
    PageItem_Question_PreviewRender,
    Options,
    SeasonSelector,
    GateFunctionEditor,
    CompetitionSelector,
  },
  setup() {
    const id = ref('')

    const item = ref(/*fixme: use null instead of {} for temporarily uninitialized case*/ {}) as Ref<PageItemContainer>
    /**
     * the fixme_ here refers to using {} instead of null for the null case
     */
    function fixme_itemIsEffectivelyNull() : boolean {
      return Object.keys(item.value).length === 0;
    }

    const triggerSave = ref(false)
    const localInstance = getCurrentInstance()
    const $toast = localInstance?.appContext.config.globalProperties.$toast
    const apiError = ref(false)
    // map of (question name -> update status), used as an "isDirty" map
    const questionStati = ref<Record<string, "pending" | "saved">>({})

    const route = useRoute()
    const router = useRouter()


    const shouldShowQuestionOptionsEditor = computed(() => {
      if (fixme_itemIsEffectivelyNull()) {
        return false;
      }

      switch (item.value.pageItem.type) {
        case iltypes.QuestionType.CHECKBOX: return true;
        case iltypes.QuestionType.RADIO: return true;
        case iltypes.QuestionType.SELECT: return true;
        case iltypes.QuestionType.TEXT: return false;
        case iltypes.QuestionType.TEXTAREA: return false;
        default:
          return false;
          // TODO: exhaustiveCaseGuard(item.value.pageItem.type);
          // but can't be done until we fixup {}vs.null case in item.value
      }
    })

    const errors = computed<QuestionFormErrors | undefined>(() => {
      if (fixme_itemIsEffectivelyNull()) {
        return undefined;
      }
      else {
        return questionFormErrorsFromQuestion(item.value.pageItem);
      }
    })

    const hasSomeSubmitBlockingError = computed(() => errors.value ? questionformErrorsHasAtLeastOneSingleError(errors.value) : false)

    const prepIncomingItem = async () => {
      id.value = route.params.id as string
      if (RegistrationStore.value.pageItems[id.value]) {
        item.value = JSON.parse(
          JSON.stringify(RegistrationStore.value.pageItems[id.value])
          )
          cleanupPageItem(item.value.pageItem)
      } else {
        await RegistrationStore.getPageItem(id.value)
        item.value = JSON.parse(
          JSON.stringify(RegistrationStore.value.pageItems[id.value])
          )
          cleanupPageItem(item.value.pageItem)
      }
      //
      // cleanupPageItem converts booleanish values to booleans. FormKit requires strict boolean values for v-model binding in checkbox type forms.
      // We should just store them in their cleaned up state. Really we need an ingress API choke point that does this.
      //
      function cleanupPageItem(pageItem: PageItem_ambiguously_QuestionOrContentChunk) {
        pageItem.isDisabled = !!pageItem.isDisabled
        pageItem.isRequired = !!pageItem.isRequired
        pageItem.isEditable = !!pageItem.isEditable
        pageItem.hasAnswers = !!pageItem.hasAnswers;
      }
    }

    const deleteQuestion = async () => {
      try {
        const response = await axiosInstance.delete(
          `/v1/registration/customQuestion/${item.value.pageItem.id as string}`
        )
        // console.log('sucessfully deleted custom question', response.data.data)
      } catch (error: any) {
        // console.log('error deleting custom question', error)
        if (error.response.status === 403) {
          $toast.error({
            message: 'This question already has answers. We canot delete it.',
          })
        } else {
          $toast.error({
            message: 'There was a problem deleting your question',
          })
        }
      }
      await router.push({ name: 'registration-admin' })
    }

    const updatePageItem = async () => {
      apiError.value = false
      const options = {
        order: item.value.order,
        isDisabled: item.value.isDisabled,
      }
      try {
        const response = await axiosInstance.put(
          `/v1/registration/pageItem/${item.value.id}`,
          options
        )
      } catch (error) {
        // console.log('error updating pageItem', error)
        apiError.value = true
      }
    }

    const updateQuestion = async () => {
      apiError.value = false
      const options = { ...item.value.pageItem }
      try {
        const response = await axiosInstance.put(
          `/v1/registration/customQuestion/${item.value.pageItem.id as string}`,
          options
        )
      } catch (error) {
        // console.log('error updating question', error)
        apiError.value = true
      }
    }

    const setCustomFields = async () => {
      const customFields = await RegistrationStore.getCustomFields()
      RegistrationStore.directCommit_setCustomFields(customFields)
      await RegistrationStore.createCustomFieldsOptionFormat(
        customFields
      )
    }

    const saveQuestion = async () => {
      await updatePageItem()
      await updateQuestion()
      triggerSave.value = true
    }

    const disableItem = async () => {
      if (item.value.isDisabled) {
        item.value.isDisabled = 0
      } else {
        item.value.isDisabled = 1
      }
      await updatePageItem()
    }

    const updateStatus = (status: "pending" | "saved", item: string) => {
      questionStati.value[item] = status
    }

    watch(
      [triggerSave, questionStati],
      async ([isSaving, freshStatusMap]) => {
        let everythingIsSaved = true;
        for (const key of Object.keys(freshStatusMap)) {
          if (freshStatusMap[key] !== 'saved') {
            everythingIsSaved = false;
          }
        }
        if (isSaving && everythingIsSaved) {
          await router.push({ name: 'registration-admin' })
        }
      },
      { deep: true }
    )

    watch(apiError, val => {
      if (apiError.value) {
        triggerSave.value = false
      }
    })

    onMounted(async () => {
      await setCustomFields()
      await prepIncomingItem()
    })

    return {
      id,
      item,
      deleteQuestion,
      saveQuestion,
      triggerSave,
      disableItem,
      apiError,
      updateStatus,
      questionStati,
      shouldShowQuestionOptionsEditor,
      errors,
      hasSomeSubmitBlockingError
    }
  },
})
</script>

