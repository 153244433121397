<template lang="pug">
.max-w-xl(v-if="ready" data-test="ConfirmRegistration")
  div(class="mb-6")
    RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")
  FormKit(type="form" @submit="proceedToWaivers" :actions="false")
    h3.text-2xl.leading-6.font-medium.text-gray-900.flex.items-center
      font-awesome-icon.mr-2(:icon='["fas", "edit"]')
      | Review Registration Details - {{ registrationAnswers.playerFirstName }}  {{ registrationAnswers.playerLastName }}
    p.mt-4.font-medium.text-gray-500.tracking-wider.italic Please review your registration details below and select Proceed with Registration if everything is correct. Once confirmed, you will be presented with any necessary electronic legal agreements, and the payment page.
    b The registration is not valid until all of these are complete.

    div(v-for="props in confirmOneProps")
      FormKit(type="group")
        ConfirmOneRegistrationElement(v-bind="props")
    h3.text-2xl.leading-6.font-medium.text-gray-900.flex.items-center.mt-12 Registration Details
    .-my-2.mt-2(class='sm:-mx-6 lg:-mx-8' v-if="Object.keys(registrationAnswers).length>0")
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider.max-w-sm(scope='col')
                  | Question
                th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col')
                  | Answer
            tbody.bg-white.divide-y.divide-gray-200
              template(v-for='question in coreQuestions')
                tr
                  td.px-6.py-4.text-sm.font-medium.text-gray-900(class="w-1/2")
                    | {{ question.label }}
                  td.px-6.py-4.text-sm.text-gray-500
                    | {{ processAnswer(registrationAnswers[question.name]) }}
              template(v-for='question in customQuestions')
                tr
                  td.px-6.py-4.text-sm.font-medium.text-gray-900(class="w-1/2" v-html="question.question")
                  td.px-6.py-4.text-sm.text-gray-500
                    | {{ processAnswer(question.value) }}
    .flex.justify-between.mt-4
      TBtn(type="button" @click="editRegistration" color="red")
        | Edit
      TBtn(type="submit" data-test="submit")
        | Proceed with Registration
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { defineComponent, ref, Ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'

import ContentChunk from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { Floatlike, Guid, QuestionOption, QuestionType, RegistrationPageItem, RegistrationQuestion } from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import { DirtyPristinePrimitive, arrayFindOrFail, assertTruthy, isCfTruthy, parseFloatOr, parseFloatOrFail, sortBy } from 'src/helpers/utils'
import { isRegistrationQuestion } from 'src/composables/InleagueApiV1'
import { type RegistrationAnswer } from 'src/interfaces/InleagueApiV1'
import { CompetitionRegistrationFlow } from 'src/composables/registration'
import { Client } from 'src/store/Client'
import { propsDef } from './R_ConfirmRegistration.route'
import * as R_PlayerRegistration from "src/components/Registration/registrationForm/R_PlayerRegistration.route"
import * as R_PlayerRegistrationWaivers from "src/components/Registration/waivers/R_PlayerRegistrationWaivers.route"
import { ConfirmOneRegistrationElement, ConfirmOneProps, FeeOverride } from "./ConfirmOne"
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { defaultImplicitlyOneTimePaymentPaymentMethodID, getMaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData } from 'src/composables/InleagueApiV1.Registration'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import * as RegistrationJourneyBreadcrumb from "src/components/Registration/RegistrationJourneyBreadcrumb"
import { type RegistrationExpandedForStore } from "src/interfaces/Store/registration";

export default defineComponent({
  components: {
    ContentChunk,
    ConfirmOneRegistrationElement,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
  },
  props: propsDef,
  setup(props) {
    const registrationAnswers = ref({}) as Ref<RegistrationExpandedForStore>
    const coreQuestions = ref({})
    const customQuestions = ref({})

    const router = useRouter()


    const getCoreQuestions = async () => {
      const response = await axiosInstance.get(`v1/registration/standardCoreQuestions?childID=${props.playerID}&seasonUID=${props.seasonUID}&withGates=1`)
      return response.data.data
    }

    /**
     * fixme: the name says "getCustomQuestions", but we're getting PageItems?
     */
    const getCustomQuestions = async () : Promise<RegistrationPageItem[]> => {
      try {
        const response = await axiosInstance.get(`/v1/registration/pageItems/${registrationAnswers.value.childID}/${props.seasonUID}`, {params: {competitionUIDs: props.competitionUIDs}})
        return response.data.data
      } catch(error) {
        return [];
      }
    }

    const getQuestionAnswer = (id: string, question: RegistrationQuestion, questionOptions: QuestionOption[]) : string => {
      let questionAnswer : RegistrationAnswer | undefined = undefined;
      for(let i=0; i<registrationAnswers.value.registrationAnswers.length; i++) {
        if(registrationAnswers.value.registrationAnswers[i].questionID === id) {
          questionAnswer=registrationAnswers.value.registrationAnswers[i]
          break
        }
      }

      // fixme/todo: Maybe we don't really expect to find an answer.
      // consider the case of a non-required text-input field; at the moment, we will not find an answer for this (an empty string either isn't stored or isn't serialized back to us)
      // The "failure case" (and the todo is more about 'is this really a failure case?') where we don't find an answer
      // doesn't have grave consequences (if we handle it) -- the user will see an empty string (or rather, not see it, since it's an empty string)
      if (!questionAnswer) {
        return "";
      }

      if (question.type === QuestionType.CHECKBOX) {
        return isCfTruthy(questionAnswer.answer) ? "Yes" : "No"
      }

      if(!questionOptions) {
        return questionAnswer.answer
      }

      for(let i=0; i<questionOptions.length; i++) {
        if(questionOptions[i].optionValue===questionAnswer.answer) {
          return questionOptions[i].optionText
        }
      }

      return questionAnswer.answer
    }

    /**
     * depending on if we really wanted to pull all page items or just questions, we can do some renaming here
     * at the moment, this is named "processCustomQuestions" but it accepts `RegistrationPageItem`s
     * since non-questions (i.e. content chunks) are being filtered away here, probably we don't need to accept anything except questions
     */
    const processCustomQuestions = (questions: RegistrationPageItem[]) => {
      const processedCustomQuestions : Record<Guid, {question: string, value: string}>= {}

      for(let i=0; i<questions.length; i++) {
        const question = questions[i];
        if (!isRegistrationQuestion(question)) {
          continue;
        }

        processedCustomQuestions[question.pageItem.id] = {
          question: question.pageItem.label,
          value: getQuestionAnswer(question.pageItem.id, question.pageItem, question.pageItem.questionOptions),
        }
      }

      return processedCustomQuestions
    }

    const processLabels = async (questions: any) => {
      let processedQuestions = questions
      let processedLabel = ''
      const season = await Client.getSeasonByUidOrFail(registrationAnswers.value.seasonUID)
      const seasonYear = season.registrationYear

      for(let i=0; i<questions.length; i++) {
        processedLabel = questions[i].label.replace('{leaguename}', `${Client.value.instanceConfig.shortname}`)
        processedLabel = questions[i].label.replace('{regyear}', `${seasonYear}`)
        processedQuestions[i].label=processedLabel
      }

      return processedQuestions
    }

    const processAnswer = (answer: any) => {
        if(answer===0) {
          return "No"
        } else if(answer===1) {
          return "Yes"
        } else {
          return answer
        }
    }

    const editRegistration = async () => {
      await router.push(R_PlayerRegistration.routeDetailToRouteLocation({
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs,
        playerID: registrationAnswers.value.childID
      }))
    }

    const proceedToWaivers = async () => {
      const mutableFees : ilapi.IncompleteCompetitionRegistrationUserMutableFeeInfo[] = confirmOneProps
        .value
        .filter(v => {
            return v.donation?.isStrictDirty() || v.payWhatYouCan?.fee.isStrictDirty()
        })
        .map(v => {
          return {
            competitionRegistrationID: v.competitionRegistration.competitionRegistrationID,
            donation: v.donation?.isStrictDirty() ? parseFloatOr(v.donation.dirty, undefined) : undefined,
            payWhatYouCan: v.payWhatYouCan?.fee.isStrictDirty() ? parseFloatOr(v.payWhatYouCan.fee.dirty, undefined) : undefined
          }
        });

      if (mutableFees.length > 0) {
        await CompetitionRegistrationFlow.updateUserMutableFees(axiosInstance, props.registrationID, mutableFees)
      }

      await router.push(R_PlayerRegistrationWaivers.routeDetailToRouteLocation({
        seasonUID: registrationAnswers.value.seasonUID,
        playerID: registrationAnswers.value.childID,
        registrationID: props.registrationID,
        competitionUIDs: confirmOneProps.value.map(v => v.competitionRegistration.competitionUID)
      }))
    }

    const ready = ref(false);

    const confirmOneProps = ref<ConfirmOneProps[]>([])

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const season = await Client.getSeasonByUidOrFail(props.seasonUID);
        const competitions = (await getCompetitionsOrFail()).value
        const divisions = await Client.getDivisions();
        const competitionSeasons = await ilapi.getCompetitionSeasons(axiosInstance, props.competitionUIDs.map(competitionUID => ({seasonUID: props.seasonUID, competitionUID})));

        const registration = await CompetitionRegistrationFlow.getExpandedRegistration(axiosInstance, props.registrationID)
        const regInvoiceTemplateInfo = await getMaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData(axiosInstance, {
          seasonUID: props.seasonUID,
          competitionUIDs: props.competitionUIDs,
          playerID: props.playerID
        })
        const allCompRegs = registration.competitions

        registrationAnswers.value = registration;

        confirmOneProps.value = props.competitionUIDs.map((competitionUID) : ConfirmOneProps => {
          const competitionRegistration = arrayFindOrFail(allCompRegs, (compReg) => !compReg.canceled && compReg.competitionUID === competitionUID)
          const competitionSeason = arrayFindOrFail(competitionSeasons, (compSeason) => compSeason.competitionUID === competitionUID)
          const division = arrayFindOrFail(divisions, div => div.divID === competitionRegistration.divID);
          const fee = parseFloatOr(competitionRegistration.fee, 0);

          const willBeSubscriptionInvoice = !!competitionRegistration.invoiceTemplate_invoiceID && !!competitionRegistration.invoiceTemplate_methodID;

          // fee override can't be supported for subscription invoices
          const feeOverride : FeeOverride | null = !willBeSubscriptionInvoice && fee >= 0.01 && typeof competitionSeason.feeOverride === "number"
            ? {
              fee: new DirtyPristinePrimitive<Floatlike>(fee),
              floor: competitionSeason.feeOverride,
              contentChunkArgs: {
                competitionSeasonUID: competitionSeason.competitionSeasonUID
              }
            }
            : null;

          // donation isn't currently supported on subscription invoices (probably can though, see backend for details)
          const donation = !willBeSubscriptionInvoice && fee >= 0.01
            ? new DirtyPristinePrimitive<Floatlike>(competitionRegistration.donation as Floatlike)
            : null

          return {
            seasonName: season.seasonName,
            registration,
            competitionRegistration,
            competitionSeason,
            division,
            donation,
            payWhatYouCan: feeOverride,
            // if there's an invoice template, we need to pull fee info from it
            // otherwise, we can use the explicit compreg fee
            feeUiString: competitionRegistration.invoiceTemplate_invoiceID
              ? (() => {
                const compRegInvoiceTemplateOptions = arrayFindOrFail(regInvoiceTemplateInfo.perCompetitionRegistration, v => v.competitionRegistrationID /*not strict*/ == competitionRegistration.competitionRegistrationID);
                const currentOption = compRegInvoiceTemplateOptions?.paymentMethods.find(opt => opt.methodID /*not strict*/ == compRegInvoiceTemplateOptions?.currentPaymentMethodID)
                if (currentOption?.methodID === defaultImplicitlyOneTimePaymentPaymentMethodID) {
                  // we repurposed the endpoint we got this data from, so this is a little funky;
                  // in the "one time payment" case, we don't want the `label` property (which was designed for a separate view)
                  // we just want the "total amount".
                  return `$${parseFloatOrFail(currentOption.totalAmount).toFixed(2)}`
                }
                else {
                  // do we expect to not find this? We should always find a result yeah?
                  return currentOption?.label || "";
                }
              })()
              : `$${parseFloatOrFail(competitionRegistration.fee).toFixed(2)}`
          }
        }).sort(sortBy(_ => competitions.find(comp => comp.competitionUID === _.competitionRegistration.competitionUID)?.competitionID ?? -1))

        coreQuestions.value = await processLabels(await getCoreQuestions())
        customQuestions.value = processCustomQuestions(await getCustomQuestions())

        await CompetitionRegistrationFlow.setFlowState_expandedRegistration(registrationAnswers.value);

        ready.value = true;
      })
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.reviewAndPay,
          seasonUID: props.seasonUID,
          playerID: props.playerID,
          competitionUIDs: props.competitionUIDs
        }
      }
    })

    return {
      registrationAnswers,
      coreQuestions,
      customQuestions,
      proceedToWaivers,
      editRegistration,
      processAnswer,
      confirmOneProps,
      ready,
      registrationJourneyBreadcrumbProps
    }
  },
})

</script>
