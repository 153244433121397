<template lang="pug">
tbody.bg-white.divide-gray-200(:data-test="`transactionID=${transaction.transactionID}`")
    td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
      div {{ formatDate(transaction.transactionDate) }}
      div(class="text-xs") {{ formatTime(transaction.transactionDate) }}
    td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
      | {{ transaction.transactionType }}
    td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
      | {{ transaction.cardType ? `${transaction.cardType} ${transaction.lastfour}` : `Check ${transaction.checkNum}` }}
    td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.pl-8
      | {{ transaction.absoluteAmount < 0 ? `-$${parseFloat(transaction.absoluteAmount*-1).toFixed(2)}` : `$${parseFloat(transaction.absoluteAmount).toFixed(2)}` }}
    td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
      TransactionIDWithMaybeReceiptURLElement(:transaction="transaction")
</template>

<script lang="tsx">
import { defineComponent } from 'vue'
import dayjs from "dayjs";
import { Transaction } from 'src/interfaces/Store/checkout'
import { TransactionIDWithMaybeReceiptURLElement } from "./TransactionIDWithMaybeReceiptURLElement"
import { vReqT } from 'src/helpers/utils';

export default defineComponent({
  name: 'Transaction',
  components: {
    TransactionIDWithMaybeReceiptURLElement,
  },
  props: {
    transaction: vReqT<Transaction>(),
  },
  setup() {
    const formatDate = (datelike: string) => {
      const v = dayjs(datelike);
      return v.isValid() ? v.format("MM-DD-YYYY") : datelike;
    }

    const formatTime = (datelike: string) => {
      const v = dayjs(datelike);
      return v.isValid() ? v.format("hh:mm a") : datelike;
    }

    return {
      formatDate,
      formatTime,
    }
  }
})
</script>
