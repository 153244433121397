import { computed, defineComponent, ref, watch } from "vue"

import { axiosInstance } from "src/boot/AxiosInstances"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { propsDef } from "./R_TournamentTeamConfigurator.route";
import { arrayDropIf, exhaustiveCaseGuard, parseIntOrFail, weakEq } from "src/helpers/utils";

import { PlayerRoster } from "./TournamentTeamConfigurator.players"
import { CoachRoster } from "./TournamentTeamConfigurator.coaches"
import { RefereeRoster, RefereeAssignments } from "./TournamentTeamConfigurator.referees";
import { AdminRoster } from "./TournamentTeamConfigurator.admins";

import { NavigationGuardWithThis } from "vue-router";

import authService from "src/helpers/authService";
import { QuestionAnswerMap, TournamentTeamRegistrationQuestionsForm, formAnswersToApiSubmittable } from "./TournamentTeam.questionsForm";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { isAuthorizedToManageTournament, teamDesignation } from "./TournamentTeamUtils";
import { User } from "src/store/User";

import { TournamentTeamExpandedForConfigurator, getTournamentTeamExpandedForConfigurator } from "./TournamentTeamConfigurator.shared";
import { tournamentTeamStore } from "./Store/TournTeamStore";
import { getCanMakeTournamentTeamEdits } from "src/composables/InleagueApiV1.Tournament";
import { Guid } from "src/interfaces/InleagueApiV1";

const handleRouteChange : NavigationGuardWithThis<any> = async function (to, _from, next) : Promise<void> {
  const tournamentTeamID = parseIntOrFail(to.params.tournamentTeamID);

  const hasPerms = await tournamentTeamStore.getIsAuthorizedToManageTournamentTeam(axiosInstance, tournamentTeamID)

  if (hasPerms) {
    return next();
  }
  else {
    return next({name: "forbidden"})
  }
}

export default defineComponent({
  name: "R_TournamentRegistration",
  props: propsDef,
  beforeRouteEnter: handleRouteChange,
  beforeRouteUpdate: handleRouteChange,
  setup(props) {


    interface ResolvedBlob {
      readonly registrationPageItems: iltournament.TournTeamRegPageItem[]
      readonly registrationAnswers: iltournament.TournamentRegistrationAnswer[]
      readonly tournamentTeam: TournamentTeamExpandedForConfigurator
      readonly canDeleteRefs: boolean,
      readonly canDeleteCoaches: boolean,
    }
    type Awaitables =
      | {readonly ready: false}
      | ({readonly ready: true} & ResolvedBlob);

    const awaitables = ref<Awaitables>({ready: false})

    // this supports direct assignment to tournamentTeam.admin rather than splicing in place,
    // but we have to pay the "assert lifecycle" tax. We don't do this for `officials` so we probably
    // don't need to do it for team admins...?
    const mut_teamAdmins = computed({
      get() {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        return awaitables.value.tournamentTeam.admin
      },
      set(v) {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        awaitables.value.tournamentTeam.admin = v;
      },
    })

    watch(() => props.tournamentTeamID, async () => {
      awaitables.value = {ready: false}

      awaitables.value = {
        ready: true,
        registrationPageItems: await iltournament.getTournamentTeamRegistrationPageItems(axiosInstance, {tournamentTeamID: props.tournamentTeamID}),
        registrationAnswers: await iltournament.getTournamentTeamRegistrationAnswers(axiosInstance, {tournamentTeamID: props.tournamentTeamID}),
        tournamentTeam: await getTournamentTeamExpandedForConfigurator(axiosInstance, props.tournamentTeamID),
        ...(await getCanMakeTournamentTeamEdits(axiosInstance, {tournamentTeamID: props.tournamentTeamID})),
      };
    }, {immediate: true});

    const handleSubmitQuestionAnswers = async (answerMap: QuestionAnswerMap) : Promise<void> => {
      try {
        await iltournament.submitTournamentTeamRegistrationAnswers(
          axiosInstance, {
            tournamentTeamID: props.tournamentTeamID,
            answers: formAnswersToApiSubmittable(answerMap)
          });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const readonlyQuestionIDs = computed<Set<Guid>>(() => {
      if (!awaitables.value.ready) {
        return new Set()
      }

      if (isRegistrar.value) {
        return new Set();
      }

      const canMakeEdits = (() => {
        switch (awaitables.value.tournamentTeam.status) {
          case "PENDING":
            return true;
          case "APPROVED":
          case "DROPPED_BY_HOST":
          case "DROPPED_BY_SUBMITTER":
          case "PAID_AWAITING_APPROVAL":
            return false;
          default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
        }
      })();

      if (canMakeEdits) {
        // "no questions are readonly"
        return new Set();
      }

      return new Set(
        awaitables.value.registrationPageItems
          .filter((v) : v is iltournament.TournTeamRegPageItem_Question => v.type === iltypes.PageItemType.QUESTION)
          .filter((v) => v.containedItem.isEditable)
          .map(v => v.questionID)
      );
    })

    const isRegistrar = computed(() => authService(User.value.roles, "registrar"));
    const isTournAdmin = computed(() => awaitables.value.ready ? isAuthorizedToManageTournament(awaitables.value.tournamentTeam.competitionUID, User.value) : false);

    return () => (
      <div data-test="R_TournamentRegistration">
        {
          awaitables.value.ready
            ? (
              <>
                <div>Tournament team {teamDesignation(awaitables.value.tournamentTeam)}</div>
                <div class="text-xs">{awaitables.value.tournamentTeam.areaTeam.teamname}</div>
                {
                  (() => {
                    switch (awaitables.value.tournamentTeam.status) {
                      case "DROPPED_BY_HOST":
                        // fallthrough
                      case "DROPPED_BY_SUBMITTER":
                        // do we ever want to configure a dropped team? maybe we want to show details about how it was prior to it being dropped
                        return null;
                      case "PENDING":
                        return (
                          <>
                            {
                              isTournAdmin.value
                                ? (
                                  // tourn admin can edit questions here in pending state
                                  <>
                                    <Rosters resolved={awaitables.value}/>
                                    <TeamManagers resolved={awaitables.value}/>
                                    <Questions resolved={awaitables.value}/>
                                  </>
                                )
                                // a "just" tournTeamManager can edit rosters here
                                : <>
                                  <Rosters resolved={awaitables.value}/>
                                  <TeamManagers resolved={awaitables.value}/>
                                </>
                            }
                          </>
                        );
                      case "PAID_AWAITING_APPROVAL":
                        // fallthrough
                      case "APPROVED":
                        return (
                          <>
                            <Rosters resolved={awaitables.value}/>
                            <TeamManagers resolved={awaitables.value}/>
                            <Questions resolved={awaitables.value}/>
                          </>
                        )
                      default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
                    }

                    function TeamManagers({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <div class="shadow-md my-6 rounded-md bg-white">
                          <div class="bg-green-800 rounded-t-md p-1 text-white">Team managers</div>
                          <div class="p-2">
                            <AdminRoster
                              tournamentTeamID={props.tournamentTeamID}
                              mut_existingAdmins={mut_teamAdmins}
                              canMakeEdits={true}
                            />
                          </div>
                        </div>
                      )
                    }

                    function Questions({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 p-1 rounded-t-md text-white">
                              <div>Registration questions</div>
                              {
                                !isRegistrar.value ? <div class="text-xs">readonly view of existing answers</div> : null
                              }
                            </div>
                            <div class="p-2">
                              <TournamentTeamRegistrationQuestionsForm
                                key={props.tournamentTeamID}
                                pageItems={resolved.registrationPageItems}
                                existingAnswers={resolved.registrationAnswers}
                                readonlyQuestionIDs={readonlyQuestionIDs.value}
                                doShowPoints={isRegistrar.value}
                                submitLabel="Update"
                                doHideSubmitButton={
                                  // if all questions are readonly, don't show the submit button
                                  resolved
                                    .registrationPageItems
                                    .filter(v => v.type === iltypes.PageItemType.QUESTION).length === readonlyQuestionIDs.value.size
                                }
                                onSubmit={handleSubmitQuestionAnswers}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                    function Rosters({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          {
                            resolved.tournamentTeam.tournament_playerRosterSubmissionConfig === iltournament.TournTeamPlayerRosterSubmissionConfig.allowed
                              ? (
                                <div class="shadow-md my-6 rounded-md bg-white">
                                  <div class="bg-green-800 rounded-t-md p-1 text-white">Player roster</div>
                                  <div class="p-2">
                                    <PlayerRoster
                                      tournamentTeamID={props.tournamentTeamID}
                                      mut_existingPlayerLinks={resolved.tournamentTeam.playerLinks}
                                      canMakeEdits={true}
                                    />
                                  </div>
                                </div>
                              )
                              : null
                          }
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Referee roster</div>
                            <div class="p-2">
                              <RefereeRoster
                                tournTeamOrUndefinedIfUnaffiliatedOrMultiple={resolved.tournamentTeam}
                                targetBinding={{type: "tournamentTeam", tournamentTeamID: props.tournamentTeamID}}
                                mut_existingOfficials={resolved.tournamentTeam.officials}
                                canDelete={resolved.canDeleteRefs}
                                onAddedRef={ref => {
                                  resolved.tournamentTeam.refTournTeamOfficials.push(ref)
                                }}
                                onRemovedRef={({tournamentTeamOfficialID}) => {
                                  resolved.tournamentTeam.refTournTeamOfficials = arrayDropIf(
                                    resolved.tournamentTeam.refTournTeamOfficials,
                                    v => weakEq(tournamentTeamOfficialID, v.tournamentTeamOfficialID)
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Referee assignments</div>
                            <div class="p-2" style="max-height:800px; overflow:auto;">
                              <RefereeAssignments refOfficials={resolved.tournamentTeam.refTournTeamOfficials}/>
                            </div>
                          </div>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Coach roster</div>
                            <div class="p-2">
                              <CoachRoster
                                tournamentTeam={resolved.tournamentTeam}
                                mut_existingOfficials={resolved.tournamentTeam.officials}
                                canDelete={resolved.canDeleteCoaches}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()
                }
              </>
            )
            : null
        }
      </div>
    )
  }
})
