import { defineComponent, onMounted, ref, watch } from "vue"

import { axiosInstance } from "src/boot/axios";

import { sortBy, sortByMany, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { EventSigninSheetViewData, EventSigninSheetViewData_Signup, getEventSigninSheetViewData } from "src/composables/InleagueApiV1.Event";
import { EventSigninSheet } from "./EventSigninSheet";

export default defineComponent({
  props: {
    eventID: vReqT<Guid>(),
  },
  setup(props) {
    const ready = ref(false)
    const data = ref<null | EventSigninSheetViewData>(null)

    onMounted(async () => {
      const rehydrateWithNewEventID = async () => {
        ready.value = false;

        data.value = await getEventSigninSheetViewData(axiosInstance, {eventID: props.eventID})
          .then(data => {
            data.signups.sort(sortSignupsByName)
            return data;
          })

        ready.value = true;
      };

      await rehydrateWithNewEventID();

      watch(() => props.eventID, rehydrateWithNewEventID);
    })

    return () => {
      if (!ready.value || !data.value) {
        return null
      }
      return <EventSigninSheet key={props.eventID} data={data.value}/>
    }
  }
})

const sortSignupsByName = sortByMany<EventSigninSheetViewData_Signup>(
  sortBy(_ => _.type === "user" ? _.user.lastName : _.child.playerLastName),
  sortBy(_ => _.type === "user" ? _.user.firstName : _.child.playerFirstName),
)
