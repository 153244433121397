import { ExtractPropTypes, PropType } from "vue";
import { axiosInstance } from "src/boot/axios";
import { RegistrationPageItem_Question } from "src/interfaces/InleagueApiV1";
import { PlayerDetailsI } from "src/interfaces/Store/registration";

import CustomQuestions from 'src/composables/customQuestions'

import * as ilapi from "src/composables/InleagueApiV1"
import familyAuth from 'src/composables/familyAuth'
import authService from 'src/helpers/authService'
import { UserData } from "src/interfaces/Store/user";
import { User } from "src/store/User";
import { vReqT } from "src/helpers/utils";

export const propsDef = {
  playerDetails: {
    required: true,
    type: Object as PropType<Pick<PlayerDetailsI, "childID" | "seasonUID" | "registrationAnswers">>
  },
  granularEditPermissions: vReqT<Record<string, boolean>>(),
  lockedDueToSomeSeasonCompetitionLockDate: {
    required: true,
    type: Boolean
  }
} as const;

export const emitsDef = {
  updateCustomQuestions: (_v: Record<string, any>) => true
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractPropTypes<typeof emitsDef>;

/**
 * hoisted out to free function for testing purposes
 */
export async function configure(props: Props) {
  const { setBuiltInFunctions, getCustomQuestions, processCustomQuestionsSchema, processAnswers } = CustomQuestions()

  await setBuiltInFunctions()

  const isEffectivelySuperUser = authService(User.value.roles, "registrar", "playerAdmin")

  let questions : RegistrationPageItem_Question[];

  if (isEffectivelySuperUser) {
    const pageItems = await ilapi.getPlayerRegistrationPageItemsForChildSeason(axiosInstance, props.playerDetails.childID, props.playerDetails.seasonUID, false, undefined);
    questions = pageItems.filter(ilapi.isRegistrationQuestion);
  }
  else {
    const pageItems = await ilapi.getPlayerRegistrationPageItemsForChildSeason(axiosInstance, props.playerDetails.childID, props.playerDetails.seasonUID, true, undefined);
    questions = pageItems.filter(ilapi.isRegistrationQuestion)
  }

  const {schema, collectedOptionsByQuestionID} = processCustomQuestionsSchema(
    /*data*/questions,
    /*registrationPreview*/false,
    /*hasRegistrationRecord*/true,
    /*discardOutOfDateContentChunks*/true,
    /*currentAnswers*/ props.playerDetails.registrationAnswers,
    /*callerIsSuperUser*/isEffectivelySuperUser
  )
  const formData = processAnswers(questions, props.playerDetails.registrationAnswers, collectedOptionsByQuestionID)

  const isChildRelated = familyAuth().isChildRelated({
    childID: props.playerDetails.childID,
    childList: (User.value.userData as UserData).belongingChildrenIDs
  });

  return {
    questions,
    schema,
    formData,
    isChildRelated
  };
}
