<template lang="pug">
#navbar.fix-notch-bottom.bg-white.text-green-700.fixed.bottom-0.w-full.border-t.border-gray-200.flex.z-40
  a.flex.flex-grow.items-center.justify-center.p-2.cursor-pointer(
    @click='handleBack',
    class='hover:text-green-500'
  )
    .text-center
      span.block.h-8.text-3xl.leading-8
        font-awesome-icon(:icon='["fas", "angle-left"]')
  a.flex.flex-grow.items-center.justify-center.p-2.cursor-pointer(
    @click='handleHome',
    class='hover:text-green-500'
  )
    .text-center
      span.block.h-8.text-3xl.leading-8
        font-awesome-icon(:icon='["fas", "home-alt"]')
  a.flex.flex-grow.items-center.justify-center.p-2.cursor-pointer(
    @click='handleForward',
    class='hover:text-green-500'
  )
    .text-center
      span.block.h-8.text-3xl.leading-8
        font-awesome-icon(:icon='["fas", "angle-right"]')
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'NavBar',

  methods: {
    async handleHome() {
      await this.$router.push({
        name: 'mobile-landing',
      })
    },
    handleBack() {
      this.$router.back()
    },
    handleForward() {
      this.$router.forward()
    },
  },
})
</script>
