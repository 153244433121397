<template lang="pug">
div(v-if="Object.keys(contentChunk).length > 0")
  quill-editor.bg-white(
    v-model:value='contentChunk.defaultText',
    :editor-toolbar='customToolbar',
    :options='editorSettings',
    data-cy='overrideRichEditor'
  )
  .max-w-md
    div.flex.flex-row.mt-3
      FormKit.m-2(
        label='Label',
        name='label',
        v-model='contentChunk.label',
        validations='required',
        data-cy='ccOvrLabel',
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Question label displayed on the registration form.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
    div.flex.flex-row
      FormKit.m-2(
        label='Short Label',
        name='label',
        v-model='contentChunk.shortLabel',
        validations='required',
        data-cy='ccOvrLabel'
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Shorthand reference to the registration field used in administration and reporting.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
  .flex
    div.m-2
      FormKit.m-2(
        v-model='contentChunk.startDate',
        label-class='text-sm t-label w-full block mb-2',
        type='date',
        validation='date:YYYY-MM-DD',
        label='Start date (optional)',
        name='startDate',
        data-cy='overrideStartDate'
      )
    div.m-2
      FormKit.m-2(
        v-model='contentChunk.endDate',
        label-class='text-sm t-label w-full block mb-2',
        type='date',
        validation='date:YYYY-MM-DD',
        label='End date (optional)',
        name='endDate',
        data-cy='overrideEndDate'
      )
  GateFunctionEditor(@apiError="apiError=true" labelItem="Registration Item" :item="contentChunk", :gateQuestionType='3', v-model:gateFunction="contentChunk.gateFunctionName" :triggerSave="triggerSave" :itemID="contentChunk.id"  @updateStatus="status=>updateStatus(status, 'gateFunction')")
  SeasonSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedSeasonsObjects='seasonUIDs' @update:seasonUIDs="(val)=>seasonUIDs=val" :triggerSave="triggerSave" :pageItemID="pageItemID"  @updateStatus="status=>updateStatus(status, 'season')")
  CompetitionSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedCompetitionsObjects="competitionUIDs" @update:competitionUIDs="val=>competitionUIDs=val" :triggerSave="triggerSave" :pageItemID="pageItemID"  @updateStatus="status=>updateStatus(status, 'competition')")
  .flex.max-w-md
    TBtn.max-w-md(type='button', label='Save Registration Item', data-cy='submitOverride' @click="createPageItem")
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'

import { useRouter } from 'vue-router'
import { PageItem_ambiguously_QuestionOrContentChunk } from 'src/interfaces/Store/registration'
import { defineComponent, onMounted, PropType, ref, Ref, watch } from 'vue'
import SeasonSelector from 'src/components/Registration/admin/SeasonSelector.vue'
import CompetitionSelector from 'src/components/Registration/admin/CompetitionSelector.vue'
import GateFunctionEditor from 'src/components/Registration/admin/GateFunctionEditor.vue'
import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'
import { htmlEditButton } from 'quill-html-edit-button'

import { RegistrationStore } from "src/store/Registration"

export default defineComponent({
  components: {
    QuillEditor,
    SeasonSelector,
    GateFunctionEditor,
    CompetitionSelector
  },
  props: {
    item: {
      type: Object as PropType<PageItem_ambiguously_QuestionOrContentChunk>,
      required: true
    }
  },
  setup(props) {
    const contentChunk = ref({}) as Ref<PageItem_ambiguously_QuestionOrContentChunk>
    const seasonUIDs = ref([])
    const competitionUIDs = ref([])
    const customToolbar = ref([
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'formula'],
      ['clean'],
    ])
    const editorSettings = ref({})
    const triggerSave = ref(false)
    const pageItemID = ref('')
    const apiError = ref(false)
    const contentChunkStati = ref({}) as Ref<{[key:string]: string}>


    const router = useRouter()

    const initializeEditorSettings = () => {
      editorSettings.value = {
        modules: {
          htmlEditButton: {
            msg: "You may edit HTML directly here, and the editor's contents will be replaced when you click Save Changes. Please use this feature carefully.",
          },
        },
      }
    }

    const addSeasons = async (contentChunkID: string) => {
      apiError.value=false
      try {
        const response = await axiosInstance.put(`/v1/registration/pageItem/${contentChunkID}/seasons`, {seasons: seasonUIDs.value})
      } catch (error) {
        apiError.value=true
      }
    }

    const createContentChunk =async () => {
      apiError.value=false
      try {
        const response = await axiosInstance.post(`/v1/registration/contentChunk`, {...contentChunk.value})
        return response.data.data
      } catch (error) {
        // console.log('error from saveContentChunk', error)
        apiError.value=true
        throw error
      }
    }

    const setCustomFields = async () => {
      const customFields = await RegistrationStore.getCustomFields()
      RegistrationStore.directCommit_setCustomFields(customFields)
      await RegistrationStore.createCustomFieldsOptionFormat(customFields)
    }

    const updateStatus = (status: string, item: string) => {
      const localContentChunkStati = JSON.parse(JSON.stringify(contentChunkStati.value))
      localContentChunkStati[item]=status
      contentChunkStati.value=localContentChunkStati
    }

    const createPageItem = async () => {
      try {
        const createdPageItem = await createContentChunk()
        contentChunk.value=createdPageItem.pageItem
        pageItemID.value = createdPageItem.id as string
        triggerSave.value = true
        updateStatus('saved', 'contentChunk')
      } catch (err) {
        // console.log(err)
      }
    }


    watch(contentChunk, (val, prevVal) => {
      if(!prevVal) contentChunkStati.value.contentChunk='pending'
    }, {deep:true})

    watch([triggerSave, contentChunkStati], (val: [boolean, {[key: string]: string}])=> {
      if(val[0]) {
        let allPropertiesStatus = 'saved'
        for(const status in val[1]) {
          if(val[1][status]!="saved") {
            allPropertiesStatus = 'pending'
          }
        }
        if(allPropertiesStatus==='saved') {
          if(!apiError.value) {
            router.push({name: 'registration-admin'}).catch((err) => {
              // console.log('err', err)
            })
          }
        }
      }
    }, {deep:true})

    watch(apiError, (val)=> {
      console.log('apiError', val)
      if(val) {
        triggerSave.value = false
      }
    })

    onMounted(async () => {
      initializeEditorSettings()
      await setCustomFields()
      contentChunk.value = props.item
    })

    return {
      contentChunk,
      addSeasons,
      customToolbar,
      editorSettings,
      triggerSave,
      initializeEditorSettings,
      createContentChunk,
      createPageItem,
      seasonUIDs,
      pageItemID,
      competitionUIDs,
      apiError,
      updateStatus,
      contentChunkStati
    }
  },
})
</script>

