/* this is an upgraded version of authService. It accepts
 userData instead of just the roles so competition manager's various roles can be verified.
 This is intended to replace authService when time allows
*/

import { CompetitionsMemento, UserData } from "src/interfaces/Store/user"

const arrayItemsToLower = (arr: string[]) => {
  const lowerArray: string[] = []
  for(let i=0; i<arr.length; i++) {
    lowerArray.push(arr[i].toLowerCase())
  }
  return lowerArray
}

const verifyProvidedRoles = (userRoles: string[], roles: string[]): boolean => {
  const lowerCaseUserRoles = arrayItemsToLower(userRoles)
  const lowerCaseRoles = arrayItemsToLower(roles)
  for (let i = 0; i < lowerCaseUserRoles.length; i++) {
    if (lowerCaseRoles.includes(lowerCaseUserRoles[i]) || lowerCaseUserRoles.includes('inleague') || (lowerCaseUserRoles.includes('webmaster') && !(lowerCaseRoles.length===1 && lowerCaseRoles[0]==='inleague'))) {
      return true
    }
  }
  return false
}

// requiredCompRoles should be in the format of: { nameOfRole: [] } where nameOfRole is the corresponding key in the CompetitionsMemento, e.g.isVolunteerAdmin
const verifyCompRoles = (userCompRoles: CompetitionsMemento[], requiredCompRoles: Record<string, string[]>|undefined) => {
  // if requireCompRoles are not defined, return false
  if(!requiredCompRoles) return false

  for(const compRole in requiredCompRoles) {
    for(let i=0; i<userCompRoles.length; i++) {
      // competitionUIDs are present and this role is limited to provided IDs
      if(requiredCompRoles[compRole].length && userCompRoles[i][compRole as keyof CompetitionsMemento] && requiredCompRoles[compRole].includes(userCompRoles[i].competitionUID)) {
        return true
      } else if(requiredCompRoles[compRole].length===0 && userCompRoles[i][compRole as keyof CompetitionsMemento]) {
        return true
      }
    }
  }

  return false
}

export default function(userData: UserData, requiredRoles: string[], compManagerRoles?: Record<string, string[]> ) {
  return verifyProvidedRoles(userData.roles, requiredRoles) || verifyCompRoles(userData.competitionsMemento, compManagerRoles)
}
