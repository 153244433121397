<template lang="pug">
template(v-if='ready')
  div(class="mb-6")
    RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")

  h2.mb-4 Before registering players, please confirm contact information and volunteer preferences for {{ currentlyTargetedUserName }}:

  template(v-if='step === 1 && stepDependentState[1]')
    div Contact information review
    UserContactVerificationImpl(
      @confirmed='handleConfirmedUserContactInfo',
      @user-contact-verification-impl-goto-user-editor="handleUserContactVerificationImplGotoUserEditor"
      :volunteerDetails='stepDependentState[1].volunteerDetails'
    )

  template(v-if='step === 2 && stepDependentState[2]')
    div Volunteer Roles
    FormKit(
      type='form',
      @submit='handleVolunteerRolesSubmit',
      submit-label='Update Volunteer Details'
    )
      SelectVolunteerRolesImpl(
        name='nestedRolesImpl',
        :seasonUID='seasonUID',
        :volunteerID='stepDependentState[2].volunteerDetails.ID',
        :volunteerDetails='stepDependentState[2].volunteerDetails'
      )

  template(v-else-if='step === 3 && stepDependentState[3]')
    div Volunteer registration name and date of birth update
    UserNameAndDob(
      @confirmed='handleNoStackKeyNeededNameAndDobUpdate',
      :volunteerID='stepDependentState[3].userID',
      :seasonUID='seasonUID'
    )

  template(v-else-if='step === 4 && stepDependentState[4]')
    div Volunteer ELAs
    VolunteerELAs(
      @complete='handleELAsComplete',
      :volunteerID='stepDependentState[4].userID',
      :seasonUID='seasonUID'
    )

  template(v-else-if='step === 5 && stepDependentState[5]')
    VolunteerConfirmationImpl(
      :seasonUID='seasonUID',
      :volunteerID='stepDependentState[5].userID'
    )

    FormKit(type='button', :label='finishLastStepLabel', @click='nextUser')
</template>

<script lang="ts">
// vue
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

// components
import UserContactVerificationImpl from 'src/components/VolunteerRegistration/UserContactVerificationImpl.vue'
import SelectVolunteerRolesImpl from 'src/components/VolunteerRegistration/SelectRolesImpl.vue'
import UserNameAndDob from 'src/components/VolunteerRegistration/UserNameAndDob.vue'
import VolunteerELAs from 'src/components/VolunteerRegistration/VolunteerELAs.vue'
import VolunteerConfirmationImpl from 'src/components/VolunteerRegistration/VolunteerConfirmationImpl.vue'

// inleague
import { assertNonNull } from 'src/helpers/utils'

import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import {
  processRoles,
  VolunteerRoleSelection,
} from 'src/components/VolunteerRegistration/SelectRolesUtils'
import * as ilapi from 'src/composables/InleagueApiV1'
import { ContactAndVolunteerDetailsUpdateFlow } from 'src/composables/registration'
import { ContactAndVolunteerDetailsUpdateFlowState } from 'src/interfaces/Store/registration'
import { VolunteerDetails } from 'src/interfaces/volunteer'
import { datePickerFormat } from 'src/helpers/formatDate'
import { HasNoStackKeyNeedsNameAndDobEdit } from 'src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route'
import { User } from 'src/interfaces/InleagueApiV1'
import * as R_ContactAndVolunteerDetailsUpdateFlow from "src/components/Registration/selections/R_ContactAndVolunteerDetailsUpdateFlow.route"
import { getPlayer } from 'src/composables/InleagueApiV1'
import * as R_SelectCompetitions from 'src/components/Registration/selections/R_SelectCompetitions.route'
import * as RegistrationJourneyBreadcrumb from 'src/components/Registration/RegistrationJourneyBreadcrumb'

type StepDependentState = [
  never, // we want 1-indexing to match the step-indexing scheme (also 1-indexed)
  undefined | { volunteerDetails: VolunteerDetails },
  undefined | { volunteerDetails: VolunteerDetails },
  undefined | { userID: string },
  undefined | { userID: string },
  undefined | { userID: string }
]

export default defineComponent({
  props: R_ContactAndVolunteerDetailsUpdateFlow.propsDef,
  components: {
    UserContactVerificationImpl,
    UserNameAndDob,
    SelectVolunteerRolesImpl,
    VolunteerELAs,
    VolunteerConfirmationImpl,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement
  },
  setup(props, context) {
    const router = useRouter()


    const ready = ref(false)
    // not a ref, never changes, we don't react to it
    let flowStateOnEntry!: /*definitely assigned in onMounted*/ ContactAndVolunteerDetailsUpdateFlowState

    onMounted(async () => {
      const {familyID} = await getPlayer(axiosInstance, {childID: props.childID})
      const maybe_flowStateOnEntry =
        await ContactAndVolunteerDetailsUpdateFlow.getFlowState(
          axiosInstance,
          props.childID,
          familyID,
          props.seasonUID
        )

      if (maybe_flowStateOnEntry === undefined) {
        // we don't expect to hit this except on a bad route URL
        await router.replace({
          name: 'select-player',
          params: { seasonUID: props.seasonUID },
        })
        return
      } else {
        flowStateOnEntry = maybe_flowStateOnEntry
      }

      await updateStateInResponseToStepChange(props.step, Direction.unknown)

      ready.value = true
    })

    const stepDependentState = ref<StepDependentState>([
      void 0 as never, // unused, to support 1-indexing
      undefined, // 1 contact info confirmation
      undefined, // 2 volunteer role selection
      undefined, // 3 volunteer registration name and date of birth update
      undefined, // 4 ELAs
      undefined, // 5 flow-completion
    ])

    const LAST_STEP = stepDependentState.value.length - 1

    let busy = false // try to guard against quick successive double clicks on child forms
    const withBusyGuard = /* extends clause prevents JSX ambiguity */ <T extends any>(f: () => T) => {
      if (busy) {
        return
      }
      try {
        busy = true
        return f()
      } finally {
        busy = false
      }
    }

    enum Direction {
      forward,
      backward,
      unknown,
    }

    const updateStateInResponseToStepChange = async (
      newStep: number,
      dir: Direction
    ) => {
      switch (newStep) {
        case 1: // 1 contact info confirmation
          stepDependentState.value[1] = undefined
          stepDependentState.value[1] = {
            volunteerDetails: await getCurrentTargetVolunteerDetails(),
          }
          break
        case 2: // volunteer role selection
          stepDependentState.value[2] = undefined
          stepDependentState.value[2] = {
            volunteerDetails: await getCurrentTargetVolunteerDetails(),
          }
          break
        case 3: // volunteer registration name and date of birth update
          stepDependentState.value[3] = undefined
          stepDependentState.value[3] = {
            userID:
              flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
          }

          // if we've come from the prior step
          // and the target user has no stack record key
          // and the target user "requires volunteer ayso registration"
          // we do run step 3 (name and dob validation)
          // otherwise we can jump to 4 (elas)
          if (dir === Direction.forward) {
            //
            // Don't reuse the prior step's volunteer details,
            // because assigning volunteer {roles,codes} in the previous step is allowed to update the user's stackRecordKey
            // (or more specifically, it is allowed to assign one if it does not already exist).
            // To save an http request, we could probably check if the cached copy in stepDependentState has
            // a `stackRecordKey` already, and assume that although the server may assign a `stackRecordKey`,
            // it will never clear it if it does already exist.
            //
            const stackRecordKey = (await getCurrentTargetVolunteerDetails()).stackRecordKey

            if (!stackRecordKey || stackRecordKey.trim() === '') {
              const volunteerSeasonStatus =
                await ilapi.getVolunteerSeasonStatus(
                  axiosInstance,
                  flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
                  props.seasonUID
                )
              if (volunteerSeasonStatus.registrationRequired) {
                // done, run this step
                break
              }
            }
          }

          // didn't need to run this step, jump to step 4
          await router.replace(routerArgsForNextStep(4))

          break
        case 4: // ELAs
          stepDependentState.value[4] = undefined
          stepDependentState.value[4] = {
            userID:
              flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
          }

          // if we've come from the prior step
          // and "registrationRequired" is here false, meaning ELAs are not required
          // we can jump to step 5
          if (dir === Direction.forward) {
            const volunteerSeasonStatus = await ilapi.getVolunteerSeasonStatus(
              axiosInstance,
              flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
              props.seasonUID
            )
            if (!volunteerSeasonStatus.registrationRequired) {
              await router.replace(routerArgsForNextStep(5))
              break
            }
          }

          break
        case 5: // flow-completion
          stepDependentState.value[5] = undefined
          stepDependentState.value[5] = {
            userID:
              flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
          }
          break
      }

      async function getCurrentTargetVolunteerDetails() {
        const v = await ilapi.getVolunteerDetails(
          axiosInstance,
          flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
          props.seasonUID
        )
        return inplaceMungeGetVolunteerDetailsForLocalFormRequirements(v)
      }

      // patch some VolunteerDetails obj to fixup dates for required form format
      function inplaceMungeGetVolunteerDetailsForLocalFormRequirements(
        v: VolunteerDetails
      ) {
        v.dob = datePickerFormat(v.dob)
        return v
      }
    }

    watch(
      () => props.step,
      async (newStep, oldStep) => {
        let dir: Direction

        if (newStep === 1 && oldStep === LAST_STEP) {
          // stepped to next user in list
          dir = Direction.forward
        } else if (newStep < oldStep) {
          dir = Direction.backward
        } else if (newStep > oldStep) {
          dir = Direction.forward
        } else {
          throw 'unreachable'
        }

        await updateStateInResponseToStepChange(newStep, dir)
      }
    )
    watch(
      () => props.userListIndex,
      async () => {
        // no-op
        // we assume if userListIndex is changing, it's because we moved forward or backward, which means step necessarily changed,
        // and the step-change watcher will take care of it
      }
    )

    function routerArgsForNextStep(
      step: number,
      userListIndex = props.userListIndex
    ) {
      // type seems to indicate it would be ok w/ numbers,
      // but there is a part in the router `matcher.stringify(params)` that does
      // `if(!text && !optional)` and if we pass a `0` then that fails the `!text` check
      // tldr: convert params to strings
      return {
        ...router.currentRoute.value,
        params: {
          ...router.currentRoute.value.params,
          userListIndex: userListIndex.toString(),
          step: step.toString(),
        },
      }
    }

    /**
     * step 1 completion handler, contact info
     */
    const handleConfirmedUserContactInfo = async () => {
      await withBusyGuard(async () => {
        try {
          await router.push(routerArgsForNextStep(2))
        } catch (error) {}
      })
    }

    const handleUserContactVerificationImplGotoUserEditor = async (/*no args*/) => {
      const targetUserID = flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID;
      await router.push({name: 'user-editor', params: {userID: targetUserID}})
    }

    /**
     * step 2 completion handler, choose volunteer roles
     */
    const handleVolunteerRolesSubmit = async ({
      nestedRolesImpl: roles,
    }: {
      nestedRolesImpl: VolunteerRoleSelection
    }) => {
      try {
        const submittableRoles = processRoles(roles)
        const submittableComments = {
          volunteerComments: roles.volunteerComments
            ? roles.volunteerComments
            : '',
        }

        const stepState = stepDependentState.value[2]
        assertNonNull(stepState, 'on step 2, state 2 must be defined')

        await ilapi.updateVolunteerDetails(
          axiosInstance,
          stepState.volunteerDetails.ID,
          props.seasonUID,
          submittableRoles
        )
        await ilapi.updateVolunteerComments(
          axiosInstance,
          stepState.volunteerDetails.ID,
          props.seasonUID,
          submittableComments
        )

        await router.push(routerArgsForNextStep(3))
      } catch (error) {}
    }

    /**
     * step 3 completion handler
     * name and dob
     */
    const handleNoStackKeyNeededNameAndDobUpdate = async (
      data: HasNoStackKeyNeedsNameAndDobEdit
    ) => {
      try {
        await ilapi.updateUserNameAndDob(
          axiosInstance,
          flowStateOnEntry.usersRequiringUpdate[props.userListIndex].ID,
          // assertion here is expected safe because we shouldn't have been given an empty string;
          // however, form state allows an empty string
          data as HasNoStackKeyNeedsNameAndDobEdit & {gender: "M" | "F"}
        )
        await router.push(routerArgsForNextStep(4))
      }
      catch (err) {
        // a lot of noisy 400s when the user submits duplicate user info, we don't really need to log such errors
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    /**
     * step 4 completion handler, ELAs
     */
    const handleELAsComplete = async () => {
      // the ELA component handles saving whatever it needs to save,
      // we just need to advance to next step when it says it's done
      await router.push(routerArgsForNextStep(5))
    }

    /**
     * step 5 completion handler
     * move to next user
     * if we're on the last user, this routes to the rest of the registration flow
     */
    const nextUser = async () => {
      if (
        props.userListIndex ===
        flowStateOnEntry.usersRequiringUpdate.length - 1
      ) {
        await router.push(R_SelectCompetitions.routeDetailToRouteLocation(R_SelectCompetitions.RouteNames.selectCompetitions, {
          playerID: props.childID,
          seasonUID: props.seasonUID
        }))
      } else {
        await router.push(routerArgsForNextStep(1, props.userListIndex + 1))
      }
    }

    const finishLastStepLabel = computed(() => {
      if (
        props.userListIndex <
        flowStateOnEntry.usersRequiringUpdate.length - 1
      ) {
        const nextUserMeta =
          flowStateOnEntry.usersRequiringUpdate[props.userListIndex + 1]
        return `Continue to ${nextUserMeta.firstName} ${nextUserMeta.lastName}`
      } else {
        return 'Continue with registration'
      }
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.contactInfoAndVolunteerPrefs,
          seasonUID: props.seasonUID,
          playerID: props.childID
        }
      }
    })

    return {
      usersRequiringUpdate: () => flowStateOnEntry.usersRequiringUpdate,
      stepDependentState,
      handleConfirmedUserContactInfo,
      handleUserContactVerificationImplGotoUserEditor,
      handleVolunteerRolesSubmit,
      handleNoStackKeyNeededNameAndDobUpdate,
      handleELAsComplete,
      nextUser,
      finishLastStepLabel,
      ready,
      currentlyTargetedUserName: computed(() => {
        const user : User = flowStateOnEntry.usersRequiringUpdate[props.userListIndex];
        return `${user.firstName} ${user.lastName}`;
      }),
      registrationJourneyBreadcrumbProps
    }
  },
})
</script>
