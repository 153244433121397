<template>
  <div
    class="fullscreen bg-blue text-center q-pa-md flex flex-center"
    data-cy="route_not-authorized"
  >
    <div>
      <div style="font-size: 30vh">403</div>

      <div class="text-h2" style="opacity:.4">Oops. You don't have permission to view this page...</div>

      <q-btn
        class="q-mt-xl"
        color="white"
        text-color="blue"
        unelevated
        to="/"
        label="Go Home"
        no-caps
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Error 404',
})
</script>
