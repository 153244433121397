<template lang="pug">
FormKit(type="form" :actions="false" @submit="handleSubmit" data-test="EventForm")
  div.grid.grid-cols-2.gap-2(class="[&>div]:px-4" v-if="ready")
    div.col-span-2
      h2 {{ bannerMsg }}
      div.col-span-2.mt-0.text-sm
        MaybeCloneLink(v-bind="props")

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Event title
    div
      FormKit(
        type="text" v-model="form.eventName" autocomplete="off"
        name="Event title"
        :validation="formValidation.eventName"
        data-test="eventName"
      )

    div All day event
    div
      FormKit(
        v-model="form.allDay"
        type="radio"
        fieldset-class="$reset"
        option-class="p-1"
        :options="[yesOption, noOption]"
        data-test="allDay"
      )

    FormKit(
      type="group"
      :validation=[['eventStartEndConstraint']]
      :validation-rules="{eventStartEndConstraint}"
      validation-visibility="live"
      #default="{messages}"
    )
      div
        div.flex.items-start.mt-1
          div.flex.items-center
            //- dot if required
            span.mr-2.text-green-500(style="font-size:.5em;")
              font-awesome-icon(icon="fa-solid fa-circle")
            span Event start date
        //- a truthy `messages.rule_X` means rule `X` failed validation
        div.text-sm.text-red-500(v-if="messages?.rule_eventStartEndConstraint" data-test="eventStartEndConstraintMessage")
            | Event start must be on or before event end
      div
        FormKit(
          type="date"
          v-model="form.eventStart.date"
          name="Event start date"
          :validation="formValidation.eventStart.date"
          data-test="eventStart.date"
        )
        FormKit(
          v-if="!form.allDay"
          type="time"
          name="Event start time"
          v-model="form.eventStart.time"
          :validation="formValidation.eventStart.time"
          data-test="eventStart.time"
        )

      div
        div Event end date
        div.text-sm.text-red-500(v-if="messages?.rule_eventStartEndConstraint")
          | Event end must be on or after event start
      div
        FormKit(
          type="date"
          name="Event end date"
          outer-class="$reset mb-1"
          v-model="form.eventEnd.date"
          data-test="eventEnd.date"
        )
        div.mb-1
          span (leave blank if same as start date)
          span.ml-1.text-blue-700
            span (
            span.cursor-pointer.underline(@click="form.eventEnd.clearDate()") clear
            span )
        FormKit(
          v-if="!form.allDay"
          type="time"
          name="Event end time"
          v-model="form.eventEnd.time"
          :validation="formValidation.eventEnd.time"
          data-test="eventEnd.time"
        )

    div.span Google calendar
    div
      FormKit(
        v-if="googleCalendarSelectOptions.connected"
        type="select"
        :options="googleCalendarSelectOptions.options"
        v-model="form.gCalID"
        data-test="allDay"
      )
      div.shadow-md.text-xs.p-2.bg-yellow-100.mb-2(v-else) We couldn't connect to your Google calendars with the current configuration. Please contact your administrator.

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Event season
    div
      FormKit(
        type="select"
        name="Event season"
        :options="seasonSelectOptions"
        v-model="form.seasonUID"
        :validation="formValidation.seasonUID"
        data-test="seasonUID"
      )

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Event Program (used for age eligibility)
    div
      FormKit(
        type="select"
        name="Event competition"
        :options="competitionSelectOptions"
        v-model="form.competitionUID"
        :validation="formValidation.competitionUID"
        data-test="competitionUID"
      )

    div
      div Team event
    div
      div(class="flex items-center mb-2")
        //- we assume the user is either a Registrar or a headcoach by the time we get here.
        //- If they are a registrar, they can make Team and "not team" events.
        //- If they are not a registrar, then they must be a head coach; and they can only make team events
        FormKit(
          v-model="form.isTeamEvent"
          type="radio"
          fieldset-class="$reset"
          outer-class="$reset"
          options-class="flex"
          option-class="$reset p-1 m-0"
          :disabled="!isRegistrar ? true : undefined"
          :options="[{...yesOption, attrs:{'data-test': 'isTeamEventYes'}}, {...noOption, attrs: {'data-test': 'isTeamEventNo'}}]"
        )
        template(v-if="!isRegistrar")
          template(v-if="mode === Mode_t.NEW || mode === Mode_t.CLONE")
            div(class="ml-2 text-xs text-gray-400" data-test="isTeamEvent-disabledDetail") (only registrars can create non-team events)
          template(v-else-if="mode === Mode_t.EDIT")
            div(class="ml-2 text-xs text-gray-400" data-test="isTeamEvent-disabledDetail") (only registrars can edit this value)
          template(v-else)
            //- unreachable

      div(v-if="form.isTeamEvent")
        div(v-if="form.seasonUID")
          FormKit(type="select" :options="teamSelectOptions" v-model="form.teamID" name="Team" validation="required")
        div(v-else)
          FormKit(type="select" :options="[{label: 'Select a season', value: ''}]" name="Team" validation="required" disabled)

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Location
    div
      EventLocationSelectAndMaybeFreeform(
        :eventVenueSelectOptions = "eventVenueSelectOptions"
        :formEventLocation = "form.eventLocation"
      )

    div Category
    div
      FormKit(
        type="select"
        :options="eventCategorySelectOptions"
        v-model="form.eventCategory"
        data-test="eventCategory"
      )

    div.col-span-2.mb-2
      div.mb-2 Description / comments:
      div.px-2.mb-3
        quill-editor.bg-white(
          v-model:value='form.comments',
          data-test="comments'"
        )

    div Region sponsor:
    div
      FormKit(type="text" v-model="form.regionSponsor" autocomplete="off" data-test="regionSponsor")

    div.col-span-2
      h2 Event Contact

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Contact name
    div
      FormKit(
        type="text"
        name="Contact name"
        v-model="form.contactName"
        autocomplete="off"
        :validation="formValidation.contactName"
        data-test="contactName"
      )

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Contact email
    div
      FormKit(
        type="email"
        v-model="form.contactEmail"
        autocomplete="off"
        name="Contact email"
        :validation="formValidation.contactEmail"
        data-test="contactEmail"
      )

    div Contact phone
    div
      FormKit(type="text" v-model="form.contactPhone" autocomplete="off" data-test="contactPhone")

    div.col-span-2
      h2 Event Signup and Fees

    div Event signup
    div
      FormKit(
        v-model="form.allowSignups"
        type="radio"
        fieldset-class="$reset"
        option-class="p-1"
        :options="allowSignupsRadioOptions"
      )

    div Fee for adults
    div
      template(v-if="!isRegistrar")
        div.text-xs(data-test="feeUser-disabledDetail")
          template(v-if="mode === Mode_t.NEW || mode == Mode_t.CLONE")
            span (Event fees can only be assigned by registrars)
          template(v-else-if="mode === Mode_t.EDIT")
            span (Event fees can only be modified by registrars)
          template(v-else)
            //- unreachable

      //- n.b. loose equality
      template(v-else-if="form.allowSignups == SignupAllowanceType.usersOnly || form.allowSignups == SignupAllowanceType.usersAndPlayers")
        FormKit(
          type="number"
          v-model="form.feeUser"
          outer-class="$reset mb-1"
          step="0.01" min="0"
          data-test="feeUser"
          :disabled="!isRegistrar ? true : undefined"
        )
        .text-sm (leave blank for no fee)
      template(v-else)
        div.text-xs (Adult / User Signups Disabled)

    div Fee for players
    div
      template(v-if="!isRegistrar")
        div.text-xs(data-test="feePlayer-disabledDetail")
          template(v-if="mode === Mode_t.NEW || mode == Mode_t.CLONE")
            span (Event fees can only be assigned by registrars)
          template(v-else-if="mode === Mode_t.EDIT")
            span (Event fees can only be modified by registrars)
          template(v-else)
            //- unreachable

      //- n.b. loose equality
      template(v-else-if="form.allowSignups == SignupAllowanceType.playersOnly || form.allowSignups == SignupAllowanceType.usersAndPlayers")
        FormKit(
          type="number"
          v-model="form.feePlayer"
          outer-class="$reset mb-1"
          step="0.01" min="0"
          data-test="feePlayer"
          :disabled="!isRegistrar ? true : undefined"
        )
        .text-sm.mb-1 (leave blank for no fee)
      template(v-else)
        div.text-xs (Player Signups Disabled)
    div
      div Limit to families of players registered in
      div.cursor-pointer.text-xs.underline.text-blue-700(
        v-if="form.adultLimitSeasons.length > 0"
        @click="() => form.adultLimitSeasons = []"
      )
        | Clear current selection (as in, "do not limit")
    div
      FormKit(
        type="select" :select-icon="false" multiple
        :size="Math.min(limitToParticularSeasonSelectOptions.byFamilyHavingPlayerRegisteredIn.length, 5)"
        :options="limitToParticularSeasonSelectOptions.byFamilyHavingPlayerRegisteredIn"
        v-model="form.adultLimitSeasons"
        data-test="adultLimitSeasons"
      )

    div
      div Limit to players registered in
      div.cursor-pointer.text-xs.underline.text-blue-700(
        v-if="form.playerLimitSeasons.length > 0"
        @click="() => form.playerLimitSeasons = []"
      )
        | Clear current selection (as in, "do not limit")
    div
      FormKit(
        type="select" :select-icon="false" multiple
        :size="Math.min(limitToParticularSeasonSelectOptions.byPlayerIsRegisteredIn.length, 5)"
        :options="limitToParticularSeasonSelectOptions.byPlayerIsRegisteredIn"
        v-model="form.playerLimitSeasons"
        data-test="playerLimitSeasons"
      )

    div
      div Limit to children in division
      div.cursor-pointer.text-xs.underline.text-blue-700(
        v-if="form.divisions.length > 0"
        @click="() => form.divisions = []"
      )
        | Clear current selection (as in, "do not limit")
    div
      FormKit(
        type="select" :select-icon="false" multiple
        :size="Math.min(limitToParticularDivisionSelectOptions.byPlayerIsInDivision.length, 5)"
        :options="limitToParticularDivisionSelectOptions.byPlayerIsInDivision"
        v-model="form.divisions"
        data-test="divisions"
      )

    div.flex.items-start.mt-1
      div.flex.items-center
        //- dot if required
        span.mr-2.text-green-500(style="font-size:.5em;")
          font-awesome-icon(icon="fa-solid fa-circle")
        span Merchant account
    div
      FormKit(
        type="select"
        :options="paymentGatewaySelectOptions"
        v-model="form.gatewayID"
        name="Merchant account"
        :validation="formValidation.gatewayID"
        data-test="gatewayID"
      )

    div Maximum attendance
    div
      FormKit(
        type="number"
        v-model="form.maxCount"
        outer-class="$reset mb-1"
        step="1" min="0"
        name="Maximum attendance"
        :validation='[["min", 0]]'
        data-test="maxCount"
      )
      .text-sm.mb-1 (leave blank for no maximum)

    div Text to include in confirmation emails
    div
      FormKit(type="textarea" autocomplete="off" v-model="form.emailText" data-test="emailText")

    div Send sign-up notification emails to
    div
      FormKit(type="text" v-model="form.notificationEmails" data-test="notificationEmails")

    div
      div Select any custom questions to display on the registration form
      div
        //- we should get all the global store state stuff sorted out, and then we could
        //-  - save the question form
        //-  - rehydrate it on nav back from editing questions
        //-  - also reflect any question changes on the form
        //- we don't have the store state sorted out yet, so we need to push the user to another page,
        //- where the implication is "changes made in the new tab won't be reflected in the current tab until a refresh"
        router-link(
          target="_blank"
          :to='{ name: "event-questions" }',
        )
          span.underline.text-blue-700.text-sm Edit event questions
    div
      FormKit(
        v-model="form.questions"
        type="checkbox"
        fieldset-class="$reset"
        option-class="p-1"
        :options="questionSelectOptions"
        data-test="questions"
      )

    div.col-span-2.mt-4
      t-btn(type="submit" data-test="submit")
        div Save event
</template>

<script lang="ts">
import { defineComponent, watch, computed, ref, onMounted } from "vue"
import { exhaustiveCaseGuard, UiOption, unsafe_objectKeys } from "src/helpers/utils";
import { emitsDef, propsDef, type Props, EventFormData, FormDetail, Mode_t, EventFormUserType } from "./EventForm.ilx";
import { MaybeCloneLink, EventLocationSelectAndMaybeFreeform } from "./EventForm.iltsx"
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { axiosInstance } from "src/boot/axios";

import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'
import { FormKitNode } from "@formkit/core";
import { setTimeParts } from "src/helpers/formatDate"
import authService from 'src/helpers/authService'

import { EventFormOptions } from "src/composables/InleagueApiV1.Event";
import { User } from "src/store/User";

const formValidation = {
  eventName: [["required"], ["length", 2]],
  eventStart: {
    date: [["required"]],
    time: [["required"]], // __not__ required if we v-if it away
  },
  eventEnd: {
    // we'd like to say "require date and time or neither, but not one or the other"
    // we'd also like to say "if supplied, must be after `eventStart`"
    date: [/* not required */],
    time: [["required"]], // won't be required if v-if'd away (by "is all day event"), which is correct
  },
  contactEmail: [["required"], ["email"]],
  gatewayID: [["required"]],
  contactName: [["required"],["length", 3]],
  seasonUID: [["required"]],
  competitionUID: [["required"]]
}

export default defineComponent({
  components: {
    QuillEditor,
    MaybeCloneLink,
    EventLocationSelectAndMaybeFreeform,
  },
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const ready = ref(false);

    // we directly mutate the form data passed in
    const form = ref<EventFormData>(props.detail.data);
    const mode = computed(() => props.detail.type);

    const yesOption : UiOption<boolean> = {label: "Yes", value: true};
    const noOption : UiOption<boolean> = {label: "No", value: false}

    const allowSignupsRadioOptions = [
      // we must stringify the values, because `closed` maps to `0`, which is falsy,
      // which is boolean-equivalent to `""`, which is the value used for "no selection",
      // so, without stringifying, we have:
      // "no selection is indistinguishable from 'Closed' is selected", which is wrong.
      // But uh ... what does it mean to have "no selection" for this field (`allowSignups`)?
      // See "freshFormData" function, we set this to a non-empty-string default.
      {attrs:{"data-test": "allowSignups/closed"}, label: "Closed", value: ilapi.event.SignupAllowanceType.closed.toString()},
      {attrs:{"data-test": "allowSignups/usersOnly"}, label: "Users only", value: ilapi.event.SignupAllowanceType.usersOnly.toString()},
      {attrs:{"data-test": "allowSignups/playersOnly"}, label: "Players only", value: ilapi.event.SignupAllowanceType.playersOnly.toString()},
      {attrs:{"data-test": "allowSignups/usersAndPlayers"}, label: "Users and players", value: ilapi.event.SignupAllowanceType.usersAndPlayers.toString()},
    ] as const;
    const eventCategorySelectOptions = [
      {label: "General", value: ilapi.event.EventCategoryType.General},
      {label: "Referees", value: ilapi.event.EventCategoryType.Referees},
      {label: "Coaches", value: ilapi.event.EventCategoryType.Coaches},
      {label: "Tryouts", value: ilapi.event.EventCategoryType.Tryouts},
      {label: "Training", value: ilapi.event.EventCategoryType.Training},
      {label: "Board", value: ilapi.event.EventCategoryType.Board},
    ] as const;

    const seasonSelectOptions = ref<UiOption[]>([])
    const competitionSelectOptions = ref<UiOption[]>([])
    const teamSelectOptions = ref<UiOption[]>([])
    const eventVenueSelectOptions = ref<UiOption[]>([])
    const questionSelectOptions = ref<UiOption[]>([])
    const limitToParticularSeasonSelectOptions = ref({
      byFamilyHavingPlayerRegisteredIn: [] as UiOption[],
      byPlayerIsRegisteredIn: [] as UiOption[]
    });
    const limitToParticularDivisionSelectOptions = ref({
      byPlayerIsInDivision: [] as UiOption[]
    })
    const paymentGatewaySelectOptions = ref<UiOption[]>([])
    const googleCalendarSelectOptions = ref<MaybeGoogleCalendarSelectOptions>({connected: false});

    onMounted(async () => {
      const mungedOptions = await (async () => {
        const eventFormOptions = await getEventFormOptions(getExistingEventIdFromProps(props.detail));
        return eventFormOptionsAsUiOptions(eventFormOptions);
      })();

      eventVenueSelectOptions.value = mungedOptions.eventVenues;
      questionSelectOptions.value = mungedOptions.eventQuestions;
      seasonSelectOptions.value = mungedOptions.seasonOptions;
      competitionSelectOptions.value = mungedOptions.competitionOptions;
      limitToParticularSeasonSelectOptions.value = mungedOptions.limitToParticularSeasonOptions;
      limitToParticularDivisionSelectOptions.value = mungedOptions.limitToParticularDivisionOptions;
      paymentGatewaySelectOptions.value = mungedOptions.paymentGatewayOptions;
      googleCalendarSelectOptions.value = mungedOptions.googleCalendarOptions;

      // TODO/FIXME: this should be part of FormData construction, which means
      // loading the options is not our responsibility here; we need to have form options in order
      // to construct the form data. In the mean time, this is an easy enough workaround to offer default
      // values dependent on dynamic available options.
      if (props.detail.type === "make-new") {
        form.value.seasonUID = seasonSelectOptions.value[0]?.value || ""; // we should always get at least one option right?
        form.value.competitionUID = competitionSelectOptions.value[0]?.value || ""; // ^^ ditto
      }

      ready.value = true;
    });

    const handleSubmit = () => {
      emit("doSubmit", props.detail);
    }

    /**
     * formkit validation rules only become reactive if we touch the node values?
     * it doesn't work like a vue computed where anything touched becomes part of the
     * dependency graph?
     */
    const WEIRD_FORMKIT_GROUP_VALIDATION_CONSTRAINT_WORKAROUND = (node: FormKitNode) => {
      for (const key of Object.keys(node.value as any)) {
        const _touch = (node.value as any)[key]
      }
    }

    /**
     * checks validity of eventStart / eventEnd values
     */
    const eventStartEndConstraint = (node: FormKitNode) : boolean => {
      // true here means "we don't know, so we'll say it passed, so we don't show an error that
      // doesn't really make sense if the form data isn't valid"
      const INDETERMINATE_SO_THIS_RULE_IS_IRRELEVANT = true;

      WEIRD_FORMKIT_GROUP_VALIDATION_CONSTRAINT_WORKAROUND(node);

      if (form.value.allDay) {
        const start = form.value.eventStart.getDayjsDate();
        const end = form.value.eventEnd.getDayjsDate();
        if (!start || !end) {
          return INDETERMINATE_SO_THIS_RULE_IS_IRRELEVANT;
        }
        return end.isSame(start, "day") || end.isAfter(start, "day");
      }
      else {
        if (!form.value.eventEnd.getDayjsTime()) {
          return INDETERMINATE_SO_THIS_RULE_IS_IRRELEVANT;
        }
        const start = form.value.eventStart.getDayjsDatetime();
        if (!start) {
          return INDETERMINATE_SO_THIS_RULE_IS_IRRELEVANT;
        }
        const end = (() => {
          const datetime = form.value.eventEnd.getDayjsDatetime();
          if (datetime) {
            return datetime;
          }
          // end date can be blank, meaning "same as start"
          const time = form.value.eventEnd.getDayjsTime();
          if (time) {
            return setTimeParts(start, time);
          }
          return null;
        })();

        if (!end) {
          return INDETERMINATE_SO_THIS_RULE_IS_IRRELEVANT;
        }

        return end.isSame(start, "second") || end.isAfter(start, "second");
      }
    }

    const isRegistrar = computed(() => authService(User.value.roles, "registrar", "eventAdmin"));

    watch(() => [form.value.isTeamEvent, form.value.seasonUID], async () => {
      if (form.value.isTeamEvent && form.value.seasonUID !== "") {
        teamSelectOptions.value = await getTeamOptions(
          getExistingEventIdFromProps(props.detail),
          form.value.seasonUID,
          props.detail.eventFormUserType
        );
      }
      else {
        // no-op
      }
    }, {immediate: true})

    return {
      bannerMsg: bannerMsg(props.detail.type),
      form,
      // options (group as object?)
      yesOption,
      noOption,
      allowSignupsRadioOptions,
      questionSelectOptions,
      eventCategorySelectOptions,
      seasonSelectOptions,
      competitionSelectOptions,
      teamSelectOptions,
      eventVenueSelectOptions,
      limitToParticularSeasonSelectOptions,
      limitToParticularDivisionSelectOptions,
      paymentGatewaySelectOptions,
      googleCalendarSelectOptions,
      // end options
      ready,
      handleSubmit,
      formValidation,
      eventStartEndConstraint,
      SignupAllowanceType: ilapi.event.SignupAllowanceType,
      isRegistrar,
      Mode_t,
      mode,
      // we forward props to some children
      props
    }
  }
})

function getExistingEventIdFromProps(v: FormDetail) {
  switch (v.type) {
      case "make-new": return undefined;
      case "edit-existing": return v.eventID;
      case "clone-existing": return v.sourceEventID;
      default: exhaustiveCaseGuard(v);
  }
}

async function getEventFormOptions(existingEventID: undefined | iltypes.Guid) {
  return await ilapi.event.getEventFormOptions(axiosInstance, existingEventID);
}

function eventFormOptionsAsUiOptions(options: ilapi.event.EventFormOptions) {
  const eventQuestions = eventQuestionsAsSelectOptions(options.eventQuestions);
  const eventVenues = eventVenuesAsUiOptions(options.eventVenues);
  const seasonOptions = seasonsAsUiOptions(options.seasonOptions, "seasonUID");
  const competitionOptions = competitionsAsUiOptions(options.competitionOptions);
  const limitToParticularSeasonOptions = {
    byFamilyHavingPlayerRegisteredIn: seasonsAsUiOptions(options.limitToParticularSeasonOptions.byFamilyHavingPlayerRegisteredIn, "seasonID"),
    byPlayerIsRegisteredIn: seasonsAsUiOptions(options.limitToParticularSeasonOptions.byPlayerIsRegisteredIn, "seasonID"),
  }
  const limitToParticularDivisionOptions = {
    byPlayerIsInDivision: divisionsAsUiOptions(options.limitToParticularDivisionOptions.byPlayerIsInDivision)
  }
  const paymentGatewayOptions = paymentGatewaysAsUiOptions(options.paymentGateways);
  const googleCalendarOptions = googleCalendarListingAsUiOptions(options.googleCalendars);

  return {
    eventQuestions,
    eventVenues,
    seasonOptions,
    competitionOptions,
    limitToParticularSeasonOptions,
    limitToParticularDivisionOptions,
    paymentGatewayOptions,
    googleCalendarOptions,
  }
}

function seasonsAsUiOptions(seasons: iltypes.Season[], whichField: "seasonID" | "seasonUID", withNilOption = false) : UiOption[] {
  const ret = seasons
    .sort((l,r) => l.seasonID < r.seasonID ? 1 : -1) // most recent first
    .map(season => ({
      label: season.seasonName,
      value: season[whichField].toString() // force to string to unify integer/Guid value types
    }));

    if (withNilOption) {
      ret.unshift({label: "", value: ""})
    }

    return ret;
}

function competitionsAsUiOptions(competitions: EventFormOptions["competitionOptions"]) : UiOption[] {
  return competitions
    .sort((l,r) => l.competitionID < r.competitionID ? -1 : 1)
    .map(v => {
      return {
        label: v.competition,
        value: v.competitionUID,
      }
    })
}

function eventVenuesAsUiOptions(eventVenues: ilapi.event.EventVenue[]) : UiOption[] {
  const ret = eventVenues
    .sort((l,r) => {
      return l.name.toLowerCase() < r.name.toLowerCase() ? -1 : 1;
    })
    .map(venue => ({label: venue.name, value: venue.venueID}));

  const useFreeformTag : EventFormData["eventLocation"]["venueID"] = "OTHER"

  ret.push({label: "Other", value: useFreeformTag});

  return ret;
}

function divisionsAsUiOptions(divisions: iltypes.Division[]) : UiOption[] {
  return divisions
    .sort((l,r) => {
      if (l.displayName.toLowerCase() === "epic") {
        // magic division needs special treatment
        return -1;
      }
      // sort on gender
      else if (l.gender < r.gender) {
        return -1;
      }
      else if (l.gender > r.gender) {
        return 1;
      }
      // then divnum (age cap)
      else {
        return l.divNum < r.divNum ? -1 : 1;
      }
    })
    .map(div => ({label: div.displayName, value: div.divID}));
}

function eventQuestionsAsSelectOptions(eventQuestions: ilapi.event.EventQuestion[]) : UiOption[] {
  return eventQuestions
    .sort((l,r) => {
      if (l === null) {
        return -1;
      }
      else if (r === null) {
        return 1;
      }
      else {
        return l < r ? -1 : l === r ? 0 : 1;
      }
    })
    .map(q => ({label: q.shortLabel, value: q.questionID}));
}

function paymentGatewaysAsUiOptions(paymentGateways: iltypes.PaymentGateway[]) : UiOption[] {
  return [
    {label: "Select an acount...", value: ""},
    ...paymentGateways.map(gateway => ({label: gateway.accountName, value: gateway.clientGatewayID}))
  ]
}

type MaybeGoogleCalendarSelectOptions =
  | {connected: true, options: UiOption[]}
  | {connected: false}

function googleCalendarListingAsUiOptions(calendarListing: ilapi.event.EventFormOptions["googleCalendars"]) : MaybeGoogleCalendarSelectOptions {
  if (!calendarListing.connected) {
    return {connected: false};
  }

  const options = [{value: "", label: "N/A"}];
  for (const v of calendarListing.calendars) {
    options.push({value: v.id, label: v.summary})
  }

  return {connected: true, options};
}

function bannerMsg(v: Props["detail"]["type"]) {
  return computed(() => {
    switch (v) {
      case "make-new": {
        return "Create a new event"
      }
      case "edit-existing": {
        return "Edit an existing event";
      }
      case "clone-existing": {
        return "Clone an existing event";
      }
      default: {
        return exhaustiveCaseGuard(v);
      }
    }
  });
}

/**
 * Get UiOptions for teams the current user would be interested in making (or allowed to make) an event for
 */
async function getTeamOptions(eventID: undefined | iltypes.Guid, seasonUID: iltypes.Guid, eventFormUserType: EventFormUserType) : Promise<UiOption[]> {
  const menu = await ilapi.event.getEventFormTeamOptions(axiosInstance, {eventID, seasonUID, eventFormUserType})

  const result : UiOption[] = [{label: "Select a team...", value: ""}];
  const teamIDs = unsafe_objectKeys(menu);
  if (teamIDs.length === 0) {
    // value is `""` with the intent that it does not count as "selected"
    result.push({label: "No teams available", value: ""})
  }
  else {
    for (const teamID of teamIDs) {
      const teamlike = menu[teamID];
      result.push({label: teamlike.displayName, value: teamlike.teamID});
    }
  }
  return result;
}
</script>
