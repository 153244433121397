import { Season, Guid } from "src/interfaces/InleagueApiV1";
import type { ExtractPropTypes, PropType } from "vue";

export function propsDef() {
  return {
    seasonUID: {
      required: true as const,
      type: String as PropType<Guid>
    },
    seasonsMap: {
      required: true as const,
      type: Object as PropType<{[seasonUID: Guid]: Season}>
    }
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
