import type { ExtractPropTypes, PropType } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"
import { User } from "src/store/User"

import { exhaustiveCaseGuard } from "src/helpers/utils"
import authService from "src/helpers/authService";
import { Guid } from "src/interfaces/InleagueApiV1";
import { Role } from "src/interfaces/Store/user";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<RoutePropsVariant>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  Rosters: "TeamSeason.rosters",
  Config: "TeamSeason.config",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

export enum queryParams {
  rosterFunc = "rf",
  seasonUID = "sid",
  competitionUID = "cid",
  divID = "did",
  teamIDs = "tids",
}

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | Rosters
  | Config

export interface Rosters extends RoutePropsBase {
  routeName: typeof RouteNames.Rosters,
}

export interface Config extends RoutePropsBase {
  routeName: typeof RouteNames.Config,
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteNames.Rosters:
      // fallthrough
    case RouteNames.Config:
      return {name: v.routeName}
    default: exhaustiveCaseGuard(v);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.Rosters:
    case RouteNames.Config:
      return {
        detail: {
          routeName: name
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}

export function hasSomeRosterPermission() : boolean {
  const roles : Role[] = [
    'Coach',
    'YouthCoach',
    'DD',
    'DDReporter',
    'playerAdmin',
    'RC',
    'registrar',
    'webmaster',
    'dataReader',
    'director',
  ];
  const hasRole = authService(User.value.roles, ...roles);
  const isRelevantCompetitionManager = !!User.userData?.competitionsMemento?.find(cm => cm.isPlayerAdmin);
  return hasRole || isRelevantCompetitionManager;
}

export function medicalReleaseByCompSeasonDiv_authz(competitionUID: Guid) : boolean {
  const roles : Role[] = [
    "registrar",
    "playerAdmin",
    "webmaster",
    "inLeague",
    "DD",
    "compManager"
  ]
  const hasSomeRole = authService(User.value.roles, ...roles);
  const isPlayerAdmin = !!User.userData?.competitionsMemento.find(v => v.isPlayerAdmin && v.competitionUID === competitionUID)
  return hasSomeRole || isPlayerAdmin
}

export type PlayerRosterSort =
  | "uniform"
  | "name"
