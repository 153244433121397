<template lang="pug">
FormKitSchema(
  :schema='schema',
)
</template>

<script lang="ts">
import { onMounted, ref } from "vue"
import { PropType, defineComponent } from "vue";
import type { Division, Season } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  props: {
    groupName: String,
    availableDivisions: {
      required: true,
      type: Array as PropType<Division[]>,
    },
    divisionToChildUiNameMap: {
      required: true,
      type: Object as PropType<Record</*divID:guid*/string, /*childUiNames*/string[]>>
    },
    season: {
      required: true,
      type: Object as PropType<Season>
    }
  },
  setup(props) {
    const schema = ref({})

    const createSchema = () => {
      const {availableDivisions, divisionToChildUiNameMap, season} = props;

      // create validation based off of season properties
      let validation : string
      if((props.groupName==='divCoach' || props.groupName==='divAsstCoach') && season.requireCoachDiv || props.groupName==='divRef'&& season.requireRefDiv) {
        validation='required|min:1|max:4'
      } else {
        validation='max:4'
      }

      const options : Record<string, any> = {};
      for (const {divID, displayName} of availableDivisions) {
        const labelBuilder = [displayName];
        if (divisionToChildUiNameMap[divID]?.length > 0) {
          labelBuilder.push(" (", divisionToChildUiNameMap[divID].join(", "), ")");
        }
        options[divID] = labelBuilder.join("");
      }

      const divSchema = {
        $formkit: 'checkbox',
        options,
        outerClass: 'pl-8',
        fieldsetClass: {'formkit-fieldset': false},
        validation: validation,
        name: `${props.groupName}`,
        label: 'Divisions',
        validationMessages: {
          max : 'Cannot have more than 3 Divisions'
        }
      }
      return divSchema
    }

    onMounted(() => {
      schema.value = createSchema()
    })

    return {
      schema,
    }
  }
})
</script>
