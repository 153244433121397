import { PropType, defineComponent, getCurrentInstance, onMounted, ref } from "vue";

import { QuillWrapper2 } from "src/components/UserInterface/QuillWrapper2";
import { axiosInstance } from "src/boot/AxiosInstances";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { useIziToast } from "src/helpers/utils";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"

export const CompRegReceiptEmailBlurbEditor = defineComponent({
  name: "CompRegReceiptEmailBlurbEditor",
  props: {
    competitionUID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    },
  },
  setup(props) {
    const iziToast = useIziToast();

    /**
     * Keep in sync w/ backend
     */
    const MAX_TEXT_LENGTH = 1000;

    type LocalState =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly competition: iltypes.Competition,
      }

    const state = ref<LocalState>({ready: false});

    const doSave = async () : Promise<void> => {
      if (!state.value.ready) {
        throw Error("state.ready must be true here");
      }

      try {
        await ilapi.updateCompetition(axiosInstance, {competition: state.value.competition})
        await iziToast.success({message: "Changes saved."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      const competition = await ilapi.getCompetition(axiosInstance, {competitionUID: props.competitionUID})

      state.value = {
        ready: true,
        competition
      }
    })

    return () => {
      if (!state.value.ready) {
        return null;
      }
      return (
        <div data-test="CompRegReceiptEmailBlurbEditor">
          <div>Program registration email receipt text</div>
          <div class="border border-slate-200 shadow-md my-4 p-2" data-test="header-container">
            <div class="text-sm font-medium">Header</div>
            <QuillWrapper2 configureFor="email" v-model={state.value.competition.competitionRegistrationReceiptEmail_header_html}/>
            <div class="text-xs flex flex-col items-end p-2">
              <div>{state.value.competition.competitionRegistrationReceiptEmail_header_html.length}/{MAX_TEXT_LENGTH}</div>
              <div>Limit includes html characters introduced for formatting</div>
            </div>
          </div>
          <div class="border border-slate-200 shadow-md my-4 p-2" data-test="footer-container">
            <div class="text-sm font-medium">Footer</div>
            <QuillWrapper2 configureFor="email" v-model={state.value.competition.competitionRegistrationReceiptEmail_footer_html}/>
            <div class="text-xs flex flex-col items-end p-2">
              <div>{state.value.competition.competitionRegistrationReceiptEmail_footer_html.length}/{MAX_TEXT_LENGTH}</div>
              <div>Limit includes html characters introduced for formatting</div>
            </div>
          </div>
          <t-btn data-test="submit" type="button" margin={false} onClick={() => doSave()}>Save changes</t-btn>
        </div>
      );
    }
  }
})
