<template lang="pug">
.grid.grid-cols-1.gap-4(class='lg:grid-cols-2' v-if='loaded')
  VolunteerStatus(
    v-if="volStatus"
    :portlet="volStatus"
  )

  DashboardRegistrationReport

  DivisionStatistics(
    :showContent='true',
    v-if='Object.keys(divisionStats).length',
    :divisionStats='divisionStats',
    @change='updateStats'
  )
  RefereeAssignments(:showContent='true', :assignments='refereeAssignments' v-if="Object.keys(refereeAssignments).length")

  //- No API endpoint yet
  //- TeamAssignments
  UpcomingEvents(
    :showContent='true',
    v-if='Object.keys(upcomingEvents).length',
    :upcomingEvents='upcomingEvents'
  )
  UpcomingGames(
    v-if="upcomingGames"
    :showContent='true',
    :portlet='upcomingGames'
  )
  VolunteerCertifications(
    :showContent='true',
    v-if='Object.keys(certifications).length',
    :certifications='certifications'
  )
  TeamAssignments(:showContent="true" :children='playerTeamAssignments' v-if="Object.keys(playerTeamAssignments).length")
  PlayersWithUnmetBirthCertificateRequirement(
    v-if="playersWithUnmetBirthCertificateRequirement"
    :portlet="playersWithUnmetBirthCertificateRequirement")

  //- extra portlets can glob onto the grid layout via this slot
  slot(name="bonusPortlets")
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import VolunteerStatus from 'src/components/Portlets/VolunteerStatus'
import DivisionStatistics from 'src/components/Portlets/DivisionStatistics'
import RefereeAssignments from 'src/components/Portlets/RefereeAssignments.vue'
import TeamAssignments from 'src/components/Portlets/TeamAssignments.vue'
import UpcomingEvents from 'src/components/Portlets/UpcomingEvents.vue'
import UpcomingGames from 'src/components/Portlets/UpcomingGames.vue'
import VolunteerCertifications from 'src/components/Portlets/VolunteerCertifications.vue'
import PlayersWithUnmetBirthCertificateRequirement from "src/components/Portlets/PlayersWithUnmetBirthCertificateRequirement"
import { formatDate } from 'src/helpers/formatDate'


import type { Portlet, PlayersWithUnmetBirthCertificateRequirementPortlet, UpcomingGamesPortlet, VolunteerStatusPortlet } from 'src/interfaces/InleagueApiV1'

import { DashboardRegistrationReport } from "./DashboardRegistrationReport"
import { User } from 'src/store/User'

export default defineComponent({
  name: 'Portlets',
  components: {
    VolunteerStatus,
    DivisionStatistics,
    RefereeAssignments,
    TeamAssignments,
    UpcomingEvents,
    UpcomingGames,
    VolunteerCertifications,
    PlayersWithUnmetBirthCertificateRequirement,
    DashboardRegistrationReport,
  },
  props: {},
  setup() {
    // TODO: don't use `{}` as "nullish"
    // Currently `Object.keys({}).length === 0` is used to represent "no data here", we should transition to using `T | null`.
    const volStatus = ref<VolunteerStatusPortlet | null>(null)
    const divisionStats = ref({})
    const certifications = ref({})
    const hasCertificates = ref(false)
    const upcomingEvents = ref({})
    const upcomingGames = ref<UpcomingGamesPortlet | null>(null)
    const refereeAssignments = ref({})
    const playerTeamAssignments = ref({})
    const playersWithUnmetBirthCertificateRequirement = ref<PlayersWithUnmetBirthCertificateRequirementPortlet | null>(null);

    const loaded = ref(false)



    const portlets = computed(() => {
      return User.value.portlets
    })

    const dividePortlets = (portlets: Portlet[]) => {
      for (const portlet of portlets) {
        switch (portlet.portletID) {
          case 'volunteerStatus':
            volStatus.value = portlet
            break;
          case 'divisionStats':
            divisionStats.value = portlet
            break
          case 'certifications':
            hasCertificates.value = portlet.certifications.length > 0
            certifications.value = portlet
            break
          case 'upcomingEvents':
            upcomingEvents.value = portlet
            break
          case 'upcomingGames':
            upcomingGames.value = portlet
            break
          case 'refAssignments':
            refereeAssignments.value = portlet
            break
          case 'teamAssignments':
            playerTeamAssignments.value = portlet
            break
          case 'playersWithUnmetBirthCertificateRequirement':
            playersWithUnmetBirthCertificateRequirement.value = portlet;
            break;
        }
      }
      loaded.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateStats = (newStats: any) => {
      divisionStats.value = newStats
    }

    watch(portlets, (newVal) => {
      dividePortlets(newVal)
    })

    onMounted(() => {
      loaded.value = false
      dividePortlets(portlets.value)
      loaded.value = true
    })

    return {
      volStatus,
      divisionStats,
      certifications,
      hasCertificates,
      upcomingEvents,
      upcomingGames,
      refereeAssignments,
      playerTeamAssignments,
      playersWithUnmetBirthCertificateRequirement,
      loaded,
      updateStats,
      portlets,
      formatDate,
    }
  },
})
</script>
