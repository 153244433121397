import type { CoachAssignmentMemento, Role } from "src/interfaces/Store/user"
import type { Guid } from "src/interfaces/InleagueApiV1"

const arrayItemsToLower = (arr: string[]) => {
  const lowerArray: string[] = []
  for(let i=0; i<arr.length; i++) {
    lowerArray.push(arr[i].toLowerCase())
  }
  return lowerArray
}

export default function (userHasTheseRoles: string[], ...userNeedsOneOfTheseRoles: Role[]): boolean {
  const lowerCaseUserRoles = arrayItemsToLower(userHasTheseRoles)
  const lowerCaseRoles = arrayItemsToLower(userNeedsOneOfTheseRoles)
  for (let i = 0; i < lowerCaseUserRoles.length; i++) {
    if (lowerCaseRoles.includes(lowerCaseUserRoles[i]) || lowerCaseUserRoles.includes('inleague') || (lowerCaseUserRoles.includes('webmaster') && !(lowerCaseRoles.length===1 && lowerCaseRoles[0]==='inleague'))) {
      return true
    }
  }
  return false
}

export function hasSomeCoachAssignmentTitle(coachAssignmentsMemento: CoachAssignmentMemento[], ...lookingFor: ("Head Coach" | "Co-Coach")[]) : boolean {
  const lcLookingFor = lookingFor.map(s => s.toLowerCase());
  return !!coachAssignmentsMemento.find(m => {
    return lcLookingFor.includes(m.title.toLowerCase());
  });
}

export function isHeadCoachForTeam(coachAssignments: CoachAssignmentMemento[], target: {teamID: Guid, seasonUID: Guid}) {
  for (const coachAssignment of coachAssignments) {
    if (coachAssignment.title === "Head Coach" || coachAssignment.title === "Co-Coach") {
      if (coachAssignment.teamID === target.teamID) {
        if (coachAssignment.seasonUID === target.seasonUID) {
          return true;
        }
      }
    }
  }
  return false;
}
