import { type LineItem } from 'src/interfaces/Store/checkout'

import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'

import { computed, defineComponent, PropType } from 'vue'
import { type ContentChunkName } from 'src/interfaces/Store/contentChunks'
import { arraySum, parseFloatOr } from 'src/helpers/utils'

export default defineComponent({
  props: {
    lineItems: {
      required: true,
      type: Array as PropType<LineItem[]>
    }
  },
  setup(props) {
    const contentChunkIDs = computed(() => getOrderedContentChunkIDsForDisplayIntent(props.lineItems));
    return () => (
      <div>
        {
          contentChunkIDs.value.map(chunkName => (
            <div class="my-4 md:mx-24">
              <ContentChunkDisplay id={chunkName}/>
            </div>
          ))
        }
      </div>
    )
  },
})

function getOrderedContentChunkIDsForDisplayIntent(lineItems: LineItem[]) : ContentChunkName[] {
  const result : ContentChunkName[] = [];
  const pushIfNotYetInList = (v: ContentChunkName) => {
    if (!result.includes(v)) {
      result.push(v);
    }
  }

  const finalAmountSum = arraySum(lineItems.map(li => parseFloatOr(li.finalAmount, 0)))

  if (finalAmountSum >= 0.01) {
    pushIfNotYetInList("PaymentExplanation")
  }

  for (const lineItem of lineItems) {
    if (
      lineItem.entity_type==='qCompetitionRegistration'
      // if we have an entity_type of qCompetitionRegistration, our contract is
      // that we definitely have an entity of that type; but, this is not yet expressed
      // in the type system (and maybe!? isn't always true);
      // hence we safenav here
      && lineItem.entity?.waitlist
    ) {
      //
      // if payment is blocked for this line item, we infer it to mean that the invoice
      // is for a single competition registration which is waitlisted, and the target
      // competitionSeason is `waitlistIsFree === WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT`.
      //
      if (lineItem.paymentBlock_isBlocked) {
        if (lineItem.finalAmount >= 0.01) {
          return ["WaitlistPaymentHold"];
        }
        else {
          return ["WaitlistPaymentHoldNoFee"]
        }
      }
      else {
        pushIfNotYetInList("Wait-ListHeaderNotice")
      }
    }
    else if (lineItem.entity_type==='qDonation') {
      pushIfNotYetInList("DonationEqualsPaymentWarning");
    }
  }

  //
  // This might be considered non-sensical for a "zero fee" invoice, so we'll omit it in such cases.
  //
  if (finalAmountSum >= 0.01) {
    pushIfNotYetInList("PaymentPageInstructions");
  }

  return result;
}
