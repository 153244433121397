import { boot } from 'quasar/wrappers'
import { tryGetVueErrorCallbackLogger } from "src/modules/LoggerService"
import { LoggedinLogWriter } from 'src/modules/Loggers';

import { User } from "src/store/User"

export default boot(async ({ app }) => {
  app.config.errorHandler = async (error, instance, info) : Promise<void> => {
    if (User.isLoggedIn) {
      // TODO: `instance` is null if the error originated in a functional component; handle that scenario better
      if (instance === null) {
        if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
          throw error
        }
      }
      tryGetVueErrorCallbackLogger(LoggedinLogWriter)(error, instance, info);
    }
    else {
      // right now, if not logged in, a local error handler must handle this if we want error reporting
      if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
        // at least in dev mode we want to see it
        throw error;
      }
    }
  };
});
