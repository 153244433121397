import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { Game } from "src/composables/InleagueApiV1.Game"
import { RouteLocationRaw, RouterLink } from "vue-router"

/**
 * TODO: typed RouteLocationRaw factory for a game's matchReport route
 */
export function MatchReportRouterLink(props: {game: Game}) {
  const to : RouteLocationRaw = {name: "match-report", params: {gameID: props.game.gameID}}
  return (
    <RouterLink to={to}>
      <FontAwesomeIcon icon={["fas", "clipboard-list"]} />
    </RouterLink>
  )
}
