import Axios, { type AxiosInstance } from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { isInleagueApiError2 } from "./InleagueApiV1"
import { DateTimelike, Guid } from "src/interfaces/InleagueApiV1"

export interface SeasonDivVolunteerCount {
  uniqueRegistrants: number,
  countsByDiv: {
    seasonUID: iltypes.Guid,
    divID: iltypes.Guid,
    count: iltypes.Integerlike,
    displayName: string
  }[]
}

export interface DashboardRegistrationStat {
  competitionUID: iltypes.Guid,
  competitionID: iltypes.Integerlike,
  seasonUID: iltypes.Guid,
  seasonID: iltypes.Integerlike,
  competition: string,
  seasonName: string,
  registrationStart: iltypes.Datelike,
  registrationEnd: iltypes.Datelike,
  waitlistDate: iltypes.Datelike,
  waitlistIsFree: iltypes.WaitlistIsFree_t["int"],
  activeRegistrationCount: iltypes.Integerlike,
  waitlistedRegistrationCount: iltypes.Integerlike,
  headCoachVolCountsBySeasonDiv: SeasonDivVolunteerCount,
  asstCoachVolCountsBySeasonDiv: SeasonDivVolunteerCount,
  refVolCountsBySeasonDiv: SeasonDivVolunteerCount,
}

export interface DashboardRegistrationFeeInfo {
  [competitionUID: iltypes.Guid]: undefined | {
    [seasonUID: iltypes.Guid]: undefined | {
      [divID: iltypes.Guid]: undefined | DashboardRegistrationReportFeeInfo_One
    }
  }
}

export interface DashboardRegistrationReportFeeInfo_One {
  displayName: string,
  divNum: iltypes.Integerlike,
  gender: "G" | "B",
  fees: {
    fee: number | string // generally a number, but, backend might return some sentinel for "couldn't figure it out"
    ifRegisteringOn: iltypes.Datelike,
  }[]
}

export interface DashboardRegistrationReport {
  menuDef: iltypes.MenuTreeDef<"competitionUID" | "seasonUID">,
  openOrSoonOpenCompSeasons: {
    competitionUID: Guid,
    competition: string,
    seasonUID: Guid,
    seasonName: string,
    registrationStart: "" | DateTimelike,
    registrationEnd: "" | DateTimelike,
  }[],
  feesByDivBySeasonByComp: DashboardRegistrationFeeInfo,
  stats: DashboardRegistrationStat[],
  generatedOn: iltypes.DateTimelike,
}

/**
 * Returns "EBUSY" if backend reports a 503 as an inLeague error (where "as an inleague error" is meant to distinguish between "server-tier" 503 and "api application-tier 503")
 * where EBUSY means "someone else just hit this endpoint, too; and they won the race to do the work to generate the resulting data; it will be cached, come back later when they are
 * done generating it."
 */
export async function getDashboardRegistrationReport(axios: AxiosInstance) : Promise<DashboardRegistrationReport | "EBUSY"> {
  const params : Record<string, any> = {}
  if (process.env.NODE_ENV === "development") {
    params.bustCache = 1;
  }
  try {
    const response = await axios.get(`v1/portlet/reportLike/dashboardRegistrationReport`, {params})
    return response.data.data;
  }
  catch (err) {
    if (Axios.isAxiosError(err) && isInleagueApiError2(err) && err.response.status === 503) {
      return "EBUSY";
    }
    else {
      throw err;
    }
  }
}
