import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import { UiOption, arrayFindOrFail, exhaustiveCaseGuard, forceCheckedIndexedAccess, lazyRef, sortBy, sortByMany, useIziToast } from "src/helpers/utils";
import { Division, Guid } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { User } from "src/store/User";
import { computed, defineComponent, onMounted, ref } from "vue";
import { DivisionEditorElement } from "./DivisionEditorImpl";
import { MaybeDivIdOrNew, NEWDIV } from "./DivisionEditor.shared";
import { DivisionCreateForm, DivisionForm_BasicUpdate, DivisionForm_InleagueCreate, DivisionForm_InleagueUpdate, DivisionForm_PermissionsType, DivisionUpdateForm, createDivision, updateDivision } from "src/composables/InleagueApiV1.Division";

export default defineComponent({
  setup() {
    const divisions = lazyRef<Division[]>()
    const iziToast = useIziToast();

    const selectedDivID = ref<MaybeDivIdOrNew>("")

    const ready = ref(false);

    const divisionOptions = computed<UiOption[]>(() => {
      if (divisions.isInit()) {
        return [
          ...User.isInleague
              ? [{label: "Create new", value: NEWDIV}]
              : [],
          ...divisions.value.map(div => ({label: div.division + (div.displayName ? ` (${div.displayName})` : ""), value: div.divID}))
        ]
      }
      return []
    })


    const divisionFormData = computed<DivisionCreateForm | DivisionUpdateForm | null>(() => {
      if (selectedDivID.value) {
        if (selectedDivID.value === NEWDIV) {
          if (!User.isInleague) {
            throw Error("illegal state");
          }
          return freshInleagueCreateDivisionForm()
        }
        else {
          const division = arrayFindOrFail(divisions.maybeValue ?? [], div => div.divID === selectedDivID.value);
          return User.isInleague
            ? freshInleagueUserUpdateDivisionForm(division)
            : freshBasicUpdateDivisionForm(division)
        }
      }
      else {
        return null;
      }
    })

    const doSubmit = async () : Promise<void> => {
      if (!divisionFormData.value) {
        throw Error("illegal state")
      }

      try {
        if (divisionFormData.value.type === "create") {
          const freshDivision = await createDivision(axiosInstance, divisionFormData.value);
          await onAfterCreateOrUpdate(freshDivision.divID);
          iziToast.success({message: "Division created."})
        }
        else if (divisionFormData.value.type === "update") {
          await updateDivision(axiosInstance, divisionFormData.value);
          await onAfterCreateOrUpdate(undefined);
          iziToast.success({message: "Division updated."})
        }
        else {
          exhaustiveCaseGuard(divisionFormData.value);
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }

      /**
       * Reload all the divisions in the store,
       * and update the selected divID if we created a new division.
       */
      async function onAfterCreateOrUpdate(newDivID: Guid | undefined) {
        divisions.value = [...await Client.loadDivisions()].sort(divisionSort);
        if (newDivID) {
          selectedDivID.value = newDivID
        }
      }
    }

    onMounted(async () => {
      divisions.value = [...await Client.getDivisions()].sort(divisionSort);

      selectedDivID.value = forceCheckedIndexedAccess(divisions.value, 0)?.divID ?? "";

      ready.value = true;
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return <DivisionEditorElement
        class="m-auto" style="max-width:1024px;"
        divisionOptions={divisionOptions.value}
        mut_selectedDivID={selectedDivID}
        mut_divisionFormData={divisionFormData.value}
        doSubmit={doSubmit}
      />
    }
  }
})


function freshBasicUpdateDivisionForm(existing: Division) : DivisionForm_BasicUpdate {
  return {
    type: "update",
    permissionsType: DivisionForm_PermissionsType.basic,
    prisitine: existing,
    displayName: existing.displayName,
    slotHours: existing.slotHours,
    slotMinutes: existing.slotMinutes
  }
}

function freshInleagueCreateDivisionForm() : DivisionForm_InleagueCreate {
  return {
    type: "create",
    permissionsType: DivisionForm_PermissionsType.inLeague,
    displayName: "",
    slotHours: 1,
    slotMinutes: 0,
    division: "",
    divNum: "",
    gender: "",
  }
}

function freshInleagueUserUpdateDivisionForm(existing: Division) : DivisionForm_InleagueUpdate {
  return {
    type: "update",
    permissionsType: DivisionForm_PermissionsType.inLeague,
    prisitine: existing,
    displayName: existing.displayName,
    slotHours: existing.slotHours,
    slotMinutes: existing.slotMinutes,
    division: existing.division,
    divNum: existing.divNum,
    gender: existing.gender,
  }
}

const divisionSort = sortByMany<Division>(
  sortBy(_ => _.divNum),
  sortBy(_ => _.gender)
);
