import type * as iltypes from "src/interfaces/InleagueApiV1"
import type { ExtractPropTypes, PropType } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard, parseIntOr } from "src/helpers/utils"
import { RouteName } from "./R_TournamentTeamListing.route";

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<PropsVariant>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  base: "create-tournament-team.base",
  ChooseSeason: "create-tournament-team.choose-season",
  ChooseCompetition: "create-tournament-team.choose-competition",
  InfoBanner_PreChooseDiv: "create-tournament-team.info-banner-pre-choose-div",
  ChooseDivision: "create-tournament-team.choose-division",
  CreateTeam: "create-tournament-team.create-team",
  Questions: "create-tournament-team.questions",
  Coaches: "create-tournament-team.coaches",
  Referees: "create-tournament-team.referees",
  Complete: "create-tournament-team.complete",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  name: RouteName
}

export type PropsVariant =
  | ChoosingSeason
  | ChoosingCompetition
  | InfoBanner_PreChooseDiv
  | ChoosingDivision
  | CreatingTeam
  | Questions
  | Coaches
  | Referees
  | Complete

export interface ChoosingSeason extends RoutePropsBase {
  name: typeof RouteNames.ChooseSeason,
}

export interface ChoosingCompetition extends RoutePropsBase {
  name: typeof RouteNames.ChooseCompetition,
  seasonUID: iltypes.Guid,
}

export interface InfoBanner_PreChooseDiv extends RoutePropsBase {
  name: typeof RouteNames.InfoBanner_PreChooseDiv,
  seasonUID: iltypes.Guid,
  competitionUID: iltypes.Guid,
  tournamentID: iltypes.Integerlike,
}

export interface ChoosingDivision extends RoutePropsBase {
  name: typeof RouteNames.ChooseDivision,
  seasonUID: iltypes.Guid,
  competitionUID: iltypes.Guid,
  tournamentID: iltypes.Integerlike,
}

export interface CreatingTeam extends RoutePropsBase {
  name: typeof RouteNames.CreateTeam,
  seasonUID: iltypes.Guid,
  competitionUID: iltypes.Guid,
  tournamentID: iltypes.Integerlike,
  divID: iltypes.Guid,
}

export interface Questions extends RoutePropsBase {
  name: typeof RouteNames.Questions,
  tournamentTeamID: iltypes.Integerlike,
}

export interface Coaches extends RoutePropsBase {
  name: typeof RouteNames.Coaches,
  tournamentTeamID: iltypes.Integerlike,
}

export interface Referees extends RoutePropsBase {
  name: typeof RouteNames.Referees,
  tournamentTeamID: iltypes.Integerlike,
}

export interface Complete extends RoutePropsBase {
  name: typeof RouteNames.Complete,
  tournamentTeamID: iltypes.Integerlike,
  query?: {
    // n.b. we assume this is an integer when it is part of the URL
    byCheck?: boolean
  }
}

export function routeDetailToRouteLocation(v: PropsVariant) : RouteLocationRaw {
  switch (v.name) {
    case RouteNames.ChooseSeason:
      return {name: v.name, params: {}}
    case RouteNames.ChooseCompetition:
      return {name: v.name, params: {seasonUID: v.seasonUID}}
    case RouteNames.InfoBanner_PreChooseDiv:
      return {name: v.name, params: {seasonUID: v.seasonUID, competitionUID: v.competitionUID, tournamentID: v.tournamentID}}
    case RouteNames.ChooseDivision:
      return {name: v.name, params: {seasonUID: v.seasonUID, competitionUID: v.competitionUID, tournamentID: v.tournamentID}}
    case RouteNames.CreateTeam:
      return {name: v.name, params: {seasonUID: v.seasonUID, competitionUID: v.competitionUID, tournamentID: v.tournamentID, divID: v.divID}}
    case RouteNames.Questions:
      // fallthrough (different route, same props)
    case RouteNames.Coaches:
    case RouteNames.Referees:
      return {name: v.name, params: {tournamentTeamID: v.tournamentTeamID}}
    case RouteNames.Complete:
      return {
        name: v.name,
        params: {tournamentTeamID: v.tournamentTeamID},
        query: {byCheck: v.query?.byCheck ? 1 : undefined}
      }
    default: exhaustiveCaseGuard(v);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.base:
      //
      // fallthrough
      //
      // This is an artifact of the first child route's path being indistinguishable from the route's base path?
      // (so we have something like `children: [{path: ""}]` which maybe could be improved?)
      //
    case RouteNames.ChooseSeason:
      return {
        detail: {
          name: RouteNames.ChooseSeason
        }
      }
    case RouteNames.ChooseCompetition:
      return {
        detail: {
          name: RouteNames.ChooseCompetition,
          seasonUID: route.params.seasonUID as string
        }
      }
    case RouteNames.InfoBanner_PreChooseDiv:
      return {
        detail: {
          name: RouteNames.InfoBanner_PreChooseDiv,
          seasonUID: route.params.seasonUID as string,
          competitionUID: route.params.competitionUID as string,
          tournamentID: route.params.tournamentID as iltypes.Integerlike
        }
      }
    case RouteNames.ChooseDivision:
      return {
        detail: {
          name: RouteNames.ChooseDivision,
          seasonUID: route.params.seasonUID as string,
          competitionUID: route.params.competitionUID as string,
          tournamentID: route.params.tournamentID as iltypes.Integerlike
        }
      }
    case RouteNames.CreateTeam:
      return {
        detail: {
          name: RouteNames.CreateTeam,
          seasonUID: route.params.seasonUID as string,
          competitionUID: route.params.competitionUID as string,
          tournamentID: route.params.tournamentID as iltypes.Integerlike,
          divID: route.params.divID as string,
        }
      }
    case RouteNames.Questions:
      // fallthrough (different route, same props)
    case RouteNames.Coaches:
      // fallthrough (different route, same props)
    case RouteNames.Referees:
      return {
        detail: {
          name: name,
          tournamentTeamID: route.params.tournamentTeamID as iltypes.Integerlike
        }
      }
    case RouteNames.Complete:
      const query : Complete["query"] = {}
      query.byCheck = !!parseIntOr(route.query.byCheck, 0);

      return {
        detail: {
          name: name,
          tournamentTeamID: route.params.tournamentTeamID as iltypes.Integerlike,
          query
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}
