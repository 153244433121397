import { AxiosInstance } from "axios";
import { Competition, CompetitionSideBySide, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { LastStatus_t } from "src/interfaces/Store/checkout";

/**
 * An open competition is at least a supertype of Competition -- at runtime we might always have a whole competition?
 * At any rate, the properties declared here are the ones we need.
 */
export type OpenCompetition = Pick<Competition, "competitionUID" | "competition" | "registrationDesc">

export type EligibleCompetition = OpenCompetition & { currentCompetitionSeason: any }

export enum Eligibility {
  eligible = "eligible",
  alreadyRegistered = "already-registered",
  /**
   * @deprecated TODO: backend will eventually remove the deprecated value, we can do so after they do.
   * we're waiting on the majority of clients to have code that can properly handle both values.
   * During the transition period, both values should be treated as meaning the same thing.
   */
  alreadyWaitlisted_deprecated = "already-waitlisted",
  alreadyWaitlistedPendingPayment = "already-waitlisted-pending-payment",
  ineligible = "ineligible",
  eligibleButNotOnThisRegistration = "eligible-but-not-on-this-registration"
}

export interface GetEligibleCompetitions2Response {
  rootEligibility: EligibleCompetition[],
  /**
   * really this is "transitive ineligibility"? -- presence here is only used to test for
   * "has reg for compA, so cannot reg for compB"
   */
  transitiveEligibility: CompetitionSideBySide[],
  currentCompRegs: {
    competitionRegistrationID: Integerlike,
    competitionUID: Guid,
    registrationStatus: "waitlistedButUnpaid" | "registered" | "incomplete",
    /** non-nullish if an invoice exists */
    invoiceInstanceID: "" | Integerlike,
    invoiceLastStatus: LastStatus_t,
  }[],
  forSelection?: {
    /**
     * Every open competitionUID the caller is aware of (by way of "allOpenCompetitionUIDs" endpoint param) should have a key in this map;
     * But we say undefined is included in the value type to enforce noUncheckedAccess.
     */
    [competitionUID: Guid]: undefined | {
      status: Eligibility
    }
  }
}

/**
 * If `forSelection` is provided, the `forSelection` property on the result will be present.
 */
export async function getEligibleCompetitions2(ax: AxiosInstance, args: {seasonUID: Guid, playerID: Guid, forSelection: null | {allOpenCompetitionUIDs: Guid[], selectedCompetitionUIDs: Guid[]}}) : Promise<GetEligibleCompetitions2Response> {
  const response = await ax.get('v1/competitionEligibility2', {
    params: {
      seasonUID: args.seasonUID,
      childID: args.playerID,
      forSelection_allOpenCompetitionUIDs: args.forSelection?.allOpenCompetitionUIDs ?? [],
      forSelection_selectedCompetitionUIDs: args.forSelection?.selectedCompetitionUIDs ?? [],
    },
  })
  return response.data.data
}

export async function getOpenCompetitions(ax: AxiosInstance, args: {seasonUID: Guid, openingWithin: number | ""}) : Promise<OpenCompetition[]> {
  const response = await ax.get(`v1/registration/season/${args.seasonUID}/competitions`, {params: {openingWithin: args.openingWithin}});
  return response.data.data
}

export function getInvoiceInstanceIDsByCompUID(eligibilityInfo: Pick<GetEligibleCompetitions2Response, "currentCompRegs">) : {[competitionUID: Guid]: Integerlike} {
  const result : {[competitionUID: Guid]: Integerlike} = {}
  for (const {competitionUID, invoiceInstanceID} of eligibilityInfo.currentCompRegs) {
    if (invoiceInstanceID) {
      result[competitionUID] = invoiceInstanceID
    }
  }
  return result;
}
