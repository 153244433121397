import dayjs, { Dayjs } from "dayjs";
import { FieldBlockForGameSchedulerView, GameForGameSchedulerView } from "src/composables/InleagueApiV1.GameScheduler";
import { accentAwareCaseInsensitiveCompare, assertTruthy, exhaustiveCaseGuard, nextOpaqueVueKey, requireNonNull, UiOption } from "src/helpers/utils";
import { CoachTitle, CompetitionUID, DateTimelike, Datelike, DivID, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { LayoutNode } from "./CalendarLayout";
import { authZ_perAction } from "./R_GameSchedulerCalendar.route";
import { Client } from "src/store/Client";

export type {
  CoachTriple,
  CompDivAuthZ,
  CreateFieldBlockForm,
  CreateGameForm,
  EditFieldBlockForm,
  EditGameForm,
}

export {
  authZ_canEditNodeViaOverlay,
  authZ_canDragOrResizeNode,
  bracketTeamLabel,
  coachBlurbForTeamName,
  GameCalendarElement,
  GameCalendarUiElement,
  isEffectivelyAllDay,
  k_POOL_ALL,
  k_TEAM_TBD,
  teamDesignationAndMaybeName
}

type GameCalendarElement =
  | {type: "game", data: GameForGameSchedulerView}
  | {type: "fieldBlock", data: FieldBlockForGameSchedulerView}

type GameCalendarUiElement =
  & GameCalendarElement
  & {
  __vueKey: string
  uiState: {
    isModalOrOverlayFocus: boolean,
    isBeingVerticallyResized: boolean,
    /**
     * null -- not being dragged
     * "stationary-drag-source" -- hasn't been moved, but is serving as a token "I'm being dragged" indicator".
     * "drag-handle" -- Actively being dragged. If a "drag-handle" node exists, there should also be a "stationary-drag-source" node
     * in the tree somewhere, in order to show both "the thing being dragged and where it is now" as well as "where the drag started from".
     */
    dragState: null | "stationary-drag-source" | "drag-handle"
    /**
     * Current start/end times; if being dragged or resized, this is the current value as a result of the drag.
     * UI layout should always consult this value (not the one in `data`).
     */
    time: {
      readonly start: Dayjs,
      readonly end: Dayjs,
      readonly isEffectivelyAllDay: boolean,
    },
    isSaving: boolean, // maybe we should remove this and only use "isBusy"
    isBusy: boolean,
    isBulkSelected: boolean,
    isOpeningEditPane: boolean,
    /**
     * If truthy, then this node is not eligible for bulk select. A message about why can be attached.
     */
    noBulkSelect: null | {msg: string}
  }
}

function GameCalendarUiElement(tag: "game", data: GameForGameSchedulerView) : GameCalendarUiElement;
function GameCalendarUiElement(tag: "fieldBlock", data: FieldBlockForGameSchedulerView) : GameCalendarUiElement;
function GameCalendarUiElement(tag: "game" | "fieldBlock", data: GameForGameSchedulerView | FieldBlockForGameSchedulerView) : GameCalendarUiElement {
  let start : Dayjs
  let end : Dayjs

  if (tag === "game") {
    const v = data as GameForGameSchedulerView
    start = dayjs(v.gameStart)
    end = dayjs(v.gameEnd)
  }
  else if (tag === "fieldBlock") {
    const v = data as FieldBlockForGameSchedulerView
    start = dayjs(v.slotStart)
    end = dayjs(v.slotEnd)
  }
  else {
    exhaustiveCaseGuard(tag)
  }

  return {
    __vueKey: nextOpaqueVueKey(),
    // type and tag cast to any because they need to be coherent but it's not currently provable from this position,
    // however the overload signatures guarantee that they are properly coherent (i.e. if tag is "game" then data is Game, same for field)
    type: tag as any,
    data: data as any,
    uiState: {
      isModalOrOverlayFocus: false,
      isBeingVerticallyResized: false,
      dragState: null,
      time: {
        start,
        end,
        isEffectivelyAllDay: isEffectivelyAllDay(start.unix(), end.unix()),
      },
      isSaving: false,
      isBusy: false,
      isOpeningEditPane: false,
      isBulkSelected: false,
      noBulkSelect: null,
    }
  }
}

/**
 * Field blocks can be "all day".
 * We maybe need a bit that marks "all day",
 * but, "all day" things should probably be able to be resized.
 * Anyway, in the old system, an "all day" thing is one that goes from 07:00-23:59 (16hr 59min).
 * In the newer system an "all day" thing is from 0:00(day n-1)-00:00(day n) (24h)
 */
function isEffectivelyAllDay(unixSecondsFrom: number, unixSecondsTo: number) {
  assertTruthy(unixSecondsFrom <= unixSecondsTo)
  const seconds_16h30min = ((16 * 60) + 30) * 60;
  return unixSecondsTo - unixSecondsFrom >= seconds_16h30min
}

interface CreateGameForm {
  competitionUID: Guid,
  divID: Guid,
  fieldUID: Guid,
  poolID: "ALL" | Integerlike,
  startDate: Datelike,
  startHour: Integerlike,
  startMinute: Integerlike,
  genderNeutral: boolean,
  playoff: boolean,
  pointsCount: boolean,
  blockFromMatchmaker: boolean,
  slotCount: Integerlike,
  slotGameDurationMinutes: Integerlike,
  repeatWeeks: Integerlike,
  comment: string,
  season: {
    // should update atomically
    readonly seasonUID: Guid,
    readonly seasonID: Integerlike,
    readonly seasonName: string
  }
}

interface EditGameForm {
  gameID: Guid,
  startDate: Datelike,
  startHour: Integerlike,
  startMinute: Integerlike,
  gameDurationMinutes: Integerlike,
  homeTeamID: "TBD" | Guid,
  visitorTeamID: "TBD" | Guid,
  season: {
    // should update atomically
    readonly seasonUID: Guid,
    readonly seasonID: Integerlike,
    readonly seasonName: string
  },
  competitionUID: Guid,
  divID: Guid,
  fieldUID: Guid,
  poolID: "ALL" | Integerlike,
  genderNeutral: boolean,
  playoff: boolean,
  pointsCount: boolean,
  blockFromMatchmaker: boolean,
  comment: string,
  roundID: Guid | "",
}

interface CreateFieldBlockForm {
  fieldUID: Guid,
  startDate: DateTimelike,
  startHour: Integerlike,
  startMinute: Integerlike,
  lengthMinutes: Integerlike,
  repeatWeeks: Integerlike,
  comment: string,
  blockEntireDay: boolean,
}

interface EditFieldBlockForm {
  id: Integerlike,
  fieldUID: Guid,
  startDate: DateTimelike,
  startHour: Integerlike,
  startMinute: Integerlike,
  lengthMinutes: Integerlike,
  comment: string,
  blockEntireDay: boolean,
}

interface CompDivAuthZ {
  canCrudGames: boolean,
  canEditGameTimes: boolean,
  canEditGameFields: boolean,
  canEditGameTeams: boolean,
}

function authZ_canEditNodeViaOverlay(node: LayoutNode<GameCalendarUiElement>, authZByCompDiv: Map<`${CompetitionUID}/${DivID}`, CompDivAuthZ>) : boolean {
  switch (node.data.type) {
    case "game": {
      const authZ = authZByCompDiv.get(`${node.data.data.competitionUID}/${node.data.data.divID}`)
      // if they have any permission, they can "edit via the overlay"
      return !!authZ?.canCrudGames || !!authZ?.canEditGameFields || !!authZ?.canEditGameTeams || !!authZ?.canEditGameTimes;
    }
    case "fieldBlock": {
      return authZ_perAction.canCrudFieldBlocks()
    }
    default: exhaustiveCaseGuard(node.data)
  }
}

function authZ_canDragOrResizeNode(node: LayoutNode<GameCalendarUiElement>, authZByCompDiv: Map<`${CompetitionUID}/${DivID}`, CompDivAuthZ>) : boolean {
  switch (node.data.type) {
    case "game": {
      const authZ = authZByCompDiv.get(`${node.data.data.competitionUID}/${node.data.data.divID}`)
      return authZ?.canEditGameTimes || false
    }
    case "fieldBlock": {
      return authZ_perAction.canCrudFieldBlocks()
    }
    default: exhaustiveCaseGuard(node.data)
  }
}

/**
 * value for UIOption meaning "no team"
 */
const k_TEAM_TBD = "TBD"

/**
 * value for UIOption meaning "no particular pool"
 */
const k_POOL_ALL = "ALL"
type k_POOL_ALL = typeof k_POOL_ALL

function teamDesignationAndMaybeName(v: {teamDesignation: string, teamName: string, teamID: Guid}) : string {
  if (v.teamID === Client.value.instanceConfig.byeteam) {
    return "Bye"
  }

  const designation = v.teamDesignation.trim()
  const name = v.teamName.trim()

  if (!name) {
    // no name, just designation
    return designation
  }

  if (designation === name) {
    // there's a name, but it's the same as the designation (seems to be like "default is teamDesignation")
    return designation
  }

  return `${designation} (${name})`
}

type CoachTriple = {title: CoachTitle, firstName: string, lastName: string}

function coachBlurbForTeamName(vs: CoachTriple[]) : string {
  return vs
    .filter(v => v.title === "Head Coach" || v.title === "Co-Coach")
    .map(v => v.lastName)
    .sort(accentAwareCaseInsensitiveCompare)
    .join(", ")
}

function bracketTeamLabel(which: "home" | "visitor", game: GameForGameSchedulerView) : string {
  const team = which === "home" ? game.homeTeam : game.visitorTeam

  if (!team) {
    const bracket = game.bracketRoundSlot
    if (!bracket) {
      return "TBD"
    }

    const source = which === "home" ? bracket.sourceLeft : bracket.sourceRight
    if (!source) {
      return "TBD"
    }

    return `${source.sourceType === "winner" ? "Winner" : "Loser"} of prior game`
  }

  const label = teamDesignationAndMaybeName(team)

  if (team.seed === null) {
    return label
  }
  else {
    return `${label}, seed ${team.seed}`
  }
}
