import { Invoice, CheckoutI } from 'src/interfaces/Store/checkout'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'

import * as ilapi from "src/composables/InleagueApiV1"

import { EventStore } from "src/store/EventStore"

import { exhaustiveCaseGuard, sortByDayJS } from 'src/helpers/utils'
import { AxiosInstance } from 'axios'
import { ref } from 'vue'
import { Guid, WithDefinite } from 'src/interfaces/InleagueApiV1'
import { EventAugmentedEntity } from 'src/interfaces/Store/events'

export const CheckoutStore = (() => {
  const state = ref(freshState());

  function getUnpaidInvoices() {
    for (const invoiceID in state.value.invoice) {
      if (!state.value.invoice[invoiceID].paid) {
        directCommit_setUnpaidInvoices({
          invoiceID,
          instanceLabel: state.value.invoice[invoiceID].instanceLabel,
        })
      }
    }
  }

  /**
   * set the list of {signups,events} that the checkout flow is aware of
   * Fully replaces old state
   */
  function initEventSignupFlow({ data }: { data: PutRegistrantArgs[] }) {
    // clear old state
    Object
      .keys(state.value.registrants)
      .map(key => state.value.registrants[key])
      .forEach((eventSignup) => {
        const args : RemoveRegistrantArgs = {type: "from-signup", eventSignup};
        removeEventSignup(args)
      })

    for (let i = 0; i < data.length; i++) {
      putEventSignup(data[i])
    }
  }

  // this is like "getOrInitEventCheckoutFlowState" ...?
  // fixme/warn: this used to be effectively "initFlowState"
  async function getRegistrants(eventID: string) : Promise<void> {
    if (Object.keys(state.value.registrants).length > 0) {
      // if the existing flowstate value is non-empty, we assume it is The Current Flowstate Value
      return;
    }
    else {
      // otherwise, we need to load it
      const signups = await ilapi.event.getEventSignups(axiosInstance, {eventID, narrowTo: "currentUserFamily"});
      await initEventSignupFlow({data: signups})
    }
  }

  async function getInvoice(
    {
      invoiceID,
      expand,
      ax,
    }: {
      invoiceID: string
      expand: boolean // all-or-nothing for particular values defined in the body
      ax?: AxiosInstance
    }
  ) : Promise<Invoice> {
    const response = await (ax || axiosInstance).get(
      `v1/invoice/${invoiceID}${
        expand ? '?expand=transactions,adminAccess,lineItems.adminAccess,lineItems.entity' : ''
      }`
    )
    const invoice = response.data.data as Invoice;
    setInvoice(invoice)
    if (invoice.transactions) {
      invoice.transactions.sort(sortByDayJS(_ => _.transactionDate, "seconds", "desc"));
    }
    return response.data.data;
  }

  async function voidInvoice(invoiceInstanceID: string) {
    try {
      const invoice = state.value.invoice[invoiceInstanceID];
      if (invoice) {
        await patchGlobalStateInResponseToInvoiceVoid(invoice)
      }

      await axiosInstance.delete(`v1/invoice/${invoiceInstanceID}`)
      directCommit_removeInvoice(invoiceInstanceID)
    } catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    }
  }

  function putEventSignup(eventSignup: WithDefinite<ilapi.event.EventSignup, "computed_feeAfterVisibleDiscounts">) {
    state.value.registrants[eventSignup.eventSignupID] = eventSignup
    const eventSignupID = eventSignup.eventSignupID
    const eventID = eventSignup.eventID;
    if (!state.value.eventsCart[eventID]) {
      state.value.eventsCart[eventID] = [];
    }
    if (!state.value.eventsCart[eventID].includes(eventSignupID)) {
      state.value.eventsCart[eventID].push(eventSignupID)
    }
  }

  function removeEventSignup(args: RemoveRegistrantArgs) {
    const countsPerEventID : Record<Guid, number> = {}
    for (const eventSignupID of Object.keys(state.value.registrants)) {
      const eventID = state.value.registrants[eventSignupID].eventID;
      countsPerEventID[eventID] = (countsPerEventID[eventID] ?? 0) + 1;
    }

    switch (args.type) {
      // easier case
      case "from-signup": {
        doDelete({
          eventID: args.eventSignup.eventID,
          eventSignupID: args.eventSignup.eventSignupID
        });
        return;
      }
      // slightly more work here
      // there shouldn't be more than ~10 so iterating like this is OK
      case "from-entity": {
        const entityID = args.eventEntity.id;
        for (const eventSignupID of Object.keys(state.value.registrants)) {
          const eventSignup = state.value.registrants[eventSignupID];
          const eventID = eventSignup.eventID;
          if (eventID !== args.eventEntity.eventID) {
            continue;
          }
          if (eventSignup.childID === entityID || eventSignup.userID === entityID) {
            doDelete({eventID, eventSignupID});
            return;
          }
        }
        return;
      }
      default: exhaustiveCaseGuard(args);
    }

    function doDelete(v: {eventID: Guid, eventSignupID: Guid}) {
      delete state.value.registrants[v.eventSignupID];

      //
      // if this is the last eventSignup for some eventID,
      // we also remove that eventID from the `eventsCart`
      //
      if (countsPerEventID[v.eventID] === 1) {
        delete state.value.eventsCart[v.eventID];
      }
    }
  }

  /**
   * Store some invoice into the local store, where it can be looked up by its instanceID
   */
  function setInvoice(args: SetInvoiceArgs) {
    const fresh = { ...state.value.invoice }
    if (Array.isArray(args)) {
      for (const invoice of args) {
        fresh[invoice.instanceID] = invoice;
      }
    }
    else {
      fresh[args.instanceID] = args
    }

    state.value.invoice = fresh;

    return state.value.invoice
  }

  function directCommit_setUnpaidInvoices(
    {
      invoiceID,
      instanceLabel,
    }: {
      invoiceID: string
      instanceLabel: string
    }
  ) {
    state.value.unpaidInvoices[invoiceID] = instanceLabel
  }

  function setPaymentAttempted(v: boolean) {
    state.value.paymentAttempted = v
  }

  function directCommit_removeInvoice(invoiceID: string) {
    const invoiceCopy = { ...state.value.invoice }
    delete invoiceCopy[invoiceID]
    state.value.invoice = invoiceCopy
  }

  function setProcessed(invoiceStatus: { invoiceID: string, processed: boolean}) {
    state.value.processed[invoiceStatus.invoiceID] = invoiceStatus.processed
  }


  return {
    get value() {
      return state.value;
    },
    getInvoice,
    removeEventSignup,
    setInvoice,
    getUnpaidInvoices,
    getRegistrants,
    voidInvoice,
    setPaymentAttempted,
    putEventSignup,
    initEventSignupFlow,
    setProcessed,
  }
})();

/**
 * Global state needs to be updated in response to voiding an invoice;
 * for each line item on the invoice, update associated underlying entities
 */
async function patchGlobalStateInResponseToInvoiceVoid(invoice: Invoice) {
  for (const lineItem of invoice.lineItems) {
    switch (lineItem.entity_type) {
      case "qEventSignup": {
        const eventSignup = (await EventStore.getSignupsByEventSignupID([lineItem.eventSignupID]))[0];

        if (!eventSignup || !eventSignup.eventID || !eventSignup.invoiceInstanceID) {
          // uh, this is why we're here, right? to void this?
          // and it doesn't exist, or we don't have the info to do it?
          // Shouldn't happen, this is a bug.
          return;
        }

        // paranoia -> make copy (it's a string) because eventSignup is a vue ref in the store
        // and its ownership model is underspecified (it might get changed out from under us after `getSignups`?)
        const targetInvoiceInstanceID = eventSignup.invoiceInstanceID;

        // this should generally be a local store cache hit, but could possibly make a network request
        // If it makes a network request, then the subsequent updates are unnecessary but it doesn't hurt to do them
        // If the results are from cache, we definitely want to do the subsequent updates.
        const signups = await EventStore.getSignups({eventID: eventSignup.eventID});

        for (const eventSignupID of Object.keys(signups)) {
          const signup = signups[eventSignupID];
          if (signup.invoiceInstanceID == targetInvoiceInstanceID) {
            EventStore.putSignupByEntityByEvent({signup:{...signup, lineItemID: "", invoiceInstanceID: null}});
          }
        }

        break;
      }
      default:
        // hm, we probably need to be doing something here
        break;
    }
  }
}

function freshState(): CheckoutI {
  return {
    eventsCart: {},
    //questionAnswers: {},
    registrants: {},
    invoice: {},
    unpaidInvoices: {},
    //pairedIDs: {},
    paymentAttempted: false,
    processed: {}
  }
}
export type RemoveRegistrantArgs =
  | {type: "from-entity", eventEntity: EventAugmentedEntity}
  | {type: "from-signup", eventSignup: ilapi.event.EventSignup}

export type PutRegistrantArgs = WithDefinite<ilapi.event.EventSignup, "computed_feeAfterVisibleDiscounts">
export type SetInvoiceArgs = Invoice | Invoice[]
