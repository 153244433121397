<template lang="pug">
div
  div(v-if="hasSomeCompetitionsWithSuperAdminAccess")
    div(class='md:hidden')
      label.sr-only(for='tabs') Select a Category
      select#tabs.block.w-full.pl-3.pr-10.py-2.text-base.border-gray-300.rounded-md(
        name='tabs'
        v-model="selectedTabID"
        class='focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm'
      )
        option(v-for='{tabID, uiName} in tabs' :key='tabID' :value="tabID") {{ uiName }}
    .hidden(class='md:block')
      .border-b.border-gray-200
        nav.mb-2(style="display:grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))")
          div.flex.justify-center.mx-2(
            v-for='{tabID, uiName} in tabs' :key='tabID' @click='changeTab(tabID)'
            :aria-current="tabID === selectedTabID ? 'page' : undefined"
            style="cursor:pointer; white-space:break-spaces;"
            :class="[tabID === selectedTabID ? 'border-green-500 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-base']"
            :data-test="`tabID=${tabID}`"
          )
            | {{ uiName }}
    .bg-gray-200.w-full
      .max-w-md.mx-auto.px-2.pt-1.pb-3
        Competitions(
          v-model='selectedCompetitionUID'
          :competitions="competitions"
          :require-per-competition-super-admin="true"
        )
        Seasons(
          :displayLabel = 'true'
          v-model='selectedSeasonUID'
          v-if='shouldOfferSeasonOptions'
        )
    div.py-4.border-gray-200(v-if="shouldDrawTabs")
      CompetitionOptions(v-if="selectedTabID === TabID.competitionAdministrator && selectedCompetitionUID", :competitionUID='selectedCompetitionUID')
      EligibilityRules(v-if="selectedTabID === TabID.eligibilityRules && selectedCompetitionUID && selectedSeasonUID", :competitionUID='selectedCompetitionUID', :seasonUID='selectedSeasonUID')
      SeasonalSettings(
        :key="`${selectedSeasonUID}/${selectedCompetitionUID}`"
        v-if="selectedTabID === TabID.seasonalSettings && selectedCompetitionUID && selectedSeasonUID", :competitionUID='selectedCompetitionUID', :seasonUID='selectedSeasonUID')
      DivisionsSetup(v-if="selectedTabID === TabID.divisionSettings && selectedCompetitionUID && selectedSeasonUID" :competitionUID='selectedCompetitionUID', :seasonUID='selectedSeasonUID')
      PlayerEligibility(v-if="selectedTabID === TabID.playerEligibility && selectedCompetitionUID && selectedSeasonUID", :competitionUID='selectedCompetitionUID' :seasonUID="selectedSeasonUID")
      CompetitionSideBySideManager(v-if="selectedTabID === TabID.sideBySide && selectedCompetitionUID", :competitionUID='selectedCompetitionUID')
      CompRegReceiptEmailBlurbEditor(v-if="selectedTabID === TabID.registrationReceipt && selectedCompetitionUID" :competitionUID="selectedCompetitionUID" :key="selectedCompetitionUID")
      CompetitionEligibilityStatusReport(
        v-if="selectedTabID === TabID.invitationStatus && selectedCompetitionUID"
        :seasonUID="selectedSeasonUID"
        :competitionUID="selectedCompetitionUID"
      )
  div(v-else)
    //- we shouldn't even mount this component end up in this condition
    div There are no competitions for which you have administrative permission.
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import Competitions from 'src/components/Selectors/Competitions'
import Seasons from 'src/components/Selectors/Seasons'
import CompetitionOptions from 'src/components/Admin/SeasonCompetitions/CompetitionOptions.vue'
import SeasonalSettings from 'src/components/Admin/SeasonCompetitions/SeasonalSettings.vue'
import DivisionsSetup from 'src/components/Admin/SeasonCompetitions/DivisionsSetup.vue'
import EligibilityRules from 'src/components/Admin/SeasonCompetitions/EligibilityRules.vue'
import PlayerEligibility from 'src/components/Admin/SeasonCompetitions/PlayerEligibility.vue'
import CompetitionSideBySideManager from './CompetitionSideBySideManager.vue'
import { CompRegReceiptEmailBlurbEditor } from 'src/components/Admin/SeasonCompetitions/pages/CompRegReceiptEmailBlurbEditor'
import { TabID_t } from "./AdminPage.ilx"
import { Competition, Guid } from 'src/interfaces/InleagueApiV1'
import { vReqT } from 'src/helpers/utils'
import { CompetitionEligibilityStatusReport } from '../CompetitionEligibilityStatusReport'

export default defineComponent({
  components: {
    Competitions,
    Seasons,
    CompetitionOptions,
    SeasonalSettings,
    DivisionsSetup,
    EligibilityRules,
    PlayerEligibility,
    CompetitionSideBySideManager,
    CompRegReceiptEmailBlurbEditor,
    CompetitionEligibilityStatusReport,
  },
  props: {
    competitions: vReqT<readonly Competition[]>(),
    inTabID: vReqT<TabID_t>(),
    inCompetitionUID: vReqT<"" | Guid>(),
    inSeasonUID: vReqT<"" | Guid>(),
    hasSomeCompetitionsWithSuperAdminAccess: vReqT<boolean>(),
    /**
     * The selected comp/season might change in response to changing a tab, because the available options can be different based on the selected tab.
     * So during a tab change, we want to not draw the target tab until this is settled, and we know which particular comp/season is the
     * fresh comp/season target. Generally the values won't change, but they can, generally because of differences in the `enabled` property
     * of each competition.
     */
    isHandlingTabChange: vReqT<boolean>(),
  },
  emits: {
    setSelectedTabID: (_: TabID_t) => true,
    setSelectedCompetitionUID: (_: "" | Guid) => true,
    setSelectedSeasonUID: (_: "" | Guid) => true,
  },
  setup(props, {emit}) {
    const selectedCompetitionUID = computed({
      get() { return props.inCompetitionUID },
      set(v) { emit("setSelectedCompetitionUID", v) }
    });

    const selectedSeasonUID = computed({
      get() { return props.inSeasonUID },
      set(v) { emit("setSelectedSeasonUID", v) }
    });

    const selectedTabID = computed({
      get() { return props.inTabID },
      set(v) { emit("setSelectedTabID", v) }
    })

    const changeTab = async (tabID: TabID_t) => {
      selectedTabID.value = tabID;
    }

    const shouldDrawTabs = computed(() => !props.isHandlingTabChange)

    const tabs = tabDefs();

    const shouldOfferSeasonOptions = computed(() => {
      return selectedTabID.value === TabID_t.seasonalSettings
        || selectedTabID.value === TabID_t.divisionSettings
        || selectedTabID.value === TabID_t.eligibilityRules
        || selectedTabID.value === TabID_t.playerEligibility
        || selectedTabID.value === TabID_t.tournaments
        || selectedTabID.value === TabID_t.invitationStatus
    })


    return {
      TabID: TabID_t,
      tabs,
      changeTab,
      selectedTabID,
      selectedSeasonUID,
      selectedCompetitionUID,
      shouldDrawTabs,
      shouldOfferSeasonOptions,
    }
  },
})

function tabDefs() {
  const tabs : {tabID: TabID_t, uiName: string}[] = [
    {tabID: TabID_t.competitionAdministrator, uiName: 'Program Administration',},
    {tabID: TabID_t.seasonalSettings, uiName: 'Seasonal Settings',},
    {tabID: TabID_t.divisionSettings, uiName: 'Division Settings',},
    {tabID: TabID_t.eligibilityRules, uiName: 'Eligibility (Rules)',},
    {tabID: TabID_t.playerEligibility, uiName: 'Eligibility (Players)'},
    {tabID: TabID_t.sideBySide, uiName: "Side by Side"},
    {tabID: TabID_t.registrationReceipt, uiName: "Program Registration Email"},
    {tabID: TabID_t.invitationStatus, uiName: "Invitation Status"},
  ]

  return tabs
}
</script>
