import { defineComponent } from "vue";

import { propsDef } from "./R_TournamentTeamCreate.route"
import { CreateTournamentTeamLoggedOut } from "./TournamentTeamCreate.loggedOut";
import { CreateTournamentTeamLoggedIn } from "./TournamentTeamCreate.loggedIn";
import { NavigationGuardWithThis } from "vue-router";
import { hasCreateTournamentTeamRoutePermission } from "./TournamentTeamUtils";
import { User } from "src/store/User";
import { Client } from "src/store/Client";

const routeChangedHandler : NavigationGuardWithThis<undefined> = (_to, _from, next) => {
  const ok = hasCreateTournamentTeamRoutePermission();
  if (ok) {
    next();
  }
  else {
    next({name: "forbidden"});
  }
}

export default defineComponent({
  name: "R_CreateTournamentTeam",
  props: propsDef,
  beforeRouteEnter: routeChangedHandler,
  beforeRouteUpdate: routeChangedHandler,
  setup(props) {

    return () => User.isLoggedIn
      ? <CreateTournamentTeamLoggedIn {...props}/>
      : <CreateTournamentTeamLoggedOut/>
  }
})
