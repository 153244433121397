import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { useTeamSelectionButtonsPaneSizeWatcher, TeamSelectionButtons } from "src/components/UserInterface/TeamChooser/TeamChooserSelectButtons"
import { BasicTeamChooserSelectionManager, teamChooserSelection } from "src/components/UserInterface/TeamChooser/TeamChooserUtils";
import { TeamChooserMenu, getTeamChooserMenu } from "src/composables/InleagueApiV1.TeamChooser2";
import { TeamCreateUpdateDeleteViewMeta, UpdateTeamArgs, createTeam, deleteTeam, getTeamCreateUpdateDeleteViewMeta, listTeams, updateTeam } from "src/composables/InleagueApiV1.Teams";
import { TailwindBreakpoint, UiOption, arrayFindIndexOrFail, exhaustiveCaseGuard, forceCheckedIndexedAccess, lazyRef, nextOpaqueVueKey, routerGetQueryParamAsStringOrNull, sortBy, useIziToast, useWindowSize, vReqT } from "src/helpers/utils";
import { Guid, Team } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { Public } from "src/store/Public";
import { Ref, computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { CreateTeamForm, EditTeamForm, TeamForm, TeamFormRowElement } from "./TeamEditor";
import { useRouter } from "vue-router";
import { QueryParams } from "./R_TeamEditor.route";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { AutoModal, DefaultModalController, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { Tabs } from "src/components/UserInterface/Tabs";
import { SortArrow } from "src/modules/TableUtils";

export default defineComponent({
  name: "R_TeamEditor",
  setup() {
    const router = useRouter();
    const iziToast = useIziToast();

    const menu = lazyRef<TeamChooserMenu>()
    const menuSelectionManager = lazyRef<BasicTeamChooserSelectionManager>()
    const menuSelection = ref(teamChooserSelection())
    const regionOptions = lazyRef<UiOption[]>()

    // meta info per (comp, division) selection; null if no such selection
    const compDivMeta = ref<TeamCreateUpdateDeleteViewMeta | null>(null)

    const compDivResolvedTeamForms = ref<null | {create: CreateTeamForm, edit: EditTeamForm[]}>(null)

    const ready = ref(false);

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        // n.b. can't prune incomplete paths here, because all paths will be "incomplete" with respect to teams
        // (the menu is (1 season) -> (some comps) -> (some divs) -> (never any teams))
        menu.value = await getTeamChooserMenu(axiosInstance, "team-crud-view-by-comp-div");
        regionOptions.value = Public.buildStandardRegionOptions(await Public.getMasterRegionList(), {includeNilOption: false});

        // should be exactly zero-or-one season in the toplevel menu
        menuSelection.value.seasonUID = forceCheckedIndexedAccess([...menu.value.orderedChildren.keys()], 0) ?? null;

        menuSelectionManager.value = BasicTeamChooserSelectionManager({
          mut_selection: menuSelection,
          menu: menu.value,
        })

        // pulling from query params is a one-time thing from onMounted, for now just to make testing a little easier
        menuSelection.value.competitionUID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, QueryParams.competitionUID);
        menuSelection.value.divID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, QueryParams.divID);

        ready.value = true;
      })
    })

    const doSubmit = async (form: TeamForm) : Promise<void> => {
      if (form.type === "create") {
        await doCreateTeam(form)
        return;
      }
      else if (form.type === "edit") {
        await doUpdateTeam(form)
        return;
      }
      else {
        exhaustiveCaseGuard(form);
      }

      async function doCreateTeam(form: CreateTeamForm) : Promise<void> {
        if (!compDivResolvedTeamForms.value) {
          throw Error("illegal state")
        }

        try {
          const {competitionUID, divID} = form;
          const team = await createTeam(axiosInstance, {
            competitionUID,
            divID,
            teamLetter: form.teamLetter,
            region: form.immutableRegion ? undefined : form.region,
            teamCity: form.immutableCity ? undefined : form.teamCity,
          })
          compDivMeta.value = await getTeamCreateUpdateDeleteViewMeta(axiosInstance, {competitionUID, divID});
          compDivResolvedTeamForms.value.create = freshCreateTeamForm(competitionUID, divID);
          compDivResolvedTeamForms.value.edit = [freshEditTeamFormFromExisting(team, compDivMeta.value), ...compDivResolvedTeamForms.value.edit];
          iziToast.success({message: "Team created."});
          selectedTabIndex.value = TABIDX_EDIT;
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      async function doUpdateTeam(teamForm: EditTeamForm) : Promise<void> {
        if (!compDivResolvedTeamForms.value) {
          throw Error("illegal state")
        }

        try {
          if (!compDivMeta.value) {
            throw Error("illegal state")
          }

          const meta = compDivMeta.value;
          const updateArgs : UpdateTeamArgs = {
            teamID: teamForm.pristine.teamID,
            active: teamForm.active,
            region: teamForm.immutableRegion ? undefined : teamForm.region,
            teamCity: teamForm.immutableCity ? undefined : teamForm.teamCity,
            teamLetter: teamForm.teamLetter
          }

          const spliceIdx = arrayFindIndexOrFail(compDivResolvedTeamForms.value.edit, form => form.pristine.teamID === teamForm.pristine.teamID)
          const freshTeam = await updateTeam(axiosInstance, updateArgs);
          compDivResolvedTeamForms.value.edit.splice(spliceIdx, 1, freshEditTeamFormFromExisting(freshTeam, meta));

          iziToast.success({message: "Team updated."})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
    }

    const doDelete = async (teamID: Guid) : Promise<void> => {
      if (!compDivResolvedTeamForms.value) {
        throw Error("illegal state")
      }

      try {
        await deleteTeam(axiosAuthBackgroundInstance, {teamID});
        compDivResolvedTeamForms.value.edit = compDivResolvedTeamForms.value.edit.filter(form => form.pristine.teamID !== teamID)
        iziToast.success({message: "Team deleted."});
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const confirmDeleteModalController = reactive((() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy();
      const doDeleteWithBusyModalWrapper = async (teamID: Guid) : Promise<void> => {
        if (busy.value) {
          return;
        }

        busy.value = true;

        try {
          try {
            await doDelete(teamID)
          }
          finally {
            busy.value = false;
          }
          confirmDeleteModalController.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController<Team>({
        title: () => <>
          <div>Confirm delete</div>
          <div class="my-2 border-b border-gray-200"/>
        </>,
        content: team => {
          if (!team) {
            return null;
          }

          return <div>
            <div class="my-4">Delete {team.team}?</div>
            <div class="flex gap-2 items-center">
              <t-btn type="button" onClick={() => doDeleteWithBusyModalWrapper(team.teamID)} data-test="yes">Yes</t-btn>
              <t-btn type="button" color="red" onClick={() => confirmDeleteModalController.close() } data-test="no">No</t-btn>
            </div>
            {
              busy.value
                ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                : null
            }
          </div>
        }
      }, {onCloseCB})
    })());

    const confirmDelete = (form: EditTeamForm) => {
      if (form.canDelete) {
        confirmDeleteModalController.open(form.pristine)
      }
      else {
        // do nothing; no reason to have been called
      }
    }

    watch(() => menuSelection.value, async () => {
      const {competitionUID, divID} = menuSelection.value;
      if (competitionUID && divID) {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          compDivResolvedTeamForms.value = null;

          const meta = await getTeamCreateUpdateDeleteViewMeta(axiosInstance, {competitionUID, divID});

          compDivResolvedTeamForms.value = {
            create: freshCreateTeamForm(competitionUID, divID),
            edit: applyEditTeamFormSort((await listTeams(axiosInstance, {
              competitionUID,
              divID,
              includeHosted: true,
            })).map(team => freshEditTeamFormFromExisting(team, meta)))
          }

          compDivMeta.value = meta;
        })
      }
    }, {deep:true})

    const {openAbsolutePosPanes, rootRef} = useTeamSelectionButtonsPaneSizeWatcher()

    const TABIDX_EDIT = 0
    const selectedTabIndex = ref(TABIDX_EDIT);

    const windowSize = useWindowSize();
    const formElementLayoutSize = computed(() => {
      return windowSize.width >= TailwindBreakpoint.lg ? "big" : "small"
    })

    const sortConfig = ref<SortConfig>({what: "active", dir: "asc"});
    const invertDir = (dir: "asc" | "desc") => dir === "asc" ? "desc" : "asc";
    const toggleSort = (v: Sortable) => {
      if (sortConfig.value.what === v) {
        sortConfig.value.dir = invertDir(sortConfig.value.dir)
        return;
      }
      else {
        sortConfig.value = {
          what: v,
          dir: "asc",
        }
      }
    }

    watch(() => sortConfig.value, () => {
      if (compDivResolvedTeamForms.value?.edit) {
        compDivResolvedTeamForms.value.edit = applyEditTeamFormSort(compDivResolvedTeamForms.value.edit)
      }
    }, {deep: true})

    const applyEditTeamFormSort = (forms: readonly EditTeamForm[]) : EditTeamForm[] => {
      const {what, dir} = sortConfig.value;
      if (what === "teamDesignation") {
        return [...forms]
          .sort(sortBy(_ => _.teamLetter, dir))
      }
      else if (what === "active") {
        return [...forms]
          .sort(sortBy(_ => _.active ? 0 : 1, dir))
      }
      else {
        exhaustiveCaseGuard(what)
      }
    }

    return () => {
      if (!ready.value) {
        return null;
      }

      if (menu.value.orderedChildren.size === 0) {
        return <div>No available options</div>
      }

      return (
        <div ref={rootRef} style="--fk-bg-input:white;" data-test="R_TeamEditor">
          <AutoModal controller={confirmDeleteModalController} data-test="ConfirmDeleteTeamModal"/>
          <h2>Master Team List</h2>
          <p>Before a program division can have players or rosters, it must have active teams. This utility shows all historical teams (active or not) and can generate new teams when required.</p>
          <TeamSelectionButtons
            teamSelectMode="single"
            menu={menu.value}
            selectionManager={menuSelectionManager.value}
            levels={{
              competition: true,
              division: true
            }}
            mut_openAbsolutePosPanes={openAbsolutePosPanes}
          />

          <div style="max-width:1024px;">
            {
              compDivResolvedTeamForms.value
                ? <CreateAndEditTabs
                    mut_selectedTabIndex={selectedTabIndex}
                    mut_createForm={compDivResolvedTeamForms.value.create}
                    mut_editForms={compDivResolvedTeamForms.value.edit}
                    toggleSort={toggleSort}
                    sortConfig={sortConfig.value}
                    regionOptions={regionOptions.value}
                    doSubmit={doSubmit}
                    confirmDelete={confirmDelete}
                    formElementLayoutSize={formElementLayoutSize.value}
                />
                : null
            }
          </div>
        </div>
      )
    }
  }
})

type Sortable = "active" | "teamDesignation"
type SortConfig = {what: Sortable, dir: "asc" | "desc"}

const CreateAndEditTabs = defineComponent({
  name: "CreateAndEditTabs",
  props: {
    mut_selectedTabIndex: vReqT<Ref<number>>(),
    mut_createForm: vReqT<CreateTeamForm>(),
    mut_editForms: vReqT<readonly EditTeamForm[]>(),
    toggleSort: vReqT<(_: Sortable) => void>(),
    sortConfig: vReqT<Readonly<SortConfig>>(),
    regionOptions: vReqT<UiOption[]>(),
    doSubmit: vReqT<(_: TeamForm) => void>(),
    confirmDelete: vReqT<(_: EditTeamForm) => void>(),
    formElementLayoutSize: vReqT<"big" | "small">(),
  },
  setup(props) {
    return () => {
      return (
        <Tabs
          selectedIndex={props.mut_selectedTabIndex.value}
          onChangeSelectedIndex={idx => { props.mut_selectedTabIndex.value = idx } }
          tabDefs={[
            {
              "data-test": "editTab",
              keepAlive: false,
              label: "Edit Teams",
              render: () => {
                if (props.mut_editForms.length === 0) {
                  return (
                    <div class="my-4 rounded-md bg-white shadow-md p-2">
                      <div class="flex justify-center items-center m-4">No teams</div>
                    </div>
                  )
                }
                return (
                  <div class="my-4 rounded-md bg-white shadow-md p-2">
                    <div>Team designations must be unique within their program and division.</div>
                    <div class="flex text-sm">
                      <div class="py-2 flex gap-2 items-center">
                        <div
                          class="flex justify-center items-center border border-gray-200 rounded-md pl-1 hover:bg-[rgb(0,0,0,.03125)] active:bg-[rgb(0,0,0,.06125)] cursor-pointer"
                          onClick={() => props.toggleSort("teamDesignation")}
                        >
                          Designation
                          <SortArrow class="ml-1 p-1 rounded-md" dir={props.sortConfig.what === "teamDesignation" ? props.sortConfig.dir : "not-sorted"}/>
                        </div>
                        <div
                          class="flex justify-center items-center border border-gray-200 rounded-md pl-1 hover:bg-[rgb(0,0,0,.03125)] active:bg-[rgb(0,0,0,.06125)] cursor-pointer"
                          onClick={() => props.toggleSort("active")}
                        >
                          Active
                          <SortArrow class="ml-1 p-1 rounded-md" dir={props.sortConfig.what === "active" ? props.sortConfig.dir : "not-sorted"}/>
                        </div>
                      </div>
                    </div>
                    <div class="border-b border-gray-200 mb-2"/>
                    <div>
                      {
                        props.mut_editForms.map((form, i, a) => {
                          const isLast = i === a.length - 1
                          return <div key={form.__vueKey}>
                            <TeamFormRowElement
                              mut_form={form}
                              regionOptions={props.regionOptions}
                              doSubmit={() => props.doSubmit(form)}
                              confirmDelete={() => props.confirmDelete(form)}
                              layoutSize={props.formElementLayoutSize}
                            />
                            {
                              isLast
                                ? null
                                : <div class="my-2 border-b border-gray-200"/>
                            }
                          </div>
                        })
                      }
                    </div>
                  </div>
                )
              }
            },
            {
              "data-test": "create-tab",
              keepAlive: false,
              label: "Create Team",
              render: () => {
                return (
                  <div class="my-4 rounded-md bg-white shadow-md P-2">
                    <div>Team designations must be unique within their program and division.</div>
                    <div>
                      <TeamFormRowElement
                        mut_form={props.mut_createForm}
                        regionOptions={props.regionOptions}
                        doSubmit={() => props.doSubmit(props.mut_createForm)}
                        confirmDelete={() => null}
                        layoutSize={props.formElementLayoutSize}
                      />
                    </div>
                  </div>
                )
              }
            },
          ]}
        />
      )
    }
  }
})

function freshCreateTeamForm(competitionUID: Guid, divID: Guid) : CreateTeamForm {
  const hasAreaPlay = !!Client.value.instanceConfig.areaplay
  return {
    __vueKey: nextOpaqueVueKey(),
    type: "create",
    competitionUID,
    divID,
    pristine: null,
    canDelete: false,
    immutableCity: !hasAreaPlay,
    immutableCityReason: !hasAreaPlay
      ? "not-area-play"
      : undefined,
    immutableRegion: !hasAreaPlay,
    immutableRegionReason: !hasAreaPlay
      ? "not-area-play"
      : undefined,
    delete: false,
    teamLetter: "",
    active: true,
    region: Client.value.instanceConfig.region,
    teamCity: Client.value.instanceConfig.leaguecity,
  }
}

function freshEditTeamFormFromExisting(team: Team, meta: TeamCreateUpdateDeleteViewMeta) : EditTeamForm {
  const hasAreaPlay = !!Client.value.instanceConfig.areaplay
  const isLocalTeam = team.clientID === Client.value.instanceConfig.clientid

  const immutableRegionAndCity = !hasAreaPlay || !isLocalTeam;
  const immutabilityReason = !hasAreaPlay
    ? "not-area-play"
    : !isLocalTeam
    ? "not-local-team"
    : undefined;

  return {
    __vueKey: `edit/${team.teamID}`,
    type: "edit",
    pristine: team,
    // expected to always be found, not having a clientMeta is a bug
    clientMeta: meta.clientInfoByClientID[team.clientID],
    // all teamIDs should be present in `deleteability`, but if its missing for some reason (probably a bug),
    // default to "no, cannot delete"
    canDelete: meta.deletability[team.teamID] ?? false,
    immutableCity: immutableRegionAndCity,
    immutableCityReason: immutabilityReason,
    immutableRegion: immutableRegionAndCity,
    immutableRegionReason: immutabilityReason,
    delete: false,
    teamLetter: team.teamLetter,
    active: !!team.active,
    region: team.region,
    teamCity: team.teamCity
  }
}
