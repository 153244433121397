<template lang="pug">
div(v-if="ready" data-test="SelectVolunteers2")
  div
    .smx-auto.w-full.max-w-md(class='md:mx-2' @submit.prevent="search")
      h1.ml-2.text-2xl.self-end.font-medium(class='md:text-4xl')
        font-awesome-icon.mr-2(:icon='["fas", "search"]')
        | Volunteer Selection
  .q-pa-md.quasar-style-wrap.mt-8.m-2.max-w-md(
      v-if='familyUsers.length',
      data-cy='familyUsersTable',
    )
      q-table(
        :rows='familyUsers',View
        :columns='cols',
        :header='false',
        row-key='userID',
        :rows-per-page-options='[0]',
        hide-pagination
        :binary-state-sort="true"
      )
        template(v-slot:body-cell-User='props')
          template(v-if="props.row.privateProfile && props.row.userID != User.value.userID")
            q-td.cursor-not-allowed.full-width(:props='props' :data-test="`select-user-${props.row.userID}`")
              div.relative
                .font-medium
                  | {{ props.row.firstName }} {{ props.row.lastName }}
                .font-normal {{ props.row.email}}
                div(class="text-xs") User profile marked private.
                //- overlay to make it look unselectable
                div(style="position:absolute; top:0; left:0; width:100%; height: 100%; background-color: rgba(255,255,255,.5);")

          template(v-else)
            q-td.cursor-pointer.full-width(:props='props' @click='handleUserSelected(props.row)' :data-test="`select-user-${props.row.userID}`")
              .font-medium
                | {{ props.row.firstName }} {{ props.row.lastName }}
              .font-normal {{ props.row.email}}
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { axiosInstance } from "src/boot/axios"
import { useRouter } from 'vue-router'
import { propsDef, emitsDef, AdhocUserlike_GetUsersLinkedToCurrentUserFamilies, getUsersLinkedToCurrentUserFamilies } from "./SelectVolunteer2.ilx";

import { User } from "src/store/User"

export default defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(_props, {emit}) {
    const router = useRouter()

    const cols = ref([
      {
        name: 'User',
        label: 'Users',
        required: true,
        align: 'left',
        field: (user: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies) => user,
        sortable: true,
        sort: (l: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies, r: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies) => {
          const xl = `${l.firstName} ${l.lastName}`;
          const xr = `${r.firstName} ${r.lastName}`;
          return xl < xr ? -1 : xl === xr ? 0 : 1;
        },
        classes: 'q-table',
      },
    ])

    const familyUsers = ref<AdhocUserlike_GetUsersLinkedToCurrentUserFamilies[]>([]);

    const handleUserSelected = (userDetail: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies) => {
      if (userDetail.privateProfile && userDetail.userID !== User.value.userID) {
        throw "template shouldn't allow calling this method for a private profile user (unless that user is the current user)";
      }

      emit("userSelected", userDetail)
    }

    const ready = ref(false);

    onMounted(async () => {
      familyUsers.value = (await getUsersLinkedToCurrentUserFamilies(axiosInstance)).users
      ready.value = true;
    })

    return {
      handleUserSelected,
      cols,
      familyUsers,
      ready,
      User,
    }
  }
})


</script>
