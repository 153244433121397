import { FormKit, FormKitMessages } from "@formkit/vue";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import { CalendarSeason, DobCutoff, SeasonCreateOrUpdateData, SeasonForManagerView } from "src/composables/InleagueApiV1.Seasons";
import { TailwindBreakpoint, UiOption, parseIntOr, useWindowSize, vOptT, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent } from "vue";

export const EditSeasonElement = defineComponent({
  props: {
    seasonName: vReqT<string>(),
    formData: vReqT<SeasonFormData>(),
  },
  emits: {
    submit: () => true,
  },
  setup(props, {emit}) {
    const windowSize = useWindowSize();
    const calendarSeasonOptions : UiOption[] = [
      {label: "", value: ""},
      {label: "Fall", value: CalendarSeason.fall.toString()},
      {label: "Winter", value: CalendarSeason.winter.toString()},
      {label: "Spring", value: CalendarSeason.spring.toString()},
      {label: "Summer", value: CalendarSeason.summer.toString()},
    ];

    const strlen = {
      seasonName: {min: 5, max: 30}
    }

    const dobCutoffRadioOptions : UiOption[] = [
      {label: "US Soccer - Full year divisions", value: DobCutoff.noAgeCuttof.toString(), attrs: {"data-test": "dobCutoff/a"}},
      {label: "AYSO (Pre-2016) - Last Day of July age cutoff", value: DobCutoff.lastDayOfJulyCutoff.toString(), attrs: {"data-test": "dobCutoff/b"}},
    ];

    const currentYear = dayjs().year();

    const submit = () => {
      emit("submit")
    }

    const dataTestAttr = computed(() => {
      return props.formData.type === "new"
        ? `EditSeasonElement/seasonUID=new`
        : `EditSeasonElement/seasonUID=${props.formData.seasonUID}`
    })

    return () => {
      return (
        <div style="--fk-margin-outer:none; --fk-max-width-input: none; --fk-bg-input:white; --fk-border:none; --fk-padding-fieldset:none;" class="bg-white rounded-md shadow-md" data-test={dataTestAttr.value}>
          <h2 class="mb-2 bg-gray-200 px-2 py-2 rounded-t-md">{props.seasonName}</h2>
          <div class="p-2">
            <FormKit type="form" actions={false} onSubmit={submit}>
              <div style={windowSize.width <= TailwindBreakpoint.lg
                ? "display:grid; grid-template-columns: 1fr"
                : "display:grid; grid-template-columns: 1fr 1fr"
              }>
                <OneFormCell
                  label="Season Name"
                  tooltip="The cosmetic name of the season that will be displayed to families and coaches.">
                  <FormKit
                    name="Season Name"
                    type="text" v-model={props.formData.data.seasonName} validation={[["required"], ["length", strlen.seasonName.min, strlen.seasonName.max]]}
                    data-test="seasonName"
                  />
                </OneFormCell>

                <OneFormCell
                  label="Season Year"
                  tooltip={`The actual year in which the season takes place, e.g. ${currentYear} for Spring ${currentYear}`}
                >
                  <FormKit
                    name="Season Year"
                    type="text" {...{inputMode: "number"}} v-model={props.formData.data.seasonYear} validation={[["required"]]}
                    data-test="seasonYear"
                  />
                </OneFormCell>

                <FormKit
                  type="group"
                  config={{validationVisibility: "live"}}
                  validation={[["fk_regYearValidity"], ["fk_regYearCalendarSeasonValidity"]]}
                >
                  <OneFormCell label="Membership Year" noBottomBorder={true}>
                    <FormKit
                      name="Membership Year"
                      type="text" {...{inputMode: "number"}} v-model={props.formData.data.registrationYear}
                      validation={[["required"], ["foo",]]}
                      data-test="registrationYear"
                    />
                  </OneFormCell>

                  <div style="grid-column:1/-1;" class="my-2"/>

                  <OneFormCell
                    label="Calendar Season"
                    tooltip={`The name of the calendar season in which the inLeague season begins (e.g. for Fall ${currentYear}/Spring ${currentYear}, select Fall).`}
                  >
                    <FormKit
                      name="Calendar Season"
                      type="select" options={calendarSeasonOptions} v-model={props.formData.data.calendarSeasonID} validation={[["required"]]}
                      data-test="calendarSeasonID"
                    />
                    <div class="my-1">
                      <FormKitMessages/>
                    </div>
                  </OneFormCell>

                </FormKit>

                <OneFormCell
                  label="Division DOB Cutoff"
                  tooltip="The DOB cutoff to use when assigning players to divisions. AYSO-style uses the last day of the school year cutoff month (July) of the Registration Year; after 7/31, players are assigned to the younger division. US Soccer-style (or 'full-year divisions') has no cutoff."
                >
                  <FormKit
                    name="Division DOB Cutoff strategy"
                    type="radio" options={dobCutoffRadioOptions} v-model={props.formData.data.fullYearCalendar} validation={[]}
                  />
                </OneFormCell>

                <OneFormCell
                  label="Use July 31 cutoff for birth years on or after"
                  tooltip="If a birth year is entered in this field, any players born in that year or later will use the school year (July 31) DOB cutoff to determine division regardless of the setting above. If you are already using the school year / July 31 cutoff, this setting has no effect."
                >
                  <FormKit
                    name="Division DOB Cutoff year"
                    type="number"
                    {...{inputMode: "numeric"}}
                    v-model={props.formData.data.algorithmSplitBirthYear}
                    validation={[["min", 1900], ["max", dayjs().year() + 1]]}
                    validationMessages={{
                      // todo: update formkit? or patch it at least. they emit "Must be at least 1900 Division DOB Cutoff ." (with the name trailing like that)
                      min: `Division DOB Cutoff must be at least 1900`
                    }}
                    data-test="algorithmSplitBirthYear"
                  />
                </OneFormCell>

                <OneFormCell
                  label="Season is Disabled"
                  tooltip="Seasons marked as disabled are ignored for registration purposes (for ALL programs!), even if their registration dates would otherwise keep them open. Seasons may be disabled to temporarily close registration without having to change registration dates."
                >
                  <FormKit
                    name="Disabled"
                    options={[{label: "Yes (disabled)", value: true, attrs: {"data-test": "disabled/yes"}}, {label: "No", value: false, attrs: {"data-test": "disabled/no"}}]}
                    type="radio" v-model={props.formData.data.disabled} validation={[]}
                  />
                </OneFormCell>

                <OneFormCell
                  label="Enable in person registration"
                  tooltip="Registrations submitted on this date will receive an option to pay by cash or check (in person only -- not electronic checks!). To disable this option, leave this field blank."
                >
                  <FormKit
                    name="In person registration date"
                    type="date" v-model={props.formData.data.inPersonDate} validation={[]}
                    data-test="inPersonDate"
                  />
                    {
                      props.formData.data.inPersonDate
                        ? <div class="text-sm il-link mt-1" onClick={() => props.formData.data.inPersonDate = ""}>Clear</div>
                        : null
                    }
                </OneFormCell>

                <OneFormCell
                  label="Enforce Ref Division Preferences"
                  tooltip="When enabled, referee volunteers must select at least one division. When disabled, allows for 'no division preference."
                >
                  <FormKit
                    name="Enforce Ref Division Preferences"
                    type="radio" options={[{label: "Yes", value: true, attrs: {"data-test": "requireRefDiv/yes"}}, {label: "No", value: false, attrs: {"data-test": "requireRefDiv/no"}}]} v-model={props.formData.data.requireRefDiv} validation={[["required"]]}
                    data-test="requireRefDiv"
                  />
                </OneFormCell>

                <OneFormCell
                  label="Allow 3 Year Olds"
                  tooltip="AYSO Playground: If enabled, will allow players who turn 3 as of January 1st of the registration year to be added to the family profile"
                >
                  <FormKit
                    name="Allow 3 Year Olds"
                    type="radio" options={[{label: "Yes", value: true, attrs: {"data-test": "hasThreeYearOlds/yes"}}, {label: "No", value: false, attrs: {"data-test": "hasThreeYearOlds/no"}}]} v-model={props.formData.data.hasThreeYearOlds} validation={[["required"]]}
                    data-test="hasThreeYearOlds"
                  />
                </OneFormCell>

                <OneFormCell
                  label="Default Date to Release Rosters"
                  tooltip="The default value upon which team rosters will be released for this season. May be overridden on a per-program or per-division basis from the Roster Embargo utility."
                >
                  <FormKit
                    name="Default Date to Release Rosters"
                    type="date" v-model={props.formData.data.rosterDate} validation={[]}
                    data-test="rosterDate"
                  />
                </OneFormCell>

                <OneFormCell
                  label="Lock Referee Volunteer Points After"
                  tooltip="Volunteer points may not be allocated to teams after this date. Leave blank if you wish to allow referees to allocate points to teams at any time."
                >
                  <FormKit
                    name="Lock Referee Volunteer Points After"
                    type="date" v-model={props.formData.data.pointsLockDate} validation={[]}
                    data-test="pointsLockDate"
                  />
                    {
                      props.formData.data.pointsLockDate
                        ? <div class="text-sm il-link mt-1" onClick={() => props.formData.data.pointsLockDate = ""}>Clear</div>
                        : null
                    }
                </OneFormCell>

                <OneFormCell
                  label="Prompt Coaches to Enter Ratings After"
                  tooltip="Leave blank if you do not want to prompt coaches to enter ratings. Coaches may enter ratings for the current season at any time regardless of this value."
                >
                  <FormKit
                    name="Prompt Coaches to Enter Ratings After"
                    type="date" v-model={props.formData.data.ratingsPromptDate} validation={[]}
                    data-test="ratingsPromptDate"
                  />
                    {
                      props.formData.data.ratingsPromptDate
                        ? <div class="text-sm il-link mt-1" onClick={() => props.formData.data.ratingsPromptDate = ""}>Clear</div>
                        : null
                    }
                </OneFormCell>

                <OneFormCell
                  label="Lock Player Ratings After"
                  tooltip="Player ratings may be entered for this season up through this date, regardless of whether the season remains active. Leave blank if you do not want to allow prior season ratings -- player ratings may only be submitted for active programs if this field is empty."
                >
                  <FormKit
                    name="Lock Player Ratings After"
                    type="date" v-model={props.formData.data.ratingsDate} validation={[]}
                    data-test="ratingsDate"
                  />
                    {
                      props.formData.data.ratingsDate
                        ? <div class="text-sm il-link mt-1" onClick={() => props.formData.data.ratingsDate = ""}>Clear</div>
                        : null
                    }
                </OneFormCell>

                <OneFormCell
                  label="Lock Registration Data After"
                  tooltip=""
                  noBottomBorder={true}
                >
                  <FormKit
                    name="Lock Registration Data After"
                    type="date" v-model={props.formData.data.profileLockDate} validation={[]}
                    data-test="profileLockDate"
                  />
                    {
                      props.formData.data.profileLockDate
                        ? <div class="text-sm il-link mt-1" onClick={() => props.formData.data.profileLockDate = ""}>Clear</div>
                        : null
                    }
                </OneFormCell>
              </div>

              <div class="mt-4">
                <t-btn type="submit" margin={false} data-test="submit">Submit</t-btn>
              </div>
            </FormKit>
          </div>
        </div>
      )
    }
  }
})

const OneFormCell = defineComponent({
  props: {
    label: vReqT<string | (() => JSX.Element)>(),
    tooltip: vOptT<string>(),
    noBottomBorder: vOptT<boolean>(),
  },
  setup(props, {slots}) {
    return () => <>
      <div class="px-2 flex items-start gap-2 mb-2 lg:mb-0">
        {
          props.tooltip
            ? (
              <span class="cursor-pointer" v-tooltip={{content: props.tooltip}}>
                <FontAwesomeIcon icon={faCircleQuestion}/>
              </span>
            )
            : null
        }
        <span>{typeof props.label === "string" ? props.label : props.label()}</span>
      </div>
      <div class="px-2">{slots.default?.()}</div>
      {
        props.noBottomBorder
          ? null
          : <div class="my-3 lg:my-2 border-b" style="grid-column: -1/1;"/>
      }
    </>
  }
})

export type SeasonFormData =
  | SeasonFormData_New
  | SeasonFormData_Edit

interface SeasonFormData_Base {
  type: "new" | "edit",
  mostRecentSeason: SeasonForManagerView["mostRecentSeason"],
  data: SeasonCreateOrUpdateData
}

interface SeasonFormData_New extends SeasonFormData_Base {
  type: "new"
}

interface SeasonFormData_Edit extends SeasonFormData_Base {
  type: "edit",
  seasonUID: Guid
}
