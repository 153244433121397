import * as iltypes from "src/interfaces/InleagueApiV1"
import { UiOption } from "src/helpers/utils"
import { PropType, WritableComputedRef, computed, defineComponent, watch } from "vue";
import { FormKit } from "@formkit/vue";

/**
 * build selections for all keys in the menu where the first valid option for each level is selected
 */
export function freshSelectionsFirstOfEach<Ks extends string>(menuDef: iltypes.MenuTreeDef<Ks>) : {[K in Ks]: string} {
  const keys = menuDef.meta.map(_ => _.key);
  const result : any = {}

  // initialize defaults of "nothing"
  // it's possible to get an empty menu
  keys.forEach(key => result[key] = "")

  let working : iltypes.MenuTree[] | null = menuDef.menu.children.length === 0 ? null : menuDef.menu.children;

  while (working) {
    const key = keys.shift();
    if (!key) {
      // should always be a key while we have a node to work
      // ("keys.length is same as tree depth")
      throw Error("incoherent menuDef / menuMeta")
    }
    const value = working[0].detail.entityID;
    result[key] = value;
    // leaf level should probably not have a children prop, or be tagged as leaf, or something
    working = working[0].children.length === 0 ? null : working[0].children;
  }

  return result;
}

export function buildOptionsByKey<Ks extends string>(menuDef: iltypes.MenuTreeDef<Ks>, selections: Record<string, string>) : Record<Ks, {disabled: boolean, options: UiOption[]}> {
  const result : Record<string, {disabled: boolean, options: UiOption[]}> = {}

  let workingRoot : iltypes.MenuTree | null = menuDef.menu;

  for (const {key} of menuDef.meta) {
    if (workingRoot === null) {
      // implying that selections up the tree have made it impossible to make a selection from this level or downward
      result[key] = {disabled: true, options: []}
      continue;
    }

    const selectedEntityID = selections[key];

    if (workingRoot.children.length === 0) {
      result[key] = {
        disabled: true,
        options: [{value: "", label: "No available options"}]
      };
    }
    else {
      result[key] = {
        disabled: false,
        options: workingRoot.children.map(v => ({value: v.detail.entityID.toString(), label: v.detail.label}))
      };
    }

    const next : iltypes.MenuTree | undefined = workingRoot.children.find(({detail}) => detail.entityID /*not strict*/ == selectedEntityID)

    if (!next) {
      workingRoot = null;
    }
    else {
      workingRoot = next;
    }
  }

  return result;
}

export function isValidSelectionPrefix(menuDef: iltypes.MenuTreeDef, selectionPath: string[]) : boolean {
  if (selectionPath.length > menuDef.meta.length) {
    return false;
  }

  let workingRoot : iltypes.MenuTree = menuDef.menu;

  for (const selectedEntityID of selectionPath) {
    const next = workingRoot.children.find(node => node.detail.entityID === selectedEntityID)
    if (!next) {
      return false;
    }
    workingRoot = next
  }

  return true;
}

export function validCompleteSelectionPathOrNull<Ks extends string>(menuDef: iltypes.MenuTreeDef<Ks>, selectionPath: string[]) : {[K in Ks]: string} | null {
  if (selectionPath.length !== menuDef.meta.length) {
    return null
  }

  if (!isValidSelectionPrefix(menuDef, selectionPath)) {
    return null
  }

  const result : any = {}
  menuDef.meta.forEach((v,i) => { result[v.key] = selectionPath[i] })
  return result;
}

/**
 * Returns the payloads for the selected nodes. Assumes a "fully selected" menu.
 * Probably we'd want to support partial selections.
 * If the "current selection" is invalid or partial, returns null.
 */
export function payloadsForCompleteSelection<Ks extends string, Payloads extends {[P in Ks]?: {}}>(
  menuDef: iltypes.MenuTreeDef<Ks, Payloads>,
  currentSelections: {[K in Ks]: string}
) : null | MenuPayloads<iltypes.MenuTreeDef<Ks, Payloads>> {
  const result : any = {}

  let workingRoot : iltypes.MenuTree = menuDef.menu;

  for (const meta of menuDef.meta) {
    const selectedEntityID = currentSelections[meta.key]
    // non-strict equality check, and convert to string first anyway
    // generally forms are going to have stringified number but the entityID might be an actual number
    const next = workingRoot.children.find(node => node.detail.entityID.toString() == selectedEntityID.toString())
    if (!next) {
      return null;
    }
    workingRoot = next
    result[`for_${meta.key}`] = workingRoot.detail
  }
  return result;
}

export type MenuPayloads<U extends iltypes.MenuTreeDef> = U extends iltypes.MenuTreeDef<infer Ks, infer Payloads>
  ? {[K in Ks as `for_${K}`]: iltypes.MenuTreeNodeDetail & Payloads[K]}
  : never;

export function menuIsEmpty(menuTree: iltypes.MenuTree) {
  return menuTree.children.length === 0
}

export const MenuTree = defineComponent({
  props: {
    menuDef: {
      required: true,
      type: null as any as PropType<iltypes.MenuTreeDef>
    },
    /**
     * we write directly into these as a direct result of user input,
     * or when user input invalidates a selection further down the tree
     */
    mut_selections: {
      required: true,
      type: null as any as PropType<Record<string, string>>
    },
  },
  setup(props) {
    const optionsByKey = computed(() => buildOptionsByKey(props.menuDef, props.mut_selections));

    const recomputeValidSelections = (currentSelections: Readonly<Record<string,string>>) : Record<string, string> => {
      const result = {...currentSelections}
      const optionsByKey = buildOptionsByKey(props.menuDef, currentSelections)

      for (const {key} of props.menuDef.meta) {
        const options = optionsByKey[key]
        if (!options) {
          throw Error("bad key?")
        }

        if (options.disabled) {
          result[key] = "";
          continue;
        }

        if (options.options.length === 0) {
          // shouldn't happen right?
          continue;
        }

        if (!options.options.find(v => v.value === currentSelections[key])) {
          result[key] = options.options[0].value;
        }
      }

      return result
    }

    const local = computed<Record<string, WritableComputedRef<string>>>(() => {
      const result : Record<string, WritableComputedRef<string>> = {}
      props.menuDef.meta.forEach(meta => {
        result[meta.key] = computed({
          //
          // Simple read
          //
          get: () => props.mut_selections[meta.key],
          //
          // On write, we need to check all other selections for validity (well, really just need to check the children
          // of the affected node, but these aren't expected to be deeper than 3 or 4 levels)
          // Once the validity and possibly adjusted selections are computed, atomically commit the update, so that
          // readers don't see selections in a weird in-between state where some selections are OK but others are not
          //
          set: (v) => {
            const currentSelections = {
              ...props.mut_selections, // all current selections
              [meta.key]: v // but with this value updated
            }

            Object.assign(props.mut_selections, recomputeValidSelections(currentSelections))
          }
        })
      });
      return result;
    })

    // this both:
    //  - useful: caller need not initialize their selections, we'll just pick the first
    //  - not useful: we might change the "initial" selections the parent provides (though, why would the parent have provided invalid selections?)
    // generally it seems more useful than not-useful
    watch(() => optionsByKey.value, () => {
      Object.assign(props.mut_selections, recomputeValidSelections(props.mut_selections))
    }, {immediate: true})

    return () => {
      return (
        <div>
          {
            props.menuDef.meta.map(({key, label}) => <FormKit
              type="select"
              v-model={local.value[key].value}
              label={label}
              disabled={optionsByKey.value[key].disabled}
              options={optionsByKey.value[key].options}
            />)
          }
        </div>
      )
    }
  }
})
