import { FormKit } from "@formkit/vue"
import type { AxiosInstance } from "axios"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { exhaustiveCaseGuard, FK_validation_strlen, UiOption, vReqT } from "src/helpers/utils"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { defineComponent, ref } from "vue"

export type TournamentTeamExpandedForConfigurator = iltypes.WithDefinite<iltournament.TournamentTeamFromListTournamentTeamsEndpoint, "admin" | "playerLinks" | "officials">

export async function getTournamentTeamExpandedForConfigurator(axios: AxiosInstance, tournamentTeamID: iltypes.Integerlike) : Promise<TournamentTeamExpandedForConfigurator> {
  return await iltournament
    .getTournamentTeam(
      axios, {
        tournamentTeamID: tournamentTeamID,
        expand: ["admin", "playerLinks", "officials"]
    }) as TournamentTeamExpandedForConfigurator
}

export interface TextWithMaxLen {
  text: string,
  readonly maxLen: number
}

export function TextWithMaxLen(initialText: string, maxLen: number) : TextWithMaxLen {
  const text = ref(initialText)
  return {
    get text() { return text.value },
    set text(s) { text.value = s },
    get maxLen() { return maxLen },
  }
}

export interface RefMutablesFormData {
  comments: TextWithMaxLen,
  maxAR: "" | iltypes.Integerlike,
  maxCR: "" | iltypes.Integerlike,
  ref_badgeLevel: TextWithMaxLen,
}

export interface CoachMutablesFormData {
  comments: TextWithMaxLen,
}

export const STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX = 3000;
export const STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX = 100;


//
// This is the agreed upon sanctioned list used to build "highest{Cr,Ar}Options".
// It's not clear if we can pull this from somewhere (list of div links for the tournmaent maybe?)
//
const __magicHardCodedHighestCrArOptions = [8,10,12,14,19];

// chief ref
export const highestCrOptions = ((() => {
  const result : UiOption[] = [{label: "N/A", value: ""}]
  for (const i of __magicHardCodedHighestCrArOptions) {
    result.push({label: `U${i}`, value: `${i}`})
  }
  return result;
})())

// asst. ref
export const highestArOptions = ((() => {
  const result : UiOption[] = [{label: "N/A", value: ""}]
  for (const i of __magicHardCodedHighestCrArOptions) {
    result.push({label: `U${i}`, value: `${i}`})
  }
  return result;
})())

export const RefMutablesForm = defineComponent({
  props: {
    highestCrOptions: vReqT<UiOption[]>(),
    highestArOptions: vReqT<UiOption[]>(),
    refForm: vReqT<RefMutablesFormData>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="flex flex-col" style="--fk-margin-outer:none;">
          <div class="text-sm font-medium my-1">Highest CR</div>
          <FormKit type="select" data-test="highestCR" name="Highest CR" options={props.highestCrOptions} v-model={props.refForm.maxCR}/>

          <div class="text-sm font-medium my-1">Highest AR</div>
          <FormKit type="select" data-test="highestAR" name="Highest AR" options={props.highestArOptions} v-model={props.refForm.maxAR}/>

          <div class="text-sm font-medium">Referee Badge Level</div>
          <div class="text-xs border-l ml-1 pl-2 my-1">We will use the AYSO-supplied badge level for each volunteer if it is available; please indicate the volunteer's badge level so that we have it if it is not available.</div>
          <FormKit type="text" data-test="ref_badgeLevel" name="Referee Badge Level" v-model={props.refForm.ref_badgeLevel.text} validation={[FK_validation_strlen(0, props.refForm.ref_badgeLevel.maxLen)]}/>

          <div>
            <div class="text-sm font-medium">Comments</div>
            <FormKit type="textarea" v-model={props.refForm.comments.text} class="w-full min-h-[10em]" validation={[FK_validation_strlen(0, props.refForm.comments.maxLen)]}/>
          </div>
          <div class="ml-auto mb-2 text-xs">
            {props.refForm.comments.text.length}/{props.refForm.comments.maxLen}
          </div>
        </div>
      )
    }
  }
})

export function getRefBadgeInfo(args: {ref_badgeLevel_fromUserRecord: string, ref_badgeLevel_fromTournTeamOfficalRecord: string}) {
  if (args.ref_badgeLevel_fromUserRecord) {
    return {
      source: "stack",
      value: args.ref_badgeLevel_fromUserRecord
    } as const
  }
  else if (args.ref_badgeLevel_fromTournTeamOfficalRecord) {
    // well, source is "someone (probably a coach! maybe an admin) input this via that 'add ref' form"
    return {
      source: "coach",
      value: args.ref_badgeLevel_fromTournTeamOfficalRecord
    } as const
  }
  else {
    return {
      source: "not-available",
      value: ""
    } as const
  }
}
