import { axiosInstance } from "src/boot/AxiosInstances";
import { Schema, getSchemaRoots, getSchema, getQuery, SerializedQueryRequest, listQueries, runQuery, QueryResult } from "src/composables/InleagueApiV1.ReportBuilder";
import { UiOption, useAsyncState, useIziToast } from "src/helpers/utils";
import { defineComponent, onMounted, ref, watch } from "vue";
import { ReportBuilderImpl } from "./ReportBuilder.impl";
import { Guid } from "src/interfaces/InleagueApiV1";
import { FormKit } from "@formkit/vue";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { User } from "src/store/User";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";

import { SoccerBall } from "../SVGs";
import { Client } from "src/store/Client";
import { defaultLoggedInErrorResponseHandler, freshAxiosInstance } from "src/boot/axios";

export default defineComponent({
  setup() {
    const iziToast = useIziToast();
    const asyncState = useAsyncState<{
      entityOptions: UiOption[],
    }>()

    const queryOptions = ref<null | UiOption[]>(null)
    const selectedQueryID = ref<"" | Guid>("")
    const selectedQuery = ref<SerializedQueryRequest | null>(null)

    const currentSchema = ref<null | Schema>(null);

    /**
     * we need to force-re-render when we swap out schema,
     * as reportBuilder impl is not robust to prop changes
     */
    const renderKey = ref(0)
    const selectedQueryRoot = ref("")
    const hasNoAvailableQueryRoots = ref(false);

    const doGetSchemaForQueryRoot = async (queryRoot: string) : Promise<void> => {
      const schema = await getSchema(axiosInstance, queryRoot)
      currentSchema.value = schema;
      renderKey.value += 1
    }

    const doGetAvailableQueriesForEntityType = async (coreEntity: string) : Promise<void> => {
      const listing = await listQueries(axiosInstance, {coreEntity: coreEntity, userID: User.isInleague ? "ALL" : User.userData!.userID})
      queryOptions.value = listing.map(v => ({label: v.queryName, value: v.queryID}))

      if (queryOptions.value.length === 0) {
        queryOptions.value = [{label: "None available", value: "", attrs: {disabled: true}}]
      }
      else {
        queryOptions.value = [
          {label: "", value: ""},
          ...queryOptions.value
        ]
      }
      renderKey.value += 1
    }

    const doLoadSelectedQuery = async () : Promise<void> => {
      try {
        selectedQuery.value = (await getQuery(axiosInstance, {queryID: selectedQueryID.value})).query;
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doRunQuery = async (request: SerializedQueryRequest) : Promise<QueryResult | null> => {
      const abortController = new AbortController();
      const axios = freshAxiosInstance({
        useCurrentBearerToken: true,
        signal: abortController.signal,
        responseInterceptors: [{
          error: defaultLoggedInErrorResponseHandler(iziToast)
        }]
      })

      const cancel = () => {
        abortController.abort();
      }

      const globalSpinner = () => (
        <div class="w-full h-full flex items-center justify-center">
          <div class="p-4 rounded-md shadow-md bg-white flex flex-col items-center justify-center">
            {/*negative margin makes up for odd vertical padding/margin on soccer ball*/}
            <div class="-mt-6">
              <SoccerBall color={Client.value.clientTheme.color} width="2em"/>
            </div>
            <div>Running query...</div>
            <div class="il-link" onClick={cancel}>Cancel</div>
          </div>
        </div>
      );

      try {
        return await GlobalInteractionBlockingRequestsInFlight
          .withSpinner(async () => await runQuery(axios, request), 0, globalSpinner);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        return null
      }
    }

    onMounted(async () => {
      asyncState.value = {
        ready: true,
        entityOptions: await getSchemaRoots(axiosInstance),
      }

      if (asyncState.value.entityOptions.length === 0) {
        hasNoAvailableQueryRoots.value = true;
        return;
      }
      else {
        selectedQueryRoot.value = asyncState.value.entityOptions[0].value
      }

      watch(() => selectedQueryRoot.value, async () => {
        await doGetSchemaForQueryRoot(selectedQueryRoot.value)
        await doGetAvailableQueriesForEntityType(selectedQueryRoot.value);
      }, {immediate: true})
    })

    return () => {
      if (hasNoAvailableQueryRoots.value) {
        return "no available core entities";
      }

      if (!asyncState.value.ready || !currentSchema.value) {
        return "loading..."
      }

      return (
        <div>
          {
            queryOptions.value
              ? <FormKit type="select" options={queryOptions.value} v-model={selectedQueryID.value} label="Queries from DB"/>
              : null
          }
          {
            selectedQueryID.value
              ? <t-btn onClick={doLoadSelectedQuery}>Load query</t-btn>
              : null
          }

          <ReportBuilderImpl
            key={renderKey.value}
            schema={currentSchema.value}
            mut_selectedQueryRoot={selectedQueryRoot}
            queryRootOptions={asyncState.value.entityOptions}
            query={selectedQuery.value}
            doRunQuery={doRunQuery}
          />
        </div>
      )
    }
  }
})
