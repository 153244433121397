import dayjs from 'dayjs'
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { boot } from 'quasar/wrappers'

export default boot(({ app, store }) => {
  app.provide('dayjs', dayjs)
  configureDayjs();
})

// tests need these extensions, too (they can't "just" import dayjs from 'dayjs')
export function configureDayjs() : void {
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);
  dayjs.extend(isSameOrAfter)
  dayjs.extend(isSameOrBefore)
}
