<template lang="pug">
t-dialog(v-if='show' data-test="AdditionalPlayer")
  div Would you like to add another player to your family?
  .flex.mt-4.w-full.justify-between
      t-btn(
        @click='$emit("close-popup")',
        label='Add Another Player',
        data-cy='addPlayer'
      )
      t-btn(
        @click='$emit("register")',
        data-cy='proceedToRegistration'
        label='Register Current Player(s)'
      )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AdditionalPlayer',
  emits: ['closePopup', 'register'],
  setup() {
    const show = ref(true)

    return {
      show
    }
  },
})
</script>

