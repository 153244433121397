<template lang="pug">
div(:class='[showModal ? "overscroll-none" : "overscroll-auto"]' v-if="ready")
  CoachListingByGameModal(
    v-bind="coachListingByGameModalProps"
    v-on="coachListingByGameModalHandlers"
  )
  RefereeModal(
    :augmentedGame='modalControllerCandidate_augmentedGame'
    :teams='modalControllerCandidate_selectedTeamsNames',
    :field='modalControllerCandidate_selectedField',
    :refSlotOptionsForSelectedCompetition="refSlotOptionsForSelectedCompetition"
    :showModal="showModal",
    v-on:close='showModal = false',
    v-on:reloadGame='handleReloadGameEvent'
  )

  .quasar-style-wrap.mt-8
    q-table.my-sticky-virtscroll-table.mx-auto(
      :title="tableTitle",
      :rows='augmentedGamesPotentiallyPrefiltered',
      :columns='columns',
      dense,
      :filter='filter',
      :binary-state-sort="true"
      v-model:pagination="quasarTablePagination"
      :rows-per-page-options='[0]',
      :virtual-scroll-sticky-size-start='48',
      style='max-width: 1800px',
      hide-pagination,
      data-cy='gamesTable'
    )
      template(v-slot:top-right)
        input.border.rounded-md.p-2(
          dense,
          debounce='300',
          v-model='filter',
          placeholder='Search',
          class='customInput'
        )
      template(v-slot:header-cell-matchReport)
        q-th
          div Match
          div Report
      template(v-slot:body="props")
        q-tr(:props="props" :key="rowRenderKey(props.row, 1)" :data-test="`gameID=${props.row.game.gameID}`")
          q-td(data-cy='gameTime' v-if="shouldRenderColumn[colNames.date]" :style="commonTdCellStyles(props)")
            div {{ formatDate(props.row.game.gameStart) }}
          q-td(data-cy='gameTime' v-if="shouldRenderColumn[colNames.time]" :style="commonTdCellStyles(props)")
            div {{ formatTime(props.row.game.gameStart) }}-
            div {{ formatTime(props.row.game.gameEnd) }}
          q-td(data-cy='field' v-if="shouldRenderColumn[colNames.field]" :style="commonTdCellStyles(props)")
            div {{ columnsMap[colNames.field].field(props.row) }}
          q-td(data-cy='divisions' v-if="shouldRenderColumn[colNames.gameDivision]" :style="commonTdCellStyles(props)")
            div {{ columnsMap[colNames.gameDivision].field(props.row) }}
          q-td(data-test='teamNames' v-if="shouldRenderColumn[colNames.teams]" :style="commonTdCellStyles(props)")
            div(class="hover:bg-slate-200 cursor-pointer" @click="openCoachListingByGameModal(props.row.game)")
              TeamInfoCell(:augmentedGame="props.row")
          q-td(:class='{ "cursor-pointer": isAdmin }', data-cy='gameNum' v-if="shouldRenderColumn[colNames.gameNo]" :style="commonTdCellStyles(props)")
            button.ml-1(
              v-if='isAdmin',
              v-tooltip.bottom='"Modify Referee Details"',
              :disabled='showModal',
              @click='() => toggleModal(props.row)',
              :class='`${isAdmin ? "cursor-pointer hover:bg-slate-200" : ""} p-1`'
            )
              .mt-1.text-weight-bold {{ props.row.game.gameNum }}
            .mt-1(v-else) {{ props.row.game.gameNum }}
          q-td(style='font-size: 1.5rem' v-if="shouldRenderColumn[colNames.matchReport]" :style="commonTdCellStyles(props)")
            div(v-if="columnsMap[colNames.matchReport].field(props.row) === 'DO-NOT-SHOW-MATCH-REPORT-LINK'")
              //- nothing
            div(v-else class="flex justify-center items-start")
              component(:is="MatchReportRouterLink" :game="props.row.game")
          //-
          //- refNum is 1-indexed;
          //- numSlots is >= 0, expected to be in range [1,4]
          //-
          template(v-for='refNum in 4')
            template(v-if="!columns.find(v => v.name === `REF${refNum}`)")
              //- nothing here -- there's no table header for this column, so we don't want to render the accompanying cell
            q-td(v-else-if="(refNum > props.row.refConfig.numSlots) || !shouldRenderColumn[`REF${refNum}`]" :style="commonTdCellStyles(props)")
              //- we want a td cell, but otherwise a no-op
            q-td(
              v-else
              data-cy='refSlots',
              :someProp='props',
              :style="commonTdCellStyles(props)"
            )
              div(v-if='props.row.game[lockTitles[refNum - 1]]')
                div(v-if='props.row.game[`ref${refNum}`]')
                  | {{ props.row.game[`ref${refNum}`].FirstName }} {{ props.row.game[`ref${refNum}`].LastName }}
                div(v-else-if='props.row.game[`ref${refNum}Vol`]')
                  | {{ props.row.game[`ref${refNum}Vol`].FirstName }} {{ props.row.game[`ref${refNum}Vol`].LastName }}
                span.italic.text-blue-700.cursor-pointer(
                  v-if="isAdmin"
                  @click="toggleModal(props.row)"
                  class="hover:bg-gray-200"
                )
                  | (Unavailable)
                span.italic(v-else) (Unavailable)
              Btn2(
                v-else-if='!props.row.game[`ref${refNum}`] && !props.row.game[`ref${refNum}Vol`]',
                class="px-1 py-1"
                style="font-weight:500; font-size:14px;"
                @click='isAdmin ? toggleModal(props.row) : createSignUpRequest(props.row.game.gameID, refNum)',
                :data-test='`signUp/gameID=${props.row.game.gameID}/refNum=${refNum}`'
              )
                div
                  div {{ isAdmin ? 'ASSIGN' : 'SIGN UP' }}
                  div(class="text-xs") {{ getButtonTierRefPosName(props.row, refNum)?.toUpperCase() }}
              span(v-if='(typeof props.row.game[`ref${refNum}`]) !== "string"')
                .mt-1
                  //- maybe-router-link candidate (same content, but somtimes router-linked, sometimes not)
                  template(v-if="volunteerDetailsLink.hasNavigateToPermission")
                    //- data-test here is like [data-test="refN=SOME-GUID"] where N is in [1,4]
                    router-link(:to="volunteerDetailsLink.to(props.row, 'ref', refNum)" target="_blank" :data-test="`ref${refNum}=${props.row.game[`ref${refNum}`].ID}`")
                      span
                        span(
                          v-if="showYouthRefIndicator(props.row.game[`ref${refNum}`])"
                          class="text-blue-500 text-xs inline-block"
                          style="margin-right: .125em; transform: translateY(-12.5%);"
                          v-tooltip="{content: 'Is youth volunteer'}"
                        )
                          | (Y)
                        span(class="p-1 hover:bg-gray-100 rounded-md" v-tooltip.bottom="{content: `Volunteer details`}")
                          span {{ props.row.game[`ref${refNum}`].FirstName }} {{ props.row.game[`ref${refNum}`].LastName }}
                  template(v-else)
                    span
                      span(
                        v-if="showYouthRefIndicator(props.row.game[`ref${refNum}`])"
                        class="text-blue-500 text-xs inline-block"
                        style="margin-right: .125em; transform: translateY(-12.5%);"
                        v-tooltip="{content: 'Is youth volunteer'}"
                      )
                        | (Y)
                      span(class="p-1") {{ props.row.game[`ref${refNum}`].FirstName }} {{ props.row.game[`ref${refNum}`].LastName }}
                  div.text-red-500.text-xs.ml-1(
                    v-if='props.row.game[`ref${refNum}`] && props.row.game[`ref${refNum}`].lastEAYSOYear < currentAYSOYear() && isAdmin',
                    class='p-0.5'
                  )
                    span(v-tooltip.bottom='{content: `${props.row.game[`ref${refNum}`].refRiskResult.color}: ${props.row.game[`ref${refNum}`].refRiskResult.description}`}')
                      | {{ props.row.game[`ref${refNum}`].refRiskResult.access === false ? '(r)' : ''}}
                .row
                  .text-white.bg-red-500.italic.rounded-md.text-xs.tracking-wide.px-1.mr-1(
                    v-if='!isSelfScheduler && refConflicts[props.row.game.gameID]?.[`ref${refNum}`]',
                    class='p-0.5'
                    :data-test="`ref${refNum}Conflict`"
                  ) Conflict
                .row
                  Btn2(
                    v-if="showCancellationButton(props.row, refNum)",
                    class="px-1 py-1"
                    style="font-weight:500; font-size:14px;"
                    :enabledClasses="btn2_redEnabledClasses"
                    @click='() => cancelRefAssignment(props.row.game.gameID, refNum)',
                    :data-test="`cancelRequest/${refNum}`"
                  )
                    div
                      div REMOVE
                      div(class="text-xs") {{ getButtonTierRefPosName(props.row, refNum)?.toUpperCase() }}
                      div(v-if="willSendApprovalAndCancellationEmails" style="font-size:.5em; line-height:.5em;") W/ CONFIRMATION EMAIL
                  div(v-else :data-test="`cancelRequest/${refNum}/disabled`")
              //- Should this else-if condition be `if typeof refXVol !== "string"`, as we do in the preceding if against refX
              //- because we are testing if this thing is the object variant of it's possible values (where it can be an object, a userID or an empty string)
              //- It seems right now we assume that if it is truthy then we assume that it is an object, but can it ever be a UserID? It is typed as such.
              span(v-else-if='props.row.game[`ref${refNum}Vol`]')
                .mt-1
                  //- maybe-router-link candidate (same content, but somtimes router-linked, sometimes not)
                  template(v-if="volunteerDetailsLink.hasNavigateToPermission")
                    //- data-test here is like [data-test="refVolN=SOME-GUID"] where N is in [1,4]
                    router-link(:to="volunteerDetailsLink.to(props.row, 'refVol', refNum)" target="_blank" :data-test="`refVol${refNum}=${props.row.game[`ref${refNum}Vol`].ID}`")
                      span
                        span(
                          v-if="showYouthRefIndicator(props.row.game[`ref${refNum}Vol`])"
                          class="text-blue-500 text-xs inline-block"
                          style="margin-right: .125em; transform: translateY(-12.5%);"
                          v-tooltip="{content: 'Is youth volunteer'}"
                        )
                          | (Y)
                        span.py-2.px-1(class="hover:bg-gray-100 rounded-md" v-tooltip.bottom="{content: `Volunteer details`}") {{ props.row.game[`ref${refNum}Vol`].FirstName }} {{ props.row.game[`ref${refNum}Vol`].LastName }}
                  template(v-else)
                    span
                      span(
                        v-if="showYouthRefIndicator(props.row.game[`ref${refNum}Vol`])"
                        class="text-blue-500 text-xs inline-block"
                        style="margin-right: .125em; transform: translateY(-12.5%);"
                        v-tooltip="{content: 'Is youth volunteer'}"
                      )
                        | (Y)
                      span(class="p-1") {{ props.row.game[`ref${refNum}Vol`].FirstName }} {{ props.row.game[`ref${refNum}Vol`].LastName }}
                  span.text-red-500.text-xs.ml-1(
                    v-if='props.row.game[`refVol${refNum}`] && props.row.game[`refVol${refNum}`].lastEAYSOYear < currentAYSOYear() && isAdmin',
                    class='p-0.5'
                  ) (r)
                .row
                  .text-white.bg-red-500.italic.text-xs.rounded-md.tracking-wide.px-1(
                    v-if='!isSelfScheduler && refConflicts[props.row.game.gameID]?.[`ref${refNum}Vol`]'
                    class='py-0.5'
                    :data-test="`ref${refNum}VolConflict`"
                  ) Conflict
                .row.whitespace-nowrap.flex.gap-2
                  Btn2(
                    v-if='isAdmin',
                    class="px-1 py-1"
                    style="font-weight:500; font-size:14px;"
                    @click='approveRefAssignment(props.row.game.gameID, refNum)',
                    :data-test="`approve/${refNum}`"
                  )
                    div
                      div APPROVE
                      div(class="text-xs") {{ getButtonTierRefPosName(props.row, refNum)?.toUpperCase() }}
                      div(v-if="willSendApprovalAndCancellationEmails" style="font-size:.5em; line-height:.5em;") W/ CONFIRMATION EMAIL
                  button.ml-1.cursor-pointer(
                    :disabled='showModal',
                    v-tooltip.bottom='"Awaiting approval from referee admin"',
                    v-else
                  )
                    .italic.text-caption.text-uppercase(
                      :padding='"xs none"',
                      :style='{ fontSize: "12px" }'
                    )
                      | Pending...
                  Btn2(
                    v-if="showCancellationButton(props.row, refNum)",
                    class="px-1 py-1"
                    style="font-weight:500; font-size:14px;"
                    :enabledClasses="btn2_redEnabledClasses"
                    @click='cancelSignUpRequest(props.row.game.gameID, refNum)',
                    :data-test="`cancelRequest/${refNum}`"
                  )
                    div
                      div {{ isAdmin ? 'REMOVE' : 'CANCEL' }}
                      div(class="text-xs") {{ getButtonTierRefPosName(props.row, refNum)?.toUpperCase() }}
                      div(v-if="isAdmin && willSendApprovalAndCancellationEmails" style="font-size:.5em; line-height:.5em;") W/ CONFIRMATION EMAIL
                  div(v-else :data-test="`cancelRequest/${refNum}/disabled`")
              span(v-else, caption)
                | {{ props.row.game[`ref${refNum}`].FirstName }} {{ props.row.game[`ref${refNum}`].LastName }}
        //-
        //- bracket info, if there is an associated bracket exists
        //-
        BracketInfoRow(
          :augmentedGame="props.row"
          :renderKey="rowRenderKey(props.row, 2)"
          :cellStyles="commonTdCellStyles(props, 2)"
        )
        //-
        //- additional row containing comments, if they exist
        //-
        q-tr(v-if="hasCommentOrRefComment(props.row)"  :key="rowRenderKey(props.row, 3)" :data-test="rowRenderKey(props.row, 3)")
          //-
          //- n.b. some component-scoped styles set td whitespace to nowrap, which we don't want here
          //-
          q-td(colspan="9999", :style="{fontSize:`.875rem`, ...zebraStripeStyle(props)}")
            template(v-if="props.row.game.comment.trim()")
              div(class="whitespace-normal")
                span.text-md.font-medium Comment:
                span.ml-1
                  | {{ props.row.game.comment }}
            template(v-if="props.row.game.refComment.trim()")
              div(class="whitespace-normal")
                span.text-md.font-medium Referee comment:
                span.ml-1
                  | {{ props.row.game.refComment }}
</template>

<script lang="tsx">

import {
  defineComponent,
  computed,
  ref,
  Ref,
  onMounted,
  PropType,
  watch,
} from 'vue'
import {
  formatTime,
  currentAYSOYear,
  dayjsFormatOr,
} from 'src/helpers/formatDate'
import { RefereeModal } from 'src/components/RefereeSchedule/RefereeModal'
import { FamilyConflict, Game, RefInfo } from 'src/composables/InleagueApiV1.Game'
import { TeamI, WellKnownRefSlotIndex } from 'src/interfaces/Store/client'
import { AugmentedGame, QTableRefereeColumnDef, colNames, ColNames_t, RefSlotOption, emitsDef, SortConfig } from './RefereeScheduleTable.ilx'

import * as iltypes from "src/interfaces/InleagueApiV1"
import { assertTruthy, exhaustiveCaseGuard, NoUncheckedIndexedAccess, requireNonNull, sortByDayJS, vReqT, Writeable } from 'src/helpers/utils'
import { MatchReportRouterLink } from './RefereeScheduleTable.iltsx'
import * as CoachListingByGameModal from "./CoachListingByGameModal"
import { LoggedinLogWriter } from 'src/modules/Loggers'
import { getLogger } from 'src/modules/LoggerService'

import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { authZ_viewVolunteerCerts } from '../User/Editor/R_UserEditor.route'
import { Field } from 'src/composables/InleagueApiV1'
import { Guid, UserID } from 'src/interfaces/InleagueApiV1'
import { teamDesignationAndMaybeName } from '../GameScheduler/calendar/GameScheduler.shared'
import { isYouthReferee } from './R_RefereeSchedule.shared'
import { QuasarPaginationSortPortion } from 'src/helpers/Quasar'
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2"
import dayjs from 'dayjs'
import { QTd, QTr } from 'quasar'
import { Route as R_BracketView } from "src/components/GameScheduler/brackets/R_BracketView"
import { RouterLink } from 'vue-router'

const BracketInfoRow = defineComponent({
  props: {
    augmentedGame: vReqT<AugmentedGame>(),
    renderKey: vReqT<string>(),
    cellStyles: vReqT<Record<string, string>>(),
  },
  setup(props) {
    return () => {
      const bracketInfo = props.augmentedGame.game.bracketInfo
      if (!bracketInfo) {
        return null
      }
      return <QTr key={props.renderKey} data-test={props.renderKey}>
        <QTd {...{colspan:"9999", style: {...props.cellStyles, fontSize: ".875rem"}} as any}>
          <RouterLink class="il-link" {...{target: "_blank"}} to={R_BracketView(bracketInfo)}>
            {bracketInfo.bracketName}, {bracketInfo.bracketRoundName}
          </RouterLink>
        </QTd>
      </QTr>
    }
  }
})

const TeamInfoCell = defineComponent({
  props: {
    augmentedGame: vReqT<AugmentedGame>(),
  },
  setup(props) {
    return () => {
      const game = props.augmentedGame.game
      const bracket = props.augmentedGame.game.bracketInfo

      if (bracket) {
        const hasHome = !!game.home
        const hasVisitor = !!game.visitor

        const leftSource = bracket?.sourceLeft ?? null
        const rightSource = bracket?.sourceRight ?? null


        const runOne = (hasTeam: boolean, teamID: Guid | "", teamDesignation: string, source: typeof leftSource) => {
          const dateformat = "MMM DD, 'YY"
          const winnerLoserText = (v: "winner" | "loser") => v === "winner" ? "Winner of" : "Loser of";
          return <div class="flex items-center p-1">
            {hasTeam
              ? getTeamDesignation({teamID, teamDesignation})
              : source
              ? (source.sourceGame
                ? <div>
                  {winnerLoserText(source.sourceType)} {source.sourceGame.gameNum}
                  <div class="text-xs">
                    {dayjs(source.sourceGame.gameStart).format(dateformat)}, {source.sourceGame.fieldAbbrev}
                  </div>
                </div>
                : <div>{winnerLoserText(source.sourceType)} prior game</div>
              )
              : "TBD"
            }
          </div>
        }

        return <div>
          {runOne(hasHome, game.home, game.homeTeamDesignation, leftSource)}
          <div class="flex gap-1">
            v. {runOne(hasVisitor, game.visitor, game.visitorTeamDesignation, rightSource)}
          </div>
        </div>
      }
      else {
        const home = getTeamDesignation({teamID: game.home, teamDesignation: game.homeTeamDesignation})
        const visitor = getTeamDesignation({teamID: game.visitor, teamDesignation: game.visitorTeamDesignation})
        return <div>
          <div class="flex items-center p-1">{home}</div>
          <div class="flex items-center p-1">v. {visitor}</div>
        </div>
      }
    }
  }
})

export default defineComponent({
  name: 'RefereeScheduleTable',
  components: {
    RefereeModal,
    CoachListingByGameModal: CoachListingByGameModal.CoachListingByGameModal,
    Btn2,
    BracketInfoRow,
    TeamInfoCell,
  },
  props: {
    teams: {
      type: Array as PropType<TeamI[]>,
      required: true,
    },
    isAdmin: Boolean,
    isAdminView: Boolean,
    refColumns: vReqT<QTableRefereeColumnDef[]>(),
    showRefPosNamesPerButton: vReqT<boolean>(),
    groupingMode: vReqT<SortConfig["topLevelGroupBy"]>(),
    targetCompetitionUID: vReqT<Guid>(),
    comp_cancellationPreGameDeadlineHours: vReqT<number | undefined>(),
    comp_nonAdminsCanCancelTheirOwnConfirmedAssignments: vReqT<boolean>(),
    tableTitle: vReqT<string>(),
    isMultiDivSelection: vReqT<boolean>(),
    /**
     * this is "The Main Thing"
     * a listing of games and their associated refConfigs
     * this is fed directly into table output, 1 array element === 1 row
     *
     * All games passed in in this array should share the same game date
     */
    augmentedGames: vReqT<AugmentedGame[]>(),
    /**
     * Either `""`, meaning "all" (as in, do not filter away passed in games based on their field)
     * Or, some integerlike value representing a FieldID
     */
    selectedField: {
      required: true,
      type: String as PropType<"" | iltypes.Integerlike>,
    },
    isSelfScheduler: {
      required: true,
      type: Boolean
    },
    /**
     * really loose with the null/undefined to enforce no-unchecked-indexed-access
     */
    refSlotOptionsForSelectedCompetition: {
      required: true,
      type: null as any as PropType<null | (undefined | RefSlotOption)[]>
    },
    willSendApprovalAndCancellationEmails: {
      required: true,
      type: Boolean,
    },
    sortConfig: vReqT<SortConfig>(),
  },
  emits: emitsDef,
  setup(props, { emit }) {
    const ready = ref(false)
    const lockTitles = ref(['CRLock', 'ARLock', 'AR2Lock', 'MentorLock'])
    const filter = ref('')
    const fields = ref([]) as Ref<readonly Field[]>
    const showModal = ref(false)

    const quasarTablePagination = ref<QuasarPaginationSortPortion<ColNames_t>>(mapSortConfigToQuasarPaginationSortPortion(props.sortConfig))
    watch(() => props.sortConfig, () => {
      quasarTablePagination.value = mapSortConfigToQuasarPaginationSortPortion(props.sortConfig);
    }, {deep: true});
    watch(() => [quasarTablePagination.value.descending, quasarTablePagination.value.sortBy], () => {
      emit("changeSortConfig", {column: quasarTablePagination.value.sortBy, dir: quasarTablePagination.value.descending ? "desc" : "asc"})
    })

    const columns = computed<QTableRefereeColumnDef[]>(() => {
      // filter refcols based on which have been selected
      // seems like we could probably merge this two vars
      // (e.g. we have props.refColumns being filtered here ... could we do that in the parent?)
      // paranoic safenav in the filter expr, fallsback to true but we should really always have a boolean from the lefthand side
      const refCols = props
        .refColumns
        .filter((_,i) => !!(props.refSlotOptionsForSelectedCompetition?.[i]?.selected ?? true))

      const maybeDateCol = props.groupingMode === "byDiv"
        ? [colDefs.date]
        : []

      //
      // column def listing slightly changes if we are looking at "all divisions"
      //
      if (props.isMultiDivSelection) {
        return [
          ...maybeDateCol,
          colDefs.time,
          colDefs.field,
          colDefs.gameDivision,
          colDefs.teams,
          colDefs.gameNo,
          colDefs.matchReport,
          ...refCols
        ]
      }
      else {
        return [
          ...maybeDateCol,
          colDefs.time,
          colDefs.field,
          colDefs.teams,
          colDefs.gameNo,
          colDefs.matchReport,
          ...refCols
        ]
      }
    })

    /**
     * representation of `columns` as a map of (colname -> columndef)
     * Only "renderable" columns are present (as per `columns`)
     */
    const columnsMap = computed<{[colname in ColNames_t]?: QTableRefereeColumnDef}>(() => {
      const result : Record<string, QTableRefereeColumnDef> = {};
      for (const col of columns.value) {
        result[col.name] = col;
      }
      return result;
    })

    /**
     * Basically the same as `columnsMap`, but type is (colname -> boolean)
     * Provides use sites a way to clearly ask "should we render some column?"
     *
     * Keys map either exactly to true or are not present
     */
    const shouldRenderColumn = computed<{[colname in ColNames_t]?: true}>(() => {
      const result : Record<string, boolean> = {};
      for (const col of columns.value) {
        if (col.name === colNames.date) {
          result[col.name] = props.groupingMode === "byDiv"
        }
        else {
          result[col.name] = true;
        }
      }
      return result;
    });

    /**
     * map of ref conflicts by gameID
     */
    const refConflicts = computed(() => getConflicts(props.augmentedGames.map(_ => _.game)))



    /**
     * some users are offered a link to the user editor for referees
     * This returns an object representing either:
     *   - "no permissions"
     *   - "has permissions and here's how to compute the route to the target page"
     */
    const volunteerDetailsLink = computed(() => {
      const hasNavigateToPermission = authZ_viewVolunteerCerts();
      if (hasNavigateToPermission) {
        return {
          hasNavigateToPermission: true,
          to: (tableRowData: AugmentedGame, refOrRefVol: "ref" | "refVol", which: WellKnownRefSlotIndex) => {
            const key : `ref${WellKnownRefSlotIndex}` | `ref${WellKnownRefSlotIndex}Vol`= refOrRefVol === "ref" ? `ref${which}` : `ref${which}Vol`;
            const details = tableRowData.game[key];
            if (typeof details === "string") {
              throw "expected a reference to an object type containing a userID, but got 'string' (which probably represents a nullish value)"
            }

            if (!details.ID) {
              //
              // Unexpected, but it seems to be happening.
              // see: https://inleague-llc.sentry.io/issues/3796740475/events/ef947d32346546b8983c82b0667799cd/?project=5661592
              //
              // 9/19/2023 -- Should be fixed by 9075df3aa20899e8f5bb48b078f14cfffdae940d.
              //              This can be removed in a few weeks after we know it's successful.
              //
              void getLogger(LoggedinLogWriter).log("warning", "RefereeScheduleTable/bughunt-no-id-in-details", {tableRowData, refOrRefVol, which})
            }

            return {
              name: "user-editor",
              params: {userID: details.ID}
            }
          }
        }
      }
      else {
        return {
          hasNavigateToPermission: false
        }
      }
    });

    const userID = computed(() => {
      return User.value.userID
    })

    /**
     * create a signup request
     */
    const createSignUpRequest = (gameID: string, refPosition: number) : void => {
      emit("createRefSignupRequest", {gameID, refPosition})
    }

    /**
     * cancel a signup request
     */
    const cancelSignUpRequest = (gameID: string, refPosition: number) : void => {
      emit("cancelRefSignupRequest", {gameID, refPosition})
    }

    /**
     * cancel an already-approved assignment
     */
    const cancelRefAssignment = (gameID: string, refPosition: number) : void => {
      emit("cancelRefAssignment", {gameID, refPosition});
    }

    /**
     * approve a ref signup intent, converting a "signup intent" to an "assignment"
     */
    const approveRefAssignment = (gameID: string, refPosition: number) : void => {
      emit("approveRefAssignment", {gameID, refPosition})
    }

    const handleReloadGameEvent = (event: {gameID: iltypes.Guid}) : void => {
      emit("reloadGame", event)
    }

    const getFieldByID = (fieldID: number) => {
      for (let i = 0; i < fields.value.length; i++) {
        if (fields.value[i].fieldID === fieldID) {
          return fields.value[i]
        }
      }
    }

    // todo: modal controller?
    const modalControllerCandidate_augmentedGame = ref<AugmentedGame | null>(null);
    const modalControllerCandidate_selectedTeamsNames = ref('')
    const modalControllerCandidate_selectedField = ref('')
    const toggleModal = (row: AugmentedGame) => {
      modalControllerCandidate_augmentedGame.value = row;

      modalControllerCandidate_selectedTeamsNames.value = `${
        getTeamDesignation({teamID: row.game.home, teamDesignation: row.game.homeTeamDesignation})
      } v. ${getTeamDesignation({teamID: row.game.visitor, teamDesignation: row.game.visitorTeamDesignation})}`

      modalControllerCandidate_selectedField.value = getFieldByID(row.game.fieldID)?.fieldName || "";

      showModal.value = !showModal.value // show modal --> should always set to true; and early return if it's already true
    }

    const getTeamByID = (teamID: string) => {
      for (let i = 0; i < props.teams?.length; i++) {
        if (props.teams[i].teamID === teamID) {
          return props.teams[i]
        }
      }
    }

    // probably the parent component should do this
    const augmentedGamesPotentiallyPrefiltered = computed<AugmentedGame[]>(() => {
      if (props.selectedField === "") {
        return props.augmentedGames;
      }
      else {
        return props.augmentedGames.filter(v => v.game.fieldID.toString() === props.selectedField.toString())
      }
    });

    const colDefs = {
      [colNames.date]: {
        name: colNames.date,
        required: true,
        align: 'left',
        label: 'Date',
        field: (row: AugmentedGame) => row,
        sortable: false, // time col is sortable though, we only show date col in "grouped by div" case
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.time]: {
        name: colNames.time,
        required: true,
        align: 'left',
        label: 'Time',
        field: (row: AugmentedGame) => row,
        sortable: true,
        sort: sortByDayJS(_ => _.game.gameStart),
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.field]: {
        name: colNames.field,
        required: true,
        label: 'Field',
        align: 'left',
        field: (row: AugmentedGame) => {
          const field = getFieldByID(row.game.fieldID)
          return field?.fieldAbbrev as string
        },
        sortable: true,
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.teams]: {
        name: colNames.teams,
        required: true,
        label: 'Teams',
        align: 'left',
        field: (row: AugmentedGame) => {
          return `${
            getTeamDesignation({teamID: row.game.home, teamDesignation: row.game.homeTeamDesignation})
          } v. ${
            getTeamDesignation({teamID: row.game.visitor, teamDesignation: row.game.visitorTeamDesignation})
          }`
        },
        sortable: false,
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.gameDivision]: {
        name: colNames.gameDivision,
        required: true,
        label: 'Div',
        align: 'left',
        field: (row: AugmentedGame) => row.game.genderNeutral ? `U${row.game.divNum}` : row.game.division,
        sortable: true,
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.gameNo]: {
        name: colNames.gameNo,
        required: true,
        align: 'left',
        label: 'Game',
        field: (row: AugmentedGame) => row.game.gameNum,
        sortable: true,
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      },
      [colNames.matchReport]: {
        name: colNames.matchReport,
        required: true,
        align: "left",
        label: "Match report",
        // returns a tag that says whether we should show a link to the match report or not
        field: (row: AugmentedGame) => row.game.userIsAuthorizedForMatchReport
          ? "MATCH-REPORT-LINK-OK"
          : "DO-NOT-SHOW-MATCH-REPORT-LINK",
        sortable: false,
        classes: 'q-table--col-auto-width',
        headerClasses: 'q-table--col-auto-width',
      }
    } as const satisfies {[colName in ColNames_t]?: QTableRefereeColumnDef};

    const hasCommentOrRefComment = (row: AugmentedGame) : boolean => {
      return !!(row.game.comment.trim() || row.game.refComment.trim());
    }

    /**
     * The props retrieved from
     * <template v-slot:body="props"> <--- here
     *  ...
     * </template>
     */
    interface QuasarRowProps<T> {
      row: T,
      rowIndex: number
    }

    /**
     * There are 3 known rows for each game, but only row 1 is always-rendered. Rows 2 and 3 are dependent on
     * if there is a bracket, or if there are comments.
     *
     * 1 - general info row (default, if no row num is provided we assume caller meant row 1)
     * 2 - bracket info row
     * 3 - comment row
     */
    const borderStyle = (props: QuasarRowProps<AugmentedGame>, rowNum?: 1 | 2 | 3) : Record<string, string> => {
      if (rowNum === undefined || rowNum === 1) {
        return (hasCommentOrRefComment(props.row) || props.row.game.bracketInfo) ? {"border-bottom": "0 !important"} : {};
      }
      else if (rowNum === 2) {
        return hasCommentOrRefComment(props.row) ? {"border-bottom": "0 !important"} : {};
      }
      else {
        return {}
      }
    }

    const zebraStripeStyle = (props: QuasarRowProps<AugmentedGame>) : Record<string, string> => {
      return props.rowIndex % 2 ? {"background-color": "#f5f5f5"} : {};
    }

    const commonTdCellStyles = (props: QuasarRowProps<AugmentedGame>, rowNum?: 1 | 2 | 3) : Record<string, string> => {
      return {
        fontSize: "1rem",
        ...borderStyle(props, rowNum),
        ...zebraStripeStyle(props)
      };
    }

    const openCoachListingByGameModal = (game: Game) : void => {
      coachListingByGameModalProps.value = {
        isOpen: true,
        detail: {
          gameStart: game.gameStart,
          homeTeamDetail: {descriptor: teamDescriptorByTeam("home")},
          homeTeamCoachInfo: coachListingByTeam("home"),
          visitorTeamDetail: {descriptor: teamDescriptorByTeam("visitor")},
          visitorTeamCoachInfo: coachListingByTeam("visitor")
        }
      }

      function teamDescriptorByTeam(which: "home" | "visitor") {
        switch (which) {
          case "home":
            return coalesceNameAndDesignation({teamID: game.home, teamDesignation: game.homeTeamDesignation, teamName: game.homeTeamName});
          case "visitor":
            return coalesceNameAndDesignation({teamID: game.visitor, teamDesignation: game.visitorTeamDesignation, teamName: game.visitorTeamName});
          default: exhaustiveCaseGuard(which);
        }

        function coalesceNameAndDesignation(args: {teamID: Guid, teamDesignation: string, teamName: string}) {
          return teamDesignationAndMaybeName({teamDesignation: getTeamDesignation(args), teamName: args.teamName, teamID: args.teamID})
        }
      }

      function coachListingByTeam(which: "home" | "visitor") {
        const teamID = (() => {
          switch (which) {
            case "home": return game.home;
            case "visitor": return game.visitor;
            default: exhaustiveCaseGuard(which);
          }
        })();

        // n.b. the "adhocCoachInfo" should be definitely-truthy here, but we need
        // to update the typings at various places to prove it. If they're not present here, it's a bug.
        return game
          .adhocCoachInfo
          ?.filter(coachInfo => coachInfo.teamID === teamID && (coachInfo.title === "Co-Coach" || coachInfo.title === "Head Coach"))
          .sort((l,r) => {
            if (l.lastName < r.lastName) {
              return -1;
            }
            else if (l.lastName === r.lastName) {
              return l.firstName < r.firstName ? -1 : 1;
            }
            else {
              return 1;
            }
          }) ?? [];
      }
    }

    const coachListingByGameModalProps : Ref<Writeable<CoachListingByGameModal.Props>> = ref({
      isOpen: false,
      detail: null
    })
    const coachListingByGameModalHandlers : CoachListingByGameModal.Emits = {
      close: () => { coachListingByGameModalProps.value.isOpen = false; },
      afterLeave: () => {
        coachListingByGameModalProps.value.detail = null;
      }
    }

    onMounted(async () => {
      if (!Client.value.fields.length) {
        await Client.loadFields()
      }

      fields.value = Client.value.fields

      ready.value = true;
    })

    const slotCancellationInfo = computed(() => {
      return new Map(props.augmentedGames.map(g => [g.game.gameID, getCancelRefSlotInfo(
        g,
        User.value.userID,
        props.isAdmin,
        props.comp_cancellationPreGameDeadlineHours,
        props.comp_nonAdminsCanCancelTheirOwnConfirmedAssignments,
      )]))
    })

    return {
      lockTitles,
      filter,
      fields,
      showModal,
      columns,
      userID,
      createSignUpRequest,
      cancelSignUpRequest,
      cancelRefAssignment,
      approveRefAssignment,
      handleReloadGameEvent,
      getFieldByID,
      toggleModal,
      getTeamByID,
      formatDate: (s: string) => dayjsFormatOr(s, "ddd, MMM DD, YYYY"),
      formatTime,
      refConflicts,
      currentAYSOYear,
      modalControllerCandidate_augmentedGame,
      modalControllerCandidate_selectedTeamsNames,
      modalControllerCandidate_selectedField,
      volunteerDetailsLink,
      colNames,
      columnsMap,
      shouldRenderColumn,
      hasCommentOrRefComment,
      commonTdCellStyles,
      borderStyle,
      zebraStripeStyle,
      MatchReportRouterLink,
      coachListingByGameModalProps,
      coachListingByGameModalHandlers,
      openCoachListingByGameModal,
      augmentedGamesPotentiallyPrefiltered,
      rowRenderKey: (v: AugmentedGame, rowIndex: number) => `gameID=${v.game.gameID}/row=${rowIndex}`,
      ready,
      quasarTablePagination,
      getTeamDesignation,
      /**
       * pass in refInfo-ish objects from untyped vue pug template, try to be paranoid with respect to possible input shapes
       */
      showYouthRefIndicator: (v: RefInfo | UserID | "" | undefined) => {
        if (typeof v !== "object") {
          return false;
        }
        // Hm, can we be in adminView=true, where isAdmin=false?
        // Anyway, check both.
        return props.isAdmin && props.isAdminView && isYouthReferee(v.DOB);
      },
      getButtonTierRefPosName: (row: AugmentedGame, refNum: WellKnownRefSlotIndex) => {
        if (!props.showRefPosNamesPerButton) {
          return null
        }
        return row.refConfig[`pos${refNum}Name`]
      },
      btn2_redEnabledClasses,
      showCancellationButton: (row: AugmentedGame, refNum: WellKnownRefSlotIndex) => {
        const cancellablePerSlot = requireNonNull(slotCancellationInfo.value.get(row.game.gameID))
        // refSlotIndices are 1-based, so make sure to adjust to be zero-based here
        return cancellablePerSlot[refNum - 1]
      },
    }
  },
})

const getTeamDesignation = (args: {teamID: Guid, teamDesignation: string}) : string => {
  if (!args.teamID) {
    return "TBD";
  }
  else if (args.teamID === Client.value.instanceConfig.byeteam) {
    return "Bye"
  }
  else {
    return args.teamDesignation
  }
}

/**
 * Returns an array of `game.refSlots.numSlots` length, where `result[N-1]` is the "can cancel" bit for "refSlot N"
 * (where the n-1 is an artifact of 1-based refSlotIndices vs 0-based arrays)
 */
function getCancelRefSlotInfo(
  row: AugmentedGame,
  userID: Guid,
  userIsRefSchedulerAdmin: boolean,
  comp_cancellationPreGameDeadlineHours: number | undefined,
  comp_nonAdminsCanCancelTheirOwnConfirmedAssignments: boolean,
) : boolean[] {
  const now = dayjs()
  const gameStart = dayjs(row.game.gameStart);
  const pregameCutoff = (() => {
    if (typeof comp_cancellationPreGameDeadlineHours === "number") {
      if (comp_cancellationPreGameDeadlineHours === 0) {
        // 0 means same as undefined -- that is, "no deadline"
        return undefined
      }
      else {
        return gameStart.subtract(comp_cancellationPreGameDeadlineHours, "hours")
      }
    }
    else {
      return undefined;
    }
  })();

  const result : boolean[] = []

  for (let i = 1; i <= row.refConfig.numSlots; i++) {
    const refObj = getRefObjForGame(row.game, i as WellKnownRefSlotIndex)
    result.push((() : boolean => {
      if (!refObj) {
        // nothing in this slot at this time -- can't cancel what doesn't exist
        return false
      }

      if (userIsRefSchedulerAdmin) {
        // super user can always cancel
        return true;
      }

      if (refObj.value.ID !== userID) {
        // non-super user can never cancel other people's
        return false
      }
      else {
        if (refObj.type === "pending") {
          // can always cancel their own pending assignments
          return true
        }
        else if (refObj.type === "confirmed-assignment") {
          if (comp_nonAdminsCanCancelTheirOwnConfirmedAssignments) {
            if (pregameCutoff) {
              // can cancel their own confirmed assignments, but not after some cutoff (some hours before game start)
              return now.isAfter(pregameCutoff) ? false : true
            }
            else {
              return now.isAfter(gameStart) ? false : true;
            }
          }
          else {
            // non-admin users cannot cancel their own confirmed assignments
            return false
          }
        }
        else {
          exhaustiveCaseGuard(refObj)
        }
      }
    })());
  }

  assertTruthy(result.length === row.refConfig.numSlots)

  return result;

}

/**
 * A game is expected to have, for a particular ref slot, exactly one of the following:
 *  - an assignment
 *  - a pending assignment
 *  - neither
 */
function getRefObjForGame(game: Game, refSlot: WellKnownRefSlotIndex) {
  return assignment() || pending() || null;

  function assignment() {
    const v = game[`ref${refSlot}`]
    return v && typeof v !== "string"
      ? {type: "confirmed-assignment" as const, value: v}
      : null
  }

  function pending() {
    const v = game[`ref${refSlot}Vol`]
    return v && typeof v !== "string"
      ? {type: "pending" as const, value: v}
      : null
  }
}

interface RefPosConflicts {
  ref1: boolean,
  ref2: boolean,
  ref3: boolean,
  ref4: boolean,
  ref1Vol: boolean,
  ref2Vol: boolean,
  ref3Vol: boolean,
  ref4Vol: boolean,
}

/**
 * Given a list of games, build a map of (gameID -> refPosition -> is-there-a-conflict).
 */
function getConflicts(games: Game[]) : {[gameID: iltypes.Guid]: NoUncheckedIndexedAccess | RefPosConflicts} {
  const conflicts : {[gameID: Guid]: RefPosConflicts} = {}

  for (const game of games) {
    const conflictBuilder = defaultNoConflicts()

    // Overwrite each defaulted "no conflict" with "has conflict", for each particular pos having a conflict.
    for (const conflict of game.familyConflicts) {
      const k = mungeRefPosKey(conflict.position)
      conflictBuilder[k] = true
    }

    conflicts[game.gameID] = conflictBuilder
  }

  return conflicts

  function defaultNoConflicts() : RefPosConflicts {
    return {
      ref1: false,
      ref2: false,
      ref3: false,
      ref4: false,
      ref1Vol: false,
      ref2Vol: false,
      ref3Vol: false,
      ref4Vol: false,
    }
  }

  function mungeRefPosKey(v: FamilyConflict["position"]) : keyof RefPosConflicts {
    switch (v) {
      case "CR": return "ref1"
      case "CRVol": return "ref1Vol"
      case "AR": return "ref2"
      case "ARVol": return "ref2Vol"
      case "AR2": return "ref3"
      case "AR2Vol": return "ref3Vol"
      case "Mentor": return "ref4"
      case "MentorVol": return "ref4Vol"
      default: exhaustiveCaseGuard(v);
    }
  }
}

function mapSortConfigToQuasarPaginationSortPortion(sortConfig: SortConfig) {
  return {
    sortBy: sortConfig.perGameColumn,
    descending: sortConfig.perGameDir === "desc" ? true : false,
  }
}
</script>

<style scoped>
.q-table thead th {
  width: 50px;
  white-space: nowrap;
}
.q-table tbody td {
  width: 50px;
  white-space: nowrap;
}
.customInput {
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 0.375rem;

}
.customInput:focus {
  outline: 0;
}
</style>

