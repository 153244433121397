<template>
<div class="beacon" :style="style"></div>
</template>
<script lang="ts">
import {computed, defineComponent} from "vue"
export default defineComponent({
  props: {
    size: {
      type: String,
      default: ".5em"
    },
    color: {
      type: String,
      default: "blue",
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const style = computed(() => {
      return {
        "--il-beacon-dim": props.size,
        "--il-beacon-color": props.color,
        "--il-beacon-display": props.disable ? "none" : undefined,
      }
    })
    return {
      style
    }
  }
})
</script>

<style scoped>
.beacon{
  background-color:var(--il-beacon-color);
  height:var(--il-beacon-dim);
  width:var(--il-beacon-dim);
  border-radius:50%;

}
.beacon:before{
  display: var(--il-beacon-display);
  position:absolute;
  content:"";
  height:var(--il-beacon-dim);
  width:var(--il-beacon-dim);
  background-color:transparent;
  border-radius:50%;
  box-shadow:0px 0px 2px 2px var(--il-beacon-color);
  animation:active 8s infinite ease-out;
}

@keyframes active{
  0%{
    transform:scale(0);
    opacity:1;
  }
  12.5%{
    transform: scale(2.5);
    opacity:0;
  }
  100%{
    transform: scale(0);
    opacity:0;
  }
}
</style>
