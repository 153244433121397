import { axiosInstance } from "src/boot/AxiosInstances";
import { getInvoice } from "src/composables/InleagueApiV1";
import { Invoice, WithDefinite, InvoiceLineItem, InvoiceLineItemOf, Integerlike } from "src/interfaces/InleagueApiV1";

export type ExpandedInvoice = Invoice & {
  lineItems: WithDefinite<InvoiceLineItem, "entity">[]
}

export type CompRegLineItem = WithDefinite<InvoiceLineItemOf<"qCompetitionRegistration">, "entity">

export async function getExpandedInvoice(instanceID: Integerlike) : Promise<ExpandedInvoice> {
  return (await getInvoice(axiosInstance, {instanceID, expand: ["lineItems.entity"]})) as ExpandedInvoice;
}

export async function getExpandedInvoices(instanceIDs: Integerlike[]) : Promise<ExpandedInvoice[]> {
  const result : ExpandedInvoice[] = []
  for (const instanceID of instanceIDs) {
    result.push(await getExpandedInvoice(instanceID))
  }
  return result;
}
