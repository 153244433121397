import { assertIs, assertTruthy, exhaustiveCaseGuard, parseIntOrFail, UiOption } from "src/helpers/utils"
import { Guid, Integerlike, RegistrationID } from "src/interfaces/InleagueApiV1"
import { ClientRating } from "./ClientRatingsConfigurator.io"
import { RegWithRatings } from "./PlayerRatings.io"

export function buildFormElemMapping(registrations: RegWithRatings[], clientRatings: {core: ClientRating, custom: ClientRating[]}) : Map<RegistrationID, RegFormElemContainer> {
  const result = new Map<RegistrationID, RegFormElemContainer>()

  for (const reg of registrations) {
    const registrationID = reg.registrationID

    // regIDs should be unique (no duplicate regs in the supplied list)
    assertTruthy(!result.has(registrationID))

    result.set(reg.registrationID, {
      core: new FormElemCore({
        registrationID,
        ratingValue: reg.ratings.core.rating.toString(),
        comment: reg.ratings.core.comment,
      }),
      custom: clientRatings.custom.map(rating => {
        const v = reg.ratings.custom.find(v => v.ratingID === rating.ratingID);
        return new FormElemCustom({
          registrationID,
          ratingID: rating.ratingID,
          ratingValue: v?.value || "",
        })
      }),
    })
  }

  return result;
}

export interface RegFormElemContainer {
  core: FormElemCore,
  custom: FormElemCustom[]
}

export abstract class FormElemBase {
  registrationID: Guid;

  ratingValue: string;
  [" __pristine_ratingValue"] : string;

  constructor(_: {registrationID: Guid, ratingValue: string}) {
    this.registrationID = _.registrationID
    this.ratingValue = _.ratingValue
    this[" __pristine_ratingValue"] = _.ratingValue
  }

  isDirty() : boolean {
    return this[" __pristine_ratingValue"] != this.ratingValue
  }

  discardLocalChanges() : void {
    this.ratingValue = this[" __pristine_ratingValue"];
  }

  commitLocalChanges() : void {
    this[" __pristine_ratingValue"] = this.ratingValue
  }
}

export class FormElemCore extends FormElemBase {
  readonly type = "core";

  comment: string;
  [" __pristine_comment"] : string;

  constructor(_: {
    registrationID: Guid,
    ratingValue: string,
    comment: string,
  }) {
    super(_)
    this.comment = _.comment
    this[" __pristine_comment"] = _.comment
  }

  isDirty(): boolean {
    if (super.isDirty()) {
      return true;
    }

    return this.comment !== this[" __pristine_comment"]
  }

  discardLocalChanges(): void {
    super.discardLocalChanges()
    this.comment = this[" __pristine_comment"]
  }

  commitLocalChanges(): void {
      super.commitLocalChanges()
      this[" __pristine_comment"] = this.comment
  }
}

export class FormElemCustom extends FormElemBase {
  readonly type = "custom";
  readonly ratingID : Guid;

  constructor(_: {
    registrationID: Guid,
    ratingValue: string,
    ratingID: Guid,
  }) {
    super(_)
    this.ratingID = _.ratingID
  }
}

export enum WellKnownCol {
  playerName = "playerName",
  age = "age",
  overallRating = "overallRating",
  comments = "comments",
  lock = "lock",
}

export function ratingOptions(v: ClientRating, existingValue: undefined | string) : UiOption[] {
  const options = v.type === "numeric"
    ? numericRatingOptions(v)
    : v.type === "text"
    ? textRatingOptions(v)
    : exhaustiveCaseGuard(v.type);

  if (existingValue) {
    if (!options.find(v => v.value === existingValue)) {
      options.unshift({label: `${existingValue} (current value, but no longer an option)`, value: existingValue})
    }
  }

  return [{label: "", value: ""}, ...options];

  function textRatingOptions(v: ClientRating) : UiOption[] {
    assertIs(v.type, "text")

    return v.textItems
      .filter(v => !!v) // shouldn't be falsy but we have some NoUncheckedIndexedAccess stuff here
      // note we use item.text as label and value -- we don't need item IDs, because
      // we don't store the IDs on the backend, just the literal text
      .map(item => ({label: item.text, value: item.text}))
  }

  function numericRatingOptions(v: ClientRating) : UiOption[] {
    assertIs(v.type, "numeric");

    // we declare that in general a clientRating can have a nullish rangeLow/rangeHigh,
    // mostly for form purposes (i.e. fresh form data needs a "default" value), but here
    // we do not support such a thing. Ranges must be integer(like)s.
    const worst = parseIntOrFail(v.rangeLow)
    const best = parseIntOrFail(v.rangeHigh)

    return go(worst, best).map(v => ({
      label: `${v}`,
      value: `${v}`
    }));

    /**
     * worst=1, best=6 --> [6,5,4,3,2,1]
     * best=1, worst=6 --> [1,2,3,4,5,6]
     * Basically, "'best' always comes first, just count in the appropriate direction"
     */
    function go(worst: number, best: number) : Integerlike[] {
      if (best < worst) {
        const m = go(best, worst);
        return m.reverse()
      }
      else {
        const result : Integerlike[] = []
        for (let i = best; i >= worst; i--) {
          result.push(i.toString() as Integerlike)
        }
        return result;
      }
    }
  }
}
