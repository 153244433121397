import { PropType, computed, ref, reactive, defineComponent } from "vue";
import { RouteLocationRaw, RouterLink } from "vue-router";


import { exhaustiveCaseGuard } from "src/helpers/utils";

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as R_TournamentTeamRegPageItemEditor from "./R_TournamentTeamRegPageItemEditor.route"
import * as Modal from "src/components/UserInterface/Modal"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { PageItem_Question_PreviewRender } from "../Registration/admin/PageItem_Question_PreviewRender";
import { Client } from "src/store/Client";

export const PageItemListingBlurb = defineComponent({
  props: {
    pageItem: {
      required: true,
      type: Object as PropType<iltournament.TournTeamRegPageItem>
    },
    doDelete: {
      required: true,
      type: null as any as PropType<(_: iltournament.TournTeamRegPageItem) => Promise<{ok: boolean}>>
    }
  },
  setup(props, {emit}) {


    const editItemLinkLocation = computed<RouteLocationRaw>(() => {
      return R_TournamentTeamRegPageItemEditor.routeDetailToRouteLocation({
        name: R_TournamentTeamRegPageItemEditor.RouteNames.edit,
        tournamentID: props.pageItem.tournamentID,
        pageItemID: props.pageItem.id
      });
    })

    const deletable = computed(() => {
      if (props.pageItem.type === iltypes.PageItemType.CONTENT_CHUNK) {
        return true;
      }
      else {
        return !props.pageItem.containedItem.hasAnswers
      }
    })

    const deletePageItemModalController = reactive((() => {
      const isBusy = ref(false);
      const onCloseCB = (cb: () => void) : void => {
        if (isBusy.value) {
          return;
        }
        else {
          cb();
        }
      }

      const doDelete = async (pageItem: iltournament.TournTeamRegPageItem) => {
        isBusy.value = true;
        const result = await props.doDelete(pageItem);
        isBusy.value = false;
        if (result.ok) {
          deletePageItemModalController.close();
        }
      }

      return Modal.DefaultModalController<iltournament.TournTeamRegPageItem>({
        title: () => (
          <>
            <div>Delete page item?</div>
            <div class="my-2 border-slate-200 border-b"/>
          </>
        ),
        content: pageItem => {
          if (!pageItem) {
            return null;
          }
          return (
            <>
              <div>
                <div>Delete page item?</div>
                <div class="flex gap-4 mt-2">
                  <t-btn type="button" onClick={() => doDelete(pageItem)}>Yes</t-btn>
                  <t-btn type="button" onClick={() => deletePageItemModalController.close()} color="red">No</t-btn>
                </div>
              </div>
              {
                isBusy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/> : null
              }
            </>
          )
        },
      }, {onCloseCB})
    })())

    return () => (
      <div>
        <Modal.AutoModal controller={deletePageItemModalController}/>
        <div class="max-w-4xl">
          <div class="w-full items-center rounded-md p-2 mt-4">
            <div>
              <h1 class={`font-bold ${props.pageItem.isDisabled ? 'text-gray-400': 'text-gray-600'}`}>
                {props.pageItem.containedItem.label}
              </h1>
              {
                props.pageItem.isDisabled
                  ? <div class="ml-3 italic font-normal text-xl" v-if="item.isDisabled">(Disabled)</div>
                  : null
              }
              <div class="text-green-800 mt-2 cursor-pointer">
                <div class="flex items-center justify-start">
                  <RouterLink to={editItemLinkLocation.value}>
                    <t-btn>
                      <FontAwesomeIcon icon={["fas", "pencil"]}/>
                      <span class="ml-2 flex items-center justify-center">Edit</span>
                    </t-btn>
                  </RouterLink>
                </div>
              </div>
              <div class="flex items-center my-1">
                <t-btn onClick={() => deletable.value ? deletePageItemModalController.open(props.pageItem) : 0} color="red">
                  <font-awesome-icon icon={["fas", "trash-alt"]}></font-awesome-icon>
                  <span class="font-extrabold ml-2">Delete</span>
                </t-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export const PageItemListingPreview = defineComponent({
  props: {
    pageItem: {
      required: true,
      type: Object as PropType<iltournament.TournTeamRegPageItem>
    }
  },
  setup(props) {
    return () => {
      switch (props.pageItem.type) {
        case iltypes.PageItemType.QUESTION:
          return (
            <PageItem_Question_PreviewRender
              inputType={props.pageItem.containedItem.type}
              label={props.pageItem.containedItem.label}
              // have to augment these options a little to get tournTeamReg_points -> points
              selectOptions={props.pageItem.containedItem.questionOptions.map(v => ({...v, points: v.tournamentTeamReg_points}))}
            />
          )
        case iltypes.PageItemType.CONTENT_CHUNK:
          return <div class="FORCE_DEFAULT_BROWSER_ANCHOR_STYLE_IN_ALL_DESCENDANTS" v-html={props.pageItem.containedItem.defaultText}/>
        default: exhaustiveCaseGuard(props.pageItem);
      }
    }
  }
})
