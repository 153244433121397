import { axiosInstance } from "src/boot/axios"
import { Guid, RelatedUser } from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"

/** for most cases, we only need part of this object type */
export type PartialRelatedUser = Pick<RelatedUser, "userID" | "relationship_type_id">;

export default () => {

  const processRelatedUsers = (relatedUsers: PartialRelatedUser[], userIDs: Guid[]) => {
    const relatedUsersObj : {[userID: Guid]: number | string} = {};

    for(let i=0; i<relatedUsers.length; i++) {
      relatedUsersObj[relatedUsers[i].userID]=relatedUsers[i].relationship_type_id
    }

    for (const userID of userIDs) {
      if(!relatedUsersObj[userID]) {
        relatedUsersObj[userID] = ''
      }
    }

    return relatedUsersObj
  }

  const getRelationshipTypes = async () => {
    return await ilapi.getRelationshipTypes(axiosInstance);
  }

  return {
    processRelatedUsers,
    getRelationshipTypes
  }

}
