import { arraySum, assertNonNull, assertTruthy, parseIntOrFail } from "src/helpers/utils";
import { Invoice } from "src/interfaces/Store/checkout";

export function isSubscriptionInvoice(invoice: Invoice) {
  if (/*__not__ instanceID*/ invoice.invoiceID && invoice.methodID) {
    return true;
  }
  else {
    return false;
  }
}
