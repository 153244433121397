<template lang="pug">
template(v-if="ready")
  div(v-if="noInfoAvailable") Volunteer info currently unavailable
  .flex.flex-row.justify-between.items-center(v-else)
    div.h-max.flex.items-center(v-if="backgroundStatus.nextStep")
      div(v-if="backgroundStatus.nextStep.summary==='Begin Background Check'")
        a.underline(:href="backgroundStatus.bgCheck.vendorSubmitLink" target='_blank')
          | {{ backgroundStatus.nextStep.summary }}
      div(v-else-if="backgroundStatus.nextStep.summary === 'Renew Now' && (typeof backgroundStatus.bgCheck === 'object' && backgroundStatus.bgCheck)")
        FormKit(type="button" label="Renew Now" @click="navToFreshTab(backgroundStatus.bgCheck.vendorSubmitLink)")
      div(v-else-if="backgroundStatus.nextStep.summary == 'Not Required' && (!backgroundStatus.submittedVolunteerPrefs)")
        router-link(:to="{path: `/volunteer/roles/${user.ID}/${seasonUID}`}")
          FormKit(
            outer-class="noMargin"
            type='button'
          )
            div Volunteer Now
      div(v-else)
       | {{ backgroundStatus.nextStep.summary }}
    div(v-if="backgroundStatus.nextStep.summary != 'Not Required' || backgroundStatus.submittedVolunteerPrefs")
      router-link(:to="{path: `/volunteer/roles/${user.ID}/${seasonUID}`}")
        FormKit(
          outer-class="noMargin"
          type='button'
        )
          div {{ backgroundStatus.submittedVolunteerPrefs ? 'Review Volunteer Preferences' : 'Volunteer Now'}}
</template>

<script lang="ts" setup>
import { onMounted, type PropType, ref, watch } from 'vue'
import { axiosInstance } from 'src/boot/axios'
import type { BackgroundCheck, User } from 'src/interfaces/InleagueApiV1'

const props = defineProps({
  user: {
    required: true,
    type: Object as PropType<User>
  },
  seasonUID: {
    required: true,
    type: String
  }
})

const backgroundStatus = ref<BackgroundCheck | undefined>(undefined)
const noInfoAvailable = ref(false)

const getBackgroundStatus = async () => {
  const response = await axiosInstance.get(`/v1/volunteer/${props.user.ID}/season/${props.seasonUID}`)
  return response.data.data
}

watch(()=>props.seasonUID, async (seasonUID, oldSeasonUID) =>{
  if(seasonUID!=oldSeasonUID) {
    backgroundStatus.value = await getBackgroundStatus()
  }
})

const ready = ref(false);
onMounted(async ()=> {
  if (props.user.objectType === "private-profile") {
    noInfoAvailable.value = true;
    ready.value = true;
    return;
  }
  else {
    backgroundStatus.value = await getBackgroundStatus()
    ready.value = true;
  }
})

const navToFreshTab = (url: string) => {
  window.open(url);
}
</script>
