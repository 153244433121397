import { unsafe_objectKeys } from "./utils";

/**
 * https://developers.google.com/recaptcha/docs/verify
 */
export const GoogleReCaptchaErrorCode = {
  /**
   * The secret parameter is missing.
   */
  "missing-input-secret": "missing-input-secret",
  /**
   * The secret parameter is invalid or malformed.
   */
  "invalid-input-secret": "invalid-input-secret",
  /**
   * The response parameter is missing.
   */
  "missing-input-response": "missing-input-response",
  /**
   * The response parameter is invalid or malformed.
   */
  "invalid-input-response": "invalid-input-response",
  /**
   * The request is invalid or malformed.
   */
  "bad-request": "bad-request",
  /**
   * The response is no longer valid: either is too old or has been used previously.
   */
  "timeout-or-duplicate": "timeout-or-duplicate"
} as const;

export type GoogleReCaptchaErrorCode = (typeof GoogleReCaptchaErrorCode)[keyof typeof GoogleReCaptchaErrorCode];

export function isGoogleRecaptchaErrorCode(v: any) : v is GoogleReCaptchaErrorCode {
  if (typeof v !== "string") {
    return false;
  }

  for (const key of unsafe_objectKeys(GoogleReCaptchaErrorCode)) {
    if (v === key) {
      return true;
    }
  }

  return false;
}
