import { ref, reactive } from "vue"
import { axiosInstance } from "src/boot/AxiosInstances"
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { parseIntOrFail, sortBy, unsafe_objectKeys } from "src/helpers/utils"
import * as ClearOnLogout from "src/store/ClearOnLogout"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise"
import { User } from "src/store/User"
import { AxiosInstance } from "axios"

class EntityNotFound extends Error {
  constructor(message?: string) {
    super(message);
  }
}

/**
 * todo: axios instances as args to each getter?
 */
export const tournamentTeamStore = (() => {
  type SeasonCompKey = `${iltypes.SeasonUID}/${iltypes.CompetitionUID}`
  const SeasonCompKey = (k: {seasonUID: iltypes.SeasonUID, competitionUID: iltypes.CompetitionUID}) : SeasonCompKey => `${k.seasonUID}/${k.competitionUID}`

  //
  // `clearObj` zeros out a Record<any,any>
  // We try to prevent ourselves from calling it on the wrong things by explicitly tagging clearable things.
  //
  const __clearable = Symbol("clearable")
  type TaggedClearable = {[__clearable]: void}
  const clearObj = <T extends TaggedClearable>(o: T) => unsafe_objectKeys(o).forEach(k => delete o[k]);

  //
  // We store promises, as opposed to the resolved results, so we only ever make the "one first request";
  // all subsequent requests for the same entity return the same cached request, which may be in
  // either a pending or a resolved state.
  //
  // Cancellation, force-retry, etc., is not supported.
  //

  /**
   * Generally we expect at least one available season.
   * Use of ReactiveFuture here is exploratory, but so are the the Promise<> we're storing (are those reactive as expected, i.e. the object contained in its [[resolved]] slot becomes reactive ... ?)
   * so we're looking for some kind of solution to bridging sync/async/store. Also if we store promises but those promises reject, that's weird behavior.
   *
   * TODO: we can just do `promiseHolder[bySomeKey] = somePromise.then(v => reactive(v))` and the object in the promise becomes reactive
   */
  const seasons = ReactiveReifiedPromise<iltypes.Season[]>()
  const competitions = reactive<{[seasonUID: iltypes.Guid]: undefined | Promise<iltypes.Competition[]>} & TaggedClearable>({} as TaggedClearable);

  /**
   * Promise here can resolve to null, meaning that "there is no assocated tournament for this season"
   * However, the code paths to retrieve this from the backend will probably throw an exception in response to a 404, so
   * additional scrutiny may be required here if we really want to interact with the null case.
   */
  const tournaments = reactive<{[key: SeasonCompKey]: undefined | Promise<null | iltournament.Tournament>} & TaggedClearable>({} as TaggedClearable);

  /**
   * This is keyed on a tournament, implying a (comp, season), so this can be thought of as being keyed on
   * (comp, season), although we physically use the tournament's tournamentID.
   */
  const divisions = reactive<{[tournamentID: iltypes.Integerlike]: undefined | Promise<iltypes.Division[]>} & TaggedClearable>({} as TaggedClearable)

  /**
   * This is for "local users", who would be shown a list of teams to pick from. "Foreign league" users
   * will be generating teams on the fly.
   */
  const teams = reactive<{[seasonUID: iltypes.Guid]: undefined | Promise<iltypes.Team[]>} & TaggedClearable>({} as TaggedClearable);

  /**
   * Teams are by (season, comp), but there isn't expected to be thousands, so we pull all by season,
   * and callers can filter by comp locally.
   */
  const tournamentTeams = reactive<{[seasonUID: iltypes.Guid]: undefined | Promise<iltournament.TournamentTeamFromListTournamentTeamsEndpoint[]>} & TaggedClearable>({} as TaggedClearable);
  const tournamentTeamsByTournamentTeamID = reactive<{[tournamentTeamID: iltypes.Integerlike]: undefined | Promise<iltournament.TournamentTeam>} & TaggedClearable>({} as TaggedClearable);

  const hasManageTournamentTeamPermissionsTheseTournTeams = ref<{[tournamentTeamID: iltypes.Integerlike]: 0 | 1 | undefined}>({});

  const clear = () => {
    seasons.reset();

    clearObj(competitions);
    clearObj(tournaments);
    clearObj(divisions);
    clearObj(teams);

    clearObj(tournamentTeams);
    clearObj(tournamentTeamsByTournamentTeamID);

    hasManageTournamentTeamPermissionsTheseTournTeams.value = {}
  }

  const loadIfNecessary_seasons_ = () => {
    if (seasons.underlying.status === "idle") {
      seasons.run(() => iltournament.getCreateTournamentTeamCandidateSeasons(axiosInstance))
    }
    return seasons;
  }

  const getSeasonsOrFail = async () : Promise<readonly iltypes.Season[]> => {
    return await loadIfNecessary_seasons_().getResolvedOrFail()
  }

  const getSeasonOrFail = async (seasonUID: iltypes.Guid) : Promise<iltypes.Season> => {
    return await loadIfNecessary_seasons_()
      .getResolvedOrFail()
      .then((seasons) => {
        const season = seasons.find(season => season.seasonUID === seasonUID);
        if (season) {
          return season;
        }
        throw new EntityNotFound(`Found no season having seasonUID=${seasonUID}`);
      })
  }

  const getCompetitions = async (seasonUID: iltypes.Guid) : Promise<readonly iltypes.Competition[]> => {
    const maybeExists = competitions[seasonUID]
    if (maybeExists) {
      return await maybeExists;
    }
    else {
      const p = competitions[seasonUID] = iltournament.getCreateTournamentTeamCandidateCompetitions(axiosInstance, {seasonUID});
      return await p;
    }
  }

  const getCompetitionOrFail = async (v: {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid}) : Promise<iltypes.Competition> => {
    const competition = (await getCompetitions(v.seasonUID)).find(comp => comp.competitionUID === v.competitionUID);
    if (competition) {
      return competition;
    }
    else {
      throw Error(`Couldn't find competition having competitionUID='${v.competitionUID}'`);
    }
  }

  /**
   * can return null if there is no such tournament for the comp/season, but probably it will throw in that case and we are not prepared for it.
   * Consider using `getTournamentOrFail` in cases where you don't want the null pollution at the callsite.
   *
   * n.b. lookup by tournamentID is possible, but we only cache locally by seasonUID/competitionUID, so tournamentID based lookups are always cache misses.
   *
   * TODO: move to "tournament" store proper (that store also has getTournament methods and caches, so we will get out of sync between the two)
   */
  const getTournament = async (v: {seasonUID: iltypes.Guid, competitionUID: iltypes.CompetitionUID} | {tournamentID: iltypes.Integerlike}) : Promise<null | iltournament.Tournament> => {
    if ("competitionUID" in v /*implying seasonUID is also present*/) {
      return await getBySeasonComp(v);
    }
    else if ("tournamentID" in v) {
      return await getByTournamentID(v);
    }
    else {
      throw Error("expected unreachable")
    }

    async function getByTournamentID(v: {tournamentID: iltypes.Integerlike}) {
      //
      // Hm, can't do a cache lookup until after we get the object to grab its (season, comp) for use as a key.
      // If this becomes a BigDeal then we can store some kind of double-keyed cache thing on both (tournamentID) and (season, comp).
      //
      const p = iltournament.getTournament(axiosInstance, v).then(v => reactive(v))
      const resolved = await p
      const key = SeasonCompKey(resolved)
      // yuck, for reactivity's sake, we need to get "the same" object if one had already been cached
      // if we already have a reactive version of this, use it; otherwise, we store our freshly resolved value
      return tournaments[key] || (tournaments[key] = p);
    }

    async function getBySeasonComp(v: {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid}) {
      const key = SeasonCompKey(v)
      const maybeExists = tournaments[key]
      if (maybeExists) {
        return await maybeExists;
      }
      else {
        return tournaments[key] = iltournament.getTournament(axiosInstance, v).then(v => reactive(v))
      }
    }
  }


  const getTournamentOrFail = async (v: {seasonUID: iltypes.Guid, competitionUID: iltypes.CompetitionUID} | {tournamentID: iltypes.Integerlike}) : Promise<iltournament.Tournament> => {
    const tournament = await getTournament(v);
    if (tournament) {
      return tournament;
    }
    else {
      throw Error(`Couldn't find tournament for key ${JSON.stringify(v)}`)
    }
  }

  const getDivisions = async (tournamentID: iltypes.Integerlike) : Promise<iltypes.Division[]> => {
    const maybeExists = divisions[tournamentID];
    if (maybeExists) {
      return await maybeExists
    }
    else {
      const p = divisions[tournamentID] = iltournament.getCreateTournamentTeamCandidateDivisions(axiosInstance, {tournamentID});
      return await p;
    }
  }

  const getDivisionOrFail = async (v: {tournamentID: iltypes.Integerlike, divID: iltypes.Guid}) : Promise<iltypes.Division> => {
    const division = (await getDivisions(v.tournamentID)).find(div => div.divID === v.divID);
    if (division) {
      return division;
    }
    else {
      throw Error(`Couldn't find division having divID=${v.divID}`)
    }
  }

  const getTournamentTeams = async (seasonUID: iltypes.Guid) : Promise<iltournament.TournamentTeamFromListTournamentTeamsEndpoint[]> => {
    const maybeExists = tournamentTeams[seasonUID];
    if (maybeExists) {
      return await maybeExists;
    }
    else {
      const p = tournamentTeams[seasonUID] = iltournament
        .listTournamentTeams(axiosInstance, {seasonUID})
        .then(v => reactive(v))

      const teams = await p;

      teams.forEach(team => {
        // These are individually __already__ reactive by virtue of reactive(theList)
        // For each, iff one already exists, we do NOT replace the one we had.
        tournamentTeamsByTournamentTeamID[team.tournamentTeamID] ??= Promise.resolve(team);
      })

      return teams;
    }
  }

  const getTournamentTeamOrFail = (tournamentTeamID: iltypes.Integerlike) : Promise<iltournament.TournamentTeam> => {
    const maybeExists = tournamentTeamsByTournamentTeamID[tournamentTeamID];
    if (maybeExists) {
      return maybeExists;
    }
    else {
      // expected to throw on 404
      return tournamentTeamsByTournamentTeamID[tournamentTeamID] = iltournament
        .getTournamentTeam(axiosInstance, {tournamentTeamID})
        .then(v => reactive(v))
    }
  }

  const createTournamentTeam = async (args: iltournament.CreateTournamentTeamArgs) : Promise<{tournamentTeamID: iltypes.Integerlike}> => {
    const tournTeam = reactive(await iltournament.createTournamentTeam(axiosInstance, args));

    hasManageTournamentTeamPermissionsTheseTournTeams.value[tournTeam.tournamentTeamID] = 1;

    // invalidate "by season" cache
    // we could add to it, but then:
    // if the cache didn't exist yet, then the cache will appear as "fully resolved, containing just 1 tourn team", which might be innacurate,
    // because it will have just the tournteam we just created, instead of all the tourn teams from the backend.
    tournamentTeams[tournTeam.seasonUID] = undefined;
    tournamentTeamsByTournamentTeamID[tournTeam.tournamentTeamID] = Promise.resolve(tournTeam);

    User.onCreateTournamentTeam();

    return {tournamentTeamID: tournTeam.tournamentTeamID}
  }

  const getOrCreateTournamentTeamInvoices = async (tournamentTeamID: iltypes.Integerlike) : Promise<{registrationInvoice: iltypes.Invoice, holdPaymentInvoice: iltypes.Invoice}> => {
    const reactive_tournamentTeam = await getTournamentTeamOrFail(tournamentTeamID);
    // maybe push this into invoice store?...
    const invoices = await iltournament.getOrCreateTournamentTeamInvoice(axiosInstance, {tournamentTeamID});
    reactive_tournamentTeam.invoiceInstanceID_registration = invoices.registrationInvoice.instanceID;
    reactive_tournamentTeam.invoiceInstanceID_holdPayment = invoices.holdPaymentInvoice.instanceID;
    return invoices;
  }

  const getIsAuthorizedToManageTournamentTeamsPermissionsMap = async(axios: AxiosInstance, args: {tournamentTeamIDs: iltypes.Integerlike[]}) : Promise<{[tournamentTeamID: iltypes.Integerlike]: undefined | 0 | 1}> => {
    const need = new Set<iltypes.Integerlike>();
    const have : {[tournTeamID: iltypes.Integerlike]: undefined | 0 | 1} = {}

    for (const tournamentTeamID of args.tournamentTeamIDs) {
      const perm = hasManageTournamentTeamPermissionsTheseTournTeams.value[tournamentTeamID]
      if (perm === undefined) {
        need.add(tournamentTeamID);
      }
      else {
        have[tournamentTeamID] = perm;
      }
    }

    if (need.size > 0) {
      const lookup = await iltournament.isAuthorizedToManageTournamentTeams(axiosInstance, {tournamentTeamIDs: [...need]});
      hasManageTournamentTeamPermissionsTheseTournTeams.value = {
        ...hasManageTournamentTeamPermissionsTheseTournTeams.value,
        ...lookup
      }
      return {
        ...have,
        ...lookup
      }
    }
    else {
      return have;
    }
  }

  const getIsAuthorizedToManageTournamentTeam = async(axios: AxiosInstance, tournamentTeamID: iltypes.Integerlike) : Promise<boolean> => {
    const mapping = await getIsAuthorizedToManageTournamentTeamsPermissionsMap(axios, {tournamentTeamIDs: [tournamentTeamID]})
    return !!mapping[tournamentTeamID];
  }

  return {
    clear,
    getSeasonsOrFail,
    getSeasonOrFail,
    getCompetitions,
    getCompetitionOrFail,
    getTournament,
    getTournamentOrFail,
    getDivisions,
    getDivisionOrFail,
    getTournamentTeams,
    getTournamentTeamOrFail,
    getOrCreateTournamentTeamInvoices,
    createTournamentTeam,
    getIsAuthorizedToManageTournamentTeamsPermissionsMap,
    getIsAuthorizedToManageTournamentTeam,
  }
})();

ClearOnLogout.register(tournamentTeamStore);
