<template lang="pug">
router-view(v-on='listenersByFlowStep')
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'


import { processRoles, VolunteerRoleSelection } from './SelectRolesUtils'
import {
  FlowStepRouteName,
  HasNoStackKeyNeedsNameAndDobEdit,
} from './R_VolunteerRegistrationFlow.route'
import { assertNonNull, exhaustiveCaseGuard } from 'src/helpers/utils'
import * as ilapi from 'src/composables/InleagueApiV1'
import {
  VolunteerDetails,
  VolunteerSeasonStatus,
  Guid
} from 'src/interfaces/InleagueApiV1'

import * as M_SeasonJustEmit from "src/components/Registration/selections/season-just-emit.ilx"
import * as M_SelectVolunteer2 from "./SelectVolunteer2.ilx"
import { User } from 'src/store/User'

export default defineComponent({
  props: {
    // only not defined on flow step 1, which is "choose season"
    // maybe this implies we should hop to another component after step 1 where we know it is not undefined
    seasonUID: {
      required: false,
      type: String as PropType<string | undefined>,
    },
    volunteerID: {
      required: false,
      type: String as PropType<Guid | undefined>,
    }
  },
  components: {},
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()

    let busy = false // try to guard against quick successive double clicks on child forms

    /**
     * the target volunteerID may or may not be present on props (passed in via the router); there are 3 possible configurations:
     *  - on routename "user", there is no volunteerID, because we are choosing a target user
     *  - on routename "season", there may or may not be an explicit volunteerID; if missing, it is implied to be "the current user"
     *  - on all other routenames for this flow, the volunteerID prop must be present, and we pull from that
     */
    const volunteerID = computed<Guid | undefined>(() => {
      const routeName = router.currentRoute.value.name as FlowStepRouteName;
      switch (routeName) {
        case FlowStepRouteName.user:
          return undefined;
        case FlowStepRouteName.season:
          return route.params.volunteerID
            ? (route.params.volunteerID as Guid)
            : User.value.userID
        default:
          assertNonNull(props.volunteerID, `expected volunteerID to be defined on routename '${routeName}'`);
          return props.volunteerID;
      }
    });

    // extends clause only to fix parse ambiguity w/ jsx
    const withBusyGuard = <T extends any>(f: () => T) => {
      if (busy) {
        return
      }
      try {
        busy = true
        return f()
      } finally {
        busy = false
      }
    }

    //
    // local cache stuff, not quite generally usable enough to put into vuex
    //
    let __localcache__volunteerDetails: VolunteerDetails | undefined
    let __localcache__volunteerSeasonStatus: VolunteerSeasonStatus | undefined
    const getVolunteerDetails = async () => {
      assertNonNull(
        props.seasonUID,
        'set in step 1, this should not be called until after step 1'
      )
      assertNonNull(
        volunteerID.value,
        'set in step 2, this should not be called until after step 2'
      )

      if (__localcache__volunteerDetails && __localcache__volunteerDetails?.ID === props.volunteerID) {
        return __localcache__volunteerDetails
      } else {
        return (__localcache__volunteerDetails =
          await ilapi.getVolunteerDetails(
            axiosInstance,
            volunteerID.value,
            props.seasonUID
          ))
      }
    }
    const getVolunteerSeasonStatus = async () => {
      assertNonNull(
        props.seasonUID,
        'set in step 1, this should not be called until after step 1'
      )
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      if (
        __localcache__volunteerSeasonStatus?.userID === volunteerID.value &&
        __localcache__volunteerSeasonStatus.season.seasonUID === props.seasonUID
      ) {
        return __localcache__volunteerSeasonStatus
      } else {
        return (__localcache__volunteerSeasonStatus =
          await ilapi.getVolunteerSeasonStatus(
            axiosInstance,
            volunteerID.value,
            props.seasonUID
          ))
      }
    }

    /**
     * step 1, choose season
     */
    const handleSeasonSelected = async (seasonUID: string) => {
      await withBusyGuard(async () => {
        assertNonNull(volunteerID.value, "must have a volunteerID in handler");
        await router.push({
          name: FlowStepRouteName.contactInfo,
          params: { volunteerID: volunteerID.value, seasonUID: seasonUID },
        })
      })
    }

    /**
     * step 2 completion handler
     * (contact info confirmation)
     *
     * This provided the user with a chance to click "edit details"
     * Otherwise, they click confirm and we perform no action except to move to the next step
     *
     * We store the retrieved volunteerDetails locally to reuse in subsequent steps until the next remount or navaway
     */

    const handleConfirmedContactInfo = async (
      volunteerDetails: VolunteerDetails
    ) => {
      await withBusyGuard(async () => {
        assertNonNull(props.seasonUID, 'must have a seasonUID in handler')
        assertNonNull(volunteerID.value, "must have a volunteerID in handler");

        __localcache__volunteerDetails = volunteerDetails

        await router.push({
          name: FlowStepRouteName.volunteerRoles,
          params: { volunteerID: volunteerID.value, seasonUID: props.seasonUID },
        })
      })
    }

    const handleUserContactVerificationImplGotoUserEditor = async (/*no args*/) => {
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      await router.push({name: 'user-editor', params: {userID: volunteerID.value}})
    }

    /**
     * step 3 completion handler
     * (choose volunteer roles)
     */
    const handleRolesSelected = async (roles: VolunteerRoleSelection) => {
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      try {
        const submittableRoles = processRoles(roles)
        const submittableComments = {
          volunteerComments: roles.volunteerComments
            ? roles.volunteerComments
            : '',
        }

        assertNonNull(props.seasonUID, 'must have a seasonUID in handler')
        assertNonNull(volunteerID.value, "must have a volunteerID in handler");

        await ilapi.updateVolunteerDetails(
          axiosInstance,
          volunteerID.value,
          props.seasonUID,
          submittableRoles
        )
        await ilapi.updateVolunteerComments(
          axiosInstance,
          volunteerID.value,
          props.seasonUID,
          submittableComments
        )

        const volunteerDetails = await getVolunteerDetails()

        //
        // flow branches here -
        //   - 4.a. may require a confirmation / edit of name and dob (then to 4.b.)
        //   - 4.b. (quasi-step, also branches) -> 4.c if necessary, otherwise 5
        //
        if (
          !volunteerDetails.stackRecordKey ||
          volunteerDetails.stackRecordKey.trim() === ''
        ) {
          const volunteerSeasonStatus = await getVolunteerSeasonStatus()
          if (volunteerSeasonStatus.registrationRequired) {
            await router.push({
              name: FlowStepRouteName.hasNoStackKeyNeedsNameAndDobEdit,
              params: { volunteerID: volunteerID.value, seasonUID: props.seasonUID },
            })

            return
          }
        }

        await maybeDoElas()
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    /**
     * step 4.a. completion handler
     * (possibly allow edits to exactly name and dob)
     */
    const handleNoStackKeyNeededNameAndDobUpdate = async (
      data: HasNoStackKeyNeedsNameAndDobEdit
    ) => {
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      try {
        await ilapi.updateUserNameAndDob(
          axiosInstance,
          volunteerID.value,
          // assertion here is expected safe because we shouldn't have been given an empty string;
          // however, form state allows an empty string
          data as HasNoStackKeyNeedsNameAndDobEdit & {gender: "M" | "F"}
        )
        await maybeDoElas()
      }
      catch (err) {
        // a lot of noisy 400s when the user submits duplicate user info, we don't really need to log such errors
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    /**
     * step 4.b. completion handler
     * This is not a routed-step, but it does branch to different routed steps, based on `registrationRequired` property
     */
    const maybeDoElas = async () => {
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      const volunteerSeasonStatus = await getVolunteerSeasonStatus()
      // `registrationRequired` is overloaded to also mean "elas required"
      if (!volunteerSeasonStatus.registrationRequired) {
        // not required, we can skip the ELA step
        await router.replace({
          name: FlowStepRouteName.done,
          params: { volunteerID: volunteerID.value, seasonUID: props.seasonUID },
        })
      } else {
        await router.push({
          name: FlowStepRouteName.elas,
          params: { volunteerID: volunteerID.value, seasonUID: props.seasonUID },
        })
      }
    }

    /**
     * step 4.c completion handler
     * (do ELAs)
     */
    const handleELAsComplete = async (value?: { skipped: boolean }) => {
      assertNonNull(volunteerID.value, "must have a volunteerID in handler");
      // the ela component does all the work of saving whatever it needs to save
      // when done, we just advance to next step
      // it may emit an indicator if it had no work to do, in which case we'll do a route replacement
      const routeParams = {
        name: FlowStepRouteName.done,
        params: { volunteerID: volunteerID.value, seasonUID: props.seasonUID },
      }

      if (value?.skipped) {
        await router.replace(routeParams)
      } else {
        await router.push(routeParams)
      }
    }

    const listenersByFlowStep = computed(() => {
      const routeName = router.currentRoute.value.name as FlowStepRouteName;
      switch (routeName) {
        case FlowStepRouteName.user: {
          const v : M_SelectVolunteer2.Emits = {
            userSelected: async (userDetail) => {
              await withBusyGuard(async () => {
                M_SeasonJustEmit.flowState.configureFlowState(userDetail)
                await router.push({
                  name: FlowStepRouteName.season,
                  params: { volunteerID: userDetail.userID },
                })
              })
            }
          }
          return v;
        }
        case FlowStepRouteName.season: {
          assertNonNull(volunteerID.value, "must have volunteerID here");

          //
          // Check for old flow state that we need to clean up
          // We can clear it if necessary, it will autohydrate in the child route.
          // The component this flow state is for doesn't clean it up; this sort of implies we should
          // "just" use props; but that'd be a refactor.
          //
          // Whats the order of operations here? On routename change, is this computed
          // guaranteed to fire before the router-view mounts a new target component?
          // The desired order (this, then remount the router-view component) seems to be the one
          // we observe.
          //
          const flowState = M_SeasonJustEmit.flowState.getFlowState();
          if (flowState !== null && flowState.volunteerDetails.userID !== volunteerID.value) {
            M_SeasonJustEmit.flowState.clear();
          }

          return {
            'season-selected': handleSeasonSelected,
          }
        }
        case FlowStepRouteName.contactInfo:
          return {
            confirmed: handleConfirmedContactInfo,
            "user-contact-verification-impl-goto-user-editor": handleUserContactVerificationImplGotoUserEditor,
          }
        case FlowStepRouteName.volunteerRoles:
          return {
            'roles-selected': handleRolesSelected,
          }
        case FlowStepRouteName.hasNoStackKeyNeedsNameAndDobEdit:
          return {
            confirmed: handleNoStackKeyNeededNameAndDobUpdate,
          }
        case FlowStepRouteName.elas:
          return {
            complete: handleELAsComplete,
          }
        case FlowStepRouteName.done:
          return {}
        case FlowStepRouteName.unavailable:
          return {};
        default:
          // vue linter is wrong here; we shouldn't need to return,
          // this should assert never (just by calling it, not returning it) if we exhaustively matched
          return exhaustiveCaseGuard(routeName)
      }
    })

    return {
      listenersByFlowStep,
    }
  },
})
</script>
