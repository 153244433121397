import authService from "src/helpers/authService";
import { type UserData } from "src/interfaces/Store/user";

import * as iltypes from "src/interfaces/InleagueApiV1"
import { UiOption } from "src/helpers/utils";

export const Action_t = {
  PROMPT_USER: "PROMPT_USER",
  IMMEDIATE_ATTEMPT_PAYMENT: "IMMEDIATE_ATTEMPT_PAYMENT",
  FORCE_CANCEL: "FORCE_CANCEL"
} as const;
export type Action_t = (typeof Action_t)[keyof typeof Action_t];

