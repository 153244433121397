/**
 * These svgs should probably eventually be imported as `import x from ".../x.svg` using some resource loader
 * from whatever bundler we're using. There's some considerations with respect to changing the build system, regarding keeping tests building,
 * so this is the initial path of least resistance.
 */

const GOOGLE_LOGO = `
<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'>
  <defs>
    <path id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/>
  </defs>
  <clipPath id='b'>
    <use xlink:href='#a' overflow='visible'/>
  </clipPath>
  <path clip-path='url(#b)' fill='#FBBC05' d='M0 37V11l17 13z'/>
  <path clip-path='url(#b)' fill='#EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/>
  <path clip-path='url(#b)' fill='#34A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/>
  <path clip-path='url(#b)' fill='#4285F4' d='M48 48L17 24l-4-3 35-10z'/>
</svg>
`
export const GoogleLogo = (props: {width?: number, height?: number}) =>
  <img src={`data:image/svg+xml;base64,${btoa(GOOGLE_LOGO)}`} width={props.width ?? 22} height={props.height ?? 22}/>

/**
 * a literal "X" symbol
 */
export function X(props: {width?: string | number, height?: string | number, penWidth?: number, penColor?: string}) {
  const penWidth = props.penWidth ?? 10;
  const penColor = props.penColor ?? "black";
  return (
    <svg width={props.width ?? 22} height={props.height ?? 22} viewBox="0 0 100 100">
      <rect fill={penColor} width={penWidth} height="100" x={50-(penWidth/2)} y="0" transform="rotate(-45 50 50)"/>
      <rect fill={penColor} width={penWidth} height="100" x={50-(penWidth/2)} y="0" transform="rotate(45 50 50)"/>
    </svg>
  );
}

/**
 * TODO: this svg is not totally symmetric (??), so rotating it around its centerpoint is ever-so-slightly janky.
 * Rotate it really fast to see that it doesn't quite rotate about its center.
 * For a quick load screen and a reasonable animation speed it's not too noticeable but we should try to fix it at some point.
 * @param color Pass in the league's theme color
 */
export function SoccerBall(props: {
  color: string,
  height?: string,
  width?: string,
  timeForOneRotation?: string
}) {
  const color = props.color;
  const height = props.height ?? "74px";
  const width = props.width ?? "74px";
  const timeForOneRotation = props.timeForOneRotation ?? "2s";
  const rotateRootID = `rotate-root-${Math.floor(Math.random() * 999999)}`
  return (
    <svg version="1.1" id="Layer_1"
	    xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px" width={width} height={height} viewBox="0 0 74 74" enable-background="new 0 0 74 74">
      <g id="Layer_2">
      </g>
      <g id={rotateRootID}>
        <path fill="#FFFFFF" stroke={color} stroke-width="2" stroke-miterlimit="10" d={`M72.243,37.591
        c0,19.854-15.945,35.947-35.615,35.947c-19.667,0-35.613-16.093-35.613-35.947c0-19.85,15.945-35.946,35.613-35.946
        C56.298,1.645,72.243,17.741,72.243,37.591z`}/>
        <g>
          <path fill="none" d={`M15.598,9.615C0.245,21.081-2.901,42.827,8.57,58.163c11.471,15.355,33.209,18.508,48.565,7.03
          c15.352-11.461,18.494-33.201,7.025-48.55C52.692,1.292,30.95-1.858,15.598,9.615L15.598,9.615z`}/>
          <g>
            <g>
              <defs>
                <path id="SVGID_1_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" overflow="visible"/>
              </clipPath>
              <g clip-path="url(#SVGID_2_)">
                <path fill="none" stroke={color} stroke-miterlimit="10" d={`M37.864,23.902l1.405-11.261L29.257,7.137 M66.383,43.042
                L60.622,32.6l-14.686,2.186 M60.694,32.514L62.49,20.84 M39.268,12.64l14.826-2.606 M43.182,48.219l5.283,10.896l11.859-1.355
                M15.932,12.076l-2.046,14.527l11.786,5.434 M24.739,64.807l-4.503-10.396l-13.462-5.23 M27.203,47.06l-6.922,7.278
                M14.011,26.431l-7.468,8.266 M36.672,69.624l11.777-10.519`}/>
              </g>
            </g>
            <g>
              <defs>
                <path id="SVGID_3_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_4_">
                <use href="#SVGID_3_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_1" clip-path="url(#SVGID_4_)" fill={color} points={`50.241,34.137 37.969,23 23.58,31.227 26.959,47.454 43.439,49.256
                      `}/>
            </g>
            <g>
              <defs>
                <path id="SVGID_5_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_6_">
                <use href="#SVGID_5_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_2" clip-path="url(#SVGID_6_)" fill={color} points={`10.175,50.609 6.799,34.372 -9.684,32.576 -16.491,47.691
              -4.213,58.84 				`}/>
            </g>
            <g>
              <defs>
                <path id="SVGID_7_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_8_">
                <use href="#SVGID_7_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_3" clip-path="url(#SVGID_8_)" fill={color} points={`15.548,16.059 29.793,7.584 26.128,-8.582 9.625,-10.098
              3.085,5.142 				`}/>
            </g>
            <g>
              <defs>
                <path id="SVGID_9_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_10_">
                <use href="#SVGID_9_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_4" clip-path="url(#SVGID_10_)" fill={color} points={`71.865,68.823 86.103,60.336 82.444,44.176 65.936,42.659
              59.396,57.896 				`}/>
            </g>
            <g>
              <defs>
                <path id="SVGID_11_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_12_">
                <use href="#SVGID_11_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_5" clip-path="url(#SVGID_12_)" fill={color} points={`29.178,90.347 43.71,82.369 40.614,66.08 24.168,63.995 17.1,79
                      `}/>
            </g>
            <g>
              <defs>
                <path id="SVGID_13_" d={`M15.197,9.081C-0.445,20.766-3.653,42.93,8.034,58.576c11.693,15.634,33.85,18.85,49.497,7.161
                c15.65-11.692,18.854-33.845,7.161-49.5C53.003,0.598,30.846-2.611,15.197,9.081L15.197,9.081z`}/>
              </defs>
              <clipPath id="SVGID_14_">
                <use href="#SVGID_13_" overflow="visible"/>
              </clipPath>
              <polygon id="SVG_6" clip-path="url(#SVGID_14_)" fill={color} points={`62.051,22.233 76.721,14.511 73.911,-1.827 57.502,-4.198
              50.173,10.68 				`}/>
            </g>
          </g>
        </g>
      </g>
      {/* <rect x="-92.802" y="522.892" fill="none" width="422.17" height="499.139"/>
      <rect x="-111.071" y="493.587" fill="none" width="497.151" height="583.004"/> */}
      <animateTransform href={`#${rotateRootID}`} attributeName="transform" type="rotate" from="0 37 37" to="360 37 37" dur={timeForOneRotation} repeatCount="indefinite"/>
    </svg>
  )
}
