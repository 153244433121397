<!--
"season selector" for program or registration flow
on season select, just emits a seasonUID
in setup, we load the target seasons; when those are loaded, emits "seasons-loaded" with the listing of those season mementos
-->
<template lang="pug">
div(v-if="ready")
  .t-page.flex.flex-col(class='md:mx-2')
    h1.text-2xl.font-medium(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "search"]')
      | Select Registration Season
      span(v-if="authService(User.value.roles, 'webmaster', 'registrar')") *
      //-
      //- it is somewhat implied that if role === 'user' then flowState is non-null,
      //- but we'll be explicit about it
      //- if we get here, and `role` is 'user', and `flowState` is null, it's a bug
      //-
      span.ml-2(v-if="role==='user' && flowState") - {{ flowState.volunteerDetails.firstName }} {{ flowState.volunteerDetails.lastName }}
      p.italic.text-lg.mt-2.font-normal(v-if="authService(User.value.roles, 'webmaster', 'registrar')") *Users with registrar permissions are shown seasons opening for registration within two weeks.
  ContentChunkDisplay.mt-2(
    v-if="role==='player'"
    id='leagueRegistrationStatus'
  )
  div(v-else) Select a Season for Volunteer Registration.

  .max-w-md(v-if='Object.keys(seasonOptions).length')
    .mr-2.my-2(v-for="season, key in seasonOptions")
      FormKit(
        type='button'
        :label="season"
        @click='handleSeasonSelected(key)'
      )

  div(v-else-if="seasonCallComplete") Player registration is not open at this time.
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { createSelect } from 'src/helpers/schemaService'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

import  authService  from 'src/helpers/authService'

import * as ilapi from "src/composables/InleagueApiV1";
import * as iltypes from "src/interfaces/InleagueApiV1"

import { emitsDef, flowState as flowState_ } from './season-just-emit.ilx'
import { Guid } from 'src/interfaces/InleagueApiV1'
import { UserData } from 'src/interfaces/Store/user'
import { exhaustiveCaseGuard } from 'src/helpers/utils'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'Select Season',
  components: {
    ContentChunkDisplay
  },
  emits: emitsDef(),
  props: {
    /**
     * role="player" <==> we are choosing a season for a program registration
     * role="user" <==> we are choosing a season for a user's volunteer registration
     */
    role: {
      required: true,
      type: String as PropType<"player" | "user">
    },
    /**
     * This component is shared across at least {program,volunteer} registration
     * For program registration, this is irrelevant (it instead would be a playerID, but we don't need to know that here).
     * For volunteer registration, this is the target volunteerID, where undefined means "implicitly the current user as per application state".
     */
    volunteerID: {
      required: false,
      type: String as PropType<iltypes.Guid>
    }
  },
  setup(props, {emit}) {
    const ready = ref(false);
    const seasonOptions = ref<{[seasonUID: Guid]: /*ui label*/ string}>({})
    const seasonCallComplete = ref(false)
    const flowState = computed(() => flowState_.getFlowState())



    const verifySeasonDetails = async () => {
      const openingWithin = authService(User.value.roles, 'webmaster', 'registrar') ? 14 : undefined;

      //
      // There's different endpoints to pull season info based on what mode (role) we're currently in.
      //
      const activeSeasons : ilapi.ActiveSeasonListingEntry[] = await (() => {
        switch (props.role) {
          case "player":
            // seasons for a program registration
            return ilapi.getOpenSeasons(axiosInstance, {openingWithin});
          case "user":
            // seasons for a volunteer registration
            return ilapi.getActiveSeasons(axiosInstance, {openingWithin});
          default: exhaustiveCaseGuard(props.role);
        }
      })();

      seasonOptions.value = createSelect(activeSeasons, 'seasonUID', 'seasonName')
      seasonCallComplete.value=true

      emit("seasons-loaded", activeSeasons.map(v => v.seasonUID));
    }

    onMounted(async () => {
      if (props.role === "user") {
        // in "user" mode, we are interested in pulling/hydrating flow state
        if (!flowState_.getFlowState()) {
          if (props.volunteerID) {
            await flowState_.hydrateFlowState(axiosInstance, props.volunteerID);
          }
          else {
            flowState_.configureFlowState(User.value.userData as UserData);
          }
        }
      }
      else {
        // in "player" mode, there's no flow state to pull
      }

      await verifySeasonDetails()
      ready.value = true;
    })

    return {
      ready,
      flowState,
      seasonOptions,
      seasonCallComplete,
      authService,
      handleSeasonSelected: (seasonUID: Guid) : void => {
        emit("season-selected", seasonUID)
      },
      User,
    }
  },
})
</script>
