<template lang="pug">
TBtn(:label='`Restore to ${impersonatingUserEmail}`' @click='restoreUser' data-test="restoreUserButton")
</template>

<script lang="ts">
import iziToast from 'izitoast'
import { AxiosErrorWrapper, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import { UserData } from 'src/interfaces/Store/user'

import { defineComponent, computed } from 'vue'

import { PayableInvoicesResolver } from 'src/store/EventuallyPinia'

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { User } from 'src/store/User'
import { resetRelevantDataOnImpersonate } from './Impersonate'

export default defineComponent({
  setup() {


    const impersonatingUserEmail = computed(() => {
      return (User.value.userData as UserData).impersonatingUserEmail
    })

    const restoreUser = async () => {
      try {
        const freshAuthResponse = await ilauth.unimpersonate(axiosInstance);

        resetRelevantDataOnImpersonate();

        iziToast.destroy()
        await User.loginUser(freshAuthResponse);

        PayableInvoicesResolver.refresh(freshNoToastLoggedInAxiosInstance())
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return {
      impersonatingUserEmail,
      restoreUser
    }
  },
})
</script>
