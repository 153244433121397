<template lang="pug">
div
  div(:class='[{ relative: showDropdown }]', :data-type='type')
    input.mt-1.rounded-md.shadow-sm.text-black.form-input.block.w-full(
      type='text',
      :value='modelValue.displayValue',
      @input='e => $emit("input", {ID: modelValue.ID, displayValue: e.target.value})'
      autocomplete='no',
      @keydown.enter.prevent='evt => selectOptionViaEnterKeypress()',
      @keydown.down.prevent='increment',
      @keydown.up.prevent='decrement',
      :placeholder='placeholder',
      @focus='toggleDropdown(true)',
      @blur='toggleDropdown(false)'
    )
    ul.absolute.mt-1.w-full.bg-white.shadow-lg.max-h-60.rounded-md.py-1.text-base.ring-1.ring-black.ring-opacity-5.overflow-auto(
      v-show='showDropdown && filteredOptions.length && !modelValue.ID',
      class='focus:outline-none sm:text-sm',
      tabindex='-1',
      role='listbox',
      aria-labelledby='listbox-label',
      aria-activedescendant='listbox-option-3',
      @click='toggleDropdown(false)'
      @keydown.enter.prevent="toggleDropdown(false)"
    )
      li.text-gray-900.cursor-default.select-none.relative.py-2.pl-3.pr-9(
        v-for='(option, index) in filteredOptions',
        :key='option.ID',
        :data-is-selected='modelValue.ID === option.ID',
        @mouseenter='selectedIndex = index',
        @click='evt => selectOptionViaIndex(index)',
        :class='{ "bg-gray-200": index === selectedIndex }'
        :data-test="option.ID"
      )
        div {{ option.firstName }} {{ option.lastName }}
        .text-gray-500 {{ option.email }}
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from 'vue'

export default defineComponent({
  name: 'autoSearchInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object as PropType<{ displayValue: string; ID: string }>,
      required: true,
    },
    /**
     * array of object
     * clarify: what do the object keys represent, what do the object values represent?
     */
    options: {
      type: Array as PropType<{ [key: string]: string }[]>,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasSearched: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input', 'assignID'],
  setup(props, { emit }) {
    /**
     * This represents the index the mouse is hovering over, or the arrow keys have navigated to.
     * It is the index that will be selected on enter keypress, and is the "highlighted" option.
     * (hence we may not have already informed the parent that this option is now "the current value")
     */
    const selectedIndex = ref(0)
    const showDropdown = ref(true)

    const inputValue = computed({
      get() {
        return props.modelValue.displayValue
      },
      set(val: string) {
        emit('input', val)
        emit('assignID', '')
      },
    })

    const toggleDropdown = (bool: boolean) => {
      setTimeout(() => {
        showDropdown.value = bool
      }, 200)
    }

    /**
     * n.b. this logic makes the component "a component for auto complete of user names and nothing else"
     */
    const filteredOptions = computed(() => {
      if (props.options?.length) {
        return props.options.filter(option => {
          const name = `${option.firstName} ${option.lastName}`
          return (
            option.firstName.toLowerCase().startsWith(
              inputValue.value.toLowerCase()
            ) ||
            option.lastName.toLowerCase().startsWith(
              inputValue.value.toLowerCase()
            ) ||
            name.toLowerCase().startsWith(inputValue.value.toLowerCase())
          )
        })
      }
      return []
    })

    const selectOptionWorker = (index: number) => {
      const selected = filteredOptions.value[index];
      if (!selected) {
        // can happen on enter keypress, if there are no options
        return;
      }

      emit('input', {
        displayValue: `${selected.firstName} ${selected.lastName}`,
        ID: selected.ID
      });

      toggleDropdown(false)
    }

    /**
     * select some option via explicit index,
     * generally in response to a {click,tap} of a particular option
     */
    const selectOptionViaIndex = (index: number) => {
      selectOptionWorker(index);
    }

    /**
     * on enter keypress, use the selected (i.e. "tentative") selection index as the target, and make the selection
     */
    const selectOptionViaEnterKeypress = () => {
      selectOptionWorker(selectedIndex.value);
    }

    /**
     * on down-arrow, update the tentative selection as appropriate
     */
    const increment = () => {
      if (selectedIndex.value + 1 < filteredOptions.value.length) {
        selectedIndex.value++
      } else {
        selectedIndex.value = 0
      }
    }

    /**
     * on up-arrow, update the tentative selection as appropriate
     */
    const decrement = () => {
      if (selectedIndex.value - 1 >= 0) {
        selectedIndex.value--
      } else {
        selectedIndex.value = filteredOptions.value.length - 1
      }
    }

    watch(
      () => props.hasSearched,
      val => {
        if (val) {
          showDropdown.value = true
        }
      }
    )

    return {
      // modelValue: props.modelValue,
      selectedIndex,
      inputValue,
      filteredOptions,
      selectOptionViaEnterKeypress,
      selectOptionViaIndex,
      toggleDropdown,
      showDropdown,
      increment,
      decrement,
    }
  },
})
</script>
