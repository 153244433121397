import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";

export function maskedLastTwoSmsPhoneNumber(phone: ilauth.MfaDetails["smsPhoneNumber"]) {
  return `••••••••${phone.lastTwo}`
}

export function SorryBadPhoneNumber(props: {phoneNumber: string}) : JSX.Element {
  return (
    <div class="flex items-center justify-center">
      We couldn't use your existing primary phone number ({props.phoneNumber}) to send you a message.
      Please contact your registrar to update your phone number to a 10 digit cell phone number.
    </div>
  )
}

export async function withGlobalSpinner(f: () => void | Promise<void>) : Promise<void> {
  GlobalInteractionBlockingRequestsInFlight.withSpinner(f);
}
