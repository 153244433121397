<template lang="pug">
t-dialog(data-test="ConfirmEventSignupCancellationModal")
  h1 Cancel event signup for {{ eventSignupEntityName }}?
  div.mt-4.flex.justify-center.gap-4
    //- need to override weird margins on bottom/right?
    t-btn.mr-0.mb-0(type="button" color="green" @click="confirmCancellation" data-test="yes")
      div Yes
    t-btn.mr-0.mb-0(type="button" color="red" @click="doNotCancel" data-test="no")
      div No
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { propsDef, emitsDef } from "./ConfirmEventSignupCancellationModal.ilx"

export default defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    const eventSignupEntityName = computed(() => {
      return props.eventSignup.childID
        ? `${props.eventSignup.childFirstName} ${props.eventSignup.childLastName}`
        : `${props.eventSignup.userFirstName} ${props.eventSignup.userLastName}`;
    });

    return {
      eventSignupEntityName,
      confirmCancellation: () : void => void emit("confirmCancellation", props.eventSignup),
      doNotCancel: () : void => void emit("doNotCancel")
    }
  },
})
</script>
