import dayjs, { Dayjs } from "dayjs";
import { JS_DayOfWeek } from "./utils";
import { IL_DayOfWeek } from "src/interfaces/InleagueApiV1";

export {
  bestUpcomingOrCurrentCompetitionStartDayOfWeek,
  testExports,
}

function ilDayToJsDay(v: IL_DayOfWeek) : JS_DayOfWeek {
  return v - 1;
}

/**
 * Given a competition's "start day of week", find the best "upoming or current"
 * start day of week, typically for initializing a calendar form with a useful value.
 */
function bestUpcomingOrCurrentCompetitionStartDayOfWeek(
  il_competitionStartDayOfWeek: IL_DayOfWeek,
  today = dayjs()
) : Dayjs {
  const compStartDayOfWeek : JS_DayOfWeek = ilDayToJsDay(il_competitionStartDayOfWeek);
  const todaysDayOfWeek : JS_DayOfWeek = today.day();

  const exactMatch = todaysDayOfWeek === compStartDayOfWeek
  const todayIsOnePast = todaysDayOfWeek === (compStartDayOfWeek + 1) % 7
  if (exactMatch || todayIsOnePast) {
    if (todaysDayOfWeek === JS_DayOfWeek.SUNDAY) {
      return today.subtract(1, "day");
    }
    else {
      return today;
    }
  }
  else {
    return theComingWeekday(JS_DayOfWeek.SATURDAY, today);
  }
}

/**
 * Get "the coming X", where X is a day of the week
 * e.g. "the coming saturday"
 */
function theComingWeekday(comingDayOfWeek: JS_DayOfWeek, origin = dayjs()) : Dayjs {
  let workingDate = origin.hour(0).minute(0).second(0).millisecond(0)

  if (workingDate.day() == comingDayOfWeek) {
    // the coming X where today is X
    // e.g. "the coming saturday where today is saturday" means "next saturday"
    return workingDate.add(1, "week")
  }

  // at most 6 iters
  while (workingDate.day() !== comingDayOfWeek) {
    workingDate = workingDate.add(1, "day")
  }

  return workingDate
}

const testExports = {
  theComingWeekday,
}
