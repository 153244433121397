import { tournTeamRegPageItemStore } from "src/components/Tournaments/Store/TournTeamRegPageItemStore";
import { tournamentTeamStore } from "src/components/Tournaments/Store/TournTeamStore";
import { tournamentStore } from "src/components/Tournaments/Store/TournamentStore";
import { User } from "src/store/User";

/**
 * This might be appropriate as part of the `System` store
 */
export function resetRelevantDataOnImpersonate() {
  User.directCommit_clearUserData()
  tournamentStore.clear();
  tournTeamRegPageItemStore.clear();
  tournamentTeamStore.clear();
}
