<template lang="pug">
div
  label.font-medium.text-sm.t-label.block.mb-2.mt-5(v-if='label', :for='name') {{ label }}
    slot
  input.t-input.rounded-md.shadow-sm.text-black.form-input.block.w-full(
    class='focus:ring-green-500 focus:border-green-500 sm:text-sm',
    :class='hasValidationErrors ? "border-red-500 " + name : name',
    :name='name',
    :type='type',
    :disabled='disabled',
    :required='required',
    v-model='localModelValue',
    :placeholder='placeholder',
    @input='update($event.target.value)'
    :autocomplete='autoComplete ? "on": "off"'
  )
  div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>

<script lang="ts">
import { formatForDateInput } from 'src/helpers/formatDate'
import { defineComponent, computed, onMounted } from 'vue'
import { datePickerFormat } from 'src/helpers/formatDate'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'TInput',
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    type: {
      // PropType<"text" | "date" | ???> ...
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
      default: () => {
        // "name" was required, but code all over the place was failing to pass it
        // If it was really required, presumably those places would have been broken; they're not broken, but they do all raise
        // warnings about not passing it in
        // So, "name" is probably not required, though there's a few places in the local template that try to use it that maybe we could remove
        // or ignore if it's not available
        // For now we provide some default random hex string value
        return Math.random().toString(16).substring(2);
      }
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    'input-class': {
      type: String,
      default: '',
    },
    autoComplete: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const localModelValue = computed({
      get() {
        // if(props.type==='date' && props.modelValue.length > 5) {
        //   // console.log('date modelValue', props.modelValue)
        //   return formatForDateInput(props.modelValue)
        // } else {
        return props.modelValue
        // }
      },
      set(value: string) {
        emit('update:modelValue', value)
      },
    })

    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })

    const update = (value: any) => {
      emit('update:modelValue', value)
    }

    onMounted(()=> {
      if(props.type==='date' && Date.parse(props.modelValue)) {
        emit('update:modelValue', datePickerFormat(props.modelValue))
      }
    })
    return {
      hasValidationErrors,
      update,
      localModelValue,
    }
  },
})
</script>
