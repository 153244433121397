import { type UserData } from "src/interfaces/Store/user";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard } from "src/helpers/utils";

export type SupportedOauthProvider = "google"

export interface LeagueDomainDetails {
  appDomain: string
  clientID: string
  regionName: string
  /**
   * Not always present?
   * If not present, considering it falsy seems fine.
   */
  isInleague?: number | boolean
}

interface AuthenticateResponseBase {
  status: "needs-mfa-challenge" | "complete" | "needs-mfa-init"
}

export type MfaType = "SMS" | "TOTP"

export interface MfaDetails {
  userID: iltypes.Guid,
  firstName: string,
  lastName: string,
  /**
   * does this user have a linked google account
   */
  hasGoogleAuthNProvider: boolean,
  /**
   * is this user enrolled in sms (mostly meaning, we have the phone number they want to receive messages to)
   */
  hasSMS: boolean,
  /**
   * is this user enrolled in totp/authenticator-app (we have a shared secret with them for this purpose)
   */
  hasTOTP: boolean,
  /**
   * Whether their league's rules require them to be enrolled and utilize MFA
   */
  leaguePolicyRequiresMFA: boolean,
  /**
   * The phone number we {would use when enrolling, are using if enrolled} for their SMS MFA.
   * If they aren't enrolled, it's possible their phone number is invalid, in which case this data is the ok=false arm.
   * This is the same as their primary phone number, and cannot be otherwise.
   */
  smsPhoneNumber:
    | {
      ok: true,
      lastTwo: string
    }
    | {
      ok: false,
      lastTwo: string,
      detail: "invalid-phone-number"
    },
  enrolledMfaTypes: MfaType[],
  unenrolledMfaTypes: MfaType[]
}

export interface AuthenticateResponse_NeedsMfaChallenge extends AuthenticateResponseBase {
  status: "needs-mfa-challenge",
  userID: iltypes.Guid,
  /**
   * Was once a value shuttled via cookie, but to play nice with iOS webviews, we need to manually wrangle this.
   * (seems Apple enforces extreme cross-domain restrictions with their webviews, e.g. even with samesite=none we cannot push a cookie from
   * capacitor:// to a production inleague domain)
   */
  token: string,
  mfaDetails: MfaDetails,
  league: LeagueDomainDetails,
}

export interface AuthenticateResponse_NeedsMfaInit extends AuthenticateResponseBase {
  status: "needs-mfa-init",
  /**
   * see comments on AuthenticateResponse_NeedsMfaChallenge
   */
  token: string,
  userID: iltypes.Guid,
  mfaDetails: MfaDetails,
  league: LeagueDomainDetails,
}

export interface AuthenticateResponse_Complete extends AuthenticateResponseBase {
  status: "complete",
  jwt: string,
  league: LeagueDomainDetails,
  /**
   * TODO: check format of this, test that it is appropriate across timezones
   */
  tokenExpiration: iltypes.DateTimelike,
  userData: UserData
  securityHash: string,
  onLoginAction?: "validate-user-gender"
}

export type AuthenticateResponse =
  | AuthenticateResponse_NeedsMfaChallenge
  | AuthenticateResponse_NeedsMfaInit
  | AuthenticateResponse_Complete

export type AuthenticateMultiResponse =
  | {type: "single", payload: AuthenticateResponse}
  | {type: "multi", payload: LeagueDomainDetails[]}

/**
 * We can receive info in the /login route's fragment, which, when present, drives behavior
 * This could probably be a query string param? Mostly same same. There's nothing we're trying to prevent sending to the server so maybe fragment is overkill and semantically wrong.
 *
 * `"oauth-login"` --> we recognize this user via oauth, but they are not yet logged in; we get a jwt assertion to continue after we come back from {demo,api}.inleague.io
 *
 * `"mfa-from-elsewhere"` --> We got put into an mfa flow from 'somewhere' in the program. We have UI code for handling these flows in one place; we'll reuse this fragment pattern to jump into
 * the appropriate component from other places which may call "login" and receive "hey you need to do MFA" responses
 *
 * `"multiple-leagues"` --> there are multiple leagues to choose from. We are probably in a 3rd party oauth acknowledgement flow (e.g. login with google succeeded, but that google account is associated
 * with an email which is associated with 2+ leagues). `isInThirdPartyOauthAckFlow` will generally be true here; false is equivalent to undefined for this value (though it's not clear we'll ever enter such a state).
 */
export type LoginURLFragment =
  | {what: "oauth-login", assertion: string}
  | {what: "mfa-from-elsewhere", data: AuthenticateResponse_NeedsMfaInit | AuthenticateResponse_NeedsMfaChallenge }
  | {what: "multiple-leagues", email: string, availableLeagues: LeagueDomainDetails[], isInThirdPartyOauthAckFlow?: boolean}


interface NewUserConfig_Base {
  what:
    | "unrecognized-oauth-login"
    | "new-user-via-aysoID-claim"
}

/**
 * `config` query param payloads for the new user form
 * This is auth-adjacent but maybe should live in some new-user-form module.
 */
export type NewUserConfigQueryParam =
  | NewUserConfig_UnrecognizedOauthLogin
  | NewUserConfig_NewUserViaAysoIdClaim

export interface NewUserConfig_UnrecognizedOauthLogin extends NewUserConfig_Base {
  /**
   * if we got an oauth login, but we don't know who the asserted person is, we get routed to the new user screen with this paylod
   */
  what: "unrecognized-oauth-login",
  provider: SupportedOauthProvider,
  /**
   * we don't recognize the oauth provider's credentials (i.e. some provider ID associated with a user account),
   * but we trust the oauth provider's asserted email address and found a user having that email address for "this" client ("this" being contextual).
   *
   * This is falsy when both:
   *   - we don't recognize the oauth provider's asserted credentials
   *   - the provider's asserted email doesn't get a match for any user
   */
  unrecognizedOauthButDefinitelyInleagueUserEmail: null | string,
  /**
   * all of the values here may be missing or empty strings, this is best effort from the provided oauth credential
   */
  potentialUserInfo: {
    firstName?: string,
    middleName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string,
    gender?: string,
    address_street?: string,
    address_city?: string,
    address_state?: string,
    address_zip?: string,
  }
}

export interface NewUserConfig_NewUserViaAysoIdClaim extends NewUserConfig_Base {
  what: "new-user-via-aysoID-claim",
  jwt: string,
  /**
   * this is "optional" in that it won't be present until after the jwt is decoded
   */
  extractedFromJWT?: {
    aysoID: string,
  }
}

export function serializeNewUserConfigQueryParam(v: NewUserConfigQueryParam) : string {
  return btoa(JSON.stringify(v))
}

/**
 * Non-throwing; on any failure, returns null
 */
export function deserializeNewUserConfigQueryParam(v: string) : NewUserConfigQueryParam | null {
  try {
    const obj = JSON.parse(atob(v)) as NewUserConfigQueryParam;
    switch (obj.what) {
      case "new-user-via-aysoID-claim": {
        const parts = obj.jwt.split(".");
        const payload_base64_serializedJSON_obj = parts[1];
        const payload_serializedJSON_obj = atob(payload_base64_serializedJSON_obj);
        const payload_obj = JSON.parse(payload_serializedJSON_obj)
        return {
          ...obj,
          extractedFromJWT: {
            aysoID: payload_obj.aysoID,
          }
        }
      }
      case "unrecognized-oauth-login": {
        return obj;
      }
      default: exhaustiveCaseGuard(obj);
    }
  }
  catch {
    return null;
  }
}

export function loginURLFragment(v: LoginURLFragment, withLeadingHash = true) {
  const hash = withLeadingHash ? "#" : "";
  return hash + btoa(JSON.stringify(v));
}

/**
 * in general we don't want to know the key on the client, except during the setup flow where
 * we obviously have to know it by way of the QR code which contains the key, and also need to literally
 * know the key to support manually entering it e.g. from a mobile phone where you'd otherwise have to scan the qr
 * code, but the camera's in the phone, and the qr code is on the screen.
 */
export interface TotpSetupKeyDetail {
  base64_jpg_qrCode: string,
}

/**
 * Contractually guaranteed response codes for "couldn't send an sms message"
 */
export type SmsFailureReason = "unknown" | "invalid-phone-number"
