import { ref, watch, PropType, defineComponent } from "vue";
import * as R_EventEditor from "./R_EventEditor.ilx"
import { RouterLink } from "vue-router";
import { FormKit } from "@formkit/vue";

import { Props, EventFormData } from "./EventForm.ilx"
import { UiOption } from "src/helpers/utils";

export function MaybeCloneLink(forwardedProps: Props) : JSX.Element | null {
  switch (forwardedProps.detail.type) {
    case "edit-existing":
      return (
        <div>
          <RouterLink
            class="text-blue-700 underline cursor-pointer"
            to={R_EventEditor.routeDetailToRoutePath({name: R_EventEditor.RouteName.clone, sourceEventID: forwardedProps.detail.eventID})}
          >
            Clone this event
          </RouterLink>
        </div>
      )
    default:
      return null;
  }
}

export const EventLocationSelectAndMaybeFreeform = defineComponent({
  props: {
    eventVenueSelectOptions: {
      required: true,
      type: Array as PropType<UiOption[]>
    },
    formEventLocation: {
      required: true,
      type: Object as PropType<EventFormData["eventLocation"]>
    }
  },
  setup(props) {
    const mutRef_target = ref(props.formEventLocation);

    const lastKnownFreeFormValue = ref("")

    watch(() => mutRef_target.value.venueID, (fresh, old) => {
      if (fresh === "OTHER") {
        // from non-freeform to freeform
        mutRef_target.value.type = "freeform";
        mutRef_target.value.eventLoc = lastKnownFreeFormValue.value // restore last freeform value (might be empty string, that's fine)
        lastKnownFreeFormValue.value = "" // "consume" last known value, not strictly necessary but tidys up
        return;
      }

      if (old === "OTHER") {
        // from freeform to non-freeform
        mutRef_target.value.type = "venueID"
        lastKnownFreeFormValue.value = mutRef_target.value.eventLoc; // save copy of previous free-form value
        mutRef_target.value.eventLoc = "" // zero out previous free-form value
        return;
      }
    })

    return () => (
      <>
        <FormKit
          type="select"
          options={props.eventVenueSelectOptions}
          v-model={mutRef_target.value.venueID}
          data-test="venueID"
        />
        {
          mutRef_target.value.type === "freeform"
            ? (
              <FormKit
                type="text"
                v-model={mutRef_target.value.eventLoc}
                data-test="eventLoc"
              />
            )
            : null
        }
      </>
    )
  }
})
