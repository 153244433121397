<template lang="pug">
h1.ml-2.mr-4.text-2xl.self-start.font-medium(class='md:text-4xl')
  font-awesome-icon.mr-2(:icon='["fas", "file-check"]')
  | Volunteer Preferences Confirmation - {{volunteerDetails.firstName}} {{ volunteerDetails.lastName}}
VolunteerConfirmationImpl(
  :seasonUID="seasonUID"
  :volunteerID="volunteerID"
)
.max-w-3xl.flex.justify-end
  div(class="flex flex-col gap-2 items-end")
    router-link(:to="familyProfileRouterLink")
      t-btn(
        type="button"
        :margin="false"
      )
        div Go To Family Profile
    router-link(v-if="maybeUserEditorVolunteerPrefsLink" :to="maybeUserEditorVolunteerPrefsLink")
      t-btn(
        type="button"
        :margin="false"
      )
        div Go to User Editor
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { computed, onMounted, ref } from 'vue'
import VolunteerConfirmationImpl from "./VolunteerConfirmationImpl.vue"
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { Client } from 'src/store/Client'
import { defineComponent } from 'vue'
import * as R_UserEditor from '../User/Editor/R_UserEditor.route'

export default defineComponent({
  components: {
    VolunteerConfirmationImpl
  },
  props: {
    seasonUID: {required: true, type: String},
    volunteerID: {required: true, type: String},
  },
  setup(props) {
    const familyProfileRouterLink = FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default});
    const maybeUserEditorVolunteerPrefsLink = computed(() => {
      if (R_UserEditor.authZ_viewVolunteerCerts()) {
        return R_UserEditor.routeDetailToRouteLocation({
          name: "user-editor.volunteer-prefs",
          userID: props.volunteerID,
          seasonUID: undefined
        });
      }
      else {
        return null;
      }
    });

    const volunteerDetails = ref({})

    // is this effectively unused?
    const season = ref({})

    // does child currently load this? this appears unused.
    const backgroundStatus = ref({})

    const getDivName = async (divID: string) => {
      if(divID){
        const division = await Client.getDivisionByID(divID)
        return division?.division ?? divID
      } else {
        return divID
      }
    }

    const addDivName = async (details: any) => {
      const updatedDetails = {...details}
      for(let i=0; i<updatedDetails.volunteerPrefs.length; i++) {
        updatedDetails.volunteerPrefs[i].divName = await getDivName(updatedDetails.volunteerPrefs[i].divID)
      }
      return updatedDetails
    }

    const getVolunteerDetails = async () => {
      const response = await axiosInstance.get(`/v1/user/${props.volunteerID}/season/${props.seasonUID}`)
      return response.data.data
    }

    const getBackgroundStatus = async () => {
      const response = await axiosInstance.get(`/v1/volunteer/${props.volunteerID}/season/${props.seasonUID}`)
      return response.data.data
    }

    onMounted(async () => {
      season.value = await Client.getSeasonByUidOrFail(props.seasonUID)
      const details = await getVolunteerDetails()
      volunteerDetails.value = await addDivName(details)
      backgroundStatus.value = await getBackgroundStatus()
    })

    return {
      familyProfileRouterLink,
      volunteerDetails,
      maybeUserEditorVolunteerPrefsLink,
    }
  }
})
</script>
