<template lang="pug">
template(v-if="ready")
  td.whitespace-normal.px-6.py-4.text-right.text-sm.font-medium.col-span-1.cursor-pointer(class="md:px-6 md:py-4")
    div
      a.text-green-600(@click="showEdit=!showEdit" class='hover:text-green-900' data-test="edit") Edit
  td.whitespace-normal.px-6.py-4.text-sm.font-medium.text-gray-900.col-span-2(data-test="cell/optionText")
    | {{ option.optionText }}
  td.whitespace-normal.px-6.py-4.text-sm.text-gray-500
    | {{ option.optionValue }}
  td.whitespace-normal.px-6.py-4.text-sm.text-gray-500
    | {{ option.order }}
  td.whitespace-normal.px-6.py-4.text-sm.text-gray-500
    div(v-if="seasonUIDs.length===0")
      | All Seasons
    div(v-else-if="Object.keys(clientSeasons).length>0")
      div(v-for="seasonUID in seasonUIDs") {{ clientSeasons[seasonUID] }}
  td.whitespace-normal.px-6.py-4.text-sm.text-gray-500.col-span-2
    div(v-if="option.gateFunctionName") {{ option.gateFunctionName }}
    div.italic(v-if="option.gateFunctionID")  {{ option.gateFunction.customField.name }} {{ option.gateFunction.matchType===1 ? 'equals' : 'does not equal'}} {{ getTargetValue(option.gateFunction.targetValue) }}
    div(v-if="!option.gateFunctionName && !option.gateFunctionID") No Gate Function
  td.whitespace-normal.px-6.py-4.text-right.text-sm.font-medium.cursor-pointer.col-span-1
    div.text-red-600(@click="deleteOption") X
  div.col-span-9(v-if="showEdit && Object.keys(option).length>0" class="md:pl-24 md:pr-12")
    div.w-full
      .flex.w-full.pr-2
        .w-60.mr-4(class="md:w-96")
          FormKit.m-2(
            label='Option Text',
            type="text",
            v-model='option.optionText',
            validations='required',
            data-test="optionText"
          )
            button.ml-1.cursor-pointer(
              v-tooltip='{content: `The label displayed for the individual option.`}'
            )
              font-awesome-icon.mr-2.text-gray-400(
                :icon='["fas", "info-circle"]'
              )
        .w-50.mr-4.flex.flex-row
          div(style="--fk-margin-outer:none;")
            FormKit(
              type="text",
              label='Option Value',
              v-model='option.optionValue',
              validations='required',
              data-test="optionValue"
            )
            div(class="my-1 text-red-600 text-xs" v-if="errors.valueInvalidLength") {{ errors.valueInvalidLengthMsg }}
          button.ml-1.cursor-pointer(
            v-tooltip="{content: `The value saved to the player's registration record when this option is selected.`}"
          )
            font-awesome-icon.mr-2.text-gray-400(
              :icon='["fas", "info-circle"]'
            )
        .w-28.mr-4
          FormKit.m-2(
            label='Option Order',
            type="number",
            v-model='option.order',
            validations='required',
          )
            button.ml-1.cursor-pointer(
              v-tooltip='{content: `The order in which this question option will be displayed.`}'
            )
              font-awesome-icon.mr-2.text-gray-400(
                :icon='["fas", "info-circle"]'
              )
      GateFunctionEditor(
        v-model:gateFunction="option.gateFunctionName"
        labelItem="Option"
        :item='option'
        :itemID="getEffectiveQuestionOptionID(option)"
        :gateQuestionType='2'
        :triggerSave="triggerSave"
        @clearCustomGate="clearCustomGate"
        @updateStatus="(status)=>stati.gateFunction=status"
      )
      //- does `pageItemID` need to be renamed to `itemID`, or is this wrong, and we really do need to pass a pageItemID?
      SeasonSelector(
        labelItem="Option"
        :pageItemID="getEffectiveQuestionOptionID(option)"
        :selectedSeasonsObjects='option.seasons'
        @selectedAllSeasons="(val)=>selectedAllSeasons=val"
        @apiError='$emit("apiError")'
        @update:seasonUIDs="(val)=>seasonUIDs=val"
        @updateStatus="(status)=>stati.seasons=status"
      )
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, computed, Ref, PropType, getCurrentInstance } from 'vue'
import SeasonSelector from 'src/components/Registration/admin/SeasonSelector.vue'
import GateFunctionEditor from 'src/components/Registration/admin/GateFunctionEditor.vue'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { CustomField, GateFunctionClass, QuestionOption, getEffectiveQuestionOptionID, getQuestionOptionLifecycleType, markQuestionOptionPersistedInPlace } from 'src/interfaces/Store/registration'

import * as ilapi from "src/composables/InleagueApiV1"
import { assertNonNull, vReqT } from 'src/helpers/utils'

import { Client } from 'src/store/Client'
import { Guid } from 'src/interfaces/InleagueApiV1'
import { RegistrationStore } from "src/store/Registration"
import { QuestionFormErrors_QuestionOptionErrors } from './pageItem/PageItem'

export default defineComponent({
  props: {
    option: vReqT<QuestionOption>(),
    triggerSave: vReqT<boolean>(),
    questionID: vReqT<string>(),
    errors: vReqT<QuestionFormErrors_QuestionOptionErrors>(),
  },
  components: {
    SeasonSelector,
    GateFunctionEditor,
  },
  emits: ['deleteOption', 'errors', 'update:modelValue', 'updateStatus', 'apiError'],
  setup(props, {emit}) {
    const ready = ref(false)
    const showEdit = ref(false)
    const clientSeasons = ref<{[seasonUID: Guid]: /*seasonName*/string}>({})
    const seasonUIDs = ref<Guid[]>([...props.option.seasons.map(v => typeof v === "string" ? v : v.seasonUID)])
    const selectedCustomField = ref({} as Ref<CustomField>)
    const seasonsChanged = ref(false)
    const apiCallsCompleted = ref(false)
    const stati = ref({}) as Ref<{[key:string]:string}>
    const valuesSet = ref(false)
    const saved = ref(false)
    const selectedAllSeasons = ref(false)


    const localInstance = getCurrentInstance()
    const toast = localInstance?.appContext.config.globalProperties.$toast

    watch(stati, (val: {[key:string]:string})=> {
      if(Object.keys(val).length===0) {
        props.option.status="unchanged"
        return
      } else {
        for(const key in val) {
          if(val[key]==="pending") {
            props.option.status= "pending"
            return
          }
        }
      }
      props.option.status="saved"
      return
    }, {deep:true})

    const setSelectedCustomField = async (customFieldID: string) => {
      if(customFieldID) {
        selectedCustomField.value = await RegistrationStore.getCustomField(customFieldID)
      } else {
        selectedCustomField.value = {} as CustomField
      }
    }

    const updateSeasons = async (id:string) : Promise<void> => {
      try {
        await axiosInstance.put(`/v1/registration/customQuestionOption/${id}/seasons`, {seasons: seasonUIDs.value})
        saved.value = true
        stati.value.seasons = 'saved'
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const updateOption = async () : Promise<Record<string, any> | null> => {
      const options = {...props.option}
      try {
        const response = await axiosInstance.put(`/v1/registration/customQuestionOption/${props.option.id as string}`, options)
        saved.value=true
        stati.value.option = 'saved'
        return response.data.data
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
      return null;
    }

    const addOption = async () : Promise<ilapi.FIXME_CreateQuestionOptionResponse | null> => {
      assertNonNull(props.questionID, "questionID should have been defined by the time we get here (question created before question options)");
      try {
        const freshOption = await ilapi.createCustomQuestionOption(axiosInstance, {
          questionID: props.questionID,
          optionText: props.option.optionText,
          optionValue: props.option.optionValue,
          order: props.option.order
        });

        saved.value = true
        stati.value.option = 'saved'
        apiCallsCompleted.value = true

        return freshOption;
      } catch (err) {
        // Not clear how to reasonably recover from this.
        // There could also be other question options being created in separate requests, so some get created, while others fail.
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }

      return null;
    }

    const deleteOption = () => {
      emit('deleteOption', getEffectiveQuestionOptionID(props.option))
    }

    const clearCustomGate = () => {
      props.option.gateFunction={} as GateFunctionClass
      props.option.gateFunctionID=''
    }

    const getTargetValue = (val: string) => {
      if(selectedCustomField.value.inputType===4) {
        return val == '1' ? 'Yes' : 'No'
      } else {
        return val
      }
    }

    watch(stati, (val) => {
      // console.log('stati watch triggered')
      let allItemsSaved='saved'
      for(const status in val) {
        if(val[status]!='saved') {
          allItemsSaved='pending'
        }
      }
      emit('updateStatus', allItemsSaved)
      // console.log('option emit updateStatus')
    }, {deep: true})

    watch(() => props.option, ()=> {
      if(valuesSet.value && !saved.value) {
        stati.value.option="pending"
      }
      if(!props.option.questionID) {
        showEdit.value=true
      }
    }, {deep: true})

    watch(seasonUIDs, () => {
      if(valuesSet.value) {
        seasonsChanged.value = true
        stati.value.seasons = "pending"
      } else {
        valuesSet.value = true
      }
    })


    watch(()=>props.triggerSave, async (val) => {
      let option : Record<string, any> | null = null;

      if(val && stati.value.option==="pending" && props.option.id) {
        // we infer from having an `id` property that the option exists already on the server, and needs to be updated
        option = await updateOption()
      } else if (val && stati.value.option==="pending") {
        // it doesn't have an ID, so we assume it needs to be pushed to the server to be created
        option = await addOption()
        if (option) {
          markQuestionOptionPersistedInPlace(props.option, option.id);
        }
      }

      if (!option) {
        // some kind of axios failure that we recovered from, in the sense that we could recognize and stop the propagation of the exception,
        // but because the request failed, we don't have resulting data, and so -- "just bail" is about the best we can do.
        emit('apiError')
        return;
      }

      if(val && seasonsChanged.value){
        if(!selectedAllSeasons.value && seasonUIDs.value.length===0) {
          // release error
          toast.error({message: 'Each option must have at least one selected season.'})
          stati.value={option: "pending", seasons: "pending"}
          emit('apiError')
        } else {
          await updateSeasons(option.id)
          stati.value.seasons="saved"
        }
      }
    })

    onMounted(async()=> {
      if (props.option.gateFunctionID) {
        await setSelectedCustomField(props.option.gateFunction.customFieldID)
      }

      clientSeasons.value = await (async () => {
        const seasonNamesBySeasonUID : {[seasonUID: string]: string} = {}
        const seasons = await Client.loadSeasons()
        for (const season of seasons) {
          seasonNamesBySeasonUID[season.seasonUID] = season.seasonName;
        }
        return seasonNamesBySeasonUID;
      })();

      ready.value = true
    })

    watch(() => RegistrationStore.value.customFields, async (val) => {
      if(props.option.gateFunction) await setSelectedCustomField(props.option.gateFunction?.customFieldID)
    }, {deep: true})

    return {
      showEdit,
      seasonUIDs,
      seasonsChanged,
      clientSeasons,
      clearCustomGate,
      setSelectedCustomField,
      getTargetValue,
      selectedCustomField,
      deleteOption,
      apiCallsCompleted,
      stati,
      valuesSet,
      selectedAllSeasons,
      getEffectiveQuestionOptionID,
      ready
    }
  },
})
</script>
