<template lang="pug">
.grid.grid-cols-11.my-2
  .p-2.mt-2.pl-4.font-medium.text-gray-700 {{ localDivision.displayName.dirty ? localDivision.displayName.dirty : division.division }}
  .p-2.mt-2 {{ localDivision.gender.dirty }}
  .p-2.mt-2 {{  division.division == 'EPIC' ? "Special" : localDivision.divNum.dirty  }}
  .col-span-1.p-2.pt-4
    FormKit.m-0(
      type='select',
      :options='forceCoed',
      v-model='localDivision.forceCoed.dirty',
      placeholder='Select Co-Ed Status'
    )
  .col-span-2.p-2.pt-4
    FormKit(type='number', v-model='localDivision.divMax.dirty')
  //- not coming from API
  .col-span-2.p-2.pt-4
    FormKit(
      type='select',
      :options='closeAction',
      v-model='localDivision.closeAction.dirty'
    )

  //- not coming from API
  .col-span-2.p-2.pt-4
    FormKit(
      type='select',
      :options='isActive',
      v-model='localDivision.isActive.dirty'
    )
  .pt-4.p-2
    FormKit(
      v-if='formIsDirty',
      type='button',
      @click='updateDivision',
      label='Save'
    )
</template>

<script lang="ts">
import { __FIXME__UNSAFE_CAST } from "src/helpers/utils"
import { axiosInstance } from 'src/boot/axios'
import { Division } from 'src/interfaces/InleagueApiV1'
import {
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
  getCurrentInstance,
} from 'vue'

export default defineComponent({
  props: {
    division: {
      type: Object as PropType<Division>,
      required: true,
    },
    competitionUID: {
      type: String,
      required: true,
    },
    seasonUID: {
      type: String,
      required: true,
    },
  },
  emits: ['updateDivision'],
  setup(props, { emit }) {
    const forceCoed = ref({ '1': 'Yes', '0': 'No' })
    const isActive = ref({ '1': 'Active', '0': 'InActive' })
    const closeAction = ref({ '0': 'Do Nothing', '1': 'Waitlist', 2: 'Close' })
    const localInstance = getCurrentInstance()
    const $toast = localInstance?.appContext.config.globalProperties.$toast

    const localDivision = ref(MutableDivisionInformationSubset(props.division))

    const formIsDirty = computed(() => isDirty(localDivision.value))

    watch(props.division, () => {
      for (const key of DivisionInformationSubsetKeylist) {
        if (props.division[key as keyof Division] != localDivision.value[key].dirty) {
          const dirty_stringOrNumber = props.division[key as keyof Division]
          localDivision.value[key].dirty = __FIXME__UNSAFE_CAST<string>(dirty_stringOrNumber);
        }
      }
    })

    const updateDivision = async () => {
      const options = (() => {
        const base = extractFromDirty(localDivision.value)
        const additional = {
          divID: props.division.divID,
          seasonUID: props.seasonUID,
          competitionUID: props.competitionUID,
          divMax: base.divMax ?? 0,
        }
        return {
          ...base,
          ...additional,
        }
      })()

      try {
        const response = await axiosInstance.post(
          `/v1/registration/competitionSeasonDivision`,
          options
        )
        $toast.success({
          message: `Division setup for ${
            localDivision.value.displayName.dirty
              ? localDivision.value.displayName.dirty
              : props.division.division
          } has been succesfully updated.`,
        })
        commitAllChanges(localDivision.value)
      } catch (error: any) {
        // console.log('error in updateDivision!!', error.response.data.messages )
        localInstance?.appContext.config.globalProperties.$toast.error({
          message: error.response.data.messages.join(', '),
        })
      }
    }

    return {
      localDivision,
      forceCoed,
      isActive,
      closeAction,
      updateDivision,
      formIsDirty,
    }
  },
})

// closeAction is not a member of Division?
// well we edit it like it is
type DivisionSubset = Pick<
  Division,
  'isActive' | 'forceCoed' | 'displayName' | 'gender' | 'divNum' | 'divMax'
> & { closeAction: number }
// is it possible to say "exhaustive keylist" ? that's what we want
const DivisionInformationSubsetKeylist: (keyof DivisionSubset)[] = [
  'isActive',
  'forceCoed',
  'displayName',
  'gender',
  'divNum',
  'divMax',
  'closeAction',
]

// we map all mutable props to dirty DirtyPristinePrimitive<string>
// otherwise, a pristine number with a dirty string from the form are not `===`
function MutableDivisionInformationSubset(v: Division) {
  const result: Record<string, DirtyPristinePrimitive<any>> = {}
  for (const key of DivisionInformationSubsetKeylist) {
    result[key] = new DirtyPristinePrimitive(v[key as keyof Division].toString())
  }
  return result as {
    [K in keyof DivisionSubset]: DirtyPristinePrimitive<string>
  }
}
type MutableDivisionInformationSubset = ReturnType<
  typeof MutableDivisionInformationSubset
>

function isDirty(v: MutableDivisionInformationSubset): boolean {
  for (const key of DivisionInformationSubsetKeylist) {
    if (v[key].isDirty()) {
      return true
    }
  }
  return false
}

function commitAllChanges(v: MutableDivisionInformationSubset): void {
  for (const key of DivisionInformationSubsetKeylist) {
    v[key].commitChanges()
  }
}

function extractFromDirty(
  v: MutableDivisionInformationSubset
): DivisionSubset {
  const result = {}
  for (const key of DivisionInformationSubsetKeylist) {
    (result as any)[key] = v[key].dirty
  }
  return result as DivisionSubset
}

type Primitive = string | number | boolean | undefined | null
class DirtyPristinePrimitive<T extends Primitive> {
  dirty: T
  pristine: T
  constructor(v: T) {
    this.dirty = v
    this.pristine = v
  }

  isDirty(): boolean {
    return this.dirty !== this.pristine
  }

  discardChanges(): void {
    this.dirty = this.pristine
  }

  commitChanges(): void {
    this.pristine = this.dirty
  }
}
</script>

