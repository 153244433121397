import { faFileExport } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { ColumnToLabelMapping } from "src/composables/InleagueApiV1.ReportBuilder"
import { dayjsFormatOr } from "src/helpers/formatDate"
import { UiOption, exhaustiveCaseGuard, parseIntOr, sortBy, sortByDayJS, sortByMany, vReqT } from "src/helpers/utils"
import { Integerlike } from "src/interfaces/InleagueApiV1"
import { SimplePaginationNav } from "src/modules/PaginationElements"
import { Paginated } from "src/modules/PaginationUtils"
import { ColDef, SortArrow, downloadAsXlsxFactory, freshSortState, getColDefHtml } from "src/modules/TableUtils"
import { Fragment, computed, defineComponent, ref } from "vue"

export const QueryResultTable = defineComponent({
  props: {
    data: vReqT<Record<string, any>[]>(),
    labels: vReqT<ColumnToLabelMapping>(),
  },
  setup(props) {
    const itemsPerPage = ref<"ALL" | Integerlike>("25")
    const itemsPerPageOptions : UiOption[] = [
      {label: "25", value: "25"},
      {label: "50", value: "50"},
      {label: "All", value: "ALL"},
    ]

    type RowData = Record<string, any>

    const sortedLabelDefs = computed(() => {
      let lastColGroup = Math.max(...Object.values(props.labels).map(v => parseIntOr(v.columnGroup, -1)));
      const result = Object
        .entries(props.labels)
        .sort(
          sortByMany(
            sortBy(_ => _[1].columnGroup /*not strict*/ == -1 ? (lastColGroup++) : _[1].columnGroup),
            sortBy(_ => _[1].columnGroupPosition),
          )
        )
      return result
    })

    const colDefs = computed<ColDef<RowData>[]>(() => {
      return sortedLabelDefs
        .value
        .map(([propName, info], i) : ColDef<RowData> => {
          const sorter : (l: RowData, r: RowData) => number = (() => {
            switch (info.colDataType) {
              case "date":
                return sortByDayJS(row => row[propName])
              case "string":
                return sortBy(row => row[propName])
              default:
                exhaustiveCaseGuard(info.colDataType)
            }
          })()

          return {
            id: `col${i}_${propName}`,
            // we should always have a label for the name, but fallback just in case to "just" `field.name`
            label: info.label,
            html: row => {
              switch (info.colDataType) {
                case "date":
                  return dayjsFormatOr(row[propName], "MMM/DD/YYYY")
                case "string":
                  return row[propName]
                default:
                  exhaustiveCaseGuard(info.colDataType)
              }
            },
            sort: {
              cb: sorter,
              dir: "not-sorted",
            }
          }
        });
    })

    const sortState = freshSortState(colDefs.value);

    const sortedRows = computed(() => {
      return [...props.data].sort(sortState.asSorter())
    })

    const pagination = computed(() => Paginated(25, sortedRows))

    const downloadAsXlsx = async () => {
      const fileName = "ReportBuilder.xlsx" // ???
      const runner = downloadAsXlsxFactory(colDefs.value)
      await runner(sortedRows.value, fileName)
    }

    return () => {
      if (props.data.length === 0) {
        return <div class="w-full bg-white rounded-md shadow-md p-6 border border-slate-100 flex items-center justify-center">
          No results matching this query
        </div>
      }
      return (
        <div class="w-full bg-white rounded-md shadow-md p-2 border border-slate-100">
          <div class="flex mb-2">
            <div class="ml-auto">
              <t-btn onClick={downloadAsXlsx} margin={false}>
                <FontAwesomeIcon icon={faFileExport}/>
                <span class="ml-1 text-xs">As Excel</span>
              </t-btn>
            </div>
          </div>
          <div class="w-full overflow-x-auto">
            <table class="w-full">
              {
                colDefs.value.map(colDef => {
                  return (
                    <th class="p-1 align-top text-left">
                      <div class="flex items-center text-sm">
                        <span>{colDef.label}</span>
                        {
                          ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                            return sorter
                              ? (
                                <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                  <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                </span>
                              )
                              : null
                          })(sortState.sortersByColID[colDef.id])
                        }
                      </div>
                    </th>
                  )
                })
              }
              {
                pagination
                  .value
                  .pageData
                  .itemsThisPage
                  .map((rowData, i) => {
                    const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                    return (
                      <Fragment key={`row-${i}`}>
                        <tr style={styles}>
                          {
                            colDefs.value.map((colDef) => {
                              return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                            })
                          }
                        </tr>
                      </Fragment>
                    )
                  })
              }
            </table>
            <div>
              {
                pagination.value.pageData.allItems.length > 0
                  ? (
                    <div class="flex text-xs">
                      <div class="ml-auto p-2">
                        <SimplePaginationNav itemsLabel={"Items"} mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>
      )
    }
  }
})
