export default function hasCompetitionRole(role: string, competitionMemento: any[], competitionID?: string, ) {
  if (!competitionMemento) return 0
  for (let i = 0; i < competitionMemento.length; i++) {
    if (
      competitionID &&
      competitionMemento[i].competitionUID === competitionID
    ) {
      return competitionMemento[i][role]
    } else if (competitionMemento[i][role] === 1) {
      return 1
    }
  }
  return 0
}
