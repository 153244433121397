import gtm from 'src/components/gtm';
import { boot } from 'quasar/wrappers'
import { Client } from 'src/store/Client';

/**
 * opaque google blob, basically sets up `dataLayer` and loads "the google tag" with some key or keys
 */
const runGoogleBlob = (key: string) => {
    (function(w: any,d: any,s: any,l: any,i: any){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);}
    )(window,document,'script','dataLayer', key);
}

export default boot(async ({ router, store }) => {
    const googleTagManagerID = (await Client.getInstanceConfig()).googletagmanager
    if(googleTagManagerID) {
        runGoogleBlob(googleTagManagerID);
        runGoogleBlob('GTM-56N4GBF');
    }
    else {
        //no client GTM key available, only use inLeague GTM ID instead
        runGoogleBlob('GTM-56N4GBF')
    }
    // this requires that we have custom events setup?
    // e.g. we push "customPageView", does anyone see that event anywhere?
    router.afterEach((to, from) => {
        gtm.logPage(to.path);
    })
})
