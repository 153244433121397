const hasUnembargoedPlayer = (payload: any) => {
  let unembargoedPlayer = false
  if (payload.userData.playerAssignmentsMemento.length) {
    payload.userData.playerAssignmentsMemento.forEach(
      (player: { embargoed: boolean }) => {
        if (!player.embargoed) unembargoedPlayer = true
      }
    )
  }
  return unembargoedPlayer
}

const hasDDCanSchedule = (payload: any, instanceConfig: any) => {
  return payload.userData.roles.includes('Director') && instanceConfig.DDCanSchedule
}

export const verifyUserAuth =  (payload: any, instanceConfig: any) => {
  const userAuth = [...payload.userData.roles]
  if(hasUnembargoedPlayer(payload)) userAuth.push('hasUnembargoedPlayer')
  if(hasDDCanSchedule(payload, instanceConfig)) userAuth.push('DDScheduler')
  return userAuth
}
