import dayjs from "dayjs"
import { UiCentricGameInfo } from "src/composables/InleagueApiV1.GameSchedulerXlsx"
import { vReqT } from "src/helpers/utils"
import { defineComponent } from "vue"

const gameTimeFormat = "MMM/DD/YYYY hh:mm a"

export const DryRunResults = defineComponent({
  props: {
    dryRunResults: vReqT<UiCentricGameInfo[]>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="rounded-md border border-slate-100 shadow-md p-2">
          <table style="--fk-margin-outer: none;" class="w-full">
            <tr>
              <th class="text-left">Game number</th>
              <th class="text-left">Game time</th>
              <th class="text-left">Program</th>
              <th class="text-left">Division</th>
              <th class="text-left">Field</th>
              <th class="text-left">New Teams</th>
            </tr>
            {
              props
                .dryRunResults
                .map((result, i, a) => {
                  const commonTdClasses = `${i % 2 ? "bg-gray-100" : ""} align-top p-1`
                  const isLast = i === a.length - 1;
                  return <>
                    <tr class={`${!isLast ? "border-b" : ""}`}>
                      <td class={commonTdClasses}>{result.gameNum}</td>
                      <td class={commonTdClasses}>
                        <div>Start: {dayjs(result.gameStart).format(gameTimeFormat)}</div>
                        <div>End: {dayjs(result.gameEnd).format(gameTimeFormat)}</div>
                      </td>
                      <td class={commonTdClasses}>{result.competition}</td>
                      <td class={commonTdClasses}>{result.division}</td>
                      <td class={commonTdClasses}>{result.field}</td>
                      <td class={commonTdClasses}>
                        <div>Home: {result.homeTeamName || "N/A (TBD)"}</div>
                        <div>Visitor: {result.visitorTeamName || "N/A (TBD)"}</div>
                      </td>
                    </tr>
                  </>
                })
            }
          </table>
        </div>
      )
    }
  }
})
