import { accentAwareCaseInsensitiveCompare, arraySum, parseIntOr, requireNonNull, sortBy, sortByMany, vReqT } from "src/helpers/utils"
import { Guid, RegistrationID } from "src/interfaces/InleagueApiV1"
import { BasicTable, ColDef, freshSortState, SortState, typedBasicTableProps } from "src/modules/TableUtils"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { ClientRating } from "./ClientRatingsConfigurator.io"
import { axiosInstance } from "src/boot/AxiosInstances"
import { FormKit } from "@formkit/vue"
import { getPlayerRatingsAuthZ as getPlayerRatingsAuthZMenu, RatingsMenu, RatingsMode, RegWithRatings } from "./PlayerRatings.io"
import { LocationQuery } from "vue-router"
import { freshSelectionsFirstOfEach, MenuTree } from "../UserInterface/MenuTree"
import { Btn2 } from "../UserInterface/Btn2"

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { k_GUID } from "src/boot/TypeBoxSetup"

import { ratingOptions, RegFormElemContainer, WellKnownCol } from "./RatePlayersForm"
import { Client } from "src/store/Client"
import ContentChunkDisplay from "../Admin/ContentChunks/ContentChunkDisplay"
import { authZ_canDoRegionalPlayerRatings } from "./R_PlayerRatings.route"
import { ReifiedPromise } from "src/helpers/ReifiedPromise"
import { SoccerBall } from "../SVGs"

export const RatePlayers = defineComponent({
  props: {
    clientRatings: vReqT<{core: ClientRating, custom: ClientRating[]}>(),
    playerRatings: vReqT<ReifiedPromise<{playerRatings: RegWithRatings[], formsByRegID: Map<RegistrationID, RegFormElemContainer>}>>(),
    q: vReqT<QueryParams_RatePlayers>(),
  },
  emits: {
    updateQueryParams: (_: QueryParams_RatePlayers) => true,
    save: () => true,
  },
  setup(props, ctx) {
    const CONTENT_CHUNK_ID_PLAYER_RATINGS_BLURB = 11
    const ready = ref(false)

    const ratingsMode = ref(RatingsMode.coach)
    const menuDef = ref<RatingsMenu>(null as any)
    const menuSelections = ref({seasonUID: "" as "" | Guid, divID: "" as "" | Guid, teamID: "" as "" | Guid})

    const currentSelectionIsComplete = () : boolean => {
      const {seasonUID, divID, teamID} = menuSelections.value;
      return !!seasonUID && !!divID && !!teamID;
    }

    onMounted(async () => {
      const authZ = await getPlayerRatingsAuthZMenu(axiosInstance)
      const q = props.q;

      ratingsMode.value = (() => {
        if (q?.mode === RatingsMode.regional) {
          if (!authZ.hasRegionalMode) {
            return RatingsMode.coach;
          }
          else {
            return RatingsMode.regional
          }
        }
        else {
          return RatingsMode.coach;
        }
      })();

      menuDef.value = authZ.authZMenu
      menuSelections.value = freshSelectionsFirstOfEach(authZ.authZMenu)

      if (q?.seasonUID && menuDef.value.menu
          .children.find(v => v.detail.entityID === q.seasonUID)
      ) {
        menuSelections.value.seasonUID = q.seasonUID
      }

      if (q?.divID && menuDef.value.menu
          .children.find(v => v.detail.entityID === q.seasonUID)
          ?.children.find(v => v.detail.entityID === q.divID)
      ) {
        menuSelections.value.divID = q.divID
      }

      if (q?.teamID && menuDef
          .value.menu
            .children.find(v => v.detail.entityID === q.seasonUID)
            ?.children.find(v => v.detail.entityID === q.divID)
            ?.children.find(v => v.detail.entityID === q.teamID)
      ) {
        menuSelections.value.teamID = q.teamID
      }

      ready.value = true
    })

    watch([() => ratingsMode.value, () => menuSelections.value], () => {
      if (!ready.value || !currentSelectionIsComplete()) {
        return
      }

      const {seasonUID, divID, teamID} = menuSelections.value
      ctx.emit("updateQueryParams", {seasonUID, divID, teamID, mode: ratingsMode.value})
    }, {deep: true, immediate: true})

    return () => {
      if (!ready.value) {
        return false;
      }

      return <div style="--fk-bg-input:white; --fk-padding-input:.5em;">
        <MenuTree menuDef={menuDef.value} mut_selections={menuSelections.value}/>
        <div class="flex items-center gap-1">
          {authZ_canDoRegionalPlayerRatings()
            ? <>
              <input name="ratingsMode" id="R_PlayerRatings-mode-a" type="radio" value={RatingsMode.coach} v-model={ratingsMode.value}/>
              <label for="R_PlayerRatings-mode-a">Coach Ratings</label>
              <input class="ml-1" name="ratingsMode" id="R_PlayerRatings-mode-b" type="radio" value={RatingsMode.regional} v-model={ratingsMode.value}/>
              <label for="R_PlayerRatings-mode-b">Region Ratings</label>
            </>
            : null
          }
        </div>

        <div class="flex">
          <ContentChunkDisplay class="mx-auto my-4" id={CONTENT_CHUNK_ID_PLAYER_RATINGS_BLURB}/>
        </div>

        {currentSelectionIsComplete()
          ? <div class="shadow-md p-2 bg-white rounded-md">
            <PlayerRatingsInputTable
              key={`${menuSelections.value.seasonUID}/${menuSelections.value.divID}/${menuSelections.value.teamID}/${ratingsMode.value}`}
              class="w-full"
              seasonUID={menuSelections.value.seasonUID}
              divID={menuSelections.value.divID}
              teamID={menuSelections.value.teamID}
              ratingsMode={ratingsMode.value}
              clientRatings={props.clientRatings}
              playerRatings={props.playerRatings}
              onSave={() => ctx.emit("save")}
            />
          </div>
          : null
        }
      </div>
    }
  }
})

const PlayerRatingsInputTable = defineComponent({
  props: {
    seasonUID: vReqT<Guid>(),
    divID: vReqT<Guid>(),
    teamID: vReqT<Guid>(),
    ratingsMode: vReqT<RatingsMode>(),
    clientRatings: vReqT<{core: ClientRating, custom: ClientRating[]}>(),
    playerRatings: vReqT<ReifiedPromise<{playerRatings: RegWithRatings[], formsByRegID: Map<RegistrationID, RegFormElemContainer>}>>(),
  },
  emits: {
    save: () => true,
  },
  setup(props, ctx) {
    const formIsDirty = computed(() => {
      if (props.playerRatings.status !== "resolved") {
        return false
      }

      const {playerRatings, formsByRegID} = props.playerRatings.data

      return playerRatings.some(v => {
        const core = requireNonNull(formsByRegID.get(v.registrationID)?.core)
        if (core.isDirty()) {
          return true
        }

        const custom = requireNonNull(formsByRegID.get(v.registrationID)?.custom)
        if (custom.some(v => v.isDirty())) {
          return true;
        }

        return false;
      })
    })

    type TableState = {colDefs: ColDef<RegWithRatings>[], sortState: SortState<RegWithRatings>}
    const freshTableState = () : TableState => {
      if (props.playerRatings.status === "resolved") {
        const colDefs = makeColDefs(props.playerRatings.data.formsByRegID, props.clientRatings.core, props.clientRatings.custom)
        const sortState = freshSortState(colDefs);
        sortState.reconfigure([{colID: WellKnownCol.playerName, dir: "asc"}])
        return {colDefs, sortState} satisfies TableState
      }
      else {
        return {
          colDefs: [],
          sortState: freshSortState([]),
        } satisfies TableState
      }
    }
    const tableState = ref(freshTableState())
    watch(() => props.playerRatings.status, () => {
      tableState.value = freshTableState()
    })

    const sortedData = computed(() => {
      if (props.playerRatings.status === "resolved") {
        return [...props.playerRatings.data.playerRatings].sort(tableState.value.sortState.asSorter())
      }
      else {
        return []
      }
    })

    const testKey = computed(() => {
      return "RatePlayers/" + [
        `seasonUID=${props.seasonUID}`,
        `divID=${props.divID}`,
        `teamID=${props.teamID}`,
        `mode=${props.ratingsMode}`,
      ].join(",")
    })

    return () => {
      if (props.playerRatings.status === "pending") {
        return <div class="p-2 w-full rounded-md overflow-auto bg-white flex items-center gap-2">
          <SoccerBall width="1em" height="1em" color={Client.value.clientTheme.color}/>
          Loading...
        </div>
      }
      else if (props.playerRatings.status === "resolved") {
        return <div
          style="--fk-margin-outer:none; --fk-padding-input:.375em; --fk-bg-input:white; overflow:auto;"
          data-test={testKey.value}
        >
          <BasicTable
            class="w-full"
            style="min-width:1000px;"
            {...typedBasicTableProps({
            colDefs: tableState.value.colDefs,
            rowData: sortedData.value,
            sortState: tableState.value.sortState,
            rowKey: v => v.registrationID,
            rowAttrs: v => ({"data-test": `regID=${v.registrationID}`}),
            noData: () => <div class="p-4">No data.</div>,
          })}/>
          <Btn2 class="mt-2 px-2 py-1" disabled={!formIsDirty.value} onClick={() => ctx.emit("save")}>Save</Btn2>
        </div>
      }
      else if (props.playerRatings.status === "error") {
        throw props.playerRatings.error
      }
      else {
        // nothing
      }
    }
  }
})

function makeColDefs(
  formElemLookup: Map<RegistrationID, RegFormElemContainer>,
  coreRating: ClientRating,
  clientRatings: ClientRating[]
) : ColDef<RegWithRatings>[] {
  return [
    {
      id: WellKnownCol.playerName,
      label: "Player name",
      headerClass: "p-1 border",
      html: v => `${v.playerFirstName} ${v.playerLastName}`,
      sort: sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(l.playerLastName, r.playerLastName),
        (l,r) => accentAwareCaseInsensitiveCompare(l.playerFirstName, r.playerFirstName),
      )
    },
    {
      id: WellKnownCol.age,
      label: "Age",
      headerClass: "p-1 border",
      html: v => v.age_calc,
      sort: sortBy(v => v.age_calc),
    },
    {
      id: WellKnownCol.overallRating,
      label: "Overall Rating",
      headerClass: "p-1 border w-32",
      cellClass: "p-1",
      html: v => {
        const formElem = requireNonNull(formElemLookup.get(v.registrationID)?.core)
        if (!!Client.value.instanceConfig.autocalcoverallrating) {
          return <FormKit type="text" disabled={true} v-model={formElem.ratingValue} data-test="rating1"/>
        }
        else {
          return <FormKit type="select" options={ratingOptions(coreRating, formElem[" __pristine_ratingValue"])} v-model={formElem.ratingValue}/>
        }
      }
    },
    ...clientRatings.map((rating, i) : ColDef<RegWithRatings> => {
      return {
        id: rating.ratingID,
        label: rating.ratingText + (rating.overallComponent ? "*" : ""),
        headerClass: `p-1 border ${rating.type === "numeric" ? "w-32" : "w-48"}`,
        cellClass: "p-1",
        html: ({registrationID}) => {
          const formElem = requireNonNull(formElemLookup.get(registrationID)?.custom.find(formElem => formElem.ratingID === rating.ratingID))
          return <>
            <FormKit
              type="select"
              options={ratingOptions(rating, formElem[" __pristine_ratingValue"])}
              v-model={formElem.ratingValue}
              data-test={`rating${i + 2}`} // one-indexed, plus account for "core" rating consuming index 1
              onInput={(value: any) => {
                if (formElem.ratingValue === value) {
                  return;
                }

                formElem.ratingValue = value

                if (!Client.value.instanceConfig.autocalcoverallrating) {
                  return;
                }

                //
                // Here we are "auto calc'ing the overall rating"
                // We set the value of the "core" (i.e. 'overall') rating to the sum of all of the numeric ratings which are "overalComponent=true"
                //
                const coreRating = requireNonNull(formElemLookup.get(registrationID)?.core)

                const relevantClientRatings = clientRatings.filter(clientRating => clientRating.type === "numeric" && clientRating.overallComponent)

                // if every relevant client rating is empty,
                // the computed core rating is empty, too
                if (relevantClientRatings.every(clientRating => {
                  const formElem = requireNonNull(formElemLookup.get(registrationID)?.custom.find(formElem => formElem.ratingID === clientRating.ratingID))
                  return parseIntOr(formElem.ratingValue, null) === null
                })) {
                  coreRating.ratingValue = ""
                }
                else {
                  coreRating.ratingValue = arraySum(
                    relevantClientRatings
                      .map(clientRating => {
                        const formElem = requireNonNull(formElemLookup.get(registrationID)?.custom.find(formElem => formElem.ratingID === clientRating.ratingID))
                        return parseIntOr(formElem.ratingValue, 0)
                      })
                  ).toString()
                }
              }}
            />
          </>
        }
      }
    }),
    {
      id: WellKnownCol.comments,
      label: "Comments",
      headerClass: "p-1 border",
      html: v => {
        const formElem = requireNonNull(formElemLookup.get(v.registrationID)?.core)
        return <FormKit type="text" v-model={formElem.comment} data-test="comment"/>
      }
    },
  ];
}

export function queryParams_ratePlayers(q: LocationQuery) {
  const shape = t.Object({
    seasonUID: t.Optional(t.String({format: k_GUID})),
    divID: t.Optional(t.String({format: k_GUID})),
    teamID: t.Optional(t.String({format: k_GUID})),
    mode: t.Optional(t.Enum(RatingsMode)),
  })

  if (Value.Check(shape, q)) {
    const {seasonUID, divID, teamID, mode} = q;
    return {
      seasonUID,
      teamID,
      divID,
      mode
    }
  }
  else {
    return undefined
  }
}

export type QueryParams_RatePlayers = ReturnType<typeof queryParams_ratePlayers>
