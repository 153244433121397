import { ref } from "vue";
import type { AxiosInstance } from "axios";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as Modal from "src/components/UserInterface/Modal"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";

import { SimpleFileUploadDialog } from "./Modal.photoUpload.common";
import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import { Client } from "src/store/Client";

export function coachPhotoUploadModalController(
  nonReactiveProplike: {
    maxSize: {
      bytes: number,
      humanReadable: string
    },
    accept?: string
  },
  handlers: {
    onSubmit: (_: {userID: iltypes.Guid, file: File}) => void | Promise<void>
  }
) {
  const isBusy = ref(false);
  const onCloseCB = (cb: () => void) : void => {
    if (isBusy.value) {
      return;
    }
    else {
      cb();
    }
  }

  const handleSubmit = async (payload: {entity: iltypes.Guid, file: File}) : Promise<void> => {
    if (isBusy.value) {
      // shouldn't happen
      return;
    }

    try {
      isBusy.value = true;
      await handlers.onSubmit({userID: payload.entity, file: payload.file})
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    }
    finally {
      isBusy.value = false;
    }
  }

  return Modal.DefaultModalController<iltypes.UserID>({
    title: () => (
      <>
        <div>Upload coach photo</div>
        <div>File types accepted: PNG, JPG, GIF, BMP, and TIF</div>
        <div class="my-2 border-b border-slate-200"/>
      </>
    ),
    content: userID => !!userID
      ? (
        <>
          {/* remount everytime for fresh Impl state -- can we get a notification for every "open" event? and bump a key counter or something? */}
          {/* a full close/open cycle should perform a complete mount/dispose lifecycle though */}
          <SimpleFileUploadDialog loopThrough={userID} maxSize={nonReactiveProplike.maxSize} accept={nonReactiveProplike.accept} onSubmit={handleSubmit}/>
          {
            isBusy.value
              ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
              : null
          }
        </>
      )
      : null
  }, {onCloseCB})
}

/**
 * @axios generally should be "axios auth background instance with toasts on failures";
 * for finer control over this, instantiate a "non-default" controller manually
 *
 * @onSuccess see notes in body for why this is necessary
 *
 */
export function defaultCoachPhotoUploadModalController(axios: AxiosInstance, onSuccess: (userID: iltypes.Guid) => void) {
  const controller = coachPhotoUploadModalController(
    {
      maxSize: {
        bytes: ilvolunteer.uploadPhotoMeta.maxSizeBytes,
        humanReadable: `${ilvolunteer.uploadPhotoMeta.maxSizeBytes / 1000 / 1000} MB`,
      },
      accept: ilvolunteer.uploadPhotoMeta.accept,
    },
    {
      onSubmit: async ({userID, file}) => {
        try {
          await ilvolunteer.uploadPhoto(axios, {userID, photo: file})
          // the player version of this has access to a `Player` object and mutates isPhotoLocked, like:
          //  `player.isPhotoLocked = true;`
          // but we don't have a user object here ... ?
          // It's not clear which version of a user object we'd get in the general case, do we always have isPhotoLocked ... ?
          // Caller will have to update "photolocked" locally as necessary, we give them (require that they provide) a hook to do so.
          onSuccess(userID);
          controller.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
    }
  })

  return controller;
}
