import { ExtractPropTypes, PropType } from "vue"
import { CompositeVolunteerRequirement, FamilySeasonCompetitionVolunteerRequirementsCheck, VolunteerRequirementKey, VolunteerDetails, Guid } from "src/interfaces/InleagueApiV1"
import { vReqT } from "src/helpers/utils"

export interface LiveVolunteerRequirementsCheck {
  satisfiesSomeVolunteerRequirement: boolean,
  // ordering of this array is the same as the volunteer requirements it's checking against
  // so this.requirements[N] is the "live check" agaiinst serverProvidedRequirements.volunteerRequirements[N]
  // boht contain a CompositeVolunteerRequirement, except that the meaning of the number in Record<RequirementsKey, number> is different:
  //   - in serverProvidedRequirements.volunteerRequirements[N][requirementkey] --> how many of `requirementkey` are required
  //   - in this.requirements[N].familyHas[requirementkey] --> how many of `requirementKey` the relevant family has
  requirements: {
    satisfiesThisVolunteerRequirement: boolean,
    familyHas: CompositeVolunteerRequirement
  }[]
}

export interface StaticVolunteerRequirementsCheck extends FamilySeasonCompetitionVolunteerRequirementsCheck {
  // the number of particular volunteer roles the relevant family contains,
  // but omitting the counts that "some user" already has
  // The values "some user" already has will be part of the selection-UI, and will be considered by taking into account what's in the form
  // The form should begin life populated with all volunteer codes "this user" has
  staticCountsOmittingTargetUser: {
    headCoach: number,
    asstCoach: number,
    referee: number,
    flex: number
  }
}

export function hasRequirementType(
  v: CompositeVolunteerRequirement,
  type: VolunteerRequirementKey
): boolean {
  //
  // If the type isn't ANY, but the composite requirement contains ANY, we pretend the type we're looking for does not exist
  // we assume the server is wrong, and we shouldn't have gotten into the state where we ask this question;
  // i.e. a composite requirement, if it contains {"*": <number>} should be exactly {"*": <number>}
  // We're attempting to avoid dealing with something like {"*": 3, "Head Coach": 2} -- "any 3 and 2 head coach", does the 2 head coach count to the any 3?
  // We just pretend we can't ask that question
  // But, a composite requirement like {"Head Coach": 1, "Referee": 1} is legitimate
  //
  if (
    type !== VolunteerRequirementKey.ANY &&
    containsRequirementType(v, VolunteerRequirementKey.ANY)
  ) {
    return false
  } else {
    return containsRequirementType(v, type)
  }

  function containsRequirementType(
    v: CompositeVolunteerRequirement,
    key: VolunteerRequirementKey
  ): boolean {
    return typeof v[key] === 'number'
  }
}

export function buildVolunteerCodeCountsOmittingTargetUser(
  volunteerRequirementsCheck: FamilySeasonCompetitionVolunteerRequirementsCheck,
  omit_userID: string
): StaticVolunteerRequirementsCheck['staticCountsOmittingTargetUser'] {
  let headCoach = 0
  let asstCoach = 0
  let referee = 0
  let flex = 0

  for (const userID of Object.keys(volunteerRequirementsCheck.codesByUser)) {
    if (userID === omit_userID) {
      continue
    } else {
      headCoach += volunteerRequirementsCheck.codesByUser[userID][
        VolunteerRequirementKey.HEAD_COACH
      ]
        ? 1
        : 0
      asstCoach += volunteerRequirementsCheck.codesByUser[userID][
        VolunteerRequirementKey.ASST_COACH
      ]
        ? 1
        : 0
      referee += volunteerRequirementsCheck.codesByUser[userID][
        VolunteerRequirementKey.REFEREE
      ]
        ? 1
        : 0
      flex += volunteerRequirementsCheck.codesByUser[userID].flex.length
    }
  }

  return {
    headCoach,
    asstCoach,
    referee,
    flex,
  }
}

export const CompetitionsVolunteerImplProps = {
  userID: vReqT<Guid>(),
  seasonUID: vReqT<Guid>(),
  playerID: vReqT<Guid>(),
  staticVolunteerRequirementsCheck: vReqT<StaticVolunteerRequirementsCheck>(),
  volunteerDetails: vReqT<VolunteerDetails>(),
}

export type CompetitionsVolunteerImplProps = ExtractPropTypes<typeof CompetitionsVolunteerImplProps>;
