import { PropType, computed, defineComponent, ref } from "vue";
import { FormKit } from "@formkit/vue"
import type { FormKitNode } from "@formkit/core"
import { vOptT } from "src/helpers/utils";

/**
 * see FormKit docs
 * TODO: do they offer this type as an import from somewhere?
 */
interface FormKitFile {
  name: string,
  file?: File,
}

export const SimpleFileUploadDialog = defineComponent({
  props: {
    /**
     * @typeparam T
     * fixme: instead of `any`, should be generic, via https://github.com/vuejs/core/pull/7963
     */
    loopThrough: {
      required: true,
      type: null as any as PropType</*T*/any>
    },
    maxSize: {
      required: true,
      type: Object as PropType<{bytes: number, humanReadable: string}>
    },
    /**
     * A comma-delimited list of file extensions WITH dots (e.g. ".jpg,.gif")
     */
    accept: {
      required: false,
      type: String
    },
    slot_aboveSubmitButton: vOptT<() => JSX.Element>(),
  },
  emits: {
    submit: (_: {entity: /*T*/ any, file: File}) => true
  },
  setup(props, {emit}) {
    /**
     * This is always an array, whether the input is a single or multiple file input,
     * but, we are a single-file input, so we will always have exactly zero-or-one element
     */
    const fk_file = ref<FormKitFile[]>([])

    /**
     * The user provided file, or undefined if there is no current selection
     */
    const file = computed<File | undefined>(() => {
      return fk_file.value.length === 1
        ? fk_file.value[0].file
        : undefined
    });

    /**
     * URL for <img> tag preview
     */
    const localFileURL = computed<string | undefined>(() => {
      return (file.value && /^image\//i.test(file.value.type))
        ? URL.createObjectURL(file.value)
        : undefined;
    })

    const handleSubmit = () => {
      if (!file.value) {
        // bug on our part
        throw Error("form submit, but no file");
      }
      emit("submit", {entity: props.loopThrough, file: file.value});
    }

    const fkv_maxFileSize = (node: FormKitNode) => {
      const VALID = true;
      const INVALID = false;
      const INDETERMINATE = VALID; // we're not sure, so consider it ok to prevent spurious errors

      if (node.value && Array.isArray(node.value)) {
        const filelist = node.value as FormKitFile[];
        if (filelist.length === 0) {
          return INDETERMINATE;
        }
        else if (filelist.length === 1) {
          const file = filelist[0].file;
          if (!file) {
            // shouldn't happen, but it is typed as optional, so ... maybe
            throw Error("Expected file to be truthy, but it was falsy.");
          }
          else {
            return file.size <= props.maxSize.bytes ? VALID : INVALID;
          }

        }
        else {
          // shouldn't happen
          throw Error(`Expected exactly 0 or 1 files in the file list, but got ${filelist.length}`);
        }
      }
      else {
        return INDETERMINATE;
      }
    }

    return () => (
      <div>
        <FormKit type="form" actions={false} onSubmit={handleSubmit}>
          <FormKit
            type="file"
            v-model={fk_file.value}
            outer-class="$reset"
            accept={props.accept}
            label="File"
            validation={[["required"], ["fkv_maxFileSize"]]}
            validationRules={{fkv_maxFileSize: fkv_maxFileSize as any}}
            validationMessages={{
              fkv_maxFileSize: "File exceeds size limit."
            }}
          />
          <div class="my-2 text-xs">Max size: {props.maxSize.humanReadable}</div>
          <div class="p-1 flex justify-center">
            <img src={localFileURL.value} width="100"/>
          </div>
          {props.slot_aboveSubmitButton?.()}
          <t-btn margin={false} type="submit">Submit</t-btn>
        </FormKit>
      </div>
    )
  }
})

export function formkitSingleFileFormRef() {
  /**
   * This is always an array, whether the input is a single or multiple file input,
   * but, we are a single-file input, so we will always have exactly zero-or-one element
   */
  const fk_file = ref<FormKitFile[]>([])

  /**
   * The user provided file, or undefined if there is no current selection
   */
  const file = computed<File | undefined>(() => {
    return fk_file.value.length === 1
      ? fk_file.value[0].file
      : undefined
  });

  return {
    /**
     * Use as v-model for file input
     */
    fk_file,
    /**
     * The standard browser File object extracted the formkit file ref,
     * or undefined if no file is selected
     */
    file
  }
}
