import { FormKit } from "@formkit/vue"
import dayjs from "dayjs"
import { GameForGameSchedulerView } from "src/composables/InleagueApiV1.GameScheduler"
import { ConflictAck, FormAugmentedDryRunResult } from "src/composables/InleagueApiV1.GameSchedulerXlsx"
import { vReqT, exhaustiveCaseGuard, UiOption } from "src/helpers/utils"
import { defineComponent } from "vue"
import { teamDesignationAndMaybeName } from "../calendar/GameScheduler.shared"

const gameTimeFormat = "MMM/DD/YYYY hh:mm a"

export const DryRunResults = defineComponent({
  props: {
    dryRunResults: vReqT<FormAugmentedDryRunResult[]>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="rounded-md border border-slate-100 shadow-md p-2">
          <table style="--fk-margin-outer: none;" class="w-full">
            <tr>
              <th class="text-left">{/*"line" number*/}</th>
              <th class="text-left">Program</th>
              <th class="text-left">Season</th>
              <th class="text-left">Division</th>
              <th class="text-left">Field</th>
              <th class="text-left">Game time</th>
              <th class="text-left">Teams</th>
            </tr>
            {
              props
                .dryRunResults
                .map((result, i, a) => {
                  const conflictAckRadioGroupName = `Conflict acknowledgement for line ${i + 1}`
                  const commonTdClasses = `${i % 2 ? "bg-gray-100" : ""} align-top p-1`
                  const isLast = i === a.length - 1;
                  return <>
                    <tr>
                      <td class={commonTdClasses}>
                        <div class="mx-1 px-1 border rounded-md flex justify-center bg-black text-white">{i + 1}</div>
                      </td>
                      <td class={commonTdClasses}>{result.ui.competition}</td>
                      <td class={commonTdClasses}>{result.ui.seasonName}</td>
                      <td class={commonTdClasses}>{result.ui.division}</td>
                      <td class={commonTdClasses}>{result.ui.field}</td>
                      <td class={commonTdClasses}>
                        <div>Start: {dayjs(result.ui.gameStart).format(gameTimeFormat)}</div>
                        <div>End: {dayjs(result.ui.gameEnd).format(gameTimeFormat)}</div>
                      </td>
                      <td class={commonTdClasses}>
                        <div>Home: {result.ui.homeTeamName || "N/A (TBD)"}</div>
                        <div>Visitor: {result.ui.visitorTeamName || "N/A (TBD)"}</div>
                      </td>
                    </tr>
                    <tr class={`${!isLast ? "border-b" : ""} ${commonTdClasses}`}>
                      <td colspan="999" class={commonTdClasses}>
                        <div class="p-1">
                          <div class="flex flex-col items-center">
                            {
                              result.conflicts.length === 0
                                ? <span>No conflicts</span>
                                : null
                            }
                            {
                              result.conflicts.map((conflict, i) => {
                                switch (conflict.type) {
                                  case "field-block":
                                    return <div class="p-1 rounded-md border border-red-400 bg-white my-1">
                                      <div>Field block</div>
                                      <div>{conflict.start} {conflict.end}</div>
                                    </div>
                                  case "game":
                                    return <div class="p-1 rounded-md border border-red-400 bg-white my-1">
                                      <div>Conflicting game</div>
                                      <div>
                                        <div>Other game start: {dayjs(conflict.start).format(gameTimeFormat)}</div>
                                        <div>Other game end: {dayjs(conflict.end).format(gameTimeFormat)}</div>
                                      </div>
                                    </div>
                                  case "intra-request":
                                    return <div class="p-1 rounded-md border border-red-400 bg-white my-1">
                                      <div>Conflicts with other request in this request</div>
                                      <div>See line {conflict.otherIndex}</div>
                                    </div>
                                  default: exhaustiveCaseGuard(conflict);
                                }
                              })
                            }
                          </div>
                          <div class="items-center flex gap-2" style="--fk-border: none; --fk-padding-fieldset: none;">
                            <FormKit
                              type="radio" name={conflictAckRadioGroupName}
                              options-class="flex gap-2"
                              options={result.conflicts.length === 0 ? conflictAckOptionsForNoConflictLine : conflictAckOptions}
                              v-model={result.conflictAck}
                              validation={[["required"]]}
                            />
                          </div>
                          {result.sheetRequestedDisregardConflicts
                            ? <div class="text-xs">Associated sheet row was marked 'disregard conflicts'</div>
                            : null
                          }
                        </div>
                      </td>
                    </tr>
                  </>
                })
            }
          </table>
        </div>
      )
    }
  }
})

export const CommittedResults = defineComponent({
  props: {
    committedResults: vReqT<GameForGameSchedulerView[]>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="rounded-md border border-slate-100 shadow-md p-2">
          <table style="--fk-margin-outer: none;" class="w-full">
            <tr>
              <th class="text-left">Game Num</th>
              <th class="text-left">Program</th>
              <th class="text-left">Season</th>
              <th class="text-left">Division</th>
              <th class="text-left">Field</th>
              <th class="text-left">Game time</th>
              <th class="text-left">Teams</th>
            </tr>
            {
              props
                .committedResults
                .map((result, i, a) => {
                  const bgClass = i % 2 ? "bg-gray-100" : "";
                  const isLast = i === a.length - 1;
                  return <>
                    <tr class={`${!isLast ? "border-b" : ""} ${bgClass}`}>
                      <td class={bgClass}>{result.gameNum}</td>
                      <td class={bgClass}>{result.competition}</td>
                      <td class={bgClass}>{result.seasonName}</td>
                      <td class={bgClass}>{result.division}</td>
                      <td class={bgClass}>{result.fieldName}</td>
                      <td class={bgClass}>
                        <div>Start: {dayjs(result.gameStart).format(gameTimeFormat)}</div>
                        <div>End: {dayjs(result.gameEnd).format(gameTimeFormat)}</div>
                      </td>
                      <td class={bgClass}>
                        <div>Home: {!result.homeTeam ? "N/A (TBD)" : teamDesignationAndMaybeName(result.homeTeam)}</div>
                        <div>Visitor: {!result.visitorTeam ? "N/A (TBD)" : teamDesignationAndMaybeName(result.visitorTeam)}</div>
                      </td>
                    </tr>
                  </>
                })
            }
          </table>
        </div>
      )
    }
  }
})

const conflictAckOptions : UiOption<ConflictAck>[] = [
  {value: "drop-request", label: "Drop request"},
  {value: "ignore-and-create", label: "Create anyway"}
];

// in this case it's not really "conflict ack" but rather "maybe you want to drop this, like all the others?"
const conflictAckOptionsForNoConflictLine : UiOption<ConflictAck>[] = [
  {value: "drop-request", label: "Drop request"},
  {value: "ignore-and-create", label: "Create"}
];
