<template lang="pug">
div(data-test="PlayerData")
  AutoModal(
    data-test="PlayerPhotoUploadModalController"
    :controller="playerPhotoUploadModalController"
  )
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200(
            v-if='Object.keys(playerData).length > 0'
          )
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(
                  colspan='4'
                )
                  div Player Data Editor
            .divide-y.divide-gray-200.grid.grid-cols-3(class='lg:grid-cols-6')
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | First Name:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2(data-test="playerFirstName")
                div(v-if='!lockedFields["playerFirstName"] && granularEditPermissions["playerData/firstName"]')
                  FormKit(
                    data-test="mutable"
                    v-model='playerData.playerFirstName',
                    name='firstName',
                    validations='required',
                    innerClass='bg-white'
                  )
                div(v-else data-test="immutable") {{ playerData.playerFirstName }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Nick Name:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2(data-test="playerNickName")
                div(v-if='!lockedFields["playerNickName"] && granularEditPermissions["playerData/nickName"]')
                  FormKit(
                    data-test="mutable"
                    v-model='playerData.playerNickName',
                    name='nick name',
                    :validations="[[`required`], [`length`, /*min*/2, /*max*/undefined], [`matches`, [/^[a-zA-Z\s]$/]]]",
                    :validation-messages="{matches: `can contain only letters and spaces`}"
                    innerClass='bg-white'
                  )
                div(v-else data-test="immutable") {{ playerData.playerNickName }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Last Name:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if='!lockedFields["playerLastName"] && granularEditPermissions["playerData/lastName"]')
                  FormKit(
                    v-model='playerData.playerLastName',
                    name='lastName',
                    validations='required',
                    innerClass='bg-white'
                  )
                div(v-else) {{ playerData.playerLastName }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Birthdate:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if='!lockedFields["playerBirthDate"] && granularEditPermissions["playerData/birthDate"]')
                  FormKit(
                    v-model='playerData.playerBirthDate',
                    name='dob',
                    validations='required',
                    type='date',
                    innerClass='bg-white'
                  )
                div(v-else) {{ dayJSDate(playerData.playerBirthDate) }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Gender:
              .px-6.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="granularEditPermissions['playerData/gender']")
                  FormKit.inline(
                    v-model='playerData.playerGender',
                    name='gender',
                    validations='required',
                    type='radio',
                    :options='{ B: "Male", G: "Female" }',
                    :row='true',
                    fieldsetClass='bg-white'
                    options-class='flex pl-4',
                    inner-class='inline pl-4',
                    wrapper-class='pt-2',
                  )
                div(v-else) {{ playerData.playerGender }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | AYSO ID:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                | {{ playerData.stackSID }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Contact Name:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                | {{  playerData.contactName }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Contact Phone:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                | {{  maybePrettifyUSPhoneNumber( playerData.contactPhone ) }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right(
                 v-if='playerData.contact2Name.length > 0'
                )
                | 2nd Contact Name:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2( v-if='playerData.contact2Name.length > 0' )
                | {{  playerData.contact2Name }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right( v-if='playerData.contact2Name.length > 0' )
                | 2nd Contact Phone:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2( v-if='playerData.contact2Name.length > 0' )
                | {{  maybePrettifyUSPhoneNumber( playerData.contact2Phone ) }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Created On:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                | {{ dayJSDate(playerData.dateCreated) }}
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right(
                v-if='granularEditPermissions["playerData/permLeagueComment"]'
              )
                | League Comment:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2(
                v-if='granularEditPermissions["playerData/permLeagueComment"]'
              )
                FormKit(
                  type="textarea"
                  v-model='playerData.permLeagueComment',
                  name='permLeagueComment',
                  validations='required',
                  :noMargins='true',
                  innerClass='bg-white'
                )
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right(
                v-if='authService(userRoles, "Registrar", "Webmaster") || userIsParentOfTargetChild'
              )
                | Birth Certificate:
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2(
                v-if='authService(userRoles, "Registrar", "Webmaster", "BirthCertAdmin") || userIsParentOfTargetChild'
              )
                BirthCertificateElement(
                  :playerData="playerData",
                  :userIsSuperUser='authService(userRoles, "Registrar", "Webmaster", "BirthCertAdmin")'
                  :userIsParentOfTargetChild="userIsParentOfTargetChild"
                )
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                | Last Modified:
              a.px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2.cursor-pointer(
                v-if='playerData.lastModifiedByUser',
                :href='`mailto:${playerData.lastModifiedByUser.email}`'
              )
                | {{ playerData.lastModifiedByUser.firstName }} {{ playerData.lastModifiedByUser.lastName }}
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2.cursor-pointer(
                v-else
              )
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right(
                v-if='granularEditPermissions["playerData/blockFromRegistration"]'
              )
                | Block from Registration:
              .px-6.text-sm.text-gray-500.bg-stone-200.col-span-2.border-black-2(
                v-if='granularEditPermissions["playerData/blockFromRegistration"]'
              )
                FormKit.inline(
                  v-model='playerData.blockFromRegistration',
                  name='blockRegistration',
                  validations='required',
                  type='radio',
                  :options='{ "0": "No", "1": "Yes" }',
                  :row='true',
                  fieldsetClass='bg-white',
                  options-class='flex pl-4',
                  inner-class='inline pl-4',
                  wrapper-class='pt-2',
                )

              //-
              //- cell
              //-
              template(v-if="shouldOfferPlayerPhotoUpload.hasPerms")
                .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                  | Player photo
                .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                  template(v-if="shouldOfferPlayerPhotoUpload.shouldOffer")
                    t-btn(:margin="false" data-test="playerPhotoUploadButton" @click="playerPhotoUploadModalController.open(playerData)") Submit photo
                  template(v-else-if="playerData.stackSID.length === 0" )
                    div (Registration &amp; AYSO ID Req'd)
                  template(v-else)
                    div {{ shouldOfferPlayerPhotoUpload.msgIfNoOffer }}
                  img( v-if="playerData.photoURL.length > 0" :src="playerData.photoURL" alt="Official Player Photo" height=120 width=100  )


              //-
              //- grid cell placeholder for an even number of cells (fixes -- "why is the bottom right cell shaded differently?")
              //-
              .px-6.py-4.text-sm.font-medium.text-gray-900.text-right
                //- nothing
              .px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2(data-test="playerFirstName")
                //- nothing
</template>


<script lang="tsx">
import { PlayerDetailsI } from 'src/interfaces/Store/registration'
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import authService from 'src/helpers/authService'

import { UserData } from 'src/interfaces/Store/user'
import { dayJSDate } from 'src/helpers/formatDate'
import { maybePrettifyUSPhoneNumber } from 'src/helpers/utils'

import { emitsDef } from "./PlayerData.ilx"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard, useIziToast, vReqT } from 'src/helpers/utils'
import { defaultPlayerPhotoUploadModalController } from 'src/components/UserInterface/Modal.photoUpload.player'
import { axiosAuthBackgroundInstance, AxiosErrorWrapper } from 'src/boot/axios'
import { AutoModal, DefaultModalController, DefaultTinySoccerballBusyOverlay } from "src/components/UserInterface/Modal"
import { User } from 'src/store/User'
import { SimpleFileUploadDialog } from 'src/components/UserInterface/Modal.photoUpload.common'
import { addBirthCertificateToChild, deleteBirthCertificate, validateBirthCertificate } from 'src/composables/InLeagueApiV1.Child'
import { Client } from 'src/store/Client'
import { Guid } from 'src/interfaces/InleagueApiV1'

const BirthCertificateElement = defineComponent({
  props: {
    playerData: vReqT<PlayerDetailsI>(),
    userIsSuperUser: vReqT<boolean>(),
    userIsParentOfTargetChild: vReqT<boolean>(),
  },
  setup(props) {
    // pattern match on 2 bools
    enum QuadState {
      notValidNoFile,
      notValidHasFile,
      isValidNoFile,
      isValidHasFile
    }

    enum DisplayMode {
      /**
       * Details and instructions on doing a thing
       */
      prompt,
      /**
       * Verify that you really want to do the thing
       */
      confirm
    }

    const birthCertFileURL = (childID: Guid) => `https://${Client.value.instanceConfig.appdomain}/api/v1/child/${childID}/birthCertificate`;

    const state = computed<QuadState>(() => {
      const isValid = !!props.playerData.birthCertificate
      const hasFile = !!props.playerData.hasSomeBirthCertificateFile

      // 0
      if (!isValid && !hasFile) {
        return QuadState.notValidNoFile
      }
      // 1
      else if (!isValid && hasFile) {
        return QuadState.notValidHasFile
      }
      // 2
      else if (isValid && !hasFile) {
        return QuadState.isValidNoFile
      }
      // 3
      else if (isValid && hasFile) {
        return QuadState.isValidHasFile
      }
      else {
        throw Error("unreachable");
      }
    })

    const uploadController = (() => {
      const busy = ref(false);
      const onCloseCB = (close: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          close();
        }
      }

      const doUpload = async (file: Blob) : Promise<void> => {
        try {
          try {
            busy.value = true;
            await addBirthCertificateToChild(axiosAuthBackgroundInstance, {childID: props.playerData.childID, data: file})
          }
          finally {
            busy.value = false;
          }

          props.playerData.hasSomeBirthCertificateFile = true;

          uploadController.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      const result = DefaultModalController<{isReplacingExisting: boolean, notes: string}>({
        title: (v) => (
          <>
            <div>Upload {v?.isReplacingExisting ? " (replace existing)" : ""}</div>
            <div class="border-b border-gray-200"/>
          </>
        ),
        content: (v) => {
          return (
            <div class="my-2">
              <SimpleFileUploadDialog
                loopThrough={null}
                maxSize={{bytes: 1024 * 1024 * 10, humanReadable: "10MB"}}
                accept={".pdf,.jpg,.png"}
                onSubmit={async ({file}) => doUpload(file)}
                slot_aboveSubmitButton={() => <div class="mb-2 text-sm">{v?.notes}</div>}
              />
              {
                busy.value
                  ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                  : null
              }
            </div>
          )
        }
      }, {onCloseCB});

      return reactive(result);
    })()

    const validationController = (() => {
      const displayMode = ref(DisplayMode.prompt);

      const busy = ref(false);
      const onCloseCB = (close: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          close();
        }
      }
      const onOpenCB = () : void => {
        displayMode.value = DisplayMode.prompt
      }

      const doValidate = async () : Promise<void> => {
        try {
          try {
            busy.value = true;
            await validateBirthCertificate(axiosAuthBackgroundInstance, {childID: props.playerData.childID});
          }
          finally {
            busy.value = false;
          }

          props.playerData.birthCertificate = true;
          validationController.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      const result = DefaultModalController<void>({
        title: () => {
          if (displayMode.value === DisplayMode.prompt) {
            return (<>
              <div>Validate birth certificate</div>
              <div class="border-b border-gray-200"/>
            </>)
          }
          else if (displayMode.value === DisplayMode.confirm) {
            return (<>
              <div>Confirm validate</div>
              <div class="border-b border-gray-200"/>
            </>)
          }
          else {
            exhaustiveCaseGuard(displayMode.value);
          }
        },
        content: () => {
          if (displayMode.value === DisplayMode.prompt) {
            return (
              <div>
                <div class="flex flex-col items-center justify-center my-6">
                  <a href={birthCertFileURL(props.playerData.childID)} target="_blank" class="il-link">View current file</a>
                  <div class="text-xs">Opens in another window</div>
                </div>
                <div class="flex gap-2 mt-6">
                  <t-btn margin={false} onClick={() => { displayMode.value = DisplayMode.confirm }}>Validate</t-btn>
                  <t-btn margin={false} color="red" onClick={() => validationController.close()}>
                    Cancel
                  </t-btn>
                </div>
              </div>
            )
          }
          else if (displayMode.value === DisplayMode.confirm) {
            return (
              <div class="mt-2">
                <div class="flex justify-center gap-4">
                  <t-btn margin={false} onClick={() => doValidate()}>Validate</t-btn>
                  <t-btn margin={false} color="red" onClick={() => { displayMode.value = DisplayMode.prompt; }}>Cancel</t-btn>
                </div>
                {
                  busy.value
                    ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                    : null
                }
              </div>
            )
          }
          else {
            exhaustiveCaseGuard(displayMode.value)
          }
        }
      }, {onCloseCB, onOpenCB});

      return reactive(result);
    })()

    const deleteController = (() => {
      const busy = ref(false);
      const displayMode = ref(DisplayMode.prompt);
      const onCloseCB = (close: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          close();
        }
      }
      const onOpenCB = () => {
        displayMode.value = DisplayMode.prompt
      }

      const doDelete = async () : Promise<void> => {
        try {
          try {
            busy.value = true;
            await deleteBirthCertificate(axiosAuthBackgroundInstance, {childID: props.playerData.childID});
          }
          finally {
            busy.value = false;
          }

          props.playerData.birthCertificate = false;
          props.playerData.hasSomeBirthCertificateFile = false;

          deleteController.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      const c = DefaultModalController<void>({
        title: () => {
          if (displayMode.value === DisplayMode.prompt) {
            return <>
              <div>Delete birth certificate</div>
              <div class="border-b border-gray-200"/>
            </>
          }
          else if (displayMode.value === DisplayMode.confirm) {
            return <>
              <div>Confirm delete</div>
              <div class="border-b border-gray-200"/>
            </>
          }
          else {
            exhaustiveCaseGuard(displayMode.value)
          }
        },
        content: () => {
          if (displayMode.value === DisplayMode.prompt) {
            return (
              <div class="mt-2">
                <div>
                  {
                    state.value === QuadState.notValidNoFile
                      ? (() => { throw Error("illegal state") })()
                      : state.value === QuadState.notValidHasFile
                      ? (
                        <div>
                          <div>This will delete the current file.</div>
                          <div class="text-xs">
                            Note that this file is not yet validated, so the player's "has validated birth certificate flag" will remain false after this action.
                          </div>
                        </div>
                      )
                      : state.value === QuadState.isValidNoFile
                      ? (
                        <div>
                          <div>No file exists, but this will clear this player's "has validated birth certificate" flag.</div>
                        </div>
                      )
                      : state.value === QuadState.isValidHasFile
                      ? (
                        <div>
                          <div>This will delete the current file and clear this player's "has validated birth certificate" flag.</div>
                        </div>
                      )
                      : exhaustiveCaseGuard(state.value)
                  }
                </div>
                <div class="flex gap-2 mt-6">
                  <t-btn margin={false} onClick={() => { displayMode.value = DisplayMode.confirm }}>Delete</t-btn>
                  <t-btn margin={false} color="red" onClick={() => deleteController.close()}>
                    Cancel
                  </t-btn>
                </div>
              </div>
            )
          }
          else if (displayMode.value === DisplayMode.confirm) {
            return (
              <div class="mt-2">
                <div class="flex justify-center gap-4">
                  <t-btn margin={false} onClick={() => doDelete()}>Delete</t-btn>
                  <t-btn margin={false} color="red" onClick={() => { displayMode.value = DisplayMode.prompt; }}>Cancel</t-btn>
                </div>
                {
                  busy.value
                    ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                    : null
                }
              </div>
            )
          }
          else {
            exhaustiveCaseGuard(displayMode.value)
          }
        }
      }, {onCloseCB, onOpenCB});

      return reactive(c);
    })()

    return () => {
      if (!props.userIsSuperUser && !props.userIsParentOfTargetChild) {
        // caller shouldn't have called
        return null;
      }

      return (
        <div>
          <AutoModal controller={uploadController}/>
          <AutoModal controller={validationController}/>
          <AutoModal controller={deleteController}/>
          {
            (() => {
              switch (state.value) {
                case QuadState.notValidNoFile: {
                  return (
                    <div>
                      <div>No birth certificate on file</div>
                      <div class="my-1">
                        <t-btn type="button" onClick={() => uploadController.open({isReplacingExisting: false, notes: ""})} margin={false}>Upload</t-btn>
                      </div>
                    </div>
                  )
                }
                case QuadState.notValidHasFile: {
                  return (
                    <div>
                      <div>A file already exists, pending validation.</div>
                      <div>
                        <a href={birthCertFileURL(props.playerData.childID)} class="il-link" target="_blank">View current file</a>
                      </div>
                      <div class="flex gap-2 mt-2 flex-wrap">
                        {
                          props.userIsSuperUser
                            ? <t-btn type="button" margin={false} onClick={() => validationController.open()}>Validate</t-btn>
                            : null
                        }
                        <t-btn type="button" margin={false} onClick={() => uploadController.open({isReplacingExisting: true, notes: ""})}>Replace</t-btn>
                        <t-btn type="button" margin={false} onClick={() => deleteController.open()} color="red">Delete</t-btn>
                      </div>
                    </div>
                  )
                }
                case QuadState.isValidNoFile: {
                  const msg = "This player's birth certificate is validated, but the file has since been deleted."
                  const msgElaborated = `${msg} Uploading a new file will leave the player's "has validated birth certificate file" flag set to its current value ("true").`
                  return (
                    <div>
                      <div>Validated, but not on file.</div>
                      <div class="text-xs my-2">{msg}</div>
                      {
                        props.userIsSuperUser
                          ? (
                            <t-btn
                              type="button" margin={false}
                              onClick={() => uploadController.open({isReplacingExisting: false, notes: msgElaborated})}
                            >
                              Upload
                            </t-btn>
                          )
                          : null
                      }
                    </div>
                  )
                }
                case QuadState.isValidHasFile: {
                  return (
                    <div>
                      <div class="text-center">Validated birth certificate on file.</div>
                      <div class="flex justify-evenly items-center mt-2">
                        <div>
                          <a href={birthCertFileURL(props.playerData.childID)} target="_blank">
                            <t-btn margin={false} type="button">View</t-btn>
                          </a>
                        </div>
                        {
                          props.userIsSuperUser
                            ? <t-btn type="button" margin={false} onClick={() => deleteController.open()} color="red">Delete</t-btn>
                            : null
                        }
                      </div>
                    </div>
                  )
                }
                default: exhaustiveCaseGuard(state.value)
              }
            })()
          }
        </div>
      )
    }
  }
})

export default defineComponent({
  components: {
    AutoModal,
    BirthCertificateElement
  },
  props: {
    playerData: {
      required: true,
      type: Object as PropType<PlayerDetailsI>,
    },
    granularEditPermissions: vReqT<Record<string, boolean>>(),
  },
  emits: emitsDef(),
  setup(props, {emit}) {

    const iziToast = useIziToast();

    const lockedFields = computed(() => {
      const isStackSportsLocked : boolean = !!props.playerData.stackRecordKey;
      const isLockedDueToHavingSomeCompleteProgramRegistration : boolean = props.playerData.hasSomeCompleteProgramRegistration.value;

      const locked = authService(User.value.roles, "registrar")
        ? isStackSportsLocked // user is registrar, fields can still be locked but there are less restrictions
        : (isStackSportsLocked || isLockedDueToHavingSomeCompleteProgramRegistration); // a few more restrictions for normal users

      return {
        playerFirstName: locked,
        playerNickName: false, // never locked
        playerLastName: locked,
        playerBirthDate: locked
      } as const satisfies {[P in keyof iltypes.Child]+?: boolean}
    })

    const userRoles = computed(() => {
      return User.value.roles
    })

    const playerPhotoUploadModalController = reactive(
      defaultPlayerPhotoUploadModalController(
        axiosAuthBackgroundInstance,
        () => iziToast.success({message: "Photo uploaded"})
      )
    );

    const userIsParentOfTargetChild = computed<boolean>(() => User.userData?.belongingChildrenIDs.includes(props.playerData.childID) ?? false);

    const shouldOfferPlayerPhotoUpload = computed<{hasPerms: boolean, shouldOffer: boolean, msgIfNoOffer: string}>(() => {
      const hasSomePermission = authService(User.value.roles, "playerAdmin", "registrar");
      const isPhotoLocked = props.playerData.isPhotoLocked === 1 || props.playerData.isPhotoLocked === "1" || props.playerData.isPhotoLocked === true;
      const hasSomeStackKey = !!props.playerData.stackRecordKey;
      const shouldOffer = hasSomePermission && !isPhotoLocked && hasSomeStackKey
      return {
        hasPerms: hasSomePermission,
        shouldOffer,
        msgIfNoOffer: shouldOffer
          ? ""
          : isPhotoLocked
          ? "(Locked by AYSO)"
          : ""
      }
    })

    return {
      authService,
      userRoles,
      dayJSDate,
      maybePrettifyUSPhoneNumber,
      lockedFields,
      playerPhotoUploadModalController,
      shouldOfferPlayerPhotoUpload,
      userIsParentOfTargetChild,
    }
  },
})
</script>
