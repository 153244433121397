import { CSSProperties } from "vue";

export type {
  CalendarElementStyle
}

export {
  calendarElementStylingByDivision,
  calendarElementStylingDefault,
  randomCssColors,
}

interface CalendarElementStyle {
  title: CSSProperties,
  body: CSSProperties,
}

const calendarElementStylingDefault : CalendarElementStyle = {
  title: {backgroundColor: "darkblue", color: "white"},
  body:  {backgroundColor: "blue", color: "white"},
}

const calendarElementStylingByDivision : Record<string, CalendarElementStyle> = {
  "B6": {
    title: {backgroundColor: "#9CC", color: "black" },
    body: { backgroundColor: "#9CF", color: "black"},
  },
  "G6": {
    title: {backgroundColor: "#F9F", color: "black" },
    body: { backgroundColor: "#FCF", color: "black" },
  },
  "C6": {
    title: {backgroundColor: "#9CC", color: "black" },
    body: { backgroundColor: "#9CF", color: "black"},
  },
  "B8": {
    title: {backgroundColor: "#000", color: "white" },
    body: { backgroundColor: "#666", color: "white" },
  },
  "G8": {
    title: {backgroundColor: "#90F", color: "white" },
    body: { backgroundColor: "#C58AFF", color: "white" },
  },
  "C8": {
    title: {backgroundColor: "#000", color: "white" },
    body: { backgroundColor: "#666", color: "white" },
  },
  "G10": {
    title: {backgroundColor: "#6F0000", color: "white" },
    body: { backgroundColor: "#C28585", color: "white" },
  },
  "B10": {
    title: {backgroundColor: "#006432", color: "white" },
    body: {backgroundColor: "#0A5", color: "white"},
  },
  "C10": {
    title: {backgroundColor: "#006432", color: "white" },
    body: {backgroundColor: "#0A5", color: "white"},
  },
  "B12": {
    title: {backgroundColor: "#06F", color: "white" },
    body: {backgroundColor: "#09F", color: "white"},
  },
  "C12": {
    title: {backgroundColor: "#06F", color: "white" },
    body: {backgroundColor: "#09F", color: "white"},
  },
  "G12": {
    title: {backgroundColor: "#63F", color: "white" },
    body: { backgroundColor: "#96F", color: "white" }
  },
  "B14": {
    title: {backgroundColor: "#039", color: "white" },
    body: {backgroundColor: "#06F", color: "white"}
  },
  "C14": {
    title: {backgroundColor: "#039", color: "white" },
    body: {backgroundColor: "#06F", color: "white"},
  },
  "B16": {
    title: {backgroundColor: "#006699", color: "white" },
    body: { backgroundColor: "#00FFCC", color: "black"},
  },
  "G16": {
    title: {backgroundColor: "#990000", color: "white" },
    body: { backgroundColor: "#CC6666", color: "white" }
  },
  "C16": {
    title: {backgroundColor: "#006699", color: "white" },
    body: { backgroundColor: "#00FFCC", color: "black"},
  },
  "B19": {
    title: {backgroundColor: "#330066", color: "white" },
    body: { backgroundColor: "#3366FF", color: "white"},
  },
  "G19": {
    title: {backgroundColor: "#990099", color: "white" },
    body: { backgroundColor: "#FF33CC", color: "black" },
  },
  "C19": {
    title: {backgroundColor: "#330066", color: "white" },
    body: { backgroundColor: "#3366FF", color: "white"},
  },
}

/**
 * 'random' element colors; currently used for round colorings.
 */
function randomCssColors() : CSSProperties[] {
  return [
    {backgroundColor: "#ffbe02", color: "black"},
    {backgroundColor: "#006cb8", color: "white"},
    {backgroundColor: "#9c983a", color: "black"},
    {backgroundColor: "#a0006e", color: "white"},
    {backgroundColor: "#f68f4b", color: "black"},
    {backgroundColor: "#77c695", color: "black"},
    {backgroundColor: "#ff82b4", color: "black"},
    {backgroundColor: "#d282be", color: "black"},
    {backgroundColor: "#cec92a", color: "black"},
    {backgroundColor: "#dc9609", color: "black"},
    {backgroundColor: "#5a230a", color: "white"},
    {backgroundColor: "#00643c", color: "white"},
    {backgroundColor: "#82c8e6", color: "black"},
    {backgroundColor: "#62259d", color: "black"},
    {backgroundColor: "#a50034", color: "black"},
    {backgroundColor: "#00a092", color: "white"},
    {backgroundColor: "#ed1b2a", color: "black"},
    {backgroundColor: "#3c91dc", color: "black"},
    {backgroundColor: "#96be05", color: "black"},
    {backgroundColor: "#f59c83", color: "black"},
  ]
}
