import { ExtractEmitsHandlers, ExtractOnEmitsHandlers } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue"

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

export const propsDef = {
  events: {
    required: true,
    type: Array as PropType<ilapi.event.Event[]>
  },
  /**
   * A user is coach for an event if that user is a coach for that event's (seasonUID, teamID).
   * An event does not require a teamID (as in, may not be a "team event"), in which case no user is coach for such an event.
   */
  currentUserIsCoachForEvent: {
    required: true,
    type: Object as PropType<{[eventID: iltypes.Guid]: boolean}>
  }
} as const;

export const emitsDef = {
  requestSignup: (eventID: iltypes.Guid) => true,
  doDelete: (eventID: iltypes.Guid) => true,
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;
export type OnEmits = ExtractOnEmitsHandlers<typeof emitsDef>;

export function isWaitlistOnly(event: Pick<ilapi.event.Event, "maxCount" | "signedUpCount">) : boolean {
  if (event.maxCount === null || event.maxCount /*not strict*/ == 0) {
    return false;
  }
  return event.signedUpCount >= event.maxCount;
}
