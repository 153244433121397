import { ExtractEmitsHandlers } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, ExtractPropTypes, PropType, ref, watch } from "vue";
import * as ilapi from "src/composables/InleagueApiV1"

import { Modal, Slots } from "src/components/UserInterface/Modal"
import { axiosInstance } from "src/boot/axios";

import dayjs from "dayjs";
import * as EventuallyPinia from "src/store/EventuallyPinia"
import { User } from "src/store/User";
import * as ClearOnLogout from "src/store/ClearOnLogout"

const propsDef = {
  isOpen: {
    required: true,
    type: Boolean
  },
  eventSignup: {
    required: true,
    // should be toggled from null<->non-null at the appropriate points in the lifecycle, with respect to `isOpen`
    type: null as any as PropType<ilapi.event.EventSignup | null>
  }
} as const;
export type Props = ExtractPropTypes<typeof propsDef>;

const emitsDef = {
  doMove: (_: {eventSignup: ilapi.event.EventSignup, freshEventID: iltypes.Guid, comments: string}) => true,
  close: () => true,
}
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export const MoveEventSignupModal = defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const slots : Slots = {
        title: () => (
          <>
            <div>Move event signup</div>
            <div class="border-b border-slate-300 mb-2" />
          </>
        ),
        content: () => props.eventSignup
          ? <MoveEventSignup
            onSubmit={payload => emit("doMove", {
              // definitely truthy here, otherwise we would have been unmounted
              eventSignup: props.eventSignup!,
              freshEventID: payload.eventID,
              comments: payload.comments
            })}
            onCancel={() => emit('close')}
            eventSignup={props.eventSignup} />
          : <span/>
    }

    return () => (
        <Modal
          data-test="MoveSignupModal"
          isOpen={props.isOpen}
          onClose={() => emit("close")}
        >
          {{...slots}}
        </Modal>
    )
  }
})

const MoveEventSignupResolved = defineComponent({
  props: {
    eventSignup: {
      required: true,
      type: Object as PropType<ilapi.event.EventSignup>
    },
    candidateEvents: {
      required: true,
      type: Array as PropType<ilapi.event.Event[]>
    },
  },
  emits: {
    submit: (_: {eventID: iltypes.Guid, comments: string}) => true,
    cancel: () => true
  },
  setup(props, {emit}) {
    const selectedEventID = ref("");
    const comments = ref("");
    const MAX_COMMENT_LEN = 500;
    const commentRemaining = computed(() => `${comments.value.length}/${MAX_COMMENT_LEN}`)

    const eventOptions = computed<JSX.Element[]>(() => {
      const options = props.candidateEvents
        .filter(event => event.eventID !== props.eventSignup.eventID)
        .sort((l,r) => l.eventName < r.eventName ? -1 : 1)
        .map(event => {
          return <option value={event.eventID} key={event.eventID}>{event.eventName}</option>
        });

        options.unshift(<option value="" key="NIL" disabled>select an event</option>)

        return options;
    })

    const submit = () => emit("submit", {eventID: selectedEventID.value, comments: comments.value});
    const cancel = () => emit("cancel");

    return () => (
      <div>
        <div>Choose an event to move this signup to:</div>
        <div>
          <select v-model={selectedEventID.value} class="w-full mb-2" data-test="eventOptions">
            {eventOptions.value}
          </select>
        </div>
        <div>
          <div class="text-sm font-medium">Comments:</div>
          <textarea maxlength={500} data-test="comments" v-model={comments.value} class="w-full"/>
          <div class="flex justify-end text-xs">{commentRemaining.value}</div>
        </div>
        <div class="my-1 text-xs">Note: No monetary transactions will be performed as a result of this action.</div>
        <div class="flex justify-between mt-1">
          <t-btn color="red" onClick={() => cancel()}>Cancel</t-btn>
          <t-btn
            type="button"
            onClick={() => submit()}
            disable={selectedEventID.value === ""}
            class={selectedEventID.value === "" ? 'bg-gray-300' : ''}
            data-test="submit"
          >Submit</t-btn>
        </div>
      </div>
    )
  }
})

const candidateEventStore = (() => {
  const cached = ref<{cachedForUserID: iltypes.Guid, events: ilapi.event.Event[]} | null>(null);

  const doGet = async (userID: iltypes.Guid) => await ilapi.event.findEligibleEventsForUser(
    axiosInstance, {
      userID: userID,
      endingOnOrAfter: dayjs().subtract(1, "day").toISOString()
    }
  );

  const getCandidateEventsVisibleToUser = async (userID: iltypes.Guid) => {
    if (cached.value?.cachedForUserID === userID) {
      return cached.value.events;
    }
    else {
      const fresh = await doGet(userID);
      cached.value = {
        cachedForUserID: userID,
        events: fresh
      }
      return cached.value.events;
    }
  }

  return {
    clear: () => { cached.value = null },
    getCandidateEventsVisibleToUser,
  }
})();

ClearOnLogout.register(candidateEventStore);

const MoveEventSignup = defineComponent({
  props: {
    eventSignup: {
      required: true,
      type: Object as PropType<ilapi.event.EventSignup>
    }
  },
  emits: {
    submit: (_: {eventID: iltypes.Guid, comments: string}) => true,
    cancel: () => true,
  },
  setup(props, {emit}) {

    const candidateEvents = ref<ilapi.event.Event[] | null>(null);

    watch(() => [User.value.userID, props.eventSignup.eventSignupID], async () => {
      if (User.value.userID) {
        const events = await candidateEventStore.getCandidateEventsVisibleToUser(User.value.userID);
        candidateEvents.value = events;
      }
      else {
        // got logged out, maybe from a 401
        candidateEvents.value = [];
      }
    }, {immediate:true});

    return () => (
      candidateEvents.value
        ? <MoveEventSignupResolved
          eventSignup={props.eventSignup}
          candidateEvents={candidateEvents.value}
          onSubmit={payload => emit("submit", payload)}
          onCancel={() => emit("cancel")}
        />
        : <div>Loading events...</div>
    )
  }
})
