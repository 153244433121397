<template lang="pug">
.text-lg.overflow-auto
  .grid.grid-cols-10.my-2.text-gray-700
    .py-2.pl-4.font-medium Division
    .py-2.font-medium Gender
    .py-2.font-medium Age Cutoff
    .py-2.font-medium.col-span-1(class='pl-0.5') Force Co-Ed
    .py-2.font-medium.col-span-2 Division Max
      .font-normal.text-sm.italic Leave Blank for No Max
    .py-2.font-medium.col-span-2 Close Action
    .py-2.font-medium.col-span-2 Is Active
  div(v-for='(division, index) in divisions')
    DivisionSetup(
      :key="division.compSeasonDivUID"
      :division='division',
      :competitionUID='competitionUID',
      :seasonUID='seasonUID',
      :class='{ "bg-gray-200": index % 2 === 0 }'
    )
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { defineComponent, onMounted, ref, watch } from 'vue'
import DivisionSetup from 'src/components/Admin/SeasonCompetitions/DivisionSetup.vue'
import * as ilapi from "src/composables/InleagueApiV1"
import { CompetitionSeasonDivision } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  components: {
    DivisionSetup,
  },
  props: {
    competitionUID: {
      type: String,
      required: true,
    },
    seasonUID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const competition = ref('')
    const season = ref('')

    const divisions = ref<CompetitionSeasonDivision[]>([])

    const prepareDivisions = (divs: CompetitionSeasonDivision[]) => {
      for (const div of divs) {
        // mostly for ui purposes, 9999 is a sentinel value for "no max"
        // explicit paranoiac check for both string/number for untyped divs[i].divMax
        if (div.divMax === 9999 || div.divMax === '9999') {
          div.divMax = ''
        }
      }
      return divs
    }

    const initValues = async () => {
      const competitionSeasonDivisions = await ilapi.getCompetitionSeasonDivisions(axiosInstance, {competitionUID: props.competitionUID, seasonUID: props.seasonUID});
      divisions.value = prepareDivisions(competitionSeasonDivisions);
    }

    watch(
      () => [props.competitionUID, props.seasonUID],
      async () => {
        await initValues()
      }
    )

    onMounted(async () => {
      await initValues()
    })

    return {
      competition,
      season,
      divisions,
    }
  },
})
</script>

