import axios from "axios"
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, ref, watch } from "vue"

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { axiosAuthBackgroundInstance, axiosBackgroundInstance, axiosInstance, axiosNoAuthInstance, defaultSetGlobalSingletonLoadingStateFlagInterceptors, FALLBACK_ERROR_MESSAGE, freshAxiosInstance } from "src/boot/axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"

import { exhaustiveCaseGuard, ExtractEmitsHandlers, useIziToast } from "src/helpers/utils";

import { Login_MfaInitFlow } from "src/store/User";
import { FormKit } from "@formkit/vue";
import { mfaTypeDisplaySort } from "./Mfa.route";
import { isInleagueApiError2 } from "src/composables/InleagueApiV1";
import { MfaDetails } from "src/composables/InleagueApiV1.Authenticate";
import { SorryBadPhoneNumber, maskedLastTwoSmsPhoneNumber, withGlobalSpinner } from "./MfaCommon";

import { SoccerBall } from "../SVGs";
import { Client } from "src/store/Client";

/**
 * link to play store for the Google Authenticator app, the preferred TOTP authenticator app.
 */
const GOOGLE_AUTHENTICATOR_SUGGESTED_URL = `https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US`;

const mfaInitSuccessEmitsDef = {
  success: (_: ilauth.AuthenticateResponse_Complete) => true
} as const;
export type MfaInitSuccessEmits = ExtractEmitsHandlers<typeof mfaInitSuccessEmitsDef>;

export const MfaInitSMS_Public = defineComponent({
  name: "MfaInitSMS_Public",
  props: {
    state: {
      required: true,
      type: Object as PropType<Login_MfaInitFlow>
    }
  },
  emits: mfaInitSuccessEmitsDef,
  setup(props, {emit}) {
    //
    // initialize ui state based on if the backend has a valid number to send codes to for the target user
    // otherwise we need to let the user know to take some out-of-band action to fix their phone number
    //
    const uiState = ref<MfaInitSmsUiState>((() => {
      if (props.state.mfaDetails.smsPhoneNumber.ok) {
        return "prompt-to-generate-code";
      }
      else {
        return "sorry-bad-phone-number";
      }
    })());
    const errorMsg = ref<string | null>(null)

    const generateCode = async () => {
      const result = await ilauth.public_.getFreshSmsInitCode(axiosBackgroundInstance, {userID: props.state.userID, token: props.state.token});
      if (result.ok) {
        uiState.value = "awaiting-code-input";
      }
      else {
        if (result.detail === "unknown") {
          uiState.value = "error-generating-code";
        }
        else if (result.detail === "invalid-phone-number") {
          uiState.value = "sorry-bad-phone-number";
        }
        else {
          exhaustiveCaseGuard(result.detail);
        }
      }
    }

    const submit = async (code: string) => {
      uiState.value = "submitting-code";
      const result = await ilauth.public_.validateFreshSmsInitCode(axiosBackgroundInstance, {userID: props.state.userID, code: code, token: props.state.token})
      if (result.ok) {
        uiState.value = "done";
        emit("success", result.detail);
      }
      else {
        uiState.value = "awaiting-code-input"
        errorMsg.value = "Invalid code."
      }
    }

    watch(() => uiState.value, async () => {
      if (uiState.value === "generating-code") {
        await generateCode();
      }
    })

    return () => (
      <div data-test="MfaInitSMS">
        <MfaInitSMS_Impl
          onDoValidateCode={async code => await submit(code)}
          uiState={uiState.value}
          userPhoneNumber={maskedLastTwoSmsPhoneNumber(props.state.mfaDetails.smsPhoneNumber)}
          onUpdateState={freshUiState => { uiState.value = freshUiState;}}
          errorMsg={errorMsg.value}
        />
      </div>
    )
  }
});

export const MfaInitOrReconfigureSMS_LoggedIn = defineComponent({
  name: "MfaInitOrReconfigureSMS_LoggedIn",
  props: {
    mfaDetails: {
      required: true,
      type: Object as PropType<MfaDetails>
    }
  },
  emits: {
    success: (_mfaDetails: MfaDetails) => true,
  },
  setup(props, {emit}) {
    //
    // initialize ui state based on if the backend has a valid number to send codes to for the target user
    // otherwise we need to let the user know to take some out-of-band action to fix their phone number
    //
    const uiState = ref<MfaInitSmsUiState>((() => {
      if (props.mfaDetails.smsPhoneNumber.ok) {
        return "prompt-to-generate-code";
      }
      else {
        return "sorry-bad-phone-number";
      }
    })());
    const errorMsg = ref<string | null>(null)

    const noToastAxios = freshAxiosInstance({
      useCurrentBearerToken: true,
      responseInterceptors: [{error: err => {throw new AxiosErrorWrapper(err as any)}}]
    })
    const iziToast = useIziToast();

    const generateCode = async () : Promise<void> => {

        uiState.value = "generating-code";
        const result = await ilauth.getFreshSmsInitCode(axiosAuthBackgroundInstance, {userID: props.mfaDetails.userID});
        if (result.ok) {
          uiState.value = "awaiting-code-input";
        }
        else {
          errorMsg.value = "Sorry, something went wrong generating your code.";
          uiState.value = "error-generating-code";
        }

    }

    const submit = async (code: string) : Promise<void> => {
      try {
        uiState.value = "submitting-code";
        const result = await ilauth.validateFreshSmsInitCode(noToastAxios, {userID: props.mfaDetails.userID, code: code})
        if (result.ok) {
          uiState.value = "done";
          emit("success", result.detail);
        }
        else {
          if (result.detail === "invalid-code") {
            errorMsg.value = "Invalid code."
            uiState.value = "awaiting-code-input";
          }
          else if (result.detail === "too-old") {
            errorMsg.value = null;
            uiState.value = "expired-code";
          }
        }
      }
      catch (e) {
        iziToast.error({message: FALLBACK_ERROR_MESSAGE});
        throw e; // intent: hit vue root error logger
      }
    }

    watch(() => uiState.value, async () => {
      if (uiState.value === "generating-code") {
        await generateCode();
      }
    })

    return () => (
      <div data-test="MfaInitSMS">
        <MfaInitSMS_Impl
          uiState={uiState.value}
          errorMsg={errorMsg.value}
          onDoValidateCode={async code => await submit(code)}
          onUpdateState={state => { uiState.value = state; errorMsg.value = "";}}
          userPhoneNumber={maskedLastTwoSmsPhoneNumber(props.mfaDetails.smsPhoneNumber)}
        />
      </div>
    )
  }
});

type MfaInitSmsUiState =
  | "sorry-bad-phone-number"
  | "prompt-to-generate-code"
  | "generating-code"
  | "error-generating-code"
  | "awaiting-code-input"
  | "submitting-code"
  | "done"
  | "expired-code"

const MfaInitSMS_Impl = defineComponent({
  name: "MfaInitSMS_Impl",
  props: {
    uiState: {
      required: true,
      type: String as PropType<MfaInitSmsUiState>
    },
    errorMsg: {
      required: true,
      type: null as any as PropType<string | null>
    },
    /**
     * only for UI purposes, the backend knows this already and will be used to send codes.
     */
    userPhoneNumber: {
      required: true,
      type: String
    }
  },
  emits: {
    doValidateCode: (_code: string) => true,
    updateState: (_state: MfaInitSmsUiState) => true
  },
  setup(props, {emit}) {
    const code = ref("");
    return () => (
      <>
        {
          props.uiState === "sorry-bad-phone-number"
            ? <SorryBadPhoneNumber phoneNumber={props.userPhoneNumber}/>
            : props.uiState === "prompt-to-generate-code"
            ? (
              <div class="flex flex-col items-center justify-center">
                <t-btn onClick={() => emit("updateState", "generating-code")} data-test="generateCode">Send code to {props.userPhoneNumber}</t-btn>
                <div class="mt-1 text-xs">Standard messaging and data rates apply.</div>
              </div>
            )
            : props.uiState === "generating-code"
            ? (
              <>
                <div class="flex items-center justify-center">Sending code to {props.userPhoneNumber}...</div>
                <div class="flex items-center justify-center my-3">
                  <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                </div>
              </>
            )
            : props.uiState === "error-generating-code"
            ? (
              <div class="flex flex-col items-center justify-center">
                <div>Something went wrong generating your code.</div>
                <t-btn margin={false} onClick={() => emit("updateState", "generating-code")}>Try again</t-btn>
              </div>
            )
            : (props.uiState === "awaiting-code-input" || props.uiState === "submitting-code")
            ? (
              <div>
                <div>
                  <div>We sent a code to {props.userPhoneNumber}</div>
                  <div>Enter it below to verify it's you.</div>
                  <FormKit type="form" onSubmit={() => emit("doValidateCode", code.value)} actions={false}>
                    <div class="flex gap-2 items-start">
                      <FormKit outer-class="$reset grow w-full mb-2" type="text" v-model={code.value} data-test="code" name="Code" validation={[["required"]]}/>
                      <t-btn type="submit" class="align-top" disable={props.uiState === "submitting-code"} margin={false} data-test="code">Ok</t-btn>
                    </div>
                  </FormKit>
                  <div class="text-red-700">{props.errorMsg}</div>
                  {
                    props.uiState === "submitting-code"
                      ? (
                        <div class="flex items-center justify-center my-4">
                            <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                        </div>
                      )
                      : null
                  }
                </div>
              </div>
            )
            : props.uiState === "done"
            ? null
            : props.uiState === "expired-code"
            ? (
              <div class="flex flex-col items-center justify-center gap-2">
                <div>The code we sent has expired. Please generate a new code.</div>
                <t-btn margin={false}>Generate a new code</t-btn>
              </div>
            )
            : exhaustiveCaseGuard(props.uiState)
        }
      </>
    )
  }
})

export const MfaInitTOTP_Public = defineComponent({
  name: "MfaInitTotp_Public",
  props: {
    // on success, the application may enter init-flow-complete, and we need to handle that
    // when in init-flow-complete, we do nothing (but, we do nothing without crashing)
    state: {
      required: true,
      type: Object as PropType<Login_MfaInitFlow>
    }
  },
  emits: mfaInitSuccessEmitsDef,
  setup(props, {emit}) {
    const uiState = ref<MfaInitTOTP_UiState>({type: "loading-qr-code"})

    const getFreshQrCode = async () : Promise<void> => {
      if (uiState.value.type !== "loading-qr-code") {
        throw "expected state to be 'loading-qr-code";
      }
      const nextState = {
        type: "awaiting-input/pending-attempt",
        keyDetail: await ilauth.public_.getFreshTotpInitSecret(axiosBackgroundInstance, {userID: props.state.userID, token: props.state.token})
      } as const;
      uiState.value = nextState;
    }

    const validateSecret = async (passcode: string) : Promise<void> => {
      if (uiState.value.type !== "attempt-failed" && uiState.value.type !== "awaiting-input/pending-attempt") {
        // contractual: we can submit from an earlier failed submit or from "no earlier submit attempt"
        // but not from any other states
        throw `invalid state '${uiState.value.type}'`
      }

      // ah, we expect this not to change during the async callback that follows.
      // Prove it to the compiler by making a copy.
      const savedKeyDetail = uiState.value.keyDetail;

      uiState.value = {
        type: "submitting-code",
        keyDetail: uiState.value.keyDetail
      }

      const result = await ilauth.public_.validateFreshTotpInitSecret(axiosBackgroundInstance, {userID: props.state.userID, passcode: passcode, token: props.state.token});

      if (result.ok) {
        uiState.value = {type: "success"};
        emit("success", result.detail);
      }
      else {
        uiState.value = {
          type: "attempt-failed",
          keyDetail: savedKeyDetail,
          msg: (() => {
            switch (result.detail) {
              case "invalid-code": return "Invalid code.";
              case "too-old": return "Timeout: please regenerate the qr code and try again.";
            }
          })()
        }
      }

    }

    watch(() => uiState.value.type, async (fresh, _old) => {
      switch (fresh) {
        case "loading-qr-code": {
          await getFreshQrCode();
          return;
        }
        default:
          // nothing to do
          return;
      }
    }, {immediate: true});

    return () => (
      <div class="flex flex-col items-center justify-start text-sm" data-test="FreshTotpImpl">
        <MfaInitTOTP_Impl
          uiState={uiState.value}
          userID={props.state.userID}
          onUiStateChange={nextUiState => {uiState.value=nextUiState}}
          onValidateSecret={passcode => validateSecret(passcode)}
        />
      </div>
    )
  }
});

export const MfaInitOrReconfigureTotp_LoggedIn = defineComponent({
  name: "MfaInitOrReconfigureTotp_LoggedIn",
  props: {
    userID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    }
  },
  emits: {
    success: (_mfaDetails: ilauth.MfaDetails) => true
  },
  setup(props, {emit}) {
    const noToastAxios = freshAxiosInstance({
      useCurrentBearerToken: true,
      requestInterceptors: [],
      responseInterceptors: [
        {error: err => { throw new AxiosErrorWrapper(err as any)}}
      ]
    })
    const iziToast = useIziToast();

    const uiState = ref<MfaInitTOTP_UiState>({type: "loading-qr-code"})

    const getFreshQrCode = async () : Promise<void> => {
      if (uiState.value.type !== "loading-qr-code") {
        throw "we expect to be in loading-qr-code state when we get here";
      }

      try {
        const nextState = {
          type: "awaiting-input/pending-attempt",
          keyDetail: await ilauth.getFreshTotpInitSecret(axiosAuthBackgroundInstance, {userID: props.userID})
        } as const;
        uiState.value = nextState;
      }
      catch (e) {
        iziToast.error({message: FALLBACK_ERROR_MESSAGE})
        AxiosErrorWrapper.rethrowIfNotAxiosError(e);
      }
    }

    const validateSecret = async (passcode: string) : Promise<void> => {
      if (uiState.value.type !== "attempt-failed" && uiState.value.type !== "awaiting-input/pending-attempt") {
        // contractual: we can submit from an earlier failed submit or from "no earlier submit attempt"
        // but not from any other states
        throw `invalid state '${uiState.value.type}'`
      }

      const savedKeyDetail = uiState.value.keyDetail;

      let result : Awaited<ReturnType<typeof ilauth.validateFreshTotpInitSecret>>;

      uiState.value = {
        type: "submitting-code",
        keyDetail: uiState.value.keyDetail
      }

      try {
        result = await ilauth.validateFreshTotpInitSecret(axiosAuthBackgroundInstance, {userID: props.userID, passcode: passcode});
      }
      catch (e) {
        uiState.value = {
          type: "awaiting-input/pending-attempt",
          keyDetail: uiState.value.keyDetail,
        }
        iziToast.error({message: FALLBACK_ERROR_MESSAGE})
        AxiosErrorWrapper.rethrowIfNotAxiosError(e);
        return;
      }

      if (result.ok) {
        uiState.value = {type: "success"};
        emit("success", result.detail);
      }
      else {
        uiState.value = {
          type: "attempt-failed",
          keyDetail: savedKeyDetail,
          msg: (() => {
            switch (result.detail) {
              case "invalid-code": return "Invalid code.";
              case "too-old": return "Timeout: please regenerate the qr code and try again.";
              default: exhaustiveCaseGuard(result);
            }
          })()
        }
      }
    }

    watch(() => uiState.value.type, async (fresh, _old) => {
      switch (fresh) {
        case "loading-qr-code": {
          await getFreshQrCode();
          return;
        }
        default:
          // nothing to do
          return;
      }
    }, {immediate: true});

    return () => (
      <div>
        <MfaInitTOTP_Impl
          userID={props.userID}
          uiState={uiState.value}
          onUiStateChange={nextUiState => {uiState.value=nextUiState}}
          onValidateSecret={passcode => validateSecret(passcode)}
        />
      </div>
    )
  }
});

// it's unergonomic that we thread `keyDetail` through most states,
// we should try to clean this approach up.
type MfaInitTOTP_UiState =
  | {type: "loading-qr-code"}
  | {type: "awaiting-input/pending-attempt", keyDetail: ilauth.TotpSetupKeyDetail}
  | {type: "submitting-code", keyDetail: ilauth.TotpSetupKeyDetail}
  | {type: "attempt-failed", keyDetail: ilauth.TotpSetupKeyDetail, msg: string}
  | {type: "success"}

const MfaInitTOTP_Impl = defineComponent({
  name: "MfaInitTotp_Impl",
  props: {
    uiState: {
      required: true,
      type: Object as PropType<MfaInitTOTP_UiState>,
    },
    userID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    },
  },
  emits: {
    validateSecret: (_passcode: string) => true,
    uiStateChange: (_: MfaInitTOTP_UiState) => true,
  },
  setup(props, {emit}) {
    const passcode = ref("");

    /**
     * there's something like a 36px white border around the qr code as we receive it,
     * which isn't quite the padding we want. So we do some adjustments so it behaves as-if
     * the padding that is part of the image isn't there. We have to use zindex=-1
     * so that, after margin adjustments, the border ends up underneath surrounding elements.
     */
    const qrCodeStyleFixup = {
      "margin-top": "-24px",
      "margin-bottom": "-16px",
      "z-index": -1
    } as const;

    /**
     * this really just manages form error messages
     */
    const forgetFailedAttempt = () : void => {
      switch (props.uiState.type) {
        case "submitting-code":
          // fallthrough (shouldn't be able to get here though ... unless the form allows a double submit?)
        case "attempt-failed":
          // fallthrough
        case "awaiting-input/pending-attempt": {
          emit("uiStateChange", {
            type: "awaiting-input/pending-attempt",
            keyDetail: props.uiState.keyDetail
          })
          return;
        }
        default:
          // unfortunately, we can't check for exhaustivity here, we tweak this design.
          throw `invalid state ${props.uiState.type}`;
      }
    }

    return () => (
      <>
          {
            (() => {
              switch (props.uiState.type) {
                case "awaiting-input/pending-attempt":
                case "attempt-failed":
                case "submitting-code":
                  return (
                    <>
                      {/* relative positioning here is intended to keep bleed from margin-top fixups inside the container*/}
                      <div class="relative flex justify-center items-center">
                        <img class="relative" style={qrCodeStyleFixup} src={`data:image/jpg;base64,${props.uiState.keyDetail.base64_jpg_qrCode}`} width={256} height={256}/>
                      </div>
                      <div class="text-sm">
                        <span>Scan the QR code using the</span>
                        <span>&nbsp;</span>
                        <a class="text-blue-700 underline cursor-pointer" href={GOOGLE_AUTHENTICATOR_SUGGESTED_URL} target="_blank">Google authenticator app</a>.
                      </div>
                      <div class="bg-yellow-300 my-2 p-1">
                        Do not share this QR code with anybody. Save it only into your authenticator app.
                      </div>
                      <div>After scanning the code, verify your login by entering the code the authenticator app generates:</div>
                      <div>
                        <form onSubmit={(event) => { event.preventDefault(); emit("validateSecret", passcode.value);}}>
                          <div class="mt-2 flex items-center justify-between">
                            <input type="text" v-model={passcode.value} onInput={() => forgetFailedAttempt()} data-test="passcode"/>
                            <t-btn type="submit" margin={false} class="ml-2" data-test="submit">
                              <div>Submit</div>
                            </t-btn>
                          </div>
                          {
                              props.uiState.type === "attempt-failed"
                                ? <div class="mr-auto mb-2 text-red-700">{props.uiState.msg}</div>
                                : props.uiState.type === "submitting-code"
                                ? (
                                  <div class="flex items-center justify-center my-4">
                                    <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                                  </div>
                                )
                                : null
                          }
                        </form>
                      </div>
                    </>
                  );
                case "loading-qr-code":
                  return (
                    <>
                      <div>Generating QR code...</div>
                      <div class="flex items-center justify-center my-4">
                        <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                      </div>
                    </>
                  );
                case "success":
                  return null;
                default: exhaustiveCaseGuard(props.uiState);
              }
            })()
          }
      </>
    )
  }
})

const mfaInitOptionsEmitsDef = {
  selected: (_: ilauth.MfaType) => true
} as const;
export type MfaInitOptionsEmits = ExtractEmitsHandlers<typeof mfaInitOptionsEmitsDef>;

export const MfaInitOptions = defineComponent({
  name: "MfaInitOptions",
  props: {
    state: {
      required: true,
      type: Object as PropType<Login_MfaInitFlow>
    }
  },
  emits: mfaInitOptionsEmitsDef,
  setup(props, {emit}) {
    return () => (
      <div style="display: grid; grid-template-columns: min-content 1fr; gap:4px;" data-test="MfaInitOptions">
        {
          props.state.mfaDetails.unenrolledMfaTypes.sort(mfaTypeDisplaySort).map((mfaType) : JSX.Element => {
            const divider = <div class="last:hidden my-2" style="grid-column: span 2; border-top:1px solid #DDD;"></div>
            const doSelect = () => emit("selected", mfaType);
            switch (mfaType) {
              case "SMS": {
                return (<>
                  <t-btn data-test="sms" type="button" style="align-self:start; margin-top:4px;" margin={false} onClick={() => doSelect()}>Choose</t-btn>
                  <div style="align-self:start;" class="ml-1">
                    <div class="underline">Text message based</div>
                    <div class="text-sm">Receive a login code via text message.</div>
                    <div class="text-xs">Standard messaging and data rates may apply.</div>
                  </div>
                  {divider}
                </>)
              }
              case "TOTP": {
                return (<>
                  <t-btn data-test="totp" type="button" style="align-self:start; margin-top:4px;" margin={false} onClick={() => doSelect()}>Choose</t-btn>
                  <div style="align-self:start;" class="ml-1">
                    <div class="underline">(Recommended) Authenticator based</div>
                    <div class="text-sm">Use the Google authenticator app on your mobile device to generate a fresh code each login.</div>
                  </div>
                  {divider}
                </>)
              }
            }
          })
        }
      </div>
    )
  }
})
