<template lang="pug">
.flex.-my-2.overflow-x-auto.scrolling-width(class='sm:-mx-6 lg:-mx-8')
  .py-2.align-middle.inline-block(class='sm:px-6 lg:px-8')
    .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
      table.w-auto.divide-y.divide-gray-200
        thead.bg-gray-50
          tr.grid.grid-cols-9.gap-4
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider.col-span-1(scope='col')
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider.col-span-2(scope='col')
              | Display Value
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col')
              | Key
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col')
              | Order
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col')
              | Seasons Displayed
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider.col-span-2(scope='col')
              | Gate Functions
            th.relative.px-6.py-3(scope='col')
              span.sr-only Edit
        tbody.bg-white.divide-y.divide-gray-200
          tr.grid.grid-cols-9.gap-4(v-for='(option, idx) in options' :key='getEffectiveQuestionOptionID(option)' :data-test="`questionOptionID=${getEffectiveQuestionOptionID(option)}`")
            div.col-span-9(class="p-2")
              div(v-if="errors[idx].valueHasIllegalComma" class="text-sm text-red-700") This option's value cannot contain the character ','
              div(v-if="errors[idx].valueIsDuplicate" class="text-sm text-red-700") This option's value is a duplicate with another option.
              div(v-if="errors[idx].valueIsEmpty" class="text-sm text-red-700") This option's value is empty.
            Option(
              :option="option"
              :triggerSave="triggerSave",
              :questionID="questionID"
              :errors="errors[idx]"
              @deleteOption="(val)=>deleteOption(val)"
              @updateStatus="(stat)=>updateOptionStati(stat, option.id)"
              @apiError="$emit('apiError')"
            )
          tr.grid.grid-cols-9.flex.flex-row.text-green-900.items-center.font-medium.h-16.cursor-pointer(@click="pushFreshOption" data-test="pushFreshOption")
            font-awesome-icon.m-2.col-span-1.w-auto.ml-0(:icon='["fas", "plus-square"]' class="md:ml-12")
            div.ml-2.col-span-7 Add Option
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref, watch } from 'vue'
import Option from 'src/components/Registration/admin/Option.vue'
import { QuestionOption, getEffectiveQuestionOptionID, freshQuestionOption, getDiscriminableQuestionOptionID } from 'src/interfaces/Store/registration'
import { QuestionFormErrors_QuestionOptionErrors } from './pageItem/PageItem'
import { exhaustiveCaseGuard } from 'src/helpers/utils'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import iziToast from 'izitoast'

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<QuestionOption[]>,
      required: true
    },
    /**
     * errors.length should exactly equal options.length, and errors[i] represents the errors for options[i]
     * But: we push to props.options from within this component, whereas errors is recomputed in some ancestor
     * in response to such a push.
     */
    errors: {
      required: true,
      type: Array as PropType<QuestionFormErrors_QuestionOptionErrors[]>
    },
    triggerSave: {
      type: Boolean,
      required: true
    },
    questionID: {
      type: String
    },
    mode: {
      required: true,
      type: String as PropType<"new" | "edit">
    }
  },
  components: {
    Option
  },
  emits: ['optionErrors', 'updateStatus', 'apiError'],
  setup(props, {emit}) {
    // sanity check / proof that although we mutate props.options directly,
    // props.errors tracks our mutations from somewhere else as appropriate.
    let justPushedOptionLocally = false;
    watch(() => [props.options.length, props.errors.length], () => {
      if (justPushedOptionLocally) {
        justPushedOptionLocally = false;
        return;
      }

      if (props.options.length !== props.errors.length) {
        throw "options.length !== errors.length"
      }
    })

    const optionErrors = ref({}) as Ref<{[key:string]: {[key:string]: string}}>
    const optionsStati = ref<Record<string, any>>({})

    const pushFreshOption = () => {
      justPushedOptionLocally = true;
      const order = props.options.length + 1; // one-indexed (first item is order=1)
      props.options.push(freshQuestionOption(order))
    }

    const deleteOption = async (questionOptionID: string) : Promise<void> => {
      const targetIdx = props.options.findIndex(option => getEffectiveQuestionOptionID(option) === questionOptionID);
      if (targetIdx === -1) {
        // shouldn't happen, couldn't find it, nothing we can do
        return;
      }

      // remove from page
      const doLocalDelete = () : void => {
        props.options.splice(targetIdx, 1)
        if (props.options.length === 0) {
          // it's never ok to have zero options after we deleted one (checkbox, radio, or select with zero options is non-sensical)
          // the UI should probably also {prevent this, make it clear why it's prevented}
          pushFreshOption();
        }
      }

      // remove from db
      const doRemoteDelete = async (questionOptionID: string) : Promise<void> => {
        await axiosInstance.delete(`/v1/registration/customQuestionOption/${questionOptionID}`)
      }

      switch (props.mode) {
        case "new": {
          doLocalDelete();
          return;
        }
        case "edit": {
          //
          // delete, except when in edit mode, and we'd delete the last persisted option we're aware of
          // (cannot leave the question in a state of having zero question options)
          //
          const targetOption = props.options[targetIdx];
          const targetOptionID = getDiscriminableQuestionOptionID(targetOption)
          switch (targetOptionID.type) {
            case "tentative": {
              doLocalDelete();
              return;
            }
            case "persisted": {
              const knownPersistedCountExcludingDeleteTarget = props
                .options
                .filter(option => option !== targetOption && getDiscriminableQuestionOptionID(option).type === "persisted")
                .length;

              if (knownPersistedCountExcludingDeleteTarget === 0) {
                // The ui should prevent attempting this
                iziToast.warning({message: "Cannot delete the last question option; consider editing this question option, or creating another and saving it prior to deleting this one."})
                return;
              }
              else {
                try {
                  await doRemoteDelete(targetOptionID.id)
                  doLocalDelete();
                }
                catch (err) {
                  AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                }
                return;
              }
            }
            default: exhaustiveCaseGuard(targetOptionID.type)
          }
        }
        default: exhaustiveCaseGuard(props.mode);
      }
    }

    const emitOptionErrors = (err: {[key:string]: string}, idx: string) => {
      optionErrors.value[idx] = err
      let emitFormat = {}
      for(const error in optionErrors.value) {
        emitFormat = {...emitFormat, ...optionErrors.value[error]}
      }
      emit('optionErrors', emitFormat)
    }

    const updateOptionStati = (status: boolean, ID: string) => {
      optionsStati.value[ID]=status
    }

    watch(optionsStati, (val: {[key: string]: string}) => {
      let allOptionsSaved = 'saved'
      for(const status in val) {
        if(val[status]!="saved") {
          allOptionsSaved = 'pending'
        }
      }
      emit('updateStatus', allOptionsSaved)
    }, {deep: true})

    return {
      pushFreshOption,
      deleteOption,
      emitOptionErrors,
      optionErrors,
      updateOptionStati,
      optionsStati,
      getEffectiveQuestionOptionID,
    }
  }
})
</script>

<style scoped>
.scrolling-width {
  min-width: 700px
}
</style>

