import { computed, defineComponent, PropType, ref, watch } from "vue";
import { exhaustiveCaseGuard, parseIntOr, FormKitValidationRule } from "src/helpers/utils";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { FormKit } from "@formkit/vue";
import dayjs from "dayjs";
import { V_EventSelector, V_SeasonGroupedCompetitionSelector, V_UserOrPlayerLinkSelector } from "./CouponEditor";

export type ExpandedCoupon = iltypes.WithDefinite<ilapi.coupon.Coupon, "hasDeletePermission" | "definiteRedemptionCount" | "pendingRedemptionCount" | "couponCompetitionSeasonLinks" | "couponEventLinks" | "couponUserLinks" | "couponChildLinks">

interface MaybeRequirement<T> {
  required: (_: T) => boolean
  satisfied: (_: T) => boolean
}

function MaybeRequiredDot<T>(props: {requirementDef: MaybeRequirement<T>, data: T, class?: string}) : JSX.Element | null {
  if (props.requirementDef.required(props.data)) {
    const satisfied = props.requirementDef.satisfied(props.data);
      return (
        <span class={`mr-2 ${satisfied ? "text-green-500" : "text-red-500"} ${props.class ?? ''}`} style="font-size:.375em;">
          <FontAwesomeIcon icon={["fas", "fa-circle"]} />
        </span>
      );
  }
  else {
    return null;
  }
}

const CouponValidation = {
  couponCode: {
    required: (v: CouponFormData) => v.type === "new",
    satisfied: (v: CouponFormData) => v.couponCode.length >= 6 && v.couponCode.length <= 50,
    formkit: [
      ["required"],
      ["length", 6, 50]
    ] as FormKitValidationRule[],
  },
  quantity: {
    required: (v: CouponFormData) => v.type === "new" || v.type === "edit", // always required
    satisfied: (v: CouponFormData) => {
      return v.couponQuantity.type === "unlimited"
        // we don't want to accept floats, and "parseInt(3.4)" is 3, but we want NaN
        || parseIntOr(v.couponQuantity.value, NaN) >= 0
    },
  },
  discount: {
    required: (v: CouponFormData) => v.type === "new",
    satisfied: (v: CouponFormData) => {
      if (v.type === "edit") {
        return true;
      }
      switch (v.discount.type) {
        case "":
          return false;
        case "absolute": {
          const maybeNaN_val = parseFloat(v.discount.value as any);
          return maybeNaN_val >= 0;
        }
        case "percentage": {
          const maybeNaN_val = parseFloat(v.discount.value as any);
          return maybeNaN_val >= 0 && maybeNaN_val <= 100;
        }
        default: exhaustiveCaseGuard(v.discount);
      }
    },
    percent: {
      formkit: [["required"], ["min", 0],["max",100]] as FormKitValidationRule[],
    },
    absolute: {
      formkit: [["required"], ["min", 0]] as FormKitValidationRule[]
    }
  },
  expirationDate: {
    required: (v: CouponFormData) => v.type === "new" || v.type === "edit", // always required
    satisfied: (v: CouponFormData) => {
      return v.expiration_date.type === "unlimited" || (v.expiration_date.type === "date" && dayjs(v.expiration_date.value).isValid())
    }
  },
  // TODO: this is duplicated in custom-validation message generation inside the coupon form component.
  // MaybeRequirement<T>["satisfied"] should become (_:T) => {ok: true} | {ok: false, msg: string}, and then
  // we can put validation+messages here in one place
  applicableTo: {
    // if type is edit and it's a wildcard coupon, then it's not required because there's nothing to change
    required: (v: CouponFormData) => v.type === "new" || (v.type === "edit" && !v.appliesTo_wildcard),
    // curried to reduce to the desired type
    satisfied: (permissions: {isRegistrar: boolean}) => (v: CouponFormData) => {
      if (permissions.isRegistrar) {
        return v.appliesTo_wildcard
          || v.entityTargets.competitionSeasons.effectivelySelected().length > 0
          || v.entityTargets.events.effectivelySelected().length > 0
          || v.entityTargets.children.effectivelySelected().length > 0
          || v.entityTargets.users.effectivelySelected().length > 0;
      }
      else {
        return v.entityTargets.competitionSeasons.effectivelySelected().length > 0 || v.entityTargets.events.effectivelySelected().length > 0
      }
    }
  }
} as const;

export const CouponEditorImpl = defineComponent({
  props: {
    formData: {
      required: true,
      type: Object as PropType<CouponFormData>
    },
    relevantUserPermissions: {
      required: true,
      type: Object as PropType<{isRegistrar: boolean}>
    }
  },
  emits: {
    doSubmit: () => true
  },
  setup(props, {emit}) {
    const mutableFormRef = ref(props.formData);

    /**
     * changing discount type clears the old value
     */
    watch(() => mutableFormRef.value.discount.type, () => {
      if (mutableFormRef.value.discount.type === "") {
        // Shouldn't happen, this is mostly to satisfy the type checker.
        // On mount, we can be undefined in the "new coupon" case, but this
        // isn't a "run immediately" watcher, so the first watch trigger will be in
        // response to a transition from undefined to some actual `type` value, and we
        // never transition back to undefined.
        return;
      }
      else {
        mutableFormRef.value.discount.value = "";
      }
    })

    {
      // switching across variants requires that when we switch back we rebuild the `value` values,
      // This is a formKit oddity where it won't track "undefined"/non-existent properties as v-model otherwise would
      watch(() => mutableFormRef.value.couponQuantity.type, () => {
        if (mutableFormRef.value.couponQuantity.type === "int") {
          mutableFormRef.value.couponQuantity.value = "" as iltypes.Integerlike;
        }
        else {
          (mutableFormRef.value.couponQuantity as any).value = undefined;
        }
      });
      watch(() => mutableFormRef.value.expiration_date.type, () => {
        if (mutableFormRef.value.expiration_date.type === "date") {
          mutableFormRef.value.expiration_date.value = "";
        }
        else {
          (mutableFormRef.value.expiration_date as any).value = undefined;
        }
      });
    }

    const customErrorsMaybeEventuallyFormKit = computed(() => {
      let satisfiesEntityRequirement : boolean;

      // a registrar can build a coupon linked to any set of couponable targets
      if (props.relevantUserPermissions.isRegistrar) {
        satisfiesEntityRequirement = props.formData.appliesTo_wildcard
          || props.formData.entityTargets.competitionSeasons.effectivelySelected().length > 0
          || props.formData.entityTargets.events.effectivelySelected().length > 0
          || props.formData.entityTargets.children.effectivelySelected().length > 0
          || props.formData.entityTargets.users.effectivelySelected().length > 0;
      }
      else {
        //
        // non registrars are restricted to creating coupons for "invoiceable entities" for which they have some relevant permission,
        // though they can additionally add users/children once a coupon is targetting at least one specific invoiceable entity for which they have permission.
        // e.g. a non-registrar user with EVENT-X permission can create a coupon for (EVENT-X), or (EVENT-X, USER-X), but not just (USER-X)
        // This permission is implied by the entities received in the form's entityTargets' objects' `mutable` properties.
        //
        satisfiesEntityRequirement = props.formData.entityTargets.competitionSeasons.effectivelySelected().length > 0
          || props.formData.entityTargets.events.effectivelySelected().length > 0
      }

      const result : JSX.Element[] = [];
      if (!satisfiesEntityRequirement) {
        if (props.relevantUserPermissions.isRegistrar) {
          result.push(<div class="text-red-400">At least one season+competition or event or user or child must be selected.</div>);
        }
        else {
          result.push(<div class="text-red-400">At least one season+competition or event must be selected.</div>);
        }
      }
      return result;
    })

    // "xprops" would be just "props" but we have to reference the outer "props" too
    // this could be a separate component (it *is* stateful...) but kind of interested in seeing how a "stateful functional" (?)
    // component works out. It's nice that it's scoped exactly here. Does vue re-evaluate the whole function, or just redo dom stuff?
    function UserOrPlayerLinkSelectorForm(xprops: {entityTarget: "children" | "users"}) {
      const searchPlaceholder = xprops.entityTarget === "children" ? "Player name..." : "User name...";
      const searchCompleted = ref(false);
      const onSearch = async () : Promise<void> => {
        await props.formData.entityTargets[xprops.entityTarget].doSearch(search.value)
        searchCompleted.value = true;
      }
      const search = ref("")

      return (
        <FormKit type="form" actions={false} onSubmit={() => void onSearch()} key={xprops.entityTarget}>
          <div class="flex flex-col gap-2">
            <div class="flex gap-2 justify-end items-center">
              <FormKit
                type="text" outer-class="$reset w-full" name="Name"
                onInput={() => {searchCompleted.value = false;}}
                wrapper-class="$reset" v-model={search.value} {...{autocomplete: "off", placeholder: searchPlaceholder}} validation={[["length",3]]}/>
              <t-btn type="button" margin={false} onClick={() => void onSearch()}>Search</t-btn>
            </div>

            {
              searchCompleted.value && props.formData.entityTargets[xprops.entityTarget].options.uncommitted.value.length === 0
                ? <div>Nothing found.</div>
                : null
            }

            <FormKit
              type="select" outer-class="$reset" wrapper-class="$reset" multiple {...{size:5}} select-icon={false}
              v-model={props.formData.entityTargets[xprops.entityTarget].selected.uncommitted.value}
              options={props.formData.entityTargets[xprops.entityTarget].options.uncommitted.value}
            />

            {/*
              `transition-none` vs `transition-all` is to have a nice transition FROM disabled to enabled,
              but prevent a jarring flashing we can't otherwise get rid of when we transition the otherway
            */}

            <div class="flex items-center">
              <div class="font-medium text-sm">Selected {xprops.entityTarget === "children" ? "players" : "users"}</div>
              <t-btn
                type="button" margin={false} key="move"
                class={`${props.formData.entityTargets[xprops.entityTarget].selected.uncommitted.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
                disable={props.formData.entityTargets[xprops.entityTarget].selected.uncommitted.value.length === 0}
                onClick={() => props.formData.entityTargets[xprops.entityTarget].commit()}
              >
                <FontAwesomeIcon icon={["fas", "user-plus"]} />
                <span class="ml-1">Add</span>
              </t-btn>
            </div>
            <FormKit
              type="select" outer-class="$reset" wrapper-class="$reset" multiple {...{size:5}} select-icon={false}
              v-model={props.formData.entityTargets[xprops.entityTarget].selected.committed.value}
              options={props.formData.entityTargets[xprops.entityTarget].options.committed.value}
              />
            <div class="flex items-center">
              <t-btn
                type="button" margin={false} key="remove"
                class={`${props.formData.entityTargets[xprops.entityTarget].selected.committed.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
                disable={props.formData.entityTargets[xprops.entityTarget].selected.committed.value.length === 0}
                onClick={() => props.formData.entityTargets[xprops.entityTarget].uncommit()}
              >
                <span class="ml-1">Remove</span>
              </t-btn>
            </div>
          </div>
        </FormKit>
      )
    }

    function EventSelector() {
      return (
        <div class="flex flex-col gap-2">
          <FormKit
            type="select" outer-class="$reset" wrapper-class="$reset" multiple {...{size:5}} select-icon={false}
            v-model={props.formData.entityTargets.events.selected.uncommitted.value}
            options={props.formData.entityTargets.events.options.uncommitted.value}
          />

          {/*
            `transition-none` vs `transition-all` is to have a nice transition FROM disabled to enabled,
            but prevent a jarring flashing we can't otherwise get rid of when we transition the otherway
          */}

          <div class="flex items-center">
            <div class="font-medium text-sm">Selected events ({props.formData.entityTargets.events.options.committed.value.length})</div>
            <t-btn
              type="button" margin={false} key="move"
              class={`${props.formData.entityTargets.events.selected.uncommitted.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
              disable={props.formData.entityTargets.events.selected.uncommitted.value.length === 0}
              onClick={() => props.formData.entityTargets.events.commit()}
            >
              <FontAwesomeIcon icon={["fas", "arrow-down"]} />
              <span class="ml-1">Add</span>
            </t-btn>
          </div>
          <FormKit
            type="select" outer-class="$reset" wrapper-class="$reset" multiple {...{size:5}} select-icon={false}
            v-model={props.formData.entityTargets.events.selected.committed.value}
            options={props.formData.entityTargets.events.options.committed.value}
            />
          <div class="flex items-center">
            <t-btn
              type="button" margin={false} key="remove"
              class={`${props.formData.entityTargets.events.selected.committed.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
              disable={props.formData.entityTargets.events.selected.committed.value.length === 0}
              onClick={() => props.formData.entityTargets.events.uncommit()}
            >
              <span class="ml-1">Remove</span>
            </t-btn>
          </div>
        </div>
      )
    }

    function CompetitionSeasonSelectorOne(xprops: {group: V_SeasonGroupedCompetitionSelector["engagedSeasonGroups"]["value"][number]}) {
      const group = xprops.group
      return (
        <div key={group.seasonDetail.seasonUID}>
          <div class="bg-black text-white">
            <div class="flex items-center">
              <span class="p-2">{group.seasonDetail.seasonName}</span>
              {
                group.seasonDetail.mutable
                  ? (
                      <span
                        class="cursor-pointer hover:bg-gray-600 ml-auto p-2"
                        onClick={()=>{ props.formData.entityTargets.competitionSeasons.unengageSeason(group.seasonDetail.seasonUID) }}>
                        <FontAwesomeIcon icon={["fas", "times-circle"]}/>
                      </span>
                  )
                  : null
              }
            </div>
          </div>
          <div class="flex flex-col gap-2 px-4 py-2">
            <FormKit
              type="select"
              select-icon={false} multiple
              outer-class="$reset" wrapper-class="$reset"
              options={group.competitionDetail.options.uncommitted.value}
              v-model={group.competitionDetail.selected.uncommitted.value}
              {...{size: 4}}/>
            <div class="flex items-center">
              <div class="font-medium text-sm">Programs ({group.competitionDetail.options.committed.value.length})</div>
              <t-btn
                type="button" margin={false} key="move"
                class={`${group.competitionDetail.selected.uncommitted.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
                disable={group.competitionDetail.selected.uncommitted.value.length === 0}
                onClick={() => group.competitionDetail.commit()}
              >
                <FontAwesomeIcon icon={["fas", "arrow-down"]} />
                <span class="ml-1">Add</span>
              </t-btn>
            </div>
            <FormKit
              type="select"
              select-icon={false} multiple
              outer-class="$reset" wrapper-class="$reset"
              options={group.competitionDetail.options.committed.value}
              v-model={group.competitionDetail.selected.committed.value}
              {...{size: Math.max(2, Math.min(5, group.competitionDetail.options.committed.value.length))}} />
            <t-btn
              type="button" margin={false} key="move"
              class={`${group.competitionDetail.selected.committed.value.length === 0 ? 'bg-gray-300 transition-none' : 'transition-all'} ml-auto`}
              disable={group.competitionDetail.selected.committed.value.length === 0}
              onClick={() => group.competitionDetail.uncommit()}
            >
              <span class="ml-1">Remove</span>
            </t-btn>
          </div>
        </div>
      )
    }

    const handleSubmit = () => {
      if (customErrorsMaybeEventuallyFormKit.value.length === 0) {
        emit("doSubmit")
      }
    }

    return () => (
      <FormKit type="form" data-test="CouponEditorImpl" actions={false} onSubmit={() => handleSubmit()}>
        <div class="grid grid-cols-2">
          <>
            <div>
              <div class="flex items-center">
                <MaybeRequiredDot requirementDef={CouponValidation.couponCode} data={props.formData} class="mr-1"/>
                <span>Coupon code</span>
              </div>
            </div>
            {
              mutableFormRef.value.type === "new" || (mutableFormRef.value.underlyingCoupon.pendingRedemptionCount === 0 && mutableFormRef.value.underlyingCoupon.definiteRedemptionCount === 0)
                ? (
                  <div class="mb-2">
                    <FormKit type="text" name="Coupon code" outer-class="$reset" v-model={mutableFormRef.value.couponCode} validation={CouponValidation.couponCode.formkit} data-test="couponCode-mutable"/>
                    {
                      new RegExp(" ").test(mutableFormRef.value.couponCode)
                        ? (
                          <div class="my-2 text-sm bg-yellow-200 text-black p-1 shadow-sm">
                            <div>We recommend the coupon code not contain spaces.</div>
                            <div>Consider using hyphens, as in</div>
                            {
                              new RegExp("^\\s+$").test(mutableFormRef.value.couponCode)
                                // input is all whitespace, show something a little different in that case
                                ? <pre>some-coupon-code</pre>
                                // otherwise show their input but with spaces as hyphens
                                : <pre>{ mutableFormRef.value.couponCode.replaceAll(/\s+/g, "-").replaceAll(/-+/g, "-")}</pre>
                            }
                          </div>
                        )
                        : null
                    }
                  </div>
                )
                : <div class="mb-2" data-test="couponCode-immutable">{mutableFormRef.value.underlyingCoupon.couponCode}</div>
            }
          </>
          <>
            <div>
              <div class="flex items-center">
                <MaybeRequiredDot requirementDef={CouponValidation.quantity} data={props.formData} class="mr-1"/>
                Quantity
              </div>
            </div>
            <div>
              {
                mutableFormRef.value.couponQuantity.type === "unlimited"
                  ? <FormKit type="number" key="couponQuantity-disabled" disabled {...{placeholder: "Unlimited"}} data-test="quantity"/>
                  : <FormKit type="number" key="couponQuantity-enabled" name="Coupon quantity" min={0} v-model={mutableFormRef.value.couponQuantity.value} validation={[["required"]]} data-test="quantity"/>
              }
              <FormKit type="checkbox" v-model={mutableFormRef.value.couponQuantity.type} {...{onValue: "unlimited", offValue: "int", value: "unlimited"}} label="Unlimited"/>
            </div>
            {/** for edit: current redemption count... */}
          </>
          <>
            <div>
              <div class="flex items-center">
                <MaybeRequiredDot requirementDef={CouponValidation.expirationDate} data={props.formData} class="mr-1"/>
                Expiration date
              </div>
            </div>
            <div>
              {
                mutableFormRef.value.expiration_date.type === "unlimited"
                  ? <FormKit type="text" key="couponExpiration-disabled" disabled {...{placeholder: "Never expires"}}/>
                  : <FormKit type="date" name="Expiration date" key="couponExpiration-enabled" v-model={mutableFormRef.value.expiration_date.value} validation={[["required"]]}/>
              }
              <FormKit type="checkbox" v-model={mutableFormRef.value.expiration_date.type} {...{onValue: "unlimited", offValue: "date", value: "unlimited"}} label="Never expires"/>
            </div>
          </>
          <>
            <div>
              <div class="flex items-center">
                <MaybeRequiredDot requirementDef={CouponValidation.discount} data={props.formData} class="mr-1"/>
                Discount
              </div>
            </div>
            <div>
              {
                // are we in edit mode AND there's some redemptions? if so, we can't edit this
                mutableFormRef.value.type === "edit" && (mutableFormRef.value.underlyingCoupon.pendingRedemptionCount > 0 || mutableFormRef.value.underlyingCoupon.definiteRedemptionCount > 0)
                  ? (
                    <div class="flex items-center mb-2">
                      {
                        mutableFormRef.value.discount.type === "absolute"
                        ? <span class="mr-2"><FontAwesomeIcon icon={["fas", "dollar-sign"]} /></span>
                        : <span class="mr-2"><FontAwesomeIcon icon={["fas", "percentage"]} /></span>
                      }
                      <span class="ml-2">{mutableFormRef.value.underlyingCoupon.discount.value.toFixed(2)}</span>
                    </div>
                  )
                  : (
                    <>
                      <FormKit
                        type="radio"
                        fieldset-class="$reset"
                        v-model={mutableFormRef.value.discount.type}
                        options={[{value: "percentage", label: "Percentage"}, {value: "absolute", label: "Fixed Amount"}]}
                        name="Discount type"
                        validation={[["required"]]}
                      />
                      <div class="flex items-center mb-2">
                        {
                          (() => {
                            switch (mutableFormRef.value.discount.type) {
                              case "":
                                // no type has been selected yet, there's no particular input to display
                                return null;
                              case "absolute":
                                return <>
                                  <span class="mr-2"><FontAwesomeIcon icon={["fas", "dollar-sign"]} /></span>
                                  <FormKit data-test="discount-absolute" outer-class="$reset" type="number" step="0.01" name="Discount (absolute)" key="couponDiscountValue/absolute" v-model={mutableFormRef.value.discount.value} min={0} validation={CouponValidation.discount.absolute.formkit}/>
                                </>
                              case "percentage":
                                return <>
                                  <span class="mr-2"><FontAwesomeIcon icon={["fas", "percentage"]} /></span>
                                  <FormKit data-test="discount-percentage" outer-class="$reset" type="number" step="0.01" name="Discount (percentage)" key="couponDiscountValue/percentage" v-model={mutableFormRef.value.discount.value} min={0} max={100} validation={CouponValidation.discount.percent.formkit} />
                                </>
                              default: exhaustiveCaseGuard(mutableFormRef.value.discount);
                            }
                          })()
                        }
                      </div>
                    </>
                  )
              }

            </div>
          </>
          <>
            <div class="col-span-2">
              <div class="flex items-center">
                <MaybeRequiredDot
                  requirementDef={{required: CouponValidation.applicableTo.required, satisfied: CouponValidation.applicableTo.satisfied(props.relevantUserPermissions)}}
                  data={props.formData}
                />
                Applies to any of the following:
              </div>
              {
                props.relevantUserPermissions.isRegistrar
                  ? (
                    <FormKit
                      type="checkbox"
                      outer-class="$reset my-3"
                      v-model={props.formData.appliesTo_wildcard}
                      disabled={props.formData.type === "edit"}
                      label="Is wildcard coupon (applicable to anything)"
                    />
                  )
                  : null
              }
            </div>
          </>
          {
            props.formData.appliesTo_wildcard
              ? null
              : (
                <>
                  <>
                    <div class="mt-2 col-span-2 border shadow-md border-gray-200 p-4">
                      <div>Events</div>
                      <EventSelector />
                    </div>
                  </>
                  <>
                    <div class="mt-4 col-span-2 border shadow-md border-gray-200 p-4">
                      <div>Programs (per season)</div>
                      <div class="flex items-center gap-2">
                        <FormKit
                          type="select"
                          outer-class="$reset flex-grow" wrapper-class="$reset"
                          v-model={props.formData.entityTargets.competitionSeasons.availableSeasonGroups.selected.value}
                          options={props.formData.entityTargets.competitionSeasons.availableSeasonGroups.options.value}
                        />
                        <t-btn
                          type="button"
                          style="margin:0;"
                          disabled={props.formData.entityTargets.competitionSeasons.availableSeasonGroups.options.value.length === 0}
                          onClick={() => props.formData.entityTargets.competitionSeasons.engageSelectedSeason()}
                        >
                          <div>Add</div>
                        </t-btn>
                      </div>
                      <div class="mt-2" style="--fk-margin-outer: none;">
                        <div>For the selected programs+seasons, this coupon applies to the following invoiceables:</div>
                        <div class="ml-1">
                          <div class="flex gap-1 items-center mb-2">
                            <FormKit type="checkbox" v-model={props.formData.appliesTo_compSeason_competitionRegistration}/>
                            <div>Program registrations</div>
                          </div>
                          <div class="flex gap-1 items-center mb-2">
                            <FormKit type="checkbox" v-model={props.formData.appliesTo_compSeason_tournamentTeamRegistration}/>
                            <div>Tournament team registrations</div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2" style="display:grid; justify-content:center; gap:.125in; grid-template-columns: repeat(auto-fit, minmax(4.5in, 1fr))">
                        {
                          props
                            .formData
                            .entityTargets
                            .competitionSeasons
                            .engagedSeasonGroups
                            .value
                            .map(group => (
                              <div class="border border-gray-100 shadow-sm">
                                <CompetitionSeasonSelectorOne group={group} />
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </>
                  <>
                    <div class="mt-2 col-span-2">
                      Additionaly constrained to only the following users/players
                      <div class="shadow-md p-3 my-4">
                        <div class="font-medium text-sm mb-1">Users</div>
                        <UserOrPlayerLinkSelectorForm entityTarget="users" />
                      </div>
                      <div class="shadow-md p-3 my-4">
                        <div class="font-medium text-sm mb-1">Players</div>
                        <UserOrPlayerLinkSelectorForm entityTarget="children" />
                      </div>
                    </div>
                  </>
                </>
              )
          }
          <div class="grid-span-4 my-3">
            <t-btn
              data-test="submit"
              type="submit" margin={false}
              class={`${customErrorsMaybeEventuallyFormKit.value.length > 0 ? 'bg-gray-300' : ''}`}
              disable={customErrorsMaybeEventuallyFormKit.value.length > 0}
            >Submit</t-btn>
            <div class="mt-2">
              {customErrorsMaybeEventuallyFormKit.value}
            </div>
          </div>
        </div>
      </FormKit>
    )
  }
})

export type CouponFormData = CouponFormDataNew | CouponFormDataEdit

interface CouponFormDataBase {
  appliesTo_wildcard: boolean,
  appliesTo_compSeason_competitionRegistration: boolean,
  appliesTo_compSeason_tournamentTeamRegistration: boolean,
  couponCode: string,
  /**
   * `value` being empty string means "indeterminate"
   * The form itself should guard submit logic on `value` being some valid integer value when type is "int"
   */
  couponQuantity: {type: "int", value: "" | iltypes.Integerlike} | {type: "unlimited"},
  expiration_date: {type: "date", value: iltypes.DateTimelike} | {type: "unlimited"},
  entityTargets: {
    events: V_EventSelector,
    competitionSeasons: V_SeasonGroupedCompetitionSelector,
    children: V_UserOrPlayerLinkSelector,
    users: V_UserOrPlayerLinkSelector,
  },
  /**
   * `type: ""` is "no choice yet", which we should only get into in the "new coupon" case
   */
  discount: {type: ""} | {type: "absolute", value: iltypes.Numeric} | {type: "percentage", value: iltypes.Numeric}
}

export interface CouponFormDataNew extends CouponFormDataBase {
  type: "new",
}
export interface CouponFormDataEdit extends CouponFormDataBase {
  type: "edit",
  underlyingCoupon: Readonly<ExpandedCoupon>
}
