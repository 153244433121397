import { freshAxiosInstance, axiosNoAuthInstance } from "src/boot/axios"
import {
  LogWriter,
  LoggerConfig
} from "./LoggerService"
import * as ilapi from "src/composables/InleagueApiV1"

export const PublicLogWriter : LogWriter = {
  log: async (level, breadcrumb, jsonifiedError, error) => {
    if (LoggerConfig.liveLogs()) {
      try {
        await ilapi.public_.oops(axiosNoAuthInstance, {breadcrumb, level, detail: jsonifiedError});
      }
      catch (e) {
        // discard
      }
    }
    else {
      console.error(error);
    }
  }
}

export const LoggedinLogWriter : LogWriter = {
  log: async (level, breadcrumb, jsonifiedError, error) => {
    if (LoggerConfig.liveLogs()) {
      try {
        const axios_loggedInNoToastOnError = freshAxiosInstance({useCurrentBearerToken: true, requestInterceptors: [], responseInterceptors: []});
        await ilapi.oops(axios_loggedInNoToastOnError, {breadcrumb, level, detail: jsonifiedError});
      }
      catch (e) {
        // discard
      }
    }
    else {
      if (error.error instanceof Error) {
        throw error.error;
      }
      else {
        console.error(error)
      }
    }
  }
}
