//? In order to fully change theme colors, change svg css fill color too

export const clientsThemes : ClientsThemeDefsByClientID = {
  'B336BDF9-4766-4280-9FA9-11362570A1D4': {
    name: 'Brooklyn AYSO',
    color: 'rgb(22, 0, 222)',
    banner: '/clientAssets/brooklyn/bk_banner_sm.png',
    favicon: '/clientAssets/brooklyn/soccer_ball.svg',
  },
  '45BA6F83-A3C9-4E19-BBFE-1282A8C54266': {
    name: 'SM AYSO',
    color: 'rgb(0, 62, 138)',
    banner: '/clientAssets/santamonica/sm_banner_sm.png',
    favicon: '/clientAssets/santamonica/soccer_ball.svg',
  },
  'C1D72574-700F-4F1E-B112-522896F33FB2': {
    name: 'AYSO Region 122 (Santa Barbara, Goleta, Montecito)',
    color: 'rgb(4, 59, 116)',
    banner: '/clientAssets/AYSO122/r122_banner_sm.png',
    favicon: '/clientAssets/AYSO122/soccer_ball.svg'
  },
  '065DE1B0-9EB6-4B87-A715-B94EF8C9DEDB': {
    name: 'Greenpoint Williamsburg Youth Soccer League',
    color: 'rgb(20, 30, 150)',
    banner: '/clientAssets/gwysl/banner_sm.png',
    favicon: '/clientAssets/inLeague/soccer_ball.svg',
  },
  '46C9CE66-053A-4151-ABE0-A4CA18A3FCA3': {
    name: 'AYSO Region 418 (Chicago Lakefront)',
    color: 'rgb(0, 114, 187)',
    banner: '/clientAssets/AYSO418/banner_sm.png',
    favicon: '/clientAssets/inLeague/soccer_ball.svg',
  },
  '6164D62E-A661-4F75-8388-6523F95017DE': {
    name: 'West Los Angeles AYSO',
    color: 'rgb(231, 0, 23)',
    banner: '/clientAssets/WESTLA/wla_banner_sm.png',
    favicon: '/clientAssets/WESTLA/soccer_ball.svg',
  },
  'D536F7AD-8A93-43D6-88D8-697D51DC57B0': {
    name: 'AYSO Region 300',
    color: 'rgb(1, 46, 121)',
    banner: '/clientAssets/Region300/r300_banner_sm.png',
    favicon: '/clientAssets/Region300/soccer_ball.svg',
  },
  '4A60D630-E6BA-4184-9D95-79CB2BFAEAD8': {
    name: 'West Side Soccer League',
    color: 'rgb(255, 178, 37)',
    banner: '/clientAssets/WSSL/wssl_banner_sm.png',
    favicon: '/clientAssets/WSSL/soccer_ball.svg',
  },
  'AE039D9B-0AB7-41F8-97B3-B19C18AF03A3': {
    name: 'Frankenmuth AYSO',
    color: 'rgb(200, 24, 39)',
    banner: '/clientAssets/frankenmuth/banner_sm.png',
    favicon: '/clientAssets/frankenmuth/soccer_ball.svg',
  },
  '341D43B8-BF80-40B3-A9BB-C28DEBCD03ED': {
    name: 'San Elijo AYSO',
    color: 'rgb(79, 125, 45)',
    banner: '/clientAssets/SanElijo/client_ayso1505_images_next_banner_sm.jpg',
    favicon: '/clientAssets/SanElijo/soccer_ball.svg',
  },
  'E2CE1A48-50A0-4C54-A754-C9DCE2F86902': [
    {
      name: 'Demo',
      color: 'rgb(25, 118, 55)',
      banner: '/clientAssets/inLeague/banner_sm.png',
      favicon: '/clientAssets/inLeague/soccer_ball.svg',
    },
    {
      variant: "area",
      name: 'Demo',
      color: 'rgb(0, 60, 120)',
      banner: '/clientAssets/AYSO-1-p/banner_sm.png',
      favicon: '/clientAssets/AYSO-1-p/soccer_ball.svg',
    },
  ],
  '66539024-E592-4B5F-AAFD-CC2BDE8486A5' : {
    name : 'Palisades / Brentwood AYSO Region 69',
    color : 'rgb(232, 170, 2)',
    banner: '/clientAssets/AYSO69/AYSO69_900.jpg',
    favicon: '/clientAssets/inLeague/soccer_ball.svg',
  },
  '46D92B6B-17EC-4A76-955F-D03C0E360EE5': {
    name: 'AYSO Region 644',
    color: 'rgb(18, 44, 82)',
    banner: '/clientAssets/AYSO644/client_ayso644_images_next_banner_sm.png',
    favicon: '/clientAssets/AYSO644/soccer_ball.svg',
  },
  'F937CDA8-4DF0-4425-9BFA-43BDB42B4427': {
    name: 'Weston Adult Soccer',
    color: 'rgb(18, 44, 82)',
    banner: '/clientAssets/WAS/client_WAS_images_next_banner_sm.png',
    favicon: '/clientAssets/WAS/soccer_ball.svg',
  },
  '40134A8D-AB5F-4B05-903A-DFC19C9971E7': {
    name: 'Mt. Diablo Soccer',
    color: 'rgb(186, 32, 40)',
    banner: '/clientAssets/mtdiablo/baner_sm.png',
    favicon: '/clientAssets/mtdiablo/soccer_ball.svg',
  },
  '3227B172-02A5-42F7-8423-E0E2F139B06C': {
    name: 'Glenview AYSO',
    color: 'rgb(19, 52, 126)',
    banner: '/clientAssets/Glenview/client_ayso362_images_next_banner_sm.png',
    favicon: '/clientAssets/Glenview/soccer_ball.svg',
  },
  '91C9461B-6056-4493-9C81-F560677CA10D': {
    name: 'Hollywood Wilshire AYSO',
    color: 'rgb(25, 35, 202)',
    banner: '/clientAssets/Hollywood/hw_banner_sm.png',
    favicon: '/clientAssets/Hollywood/soccer_ball.svg',
  },
  '1758E7C4-5D51-4975-9A5F-F12929ECBFF6': [
    {
      name: 'Beverly Hills AYSO',
      color: 'rgb(230, 123, 0)',
      banner: '/clientAssets/BeverlyHills/bh_banner_sm.png',
      favicon: '/clientAssets/BeverlyHills/soccer_ball.svg',
    },
    {
      variant: "area",
      name: 'Beverly Hills AYSO',
      color: 'rgb(0, 60, 120)',
      banner: '/clientAssets/AYSO-1-p/banner_sm.png',
      favicon: '/clientAssets/AYSO-1-p/soccer_ball.svg',
    },
  ],
  '71E92333-B0D8-46CF-BFD3-D8AEC2CD11A5': {
    name: 'Region 13 (Pasadena)',
    color: 'rgb(0, 69, 131)',
    banner: '/clientAssets/AYSO-13/AYSO13_900.jpg',
    favicon: '/clientAssets/AYSO-13/soccer_ball.svg'
  },
  '205E682E-651D-4D36-8186-202D6837A67D': {
    name: 'AYSO Region 425',
    color: 'rgb(0,	100,	162)',
    banner: '/clientAssets/AYSO425/banner425.png',
    favicon: '/clientAssets/AYSO425/soccer_ball.svg'
  },
  'D8B4C2C7-E40C-4432-92A4-5FE32E4F6CF8' : {
    name : 'SCS AYSO (Region 158)',
    color: 'rgb(0, 198, 1)',
    banner: '/clientAssets/AYSO158/banner_sm.png',
    favicon: '/clientAssets/AYSO158/soccer_ball.svg'
  }
}

/**
 * When the value associated with a clientID is an object type, it is the only clientTheme available for that client.
 * Otherwise, it is an array, satisfying the following:
 *  - length is GTE 1
 *  - the first element is always the "default" theme for its client
 *  - subsequent ThemeDefs are relevant when matched by their `variant` tag
 */
interface ClientsThemeDefsByClientID {
  [clientID: string]: ClientThemeDef | ClientThemeDef[]
}

export interface ClientThemeDef {
  variant?: string,
  name: string
  color: string
  banner: string
  favicon: string
}

export function maybeFindClientThemeVariant(targetVariant: undefined | string, themeVariants: ClientThemeDef[]) : ClientThemeDef | undefined {
  if (!targetVariant) {
    // provision to not run search with falsy target, esp. because `.variant` property we're searching over can be undefined and match erroneously
    return undefined;
  }
  else {
    return themeVariants.find(v => v.variant === targetVariant)
  }
}
