import { computed, defineComponent, onMounted, ref } from "vue";
import { propsDef } from "./R_SelectPaymentOption.route";
import { AxiosInstance } from "axios";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { axiosInstance } from "src/boot/AxiosInstances";
import { UiOption, assertNonNull, parseIntOr, parseIntOrFail, sortBy } from "src/helpers/utils";
import { useRouter } from "vue-router";
import { FormKit } from "@formkit/vue";
import { Btn2 } from "../UserInterface/Btn2";
import * as R_ConfirmRegistration from "./confirmation/R_ConfirmRegistration.route";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { MaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData, UpdateCompregPaymentSchedulesRequest, getMaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData, updateCompregPaymentSchedules } from "src/composables/InleagueApiV1.Registration";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { deleteInvoice } from "src/composables/InleagueApiV1.Invoice";
import { Step, RegistrationJourneyBreadcrumbElement } from "./RegistrationJourneyBreadcrumb";

export default defineComponent({
  props: propsDef,
  setup(props) {
    const ready = ref(false)
    const viewData = ref<MaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData | null>(null);
    const selectedPaymentMethodIDByCompRegID = ref<{[compRegID: Integerlike]: "" | Integerlike}>({});
    const router = useRouter();

    const optionsByCompRegID = computed(() => {
      const result : {[compRegID: Integerlike]: UiOption[]} = {}

      viewData.value?.perCompetitionRegistration.forEach(v => {
        result[v.competitionRegistrationID] = v.paymentMethods.map(opt => {
          return {
            label: opt.label,
            value: opt.methodID.toString(),
            attrs: {
              disabled: v.canChoosePaymentMethods ? false : true
            }
          }
        })
      })

      return result;
    })

    const doSubmit = async () : Promise<void> => {
      if (!viewData.value) {
        return; // shouldn't happen
      }

      const viewData_ = viewData.value;

      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          //
          // void voidable invoices
          // updateCompregPaymentSchedules could do this for us, but we don't send it the "canChoosePaymentMethods=false" compregs,
          // even though "the current collection of compregs" is a package deal. We try to void everything so that subsequent "create invoice" calls
          // collect compregs appropriately onto the fewest invoices possible.
          //
          // n.b. make sure to uniquify because N compregs can share 1 invoice, and we cannot call `delete` on the same invoice more than once.
          //
          for (const invoiceInstanceID of new Set(
            viewData_
              .perCompetitionRegistration
              .filter(v => v.hasVoidableInvoice && parseIntOr(v.invoiceInstanceID, null) !== null)
              .map(v => parseIntOrFail(v.invoiceInstanceID))
          )) {
            await deleteInvoice(axiosInstance, {invoiceInstanceID})
          }

          const args : UpdateCompregPaymentSchedulesRequest = viewData_
            .perCompetitionRegistration
            .filter(v => v.canChoosePaymentMethods) // only submit those that we were told are submittable
            .map(v => {
              return {
                paymentMethodID: selectedPaymentMethodIDByCompRegID.value[v.competitionRegistrationID],
                competitionRegistrationID: v.competitionRegistrationID,
              }
            })

          await updateCompregPaymentSchedules(axiosInstance, args)
        })

        await nextJourneyStep("push")
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const nextJourneyStep = async (which: "push" | "replace") : Promise<void> => {
      const route = R_ConfirmRegistration.routeDetailToRouteLocation({
        registrationID: props.detail.registrationID,
        seasonUID: props.detail.seasonUID,
        competitionUIDs: props.detail.competitionUIDs,
        playerID: props.detail.playerID,
      })

      if (which === "push") {
        await router.push(route)
      }
      else {
        await router.replace(route)
      }
    }

    onMounted(async () => {
      const paymentOptions_ = await getMaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData(axiosInstance, {
        playerID: props.detail.playerID,
        seasonUID: props.detail.seasonUID,
        competitionUIDs: props.detail.competitionUIDs
      });

      if (
        paymentOptions_.perCompetitionRegistration.every(opt => !opt.canChoosePaymentMethods
            || (opt.paymentMethods.length === 1 && opt.currentPaymentMethodID /*not strict*/ == opt.paymentMethods[0].methodID))
      ) {
        await nextJourneyStep("replace");
        return;
      }

      paymentOptions_.perCompetitionRegistration.sort(sortBy(_ => parseIntOr(_.competitionID, -1)));

      viewData.value = paymentOptions_
      selectedPaymentMethodIDByCompRegID.value = {}

      paymentOptions_.perCompetitionRegistration.forEach(opt => {
        selectedPaymentMethodIDByCompRegID.value[opt.competitionRegistrationID] = opt.currentPaymentMethodID
      })

      ready.value = true;
    })

    return () => {
      if (!ready.value || !viewData.value) {
        return null;
      }

      return (
        <div data-test="R_SelectPaymentOption">
          <div class="mb-6">
            <RegistrationJourneyBreadcrumbElement detail={{
              step: Step.reviewAndPay,
              competitionUIDs: props.detail.competitionUIDs,
              seasonUID: props.detail.seasonUID,
              playerID: props.detail.playerID
            }}/>
          </div>
          <FormKit type="form" actions={false} onSubmit={doSubmit}>
            <h2>Payment Options</h2>
            {
              viewData.value.perCompetitionRegistration.map(opt => {
                return (
                  <div key={opt.competitionUID} class="my-6 bg-white shadow-md rounded-md">
                    <div class="p-2 bg-gray-200 rounded-t-md">{opt.competition}</div>
                    <div class="p-2 bg-white rounded-b-md" style="--fk-margin-outer:none; --fk-border:none;" data-test={opt.competitionRegistrationID}>
                      {
                        opt.canChoosePaymentMethods
                          ? (
                            <FormKit type="radio" name={opt.competitionUID}
                              v-model={selectedPaymentMethodIDByCompRegID.value[opt.competitionRegistrationID]}
                              options={optionsByCompRegID.value[opt.competitionRegistrationID]}
                              validation={[["required"]]}
                              validationMessages={{
                                required: `A selection for ${opt.competition} is required.`
                              }}
                            />
                          )
                          // If the user can't choose, the backend guarantees they will provide exactly 1 "option" with an informative label
                          // The user can't choose it but it's something to show how it fits in with the rest of the compregs (where a choice
                          // for the others _is_ required).
                          : opt.paymentMethods[0].label
                      }
                    </div>
                  </div>
                )
              })
            }
            <Btn2 type="submit" class="p-2" data-test="submit">Continue with registration</Btn2>
          </FormKit>
        </div>
      )
    }
  }
})
