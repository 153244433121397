<template lang="pug">
.t-page.flex.flex-center
  div
    h1.text-4xl.self-end.font-medium
      i.mr-2.fas.fa-user-lock
      | Reset Password
    p Please create your new password
    t-alert(
      v-if='errorMessage',
      title='Error:',
      :message='errorMessage',
      type='danger'
    )
    t-alert(
      v-if='successMessage',
      title='',
      :message='successMessage',
      type='success'
    )
    FormKit.d-pa-md.d-gutter-sm(v-if="ready" @submit='setPwd' @keyup.enter='setPwd', type='form', :actions='false')
      //- the email input is a 1-way binding, and it's disabled
      //- this page is only good for one email address, the user won't be changing it
      //- email's :value="email" is init'd to "" so we have to wait for ready (by which time we've updated it)
      //- we initialize `email` to the route query param `email` in onMounted
      FormKit(
        name='email',
        :value='email',
        :disabled="true"
        label='Email Address',
        validation='email',
        data-cy='email'
      )
      FormKit(
        name='password',
        type="password",
        autocomplete="new-password"
        v-model='password',
        label='New Password',
        validations='required|min:8,length',
        data-test='new-password'
      )
      FormKit(
        name='password_confirm',
        v-model='confirmPassword',
        type="password",
        autocomplete="new-password"
        label='Confirm Password',
        validations='required|min:8,length|confirm:password',
        data-test='password-confirm'
      )
      TBtn(
        :margin='false',
        type='submit',
        data-test='submit',
        label='Set New Password'
      )
      p(v-if='apiError') {{ apiError }}
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { axiosNoAuthInstance } from 'boot/axios'
import { useRoute, useRouter } from 'vue-router'


import { exhaustiveCaseGuard } from 'src/helpers/utils'
import * as ilauth from 'src/composables/InleagueApiV1.Authenticate'
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"
import { System } from 'src/store/System'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'ResetPassword',
  components: {},
  setup() {
    const $route = useRoute()
    const router = useRouter();
    const email = ref('')
    const password = ref('')

    const confirmPassword = ref('')
    const apiError = ref('')
    const successMessage = ref('')
    const errorMessage = ref('')
    const errors = ref(false)
    const ready = ref(false)

    const setPwd = async () : Promise<void> => {
      const args : ilauth.public_.FinalizePasswordResetArgs = {
        email: email.value,
        resetKey: $route.query.resetKey as string,
        newPassword: password.value,
      };

      const worker = () : Promise<void> => ilauth
        .public_
        .finalizePasswordReset(axiosNoAuthInstance, args)
        .then(() => {
          const username = $route.query.email as string
          const pwd = password.value
          User
            // `leagueSelected` is true, because a password reset should always be attempted against a particular league,
            // meaning, if the finalizePasswordReset request was succesful, it is because we attempted against a particular league,
            // and so we are here definitely configured to (with regards to axios target api URL) point at a particular league
            .login({ username, password: pwd, leagueSelected: true })
            .then(async res => {
              if (res.ok) {
                successMessage.value = 'Your password has changed succesfully'
                errorMessage.value=''

                if (res.type === "single") {
                  if (res.data.status === "complete") {
                    await User.loginUser(res.data);
                    // We have to perform a page navigation for chrome to offer the "save updated password?" prompt
                    // Chrome appears to use the **state of the form on this page and not any HTTP requests**
                    // as the source of "updated password and the associated username" info
                    if (System.value.isMobile) {
                      return await router.push({name: 'mobile-landing'})
                    }
                    else {
                      return await router.push({name: 'home'})
                    }
                  }
                  else if (res.data.status === "needs-mfa-challenge" || res.data.status === "needs-mfa-init") {
                    const fragment = ilauth.loginURLFragment({
                      what: "mfa-from-elsewhere",
                      data: res.data
                    });
                    return await router.push({path: "/login", hash: fragment});
                  }
                  else {
                    exhaustiveCaseGuard(res.data);
                  }
                }
                else if (res.type === "multi") {
                  //
                  // this probably cannot happen, because a successful password reset is
                  // against a specific client for a specific user having a specific serverside "change your email" token
                  //
                  const fragment = ilauth.loginURLFragment({
                      what: "multiple-leagues",
                      availableLeagues: res.data,
                      email: args.email
                    });
                    return router.push({path: "/login", hash: fragment});
                }
                else {
                  exhaustiveCaseGuard(res);
                }
              } else {
                // console.log('in else above')
                errorMessage.value = 'ERROR: ' + JSON.stringify(res.msg)
              }
            })
            .catch((error: any) => {
              // console.log('in catch block 1')
              errorMessage.value = error.response.data.messages[0]
            })
        })
        .catch((error: any) => {
          // console.log('in catch block 2')
          errorMessage.value = error.response.data.messages[0]
        })

        try {
          GlobalInteractionBlockingRequestsInFlight.increment();
          await worker();
        }
        finally {
          GlobalInteractionBlockingRequestsInFlight.decrement();
        }
    }

    onMounted(()=> {
      if (!$route.query.resetKey) {
        errorMessage.value = 'Form will not work because ResetKey is missing.'
      }

      // todo: what if there is no email value?
      if($route.query.email) {
        email.value = $route.query.email as string
      }

      ready.value = true;
    })

    return {
      setPwd,
      email,
      password,
      confirmPassword,
      apiError,
      successMessage,
      errorMessage,
      errors,
      ready
    }
  },
})
</script>
