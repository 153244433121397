import { computed, defineComponent, ExtractPropTypes, ref } from "vue";

import { ExtractEmitsHandlers, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { Btn2 } from "../UserInterface/Btn2";

export interface MinimalSearchResult {
  entityID: Guid,
  name: string,
}

const propsDef = {
  entitiesHavingSignups: vReqT<Set<Guid>>(),
  entitySearcher: vReqT<(_: string) => Promise<MinimalSearchResult[]>>()
} as const;

const emitsDef = {
  entitySelected: (_: Guid) => true
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;

export const ForceAddSignupSearcher = defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit, slots}) {
    const userInput = ref("");
    const resultHolder = ref<null | MinimalSearchResult[]>(null);
    const doLookup = async () => {
      resultHolder.value = await props.entitySearcher(userInput.value);
    }
    const defaultSlotProps = computed(() => {
      return {
        hasResults: resultHolder.value !== null,
        clearResults: () => { resultHolder.value = null; }
      }
    })
    return () => (
      <form
        class="inline-block"
        onSubmit={(event) => {event.preventDefault(); doLookup();}}
      >
        {slots.default?.(defaultSlotProps)}
        <div class="flex items-center">
          <input type="text"
            class="rounded-md p-1"
            v-model={userInput.value}
          />
          <div class="inline-block pl-2">
            <Btn2 class="px-2 py-1" type="submit">
              <div>Search</div>
            </Btn2>
          </div>
        </div>
        {
          resultHolder.value === null
          ? null
          : resultHolder.value.length === 0
          ? <div>No results</div>
          : (
            <div>
              {
                resultHolder.value.map((result, idx) => {
                  return (
                    <div class={`${idx % 2 ? "bg-gray-100" : ""} p-1 flex items-center width-full gap-2`} data-test={`entityID=${result.entityID}`}>
                      <span>{result.name}</span>
                      <span class="text-xs ml-auto">
                        {
                          props.entitiesHavingSignups.has(result.entityID)
                            ? <span>already has signup</span>
                            : (
                              <Btn2 class="px-2 py-1" type="button" onClick={() => emit("entitySelected", result.entityID)}>
                                Add
                              </Btn2>
                            )
                        }
                      </span>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </form>
    )
  }
})
