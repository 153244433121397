<template lang="pug">
div(data-cy='selectLeague')
  t-alert(v-if='error', title='', :message='error', type='error')
  FormKit(class='sm:mx-auto sm:w-full sm:max-w-md', type='form')
  .bg-green-00.py-3.px-3.text-green-800(class='sm:px-10')
    FormKit(
      v-model='homeLeague',
      :options='selectOptions',
      type='select',
      placeholder='Select your league',
      label='Home League'
    )
  t-btn(
    @click='saveLeague',
    label='Create New User',
    data-cy='selectLeagueBtn'
  )
</template>

<script lang="ts">
import { Plugins } from '@capacitor/core'
import { Location, LeagueLocation } from 'src/interfaces/location'
import { axiosInstance, updateApiUrl } from 'src/boot/axios'
import { useRouter } from 'vue-router'
const { Geolocation } = Plugins
// const { Storage } = Plugins

import { defineComponent, onMounted, ref, Ref } from 'vue'
export default defineComponent({
  name: 'SelectLeague',
  components: {},
  setup() {
    const router = useRouter()
    const location = ref({}) as Ref<Location>
    const homeLeague = ref('0')
    const sortedLeagues = ref([]) as Ref<LeagueLocation[]>
    const selectOptions = ref({})
    const error = ref('')

    // methods
    const createSelectOptions = (leagues: LeagueLocation[]) => {
      const selectOptions: { [key: string]: string } = {}
      for (let i = 0; i < leagues.length; i++) {
        selectOptions[i] = leagues[i].leagueCity
      }
      // // console.log('increateSelectOptioons', selectOptions)
      return selectOptions
    }

    const convertKmToMiles = (km: number) => {
      return km * 0.621371192
    }

    const toRad = (degrees: number) => {
      return (degrees * Math.PI) / 180
    }

    const getDistance = (location1: Location, location2: Location) => {
      const radLat1 = toRad(location1.latitude)
      const radLat2 = toRad(location2.latitude)
      const dLat = toRad(location2.latitude - location1.latitude)
      const dLon = toRad(location2.longitude - location1.longitude)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(radLat1) *
          Math.cos(radLat2) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = 6371 * c // distance in km
      const distance = convertKmToMiles(d)
      return distance
    }

    const sortLeagues = (leagues: LeagueLocation[]) => {
      return leagues.sort((league1, league2) => {
        return (
          getDistance(
            { latitude: league1.latitude, longitude: league1.longitude },
            location.value
          ) -
          getDistance(
            { latitude: league2.latitude, longitude: league2.longitude },
            location.value
          )
        )
      })
    }

    const saveLeague = async () => {
      if (!homeLeague.value) {
        error.value = 'Please select a league'
      } else {
        await updateApiUrl(`https://${sortedLeagues.value[parseInt(homeLeague.value)].appDomain}/api`)
        await router.push('new-user')
      }
    }

    // lifecycles
    onMounted(async () => {
      Geolocation.getCurrentPosition().then((position: any) => {
        // // console.log('current', position)
        location.value = {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        }
      })
      try {
        const response = await axiosInstance.get('public/leagues')
        const leagueList = response.data.data
        // // console.log('leagueList', leagueList)
        sortedLeagues.value = sortLeagues(leagueList)
        // // console.log('sorted leagues', sortedLeagues)
        selectOptions.value = createSelectOptions(sortedLeagues.value)
      } catch (err: any) {
        error.value = err.data.message
        // // console.error(err)
      }
    })

    return {
      error,
      homeLeague,
      selectOptions,
      saveLeague,
    }
  },
})
</script>
