import { type AxiosInstance } from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Child, Floatlike, Guid, Integerlike, Numbool, Registration } from "src/interfaces/InleagueApiV1";

export async function uploadPhoto(axios: AxiosInstance, args: {playerID: iltypes.Guid, photo: Blob}) : Promise<void> {
  const form = new FormData();
  form.append("uploadDocument", args.photo);
  await axios.post(
    `v1/player/${args.playerID}/photo`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

// as opposed to {K,M}iB
const KB = 1000;
const MB = KB * 1000;

export const uploadPhotoMeta = {
  maxSizeBytes: 5 * MB,
  accept: [".jpg",".png",".gif",".bmp",".tif",".tiff"].join(","),
} as const;
