import { ref, computed, reactive } from "vue"
import type { PropType, ExtractPropTypes } from "vue"
import type { Child, Competition } from "src/interfaces/InleagueApiV1"
import type { ExtractEmitsHandlers, ExtractOnEmitsHandlers } from "src/helpers/utils";

export function propsDef() {
  return {
    player: {
      required: true as true,
      type: Object as PropType<Child>
    }
  }
}

export function emitsDef() {
  return {
    close: () => true,
    successfulUpload: (_player: Child) => true,
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
export type OnEmits = ExtractOnEmitsHandlers<ReturnType<typeof emitsDef>>;

/**
 * contractual guarantee -- if visible=true, then {props, handlers} are in a valid state,
 * suitable for binding to the target component with {v-bind,v-on}
 */
export function DefaultController(handlers: Emits) {
  const private_ = {
    visible: ref(false),
    props: ref<Props | null>(null),
  }

  const show = (player: Child) => {
    private_.props.value = {player}
    private_.visible.value = true;
  }

  const hide = () => {
    private_.props.value = null;
    private_.visible.value = false;
  }

  return reactive({
    visible: computed(() => private_.visible.value),
    props: computed(() => private_.props.value),
    handlers: handlers,
    show,
    hide
  })
}

export function shouldShowBirthCertificateUpload(player: Pick<Child, "birthCertificate">, isRegisteredInTheseCompetitions: Competition[]) {
  if (player.birthCertificate) {
    // they already have a birth certificate uploaded
    return false;
  }
  else {
    // any of the competitions in which they are registered is flagged "enable birth ceritificate collection"
    return isRegisteredInTheseCompetitions.some(competition => competition.enableBirthCertificateCollection);
  }
}

export interface Injectable {
  /**
   * we give up on the upload after this amount of time. The server may be configured differently.
   */
  uploadTimeout_ms: number,
  /**
   * show friendly warning in connection timeout cases if file size is greater than this
   */
  uploadWarning_if_gt_bytes: number,
  /**
   * ui version of file size warning; e.g. 1MB insted of 1,048,576
   */
  uploadWarning_uiSize: string,
}
// would be nice to get this at compiletime, like a __FILE__ macro
export const FILE = "BirthCertificateUploadModal.ilx"
if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
  (window as any)[FILE] = {};
}

const DefaultInject : Injectable = {
  /**
   * this was 45 seconds but we were getting noticeable amount of timeouts in error traces.
   */
  uploadTimeout_ms: 2 * 60 * 1000,
  uploadWarning_if_gt_bytes: 1024 * 1024,
  uploadWarning_uiSize: "1 MB"
} as const

function __getInjection__object() : Injectable {
  return (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING)
    ? (window as any)[FILE]
    : DefaultInject;
}

export function getInjection<K extends keyof Injectable>(k: K) : Injectable[K] {
  // this is broken if we want to inject null or undefined
  return __getInjection__object()[k] ?? DefaultInject[k];
}
