import { PropType, ExtractPropTypes } from "vue"
import { Competition, CompetitionRegistration, Guid } from "src/interfaces/InleagueApiV1"
import { PlayerDetailsI } from "src/interfaces/Store/registration";
import { ExtractEmitsHandlers } from "src/helpers/utils";

export function propsDef() {
  return {
    target: {
      required: true as const,
      type: Object as PropType<{
        player: PlayerDetailsI,
        competition: Competition,
        registrationID: Guid,
        competitionRegistration: CompetitionRegistration,
      }>
    },
  }
}

export interface CompetitionRegistrationForceActivationEvent {
  competitionRegistration: CompetitionRegistration,
}

export function emitsDef() {
  return {
    confirmActivation: (event: CompetitionRegistrationForceActivationEvent) => true,
    doNotActivate: () => true,
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
