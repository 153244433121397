import { FormKit, FormKitMessages } from "@formkit/vue"
import { assertIs, assertNonNull, assertTruthy, exhaustiveCaseGuard, FK_nodeRef, UiOption, vReqT } from "src/helpers/utils"
import { defineComponent, onMounted, ref, watch } from "vue"
import { RatingType, SingleFormRow_Nothing, freshRatingConfigForm, SingleFormRow_Numeric, SingleFormRow_Text, ClientRatingForm, NumericRatingForm } from "./ClientRatingsConfiguratorElems"
import { Btn2 } from "../UserInterface/Btn2"
import { ClientRating } from "./ClientRatingsConfigurator.io"
import { Guid } from "src/interfaces/InleagueApiV1"

export const ClientRatingsConfigurator = defineComponent({
  props: {
    clientRatings: vReqT<{core: ClientRating, custom: ClientRating[], hasSomeExistingCoreRating: boolean}>(),
  },
  emits: {
    submit: (_: ClientRatingFormEvent) => true,
  },
  setup(props, ctx) {
    const ratingTypeOptions : UiOption<RatingType | "">[] = [
      {label: "", value: ""},
      {label: "Numeric", value: RatingType.numeric},
      {label: "Text", value: RatingType.text},
    ]

    const clientRatingConfigForm = ref<
      | null
      // note that we expect the first element here to always "overall element", a numeric rating,
      // and that the length of this array is always exactly MAX_RATINGS_COUNT
      | (ClientRatingConfigFormWrapper | ClientRatingConfigSentinelWrapper)[]
    >(null);

    const MAX_RATINGS_COUNT = 10
    watch(() => clientRatingConfigForm.value, () => {
      // really this is a tuple of MAX_RATINGS elements
      assertTruthy(!clientRatingConfigForm.value || clientRatingConfigForm.value.length === MAX_RATINGS_COUNT);
    }, {immediate: true})

    const handleChangeRatingType = (newRatingType: "" | RatingType, zi_elemIdx: number ) => {
      assertNonNull(clientRatingConfigForm.value)
      assertTruthy(zi_elemIdx < clientRatingConfigForm.value.length)

      if (zi_elemIdx === 0) {
        // can't change the first element's type, form shouldn't allow it to happen,
        // users messing with DOM elements could probably make it happen though
        return;
      }

      const currentElemThisPos = clientRatingConfigForm.value[zi_elemIdx]

      if (currentElemThisPos.type === "sentinel") {
        if (!newRatingType) {
          // hm, shouldn't happen, but not really a failure state;
          // "no current config" -> "no current config"
          return;
        }
        else {
          // "no current config" -> "some config"
          clientRatingConfigForm.value[zi_elemIdx] = {
            type: "form",
            value: freshRatingConfigForm({ratingID: currentElemThisPos.lastKnownRatingIdThisSlot, ratingType: newRatingType})
          }
          return;
        }
      }
      else {
        if (!newRatingType) {
          // "some current config" -> "no config"
          clientRatingConfigForm.value[zi_elemIdx] = {
            type: "sentinel",
            lastKnownRatingIdThisSlot: currentElemThisPos.value.ratingID
          }
          return;
        }
        else {
          // "some current config" -> "some other config"
          clientRatingConfigForm.value[zi_elemIdx] = {
            type: "form",
            value: freshRatingConfigForm({ratingID: currentElemThisPos.value.ratingID, ratingType: newRatingType})
          }
          return;
        }
      }
    }

    const handleSubmit = async () : Promise<void> => {
      assertNonNull(clientRatingConfigForm.value)
      const core = (() => {
        const v = clientRatingConfigForm.value[0]
        assertIs(v.type, "form");
        assertIs(v.value.type, "numeric")
        return v.value
      })();

      ctx.emit("submit", {
        core,
        // n.b. keys are one-indexed, but we're sourcing from an array which is zero-indexed
        2: maybeUnwrap(clientRatingConfigForm.value[1]),
        3: maybeUnwrap(clientRatingConfigForm.value[2]),
        4: maybeUnwrap(clientRatingConfigForm.value[3]),
        5: maybeUnwrap(clientRatingConfigForm.value[4]),
        6: maybeUnwrap(clientRatingConfigForm.value[5]),
        7: maybeUnwrap(clientRatingConfigForm.value[6]),
        8: maybeUnwrap(clientRatingConfigForm.value[7]),
        9: maybeUnwrap(clientRatingConfigForm.value[8]),
        10: maybeUnwrap(clientRatingConfigForm.value[9]),
      });

      function maybeUnwrap(v: ClientRatingConfigFormWrapper | ClientRatingConfigSentinelWrapper) : ClientRatingForm | undefined {
        if (v.type === "sentinel") {
          return undefined;
        }
        else {
          return v.value
        }
      }
    }

    const ready = ref(false)

    onMounted(async () => {
      const elems : (ClientRatingConfigFormWrapper | ClientRatingConfigSentinelWrapper)[] = [props.clientRatings.core, ...props.clientRatings.custom]
        .map(v => ({type: "form", value: ClientRatingForm(v)}))

      {
        // pad out array with sentinel values, if it's not already full
        const requiredDefaults = MAX_RATINGS_COUNT - elems.length;
        for (let i = 0; i < requiredDefaults; i++) {
          elems.push({type: "sentinel", lastKnownRatingIdThisSlot: undefined});
        }
      }

      clientRatingConfigForm.value = elems

      ready.value = true;
    });

    const fkNodeRef = FK_nodeRef();

    return () => {
      if (!ready.value || !clientRatingConfigForm.value) {
        return null;
      }

      return (
        <div data-test="ClientRatingsConfigurator">
          <div class="rounded-md shadow-md bg-white">
            <FormKit type="form" actions={false} ref={fkNodeRef} onSubmit={handleSubmit}>
              <div class="p-2 bg-green-900 rounded-t-md text-white">League Rating Configuration</div>
              <div class="p-2 rounded-md overflow-auto">
                <p class="p-1 text-sm rounded-md">
                  You can define up to 10 types of player ratings.
                  Player ratings may either be numeric or preset text selections from a dropdown menu.
                  Numeric ranges may use any integer scale and may be <strong>ascending</strong> (higher numbers are better) or <strong>descending</strong> (lower numbers are better).
                </p>
                <div class="border-b my-1"/>
                <p class="p-1 text-sm rounded-md mb-2"><strong>Note:</strong> Rating fields should be entered as brief labels, e.g. 'speed' or 'attitude.' You may enter more detailed instructions on the ratings entry page. Every league must have a numeric overall rating.</p>
                <table
                  style="--fk-padding-input: .375em; border-collapse:collapse; --fk-margin-outer:none; width:100%; min-width:1000px;"
                  class="[&_tr_td]:py-1 [&_tr_td]:px-2">
                  {clientRatingConfigForm.value.map((e, i) => {
                    const isFirst = i === 0
                    return <>
                      {isFirst ? null : <td colspan="999"><div class="my-1 border-b border-gray-300"/></td>}
                      {(() => {
                        if (e.type === "sentinel") {
                          return <SingleFormRow_Nothing
                            oi_index={i+1}
                            ratingTypeOptions={ratingTypeOptions}
                            onChangeType={ratingType => handleChangeRatingType(ratingType, i)}
                            data-test={`rating${i + 1}`}
                          />
                        }

                        switch (e.value.type) {
                          case "numeric":
                            return <SingleFormRow_Numeric
                              oi_index={i+1}
                              isSpecialCoreRating={isFirst}
                              hasSomeExistingCoreRating={props.clientRatings.hasSomeExistingCoreRating}
                              ratingTypeOptions={ratingTypeOptions}
                              data={e.value}
                              onChangeType={ratingType => handleChangeRatingType(ratingType, i)}
                              data-test={`rating${i + 1}`}
                            />
                          case "text":
                            return <SingleFormRow_Text
                              oi_index={i+1}
                              ratingTypeOptions={ratingTypeOptions}
                              data={e.value}
                              onChangeType={ratingType => handleChangeRatingType(ratingType, i)}
                              data-test={`rating${i + 1}`}
                            />
                          default: exhaustiveCaseGuard(e.value)
                        }
                      })()}
                    </>
                  })}
                </table>
                <div class="mt-2">
                  <Btn2 type="submit" class="px-2 py-1">Submit</Btn2>
                  <FormKitMessages node={fkNodeRef.value?.node}/>
                </div>
              </div>

            </FormKit>
          </div>
        </div>
      )
    }
  }
})

export type ClientRatingConfigFormWrapper = {type: "form", value: ClientRatingForm}

/**
 * Sentinel object representing last known ratingID for this slot, or null if no such thing
 */
export type ClientRatingConfigSentinelWrapper = {type: "sentinel", lastKnownRatingIdThisSlot: Guid | undefined}

export type ClientRatingFormEvent = {
  core: NumericRatingForm,
  2: undefined | ClientRatingForm,
  3: undefined | ClientRatingForm,
  4: undefined | ClientRatingForm,
  5: undefined | ClientRatingForm,
  6: undefined | ClientRatingForm,
  7: undefined | ClientRatingForm,
  8: undefined | ClientRatingForm,
  9: undefined | ClientRatingForm,
  10: undefined | ClientRatingForm,
}
