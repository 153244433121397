<template lang="pug">
t-dialog(v-if='show')
  h1 Duplicate User Found
  p We have found another user with similar details in our system. Please confirm that you are not the user with e-mail address: ************{{ email }}
  .flex
    t-btn(
      @click='$emit("close-popup")',
      label='That isn\'t me',
      data-cy='closePopup'
    )
    t-btn(
      @click='forgotPassword',
      label='Recover Account',
      data-cy='recoverAccount'
    )
    t-btn(
      @click='$emit("clear-form")',
      label='Create Different User',
      data-cy='differentUser'
    )
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    email: String,
  },
  setup() {
    // Data
    const $router = useRouter()
    const show = true

    // Methods
    const forgotPassword = async () => {
      await $router.push('forgot-password')
    }
    return {
      show,
      forgotPassword,
    }
  },
})
</script>
