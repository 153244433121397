import { Guid, Datelike, Competition, Season, CompetitionSeason } from "src/interfaces/InleagueApiV1";
import { rpc } from "./Rpc";

export const xlsxExport_menu_getCompetitions = rpc<void, Competition[]>("get", "v1/mojoExports/xlsxExport_menu_getCompetitions")
export const xlsxExport_menu_getSeasons = rpc<void, Season[]>("get", "v1/mojoExports/xlsxExport_menu_getSeasons")
export const xlsxExport_menu_getCompetitionSeason = rpc<{competitionUID: Guid, seasonUID: Guid}, {competitionSeason: CompetitionSeason | null}>("get", "v1/mojoExports/xlsxExport_menu_getCompetitionSeason")

export const xlsxExport_player_apiPath = (args: {competitionUID: Guid, seasonUID: Guid}) => [
  `/api/v1/mojoExports/xlsxExport_player`,
  `?competitionUID=${args.competitionUID}`,
  `&seasonUID=${args.seasonUID}`,
].join("")

export const xlsxExport_coachTeamAssignments_apiPath = (args: {competitionUID: Guid, seasonUID: Guid}) => [
  `/api/v1/mojoExports/xlsxExport_coachTeamAssignments`,
  `?competitionUID=${args.competitionUID}`,
  `&seasonUID=${args.seasonUID}`,
].join("")

export const xlsxExport_games_apiPath = (args: {competitionUID: Guid, seasonUID: Guid, dateFrom: Datelike, dateTo: Datelike}) => [
  `/api/v1/mojoExports/xlsxExport_games`,
  `?competitionUID=${args.competitionUID}`,
  `&seasonUID=${args.seasonUID}`,
  `&dateFrom=${args.dateFrom}`,
  `&dateTo=${args.dateTo}`,
].join("")
