<template lang="pug">
.flex.flex-col(data-test="AdminPlayers")
  .overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
    h3.bg-gray-200.min-w-full.rounded-t-lg.px-4.py-2.font-medium Players
    .align-middle.inline-block.min-w-full
      .shadow.overflow-hidden.border-b.border-gray-200
        table.min-w-full.divide-y.divide-gray-200
          thead.bg-gray-50
            tr
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Name
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Birth Date
              th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col') Registration in Seasons
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='player in players', :key='player.childID' :data-test="`childID=${player.childID}`")
              td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900(
                v-if='familyID === player.familyID'
              )
                | {{ player.playerFirstName }} {{ player.playerLastName }}
              td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500(
                v-if='familyID === player.familyID'
              )
                | {{ dayJSDate(player.playerBirthDate) }}
              td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                SeasonsListingByChildIDResolver(
                  :childID="player.childID",
                  :p_seasonsListingByChildID="p_seasonsListingByChildID"
                )

    h3.bg-gray-200.min-w-full.px-4.py-2.font-medium Add Player to Family
    p.px-4.py-2 Enter part of a player's name or email address below to add them to this family profile.
    .flex.px-4.items-center
      FormKit(type='text', v-model='searchValue')
      .ml-2
        FormKit(type='button', label='Search Players', @click='lookupPlayers')

    div(v-if='playerSearchValues.length > 0')
      table.min-w-full.divide-y.divide-gray-200
        thead.bg-gray-50
          tr
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
              scope='col'
            )
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
              scope='col'
            ) Name
            th.px-6.py-3.text-left.text-xs.font-medium.text-gray-500.uppercase(
              scope='col'
            ) Birthdate
        tbody.bg-white.divide-y.divide-gray-200
          tr(v-for='player in playerSearchValues', :key='player.childID')
            td.px-6.py-4.text-sm.font-medium.text-gray-900.flex.items-center
              FormKit(
                type='radio',
                v-model='movePlayerID',
                :options='{ [player.childID]: "" }',
                fieldsetClass='noBorder'
              )
            td.px-6.py-4.text-sm.font-medium.text-gray-900
              | {{ player.playerFirstName }} {{ player.playerLastName }}
            td.px-6.py-4.text-sm.text-gray-500
              | {{ dayJSDate(player.playerBirthDate) }}
      .px-4.my-2
        FormKit(type='button', label='Move Player', @click='movePlayer')
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { axiosInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import { dayJSDate } from 'src/helpers/formatDate'
import * as ilapi from  "src/composables/InleagueApiV1"
import type { Child, PlayerSearchResult } from 'src/interfaces/InleagueApiV1';
import { propsDef } from "./AdminPlayers.ilx"
import { RegisteredSeasonsForChildrenResponse, getRegisteredSeasonsForChildren } from 'src/composables/InLeagueApiV1.Child';
import { SeasonsListingByChildIDResolver } from "./SeasonsListingByChildIDResolver"

export default defineComponent({
  components: {
    SeasonsListingByChildIDResolver
  },
  props: propsDef,
  setup(props) {
    const players = ref<Child[]>([])
    const playerSearchValues = ref<PlayerSearchResult[]>([])
    const searchValue = ref('')
    const movePlayerID = ref('')

    const p_seasonsListingByChildID = computed<Promise<RegisteredSeasonsForChildrenResponse>>(() => {
      const childIDs = players.value.map(v => v.childID)
      if (childIDs.length === 0) {
        // api requires a non-empty list, and we don't need to "ask for nothing" anyway
        return Promise.resolve({})
      }
      else {
        return getRegisteredSeasonsForChildren(freshNoToastLoggedInAxiosInstance(), {childIDs})
      }
    })

    const getPlayerDetails = () => {
      return ilapi.getAllChildrenOfFamily(axiosInstance, {familyID: props.familyID});
    }

    const movePlayer = async () => {
      try {
        const response = await axiosInstance.put(
          `/v1/child/${movePlayerID.value}/family`,
          { newfamilyID: props.familyID }
        )
        playerSearchValues.value = []
        searchValue.value = ''
        players.value = await getPlayerDetails()
        movePlayerID.value = ''
      } catch (error) {
        throw error
      }
    }

    const lookupPlayers = async () => {
      playerSearchValues.value = await ilapi.findPlayers(axiosInstance, {search: searchValue.value});
    }

    onMounted(async () => {
      players.value = await getPlayerDetails()
    })

    return {
      players,
      playerSearchValues,
      searchValue,
      movePlayerID,
      movePlayer,
      dayJSDate,
      lookupPlayers,
      p_seasonsListingByChildID,
    }
  }
})
</script>
