<template lang="pug">
t-dialog(v-if='show')
  p.text-red-600.mb-6 There are multiple families associated with this account.
    div Please select which family profile you would like to view.
  .flex.flex-col.justify-center
    div(
      v-for="(family, key) in families"
      @click='familyIdSelected(family.familyID)',
    )
      .flex.flex-col.rounded-md.border-2.border-grey-800.p-2.m-2.text-sm
        div.uppercase.tracking-wide.font-bold.text-base.text-gray-700 Parents
        div(v-for="parent in family.familyParents") {{ parent.firstName }} {{parent.lastName}}
          span.ml-2(v-if="parent.userID === family.parent1ID") (Parent 1)
          span.ml-2(v-else-if="parent.userID === family.parent2ID") (Parent 2)
        div.font-medium.mt-2.uppercase.tracking-wide.font-bold.text-base.text-gray-700 Players
        div(v-for="player in family.familyChildren") {{ player.playerFirstName }} {{ player.playerLastName }}
        .mt-2
          FormKit(
            type="button"
            label="View Family"
            wrapper-class="$reset"
          )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { propsDef, emitsDef } from './MultipleFamilies.ilx'

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(_props, {emit}) {
    const show = ref(true)
    return {
      show,
      familyIdSelected: (familyID: string) => {
        emit("familyIdSelected", familyID)
      }
    }
  },
})
</script>
