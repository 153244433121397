import { type AxiosInstance } from 'axios';

import * as ilapi from "src/composables/InleagueApiV1";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Guid } from 'src/interfaces/InleagueApiV1';

export type ExpandedPlayerSearchResult = iltypes.WithDefinite<ilapi.PlayerSearchResult, "teamAssignmentsCurrent">;

/**
 * we always expand teamAssignmentsCurrent, but we might skip doing so if seasonID is some seasonID (rather than "ALL");
 * for some seasonID target, we should be interested in the team assignments for that season (and they might not exist, but that's OK).
 */
export async function doPlayerSearch(axios: AxiosInstance, nameQuery: string, seasonID: "ALL" | iltypes.Integerlike, divIDs: Guid[]) : Promise<ExpandedPlayerSearchResult[]> {
  const data = await ilapi.playerSearch(axios, {nameQuery, seasonID: seasonID === "ALL" ? undefined : seasonID, expand: ["teamAssignmentsCurrent"], divIDs})
  return data as ExpandedPlayerSearchResult[];
}

export function makeLegacyTournamentRosterLink_justPathAndQuery(
  teamInfo: Pick<iltypes.TeamAssignment, "teamID" | "seasonID">
) : string {
  return `/rosters/tournament-roster.cfm?team=${teamInfo.teamID}&sortyBy=uniform&seasonID=${teamInfo.seasonID}`;
}
