<template lang="pug">
div Stripe Test
//- stripe-sheet(open="true")
//-   stripe-payment-sheet(
//-     publishable-key="pk_test_51H7YlAGfc9bVPDYej5WC48NnmZJilRvTx6BKdaBRhndJGoSVsK1ENdVur3a3CisDiaMg3E7soFghFdq2ZPSL5Dpj006BYfcoB0"
//-     show-label="false"
//-     should-use-default-form-submit-action="false"
//-     :handleSubmit="submitPayment"
//-     intent-type="setup"
//-   )
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Stripe } from '@capacitor-community/stripe';
import { StripeSheet, StripePaymentSheet } from '@stripe-elements/stripe-elements'
import { axiosInstance } from 'src/boot/axios';
import { useRoute } from 'vue-router'

import { Client } from 'src/store/Client';
import { CheckoutStore } from "src/store/CheckoutStore"

// const form = document.getElementById('open-modal-form')
// const resultElement = document.getElementById('result')
// const errorMessage = document.getElementById('error-message')
// const targetElement = document.getElementById('stripe');
// const modalElement = document.createElement('stripe-sheet') as any

// const setup = async () => {
//     await customElements.whenDefined('stripe-sheet')
//       .then(() => {
//           modalElement.addEventListener('close', () => {
//               modalElement.innerHTML = ''
//           })
//       })

//     form?.addEventListener('submit', (event) => {
//         event.preventDefault()
//         const stripePublishableAPIKey = document['open-modal-form']['stripe-publishable-api-key'].value
//         if (!stripePublishableAPIKey) {
//             (errorMessage as HTMLElement).innerText = 'Stripe Publishable API Key is required'
//             return
//         }
//         const paymentIntentClientSecret = document['open-modal-form']['intent-client-secret'].value
//         if (!paymentIntentClientSecret) {
//             (errorMessage as HTMLElement).innerText = 'Payment Intent Client Secret is required'
//             return
//         }

//         /**
//          * Define and launch Web Components
//          **/
//         const stripeElement = document.createElement('stripe-payment-sheet') as any
//         const stripePaymentRequestElement = document.createElement('stripe-payment-request-button') as any
//         modalElement.appendChild(stripePaymentRequestElement as HTMLElement);
//         modalElement.appendChild(stripeElement)
//         targetElement?.appendChild(modalElement)

//         /**
//          * Wait for defining these components
//          **/
//         async () => {
//           await customElements.whenDefined('stripe-sheet')
//           await customElements.whenDefined('stripe-payment-request-button')
//           await customElements.whenDefined('stripe-payment-sheet')
//           /**
//            * Lauch Payment request button if possible to use
//            **/
//           await stripePaymentRequestElement.setPaymentRequestOption({
//               country: 'JP',
//               currency: 'usd',
//               total: {
//                   label: 'Total',
//                   amount: 100,
//               },
//               requestShipping: false,
//               requestPayerEmail: false,
//           });

//           /**
//            * Load Stripe Client
//            **/
//           await stripePaymentRequestElement.initStripe(stripePublishableAPIKey)
//           await stripeElement.initStripe(stripePublishableAPIKey)
//         }
//         /**
//          * Set the payment intent client secret
//          **/
//         stripeElement.setAttribute('intent-client-secret', paymentIntentClientSecret)
//         stripeElement.setAttribute('payment-request-button', true)

//         /**
//          * Disable default form submit event
//          **/
//         stripeElement.setAttribute('should-use-default-form-submit-action', false);

//         /**
//          * Set custom form submit event manually
//          **/
//         stripeElement.addEventListener('formSubmit', async props => {
//           const {
//             detail: { stripe, cardNumber, event },
//           } = props;
//           const result = await stripe.createPaymentMethod({
//             type: 'card',
//             card: cardNumber,
//           });
//           (resultElement as HTMLElement).innerHTML = `<pre><code>${JSON.stringify(result,null,2)}</code></pre>`
//           stripeElement.updateProgress('success');
//           await modalElement.closeModal()
//         });

//         /**
//          * Open modal
//          **/
//         modalElement.setAttribute('open', true)
//     })
//   }

// setup().then(()=>{// console.log('in then')}).catch(()=> { // console.log('in set up catch')})

export default defineComponent({
  setup() {

    const stripeAccount = ref('')

    const route = useRoute()


    const invoiceInstance = computed(() => {
      // console.log('invoiceID', route.params.invoiceID)
      return CheckoutStore.value.invoice[route.params.invoiceID as string]
    })

    const getAccountNumber = async ()=> {
      try {
        const response = await axiosInstance.get(
          `v1/payments/${invoiceInstance.value.paymentGatewayID}/paymentMethods`
        )
        stripeAccount.value = response.headers['x-stripeaccount']
      } catch (error) {
        // console.log('error in getAccountNumber')
      }
    }

  const initializeStripe  = async () =>  {
    // console.log('initialize')
    // await Stripe.initialize({
    //   publishableKey: "pk_test_51H7YlAGfc9bVPDYej5WC48NnmZJilRvTx6BKdaBRhndJGoSVsK1ENdVur3a3CisDiaMg3E7soFghFdq2ZPSL5Dpj006BYfcoB0",
    //   stripeAccount: stripeAccount.value
    // })
    await Stripe.initialize({publishableKey: Client.value.stripePublicKey})
    // console.log('after stripe initialize')
    const hasApplePay = await Stripe.isApplePayAvailable()
    const applePay = await Stripe.createApplePay({paymentSummaryItems: [{label: 'test', amount: 20.00}], countryCode: 'USD', currency: 'usd', merchantIdentifier: stripeAccount.value, paymentIntentClientSecret: 'someSecret'})
  }

  const getInvoice = async () => {
      if (!invoiceInstance.value) {
        const response = await axiosInstance.get(
          `v1/invoice/2031`
        )
        CheckoutStore.setInvoice(response.data.data)
      }
    }

  // const setup = async () => {
  //   await customElements.whenDefined('stripe-sheet')
  //     .then(() => {
  //         modalElement.addEventListener('close', () => {
  //             modalElement.innerHTML = ''
  //         })
  //     })

  //   form?.addEventListener('submit', (event) => {
  //       event.preventDefault()
  //       const stripePublishableAPIKey = document['open-modal-form']['stripe-publishable-api-key'].value
  //       if (!stripePublishableAPIKey) {
  //           (errorMessage as HTMLElement).innerText = 'Stripe Publishable API Key is required'
  //           return
  //       }
  //       const paymentIntentClientSecret = document['open-modal-form']['intent-client-secret'].value
  //       if (!paymentIntentClientSecret) {
  //           (errorMessage as HTMLElement).innerText = 'Payment Intent Client Secret is required'
  //           return
  //       }

  //       /**
  //        * Define and launch Web Components
  //        **/
  //       const stripeElement = document.createElement('stripe-payment-sheet') as any
  //       const stripePaymentRequestElement = document.createElement('stripe-payment-request-button') as any
  //       modalElement.appendChild(stripePaymentRequestElement as HTMLElement);
  //       modalElement.appendChild(stripeElement)
  //       targetElement?.appendChild(modalElement)

  //       /**
  //        * Wait for defining these components
  //        **/
  //       async () => {
  //         await customElements.whenDefined('stripe-sheet')
  //         await customElements.whenDefined('stripe-payment-request-button')
  //         await customElements.whenDefined('stripe-payment-sheet')
  //         /**
  //          * Lauch Payment request button if possible to use
  //          **/
  //         await stripePaymentRequestElement.setPaymentRequestOption({
  //             country: 'JP',
  //             currency: 'usd',
  //             total: {
  //                 label: 'Total',
  //                 amount: 100,
  //             },
  //             requestShipping: false,
  //             requestPayerEmail: false,
  //         });

  //         /**
  //          * Load Stripe Client
  //          **/
  //         await stripePaymentRequestElement.initStripe(stripePublishableAPIKey)
  //         await stripeElement.initStripe(stripePublishableAPIKey)
  //       }
  //       /**
  //        * Set the payment intent client secret
  //        **/
  //       stripeElement.setAttribute('intent-client-secret', paymentIntentClientSecret)
  //       stripeElement.setAttribute('payment-request-button', true)

  //       /**
  //        * Disable default form submit event
  //        **/
  //       stripeElement.setAttribute('should-use-default-form-submit-action', false);

  //       /**
  //        * Set custom form submit event manually
  //        **/
  //       stripeElement.addEventListener('formSubmit', async props => {
  //         const {
  //           detail: { stripe, cardNumber, event },
  //         } = props;
  //         const result = await stripe.createPaymentMethod({
  //           type: 'card',
  //           card: cardNumber,
  //         });
  //         (resultElement as HTMLElement).innerHTML = `<pre><code>${JSON.stringify(result,null,2)}</code></pre>`
  //         stripeElement.updateProgress('success');
  //         await modalElement.closeModal()
  //       });

  //       /**
  //        * Open modal
  //        **/
  //       modalElement.setAttribute('open', true)
  //   })
  // }

  const setup = async () => {
    // console.log('in set up ')
    const customEl = await customElements.whenDefined('stripe-payment-sheet')
      .then(() => {
        // console.log('customElementsDefined')
          const elements = document.getElementsByTagName('stripe-payment-sheet')
          // console.log('elements', elements)
          if (elements.length < 1) return;
          const element = elements[0] as any
          element.addEventListener('formSubmit', (props: any) => {
            async() => {
              // console.log('on formSubmit')
            const {
                detail: { stripe, cardNumber, event },
            } = props;
            const result = await stripe.createPaymentMethod({
              type: 'card',
              card: cardNumber,
          });
          element.updateProgress('success');
            }
          const resultElement = document.getElementById('result') as any
          resultElement.innerHTML = `<pre><code>${JSON.stringify('result',null,2)}</code></pre>`
          // console.log('resultElement', resultElement)
      });
      // console.log('customEl', customEl)
  })
  }

  const submitPayment = (details: any) => {
    // console.log('submit paymnt', details)
  }

  onMounted(async () => {
    // console.log('preInvoice')
    await getInvoice()
    // console.log('pre getAccountNumber')
    await getAccountNumber()
    // console.log('pre initializeStripe')
    await initializeStripe()
    // console.log('pre setup')
    // await setup()
  })

  return {
    // form,
    // resultElement,
    // errorMessage,
    // targetElement,
    // modalElement,
    stripeAccount,
    route,
    invoiceInstance,
    getAccountNumber,
    initializeStripe,
    setup,
    // StripeSheet,
    // StripePaymentSheet,
    submitPayment
  }

// async function createPaymentSheet(): Promise<void> {
//   /**
//    * Connect to your backend endpoint, and get every key.
//    */
//   const { paymentIntent, ephemeralKey, customer } = await this.http.post<{
//     paymentIntent: string;
//     ephemeralKey: string;
//     customer: string;
//   }>(environment.api + 'payment-sheet', {}).pipe(first()).toPromise(Promise);

//   Stripe.createPaymentSheet({
//     paymentIntentClientSecret: paymentIntent,
//     customerId: customer,
//     // merchantDisplayName: 'Your App Name or Company Name',
//     // customerEphemeralKeySecret: ephemeralKey,
//     // style: 'alwaysDark',
//   });
// }
  }
})
</script>

