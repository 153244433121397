import { sortBy, sortByMany, vReqT } from "src/helpers/utils";
import { defineComponent, ExtractPropTypes, onMounted, ref } from "vue";
import { Bracket, BracketRoundSlotTeamSource, getBracketForInfoView } from "./Bracket.io";
import { axiosInstance } from "src/boot/AxiosInstances";
import { BracketElement, BracketElementSlots } from "./Bracket";
import { RoundSlotElemRefTracker } from "./BracketBuilderElems";
import { CoachTriple, teamDesignationAndMaybeName } from "../calendar/GameScheduler.shared";
import dayjs from "dayjs";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { RouteLocationRaw } from "vue-router";

const props = {
  bracketID: vReqT<Integerlike>()
} as const

type PropsDef = ExtractPropTypes<typeof props>

export default defineComponent({
  props: props,
  setup(props) {
    const bracket = ref<Bracket | null>()
    const roundSlotElemRefTracker = RoundSlotElemRefTracker()

    onMounted(async () => {
      bracket.value = (await getBracketForInfoView(axiosInstance, {bracketID: props.bracketID})).bracket
      roundSlotElemRefTracker.reinitFrom(bracket.value)
    })

    return () => {
      if (!bracket.value) {
        return null
      }
      return <div>
        <div>{bracket.value.bracketName}</div>
        <BracketElement
          bracket={bracket.value}
          roundSlotElemRefTracker={roundSlotElemRefTracker}
          mode="existing"
          debugBracketRoundSlotIDs={false}
        >
          {{
            roundHeader: ({bracketRound}) => {
              return <div>{bracketRound.bracketRoundName}</div>
            },
            roundSlot: ({bracketRoundSlot}) => {
              if (bracketRoundSlot.disabled || !bracketRoundSlot.game) {
                return <div class="bg-gray-200 h-full">&nbsp;</div>
              }

              const game = bracketRoundSlot.game
              const homeTeam = bracketRoundSlot.game.homeTeam
              const homeCoaches = bracketRoundSlot.game.coaches.filter(v => v.teamID === homeTeam?.teamID)
              const visitorTeam = bracketRoundSlot.game.visitorTeam
              const visitorCoaches = bracketRoundSlot.game.coaches.filter(v => v.teamID === visitorTeam?.teamID)

              return <div class="rounded-md h-full">
                <div class="px-2 py-1 bg-green-800 text-xs text-white">Game #{bracketRoundSlot.game.gameNum}</div>
                <div class="p-1">
                  <div>{dayjs(game.gameStart).format("MMM DD, YYYY @ h:mm a")}</div>
                  <div>{bracketRoundSlot.game.fieldAbbrev}</div>
                  <div style="display: grid; grid-template-columns: auto auto">
                    <div>{/*grid cell placeholder*/}</div>
                    <div>Score</div>

                    <div class="px-2 flex items-center">{bracketAwareTeamName(homeTeam, homeCoaches, bracketRoundSlot.sourceLeft)}</div>
                    <div class="px-2 flex items-center">{game.homeGoals}</div>

                    <div style="grid-column:-1/1; margin: 4px 2px;" class="border-b border-dashed border-gray-300"></div>

                    <div class="px-2 flex gap-2 items-center">
                      <span>v.</span>
                      <span>{bracketAwareTeamName(visitorTeam, visitorCoaches, bracketRoundSlot.sourceRight)}</span>
                    </div>
                    <div class="px-2 flex items-center">{game.visitorGoals}</div>
                  </div>
                </div>
              </div>
            },
          } satisfies BracketElementSlots}
        </BracketElement>
      </div>
    }
  }
})

export function Route(_: PropsDef) : RouteLocationRaw {
  return {
    name: Route.routeName,
    params: {
      bracketID: _.bracketID,
    }
  }
}

Route.routeName = "R_BracketView"

const bracketAwareTeamName = (
  teamlike: {teamDesignation: string, teamName: string, teamID: string} | null,
  coaches: CoachTriple[],
  bracketSlotSource: BracketRoundSlotTeamSource | null
) => {
  const winnerLoserText = (v: "winner" | "loser") => v === "winner" ? "Winner of" : "Loser of";

  const headAndCoCoaches : string = (() => {
    if (coaches.length === 0) {
      return "";
    }

    return coaches
      .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach")
      .sort(sortByMany(sortBy(_ => _.lastName)))
      .map(v => v.lastName)
      .join(", ") ?? ""
  })();

  return <div class="flex items-center p-1">
    {teamlike
      ? <div>
        <div>{teamDesignationAndMaybeName(teamlike)}</div>
        <div class="text-sm">{headAndCoCoaches}</div>
      </div>
      : bracketSlotSource
      ? <div>{winnerLoserText(bracketSlotSource.sourceType)} prior game</div>
      : <div>&nbsp;</div>
    }
  </div>
}
