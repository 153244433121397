<template lang="pug">
div
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="4")
                  | Emergency Contact
            .grid.grid-cols-3.divide-y.divide-gray-200(v-if="Object.keys(playerDetails).length>0" class='lg:grid-cols-6')
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Emergency Contact:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['EmergencyContact']" data-test="EmergencyContact-mutable")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.EmergencyContact',
                      name='EmergencyContact',
                      validations='required',
                      innerClass='bg-white'
                      data-test="EmergencyContact"
                    )
                  div(v-else data-test="EmergencyContact-immutable") {{ playerDetails.EmergencyContact }}
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Emergency Phone:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['EmergencyPhone']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.EmergencyPhone',
                      name='EmergencyPhone',
                      validations='required',
                      innerClass='bg-white'
                    )
                  div(v-else) {{ playerDetails.EmergencyPhone }}
</template>

<script lang="ts">
import { RegistrationDataI } from 'src/interfaces/Store/registration'
import { defineComponent, PropType } from 'vue'


export default defineComponent({
  props: {
    playerDetails: {
      required: true,
      type: Object as PropType<RegistrationDataI>
    },
    coreQuestionEditPermissionMap: {
      required: true,
      type: Object as PropType<Record<string, boolean>>
    }
  }
})
</script>

