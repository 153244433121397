<template lang="pug">
PlayerData(
  v-if="!playerDetailsIsEffectivelyNull"
  :granularEditPermissions="granularEditPermissions"
  v-model:playerData="playerDetails"
  v-on="playerDataHandlers"
)
ParentData.mt-4(v-if="playerDetails.parent1ID", :parentNumber='1' :parentID="playerDetails.parent1ID" :canEdit='hasSomeSuperEditPermission')
ParentData.mt-4(v-if="playerDetails.parent2ID", :parentNumber='2' :parentID="playerDetails.parent2ID" :canEdit='hasSomeSuperEditPermission')
RegistrationData.mt-4(
  v-if="shouldShowRegistrationData"
  @updateCoreQuestionAnswers="(val) => $emit('updateCoreQuestionAnswers', val)"
  @updateCustomQuestions="(val) => $emit('updateCustomQuestions', val)"
  :playerDetails="playerDetails"
  :hasSomeSuperEditPermission='hasSomeSuperEditPermission'
  :granularEditPermissions='granularEditPermissions'
  :coreQuestionEditPermissionMap="coreQuestionEditPermissionMap"
  :lockedDueToSomeSeasonCompetitionLockDate="lockedDueToSomeSeasonCompetitionLockDate"
  :coreQuestions="coreQuestions"
)
</template>

<script lang="ts">
import { PlayerDetailsI } from 'src/interfaces/Store/registration'
import { defineComponent, computed, PropType } from 'vue'
import PlayerData from 'src/components/PlayerEditor/FamilyData/PlayerData.vue'
import ParentData from 'src/components/PlayerEditor/FamilyData/ParentData.vue'
import RegistrationData from 'src/components/PlayerEditor/RegistrationData/RegistrationData.vue'
import authService from 'src/helpers/authService'

import { UserData } from 'src/interfaces/Store/user'
import familyAuth from 'src/composables/familyAuth'
import { __fixme__CoreQuestionAnswerRecord, CoreQuestion } from 'src/interfaces/InleagueApiV1'
import * as M_PlayerData from "./PlayerData.ilx"
import { emitsDef } from "./FamilyData.ilx"
import { User } from 'src/store/User'
import { vReqT } from 'src/helpers/utils'

export default defineComponent({
  components: {
    PlayerData,
    ParentData,
    RegistrationData
  },
  props: {
    playerDetails: {
      required: true,
      type: Object as PropType<PlayerDetailsI>
    },
    coreQuestionEditPermissionMap: {
      required: true,
      type: Object as PropType<Record<string, boolean>>
    },
    hasSomeSuperEditPermission: {
      required: true,
      type: Boolean
    },
    granularEditPermissions: vReqT<Record<string, boolean>>(),
    lockedDueToSomeSeasonCompetitionLockDate: {
      required: true,
      type: Boolean
    },
    coreQuestions: vReqT<CoreQuestion[]>(),
  },
  emits: emitsDef(),
  setup(props, {emit}) {
    const { isChildRelated } = familyAuth()



    const userRoles = computed<string[]>(() => {
      if (typeof User.value.userData === "string") {
        // shouldn't happen, user is not logged in here
        return [];
      }
      else {
        return User.value.userData.roles
      }
    })

    /**
     * empty object is used to represent null here, this should eventually be turned into actual `T | null`
     */
    const playerDetailsIsEffectivelyNull = computed<boolean>(() => {
      return Object.keys(props.playerDetails).length === 0;
    })

    const shouldShowRegistrationData = computed<boolean>(() => {
      if (typeof User.value.userData === "string") {
        // shouldn't happen, user is not logged in here
        return false;
      }

      if (playerDetailsIsEffectivelyNull.value) {
        return false;
      }

      // only show registration if we're focused on some particular registration
      //
      const hasSomeRegistrationID = !!props.playerDetails.registrationID;
      const hasSomeRelevantPerms = authService(userRoles.value, 'playerAdmin', 'registrar', 'webmaster', 'director', 'compManager', 'scholarshipAdmin', "DD", 'Data Reader') ||
        !!User.userData?.divisionsMemento.find(div => props.playerDetails.competitions.find(compReg => compReg.divID === div.divID))
      const isParentOfPlayer = isChildRelated({childID: props.playerDetails.childID, childList: User.value.userData.belongingChildrenIDs})

      return hasSomeRegistrationID && (hasSomeRelevantPerms || isParentOfPlayer);
    })

    const playerDataHandlers : M_PlayerData.Emits = {
      "player-data-invalidated": () => emit("player-data-invalidated")
    }

    return {
      authService,
      userRoles,
      isChildRelated,
      playerDataHandlers,
      playerDetailsIsEffectivelyNull,
      shouldShowRegistrationData,
    }
  }
})
</script>
