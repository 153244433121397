import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";
import * as iltypes from "src/interfaces/InleagueApiV1"

export const RouteNames = {
  main: "TournamentRegistration.main"
} as const;

export const propsDef = {
  tournamentTeamID: {
    required: true,
    type: [Number,String] as PropType<iltypes.Integerlike>
  }
} as const

export type Props = ExtractPropTypes<typeof propsDef>


export function routeDetailToRouteLocation(props: Props) : RouteLocationRaw {
  return {name: RouteNames.main, params: props}
}
