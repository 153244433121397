import { CompletedELA, ELAProperties, Waiver, WaiverSubmission } from "src/interfaces/registration"

import { User } from "src/interfaces/InleagueApiV1"
import { assertNonNull } from "src/helpers/utils"
import { Client } from "src/store/Client"

export default () => {
  /**
   * Retain those elements of `leagueELAs` not present in `completedELAs`
   */
  const retainOnlyIncompleteELAs = (leagueELAs: Waiver[], completedELAs: CompletedELA[]) : Waiver[] => {
    const displayELAs = [...leagueELAs]
    for(let i=leagueELAs.length-1; i>=0; i--) {
      const hasCompletedELA = completedELAs.some(ELA => ELA.ELAKey.toLowerCase()===leagueELAs[i].Key.toLowerCase() && ELA.VersionID.toLowerCase() === leagueELAs[i].VersionID.toLowerCase())
      if(hasCompletedELA) {
        displayELAs.splice(i, 1)
      }
    }
    return displayELAs
  }

  const setupCheckboxes = (ELAs: Waiver[]) => {
    const checkboxes : Record<string, any> = {}
    for(let i = 0; i<ELAs.length; i++) {
      checkboxes[ELAs[i].ID]=false
    }
    return checkboxes
  }

  /**
   * @param elaIsForThisUser the <current user> and the <user the ela is for> need not be the same;
   * the signature must match the username of "elaIsForThisUser"
   *
   * n.b. returns false when an elaSignature is determined to be valid (effectively returns "was invalid?")
   */
  const verifyName = (elaSignature: WaiverSubmission | undefined, elaIsForThisUser: Pick<User, "firstName" | "lastName">) => {
    // helpful aliases to be clear about return expectations
    const SIGNATURE_IS_VALID = false;
    const SIGNATURE_IS_NOT_VALID = true;
    if (!elaSignature) {
      return SIGNATURE_IS_NOT_VALID;
    } else if (
      elaSignature.AcceptedByFirstName?.trim().toLowerCase() === elaIsForThisUser.firstName.trim().toLowerCase() &&
      elaSignature.AcceptedByLastName?.trim().toLowerCase() === elaIsForThisUser.lastName.trim().toLowerCase()
    ) {
      return SIGNATURE_IS_VALID;
    } else {
      return SIGNATURE_IS_NOT_VALID;
    }
  }

  const verifyCheckboxes = (checkboxes: any) => {
    for(const ID in checkboxes) {
      if(!checkboxes[ID]) {
        return true
      }
    }
    return false
  }

  const createPostOptions = (seasonUID: string, ELAs: Waiver[], checkboxes: any, signatures: WaiverSubmission) => {
    // it's a very strong guarantee that these have been set to non-undefined;
    // the caller is expected to have enforced that the "signature is valid", which implies that they are of type string
    assertNonNull(signatures.AcceptedByFirstName, "possibly undefined type must not be undefined here");
    assertNonNull(signatures.AcceptedByLastName, "possibly undefined type must not be undefined here");

    const options : WaiverSubmission = {
      ...signatures,
      AcceptedByFirstName: signatures.AcceptedByFirstName.trim(),
      AcceptedByLastName: signatures.AcceptedByLastName.trim(),
    }

    options.ClubKey = Client.value.instanceConfig.stackclubkey
    options.seasonUID = seasonUID

    // For every ELA that has been signed, add its details to elaSignature
    const signedElas: ELAProperties[] = []
    for(let i=0; i<ELAs.length; i++) {
      if(checkboxes[ELAs[i].ID]) {
        signedElas.push({elaID: ELAs[i].ID, ELAKey: ELAs[i].Key , VersionID: ELAs[i].VersionID})
      }
    }
    options.elas = signedElas

    return options

  }

  return {
    retainOnlyIncompleteELAs,
    setupCheckboxes,
    verifyName,
    verifyCheckboxes,
    createPostOptions
  }
}
