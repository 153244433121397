<template lang="pug">
div
  .bg-white.shadow.overflow-hidden(
    class='sm:rounded-lg',
    v-if='Object.keys(parent).length'
  )
    .px-4.py-5.border-b.border-gray-200.flex.flex-row.justify-between(class='sm:px-6')
      h3.text-lg.leading-6.font-medium.text-gray-900
        div {{ parent.firstName }} {{ parent.lastName }}
      ImpersonateButton(
        :userID='parent.ID',
        v-if='authService(roles, "Registrar", "Webmaster", "inLeague")'
      )
    .px-4.py-5(class='sm:p-0')
      dl
        div(class='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5')
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Email
          a(:href='`mailto:${parent.email}`')
            dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
              | {{ parent.email }}
        .mt-4(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Primary Phone
          a(v-if='parent.primaryPhone', :href='`tel:${parent.primaryPhone}`')
            dd.mt-1.text-sm.leading-5.text-gray-900(
              class='sm:mt-0 sm:col-span-2',
              data-cy='phoneNumber'
            )
              | {{ formatPhoneNumber(parent.primaryPhone) }}
          dd.mt-1.text-sm.leading-5.text-gray-900(v-else, class='sm:mt-0 sm:col-span-2')
            | N/A
        .mt-4(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          router-link(:to='{ name: "schedule" }')
            dt.text-sm.leading-5.font-medium.text-gray-500
              | Alternate
          a(v-if='parent.homePhone', :href='`tel:${parent.homePhone}`')
            dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
              | {{ formatPhoneNumber(parent.homePhone) }}
          dd.mt-1.text-sm.leading-5.text-gray-900(v-else, class='sm:mt-0 sm:col-span-2')
            | N/A
        .mt-4(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Address
          dd.mt-1.text-sm.leading-5.text-gray-900(
            class='sm:mt-0 sm:col-span-2',
            data-cy='address'
          )
            | {{ parent.street }} {{ parent.street2 }}, {{ parent.city }}, {{ parent.state }} {{ parent.zip }}
        .mt-4(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Safe Haven Certified
          dd.mt-1.text-sm.leading-5.text-gray-900(
            class='sm:mt-0 sm:col-span-2',
            data-cy='certification'
          )
            | {{ parent.isSafeHavenCertified ? 'Yes' : 'No' }}
        .mt-4(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-b sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | CDC Concussion Certified
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            | {{ parent.isConcussionCertified ? 'Yes' : 'No' }}

        .bg-white.py-5(class='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6')
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Family
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            ul.border-r.border-l.border-b.border-gray-200.rounded-md.inline-block
              li(
                v-for='member in familyMembers.users',
                @click='toMember(member.ID, true)',
                data-cy='familyMember'
              )
                .border-t.border-gray-200.pl-3.pr-4.py-3.flex.items-center.justify-between.text-sm.leading-5.cursor-pointer(
                  v-if='member.ID != parent.ID'
                )
                  | {{ member.firstName }} {{ member.lastName }}
                  font-awesome-icon.ml-6(:icon='["fas", "angle-double-right"]')
              li.border-t.border-gray-200.pl-3.pr-4.py-3.flex.items-center.justify-between.text-sm.leading-5.cursor-pointer(
                v-for='member in familyMembers.children',
                @click='toMember(member.childID, false)',
                data-cy='familyMember'
              )
                | {{ member.playerFirstName }} {{ member.playerLastName }} (Age: {{ calculateAge(member.playerBirthDate, false) }})
                font-awesome-icon.ml-6(:icon='["fas", "angle-double-right"]')
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { maybePrettifyUSPhoneNumber } from 'src/helpers/utils'
import { calculateAge } from 'src/helpers/formatDate'
import ImpersonateButton from 'src/components/Admin/ImpersonateUsers/ImpersonateButton.vue'
import authService from 'src/helpers/authService'
import { axiosInstance } from 'src/boot/axios'
import { ParentI } from 'src/interfaces/lookup'

import { UserData } from 'src/interfaces/Store/user'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'VolunteerView',
  components: {
    ImpersonateButton,
  },
  setup() {
    const parent = ref({}) as Ref<ParentI>
    const familyMembers = ref({})


    const route = useRoute()
    const router = useRouter()

    const roles = computed(() => {
      return (User.value.userData as UserData).roles
    })

    const getParent = async () => {
      try {
        const response = await axiosInstance(
          `v1/user/${
            route.params.volunteerID as string
          }?expand=isSafeHavenCertified,isConcussionCertified,familyMemberUsers,familyMembersChildren`
        )
        parent.value = response.data.data
      } catch (err) {
        // console.error(err)
      }
    }

    const getFamily = async () => {
      try {
        const response = await axiosInstance(`v1/familyMembers`, {
          params: { userid: route.params.volunteerID },
        })
        familyMembers.value = response.data.data
      } catch (err) {
        // console.error(err)
      }
    }

    const toMember = async (memberID: string, isAdult: boolean) => {
      console.log('toMember', memberID, isAdult)
      if (isAdult) {
        console.log('in isAdult')
        await router.replace({
          name: 'volunteer-details',
          params: { volunteerID: memberID },
        })
        console.log('after router.push')
      } else {
        await router.push({ name: 'player-editor', params: { playerID: memberID } })
      }
    }

    watch(()=>route.params, async (newRoute, oldRoute) =>
    {
      if(newRoute.volunteerID) {
        await getParent()
        await getFamily()
      }
    })

    onMounted(async () => {
      await getParent()
      await getFamily()
    })

    return {
      parent,
      familyMembers,
      toMember,
      getFamily,
      getParent,
      formatPhoneNumber: maybePrettifyUSPhoneNumber,
      calculateAge,
      roles,
      authService,
    }
  },
})
</script>
