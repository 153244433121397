import { computed, defineComponent, ExtractPropTypes, getCurrentInstance, onMounted, PropType, Ref, ref, watch } from "vue";
import { axiosInstance } from "src/boot/axios";

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { MfaInitOrReconfigureSMS_LoggedIn, MfaInitOrReconfigureTotp_LoggedIn } from "src/components/User/MfaInit"

import { GoogleLogo } from "src/components/SVGs"
import { useRouter } from "vue-router";
import { Modal, Slots as ModalSlots } from "../../UserInterface/Modal";
import { exhaustiveCaseGuard, useIziToast, Writeable } from "src/helpers/utils";

import { MfaDetails } from "src/composables/InleagueApiV1.Authenticate";
import { Client } from "src/store/Client";

const LEAGUE_REQUIRES_MFA_BLURB = `League policies require this user to participate in MFA. If they have no other enabled MFA options, they will be required to configure one at their next login.`
const LEAGUE_DOES_NOT_REQURE_MFA_BLURB = `League policies do not currently require this user to participate in MFA.`

const thirdPartyAuthnConfiguratorImplPropsDef = {
  isSomeSuperUserEditingAnotherUser: {
    required: true,
    type: Boolean,
  },
  mfaDetails: {
    required: true,
    type: Object as PropType<ilauth.MfaDetails>
  },
  oauthConnectFlowReturnURL: {
    required: true,
    type: String
  }
} as const;

const thirdPartyAuthnConfiguratorEmitsDef = {
  updatedMfaDetails: (_fresh: ilauth.MfaDetails) => true,
}

type ThirdPartyAuthnConfiguratorProps = ExtractPropTypes<typeof thirdPartyAuthnConfiguratorImplPropsDef>;

const ThirdPartyAuthNConfigurator = defineComponent({
  name: "ThirdPartyAuthnConfigurator",
  props: thirdPartyAuthnConfiguratorImplPropsDef,
  emits: thirdPartyAuthnConfiguratorEmitsDef,
  setup(props, {emit}) {
    const deleteOauthConnection = async (provider: ilauth.SupportedOauthProvider) => {
      const freshDetails = await ilauth.deleteAuthNProvider(axiosInstance, {userID: props.mfaDetails.userID, provider});
      emit("updatedMfaDetails", freshDetails);
    }

    const init3rdPartyOauthConnectFlow = async (provider: ilauth.SupportedOauthProvider) => {
        const oauthURL = await ilauth.notifyOfIntentToInit3rdPartyOauthConnectFlow(axiosInstance, {userID: props.mfaDetails.userID, provider, onSuccessURL: props.oauthConnectFlowReturnURL});
        window.location.assign(oauthURL);
    }

    const isEditingSelf = computed(() => !props.isSomeSuperUserEditingAnotherUser);

    return () => (
      <div class="p-2">
        <div data-test="google">
          {
            props.mfaDetails.hasGoogleAuthNProvider
              ? (
                <>
                  <div class="flex">
                    <GoogleLogo/>
                    <div class="ml-1">Sign-in with Google is enabled.</div>
                  </div>
                  <t-btn type="button" class="mt-2" data-test="disable" onClick={() => deleteOauthConnection("google")} margin={false}>Disable</t-btn>
                </>
              )
              : (
                <>
                  <div class="flex">
                    <GoogleLogo/>
                    <div class="ml-1">Sign-in with Google is not enabled.</div>
                  </div>
                  {
                    isEditingSelf.value
                      ? <t-btn type="submit" class="mt-2" margin={false} data-test="enable" onClick={() => init3rdPartyOauthConnectFlow("google")}>Enable</t-btn>
                      : <span class="italic font-light text-gray-800">Only {props.mfaDetails.firstName} {props.mfaDetails.lastName} can enable this.</span>
                  }

                </>
              )
          }
        </div>
      </div>
    )
  }
})

const mfaConfiguratorImplPropsDef = {
  isSomeSuperUserEditingAnotherUser: {
    required: true,
    type: Boolean,
  },
  mfaDetails: {
    required: true,
    type: Object as PropType<ilauth.MfaDetails>
  }
} as const;
type MfaConfiguratorProps = ExtractPropTypes<typeof mfaConfiguratorImplPropsDef>

const SMSInitDialogElementWrapper = defineComponent({
  props: {
    mfaDetails: {
      required: true,
      type: Object as PropType<MfaDetails>
    }
  },
  emits: {
    success: (_mfaDetails: MfaDetails) => true
  },
  setup(props, {emit}) {
    type LifecyclePhase = "working" | "done"
    const phase = ref<LifecyclePhase>("working")

    const onSuccess = (mfaDetails: ilauth.MfaDetails) => {
      phase.value = "done";
      emit("success", mfaDetails);
    }

    return () => (
      phase.value === "working"
        ? <MfaInitOrReconfigureSMS_LoggedIn
          data-test="sms-init-loggedIn"
          mfaDetails={props.mfaDetails}
          onSuccess={onSuccess}
        />
        : phase.value === "done"
        ? <div class="flex items-center justify-center">You're all set!</div>
        : exhaustiveCaseGuard(phase.value)
    )
  }
})

const TOTPInitDialogElementWrapper = defineComponent({
  props: {
    mfaDetails: {
      required: true,
      type: Object as PropType<MfaDetails>
    }
  },
  emits: {
    success: (_mfaDetails: MfaDetails) => true
  },
  setup(props, {emit}) {
    type LifecyclePhase = "working" | "done"
    const phase = ref<LifecyclePhase>("working")

    const onSuccess = (mfaDetails: ilauth.MfaDetails) => {
      phase.value = "done";
      emit("success", mfaDetails);
    }

    return () => (
      phase.value === "working"
        ? <MfaInitOrReconfigureTotp_LoggedIn
            data-test="totp-init-loggedIn"
            userID={props.mfaDetails.userID}
            onSuccess={onSuccess}
          />
        : phase.value === "done"
        ? <div class="flex items-center justify-center">You're all set!</div>
        : exhaustiveCaseGuard(phase.value)
    )
  }
})

const MfaConfigurator = defineComponent({
  name: "MfaConfigurator",
  props: mfaConfiguratorImplPropsDef,
  emits: {
    updatedMfaDetails: (_fresh: ilauth.MfaDetails) => true
  },
  setup(props, {emit}) {
    const smsInitModalController = (() => {
      let delayedModalBehaviorTimeoutHandle : null | number = null;
      const isOpen = ref(false);
      const open = () => {
        isOpen.value = true
      };

      const close = () => {
        isOpen.value = false
        if (delayedModalBehaviorTimeoutHandle !== null) {
          // clear the pending delayed modal behavior, if it exists
          clearTimeout(delayedModalBehaviorTimeoutHandle);
          delayedModalBehaviorTimeoutHandle = null;
        }
      };

      const onSuccess = (mfaDetails: ilauth.MfaDetails) => {
        emit("updatedMfaDetails", mfaDetails);
        delayedModalBehaviorTimeoutHandle = setTimeout(close, 1000) as any as number;
      }

      const slots : ModalSlots = {
        title: () => (
          <>
            <div>SMS MFA setup</div>
            <div class="border-b border-slate-300 mb-2" />
          </>
        ),
        content: () => <SMSInitDialogElementWrapper mfaDetails={props.mfaDetails} onSuccess={onSuccess} />
      };

      return {
        open,
        close,
        isOpen: computed(() => isOpen.value),
        slots
      }
    })();

    const totpInitModalController = (() => {
      let delayedModalBehaviorTimeoutHandle : null | number = null;

      const isOpen = ref(false);
      const open = () => {
        isOpen.value = true

      };
      const close = () => {
        isOpen.value = false
        if (delayedModalBehaviorTimeoutHandle !== null) {
          // clear the pending delayed modal behavior, if it exists
          clearTimeout(delayedModalBehaviorTimeoutHandle);
          delayedModalBehaviorTimeoutHandle = null;
        }
      };

      const onSuccess = (mfaDetails: ilauth.MfaDetails) => {
        emit("updatedMfaDetails", mfaDetails);
        delayedModalBehaviorTimeoutHandle = setTimeout(close, 1000) as any as number;
      }

      const slots : ModalSlots = {
        title: () => (
          <>
            <div class="mb-2">Authenticator MFA setup</div>
            <div class="border-b border-slate-300 mb-2" />
          </>
        ),
        content: () => <TOTPInitDialogElementWrapper mfaDetails={props.mfaDetails} onSuccess={onSuccess} />
      }

      return {
        open,
        close,
        isOpen: computed(() => isOpen.value),
        slots
      }
    })();

    const iziToast = useIziToast();

    const unenrollFromSms = async () => {
      const freshDetails = await ilauth.deleteSmsForUser(axiosInstance, {userID: props.mfaDetails.userID});
      iziToast.success({message: "Text message MFA disabled."});
      emit("updatedMfaDetails", freshDetails);
    }

    const unenrollFromTotp = async () => {
      const freshDetails = await ilauth.deleteTotpForUser(axiosInstance, {userID: props.mfaDetails.userID});
      iziToast.success({message: "Authenticator-based MFA disabled."});
      emit("updatedMfaDetails", freshDetails);
    }

    const canUnenrollFromAnyParticularMfaOption = computed(() => {
      return props.isSomeSuperUserEditingAnotherUser || !props.mfaDetails.leaguePolicyRequiresMFA;
    });
    const isEditingSelf = computed(() => {
      return !props.isSomeSuperUserEditingAnotherUser;
    })

    return () => (
      <div class="p-2">
        <Modal
          isOpen={ smsInitModalController.isOpen.value }
          onClose={() => smsInitModalController.close() }
          closeOnClickOutsideOrPressEscape={false}
        >
          {{
            ...smsInitModalController.slots
          }}
        </Modal>

        <Modal
          isOpen={ totpInitModalController.isOpen.value }
          onClose={() => totpInitModalController.close() }
          closeOnClickOutsideOrPressEscape={false}
        >
          {{
            ...totpInitModalController.slots
          }}
        </Modal>

        <div data-test="sms">
          {
            props.mfaDetails.hasSMS
              ? (
                <>
                  <div class="flex">
                    <div class="ml-1">Text message (SMS) MFA is enabled.</div>
                  </div>

                  <t-btn
                    type="button"
                    data-test="unenroll"
                    disable={!canUnenrollFromAnyParticularMfaOption.value}
                    class={ `mt-2 ${canUnenrollFromAnyParticularMfaOption.value ? "" : "bg-gray-300"}`}
                    onClick={() => { unenrollFromSms() }}
                  >Disable</t-btn>
                  {
                    canUnenrollFromAnyParticularMfaOption.value
                      ? null
                      : <div class="text-sm italic">League policies require MFA for {props.isSomeSuperUserEditingAnotherUser ? "this user." : "users with your permission levels."}</div>
                  }
                </>
              )
              : (
                <>
                  <div class="flex">
                    <div class="ml-1">Text message (SMS) MFA is not enabled.</div>
                  </div>
                  <t-btn
                    type="button"
                    disable={!isEditingSelf.value /*disabled if NOT editing self*/ }
                    class={ `mt-2 ${isEditingSelf.value ? "" : "bg-gray-300"}`}
                    data-test="enroll"
                    onClick={() => smsInitModalController.open()}
                  >Enable</t-btn>
                  {
                    isEditingSelf.value
                      ? null
                      : <div class="italic font-light text-gray-800">
                          <div>Only {props.mfaDetails.firstName} {props.mfaDetails.lastName} can enable this.</div>
                          <div class="text-sm">{props.mfaDetails.leaguePolicyRequiresMFA ? LEAGUE_REQUIRES_MFA_BLURB : LEAGUE_DOES_NOT_REQURE_MFA_BLURB}</div>
                      </div>
                  }
                </>
              )
          }
        </div>
        <div class="my-2 border-b border-slate-300 border-dashed"/>
        <div data-test="totp">
          {
            props.mfaDetails.hasTOTP
              ? (
                <>
                  <div class="flex">
                    <div class="ml-1">Authenticator app based MFA is enabled.</div>
                  </div>
                  <t-btn
                    type="button"
                    data-test="unenroll"
                    disable={!canUnenrollFromAnyParticularMfaOption.value}
                    class={ `mt-2 ${canUnenrollFromAnyParticularMfaOption.value ? "" : "bg-gray-300"}`}
                    onClick={() => { unenrollFromTotp(); }}
                  >Disable</t-btn>
                  {
                    canUnenrollFromAnyParticularMfaOption.value
                      ? null
                      : <div class="text-sm italic">League policies require MFA for {isEditingSelf.value ? "users with your permission levels." : "this user."}</div>
                  }

                  {
                    isEditingSelf.value
                      ? <t-btn
                        type="button"
                        class="mt-2"
                        data-test="reconfigure"
                        onClick={() => { totpInitModalController.open() }}
                      >Reconfigure with new secret</t-btn>
                      : null
                  }
                </>
              )
              : (
                <>
                  <div class="flex">
                    <div class="ml-1">Authenticator app based MFA is not enabled.</div>
                  </div>
                  <t-btn
                    type="button"
                    class={`mt-2 ${props.isSomeSuperUserEditingAnotherUser ? "bg-gray-300" : ""}`}
                    disable={props.isSomeSuperUserEditingAnotherUser}
                    data-test="enroll"
                    onClick={() => { totpInitModalController.open() }}
                  >Enable</t-btn>
                  {
                    isEditingSelf.value
                      ? null
                      : (
                        <div class="italic font-light text-gray-800">
                          <div>Only {props.mfaDetails.firstName} {props.mfaDetails.lastName} can enable this.</div>
                          <div class="text-sm">{props.mfaDetails.leaguePolicyRequiresMFA ? LEAGUE_REQUIRES_MFA_BLURB : LEAGUE_DOES_NOT_REQURE_MFA_BLURB}</div>
                        </div>
                      )
                  }
                </>
              )
          }
        </div>
      </div>
    )
  }
})

export const AccountSecurity = defineComponent({
  name: "AccountSecurity",
  props: {
    isSomeSuperUserEditingAnotherUser: {
      required: true,
      type: Boolean
    },
    userID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    }
  },
  emits: {},
  setup(props) {

    const router = useRouter();
    const thisPathsURL = computed(() => `${window.location.origin}${router.resolve(router.currentRoute.value).href}`);

    const oauthProps = ref<Writeable<ThirdPartyAuthnConfiguratorProps> | null>(null);
    const mfaProps = ref<Writeable<MfaConfiguratorProps> | null>(null);

    const updateImplProps = (v: ilauth.MfaDetails) => {
      oauthProps.value = {
        isSomeSuperUserEditingAnotherUser: props.isSomeSuperUserEditingAnotherUser,
        mfaDetails: v,
        oauthConnectFlowReturnURL: thisPathsURL.value
      },
      mfaProps.value = {
        isSomeSuperUserEditingAnotherUser: props.isSomeSuperUserEditingAnotherUser,
        mfaDetails: v,
      }
    }

    onMounted(async () => {
      updateImplProps(
        await ilauth.getMfaDetailsForSomeUser(axiosInstance, {userID: props.userID})
      );
    });

    return () => (
      <div data-test="AccountSecurity">
        <div class="mb-6 shadow-md border border-slate-300" data-test="ThirdPartyAuthNConfigurator">
          <div class="p-2">Third party auth providers</div>
          <div class="border-t border-slate-300"></div>
          {
            oauthProps.value
              ? <ThirdPartyAuthNConfigurator
                onUpdatedMfaDetails={mfaDetails => updateImplProps(mfaDetails)}
                {...oauthProps.value}
              />
              : null
          }
        </div>
        {
          Client.value.instanceConfig.mfa_enabled
          ?
            <div class="shadow-md border border-slate-300" data-test="MfaConfigurator">
              <div class="p-2">MFA</div>
              <div class="border-t border-slate-300"></div>
              {
                mfaProps.value
                  ? <MfaConfigurator
                    onUpdatedMfaDetails={mfaDetails => updateImplProps(mfaDetails)}
                    {...mfaProps.value}
                  />
                  : null
              }
            </div>
          : <div>MFA options aren't enabled for this league.</div>
        }
      </div>
    )
  }
})
