<template lang="pug">
div
  .bg-white.overflow-hidden.shadow.rounded-lg.divide-y.divide-gray-200
    .px-4.py-5(class='sm:px-6 sm:flex sm:flex-col sm:items-center')
      FormKit(
        type="button"
        @click="forceRefresh()"
        label="Refresh registration listings"
      )
      FormKit(
        type="radio"
        v-model="state.selected.grouping"
        :options="state.options.grouping"
        :outer-class='{"sm:w-3/5": true}'
        :fieldset-class='{"CASH-OR-CHECK-IMPL-sm-formkit-fieldset-margin-kludge": true}'
      )

      FormKit(
        type="select"
        label="Player"
        v-model="state.selected.playerID"
        :options="state.options.availablePlayers"
        :outer-class='{"sm:w-3/5": true}'
        :wrapper-class='{"sm:mx-auto": true}'
      )

      template(v-if="state.selected.playerID && !state.exactlyOneRegistrationForSelectedPlayer")
        FormKit(
          type="select"
          label="Registrations"
          v-model="state.selected.registrationID"
          :options="state.options.availableRegistrations"
          :outer-class='{"sm:w-3/5": true}'
          :wrapper-class='{"sm:mx-auto": true}'
        )

      template(v-if="state.selected.registrationID")
        FormKit(
          type="select"
          label="Program registrations"
          v-model="state.selected.competitionRegistrationID"
          :options="state.options.availableCompetitionRegistrations"
          :outer-class='{"sm:w-3/5": true}'
          :wrapper-class='{"sm:mx-auto": true}'
        )

    .px-4.py-5(class='sm:px-6 sm:flex sm:flex-col sm:items-center')
      template(v-if="state.targetCompetitionRegistration && state.form")
        h3 {{ state.targetCompetitionRegistration.transtype.trim() || "Program Registration" }} - ${{ state.targetCompetitionRegistration.fee.toFixed(2) }}
        FormKit(
          :form-class='{"sm:w-3/5": true}'
          :actions-class='{"CASH-OR-CHECK-IMPL-sm-formkit-non-uniform-width-kludge": true}'
          :config='{classes: {messages: {"CASH-OR-CHECK-IMPL-sm-formkit-non-uniform-width-kludge": true}}}'
          type="form" @submit="submit(state.form)" :actions="!state.targetCompetitionRegistration.paid")
          FormKit(
            :outer-class='{"sm:w-full": true}'
            :fieldset-class='{"CASH-OR-CHECK-IMPL-sm-formkit-fieldset-margin-kludge": true}'
            type="radio", v-model="state.form.paymentType" label="Cash or check?" :options='[{value: "cash", label: "Cash"}, {value: "check", label: "Check"}]')
          FormKit(
            :outer-class='{"sm:w-full": true}'
            :wrapper-class='{"sm:mx-auto": true}'
            type="number" v-model="state.form.feePaid" label="Amount" step=".01" :validation="[['required'], ['min',0]]")
          FormKit(
            :outer-class='{"sm:w-full": true}'
            :wrapper-class='{"sm:mx-auto": true}'
            type="text" v-model="state.form.checkOrBatchNumber" :label="state.form.paymentType === PaymentType.check ? 'Check number' : 'Batch number'")
          FormKit(
            :outer-class='{"sm:w-full": true}'
            :wrapper-class='{"sm:mx-auto": true}'
            type="textarea" v-model="state.form.comments" label="Comments")
          FormKit(
            :outer-class='{"CASH-OR-CHECK-IMPL-sm-formkit-non-uniform-width-kludge": true}'

            type="checkbox" v-model="state.form.emailConfirmation" label="Email confirmation")
          div(v-if="state.targetCompetitionRegistration.paid")
            div
              | &#x2713; Paid
            router-link(
              :to='{name: `player-editor`, params: {playerID: state.targetCompetitionRegistration.childID, registrationID: state.targetCompetitionRegistration.registrationID}}'
            )
              a.underline.text-blue-600(href="javascript:void(0)") Details page for this registration
</template>

<script lang="ts">
import { Season, Registration, Guid, WithDefinite, CompetitionRegistration } from "src/interfaces/InleagueApiV1"
import { onMounted, defineComponent, reactive, watch, ref, computed, Ref, ComputedRef } from "vue"
import * as ilapi from "src/composables/InleagueApiV1"
import { axiosInstance } from "src/boot/axios"
import { propsDef } from "./CashOrCheckImpl"
import { assertNonNull, exhaustiveCaseGuard } from "src/helpers/utils"

export default defineComponent({
  props: propsDef(),
  setup(props) {
    const state = ComponentState(props.seasonsMap);

    watch(() => state.selected.grouping.value, async (freshGrouping) => {
      state.selected.playerID.value = "";
      state.selected.registrationID.value= "";
      state.selected.competitionRegistrationID.value = "";
      state.form.value = null;

      if (state.registrationListingMap.value[freshGrouping] === null) {
        state.registrationListingMap.value[freshGrouping] = await getPendingRegistrationsByGrouping(freshGrouping, props.seasonUID);
      }
    });

    watch(() => state.selected.playerID.value, (freshPlayerID) => {
      state.selected.registrationID.value = "";
      state.selected.competitionRegistrationID.value = "";
      state.form.value = null;

      if (freshPlayerID === "") {
        return;
      }

      // if there's only really 1 available registration, we can just select it immediately
      // We expect here that the `exactlyOneRegistrationForSelectedPlayer` computed has updated prior to arriving in this watch,
      // which seems reasonable given that that computed was registered prior to this watch, but not sure if that's guaranteed
      // behavior? ... in the failure case (where this ordering assumption doesn't hold), the user will have to choose
      if (state.exactlyOneRegistrationForSelectedPlayer.value) {
        state.selected.registrationID.value = state.exactlyOneRegistrationForSelectedPlayer.value.registrationID;
      }
    })

    watch(() => state.selected.registrationID.value, (freshRegID) => {
      state.selected.competitionRegistrationID.value = "";
      state.form.value = null;
    })

    watch(() => state.selected.competitionRegistrationID.value, (freshCompRegID) => {
      if (freshCompRegID === "") {
        state.form.value = null;
        return;
      }
      else {
        assertNonNull(state.targetCompetitionRegistration.value, "definitely non-null by computed prop evaluating on update of compRegID");
        state.form.value = FormEntry(state.targetCompetitionRegistration.value);
      }
    })

    const forceRefresh = async () => {
      state.selected.playerID.value = "";
      state.selected.registrationID.value = "";
      state.selected.competitionRegistrationID.value = "";
      state.form.value = null;

      state.registrationListingMap.value = {
        [RegistrationGrouping.all]: null,
        [RegistrationGrouping.justTodays]: null,
      }

      state.registrationListingMap.value[state.selected.grouping.value] = await getPendingRegistrationsByGrouping(state.selected.grouping.value, props.seasonUID);
    }

    const submit = async (formEntry: FormEntry) => {
      const freshCompReg = await ilapi.activateCompetitionRegistrationWithOutOfBandPayment(
        axiosInstance,
        {
          registrationID: formEntry.targetCompetitionRegistration.registrationID,
          competitionRegistrationID: formEntry.targetCompetitionRegistration.competitionRegistrationID,
          comments: formEntry.comments,
          // string representing number, we expect the form to have verified this
          // can we get formkit to fill in just a number, and set it to null on invalid or empty or ... ?
          paymentAmount: formEntry.feePaid as any as number,
          paymentType: formEntry.paymentType,
          paymentID: formEntry.checkOrBatchNumber,
          emailConfirmation: formEntry.emailConfirmation
        }
      );

      updateRegistrationListings(state.registrationListingMap.value, freshCompReg);
    }

    onMounted(async () => {
      state.registrationListingMap.value[RegistrationGrouping.justTodays] = await getPendingRegistrationsByGrouping(RegistrationGrouping.justTodays);
    })

    return {
      // reactive so we don't have to say `.value` in the template
      state: reactive(state),
      submit,
      PaymentType,
      forceRefresh,
    }
  }
})

async function getPendingRegistrationsByGrouping(which: RegistrationGrouping.justTodays) : Promise<MappedRegistrationListing>;
async function getPendingRegistrationsByGrouping(which: RegistrationGrouping, seasonUID: Guid) : Promise<MappedRegistrationListing>;
async function getPendingRegistrationsByGrouping(which: RegistrationGrouping, seasonUID?: Guid) : Promise<MappedRegistrationListing> {
  switch (which) {
    case RegistrationGrouping.justTodays:
      return mungeResponse(await ilapi.getPendingRegistrations(axiosInstance, {which: "today"}));
    case RegistrationGrouping.all:
      assertNonNull(seasonUID, "non-null if which===all");
      return mungeResponse(await ilapi.getPendingRegistrations(axiosInstance, {which: "season", seasonUID}));
    default: exhaustiveCaseGuard(which);
  }

  function mungeResponse(v: ExpandedRegistration[]) {
    const result : {[childID: Guid]: {[registrationID: Guid]: ExpandedRegistration}} = {};
    for (const registration of v) {
      result[registration.childID] ??= {};
      result[registration.childID][registration.registrationID] = registration;
    }
    return result;
  }
}

function generatePlayerSelectOptions(registrationListing: MappedRegistrationListing) : UiOption<Guid>[] {
  const result : UiOption<Guid>[] = [];
  for (const registrationsPerChild of Object.values(registrationListing)) {
    const registrationIDs = Object.keys(registrationsPerChild);
    if (registrationIDs.length === 0) {
      // weird case; we shouldn't have an entry in the listing for a child that maps to zero registrations
      // nothing we can do
      continue;
    }
    // 99% of the time we'll only have a single entry,
    // but even if we have more than 1 registration per child, the (childID, playerFirstName, playerLastName) values
    // will be the same across every registration for that child; so we pick it from the first
    const {childID, playerFirstName, playerLastName} = registrationsPerChild[registrationIDs[0]];
    result.push({
      value: childID,
      label: `${playerLastName}, ${playerFirstName}`,
    })
  }

  // intent is sort by last name, ascending
  result.sort((l,r) => l.label < r.label ? -1 : 1);

  if (result.length > 0) {
    // place empty option first
    result.unshift({value: "", label: ""})
  }
  else {
    result.push({label: "No players with pending registrations found", value: "", attrs: {disabled: true}})
  }
  return result;
}

function generateRegistrationSelectOptions(regs: Registration[], seasonsMap: {[seasonUID: Guid]: Season}) : UiOption<Guid>[] {
  const result : UiOption<Guid>[] = [{value: "", label: ""}];
  for (const reg of regs) {
    result.push({
      value: reg.registrationID,
      label: seasonsMap[reg.seasonUID]?.name ?? `Season ${reg.seasonUID}`,
    })
  }
  return result;
}

function generateCompetitionRegistrationSelectOptions(compRegs: CompetitionRegistration[]) : UiOption<number | string>[] {
  const result : UiOption<number | string>[] = [{value: "", label: ""}];
  for (const compReg of compRegs) {
    const paid = compReg.paid ? " (paid)" : "";
    result.push({
      value: compReg.competitionRegistrationID,
      label: `${compReg.competitionName}${paid}`,
    })
  }
  return result;
}

/**
 * We treat a GroupedRegistrationListing as a local cache,
 * this helper takes a new CompetitionRegistration (presumably updated after having been paid),
 * and updates the appropriate entries in the supplied cache
 */
function updateRegistrationListings(listings: GroupedRegistrationListing, freshCompReg: CompetitionRegistration): void {
  {
    const targetChildTodaysRegistrations = listings[RegistrationGrouping.justTodays]?.[freshCompReg.childID];
    if (targetChildTodaysRegistrations) {
      scanReplaceCompRegWithFresh(targetChildTodaysRegistrations);
    }
  }

  {
    const targetChildAllRegistrations = listings[RegistrationGrouping.all]?.[freshCompReg.childID];
    if (targetChildAllRegistrations) {
      scanReplaceCompRegWithFresh(targetChildAllRegistrations);
    }
  }

  function scanReplaceCompRegWithFresh(regs: {[registrationID: Guid]: ExpandedRegistration}) : void {
    for (const reg of Object.values(regs)) {
      const compRegs = reg.competitions;
      for (let i = 0; i < compRegs.length; ++i) {
        if (compRegs[i].competitionUID === freshCompReg.competitionUID) {
          compRegs[i] = freshCompReg;
          return;
        }
      }
    }
  }
}

type ExpandedRegistration = WithDefinite<Registration, "competitions">

interface UiOption<T> {
  value: T,
  label: string,
  attrs?: { [key: string]: any }
}

enum RegistrationGrouping { justTodays = "justTodays", all = "all" }
enum PaymentType { cash = "cash", check = "check" }

type MappedRegistrationListing = {[childID: Guid]: {[registrationID: Guid]: ExpandedRegistration}};
type GroupedRegistrationListing = Record<RegistrationGrouping, MappedRegistrationListing | null>;

interface ComponentState {
  readonly selected: {
    grouping: Ref<RegistrationGrouping>,
    playerID: Ref<Guid>,
    registrationID: Ref<Guid>,
    competitionRegistrationID: Ref<number | string>,
  },
  readonly options: {
    readonly grouping: readonly UiOption<RegistrationGrouping>[],
    readonly availablePlayers: ComputedRef<UiOption<Guid>[]>,
    readonly availableRegistrations: ComputedRef<UiOption<Guid>[]>,
    readonly availableCompetitionRegistrations: ComputedRef<UiOption<string | number>[]>,
  },
  readonly targetCompetitionRegistration: ComputedRef<CompetitionRegistration | null>,
  /**
   * null if not true (i.e. there is more than one registration for the currently selected user),
   * otherwise an ExpandedRegistration (that is intended to serve double duty as truthy)
   */
  readonly exactlyOneRegistrationForSelectedPlayer: ComputedRef<ExpandedRegistration | null>,
  registrationListingMap: Ref<GroupedRegistrationListing>,
  form: Ref<FormEntry | null>
}

function ComponentState(seasonsMap: {[seasonUID: Guid]: Season}) : ComponentState {
  const form = ref<FormEntry | null>(null);

  const selected = {
    grouping: ref(RegistrationGrouping.justTodays),
    playerID: ref(""),
    registrationID: ref(""),
    competitionRegistrationID: ref("")
  };

  const registrationListingMap = ref<GroupedRegistrationListing>({
    [RegistrationGrouping.justTodays]: null,
    [RegistrationGrouping.all]: null
  });

  const options = {
    grouping: [
      {
        value: RegistrationGrouping.justTodays,
        label: `Just today's registrations`
      },{
        value: RegistrationGrouping.all,
        label: `All unregistered players`
      }
    ],
    availablePlayers: computed(() => {
      const listing = registrationListingMap.value[selected.grouping.value];
      if (!listing) {
        return [];
      }
      return generatePlayerSelectOptions(listing);
    }),
    availableRegistrations: computed(() => {
      const regs = registrationListingMap.value[selected.grouping.value]?.[selected.playerID.value];
      if (!regs) {
        return []
      }
      return generateRegistrationSelectOptions(Object.values(regs), seasonsMap);
    }),
    availableCompetitionRegistrations: computed(() => {
      const compRegs = registrationListingMap.value[selected.grouping.value]?.[selected.playerID.value]?.[selected.registrationID.value]?.competitions;
      if (!compRegs) {
        return [];
      }
      return generateCompetitionRegistrationSelectOptions(compRegs);
    })
  };

  const targetCompetitionRegistration = computed(() => {
    if (selected.competitionRegistrationID.value === "") {
      return null;
    }

    const targetRegistration = registrationListingMap.value
      ?.[selected.grouping.value]
      ?.[selected.playerID.value]
      ?.[selected.registrationID.value];

    assertNonNull(targetRegistration, "expected to find a registration");

    for (const compReg of targetRegistration.competitions) {
      if (compReg.competitionRegistrationID.toString() === selected.competitionRegistrationID.value.toString()) {
        return compReg;
      }
    }

    throw "unexpected failure to find target competition registration";
  });

  // in most (all!?) cases this will be truthy (non-null, meaning "yeah just one registration"),
  // since we're focused on either "today's registrations" or "some season's registrations"
  // For completeness, we support receiving more than 1 pending registration per child (i.e. there are pending registrations for more than 1 season outstanding)
  const exactlyOneRegistrationForSelectedPlayer = computed<ExpandedRegistration | null>(() => {
    const regs = registrationListingMap.value[selected.grouping.value]?.[selected.playerID.value];
    if (!regs) {
      // there are no registrations for the selected player, or no selected player
      return null;
    }
    const keys = Object.keys(regs);
    if (keys.length === 1) {
      return regs[keys[0]];
    }
    else {
      return null;
    }
  })

  return {
    selected,
    options,
    registrationListingMap,
    targetCompetitionRegistration,
    exactlyOneRegistrationForSelectedPlayer,
    form,
  };
}

interface FormEntry {
  readonly targetCompetitionRegistration: Readonly<CompetitionRegistration>,
  feePaid: string,
  checkOrBatchNumber: string,
  emailConfirmation: boolean,
  comments: string,
  paymentType: PaymentType
}

function FormEntry(competitionRegistration: CompetitionRegistration) : FormEntry {
  return reactive<FormEntry>({
    targetCompetitionRegistration: competitionRegistration,
    feePaid: "",
    checkOrBatchNumber: "",
    emailConfirmation: false,
    comments: "",
    paymentType: PaymentType.cash
  })
}
</script>

<!--
  we cannot scope these styles because we need to add these to elements using FormKit's :selector-key="{'statically-known-classname': true}"
  But we don't know a scoped-style's actual name, and the compiler will not auto-transform it for us in the template if it is not in class-attribute position
-->
<style>

/** this min-width must correspond exactly with tailwind's `sm:` breakpoint in px */
/** todo: Just use FormKit's magic '$reset' classname? */
@media (min-width: 640px) {
  .CASH-OR-CHECK-IMPL-sm-formkit-fieldset-margin-kludge {
    --fk-margin-fieldset: auto;
  }
  .CASH-OR-CHECK-IMPL-sm-formkit-non-uniform-width-kludge {
    max-width: var(--fk-max-width-input);
    margin-left:auto;
    margin-right:auto;
  }
}
</style>