import { FormKit } from "@formkit/vue";
import { faFilterCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { axiosAuthBackgroundInstance } from "src/boot/axios";
import { SoccerBall, X } from "src/components/SVGs";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { AutoModal, DefaultModalController } from "src/components/UserInterface/Modal";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { accentAwareCaseInsensitiveCompare, assertNonNull, EscapedRegExp, exhaustiveCaseGuard, sortBy, sortByMany, SortDir, UiOption, vReqT } from "src/helpers/utils";
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { downloadAsXlsxFactory, FontAwesomeSortArrow, freshSortState } from "src/modules/TableUtils";
import { Client } from "src/store/Client";
import { computed, defineComponent, reactive, ref, watch } from "vue";

import { DynamicScroller, DynamicScrollerSlots, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export const CompetitionEligibilityStatusReport = defineComponent({
  props: {
    seasonUID: vReqT<Guid>(),
    competitionUID: vReqT<Guid>(),
  },
  setup(props) {
    const competitionEligbilityStatusReport = ReactiveReifiedPromise<CompetitionEligibilityStatusReportResponse>()
    const competitionEligbilityStatusReportFlat = computed(() => {
      return competitionEligbilityStatusReport.underlying.status === "resolved" ? [
        ...competitionEligbilityStatusReport.underlying.data.byChildID,
        ...competitionEligbilityStatusReport.underlying.data.byNameAndDOB,
      ] : []
    })
    const competitionEligbilityStatusReportFilters = ref<Filters>({
      type: [],
      name: "",
      status: [],
    })

    watch([() => props.seasonUID, () => props.competitionUID], () => {
      if (props.seasonUID && props.competitionUID) {
        competitionEligbilityStatusReport.run(() => getCompetitionEligibilityStatusReport(axiosAuthBackgroundInstance, {
          competitionUID: props.competitionUID,
          seasonUID: props.seasonUID
        }))
      }
      else {
        // do nothing
      }
    }, {immediate: true})

    const competitionEligbilityStatusReportFilteredFlat = computed(() => {
      if (competitionEligbilityStatusReport.underlying.status !== "resolved") {
        return []
      }

      const filterFuncs : {[K in keyof Filters]: (v: CompetitionEligbitilityStatusReportElement) => boolean} = {
        type: (() => {
          if (competitionEligbilityStatusReportFilters.value.type.length === 0) {
            return () => true
          }
          else {
            return (row) => competitionEligbilityStatusReportFilters.value.type.includes(row.type)
          }
        })(),
        name: (() => {
          const s = competitionEligbilityStatusReportFilters.value.name.trim()
          if (s.trim() === "") {
            return () => true
          }
          else {
            const pattern = EscapedRegExp(s, "i")
            return (row) => !!row.player && pattern.test(`${row.player.playerFirstName} ${row.player.playerLastName}`)
          }
        })(),
        status: (() => {
          if (competitionEligbilityStatusReportFilters.value.status.length === 0) {
            return () => true
          }
          else {
            return (row) => {
              if (!row.player) {
                return competitionEligbilityStatusReportFilters.value.status.includes("none")
              }
              else {
                return competitionEligbilityStatusReportFilters.value.status.includes(row.player.status)
              }
            }
          }
        })()
      };

      return competitionEligbilityStatusReportFlat.value.filter(row => {
        return filterFuncs.type(row)
          && filterFuncs.name(row)
          && filterFuncs.status(row)
      })
    })

    const downloadCompetitionEligibilityStatusReportAsXlsx = async (rows: CompetitionEligbitilityStatusReportElement[]) => {
      const competition = await Client.getCompetitionByUidOrFail(props.competitionUID)
      const season = await Client.getSeasonByUidOrFail(props.seasonUID)
      const date = dayjs().format("MM-DD-YYYY")
      const fileName = `${date}_${season.seasonName}_${competition.competition}.xlsx`.replaceAll(/\s+/g, "_")
      downloadAsXlsxFactory<CompetitionEligbitilityStatusReportElement>([
        {
          id: "type",
          label: "Type",
          html: "never",
          xlsx: v => v.type === "byChildID" ? "Exact by playerID" : `By lastName '${v.rule_lastName}' and DOB '${dayjs(v.rule_DOB).format("MMM/DD/YYYY")}'`
        },
        {
          id: "name",
          label: "Name",
          html: "never",
          xlsx: v => v.player ? `${v.player.playerFirstName} ${v.player.playerLastName}` : "(n/a)"
        },
        {
          id: "name",
          label: "Status",
          html: "never",
          xlsx: v => uiStatus(v)
        }
      ])(rows, fileName)
    }

    return () => {
      return (
        <div class="my-4 rounded-md il-box-shadow-2">
          <div class="text-sm p-2 bg-gray-200">Registration Status for Invited Players</div>
          {competitionEligbilityStatusReport.underlying.status === "resolved"
            ? <CompetitionEligibilityStatusReportImpl
              xsFlat={competitionEligbilityStatusReportFlat.value}
              xsFilteredFlat={competitionEligbilityStatusReportFilteredFlat.value}
              filters={competitionEligbilityStatusReportFilters.value}
              onDownloadXlsx={sortedRows => downloadCompetitionEligibilityStatusReportAsXlsx(sortedRows)}
            />
            : competitionEligbilityStatusReport.underlying.status === "pending"
            ? <div class="text-sm p-2 flex gap-1 items-center">
              <SoccerBall color={Client.value.clientTheme.color} timeForOneRotation="2s" width="1.25em" height="1.25em"/>
              <span>Loading...</span>
            </div>
            : <div class="p-2">No data</div>
          }
        </div>
      )
    }
  }
})

const CompetitionEligibilityStatusReportImpl = defineComponent({
  props: {
    xsFlat: vReqT<CompetitionEligbitilityStatusReportElement[]>(),
    xsFilteredFlat: vReqT<CompetitionEligbitilityStatusReportElement[]>(),
    filters: vReqT<Filters>(),
  },
  emits: {
    downloadXlsx: (_sortedRows: CompetitionEligbitilityStatusReportElement[]) => true,
  },
  setup(props, {emit}) {
    const rowBgColorStyle = (row: number) => {
      return row % 2 ? {backgroundColor: "#FFFFFF"} : {backgroundColor: "#F0F0F0"};
    }

    const sortState = ref(freshSortState<CompetitionEligbitilityStatusReportElement, string>([
      {
        id: "type",
        sort: sortBy(_ => _.type === "byChildID" ? 1 : _.type === "byNameAndDOB" ? 2 : exhaustiveCaseGuard(_))
      },
      {
        id: "name",
        sort: sortByMany(
          (l,r) => accentAwareCaseInsensitiveCompare(l.player?.playerLastName || "", r.player?.playerLastName || ""),
          (l,r) => accentAwareCaseInsensitiveCompare(l.player?.playerFirstName || "", r.player?.playerFirstName || ""),
        )
      },
      {
        id: "status",
        sort: sortBy(_ => _.player?.status === "registered" ? 0
          : _.player?.status === "waitlisted" ? 1
          : _.player?.status === "incomplete" ? 2
          : (_.player?.status === "none" || !_.player) ? 3
          : 999)
      }
    ]))
    sortState.value.reconfigure([{colID: "name", dir: "asc"}])

    const SortArrow = ({sortID}: {sortID: string}) => {
      return <SortArrowImpl
        dir={sortState.value.sortersByColID[sortID]?.dir || "asc"}
        onClick={() => sortState.value.sortersByColID[sortID]?.sortAndPrioritize()}
      />
    }

    const sortedWithIdAndDenseIdx = computed(() => {
      return props.xsFilteredFlat.sort(sortState.value.asSorter()).map((v,i) => ({idx: i, id: idifyForVirtualScroll(v), ...v}))
    })

    const typeFilterModalController = reactive((() => {
      const typeFilterOptions : UiOption<CompetitionEligbitilityStatusReportElement_PerPlayer["type"]>[] = [
        {label: "By name/DOB", value: "byNameAndDOB"},
        {label: "Exact match", value: "byChildID"},
      ];

      return DefaultModalController<void>({
        title: () => <>
          <div>Filter by invitation type</div>
          <div class="my-2 border-b"></div>
        </>,
        content: () => {
          return <div style="--fk-margin-outer: none; --fk-border: none; --fk-padding-fieldset: none;">
            <Btn2 class="py-1 px-2 mb-4 " disabled={props.filters.type.length === 0} onClick={() => {props.filters.type = []}}>Clear</Btn2>
            <FormKit type="checkbox" options={typeFilterOptions} v-model={props.filters.type}/>
          </div>
        }
      })
    })());

    const statusFilterModalController = reactive((() => {
      const statusFilterOptions : UiOption<CompetitionEligbitilityStatusReportElement_PerPlayer["status"]>[] = [
        {label: "Registered", value: "registered"},
        {label: "Waitlisted", value: "waitlisted"},
        {label: "Incomplete", value: "incomplete"},
        {label: "No registration", value: "none"}
      ];

      return DefaultModalController<void>({
        title: () => <>
          <div>Filter by status</div>
          <div class="my-2 border-b"></div>
        </>,
        content: () => {
          return <div style="--fk-margin-outer: none; --fk-border: none; --fk-padding-fieldset: none;">
            <Btn2 class="py-1 px-2 mb-4 " disabled={props.filters.status.length === 0} onClick={() => {props.filters.status = []}}>Clear</Btn2>
            <FormKit type="checkbox" options={statusFilterOptions} v-model={props.filters.status}/>
          </div>
        }
      })
    })());

    const counts = computed(() => {
      let registered = 0
      let waitlisted = 0
      let incomplete = 0
      let noRegistration = 0

      for (const x of props.xsFlat) {
        if (!x.player) {
          // nameAndDOB with no matching players
          noRegistration++
          continue
        }

        switch (x.player.status) {
          case "registered":
            registered++;
            continue;
          case "waitlisted":
            waitlisted++;
            continue;
          case "incomplete":
            incomplete++;
            continue;
          case "none":
            noRegistration++;
            continue;
          default: exhaustiveCaseGuard(x.player.status)
        }
      }

      return {
        registered, waitlisted, incomplete, noRegistration
      }
    })

    const px_minItemHeight = 20
    const cssUnit_colWidth_type = "2in"
    const cssUnit_colWidth_name = "3.5in"
    const cssUnit_colWidth_status = "2in"
    const cssUnit_minRowWidth = "7.5in"

    return () => (
      <div class="InvitedOverview relative w-full">
        <AutoModal controller={statusFilterModalController}/>
        <AutoModal controller={typeFilterModalController}/>
        <div class="mx-1 mt-1 flex flex-wrap gap-1 text-xs">
          <Btn2 class="text-xs px-1 flex gap-1 items-center text-nowrap" onClick={() => emit("downloadXlsx", sortedWithIdAndDenseIdx.value)}>
            <FontAwesomeIcon icon={faDownload}/>
            <span>Download as Excel</span>
          </Btn2>
          <div class="rounded-md border p-1 flex items-center">Registered: {counts.value.registered}</div>
          <div class="rounded-md border p-1 flex items-center">Waitlisted: {counts.value.waitlisted}</div>
          <div class="rounded-md border p-1 flex items-center">Incomplete: {counts.value.incomplete}</div>
          <div class="rounded-md border p-1 flex items-center">No registration: {counts.value.noRegistration}</div>
        </div>
        <style>
            {`
              .InvitedOverview div.header.row .cell,
              .InvitedOverview div.body.row .cell {
                padding: .3em;
              }
              .InvitedOverview .vue-recycle-scroller__slot:first-child {
                top:0;
                position:sticky;
                z-index:1;
                background-color:white;
              }

              .InvitedOverview .vue-recycle-scroller__item-wrapper {
                  overflow: visible !important;
              }
              .InvitedOverview .vue-recycle-scroller {
                  overflow: auto !important;
              }
            `}
        </style>
        <DynamicScroller
          style="max-height:40vh; overflow:auto;"
          items={sortedWithIdAndDenseIdx.value}
          minItemSize={px_minItemHeight}
        >
          {{
            after: () => {
              if (props.xsFilteredFlat.length === 0) {
                return <div class="p-2">No results.</div>
              }
              else {
                return null;
              }
            },
            before: () => {
              return (
                <div class="text-sm row header" style={{position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, minWidth: cssUnit_minRowWidth}}>
                  <div class="cell inline-block" style={`width:${cssUnit_colWidth_type};`}>
                    <div class="font-medium flex items-center gap-1">
                      Type
                      <SortArrow sortID="type"/>
                    </div>
                    <Btn2 class="text-xs px-2 py-1 flex items-center gap-1" onClick={() => typeFilterModalController.open()}>
                      {props.filters.type.length > 0 ? <FontAwesomeIcon icon={faFilterCircleXmark}/> : null}
                      Filter...
                    </Btn2>
                  </div>
                  <div class="cell inline-block" style={`width:${cssUnit_colWidth_name};`}>
                    <div class="font-medium flex items-center gap-1">
                      Player Name
                      <SortArrow sortID="name"/>
                    </div>
                    <div class="flex items-center gap-1">
                      <input type="text" class="text-xs p-1 rounded-md" v-model={props.filters.name}/>
                      <button type="button" class={`${!props.filters.name ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filters.name = ""}}>
                        <X width=".825em" height=".825em"/>
                      </button>
                    </div>
                  </div>
                  <div class="cell inline-block" style={`width:${cssUnit_colWidth_status};`}>
                    <div class=" font-medium flex items-center gap-1">
                      Status
                      <SortArrow sortID="status"/>
                    </div>
                    <Btn2 class="text-xs px-2 py-1 flex items-center gap-1" onClick={() => statusFilterModalController.open()}>
                      {props.filters.status.length > 0 ? <FontAwesomeIcon icon={faFilterCircleXmark}/> : null}
                      Filter...
                    </Btn2>
                  </div>
                </div>
              )
            },
            default: ({item, index, active}) => {
              return (
                <DynamicScrollerItem
                  key={`dynitem/${item.id}`}
                  item={item}
                  active={active}
                  data-index={index}
                >
                  {/* bg style on "row" to extend all the way to end of scroll container, not just outer visual container*/}
                  {/* bg style on absolute inner element to extend all the way to end of visual container in case row does not extend that far*/}
                  <div key={`row/${item.id}`} class="body row" style={{...rowBgColorStyle(item.idx), minHeight: `${px_minItemHeight}px`, minWidth: "8in"}}>
                    <div class="absolute w-full h-full bg-yellow-700" style={rowBgColorStyle(item.idx)}></div>
                    <div style={`display:grid; grid-template-columns: ${cssUnit_colWidth_status} ${cssUnit_colWidth_name} ${cssUnit_colWidth_type}`}>
                      <div class="cell relative" style={{width:cssUnit_colWidth_type}}>
                        {item.type === "byChildID"
                          ? <div>Exact player match</div>
                          : <>
                            <div class="text-xs">By name & DOB</div>
                            <div>{item.rule_lastName}, {dayjs(item.rule_DOB).format("MMM/DD/YYYY")}</div>
                          </>
                        }
                      </div>
                      <div class="cell relative" style={{width:cssUnit_colWidth_name}}>
                        {item.player
                          ? `${item.player.playerFirstName} ${item.player.playerLastName}`
                          : "(n/a)"
                        }
                      </div>
                      <div class="cell relative" style={{width:cssUnit_colWidth_status}}>
                        {uiStatus(item)}
                      </div>
                    </div>
                  </div>
                </DynamicScrollerItem>
              )
            }
          } satisfies DynamicScrollerSlots<(typeof sortedWithIdAndDenseIdx.value)[number]>}
        </DynamicScroller>

      </div>
    )
  }
})

export interface Filters {
  type: CompetitionEligbitilityStatusReportElement["type"][],
  name: string,
  status: CompetitionEligbitilityStatusReportElement_PerPlayer["status"][],
}

const SortArrowImpl = defineComponent({
  props: {
    dir: vReqT<SortDir | "not-sorted">(),
  },
  emits: {
    click : () => true,
  },
  setup(props, {emit}) {
    return () => (
      <span class={"p-1 rounded-md hover:bg-[rgb(0,0,0,.06125)] active:bg-[rgb(0,0,0,.125)] cursor-pointer"} onClick={() => emit("click")}>
        <FontAwesomeSortArrow dir={props.dir}/>
      </span>
    )
  }
})

export function uiStatus(v: CompetitionEligbitilityStatusReportElement) {
  if (v.type === "byNameAndDOB" && !v.player) {
    return "No matching players for this name/DOB rule."
  }

  assertNonNull(v.player, "definitely non-null after prior check")

  switch (v.player.status) {
    case "incomplete":
      return "Incomplete registration"
    case "none":
      return "No registration"
    case "registered":
      return "Registered"
    case "waitlisted":
      return "Registered (waitlisted)"
    default: exhaustiveCaseGuard(v.player.status)
  }
}

function idifyForVirtualScroll(v: CompetitionEligbitilityStatusReportElement) {
  if (v.type === "byChildID") {
    return `${v.type}/playerID=${v.player.childID}`
  }
  else {
    return `${v.type}/playerID=${v.player?.childID || "no-match-for-nameAndDOB"}/ruledID=${v.rule_ID}`
  }
}

export type CompetitionEligbitilityStatusReportElement =
  | CompetitionEligbitilityStatusReportElement_ByPlayer
  | CompetitionEligbitilityStatusReportElement_ByNameAndDob

export interface CompetitionEligbitilityStatusReportElement_PerPlayer {
  type: "byChildID" | "byNameAndDOB",
  childID: Guid,
  registrationID: "" | Guid,
  playerFirstName: string,
  playerLastName: string,
  status: "registered" | "waitlisted" | "incomplete" | "none"
}

export interface CompetitionEligbitilityStatusReportElement_ByPlayer {
  type: "byChildID"
  player: CompetitionEligbitilityStatusReportElement_PerPlayer
}

export interface CompetitionEligbitilityStatusReportElement_ByNameAndDob {
  type: "byNameAndDOB"
  rule_ID: Integerlike,
  rule_DOB: Datelike,
  rule_lastName: string,
  player: CompetitionEligbitilityStatusReportElement_PerPlayer | null
}

export type CompetitionEligibilityStatusReportResponse = {
  byChildID: CompetitionEligbitilityStatusReportElement_ByPlayer[],
  byNameAndDOB: CompetitionEligbitilityStatusReportElement_ByNameAndDob[]
}

export async function getCompetitionEligibilityStatusReport(ax: AxiosInstance, args: {seasonUID: Guid, competitionUID: Guid}) : Promise<CompetitionEligibilityStatusReportResponse> {
  const response = await ax.get(`v1/competitionEligibility/statusReport`, {params: {seasonUID: args.seasonUID, competitionUID: args.competitionUID}});
  return response.data.data;
}
