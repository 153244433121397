import { ExtractEmitsHandlers } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { ExtractPropTypes, PropType } from "vue";

import { EventAugmentedEntity } from "src/interfaces/Store/events";
import * as ilapi from "src/composables/InleagueApiV1"

export const propsDef = {
  event: {
    required: true,
    type: Object as PropType<ilapi.event.GetEventResult>
  },
  entity: {
    required: true,
    type: Object as PropType<EventAugmentedEntity>
  },
  maybeExistingSignup: {
    required: false,
    type: Object as PropType<ilapi.event.EventSignup>
  },
  formkitEventQuestionsSchema: {
    required: true,
    // untypable list of arbitrary dom-node like things
    type: Array as PropType<any[]>
  }
} as const;

export const emitsDef = {
  // @rmme
  // formChanged: (args: {
  //   entity: EventAugmentedEntity,
  //   customQuestions: {[questionID: iltypes.Guid]: /*answer*/ string | boolean | number},
  // }) => true,
  selected: (args: {
    entity: EventAugmentedEntity,
    isSelected: boolean,
  }) => true,
  enrollmentCanceled: (args: {entity: EventAugmentedEntity}) => true,
  // @rmme
  // blur: (...args: any[]) => true, // fixme, do we even use this?
  // @rmme
  //hasError: (args: {entityID: iltypes.Guid, hasError: boolean}) => true
} as const

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;
