<template lang="pug">
div
  .font-medium.text-smt-label.block.mb-2.mt-5(v-if='label' v-html='label')
  .mt-2.flex(:class="{'flex-col' : !row }")
    .flex.flex-row.items-center.m-2(
      v-if='!Array.isArray(options)',
      v-for='(option, optionID) in options',
      :key='optionID'
    )
      input.h-4.w-4.cursor-pointer.text-green-600.border-gray-300(
        :class='hasValidationErrors ? "border-red-500" : ""',
        class='mt-0.5 focus:ring-green-500',
        type='radio',
        :name='name',
        :id='optionID',
        v-model='radioValue',
        :value='optionID',
        :disabled='disabled',
        :required='required',
        @input='handleChange'
      )
      span.mx-3.font-medium.text-gray-700(:for='optionID' v-html='option')
    .inline-block(
      v-else,
      v-for='(option, index) in options',
      :key='option.label'
    )
      //- id of "just a number" is not a valid html id attribute, maybe same issue with above in Array case
      //- querySelector("#0") --> "#0" is not a valid selector
      //- this isn't really a problem unless some other code expects to query by id?
      //- easy fix is prepend "_", could be a breaking change?
      input.inline-block.cursor-pointer.text-green-600.border-gray-300.h-4.w-4(
        :class='hasValidationErrors ? "border-red-500" : ""',
        class='focus:ring-green-500',
        type='radio',
        :name='name',
        :id='(name ?? "") + index',
        :value='option.value',
        v-model='radioValue',
        :disabled='disabled',
        :required='required',
        @input='handleChange'
      )
      label.inline-block.cursor-pointer.ml-1.mr-3.block.font-medium.text-gray-700(
        :for='(name ?? "") + index'
        v-html='option.label'
      )

  div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch, PropType } from 'vue'
export default defineComponent({
  name: 'TRadio',
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Object, Array] as PropType<Record<string, string> | {value: string, label: string}[]>,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: '',
    },
    modelValue: {
      type: [String, Boolean, Number],
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: true
    },
    row: {
      default: false,
      type: Boolean,
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  emits: ['input', 'update:modelValue'],
  setup(props, { emit }) {
    const radioValue = ref('')
    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })

    const handleChange = (event: any) => {
      // console.log('handleChange', event.target.value)
      emit('input', event.target.value)
      emit('update:modelValue', event.target.value)
    }

    watch(()=> props.modelValue, (modelValue) => {
      // console.log('watcher in t-radio called', modelValue)
      radioValue.value = props.modelValue as string
    })

    onMounted(() => {
      // console.log('in t-radio', props.modelValue)
      radioValue.value = props.modelValue as string
    })

    return {
      hasValidationErrors,
      radioValue,
      handleChange,
    }
  },
})
</script>
