<template lang="pug">
div(v-if="ready && ELAs.length>0")
  div(class="mb-6")
    RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")

  h1.flex.items-center.items-start.mb-5
    font-awesome-icon.mr-4(:icon='["fas", "edit"]')
    .text-4xl.font-medium Disclaimer, Assumption of Risk, Waiver, and Consent Agreements - {{ player.playerFirstName }} {{ player.playerLastName }}
  div(v-for="ELA in ELAs" :data-test="`elaID=${ELA.ID}`")
    div.my-8.pb-5(v-html="ELA.Text")
    FormKit(
      v-model="checkboxes[ELA.ID]",
      type='checkbox',
      wrapper-class='my-10',
      label="I AGREE",
      label-class="$reset text-2xl text-red-700 font-bold",
      @click="checkboxes[ELA.ID]=!checkboxes[ELA.ID]"
    )
  .w-96.flex.items-end
    FormKit(
      v-model="elaSignature.AcceptedByFirstName",
      label="First Name"
    )
    FormKit(
      v-model="elaSignature.AcceptedByLastName",
      label="Last Name"
    )
  div(v-if="!nameError") {{ mustBeSignedByMessage }}

  TAlert(v-if="checkboxError || nameError" type="danger")
    div(v-if="checkboxError") Please select all checkboxes.
    div(v-if="nameError") {{ mustBeSignedByMessage }}

  TBtn.mt-4(label="Proceed to Payment" @click="verifySignature")

</template>
<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { CompletedELA, Waiver, WaiverSubmission } from 'src/interfaces/registration'
import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import elas from 'src/composables/elas'
import { UserData } from 'src/interfaces/Store/user'
import * as ilapi from 'src/composables/InleagueApiV1'
import { isCfNull, Invoice, Guid, Integerlike } from 'src/interfaces/InleagueApiV1'
import { CompetitionRegistrationFlow } from 'src/composables/registration'
import { __FIXME__UNSAFE_CAST, assertTruthy, parseIntOr, parseIntOrFail, setIsSame, vReqT } from "src/helpers/utils"
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { AxiosInstance } from 'axios'

import { propsDef } from "./R_PlayerRegistrationWaivers.route"
import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'
import * as RegistrationJourneyBreadcrumb from 'src/components/Registration/RegistrationJourneyBreadcrumb'
import { type RegistrationExpandedForStore } from 'src/interfaces/Store/registration'
import { getRegistrationExpandedForStore } from 'src/store/Registration'
import { CheckoutStore } from 'src/store/CheckoutStore'

export default defineComponent({
  props: propsDef,
  components: {
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
  },
  setup(props) {
    const ELAs = ref([]) as Ref<Waiver[]>
    const elaSignature = ref({AcceptedByFirstName: '', AcceptedByLastName: ''}) as Ref<WaiverSubmission>
    const checkboxes = ref({})
    const checkboxError = ref(false)
    const nameError = ref(false)
    const player = ref({})


    const router = useRouter()
    const route = useRoute()
    const { retainOnlyIncompleteELAs, setupCheckboxes, verifyName, verifyCheckboxes, createPostOptions } = elas()

    const submitELAs = async () : Promise<void> => {
      const options = createPostOptions(props.seasonUID as string, ELAs.value, checkboxes.value, elaSignature.value)
      try {
        await axiosInstance.put(`/v1/registration/playerELAs/player/${props.playerID}`, options)
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      }
    }

    const createInvoice = async (reg: RegistrationExpandedForStore) : Promise<Invoice[]> => {
      // Kinda weird, we have to ask the backend for the reg and its compregs, then filter for the compregs we're looking for.
      // Probably should be pushed more serverside.
      const compRegs = reg.competitions.filter(v => !v.canceled && props.competitionUIDs.includes(v.competitionUID) && !v.invoiceInstanceID)

      assertTruthy(new Set(compRegs.map(v => v.competitionRegistrationID)).size === compRegs.length, "no duplicate compregIDs");

      const response = await ilapi.createCompetitionRegistrationInvoice(
        axiosInstance,
        {
          registrationID: props.registrationID,
          competitionRegistrationIDs: compRegs.map(v => v.competitionRegistrationID)
        }
      );

      await CheckoutStore.setInvoice(response);

      return response;
    }

    const verifySignature = async() => {
      nameError.value = verifyName(elaSignature.value, User.value.userData as UserData);
      checkboxError.value = verifyCheckboxes(checkboxes.value)
      if(!checkboxError.value && !nameError.value) {
        // Prepare and submit ELAs
        // updateELASignature()
        await submitELAs()

        const reg = await getRegistrationExpandedForStore(axiosInstance, props.registrationID)
        const invoices = await createInvoice(reg)

        await nextJourneyStep(invoices.map(v => v.instanceID))
      }
    }

    const getOrCreateInvoiceInstanceID = async () : Promise<Pick<Invoice, "instanceID">[]> => {
      const reg = await getRegistrationExpandedForStore(axiosInstance, props.registrationID)
      const compRegs = reg.competitions.filter(v => !v.canceled && props.competitionUIDs.includes(v.competitionUID));

      // there's a lot of probably-should-be-on-the-backend logic here --
      // We assume: all compregs that we found were either (ALL for the same invoice instanceID) or (none have an invoice)
      // If all share the same invoice instanceID, then invoice instanceID from any particular compreg is "the correct" one,
      // otherwise, we have no invoice instanceID and assume we may create one.
      const invoiceInstanceIDs = compRegs
        .filter(v => parseIntOr(v.invoiceInstanceID, null) !== null)
        .map(v => parseIntOrFail(v.invoiceInstanceID))

      // dedupe is because N compregs can share 1 invoiceInstanceID
      const uniqueCurrentInvoiceInstanceIDs = new Set(invoiceInstanceIDs);

      if (invoiceInstanceIDs.length === compRegs.length) {
        return [...uniqueCurrentInvoiceInstanceIDs].map(v => ({instanceID: v}))
      }
      else {
        const fresh = await createInvoice(reg);
        return [
          ...fresh, // should be "just the ones that needed to be created"
          ...[...uniqueCurrentInvoiceInstanceIDs].map(v => ({instanceID: v})) // "just the ones that already existed"
        ]
      }
    }

    watch(() => [elaSignature.value.AcceptedByFirstName, elaSignature.value.AcceptedByLastName, checkboxes], ()=> {
      nameError.value=false
      checkboxError.value=false
    }, {deep: true})

    const ready = ref(false);

    const nextJourneyStep = async (invoiceInstanceIDs: Integerlike[]) => {
      // We use `replace` here, because on next mount we will auto-advance due to already having completed everything on this page.
      // So ultimately this page is never re-navigable.
      await router.replace(R_Checkout.routeDetailToRouteLocation({invoiceInstanceIDs}))
    }

    onMounted(async ()=> {
      const leagueELAs = await getELAs(axiosInstance, {seasonUID: props.seasonUID})
      const completedELAs = await getPlayersELAs(axiosInstance, {
        playerID: props.playerID,
        seasonUID: props.seasonUID
      })

      ELAs.value = retainOnlyIncompleteELAs(leagueELAs, completedELAs)

      if (ELAs.value.length === 0) {
        //
        // A common 400 error here is "already-paid zero-fee invoice", see details in `getOrCreateInvoiceInstanceID`.
        // Not sure how to handle it ... maybe router push to playerEditor for this registration?
        // The user gets an error toast in this situation from the http error messages response, to the effect of
        // "this compreg is already paid", which is probably a little cryptic for them.
        // Why are they here? Probably cycling back/forward in browser?
        //
        try {
          const invoiceInstanceIDs = (await getOrCreateInvoiceInstanceID()).map(v => v.instanceID);
          await nextJourneyStep(invoiceInstanceIDs)
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
      else {
        checkboxes.value = setupCheckboxes(ELAs.value)
        player.value = await ilapi.getPlayer(axiosInstance, {childID: props.playerID as string})
      }

      ready.value = true
    })

    // n.b. not a ref/computed, is not expected to change during lifetime of component
    const mustBeSignedByMessage = `This form must be signed by the logged-in user: '${(User.value.userData as UserData).firstName} ${(User.value.userData as UserData).lastName}'`;

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.reviewAndPay,
          seasonUID: props.seasonUID,
          competitionUIDs: props.competitionUIDs,
          playerID: props.playerID,
        }
      }
    })

    return {
      ELAs,
      checkboxes,
      verifySignature,
      checkboxError,
      nameError,
      elaSignature,
      player,
      mustBeSignedByMessage,
      ready,
      registrationJourneyBreadcrumbProps
    }
  },
})

export async function getELAs(ax: AxiosInstance, args: {seasonUID: Guid}) : Promise<Waiver[]> {
  const response = await ax.get('v1/registration/playerELAs', {params: {seasonUID: args.seasonUID}})
  return response.data.data
}

export async function getPlayersELAs(ax: AxiosInstance, args: {playerID: Guid, seasonUID: Guid}) : Promise<CompletedELA[]> {
    const response = await ax.get(`/v1/registration/playerELAs/player/${args.playerID}`, {params: {seasonUID: args.seasonUID}})
    return response.data.data
}
</script>
