<template lang="pug">
div
  //- .font-medium.text-smt-label.block.mb-2.mt-5(v-if='label') {{ label }}
  div.grid.gap-x-8(v-if='options && Object.keys(options).length' :class="`grid-cols-${cols}`")
    .flex.items-start.m-2(v-for='(option, key) in options')
      .flex.items-center
        input.h-4.w-4.text-green-600.border-gray-300.rounded(
          :data-test="xDataTest"
          class="focus:ring-green-500"
          :class='hasValidationErrors ? "border-red-500" : ""',
          type='checkbox',
          :modelValue='content',
          :checked='modelValue.includes(key)',
          :placeholder='checkboxLabel',
          @input='toggleKey(key)'
        )
      .pl-7.text-sm.leading-5
        label.font-medium.text-gray-700(v-html='option')

        slot

    div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
      | {{ validation.errorMessage }}
  div(v-else)
    .flex.m-2
      .items-center
        input.h-4.w-4.text-green-600.border-gray-300.rounded(
          :data-test="xDataTest"
          class="focus:ring-green-500"
          :class='hasValidationErrors ? "border-red-500" : ""',
          type='checkbox',
          :modelValue='content',
          :checked='modelValue',
          :placeholder='checkboxLabel',
          @input='handleChange'
        )
      .pl-3.text-sm.flex.items-end
        label.font-medium.text-gray-700(v-html='label')
        slot

  div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref, computed } from 'vue'
export default defineComponent({
  name: 'TCheckbox',
  props: {
    /**
     * data-test attribute but added late, so we take considerations that we can't just blindly forward top level attributes
     * tldr: If you want the <input/> element we render here to have a data-test attribute, add an xDataTest attr to the <t-checkbox/>.
     */
    xDataTest: {
      required: false,
      type: String
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: [Array, Boolean],
      required: true,
    },
    label:{type: String},
    options: {
      type: Object,
      required: false,
    },
    cols: {
      type: [String, Number],
      default: 1
    },
    autoComplete: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const content = ref() as Ref<boolean> | Ref<Array<string>>
    const checkboxLabel = ref('')

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (event: any) => {
      emit('input', event.target.checked)
    }

    const toggleKey = (key: string) => {
      // console.log('toggleKey', key)
      const keys = [...(props.modelValue as Array<string>)]
      // take out of array
      if (keys.includes(key)) {
        // console.log('included')
        const index = keys.findIndex(currentKey => currentKey === key)
        // console.log('index', index)
        keys.splice(index, 1)
        // console.log('keys', keys)
      // add to array
      } else {
        // console.log('need to add')
        keys.push(key)
      }
      emit('input', keys)
      // console.log('emitted', keys)
    }

    onMounted(() => {
      // console.log('props.modelValue', props.modelValue)
      content.value = props.modelValue as boolean | Array<string>
      checkboxLabel.value = props.label as string
    })

    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })
    return {
      hasValidationErrors,
      content,
      checkboxLabel,
      value: props.modelValue,
      // label: props.label,
      handleChange,
      toggleKey,
    }
  },
})
</script>
