import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { FormKit } from "@formkit/vue";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"

import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { AxiosErrorWrapper, axiosBackgroundInstance, axiosInstance } from "src/boot/axios";
import { UiOption, exhaustiveCaseGuard, sortBy } from "src/helpers/utils";
import { RouterLink } from "vue-router";


import * as R_TournamentTeamRegPageItemEditor from "./R_TournamentTeamRegPageItemEditor.route"

import { tournamentTeamStore } from "./Store/TournTeamStore";
import { tournTeamRegPageItemStore } from "./Store/TournTeamRegPageItemStore"

import * as EventuallyPinia from "src/store/EventuallyPinia"

import { PageItemListingPreview, PageItemListingBlurb } from "./TournamentTeamRegPageItem.misc";
import { Draglist } from "./Draglist";
import * as Modal from "src/components/UserInterface/Modal"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-regular-svg-icons";

import * as R_Self from "./R_TournamentTeamRegPageItemListing.route"
import { TournamentTeamRegistrationQuestionsForm } from "./TournamentTeam.questionsForm";

import { nilGuid } from "src/composables/InleagueApiV1";

import { CreateTournamentTeam_TeamForm_Impl, freshCreateTeamForm } from "./TournamentTeamCreate.loggedIn";
import { Client } from "src/store/Client";
import * as ClearOnLogout from "src/store/ClearOnLogout"

type MenuSeason = iltournament.TournTeamRegPageItemMenuSeason;
type MenuCompetition = iltournament.TournTeamRegPageItemMenuCompetition;

const savedState = (() => {
  const selectedSeasonUID = ref<"" | iltypes.Guid>("");
  const selectedCompetitionUID = ref<"" | iltypes.Guid>("");
  const clear = () : void => {
    selectedSeasonUID.value = "";
    selectedCompetitionUID.value = "";
  }
  return {
    selectedSeasonUID,
    selectedCompetitionUID,
    clear
  }
})()

ClearOnLogout.register(savedState);

export default defineComponent({
  name: "R_TournamentTeamRegPageItemListing",
  props: R_Self.propsDef,
  components: {
    ContentChunkDisplay,
  },
  setup(props) {
    const ready = ref(false);


    const menuSeasons = ref<readonly MenuSeason[]>([])
    const menuCompetitions = ref<readonly MenuCompetition[]>([])
    const pageItems = ref<readonly iltournament.TournTeamRegPageItem[]>([])
    const doShowDisabledItems = ref(false)

    const {selectedSeasonUID, selectedCompetitionUID} = savedState;

    /**
     * a selected (season, comp) implies a selected (tournament)
     * TODO: `effectivelySelectedTournament : null | Tournament` (since tournament carries a seasonUID/compUID with it)
     */
    const completeSelection = ref<
      | null
      | {
        readonly seasonUID: iltypes.Guid,
        readonly competitionUID: iltypes.Guid,
        readonly tournament: iltournament.Tournament
      }
    >(null);

    const seasonOptions = computed(() => {
      return [
        {label: "", value: ""},
        ...[...menuSeasons.value].sort(sortBy(_ => _.seasonID)).map(v => ({value: v.seasonUID, label: v.seasonName}))
      ]
    })

    const competitionOptions = computed<{disabled: boolean, options: UiOption[]}>(() => {
      if (selectedSeasonUID.value === "") {
        return {
          disabled: true,
          options: [{label: "Select a season", value: ""}]
        }
      }
      else {
        return {
          disabled: false,
          options: menuCompetitions.value.map(v => ({label: v.competition, value: v.competitionUID}))
        }
      }
    })

    onMounted(async () => {
      menuSeasons.value = await tournTeamRegPageItemStore.getMenuSeasons(axiosInstance);

      if (menuSeasons.value.length > 0 && !selectedSeasonUID.value) {
        selectedSeasonUID.value = menuSeasons.value[0].seasonUID;
      }

      watch(() => selectedSeasonUID.value, async () => {
        if (selectedSeasonUID.value === "") {
          menuCompetitions.value = [];
          doClearCompleteSelection();
          return;
        }
        else {
          menuCompetitions.value = await tournTeamRegPageItemStore.getMenuCompetitions(axiosInstance, {seasonUID: selectedSeasonUID.value});
          if (!!menuCompetitions.value.find(v => v.competitionUID === selectedCompetitionUID.value)) {
            await doCommitCompleteSelection()
          }
          else {
            selectedCompetitionUID.value = "";
            doClearCompleteSelection(); // should also be triggered by selectedCompetitionUID.value watcher, but that's a little cryptic
          }
        }
      }, {immediate: true});

      watch(() => [
        selectedCompetitionUID.value, // typical selection watcher
        menuCompetitions.value // menuCompetitions might be pending an async assignment, need to watch that too
      ], async () => {
        if (selectedSeasonUID.value && selectedCompetitionUID.value) {
          await doCommitCompleteSelection();
        }
        else if (selectedSeasonUID.value && menuCompetitions.value.length > 0) {
          selectedCompetitionUID.value = menuCompetitions.value[0].competitionUID
          doCommitCompleteSelection();
        }
        else {
          doClearCompleteSelection();
        }
      }, {immediate: true});

      ready.value = true;

      return;

      async function doCommitCompleteSelection() : Promise<void> {
        if (!selectedSeasonUID.value || !selectedCompetitionUID.value) {
          throw Error("expected a selected seasonUID and competitionUID here");
        }

        const tournament = await tournamentTeamStore.getTournamentOrFail({seasonUID: selectedSeasonUID.value, competitionUID: selectedCompetitionUID.value});

        completeSelection.value = {
          seasonUID: selectedSeasonUID.value,
          competitionUID: selectedCompetitionUID.value,
          tournament
        }
        pageItems.value = await tournTeamRegPageItemStore.getByTournamentID(axiosInstance, tournament.tournamentID);
      }

      function doClearCompleteSelection() : void {
        completeSelection.value = null;
        pageItems.value = [];
      }
    })

    const doDeletePageItem = async (pageItem: iltournament.TournTeamRegPageItem) : Promise<{ok: boolean}> => {
      try {
        await tournTeamRegPageItemStore.delete(axiosInstance, {tournamentID: pageItem.tournamentID, pageItemID: pageItem.id});
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const reorderPageItemsController = reactive((() => {
      const isBusy = ref(false);
      const onCloseCB = (cb: () => void) => {
        if (isBusy.value) {
          return;
        }
        else {
          cb();
        }
      }

      return Modal.DefaultModalController<readonly iltournament.TournTeamRegPageItem[]>({
        title: () => (
          <>
            <div>Reorder page items</div>
            <div class="my-2 border-b border-slate-200" />
          </>
        ),
        content: pageItems => {
          if (!pageItems) {
            return null;
          }
          return (
            <div class="max-h-96 overflow-y-auto">
              <Draglist
                doMove={async payload => {
                  if (!completeSelection.value) {
                    // shouldn't happen
                    return;
                  }
                  try {
                    isBusy.value = true;
                    await tournTeamRegPageItemStore.movePageItem(axiosBackgroundInstance, completeSelection.value.tournament.tournamentID, payload);
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                  }
                  finally {
                    isBusy.value = false;
                  }
                }}
                list={pageItems}
                render={(pageItem: iltournament.TournTeamRegPageItem, idx) => (
                  <div class={`flex items-center p-1 ${idx % 2 ? 'bg-gray-200' : ''}`}>
                    <div class="p-1 il-drag-handle cursor-grab">
                      <FontAwesomeIcon icon={faGripDotsVertical} />
                    </div>
                    <div>{pageItem.containedItem.label}</div>
                  </div>
                )}
              />
              {
                isBusy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color} /> : null
              }
            </div>
          )
        }
      }, {onCloseCB})
    })())

    function PageItemsListing() {
      return (
        <>
          {
            pageItems.value.length === 0
              ? <div>No available items</div>
              : null
          }
          {
            pageItems
              .value
              .filter(v => !v.isDisabled || (v.isDisabled && doShowDisabledItems.value))
              .map((pageItem, idx) => (
                <>
                  {
                    idx > 0 ? <div class="border-gray-200 border-b my-2"/> : null
                  }
                  <div class="grid grid-cols-1 divide-y-2 divide-gray-400 md:grid-cols-2 md:divide-y-0 md:divide-x-2">
                    <div class="p-2">
                      <PageItemListingBlurb pageItem={pageItem} doDelete={doDeletePageItem}/>
                    </div>
                    <div class="flex justify-center w-full divide-y-2 items-start">
                      <div class="p-4 m-4 border border border-slate-200 shadow-md w-full">
                        <PageItemListingPreview pageItem={pageItem}/>
                      </div>
                    </div>
                  </div>
                </>
              )
            )
          }
        </>
      )
    }

    return () => {
      if (!ready.value) {
        return null;
      }

      if (props.detail.name === R_Self.RouteNames.main) {
        return (
          <div data-test="R_TournamentTeamRegPageItemListing">
            <Modal.AutoModal controller={reorderPageItemsController} />
            <div class="flex flex-col items-start mb-5 justify-between md:flex-row md:items-center">
              <h1 class="text-4xl font-medium mb-4">
                Tournament Registration Configuration
              </h1>
            </div>
            <div class="ml-1 flex items-start justify-between mb-2">
              <div>
                <FormKit v-model={selectedSeasonUID.value} type="select" options={seasonOptions.value} label="Season" data-test="seasonUID"/>
                <FormKit v-model={selectedCompetitionUID.value} type="select" disabled={competitionOptions.value.disabled} options={competitionOptions.value.options} label="Program" data-test="competitionUID"/>
                {
                  completeSelection.value
                    ? (
                      <t-btn margin={false} class="mb-4 font-medium cursor-pointer">
                        <RouterLink to={R_TournamentTeamRegPageItemEditor.routeDetailToRouteLocation({
                          name: R_TournamentTeamRegPageItemEditor.RouteNames.new,
                          tournamentID: completeSelection.value.tournament.tournamentID
                        })}>
                          <font-awesome-icon icon={["fas", "plus-circle"]}></font-awesome-icon>
                          <span class="ml-2">Add New Registration Form Question/Content</span>
                        </RouterLink>
                      </t-btn>
                    )
                    : null
                }
                <FormKit v-model={doShowDisabledItems.value} outer-class="$reset" type="checkbox" label="View disabled registration items">
                  <div class="text-lg text-gray-600">View Disabled Registration Items</div>
                </FormKit>
              </div>
              <div class="p-1">
                {
                  completeSelection.value
                    ? (
                      <router-link to={R_Self.routeDetailToRouteLocation({name: R_Self.RouteNames.preview, tournamentID: completeSelection.value.tournament.tournamentID})}>
                        <t-btn margin={false}>Preview your registration page</t-btn>
                      </router-link>
                    )
                    : null
                }
              </div>
            </div>
            <div class="border-gray-200 border-b my-4"/>
            <div>
              {
                completeSelection.value
                  ? (
                    <>
                      {
                        pageItems.value.length > 1
                          ? <t-btn onClick={() => reorderPageItemsController.open(pageItems.value)}>Reorder list...</t-btn>
                          : null
                      }
                      <PageItemsListing/>
                    </>
                  )
                  : null
              }
            </div>
          </div>
        )
      }
      else if (props.detail.name === R_Self.RouteNames.preview) {
        return <TournamentTeamRegPageItemPreview key={props.detail.tournamentID} tournamentID={props.detail.tournamentID} />
      }
      else {
        exhaustiveCaseGuard(props.detail);
      }
    }
  }
})

const TournamentTeamRegPageItemPreview = defineComponent({
  name: "TournamentTeamRegPageItemPreview",
  props: {
    tournamentID: {
      required: true,
      type: null as any as PropType<iltypes.Integerlike>
    }
  },
  setup(props) {

    type Awaitables =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly tournament: iltournament.Tournament,
        readonly pageItems: iltournament.TournTeamRegPageItem[],
      }

    const FOREIGN = "foreign";
    const LOCAL = "local";
    const which = ref<"foreign" | "local">("foreign")
    const awaitables = ref<Awaitables>({ready: false})

    onMounted(async () => {
      awaitables.value = {
        ready: true,
        tournament: await tournamentTeamStore.getTournamentOrFail(props),
        pageItems: await tournTeamRegPageItemStore.getByTournamentID(axiosInstance, props.tournamentID),
      }
    })

    return () => {
      if (!awaitables.value.ready) {
        return null;
      }
      return (
        <div class="p-2" style="background-color:#FCFCFC">
          <div class="my-4 bg-white p-2 rounded-sm shadow-md max-w-lg">
            <FormKit type="radio" v-model={which.value} options={[{label: "Local team", value: LOCAL},{label: "Non-local team", value: FOREIGN}]} label="Team registration type"/>
          </div>
          <div class="my-4 bg-white p-2 rounded-sm shadow-md max-w-lg">
            <CreateTournamentTeam_TeamForm_Impl
                key={which.value}
                season={{seasonName: "Placholder-Season-Name"}}
                competition={{competition: "Placholder-Competition-Name"}}
                division={{divID: nilGuid, division: "", displayName: "Placeholder-Division-01"}}
                tournament={awaitables.value.tournament}
                availableRegions={[{
                    Region: 998,
                    LeagueID: "PLACEHOLDER-LEAGUE-ID-01",
                    Section: 998,
                    Area: "PLACEHOLDER-AREA-02",
                    ClubID: "PLACEHOLDER-CLUBID-02",
                    RegionName: "Placeholder-RegionName-01",
                  },{
                    Region: 999,
                    LeagueID: "PLACEHOLDER-LEAGUE-ID-02",
                    Section: 999,
                    Area: "PLACEHOLDER-AREA-02",
                    ClubID: "PLACEHOLDER-CLUBID-02",
                    RegionName: "Placeholder-RegionName-02",
                  }
                ]}
                mut_form={
                  which.value === "foreign"
                    ? freshCreateTeamForm("foreign", Client.value.instanceConfig, 1, nilGuid, 999)
                    : which.value === "local"
                    ? freshCreateTeamForm("local", Client.value.instanceConfig, 1, nilGuid, 999)
                    : exhaustiveCaseGuard(which.value)
                }
                withSubmitButton={false}
              />
          </div>
          <div class="my-6 bg-white p-2 rounded-sm shadow-md max-w-lg">
            <TournamentTeamRegistrationQuestionsForm
              pageItems={awaitables.value.pageItems}
              existingAnswers={[]}
              doShowPoints={true}
              doHideSubmitButton={true}
            />
          </div>
          <div>
            <RouterLink to={R_Self.routeDetailToRouteLocation({name: R_Self.RouteNames.main})}>
              <t-btn class="text-xs">
                Return to Tournament Registration Form Editor
              </t-btn>
            </RouterLink>
          </div>
        </div>
      )
    }
  }
})
