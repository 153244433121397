import dayjs, { Dayjs } from "dayjs";
import authService from "src/helpers/authService";
import { DAYJS_FORMAT_HTML_DATE, dayjsOr } from "src/helpers/formatDate";
import { isGuidUpper, routeGetQueryParamAsStringArrayOrNull, routeGetQueryParamAsStringOrNull, routerGetQueryParamAsStringArrayOrNull, vOptT, vReqT } from "src/helpers/utils";
import { Datelike, DivID, Guid, TeamID } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { User } from "src/store/User";
import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

export const propsDef = {
  queryParams: vOptT<{
    competitionUIDs?: Guid[],
    divIDs?: Guid[],
    fieldUIDs?: Guid[],
    dateFrom?: Dayjs,
    dateTo?: Dayjs,
    focusOnBracketGames?: "0" | "1",
  }>()
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "GameSchedulerCalendar.main",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | R_Main

export interface R_Main extends RoutePropsBase {
  routeName: typeof RouteNames.main,
  queryParams: Props["queryParams"]
}

interface QueryParams {
  competitionUIDs: "competitionUIDs",
  divIDs: "divIDs",
  fieldUIDs: "fieldUIDs",
  dateFrom: "dateFrom",
  dateTo: "dateTo",
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  const {routeName, queryParams, ...params} = v
  return {name: v.routeName, params, query: {
    competitionUIDs: queryParams?.competitionUIDs,
    divIDs: queryParams?.divIDs,
    fieldUIDs: queryParams?.fieldUIDs,
    dateFrom: queryParams?.dateFrom?.format(DAYJS_FORMAT_HTML_DATE),
    dateTo: queryParams?.dateTo?.format(DAYJS_FORMAT_HTML_DATE),
    focusOnBracketGames: queryParams?.focusOnBracketGames,
  }}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {
    queryParams: {
      competitionUIDs: routeGetQueryParamAsStringArrayOrNull(route.query.competitionUIDs)?.filter(v => isGuidUpper(v)),
      divIDs: routeGetQueryParamAsStringArrayOrNull(route.query.divIDs)?.filter(v => isGuidUpper(v)),
      fieldUIDs: routeGetQueryParamAsStringArrayOrNull(route.query.fieldUIDs)?.filter(v => isGuidUpper(v)),
      dateFrom: dayjsOr(routeGetQueryParamAsStringOrNull(route.query.dateFrom)),
      dateTo: dayjsOr(routeGetQueryParamAsStringOrNull(route.query.dateTo)),
      focusOnBracketGames: (route.query.focusOnBracketGames ?? undefined) as "0" | "1" | undefined,
    }
  }
}

export const ANY = Symbol("any")
type CompDiv = {competitionUID: Guid | typeof ANY, divID: Guid | typeof ANY}

function isGameSchedulerCompetitionManager(competitionUID: Guid | typeof ANY) {
  return User.userData?.competitionsMemento.find(v => competitionUID === ANY
    ? v.isGameScheduler
    : v.isGameScheduler && v.competitionUID === competitionUID)
}

function isDivisionDirectorWithWriteAccess(divID: Guid | typeof ANY) {
  return User.userData?.divisionsMemento.find(v => divID === ANY
    ? v.writeAccess
    : v.writeAccess && v.divID === divID
  )
}

function isGameSchedulerSuperUser() {
  return authService(User.userData?.roles ?? [], "gameScheduler", "webmaster")
}

export const authZ_perAction = {
  canCrudGames({competitionUID, divID}: CompDiv) : boolean {
    if (isGameSchedulerSuperUser()) {
      return true;
    }

    if (isGameSchedulerCompetitionManager(competitionUID)) {
      return true;
    }

    const {ddcanschedule, ddcaneditfields, ddcanedittimes} = Client.value.instanceConfig

    if (ddcanschedule && ddcaneditfields && ddcanedittimes) {
      if (isDivisionDirectorWithWriteAccess(divID)) {
        return true;
      }
    }

    return false;
  },
  canCrudFieldBlocks() {
    return isGameSchedulerSuperUser()
  },
  canEditGameTimes({competitionUID, divID}: CompDiv) : boolean {
    if (isGameSchedulerSuperUser()) {
      return true
    }

    if (isGameSchedulerCompetitionManager(competitionUID)) {
      return true
    }

    const {ddcanschedule, ddcanedittimes} = Client.value.instanceConfig

    if (ddcanschedule && ddcanedittimes) {
      if (isDivisionDirectorWithWriteAccess(divID)) {
        return true;
      }
    }

    return false;
  },
  canEditGameFields({competitionUID, divID}: CompDiv) : boolean {
    if (isGameSchedulerSuperUser()) {
      return true
    }

    if (isGameSchedulerCompetitionManager(competitionUID)) {
      return true
    }

    const {ddcanschedule, ddcaneditfields} = Client.value.instanceConfig

    if (ddcanschedule && ddcaneditfields) {
      if (isDivisionDirectorWithWriteAccess(divID)) {
        return true;
      }
    }

    return false;
  },
  canEditGameTeams({competitionUID, divID}: CompDiv) : boolean {
    if (isGameSchedulerSuperUser()) {
      return true
    }

    if (isGameSchedulerCompetitionManager(competitionUID)) {
      return true
    }

    const {ddcanschedule, ddcaneditmatches} = Client.value.instanceConfig

    if (ddcanschedule && ddcaneditmatches) {
      if (isDivisionDirectorWithWriteAccess(divID)) {
        return true;
      }
    }

    return false;
  }
} as const;

export function hasSomeRoutePermission() : boolean {
  const any : CompDiv = {competitionUID: ANY, divID: ANY}
  return authZ_perAction.canCrudGames(any)
    || authZ_perAction.canCrudFieldBlocks()
    || authZ_perAction.canEditGameFields(any)
    || authZ_perAction.canEditGameTimes(any)
    || authZ_perAction.canEditGameTeams(any)
}
