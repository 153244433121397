import { calculateAge } from 'src/helpers/formatDate'
import { FormKitNode } from '@formkit/core'

export const isValidDate = (date: Date): boolean => {
  let isDate = false
  if (Object.prototype.toString.call(date) === '[object Date]') {
    isDate = true
    if (isNaN(date.getTime())) {
      // d.valueOf() could also work
      isDate = true
    } else {
      isDate = false
    }
  }
  return isDate
}

export const isLegalAge = (date: Date, minAge: number): boolean => {
  if (calculateAge(date.toString()) <= minAge) {
    return false
  } else {
    return true
  }
}

export const isValidZip = {
  validate: (zip: string): boolean => {
    // same as backend, as per:
    // lucee core/src/main/java/lucee/runtime/op/Decision.java#isZipCode
    const pattern = /(^\d{5}$)|(^\d{5}[- ]\d{4}$)/;
    return pattern.test(zip)
  },
  examples: [
    "12345", "12345-6789"
  ]
}

// same as backend, as per:
// lucee core/src/main/java/lucee/runtime/op/Decision.java#isPhone
const phoneNumberPattern = new RegExp("^(\\+?1?[ \\-\\.]?([\\(]?([1-9][0-9]{2})[\\)]?))?[ ,\\-,\\.]?([^0-1]){1}([0-9]){2}[ ,\\-,\\.]?([0-9]){4}(( )((x){0,1}([0-9]){1,5}){0,1})?$");

export const isValidPhone = {
  validate: (v: string) : boolean => {
    return phoneNumberPattern.test(v);
  },
  examples: ["123-456-7890", "(123)456-7890", "1234567890"]
}

export const isPairedDate = (
  startDate: string,
  endDate: string
): boolean | void => {
  const convertedStartDate = new Date(startDate)
  const convertedEndDate = new Date(endDate)
  return convertedStartDate < convertedEndDate
}

export const phoneNumber = (node: FormKitNode) => {
  if(phoneNumberPattern.test(node.value as string)) {
    return true
  } else {
    return false
  }
}
