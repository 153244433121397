<template lang="pug">
.t-page.flex.flex-center
  div
    h1.text-4xl.self-end.font-medium
      i.mr-2.fas.fa-user-lock
      | Update Password
    t-alert(
      v-if='errorMessage',
      title='Error:',
      :message='errorMessage',
      type='danger'
    )
    t-alert(
      v-if='successMessage',
      title='',
      :message='successMessage',
      type='success'
    )
    p If you need a new password, click the button below and we will send you an email to change it.
    TBtn(label='Change Password', @Click='requestNewPwd')
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import { axiosNoAuthInstance, FALLBACK_ERROR_MESSAGE } from 'boot/axios'
import axios from "axios"
import { APIError } from 'src/interfaces/InleagueApiV1'



import TAlert from 'components/UserInterface/t-alert.vue'
import { UserData } from 'src/interfaces/Store/user'
import { isInleagueApiError2 } from 'src/composables/InleagueApiV1'
import { User } from "src/store/User"

export default defineComponent({
  name: 'RequestNewPwd',
  components: { TAlert },
  setup() {


    // Data
    const userEmail = computed(() => {
      return (User.value.userData as UserData).email
    })

    const lastName = computed(() => {
      return (User.value.userData as UserData).lastName
    })

    const successMessage = ref('')
    const errorMessage = ref('')

    // Methods
    const setAPIErrors = (errorMsgs: Array<string>): void => {
      errorMessage.value = errorMsgs.join(' ')
    }

    // API calls
    const requestNewPwd = () => {
      axiosNoAuthInstance
        .get(
          `/public/user/${encodeURIComponent(
            userEmail.value
          )}/passwordReset/?lastName=${lastName.value}`
        )
        .then(() => {
          successMessage.value =
            'Request successful! Please check your e-mail for the link to reset your password.'
        })
        .catch((e: unknown) => {
          if (axios.isAxiosError(e) && isInleagueApiError2(e)) {
            setAPIErrors(e.response.data.messages);
          }
          else {
            setAPIErrors([FALLBACK_ERROR_MESSAGE]);
          }
        })
    }

    return {
      userEmail,
      lastName,
      successMessage,
      errorMessage,
      requestNewPwd,
    }
  },
})
</script>
