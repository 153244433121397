/**
 * @deprecated -- prefer inline `.map(...)` at callsite
 */
export const createSelect = (
  arr: readonly any[],
  key: string,
  displayValueA: string,
  displayValueB?: string
) => {
  const selectArr: { [key: string]: string } = {}
  for (let i = 0; i < arr.length; i++) {
    if (displayValueB) {
      selectArr[
        arr[i][key]
      ] = `${arr[i][displayValueA]} ${arr[i][displayValueB]}`
    } else {
      selectArr[arr[i][key]] = arr[i][displayValueA]
    }
  }
  return selectArr
}

/**
 * @deprecated -- prefer inline `.map(...)` at callsite
 */
export const createNameSelect = (
  arr: readonly any[],
  key: string,
  firstName: string,
  lastName: string,
  nickname?: string
) => {
  const selectArr: { [key: string]: string } = {}
  for (let i = 0; i < arr.length; i++) {
    let name = ''
    if (arr[i].nickname) {
      name = `${arr[i].nickname} ${arr[i].lastName}`
    } else {
      name = `${arr[i].firstName} ${arr[i].lastName}`
    }
    selectArr[arr[i][key]] = name
  }
  return selectArr
}
