<template lang="pug">
span.w-full.mt-2.inline-flex.rounded-md.shadow-sm
  div(
    :class='"w-full block border px-4 py-3 rounded relative " + typeClasses[type]',
    role='alert'
  )
    slot
      strong.font-bold.mr-2(v-if='title') {{ title }}
      span.block(class='sm:inline') {{ message }}
</template>

<script lange="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TAlert',
  props: ['title', 'message', 'type'],
  setup() {
    const typeClasses = {
      danger: 'bg-red-100 border-red-400 text-red-600',
      success: 'bg-green-100 border-green-400 text-green-700',
    }
    return {
      typeClasses,
    }
  },
})
</script>
