<template lang="pug">
.flex.flex-col.items-center(data-test='selectLeagueLogin')
  form(@submit.prevent="populateLeagues")
    .w-full
      .bg-green-00.py-3.text-green-800(class='sm:px-10')
        FormKit(
          label='Email address',
          v-model='email',
          type='email',
          autocomplete="username"
          validations='required|email',
          input-class='text-black form-input block w-full',
          data-test='username',
          name='emailAddress'
        )
      .bg-green-00.py-3.text-green-800(class='sm:px-10' v-if="!isInThirdPartyOauthAckFlow")
        FormKit(
          label='Password',
          v-model='pwd',
          type='password',
          autocomplete="current-password"
          validations='required|min:5,length',
          input-class='text-black form-input block w-full',
          data-test='password',
          name='password'
        )
    div(class="flex justify-center")
      t-btn(type="submit" data-cy='findLeagues')
        div(data-test="find-leagues") Find Leagues

  div(v-if='messages')
    h1 {{ messages }}
  #errors
  div(v-if='Object.keys(leagueEntryPoints).length > 1')
    h2.italic.text-center.m-4(data-cy='multipleAccountText')
      | More than one account found with your email address.
    h2.italic.text-center.m-4
      | Please select the desired league below.
    .flex.flex-col.items-center(class='sm:mx-auto sm:w-full sm:max-w-md')
      .bg-green-00.py-3.px-3.text-green-800(class='sm:px-10')
        FormKit(
          v-model='league',
          :options='leagueEntryPoints',
          type='select',
          placeholder='Select your league',
          data-test='leagueSelector'
        )
        FormKit(
          v-model='saveLeague'
          type='checkbox'
          label='Save league selection on this device'
        )
      t-btn(
        label='Login',
        :value='league',
        @click='loginUser',
        data-test='selectLeagueLoginButton'
      )
</template>

<script lang="ts">
import { axiosNoAuthInstance, updateApiUrl } from 'src/boot/axios'
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, computed, ref, Ref } from 'vue'


import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { DeepConst, exhaustiveCaseGuard } from 'src/helpers/utils'
import { LeagueDomainDetails } from 'src/composables/InleagueApiV1.Authenticate'
import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

/**
 * we assume here that we are on a phone, and we do not yet know the league (consequently we don't know the target domain yet, either)
 * It seems that to get here, we've already logged the user in, so we assume that the user may or may not be already logged in here.
 * (to know if they have more than 1 league we had to have already tried authenticateMulti, right? ...)
 */

export default defineComponent({
  name: 'SelectLeagueLogin',
  components: {},
  setup() {
    const router = useRouter()

    const isInThirdPartyOauthAckFlow = computed(() => {
      return !!router.currentRoute.value.query.oauthAck
    })

    /**
     * league app domain options
     */
    const leagueEntryPoints = ref({}) as Ref<{ [leagueAppDomain: string]: /*league region ui name*/ string }>
    /**
     * "league app domain", used in string interpolation like `https://${league.value}/api`
     */
    const league = ref('')

    const noEmailProvided = ref(false)
    const noLeaguesFound = ref(false)
    const email = ref('')
    const pwd = ref('')
    const messages = ref('')
    const saveLeague = ref(false)

    // Computed
    const leagueOptions = computed(() => {
      return System.value.accessPointOptions
    })

    // Methods
    const createSelectOptions = () => {
      const selectOptions: { [key: string]: string } = {}
      // // console.log(leagueOptions)
      for (let i = 0; i < leagueOptions?.value?.length; i++) {
        selectOptions[leagueOptions.value[i].appDomain] =
          leagueOptions.value[i].regionName
      }
      // // console.log('increateSelectOptioons', selectOptions)
      leagueEntryPoints.value = selectOptions
      league.value = Object.keys(leagueEntryPoints.value)[0]
      // // console.log('league', league)
    }

    const finalizeLogin = async (authData: ilauth.AuthenticateResponse_Complete) : Promise<void> => {
      await User.loginUser(authData);

      if (System.getRedirectOnLogin()) {
        await System.performAndConsumeLoginRedirect(router)
        return;
      }
      else {
        // otherwise direct to default mobile landing page
        await router.push({ name: 'mobile-landing' })
      }
    }

    const loginUser = async () => {
      await updateApiUrl(`https://${league.value}/api`)

      const leagueDetails = await System.selectLeague(league.value)

      if(saveLeague.value) localStorage.setItem('savedLeague', JSON.stringify(leagueDetails))

      await Client.mobileClientCustomization();

      if (User.loginState.state === "oauth-ack-multiple-leagues") {
        // send the user on another journey to the oauth provider, this time with a specific clientID
        const clientID = User.loginState.availableLeagues.find(v => v.appDomain === league.value)?.clientID;
        if (!clientID) {
          throw "Unexpected failure to find clientID in secondary oauth redirect.";
        }
        const oauthRedirectURL = ilauth.buildGoogleLoginOauthURL(clientID, window.origin)
        window.location.assign(oauthRedirectURL);
        return;
      }

      // Checking for password and redirecting to login if missing
      if (!pwd.value) {
        await router.push({ name: 'login' })
      } else {
        try {
          // we "login web" here, because we are now focused on a particular league,
          // meaning our api origin is some league domain, which is "web mode"
          const result = await User.loginWeb(axiosNoAuthInstance, {
            username: email.value,
            password: pwd.value,
            leagueSelected: true,
          })

          if (result.ok) {
            if (result.data.status === "complete") {
              await finalizeLogin(result.data);
              return;
            }
            else {
              const fragment = ilauth.loginURLFragment({what: "mfa-from-elsewhere", data: result.data})
              await router.push({path: "/login", hash: fragment})
              return;
            }
          }
          else {
            await fail();
          }
        } catch (err) {
          await fail();
        }
      }

      async function fail() {
        await router.push({ name: 'login' })
      }
    }

    const populateLeagues = async () => {
      try {
        await System.setLoading(true);

        const response = await ilauth.public_.authenticateMulti(axiosNoAuthInstance, {
          username: email.value,
          password: pwd.value,
        });

        switch (response.type) {
          case "single": {
            league.value = response.payload.league.appDomain;
            await loginUser()
            return;
          }
          case "multi": {
            await givenLeagueDomainDetailsConfigureGlobalState(response.payload);
            return;
          }
          default: exhaustiveCaseGuard(response);
        }
      } catch (err: any) {
        // Unexpected error, probably axios but maybe not.
        // Try to recover somehow, this is the best we can do.
        await router.replace({name: "home"});
      }
      finally {
        await System.setLoading(false)
      }
    }

    // yes but __what__ global state?
    const givenLeagueDomainDetailsConfigureGlobalState = async (v: LeagueDomainDetails[]) : Promise<void> => {
      await System.setAccessPointOptions(v);
      createSelectOptions()
      noLeaguesFound.value = false
    }

    onMounted(async () => {
      if (User.loginState.state === "oauth-ack-multiple-leagues") {
        email.value = User.loginState.email;
        pwd.value = ""; // irrelevant in this case
        await givenLeagueDomainDetailsConfigureGlobalState(User.loginState.availableLeagues);
      }
      else {
        email.value = User.value.userEmail
        pwd.value = User.value.pwd
        if (email.value && pwd.value) {
          await populateLeagues()
        } else {
          noEmailProvided.value = true
        }
      }
    })

    return {
      populateLeagues,
      loginUser,
      leagueEntryPoints,
      league,
      noEmailProvided,
      noLeaguesFound,
      email,
      messages,
      pwd,
      saveLeague,
      isInThirdPartyOauthAckFlow
    }
  },
})
</script>
