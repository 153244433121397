import { FormKit } from "@formkit/vue";
import { type GlobalTopLevelModalState } from "src/store/EventuallyPinia"
import { PropType, defineComponent, getCurrentInstance, reactive, ref } from "vue";
import { AxiosErrorWrapper, axiosAuthBackgroundInstance } from "src/boot/axios";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { useIziToast } from "src/helpers/utils";
import { SoccerBall } from "../SVGs";

import { Client } from "src/store/Client";

export const freshGlobalTopLevelModalToUpdateUserInfoState = (args: {userID: iltypes.Guid, onComplete: () => void}) : GlobalTopLevelModalState => {
  return {
    props: {
      isOpen: true,
      closeOnClickOutsideOrPressEscape: false,
      withXButton: false,
    },
    emitsHandlers: {
      // user can never manually close this
      close: () : void => void 0,
      onAfterLeave: () : void => void 0
    },
    slots: {
      title: () => (
        <>
          <div>Let's finish setting up your account</div>
          <div class="text-sm">Your account was imported from another service -- please help us complete the process by providing the data below.</div>
          <div class="border-b border-slate-200 mb-2"/>
        </>
      ),
      content: () => <GenderUpdateForm
        key={/*force re-render on change to userID*/ args.userID}
        userID={args.userID}
        onComplete={() => args.onComplete() }
      />
    }
  }
};

const GenderUpdateForm = defineComponent({
  props: {
    userID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    }
  },
  emits: {
    complete: () => true
  },
  setup(props, {emit}) {

    const iziToast = useIziToast()
    const formData = reactive(freshFormData())

    type State = "awaiting-input" | "submitting" | "complete"
    const state = ref<State>("awaiting-input")

    const handleSubmit = async () => {
      state.value = "submitting"
      try {
        await doUpdate(props.userID, formData)
        iziToast.success({message: "Your info has been updated, thank you!"})
        state.value = "complete"
        emit("complete")
      }
      catch (err) {
        state.value = "awaiting-input"
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return () => (
      <div data-test="GenderUpdateForm">
        <FormKit type="form" actions={false} onSubmit={() => handleSubmit()}>
          <FormKit type="select"
            label="Please confirm your gender:"
            v-model={formData.gender}
            options={[
              {value: "", label: "", disabled: true},
              {value: "M", label: "Male"},
              {value: "F", label: "Female"}
            ]}
            validation={[["required"]]}
          />
          <t-btn type="submit" margin={false}>Submit</t-btn>
        </FormKit>
        <div>
          {
            state.value === "submitting"
              ? (
                <div class="flex items-center justify-center my-4">
                  <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                </div>
              )
              : null
          }
        </div>
      </div>
    )
  }
})

interface GenderForm {
  gender: "" | "M" | "F"
}

interface SubmittableGenderForm {
  gender: "M" | "F"
}

function freshFormData() : GenderForm {
  return {
    gender: ""
  }
}

function formToSubmittableForm(form: GenderForm) : SubmittableGenderForm {
  if (form.gender === "") {
    throw "formdata was expected to be constrained to M | F by the HTML form"
  }
  return {
    gender: form.gender
  }
}

async function doUpdate(userID: iltypes.Guid, formData: GenderForm) : Promise<void> {
  const submittableFormData = formToSubmittableForm(formData)
  await ilapi.updateUser(axiosAuthBackgroundInstance, {userID: userID, toUpdate: submittableFormData});
}
