import { PropType, defineComponent, reactive, ref } from "vue";
import { DeepConst } from "src/helpers/utils";

import type { ExpandedTournamentTeam } from "./TournamentTeamListing.utils";
import { FormKit } from "@formkit/vue";

export class PayViaCheckFormData {
  checkNo: string;
  comments: string;

  private constructor(checkNo: string, comments: string) {
    this.checkNo = checkNo;
    this.comments = comments;
  }

  static fresh() : PayViaCheckFormData {
    return new PayViaCheckFormData("", "")
  }

  static freshFromExisting(v: ExpandedTournamentTeam) : PayViaCheckFormData {
    return new PayViaCheckFormData(v.payViaCheck_checkNo, v.payViaCheck_comments);
  }
}

export const PayViaCheckForm = defineComponent({
  name: "PayViaCheckForm",
  props: {
    tournamentTeam: {
      required: true,
      type: Object as PropType<DeepConst<ExpandedTournamentTeam>>
    },
    mut_formData: {
      required: true,
      type: Object as PropType<PayViaCheckFormData>
    },
    confirm: {
      required: true,
      type: Function as PropType<() => void>
    },
    cancel: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props) {
    const MAX_CHECKNUM_LEN = 20; // see transactions.checkNum col
    const MAX_COMMENTS_LEN = 500;

    const handleSubmit = () => {
      props.confirm();
    }

    return () => (
      <FormKit type="form" actions={false} onSubmit={handleSubmit} data-test="PayViaCheckForm">
        <FormKit type="text" v-model={props.mut_formData.checkNo} label="Check No." validation={[["required"], ["length", 1, MAX_CHECKNUM_LEN]]} data-test="checkNo"/>
        <FormKit type="textarea" label="Comments" v-model={props.mut_formData.comments} {...{maxlength:MAX_COMMENTS_LEN}} data-test="comments"/>
        <div class="flex justify-end text-xs">
          {props.mut_formData.comments.length}/{MAX_COMMENTS_LEN}
        </div>
        <div class="flex gap-4">
          <t-btn type="submit" margin={false} data-test="submit">Submit</t-btn>
          <t-btn type="button" margin={false} color="red" onClick={() => props.cancel()} data-test="cancel">Cancel</t-btn>
        </div>
      </FormKit>
    )
  }
})
