import { ExtractEmitsHandlers, ExtractOnEmitsHandlers } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { computed, ExtractPropTypes, PropType, reactive, ref } from "vue";
import * as ilapi from "src/composables/InleagueApiV1"

export const propsDef = {
  event: {
    required: true,
    type: Object as PropType<ilapi.event.Event>
  }
} as const;

export const emitsDef = {
  confirmDeletion: (_eventID: iltypes.Guid) => true,
  doNotDelete: () => true,
} as const

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;
export type OnEmits = ExtractOnEmitsHandlers<typeof emitsDef>;

export function DefaultController(
  args: {
    handlers: Emits
    preShow?: (props: Props) => void,
    preHide?: (props: Props) => void
  }
) {
  const private_ = {
    visible: ref(false),
    props: ref<Props | null>(null)
  }

  const show = (event: ilapi.event.Event) => {
    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    private_.props.value = {event}

    if (args.preShow) {
      args.preShow(private_.props.value);
    }

    private_.visible.value = true;
  }

  const hide = () => {
    if (!private_.visible.value) {
      // likely bug in caller
      return;
    }

    if (args.preHide) {
      // props is definitely truthy if the modal is visible
      args.preHide(private_.props.value!);
    }

    private_.visible.value = false;
    private_.props.value = null;
  }

  // can we do something like `TheThing(v-everything="{bind: {...}, on: {...}, if: boolean}")
  // and then the actual use site would look like `TheThing(v-everything="controller")`
  // ?

  return reactive({
    /**
     * in default-init mode, caller passes no args, and so implicitly there is no permission
     * for `v-if`
     */
    visible: computed(() => private_.visible.value),
    show,
    hide,
    /**
     * for `v-bind`
     */
    props: computed(() => private_.props.value),
    /**
     * for `v-on`
     */
    handlers: args.handlers
  });
}
