import { ExtractEmitsHandlers, vReqT } from "src/helpers/utils"
import { Invoice, LineItem, LineItemSpecialization } from "src/interfaces/Store/checkout"
import { ExtractPropTypes, PropType } from "vue"

import { AttachTentativeStripePaymentMethodToInvoiceArgs, PayInvoiceArgs, attachTentativeStripePaymentMethodToInvoice, payInvoice } from "src/composables/InleagueApiV1.Invoice"

export const propsDef = {
  invoiceInstance: vReqT<Invoice>(),
  total: {
    required: false,
    type: String
  },
  showPayWithCashOrCheckButton: {
    required: false,
    type: Boolean,
    default: false
  },
  showPayWithCashOrCheckLabel: {
    required: false,
    type: String,
    default: "",
  },
  /**
   * If the invoice being payed is a tournament team reg invoice, this MUST be the associated hold payment invoice.
   * Otherwise, this MUST be null.
   */
  tournamentTeamRegHoldPaymentInvoice: {
    required: true,
    type: null as any as PropType<Invoice | null>
  },
  attachTentativeStripePaymentMethodToInvoice: vReqT<(_: AttachTentativeStripePaymentMethodToInvoiceArgs) => Promise<{ok: true} | {ok: false, message: string}>>(),
  payInvoice: vReqT<(_: PayInvoiceArgs) => Promise<{ok: true} | {ok: false, message: string}>>(),
  paymentScheduleBlurb: vReqT<string | undefined>(),
} as const

export const emitsDef = {
  payWithCashOrCheck: () => true
}

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

/**
 * This assumes there is zero-or-more per invoice
 */
export function maybeGetCompRegLineItems(invoiceInstance: Invoice | /*TODO: fix caller's spurious undefined's*/ undefined) : LineItemSpecialization<"qCompetitionRegistration">[] | null {
  if (!invoiceInstance) {
    return null;
  }

  const compRegTag = "qCompetitionRegistration"
  const isCompRegLineItem = (v: LineItem) : v is LineItemSpecialization<typeof compRegTag> => v.entity_type === compRegTag;
  const lineItems = invoiceInstance.lineItems.filter(isCompRegLineItem);
  return lineItems.length === 0 ? null : lineItems;
}

/**
 * This assumes that zero-or-more event signups per invoice
 */
export function maybeGetEventSignups(invoiceInstance: Invoice | /*TODO: fix caller's spurious undefined's*/ undefined) : LineItemSpecialization<"qEventSignup">[] | null {
  if (!invoiceInstance) {
    return null;
  }

  const result = invoiceInstance.lineItems.filter(isEventSignupLineItem);

  if (result.length > 0) {
    return result;
  }
  else {
    return null;
  }

  function isEventSignupLineItem(v: LineItem) : v is LineItemSpecialization<"qEventSignup"> {
    return v.entity_type === "qEventSignup";
  }
}

/**
 * This assumes there is exactly zero-or-one per invoice
 */
export function maybeGetTournamentTeamRegLineItem(invoiceInstance: Invoice | /*fix caller's spurious undefined's*/ undefined) : LineItemSpecialization<"qTournament_teamReg"> | null {
  if (!invoiceInstance) {
    return null;
  }
  for (const lineItem of invoiceInstance.lineItems) {
    if (lineItem.entity_type === "qTournamentTeam_teamReg") {
      return lineItem;
    }
  }
  return null;
}

export function isTournamentTeamRegInvoice(invoice: Invoice) : boolean {
  return !!maybeGetTournamentTeamRegLineItem(invoice);
}

/**
 * This assumes there is exactly zero-or-one per invoice
 */
export function maybeGetTournamentTeamHoldPaymentLineItem(invoiceInstance: Invoice | /*fix caller's spurious undefined's*/ undefined) : LineItemSpecialization<"qTournament_holdPayment"> | null {
  if (!invoiceInstance) {
    return null;
  }
  for (const lineItem of invoiceInstance.lineItems) {
    if (lineItem.entity_type === "qTournamentTeam_holdPayment") {
      return lineItem;
    }
  }
  return null;
}

export function isTournamentTeamHoldPaymentInvoice(invoice: Invoice) : boolean {
  return !!maybeGetTournamentTeamHoldPaymentLineItem(invoice);
}
