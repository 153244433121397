import { GetRegistrationQuestionAnswersResponse } from "src/composables/InleagueApiV1.Registration"
import { TeamForTeamAssignmentsView } from "src/composables/InleagueApiV1.Teams"
import { SetEx, arrayFindOrFail, vReqT } from "src/helpers/utils"
import { Guid, RegistrationQuestion } from "src/interfaces/InleagueApiV1"
import { defineComponent, Ref, ref } from "vue"
import { assignedPlayerDragMimeType, k_activeDropTargetOutlineClasses, globalTeamAssignmentsDragData, SelectedQuestions, PlayerForTeamAssignmentViewEx, useShouldOfferTeamAssignmentDragAndDrop, PlayerForTeamAssignmentViewExFilter } from "./TeamAssignments.shared"
import { vueDirective_ilDropTarget, ilDropTarget } from "src/modules/ilDraggable"
import { CommonTeamAssignmentsPlayerListing } from "./CommonTeamAssignmentsPlayerListing"
import { Btn2 } from "src/components/UserInterface/Btn2"

export const UnassignedPlayersElement = defineComponent({
  props: {
    unassignedPlayers_unfiltered: vReqT<PlayerForTeamAssignmentViewEx[]>(),
    unassignedPlayers: vReqT<PlayerForTeamAssignmentViewEx[]>(),
    selectedQuestions: vReqT<SelectedQuestions>(),
    registrationQuestions: vReqT<RegistrationQuestion[]>(),
    registrationQuestionAnswers: vReqT<GetRegistrationQuestionAnswersResponse>(),
    busyByPlayerID: vReqT<Set<Guid>>(),
    showTentativeAssignmentInfo: vReqT<boolean>(),
    selectedPlayerIDs: vReqT<SetEx<Guid>>(),
    // filter + "has some non empty filters" might could be put into a class together, so they are guaranteed in sync
    hasSomeNonEmptyFilters: vReqT<boolean>(),
    filter: vReqT<PlayerForTeamAssignmentViewExFilter>(),
  },
  directives: {
    ilDropTarget: vueDirective_ilDropTarget
  },
  emits: {
    /**
     * This only makes sense when emitted to up to a parent which has a contextual "focused team"
     * (i.e. we don't know to which team the assignment is occurring, so the parent needs to know).
     */
    assignSelectedPlayers: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    /**
     * In response to a drag-and-drop FROM a team to the unassigned players element.
     */
    didDropPlayers: (_: {originTeam: TeamForTeamAssignmentsView, players: PlayerForTeamAssignmentViewEx[]}) => true,
    /**
     * Restore players "tentatively" deleted (and so were moved into the unassigned pool) back to their originally assigned teams.
     */
    undeletePlayers: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    clearFilters: () => true,
  },
  setup(props, {emit}) {
    const activeDropTargetOutlineClasses = ref("")

    const dropTargetConfig : ilDropTarget = {
      onEnter: (dataTransfer: DataTransfer) => {
        if (dataTransfer.types.includes(assignedPlayerDragMimeType)) {
          activeDropTargetOutlineClasses.value = k_activeDropTargetOutlineClasses
          return true;
        }
        return false;
      },
      onDragOver: "sameAsOnEnter",
      onLeaveOrEnd: () => {
        activeDropTargetOutlineClasses.value = ""
      },
      onDrop: dataTransfer => {
        if (!dataTransfer.types.includes(assignedPlayerDragMimeType)) {
          return;
        }
        if (!globalTeamAssignmentsDragData.assignedPlayersBeingDragged) {
          return;
        }
        emit("didDropPlayers", {
          originTeam: globalTeamAssignmentsDragData.assignedPlayersBeingDragged.originTeam,
          players: globalTeamAssignmentsDragData.assignedPlayersBeingDragged.players,
        });
      }
    }

    const assignSelectedPlayers = () => {
      const selectedPlayers = [...props.selectedPlayerIDs].map(playerID => arrayFindOrFail(props.unassignedPlayers, v => v.apiData.child.childID === playerID));
      emit("assignSelectedPlayers", {players: selectedPlayers});
    }

    const offerDrag = useShouldOfferTeamAssignmentDragAndDrop();

    return () => {
      return (
        <div
          class={[`shadow-md rounded-md bg-white`, activeDropTargetOutlineClasses.value]}
          v-ilDropTarget={dropTargetConfig}
        >
          <div class="p-2 bg-gray-200 rounded-t-md">
            Unassigned Players ({props.unassignedPlayers.length} player{props.unassignedPlayers.length === 1 ? "" : "s"})
          </div>
          <div class="m-2">
            {
              props.unassignedPlayers.length > 0
                ? offerDrag.value
                  ? <div class="my-2 text-xs">Select players and click "assign selected players" below (or drag and drop onto target teams) to assign players to a team.</div>
                  : <div class="my-2 text-xs">Select players and click "assign selected players" below to assign players to a team.</div>
                : null
            }
            <div>
              <CommonTeamAssignmentsPlayerListing
                selectedPlayerIDs={props.selectedPlayerIDs}
                selectedQuestions={props.selectedQuestions}
                registrationQuestions={props.registrationQuestions}
                registrationQuestionAnswers={props.registrationQuestionAnswers}
                players_unfiltered={props.unassignedPlayers_unfiltered}
                players={props.unassignedPlayers}
                filter={props.filter}
                hasSomeNonEmptyFilters={props.hasSomeNonEmptyFilters}
                showTentativeAssignmentInfo={props.showTentativeAssignmentInfo}
                busyByPlayerID={props.busyByPlayerID}
                mode="unassigned-listing"
                showDragGrip={offerDrag.value}
                onUndeletePlayers={args=> emit("undeletePlayers", args)}
                onClearFilters={() => emit("clearFilters")}
              />
            </div>
          </div>
          <div class="pb-2 px-2">
            <Btn2
              class="p-1"
              onClick={assignSelectedPlayers}
              disabled={props.selectedPlayerIDs.size === 0}
            >Assign selected players</Btn2>
          </div>
        </div>
      )
    }
  }
})
