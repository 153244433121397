import { boot } from 'quasar/wrappers'
import TBtn from 'src/components/UserInterface/t-btn.vue'
import TLoader from 'src/components/UserInterface/t-loader.vue'
import TVhtml from 'src/components/UserInterface/t-vhtml.vue'
import TDialog from 'src/components/UserInterface/t-dialog.vue'
import TCheckbox from 'src/components/UserInterface/t-checkbox.vue'
import TAlert from 'src/components/UserInterface/t-alert.vue'
import TRadio from 'src/components/UserInterface/t-radio.vue'
import TSingleRadio from 'src/components/UserInterface/t-singleRadio.vue'
import TSelect from 'src/components/UserInterface/t-select.vue'
import TAutoSearchInput from 'src/components/UserInterface/t-autoSearchInput.vue'
import TInput from 'src/components/UserInterface/t-input.vue'
import TLegacyAppLink from 'src/components/UserInterface/t-legacyAppLink.vue'
import TSwitch from 'src/components/UserInterface/t-switch.vue'
import TNumber from 'src/components/UserInterface/t-number.vue'

export default boot(({ app }) => {
  app.component('TAlert', TAlert)
  app.component('TBtn', TBtn)
  app.component('TLoader', TLoader)
  app.component('TVhtml', TVhtml)
  app.component('TDialog', TDialog)
  app.component('TCheckbox', TCheckbox)
  app.component('TRadio', TRadio)
  app.component('TSingleRadio', TSingleRadio)
  app.component('TSelect', TSelect)
  app.component('TAutoSearchInput', TAutoSearchInput)
  app.component('TLegacyAppLink', TLegacyAppLink)
  app.component('TInput', TInput)
  app.component('TNumber', TNumber)
  app.component('TSwitch', TSwitch)
})
