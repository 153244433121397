<template lang="pug">
.flex.items-center
  input.h-4.w-4.text-green-600.transition.duration-150.ease-in-out(
    type='radio'
    :checked="shouldBeChecked",
    :value="value",
    @input="event => $emit('input', event.target.value)",
    @change="event => $emit('change', event.target.value)"
    :data-test="dataTest"
  )
  label.ml-3
    span.block.text-sm.leading-5.font-medium.text-gray-700 {{ label }}
</template>

<script lang="ts">
import { vOptT } from 'src/helpers/utils'
import {
  defineComponent,
  computed
} from 'vue'
export default defineComponent({
  // parent component must pass value of current radio (id, or some unique specifier) and model value - i.e. the value that is currently chosen. If the two values are equal to each other that radio button will display checked
  name: 'TRadio',
  props: {
    value: String,
    label: String,
    modelValue: String,
    dataTest: vOptT(),
  },
  emits: ['change', 'input'],
  setup(props, {emit}) {
    const shouldBeChecked = computed(()=> {
      return props.modelValue === props.value
    })

    const updateInput = () => {
      emit('change', props.value)
    }

    return {
      shouldBeChecked,
      updateInput,
      // value: props.value,
      // label: props.label,
      // modelValue: props.modelValue
    }
  }
})
</script>

