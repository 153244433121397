import { ExtractPropTypes, PropType } from "vue";
import { AugmentedGame, RefSlotOption } from "./RefereeScheduleTable.ilx";
import * as iltypes from "src/interfaces/InleagueApiV1"

export const propsDef = {
  /**
   * todo: document meaning of this
   */
  teams: {
    required: true,
    type: String,
  },
  /**
   * required?
   * todo: document meaning of this
   */
  field: String,
  /**
   * the game from obj from which we pull ref info.
   * We make a deep copy of it here, and the form we pass it to writes directly into it.
   */
  augmentedGame: {
    required: true,
    type: Object as PropType<AugmentedGame>
  },
  refSlotOptionsForSelectedCompetition: {
    required: true,
    type: null as any as PropType<RefSlotOption[]>
  }
} as const;

export const emitsDef = {
  /**
   * some action happend where we need to tell the parent to reload a game
   */
  reloadGame: (_: {gameID: iltypes.Guid}) => true,
  close: () => true,
} as const;

export type Props = ExtractPropTypes<typeof propsDef>
