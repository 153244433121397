import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosInstance } from "src/boot/AxiosInstances";
import { computed, defineComponent, ref } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { accentAwareCaseInsensitiveCompare, arrayFindIndexOrFail, copyViaJsonRoundTrip, sortBy, sortByDayJS, sortByMany, vReqT } from "src/helpers/utils";
import { AxiosInstance } from "axios";

import { RefereeRosterImpl } from "./TournamentTeamConfigurator.referees.impl";
import { RefMutablesFormData } from "./TournamentTeamConfigurator.shared"
import { RefAssignment, TournamentTeam, TournamentTeamFromListTournamentTeamsEndpoint, TournamentTeamOfficial } from "src/composables/InleagueApiV1.Tournament";
import { User } from "src/store/User";
import { directionsToPlayingFieldURL } from "src/helpers/GoogleMaps";
import { DAYJS_FORMAT_IL_FULL_1, dayjsOr } from "src/helpers/formatDate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { Integerlike, WithDefinite } from "src/interfaces/InleagueApiV1";
import { BasicTable, freshSortState, inferColIDs, typedBasicTableProps } from "src/modules/TableUtils";

export const RefereeRoster = defineComponent({
  props: {
    tournTeamOrUndefinedIfUnaffiliatedOrMultiple: vReqT<TournamentTeam | undefined>(),
    targetBinding: vReqT<iltournament.TournamentTeamOfficialBinding>(),
    mut_existingOfficials: vReqT<iltournament.TournamentTeamOfficial[]>(),
    canDelete: vReqT<boolean>(),
  },
  emits: {
    addedRef: (_: WithDefinite<TournamentTeamOfficial, "refAssignments">) => true,
    removedRef: (_: {tournamentTeamOfficialID: Integerlike}) => true,
  },
  setup(props, ctx) {
    const mostRecentCandidateSearchResult = ref<
      | "no-search-yet"
      | "nothing-found"
      | iltournament.TournamentTeamOfficialCandidate
    >("no-search-yet")

    const doCandidacyLookupByStackSid = async (axios: AxiosInstance, args: {stackSID: string, lastName: string}) : Promise<void> => {
      try {
        // TODO: axiosInstance that does not error toast on 404?
        const playerCandidate = await iltournament.getTournamentTeamOfficialCandidateByStackSID(
          axios, {
            binding: props.targetBinding,
            stackSID: args.stackSID,
            lastName: args.lastName,
            officialType: iltournament.TournamentTeamOfficialType.REFEREE
        });

        if (playerCandidate) {
          mostRecentCandidateSearchResult.value = playerCandidate;
        }
        else {
          mostRecentCandidateSearchResult.value = "nothing-found";
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddRef = async (
      axios: AxiosInstance,
      stackInfo: {stackSID: string, lastName: string},
      refForm: RefMutablesFormData,
      selectedUserIdIfMatchingInleagueUser: undefined | iltypes.Guid,
      selectedRegionIfWillBeGeneratingUser: undefined | iltypes.Integerlike,
    ) : Promise<{ok:boolean}> => {
      try {
        const fresh = await iltournament.createTournamentTeamOfficialByStackSID(
          axios, {
            binding: props.targetBinding,
            stackSID: stackInfo.stackSID,
            lastName: stackInfo.lastName,
            officialType: iltournament.TournamentTeamOfficialType.REFEREE,
            maxCR: refForm.maxCR === "" ? undefined : refForm.maxCR,
            maxAR: refForm.maxAR === "" ? undefined : refForm.maxAR,
            ref_badgeLevel: refForm.ref_badgeLevel.text,
            comments: refForm.comments.text,
            selectedUserIdIfMatchingInleagueUser,
            selectedRegionIfWillBeGeneratingUser
          });
        props.mut_existingOfficials.unshift(fresh); // new to front
        mostRecentCandidateSearchResult.value = "no-search-yet"; // "zero out" the current search, we assume here we've just "consumed" it

        // TODO: lift his all up a level, we should be doing IO in the route root component
        ctx.emit("addedRef", copyViaJsonRoundTrip({...fresh, refAssignments: []}))

        return {ok:true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const doUpdateRef = async (axios: AxiosInstance, official: iltournament.TournamentTeamOfficial, form: RefMutablesFormData) : Promise<{ok: boolean}> => {
      try {
        const freshOfficial = await iltournament.updateTournamentTeamOfficial(axios, {
          tournamentTeamOfficialID: official.tournamentTeamOfficialID,
          officialType: iltournament.TournamentTeamOfficialType.REFEREE,
          comments: form.comments.text,
          maxAR: form.maxAR === "" ? null : form.maxAR,
          maxCR: form.maxCR === "" ? null : form.maxCR,
          ref_badgeLevel: form.ref_badgeLevel.text,
        })

        const targetIdx = arrayFindIndexOrFail(props.mut_existingOfficials, _ => _.tournamentTeamOfficialID === official.tournamentTeamOfficialID)
        props.mut_existingOfficials.splice(targetIdx, 1, freshOfficial);
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }
    const doRemoveRef = async (axios: AxiosInstance, official: iltournament.TournamentTeamOfficial) : Promise<void> => {
      try {
        await iltournament.deleteTournamentTeamOfficialByTournamentTeamOfficialID(axiosInstance, {tournamentTeamOfficialID: official.tournamentTeamOfficialID});
        const idx = arrayFindIndexOrFail(props.mut_existingOfficials, v => v.stackSID === official.stackSID);
        const obj = props.mut_existingOfficials[idx]

        props.mut_existingOfficials.splice(idx, 1);
        // TODO: lift his all up a level, we should be doing IO in the route root component
        ctx.emit("removedRef", obj)
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return () => <RefereeRosterImpl
      shouldDisplayAddRef={true}
      tournTeamOrUndefinedIfUnaffiliatedOrMultiple={props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple}
      mostRecentCandidateSearchResult={mostRecentCandidateSearchResult.value}
      mut_existingRefs={props.mut_existingOfficials.filter(isReferee)}
      doCandidacyLookupByStackSid={doCandidacyLookupByStackSid}
      doAddRef={doAddRef}
      doUpdateRef={doUpdateRef}
      doRemoveRef={doRemoveRef}
      canDelete={props.canDelete}
    />
  }
})

function isReferee(v: iltournament.TournamentTeamOfficial) {
  switch (v.type) {
    case iltournament.TournamentTeamOfficialType.REFEREE: return true;
    default: return false;
  }
}

export const RefereeAssignments = defineComponent({
  props: {
    refOfficials: vReqT<TournamentTeamFromListTournamentTeamsEndpoint["refTournTeamOfficials"]>()
  },
  setup(props) {
    // as if by a left join, refAssignment is null if there are no ref assignments for this official
    type TableRow = {official: TournamentTeamOfficial, refAssignment: RefAssignment | null}

    const cols = inferColIDs<TableRow>()([
      {
        id: "name",
        label: "Name",
        headerClass: "border align-top",
        cellClass: "border px-2",
        html: row => `${row.official.firstName} ${row.official.lastName}`,
        sort: sortByMany(
          (l,r) => accentAwareCaseInsensitiveCompare(l.official.lastName, r.official.lastName),
          (l,r) => accentAwareCaseInsensitiveCompare(l.official.firstName, r.official.firstName),
        )
      },
      {
        id: "gameNum",
        label: "Game No.",
        headerClass: "border align-top pr-2",
        cellClass: "border px-2",
        html: row => row.refAssignment?.gameNum ?? "No assignments",
        sort: sortBy(v => v.refAssignment === null ? Infinity : v.refAssignment.gameNum),
      },
      {
        id: "gameDate",
        label: "Game Date",
        headerClass: "border align-top",
        cellClass: "border px-2",
        html: row => dayjsOr(row.refAssignment?.gameStart)?.format(DAYJS_FORMAT_IL_FULL_1) ?? "",
        sort: sortBy(v => dayjsOr(v.refAssignment?.gameStart)?.unix() ?? 0)
      },
      {
        id: "field",
        label: "Field",
        headerClass: "border align-top pr-1",
        cellClass: "border px-2",
        html: row => {
          if (!row.refAssignment) {
            return "";
          }
          const googleMapUrl = directionsToPlayingFieldURL(User.value.userAddress, row.refAssignment.field)
          return <a class="il-link inline-flex gap-2 items-center" href={googleMapUrl} target="_blank">
            <FontAwesomeIcon icon={faMapMarkedAlt}/>
            <span>{row.refAssignment.field.fieldAbbrev} ({row.refAssignment.field.fieldName})</span>
          </a>
        },
        sort: sortBy(v => v.refAssignment?.field?.fieldAbbrev ?? "zzz")
      },
      {
        id: "refSlot",
        label: "Position",
        headerClass: "border align-top pr-2",
        cellClass: "border px-2",
        html: row => row.refAssignment?.positionName ?? "",
        sort: sortBy(v => v.refAssignment?.positionName)
      },
      {
        id: "comments",
        label: "Comments",
        headerClass: "border align-top pl-1",
        cellClass: "border px-2",
        html: row => row.refAssignment?.comments ?? ""
      }
    ])

    const sortState = ref(freshSortState(cols))
    sortState.value.reconfigure([{colID: "name", dir: "asc"}, {colID: "gameDate", dir: "asc"}])

    const tableRows = computed(() => {
      const rows = props.refOfficials.flatMap(official => {
        return official.refAssignments.length > 0
          ? official.refAssignments.map((refAssignment) : TableRow => ({official, refAssignment}))
          : [{official, refAssignment: null} satisfies TableRow]
        });

      return rows.sort(sortState.value.asSorter());
    });

    const rowKey = (row: TableRow) => `userID=${row.official.userID}/assignmentID=${row.refAssignment?.assignmentID || 'no-assignment'}`

    return () => {
      return (
        <div data-test="RefereeAssignments">
          <BasicTable style="min-width:1000px; width:100%; overflow:hidden;" {...typedBasicTableProps({
            colDefs: cols,
            rowData: tableRows.value,
            sortState: sortState.value,
            rowKey: row => rowKey(row),
            rowAttrs: row => ({"data-test": rowKey(row)}),
            noData: () => <div class="p-2">No current refs for this team.</div>,
          })}/>
        </div>
      )
    }
  }
})
