<template lang="pug">
div(data-test="RegistrationComplete" v-if="ready")
  //-
  //- once per max(1, invoices.length)
  //-
  ListCompletitionStatuses(
    :playerID="playerID"
    :seasonUID="seasonUID"
    :competitionUIDs="competitionUIDs"
    :invoiceInstanceIDs="invoiceInstanceIDs"
    :getInvoiceByInstanceIdOrFail="getInvoiceByInstanceIdOrFail"
    :wasDeferredForCashOrCheckPayment="wasDeferredForCashOrCheckPayment"
  )

  //-
  //- once per page (per (child, season))
  //-
  BirthCertificateUploadModal(
    v-if="birthCertificateUploadModalController.visible"
    v-bind="birthCertificateUploadModalController.props"
    v-on="birthCertificateUploadModalController.handlers"
  )
  div
    div(class="my-2")
      t-btn(
        v-if='requiresBirthCertificate',
        :margin="false"
        type='button',
        label='Upload Birth Certificate...',
        @click="birthCertificateUploadModalController.show(player)"
        style="width:14rem;"
      )
    div(class="my-2")
      router-link(:to='{ name: "select-season", params: { role: "player" } }')
        t-btn(
          :margin="false"
          type='button',
          label='Register Another Player',
          style="width:14rem;"
        )
    div(class="my-2")
      router-link(:to='{ name: "select-season", params: { role: "user" } }')
        t-btn(
          :margin="false"
          type='button',
          label='Register Volunteer',
          style="width:14rem;"
        )
    div(class="my-2")
      router-link(:to='{ name: "family-profile", params: { } }')
        t-btn(
          :margin="false"
          type='button',
          label='View Family Profile',
          style="width:14rem;"
        )
</template>

<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue'
import { axiosInstance } from 'src/boot/axios'
import type { Child, Guid, Integerlike } from 'src/interfaces/InleagueApiV1'

import BirthCertificateUploadModal from 'src/components/PlayerEditor/BirthCertificateUploadModal.vue'
import * as BirthCertificateUploadModal_ from 'src/components/PlayerEditor/BirthCertificateUploadModal.ilx'

import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { propsDef } from "./R_RegistrationComplete.route"
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { ExpandedInvoice, getExpandedInvoices } from './RegistrationComplete.shared'
import { ListCompletitionStatuses } from './PerItemCompletion'
import { assertNonNull } from 'src/helpers/utils'

export default defineComponent({
  components: {
    BirthCertificateUploadModal,
    ContentChunkDisplay,
    ListCompletitionStatuses,
  },
  props: propsDef,
  setup(props) {
    const birthCertificateUploadModalController = ref(BirthCertificateUploadModal_.DefaultController({
      close: () => {
        birthCertificateUploadModalController.value.hide();
      },
      successfulUpload: () => {
        birthCertificateUploadModalController.value.hide();
      }
    }))

    const invoicesByInstanceID = ref<{[instanceID: Integerlike]: ExpandedInvoice}>({})
    const getInvoiceByInstanceIdOrFail = (instanceID: Integerlike) : ExpandedInvoice => {
      const v = invoicesByInstanceID.value[instanceID];
      assertNonNull(v);
      return v;
    }

    const requiresBirthCertificate = ref(false)
    const player = ref<Child>(/*definitely assigned in onMounted*/ null as any);
    const ready = ref(false);

    /**
     * this is more like WithDefinite<Child, "competition"> but we don't have `competition` as an expandable, is it missing?
     */
    const getPlayer = async (playerID: Guid) : Promise<Child> => {
      const response = await axiosInstance.get(
        `v1/child/${playerID}?expand=competition`
      )
      return response.data.data
    }

    onMounted(async () => {
      if (props.invoiceInstanceIDs) {
        for (const invoice of await getExpandedInvoices(props.invoiceInstanceIDs)) {
          invoicesByInstanceID.value[invoice.instanceID] = invoice;
        }
      }

      player.value = await getPlayer(props.playerID)

      const competitions = (await getCompetitionsOrFail()).value.filter(v => props.competitionUIDs.includes(v.competitionUID));

      requiresBirthCertificate.value = BirthCertificateUploadModal_.shouldShowBirthCertificateUpload(player.value, competitions);
      ready.value = true;
    })

    return {
      player,
      birthCertificateUploadModalController,
      getInvoiceByInstanceIdOrFail,
      requiresBirthCertificate,
      ready,
    }
  }
})
</script>
