<template lang="pug">
Portlet(
  :label='`${certifications.title} - AYSO ID: ${certifications.AYSOID}`',
  prefix='fas',
  :icon='preparedIcon',
  @expand='toggleExpand'
)
  div(v-if='(isMobile && show) || !isMobile')
    .flex.flex-col
      .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg' )
            table.min-w-full.divide-y.divide-gray-200(v-if='certifications.certifications && certifications.certifications.length')
              tbody.bg-white.divide-y.divide-gray-200.text-sm
                tr(v-for='certificate in certifications.certifications')
                  td.px-6.py-4.whitespace-nowrap
                    | {{ formatDateAsNums(certificate.certDate) }}
                  td.px-6.py-4.whitespace-nowrap
                    | {{ certificate.certificationDesc }}
                  td.px-6.py-4.whitespace-nowrap.italic
                    | {{ certificate.provisional ? "(Provisional)" : "" }}
            div.italic.text-center.p-4(v-else) No volunteer certifications on file. Go to
              router-link(:to="{name: 'e-train-u'}").ml-1.font-semibold eTrainU
              span.ml-1 for training
</template>


<script lang="ts">
import { defineComponent, ref, Ref, computed, onMounted } from 'vue'
import Portlet from 'src/components/Portlets/Portlet.vue'


import { formatDateAsNums } from 'src/helpers/formatDate'
import { System } from 'src/store/System'

export default defineComponent({
  name: 'VolunteerCertifications',
  components: {
    Portlet,
  },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
    certifications: Object,
  },
  setup(props) {
    const syncDate = ref(new Date())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const show = ref(props.showContent)



    const preparedIcon = computed(() => {
      return props.certifications?.fontAwesome.replace('-o', '-alt')
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const toggleExpand = (expand: boolean) => {
      // console.log('toggleExpand', expand, 'showContent', show.value)
      show.value = expand
      // console.log('showContent after toggle', show.value)
    }


    return {
      syncDate,
      preparedIcon,
      isMobile,
      show,
      toggleExpand,
      formatDateAsNums,
    }
  },
})
</script>
