<template lang="pug">
t-btn(
  type='button'
  :margin="false"
  @click='impersonateUser'
  :data-test="`impersonateButton/userID=${userID}`"
)
  div Impersonate User
</template>

<script lang="ts">
import iziToast from 'izitoast'
import { AxiosErrorWrapper, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'

import { PayableInvoicesResolver } from 'src/store/EventuallyPinia'
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { resetRelevantDataOnImpersonate } from './Impersonate'

export default defineComponent({
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  setup(props) {

    const router = useRouter()

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const impersonateUser = async () => {
      try {
        const freshAuthResponse = await ilauth.impersonate(axiosInstance, {becomeThisUserID: props.userID});

        resetRelevantDataOnImpersonate();

        iziToast.destroy()
        await User.loginUser(freshAuthResponse);

        PayableInvoicesResolver.refresh(freshNoToastLoggedInAxiosInstance())

        if(isMobile.value) {
          await router.push({name: 'mobile-landing'})
        } else {
          await router.push({name: 'home'})
        }
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return {
      impersonateUser
    }
  },
})
</script>



<style>
.noMargin {
  margin: 0 !important;
}
</style>
