import { ref, onMounted, computed, watch, defineComponent, Ref } from 'vue'
import C_ParentRecord from 'src/components/User/Editor/ParentRecord.vue'
import { useRoute, useRouter } from 'vue-router'
import authService from 'src/helpers/authService'
import {atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature} from "./UserEditor"

import { AccountSecurity } from "./AccountSecurity"
import type * as iltypes from "src/interfaces/InleagueApiV1"
import * as R_UserEditor from "./R_UserEditor.route"
import { vueDirective_onMountClickIt, type UiOption, vReqT, arrayFindIndexOrFail, useAsyncState, VueNeverUnwrappable, copyViaJsonRoundTrip, sortBy, parseIntOrFail } from 'src/helpers/utils'
import { FormKit } from '@formkit/vue'
import { buildLegacyLink } from 'src/boot/auth'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { UserCertsImpl } from './UserCerts'
import { Guid } from 'src/interfaces/InleagueApiV1'
import { AxiosErrorWrapper } from 'src/boot/AxiosErrorWrapper'
import { axiosInstance } from 'src/boot/AxiosInstances'
import { getUserVolunteerCertEditorViewData, CreateProvisionalCertOption, createProvisionalCert, resyncCerts, deleteProvisionalCerts, GetUserVolunteerCertEditorViewDataResponse, VolunteerCertEditorCert, UserLogEntry, getUserLog, getActiveOrRegSeasonVolunteerPrefs, GetActiveOrRegSeasonVolunteerPrefsResponse } from 'src/composables/InleagueApiV1.User'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { UserLogImpl } from './UserLog'
import { VolunteerPrefsImpl } from './VolunteerPrefs'

// sfc<->jsx shim, actually just for ide (volar can't typecheck use site but it checks fine at actual compile time)
declare function ParentRecord() : JSX.Element;

export default defineComponent({
  name: "UserEditor",
  props: R_UserEditor.propsDef,
  components: {
    ParentRecord: C_ParentRecord,
  },
  directives: {
    onMountClickIt: vueDirective_onMountClickIt,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const selectedTab = ref('parent-record')

    // incomplete; fixup as we clean
    const Tab_t = {
      PARENT_RECORD: "parent-record",
      SECURITY: "security",
      CERTS: "certifications",
      LOG: "log",
      VOLPREFS: "volunteer-prefs",
    } as const;

    const tabs = ref<Record<string, any>>({
      [Tab_t.PARENT_RECORD]: 'Parent Record',
    })

    const targetUserIsSameAsCurrentUser = computed<boolean>(() => {
      return User.value.userID === props.detail.userID;
    })
    const userIsWebmaster = computed<boolean>(() => {
      return authService(User.value.roles, "webmaster");
    })
    const securityTabEnabled = computed<boolean>(() => {
      return userIsWebmaster.value || targetUserIsSameAsCurrentUser.value;
    })

    const changeTab =  (tabName: string) => {
      selectedTab.value = tabName
    }

    /**
     * n.b. insertion order here is meaningful for where we compute the index of some tabID in the result of Object.keys(tabs.value)
     */
    const addPermissionBasedTabs = () => {
      // Auth checks have to be in this order to open the correct tabs in legacy site
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature.permissions)) {
        tabs.value['permissions']='Permissions'
      }
      if(R_UserEditor.authZ_viewVolunteerPrefs()) {
        tabs.value[Tab_t.VOLPREFS]='Volunteer Prefs'
      }
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature['coach-data'])) {
        tabs.value['coach-data']='Coach Data'
      }
      if(R_UserEditor.authZ_viewVolunteerCerts()) {
        tabs.value[Tab_t.CERTS] = 'Certifications'
      }
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature['merge-user'])) {
        tabs.value['merge-user']='Merge User'
      }
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature.scholarships)) {
        tabs.value['scholarships']='Scholarships'
      }
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature['volunteer-points'])) {
        tabs.value['volunteer-points']='Volunteer Points'
      }
      if(authService(User.value.roles, ...atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature['custom-fields'])) {
        tabs.value['custom-fields']='Custom Fields'
      }
      if(R_UserEditor.authZ_viewUserLog()) {
        tabs.value[Tab_t.LOG]='System Log'
      }
      if (securityTabEnabled.value) {
        tabs.value[Tab_t.SECURITY] = 'Account security'
      }
    }

    const tabsAsUiOptions = computed<UiOption[]>(() => {
      const result : UiOption[] = []
      for (const tabID of Object.keys(tabs.value)) {
        result.push({label: tabs.value[tabID], value: tabID})
      }
      return result;
    })

    const tabShouldFlowToLegacy = (tabID: string) => {
      switch (tabID) {
        case Tab_t.PARENT_RECORD:
          // fallthrough
        case Tab_t.SECURITY:
          // fallthrough
        case Tab_t.CERTS:
          // fallthrough
        case Tab_t.LOG:
          // fallthrough
        case Tab_t.VOLPREFS:
          return false;
        default:
          return true;
      }
    }

    /**
     * `null` if we're not on a tab targeting a legacy page; otherwise, the url for that page.
     */
    const currentTargetLegacyURL = ref<string | null>(null);

    watch(selectedTab, (freshTabID: string) => {
      if (tabShouldFlowToLegacy(freshTabID)) {
        const tabKeys = Object.keys(tabs.value)
        const tabIndex = tabKeys.indexOf(freshTabID) // warn, relies on insertion order and iteration being the same; guaranteed generally by impls but not by spec
        const legacyPathUrlTarget = `/User/Manage?userID=${props.detail.userID}#tab-${tabIndex+1}`
        const v = buildLegacyLink(Client.value.instanceConfig.appdomain, legacyPathUrlTarget, "")
        currentTargetLegacyURL.value = v;

        // nav to "base" route, since we're no longer on whichever child route we were on when we entered the method
        router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor", userID: props.detail.userID}));
      }
      else {
        currentTargetLegacyURL.value = null;
        switch (freshTabID) {
          case Tab_t.SECURITY:
            router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor.security", userID: route.params.userID as string}));
            return;
          case Tab_t.CERTS:
            router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor.certs", userID: route.params.userID as string}));
            return;
          case Tab_t.LOG:
            router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor.log", userID: route.params.userID as string}));
            return;
          case Tab_t.VOLPREFS:
            router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor.volunteer-prefs", userID: route.params.userID as string, seasonUID: route.query.seasonUID as Guid}));
            return;
          default:
            // nav to "base" route, since we're no longer on whichever child route we were on when we entered the method
            router.push(R_UserEditor.routeDetailToRouteLocation({name: "user-editor", userID: props.detail.userID}));
        }
      }
    })

    onMounted(()=> {
      addPermissionBasedTabs()

      //
      // eventually, all "changeTab" behaviors should be in response to a route change,
      // but for now, it is enough for our use case to only define and deal with the `/security` child route
      //
      // WARN: this can result in an infinite watch/mutate chain between `selectedTab` and the current route
      // because there is a circular dependency between the two; but it should reach a fixed point after 1 or 2 iterations.
      //
      watch(route, (v) => {
        switch (v.name) {
          case R_UserEditor.RouteName.certs:
            changeTab(Tab_t.CERTS);
            return;
          case R_UserEditor.RouteName.security:
            changeTab(Tab_t.SECURITY);
            return;
          case R_UserEditor.RouteName.log:
            changeTab(Tab_t.LOG);
            return;
          case R_UserEditor.RouteName.volunteerPrefs:
            changeTab(Tab_t.VOLPREFS);
            return;
          default:
            // no-op
        }
      }, {immediate: true})
    })

    return () => (
      <div style="--fk-bg-input: white;">
        {/* small mode we offer a <select> in lieu of tabs */}
        <div class="sm:hidden bg-white">
          <FormKit type="select"
            id="tabs"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md"
            v-model={selectedTab.value}
            options={tabsAsUiOptions.value}
            {...{placeholder:"Select A Category"}}
            data-cy="selectTab"
          />
        </div>

        {/*bigmode is a display of tabs*/}
        <div class="hidden border-b border-gray-200 sm:block bg-white">
          <nav class="mb-px flex space-x-8 cursor-pointer" aria-label="Tabs"
            style="display:grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))">
            {
              tabsAsUiOptions.value.map(uiOption => {
                return (
                  <div key={uiOption.value}
                    onClick={() => changeTab(uiOption.value)}
                    class={`${uiOption.value === selectedTab.value ? 'border-green-500 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} flex justify-center mx-2 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base`}
                    aria-current={uiOption.value === selectedTab.value ? 'page' : undefined} data-test={uiOption.value}
                  >
                    {uiOption.label}
                  </div>
                )
              })
            }
          </nav>
        </div>

        {/*based on "current tab" (which is evolving into "current route"), show some component*/}
        <div class="py-4 border-gray-200 min-w-full">
          {
            // here we're using literal tab values to choose a component
            // anything here should be refactored into switching on a route name rather than on a tab name
            selectedTab.value === 'parent-record'
              ? <ParentRecord/>
              : null
          }
          {
            // Here we're using child routes; eventually everything should be a child route.
            // There should never be overlap between a tab value and a route (as in, a selected tab is either a legacy tab-name based component mount that should be refactored into a route based one,
            // or a route based component mount, but never both at the same time.
            props.detail.name === R_UserEditor.RouteName.security
              ? <AccountSecurity userID={props.detail.userID} isSomeSuperUserEditingAnotherUser={userIsWebmaster.value && !targetUserIsSameAsCurrentUser.value}/>
              : props.detail.name === R_UserEditor.RouteName.certs
              ? <UserCertsLoader class="mt-2" key={props.detail.userID} userID={props.detail.userID}/>
              : props.detail.name === R_UserEditor.RouteName.log
              ? <UserLogLoader class="mt-2" key={props.detail.userID} userID={props.detail.userID}/>
              : props.detail.name === R_UserEditor.RouteName.volunteerPrefs
              ? <VolunteerPrefsLoader class="mt-2" key={props.detail.userID} userID={props.detail.userID} seasonUID={props.detail.seasonUID}/>
              : null
          }
          {
            // if we've changed tabs to a "legacy" tab, we'll update `currentTargetLegacyURL` to truthy, and it will
            // contain a full url. We key on currentTargetLegacyURL so we remount on any change, and trigger the v-onMountClickIt,
            // which simulates a navigate-on-tab change, if the browser allows it. This is required on iOS where safari does
            // not allow programmatically clicking the link to open a new window, i.e. we have to
            // offer the user a way to actually click the link.
            currentTargetLegacyURL.value
              ? (
                <div class="p-3 border border-slate-200 shadow-md bg-white">
                  <div class="border-b border-slate-200">Legacy link</div>
                  <a class="underline text-blue-700 cursor-pointer"
                    href={currentTargetLegacyURL.value} key={currentTargetLegacyURL.value}
                    target="_blank"
                    v-onMountClickIt
                  >
                    To legacy { tabs.value[selectedTab.value] }
                  </a>
                </div>
              )
              : null
          }
        </div>
      </div>
    )
  }
})

const UserCertsLoader = defineComponent({
  props: {
    userID: vReqT<Guid>(),
  },
  setup(props) {
    interface AsyncState {
      certs: Ref<VolunteerCertEditorCert[]>,
      userData: Ref<GetUserVolunteerCertEditorViewDataResponse["userData"]>
      selectedDeletes: Ref<Record<iltypes.Integerlike, boolean>>,
      selectedCreateProvisionalCertOption: Ref<null | CreateProvisionalCertOption>
      createProvisionalCertOptions: Ref<UiOption<CreateProvisionalCertOption | null>[]>,
    }

    const asyncState = useAsyncState<VueNeverUnwrappable<AsyncState>>()

    const doCreateProvisionalCert = async () : Promise<void> => {
      if (!asyncState.value.ready) {
        throw Error("illegal component state")
      }

      if (!asyncState.value.selectedCreateProvisionalCertOption.value) {
        throw Error("Illegal state, form should prevent this")
      }

      try {
        const {code, category} = asyncState.value.selectedCreateProvisionalCertOption.value
        const freshCert = await createProvisionalCert(axiosInstance, {
          userID: props.userID,
          code,
          category,
        });

        asyncState.value.certs.value.unshift(freshCert)

        const idx = arrayFindIndexOrFail(asyncState.value.createProvisionalCertOptions.value, v => v.value?.code === code && v.value?.category === category)
        asyncState.value.createProvisionalCertOptions.value.splice(idx, 1);
        asyncState.value.selectedCreateProvisionalCertOption.value = null;
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doDeleteProvisionalCerts = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (!asyncState.value.ready) {
          throw Error("bad component state")
        }

        const vCertIDs = Object
          .entries(asyncState.value.selectedDeletes.value satisfies Record<string, boolean>)
          .filter(([_,sel]) => sel)
          .map(([vCertID]) => vCertID as iltypes.Integerlike)

        try {
          await deleteProvisionalCerts(axiosInstance, {userID: props.userID, vCertIDs})

          //
          // Pull in fresh options
          // we can track consume/unconsume of options that exist at initial mount,
          // but "unconsume" of an option that wasn't an option to start with (because it was already consumed, i.e. not an option, at mount)
          // requires we ask the server.
          // Running the entire `getFreshState` for "just options" is somewhat wasteful but unlikely to be a bottleneck.
          //
          const {createProvisionalCertOptions} = await getFreshState();

          const didDelete = new Set(vCertIDs.map(v => v.toString()))
          asyncState.value.createProvisionalCertOptions = createProvisionalCertOptions;
          asyncState.value.certs.value = asyncState.value.certs.value.filter(v => !didDelete.has(v.vCertID.toString()))
          asyncState.value.selectedDeletes.value = {}
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doResyncCerts = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (!asyncState.value.ready) {
          throw Error("illegal component state")
        }
        try {
          await resyncCerts(axiosInstance, {userID: props.userID});
          const freshState = await getFreshState()
          asyncState.value = VueNeverUnwrappable({
            ready: true,
            // fresh
            certs: freshState.certs,
            userData: freshState.userData,
            // reuse
            selectedDeletes: asyncState.value.selectedDeletes,
            selectedCreateProvisionalCertOption: asyncState.value.selectedCreateProvisionalCertOption,
            // fresh
            createProvisionalCertOptions: freshState.createProvisionalCertOptions,
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      })
    }

    const getFreshState = async () : Promise<AsyncState> => {
      const apiData = await getUserVolunteerCertEditorViewData(axiosInstance, {userID: props.userID});

      const selectedDeletes = (() => {
        const result = ref<Record<string, boolean>>({})
        apiData.certs.forEach(cert => { result.value[cert.vCertID] = false })
        return result;
      })();

      const selectedCreateProvisionalCertOption = ref(null)
      const createProvisionalCertOptions = ref([
        {label: "-- Select a certification --", value: null},
        ...apiData.createProvisionalCertOptions.map(v => ({label: `${v.category} - ${v.description}`, value: v}))
      ]);

      return {
        certs: ref(apiData.certs),
        userData: ref(apiData.userData),
        selectedDeletes,
        selectedCreateProvisionalCertOption,
        createProvisionalCertOptions,
      }
    }

    onMounted(async () => {
      asyncState.value = VueNeverUnwrappable({
        ready: true,
        ...(await getFreshState())
      })
    })

    /**
     * getter that:
     * - makes a copy because in forwarding the options to formkit, formkit __mutates the objects__ we give it, in type unsafe ways
     */
    const getFilteredCreateProvisionalOptions = () => {
      if (!asyncState.value.ready) {
        return [];
      }
      else {
        return copyViaJsonRoundTrip(asyncState.value.createProvisionalCertOptions.value);
      }
    }

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }
      else {
        return <UserCertsImpl
          certs={asyncState.value.certs.value}
          userData={asyncState.value.userData.value}
          createProvisionalCertOptions={getFilteredCreateProvisionalOptions()}
          mut_selectedCreateProvisionalCertOption={asyncState.value.selectedCreateProvisionalCertOption}
          doCreateProvisionalCert={doCreateProvisionalCert}
          mut_selectedDeletes={asyncState.value.selectedDeletes}
          doDeleteProvisionalCerts={doDeleteProvisionalCerts}
          doResyncCerts={doResyncCerts}
        />
      }
    }
  }
})

const UserLogLoader = defineComponent({
  props: {
    userID: vReqT<Guid>(),
  },
  setup(props) {
    interface AsyncState {
      logEntries: UserLogEntry[],
    }

    const asyncState = useAsyncState<VueNeverUnwrappable<AsyncState>>()

    const getFreshState = async () : Promise<AsyncState> => {
      const logEntries = await getUserLog(axiosInstance, {userID: props.userID});

      return {
        logEntries,
      };
    }

    onMounted(async () => {
      asyncState.value = VueNeverUnwrappable({
        ready: true,
        ...(await getFreshState())
      })
    })

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }
      else {
        return <UserLogImpl
          logEntries={asyncState.value.logEntries}
        />
      }
    }
  }
})

const VolunteerPrefsLoader = defineComponent({
  props: {
    userID: vReqT<Guid>(),
    seasonUID: vReqT<Guid | undefined>(),
  },
  setup(props) {
    interface AsyncState {
      data: GetActiveOrRegSeasonVolunteerPrefsResponse,
    }

    const asyncState = useAsyncState<VueNeverUnwrappable<AsyncState>>()

    const getFreshState = async () : Promise<AsyncState> => {
      return await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const data = await getActiveOrRegSeasonVolunteerPrefs(axiosInstance, {userID: props.userID, seasonUID: props.seasonUID})
          .then(data => {
            data.volunteerPrefsSeasonalViewData.sort(sortBy(_ => parseIntOrFail(_.seasonID), "desc"))
            return data;
          });

        return {
          data
        };
      })
    }

    onMounted(async () => {
      asyncState.value = VueNeverUnwrappable({
        ready: true,
        ...(await getFreshState())
      })
    })

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }
      else {
        return <VolunteerPrefsImpl
          data={asyncState.value.data}
        />
      }
    }
  }
})
