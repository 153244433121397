import type { AxiosInstance } from 'axios'
import type * as iltypes from "src/interfaces/InleagueApiV1"

export async function oops(axios: AxiosInstance, args: {breadcrumb: string, level: "info" | "warning" | "error", detail: any}) : Promise<void> {
  await axios.post(`public/oops`, args);
}

export interface NewUserArgs {
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  password2: string,
  street: string,
  zip: string,
  city: string,
  primaryPhone: string,
  state: string
  /**
   * We might have a token serving as proof that this user should be linked to a particular AYSOID, which the user
   * will have carried with them in the URL, from an email to their email account on file as part of their AYSO account.
   */
  aysoIDClaim_JWT?: string
}

export async function createNewUser(axios: AxiosInstance, args: NewUserArgs) : Promise<void /*maybe User?*/> {
  await axios.post(`public/user`, args);
}

// noexport
interface InleagueUserViaAysoBase {
  type:
    | "ayso-miss"
    | "ayso-hit/no-inleague-user"
    | "ayso-hit/inleague-users"
}

/**
 * in order of "least successfull lookup" to "most successful lookup"
 */
export type InleagueUserViaAyso =
  | InleagueUserViaAyso_AysoMiss
  | InleagueUserViaAyso_InleagueMiss
  | InleagueUserViaAyso_InleagueHit

/**
 * Didn't even find an ayso user (so there's no way to try to find an inleague user)
 */
export interface InleagueUserViaAyso_AysoMiss extends InleagueUserViaAysoBase {
  type: "ayso-miss",
}

/**
 * Found an ayso user, but no associated inleague user
 */
export interface InleagueUserViaAyso_InleagueMiss extends InleagueUserViaAysoBase {
  type: "ayso-hit/no-inleague-user",
  partialAysoUserData: {
    firstName: string,
    lastName: string,
    email: string,
    aysoID: string,
  }
}

/**
 * Found an ayso user and an associated inleague user
 *
 * There can be multiple inleague users for the lookup (which at this time is by aysoID / lastname)
 *
 * A common reason to get multiple matches is a married couple sharing an aysoID and a lastname.
 *
 * n.b. the list is always non-empty, so if this type is returned, the list of matches is at least of length 1.
 */
export interface InleagueUserViaAyso_InleagueHit extends InleagueUserViaAysoBase {
  type: "ayso-hit/inleague-users",
  users: {
    email: string,
  }[]
}

export async function getInleagueUserViaAyso(axios: AxiosInstance, args: {aysoID: string, lastName: string}) : Promise<InleagueUserViaAyso> {
  const response = await axios.get(`public/user/inleagueUserViaAyso`, {params: args});
  return response.data.data;
}

interface Region {
  region: iltypes.Integerlike,
  regionName: string,
  regionAbbrev: string,
}

export async function getRegions(axios: AxiosInstance) : Promise<Region[]> {
  const response = await axios.get("public/regions");
  return response.data.data;
}


export async function sendNewUserViaAysoIdClaimEmail(
  axios: AxiosInstance,
  args: {
    aysoID: string,
    lastName: string,
    region: iltypes.Integerlike
  }
) : Promise<void> {
  await axios.post(`public/user/inleagueUserViaAyso`, args);
}

interface GetStackUserInfoFromNewUserViaAysoIdClaimResponse {
  email: string,
  firstName: string,
  lastName: string,
  gender: string, // "M" | "F" ? check with backend
  /**
   * Nov/14/2023 -- This is possibly nullish, but a month or so after introduction it should always be a valid integerlike
   */
  region: "" | iltypes.Integerlike,
  primaryPhone: string,
  street: string,
  city: string,
  state: string,
  zip: string,
}

/**
 * We need the jwt we sent to their ayso-registered email address to pull this info,
 * otherwise it's a 403.
 */
export async function getCreateNewUserInfoForAysoIdClaim(
  axios: AxiosInstance,
  args: {jwt: string}
) : Promise<GetStackUserInfoFromNewUserViaAysoIdClaimResponse> {
  const response = await axios.get(`public/user/newUserInfoViaAysoIdClaim`, {params: {jwt: args.jwt}});
  return response.data.data;
}

export interface RegionDef {
  Region: iltypes.Integerlike,
  LeagueID: string,
  Section: iltypes.Integerlike,
  Area: string,
  ClubID: string,
  RegionName: string,
}

export async function getMasterRegionList(axios: AxiosInstance): Promise<RegionDef[]> {
  const response = await axios.get(`public/regions/all`);
  return response.data.data;
}
