<template lang="pug">
label.font-medium.text-smt-label.block.mt-6.ml-2.-mb-3
  span(class="md:hidden") {{ labelItem }}
  span Competition Restrictions
  button.ml-1.cursor-pointer(
    v-tooltip='{content: `Restrict display of registration item based upon competition.`}'
  )
    font-awesome-icon.mr-2.text-gray-400(
      :icon='["fas", "info-circle"]'
    )
FormKit(
  v-model='selectAllCompetitions',
  label="All Competitions",
  type='checkbox',
  wrapper-class='mt-5',
  data-test="all-competitions"
)
div.block.h-full.mt-4
  FormKit(
    v-model="selectedCompetitionsUIDs",
    label="Competitions"
    :options='competitions',
    type='checkbox',
    cols="2",
  )
</template>

<script lang="ts">
import { assertNonNull } from 'src/helpers/utils'
import { axiosInstance } from 'src/boot/axios'
import { createSelect } from 'src/helpers/schemaService'

import { defineComponent, ref, onMounted, watch, computed, PropType, Ref } from 'vue'
import { getCompetitionsOrFail } from "src/store/Competitions"

export default defineComponent({
  props: {
    selectedCompetitionsObjects: {
      type: Array as PropType<any[]>,
      required: true
    },
    pageItemID: {
      type: String,
      required: true
    },
    triggerSave: {
      type: Boolean,
      required: true
    },
    labelItem: {
      type: String,
      required: true
    }
  },
  inheritAttrs: false,
  emits: ['update:competitionUIDs', 'updateStatus'],
  setup(props, {emit}) {
    const competitions = ref({})
    const selectAllCompetitions = ref(false)
    const selectedCompetitionsUIDs = ref([]) as Ref<string[]>
    const competitionStatus = ref('unchanged')



    const getCompetitionsUIDs = () => {
      const competitionUIDs: string[] = []
      for(let i=0; i<props.selectedCompetitionsObjects.length; i++) {
        competitionUIDs.push(props.selectedCompetitionsObjects[i].competitionUID)
      }
      return competitionUIDs
    }

    const getCompetitions = async () : Promise<void> => {
      const comps = (await getCompetitionsOrFail()).value;
      const competitionsSelectFormat = createSelect(
        comps,
        'competitionUID',
        'competition'
      )
      competitions.value=competitionsSelectFormat
    }

    const addCompetitions = async (pageItemID: string) => {
      try {
        const response = await axiosInstance.put(`/v1/registration/pageItem/${pageItemID}/competitions`, {competitions: selectAllCompetitions.value ? [] : selectedCompetitionsUIDs.value})
        // console.log('updateSeasons', response.data.data)
        competitionStatus.value='saved'
      } catch (error) {
        // console.log('error in updateSeasons', error)
      }
    }

    watch(competitionStatus, (val) => {
      emit('updateStatus', val)
    })

    watch(()=>props.triggerSave, async (val) => {
      if(val) {
        await addCompetitions(props.pageItemID)
      }
    })

    watch(selectAllCompetitions, (val: boolean) => {
      if(val) {
        selectedCompetitionsUIDs.value = Object.keys(competitions.value)
      }
    })

    watch(selectedCompetitionsUIDs, (competitionUIDs: string[] | null, prevCompetitionUIDs: string[] | null) => {
      // strictFunctionTypes indicates this is possibly null,
      // but prior to checking w/ strictFunctionTypes it is not assumed that competitionUIDs is ever null here
      // (we always unsafely accessed competitionUIDs.length, which would crash just as we would crash here on the following assert)
      assertNonNull(competitionUIDs, "null competitionUIDs is unexpected here");

      if(prevCompetitionUIDs) competitionStatus.value = 'pending'
      if(competitionUIDs.length != Object.keys(competitions.value).length) {
        // console.log('will clear selectAllCompetitions', competitionUIDs, Object.keys(competitions.value))
        selectAllCompetitions.value = false
      }

      if(selectAllCompetitions.value) {
        emit('update:competitionUIDs', [])
      } else {
        emit('update:competitionUIDs', competitionUIDs)
      }
    })

    onMounted(async() => {
      // console.log('competitionSelector onMounted')
      selectedCompetitionsUIDs.value = getCompetitionsUIDs()
      await getCompetitions()
      if(props.selectedCompetitionsObjects.length===0) selectAllCompetitions.value=true
    })

    return {
      selectedCompetitionsUIDs,
      competitions,
      selectAllCompetitions,
    }
  },
})
</script>

