

import * as ilapi from "src/interfaces/InleagueApiV1" // @circular, webpack seems OK with it
import { StaticVolunteerRequirementsCheck } from 'src/components/Registration/selections/common';
import { CompetitionRegistrationID, Guid, Registration, WithDefinite } from "src/interfaces/InleagueApiV1";

import { exhaustiveCaseGuard } from "src/helpers/utils";

export type RegistrationExpandedForStore = WithDefinite<Registration, "competitions" | "registrationAnswers">

export interface VolunteerRequirementsFlowState {
  expectedRoute: {
    familyID: string,
    userID: string,
    seasonUID: string,
    competitionUIDs: Guid[],
  },
  staticVolunteerRequirementsCheck: StaticVolunteerRequirementsCheck,
  volunteerDetails: ilapi.VolunteerDetails
}

export interface ContactAndVolunteerDetailsUpdateFlowState {
  expectedRoute: {
    childID: string,
    familyID: string,
    seasonUID: string
  },
  usersRequiringUpdate: ilapi.User[]
}


export interface RegistrationI {
  regFormInput: object
  regData: RegistrationExpandedForStore | null,
  esignData: object
  pageItems: {[key:string]: PageItemContainer}
  customFieldsOptionFormat: {[key:string]: string}
  customFields: any[],
  gateFunctions: any[],
  gateFunctionsOptionFormat: {[key: string]: string}
  donations: {[key:string]: string },
  // `competitionVolunteer` is state passed between choosing a competition in the registration flow and choosing volunteer roles
  // (if choosing volunteer roles is determined to be required)
  volunteerRequirementsFlow: VolunteerRequirementsFlowState | undefined
  contactAndVolunteerDetailsUpdateFlow: ContactAndVolunteerDetailsUpdateFlowState | undefined,
  /**
   * map of (playerID -> seasonUID -> competitionRegistrationID -> competitionRegistration)
   */
  competitionRegistrations:
    Record<
      ilapi.PlayerID, Record<
        ilapi.SeasonUID, Record<
          CompetitionRegistrationID, ilapi.CompetitionRegistration>>>
}



export interface Player {
  dateModified: string;
  childID: string;
  birthCertificateFile: string;
  birthCertificate: number;
  modifiedBy: string;
  clientID: string;
  stackRecordKey: string;
  playerNickName: string;
  blockFromRegistration: number;
  stackFamilyID: string;
  dateCreated: string;
  playerBirthDate: string;
  familyID: string;
  childNum: number;
  provisionalAYSOID: number;
  playerLastName: string;
  playerFirstName: string;
  AYSOID: string;
  playerGender: string;
  stackSID: string;
}

export interface RegistrationAnswer {
  userID: string;
  shortLabel: string;
  clientID: string;
  answer: string;
  id: string;
  registrationID: string;
  questionID: string;
  label: string;
}


// Anywhere this is used should be marked with why we've merged the 2 things.
// This is often used as if it were `Child | (Child & Registration)`, as in, "always a child object, maybe with a registration merged into it".
export type PlayerDetailsI = ilapi.Child & ilapi.Registration;


/**
 * "outer" page item, containing the "inner" page item (that is, a question or content chunk)
 */
export interface PageItemContainer {
  pageItemType:          string;
  inleagueQuestionGroup: string;
  clientID:              string;
  order:                 number;
  isDisabled:            number;
  contentID:             number | string;
  itemSeasons:           ItemSeason[];
  /**
   * competition linkage, an empty array means "unconstrained, implicitly links to all competitions"
   */
  itemCompetitions: {competitionUID: string}[]
  id:                    string;
  type:                  number;
  seasonUIDs:            string[];
  questionID:            string;
  pageItem:              PageItem_ambiguously_QuestionOrContentChunk;
}

export interface ItemSeason {
  pageItemID:   string;
  seasonUID:    string;
  regQSeasonID: string;
  seasonID:     number;
}

export interface GateFunctionClass {
  gateContentID:    string;
  targetValue:      string;
  customField:      CustomField;
  clientID:         string;
  customFieldID:    string;
  matchType:        number;
  gateFunctionID:   string;
  gateQuestionType: number;
  gateQuestionID:   string;
}

export enum CustomFieldInputType {
  TEXT = 1,
  TEXT_AREA = 2,
  NUMERIC = 3,
  BOOLEAN = 4,
  SELECT = 5,
}

export interface CustomField {
  fieldGroup:    string;
  inputType: CustomFieldInputType;
  selectOptions: string;
  clientID:      string;
  fieldID:       string;
  name:          string;
  defaultValue:  string;
}

//
// This is conceptually "question or content chunk", though it's typed as if it were `Partial<Question> & Partial<ContentChunk>` -- fields unique to either are merged in as optionals here.
// There are more refined types in interfaces/InleagueApiV1 we should transition to.
// TODO: replace w/ ilapi's PageItem_Question/PageItem_ContentChunk
//
// This appears to represent the client side form version of a registration page item, and so is possibly disjoint
// from a registration page item that would be pushed over the wire to us from the api.
//
// This can sometimes be inferred (by us, not the machine) as representing
// either a Question or ContentChunk by surrounding context (e.g. use inside of a file named NewQuestion or similar).
//
export interface PageItem_ambiguously_QuestionOrContentChunk {
  disableRich?:           string;
  defaultText?:           string;
  shortLabel:             string;
  endDate?:               string | undefined;
  clientID?:               string;
  startDate?:             string | undefined;
  overridden?:            boolean;
  id?:                     number | string;
  label:                  string;
  gateFunctionName?:      string;
  inLeagueQuestionGroup?: number;
  // FormKit requires strictly boolean values for v-model binding on checkbox type forms instead of truthy/falsey numeric values, we must convert at appropriate places
  isRequired?:            ilapi.Numbool;
  isDisabled?:            ilapi.Numbool;
  isEditable?:            ilapi.Numbool;
  hasAnswers?:            ilapi.Numbool;

  order?:                 number;
  gateFunction?:          GateFunctionClass;
  questionOptions?:       QuestionOption[];
  /**
   * present in the question case
   */
  type?:                  ilapi.QuestionType;
  gateFunctionID?:        string;
}

/**
 * A question option might be "tentative" or it might be "persisted". Eventually we should gain control of anywhere that constructs
 * such objects and tag them directly; but, for now we distinguish between the two states by presence of one-or-the-other properties:
 *  - if `id` is present, it's a "persisted" QuestionOption
 *  - if `createNewOptionForm_localOptionID` is present, it's a "tentative" QuestionOption
 *
 * Note that generally it is not correct to read from `id` without first checking if a question is "persisted", or alternatively
 * grabbing its "effective" ID using `getEffectiveQuestionOptionID`
 *
 * see: getEffectiveQuestionOptionID
 * see: getQuestionOptionLifecycleType
 */
export interface QuestionOption {
  id?:               string;
  /**
   * A unique value for a "fresh" option created on the frontend.
   * We cannot write into `id` for this purpose because various places around the codebase use the truthiness of the `id` property
   * to determine "oh it has an ID property, this must be a server-side persisted question option that we've pulled from the server".
   */
  createNewOptionForm_localOptionID?: string;
  gateFunctionName: string;
  order?:            number;
  optionValue:      string;
  seasons:          (string | {seasonUID: string})[];
  gateFunction:     GateFunctionClass;
  optionText:       string;
  /**
   * what does it mean for a QuestionOption to have a missing `questionID`?
   *
   * the type within the app may be undefined for a tentative or etc, but the API entity should never have a missing questionID
   */
  questionID?: string | undefined;
  gateFunctionID:   string;
  status?:           string;
}

function assertIsExactlyEitherPersistedOrTentativeQuestionOption(q: QuestionOption) : void {
  const hasID = !!q.id ? 1 : 0;
  const hasLocalOptionID = !!q.createNewOptionForm_localOptionID ? 1 : 0;
  if (!(hasID ^ hasLocalOptionID)) {
    throw "a question option here must have exactly either a server-generated ID or a client-side generated ID"
  }
}

/**
 * pull some question option's ID from it's appropriate field, based on whether it is a persisted or tentative question option.
 */
export function getEffectiveQuestionOptionID(q: QuestionOption) : string {
  assertIsExactlyEitherPersistedOrTentativeQuestionOption(q)

  // from here we know that we are exactly one or the other (but TS can't see into this so we redo some ifs to peel away undefined)

  if (q.id) {
    return q.id;
  }
  else if (q.createNewOptionForm_localOptionID) {
    return q.createNewOptionForm_localOptionID;
  }
  else {
    throw "unreachable";
  }
}

export function getDiscriminableQuestionOptionID(q: QuestionOption) : {type: "persisted" | "tentative", id: string} {
  assertIsExactlyEitherPersistedOrTentativeQuestionOption(q)

  if (q.id) {
    return {type: "persisted", id: q.id}
  }
  else if (q.createNewOptionForm_localOptionID) {
    return {type: "tentative", id: q.createNewOptionForm_localOptionID}
  }
  else {
    throw "unreachable";
  }
}

export function markQuestionOptionPersistedInPlace(q: QuestionOption, freshID: string) : void {
  const lifecycleType = getQuestionOptionLifecycleType(q);
  switch (lifecycleType) {
    case "persisted":
      throw "this method should never be called against an already persisted question option"
    case "tentative":
      q.id = freshID;
      delete q.createNewOptionForm_localOptionID;
      break;
    default: exhaustiveCaseGuard(lifecycleType);
  }
}

/**
 * We use an ID for a vue template keys, but naturally "new" options don't have server generated keys.
 * This generates an arbitrary series of such keys.
 */
const nextLocalOptionID = (() => {
  let v = 0;
  return {get: () => "freshLocalOptionID/" + (v++).toString()}
})();

export function freshQuestionOption(order?: number) : QuestionOption {
  return {
    createNewOptionForm_localOptionID: nextLocalOptionID.get(),
    gateFunction: {} as GateFunctionClass, // TODO: saner default? This is brittle.
    gateFunctionID: '',
    gateFunctionName: '',
    optionText: '',
    optionValue: '',
    seasons: [],
    order,
  }
}

export type QuestionOptionLifecycleType = "persisted" | "tentative"

export function getQuestionOptionLifecycleType(q: QuestionOption) : QuestionOptionLifecycleType {
  assertIsExactlyEitherPersistedOrTentativeQuestionOption(q);
  return q.id ? "persisted" : "tentative";
}

export interface RegistrationDataI {
  lBrother:             string;
  playerWeight:         string;
  doctorFull:           string;
  registered:           number;
  moveUpStatus:         number;
  playerSchool:         string;
  neverPlayed:          number;
  coed:                 number;
  batchNum:             string;
  dateCreated:          string;
  droppedComments:      string;
  medCond:              number;
  comments:             string;
  playerEmail:          string;
  refundBy:             string;
  lastModifiedBy:       string;
  moveUpAsked:          number;
  mediProbs:            string;
  parent1Email:         string;
  childID:              string;
  donation:             string;
  refund:               number;
  WL:                   number;
  Fee:                  string;
  medicalInsCarrier:    string;
  amountPaid:           string;
  isAYSOExtra:          number;
  playerBirthDate:      string;
  playerCellPhone:      string;
  parent2ID:            string;
  registrationID:       string;
  VIP:                  number;
  playerLastName:       string;
  AYSOID:               string;
  moveUpOldDiv:         string;
  seasonUID:            string;
  sexdiv:               string;
  parent1ID:            string;
  EmergencyPhone:       string;
  seasonID:             number;
  parent1LastName:      string;
  noPracticeDay:        string;
  dateModified:         string;
  invoiceNo:            string;
  moveUp:               number;
  seasonName:           string;
  EmergencyContact:     string;
  sexdivNextYear:       string;
  methodOfPayment:      string;
  familyID:             string;
  excludeFromCaps:      number;
  grade:                string;
  submitType:           string;
  excludeFromWaitList:  number;
  playerHeight:         string;
  refundDate:           string;
  recentlyMoved:        number;
  age_calc:             number;
  stackEnrollmentDate:  string;
  divID:                string;
  physicianName:        string;
  refundAmount:         string;
  droppedDate:          string;
  registrarOnly:        string;
  droppedBy:            string;
  email_other:          string;
  dropped:              number;
  parent1FirstName:     string;
  clientID:             string;
  submitterID:          string;
  feeOverride:          number;
  WLRemoveDate:         string;
  registrationComments: string;
  WLRemoveBy:           string;
  playerNum:            number;
  div:                  number;
  playerFirstName:      string;
  scholarship:          number;
  suspended:            number;
}

