<template lang="pug">
div
  img(src='https://theme.zdassets.com/theme_assets/1000853/82f2904ef9d951e6113aeab280bca002ce7e62d2.png' alt='eTrainU Logo')
.relative.border.border-gray-300.rounded-md.px-3.py-2.shadow-sm.mt-4(class='focus-within:ring-1 focus-within:ring-green-600 focus-within:border-green-600')
  label.absolute.-top-4.left-2.-mt-px.inline-block.px-2.bg-white.text-lg.font-medium.text-gray-600 AYSOU
  //- h2.mt-2.block.text-xl.font-medium.text-gray-700 Sign into eTrainU or SafeSport
  p.mt-2
    | The New AYSOU (by eTrainU) is the AYSO Learning Management System where coach, referee, and Safe Haven courses are offered. Training completed on AYSOU or in person at an AYSO clinic is automatically recorded on your inLeague user profile.
  div(v-if='!userData.stackUserID' data-test="noPerms")
    p.mt-2
      | Before you can sign in to AYSOU,
      router-link.ml-1.font-semibold(:to='{name: "select-season", params: {role: "user"}}') register as a volunteer
      | . Within a few hours of completing the registraiton process, you will be assigned an AYSO ID number that inLeague can use to log you in automatically. This page will be updated with a login button when that occurs.
  div(v-else)
    p.my-2
      b You do not need separate credentials to log into AYSOU.
      |  Your inLeague account will automatically log you in with the button below.
    form(name='ssoForm', method='post', :action='eTrainURL', target='_blank')
      input(v-for="(option, key) in eTrainUOptions" :name='key' :value='option' type='hidden')
      FormKit(
        type='submit',
        label='Log into AYSOU (powered by eTrainU)'
      )
.relative.border.border-gray-300.rounded-md.px-3.py-2.shadow-sm.mt-8(class='focus-within:ring-1 focus-within:ring-green-600 focus-within:border-green-600' v-if="userData.stackUserID && !userData.isImpersonating")
  label.absolute.-top-4.left-2.-mt-px.inline-block.px-2.bg-white.text-lg.font-medium.text-gray-600 SafeSport
  p.mt-2
    | SafeSport training is offered separately via the U.S. Center for Safesport.
    b  All AYSO volunteers must use the link below to access SafeSport
    |  so that AYSO will receive the necessary records. The link is the same for all AYSO regions and it is not unique to your user account, so it may be shared,
    b  but it is essential that you create your SafeSport account with personal information identical to your inLeague account.
    |  inLeague cannot automatically supply your account information to SafeSport; if your name or email address do not match exactly, your results may not be processed by AYSO.
  p.mt-2
    | If any of the below information is incorrect,
    b.mx-1 stop here
    |  and contact your league registrar to update your volunteer record.
  .flex.flex-col.max-w-md.my-4
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow-md.overflow-hidden.border-2.border-gray-300(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-300
            tbody.bg-white.divide-y.divide-gray-300
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | First Name
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{userData.firstName}}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | Last Name
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{userData.lastName}}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | Email
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{userData.email}}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | DOB
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{dayJSDate(userData.DOB)}}
  .mt-2
    a(href='https://safesporttrained.org/?KeyName=tsVWe36Xa6PS3b5NzOug' target='_blank')
      FormKit(
        type='button',
        @click='signIntoSafeSport'
        label='SafeSport link (will open in another window)'
      )
    a(href='https://safesporttrained.org/?KeyName=tsVWe36Xa6PS3b5NzOug' target="_blank")
      | (
      span.text-blue-600 https://safesporttrained.org/?KeyName=tsVWe36Xa6PS3b5NzOug
      | )
</template>

<script lang="ts" setup>
import axios from 'axios'
import { dayJSDate } from 'src/helpers/formatDate'
import type { UserData } from 'src/interfaces/Store/user'

import { User } from 'src/store/User'
import { computed } from "vue"



const userData = computed(() => {
  return User.value.userData
})

const jwtToken = computed(()=> {
  return User.jwtToken
})

const getRiskStatus = () => {
  if(new Date() < new Date('8/1/2022')) {
    return (userData.value as UserData).lasteAYSOYear==='2021' ? 'Green' : ''
  } else {
    return (userData.value as UserData).RiskStatus
  }
}

const eTrainUOptions = computed(() => {
  const options = {
  FirstName: (userData.value as UserData).firstName,
  LastName: (userData.value as UserData).lastName,
  DateOfBirth: dayJSDate((userData.value as UserData).DOB),
  Email: (userData.value as UserData).email,
  BackgroundCheckRiskStatus: getRiskStatus(),
  AdminGUID: (userData.value as UserData).stackUserID,
  AYSONumber: (userData.value as UserData).region,
  AdminID: (userData.value as UserData).stackUserSID,
  AccessToken: jwtToken.value
  }
  return options
})

const eTrainURL = computed(() => {
  if((userData.value as UserData).appDomain==='inleague.localtest.me') {
    return 'https://ayso.stg-learn-usa.etrainu.com/training/index.cfm?event=integrations.in-league.sso'
  } else {
    return 'https://ayso.learn-usa.etrainu.com/training/index.cfm?event=integrations.in-league.sso'
  }
})

</script>

