<template lang="pug">
div
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="4")
                  | Medical Information
            .grid.grid-cols-3.divide-y.divide-gray-200(v-if="Object.keys(playerDetails).length>0" class="lg:grid-cols-6")
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Insurance Carrier:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['medicalInsCarrier']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.medicalInsCarrier',
                      name='medicalInsCarrier',
                      validations='required',
                      innerClass='bg-white'
                    )
                  div(v-else) {{ playerDetails.medicalInsCarrier }}
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Medical Condition:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div.custom(v-if="coreQuestionEditPermissionMap['medCond']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.medCond',
                      name='medCond',
                      validations='required'
                      type="radio"
                      row=true
                      :options="{0: 'No', 1: 'Yes'}"
                      optionsClass='flex',
                      innerClass="ml-6",
                    )
                  div(v-else) {{ boolHandler(playerDetails.medCond) }}
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Medical Problems:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['mediProbs']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.mediProbs',
                      name='mediProbs',
                      validations='required',
                      innerClass='bg-white'
                    )
                  div(v-else) {{ playerDetails.mediProbs }}
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Physician:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['physicianName']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.physicianName',
                      name='physicianName',
                      validations='required',
                      innerClass='bg-white'
                    )
                  div(v-else) {{ playerDetails.physicianName }}
                td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                  | Doctor Phone:
                td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                  div(v-if="coreQuestionEditPermissionMap['doctorFull']")
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.doctorFull',
                      name='doctorFull',
                      validations='required',
                      innerClass='bg-white'
                    )
                  div(v-else) {{ playerDetails.doctorFull }}
</template>

<script lang="ts" setup>
import type { RegistrationDataI } from 'src/interfaces/Store/registration'
import type { PropType } from 'vue'

defineProps({
  playerDetails: {
    required: true,
    type: Object as PropType<RegistrationDataI>
  },
  coreQuestionEditPermissionMap: {
    required: true,
    type: Object as PropType<Record<string, boolean>>
  },
})

const boolHandler = (val: string) => {
  return val=='0' ? 'No' : 'Yes'
}
</script>

