<!--
  this component is minimally route-aware
  It is forwarded cleaned-up route props (and so we must utter "RouteName" when we switch on props.wrapper.name against the "FamilyProfile.RouteName.*" enum),
  but we should never directly access the router (i.e. no imports of {useRoute, useRouter})
-->
<template lang="pug">
div(v-if="ready")
  //-
  //- if there is more than 1 family, show a modal for the user to select just 1
  //-
  MultipleFamilies(
    v-if='!selectedFamilyID',
    selectionReason='viewProfile',
    :families='families',
    @familyIdSelected='val => void (selectedFamilyID = val)'
  )
  div(v-else)
    div(class='sm:hidden')
      label.sr-only(for='tabs') Select a Category
      select#tabs.block.w-full.pl-3.pr-10.py-2.text-base.border-gray-300.rounded-md(
        name='tabs',
        class='cursor-pointer focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm',
        v-model='selectedTab'
      )
        option(v-for='(tab, key) in tabs', :key='key', :value='key') {{ tab }}
    .hidden(class='sm:block')
      .border-b.border-gray-200
        nav.-mb-px.flex.space-x-8(aria-label='Tabs')
          div(
            v-for='(tab, key) in tabs',
            :key='key',
            @click='changeTab(key)',
            class="cursor-pointer"
            :class='[key === selectedTab ? "border-green-500 text-green-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300", "py-4 px-1 border-b-2 font-medium text-base"]',
            :aria-current='key === selectedTab ? "page" : undefined'
            :data-test="`tab-for-season-${key}`"
          )
            | {{ tab }}
    //-
    //- show "choose other family" button if it makes sense to do so
    //-
    .w-full.mt-3.-mb-4.flex.justify-center(
      v-if='familyIDs.length > 1 && families.length > 1 && selectedTab != "admin"'
    )
      FormKit(type='button', @click='enterSelectOtherFamilyState')
        .w-96.font-bold.text-lg View Other Family
    //-
    //- the Main Thing
    //-
    .py-4.border-gray-200.px-8.max-w-4xl.mx-auto(v-if='selectedTab')
      Season(
        v-if='family && selectedTab != "admin"',
        :seasonUID='selectedTab',
        :family='family'
        :childRegistrationMapping='extractChildRegistrationMapping(family)'
        @parentOrdinalsUpdated="updateFamilyDetails"
      )
      Admin(v-else-if="selectedTab === 'admin'", :familyIDs='familyIDs')
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'

import MultipleFamilies from 'src/components/FamilyProfile/MultipleFamilies.vue'
import * as TS_MultipleFamilies from "src/components/FamilyProfile/MultipleFamilies.ilx"

import {Admin} from 'src/components/FamilyProfile/Admin'
import Season from 'src/components/FamilyProfile/Season.vue'
import { ref, onMounted, watch } from 'vue'
import type { Ref } from "vue"

import type { UserData } from 'src/interfaces/Store/user'
import type { Child, Guid, Registration, SeasonUID, WithDefinite } from 'src/interfaces/InleagueApiV1'
import * as FamilyProfile from "./FamilyProfile.ilx"
import * as Family from "src/composables/InleagueApiV1.Family";
import * as ilapi from "src/composables/InleagueApiV1"
import { exhaustiveCaseGuard } from 'src/helpers/utils'
import authService from 'src/helpers/authService'
import { User } from 'src/store/User'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    MultipleFamilies,
    Admin,
    Season,
  },
  props: FamilyProfile.propsDef,
  setup(props) {
    // type of the expanded object for the particular expansions we are interested in here
    type ExpandedFamilySeason = Family.FamilyData & {children: WithDefinite<Child, "registrations">[]};

    // don't render anything until after onMount runs to load data
    const ready = ref(false);

    // defines displayable tabs
    const tabs = ref<{[value: string]: /*uiLabel*/ string}>({})

    // selectedTab often directly represents a seasonUID, but can also be placeholder values like "admin" or etc.
    const selectedTab = ref('')

    // populated on first need
    const families = ref<TS_MultipleFamilies.Props["families"]>([])

    // family object for the target family/season, refreshed on each change of season
    // undefined when not yet initialized
    const family = ref<ExpandedFamilySeason | null>(null)

    // local cache for seasons we've already seen
    const perSeasonCacheForCurrentFamily = ref<Record<SeasonUID, ExpandedFamilySeason>>({})
    const familyIDs = ref([] as string[])

    // there may be more than 1 family for the current user, but only one may be selected at a time
    const selectedFamilyID = ref('')



    const changeTab = (key: string) => {
      selectedTab.value = key
    }

    const getFamilyDetailsForTargetSeason = async () : Promise<ExpandedFamilySeason> => {
      return await Family.getFamilySeason(
        axiosInstance,
        {
          familyID: selectedFamilyID.value,
          seasonUID: selectedTab.value,
          expand: ["parent1", "parent2", "children.registrations", "parents", "parents.volunteerStatus"]
        }
      ) as ExpandedFamilySeason;
    }

    /**
     * The API contract is that the (appropriately expanded) familySeason object will contain exactly zero or one registrations per child
     * If the child has no registration, the array is empty; otherwise, the array contains exactly the target registration
     */
    const extractChildRegistrationMapping = (expandedFamilySeason: (typeof family)["value"]): {[childID: Guid]: Registration | null} => {
      const result : {[childID: Guid]: Registration | null} = {};
      if (!expandedFamilySeason) {
        return result;
      }

      for (const child of expandedFamilySeason.children) {
        result[child.childID] = child.registrations?.[0] ?? null;
      }

      return result;
    }

    /**
     * Configure ourselves such that we should display the "select other family" modal
     */
    const enterSelectOtherFamilyState = async () => {
      selectedFamilyID.value = ''
      // over eager, but not wrong (click away from family and then right back to it, we just blew away the cache for nothing)
      perSeasonCacheForCurrentFamily.value = {}
      family.value = null
    }

    /**
     * update component state based on some familySeason record
     */
    const updateFamilyDetails = async () => {
      family.value = await getFamilyDetailsForTargetSeason();
    }

    /**
     * when either the selectedTab or selectedFamilyID changes, update component state to reflect the change
     * "admin" acts differently, and does not participate in the local caching behavior
     */
    watch([selectedTab, selectedFamilyID], async () => {
      if (selectedTab.value === "admin") {
        return;
      }

      if (selectedFamilyID.value && selectedTab.value) {
        if (perSeasonCacheForCurrentFamily.value[selectedTab.value]) {
          family.value = perSeasonCacheForCurrentFamily.value[selectedTab.value]
        }
        else {
          family.value = await getFamilyDetailsForTargetSeason()
          perSeasonCacheForCurrentFamily.value[selectedTab.value] = family.value
        }
      }
    })

    onMounted(async () => {
      switch (props.wrapper.name) {
        case FamilyProfile.RouteName.default: {
          families.value = await Family.getFamilyListingForUser(axiosInstance, {userID: User.value.userID});
          familyIDs.value = families.value.map(v => v.familyID);
          break;
        }
        case FamilyProfile.RouteName.singularFamily: {
          familyIDs.value = [props.wrapper.singularFamilyID];
          break;
        }
        case FamilyProfile.RouteName.user: {
          families.value = await Family.getFamilyListingForUser(axiosInstance, {userID: props.wrapper.userID});
          familyIDs.value = families.value.map(v => v.familyID);
          break;
        }
        case FamilyProfile.RouteName.child: {
          const child = await User.getChild({playerID: props.wrapper.childID})
          familyIDs.value = [child.familyID]; // a child has exactly 1 familyID
          break;
        }
        default: exhaustiveCaseGuard(props.wrapper)
      }

      if (familyIDs.value.length === 1) {
        selectedFamilyID.value = familyIDs.value[0]
      }
      else {
        // already initialized to empty string, but we'll be explicit
        selectedFamilyID.value = "";
      }

      initializeTabs(
        await ilapi.getActiveSeasons(axiosInstance),
        tabs,
        selectedTab
      );

      ready.value = true;
    })

    const initializeTabs = (
      seasons: ilapi.ActiveSeasonListingEntry[],
      tabsRef: Ref<{[key: string]: string}>,
      selectedTabRef: Ref<string>
    ) : void => {
      tabsRef.value = {};
      for (const {seasonUID, seasonName} of seasons) {
        tabsRef.value[seasonUID] = seasonName;
      }
      selectedTabRef.value = seasons[0]?.seasonUID ?? "admin";

      if (authService(User.value.roles, "DD", "playerAdmin", "registrar" , "webmaster", "compManager")) {
        // we push this last, relying on insertion order to mean "this tab is displayed last"
        // it should only be displayed to users with elevated permissions
        tabsRef.value["admin"] = "Admin";
      }
    }

    return {
      ready,
      tabs,
      selectedTab,
      families,
      family,
      familyIDs,
      selectedFamilyID,
      changeTab,
      extractChildRegistrationMapping,
      enterSelectOtherFamilyState,
      updateFamilyDetails,
    }
  }
})
</script>
