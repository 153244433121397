import authService, { hasSomeCoachAssignmentTitle } from "src/helpers/authService";
import { Guid } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";

const allAllowedRoles = ["RC", "refScheduler", "gameScheduler", "webmaster", "DD", "director", "compManager", "ChiefRef", "ref"] as const
const rolesRequiringDivConstraint = allAllowedRoles.filter((v) : boolean => v === "director" || v === "DD");
const rolesAllowingUnconstrainedDivSearch = (() => {
  const set_requiresDivConstraint = new Set(rolesRequiringDivConstraint)
  return allAllowedRoles.filter(v => !set_requiresDivConstraint.has(v))
})();

export function hasSomeRoutePermission() {
  return authService(User.value.roles, ...allAllowedRoles) || hasSomeCoachAssignmentTitle(User.userData?.coachAssignmentsMemento ?? [], "Head Coach", "Co-Coach");
}

export function allowsUnconstrainedDivSearch() {
  return authService(User.value.roles, ...rolesAllowingUnconstrainedDivSearch);
}

export function requiresDivConstrainedSearch() {
  return authService(User.value.roles, ...rolesRequiringDivConstraint) && !authService(User.value.roles, ...rolesAllowingUnconstrainedDivSearch);
}

export function getAllowedDivisions() : "*" | Guid[] {
  if (allowsUnconstrainedDivSearch()) {
    return "*"
  }
  else {
    // Probably should be filtered by `writeAccess=true`?
    const divAssignmentsDivIDs = User.userData?.divisionsMemento.map(div => div.divID) ?? []
    // We're interested in divIDs here; but mayEnterScores is derived from a join against competitions,
    // so "may enter scores" is really a function of `(comp, div) => bool`, but we are only computing `(div) => bool` here.
    // This will produce some instances where the user selects a (comp, div) but receives no games; at the very least,
    // that should not be a backend error (400, or etc.) but rather just return an empty listing.
    const coachAssignmentsDivIDs = User.userData?.coachAssignmentsMemento.filter(ca => ca.mayEnterScores).map(ca => ca.divID) ?? []
    return [...new Set([...divAssignmentsDivIDs, ...coachAssignmentsDivIDs])]
  }
}
