<template lang="pug">
div(v-if='ready' data-test="eventSignupRoot")
  .bg-white.shadow.overflow-hidden(class='sm:rounded-lg')
    .px-4.py-5.border-b.border-gray-200(class='sm:px-6')
      h3.text-lg.leading-6.font-medium.text-gray-900
        font-awesome-icon.mr-2(:icon='["fas", "calendar-day"]')
        | {{ event.eventName }}
      p.mt-1.max-w-2xl.text-sm.leading-5.text-gray-500(
        v-if='event.regionSponsor'
      ) Sponsored By: {{ event.regionSponsor }}
    .px-4.py-5(class='sm:p-0')
      dl
        div(class='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5')
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Date
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            | {{ formatDates(event.eventStart, event.eventEnd) }}
        .mt-8(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Location
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            ul
              li
                font-awesome-icon.mr-2(:icon='["fas", "map-marker-alt"]')
                a(
                  :href='"https://www.google.com/maps/place/" + event.address.split(" ").join("+")',
                  target='_blank'
                ) {{ event.address }}
        .mt-8(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Player Fee
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            | {{ event.feePlayer ? event.feePlayer : 'FREE' }}
        .mt-8(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | User Fee
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            | {{ event.feeUser ? event.feeUser : 'FREE' }}
        .mt-8(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | About
          dd.mt-1.text-sm.leading-5.text-gray-900(
            class='sm:mt-0 sm:col-span-2',
            v-html='event.comments'
          )
            |
        .mt-8(
          class='sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5'
        )
          dt.text-sm.leading-5.font-medium.text-gray-500
            | Contact
          dd.mt-1.text-sm.leading-5.text-gray-900(class='sm:mt-0 sm:col-span-2')
            ul
              li {{ event.contactName }}
              li
                a(:href='"mailto:" + event.contactEmail') {{ event.contactEmail }}
              li {{ event.contactPhone }}
  .my-4.bg-white.shadow.overflow-hidden(
    class='sm:rounded-lg',
    v-if='event.signedUpCount > 0 && (authService(userRoles, "EventAdmin", "Registrar", "EmailAdmin") || event.contactEmail === userEmail || hasCompetitionRole("isEmailAdmin", userCompRoles))'
  )
    .px-4.py-5.border-b.border-gray-200(class='sm:px-6')
      h3.text-lg.leading-6.font-medium.text-gray-900
        font-awesome-icon.mr-2(:icon='["fas", "users-cog"]')
        | Contact Attendees

      a(:href='emailURL')
        t-btn(label='Email Atendees')

  .my-4.bg-white.shadow.overflow-hidden(
    class='sm:rounded-lg',
    v-if='event.allowSignups'
  )
    FormKit(type="form" @submit="submit" :actions="false" v-model="formData")
      .px-4.pt-5.border-b.border-gray-200(class='sm:px-6')
        h3.text-lg.leading-6.font-medium.text-gray-900
          font-awesome-icon.mr-2(:icon='["fas", "user-plus"]')
          | Registration
        div
          .flex.items-start.grid.grid-cols-8.gap-4.uppercase.text-sm.font-bold(
            class='sm:mt-0 sm:border-t sm:border-gray-200 sm:py-5 md:gap-6'
          )
            .col-span-2.flex.justify-start(class='md:mt-0, md:col-span-2') Status
            .col-span-4.grid.grid-cols-2(class='md:col-span-5')
              .col-span-2(class='md:col-span-1') Name
              .col-span-2.font-normal.text-xs(
                v-if='children.length',
                class='md:col-span-1'
              ) (Player Division)
            p.col-span-1.text-right(class='md:col-span-1 md:text-left') Fee
        div
          div(
            v-for='adult in adults',
            v-if='adults.length',
            :key='createKey(adult.clientID, "-enrolled")'
          )
            entity-event-signup(
              v-if='adult.isEnrolled',
              v-bind="entityEventSignupProps(adult)"
              v-on='entityEventSignupHandlers',
            )
          div(
            v-if='children.length',
            v-for='child in children',
            :key='createKey(child.childID, "-enrolled")'
          )
            entity-event-signup(
              v-if='child.isEnrolled',
              v-bind="entityEventSignupProps(child)"
              v-on='entityEventSignupHandlers',
            )
          div(
            v-if='adults.length',
            v-for='adult in adults',
            :key='createKey(adult.clientID, "-notEnrolled")'
          )
            entity-event-signup(
              v-if='!adult.isEnrolled',
              v-bind="entityEventSignupProps(adult)"
              v-on='entityEventSignupHandlers',
            )
          div(
            v-if='children.length',
            v-for='child in children',
            :key='createKey(child.childID, "-notEnrolled")'
          )
            entity-event-signup(
              v-if='!child.isEnrolled',
              v-bind="entityEventSignupProps(child)"
              v-on='entityEventSignupHandlers',
            )
      //- Need to change colors based upon disable
      div.p-4
        t-btn.m-0(
          type="submit"
          v-if='entitiesEnrolled',

          :label='"Continue to Signup"',

          data-test='submit'
        )
  h1.text-red-600.mt-4.italic(v-else) This event is currently closed for sign ups
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, onMounted, PropType, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import {
  EventChild, EventUser, EventAugmentedChild, EventAugmentedUser, EventAugmentedEntity, EventAugmentedEntityBase
} from 'src/interfaces/Store/events'

import { formatDates } from 'src/helpers/formatDate'
import authService from 'src/helpers/authService'
import hasCompetitionRole from 'src/helpers/competitionRole'
import EntityEventSignup from 'src/components/Events/EntityEventSignup.vue'
import * as M_EntityEventSignup from "./EntityEventSignup.ilx"
import { UserData } from 'src/interfaces/Store/user'

import { exhaustiveCaseGuard, assertNonNull, logOrThrow, copyViaJsonRoundTrip, isGuid, unsafe_objectKeys, SetEx } from 'src/helpers/utils'

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { getChildrenBelongingToSomeUserWithAdhocSeasonalInformation } from "./R_EventSignup.ilx"
import { propsDef } from './R_EventSignup.route'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'
import { EventStore } from "src/store/EventStore"
import { CheckoutStore } from "src/store/CheckoutStore"

type ExpandedEvent = ilapi.event.GetEventResult;

export default defineComponent({
  name: 'EventSignup',
  components: {
    EntityEventSignup,
  },
  props: propsDef,
  setup(props) {

    const ready = ref(false)
    const event = ref<ExpandedEvent>(/*definitely assigned in onMounted*/ null as any)

    const preExistingSignups = computed<iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[]>(() => {
      if (!event.value) {
        return [];
      }
      const eventSignupMap = EventStore.value.existingSignups[event.value.eventID];
      if (!eventSignupMap) {
        return [];
      }
      const result : iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[] = [];
      for (const key of Object.keys(eventSignupMap)) {
        result.push(eventSignupMap[key]!);
      }
      return result;
    });

    const entitiesArrayView = computed<EventAugmentedEntity[]>(() => {
      if (!event.value) {
        return [];
      }
      const entityMap = EventStore.value.registeringEntities[event.value.eventID];
      if (!entityMap) {
        return [];
      }
      const result : EventAugmentedEntity[] = [];
      for (const key of Object.keys(entityMap)) {
        result.push(entityMap[key]!);
      }
      return result;
    });

    const adults = computed<EventAugmentedUser[]>(() => {
      return entitiesArrayView.value.filter((v) : v is EventAugmentedUser => !v.isChild);
    })
    const children = computed<EventAugmentedChild[]>(() => {
      return entitiesArrayView.value.filter((v) : v is EventAugmentedChild => v.isChild);
    })

    /**
     * actively selected event entities who will be part of the "submit signup" request
     */
    const registering = ref<{[entityID: iltypes.Guid]: EventAugmentedEntity}>({})
    /**
     * Answers to questions per entity.
     * It is possible that some entity in the `registering` map have no answers (and/or there are no questions)
     * so some entities in `registering` may not have entries here (hence the possible `undefined` mapping)
     */
    const formData = ref<{[entityID: iltypes.Guid]: undefined | SignupFormDataPerEntity}>({});

    const totalCost = ref(0)
    const entitiesEnrolled = ref(0)

    const emailURL = ref('')
    const submitMessage = ref('Submit')

    const router = useRouter()

    const maybeFindExistingPendingOrActiveSignup = (entityID: iltypes.Guid) => {
      for (const signup of preExistingSignups.value) {
        if (signup.entityID === entityID && !signup.canceled) {
            return signup;
        }
      }
      return undefined;
    }

    const userRoles = computed(() => {
      return User.value.roles
    })

    const userEmail = computed(() => {
      return User.value.userEmail
    })

    const userCompRoles = computed(() => {
      const compRoles = User.value.userData as UserData
      return compRoles.competitionsMemento
    })

    /**
     * pull the user listing from the relevant endpoint, and map the results as EventChild
     */
    const getChildren = async (seasonID: iltypes.Integerlike, competitionUID: iltypes.Guid) : Promise<EventAugmentedChild[]> => {
      const adhocChildrenDetails = await getChildrenBelongingToSomeUserWithAdhocSeasonalInformation(axiosInstance, {userID: User.value.userID, seasonID, competitionUID});

      return await Promise.all(
        adhocChildrenDetails.map(async adhocChildInfo => {
            const eventChild : EventChild = {...adhocChildInfo, divisionName: await getDivisionName(adhocChildInfo)};
            return unifyEventTargetEntity(event.value, preExistingSignups.value, eventChild, "child")
        })
      );
    }

    /**
     * pull the user listing from the relevant endpoint, and map the results as EventUser
     */
    const getAdults = async (seasonID: iltypes.Integerlike) : Promise<EventAugmentedUser[]> => {
      const response = await axiosInstance.get(
        `v1/users/?seasonid=${seasonID}`
      )

      const array_quasiUser : /*(User (?) & {???})[]*/ any[] = response.data.data;

      return await Promise.all(array_quasiUser.map(quasiUser => unifyEventTargetEntity(event.value, preExistingSignups.value, quasiUser, "user", children.value)));
    }

    const createKey = (id: string, enrollmentStatus: string) => {
      return `${id}-${enrollmentStatus}`
    }

    /**
     * fixme: `enrolled` isn't a thing; `signedUp` is ... and it's `!paid` ?...
     * does "enrolled" mean "signed up, but the signup is not complete"?
     */
    const countEnrolled = () => {
      let sum = 0;
      for (const enrollee of preExistingSignups.value) {
        // not paid, not canceled === enrolled?
        if (!enrollee.paid && !enrollee.canceled) {
          sum += 1;
        }
      }
      // other places also mutate this value; here, the semantics are that we fully refresh it
      entitiesEnrolled.value = sum;
    }

    const submit = async () => {
      try {
        const signupRequests = formDataAsSignupRequests(registering.value, formData.value);

        if (signupRequests.length > 0) {
            const eventSignups = await ilapi.event.signup(
              axiosInstance,
              {eventID: props.eventID, signupRequests}
            );

            //
            // update relevant flowstate
            //
            for (const signup of eventSignups) {
              await EventStore.putSignupByEntityByEvent({signup});
              await EventStore.putEntityByEvent({
                entity: {
                  ...findEntityOrFail(signup.childID || signup.userID),
                  isEnrolled: true
                }
              });
              //
              // We transform from {[key: `${questionID}/${entityID}`]: questionAnswer} -> {[key: `${questionID}`]: questionAnswer}
              // That is, we change the keys from "questionID/entityID" to just "questionID"
              //
              const answersByQuestionId = mungeFormData(formData.value[signup.entityID]).questionAnswers ?? null

              await EventStore.putAnswersByEntityByEvent({
                entityID: signup.entityID,
                eventID: signup.eventID,
                answers: answersByQuestionId,
              })
              await CheckoutStore.putEventSignup(signup);
            }

            await router.push({
              name: 'purchase-confirmation',
              query: { eventID: props.eventID },
            })
        }
        else {
          //
          // should we get here, if there are no signup requests?
          //
          await router.push({
            name: 'purchase-confirmation',
            query: { eventID: props.eventID },
          })
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const findEntityOrFail = (entityID: iltypes.Guid) => {
      const p = (entity: EventAugmentedEntity) => entity.id === entityID;
      const entity = children.value.find(p) || adults.value.find(p);
      if (!entity) {
        throw `'findEntityOrFail' didn't find entity with id ${entityID}`;
      }
      return entity;
    }

    /**
     * todo: clarify what `isAttending` means
     */
    const startTrackingEntityResponse = (entity: EventAugmentedEntity) : void => {
      registering.value[entity.id] = entity
      entitiesEnrolled.value++
      const userFee = entity.isChild
        ? event.value.feePlayer
        : event.value.feeUser
      if (userFee) {
        totalCost.value += userFee
      }
    }

    /**
     * we might have some registration data stored that __does not__ have associated entity response info,
     * e.g. in the case of a pending signup we receive in onMounted; so it is not an error here to NOT find
     * a response to delete.
     */
    const stopTrackingAndDiscardEntityResponse = (entity: EventAugmentedEntity) : void => {
      delete registering.value[entity.id]
      entitiesEnrolled.value--
      const userFee = entity.isChild
        ? event.value.feePlayer
        : event.value.feeUser
      if (userFee) {
        totalCost.value -= userFee
      }

      // not awaiting this should be OK (is it "really" async? yeah it's async but it probably completes synchronously, right?)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      EventStore.removeAnswersByEntityByEvent({entityID: entity.id, eventID: entity.eventID})
    }

    const entityEventSignupHandlers : M_EntityEventSignup.Emits = {
      // @rmme blur: (v) => /* do nothing; is this necessary? */ undefined,
      enrollmentCanceled: async ({entity}) => {
        const entityGroup = entity.isChild ? children.value : adults.value
        for (let i = 0; i < entityGroup.length; i++) {
          if (entityGroup[i].id === entity.id) {
            await EventStore.putEntityByEvent({entity: {...entityGroup[i], isEnrolled: false}});
            break;
          }
        }

        stopTrackingAndDiscardEntityResponse(entity)
        await EventStore.removeSignup({type: "from-entity", eventEntity: entity})
        await CheckoutStore.removeEventSignup({type: "from-entity", eventEntity: entity});
      },
      // @rmme
      // formChanged: (detail) => {
      //   // no-op -- probably can delete this emit type?
      // },
      selected: (detail) => {
        if (!detail.entity.id) {
          // shouldn't happen, but there was some existing code that checked for it here
          logOrThrow("Expected an entityID in `selected` event handler but received nothing; event will be ignored.");
          return;
        }

        if (detail.isSelected) {
          startTrackingEntityResponse(detail.entity);
        } else {
          stopTrackingAndDiscardEntityResponse(detail.entity);
        }
      }
    }

    const entityEventSignupProps = (entity: EventAugmentedEntity) : M_EntityEventSignup.Props => {
      // `reactive` so the computed "collapses" from Computed<T> to T.
      // The "maybeExistingSignup" might dissapear (as in, maybeFind finds nothing), if a signup is canceled
      return reactive({
        entity,
        event: event.value,
        maybeExistingSignup: computed(() => {
          return maybeFindExistingPendingOrActiveSignup(entity.id)
        }),
        formkitEventQuestionsSchema: eventQuestionsAsFormKitSchema(entity.id, event.value.questions)
      })
    }

    onMounted(async () => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        event.value = await EventStore.getEvent({eventID: props.eventID});

        // side-effect: populate store if it was empty
        await EventStore.getSignups({eventID: props.eventID})
        const runAfterEntitiesDefinitelyLoaded : (() => void)[] = [];

        {
          const pendingSignups = preExistingSignups.value.filter(eventSignup => !eventSignup.canceled && !eventSignup.paid);
          if (pendingSignups.length > 0) {
            await CheckoutStore.initEventSignupFlow({data: pendingSignups});
          }
          for (const eventSignup of pendingSignups) {
            formData.value[eventSignup.entityID] = initEventSignupForm(eventSignup, event.value.questions);
            // We want to "startTrackingEntityResponse" for pending signups, but we don't have the entity objects to do that yet
            // We can defer this until we know we have loaded them
            runAfterEntitiesDefinitelyLoaded.push(() => {
              const entity = entitiesArrayView.value.find(entity => entity.id === eventSignup.entityID)
              assertNonNull(entity, "entity must be defined here");
              startTrackingEntityResponse(entity);
            })
          }
        }

        countEnrolled()

        //
        // This is broken, we have to set this to false before we run the subsequent loads.
        // Maybe a single axios instance should count how many requests it has in flight and
        // "multiple calls" is then "requests in flight is > 0" and there would be a 50ms (or so) debounce from
        // "ah it hit zero" to "ok, is it still zero? clear the global loading spinner"
        // See also the finally block
        //
        await System.setMultipleCalls(false);

        if (EventStore.value.registeringEntities[event.value.eventID]) {
          // no-op, we already have the things in the store
        }
        else {
          // cache them, under the assumption that a user's family members won't change during some event signup flow
          if (event.value.allowSignups === ilapi.event.SignupAllowanceType.playersOnly || event.value.allowSignups === ilapi.event.SignupAllowanceType.usersAndPlayers) {
            (await getChildren(event.value.seasonID, event.value.competitionUID)).forEach(entity => EventStore.putEntityByEvent({entity}));
          }
          if (event.value.allowSignups === ilapi.event.SignupAllowanceType.usersOnly || event.value.allowSignups === ilapi.event.SignupAllowanceType.usersAndPlayers) {
            (await getAdults(event.value.seasonID)).forEach(entity => EventStore.putEntityByEvent({entity}));
          }
        }

        // ok, now we have the entities
        runAfterEntitiesDefinitelyLoaded.forEach(f => f());

        emailURL.value = `https://${
          Client.value.instanceConfig.appdomain
        }/Main/in?urlTarget=%2FemailManager%2Femail.cfm&eventIDs=${
          event.value.eventID
        }`

        // side-effects only
        await getCompetitionsOrFail();
        await Client.loadSeasons();

        ready.value = true;
      })
    })

    return {
      ready,
      event,
      adults,
      children,
      registering,
      totalCost,
      entitiesEnrolled,
      emailURL,
      submitMessage,
      createKey,
      submit,
      entityEventSignupHandlers,
      entityEventSignupProps,
      formatDates,
      authService,
      userRoles,
      userEmail,
      hasCompetitionRole,
      userCompRoles,
      formData,
    }
  },
})

/**
 * For the purposes of an event, we merge `User | Child` into `(CommonBase) & (User | Child)`
 * This installs the required properties onto the target entity in order to
 * unify them such that they share the `CommonBase` shape.
 *
 * Input entity shapes here are `quasi-child` and `quasi-user` because they don't conform to the "default" child/user shapes,
 * and so have fields like `registrationDivisionID` and `unregisteredDivisionID` which seem to only materialize from particular endpoints
 * and are not otherwise explicitly expandable fields on such entities.
 *
 * todo: use a tagged union
 * todo: factor out endpoints that get the input entitiies here and clarify their shapes
 * todo: better in/out types
 * todo: tagged union would maybe prevent excessive spreads?...
 *
 * This operates in place (mutates the targetEntity), but also returns the mutated value for convenient use with `map` and friends.
 */
function unifyEventTargetEntity(event: ExpandedEvent, signups: ilapi.event.EventSignup[], targetEntity: EventChild, unifyAs: "child") : EventAugmentedChild;
function unifyEventTargetEntity(event: ExpandedEvent, signups: ilapi.event.EventSignup[], targetEntity: EventUser, unifyAs: "user", children: EventAugmentedChild[]) : EventAugmentedUser;
function unifyEventTargetEntity(event: ExpandedEvent, signups: ilapi.event.EventSignup[], targetEntity: EventUser | EventChild, unifyAs: "child" | "user", children?: EventAugmentedChild[]) : EventAugmentedChild | EventAugmentedUser {
  switch (unifyAs) {
    case "child": {
        const targetChild = targetEntity as EventChild;
        const baseBuilder : EventAugmentedEntityBase = {
          eventID: event.eventID,
          id: targetChild.childID,
          lastName: targetChild.playerLastName,
          firstName: targetChild.playerFirstName,
          isChild: true,
          isEnrolled: hasSomePendingOrActiveSignup(signups, targetChild.childID),
          isEligible: isEligible(event, targetChild, "child")
        };
        return {
          ...baseBuilder,
          ...targetChild
        }
    }
    case "user": {
      const targetUser = targetEntity as EventUser;
      // definitely an `EventChild[]` in this arm of the switch
      const definiteChildren = children!;
      const baseBuilder : EventAugmentedEntityBase = {
        eventID: event.eventID,
        id: targetUser.ID,
        lastName: targetUser.lastName,  // n.b. this is auto unifying but we'll be explicit
        firstName: targetUser.firstName,  // n.b. this is auto unifying but we'll be explicit
        isChild: false,
        isEnrolled: hasSomePendingOrActiveSignup(signups, targetUser.ID),
        isEligible: isEligible(event, targetUser, "user", definiteChildren)
      };
      return {
        ...baseBuilder,
        ...targetUser
      }
    }
    default: exhaustiveCaseGuard(unifyAs);
  }
}

function hasSomePendingOrActiveSignup(signupsListing: ilapi.event.EventSignup[], entityID: iltypes.Guid) {
  for (const signup of signupsListing) {
    if (signup.canceled) {
      continue;
    }
    if (signup.childID === entityID || signup.userID === entityID) {
      return true;
    }
  }
  return false
}

function isEligible(event: ExpandedEvent, entity: EventChild, entityType: "child") : boolean;
function isEligible(event: ExpandedEvent, entity: EventUser, entityType: "user", children: EventChild[]) : boolean;
function isEligible(event: ExpandedEvent, entity: EventChild | EventUser, entityType: "child" | "user", children?: EventChild[]) : boolean {
  if (
    event.playerLimitSeasons.length === 0 &&
    event.adultLimitSeasons.length == 0 &&
    event.divisions.length === 0
  ) {
    // there are zero constraints on eligibility, so every user/player is trivially eligible
    return true;
  }

  if (entityType === "child") {
    const child = entity as EventChild;
    if (!unifyEffectiveDivisionID(child)) {
      // there is neither a "currently registered in" divisionID, nor a "would be registered in" divisionID.
      // At the moment, we interpret this as "ineligible for all events", which seems possibly wrong.
      // However, it is unlikely (but not impossible) that a player has neither, because the algo to determine the
      // "would be registered in" divID should almost always find something.
      return false;
    } else if (
      event.divisions.length > 0 &&
      !event.divisions.includes(unifyEffectiveDivisionID(child))
    ) {
      return false;
    }
    else if ( event.playerLimitSeasonUIDs.length > 0 ) {
      return new SetEx(event.playerLimitSeasonUIDs).intersect(child.activeSeasonUIDs).size > 0
    }
    else {
      // no restrictions on this event
      return true;
    }
  }
  else {
    assertNonNull(children, "children is definitely defined in this overload (where `entity` is EventUser)");
    // is this basically "is any child eligible using the child-is-eligible rules"?
    for (let i = 0; i < children.length; i++) {
      if (
        event.divisions.length > 0 &&
        event.divisions.includes(
          unifyEffectiveDivisionID(children[i])
        )
      ) {
        return true;
      } else if (
        event.divisions.length > 0 &&
        event.divisions.includes(unifyEffectiveDivisionID(children[i]))
      ) {
        return true;
      }
      else if (
        event.adultLimitSeasonUIDs.length > 0 &&
        new SetEx(event.adultLimitSeasonUIDs).intersect(children[i].activeSeasonUIDs).size > 0
      ) {
        return true;
      }
    }

    return false;
  }

  function unifyEffectiveDivisionID(v: EventChild) {
    // it is expected that one or the other is truthy, but not both.
    // the truthy one represents the effective divisionID for the child
    return v.registrationDivisionID || v.unregisteredDivisionID;
  }
}

function questionOptionsAsUiOptions(q: iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions">, options?: {withNilOption: boolean}) : {label: string, value: string}[] {
  const withNilOption = options?.withNilOption || false;
  const result = q.questionOptions.map(option => ({label: option.optionText, value: option.optionValue}))
  if (withNilOption) {
    result.unshift({label: "", value: ""})
  }
  return result;
}

/**
 * manually construct a schema node tree as per FormKit's schema definition requirements
 * Conceptually this sorta-kinda models a DOM tree
 */
function eventQuestionsAsFormKitSchema(entityID: string, qs: iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions">[]) : Record<string, any>[] {
  const result : Record<string, any>[] = [];

  for (const q of qs) {
    const name = `${q.questionID}/${entityID}`
    switch (q.type) {
      case "checkbox":
        // fallthrough
      case "text":
        // fallthrough
      case "textarea": {
        result.push({
          $cmp: 'FormKit',
          props: {
            type: q.type,
            label: q.shortLabel,
            name,
            validation: q.isRequired ? [["required"]] : []
          },
        });
        break;
      }
      case "select":
        // fallthrough
      case "radio": {
        result.push({
          $cmp: 'FormKit',
          props: {
            type: q.type,
            label: q.shortLabel,
            name,
            options: questionOptionsAsUiOptions(q, {withNilOption: q.type === "select"}),
            validation: q.isRequired ? [["required"]] : []
          },
        });
        break;
      }
      default: exhaustiveCaseGuard(q.type);
    }
  }

  return result;
}

/**
 * initialize the signup form so that it can be written into by the form (i.e. has the right key->value pairs)
 * All questions have answers initialized to either `""` or an existing answer if one exists.
 */
function initEventSignupForm(
  existingSignup: iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers">,
  questionListing: iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions">[],
) : SignupFormDataPerEntity {
  const entityID = existingSignup.entityID;
  const result : SignupFormDataPerEntity = {comments: existingSignup.comments || ""};

  // if there is an existing signup, write its answers into the result
  if (existingSignup) {
    for (const q of existingSignup.questionAnswers) {
      result[`${q.questionID}/${entityID}`] = q.answer;
    }
  }

  // default everything to an empty string answer
  // but only if we don't already have an answer
  for (const q of questionListing) {
    const key = `${q.questionID}/${entityID}` as const;
    if (result[key] === undefined) { // there's no existing answer
      if (q.type === "checkbox") {
        result[key] = false;
      }
      else {
        result[key] = "";
      }
    }
  }

  return result;
}

/**
 * As it exists from FormKit
 * Dynamic keys here must contain both questionID and entityID in order to play nice with formkit's radio options
 * and our use of their auto-bind-element-name-to-form-object; we cannot share a name for a collection of radio options across formkit groups
 * e.g. (rough pseudocode)
 * ```jsx
 * <FormKit>
 *  <Formkit type="group"> // entity1
 *   <FormKit type="radio">
 *     <option name={questionID} value="a">
 *     <option name={questionID} value="b">
 *   </FormKit>
 *  </Formkit>
 *  <Formkit type="group"> // entity2
 *   <FormKit type="radio">
 *     <option name={questionID} value="a">
 *     <option name={questionID} value="b">
 *   </FormKit>
 *  </Formkit>
 * </FormKit>
 * ```
 * In the above, the html behavior of `option name={questionID}` is to mean you can only make a single selection across all radio options named `questionID`
 */
interface SignupFormDataPerEntity {
  [questionIdAndEntityID: `${iltypes.Guid}/${iltypes.Guid}`]: string | number | boolean,
  comments: string
}

type SignupFormDataPerEntityKeyType = "questionID/entityID" | "comments"

/**
 * As we need it
 */
interface MungedSignupFormDataPerEntity {
  questionAnswers: {
    [questionID: iltypes.Guid]: string | number | boolean
  } | undefined
  comments: string
}

// Really, the form should be writing the data in this shape, but to do that would be a slight overhaul.
// As it stands, the formdata is always one level deep, like {"<<questionID-1>>/<<entityID>>": "<<answer>>", "<<questionID2>>/<<entityID>>": "<<answer>>", comments: string}
// and we want to pull the questionID keys into their own sub-object
function mungeFormData(formData: SignupFormDataPerEntity | undefined) : MungedSignupFormDataPerEntity {
  if (!formData) {
    return {
      questionAnswers: undefined,
      comments: ""
    }
  }
  else {
    return {
      questionAnswers: (() => {
        const result : MungedSignupFormDataPerEntity["questionAnswers"] = {};
        for (const key of unsafe_objectKeys(formData)) {
          const decomposedKey = decomposeKeyOrFail(key)
          switch (decomposedKey.type) {
            case "comments": {
              // no-op, this is done separately
              continue;
            }
            case "questionID/entityID": {
              result[decomposedKey.questionID] = formData[key];
              continue;
            }
            default: exhaustiveCaseGuard(decomposedKey)
          }
        }
        return result;
      })(),
      comments: formData.comments,
    }
  }

  interface DecomposedKeyBase {
    type: SignupFormDataPerEntityKeyType
  }
  interface JustComments extends DecomposedKeyBase {
    type: "comments"
  }
  interface QuestionIdAndEntityID  extends DecomposedKeyBase {
    type: "questionID/entityID"
    questionID: iltypes.Guid,
    entityID: iltypes.Guid
  }
  type DecomposedKey =
    | JustComments
    | QuestionIdAndEntityID

  function decomposeKeyOrFail(key: string) : DecomposedKey {
    if (key === "comments") {
      return {
        type: "comments",
      }
    }
    const decomposeKeyMatch = key.split("/");
    if (decomposeKeyMatch.length !== 2) {
      throw Error(`expected key to decompose into 2 elements; key was "${key}"`)
    }
    const [questionID, entityID] = decomposeKeyMatch;
    if (!isGuid(questionID, "upper") || !isGuid(entityID, "upper")) {
      throw Error(`expected key to be composed of 2 guids; key was "${key}"`)
    }
    return {type: "questionID/entityID", questionID, entityID}
  }
}

/**
 * given a listing of entities who are registering, and formdata mapping entities to their question answers,
 * produce an API SignupRequest
 *
 * Note that a registering entity need not have an entry in `formData`; if there are no questions, or no questions for
 * which they have an answer, the entity's entry in the formData map may be absent.
 */
function formDataAsSignupRequests(
  registeringEntitiesMap: {[entityID: iltypes.Guid]: EventAugmentedEntity},
  formData: {[entityID: iltypes.Guid]: undefined | SignupFormDataPerEntity},
) : ilapi.event.SignupRequest[] {
  const result : ilapi.event.SignupRequest[] = [];
  for (const entityID of Object.keys(registeringEntitiesMap)) {
    const munged = mungeFormData(formData[entityID]);
    if (registeringEntitiesMap[entityID].isChild) {
      result.push({
        childID: entityID,
        isChild: true,
        customQuestions: munged.questionAnswers,
        isAttending: true,
        comments: munged.comments,
      })
    }
    else {
      result.push({
        userID: entityID,
        isChild: false,
        customQuestions: munged.questionAnswers,
        isAttending: true,
        comments: munged.comments
      })
    }
  }

  return result;
}

async function getDivisionName(
  onlyOneShouldBeNonEmptyString: Pick<EventChild, "registrationDivisionID" | "unregisteredDivisionID">) : Promise<string> {
  // only one should be non empty string, but if for some reason both are non-empty, prefer the "registered" value
  const targetDivID = onlyOneShouldBeNonEmptyString.registrationDivisionID || onlyOneShouldBeNonEmptyString.unregisteredDivisionID;
  return (await Client.getDivisionByID(targetDivID))?.displayName || "(no division name on file)";
}

</script>
