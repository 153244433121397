import { computed, defineComponent, onMounted, PropType, reactive, Ref, ref, watch } from "vue";
import { ColDef, colDefsAsMap } from "src/library/ColDef"
import { EscapedRegExp, exhaustiveCaseGuard, UiOption, VueNeverUnwrappable } from "src/helpers/utils";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { dayjsFormatOr } from "src/helpers/formatDate";
import * as R_CouponEditor from "./R_CouponEditor.route"
import * as R_MasterInvoice from "src/components/Payment/pages/MasterInvoice.route"
import { RouterLink } from "vue-router";
import { paginationData } from "src/modules/PaginationUtils";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { FormKit } from "@formkit/vue";
import { AutoSearch2, Props as AutoSearch2Props } from "src/components/UserInterface/AutoSearch2";

export type ExpandedCoupon = iltypes.WithDefinite<ilapi.coupon.Coupon, "couponCompetitionSeasonLinks" | "couponEventLinks" | "shallowInvoiceDetail" | "definiteRedemptionCount" | "pendingRedemptionCount">
type ShallowInvoiceDetail = ExpandedCoupon["shallowInvoiceDetail"]

const UserSearch = AutoSearch2<iltypes.Guid>();
const ChildSearch = AutoSearch2<iltypes.Guid>();
const EventSearch = AutoSearch2<iltypes.Guid>();

function Pagination<T>(itemsPerPage: number, itemsRef: {value: T[]}) {
  const zi_currentPage = ref(0);
  return reactive({
    zi_currentPage,
    pageData: computed(() => paginationData(itemsRef.value, zi_currentPage.value, itemsPerPage))
  });
}

const SimplePaginationNav = defineComponent({
  props: {
    pagination: {
      required: true,
      type: Object as PropType<ReturnType<typeof Pagination>>
    }
  },
  emits: {
    next: () => true,
    prev: () => true,
  },
  setup(props, {emit}) {
    const hasPrevOrNext = computed(() => props.pagination.pageData.hasPrev || props.pagination.pageData.hasNext);
    return () => props.pagination.pageData.count_totalItems === 0
        ? <div>No items</div>
        : (
          <>
            <div>Items {props.pagination.pageData.zi_currentPageFirstIndex + 1} - {props.pagination.pageData.zi_currentPageLastIndex} of {props.pagination.pageData.count_totalItems}</div>
            {
              // don't take up vertical space if there aren't any forward/back links to show
              hasPrevOrNext.value
                ? (
                  <div class="flex justify-end">
                    <span class={`${props.pagination.pageData.hasPrev ? '' : "invisible"} cursor-pointer text-blue-700 underline mr-1`} onClick={() => emit("prev")}>Prev {props.pagination.pageData.count_itemsPerPage}</span>
                    <span class={`${props.pagination.pageData.hasNext ? '' : "invisible"} cursor-pointer text-blue-700 underline`} onClick={() => emit("next")}>Next {props.pagination.pageData.count_itemsNextPage}</span>
                  </div>
                )
                : null
            }
          </>
        )
  }
})

const InvoiceDetailListing = defineComponent({
  props: {
    shallowInvoiceDetail: {
      required: true,
      type: Array as PropType<ShallowInvoiceDetail>
    }
  },
  setup(props) {
    const filters = reactive({
      fuzzy: {
        live: "",
        committed: "",
        commit: () => { filters.fuzzy.committed = filters.fuzzy.live; },
        clear: () => { filters.fuzzy.committed = filters.fuzzy.live = ""; }
      },
    })

    const filteredData = computed(() => {
      return applyFuzzy(props.shallowInvoiceDetail);

      //
      // check if there's a substring in any of a few different places on the data we have
      //
      function applyFuzzy(vs: ShallowInvoiceDetail) {
        const pattern = filters.fuzzy.committed.trim() === "" ? null : EscapedRegExp(filters.fuzzy.committed, "i");
        return pattern
          ? props.shallowInvoiceDetail.filter(v => {
            const testTargets : string[] = [v.instanceID.toString()];
            for (const lineItem of v.invoiceLineItems) {
              switch (lineItem.entity_type) {
                case "qCompetitionRegistration":
                  testTargets.push(
                    lineItem.competitionRegistration.childDisplayName,
                    lineItem.competitionRegistration.competitionDisplayName,
                    lineItem.competitionRegistration.seasonDisplayName,
                    lineItem.competitionRegistration.divisionDisplayName,
                  )
                  break;
                case "qEventSignup":
                  testTargets.push(
                    lineItem.eventSignup.eventName,
                    lineItem.eventSignup.entityType === "child"
                      ? lineItem.eventSignup.childDisplayName
                      : lineItem.eventSignup.userDisplayName
                  )
                  break;
                case "qTournamentTeam_teamReg":
                  testTargets.push(lineItem.tournamentTeamRegistration.submitterName)
                  break;
                default: exhaustiveCaseGuard(lineItem);
              }
            }
            return testTargets.some(s => pattern.test(s));
          })
          : props.shallowInvoiceDetail;
      }
    });

    const pagination = Pagination(15, filteredData);

    watch(() => filteredData.value, () => {
      pagination.zi_currentPage = 0;
    })

    function shallowInvoiceDetailBlurb(v: ExpandedCoupon["shallowInvoiceDetail"][number]) : JSX.Element {
      return (
        <>
          <div>Status: {v.lastStatus}</div>
          {v.invoiceLineItems.map(runOne)}
        </>
      )

      function discountLine(v: {amount: number, discount: number}) : JSX.Element {
        return (
          <div class="flex items-center gap-1 text-xs">
            <span>${v.amount.toFixed(2)}</span>
            <FontAwesomeIcon icon={["fa", "arrow-right"]}/>
            <span>${(v.amount - v.discount).toFixed(2)}</span>
            <span>
              (
                <FontAwesomeIcon icon={["fa", "arrow-right"]} class="rotate-90 transform"/>
                ${v.discount.toFixed(2)}
              )
            </span>
          </div>
        )
      }

      function runOne(v: ExpandedCoupon["shallowInvoiceDetail"][number]["invoiceLineItems"][number]) {
        switch (v.entity_type) {
          case "qCompetitionRegistration":
            return (
              <div>
                <div>Program registration</div>
                {discountLine(v)}
                <div class="border-l border-zinc-400 ml-1 mb-1 pl-2">
                  <div>{v.competitionRegistration.childDisplayName}</div>
                  <div>{v.competitionRegistration.seasonDisplayName}</div>
                  <div>{v.competitionRegistration.competitionDisplayName}</div>
                  <div>{v.competitionRegistration.divisionDisplayName}</div>
                </div>
              </div>
            )
          case "qEventSignup": {
            const entityName = v.eventSignup.entityType === "child"
              ? v.eventSignup.childDisplayName
              : v.eventSignup.userDisplayName;
            const uiType = v.eventSignup.entityType === "child"
              ? "(player)"
              : "(adult)";
            return (
              <div>
                <div>Event signup {uiType}</div>
                {discountLine(v)}
                <div class="border-l border-zinc-400 ml-1 mb-1 pl-2">
                  <div>{v.eventSignup.eventName}</div>
                  <div>{entityName}</div>
                </div>
              </div>
            )
          }
          case "qTournamentTeam_teamReg": {
            return (
              <div>
                <div>Tournament team registration</div>
                {discountLine(v)}
                <div class="border-l border-zinc-400 ml-1 mb-1 pl-2">
                  <div>{v.tournamentTeamRegistration.seasonName} {v.tournamentTeamRegistration.competitionName}</div>
                  <div>Submitted by {v.tournamentTeamRegistration.submitterName}</div>
                </div>
              </div>
            )
            break;
          }
          default: exhaustiveCaseGuard(v);
        }
      }
    }

    return () => (
      <div>
        <div class="p-2 mb-2">
          <form onSubmit={(e) => {e.preventDefault(); filters.fuzzy.commit();}} class="flex gap-2">
            <input
              type="text"
              v-model={filters.fuzzy.live}
              autocomplete="off"
              class="block w-full min-w-0 flex-grow rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Player name / event name / etc..."
            />
            <t-btn type="submit" margin={false}>
              Filter
            </t-btn>
          </form>
          <div class="flex mt-1">
            <div>
              {
                filters.fuzzy.committed
                  ? <span class="ml-1 text-xs text-blue-700 underline cursor-pointer" onClick={() => filters.fuzzy.clear()}>Clear</span>
                  : null
              }
            </div>
            <div class="ml-auto">
              <SimplePaginationNav pagination={pagination} onNext={() => pagination.zi_currentPage++} onPrev={() => pagination.zi_currentPage--}/>
            </div>
          </div>
        </div>
        <div style="display:grid; gap:.125in; grid-template-columns: repeat(auto-fit, minmax(3in, 1fr))">
          {pagination.pageData.itemsThisPage.map(detail => {
            const redemptionParenthetical = detail.redemptionStatus === "pending-redemption"
              ? `(${detail.invoiceLineItems.length} pending redemption${detail.invoiceLineItems.length > 1 ? "s" : ""})`
              : `(${detail.invoiceLineItems.length} redeemed)`;
            return (
              <div
                key={detail.instanceID}
                class="relative rounded-lg border border-gray-300 bg-white shadow-sm hover:border-gray-400"
              >
                <div class="bg-green-900 text-white p-1 pl-2 items-center w-full rounded-t-lg flex">
                  <div>
                    <span>Invoice {detail.instanceID}</span>
                    <span class="ml-1 text-xs">{redemptionParenthetical}</span>
                  </div>
                  <div class="ml-auto">
                    <RouterLink {...{target:"_blank"}} to={R_MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: detail.instanceID})}>
                      <FontAwesomeIcon v-tooltip={{content: "To invoice detail"}} icon={["fas", "arrow-circle-right"]} class="-rotate-45 transform"/>
                    </RouterLink>
                  </div>
                </div>
                <div class="px-2 pt-1 pb-2">
                  {shallowInvoiceDetailBlurb(detail)}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
})

interface CouponSearcher {
  couponCode: Ref<string>,
  compSeasons: {
    seasonOptions: UiOption<iltypes.Guid>[],
    selectedSeasonUID: Ref<iltypes.Guid>,
    competitionOptions: UiOption<iltypes.Guid>[],
    selectedCompetitionUID: Ref<iltypes.Guid>
  }
  expiresOnOrAfter: Ref<iltypes.Datelike>
  includeNeverExpires: Ref<boolean>,
  eventLookup: Ref<AutoSearch2Props<iltypes.Guid>>,
  userLookup: Ref<AutoSearch2Props<iltypes.Guid>>,
  childLookup: Ref<AutoSearch2Props<iltypes.Guid>>,
  reset: () => void,
}

export type V_CouponSearcher = VueNeverUnwrappable<CouponSearcher>

export const CouponManagerImpl = defineComponent({
  components: {
    InvoiceDetailListing,
  },
  props: {
    coupons: {
      required: true,
      type: Array as PropType<ExpandedCoupon[]>
    },
    searcher: {
      required: true,
      type: Object as PropType<V_CouponSearcher>
    }
  },
  emits: {
    doSearch: () => true,
  },
  setup(props, {emit}) {
    const colDefs = CouponListingColDefs();
    const colDefsMap = colDefsAsMap(colDefs);

    const pagination = Pagination(25, computed(() => props.coupons));
    watch(() => props.coupons, () => {pagination.zi_currentPage = 0;})

    return () => (
      <div data-test="CouponManagerImpl">
        <div class="mb-4 rounded-lg bg-white shadow">
          <div class="p-2">
            <h2>Coupon Manager</h2>
            <p>Coupons may be applied to program registrations or events, and apply either a fixed amount or a percentage off of fees. They do not stack with scholarships -- only the 'best' discount will apply.</p>
          </div>
          <div class="px-2 pt-3 pb-2">
            <form onSubmit={(e) => {e.preventDefault(); emit("doSearch");}}>
              <div class="flex gap-2 items-center">
                <t-btn margin={false}>
                  <RouterLink to={R_CouponEditor.routeDetailToRoutePath({name: "coupon-editor.new"})} class="flex items-center">
                    <FontAwesomeIcon icon={["fas", "square-plus"]}/>
                    <span class="ml-1">New</span>
                  </RouterLink>
                </t-btn>
                <input
                  type="text"
                  v-model={props.searcher.couponCode.value}
                  autocomplete="off"
                  class="block w-full min-w-0 flex-grow rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Search by Coupon code..."
                />
              </div>
              <div style="display:grid; grid-template-columns: repeat(auto-fit, minmax(1.5in, 1fr));" class="mt-2 gap-2">
                <div>
                  <div class="mb-1 font-medium text-sm">Expires on or after</div>
                  <FormKit type="date" v-model={props.searcher.expiresOnOrAfter.value}/>
                  <FormKit type="checkbox" v-model={props.searcher.includeNeverExpires.value} label="Include 'never expires'"/>
                </div>
                <div>
                  <div class="mb-1 font-medium text-sm">Season + competition</div>
                  <FormKit
                    type="select"
                    outer-class="$reset mb-2"
                    wrapper-class="$reset"
                    options={props.searcher.compSeasons.seasonOptions}
                    v-model={props.searcher.compSeasons.selectedSeasonUID.value}
                  />
                  <FormKit
                    type="select"
                    options={props.searcher.compSeasons.competitionOptions}
                    v-model={props.searcher.compSeasons.selectedCompetitionUID.value} />
                </div>
                <div>
                  <div class="mb-1 font-medium text-sm">Event</div>
                  <EventSearch {...props.searcher.eventLookup.value} />
                </div>
                <div>
                  <div class="mb-1 font-medium text-sm">User</div>
                  <UserSearch {...props.searcher.userLookup.value} />
                </div>
                <div>
                  <div class="mb-1 font-medium text-sm">Child</div>
                  <ChildSearch {...props.searcher.childLookup.value} />
                </div>
              </div>
            </form>
            <div class="text-xs flex mt-2">
              <div class="flex items-end">
                <span class="cursor-pointer underline text-blue-700 " onClick={() => props.searcher.reset()}>
                  Reset search
                </span>
              </div>
              <div class="ml-auto">
                <div class="flex flex-col items-end">
                  <t-btn type="button" margin={false} class="mb-1" onClick={() => emit("doSearch")}>
                    Search
                  </t-btn>
                  <SimplePaginationNav pagination={pagination} onNext={() => pagination.zi_currentPage++} onPrev={() => pagination.zi_currentPage--}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-hidden bg-white shadow sm:rounded-md">
          {
            pagination.pageData.count_totalItems === 0
              ? (
                <div class="shadow-md flex flex-col items-center justify-center py-4">
                  <div>No coupons found.</div>
                  <RouterLink class="mt-1" to={R_CouponEditor.routeDetailToRoutePath({name: "coupon-editor.new"})}>
                    <t-btn margin={false}>
                      Create new coupon
                    </t-btn>
                  </RouterLink>
                </div>
              )
              : null
          }
          <ul role="list" class="divide-y divide-gray-200">
            {pagination.pageData.itemsThisPage.map(coupon => (
              <li key={coupon.couponID}>
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div>
                      <p class="truncate text-sm font-medium">{coupon.couponCode}</p>
                      <div class="text-xs">
                        {
                          (() => {
                            const maybeTimeString = dayjsFormatOr(coupon.createdOn, "MMM/DD/YY HH:MM a");
                            return maybeTimeString
                              ? `Created on: ${maybeTimeString}`
                              : "";
                          })()
                        }
                      </div>
                      {
                        coupon.appliesTo_wildcard
                          ? <div class="text-xs">Wildcard coupon (applies to anything)</div>
                          : null
                      }
                    </div>
                    <RouterLink to={R_CouponEditor.routeDetailToRoutePath({name: R_CouponEditor.RouteName.edit, couponID: coupon.couponID})}>
                      <t-btn margin={false}><div class="text-sm">Edit</div></t-btn>
                    </RouterLink>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="flex">
                      <div class="flex flex-col items-start text-sm text-gray-500">
                        <div>{colDefsMap.expirationDate.html.cellValue(coupon)}</div>
                      </div>
                      <div class="ml-2 flex-col items-start text-sm text-gray-500 sm:ml-6">
                        <div>{colDefsMap.discount.html.cellValue(coupon)}</div>
                      </div>
                    </div>
                    <div class="mt-2 flex flex-col items-start text-sm text-gray-500 sm:mt-0">
                      <div>{colDefsMap.redeemed.html.cellValue(coupon)}</div>
                    </div>
                  </div>
                  <div class="w-full my-1">
                    <div class="w-full rounded-2xl bg-white">
                      <Disclosure>
                        {({open}: {open: boolean, close: () => void}) => (
                            <>
                              <DisclosureButton
                                class="border-b border-black pr-2 flex items-center text-left text-sm font-medium"
                              >
                                <FontAwesomeIcon icon={["fas", "chevron-right"]} class={`${open ? 'rotate-90 transform' : ''} transition-transform`}/>
                                <span class="ml-1">Coupon redemption detail</span>
                              </DisclosureButton>
                              <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-800">
                                <InvoiceDetailListing shallowInvoiceDetail={coupon.shallowInvoiceDetail}/>
                              </DisclosurePanel>
                            </>
                          )
                        }
                      </Disclosure>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
})

export function CouponListingColDefs() {
  return [
    {
      name: "code",
      label: "Code",
      html: {
        cellValue: coupon => coupon.couponCode
      },
    },
    {
      name: "expirationDate",
      label: "Expiration date",
      html: {
        cellValue: coupon => (
          <>
            <div class="text-xs">Expires:</div>
            <div>{coupon.expiration_date.type === "unlimited" ? "Never expires" : dayjsFormatOr(coupon.expiration_date.value, "MMM/DD/YYYY")}</div>
          </>
        )
      },
    },
    {
      name: "discount",
      label: "Discount",
      html: {
        cellValue: coupon => (
          <>
            <div class="text-xs">Discount:</div>
            <div>{coupon.discount.type === "absolute"
              ? `$${coupon.discount.value.toFixed(2)}`
              : `${coupon.discount.value.toFixed(2)}%`
            }</div>
          </>
        )
      },
    },
    {
      name: "redeemed",
      label: "Redeemed",
      html: {
        cellValue: coupon => (
          <>
            <div class="text-xs">Redemptions:</div>
            <div>Pending: {coupon.pendingRedemptionCount}</div>
            <div>Fully redeemed: {coupon.definiteRedemptionCount}</div>
          </>
        )
      },
    },
  ] as const satisfies readonly ColDef<ExpandedCoupon>[]
}
