<template lang="pug">
.flex.flex-col
  div(v-if='invoiceInstance' :class='[paymentProcessing || refundProcessing ? "hidden" : ""]')
    .flex.justify-between(class='md:items-end')
      .flex.flex-col(class='md:flex-row')
        h1.text-l.font-medium.flex.items-center
          font-awesome-icon.mr-2(:icon='["fas", "file-invoice"]')
          | Invoice # {{ invoiceInstance.instanceID }}
        .ml-2.text-base.flex.items-center(
          v-if='invoiceInstance.stripe_invoiceNumber'
        ) ({{ invoiceInstance.stripe_invoiceNumber }})
      t-btn(v-if='voidable', @click='voidInvoice', data-cy='void')
        | Void Invoice
      div(class="flex flex-col gap-2 items-end")
        t-btn(
          v-if='showRefundButton',
          @click='refundInvoice = !refundInvoice',
          data-test='refund'
        )
          | Refund Options
    .px-16.py-4(v-if='paymentAttempted && invoiceInstance.status === "draft"')
      h3.italic.text-center Your payment was accepted, but we are temporarily unable to process the invoice. Please contact support if you have not received payment confirmation within an hour.
    MasterInvoiceDisplay(
      :key="`masterInvoiceDisplay/${xRenderKey}`"
      :invoice='invoiceInstance'
    )
    LineItemsDisplay.m-6(
      :key="`lineItemsDisplay/${xRenderKey}`"
      class='md:-mx-6 md:my-0',
      :invoice='invoiceInstance',
      :masterInvoice='true',
      :refund='refundInvoice',
      @processed='onProcessed'
      @completedRefund="onCompletedRefund"
      :showDueNow="false"
      :paymentScheduleBlurb="paymentScheduleBlurb"
    )
    .flex.w-full.justify-end(v-if='!paymentAttempted && checkoutRoute')
      router-link(
        v-if='payable',
        :to='checkoutRoute'
      )
        t-btn.mt-2(:margin='false', data-cy='goToPayment')
          | Proceed to Payment
    div(
      class="mt-4 text-sm flex justify-center"
      v-if="processing"
    )
      | A payment request for this invoice is actively being processed.
    TransactionDisplay(
      v-if='invoiceInstance.transactions && invoiceInstance.transactions.length',
      :transactions='invoiceInstance.transactions'
    )
</template>

<script lang="tsx">
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'


import MasterInvoiceDisplay from 'src/components/Payment/MasterInvoiceDisplay.vue'
import LineItemsDisplay from 'src/components/Payment/LineItemsDisplay.vue'
import TransactionDisplay from 'src/components/Payment/TransactionDisplay.vue'
import { Invoice, LastStatus_t } from 'src/interfaces/Store/checkout'
import { System } from 'src/store/System'

import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'
import { AxiosErrorWrapper } from 'src/boot/AxiosErrorWrapper'
import { assertNonNull, assertTruthy, useIziToast, vReqT } from 'src/helpers/utils'
import { getPaymentScheduleBlurb } from 'src/composables/InleagueApiV1.Invoice'
import { axiosInstance } from 'src/boot/AxiosInstances'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { isSubscriptionInvoice } from '../InvoiceUtils'
import { Integerlike } from 'src/interfaces/InleagueApiV1'

import { AutoModal } from 'src/components/UserInterface/Modal'
import { CheckoutStore } from "src/store/CheckoutStore"

export default defineComponent({
  name: 'MasterInvoicePage',
  props: {
    invoiceID: vReqT<Integerlike>(),
  },
  components: {
    MasterInvoiceDisplay,
    LineItemsDisplay,
    TransactionDisplay,
    AutoModal,
  },
  setup(props) {
    const iziToast = useIziToast();
    const refundInvoice = ref(false)

    const route = useRoute()
    const router = useRouter()

    const refund = computed(() => {
      return refundInvoice
    })

    const invoiceInstance = computed<Invoice | undefined>(() => {
      return CheckoutStore.value.invoice[props.invoiceID]
    })

    const multipleCalls = computed(() => {
      return System.value.multipleCalls
    })

    const paymentProcessing = computed(() => {
      return System.value.paymentProcessing
    })

    const refundProcessing = computed(() => {
      return System.value.refundProcessing
    })

    const paymentAttempted = computed(() => {
      return CheckoutStore.value.paymentAttempted
    })

    const processed = computed(() => {
      return CheckoutStore.value.processed
    })

    const onProcessed = () => {
      refundInvoice.value = false
      xRenderKey.value += 1;
    }

    const voidInvoice = async () => {
      try {
        await CheckoutStore.voidInvoice(route.params.invoiceID as string);
        // reload in voided state for view purposes
        await CheckoutStore.getInvoice({invoiceID: route.params.invoiceID as string, expand: true});
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const hasRefundPermission = computed(() => {
      return refundable.value
        && invoiceInstance.value
        && invoiceInstance.value.adminAccess
    })

    const hasCancelSubscriptionPermission = computed(() => hasRefundPermission.value)

    const showRefundButton = computed(() => {
      return hasRefundPermission.value
        && invoiceInstance.value
        && invoiceInstance.value.transactionBalance > 0
    })

    const isSubscription = computed(() => {
      return invoiceInstance.value && isSubscriptionInvoice(invoiceInstance.value)
    })

    const processing = computed(() => invoiceInstance.value?.lastStatus === LastStatus_t.IN_FLIGHT);

    const refundable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.PAID_AND_PROCESSED:
        case LastStatus_t.REFUNDED: // n.b. does not consider remaining monetary amounts, that should be done elsewhere
          return true;
        default:
          return false;
      }
    })

    const payable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.NULLISH:
        case LastStatus_t.CREATED:
        case LastStatus_t.PAYMENT_REJECTED:
          return true;
        default:
          return false;
      }
    })

    const voidable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.NULLISH:
        case LastStatus_t.CREATED:
        case LastStatus_t.PAYMENT_REJECTED:
          return true;
        default:
          return false;
      }
    });

    const checkoutRoute = computed(() => {
      if (invoiceInstance.value) {
        return R_Checkout.routeDetailToRouteLocation({
          invoiceInstanceIDs: [invoiceInstance.value.instanceID]
        })
      }
      return null;
    })

    watch(
      processed,
      (value: { [key: string]: boolean }) => {
        // console.log('processed watcher', value)
        if (value[route.params.invoiceID as string]) {
          onProcessed()
        }
      },
      { deep: true }
    )

    onMounted(async () => {
      if (!paymentProcessing.value) {
        // console.log('in mounted')
        await CheckoutStore.getInvoice({
          invoiceID: route.params.invoiceID as string,
          expand: true,
        })

        const invoice = CheckoutStore.value.invoice[route.params.invoiceID as string]
        const transactions = invoice?.transactions;

        assertTruthy(invoice, "side effect of `getInvoice`")
        assertNonNull(transactions, "expected shape")
      }
    })

    onBeforeUnmount(async () => {
      await CheckoutStore.setPaymentAttempted(false)
    })

    const paymentScheduleBlurb = ref<string | undefined>(undefined);

    const getOrRefreshPaymentScheduleBlurb = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        paymentScheduleBlurb.value = await (async () => {
          if (invoiceInstance.value && isSubscriptionInvoice(invoiceInstance.value)) {
            return await getPaymentScheduleBlurb(axiosInstance, {invoiceInstanceID: props.invoiceID})
          }
          else {
            return undefined;
          }
        })();
      })
    }

    const onCompletedRefund = () => {
      CheckoutStore.getInvoice({invoiceID: props.invoiceID.toString(), expand: true})
      xRenderKey.value += 1;
    }

    watch(
      [() => invoiceInstance.value?.instanceID, () => invoiceInstance.value?.invoiceID, () => invoiceInstance.value?.methodID],
      getOrRefreshPaymentScheduleBlurb,
      {immediate: true}
    )

    // There's a few components that have state constructed via setup/onMounted where really this component should own that state.
    // This is intended to force re-run the setup/onMounted cycles of those components.
    const xRenderKey = ref(0)

    return {
      refundInvoice,
      onProcessed,
      voidInvoice,
      refund,
      voidable,
      payable,
      processing,
      invoiceInstance,
      multipleCalls,
      refundProcessing,
      paymentAttempted,
      paymentProcessing,
      checkoutRoute,
      paymentScheduleBlurb,
      onCompletedRefund,
      showRefundButton,
      xRenderKey,
    }
  },
})
</script>
