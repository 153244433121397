import { Switch as HUI_Switch } from '@headlessui/vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue';

export const Switch = defineComponent({
  props: {
    modelValue: {
      required: false,
      default: false,
    },
    on: {
      required: false,
      type: null as any as PropType<string | number | boolean>,
      default: true
    },
    off: {
      required: false,
      type: null as any as PropType<string | number | boolean | null | undefined>,
      default: false
    }
  },
  setup(props, {emit}) {
    const localEnabled = ref<string|number|boolean|null|undefined>(props.modelValue);
    const enabled = computed({
      get() { return localEnabled.value === props.on },
      set(v: boolean) {
        localEnabled.value = v ? props.on : props.off;
        emit("update:modelValue", localEnabled.value);
      }
    })
    watch(() => props.modelValue, () => {
      localEnabled.value = props.modelValue;
    })

    return () => (
      <HUI_Switch
        v-model={enabled.value}
        class={`${enabled.value ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
        data-test-current-value={localEnabled.value}
      >
        <span
          class={`${enabled.value ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </HUI_Switch>
    );
  }
})
