<template lang="pug">
label.font-medium.text-smt-label.block.mt-6.ml-2.-mb-3
  span(class="md:hidden") {{labelItem}}
  span Season Restrictions
  button.ml-1.cursor-pointer(
    v-tooltip='{content: `Restrict display of registration item based upon registration season.`}'
  )
    font-awesome-icon.mr-2.text-gray-400(
      :icon='["fas", "info-circle"]'
    )
FormKit(
  v-model='selectAllSeasons',
  label="All Seasons",
  type='checkbox',
  wrapper-class='mt-5'
)
div.block.h-full.mt-4
  FormKit(
    v-model="selectedSeasonsUIDs",
    label="Seasons"
    :options='seasons',
    type='checkbox',
    validations='required'
    cols="2"
  )
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { createSelect } from 'src/helpers/schemaService'
import { assertNonNull } from 'src/helpers/utils'
import { ItemSeason } from 'src/interfaces/Store/registration'
import { Client } from 'src/store/Client'
import { defineComponent, ref, onMounted, watch, computed, PropType, Ref, getCurrentInstance } from 'vue'

export default defineComponent({
  props: {
    selectedSeasonsObjects: {
      type: Array as PropType<ItemSeason[]>,
      required: true
    },
    /**
     * This was marked as required, but there's callers that don't pass it (one such instance is src/components/Registration/admin/Option.vue)
     * So, we'll say it's not required, and default to false, because that mimics actual runtime behavior that we've been exhibiting for some time.
     */
    triggerSave: {
      type: Boolean,
      required: false,
      default: false
    },
    pageItemID: {
      type: String,
      required: false
    },
    labelItem: {
      type: String,
      required: true
    }
  },
  emits: ['update:seasonUIDs', 'updateStatus', 'apiError', 'selectedAllSeasons'],
  setup(props, {emit}) {
    const seasons = ref({})
    const selectAllSeasons = ref(false)
    const selectedSeasonsUIDs = ref(null) as Ref<string[] | null>
    const seasonsStatus = ref('unchanged')

    const localInstance = getCurrentInstance()
    const toast = localInstance?.appContext.config.globalProperties.$toast

    const getSeasonsUIDs = () => {
      const seasonUIDs: string[] = []
      for(let i=0; i<props.selectedSeasonsObjects.length; i++) {
        seasonUIDs.push(props.selectedSeasonsObjects[i].seasonUID)
      }
      return seasonUIDs
    }

    const getSeasons = async () => {
      // console.log('getting seasons')
      const seasns = await Client.loadSeasons()
      // console.log('after await for seasons')
      // console.log('seasns', seasns)
      const yearLimit = (new Date()).getFullYear() -2
      const seasonsWithinLastTwoYears: any[] = []
      for(let i=0; i<seasns.length; i++) {
        if(seasns[i].registrationYear>=yearLimit) {
          seasonsWithinLastTwoYears.push(seasns[i])
        }
      }

      const seasonsSelectFormat = createSelect(
        seasonsWithinLastTwoYears,
        'seasonUID',
        'name'
      )
      // console.log('in get Options ', seasonsSelectFormat)
      seasons.value=seasonsSelectFormat
    }


    const addSeasons = async (customQuestionID: string) : Promise<void> => {
      if (selectAllSeasons.value) {
        try {
          await axiosInstance.put(`/v1/registration/pageItem/${customQuestionID}/seasons`, {seasons: /*empty array is "all seasons"*/[]});
          seasonsStatus.value='saved'
        }
        catch(err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
      else {
        try {
          await axiosInstance.put(`/v1/registration/pageItem/${customQuestionID}/seasons`, {seasons: selectedSeasonsUIDs.value})
          seasonsStatus.value='saved'
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }
    }

    watch(()=>props.triggerSave, async (val) => {
      // console.log('triggerSave, SeasonSelector', val)
      // console.log('triggerSave in SEA@sons', val)
      if(val && !selectAllSeasons.value && (selectedSeasonsUIDs.value as string[]).length===0) {
        emit('apiError')
        // console.log('need to release toast to say there is an error. ')
        toast.error({message: 'Please select at least one season.'})
      } else if(val) {
        // console.log('has val, going to call add seasons', selectedSeasonsUIDs.value, props.pageItemID)
        await addSeasons(props.pageItemID as string)
      }
    })

    watch(selectAllSeasons, (val: boolean) => {
      // console.log('selectAllSeasons, SeasonSelector')
      emit('selectedAllSeasons', val)
      if(val) {
        selectedSeasonsUIDs.value = Object.keys(seasons.value)
      } else {
        selectedSeasonsUIDs.value = []
      }
    })

    watch(seasonsStatus, (val) => {
      // console.log('seasonStatus, SeasonSelector')
      emit('updateStatus', val)
    })

    watch(selectedSeasonsUIDs, (seasonsUIDs: string[] | null, prevSeasonUIDs: string[] | null) => {
      // strictFunctionTypes indicates this is possibly null,
      // but prior to checking w/ strictFunctionTypes it is not assumed that seasonsUIDs is ever null here
      // (we always unsafely accessed seasonsUIDs.length, which would crash just as we would crash here on the following assert)
      assertNonNull(seasonsUIDs, "null seasonsUIDs is unexpected here");

      if(prevSeasonUIDs) seasonsStatus.value='pending'
      if(seasonsUIDs.length != Object.keys(seasons.value).length) {
        // console.log('will clear selectAllSeasons', seasonsUIDs, Object.keys(seasons.value))
        selectAllSeasons.value = false
      }

      if(selectAllSeasons.value) {
        emit('update:seasonUIDs', [])
      } else {
        emit('update:seasonUIDs', seasonsUIDs)
      }
    })

    onMounted(async() => {
      // console.log('onMounted, SeasonSelector')

      await getSeasons()
      if(props.selectedSeasonsObjects.length===0) {
        selectAllSeasons.value=true
      } else {
        selectedSeasonsUIDs.value = getSeasonsUIDs()
      }
    })

    return {
      selectedSeasonsUIDs,
      seasons,
      selectAllSeasons,
      addSeasons,
      seasonsStatus
    }
  },
})
</script>

