import { ExtractPropTypes, PropType } from "vue";
import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import type * as iltypes from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard } from "src/helpers/utils";

export const RouteNames = {
  base: "tournament-page-item-editor",
  new: "tournament-page-item-editor.new",
  edit: "tournament-page-item-editor.edit",
} as const;

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<PropsVariant>
  }
} as const;

type Props = ExtractPropTypes<typeof propsDef>

type PropsVariant =
  | CreateNew
  | EditExisting

interface PropsBase {
  name:
    | typeof RouteNames.new
    | typeof RouteNames.edit,
  tournamentID: iltypes.Integerlike,
}

interface CreateNew extends PropsBase {
  name: typeof RouteNames.new
}

interface EditExisting extends PropsBase {
  name: typeof RouteNames.edit,
  pageItemID: iltypes.Integerlike
}

export function routeDetailToRouteLocation(v: PropsVariant) : RouteLocationRaw {
  switch (v.name) {
    case RouteNames.new:
      return {name: RouteNames.new, params: {tournamentID: v.tournamentID}}
    case RouteNames.edit:
      return {name: RouteNames.edit, params: {tournamentID: v.tournamentID, pageItemID: v.pageItemID}}
    default: exhaustiveCaseGuard(v)
  }
}

export function routeLocationToProps(v: RouteLocationNormalized) : Props {
  const name = v.name as PropsBase["name"]
  switch (name) {
    case RouteNames.new:
      return {
        detail: {
          name: RouteNames.new,
          tournamentID: v.params.tournamentID as iltypes.Integerlike
        }
      }
    case RouteNames.edit:
      return {
        detail: {
          name: RouteNames.edit,
          tournamentID: v.params.tournamentID as iltypes.Integerlike,
          pageItemID: v.params.pageItemID as iltypes.Integerlike
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}
