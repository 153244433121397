<template lang="pug">

template(v-if="ready && ELAs.length>0")
  h1.flex.items-center.items-start.mb-5
    font-awesome-icon.mr-4(:icon='["fas", "edit"]')
    .text-4xl.font-medium Disclaimer, Assumption of Risk, Waiver, and Consent Agreements - {{ elaIsForThisUser.firstName }} {{ elaIsForThisUser.lastName }}

  div(v-for="ELA in ELAs" :data-test="`elaID=${ELA.ID}`")
    div(v-html="ELA.Text")
    FormKit(
      v-model="checkboxes[ELA.ID]"
      wrapper-class='my-10',
      label="I AGREE",
      label-class="$reset text-2xl text-red-700 font-bold",
      type='checkbox'
    )
      .text-2xl.tracking-wide.text-red-700.font-bold(@click="checkboxes[ELA.ID]=!checkboxes[ELA.ID]") I AGREE
  .w-96.flex.items-end
    FormKit(
      v-model="elaSignature.AcceptedByFirstName"
      label="First Name"
    )
    FormKit(
      v-model="elaSignature.AcceptedByLastName"
      label="Last Name"
    )
  TAlert(v-if="checkboxError || nameError" type="danger")
    div(v-if="checkboxError") Please select all checkboxes.
    div(v-if="nameError") {{ mustBeSignedByMessage }}

  //- this is displayed by default as a non-error
  //- clicking submit and having the name be typed wrong will shift it into the error display
  div(v-if="!nameError").text-sm {{ mustBeSignedByMessage }}
  TBtn.mt-4(label="I Agree" @click="verifySignature")

</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { CompletedELA, Waiver, WaiverSubmission } from 'src/interfaces/registration'
import elas from 'src/composables/elas'
import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import * as ilapi from "src/composables/InleagueApiV1"
import type { Guid, User as IUser } from "src/interfaces/InleagueApiV1"
import { User } from "src/store/User"
import { assertNonNull } from 'src/helpers/utils'
import { Client } from 'src/store/Client'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    seasonUID: {required: true, type: String},
    volunteerID: {required: true, type: String},
  },
  emits: {
    complete: (_?: {skipped: boolean}) => true,
  },
  setup(props, context) {
    const ELAs = ref([]) as Ref<Waiver[]>

    const elaSignature = ref<WaiverSubmission>({
      AcceptedByFirstName: "",
      AcceptedByLastName: "",
      ClubKey: "",
      elas: [],
      seasonUID: props.seasonUID
    });

    const checkboxes = ref({})
    const checkboxError = ref(false)
    const nameError = ref(false)

    const router = useRouter()
    const route = useRoute()
    const { retainOnlyIncompleteELAs, setupCheckboxes, verifyName, verifyCheckboxes, createPostOptions } = elas()

    const ready = ref(false);

    // definitely assigned in onMounted
    // the "current user" MAY be different from the user the ELA is for
    // this is conceptually "signed by <current user> on behalf of <elaIsForThisUser>"
    const elaIsForThisUser = ref<Pick<IUser, "ID" | "firstName" | "middleName" | "lastName" | "region"> | undefined>();

    const mustBeSignedByMessage = computed(() => {
      if (elaIsForThisUser.value === undefined) {
        return "";
      }
      else if (elaIsForThisUser.value.ID === User.value.userID) {
        return `This form must be signed by you ('${elaIsForThisUser.value.firstName} ${elaIsForThisUser.value.lastName}')`
      }
      else {
        return `This form must be signed by ${elaIsForThisUser.value.firstName} ${elaIsForThisUser.value.lastName}`;
      }
    })


    // const instanceConfig = Client.value.instanceConfig

    // const verifyIncompleteELAs = (leagueELAs: Waiver[], completedELAs: CompletedELA[]) => {
    //   // console.log('ELAs', leagueELAs, completedELAs)
    //   const displayELAs = [...leagueELAs]
    //   for(let i=leagueELAs.length-1; i>=0; i--) {
    //     // console.log('in for loop, leagueELAs', leagueELAs[i])
    //     const hasCompletedELA = completedELAs.some(ELA => ELA.ELAKey.toLowerCase()===leagueELAs[i].Key.toLowerCase() && ELA.VersionID.toLowerCase() === leagueELAs[i].VersionID.toLowerCase())
    //     // console.log(hasCompletedELA)
    //     if(hasCompletedELA) {
    //       displayELAs.splice(i, 1)
    //     }
    //   }
    //   return displayELAs
    // }

    // const setupCheckboxes = () => {
    //   for(let i = 0; i<ELAs.value.length; i++) {
    //     checkboxes.value[ELAs.value[i].ID]=false
    //   }
    // }

    // const verifyCheckboxes = () => {
    //   for(const ID in checkboxes.value) {
    //     // console.log(checkboxes.value[ID], ID)
    //     if(!checkboxes.value[ID]) {
    //       checkboxError.value=true
    //       break
    //     }
    //   }
    // }

    const submitELAs = async () => {
      const options = createPostOptions(props.seasonUID, ELAs.value, checkboxes.value, elaSignature.value)
      try {
        const response = await axiosInstance.put(`/v1/registration/volunteerELAs/user/${props.volunteerID}`, options)
        return response.data.data
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      }
    }

    // const updateELASignature = () => {
    //   // add ClubKey and season
    //   // console.log('clubKey', instanceConfig.stackclubkey)
    //   elaSignature.value.ClubKey=instanceConfig.stackclubkey
    //   elaSignature.value.seasonUID=props.seasonUID as string

    //   // For every ELA that has been signed, add its details to elaSignature
    //   const signedElas: ELAProperties[] = []
    //   for(let i=0; i<ELAs.value.length; i++) {
    //     if(checkboxes.value[ELAs.value[i].ID]) {
    //       signedElas.push({ELAKey: ELAs.value[i].Key , VersionID: ELAs.value[i].VersionID})
    //     }
    //   }

    //   elaSignature.value.elas=signedElas
    //   // console.log('elaSignature.value', elaSignature.value)
    // }

    const verifySignature = async () => {
      assertNonNull(elaIsForThisUser.value, "definitely assigned in onMounted");

      nameError.value = verifyName(elaSignature.value, elaIsForThisUser.value);
      checkboxError.value = verifyCheckboxes(checkboxes.value)
      if(!checkboxError.value && !nameError.value) {
        // Prepare and submit ELAs
        // updateELASignature()

        // proceed to payment
        try {
          await submitELAs()
          context.emit("complete");//await router.push({name: 'volunteer-confirmation', params: {volunteerID: props.volunteerID, seasonUID: props.seasonUID }})
        } catch(error) {
          // console.log('error in creating invoice', error)
        }
      }
    }

    watch(() => [elaSignature.value.AcceptedByFirstName, elaSignature.value.AcceptedByLastName, checkboxes], ()=> {
      nameError.value=false
      checkboxError.value=false
    }, {deep: true})

    onMounted(async ()=> {
      elaIsForThisUser.value = await (async () => {
        if (props.volunteerID === User.value.userID && typeof User.value.userData === "object") {
          // in the trivial case, the ela is for the current user; we can use the current user's record
          return {
            firstName: User.value.userData.firstName,
            lastName: User.value.userData.lastName,
            middleName: User.value.userData.middleName,
            ID: User.value.userID,
            region: User.value.userData.region,
          }
        }
        else {
          // otherwise we need to hit the api
          return await ilapi.getUser(axiosInstance, props.volunteerID);
        }
      })();

      if (elaIsForThisUser.value.region.toString() !== Client.value.instanceConfig.region.toString()) {
        // "foreign" users don't do ELAs at this time
        context.emit("complete", {skipped: true});
        return;
      }

      const leagueELAs = await getELAs(axiosInstance, {seasonUID: props.seasonUID})
      const completedELAs = await getVolunteersELAs(axiosInstance, {volunteerID: props.volunteerID, seasonUID: props.seasonUID})

      ELAs.value = retainOnlyIncompleteELAs(leagueELAs, completedELAs)

      if (ELAs.value.length === 0) {
        context.emit("complete", {skipped: true});
      }
      checkboxes.value = setupCheckboxes(ELAs.value)

      ready.value = true;
    })

    return {
      ELAs,
      checkboxes,
      verifySignature,
      checkboxError,
      nameError,
      elaSignature,
      mustBeSignedByMessage,
      ready,
      elaIsForThisUser
    }
  },
})

async function getELAs(ax: AxiosInstance, args: {seasonUID: Guid}) : Promise<Waiver[]> {
  const response = await ax.get(`/v1/registration/volunteerELAs?seasonUID=${args.seasonUID}`)
  return response.data.data
}

async function getVolunteersELAs(ax: AxiosInstance, args: {seasonUID: Guid, volunteerID: Guid}) : Promise<CompletedELA[]> {
    const response = await ax.get(`v1/registration/volunteerELAs/user/${args.volunteerID}`, {params: {seasonUID: args.seasonUID}})
    return response.data.data
}
</script>
