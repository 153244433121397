<template lang="pug">
C_ResetAysoDataModal(
  v-if="resetAysoDataModalController.visible"
  v-bind="resetAysoDataModalController.props"
  v-on="resetAysoDataModalController.handlers"
)
AutoModal(
  data-test="CoachPhotoUploadModalController"
  :controller="coachPhotoUploadModalController"
)
div(
  v-if="ready && (authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []}) || isUserRelated({userID: userDetails.ID, userList: userData.belongingUserIDs}) || userDetails.ID===userData.userID)"
  data-test="ParentRecord"
)
  .w-full.flex.justify-center
    t-btn(
      type='button',
      :margin="false"
      label='Save Parent Record',
      @click='doSaveParent',
      v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []}) || isUserRelated({userID: userDetails.ID, userList: userData.belongingUserIDs}) || userDetails.ID===userData.userID"
      data-test="submit"
    )
  .flex.flex-col
    .my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full(v-if="Object.keys(userDetails).length>0" :style="useMobileLayout ? {'--fk-margin-outer': 'none'} : {}")
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200.flex-col.flex.items-center.justify-between(class="lg:flex-row" colspan="4")
                  div {{ userDetails.firstName }} {{ userDetails.lastName }} - Parent/Adult Record
                  div.flex(class="mt-2 lg:mt-0 gap-2 justify-center" v-if="authUser(userData, ['Registrar', 'Webmaster'])")
                    t-btn(
                      :margin="false"
                      type='button',
                      @click="toFamilyProfile()"
                    )
                      div.whitespace-nowrap Family Profile
                    ImpersonateButton(:userID='userDetails.ID')
            .grid.grid-cols-1(class="lg:grid-cols-6 lg:divide-y lg:divide-gray-200 mt-1 lg:mt-0")
              CommonFormLabelCell
                | First Name:
              CommonFormBodyCell
                div(v-if="!userDetails.stackRecordKey && canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.firstName',
                    name='firstName',
                    validations='required'
                    data-test="firstName"
                  )
                div(v-else data-test="firstName/locked") {{ userDetails.firstName }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Middle Name:
              CommonFormBodyCell
                div(v-if="!userDetails.stackRecordKey && canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.middleName',
                    name='middleName',
                    data-test="middleName"
                  )
                div(v-else data-test="middleName/locked") {{ userDetails.middleName }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Last Name:
              CommonFormBodyCell
                div(v-if="!userDetails.stackRecordKey && canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.lastName',
                    name='lastName',
                    validations='required'
                    data-test="lastName"
                  )
                div(v-else data-test="lastName/locked") {{ userDetails.lastName }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Nick Name:
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.nickName',
                    name='nickName',
                    validations='required'
                  )
                div(v-else) {{ userDetails.nickName }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Birthdate:
              CommonFormBodyCell
                div(v-if="!userDetails.stackRecordKey && canEdit")
                  //- note -- dob must have been pre-munged into a valid form format
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.dob',
                    name='dob',
                    validations='required'
                    type="date"
                    data-test="dob"
                  )
                //- note -- dob is formatted on the fly here
                div(v-else data-test="dob/locked") {{ dayJSDate(userDetails.dob) }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Gender:
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    class="$reset tailwindcss classes inline",
                    fieldset-class='bg-white',
                    options-class='flex pl-4',
                    inner-class='inline pl-4',
                    wrapper-class='pt-2',
                    v-model='userDetails.gender',
                    name='gender',
                    validations='required'
                    type="radio"
                    :options="{'M':'Male', 'F': 'Female'}"
                    :row="true"
                  )
                div.py-4(v-else) {{ userDetails.gender ==='M' ? 'Male' : 'Female'}}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Risk Status:
              CommonFormBodyCell
                div(v-if="userDetails.RiskStatus") {{userDetails.RiskStatus}} ({{userDetails.RiskStatusExpiration}})
                div(v-else) N/A
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Primary Email Address
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.email',
                    name='email',
                    validations='required'
                  )
                div(v-else) {{ userDetails.email }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | 2nd Email Address
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.email2',
                    name='email2',
                    validations='required',
                  )
                div(v-else) {{ userDetails.email2 }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | 3rd Email Address
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.email3',
                    name='email3',
                    validations='required'
                  )
                div(v-else) {{ userDetails.email3 }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | AYSO ID
              CommonFormBodyCell
                div.flex(v-if="userDetails.stackSID")
                  span.mr-auto {{userDetails.stackSID}}
                  TBtn.ml-auto(
                    type="button"
                    label="Reset Assoc. Data" color="red"
                    v-if="authService(userRoles, 'registrar')"
                    @click="resetAysoDataModalController.show(userDetails.ID, `${userDetails.firstName} ${userDetails.lastName}`)"
                  )
                div(v-else) (Not Registered)
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Password
              CommonFormBodyCell
                template(v-if="userID === loggedInUserID")
                  TLegacyAppLink(url="change-password.cfm")
                    span.font-bold.underline.cursor-pointer Reset Password
                template(v-else)
                  template(v-if='authService(userRoles, "Webmaster", "registrar")')
                    TLegacyAppLink(:url="`admin/admin-password.cfm?userID=${userID}`")
                      span.font-bold.underline.cursor-pointer Reset Password
                  template(v-else)
                    //- no-op
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Region
              CommonFormBodyCell
                FormKit(
                  inner-class='bg-white',
                  v-if="canEdit",
                  type='select',
                  :options='regionOptions',
                  name='region',
                  v-model='userDetails.region'
                  data-test="region"
                )
                div(v-else) {{ userDetails.region }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Street Address
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.street',
                    name='street',
                    validations='required'
                  )
                div(v-else) {{ userDetails.street }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Street Address 2
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.street2',
                    name='street2',
                    validations='required'
                  )
                div(v-else) {{ userDetails.street }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | City
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.city',
                    name='city',
                    :validation="[['required'],['matches', userCityValidityPattern]]"
                    :validationMessages="{matches: 'City cannot contain numbers.'}"
                  )
                div(v-else) {{ userDetails.city }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | State
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.state',
                    name='state',
                    validations='required'
                  )
                div(v-else) {{ userDetails.state }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Zip
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.zip',
                    name='zip',
                    validations='required'
                  )
                div(v-else) {{ userDetails.zip }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Occupation
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.occupation',
                    name='occupation',
                    validations='required'
                  )
                div(v-else) {{ userDetails.occupation }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Employer
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.businessEmployer',
                    name='businessEmployer',
                    validations='required'
                  )
                div(v-else) {{ userDetails.businessEmployer }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Home Phone
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.homePhone',
                    name='homePhone',
                    validations='required'
                  )
                div(v-else) {{ userDetails.homePhone }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Primary Phone
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.primaryPhone',
                    name='primaryPhone',
                    validations='required'
                  )
                div(v-else) {{ userDetails.primaryPhone }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Text message notifications
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    type="checkbox"
                    v-model='userDetails.SMSEnabled',
                    name='SMSEnabled',
                    :label="smsBlurb"
                    :onValue="1"
                    :offValue="0"
                  )
                div(v-else)
                  div {{ smsBlurb }}
                  div -- {{ userDetails.SMSEnabled ? "Yes" : "No" }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Work Phone
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.workPhone',
                    name='workPhone',
                    validations='required'
                  )
                div(v-else) {{ userDetails.workPhone }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Work Extension
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.workPhoneExt',
                    name='workPhoneExt',
                    validations='required'
                  )
                div(v-else) {{ userDetails.workPhoneExt }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Business/Work Zip
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    inner-class='bg-white',
                    v-model='userDetails.workZip',
                    name='workZip',
                    validations='required'
                  )
                div(v-else) {{ userDetails.workZip }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Opt-Out
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    input-class='bg-white'
                    v-model='userDetails.isActive',
                    name='optOut',
                    type='checkbox'
                    :label="`I wish to opt-out of receiving email from ${regionName}`"
                    :on-value="0"
                    :off-Value="1"
                  )
                div(v-else) {{ userDetails.isActive ? 'No' : 'Yes' }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Private profile
              CommonFormBodyCell
                div(v-if="canEdit")
                  FormKit(
                    input-class='bg-white'
                    v-model='userDetails.privateProfile',
                    name='privateProfile',
                    type='checkbox'
                    label="Other users in this user's families won't have access to this user's profile information."
                    :on-value="1"
                    :off-Value="0"
                  )
                div(v-else) {{ userDetails.privateProfile ? 'Private' : 'Not private' }}
              SmallLayoutRowDivider

              CommonFormLabelCell
                | Last Login
              CommonFormBodyCell
                div {{ userDetails.lastLogin }}
              SmallLayoutRowDivider

              CommonFormLabelCell(v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []})")
                | League Comment (Permanent)
              CommonFormBodyCell(v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []})")
                div(v-if="canEdit")
                  FormKit(
                    type="textarea"
                    inner-class='bg-white'
                    v-model='userDetails.permLeagueComment',
                    name='permLeagueComment',
                    validations='required'
                  )
                div(v-else) {{ userDetails.permLeagueComment }}
              SmallLayoutRowDivider

              CommonFormLabelCell(v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []})")
                | Volunteer Queue Override
              CommonFormBodyCell(v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []})")
                div(v-if="userDetails.bgCheckAllowedUniversal==0") Review Annually
                div(v-if="userDetails.bgCheckAllowedUniversal==1") Always Approved
                div(v-if="userDetails.bgCheckAllowedUniversal==2") Never Approved

              //-
              //- cellgroup
              //-
              template(v-if="coachPhotoUploadButton.shouldDisplayCell")
                //- border here for preceding item if we are present
                SmallLayoutRowDivider

                CommonFormLabelCell
                  | Coach photo upload
                CommonFormBodyCell
                  template(v-if="coachPhotoUploadButton.shouldOfferUpload")
                    t-btn(
                      :margin="false" data-test="coachPhotoUploadButton"
                      @click="() => coachPhotoUploadModalController.open(coachPhotoUploadButton.userID)"
                    ) Upload coach photo
                  template(v-else)
                    div(class="text-xs") {{ coachPhotoUploadButton.whyNoUpload }}

  .w-full.flex.justify-center.mt-4
    t-btn(
      :margin="false"
      type='button',
      label='Save Parent Record',
      @click='doSaveParent',
      v-if="authUser(userData, ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []}) || isUserRelated({userID: userDetails.ID, userList: userData.belongingUserIDs}) || userDetails.ID===userData.userID"
    )
</template>


<script lang="tsx">
import ImpersonateButton from 'src/components/Admin/ImpersonateUsers/ImpersonateButton.vue'
import { computed, ref, onMounted, reactive, watch, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import type { Ref } from 'vue'

import type
{ UserData } from 'src/interfaces/Store/user'
import { useRoute } from 'vue-router'
import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import authUser from 'src/helpers/authUser'
import { datePickerFormat, dayJSDate } from 'src/helpers/formatDate'
import familyAuth from 'src/composables/familyAuth'
import type { User_Privileged, User_Standard } from 'src/interfaces/InleagueApiV1'
import { assertNonNull, useIziToast, __FIXME__collapseLeagueCommentPropertyToString, useWindowSize, TailwindBreakpoint, UiOption } from 'src/helpers/utils';
import authService from 'src/helpers/authService'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"

import C_ResetAysoDataModal from "./ResetAysoDataModal.vue" // component
import * as M_ResetAysoDataModal from "./ResetAysoDataModal.ilx" // module

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from 'src/interfaces/InleagueApiV1'
import { defaultCoachPhotoUploadModalController } from 'src/components/UserInterface/Modal.photoUpload.coach'
import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import { AutoModal } from 'src/components/UserInterface/Modal'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { userCityValidityPattern } from '../Common'
import { Public } from 'src/store/Public'

const CommonFormLabelCell = defineComponent({
  setup(_, {slots}) {
    return () => <div class="px-2 pb-2 text-sm font-medium text-gray-900 lg:text-right lg:px-6 lg:py-4">{slots.default?.()}</div>
  }
})

const CommonFormBodyCell = defineComponent({
  setup(_, {slots}) {
    return () => <div class="px-2 pb-2 text-sm text-gray-500 lg:col-span-2 lg:bg-stone-200 lg:px-6 lg:py-4">{slots.default?.()}</div>
  }
})

const SmallLayoutRowDivider = defineComponent({
  setup() {
    return () => <div class="lg:hidden border-b my-1" style="grid-column:1/-1;"></div>
  }
})

export default defineComponent({
  components: {
    ImpersonateButton,
    AutoModal,
    C_ResetAysoDataModal,
    CommonFormLabelCell,
    CommonFormBodyCell,
    SmallLayoutRowDivider,
  },
  setup() {
    /** TODO: do we really have an admin view here? */
    const userDetails = ref({}) as Ref<User_Standard | User_Privileged>
    const regionOptions = ref<UiOption[]>([])
    const canEdit = ref(false)
    const { isUserRelated } = familyAuth()

    const $toast = useIziToast();
    const ready = ref(false);

    const route = useRoute()

    const router = useRouter();

    // fixme: this should be a prop
    const userID = route.params.userID as string;
    const SHOULD_BE_PROPS_userID = userID;

    const loggedInUserID = User.value.userID;

    const regionName = Client.value.instanceConfig.shortname || "inLeague";
    const smsBlurb = `I permit ${regionName} to send weather and game-related updates to my mobile phone via text message (SMS). Standard rates may apply.`;

    const userRoles = computed(()=> { return (User.value.userData as UserData).roles})

    const userData = computed(()=> {
      return User.value.userData
    })

    const getParentDetails = async () : Promise<User_Standard | User_Privileged | /*failure case, likely 403 or private-profile result / no permission*/ undefined> => {
      const expandables = authUser((userData.value as UserData), ['Registrar', 'Webmaster', 'volunteerAdmin', 'Director'], {isVolunteerAdmin: []})
        ? ["permLeagueComment"] as const
        : undefined

      try {
        const user = await ilapi.getUser(axiosInstance, route.params.userID as string, expandables)
        if (user.objectType === "private-profile") {
          // no usable info
          return undefined;
        }
        else {
          return mungePartialUserResponseInPlace(user);
        }
      } catch (error) {
        // n.b. 403
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
      return undefined;
    }

    const doSaveParent = async () : Promise<void>  => {
      try {
        await saveParentRecord()
        await maybeSaveParentNameDobIfNotStackSportsLocked()
        $toast.success({message: `Parent record has been succesfully saved.`})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }

      const refreshedUserData = await getParentDetails();

      if (refreshedUserData) {
        userDetails.value = refreshedUserData;
      }
      else {
        //
        // no-op, nothing we can do.
        // But, this is very unlikely, we should only be able to get to a place where we can call the current function
        // if `getParentDetails` returned data the first time around on component mount; and there's no reason it
        // shouldn't return data the second (or third, or ...) time for the same requesting user.
        // (one unlikely case might be `load page -> ok -> target user is marked private by some request elsewhere in the world -> try save -> oops user was marked private between load and save`)
        //
      }

      return;

      async function saveParentRecord() : Promise<void> {
        await ilapi.updateUser(axiosInstance, {userID: userDetails.value.ID, toUpdate: userDetails.value});
      }

      async function maybeSaveParentNameDobIfNotStackSportsLocked() : Promise<void> {
        // is "stackSportsLocked" if they have a stackRecordKey
        const isStackSportsLocked = !!userDetails.value.stackRecordKey
        if (isStackSportsLocked) {
          // cannot update these fields if we're "stackSportsLocked"
          return;
        }

        const options = {
          firstName : userDetails.value.firstName,
          middleName: userDetails.value.middleName,
          lastName: userDetails.value.lastName,
          nickname: userDetails.value.nickName,
          dob: userDetails.value.dob,
        }

        await axiosInstance.put(`/v1/user/${userDetails.value.ID}/nameAndDob`, options)
      }
    }

    const toFamilyProfile = async () => {
      const userID = userDetails.value.ID;
      if (!userID) {
        // maybe not initialized yet
        return;
      }

      await router.push(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.user, userID: userID}));
    }

    const resetAysoDataModalController = ref(ResetAysoDataModalController());

    const needsCoachPhotoInfo = ref<ilvolunteer.NeedsCoachPhotoInfo[]>([]);
    const coachPhotoUploadButton = computed<
      | {shouldDisplayCell: false}
      | {shouldDisplayCell: true, shouldOfferUpload: true, userID: iltypes.Guid} // userID is a bit redundant here, but lets us depend less on route
      | {shouldDisplayCell: true, shouldOfferUpload: false, whyNoUpload: string}
    >(() => {
      const infoForUser = needsCoachPhotoInfo.value.find(v => v.userID === SHOULD_BE_PROPS_userID)
      if (!infoForUser) {
        // shouldn't happen
        return {
          shouldDisplayCell: false,
        }
      }

      if (!infoForUser.hasStackRecordKey) {
        return {shouldDisplayCell: false}
      }

      if (infoForUser.isPhotoLocked) {
        return {shouldDisplayCell: true, shouldOfferUpload: false, whyNoUpload: "Photo is locked"}
      }

      if (infoForUser.hasAtLeastOneCoachAssignmentForCompRequiringPhoto) {
        return {shouldDisplayCell: true, shouldOfferUpload: true, userID: infoForUser.userID}
      }

      return {
        shouldDisplayCell: true, shouldOfferUpload: false, whyNoUpload: "This user has no coach assignments requiring a photo."
      }
    })

    const coachPhotoUploadModalController = reactive(
      defaultCoachPhotoUploadModalController(
        axiosAuthBackgroundInstance,
        (userID) => {
          $toast.success({message: "Photo uploaded"})
          // on success, simulate receiving an update from the backend that this user's photo status is now locked
          const localUpdateable = needsCoachPhotoInfo.value.find(_ => _.userID === userID);
          if (localUpdateable) {
            localUpdateable.isPhotoLocked = true;
          }
        }
      ));

    watch(() => route.params.userID, async () => {
      const userID = route.params.userID;
      if (typeof userID === "string") {
        try {
          needsCoachPhotoInfo.value = await ilvolunteer.getWhoNeedsCoachPhotos(freshNoToastLoggedInAxiosInstance(), {userIDs: [userID], seasonUID: "ALL"})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
      else {
        // shouldn't happen
      }
    }, {immediate: true})

    onMounted(async ()=> {
      resetAysoDataModalController.value = ResetAysoDataModalController({
        handlers: {
          closeModalPerformingNoAction: () => {
            resetAysoDataModalController.value.hide();
          },
          doClearAysoInfo: async ({userID, userName}) => {
            // we have to canonicalize the route param userID, it's mostly always uppercase but this route is not guarded on that
            // the userID param should already be canonicalized
            if (userID !== (route.params.userID as string).toUpperCase()) {
              // Shouldn't ever happen;
              // But, this verifies (or rather, proves incorrect) our assumption that
              // the route userID never changes for a single lifecycle of this component.
              // What we pushed into the modal should be returned to us, and the route
              // userID should not have changed in between time.
              $toast.error({message: `Sorry, something went wrong.`})
              return;
            }
            else {
              try {
                const response = await ilapi.resetVolunteerAysoInfo(axiosInstance, {userID});
                userDetails.value = {...userDetails.value, ...mungePartialUserResponseInPlace(response.mutated)};
                $toast.success({message: `AYSO info for ${userName} reset.`})
                resetAysoDataModalController.value.hide();
              }
              catch (error) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(error);
              }
            }
          }
        }
      })

      const maybeUserData = await getParentDetails();
      if (!maybeUserData) {
        // nothing we can do, either no permissions or insufficient permissions to do anything here
        await router.replace({path: "/"});
        return;
      }

      userDetails.value = maybeUserData;
      regionOptions.value = Public.buildStandardRegionOptions(await Public.getMasterRegionList())

      //TODO: ADD competition managers with the volunteerAdmin sub-role
      canEdit.value = authUser(userData.value as UserData, ['Registrar', 'Webmaster', 'volunteerAdmin'], {isVolunteerAdmin: []})
        || isUserRelated({userID: userDetails.value.ID, userList: (userData.value as UserData).belongingUserIDs})
        || userDetails.value.ID === (userData.value as UserData).userID
      ready.value = true;
    })

    const windowSize = useWindowSize();
    const useMobileLayout = computed(() => windowSize.width <= TailwindBreakpoint.lg);

    return {
      authService,
      authUser,
      userDetails,
      regionOptions,
      canEdit,
      isUserRelated,
      ready,
      userID,
      loggedInUserID,
      regionName,
      smsBlurb,
      userRoles,
      userData,
      doSaveParent,
      toFamilyProfile,
      resetAysoDataModalController,
      coachPhotoUploadButton,
      coachPhotoUploadModalController,
      dayJSDate,
      useMobileLayout,
      userCityValidityPattern,
    }
  }
})

interface Controller<Props, Handlers> {
  readonly visible: boolean,
  readonly show: (userID: iltypes.Guid, userName: string) => void,
  readonly hide: () => void,
  // must be non-null when visible=true
  readonly props: Props | null,
  readonly handlers: Handlers | null
}

function ResetAysoDataModalController(args?: {
  handlers: M_ResetAysoDataModal.Emits
}) : Controller<M_ResetAysoDataModal.Props, M_ResetAysoDataModal.Emits> {
  if (!args) {
    // "default" init, does nothing, but safe to interact with
    return reactive({
      visible: computed(() => false),
      show: () => void 0,
      hide: () => void 0,
      props: null,
      handlers: null,
    })
  }

  const private_ = {
    visible: ref(false),
    props: ref<M_ResetAysoDataModal.Props | null>(null)
  }

  const show = (userID: iltypes.Guid, userName: string) => {
    assertNonNull(args, "args must be defined here");

    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    private_.props.value = {userID, userName};
    private_.visible.value = true;
  }

  const hide = () => {
    private_.props.value = null;
    private_.visible.value = false;
  }

  return reactive({
    visible: computed(() => private_.visible.value),
    show,
    hide,
    // the following must not be accessed when visible is false
    // calling `show` must update these to appropriate values for passing to the target component
    props: computed(() => private_.props.value),
    handlers: args.handlers,
  })
}

/**
 * Transform a User api response into the application centric object we need
 *
 * Accepts a Partial<User>, and if a key that would normally be munged is not present, does nothing and
 * notably DOES NOT ADD the property to the object.
 *
 * As a convenience, returns a reference to the passed in object,
 * but do note that this mutates the passed in object (if there are mungable properties present)
 */
function mungePartialUserResponseInPlace<T extends Partial<User_Standard | User_Privileged>>(v: T) : T {
  if (v.permLeagueComment !== undefined) {
    v.permLeagueComment = __FIXME__collapseLeagueCommentPropertyToString(v.permLeagueComment);
  }
  if (v.dob !== undefined) {
    v.dob = datePickerFormat(v.dob);
  }
  return v;
}
</script>
