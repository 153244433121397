<template lang="pug">
button.inline-flex.rounded-md.shadow-sm(
  :type="type || undefined"
  :class='[forceClasses ?? "", margin ? "mr-1 mb-1" : "", selected ? selectedColor : notSelectedColor, width, colorClasses, tBtnClasses, inputClass]',
  :disabled='disable'
  v-on:click='$emit("click", $event)'
)
  span.text-center.w-full(v-if='label') {{ label }}
  slot.flex.flex-col
</template>

<script lang="ts">
import { defineComponent, ref, computed, type PropType } from 'vue'

import { System } from 'src/store/System'

/**
 * @deprecated prefer `Btn2`
 */
export default defineComponent({
  name: 'TBtn',
  components: {},
  emits: ['click'],
  props: {
    label: String,
    onClick: Function,
    disable: Boolean,
    width: {
      type: String,
      default: 'w-auto',
    },
    margin: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'green',
    },
    type: {
      // specify type=button, or HTML buttons will by default bind to the nearest (preceding?) form element, and submit it on click
      type: String as PropType<"button" | "submit" | "reset" | undefined>,
    },
    modelValue: {
      type: String,
    },
    inputClass: {
      type: String,
    },
    /**
     * we'll just keep bolting stuff on,
     * this is intended to override `tBtnClasses`.
     * When not provided, it's the same as passing `""` (so all the "default" tBtnClasses will be used)
     *
     * n.b. You probably want to 'force' each class provided here, e.g. "!px-N" instead of "px-N".
     */
    forceClasses: {
      required: false,
      type: String
    }
  },
  setup(props) {
    const content = ref(props.label)

    const tBtnClasses = computed(() => {
      return System.value.tBtnClasses
    })

    const SHOULD_NOT_USE_DYNAMIC_TAILWIND_CLASSES_TAILWIND_WILL_NOT_RECOGNIZE_THEM_PUT_LITERALS_HERE_AS_A_TEMPORARY_WORKAROUND = (..._s: any[]) => {
      /* do nothing, caller put literal values in args, see use sites for examples, this should be removed eventually */
      _s;
    }

    const colorClasses = computed(() => {
      SHOULD_NOT_USE_DYNAMIC_TAILWIND_CLASSES_TAILWIND_WILL_NOT_RECOGNIZE_THEM_PUT_LITERALS_HERE_AS_A_TEMPORARY_WORKAROUND(
        `hover:bg-red-700 focus:border-red-700 focus:ring-red active:bg-red-700`,
        `hover:bg-green-700 focus:border-green-700 focus:ring-green active:bg-green-700`
      );
      return props.disable ? "" : `hover:bg-${props.color}-700 focus:border-${props.color}-700 focus:ring-${props.color} active:bg-${props.color}-700`;
    })

    const selectedColor = computed(() => {
      SHOULD_NOT_USE_DYNAMIC_TAILWIND_CLASSES_TAILWIND_WILL_NOT_RECOGNIZE_THEM_PUT_LITERALS_HERE_AS_A_TEMPORARY_WORKAROUND(
        `bg-red-800`,
        `bg-green-300`
      );
      return props.disable ? "" : `bg-${props.color}-800`
    })

    const notSelectedColor = computed(() => {
      SHOULD_NOT_USE_DYNAMIC_TAILWIND_CLASSES_TAILWIND_WILL_NOT_RECOGNIZE_THEM_PUT_LITERALS_HERE_AS_A_TEMPORARY_WORKAROUND(
        `bg-red-300`,
        `bg-green-300`
      );
      return props.disable ? "" : `bg-${props.color}-300`
    })

    return {
      content,
      tBtnClasses,
      selectedColor,
      notSelectedColor,
      colorClasses,
    }
  },
})
</script>

