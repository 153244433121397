import { ComputedRef, TransitionProps, computed, defineComponent, ref } from "vue";
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside"
import { useWindowEventListener, vOptT, vReqT } from "src/helpers/utils";

export type PaneController = {
  open: () => void,
  close: () => void,
  toggle: () => void,
  isOpen: ComputedRef<boolean>,
  defaultTransitionProps: TransitionProps
}

export const Pane = defineComponent({
  props: {
    element: vReqT<(_: PaneController) => JSX.Element | null>(),
    onClose: vOptT<() => void>(),
  },
  directives: {
    //
    // This (luckily, but maybe not guaranteed?) is invokable/applyable from within the elements generated by `props.element`
    // e.g. something like `() => <div v-onClickOutside>...</div>` from the caller is fine, without them having to
    // import onClickOutside.
    // edit: This is available to `props.element()` because props.element is "just a function rendered in this context".
    // If using slots, v-onClickOutside is NOT made available. Unfortunately, the inverse situation exists when using slots -
    // that is, we have the following:
    // pane-content-rendering-method | pane-parent-directive | pane-directive
    // props JSX returning func      | inaccessible          | accessible
    // slot                          | accessible            | inaccessible
    //
    onClickOutside: vueDirective_ilOnClickOutside
  },
  setup(props) {
    const isVisible = ref(false);

    const controller : PaneController = {
      open: () => { isVisible.value = true },
      close: () => {
        isVisible.value = false
        props.onClose?.()
      },
      toggle: () => {
        isVisible.value = !isVisible.value
        if (!isVisible.value) {
          props.onClose?.()
        }
      },
      // can we use a geter here? You can't destructure that, right (which probalby means no we don't want to use a getter)?
      isOpen: computed(() => isVisible.value),
      defaultTransitionProps: {
        enterActiveClass: "transition duration-100 ease-[cubic-bezier(0.000, 0.5, 0.000, 1.000)]",
        enterFromClass: "translate-y-2 opacity-0",
        enterToClass: "translate-y-0 opacity-100",
        leaveActiveClass: "transition duration-100 ease-[cubic-bezier(1.000, 0.0, 1.000, 0.5)]",
        leaveFromClass: "translate-y-0 opacity-100",
        leaveToClass: "translate-y-2 opacity-0",
      }
    }

    // good enough for now, but this will apply to "all panes" if there are more than one being rendered at a time
    useWindowEventListener("keyup", evt => {
      if (evt.key === "Escape") {
        controller.close()
      }
    })

    return () => props.element(controller)
  }
})
