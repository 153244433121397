import { FormKit } from "@formkit/vue";
import { vReqT, UiOption, FormKitValidationRule } from "src/helpers/utils";
import { defineComponent, Ref } from "vue";
import { RouterLink } from "vue-router";
import { MAXLEN_DISPLAYNAME, MAXLEN_DIVISION, MaybeDivIdOrNew } from "./DivisionEditor.shared";
import { DivisionCreateForm, DivisionUpdateForm } from "src/composables/InleagueApiV1.Division";

export const DivisionEditorElement = defineComponent({
  props: {
    divisionOptions: vReqT<UiOption<MaybeDivIdOrNew>[]>(),
    mut_selectedDivID: vReqT<Ref<MaybeDivIdOrNew>>(),
    mut_divisionFormData: vReqT<DivisionCreateForm | DivisionUpdateForm | null>(),
    doSubmit: vReqT<() => void>(),
  },
  setup(props) {
    return () => {
      return (
        <div data-test="DivisionEditorElement">
          <h2>Master Division List</h2>
          <p>This utility governs the name (display name and internal name), age cutoff, default game length, and default gender of all possible divisions. To configure program-specific division caps, waitlist status, or co-ed settings, visit Division Settings under <RouterLink class="text-blue-700 cursor-pointer underline" to="/admin-competition">Program Administration</RouterLink>.</p>
        <br />
           <p>To add a new division, contact <a href="mailto:support@inleague.io">inLeague Support</a>.</p>
          <div class="m-auto flex justify-center my-4">
            <div style="width: var(--fk-max-width-input)">
              <FormKit type="select" options={props.divisionOptions} v-model={props.mut_selectedDivID.value} label="Division" data-test="division"/>
            </div>
          </div>
          {
            props.mut_divisionFormData
              ? (
                <div class="bg-white rounded-md shadow-md">
                  <h2 class="bg-gray-200 p-2 rounded-t-md">
                    {
                      props.mut_divisionFormData.type === "create"
                        ? `Create New Division`
                        : `Edit Division \u2013 ${props.mut_divisionFormData.prisitine.division}`
                    }
                  </h2>
                  <div class="p-4" key={props.mut_selectedDivID.value} data-test={`form/${props.mut_selectedDivID.value}`}>
                    <DivisionCreateOrUpdateForm mut_form={props.mut_divisionFormData} doSubmit={props.doSubmit}/>
                  </div>
                </div>
              )
              : null
          }
        </div>
      )
    }
  }
})

const DivisionCreateOrUpdateForm = defineComponent({
  props: {
    mut_form: vReqT<DivisionCreateForm | DivisionUpdateForm>(),
    doSubmit: vReqT<() => void>(),
  },
  setup(props) {
    const genderOptions = () : UiOption[] => {
      return [
        {label: "", value: ""},
        {label: "Male", value: "B"},
        {label: "Female", value: "G"},
      ]
    }

    const displayNameLenRule : FormKitValidationRule[] = [["length", 1, MAXLEN_DISPLAYNAME]]
    const divisionLenRule : FormKitValidationRule[] = [["length", 1, MAXLEN_DIVISION]]

    const DisplayName = defineComponent(() => () => {
      return <div>
        <FormKit type="text"
          v-model={props.mut_form.displayName}
          label="Display Name"
          validation={[["required"], ...displayNameLenRule]}
          data-test="displayName"
        />
      </div>
    })

    const SlotLength = defineComponent(() => () => {
      return <div class="grid gap-2" style="grid-template-rows: min-content 1fr; grid-template-cols: 1fr 1fr; grid-auto-flow: column; max-width: var(--fk-max-width-input);">
        <div class="text-sm font-medium">Game slot hours</div>
        <div>
          <FormKit type="number"
            v-model={props.mut_form.slotHours}
            validation={[["required"], ["min", 0]]}
            name="Slot hours"
            data-test="slotHours"
          />
        </div>
        <div class="text-sm font-medium">Game slot minutes</div>
        <div>
          <FormKit type="number"
            v-model={props.mut_form.slotMinutes}
            validation={[["required"], ["min", 0]]}
            name="Slot minutes"
            data-test="slotMinutes"
          />
        </div>
      </div>;
    });

    const DivisionName = defineComponent(() => () => {
      if (props.mut_form.permissionsType === "inleague") {
        return <div>
          <FormKit type="text"
            v-model={props.mut_form.division}
            label="Division Name"
            validation={[["required"]]}
            data-test="division"
          />
        </div>
      }
      else {
        return <div>
          <div class="text-sm font-medium">Division Name:</div>
          <div>{props.mut_form.prisitine.division}</div>
        </div>
      }
    })

    const Gender = defineComponent(() => () => {
      if (props.mut_form.permissionsType === "inleague") {
        return <div>
          <FormKit type="select"
            v-model={props.mut_form.gender}
            label="Gender"
            options={genderOptions()}
            validation={[["required"], ...divisionLenRule]}
            data-test="gender"
          />
        </div>
      }
      else {
        return <div>
          <div class="text-sm font-medium">Gender:</div>
          <div>{props.mut_form.prisitine.gender}</div>
        </div>
      }
    })

    const Age = defineComponent(() => () => {
      if (props.mut_form.permissionsType === "inleague") {
        return <div>
          <FormKit type="number"
            v-model={props.mut_form.divNum}
            label="Age"
            validation={[["required"], ["min", 0]]}
            data-test="divNum"
          />
        </div>
      }
      else {
        return <div>
          <div class="text-sm font-medium">Age:</div>
          <div>{props.mut_form.prisitine.divNum}</div>
        </div>
      }
    });

    const Submit = defineComponent(() => () => {
      return <t-btn type="submit" margin={false} data-test="submit">Submit changes</t-btn>
    });

    return () => {
      if (props.mut_form.permissionsType === "inleague") {
        return (
          <div style="--fk-margin-outer:none;">
            <FormKit type="form" actions={false} onSubmit={props.doSubmit}>
              <DisplayName class="mb-2"/>
              <DivisionName class="mb-2"/>
              <SlotLength class="mb-2"/>
              <Gender class="mb-2"/>
              <Age class="mb-2"/>
              <Submit/>
            </FormKit>
          </div>
        )
      }
      else {
        return (
          <div style="--fk-margin-outer:none;">
            <FormKit type="form" actions={false} onSubmit={props.doSubmit}>
              <DisplayName class="mb-2"/>
              <SlotLength class="mb-2"/>
              {/*all subsequent items will be readonly*/}
              <DivisionName class="mb-2"/>
              <Gender class="mb-2"/>
              <Age class="mb-2"/>
              <Submit/>
            </FormKit>
          </div>
        )
      }
    }
  }
})
