<template lang="pug">
TDialog
  .text-xl Reset AYSO Data
  div.divide-y.font-normal
    div.p-2.text-left
      | This action will wipe {{ userName }}'s AYSO ID and the link to their record on Affinity.
      |  This should only be done in the event that a duplicate user exists in Affinity whose name or DOB does not match this record, e.g.
      pre.mt-4
        | inLeague record: Bob
        | Affinity record: Robert (correct)
    div.p-2.text-left
      | Once reset, you can change the name or DOB on this record, and then view their family profile to re-connect the record to Affinity.
    div.p-2.text-left.font-bold
      | Do not reset a volunteer record just to change a user's name or DOB if you are not certain there is a duplicate in Affinity.
    div.p-2.text-left
      | Name and DOB changes for regular, non-duplicated records must be done in Affinity.
    div.flex.justify-between.items-start.pt-4
      TBtn(type="button" @click="closeModalPerformingNoAction()" color="red")
        div.flex.flex-col.text-left.items-start(slot)
          div Cancel
          div Do not reset this
      TBtn(type="button" @click="doClearAysoInfo()" color="green")
        div.flex.flex-col.text-right.items-end(slot)
          div I understand
          div Reset this record
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { propsDef, emitsDef } from "./ResetAysoDataModal.ilx";

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, { emit }) {
    const closeModalPerformingNoAction = (): void => {
      emit("closeModalPerformingNoAction");
    };

    const doClearAysoInfo = (): void => {
      // it is not expected that props changes on this instance of this component for a single mount lifecycle
      emit("doClearAysoInfo", {userID: props.userID, userName: props.userName});
    }

    return {
      closeModalPerformingNoAction,
      doClearAysoInfo,
    }
  }
})
</script>
