<template lang="pug">
.modal-wrap.z-50.fixed.bottom-0.inset-x-0.pb-6.inset-0.p-0.flex.items-center.justify-center
  .fixed.inset-0.transition-opacity
    .absolute.inset-0.bg-gray-600.opacity-75
  .bg-white.pb-4.container.shadow-xl.transform.transition-all.absolute.flex.flex-col.max-w-sm.rounded-lg.align-center(
    data-cy='processing',
    class='sm:pb-0 md:pb-4 md:overflow-hidden md:bottom-auto md:left-auto md:h-auto sm:flex-row md:flex-col',
    role='dialog',
    aria-modal='true',
    aria-labelledby='modal-headline',
    v-if='fullMessage'
  )
    h3.text-xl.self-end.font-medium.text-gray-600.italic.tracking-wide.w-full.h-full.pt-4.pb-2.pr-5.pl-5(
      class='pl-1/5 lg:pl-1/4'
    ) {{ fullMessage }}
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, Ref } from 'vue'

import { System } from 'src/store/System'

export default defineComponent({
  name: 'Progress',
  setup() {
    const message = ref(0)
    const messageList = ref([]) as Ref<string[]>
    const fullMessage = ref('')
    const messageGroup = ref([]) as Ref<string[]>
    const paymentMessage = [
      'Payment processing   ',
      ' Payment processing.  ',
      ' Payment processing.. ',
      ' Payment processing...',
    ]
    const refundMessage = [
      'Refund processing   ',
      ' Refund processing.  ',
      ' Refund processing.. ',
      ' Refund processing...',
    ]


    const processing = computed(() => {
      return (
        System.value.paymentProcessing ||
        System.value.refundProcessing
      )
    })

    const startProgress = () => {
      // console.log('startProgress')
      if (System.value.paymentProcessing) {
        messageGroup.value = paymentMessage
      } else if (System.value.refundProcessing) {
        messageGroup.value = refundMessage
      }
      setTimeout(() => {
        // console.log('timeout')
        if (message.value === 3) {
          message.value = 0
        } else {
          message.value++
        }
        fullMessage.value = messageGroup.value[message.value]
        if (processing.value) {
          startProgress()
        }
        // console.log('after if statement')
      }, 750)
      // console.log('after timeout')
    }

    onMounted(() => {
      startProgress()
    })

    return { messageList, fullMessage }
  },
})
</script>
