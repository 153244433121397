<template lang="pug">
div(data-cy='newMessagePage')
  FormKit(class='sm:mx-auto sm:w-full sm:max-w-md', type='form')
    h1.mx-2.text-2xl(class='md:text-4xl')
      font-awesome-icon(:icon='["fas", "edit"]')
      span.ml-2 New Message
    FormKit.m-2(
      v-model="menu.state.selected.value.competitionUID"
      :options='menu.state.options.competitions.value',
      type='select',
      label='Competition',
      placeholder='Select a Competition',
      data-test='competition-selector'
    )
    FormKit.m-2(
      ref='div',
      v-model='menu.state.selected.value.divID',
      :options='menu.state.options.divisions.value',
      type='select',
      placeholder='Select a Division',
      label='Division',
      :disabled='!menu.state.selected.value.competitionUID',
      data-test='division-selector'
    )
    FormKit.m-2(
      v-model='menu.state.selected.value.teamID',
      :options='menu.state.options.teams.value',
      type='select',
      placeholder='Select a Team',
      label='Team',
      :disabled='!menu.state.selected.value.divID',
      data-test='team-selector'
    )
    div(v-if='menu.state.selected.value.teamID')
      p.text-l.font-medium.mx-2 Recipient(s)
      .flex.justify-around
        .flex.flex-col
          FormKit(
            type='button',
            label='Everyone',
            value='Everyone',
            @click='selectRecipients("Everyone")',
            data-cy='selectEveryone',
            :input-class='messageTo === "Everyone" || messageTo === "" ? " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-800 w-32 min-w-full" : " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-300 w-32 min-w-full"'
          )
          FormKit(
            type='button',
            label='Parents',
            value='Parents',
            @click='selectRecipients("Parents")',
            :input-class='messageTo === "Parents" || messageTo === "" ? " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-800 w-32 min-w-full" : " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-300 w-32 min-w-full"'
          )
          FormKit(
            type='button',
            label='Staff',
            value='Staff',
            @click='selectRecipients("Staff")',
            :input-class='messageTo === "Staff" || messageTo === "" ? " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-800 w-32 min-w-full" : " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-300 w-32 min-w-full"'
          )
          FormKit.mb-2(
            type='button',
            label='Individuals',
            value='Individuals',
            @click='selectRecipients("Individuals", true)',
            :input-class='messageTo === "Individuals" || messageTo === "" ? "px-2 py-2 rounded text-white hover:bg-green-700 bg-green-800 w-32 min-w-full" : " px-2 py-2 rounded text-white hover:bg-green-700 bg-green-300 w-32 min-w-full"'
          )
        .overflow-y-auto.border.pt-10.mt-2.max-h-64.rounded-lg(
          class='w-1/2',
          @click='selectRecipients("Individuals")'
        )
          FormKit(
            v-model='recipientIDs',
            :options='availableRecipients',
            type='checkbox',
            fieldset-class="$reset tailwindcss classes",
            wrapper-class='ml-5'

          )
.bg-gray-700.w-full.rounded-lg
  .p-3.text-gray-600.flex.flex-col.items-center
    input.form-input.w-full.m-2.text-sm.overflow-hidden.align-center.p-1.rounded-sm(
      placeholder='Subject (optional)',
      data-cy='topic',
      v-model='topic'
    )
    textarea.w-full.m-2.text-sm.overflow-hidden.align-center.p-1.rounded-sm.resize-y(
      placeholder='Type a message',
      data-cy='messageTextArea',
      v-model='newMessage',
      @keyup.enter='sendMessage'
    )
    TBtn.w-full(@click='sendMessage', label='Send')
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { createNameSelect } from 'src/helpers/schemaService'
import { defineComponent, ref, Ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import * as ilapi from "src/composables/InleagueApiV1"
import * as TeamChooser from "src/components/Selectors/TeamChooser.ilx"
import { Guid, Integerlike } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  name: 'NewMessage',
  setup() {
    // Data
    const $router = useRouter()
    const messageTo = ref('')

    const parents = ref({})
    const staff = ref({})
    const allRecipients = ref({}) as Ref<{ [key: string]: string }>

    const recipientIDs = ref([]) as Ref<string[]>
    const selectedRecipients = ref({})
    const availableRecipients = ref({})
    const topic = ref('')
    const newMessage = ref('')

    const menu = ref(TeamChooser.DefaultSelectionManager({}));

    const sendMessage = async () => {
      try {
        const options = {
          message: newMessage.value,
          recipientIDs: recipientIDs.value,
          topic: topic.value,
        }
        const response = await axiosInstance.post(
          'v1/teamMessage/conversation',
          options
        )
        await $router.push({
          name: 'message-thread',
          params: { id: response.data.data.conversationID },
        })
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const getRecipients = async (seasonID: Integerlike, teamID: Guid) => {
      const response = await axiosInstance.post(
        'v1/teamMessage/availableRecipients',
        { seasonID: seasonID, teamUID: teamID }
      )

      parents.value = createNameSelect(
        response.data.data.parents,
        'ID',
        'firstName',
        'lastName',
        'nickname'
      )
      staff.value = createNameSelect(
        response.data.data.staff,
        'ID',
        'firstName',
        'lastName',
        'nickname'
      )
      allRecipients.value = { ...staff.value, ...parents.value }
      availableRecipients.value = allRecipients.value
    }

    watch(() => menu.value.state.selected.value.teamID, async (freshValue) => {
      if (freshValue !== "") {
        // we've selected a UID, but need the ID
        // this lookup is contractually guaranteed to succeed (all seasonUIDs in the selection options MUST be present in this bimap)
        const seasonID = menu.value.alternateKeyBiMap.season.idByUid[menu.value.state.selected.value.seasonUID];
        const teamID = menu.value.state.selected.value.teamID;
        await getRecipients(seasonID, teamID);
      }
    });

    const selectRecipients = (category: string, clear?: boolean) => {
      messageTo.value = category
      if (category === 'Individuals' && clear) {
        selectedRecipients.value = {}
      } else if (category === 'Everyone') {
        selectedRecipients.value = allRecipients.value
      } else if (category === 'Parents') {
        selectedRecipients.value = parents.value
      } else if (category === 'Staff') {
        selectedRecipients.value = staff.value
      }
    }

    watch(messageTo, () => {
      if (messageTo.value === 'Everyone') {
        recipientIDs.value = Object.keys(allRecipients.value)
      } else if (messageTo.value === 'Parents') {
        recipientIDs.value = Object.keys(parents.value)
      } else if (messageTo.value === 'Staff') {
        recipientIDs.value = Object.keys(staff.value)
      } else if (messageTo.value === 'Individuals') {
        recipientIDs.value = Object.keys(selectedRecipients.value)
      } else {
        recipientIDs.value = []
      }
    })

    onMounted(async () => {
      const menuDef = await ilapi.getAvailableTeamMessageTeamOptions(axiosInstance)
      menu.value = TeamChooser.DefaultSelectionManager(menuDef);
    })

    return {
      sendMessage,
      selectRecipients,
      messageTo,
      parents,
      staff,
      allRecipients,
      recipientIDs,
      selectedRecipients,
      availableRecipients,
      topic,
      newMessage,
      menu
    }
  },
})
</script>

<style scoped>
.scroll {
  scroll-margin-bottom: 5em;
}
</style>

