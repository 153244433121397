<template lang="pug">
label.font-medium.text-smt-label.block.mt-5.mb-2
  span(class="md:hidden") {{ labelItem }}
  span Gate Functions
  button.ml-1.cursor-pointer(
    v-tooltip='{ content: `Restrict display of registration item based upon custom or built in criteria` }'
  )
    font-awesome-icon.mr-2.text-gray-400(
      :icon='["fas", "info-circle"]'
    )
.-my-2.overflow-x-auto.max-w-4xl.mb-2(class='sm:-mx-6 lg:-mx-8')
  .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
    .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
      table.min-w-full.divide-y.divide-white
        tbody.bg-white.divide-y.divide-white
          tr.grid.grid-cols-5.bg-gray-200(v-if="item.gateFunctionName" @click="isEditingBuiltinGateFunction = !isEditingBuiltinGateFunction")
            td.px-6.py-4.text-sm.font-medium.text-green-900.cursor-pointer.col-span-1.whitespace-nowrap
              font-awesome-icon.mr-2(:icon='["fas", "pencil"]')
              | Edit
            td.px-6.py-4.text-sm.font-medium.text-gray-900.col-span-3.break-words
              | Built In Gate Function:
              span.italic.font-normal.ml-2 {{ builtInFunctions[item.gateFunctionName] }}
            td.px-6.py-4.text-sm.font-medium.text-red-700.cursor-pointer.text-center(@click.stop="deleteGateFunction")
              | X
          tr.grid.grid-cols-5.bg-gray-200(v-else @click="isEditingBuiltinGateFunction = !isEditingBuiltinGateFunction")
            td.px-6.py-4.text-sm.font-medium.text-green-900.cursor-pointer.flex.items-center.col-span-1.whitespace-nowrap
              font-awesome-icon.mr-2(:icon='["fas", "plus-square"]')
              | Add
            td.px-6.py-4.text-sm.font-medium.text-gray-900.col-span-4
              label.font-medium.text-smt-label.block Built In Gate Function
          tr.grid.col-span-5.px-4.pb-2(v-if="isEditingBuiltinGateFunction")
            .mt-2
              FormKit(
                type="radio",
                :modelValue="gateFunctionName",
                @update:modelValue="setGateFunctionName",
                :options="builtInFunctions",
                label="Select Gate Function"
              )
          tr.grid.grid-cols-5.bg-gray-200(v-if="customFieldGateFunction.customFieldID" @click="isEditingCustomFieldGateFunction = !isEditingCustomFieldGateFunction")
            td.px-6.py-4.text-sm.font-medium.text-green-900.cursor-pointer.col-span-1.whitespace-nowrap
              font-awesome-icon.mr-2(:icon='["fas", "pencil"]')
              | Edit
            td.px-6.py-4.text-sm.font-medium.text-gray-900.col-span-3.break-words
              | Custom Field Gate Function:
              span.italic.font-normal.ml-2 {{ customFieldsOptionFormat[customFieldGateFunction.customFieldID] }} {{ customFieldGateFunction.matchType === 1 ? 'equals' : 'not equal' }} {{ getTargetValue(customFieldGateFunction.targetValue) }}
            td.px-6.py-4.text-sm.font-medium.text-red-700.cursor-pointer.text-center(@click.stop="deleteCustomGateFunction")
              | X
          tr.grid.grid-cols-5.bg-gray-200(v-else @click="isEditingCustomFieldGateFunction = !isEditingCustomFieldGateFunction")
            td.px-6.py-4.text-sm.font-medium.text-green-900.cursor-pointer.col-span-1.whitespace-nowrap
              font-awesome-icon.mr-2(:icon='["fas", "plus-square"]')
              | Add
            td.px-6.py-4.text-sm.font-medium.text-gray-900.col-span-4
              label.font-medium.text-sm.t-label.block Custom Gate Function
          tr.grid.grid-cols-4(v-if="isEditingCustomFieldGateFunction")
            td.px-6.text-sm.font-medium.text-gray-900.col-span-4
              div(class="font-normal flex justify-center p-2" v-if="Object.keys(customFieldsOptionFormat).length === 0")
                | No custom fields are defined for {{ regionUiString }}
              .flex.flex-row.justify-between.mt-4(v-else)
                div.mt-2.mr-2.text-gray-700.font-normal Show this item when
                .mt-7.mr-2
                  FormKit(
                    type="select",
                    v-model="customFieldGateFunction.customFieldID",
                    :options="customFieldsOptionFormat"
                  )
                .mt-7.-mr-4
                  FormKit(
                    type="select",
                    v-model="customFieldGateFunction.matchType",
                    :options="{ 1: 'equals', 2: 'not equals' }"
                  )
                div.mt-7.ml-5(v-if="customFieldGateFunction.customFieldID && selectedCustomField")
                  FormKit(
                    v-if="selectedCustomField.inputType === 1",
                    type="text",
                    v-model="customFieldGateFunction.targetValue",
                  )
                  FormKit(
                    v-else-if="selectedCustomField.inputType === 2",
                    type="textarea",
                    v-model="customFieldGateFunction.targetValue",
                  )
                  FormKit(
                    v-else-if="selectedCustomField.inputType === 3",
                    type="number",
                    v-model="customFieldGateFunction.targetValue",
                  )
                  FormKit(
                    v-else-if="selectedCustomField.inputType >= 4",
                    type="select",
                    v-model="customFieldGateFunction.targetValue",
                    :options="options"
                  )
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { QuestionOption, PageItem_ambiguously_QuestionOrContentChunk as PageItemI, CustomField, CustomFieldInputType } from 'src/interfaces/Store/registration'
import { defineComponent, onMounted, PropType, ref, watch, Ref, computed, WatchCallback, WatchOptions } from 'vue'

import { CustomFieldGateFunction, GateFunctionMatchType, GateFunctionQuestionType } from 'src/interfaces/InleagueApiV1'
import { exhaustiveCaseGuard, assertNonNull } from 'src/helpers/utils'

import { Client } from 'src/store/Client'
import { RegistrationStore } from 'src/store/Registration'

export default defineComponent({
  name: 'GateFunctionEditor',
  props: {
    item: {
      type: Object as PropType<PageItemI | QuestionOption>,
      required: true
    },
    /**
     * This is a questionID, contentChunkID, or questionOptionID, depending on item type.
     */
    itemID: {
      type: String,
      required: true
    },
    gateQuestionType: {
      type: Number,
      required: true
    },
    gateFunction: {
      type: String,
      required: true
    },
    triggerSave: {
      type: Boolean,
      required: true
    },
    labelItem: {
      type: String,
      required: true
    }
  },
  emits: ['update:gateFunction', 'clearCustomGate', 'apiError', 'updateStatus'],
  setup(props, { emit }) {
    const isEditingBuiltinGateFunction = ref(false)
    const isEditingCustomFieldGateFunction = ref(false)
    const gateFunctionName = ref('')
    const customFieldGateFunction = ref<CustomFieldGateFunction>(/*definitely assigned in onMounted*/{} as any);
    const customFieldGateFunctionChanged = ref(false)
    const selectedCustomField = ref<CustomField | undefined>(undefined)
    const options = ref({})



    const customFieldsOptionFormat = computed(() => {
      return RegistrationStore.value.customFieldsOptionFormat
    })

    const builtInFunctions = computed(() => {
      return RegistrationStore.value.gateFunctionsOptionFormat
    })

    const getTargetValue = (val: string) => {
      if (selectedCustomField.value?.inputType === CustomFieldInputType.BOOLEAN) {
        return val == '1' ? 'Yes' : 'No'
      } else {
        return val
      }
    }

    const createCustomGateFunction = async () => {
      const options = { ...customFieldGateFunction.value }

      try {
        await customFieldGateFunctionMutationWatcher.withDisabledWatch(async () => {
          const response = await axiosInstance.post('/v1/registration/gateFunction', options)
          customFieldGateFunction.value = response.data.data
          emit('updateStatus', 'saved')
        });
      } catch (error) {
        emit('apiError')
      }
    }

    const updateCustomGateFunction = async () => {
      const options = { ...customFieldGateFunction.value }

      try {
        assertNonNull(customFieldGateFunction.value.gateFunctionID, "gateFunctionID is expected to be defined here");
        await axiosInstance.put(`/v1/registration/gateFunction/${customFieldGateFunction.value.gateFunctionID}`, options)
        emit('updateStatus', 'saved')
      } catch (error) {
        emit('apiError')
      }
    }

    const deleteCustomGateFunction = async () => {
      await customFieldGateFunctionMutationWatcher.withDisabledWatch(async () => {
        // if this exists on the server, delete it from the server
        if (!isTentativeCustomFieldGateFunction(customFieldGateFunction.value)) {
          try {
            await axiosInstance.delete(`/v1/registration/gateFunction/${props.item.gateFunctionID}`)
            emit('clearCustomGate')
            emit('updateStatus', 'saved')
          } catch (error) {
            emit('apiError')
            return;
          }
        }

        // also update local state
        // we always need at least a bare-minimum object having some custom field info in it, for the view
        customFieldGateFunction.value = freshTentativeCustomFieldGateFunction(props.gateQuestionType, props.itemID);
        customFieldGateFunctionChanged.value = false
        isEditingCustomFieldGateFunction.value = false;
      })
    }

    const setGateFunctionName = (val: string) => {
      gateFunctionName.value = val
      emit('update:gateFunction', val)
    }

    const deleteGateFunction = () => {
      setGateFunctionName('');
      isEditingBuiltinGateFunction.value = false;
    }

    const setSelectedCustomField = async (customFieldID: string | undefined) => {
      if (customFieldID) {
        selectedCustomField.value = await RegistrationStore.getCustomField(customFieldID)
      } else {
        selectedCustomField.value = undefined;
      }

      if (!selectedCustomField.value) {
        return;
      }

      switch (selectedCustomField.value.inputType) {
        case CustomFieldInputType.TEXT:
          return; // no-op --> no options to set
        case CustomFieldInputType.TEXT_AREA:
          return; // no-op --> no options to set
        case CustomFieldInputType.NUMERIC:
          return; // no-op --> no options to set
        case CustomFieldInputType.BOOLEAN:
          options.value = { 1: 'Yes', 0: 'No' }
          return;
        case CustomFieldInputType.SELECT: {
          const selectOptionsArray = selectedCustomField.value.selectOptions.split(',')
          const selectOptionsObject: Record<string, string> = {};
          for (let i = 0; i < selectOptionsArray.length; i++) {
            selectOptionsObject[selectOptionsArray[i]] = selectOptionsArray[i]
          }
          options.value = selectOptionsObject
          return;
        }
        default:
          exhaustiveCaseGuard(selectedCustomField.value.inputType);
      }
    }

    /**
     * parent sets this to indicate it wants to save
     */
    watch(() => props.triggerSave, async (callerRequestsSave: boolean) => {
      if (callerRequestsSave && customFieldGateFunctionChanged.value && props.itemID) {
        if (isTentativeCustomFieldGateFunction(customFieldGateFunction.value)) {
          await createCustomGateFunction()
        }
        else {
          await updateCustomGateFunction()
        }
      }
    })

    /**
     * mutated by a <select> in the form
     */
    watch(() => customFieldGateFunction.value.customFieldID, async (newID: string) => {
      await setSelectedCustomField(newID)
    })

    /**
     * When does this change, why does the parent change it?
     *  - Does this change when the parent item was persisted, i.e. it transitioned from tentative to persisted and acquired an "actual" ID?
     */
    watch(() => props.itemID, async () => {
      // should the following check for the appropriate target entity id property?
      // if it's a custom field gate function for a content chunk, we're probably not interested in knowing about the question id
      if (!customFieldGateFunction.value.gateQuestionID && customFieldGateFunctionChanged.value) {
        setCustomFieldGateFunctionTargetEntityID(customFieldGateFunction.value, props.itemID);
      }
      if (customFieldGateFunctionChanged.value && !customFieldGateFunction.value.gateFunctionID) {
        await createCustomGateFunction()
      }
    })

    /**
     * Intent here is to track dirtyness: "ah, we've changed; tell parent we're 'pending'"
     * Immediately within onMounted and after create and delete HTTP calls we need to temporarily disable watching because those calls
     * perform mutations that would otherwise trigger the watcher, but they aren't intended to;
     * (they themselves emit "updateStatus=saved" events, but if we're still watching, the next event loop will run this watcher and emit "updateStatus=pending")
     */
    const customFieldGateFunctionMutationWatcher = watchWithDisablement(() => customFieldGateFunction, (_vals, prevVals) => {
      if (Object.keys(prevVals.value).length > 1) { // always true?
        customFieldGateFunctionChanged.value = true
        emit('updateStatus', 'pending')
      }
    }, { deep: true })

    onMounted(async () => {
      await customFieldGateFunctionMutationWatcher.withDisabledWatch(async () => {
        await initBuiltinFunctionsStoreState();
        await initCustomFieldsStoreState();

        gateFunctionName.value = props.gateFunction

        if (!!props.item.gateFunctionID && props.item.gateFunction) {
          const clone = JSON.parse(JSON.stringify(props.item.gateFunction));
          customFieldGateFunction.value = clone;
        } else {
          customFieldGateFunction.value = freshTentativeCustomFieldGateFunction(props.gateQuestionType, props.itemID);
        }

        // prime the custom field selection
        await setSelectedCustomField(props.item.gateFunction?.customFieldID)

        customFieldGateFunctionChanged.value = false
      });

      async function initBuiltinFunctionsStoreState() {
        const builtInFunctions = await RegistrationStore.getGateFunctions()
        RegistrationStore.directCommit_setGateFunctions(builtInFunctions)
        await RegistrationStore.createGateFunctionsOptionFormat(builtInFunctions)
      }

      async function initCustomFieldsStoreState() {
        const customFields = await RegistrationStore.getCustomFields()
        RegistrationStore.directCommit_setCustomFields(customFields)
        await RegistrationStore.createCustomFieldsOptionFormat(customFields)
      }
    })

    return {
      isEditingBuiltinGateFunction,
      isEditingCustomFieldGateFunction,
      builtInFunctions,
      gateFunctionName,
      customFieldGateFunction,
      customFieldsOptionFormat,
      createCustomGateFunction,
      customFieldGateFunctionChanged,
      options,
      setGateFunctionName,
      selectedCustomField,
      deleteGateFunction,
      updateCustomGateFunction,
      deleteCustomGateFunction,
      getTargetValue,
      regionUiString: computed(() => "R" + Client.value.instanceConfig.region)
    }
  },
})

/**
 * a watcher that can run a (async) callback while the watch is temporarily disabled
 */
function watchWithDisablement<T>(watchTargetGen: () => T, f: WatchCallback<T, T>, options?: WatchOptions) {
  async function withDisabledWatch(f: () => Promise<void>): Promise<void> {
    try {
      stopHandleRef.stop();
      await f();
    }
    finally {
      stopHandleRef.stop = doWatch();
    }
  }

  /**
   * returns the watch stop handle
   */
  function doWatch() {
    return watch(watchTargetGen, f as any, options as any);
  }

  const stopHandleRef = {
    stop: doWatch(),
    withDisabledWatch
  };

  return stopHandleRef;
}

/**
 * a custom gate function is a variant type that contains either a gateQuestionID | gateQuestionOptionID | gateContentID
 */
function setCustomFieldGateFunctionTargetEntityID(target: CustomFieldGateFunction, targetEntityID: string): void {
  switch (target.gateQuestionType) {
    case GateFunctionQuestionType.CLIENT_REGISTRATION_QUESTION:
      target.gateQuestionID = targetEntityID
      break
    case GateFunctionQuestionType.CLIENT_REGISTRATION_QUESTION_OPTION:
      target.gateQuestionOptionID = targetEntityID
      break
    case GateFunctionQuestionType.CONTENT_CHUNK:
      target.gateContentID = targetEntityID
      break
    default:
      exhaustiveCaseGuard(target.gateQuestionType);
  }
}

/**
 * constructor for a "tentative" CustomFieldGateFunction
 * tentative meaning we've created it here, it doesn't exist on the server yet
 * If a customFieldGateFunction has `gateFunctionID === ""` then we assume it is tentative
 * Many fields missing (fixme: they're not necessary for a tentative entry? all filled in by a form?)
 * The form requires that for "no custom field gate function", we have at least what is generated here (rather than something falsy indicating 'nothing')
 */
function freshTentativeCustomFieldGateFunction(gateQuestionType: GateFunctionQuestionType, targetEntityID: string): CustomFieldGateFunction {
  const result: CustomFieldGateFunction = {
    gateFunctionID: "",
    gateQuestionType: gateQuestionType,
    matchType: GateFunctionMatchType.EQ,
    targetValue: '',
    customFieldID: ''
  };
  setCustomFieldGateFunctionTargetEntityID(result, targetEntityID);
  return result;
}

/**
 * a customFieldGateFunction is presumed "tentative" if its `gateFunctionID` field is falsy (empty string or undefined)
 * Tentative means "does not yet exist on the server"
 */
function isTentativeCustomFieldGateFunction(v: CustomFieldGateFunction | undefined): boolean {
  const gateFunctionIdIsFalsy = !(v?.gateFunctionID?.trim());
  return gateFunctionIdIsFalsy;
}
</script>

