import { AxiosInstance } from "axios"
import { VolunteerRoles, Guid } from "src/interfaces/InleagueApiV1";
import * as ilapi from "src/composables/InleagueApiV1"

export interface VolunteerRoleSelection {
  coach: {
    headCoach: boolean,
    divCoach?: string[],
    asstCoach: boolean,
    divAsstCoach?: string[]
  },
  referee: {
    ref: boolean,
    /**
     * empty string means "all"
     * This is optional, but "not present" is the same as "empty array" (i.e., the user has made zero selections)
     */
    divRef?: ("" | Guid)[]
  },
  /**
   * a "not selected" flex entry may be either not present by key, or present but map to false;
   * a selected flex entry is present by key and maps to true
   */
  flex: {[codeID: /*GUID but possibly with hyphens stripped*/ string]: boolean},
  volunteerComments?: string
};

export function VolunteerRoleSelection() : VolunteerRoleSelection {
  return {
    coach: {
      headCoach: false,
      asstCoach: false,
    },
    referee: { ref: false },
    flex: {},
    volunteerComments: ""
  }
}

/**
 * fixme, this should not be necessary.
 * it maps: GUID -> (GUID-like without hyphens) -> GUID
 * Some form values are pre/post processed with this
 */
export function handleGUID(GUID: string, removeDashes: boolean) {
  if(removeDashes) {
    return GUID.split('-').join('')
  }else {
    const formattedGUID = GUID.split('')
    formattedGUID.splice(8, 0, '-')
    formattedGUID.splice(13, 0, '-')
    formattedGUID.splice(18, 0, '-')
    formattedGUID.splice(23, 0, '-')
    return formattedGUID.join('')
  }
}

// wrangle data from form format to format needed for the API
export function processRoles(roleForm: VolunteerRoleSelection) : VolunteerRoles {
  const options : VolunteerRoles = {
    flex: [],
    referee: [],
    coach: []
  };

  for(const objID in roleForm.flex) {
    if(roleForm.flex[objID]) {
      options.flex.push({codeID: handleGUID(objID, false)})
    }
  }

  // guard on `.ref`, because if it's false, there should be no divs, but the form might have erroneously left some behind.
  // Ideally the form would not write any divisions into the div array when `ref` is false,
  // but that doesn't seem to be the case currently.
  if (roleForm.referee.ref) {
    for (const divID of roleForm.referee.divRef ?? []) {
      options.referee.push({divID})
    }
  }

  if(roleForm.coach.divCoach){ // should guard on .headCoach?
    for(let i=0; i<roleForm.coach.divCoach.length; i++) {
      options.coach.push({
        headCoach: true,
        divID: roleForm.coach.divCoach[i]
      })
    }
  }

  if(roleForm.coach.divAsstCoach) { // should guard on .asstCoach?
    for(let i=0; i<roleForm.coach.divAsstCoach.length; i++) {
      options.coach.push({
        headCoach: false,
        divID: roleForm.coach.divAsstCoach[i]
      })
    }
  }

  return options
}

export async function persistSelectedVolunteerRoles(axios: AxiosInstance, userID: Guid, seasonUID: Guid, roles: VolunteerRoleSelection) {
  const submittableRoles = processRoles(roles)
  const submittableComments = {
    volunteerComments: roles.volunteerComments
    ? roles.volunteerComments
    : '',
  }

  await ilapi.updateVolunteerDetails(
    axios,
    userID,
    seasonUID,
    submittableRoles
  )
  await ilapi.updateVolunteerComments(
    axios,
    userID,
    seasonUID,
    submittableComments
  )
}
