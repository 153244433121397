import { defineComponent, ref } from "vue";
import { faCopy } from "@fortawesome/pro-solid-svg-icons"
import { vReqT } from 'src/helpers/utils';
import { Transition } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Transaction } from 'src/interfaces/Store/checkout'

/**
 * Ideally we'd just show a link to the URL, but for Stripe transactions (which all of them will be)
 * we offer the email address of the submitting user because it will be required when the URL
 * has expired. The Stripe page will ask for the email address and then regenerate a new link, sent
 * to the originator.
 */
export const TransactionIDWithMaybeReceiptURLElement = defineComponent({
  props: {
    transaction: vReqT<Transaction>(),
  },
  setup(props) {
    /**
     * when this changes, the email addr will flash; intended to visually
     * show "hey we copied THIS email addr to the clipboard"
     */
    const onCopyEmailAddrFlashKey = ref(0);

    const writeSubmitterEmailAddrToClipboard = () => {
      if (!props.transaction.submitterEmailAddr) {
        return
      }

      navigator.clipboard.writeText(props.transaction.submitterEmailAddr)
      onCopyEmailAddrFlashKey.value += 1;
    }

    return () => {
      if (!props.transaction.gatewayInvoiceURL) {
        return <span>{props.transaction.gatewayTransactionID}</span>
      }

      return (
        <div>
          <div>
            <a href={props.transaction.gatewayInvoiceURL} class="il-link" target="_blank">Receipt</a>
          </div>
          {
            props.transaction.submitterEmailAddr
              ? (
                <div class="text-xs flex items-center gap-1">
                  <span>Transaction by</span>
                  <Transition
                    enterFromClass="bg-gray-300"
                    enterActiveClass="duration-500"
                    leaveActiveClass="hidden"
                  >
                    <span key={onCopyEmailAddrFlashKey.value}>{props.transaction.submitterEmailAddr}</span>
                  </Transition>
                  <span
                    class="cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-[2px] rounded-sm"
                    v-tooltip={{content: 'Copy email to clipboard'}}
                    onClick={writeSubmitterEmailAddrToClipboard}
                  >
                    <FontAwesomeIcon icon={faCopy}/>
                  </span>
                </div>
              )
              : null
          }
        </div>
      )
    }
  }
})
