import { defineComponent, PropType, computed, watch } from 'vue'
import { FormKit } from '@formkit/vue'
import { ScoreTransactionForm, TransactionType } from 'src/interfaces/score'

import { Team } from 'src/composables/InleagueApiV1.Game'

import { FK_validation_strlen, noAvailableOptions, UiOption, UiOptions, vOptT, vReqT } from 'src/helpers/utils'
import { Btn2 } from '../UserInterface/Btn2'
import { Guid } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  props: {
    gameID: vReqT<Guid>(),
    compTransactions: vOptT<TransactionType[]>(() => []),
    // TODO: what's up with this default
    homeTeam: { type: Object as PropType<Team>, default: () => ({}) },
    // TODO: what's up with this default
    visitorTeam: { type: Object as PropType<Team>, default: () => ({}) },
    /**
     * This is the "transaction index" for the game in question.
     * e.g. if there are 10 (tentative or persisted) transactions,
     * this might be transaction 0, or 1, or ... up to 9.
     * Zero-indexed.
     */
    zi_transactionIdx: vReqT<number>(),
    form: vReqT<ScoreTransactionForm>(),
  },
  emits: {
    deleteTransaction: () => true,
  },
  setup(props, ctx) {
    const teamOptions = computed<UiOption[]>(() => {
      return [
        {label: "", value: ""},
        // we always have a home team?
        {label: "Home: " + props.homeTeam.fullName, value: props.homeTeam.ID},
        // here we say "there might not be a visitor team"
        {label: "Visitor: " + props.visitorTeam.fullName || 'TBD', value: props.visitorTeam.ID || 'TBD'},
      ]
    })

    const transactionOptions = computed<UiOption[]>(() => {
      return [
        {label: "", value: ""},
        ...props.compTransactions.map(v => ({label: v.label, value: v.typeID}))
      ]
    });

    /**
     * Player options are sourced based on the transaction's selected team.
     * A transaction for home team gets home team players, and a transaction for visitor gets visitor team players.
     * Players are found via their registrations, so we use registrationID rather than childID.
     */
    const playerOptions = computed<UiOptions>(() => {
      if (props.form.teamID === props.homeTeam.ID) {
        const opts = (props.homeTeam.players ?? []).map(playerAsOption)
        return opts.length === 0
          ? noAvailableOptions("No home team players assigned")
          : {disabled: false, options: [{label: "", value:""}, ...opts]};
      }
      else if (props.form.teamID === props.visitorTeam.ID) {
        const opts = (props.visitorTeam.players ?? []).map(playerAsOption)
        return opts.length === 0
          ? noAvailableOptions("No visitor team players assigned")
          : {disabled: false, options: [{label: "", value: ""}, ...opts]};
      }
      else {
        // in this case we currently won't even show a select element, so just a dummy "nothing available" is fine
        return noAvailableOptions("");
      }

      function playerAsOption(v: Team["players"][number]) : UiOption {
        return {
          label: `${v.playerFirstName} ${v.playerLastName}`,
          value: v.registrationID
        }
      }
    })

    /**
     * When player options change, we might need to change the selected player (or rather, the selected player's registrationID, same same)
     */
    watch(() => playerOptions.value, () => {
      if (!playerOptions.value.options.find(v => v.value === props.form.registrationID)) {
        props.form.registrationID = ""
      }
    }, {immediate: true})

    return () => {
      return (
        <div data-test="GameTransaction">
          <div class="font-medium flex flex-col justify-center text-lg">
            <FormKit type="select" label="Team" options={teamOptions.value} v-model={props.form.teamID} validation={[["required"]]} data-test="teamID"/>
            <FormKit type="select" label="Transaction" options={transactionOptions.value} v-model={props.form.transactionType} validation={[["required"]]} data-test="transactionType"/>
            {props.form.teamID === props.homeTeam.ID || props.form.teamID === props.visitorTeam.ID
              ? <FormKit type="select" label="Player" options={playerOptions.value.options} disabled={playerOptions.value.disabled} v-model={props.form.registrationID} data-test="registrationID"/>
              // can happen if no team is selected, otherwise shouldn't happen (team options are either home or visitor or tentatively-no-selection)
              : null
            }

            <FormKit type="textarea" label="Transaction Comments" v-model={props.form.comment} validation={[["required"], FK_validation_strlen(8, Infinity)]} data-test="comment"/>

            <Btn2 class="px-2 py-1" onClick={() => ctx.emit("deleteTransaction")} data-test="delete">Delete</Btn2>
          </div>
        </div>
      )
    }
  }
})
