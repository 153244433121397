<!--
  prop loader / permissions checker
  when everything's good we just forward to the actual implementation
-->
<template lang="pug">
template(v-if="ready")
  CashOrCheckImpl(v-bind="forwardedProps")
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType, getCurrentInstance, onBeforeMount } from "vue";
import type { Props } from "./CashOrCheckImpl";
import C_CashOrCheckImpl from "./CashOrCheckImpl.vue"

import authService from "src/helpers/authService";
import { UserData } from "src/interfaces/Store/user";
import { User } from "src/store/User";
import { Client } from "src/store/Client";

export default defineComponent({
  components: {
    CashOrCheckImpl: C_CashOrCheckImpl
  },
  beforeRouteEnter(from, to, next) {
    const roles = (User.value.userData as UserData).roles;
    if (!authService(roles, "registrar")) {
      next({path: "/forbidden"})
    }
    else {
      next();
    }
  },
  setup(_props) {
    const ready = ref(false);
    const forwardedProps = ref<Props | null>(null);



    onMounted(async () => {
      forwardedProps.value = {
        seasonUID: Client.value.instanceConfig.currentseasonuid,
        seasonsMap: await Client.getSeasonsMap()
      }
      ready.value = true;
    })
    return { ready, forwardedProps }
  }
})
</script>