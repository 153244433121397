export const TabID_t = {
  competitionAdministrator: 'competition-administrator',
  seasonalSettings: 'seasonal-settings',
  divisionSettings: 'division-settings',
  eligibilityRules: 'eligibility-rules',
  playerEligibility: 'player-eligibility',
  sideBySide: 'side-by-side',
  registrationReceipt: 'registration-receipt',
  tournaments: 'tournaments',
  invitationStatus: "invitationStatus",
} as const;
export type TabID_t = (typeof TabID_t)[keyof typeof TabID_t];