import { AxiosInstance } from "axios"
import { ExtractEmitsHandlers } from "src/helpers/utils"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { ExtractPropTypes } from "vue"

export const propsDef = {
  // none
}

export const emitsDef = {
  userSelected: (_userDetail: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies) => true
}

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

/**
 * get those users who are related to the current user
 * candidate for ilapi.family module
 */
export interface AdhocUserlike_GetUsersLinkedToCurrentUserFamilies {
  userID: string;
  lastName: string;
  familyIDs: string[];
  firstName: string;
  email: string;
  privateProfile: boolean;
}
export async function getUsersLinkedToCurrentUserFamilies(axios: AxiosInstance) : Promise<{users: AdhocUserlike_GetUsersLinkedToCurrentUserFamilies[]}> {
  const response = await axios.get(`/v1/family/users`)
  return response.data.data
}
