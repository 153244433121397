<template lang="pug">
TDialog(data-test="force-activate-competition-registration-modal")
  .text-xl.mb-4 Force activate program registration
  ul
    li.p-2.text-left
      | This will activate {{ targetPlayerUiName }}'s {{ targetCompRegCompetitionUiName }} registration.
    li.p-2.text-left
      | No monetary transactions will be performed.
    li.p-2.text-left
      div.mb-2.p-1(v-if="waitlistedButUnpaidDetail" style="border:2px dashed orange;" data-test="waitlisted-pending-payment-warning")
        |  This program registration is waitlisted, with a payment to occur upon waitlist removal.
        |  Force activating it will skip the payment process entirely. Consider using the
        //- whitespace here is intentional and necessary, the following line should be exactly "|"
        |
        router-link.text-blue-700.underline.cursor-pointer(:to="waitlistedButUnpaidDetail.paymentManagerRouterLink" target="_blank")
          | payment manager
        |  for finer grained options.
      div.mb-2 Activate this program registration?

    div
      div.mb-4
        t-btn(
          data-test="confirm-activation"
          type='button'
          @click="confirmActivation()"
          color="green"
        )
          div Yes, activate this program registration.
      div.mb-4.text-center
        t-btn(
          data-test="do-not-activate"
          type='button'
          @click="doNotActivate()"
          color="red"
        )
          div No, do not activate this program registration.
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { propsDef, emitsDef } from "./ForceActivateCompetitionRegistrationModal.ilx";
import { routeDetailToRoutePath, RouteName } from "src/components/Admin/R_CompRegsWithBlockedPaymentIntents.route"

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, { emit }) {

    const targetCompRegCompetitionUiName = computed(() => {
      return props.target.competition.competition;
    });
    const waitlistedButUnpaidDetail = computed(() => {
      if (!!props.target.competitionRegistration.isWaitlistedButUnpaid) {
        return {
          paymentManagerRouterLink: routeDetailToRoutePath({name: RouteName.main, query: {registrationID: props.target.registrationID}})
        }
      }
      else {
        return undefined;
      }
    })

    const targetPlayerUiName = computed(() => `${props.target.player.playerFirstName} ${props.target.player.playerLastName}`);

    const notifyDivisionDirectorAndHeadCoaches = ref(false);
    const notifyCompetitionManagers = ref(false);
    const markPlayerAsNeverPlayed = ref(false);

    return {
      confirmActivation: (): void => {
        emit("confirmActivation", {
          competitionRegistration: props.target.competitionRegistration,
        });
      },
      doNotActivate: (): void => {
        emit("doNotActivate")
      },
      targetCompRegCompetitionUiName,
      targetPlayerUiName,
      notifyDivisionDirectorAndHeadCoaches,
      notifyCompetitionManagers,
      markPlayerAsNeverPlayed,
      waitlistedButUnpaidDetail
    }
  }
})
</script>
