import { ExtractEmitsHandlers } from "src/helpers/utils";

export function emitsDef() {
  return {
    updateCoreQuestionAnswers: (v: /* fixme ??? */ any) => true,
    updateCustomQuestions: (v: /* fixme ??? */ any) => true,
    "player-data-invalidated": () => true
  }
}

export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
