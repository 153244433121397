<template lang="pug">
div
  h1.text-4xl.self-end.font-medium.mb-8
    font-awesome-icon.mr-2(:icon='["fas", "users-cog"]')
    | Content Chunk Search
  .flex.flex-wrap.content-center
    .flex.py-2
      div.flex.flex-row
        FormKit#search.w-full(
          noMargins='true',
          type='text',
          @keyup.enter.native='search',
          v-model='searchParam',
          name='searchParam',
          data-cy='searchInput',
          input-class='focus:outline-none rounded-l-full w-full py-4 px-6 text-gray-700 leading-tight',
          wrapper-class='mt-1'
        )
        FormKit(
          noMargins='true',
          type='submit',
          data-cy='submit',
          @click='search',
          input-class='bg-green-800 hover:bg-green-700 text-white rounded-full p-2 w-12 h-12 flex items-center justify-center focus:outline-none'
          wrapper-class='ml-5',
          outer-class='mb-0'
        )
          font-awesome-icon(:icon='["fas", "search"]')

    .flex.flex-row.q-pa-md.quasar-style-wrap.mt-8(
      data-cy='searchResultsTable',
      v-if='display.length'
    )
      q-table(
        title='Content Chunks With Export',
        :rows='display',
        :columns='columns',
        row-key='name',
        table-class='content',
        :rows-per-page-options='[0]',
        hide-pagination
      )
        template(v-slot:top-right)
          t-btn(label='Export to Excel', @click='exportTableXLSX')
        template(v-slot:body-cell-id='cellProperties')
          q-td(:props='cellProperties', :class='ccID', data-cy='searchResult')
            router-link(
              :to='{ name: "content-chunk-result", params: { id: cellProperties.row.id }, query: { override: false, search: searchParam } }'
            ) {{ cellProperties.row.id }}
        template(v-slot:body-cell-label='cellProperties')
          q-td(:props='cellProperties', class=ccLabel)
            router-link(
              :to='{ name: "content-chunk-result", params: { id: cellProperties.row.id }, query: { override: false, search: searchParam } }'
            ) {{ cellProperties.row.label }}
        template(v-slot:body-cell-defaultText='cellProperties')
          q-td(:props='cellProperties', :style='{ width: "10px" }')
            router-link(
              :to='{ name: "content-chunk-result", params: { id: cellProperties.row.id }, query: { override: false, search: searchParam } }'
            ) {{ `${cellProperties.row.defaultText.slice(0, 200)}...` }}
        template(v-slot:body-cell-overrideText='cellProperties')
          q-td(:props='cellProperties')
            router-link(
              :to='{ name: "content-chunk-result", params: { id: cellProperties.row.id }, query: { override: true, search: searchParam } }'
            ) {{ cellProperties.row.overrideText ? (cellProperties.row.overrideText.length > 200 ? `${cellProperties.row.overrideText.slice(0, 200)}...` : cellProperties.row.overrideText) : 'Create Override' }}
    .mt-8(v-else-if='message', data-cy='noData') {{ message }}
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onBeforeMount } from 'vue'
import C_ContentChunk from 'src/components/Admin/ContentChunks/ContentChunk.vue'
import { ContentChunk } from 'src/interfaces/InleagueApiV1'
import { SearchDisplayInterface } from 'src/interfaces/contentChunk'
import { useRoute } from 'vue-router'
import { axiosInstance } from 'src/boot/axios'
import { downloadFromObjectURL } from "src/helpers/utils";
import * as XlsxUtils from 'src/modules/XlsxUtils'

export default defineComponent({
  name: 'ContentChunkSearch',
  components: {
    ContentChunk: C_ContentChunk,
  },
  setup() {
    const searchResponses = ref([]) as Ref<ContentChunk[]>
    const display = ref([]) as Ref<SearchDisplayInterface[]>
    const searchParam = ref('')
    const message = ref('')
    const pagination = ref({
      sortBy: 'id',
      descending: false,
      rowsPerPage: 300,
    })

    const columns = ref([
      {
        name: 'id',
        required: true,
        label: 'ID',
        align: 'left',
        field: (contentChunk: ContentChunk) => contentChunk,
        sortable: true,
        style: 'vertical-align: top; width: 40px;',
        classes: 'q-table',
      },
      {
        name: 'label',
        required: false,
        label: 'Label',
        align: 'left',
        sortable: true,
        style: 'vertical-align: top; width: 50px !important;',
        classes: 'q-table',
      },
      {
        name: 'defaultText',
        align: 'center',
        label: 'Default',
        field: 'defaultText',
        sortable: true,
      },
      {
        name: 'overrideText',
        align: 'center',
        label: 'Override',
        field: 'overrideText',
        sortable: true,
      },
    ])

    const route = useRoute()

    const search = async () => {
      searchResponses.value = []
      display.value = []
      const response = await axiosInstance
        .get(`/v1/contentChunks/search?searchparam=${searchParam.value}`)
        .catch(e => {
          // console.error(e)
          searchResponses.value = []
        })
      if (response && Array.isArray( response.data.data ) && response.data.data.length) {
        searchResponses.value = response.data.data
        display.value = searchResponses.value.map(chunk => {
          return {
            id: chunk.id,
            label: chunk.shortLabel,
            defaultText: chunk.defaultText,
            overrideText: chunk.override
              ? chunk.override.overrideText
              : 'Create Chunk',
          }
        })
      } else {
        message.value = `No dynamic content found matching ${searchParam.value}`
      }
    }

    const exportTableXLSX = async () => {
      const xlsxBuilder = XlsxUtils.builderWithKludgyAutoWidths(["ID", "Label", "Default Text", "Override Text"])
      display.value.forEach(e => {
        xlsxBuilder.pushRow([e.id, e.label, e.defaultText, e.overrideText])
      })
      downloadFromObjectURL(await xlsxBuilder.build(), "Content Chunks.xlsx");
    }

    onBeforeMount(async () => {
      if (route.query.search) {
        searchParam.value = route.query.search as string
        await search()
      }
    })

    return {
      searchResponses,
      display,
      searchParam,
      message,
      pagination,
      columns,
      search,
      exportTableXLSX,
    }
  },
})
</script>

<style>
.searchDisplay {
  max-height: 40px;
  overflow: hidden;
  margin: 50px 10px 50px 10px;
}

.q-table td,
.q-table th .q-table tr {
  /* don't shorten cell contents */
  white-space: normal !important;
  word-wrap: break-word;
}

.content table {
  table-layout: fixed;
  width: 100%;
}

.content tr > *:nth-child(1) {
  width: 5vw;
}

.content tr > *:nth-child(2) {
  white-space: normal;
  width: 15vw;
}

.content tr > *:nth-child(3),
.content tr > *:nth-child(4) {
  color: purple;
  width: 25vw;
}
</style>
