import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { vReqT } from "src/helpers/utils"
import { Integerlike } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  invoiceInstanceIDs: vReqT<Integerlike[]>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "checkout",
} as const;

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {
    name: RouteNames.main,
    params: {
      invoiceInstanceIDs: v.invoiceInstanceIDs,
    },
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {...route.params as any}
}
