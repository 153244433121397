import type { ExtractPropTypes } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { parseIntOrFail, vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1";
import { ContactAndVolunteerDetailsUpdateFlow } from "src/composables/registration";

export const propsDef = {
  childID: vReqT<Guid>(),
  seasonUID: vReqT<Guid>(),
  /**
   * 0-indexed
   * Which user in the list are we working on
   */
  userListIndex: vReqT<number>(),
  /**
   * 1-indexed
   * Which step for the target user are we on
   */
  step: vReqT<number>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  // investigate: define this here?
  main: ContactAndVolunteerDetailsUpdateFlow.routeEntry,
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

export function routeDetailToRouteLocation(v: Props) : RouteLocationRaw {
  return {name: RouteNames.main, params: v}
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  return {
    ...route.params as any,
    userListIndex: parseIntOrFail(route.params.userListIndex),
    step: parseIntOrFail(route.params.step)
  }
}
