type Slot = [
  homeSeed: number,
  visitorSeed: number,
  schedulingIntent: "home" | "visitor" | "both" | "empty" | "pending"
]

// TODO: runtime validator? round lenghts are powers of 2, etc.
export type StaticBracketConfig = Record</*teamCount*/number, Slot[][]>

export const r122 : StaticBracketConfig = {
  5: [
    [
      [1,8,"empty"],
      [5,4,"both"],
      [3,6,"empty"],
      [7,2,"empty"],
    ],
    [
      [1,4,"home"],
      [3,2,"both"]
    ],
    [
      [1,2,"pending"]
    ]
  ],
  6: [
    [
      [1,8,"empty"],
      [5,4,"both"],
      [3,6,"both"],
      [7,2,"empty"],
    ],
    [
      [1,4,"home"],
      [3,2,"visitor"]
    ],
    [
      [1,2,"pending"]
    ]
  ],
  7: [
    [
      [1,8,"empty"],
      [5,4,"both"],
      [3,6,"both"],
      [7,2,"both"],
    ],
    [
      [1,4,"home"],
      [3,2,"pending"]
    ],
    [
      [1,2,"pending"]
    ]
  ],
  8: [
    [
      [1,8,"both"],
      [5,4,"both"],
      [3,6,"both"],
      [7,2,"both"],
    ],
    [
      [1,4,"pending"],
      [3,2,"pending"]
    ],
    [
      [1,2,"pending"]
    ]
  ],
  //////////////////////
  16: [
    [
      [1,16, "both"],
      [9,8, "both"],
      [5,12, "both"],
      [13,4, "both"],
      [3,14, "both"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "both"],
    ],
    [
      [1,8, "pending"],
      [5,4, "pending"],
      [3,6, "pending"],
      [7,2, "pending"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  15: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "both"],
      [13,4, "both"],
      [3,14, "both"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "both"],
    ],
    [
      [1,8, "home"],
      [5,4, "pending"],
      [3,6, "pending"],
      [7,2, "pending"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  14: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "both"],
      [13,4, "both"],
      [3,14, "both"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "pending"],
      [3,6, "pending"],
      [7,2, "visitor"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  13: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "both"],
      [13,4, "both"],
      [3,14, "empty"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "pending"],
      [3,6, "home"],
      [7,2, "visitor"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  12: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "both"],
      [13,4, "empty"],
      [3,14, "empty"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "visitor"],
      [3,6, "home"],
      [7,2, "visitor"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  11: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "empty"],
      [13,4, "empty"],
      [3,14, "empty"],
      [11,6, "both"],
      [7,10, "both"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "both"],
      [3,6, "home"],
      [7,2, "visitor"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  10: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "empty"],
      [13,4, "empty"],
      [3,14, "empty"],
      [11,6, "empty"],
      [7,10, "both"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "both"],
      [3,6, "both"],
      [7,2, "visitor"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
  9: [
    [
      [1,16, "empty"],
      [9,8, "both"],
      [5,12, "empty"],
      [13,4, "empty"],
      [3,14, "empty"],
      [11,6, "empty"],
      [7,10, "empty"],
      [15,2, "empty"],
    ],
    [
      [1,8, "home"],
      [5,4, "both"],
      [3,6, "both"],
      [7,2, "both"],
    ],
    [
      [1,4, "pending"],
      [3,2, "pending"]
    ],
    [
      [1,2, "pending"]
    ]
  ],
}
