import { FormKit } from "@formkit/vue";
import { faCircle, faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { InleagueUserViaAyso_InleagueMiss, RegionDef } from "src/composables/InleagueApiV1.Public";
import { vReqT, UiOption, parseIntOrFail, sortBy } from "src/helpers/utils";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { Public } from "src/store/Public";
import { defineComponent, ref, computed, onMounted } from "vue";

export const SendEmailToUserRequiringAysoID = defineComponent({
  props: {
    aysoLookupResult: vReqT<InleagueUserViaAyso_InleagueMiss>(),
  },
  emits: {
    doSendNewUserViaAysoIdClaimEmail: (_region: Integerlike) => true
  },
  setup(props, {emit}) {
    const selectedRegionID = ref<"" | Integerlike>("")

    type AsyncState =
      | {ready: false}
      | {ready: true, regionList: RegionDef[]}

    const asyncState = ref<Readonly<AsyncState>>({ready: false});

    const regionOptions = computed<UiOption[]>(() => {
      if (!asyncState.value.ready) {
        return []
      }

      return Public.buildStandardRegionOptions(asyncState.value.regionList, {includeNilOption: true});
    })

    const doSubmit = () => {
      if (!selectedRegionID.value) {
        throw Error("Illegal state")
      }

      emit("doSendNewUserViaAysoIdClaimEmail", selectedRegionID.value)
    }

    onMounted(async () => {
      asyncState.value = {
        ready: true,
        regionList: await Public.getMasterRegionList()
      }
    })

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }

      return (
        <FormKit type="form" actions={false} onSubmit={doSubmit}>
          <div class="rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2">
            <div>To proceed with tournament team registration, we will create an inLeague account for you based on your AYSO volunteer record with the National Association.</div>
            <div class="border-b border-slate-200 my-2"/>
            <div>To confirm your identity, we will send an email to the address on file with AYSO containing a link that will create your new AYSO-connected inLeague account. If the below email is not correct, please have your regional commissioner update your volunteer record in Affinity and then return to this page.</div>
            <div class="border-b border-slate-200 my-2"/>
            <div>Select your home region:</div>
            <div class="text-xs my-1">You are registering as a user for region {Client.value.instanceConfig.region}, but your home region might be different.</div>
            <FormKit type="select" options={regionOptions.value} name="Region" v-model={selectedRegionID.value} validation={[["required"]]} data-test="newUserRegion"/>
            <div class="flex justify-center">
              <t-btn data-test="send-email" type="submit" margin={false}>Send email to {props.aysoLookupResult.partialAysoUserData.email}</t-btn>
            </div>
          </div>
        </FormKit>
      )
    }
  }
})
