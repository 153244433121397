import { RouteLocationRaw } from "vue-router";

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import type { Router } from "vue-router"

import { exhaustiveCaseGuard } from "src/helpers/utils";

import { User } from "src/store/User";

export const MfaInitRouteName = {
  "sms": "mfa-init.sms",
  "totp": "mfa-init.totp",
  "options": "mfa-init.options",
  "complete": "mfa-init.complete"
} as const;

export const MfaChallengeRouteName = {
  "sms": "mfa-challenge.sms",
  "totp": "mfa-challenge.totp",
  "options": "mfa-challenge.options",
} as const;

export const mfaInitRouteNameByMfaType = (type: ilauth.MfaType | "CHOOSE!" | "COMPLETE!") : RouteLocationRaw => {
  switch (type) {
    case "SMS": return {name: MfaInitRouteName.sms};
    case "TOTP": return {name: MfaInitRouteName.totp};
    case "CHOOSE!": return {name: MfaInitRouteName.options};
    case "COMPLETE!": return {name: MfaInitRouteName.complete}
    default: exhaustiveCaseGuard(type);
  }
}

export const mfaChallengeRouteNameByMfaType = (type: ilauth.MfaType | "CHOOSE!") : RouteLocationRaw => {
  switch (type) {
    case "SMS": return {name: MfaChallengeRouteName.sms};
    case "TOTP": return {name: MfaChallengeRouteName.totp};
    case "CHOOSE!": return {name: MfaChallengeRouteName.options};
    default: exhaustiveCaseGuard(type);
  }
}

const MfaTypeDisplayOrdering : Readonly<Record<ilauth.MfaType, /*display order*/number>> = {
  "SMS": 1,
  "TOTP": 2
};

export function mfaTypeDisplaySort(l: ilauth.MfaType, r: ilauth.MfaType) {
  const vL = MfaTypeDisplayOrdering[l];
  const vR = MfaTypeDisplayOrdering[r];
  return vL < vR ? -1 : vL === vR ? 0 : 1;
}

export async function beginMfaInitJourney(router: Router, authResponse: ilauth.AuthenticateResponse_NeedsMfaInit) {
  User.loginState = {
    state: "mfa-init-flow",
    token: authResponse.token,
    userID: authResponse.userID,
    mfaDetails: authResponse.mfaDetails
  };

  if (authResponse.mfaDetails.unenrolledMfaTypes.length === 1) {
    // push directly to target state
    await router.push(mfaInitRouteNameByMfaType(authResponse.mfaDetails.unenrolledMfaTypes[0]))
  }
  else {
    await router.push(mfaInitRouteNameByMfaType("CHOOSE!"))
  }
}
