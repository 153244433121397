import { defineComponent, ref, computed, watch } from "vue";
import { vReqT, UiOption, sortBy, sortByMany, parseIntOr, exhaustiveCaseGuard } from "src/helpers/utils";
import { EscapedRegExp } from "src/helpers/utils-backend";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import { Paginated } from "src/modules/PaginationUtils";
import { inferColIDs, freshSortState, SortArrow, getColDefHtml } from "src/modules/TableUtils";
import { CompleteTeamChooserSelection } from "../UserInterface/TeamChooser/TeamChooserUtils";
import { PlayerRosterSort } from "./R_TeamSeason.route";
import { ExcludablePlayer } from "src/composables/InleagueApiV1.Teams";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { RosterExcludedPlayerIDs } from "./RosterExcludedPlayerIDs";
import { Btn2 } from "../UserInterface/Btn2";

export const ExcludablePlayersElement = defineComponent({
  props: {
    selection: vReqT<CompleteTeamChooserSelection>(),
    listing: vReqT<ExcludablePlayer[]>(),
    sort: vReqT<PlayerRosterSort>(),
    rosterExcludedPlayerIDs: vReqT<RosterExcludedPlayerIDs>(),
  },
  setup(props) {
    const itemsPerPage = ref<Integerlike | "ALL">("ALL")
    const itemsPerPageOptions : UiOption[] = [
      {label: "25", value: "25"},
      {label: "50", value: "50"},
      {label: "All", value: "ALL"}
    ]

    const colDefs = ref(inferColIDs<ExcludablePlayer>()([
      {
        id: "checked",
        label: "Exclude",
        html: v => {
          return (
            <div class="flex items-center justify-start ml-4">
              <input
                type="checkbox"
                checked={props.rosterExcludedPlayerIDs.has(v.playerID)}
                onInput={() => {
                  props.rosterExcludedPlayerIDs.toggle(v)
                  props.rosterExcludedPlayerIDs.persistToLocalStorage()
                }}
              />
            </div>
          )
        }
      },
      {
        id: "player",
        label: "Player",
        html: v => `${v.playerFirstName} ${v.playerLastName}`,
        sort: {
          cb: sortByMany(sortBy(_ => _.playerLastName), sortBy(_ => _.playerFirstName)),
          dir: "asc",
        }
      },
      {
        id: "team",
        label: "Team",
        html: v => `${v.teamName}`,
        sort: {
          cb: sortBy(_ => _.teamName),
          dir: "not-sorted",
        }
      },
      {
        id: "uniform",
        label: "Uniform",
        html: v => v.uniform,
        sort: {
          cb: sortBy(_ => {
            // try to sort by integer uniform IDs ("jersey number"),
            // but if it's not an int just do the default on whatever stringly-typed value we have
            return parseIntOr(_.uniform, _.uniform)
          }),
          dir: "not-sorted",
        }
      },
    ]));

    const sortState = freshSortState(colDefs.value)

    // Initial sort state is by playerName, which makes sense for the way this component is used
    // But, we also will respect the prop sortState if the user starts toggling it.
    // It is unlikely that even if the prop sortState is "by uniform" when we mount that the user
    // actually wants to sort by uniform here.
    sortState.reconfigure([{colID: "player", dir: "asc"}])
    watch(() => props.sort, () => {
      switch (props.sort) {
        case "name":
          sortState.reconfigure([{colID: "player", dir: "asc"}])
          return;
        case "uniform":
          sortState.reconfigure([{colID: "uniform", dir: "asc"}])
          return;
        default: exhaustiveCaseGuard(props.sort);
      }
    }, {immediate: false})

    const filters = ref({playerName: ""})

    const applyFilters = (vs: readonly ExcludablePlayer[]) => {
      return vs
        .filter(dedupe())
        .filter(filterByName())

      // todo: Dedupe probably shouldn't be necessary, but sometimes we do see duplicate playerIDs, maybe a backend issue,
      // or our assumptions about what it returns are wrong.
      function dedupe() {
        const seen = new Set<Guid>()
        return (v: ExcludablePlayer) => {
          if (seen.has(v.playerID)) {
            return false;
          }
          else {
            seen.add(v.playerID)
            return true;
          }
        }
      }

      function filterByName() {
        const s = filters.value.playerName.trim()
        const pattern = s ? EscapedRegExp(s, "i") : null;
        if (pattern) {
          return (v: ExcludablePlayer) => pattern.test(`${v.playerFirstName} ${v.playerLastName}`)
        }
        else {
          return () => true
        }
      }
    }

    const applySort = (vs: readonly ExcludablePlayer[]) => {
      return [...vs].sort(sortState.asSorter())
    }

    const filteredSortedRows = computed(() => {
      return applySort(applyFilters(props.listing))
    })

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), filteredSortedRows))

    return () => (
      <div class="rounded-md shadow-md border border-slate-100 m-auto overflow-auto" style="min-width:32em;">
        <div class="p-2">
          <div>Excluded players</div>
          <div>Players selected here will be excluded from generated roster PDFs.</div>
          <Btn2 class="p-2 text-xs" onClick={() => {
            props.rosterExcludedPlayerIDs.clear()
            props.rosterExcludedPlayerIDs.persistToLocalStorage()
          }}>Clear all</Btn2>
        </div>
        <div class="border-b border-gray-200 px-2"/>
        <div>
          <table class="w-full" style="--fk-margin-outer:none;">
              <tr>
                {
                  colDefs.value.map(colDef => {
                    return (
                      <th class="p-2 text-left whitespace-nowrap">
                        <div class="flex items-center text-sm">
                          <span>{colDef.label}</span>
                          {
                            ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                              return sorter
                                ? (
                                  <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                    <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                  </span>
                                )
                                : null
                            })(sortState.sortersByColID[colDef.id])
                          }
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
              {
                pagination.value.pageData.itemsThisPage.map((entry, i) => {
                  const rowBgColor = i % 2 ? "bg-gray-100" : ""
                  return (
                    <tr key={entry.assignmentID}>
                      {
                        colDefs.value.map(colDef => {
                          return (
                            <td class={`${rowBgColor} p-2`}>
                              {
                                getColDefHtml(colDef, entry)
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </table>
          {
            props.listing.length === 0
              ? <div class="p-4 flex items-center justify-center">Nothing found</div>
              : null
          }
          {
            props.listing.length > 0
              ? (
                <div class="flex">
                  <div class="ml-auto p-2">
                    <SimplePaginationNav mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                  </div>
                </div>
              )
              : null
          }
        </div>
      </div>
    )
  }
})
