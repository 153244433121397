<template lang="pug">
Portlet(
  label='Referee Assignments',
  :icon='assignments.fontAwesome',
  prefix='fas',
  @expand='toggleExpand'
)
  div(v-if='(isMobile && show) || !isMobile')
    .flex.flex-col(v-if='assignments.refAssignments.length')
      .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
            table.min-w-full.divide-y.divide-gray-200
              tbody.bg-white.divide-y.divide-gray-200
                tr.text-sm(v-for='assignment in assignments.refAssignments')
                  td.px-6.py-4.whitespace-nowrap
                    .mx-auto {{ formatDate(assignment.gameStart) }}
                    .mx-auto.text-xs {{ formatTime(assignment.gameStart) }}
                  td.px-6.py-4.whitespace-nowrap
                    | {{ assignment.field }}
                  td.px-6.py-4.whitespace-nowrap
                    | {{ assignment.homeTeam ? assignment.homeTeam : "TBD" }} vs. {{ assignment.visitorTeam ? assignment.visitorTeam : "TBD" }}
                  td.px-6.py-4.whitespace-nowrap
                    | {{ assignment.refereeAssignment }}
    .italic.text-center.p-4(v-else)
      | You have no upcoming referee assignments.
</template>


<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import {
  formatDateWithoutTime,
  formatTime as formatTimeMethod,
} from 'src/helpers/formatDate'
import Portlet from 'src/components/Portlets/Portlet.vue'


import { System } from 'src/store/System'

export default defineComponent({
  name: 'RefereeAssignments',
  components: {
    Portlet,
  },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
    assignments: Object,
  },
  setup(props) {
    const show = ref(props.showContent)



    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    const formatDate = (date: string) => {
      // console.log('formatDate', date)
      return formatDateWithoutTime(date)
    }

    const formatTime = (date: string) => {
      return formatTimeMethod(date)
    }

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    return {
      formatDate,
      formatTime,
      show,
      toggleExpand,
      isMobile,
    }
  },
})
</script>
