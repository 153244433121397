import { computed, defineComponent } from "vue";
import { PayableInvoicesResolver } from "src/store/EventuallyPinia";
import axios from "axios";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { exhaustiveCaseGuard, parseFloatOr } from "src/helpers/utils";
import { RouteLocationRaw, RouterLink } from "vue-router";

import * as iltypes from "src/interfaces/InleagueApiV1"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { freshNoToastLoggedInAxiosInstance } from "src/boot/axios";

import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'

export default defineComponent({
  setup() {
    const payableInvoices = computed(() => PayableInvoicesResolver.getOnce(freshNoToastLoggedInAxiosInstance()))

    const formattedFloatOrId = (v: any) => {
      return parseFloatOr(v, null)?.toFixed(2) ?? v;
    }

    const checkoutRoute = (instanceID: iltypes.Integerlike) : RouteLocationRaw => {
      return R_Checkout.routeDetailToRouteLocation({
        invoiceInstanceIDs: [instanceID]
      })
    }

    return () => (
      <>
        <h1>
          <FontAwesomeIcon icon={faFileInvoiceDollar}/>
          <span class="ml-2">Your current invoices</span>
        </h1>
        {
          (() => {
            switch (payableInvoices.value.status) {
              case "idle":
                // shouldn't happen, or only very briefly
                return null;
              case "canceled":
                return <div>Sorry, something went wrong</div>
              case "error":
                return <div>Sorry, something went wrong</div>
              case "pending":
                return <div>Retrieving invoices...</div>
              case "resolved":
                return (
                  <div>
                    {
                      // shouldn't generally happen, we dont' offer a link to this page if there aren't values to show
                      payableInvoices.value.data.length === 0
                        ? <div class="p-2">We couldn't find anything</div>
                        : null
                    }
                    {
                      payableInvoices.value.data.map(invoice => {
                        return (
                          <div class="p-2 m-2 border border-slate-100 shadow-md rounded-md">
                            <div>Invoice {invoice.instanceID}</div>
                            <div>${formattedFloatOrId(invoice.lineItemSum)}</div>
                            {invoice.lineItems.map(line => <div class="pl-1">{line.description}</div>)}
                            <RouterLink to={checkoutRoute(invoice.instanceID)}>
                              <t-btn type="button" margin={false} class="text-sm">Checkout</t-btn>
                            </RouterLink>
                          </div>
                        )
                      })
                    }
                  </div>
                );
              default: exhaustiveCaseGuard(payableInvoices.value)
            }
          })()
        }
      </>
    )
  }
})
