import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import { createSelect } from "src/helpers/schemaService";
import { PageItemContainer, RegistrationExpandedForStore, RegistrationI } from "src/interfaces/Store/registration";
import * as ilapi from "src/composables/InleagueApiV1"
import { Guid } from "src/interfaces/InleagueApiV1";
import { type AxiosInstance } from "axios";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { ref } from "vue";

type __fixme__GateFunction = any
const __gateFunctions = ReactiveReifiedPromise<__fixme__GateFunction[]>()

export const RegistrationStore = (() => {
  const state = ref(freshState());

  function createCustomFieldsOptionFormat(customFields: {fieldID: string, name: string}[]) {
    const fieldsOptionFormat = createSelect(customFields, 'fieldID', 'name')
    directCommit_setCustomFieldsOptionFormat(fieldsOptionFormat)
  }
  async function getCustomFields() {
    if(state.value.customFields.length===0){
      try {
        const response = await axiosInstance.get('/v1/players/customFields')
        return response.data.data
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    } else {
      return state.value.customFields
    }
  }
  function getCustomField(customFieldID: any){
    for(let i=0; i<state.value.customFields.length; i++) {
      if(state.value.customFields[i].fieldID===customFieldID) {
        return state.value.customFields[i]
      }
    }
    return {}
  }
  async function getGateFunctions () : Promise<__fixme__GateFunction[]> {
    if(state.value.gateFunctions.length===0) {
      try {
        const gateFunctions = await (__gateFunctions.underlying.status === "idle"
          ? __gateFunctions.run(async () => {
            const response = await axiosInstance.get('/v1/registration/gateFunctions')
            return response.data.data;
          }).getResolvedOrFail()
          : __gateFunctions.getResolvedOrFail())

        directCommit_setGateFunctions(gateFunctions);

        return gateFunctions
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
        return []
      }
    } else {
      return state.value.gateFunctions
    }
  }
  function createGateFunctionsOptionFormat(gateFunctions: __fixme__GateFunction[]) {
    const gateFunctionsOptionFormat = createSelect(gateFunctions, 'name', 'label')
    directCommit_setGateFunctionsOptionFormat(gateFunctionsOptionFormat)
  }
  async function getPageItem(pageItemID: string) {
    try {
      const response = await axiosInstance.get(`/v1/registration/pageItem`, {params: {pageitemid: pageItemID}})
      directCommit_setPageItem(response.data.data)
    } catch (error) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(error)
    }
  }
  async function getCompetitionRegistrations(args: {playerID: Guid, seasonUID: Guid}) {
    const fresh = await ilapi.getCompetitionRegistrations(axiosInstance, {playerID: args.playerID, seasonUID: args.seasonUID});
    const mapped : RegistrationI["competitionRegistrations"][string][string] = {};
    for (const competitionRegistration of fresh) {
      mapped[competitionRegistration.competitionRegistrationID] = competitionRegistration;
    }

    directCommit_setCompetitionRegistrations({playerID: args.playerID, seasonUID: args.seasonUID, competitionRegistrations: mapped})

    return mapped;
  }

  /**
   * Set the store-global "regData" property, to whatever RegistrationAnswers object is relevant for the current flow
   * commit(regData) was being called directly in code at one time; does that return a promise? Probably that was the motivating factor, avoiding promises
   */
  async function setRegData(registrationAnswers: RegistrationExpandedForStore) : Promise<void> {
    directCommit_setRegData(registrationAnswers);
  }
  async function getRegData() : Promise<RegistrationExpandedForStore | null> {
    return state.value.regData;
  }

  function directCommit_setRegFormInput(data: any) {
    state.value.regFormInput = data
  }
  function directCommit_setRegData(data: RegistrationExpandedForStore) {
    state.value.regData = data;
  }
  function directCommit_setEsignData(data: any) {
    state.value.esignData = data
  }
  function directCommit_setPageItem(data: PageItemContainer) {
    state.value.pageItems[data.id]=data
  }
  function directCommit_setCustomFieldsOptionFormat(data: any) {
    state.value.customFieldsOptionFormat = data
  }
  function directCommit_setCustomFields(data: any) {
    state.value.customFields=data
  }
  function directCommit_setGateFunctions(data: any) {
    state.value.gateFunctions=data
  }
  function directCommit_setGateFunctionsOptionFormat(data: any) {
    state.value.gateFunctionsOptionFormat=data
  }
  function directCommit_setDonation(data: {registrationID: string, donation: string}) {
    state.value.donations[data.registrationID]=data.donation
  }
  function directCommit_setCompetitionVolunteer(data: RegistrationI["volunteerRequirementsFlow"]) {
    state.value.volunteerRequirementsFlow = data;
  }
  function directCommit_setContactAndVolunteerDetailsUpdateFlow(data: RegistrationI["contactAndVolunteerDetailsUpdateFlow"]) {
    state.value.contactAndVolunteerDetailsUpdateFlow = data;
  }
  function directCommit_setCompetitionRegistrations(data: {playerID: string, seasonUID: string, competitionRegistrations: RegistrationI["competitionRegistrations"][string][string]}) {
    state.value.competitionRegistrations[data.playerID] ??= {};
    state.value.competitionRegistrations[data.playerID][data.seasonUID] = data.competitionRegistrations;
  }

  return {
    get value() {
      return state.value;
    },
    directCommit_setContactAndVolunteerDetailsUpdateFlow,
    directCommit_setCompetitionVolunteer,
    setRegData,
    getRegData,
    getCustomField,
    getGateFunctions,
    directCommit_setGateFunctions,
    createGateFunctionsOptionFormat,
    getCustomFields,
    directCommit_setCustomFields,
    createCustomFieldsOptionFormat,
    directCommit_setPageItem,
    getPageItem,
  }
})()

function freshState(): RegistrationI {
  return {
    regFormInput: {},
    regData: null,
    esignData: {},
    pageItems: {},
    customFieldsOptionFormat: {} as {[key:string]: string},
    customFields: [],
    gateFunctions: [],
    gateFunctionsOptionFormat: {},
    donations: {} as {[key:string]: string},
    volunteerRequirementsFlow: undefined,
    contactAndVolunteerDetailsUpdateFlow: undefined,
    competitionRegistrations: {}
  }
}

export async function getRegistrationExpandedForStore(axios: AxiosInstance, registrationID: Guid) : Promise<RegistrationExpandedForStore> {
  return await ilapi.getRegistration(
    axios, {registrationID, expand: ["competitions", "registrationAnswers"]}) as RegistrationExpandedForStore;
}
