import { AxiosInstance } from "axios"
import { exhaustiveCaseGuard } from "src/helpers/utils";

/**
 * Boilerplate for our very common usage of axios to just do an RPC against some handler.
 * auth credentials and other configuration info are expected to be contained within the axios instance when the generated
 * methods are invoked.
 * @apiV1Rootedpath should be something like "v1/someHandler/someMethod"
 */
export function rpc<Args, Result>(method: "get" | "post" | "put" | "delete", apiV1RootedPath: string) {
  return async (ax: AxiosInstance, args: Args) : Promise<Result> => {
    const response = await (() => {
      switch (method) {
        case "get":
          return ax.get(apiV1RootedPath, {params: args})
        case "post":
          return ax.post(apiV1RootedPath, args)
        case "delete":
          return ax.delete(apiV1RootedPath, {params: args})
        case "put":
          return ax.put(apiV1RootedPath, args)
        default: exhaustiveCaseGuard(method)
      }
    })();
    return response.data.data
  }
}
