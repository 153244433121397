import { computed } from "vue";
import { SeasonItem, CompetitionItem, DivisionItem, TeamChooserMenu, TeamItem } from "src/composables/InleagueApiV1.TeamChooser2";
import { Reflike, UiOption, VueNeverUnwrappable, exhaustiveCaseGuard, isGuidUpper } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";

export interface TeamChooserSelection {
  seasonUID: null | Guid,
  competitionUID: null | Guid,
  divID: null | Guid,
  teamIDs: null | Guid[],
}

export type CompleteTeamChooserSelection = {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  teamIDs: Guid[],
}

export interface CompleteTeamChooserSelectionSingular {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  teamID: Guid,
}

export function teamChooserSelection() : TeamChooserSelection {
  return {
    seasonUID: null,
    competitionUID: null,
    divID: null,
    teamIDs: null,
  }
}

export type BasicTeamChooserSelectionManager = ReturnType<typeof BasicTeamChooserSelectionManager>

export function BasicTeamChooserSelectionManager(props: {
  mut_selection: Reflike<TeamChooserSelection>,
  menu: TeamChooserMenu,
}) {
  const selectedSeasonUID = computed({
    get() {
      return props.mut_selection.value.seasonUID
    },
    set(fresh) {
      rebindSelection(fresh, props.mut_selection.value.competitionUID, props.mut_selection.value.divID, props.mut_selection.value.teamIDs);
    }
  })

  const selectedCompetitionUID = computed({
    get() {
      return props.mut_selection.value.competitionUID
    },
    set(fresh) {
      rebindSelection(props.mut_selection.value.seasonUID, fresh, props.mut_selection.value.divID, props.mut_selection.value.teamIDs);
    }
  })

  const selectedDivID = computed({
    get() {
      return props.mut_selection.value.divID
    },
    set(fresh) {
      rebindSelection(props.mut_selection.value.seasonUID, props.mut_selection.value.competitionUID, fresh, props.mut_selection.value.teamIDs);
    }
  })

  const selectedTeamIDs = computed((() => {
    //
    // We have to lie a little bit to satisfy vue's where a getter/setter must have the same type.
    // At runtime the teamID selector can be either a checkbox (where we get arrays) or a radio (where we get a single value or nothing)
    // After doing work in the setter, we'll have munged the value into definitely an array, but we have to kludge around vue typings a bit.
    //
    type ReallyHasDifferentGetterSetterTypesButLieToVueWith = {
      get: () => Guid[] | null,
      set: (_: Guid[] | null) => void
    }

    return {
      get() : Guid[] | null {
        return props.mut_selection.value.teamIDs
      },
      set(fresh: Guid | Guid[] | null | "") {
        const arrayifiedGuids = (Array.isArray(fresh) ? fresh : [fresh]).filter(isGuidUpper)
        rebindSelection(props.mut_selection.value.seasonUID, props.mut_selection.value.competitionUID, props.mut_selection.value.divID, arrayifiedGuids);
      },
    } as ReallyHasDifferentGetterSetterTypesButLieToVueWith
  })());

  /**
   * walk through the available options and pick "the current selection or the only available option or nothing" at each level
   */
  const computeSelectableSingularValues = () : TeamChooserSelection => {
    const seasons = props.menu.orderedChildren;
    const seasonUID = selectedSeasonUID.value || (mapGetOnly(seasons)?.k ?? null)
    if (!seasonUID) {
      return {
        seasonUID: null,
        competitionUID: null,
        divID: null,
        teamIDs: null
      }
    }

    const competitions = seasons.get(seasonUID)!.orderedChildren;
    const competitionUID = selectedCompetitionUID.value || (mapGetOnly(competitions)?.k ?? null)
    if (!competitionUID) {
      return {
        seasonUID,
        competitionUID: null,
        divID: null,
        teamIDs: null
      }
    }

    const divisions = competitions.get(competitionUID)!.orderedChildren;
    const divID = selectedDivID.value || (mapGetOnly(divisions)?.k ?? null)
    if (!divID) {
      return {
        seasonUID,
        competitionUID,
        divID: null,
        teamIDs: null
      }
    }

    const teams = divisions.get(divID)!.orderedChildren;
    const teamIDs = (() => {
      if (selectedTeamIDs.value && selectedTeamIDs.value.length > 0) {
        return selectedTeamIDs.value;
      }

      const teamID = mapGetOnly(teams)?.k ?? null;
      return teamID ? [teamID] : null;
    })();

    return {
      seasonUID,
      competitionUID,
      divID: divID,
      teamIDs,
    }

    /**
     * Get "the only" K,V pair from a map, or null if the map contains a number of elements that is not exactly 1.
     */
    function mapGetOnly<K,V>(m: Map<K,V>) : {k: K, v: V} | null {
      if (m.size !== 1) {
        return null;
      }

      const r = m.entries().next()

      if (r.done) {
        // https://github.com/microsoft/TypeScript/issues/33353
        return null;
      }
      else {
        return {k: r.value[0], v: r.value[1]}
      }
    }
  }

  /**
   * Update the selection (atomically, so parent sees changes to all properties all at once) based on changes to other selections.
   * Really the menu is a DAG so we could be smarter and only update the children from the point of change, but the tree
   * is always exactly 4 deep so it's not really onerous to just check everything each time.
   */
  const rebindSelection = (seasonUID: Guid | null, competitionUID: Guid | null, divID: Guid | null, teamID: Guid[] | null) : void => {
    // 2 assignments, but this is atomic for vue right? as in, computeds and etc. don't run on each assignment?, and
    // the world only sees results when we're done here...
    // Probably should just do one assignment, from 2 pure computations, really.
    props.mut_selection.value = longestValidMenuPath(props.menu, {seasonUID, competitionUID, divID, teamIDs: teamID});
    props.mut_selection.value = computeSelectableSingularValues();
  }

  const seasonOptions = computed(() => {
    return buildSeasonOptions(props.menu)
  })
  const competitionOptions = computed(() => {
    return selectedSeasonUID.value
      ? buildCompetitionOptions(props.menu, selectedSeasonUID.value)
      : []
  })
  const divisionOptions = computed(() => {
    return selectedSeasonUID.value && selectedCompetitionUID.value
      ? buildDivisionOptions(props.menu, selectedSeasonUID.value, selectedCompetitionUID.value)
      : []
  })
  const teamOptions = computed(() => {
    return selectedSeasonUID.value && selectedCompetitionUID.value && selectedDivID.value
      ? buildTeamOptions(props.menu, selectedSeasonUID.value, selectedCompetitionUID.value, selectedDivID.value)
      : []
  })

  props.mut_selection.value = computeSelectableSingularValues();

  return VueNeverUnwrappable({
    selectedSeasonUID,
    selectedCompetitionUID,
    selectedDivID,
    selectedTeamIDs,
    seasonOptions,
    competitionOptions,
    divisionOptions,
    teamOptions,
  })
}

function optionsOrNoneAvailable(options: UiOption[]) : UiOption[] {
  return options.length === 0 ? [{value: "", label: "None available", attrs: {disabled: true}}] : options;
}

function buildSeasonOptions(menu: TeamChooserMenu) : UiOption[] {
  const root = menu.orderedChildren;
  const options = [...root.entries()]
    .map(([seasonUID, node]) : UiOption => {
      return {
        value: seasonUID,
        label: node.name
      }
    })
  return optionsOrNoneAvailable(options)
}

function isSeasonOptionValid(menu: TeamChooserMenu, seasonUID: Guid) : boolean {
  return !!menu.orderedChildren.get(seasonUID);
}

function buildCompetitionOptions(menu: TeamChooserMenu, seasonUID: Guid) {
  const root = menu.orderedChildren.get(seasonUID)?.orderedChildren;

  if (!root) {
    throw Error(`bad menu competition lookup, seasonUID='${seasonUID}'`)
  }

  const options = [...root.entries()]
    .map(([competitionUID, node]) : UiOption => {
      return {
        value: competitionUID,
        label: node.name
      }
    })

  return optionsOrNoneAvailable(options);
}

function isCompetitionOptionValid(menu: TeamChooserMenu, seasonUID: Guid, competitionUID: Guid) : boolean {
  return !!menu.orderedChildren.get(seasonUID)?.orderedChildren.get(competitionUID)
}

function buildDivisionOptions(menu: TeamChooserMenu, seasonUID: Guid, competitionUID: Guid) : UiOption[] {
  const root = menu.orderedChildren.get(seasonUID)?.orderedChildren.get(competitionUID)?.orderedChildren;

  if (!root) {
    throw Error(`bad menu division lookup, seasonUID='${seasonUID}', competitionUID='${competitionUID}'`)
  }

  const options = [...root.entries()]
    .map(([divID, node]) : UiOption => {
      return {
        value: divID,
        label: node.name
      }
    })
  return optionsOrNoneAvailable(options);
}

function isDivisionOptionValid(menu: TeamChooserMenu, seasonUID: Guid, competitionUID: Guid, divID: Guid) : boolean {
  return !!menu.orderedChildren.get(seasonUID)?.orderedChildren.get(competitionUID)?.orderedChildren.get(divID)
}

function buildTeamOptions(menu: TeamChooserMenu, seasonUID: Guid, competitionUID: Guid, divID: Guid) : UiOption[] {
  const root = menu.orderedChildren.get(seasonUID)?.orderedChildren.get(competitionUID)?.orderedChildren.get(divID)?.orderedChildren
  if (!root) {
    throw Error(`bad menu team lookup, seasonUID='${seasonUID}', competitionUID='${competitionUID}', divID='${divID}'`)
  }

  const options = [...root.entries()]
    .map(([teamID, node]) : UiOption => {
      const name = node.name.trim()
      const headCoaches = node.headCoaches?.trim() ?? ""
      return {
        value: teamID,
        label: name + (headCoaches ? ` (${headCoaches})` : "")
      }
    })
  return optionsOrNoneAvailable(options);
}

function isTeamOptionValid(menu: TeamChooserMenu, seasonUID: Guid, competitionUID: Guid, divID: Guid, teamIDs: Guid[]) : boolean {
  const teamItems = menu.orderedChildren.get(seasonUID)?.orderedChildren.get(competitionUID)?.orderedChildren.get(divID)?.orderedChildren
  if (!teamItems) {
    return false;
  }
  return teamIDs.every(teamID => teamItems.has(teamID))
}

export function getTeamChooserMenuNode(menu: TeamChooserMenu, which: "season", sel: Pick<CompleteTeamChooserSelectionSingular, "seasonUID">) : SeasonItem | undefined;
export function getTeamChooserMenuNode(menu: TeamChooserMenu, which: "competition", sel: Pick<CompleteTeamChooserSelectionSingular, "seasonUID" | "competitionUID">) : CompetitionItem | undefined;
export function getTeamChooserMenuNode(menu: TeamChooserMenu, which: "division", sel: Pick<CompleteTeamChooserSelectionSingular, "seasonUID" | "competitionUID" | "divID">) : DivisionItem | undefined;
export function getTeamChooserMenuNode(menu: TeamChooserMenu, which: "team", sel: CompleteTeamChooserSelectionSingular) : TeamItem | undefined;
export function getTeamChooserMenuNode(menu: TeamChooserMenu, which: "season" | "competition" | "division" | "team", sel: Partial<CompleteTeamChooserSelectionSingular>) : SeasonItem | CompetitionItem | DivisionItem | TeamItem | undefined {
  switch (which) {
    case "season": {
      return menu
        .orderedChildren.get(sel.seasonUID || "")
    }
    case "competition": {
      return menu
        .orderedChildren.get(sel.seasonUID || "")
        ?.orderedChildren.get(sel.competitionUID || "");
    }
    case "division": {
      return menu
        .orderedChildren.get(sel.seasonUID || "")
        ?.orderedChildren.get(sel.competitionUID || "")
        ?.orderedChildren.get(sel.divID || "");
    }
    case "team": {
      return menu
        .orderedChildren.get(sel.seasonUID || "")
        ?.orderedChildren.get(sel.competitionUID || "")
        ?.orderedChildren.get(sel.divID || "")
        ?.orderedChildren.get(sel.teamID || "")
    }
    default: exhaustiveCaseGuard(which)
  }
}

/**
 * @grep bestMenuSelection
 */
export function longestValidMenuPath(menu: TeamChooserMenu, {seasonUID, competitionUID, divID, teamIDs}: TeamChooserSelection) : TeamChooserSelection {
  if (!isSeasonOptionValid(menu, seasonUID || "")) {
    return {
      seasonUID: null,
      competitionUID: null,
      divID: null,
      teamIDs: null
    }
  }
  else if (!isCompetitionOptionValid(menu, seasonUID || "", competitionUID || "")) {
    return {
      seasonUID,
      competitionUID: null,
      divID: null,
      teamIDs: null
    }
  }
  else if (!isDivisionOptionValid(menu, seasonUID || "", competitionUID || "", divID || "")) {
    return {
      seasonUID,
      competitionUID,
      divID: null,
      teamIDs: null
    }
  }
  else if (!isTeamOptionValid(menu, seasonUID || "", competitionUID || "", divID || "", teamIDs ?? [])) {
    return {
      seasonUID,
      competitionUID,
      divID,
      teamIDs: null
    }
  }
  else {
    return {
      seasonUID,
      competitionUID,
      divID,
      teamIDs,
    }
  }
}

/**
 * Map<> is reasonably convenient for it supporting both hashmap lookups and maintaining insertion order,
 * but we have to jump through some hoops to copy it.
 * the `structuredClone` api looks tempting but it's too new to use
 */
function copyMenu(menu: TeamChooserMenu) : TeamChooserMenu {
  return {
    orderedChildren: new Map([...menu.orderedChildren.entries()].map(([seasonUID, item]) => [seasonUID, copySeasonItem(item)]))
  }

  function copySeasonItem(v: SeasonItem) : SeasonItem {
    return {
      ...copyPrimitives(v),
      orderedChildren: new Map([...v.orderedChildren.entries()].map(([competitionUID, item]) => [competitionUID, copyCompetitionItem(item)]))
    }
  }

  function copyCompetitionItem(v: CompetitionItem) : CompetitionItem {
    return {
      ...copyPrimitives(v),
      orderedChildren: new Map([...v.orderedChildren.entries()].map(([divID, item]) => [divID, copyDivItem(item)]))
    }
  }

  function copyDivItem(v: DivisionItem) : DivisionItem {
    return {
      ...copyPrimitives(v),
      orderedChildren: new Map([...v.orderedChildren.entries()].map(([teamID, item]) => [teamID, copyTeamItem(item)]))
    }
  }

  function copyTeamItem(v: TeamItem) : TeamItem {
    return {...copyPrimitives(v)}
  }

  // Save ourselves the heartache of later adding non-primitives that will be copy'd-by-ref.
  // Things we pass in here are expected to be fully roundtrippable to and from json.
  function copyPrimitives<T>(v: T) : T { return JSON.parse(JSON.stringify(v)) }
}

export function pruneIncompleteMenuPaths(menu: TeamChooserMenu) : TeamChooserMenu {
  const incompletePaths = buildIncompletePrefixPaths(menu);

  const freshMenu = copyMenu(menu);

  for (const incompletePath of incompletePaths) {
    pruneIncompletePath(freshMenu.orderedChildren, incompletePath);
  }

  return freshMenu;

  // `orderedChildren` should only be absent on the actual leaf nodes (e.g. leaf nodes representing a leaf of a complete path)
  // which at this time is always the team nodes in season->comp->div->team
  type Node = {orderedChildren?: NodeList}
  type NodeList = Map<string, Node>
  type Path = readonly string[]

  function pruneIncompletePath(root: NodeList, path: Path) {
    if (path.length === 0) {
      throw Error("shouldn't get here");
    }
    else if (path.length === 1) {
      root.delete(path[0]);
      return;
    }
    else {
      const [next, ...rest] = path;
      pruneIncompletePath(getNextNodeListOrFail(root, next), rest);
      return;
    }

    function getNextNodeListOrFail(node: NodeList, key: string) {
      const nodeList = node.get(key)?.orderedChildren
      if (!nodeList) {
        // need to keep "working" path for better diagnostics
        throw Error(`bad element lookup`)
      }
      return nodeList;
    }
  }

  function buildIncompletePrefixPaths(root: Node) : Path[] {
    if (!root.orderedChildren) {
      return []
    }

    return [...root.orderedChildren.entries()].flatMap(([id, node]) => worker(node, [id]));

    function worker(node: Node, currentPath: Path) {
      if (isLeafOfCompletePath(node)) {
        return [];
      }
      else if (isLeafOfIncompletePath(node)) {
        return [[...currentPath]];
      }
      else if (isInnerNode(node)) {
        const incompletePaths : Path[] = []
        for (const [id, childNode] of node.orderedChildren.entries()) {
          incompletePaths.push(...worker(childNode, [...currentPath, id]));
        }

        const everyIncompletePathIsForDirectChild = incompletePaths.every(path => path.length == currentPath.length + 1)
        const everyDirectChildIsPrunable = everyIncompletePathIsForDirectChild && incompletePaths.length === node.orderedChildren.size;
        const hasSomeCompletePath = !everyDirectChildIsPrunable

        if (hasSomeCompletePath) {
          // there was at least 1 valid path, so we'll want to prune only particular paths through some children
          return incompletePaths;
        }
        else {
          // all children were incomplete, this node itself is prunable
          return [[...currentPath]];
        }
      }
      else {
        throw Error("unreachable");
      }
    }

    function isLeafOfCompletePath(v: Node) {
      // the absence of an `orderedChildren` property implies "is leaf node"
      return !v.orderedChildren;
    }
    function isLeafOfIncompletePath(v: Node) {
      // having an `orderedChildren` property, but without any children therein,
      // means this path is incomplete (for ex. a div with no child teams)
      return v.orderedChildren?.size === 0
    }
    function isInnerNode(v: Node) : v is {orderedChildren: NodeList} {
      // has non-empty `orderedChildren`
      return !!(v.orderedChildren && v.orderedChildren.size > 0);
    }
  }
}
