<template lang="pug">
.flex.flex-col.items-end.mb-5(class="md:flex-row")
  h1.text-4xl.font-medium
    font-awesome-icon.mx-4.mt-4.mb-3.cursor-pointer(:icon='["fas", "pencil-alt"]')
    | Edit Registration Item: Text/HTML
  .ml-4(v-if="Object.keys(item).length > 0")
    TBtn(label="Delete Registration Item", color="red", @click="deleteContentChunk")
    TBtn(:label="`${item.isDisabled ? 'Enable ' : 'Disable '} Registration Item`", color="green", @click="val=>disableItem(val)")
div.max-w-4xl(@submit='saveChanges' v-if="Object.keys(item).length > 0")
  quill-editor.bg-white(
    v-if="editorContentReady"
    v-model:value='item.pageItem.defaultText',
    :editor-toolbar='customToolbar',
    :options='editorSettings',
    data-cy='overrideRichEditor'
  )
  .max-w-lg
    .flex.col.no-wrap
      FormKit.m-2(
        label='Label',
        name='label',
        v-model.lazy='item.pageItem.label',
        validations='required',
        data-cy='ccOvrLabel',
        wrapper-class='mt-5'
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Question label displayed on the registration form.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
    .flex.col.no-wrap
      FormKit.m-2(
        label='Short Label',
        name='label',
        v-model.lazy='item.pageItem.shortLabel',
        validations='required',
        data-cy='ccOvrLabel',
        wrapper-class='mt-5'
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Shorthand reference to the registration field used in administration and reporting.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
    .flex
      div.mr-2.my-2
        FormKit.m-2(
          v-model.lazy='item.pageItem.startDate',
          label-class='text-sm t-label w-full block mb-2',
          type='date',
          validation='date:YYYY-MM-DD',
          label='Start date',
          name='startDate',
          data-cy='overrideStartDate',
          wrapper-class='mt-5'
        )
      div.m-2
        FormKit.m-2(
          v-model.lazy='item.pageItem.endDate',
          label-class='text-sm t-label w-full block mb-2',
          type='date',
          validation='date:YYYY-MM-DD',
          label='End date',
          name='endDate',
          data-cy='overrideEndDate',
          wrapper-class='mt-5'
        )
    .w-20.flex.col.no-wrap
      FormKit.m-2(
        label='Order',
        type="number"
        name='order',
        v-model.lazy='item.order',
        validations='required',
      )
      button.ml-1.cursor-pointer(
        v-tooltip='{content: `Order in which the text/HTML is displayed on the registration form.`}'
      )
        font-awesome-icon.mr-2.text-gray-400(
          :icon='["fas", "info-circle"]'
        )
  GateFunctionEditor(@apiError="apiError=true" labelItem="Registration Item" :item="item.pageItem", :gateQuestionType='3', v-model:gateFunction="item.pageItem.gateFunctionName" :triggerSave="triggerSave" :itemID="item.pageItem.id" @updateStatus="status=>updateStatus(status, 'gateFunction')")
  SeasonSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedSeasonsObjects='item.itemSeasons' @update:seasonUIDs="(val)=>item.seasonUIDs=val" :triggerSave="triggerSave" :pageItemID="id" @updateStatus="status=>updateStatus(status, 'season')")
  CompetitionSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedCompetitionsObjects="item.itemCompetitions" @update:competitionUIDs="val=>item.itemCompetitions=val" :triggerSave="triggerSave" :pageItemID="id" @updateStatus="status=>updateStatus(status, 'competition')")
  .flex
    TBtn(type='button', label='Save Registration Item', data-cy='submitOverride' @click="saveContentChunk")
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, Ref, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import SeasonSelector from 'src/components/Registration/admin/SeasonSelector.vue'
import CompetitionSelector from 'src/components/Registration/admin/CompetitionSelector.vue'
import GateFunctionEditor from 'src/components/Registration/admin/GateFunctionEditor.vue'
import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'
import { htmlEditButton } from 'quill-html-edit-button'
import { PageItemContainer } from 'src/interfaces/Store/registration'
import { axiosInstance } from 'src/boot/axios'

import { formatForDateInput } from 'src/helpers/formatDate'

import { RegistrationStore } from "src/store/Registration"

export default defineComponent({
  components: {
    QuillEditor,
    SeasonSelector,
    GateFunctionEditor,
    CompetitionSelector
  },
  setup() {
    const id = ref('')
    const customToolbar = ref([
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'formula'],
      ['clean'],
    ])
    const editorSettings = ref({})
    const item = ref({}) as Ref<PageItemContainer>
    const localInstance = getCurrentInstance()
    const triggerSave = ref(false)
    const $toast = localInstance?.appContext.config.globalProperties.$toast
    const apiError = ref(false)
    const contentChunkStati = ref({}) as Ref<{[key:string]: string}>

    const route = useRoute()
    const router = useRouter()



    const prepIncomingItem = async () => {
      // console.log('prepIncomingItem', route.params.id)
      id.value = route.params.id as string
      if(!RegistrationStore.value.pageItems[id.value]) {
        await RegistrationStore.getPageItem(route.params.id as string)
      }
      item.value = JSON.parse(JSON.stringify(RegistrationStore.value.pageItems[id.value]))
      item.value.pageItem.startDate = formatForDateInput(item.value.pageItem.startDate as string) ? formatForDateInput(item.value.pageItem.startDate as string) : item.value.pageItem.startDate
      item.value.pageItem.endDate = formatForDateInput(item.value.pageItem.endDate as string) ? formatForDateInput(item.value.pageItem.endDate as string) : item.value.pageItem.endDate
    }

    const initializeEditorSettings = () => {
      editorSettings.value = {
        modules: {
          htmlEditButton: {
            msg: "You may edit HTML directly here, and the editor's contents will be replaced when you click Save Changes. Please use this feature carefully.",
          },
        },
      }
    }

    const updatePageItem = async () => {
      apiError.value=false
      const options = {
        order: item.value.order,
        isDisabled: item.value.isDisabled
      }
      try {
        const response = await axiosInstance.put(`/v1/registration/pageItem/${item.value.id}`, options)
      } catch (error) {
        // console.log('error updating pageItem', error)
        apiError.value = true
      }
    }

    const deleteContentChunk = async () => {
      apiError.value=false
      try {
        const response = await axiosInstance.delete(`/v1/registration/contentChunk/${item.value.pageItem.id as string}`)
        $toast.success({message: `Content Chunk ${item.value.pageItem.label} has been deleted`})
        await router.push({name: 'registration-admin'})
      } catch (error) {
        // console.log('deleteContentChunk', error)
        apiError.value = true
      }
    }

    const updateContentChunk = async () => {
      apiError.value = false
      try {
        const response = await axiosInstance.put(`/v1/registration/contentChunk/${item.value.pageItem.id as string}`, {...item.value.pageItem})
        // console.log('response from updateContentChunk', response.data.data)
      } catch (error) {
        // console.log('error in updateContentChunk', error)
        apiError.value=true
      }
    }

    const saveContentChunk = (async () => {
      if(item.value.pageItem.id) {
        await updateContentChunk()
        await updatePageItem()
        contentChunkStati.value.contentChunk='saved'
      }
      triggerSave.value = true
    })

    const disableItem = async () => {

      if(item.value.isDisabled) {
        item.value.isDisabled=0
      } else {
        item.value.isDisabled=1
      }
      await updatePageItem()
    }

    const updateStatus = (status: string, item: string) => {
      contentChunkStati.value[item]=status
    }

    watch(item, (val) => {
      contentChunkStati.value.contentChunk='pending'
    }, {deep: true})

    watch([triggerSave, contentChunkStati], (val: [boolean, {[key: string]: string}])=> {
      if(val[0]) {
        // console.log('watch contentChunkStati', val)
        let allPropertiesStatus = 'saved'
        for(const status in val[1]) {
          if(val[1][status]!="saved") {
            allPropertiesStatus = 'pending'
          }
        }
        // console.log('allPropertiesStatus', allPropertiesStatus, apiError.value)
        if(allPropertiesStatus==='saved') {
          if(!apiError.value) {
            router.push({name: 'registration-admin'}).catch((err) => {// console.log('err', err)
            })
          }
        }
      }
    }, {deep:true})

    watch(apiError, (val)=> {
      if(apiError.value) {
        triggerSave.value = false
      }
    })

    // don't mount quill until we have coherent data to feed it
    const editorContentReady = ref(false);

    onMounted(async ()=> {
      await prepIncomingItem()
      initializeEditorSettings()
      editorContentReady.value = true;
    })

    return {
      customToolbar,
      id,
      editorSettings,
      item,
      triggerSave,
      saveContentChunk,
      deleteContentChunk,
      disableItem,
      apiError,
      updateStatus,
      contentChunkStati,
      editorContentReady,
    }
  },
})
</script>

  <!-- div.flex.no-wrap
    FormKit.flex.wrap(
      label='Short Label',
      type="text"
      name='shortLabel',
      v-model.lazy='item.pageItem.shortLabel',
      validations='required',
      data-cy='label',
      wrapper-class='mt-4'
    )
    button.ml-1.cursor-pointer(
      v-tooltip='{content: `Shorthand reference to the registration field used in administration and reporting.`}'
    )
      font-awesome-icon.mr-2.text-gray-400(
        :icon='["fas", "info-circle"]'
      ) -->
