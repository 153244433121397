<template lang="pug">
div(v-if='componentContent.id === ""')
  | Loading
.w-full(v-else, data-cy='contentChunk')
  .my-class
  .d-pa-md.d-gutter-sm(
    v-if='componentContent.override && showContent(this.componentContent.override.startDate, this.componentContent.override.endDate) && !showEditor'
  )
    div(v-html='this.componentContent.override.overrideText')
    span.font-awesome-icon(
      :icon='["fas", "pencil-alt"]',
      data-cy='editPencil',
      v-show='authService(userRoles, "Webmaster", "inLeague")',
      @click='initiateEditor',
      :style='{ cursor: "pointer" }'
    )
  .d-pa-md.d-gutter-sm(
    v-else-if='showContent(this.componentContent.startDate, this.componentContent.endDate) && !showEditor'
  )
    div(v-html='this.componentContent.defaultText')
    span.font-awesome-icon.fa-pencil-alt(
      data-cy='editPencil',
      v-show='authService(userRoles, "Webmaster", "inLeague")',
      @click='initiateEditor',
      :style='{ cursor: "pointer" }'
    )
  div
    .d-pa-md.d-gutter-sm.bg-gray-100.p-5.rounded-md.shadow-md.mb-5(
      v-show='showEditor',
      data-cy='editor'
    )
      h2.font-bold Default Text (All Leagues)
        FormKit(
          type='checkbox',
          v-model='componentContent.disableRich',
          label='Disable Rich Content',
          name='disableRich',
          wrapper-class='mt-4',
          input-class='bg-white'
        )
      div(v-if='authService(this.userRoles, "inLeague")')
        FormKit(@submit='persistChangesToBaseChunk', :actions='false', type='form')
          div(v-if='componentContent.disableRich')
            FormKit(
              data-cy='defaultPlainEditor',
              v-model='componentContent.defaultText',
              type='textarea',
              rows='5',
              validations='required'
            )
          div(v-else)
            quill-editor.bg-white(
              data-cy='defaultRichEditor',
              v-model:value='componentContent.defaultText',
              :editor-toolbar='customToolbar',
              :editorOptions='editorSettings'
            )
          FormKit.m-2(
            type='group',
            label='Date range (optional)',
            error-behavior='live',
            validation='optional',
            :validation-rules='{ pairedDates: dateValidation }',
            :validation-messages='{ pairedDates: "Please provide a start and end date, or no dates at all" }',
            input-class='bg-white'
          )
            .flex
              FormKit.my-2.mr-2(
                v-model.lazy='componentContent.startDate',
                type='date',
                validation='date:YYYY-MM-DD',
                label-class='text-sm t-label w-full block mb-2',
                label='Start date',
                name='startDate',
                inner-class='bg-white',
                wrapper-class='mt-8'
              )
              FormKit.m-2(
                v-model.lazy='componentContent.endDate',
                type='date',
                validation='date:YYYY-MM-DD',
                label-class='text-sm t-label w-full block mb-2',
                label='End date',
                name='endDate',
                inner-class='bg-white',
                wrapper-class='mt-8 ml-5'
              )
          FormKit.m-2(
            label='Label',
            name='label',
            v-model.lazy='componentContent.label',
            validations='required',
            inner-class='bg-white',
            wrapper-class='mt-6'
          )
          .flex
            TBtn.mx-2(
              type='button',
              label='Save Changes',
              @click='persistChangesToBaseChunk',
              data-cy='submitDefault'
            )
            TBtn.mx-2(
              type='button',
              label='Close Editor',
              @click='closeEditor',
              data-cy='close-editor'
            )
      div(
        v-else='authService(userRoles, "Webmaster")',
        data-cy='default-display'
      )
        div(v-html='componentContent.defaultText')
        div Default Display:

        p(v-if='componentContent.label') Label: {{ componentContent.label }}

        p(v-if='componentContent.startDate') Start Date: {{ componentContent.startDate }}
        p(v-if='componentContent.endDate') End Date: {{ componentContent.endDate }}
      br
      hr
      br
      div(v-if='overrideNotPresent')
        t-btn(
          label='Create Override',
          @click='createOverride',
          data-cy='create-override'
        )
      div(v-else, ref='override')
        h2.mb-3.font-bold Override
        FormKit(type="form" @submit.prevent :actions="false")
          div(v-if='!componentContent.disableRich')
            quill-editor.bg-white(
              v-model:value='componentContent.override.overrideText',
              :editor-toolbar='customToolbar',
              :options='editorSettings',
              data-cy='overrideRichEditor'
            )
          FormKit(
            v-else,
            v-model='componentContent.override.overrideText',
            type='textarea',
            rows='5',
            name='override',
            validation='optional',
            data-cy='overridePlainEditor'
          )
          FormKit.m-2(
            type='group',
            label='Date range (optional)',
            error-behavior='live',
            validation='optional|pairedDates',
            :validation-rules='{ pairedDates: dateValidation }',
            :validation-messages='{ pairedDates: "Please provide a start and end date, or no dates at all" }'
          )
          .flex
            FormKit.my-2.mr-2(
              v-model.lazy='componentContent.override.startDate',
              label-class='text-sm t-label w-full block mb-2',
              type='date',
              validation='date:YYYY-MM-DD',
              label='Start date',
              name='startDate',
              data-cy='overrideStartDate',
              inner-class='bg-white',
              wrapper-class='mt-8'
            )
            FormKit.m-2(
              v-model.lazy='componentContent.override.endDate',
              label-class='text-sm t-label w-full block mb-2',
              type='date',
              validation='date:YYYY-MM-DD',
              label='End date',
              name='endDate',
              data-cy='overrideEndDate',
              inner-class='bg-white',
              wrapper-class='mt-8 ml-5'
            )
          .flex
            TBtn(type='button', label='Save Changes', @click='persistChangesToOverride', data-cy='submitOverride')
            TBtn(type='button', label='Close Editor', @click='closeEditor', data-cy='closedEditorOverride')
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  watch,
  ref,
  nextTick,
  Ref,
  onMounted,
  getCurrentInstance,
  PropType,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  ContentChunk,
  ContentOverrideInterface,
  isCfNull,
} from 'src/interfaces/InleagueApiV1'
import { axiosInstance } from 'src/boot/axios'
import { isPairedDate } from 'src/helpers/validationHelpers'
import authService from 'src/helpers/authService'
import { copyViaJsonRoundTrip } from "src/helpers/utils"

import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'

import { User } from 'src/store/User'
import { ContentChunkStore } from "src/store/ContentChunkStore"

/**
 * This component requires that the target content chunk(s) are present in store.state.contentChunk[...]
 * prior to entering this component's `setup` method
 *
 * This component must copy values out of the store in order to mutate them directly (esp. the override),
 * and then place them back into the store as necessary
 */


export default defineComponent({
  name: 'ContentChunkEditor',
  props: {
    // not required but if present must be string | number
    contentChunkId: {
      type: undefined as any as PropType<string | number | undefined>,
      validator: (v: any) => {
        switch (typeof v) {
          case 'string':
          case 'number':
          case 'undefined':
            return true
          default:
            return false
        }
      },
    },
  },
  components: {
    QuillEditor,
  },
  setup(props) {
    const showEditor = ref(false)
    const errors = ref(false)
    const componentContent = ref<ContentChunk>({
      clientID: '',
      defaultText: '',
      disableRich: 0,
      id: '',
      label: '',
      overridden: false,
      override: {} as ContentOverrideInterface,
      shortLabel: '',
      startDate: "",
      endDate: "",
      gateFunctionName: "",
      gateFunctionID: ""
    })
    const overrideNotPresent = ref(false)
    const editorSettings = ref({})
    const customToolbar = ref([
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'formula'],
      ['clean'],
    ])
    const override = ref(null) as Ref<HTMLElement> | Ref<null>

    const localInstance = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()


    // Computed
    const getName = computed(() => {
      return User.value.fullName
    })

    const userRoles = computed(() => {
      return User.value.roles
    })

    const content = computed(() => {
      if (props.contentChunkId) {
        //
        // we require that the parent component has populated the store such that
        // indexing into here is always valid at any point in our lifecycle
        //
        return {
          ...ContentChunkStore.value.content[
            props.contentChunkId.toString().toLowerCase().trim()
          ],
        }
      } else {
        // console.log('NO PROPS')
        return { ...ContentChunkStore.value.content }
      }
    })

    const showContent = (
      startDate: Date | undefined | null,
      endDate: Date | undefined | null
    ) => {
      if (startDate && endDate) {
        return (
          new Date(startDate) < new Date() && new Date(endDate) > new Date()
        )
      } else {
        return true
      }
    }

    const createOverride = () => {
      // console.log('create override called')
      componentContent.value.override = {
        overrideText: componentContent.value.defaultText,
        startDate: componentContent.value.startDate,
        endDate: componentContent.value.endDate,
      } as ContentOverrideInterface
      overrideNotPresent.value = false
    }

    const formatDates = (date: string) => {
      const incomingDate = new Date(date)
      return incomingDate.toISOString().substr(0, 10)
    }

    const dateValidation = ({ value }: { value: unknown }) => {
      console.log(value)
      if (Array.isArray(value)) {
        const [pairedDates] = value
        if (pairedDates.startDate && pairedDates.endDate) {
          return isPairedDate(
            pairedDates.startDate as string,
            pairedDates.endDate as string
          )
        }
      }
      return false
    }

    const closeEditor = async () => {
      if (route.name === 'content-chunk-result') {
        await router.push({
          name: 'content-search',
          query: { search: route.query.search },
        })
      } else {
        showEditor.value = !showEditor.value
      }
    }

    const initiateEditor = async () => {
      showEditor.value = !showEditor.value
      if (
        !componentContent.value.override &&
        componentContent.value.overridden
      ) {
        const updatedContent = await ContentChunkStore.getContentChunk(
          {
            id: props.contentChunkId!,
            override: false,
          }
        )
        componentContent.value = updatedContent
      }
    }

    const persistChangesToBaseChunk = async () => {
      const options = {
        disableRich: componentContent.value.disableRich,
        defaultText: componentContent.value.defaultText,
        endDate: componentContent.value.endDate,
        startDate: componentContent.value.startDate,
        label: componentContent.value.label,
      }
      try {
        const response = await axiosInstance.put(
          `v1/contentChunk/${componentContent.value.id}`,
          options
        )
        ContentChunkStore.setContent(response.data.data)
        if (props.contentChunkId)
          componentContent.value = {
            ...(content.value as unknown as ContentChunk),
          }
        localInstance?.appContext.config.globalProperties.$toast.info({
          message: `Content Chunk ${response.data.data.id} successfully saved. Thank you.`,
        })
      } catch (error) {
        localInstance?.appContext.config.globalProperties.$toast.info({
          message:
            'We ran into a problem saving your content chunk.',
        })
      }
    }

    const persistChangesToOverride = async () => {
      let options
      if (
        componentContent.value.override &&
        componentContent.value.override.endDate
      ) {
        options = {
          overrideText: componentContent.value.override.overrideText,
          endDate: componentContent.value.override.endDate,
          startDate: componentContent.value.override.startDate,
        }
      } else if (componentContent.value.override) {
        options = { overrideText: componentContent.value.override.overrideText }
      }
      try {
        const response = await axiosInstance.put(
          `v1/contentChunkOverride/${componentContent.value.id}`,
          options
        )
        const contentCopy = { ...componentContent.value }
        contentCopy.override = response.data.data
        componentContent.value = contentCopy as unknown as ContentChunk
        ContentChunkStore.setContent(componentContent.value)
        localInstance?.appContext.config.globalProperties.$toast.info({
          message: `Override for Content Chunk ${response.data.data.chunkID} successfully saved. Thank you.`,
        })
      } catch (error) {
        localInstance?.appContext.config.globalProperties.$toast.info({
          message:
            'We ran into a problem saving your content chunk.',
        })
      }
    }

    onMounted(() => {
      if (route.path.includes('content-chunk-result')) showEditor.value = true
      if (props.contentChunkId) {
        componentContent.value = deepCopyContentChunkWithIntentToStripStoreProxyWrapper(content.value as ContentChunk);
      }
      if (!isCfNull(componentContent.value.startDate))
        componentContent.value.startDate = formatDates(
          componentContent.value.startDate
        )
      if (!isCfNull(componentContent.value.endDate))
        componentContent.value.endDate = formatDates(
          componentContent.value.endDate
        )
      if (
        componentContent.value.override &&
        componentContent.value.override.startDate
      )
        componentContent.value.override.startDate = formatDates(
          componentContent.value.override.startDate
        )
      if (
        componentContent.value.override &&
        componentContent.value.override.endDate
      )
        componentContent.value.override.endDate = formatDates(
          componentContent.value.override.endDate
        )
      if (route.query.override === 'true') {
        if (!chunkHasOverride(componentContent.value)) {
          createOverride()
        }
      }

      if (route.query.override === 'true') {
        nextTick(() => {
          override.value?.focus()
        })
      }
      editorSettings.value = {
        modules: {
          htmlEditButton: {
            msg: "You may edit HTML directly here, and the editor's contents will be replaced when you click Save Changes. Please use this feature carefully.",
          },
        },
      }

      overrideNotPresent.value = !chunkHasOverride(componentContent.value);
    })

    watch(componentContent, (value: ContentChunk) => {
      overrideNotPresent.value = !chunkHasOverride(value);
    })

    /**
     * `content` is a computed that changes:
     *   - at least once, on mount, in response to what the target contentID is
     *   - any other times?
     *
     * `content` is a live reference to a value from the store, it may not be edited directly
     * Therefore, when it changes, we must deep copy it
     *
     * Note that `componentContent` (assigned here), is also assigned in at least onMounted
     *
     * todo: clarify -- expected order of assignments to `componentContent`, and expected total number of assignments for one lifecycle?
     * i.e., is this ever intended to overwrite the results of onMounted?
     */
    watch(content, (value: any) => {
      componentContent.value = deepCopyContentChunkWithIntentToStripStoreProxyWrapper(value)
    })

    return {
      authService,
      showEditor,
      errors,
      componentContent,
      overrideNotPresent,
      editorSettings,
      customToolbar,
      override,
      route,
      router,
      getName,
      userRoles,
      content,
      showContent,
      createOverride,
      formatDates,
      dateValidation,
      closeEditor,
      initiateEditor,
      persistChangesToBaseChunk,
      persistChangesToOverride,
      props,
    }
  },
})

/**
 * We anticipate that ContentChunk is "store-bound" (i.e. the data is owned by the app store, so is wrapped in a proxy that watches access/assignment),
 * which means we must not mutate it directly (i.e. `contentChunk.override.foo = "42"` is wrong)
 * We deep copy it to get a copy of it that does not have the store's magic vue proxies hooked onto it
 */
function deepCopyContentChunkWithIntentToStripStoreProxyWrapper(contentChunk: ContentChunk) {
  return copyViaJsonRoundTrip(contentChunk);
}

function chunkHasOverride(contentChunk: ContentChunk) : boolean {
  // is there a more specific test based on a type tag or exact property names we might perform?
  return !!contentChunk.override && Object.keys(contentChunk.override).length > 0;
}
</script>
