import type { Guid } from "src/interfaces/InleagueApiV1"

export default () => {
  const isUserRelated = (namedArgs: {userID: Guid, userList: Guid[]}) => {
    return namedArgs.userList.includes(namedArgs.userID);
  }

  const isChildRelated = (namedArgs: {childID: Guid, childList: Guid[]}) => {
    return namedArgs.childList.includes(namedArgs.childID);
  }

  return {
    isUserRelated, isChildRelated
  }
}
