import { defineComponent, ref, watch, PropType, computed } from 'vue'
import { QuestionType } from 'src/interfaces/InleagueApiV1'
import { UiOption, exhaustiveCaseGuard, parseFloatOr } from 'src/helpers/utils'
import { FormKit } from '@formkit/vue'
import * as iltypes from "src/interfaces/InleagueApiV1"

export const PageItem_Question_PreviewRender = defineComponent({
  props: {
    inputType: {
      type: Number as PropType<QuestionType>,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    selectOptions: {
      type: Array as PropType<{
        optionValue: string,
        optionText: string,
        /**
         * Required, but can be explicitly undefined;
         * Meaning, this option doesn't participate in points.
         */
        points: undefined | iltypes.Numeric
      }[]>,
      default: () => [] // fixme: make this required, who's not passing this and we need a default? -- ah, text/textarea values?
    },
  },
  setup(props) {
    // we assume formkit will not create arrays of complex things for our questions,
    // it's not a supported case. all values are strings, and in the multiple selection case,
    // we should get an array of strings, never an array of objects or etc.
    // null is _our_ tag for "no selection".
    const selectionOrInput = ref<null | string | string[]>(null);

    watch(() => props.selectOptions, () => {
      if (selectionOrInput.value === null || props.selectOptions.length === 0) {
        selectionOrInput.value = null;
        return;
      }

      const freshOptionsHasSomeNonNullishOptionValue = (target: string) => props
        .selectOptions
        .some(opt => opt.optionValue !== "" && opt.optionValue === target);

      if (Array.isArray(selectionOrInput.value)) {
        selectionOrInput.value = selectionOrInput
          .value
          .filter(selected => freshOptionsHasSomeNonNullishOptionValue(selected))
      }
      else {
        const optionStillExists = freshOptionsHasSomeNonNullishOptionValue(selectionOrInput.value)
        if (!optionStillExists) {
          selectionOrInput.value = null;
        }
      }
    }, {deep:true})

    const inferredShouldShowPoints = computed(() => {
      const atLeastOneOptionHasPoints = props.selectOptions.some(v => v.points !== undefined);
      return atLeastOneOptionHasPoints;
    });

    const pointsSum = computed(() => {
      let sum = 0;

      props.selectOptions.forEach(v => {
        const isSelected = Array.isArray(selectionOrInput.value)
          ? selectionOrInput.value.find(s => s === v.optionValue)
          : selectionOrInput.value !== "" && selectionOrInput.value === v.optionValue;

        if (isSelected) {
          sum += parseFloatOr(v.points, null) ?? 0
        }
      });

      return sum;
    })

    const stringifiedSelectionOrInput = computed<string | null>(() => {
      if (selectionOrInput.value === null) {
        return null;
      }
      else if (typeof selectionOrInput.value === "string") {
        return selectionOrInput.value;
      }
      else if (Array.isArray(selectionOrInput.value)) {
        return selectionOrInput.value.join(",")
      }
      else {
        // shouldn't happen; if it does our assumptions about formkit's output are wrong.
        throw Error(`unexpected obj type, JSONifies as ${JSON.stringify(selectionOrInput.value)}`);
      }
    })

    const options = computed<UiOption[]>(() => {
      return props
        .selectOptions
        .map(e => { return {value: e.optionValue, label: e.optionText} });
    })

    const noOptionsOr = (lazyElement: () => JSX.Element) : JSX.Element => {
      return props.selectOptions.length === 0 ? <span class="text-sm italic">No options to display</span> : lazyElement();
    }

    return () => (
      <div>
        <FormKit type="form" name="il_PageItem_Question_PreviewRender" actions={false}>
          {
            (() => {
              switch (props.inputType) {
                case QuestionType.TEXT:
                  return <FormKit type="text" v-model={selectionOrInput.value} label={props.label}/>
                case QuestionType.TEXTAREA:
                  return <FormKit type="textarea" v-model={selectionOrInput.value} label={props.label} />
                case QuestionType.RADIO:
                  return noOptionsOr(() => <FormKit type="radio" v-model={selectionOrInput.value} label={props.label} options={options.value} />)
                case QuestionType.SELECT:
                  return noOptionsOr(() => <FormKit type="select" v-model={selectionOrInput.value} label={props.label} options={[{value: "", label: ""}, ...options.value]} />)
                case QuestionType.CHECKBOX:
                  return noOptionsOr(() => <FormKit type="checkbox" v-model={selectionOrInput.value} label={props.label} options={options.value} />)
                default: exhaustiveCaseGuard(props.inputType);
              }
            })()
          }
        </FormKit>
        <div>
          <div>Resulting value:</div>
          <div class="flex">
            <div class="border-l-2 border-slate-200 pl-1" />
            <div class="ml-1">
              {
                stringifiedSelectionOrInput.value
                  ? <span class="whitespace-pre-wrap">`{stringifiedSelectionOrInput.value}`</span>
                  : <span>&nbsp;</span>
              }
            </div>
          </div>
          <div class="my-1 text-xs">
            {
              inferredShouldShowPoints.value
                ? <span>Points: {pointsSum.value}</span>
                : null
            }
          </div>
          <div class="divide-y">
            {
              stringifiedSelectionOrInput.value
                ? <div class="py-1 text-xs">Note: surrounding `` characters are not part of the actual value and serve only to assist in visualizing leading/trailing spaces.</div>
                : null
            }
          </div>
        </div>
      </div>
    )
  },
})
