import { UnionToIntersection } from "src/helpers/utils"

export interface ColDef<T> {
  [key: string]: any,
  name: string,
  label: string,
  sort?: (l: T, r: T) => -1 | 0 | 1,
  filter?: (v: T) => boolean,
  html: {
    cellValue: (_: T) => JSX.Element | string,
    if?: () => boolean,
  }
  /**
   * if not present (or undefined), means "same as html"
   * if "never", means "not ever part of xlsx output"
   */
  xlsx?:
    | "never"
    | {
        cellValue: (_: T) => string
        if?: () => boolean
      }
}

export function colDefsAsMap<T extends ColDef<any>>(colDefs: readonly T[]) : UnionToIntersection<T extends any ? {[P in T["name"]]: T} : never> {
  const result : Record<string, ColDef<T>> = {};
  for (const colDef of colDefs) {
    result[colDef.name] = colDef;
  }
  return result as any;
}
