import type { ExtractPropTypes, PropType } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router"

import { exhaustiveCaseGuard } from "src/helpers/utils"
import { User } from "src/store/User";
import authService from "src/helpers/authService";
import { Guid } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  // detail: {
  //   required: true,
  //   type: Object as PropType<RoutePropsVariant>
  // }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "TeamAssignments.main",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | Main

export interface Main extends RoutePropsBase {
  routeName: typeof RouteNames.main,
}

export const queryParams = {
  seasonUID: "sid",
  competitionUID: "cuid",
  divID: "divID",
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  switch (v.routeName) {
    case RouteNames.main:
      // fallthrough
      return {name: v.routeName}
    default: exhaustiveCaseGuard(v.routeName);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.main:
      return {
        detail: {
          routeName: name
        }
      }
    default: exhaustiveCaseGuard(name)
  }
}

export function getAllowedDivisions() : "*" | Guid[] {
  const isEffectivelySuperUser = authService(User.value.roles, "playerAdmin", "registrar", "webmaster")
  const isPlayerAdminForSomeCompetition = !!User.userData?.competitionsMemento.find(v => v.isPlayerAdmin)
  if (isEffectivelySuperUser || isPlayerAdminForSomeCompetition) {
    return "*";
  }
  else {
    return User.userData?.divisionsMemento.filter(v => v.writeAccess && v.divID).map(v => v.divID) ?? []
  }
}

export function hasSomeRoutePermission() : boolean {
  const isEffectivelySuperUser = authService(User.value.roles, "playerAdmin", "registrar", "webmaster")
  const isDivisionDirector = authService(User.value.roles, "DD", "director");

  const isPlayerAdminForSomeCompetition = !!User.userData?.competitionsMemento.find(v => v.isPlayerAdmin)
  const isDivisionDirectorForSomeDivision = !!User.userData?.divisionsMemento.find(v => v.writeAccess)

  return isEffectivelySuperUser
    || isDivisionDirector
    || isPlayerAdminForSomeCompetition
    || isDivisionDirectorForSomeDivision;
}
