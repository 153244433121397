import axios, { type AxiosInstance } from "axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as AuthCommon from "./InLeagueApiV1.Authenticate.Common"
import { isInleagueApiError2 } from "./InleagueApiV1";

export async function authenticateMulti(axios: AxiosInstance, args: {username: string, password: string}) : Promise<AuthCommon.AuthenticateMultiResponse> {
  try {
    const response = await axios.post('public/authenticateMulti', args);
    return {type: "single", payload: response.data.data};
  } catch (err_: any) {
    //
    // if we get a 300, it doesn't actually mean "relocate", instead it serves to disambiguate
    // the response payload as being a "authenticateMulti" response (rather than a "authenticateSingle" response)
    //
    if (err_ instanceof AxiosErrorWrapper) {
      const err = err_.unwrap();
      if (err.response?.status === 300 && err.response.data.data) {
        return {type: "multi", payload: err.response.data.data as AuthCommon.LeagueDomainDetails[]};
      }
    }
    // default case, some error we weren't expecting
    throw err_;
  }
}

/**
 * TODO: encode most failure states as return types rather than exceptions (500s and etc. should probably still be exceptions; but particular 401s should be some kind of type)
 */
export async function authenticate(axios: AxiosInstance, args: {username: string, password: string, forMobileDevice: boolean}) : Promise<AuthCommon.AuthenticateResponse> {
  const response = await authenticateRaw(axios, args)
  return response.data.data;
}

export async function authenticateRaw(axios: AxiosInstance, args: {username: string, password: string, forMobileDevice: boolean}) {
  return await axios.post('public/authenticate',
    {
      username: args.username,
      password: args.password,
      forMobileDevice: args.forMobileDevice ? 1 : undefined,
    },
    {
      // with cookies
      withCredentials: true,
    }
  );
}

/**
 * Finalize a 3rd party login flow.
 * This requires that we have some jwt assertion that we are in this flow.
 * MFA challenges / initialization prompts can still occur after this succeeds.
 *
 * happy path:
 * il-client      --(i am user X)-->
 * oauth-provider --(looks legit, this is user X)-->
 * il-server      --(looks legit, I agree user X is trying to login via oauth, here's a token, but you're not logged in yet)-->
 * il-client      --(I got a LoginURLFragment, I would like to complete the login flow, here's your token back, it should jive with this userID)-->
 * il-server      --(looks good, finalizing login (or possibly, you need to run through an MFA step))-->
 * il-client logged in as usual (possibly after completing an MFA step)
 */
export async function authenticateUsingImplied3rdPartyOauthFlow(axios: AxiosInstance, args: {assertion: string}) : Promise<AuthCommon.AuthenticateResponse> {
  const response = await axios.post('public/authenticate/from-oauth',
    {
      assertion: args.assertion,
    },
    {
      // see comments in `authenticate`
      withCredentials: true,
    }
  );
  return response.data.data;
}

/**
 * @param token csrf-like token that serves as proof that this request is valid
 * @param passcode the passcode to verify
 *
 * completes succesfully or throws an HTTP error
 *  - 400: bad or expired cookie, or otherwise invalid request
 *  - 401: good request, invalid code
 */
export async function authenticateWithTOTP(axios: AxiosInstance, args: {userID: iltypes.Guid, token: string, passcode: string, rememberThisDevice: boolean}) : Promise<AuthCommon.AuthenticateResponse_Complete> {
  const response = await axios.post('public/authenticate/totp', {
    userID: args.userID,
    passcode: args.passcode,
    token: args.token,
    rememberThisDevice: args.rememberThisDevice,
  })

  return response.data.data;
}

/**
 * @param code the sms code to verify
 * @param token is sort of like a csrf token, some short lived proof that this is a legit request for this user
 *
 * completes succesfully or throws an HTTP error
 *  - 400: bad or expired cookie, or otherwise invalid request
 *  - 401: good request, invalid code
 */
export async function authenticateWithSMS(axios: AxiosInstance, args: {userID: iltypes.Guid, code: string, token: string, rememberThisDevice: boolean}) : Promise<AuthCommon.AuthenticateResponse_Complete> {
  const response = await authenticateWithSMSRaw(axios, args);
  return response.data.data;
}

export async function authenticateWithSMSRaw(axios: AxiosInstance, args: {userID: iltypes.Guid, code: string, token: string, rememberThisDevice: boolean}) {
  return await axios.post('public/authenticate/sms', {
    userID: args.userID,
    code: args.code,
    token: args.token,
    rememberThisDevice: args.rememberThisDevice,
  })
}

/**
 * @param token csrf-like token that serves as short-lived proof that we are in an mfa-challenge flow
 *
 * generates an SMS 2FA challenge code and sends it to the user's registered phone number. The user must be in the MFA challenge flow, which is
 * information stored in http-only cookies. The userID param here must be coherent with that cookie, not as a security check but as a sanity check.
 */
export async function generateSmsChallengeCode(axiosInstance: AxiosInstance, args: {token: string, userID: iltypes.Guid}) : Promise<{ok: true} | {ok: false, detail: AuthCommon.SmsFailureReason}> {
  try {
    await axiosInstance.post(
      `public/authenticate/sms/generateSmsChallengeCode`,
      args,
      {
        // with cookies
        withCredentials: true,
      }
    );
    return {ok: true}
  }
  catch (err) {
    if (axios.isAxiosError(err) && isInleagueApiError2(err)) {
      const detail = err.response.data.messages[0] as AuthCommon.SmsFailureReason
      return {ok: false, detail}
    }
    throw err;
  }
}

/**
 * @param token csrf-like token, received from server when it says "hey you need to an init flow"
 */
export async function getFreshTotpInitSecret(axios: AxiosInstance, args: {userID: iltypes.Guid, token: string}) : Promise<AuthCommon.TotpSetupKeyDetail> {
  const response = await axios.post(`public/authenticate/totp/reset`, args);
  return response.data.data;
}

/**
 * @param token csrf-like token, received from server when it says "hey you need to an init flow"
 * @param code the code to verify
 */
export async function validateFreshTotpInitSecret(
  axiosInstance: AxiosInstance,
  args: {userID: iltypes.Guid, token: string, passcode: string}
) : Promise<
  | {ok: true, detail: AuthCommon.AuthenticateResponse_Complete}
  | {ok: false, detail: "too-old" | "invalid-code"}
> {
  try {
    const response = await axiosInstance.post(`public/authenticate/totp/validate-fresh`, args);
    return {
      ok: true,
      detail: response.data.data
    }
  }
  catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400 && isInleagueApiError2(e)) {
      const message = e.response.data.messages[0];
      if (message === "too-old" || message === "invalid-code") {
        return {ok: false, detail: message}
      }
    }
    // otherwise, unhandled
    throw e;
  }
}

/**
 * @param token csrf-like token, received from server when it says "hey you need to an init flow"
 *
 * Requires a valid MFA init flow cookie be set in the current browser session.
 * The phone number we send this to is determined on the backend based on the user's primary phone number.
 */
export async function getFreshSmsInitCode(axiosInstance: AxiosInstance, args: {userID: iltypes.Guid, token: string}) : Promise<{ok: true} | {ok: false, detail: AuthCommon.SmsFailureReason}> {
  try {
    await axiosInstance.post(`public/authenticate/sms/reset`, args);
    return {ok: true}
  }
  catch (err) {
    if (axios.isAxiosError(err) && isInleagueApiError2(err)) {
      // these codes are contractual and should be exhaustive
      const detail = err.response.data.messages[0] as AuthCommon.SmsFailureReason
      return {ok: false, detail}
    }
    throw err;
  }

}

/**
 * @param token csrf-like token, received from server when it says "hey you need to an init flow"
 * @param code the code to verify
 */
export async function validateFreshSmsInitCode(
  axiosInstance: AxiosInstance,
  args: {userID: iltypes.Guid, token: string, code: string}
) : Promise<
  | {ok: true, detail: AuthCommon.AuthenticateResponse_Complete}
  | {ok: false, detail: "too-old" | "invalid-code"}
> {
  try {
    const response = await axiosInstance.post(`public/authenticate/sms/validate-fresh`, args);
    return {ok: true, detail: response.data.data}
  }
  catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400 && isInleagueApiError2(e)) {
      const message = e.response.data.messages[0];
      if (message === "too-old" || message === "invalid-code") {
        return {ok: false, detail: message}
      }
    }
    // otherwise, unhandled
    throw e;
  }
}

export async function requestPasswordReset(axios: AxiosInstance, args: {email: string, lastName: string}) : Promise<void> {
  await axios.get(`public/user/${encodeURIComponent(args.email)}/passwordReset?lastName=${encodeURIComponent(args.lastName)}`);
}

export interface FinalizePasswordResetArgs {
  email: string,
  resetKey: string,
  newPassword: string
}

/**
 * Api specifies: Must be submitted against the domain of the user's inLeague instance.
 * The above meaning, the supplied axios instance must be configured to target a particular league's URL
 * (i.e. the user-who-is-reseting-their-password's league's URL), rather than the "league agnostic" base URL
 */
export async function finalizePasswordReset(axios: AxiosInstance, args: FinalizePasswordResetArgs) : Promise<void> {
  const {email, ...putBody} = args;
  await axios.put(`/public/user/${encodeURIComponent(email)}/passwordReset`, putBody)
}

export async function claimUnclaimedAccount_claim(axios: AxiosInstance, args: {jwt: string, newPassword: string}) : Promise<ClaimUnclaimedAccountResult> {
  const response = await axios.post(`/public/user/claimUnclaimedAccount/claim`, args);
  return response.data.data;
}

type ClaimUnclaimedAccountResult =
  | {status: "expired-token"}
  | {status: "ok"}

export async function claimUnclaimedAccount_refreshNewToken(axios: AxiosInstance, args: {jwt: string}) : Promise<void> {
  const response = await axios.post(`/public/user/claimUnclaimedAccount/refreshToken`, args);
  return response.data.data;
}

/**
 * check that some token is valid, with a slop of N minutes (10 at this time, but that's up to the backend)
 * This can help catch some UI annoyances where a form is filled out, submitted, and the response is "oh sorry your token is expired".
 * It cannot catch all such cases (e.g. expires after we do this check) but we try to be helpful.
 *
 * This loops the target email addr back to us, we could pull it from the unbase64'd token but then we'd need to parse a user provided token client side.
 */
export async function claimUnclaimedAccount_tokenExpirationPrecheck(axios: AxiosInstance, args: {jwt: string}) : Promise<ClaimUnclaimedAccountPrecheck> {
  const response = await axios.get(`/public/user/claimUnclaimedAccount/tokenPrecheck`, {params: {jwt: args.jwt}});
  return response.data.data;
}

type ClaimUnclaimedAccountPrecheck =
  | {status: "malformed"}
  | {status: "already-claimed"}
  | {status: "expired", email: string, userFullName: string}
  | {status: "ok", email: string, userFullName: string}
