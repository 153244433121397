import type { AxiosInstance } from 'axios'
import { Division, Integerlike } from 'src/interfaces/InleagueApiV1';

export enum DivisionForm_PermissionsType {
  inLeague = "inleague",
  basic = "basic",
}

interface DivisionForm_Base {
  readonly type: "create" | "update",
  readonly permissionsType: DivisionForm_PermissionsType,
}

export interface DivisionForm_BasicUpdate extends DivisionForm_Base {
  readonly type: "update",
  readonly permissionsType: DivisionForm_PermissionsType.basic,
  readonly prisitine: Division,
  displayName: string,
  slotHours: "" | Integerlike,
  slotMinutes: "" | Integerlike,
}

export interface DivisionForm_InleagueCreate extends DivisionForm_Base {
  readonly type: "create",
  readonly permissionsType: DivisionForm_PermissionsType.inLeague,
  displayName: string,
  slotHours: "" | Integerlike,
  slotMinutes: "" | Integerlike,
  division: string,
  gender: string,
  divNum: "" | Integerlike,
}

export interface DivisionForm_InleagueUpdate extends DivisionForm_Base {
  readonly type: "update",
  readonly permissionsType: DivisionForm_PermissionsType.inLeague,
  readonly prisitine: Division,
  displayName: string,
  slotHours: "" | Integerlike,
  slotMinutes: "" | Integerlike,
  division: string,
  gender: string,
  divNum: Integerlike,
}

export type DivisionCreateForm = DivisionForm_InleagueCreate
export type DivisionUpdateForm = DivisionForm_BasicUpdate | DivisionForm_InleagueUpdate

export async function createDivision(ax: AxiosInstance, args: DivisionCreateForm) : Promise<Division> {
  const response = await ax.post(`v1/division`, args);
  return response.data.data;
}

export async function updateDivision(ax: AxiosInstance, args: DivisionUpdateForm) : Promise<Division> {
  const response = await ax.put(`v1/division/${args.prisitine.divID}`, args);
  return response.data.data;
}
