<template lang="pug">
div(class="mb-6")
  RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")
h1.ml-2.text-2xl.font-medium(class='md:text-4xl')
  font-awesome-icon.mr-2(:icon='["fas", "search"]')
  | Player Registration - {{ season.name }}

  .text-2xl.text-gray-700.font-normal.mt-4.ml-2.italic Select a player to register for {{ season.name }}
.t-page.flex.flex-col.max-w-md.justify-center(class='md:mx-2')
  .q-pa-md.quasar-style-wrap.mt-8.m-2.max-w-sm(
    data-cy='searchResultsTable',
    v-if='players.length'
  )
    q-table(
      :rows='players',
      :columns='columns',
      row-key='individualID',
      :rows-per-page-options='[0]',
      hide-pagination
    )
      template(v-slot:body-cell-Player='props')
        q-td.cursor-pointer.full-width.font-semibold(
          :props='props',
          :data-test="`childID=${props.row.childID}`"
          v-if='props.row.openCompetitions && props.row.openCompetitions.length > 0',
          @click='toCompetitions(props.row)'
        )
          | {{ props.row.playerFirstName }} {{ props.row.playerLastName }}
          .font-normal Birthdate: {{ dayJSDate(props.row.playerBirthDate) }}
        q-td.full-width.disabled(:props='props', v-else)
          | {{ props.row.playerFirstName }} {{ props.row.playerLastName }}
          .font-normal Birthdate: {{ dayJSDate(props.row.playerBirthDate) }}
          .text-xs.italic (No Eligible Open Competitions)
  t-btn.ml-2(width='w-96', label='Add a New Player', @click='toAddPlayer')

  router-link(:to='{ name: "family-profile" }')
    t-btn.ml-2(width='w-96', label='Return to Family Profile')
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { useRouter, useRoute, RouteLocationRaw } from 'vue-router'
import { defineComponent, onMounted, ref, Ref, computed, PropType } from 'vue'
import { Player } from 'src/interfaces/registration'

import { dayJSDate } from 'src/helpers/formatDate'
import { ContactAndVolunteerDetailsUpdateFlow } from 'src/composables/registration'
import { exhaustiveCaseGuard } from 'src/helpers/utils';

import { type Child } from 'src/interfaces/InleagueApiV1'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import * as R_ContactAndVolunteerDetailsUpdateFlow from "src/components/Registration/selections/R_ContactAndVolunteerDetailsUpdateFlow.route"
import * as R_SelectCompetitions from 'src/components/Registration/selections/R_SelectCompetitions.route'
import * as RegistrationJourneyBreadcrumb from 'src/components/Registration/RegistrationJourneyBreadcrumb'

export default defineComponent({
  name: 'Select Player',
  components: {
    ContentChunkDisplay,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
  },
  props: {
    seasonUID: {
      required: true,
      type: String,
    },
    preselectedPlayerID: {
      required: false,
      type: String as any as PropType<string | undefined>
    },
  },
  setup(props) {
    const players = ref([]) as Ref<Player[]>
    const season = ref({})

    const router = useRouter()
    const route = useRoute()




    const columns = computed(() => {
      return [
        {
          name: 'Player',
          label: 'Players',
          required: true,
          align: 'left',
          field: (player: Player) => player,
          sortable: true,
          classes: 'q-table',
        },
      ]
    })

    const getChildren = async () => {
      try {
        const response = await axiosInstance.get('v1/children')
        players.value = response.data.data
      } catch (error) {}
    }

    const getCompetitions = async () => {
      for (let i = 0; i < players.value.length; i++) {
        try {
          const response = await axiosInstance.get(
            'v1/competitionEligibility',
            {
              params: {
                seasonUID: props.seasonUID,
                childID: players.value[i].childID,
              },
            }
          )
          players.value[i].openCompetitions = response.data.data
        } catch (error) {}
      }
    }

    const toCompetitions = async (player: Child, navType: "push" | "replace" = "push") => {
      User.directCommit_setChildren(player)

      const usersNeedingUpdate =
        await ContactAndVolunteerDetailsUpdateFlow.checkRequirement(
          axiosInstance,
          player.familyID,
          props.seasonUID
        )

      let routeLocation : RouteLocationRaw;

      // need a way to test this
      // const DEV_FORCE_FLOW = true
      if (/*DEV_FORCE_FLOW ||*/ usersNeedingUpdate.length > 0) {
        // for dev we want to see that this flow is triggered
        // to test refresh, `ContactAndVolunteerDetailsUpdateFlow` must be similarly patched
        ///* @DEV_FORCE_FLOW */const usersNeedingUpdate = [{userID: User.value.userID, isParent1: true, isParent2: false,}, {userID: User.value.userID, isParent1: false, isParent2: true}];

        await ContactAndVolunteerDetailsUpdateFlow.setFlowState_fromPreCheck(
          axiosInstance,
          player.childID,
          player.familyID,
          props.seasonUID,
          usersNeedingUpdate
        )

        routeLocation = R_ContactAndVolunteerDetailsUpdateFlow.routeDetailToRouteLocation({
            childID: player.childID,
            seasonUID: props.seasonUID,
            userListIndex: 0,
            step: 1,
        })
      }
      else {
        routeLocation = R_SelectCompetitions.routeDetailToRouteLocation(R_SelectCompetitions.RouteNames.selectCompetitions, {
          playerID: player.childID,
          seasonUID: props.seasonUID
        })
      }

      switch (navType) {
        case "push":
          await router.push(routeLocation);
          break;
        case "replace":
          await router.replace(routeLocation);
          break;
        default: exhaustiveCaseGuard(navType);
      }
    }

    const toAddPlayer = async () => {
      await router.push({ name: 'add-player' })
    }

    onMounted(async () => {
      // support jumping here from elsewhere, where we've already chosen a player but want to run the "selected player" logic defined in this component
      if (props.preselectedPlayerID !== undefined && props.preselectedPlayerID !== "") {
        // if it's not undefined or an empty string, it is a GUID
        // a test for `isGuid` would be nicer
        const player = await User.getChild(props.preselectedPlayerID);
        await toCompetitions(player, /*navType*/ "replace");
        return;
      }

      // n.b. resolve season first so it shows in UI while waiting on the rest
      season.value = await Client.getSeasonByUidOrFail(props.seasonUID)
      await getChildren()
      await getCompetitions()
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.selectPlayer,
          seasonUID: props.seasonUID
        }
      }
    })

    return {
      players,
      columns,
      toCompetitions,
      toAddPlayer,
      dayJSDate,
      season,
      registrationJourneyBreadcrumbProps
    }
  },
})
</script>
