import { AxiosInstance } from "axios"
import { ContactAndVolunteerDetailsUpdateFlowState, VolunteerRequirementsFlowState } from "src/interfaces/Store/registration"


import * as ilapi from "src/composables/InleagueApiV1"
import { CompositeVolunteerRequirement, FamilySeasonCompetitionVolunteerRequirementsCheck, FamilyUsersNotHavingUserSeasonRecordsForSeason, Guid, User, VolunteerRequirementKey } from "src/interfaces/InleagueApiV1";
import { buildVolunteerCodeCountsOmittingTargetUser } from "src/components/Registration/selections/common";
import { type RegistrationExpandedForStore } from "src/interfaces/Store/registration";
import { getRegistrationExpandedForStore } from "src/store/Registration"
import { exhaustiveCaseGuard, setIsSame, UiOption, unsafe_objectKeys } from "src/helpers/utils"
import { RegistrationStore } from "src/store/Registration"

/**
 * get / set route flow state for the registration contact / volunteer details flow
 */
export const ContactAndVolunteerDetailsUpdateFlow = (() => {
  /**
   * figure out who needs to do their contact / volunteer details updates
   */
  async function checkRequirement(
    axios: AxiosInstance,
    familyID: string,
    seasonUID: string
  ): Promise<FamilyUsersNotHavingUserSeasonRecordsForSeason[]> {
    const result = await ilapi.getUserIDsOfUsersNotHavingUserSeasonRecordsForSeason(
      axios,
      familyID,
      seasonUID
    );

    return result.filter(v => v.isParent1 || v.isParent2);
  }

  /**
   * rehydrate when the caller has already done a precheck and we can avoid re-doing it
   */
  async function setFlowState_fromPreCheck(
    axios: AxiosInstance,
    childID: string,
    familyID: string,
    seasonUID: string,
    precheck: FamilyUsersNotHavingUserSeasonRecordsForSeason[]
  ): Promise<ContactAndVolunteerDetailsUpdateFlowState | undefined> {
    if (precheck.length === 0) {
      RegistrationStore.directCommit_setContactAndVolunteerDetailsUpdateFlow(undefined);
      return undefined;
    }

    const userInfo: User[] = await Promise.all(precheck.map(v => ilapi.getUser(axios, v.userID)));

    const result: ContactAndVolunteerDetailsUpdateFlowState = {
      expectedRoute: {
        childID,
        familyID,
        seasonUID
      },
      // sorted because an (arbitrary but consistent) order is important for this route, which loops over the listing
      usersRequiringUpdate: userInfo.sort((l, r) => {
        const vl = l.ID.toUpperCase();
        const vr = r.ID.toUpperCase();
        return vl < vr ? -1 : 1;
      })
    }

    RegistrationStore.directCommit_setContactAndVolunteerDetailsUpdateFlow(result);

    return result;
  }

  /**
   * rehydrate when caller knows only the route params
   */
  async function setFlowState_full(
    axios: AxiosInstance,
    childID: string,
    familyID: string,
    seasonUID: string
  ): Promise<ContactAndVolunteerDetailsUpdateFlowState | undefined> {

    // // devonly, this should be in a test
    // const precheck : FamilyUsersNotHavingUserSeasonRecordsForSeason[] = [
    //     {userID: User.value.userID, isParent1: true, isParent2: false},
    //     {userID: User.value.userID, isParent1: false, isParent2: true}
    // ]

    const precheck = await checkRequirement(axios, familyID, seasonUID);
    const result = await setFlowState_fromPreCheck(axios, childID, familyID, seasonUID, precheck);

    return result;
  }

  /**
   * pull from store or rehydrate as necessary
   */
  async function getFlowState(
    axios: AxiosInstance,
    childID: string,
    familyID: string,
    seasonUID: string
  ): Promise<ContactAndVolunteerDetailsUpdateFlowState | undefined> {
    const current = RegistrationStore.value.contactAndVolunteerDetailsUpdateFlow;
    if (!current || current.expectedRoute.childID != childID || current.expectedRoute.seasonUID != seasonUID) {
      const result = await setFlowState_full(axios, childID, familyID, seasonUID);
      return result;
    }
    else {
      return current;
    }
  }

  return {
    checkRequirement,
    setFlowState_fromPreCheck,
    setFlowState_full,
    getFlowState,
    routeEntry: "program-registration-contact-and-volunteer-details-update-flow" as const
  }
})();

export const RegistrationVolunteerRequirementsFlow = (() => {
  async function setFlowState_fromPreChecks(
    axios: AxiosInstance,
    familyID: string,
    userID: string,
    seasonUID: string,
    competitionUIDs: Guid[],
    precheck: FamilySeasonCompetitionVolunteerRequirementsCheck
  ): Promise<VolunteerRequirementsFlowState | undefined> {
    const volunteerDetails = await ilapi.getVolunteerDetails(axios, userID, seasonUID);

    const result: VolunteerRequirementsFlowState = {
      expectedRoute: {
        familyID,
        competitionUIDs,
        seasonUID,
        userID,
      },
      staticVolunteerRequirementsCheck: {
        ...precheck,
        staticCountsOmittingTargetUser: buildVolunteerCodeCountsOmittingTargetUser(precheck, userID),
      },
      volunteerDetails
    }

    if (result.staticVolunteerRequirementsCheck.satisfiesVolunteerRequirement) {
      // all requirements already satisfied
      RegistrationStore.directCommit_setCompetitionVolunteer(undefined);
      return undefined;
    }
    else {
      RegistrationStore.directCommit_setCompetitionVolunteer(result);
      return result;
    }
  }

  async function setFlowState_full(
    axios: AxiosInstance,
    familyID: string,
    userID: string,
    seasonUID: string,
    competitionUIDs: Guid[]
  ): Promise<VolunteerRequirementsFlowState | undefined> {
    const requirements = await ilapi.checkFamilySeasonCompetitionVolunteerRequirementsManyComps(axios, familyID, seasonUID, competitionUIDs);
    const mergedRequirements = mergeManyFamilySeasonCompVolReqChecks(requirements);
    const result = await setFlowState_fromPreChecks(axios, familyID, userID, seasonUID, competitionUIDs, mergedRequirements);
    return result;
  }

  async function getFlowState(
    axios: AxiosInstance,
    familyID: string,
    userID: string,
    seasonUID: string,
    competitionUIDs: Guid[]
  ): Promise<VolunteerRequirementsFlowState | undefined> {
    const current = RegistrationStore.value.volunteerRequirementsFlow;
    if (!current
      || current.expectedRoute.familyID !== familyID
      || current.expectedRoute.userID !== userID
      || current.expectedRoute.seasonUID !== seasonUID
      || !setIsSame(new Set(current.expectedRoute.competitionUIDs), new Set(competitionUIDs))) {
      const result = await setFlowState_full(axios, familyID, userID, seasonUID, competitionUIDs);
      return result;
    }
    else {
      return current;
    }
  }

  return {
    setFlowState_fromPreChecks,
    setFlowState_full,
    getFlowState,
    routeEntry: "program-registration-volunteer-requirements-flow" as const
  }
})();

export const CompetitionRegistrationFlow = (() => {
  /**
   * get a registration expanded to include those items relevant to a competition registration flow
   * return type would be more accurate as `Promise<WithDefinite<Registration, "competitions" | "registrationAnswers">>`
   * i.e. it is a Registration with some expanded expandables
   */
  async function getExpandedRegistration(axios: AxiosInstance, registrationID: Guid) : Promise<RegistrationExpandedForStore> {
    return await getRegistrationExpandedForStore(axios, registrationID);
  }

  async function setFlowState_expandedRegistration(v: RegistrationExpandedForStore) : Promise<void> {
    await RegistrationStore.setRegData(v);
  }

  /**
   * Update fees that the user is allowed to update (donation, and (depending on league config) pay-what-you-can)
   * Generally this is done immediately after create/update of registration/competitionRegistration, during which the "main fee" is computed
   * We update our local copy of the target competition registration because information about it can change
   * (i.e. the associated invoice may be invalidated if one existed and we perform any mutations against fees)
   */
  async function updateUserMutableFees(axios: AxiosInstance, regID: Guid, mutableFees: ilapi.IncompleteCompetitionRegistrationUserMutableFeeInfo[]) {
    await ilapi.updateIncompleteCompetitionRegistrationUserMutableFeeInfo(axios, mutableFees);

  }

  /**
   * pull `RegistrationAnswers` (a Registration record expanded for use in the competition registration flow) either from flow state cache or from the server
   */
  async function getFlowState_expandedRegistration(axios: AxiosInstance, registrationID: Guid) : Promise<RegistrationExpandedForStore> {
    const regData = await RegistrationStore.getRegData();
    if (regData?.registrationID === registrationID) {
      // we assume if the ID matches that it is fresh enough to use
      return regData;
    }
    else {
      return await getExpandedRegistration(axios, registrationID);
    }
  }

  return {
    getExpandedRegistration,
    setFlowState_expandedRegistration,
    getFlowState_expandedRegistration,
    updateUserMutableFees,
  }
})();

/**
 * Investigate: move this to the backend; alot of this relies on details of backend implementation.
 *
 * Prior to this we were only ever running one check at a time, because users could only register for one competition at a time.
 * With the change to allow registering for more than 1 competition at a time, we need to aggregate multiple checks into one.
 * It should be noted:
 * - volunteer codes (the codes that satisfy per-competition requirements) are by (season, user) (as in, not by competition)
 * - family-season-competition-volunteer requirements checks mean "check the competition's volunteer requirements, for the season, for all users in the family"
 * - family-season-competition-volunteer checks are thus checks for all users in particular family, for some season, using volunteer requirements for some competition
 *   - because of this, the userIDs in the "codesByUser" mapping in each check will be the same (because the familyID across all checks is the same)
 *   - each check will produce the same "codesByUser" (because the season is the same across all checks); those codes may or may not satisfy the competition's volunteer requirements
 * - We want the "aggregated max" of all volunteer requirements across all checks (e.g, which competition has 'the most' requirements -- we need to satisfy that)
 */
export function mergeManyFamilySeasonCompVolReqChecks(
  checks: FamilySeasonCompetitionVolunteerRequirementsCheck[]
) : FamilySeasonCompetitionVolunteerRequirementsCheck {

  sanityCheck();

  const unsatisfied = checks.filter(v => !v.satisfiesVolunteerRequirement);
  if (unsatisfied.length === 0) {
    return checks[0]
  }

  return {
    satisfiesVolunteerRequirement: false,
    // see comments above; all codes by user are structurally equal
    codesByUser: unsatisfied[0].codesByUser,
    volunteerRequirements: (() => {
      // because volunteer requirement is expected to have a single top level key (see sanity check),
      // this is just a max all unique requirements
      const maxs = {
        [VolunteerRequirementKey.HEAD_COACH]: undefined as undefined | number,
        [VolunteerRequirementKey.ASST_COACH]: undefined as undefined | number,
        [VolunteerRequirementKey.REFEREE]: undefined as undefined | number,
        [VolunteerRequirementKey.ANY]: undefined as undefined | number,
      }
      for (const check of checks) {
        for (const req of check.volunteerRequirements) {
          const key = Object.keys(req)[0] as VolunteerRequirementKey
          switch (key) {
            case VolunteerRequirementKey.HEAD_COACH:
            case VolunteerRequirementKey.ASST_COACH:
            case VolunteerRequirementKey.REFEREE:
            case VolunteerRequirementKey.ANY:
              maxs[key] = Math.max(maxs[key] ?? 0, req[key]!);
              continue;
            default: exhaustiveCaseGuard(key)
          }
        }
      }
      const result : CompositeVolunteerRequirement[] = []
      for (const k of unsafe_objectKeys(maxs)) {
        const v = maxs[k]
        if (v !== undefined) {
          result.push({[k]: v})
        }
      }
      return result;
    })()
  }

  /**
   * Assert the following: across all checks
   *  - the resulting "codesByUser" objects are structurally equal
   *  - all volunteerRequirements are objects having a single top level key
   * See comments above for why we beleive this to be the case.
   */
  function sanityCheck() {
    // scan through all pairs and compare for structural equality
    for (let i = 0; i < checks.length - 1; i++) {
      const a = checks[i]
      const b = checks[i]
      assertTrue(setIsSame(new Set(Object.keys(a.codesByUser)), new Set(Object.keys(b.codesByUser))))
      for (const userID of Object.keys(a.codesByUser)) {
        assertTrue(a.codesByUser[userID][VolunteerRequirementKey.HEAD_COACH] === b.codesByUser[userID][VolunteerRequirementKey.HEAD_COACH]);
        assertTrue(a.codesByUser[userID][VolunteerRequirementKey.ASST_COACH] === b.codesByUser[userID][VolunteerRequirementKey.ASST_COACH]);
        assertTrue(a.codesByUser[userID][VolunteerRequirementKey.REFEREE] === b.codesByUser[userID][VolunteerRequirementKey.REFEREE]);
        assertTrue(setIsSame(new Set(a.codesByUser[userID].flex), new Set(b.codesByUser[userID].flex)))
      }
    }

    for (const check of checks) {
      for (const req of check.volunteerRequirements) {
        // see comments on CompositeVolunteerRequirement
        assertTrue(Object.keys(req).length === 1, "vol requirements has a single top level key")
      }
    }

    function assertTrue(v: boolean, msg: string = "AssertionFailure") {
      if (!v) {
        throw Error(msg);
      }
    }
  }
}

export function wellKnownRegistrationGrades() : UiOption[] {
  return [
    { label: 'Pre-school', value: 'Pre-School' },
    { label: 'Pre-K', value: 'Pre-K' },
    { label: 'K', value: 'K' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: 'College', value: 'College' },
  ]
}
