<template lang="pug">
.flex.flex-col.items-start.mb-5.justify-between(class="md:flex-row md:items-center")
  h1.text-4xl.font-medium.mb-4
    i.mr-2.fas.fa-users-cog
    | Registration Configuration
  div.ml-0.text-green-700.text-3xl.font-medium.cursor-pointer(@click="toNewPageItem" class="md:ml-14")
    font-awesome-icon.mr-2(:icon='["fas", "plus-circle"]')
    | Add Registration Item
div.ml-0.flex.justify-between(class="md:ml-14")
  FormKit(
    v-model="viewDisabled",
    type="checkbox",
    label="View Disabled Registration Items",
    name="viewDisabled",
    inputClass="sr-only peer",
    )
    .text-lg.text-gray-600 View Disabled Registration Items
  router-link(:to="{name: 'registration-preview'}")
    .text-lg.text-gray-600.cursor-pointer Preview Your Registration Page
div.divide-y-2.divide-gray-400.border-t-2.border-b-2.border-gray-400
  .grid.grid-cols-1.divide-y-2.divide-gray-400(v-for="item in pageItems" class="md:grid-cols-2 md:divide-y-0 md:divide-x-2")
    div.p-2
      Title(:item="item" @refreshItems="getPageItems")
    div.flex.justify-center.w-full.divide-y-2.items-start
      div(class="p-4 m-4 border border-dashed border-slate-200 shadow-md w-full")
        //- todo: component like "PageItemPreviewRender" which does the switching over questino/content in a tsx block
        PageItem_Question_PreviewRender(v-if="item.pageItemType==='Question'" :inputType="item.pageItem.type", :label="item.pageItem.label", :selectOptions="item.pageItem.questionOptions")
        ContentChunkDisplay.mt-6.mb-6(:showPencil="false" v-if="item.pageItemType==='Content'" :id="item.id", :contentChunkObj="item.pageItem" classes="md:mb-0")
</template>

<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { defineComponent, ref, onMounted, watch } from 'vue'
import { PageItem_Question_PreviewRender } from 'src/components/Registration/admin/PageItem_Question_PreviewRender'
import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import Title from 'src/components/Registration/admin/Title.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    PageItem_Question_PreviewRender,
    ContentChunkDisplay,
    Title
  },
  setup() {
    const pageItems = ref({})
    const viewDisabled = ref(false)
    const router = useRouter()

    const getPageItems = async () => {
      // console.log('getting PageItems')
      try {
        const response = await axiosInstance.get(`/v1/registration/pageItems`, {params: {includedisabled: viewDisabled.value}})
        pageItems.value=response.data.data
      } catch (error) {
        // console.log('getPageItems error', error)
      }
    }

    const toNewPageItem = async () => {
      await router.push({name: 'new-page-item'})
    }

    watch(viewDisabled, async () => {
      await getPageItems()
    })

    onMounted(async ()=> {
      await getPageItems()
    })
    return {
      toNewPageItem,
      pageItems,
      getPageItems,
      viewDisabled
    }
  }
})
</script>
