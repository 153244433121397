import { axiosInstance } from 'src/boot/axios'
import { GetEventResult } from 'src/composables/InleagueApiV1.Event'
import { EventAugmentedEntity, EventI } from 'src/interfaces/Store/events'
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import { ref } from 'vue'
import { exhaustiveCaseGuard } from 'src/helpers/utils'

export const EventStore = (() => {
  const state = ref(freshState());

  /**
   * returns those eventSignups that are locally available
   */
  async function getSignupsByEventSignupID(targetEventSignupIDs: iltypes.Guid[]) : Promise<iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[]> {
    const lookingFor = new Set(targetEventSignupIDs);
    const result : iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[] = [];
    for (const eventID of Object.keys(state.value.existingSignups)) {
      const signupsByEntityID = state.value.existingSignups[eventID];
      for (const entityID of Object.keys(signupsByEntityID)) {
        const signup = signupsByEntityID[entityID];
        if (lookingFor.has(signup.eventSignupID)) {
          result.push(signup);
          lookingFor.delete(signup.eventSignupID);
        }
      }
    }
    return result;
  }

  async function getSignups(arg: {eventID: iltypes.Guid, bustCache?: boolean}) : Promise<{[entityID: iltypes.Guid]: iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">}> {
    if (!arg.bustCache && state.value.existingSignups[arg.eventID]) {
      return state.value.existingSignups[arg.eventID]
    }

    //
    // might need to track "requests in flight" and many callers can wait on one request?
    //
    const signups = await ilapi.event.getEventSignups(axiosInstance, {eventID: arg.eventID, narrowTo: "currentUserFamily"})
    for (const signup of signups) {
      const arg : PutSignupByEntityByEventArgs = {signup};
      await putSignupByEntityByEvent(arg);
    }

    // we could have received no signups
    return state.value.existingSignups[arg.eventID] || {};
  }

  async function getEvent(arg: {eventID: iltypes.Guid}) : Promise<GetEventResult> {
    // No try/catch, if it fails, and we catch, what would we return?
    const event = await ilapi.event.getEvent(axiosInstance, arg.eventID);

    // We really would rather not cache this, which is why we always re-get it;
    // however, there is an unknown amount of code that __reads directly from the cache__
    // from untyped positions in vue sfc pug templates, and makes lifetime assumptions
    // about the cache such as when it was last populated and implicitly when reading from
    // it is valid and etc, and these assumptions have congealed into something that will
    // take a focused effort to remove.
    // So, we reload every time, but we also must put it into the "cache" here.
    state.value.registeringEvents[event.eventID] = event;

    return event;
  }

  function putEntityByEvent(payload: {entity: EventAugmentedEntity}) {
    if (!state.value.registeringEntities[payload.entity.eventID]) {
      state.value.registeringEntities[payload.entity.eventID] = {};
    }
    state.value.registeringEntities[payload.entity.eventID]![payload.entity.id] = payload.entity;
  }

  function putSignupByEntityByEvent(payload: PutSignupByEntityByEventArgs) {
    const entityID = payload.signup.childID || payload.signup.userID;
    if (!state.value.existingSignups[payload.signup.eventID]) {
      state.value.existingSignups[payload.signup.eventID] = {};
    }
    state.value.existingSignups[payload.signup.eventID][entityID] = payload.signup;
  }

  function removeSignup(payload: RemoveSignupArgs) {
    const eventID = (() => {
      switch (payload.type) {
        case "from-entity": return payload.eventEntity.eventID;
        case "from-signup": return payload.eventSignup.eventID;
        default: exhaustiveCaseGuard(payload);
      }
    })();
    const entityID = (() => {
      switch (payload.type) {
        case "from-entity": return payload.eventEntity.id;
        case "from-signup": return payload.eventSignup.entityID;
        default: exhaustiveCaseGuard(payload);
      }
    })();
    if (state.value.existingSignups[eventID]) {
      delete state.value.existingSignups[eventID][entityID];
    }
  }

  function putAnswersByEntityByEvent(payload: PutAnswersByEntityByEventArgs) {
    if (!state.value.registeringAnswers[payload.eventID]) {
      state.value.registeringAnswers[payload.eventID] = {};
    }
    state.value.registeringAnswers[payload.eventID][payload.entityID] = payload.answers;
  }

  function removeAnswersByEntityByEvent(payload: RemoveAnswersByEntityByEventArgs) {
    if (state.value.registeringAnswers[payload.eventID]) {
      delete state.value.registeringAnswers[payload.eventID][payload.entityID];
    }
  }

  return {
    get value() {
      return state.value;
    },
    putEntityByEvent,
    removeSignup,
    getSignupsByEventSignupID,
    putSignupByEntityByEvent,
    getEvent,
    getSignups,
    putAnswersByEntityByEvent,
    removeAnswersByEntityByEvent,
  }
})();

export type RemoveSignupArgs =
  | {type: "from-signup", eventSignup: ilapi.event.EventSignup}
  | {type: "from-entity", eventEntity: EventAugmentedEntity}

export interface PutAnswersByEntityByEventArgs {
  eventID: iltypes.Guid,
  entityID: iltypes.Guid,
  answers: null | {[questionID: iltypes.Guid]: string | number | boolean}
}

export interface RemoveAnswersByEntityByEventArgs {
  eventID: iltypes.Guid,
  entityID: iltypes.Guid,
}

export interface PutSignupByEntityByEventArgs {
  signup: iltypes.WithDefinite<ilapi.event.EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">
}

function freshState(): EventI {
  return {
    existingSignups: {},
    registeringEvents: {},
    registeringEntities: {},
    registeringAnswers: {},
  }
}
