import authService from "src/helpers/authService";
import { Competition, Guid } from "src/interfaces/InleagueApiV1"
import { UserData } from "src/interfaces/Store/user"

/**
 * narrow some list of competitions to those competitions for which the user has super admin permissions
 */
export function filterCompetitionsListingBySuperAdmin(userData: UserData, competitions: readonly Competition[]): readonly Competition[] {
  // If a user has some particular permissions like registrar or etc., we don't even need to check whether
  // they have competitions manager records which say "yeah this user has super permission for this competition".
  // They might not even have such a record, but with their elevated permissions level they have implicit super-admin-permission-per-competition
  if (authService(userData.roles, "registrar")) {
    return competitions;
  }

  const competitionUIDsCurrentUserIsSuperAdminOf = extractCompetitionUIDsSomeUserIsSuperAdminOf(userData);
  return competitions.filter(({ competitionUID }) => competitionUIDsCurrentUserIsSuperAdminOf.has(competitionUID));
}

/**
 * Given UserData, pick out those competitionUIDs for competitions for which the user has "superAdmin" permission
 */
export function extractCompetitionUIDsSomeUserIsSuperAdminOf(userData: UserData): Set<Guid> {
  const builder: Guid[] = [];
  for (const perm of userData.competitionsMemento) {
    if (!!perm.isSuperAdmin) {
      builder.push(perm.competitionUID);
    }
  }
  return new Set(builder);
}
