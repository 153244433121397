//
// todo: cut into modules in the same way as back end handlers, then this is just an "export import" deal
// goal is to write `ilapi.user.getUser(axios, someUserID)`, `ilapi.registration.createOrUpdateRegistration`, etc.
// types could be moved into their appropriate module homes as well, with "base" types (Guid, ChildID, ApiResponse<T>, etc.) in base module
//

import * as iltypes from 'src/interfaces/InleagueApiV1'

import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import axios from "axios"

import { AxiosErrorWrapper } from 'src/boot/AxiosErrorWrapper'

import * as TeamChooserMenu from "./InleagueApiV1.TeamChooserMenu"
import { LastStatus_t } from 'src/interfaces/Store/checkout'
import { GameBase, VisitorTeam, HomeTeam, HomeCoach, VisitorCoach, RefInfo } from 'src/composables/InleagueApiV1.Game'
import { CompetitionSeason, DateTimelike, FamilySeasonCompetitionVolunteerRequirementsCheck, Integerlike, Numbool, UserID, WithDefinite } from 'src/interfaces/InleagueApiV1'

// uhhhh how are these different, do they need to be?
import { Game } from 'src/interfaces/InleagueApiV1'
import { Game as Game2 } from "src/composables/InleagueApiV1.Game"

export * as family from "./InleagueApiV1.Family";
export * as event from "./InleagueApiV1.Event"
export * as child from "./InLeagueApiV1.Child"
export * as public_ from "./InleagueApiV1.Public"
export * as coupon from "./InleagueApiV1.Coupon"
export * as auth from "./InleagueApiV1.Authenticate"

type Guid = iltypes.Guid

/**
 * is some value shaped enough like an Axios error that we can inspect the data property?
 *
 * is this { response: AxiosResponse } ?
 *
 * This is different than axios.isAxiosError, mostly in that we are interested in proving the existence
 * of particular properties (response, response.data, response.status).
 *
 * @deprecated -- in favor of `axios.isAxiosError(v)` (often, chained with `&& isInleagueApiError2(v)`)
 */
export function isAxiosErrorLike(v: any): v is {
  response: {
    status: number
    data: /*presumably inleague API error response not checked in this method */ any
  }
} {
  return (
    v?.hasOwnProperty('response') &&
    v?.response?.hasOwnProperty('data') &&
    v?.response?.hasOwnProperty('status')
  )
}

/**
 * Accepts anything and checks to see if it is an APIError
 * Generally `this means passing an "axiosResponseLike"'s `response.data` property, which means first guarding with
 * `isAxiosResponseLike(foo) && isInleagueApiError(foo.response.data)` where foo.response.data is (maybe) an ApiError response
 * @deprecated use isInleagueApiError2 or isAxiosInleagueApiError
 */
export function isInleagueApiError(v: any): v is iltypes.APIError {
  return (
    v?.hasOwnProperty('data') &&
    v?.hasOwnProperty('error') &&
    v?.hasOwnProperty('messages') &&
    Array.isArray(v.messages) &&
    v.error === true
  )
}

/**
 * improved version of isInleagueApiError
 */
export function isInleagueApiError2(v: AxiosError<any>): v is Omit<AxiosError, "response"> & {response: AxiosResponse<iltypes.APIError>} {
  return (
    v.response?.data?.hasOwnProperty('data') &&
    v.response?.data?.hasOwnProperty('error') &&
    v.response?.data?.hasOwnProperty('messages') &&
    Array.isArray(v.response.data.messages) &&
    v.response.data.error === true
  )
}

export function isAxiosInleagueApiError(v: any) : v is Omit<AxiosError, "response"> & {response: AxiosResponse<iltypes.APIError>} {
  return axios.isAxiosError(v) && isInleagueApiError2(v)
}

export async function updateVolunteerDetails(
  axiosInstance: AxiosInstance,
  volunteerID: string,
  seasonUID: string,
  data: iltypes.VolunteerRoles
): Promise<any> {
  const response = await axiosInstance.post<iltypes.InleagueApiResponse<any>>(
    `v1/volunteerCode/${volunteerID}/${seasonUID}`,
    data
  )
  return response.data.data
}

export async function updateVolunteerComments(
  axiosInstance: AxiosInstance,
  volunteerID: string,
  seasonUID: string,
  data: iltypes.VolunteerComments
): Promise<any> {
  const response = await axiosInstance.post<iltypes.InleagueApiResponse<any>>(
    `/v1/volunteer/${volunteerID}/season/${seasonUID}`,
    data
  )
  return response.data.data
}

export async function getVolunteerDetails(
  axiosInstance: AxiosInstance,
  userID: string,
  seasonUID: string
): Promise<iltypes.VolunteerDetails> {
  const response = await axiosInstance.get(
    `/v1/user/${userID}/season/${seasonUID}`
  )
  return response.data.data
}

/**
 * This endpoint requires that the user is not subject to a "stack sports lock"
 * i.e, the user must have a nullish `stackSportsKey` for this request to not result in an error
 */
export async function updateUserNameAndDob(
  axiosInstance: AxiosInstance,
  userID: string,
  data: {
    firstName?: string
    lastName?: string
    middleName?: string
    dob?: string,
  }
): Promise<void> {
  await axiosInstance.put(`v1/user/${userID}/nameAndDob`, data)
}

export async function checkFamilySeasonCompetitionVolunteerRequirements(
  axios: AxiosInstance,
  familyID: string,
  seasonUID: string,
  competitionUID: string
): Promise<FamilySeasonCompetitionVolunteerRequirementsCheck> {
  const response = await axios.get<
    iltypes.InleagueApiResponse<iltypes.FamilySeasonCompetitionVolunteerRequirementsCheck>
  >(
    `v1/family/${familyID}/volunteerRequirements/season/${seasonUID}/competition/${competitionUID}`
  )
  return response.data.data
}

export async function checkFamilySeasonCompetitionVolunteerRequirementsManyComps(
  ax: AxiosInstance,
  familyID: string,
  seasonUID: string,
  competitionUIDs: Guid[]
): Promise<FamilySeasonCompetitionVolunteerRequirementsCheck[]> {
  const result : FamilySeasonCompetitionVolunteerRequirementsCheck[] = []
  for (const competitionUID of competitionUIDs) {
    result.push(await checkFamilySeasonCompetitionVolunteerRequirements(ax, familyID, seasonUID, competitionUID))
  }
  return result;
}
interface Expandables {
  getPlayer:
    | "mostRecentRegistration"
    | "mostRecentRegistration.teamAssignments"
    | "parent1"
    | "parent2"
    | "parent1ID"
    | "parent2ID"
    | "parent2Email"
    | "parent2Phone"
    | "parent2FirstName"
    | "parent2LastName"
    | "teamAssignmentsCurrent"
    | "relatedUsers"
    | "permLeagueComment"
    | "familyMembers"
    | "contactPhone"
    | "contactName"
    | "contact2Phone"
    | "contact2Name"
}

export async function getPlayer(
  axios: AxiosInstance,
  args: {
    childID: Guid,
    expand?: Expandables["getPlayer"][]
  }
): Promise<iltypes.Child> {
  const params : Record<string, any> = {};
  if (args.expand?.length) {
    params.expand = args.expand;
  }
  const response = await axios.get(`v1/child/${args.childID}`, {params})
  return response.data.data
}

export async function getUserIDsOfUsersNotHavingUserSeasonRecordsForSeason(
  axios: AxiosInstance,
  familyID: string,
  seasonUID: string
): Promise<iltypes.FamilyUsersNotHavingUserSeasonRecordsForSeason[]> {
  const response = await axios.get<
    iltypes.InleagueApiResponse<
      iltypes.FamilyUsersNotHavingUserSeasonRecordsForSeason[]
    >
  >(`v1/family/${familyID}/userSeason/${seasonUID}/missing`)
  return response.data.data
}

/**
 * this endpoint returns different status codes to indicate success:
 *   - find 0 records? 200
 *   - find 1 record? 302
 *   - find more than 1 record? 300
 */
export async function getAvailableSeasons(
  axiosInstance: AxiosInstance,
  openingWithin?: number
): Promise</*season memento?*/ any[]> {
  try {
    const queryString =
      openingWithin === undefined ? '' : `?openingWithin=${openingWithin}`
    const response = await axiosInstance.get(
      `v1/registration/seasons${queryString}`
    )
    return response.data.data
  } catch (err) {
    AxiosErrorWrapper.rethrowIfNotAxiosError(err);

    const unwrapped = err.unwrap();

    // we know it's an AxiosError, but an AxiosError does not necessarily have a response property
    // we need to further constrain it
    if (isAxiosErrorLike(unwrapped)) {
      if (unwrapped.response.status === 300 || unwrapped.response.status === 302) {
        return unwrapped.response.data.data;
      }
    }

    // unexpected error; rethrow
    // maybe we should just return `[]` here?
    throw err
  }
}

export async function getUser(
  axios: AxiosInstance,
  userID: string,
  expand?: readonly ("permLeagueComment")[]
): Promise<iltypes.User> {
  const params : any = {}
  if (expand?.length) {
    params.expand = expand.join(",");
  }
  const response = await axios.get<iltypes.InleagueApiResponse<any>>(
    `/v1/user/${userID}`,
    {params}
  )
  return response.data.data
}

/**
 * Update a portion of an existing registration's question answers
 *
 * This does not target a competition, but rather a "top-level" registration
 *
 * For a registrar:
 *
 * It may update any of the registration's core or custom questions that are bound to the target season (or alternatively not bound to any season),
 * and for each question, may use all question options bound to the registration's season (or alternatively not bound to any season).
 * Gates are not considered when determining a question or its option's validity
 *
 * For a parent:
 *
 * It may update only those questions which are "editable" and which already have answers.
 *
 * @customQuestionAnswers -- null values indicate "do not submit this"
 * (this should be `undefined`, with null as "force clear or delete or etc.", but formkit appropriates `undefined` for its own use
 * and we can't use it in a formkit form, where "initially explicitly undefined" values aren't tracked via v-model)
 */
export async function updateRegistration_targetingSeasonOnly(
  axios: AxiosInstance,
  registrationID: string,
  coreQuestionAnswers: iltypes.__fixme__CoreQuestionAnswerRecord,
  customQuestionAnswers: Record<string, string | number | boolean>
): Promise<void> {
  await axios.put(`v1/registration/${registrationID}/force`, {
    coreQuestionAnswers,
    customQuestionAnswers,
  })
}

/**
 * get registration page items for some (child, season), optionally gated, and optionally considering competition linkage
 *
 * "considering competition linkage" could mean:
 *   - (A) no list of competitions -- do not consider competition linkage
 *   - (B) an empty list of competitions -- find only those questions not having any linked competitions
 *   - (C) a non-empty list of competitions -- find only those questions having linkage to these competitions
 *
 * option (B) is not implemented; the caller must provide either no list or a non-empty list
 */
export async function getPlayerRegistrationPageItemsForChildSeason(
  axios: AxiosInstance,
  childID: string,
  seasonUID: string,
  withGates: boolean,
  competitionUIDs?: string[]
): Promise<iltypes.RegistrationPageItem[]> {
  const params: Record<string, string> = { withGates: withGates ? '1' : '0' }

  // backend would reject this, too
  if (competitionUIDs && competitionUIDs.length === 0) {
    throw 'unsupported'
  }

  if (competitionUIDs) {
    params.competitionUIDs = JSON.stringify(competitionUIDs)
    params.withCompetitionLinkage = '1'
  } else {
    params.competitionUIDs = JSON.stringify([])
    params.withCompetitionLinkage = '0'
  }

  const response = await axios.get(
    `/v1/registration/pageItems/${childID}/${seasonUID}`,
    { params }
  )

  //
  // back end makes no guarantee on response ordering,
  // but client side we may be interested in a consistent ordering for ui purposes
  //
  return (response.data.data as iltypes.RegistrationPageItem[]).sort((l, r) =>
    l.order < r.order ? -1 : 1
  )
}

export async function getRegistrationPageItems(axios: AxiosInstance, args?: {includeDisabled?: boolean, seasonUID?: Guid, competitionUID?: Guid}) : Promise<iltypes.RegistrationPageItem[]> {
  const {includeDisabled, seasonUID, competitionUID} = args ?? {};
  const response = await axios.get(`/v1/registration/pageItems`, {params: {includeDisabled, seasonUID, competitionUID}})
  return response.data.data;
}

export function isRegistrationQuestion(
  v: iltypes.RegistrationPageItem
): v is iltypes.RegistrationPageItem_Question {
  return v.type === iltypes.PageItemType.QUESTION
}
export function isRegistrationContentChunk(
  v: iltypes.RegistrationPageItem
): v is iltypes.RegistrationPageItem_ContentChunk {
  return v.type === iltypes.PageItemType.CONTENT_CHUNK
}

export async function getCoreQuestions(
  axios: AxiosInstance,
  argbag: { asPreview: true, childID?: Guid, seasonUID?: Guid, competitionUIDs?: Guid[] }
): Promise<iltypes.CoreQuestion[]>
export async function getCoreQuestions(
  axios: AxiosInstance,
  argbag: { asPreview: false; childID: Guid; seasonUID: Guid, competitionUIDs: Guid[] }
): Promise<iltypes.CoreQuestion[]>
export async function getCoreQuestions(
  axios: AxiosInstance,
  argbag:
    | { asPreview: true, childID?: Guid, seasonUID?: Guid, competitionUIDs?: Guid[] }
    | { asPreview: false, childID: Guid, seasonUID: Guid, competitionUIDs: Guid[] }
): Promise<iltypes.CoreQuestion[]> {
  const payload: AxiosRequestConfig = {
    params: {
      withGates: true, // always want gates with core questions? irrespective of preview status
      childID: argbag.childID,
      seasonUID: argbag.seasonUID,
      competitionUIDs: argbag.competitionUIDs,
    }
  }

  const response = await axios.get(
    '/v1/registration/standardCoreQuestions',
    payload
  )

  return response.data.data
}

export async function getActiveDivisionsForSeason(
  axios: AxiosInstance,
  seasonUID: string
): Promise<iltypes.Division[]> {
  const response = await axios.get(`/v1/divisions/season/${seasonUID}`)
  return response.data.data
}

export async function getChildDivisionsForUserSeason(
  axios: AxiosInstance,
  userID: string,
  seasonUID: string
): Promise<iltypes.ChildDivisionsForUserSeason> {
  const response = await axios.get(
    `/v1/user/${userID}/childDivisions/season/${seasonUID}`
  )
  return response.data.data
}

export async function getVolunteerSeasonStatus(
  axios: AxiosInstance,
  userID: string,
  seasonUID: string
): Promise<iltypes.VolunteerSeasonStatus> {
  const response = await axios.get(
    `/v1/volunteer/${userID}/season/${seasonUID}`
  )
  return response.data.data
}

export const nilGuid = '00000000-0000-0000-0000-000000000000'

export async function getCompetitionSeason(
  axios: AxiosInstance,
  competitionUID: string,
  seasonUID: string
): Promise<iltypes.CompetitionSeason> {
  const response = await axios.get(
    `/v1/competition/${competitionUID}/season/${seasonUID}`
  )
  return response.data.data
}

/**
 * TODO: implement on backend as "bulk" endpoint
 */
export async function getCompetitionSeasons(
  axios: AxiosInstance,
  keys: {
    competitionUID: Guid,
    seasonUID: Guid
  }[],
): Promise<CompetitionSeason[]> {
  const result : CompetitionSeason[] = []
  for (const {competitionUID, seasonUID} of keys) {
    result.push(await getCompetitionSeason(axios, competitionUID, seasonUID))
  }
  return result;
}

/**
 * n.b. We do not, and cannot, send a Partial<CompetitionSeason>, because the server will interpret missing object properties
 * as "please set these to null" (e.g. sending an update to exactly one field will zero out all other fields)
 *
 * @param obj -- The whole obj, not a Partial<>
 */
export async function updateCompetitionSeason(axios: AxiosInstance, obj: iltypes.CompetitionSeason) : Promise<void> {
  return await axios.put(`v1/competition/${obj.competitionUID}/season/${obj.seasonUID}`, obj);
};

export interface IncompleteCompetitionRegistrationUserMutableFeeInfo {
  competitionRegistrationID: Integerlike,
  payWhatYouCan: number | undefined
  donation: number | undefined
}

/**
 * After registration confirmation, there are some fees that can be updated
 * The target competition must not be active or canceled
 */
export async function updateIncompleteCompetitionRegistrationUserMutableFeeInfo(
  axios: AxiosInstance,
  perCompReg: IncompleteCompetitionRegistrationUserMutableFeeInfo[]
): Promise<iltypes.CompetitionRegistration | null> {
  const response = await axios.put(
    `/v1/competitionRegistration/userMutableFees`,
    {perCompReg}
  )

  // response of empty string means "no data"
  return response.data.data || null;
}

export async function getVolunteerCodes(
  axios: AxiosInstance,
  seasonUID: string
): Promise<iltypes.WithDefinite<iltypes.VolunteerCode, "countForSeason">[]> {
  const response = await axios.get(`/v1/volunteerCode`, {
    params: { seasonUID: seasonUID },
  })
  return response.data.data
}

/**
 * Update some family's parent ordinals
 * parent1 must always be something
 * parent2 can be null; passing null will set it to null, otherwise a valid Guid is required
 */
export async function setParentOrdinals(
  axios: AxiosInstance,
  familyID: Guid,
  parent1ID: Guid,
  parent2ID: Guid | null
): Promise<void> {
  const data = {
    parent1ID,
  } as { parent1ID: string; parent2ID?: string }

  if (parent2ID) {
    data.parent2ID = parent2ID
  }

  await axios.post(`/v1/family/${familyID}/setParentOrdinals`, data)
}

export async function getTryoutEventsForSeason(
  axios: AxiosInstance,
  seasonUID: Guid
): Promise<iltypes.TryoutEvent[]> {
  const response = await axios.get(`/v1/events/tryouts/${seasonUID}`)
  return response.data.data
}

/**
 * General updateables for the updateChild endpoint
 */
export interface UpdateChildArgPack1 {
  /**
   * Cannot be updated if "stack sports locked" or "has some registration history", server will reject such a request
   */
  playerFirstName?: string
  /**
   * Cannot be updated if "stack sports locked" or "has some registration history", server will reject such a request
   */
  playerLastName?: string
  /**
   * Cannot be updated if "stack sports locked" or "has some registration history", server will reject such a request
   */
  playerBirthDate?: string
  playerGender?: string
  playerNickName?: string
  permLeagueComment?: string
}

/**
 * The API makes a distinction that it will not update `blockFromRegistration` unless `birthCertificate` is also present
 */
export interface UpdateChildArgPack2 {
  birthCertificate: boolean
  blockFromRegistration?: boolean
}

export async function updateChild(
  axios: AxiosInstance,
  childID: Guid,
  argpack1: UpdateChildArgPack1,
  argpack2?: UpdateChildArgPack2
): Promise<void> {
  const args: Partial<UpdateChildArgPack1> & Partial<UpdateChildArgPack2> = {}

  const runArgpack1 = <K extends keyof UpdateChildArgPack1>(key: K) => {
    if (argpack1[key] !== undefined) {
      args[key] = argpack1[key]
    }
  }
  const runArgpack2 = <K extends keyof UpdateChildArgPack2>(key: K) => {
    if (argpack2?.[key] !== undefined) {
      args[key] = argpack2[key]
    }
  }

  // pull exactly those properties from argpack1 and (if passed in by caller) argpack2 that we want to support
  runArgpack1('playerFirstName')
  runArgpack1('playerLastName')
  runArgpack1('playerGender')
  runArgpack1('playerBirthDate')
  runArgpack1('playerNickName')
  runArgpack1('permLeagueComment')

  runArgpack2('birthCertificate')
  runArgpack2('blockFromRegistration')

  await axios.put(`v1/child/${childID}`, args)
}

interface Expandables {
  competition:
    | 'currentCompetitionSeason'
    | 'eligibilityRules'
    | 'sideBySide'
    | 'competitionSeasons'
    | 'sourceCompetitionUIDs'
}

/**
 * n.b. the return type here changes based on whether the user is logged in or not
 */
export async function getCompetitions(
  axios: AxiosInstance,
  args: {
    includeDisabled: boolean,
    expand?: Expandables["competition"][]
  }
): Promise<iltypes.Competition[]> {
  const params : Record<string, any> = {
    includeDisabled: args.includeDisabled ? 1 : 0
  }

  if (args.expand?.length) {
    params.expand = args.expand
  }

  const response = await axios.get('v1/competitions', {params})
  return response.data.data
}

export async function getCompetition(
  axios: AxiosInstance,
  args: {
    competitionUID: Guid
    expand?: Expandables["competition"][]
  }
): Promise<iltypes.Competition> {
  const params = args.expand ? { params: { expand: args.expand } } : undefined
  const response = await axios.get(
    `/v1/competition/${args.competitionUID}`,
    params
  )
  return response.data.data
}

export async function createOrUpdateCompetitionSideBySideExclusion(
  axios: AxiosInstance,
  args: {
    competitionUID: Guid
    otherCompetitionUID: Guid
    canExchange: boolean
    creditFeeForExchange: boolean
    applicability_exclude_if_eligible: boolean
    applicability_exclude_if_registered: boolean
    applicability_autoeligible_if_registered: boolean
    applicability_only_if_registered: boolean
  }
): Promise<iltypes.CompetitionSideBySide> {
  const response = await axios.post(`v1/competition/${args.competitionUID}/sideBySide/${args.otherCompetitionUID}`, args)
  return response.data.data
}

export async function deleteCompetitionSideBySideExclusion(
  axios: AxiosInstance,
  args: {
    competitionUID: Guid
    otherCompetitionUID: Guid
  }
): Promise<void> {
  await axios.delete(
    `v1/competition/${args.competitionUID}/sideBySide/${args.otherCompetitionUID}`
  )
}

export async function getCompetitionEligibility(
  axios: AxiosInstance,
  args: { competitionUID: Guid; seasonUID: Guid }
): Promise<iltypes.CompetitionEligibility[]> {
  const response = await axios.get(
    `v1/competitionEligibility/${args.competitionUID}`,
    { params: { seasonUID: args.seasonUID } }
  )
  return response.data.data
}

export async function getCompetitionSeasonDivisions(
  axios: AxiosInstance,
  args: { competitionUID: Guid; seasonUID: Guid }
): Promise<iltypes.CompetitionSeasonDivision[]> {
  const response = await axios.get(
    `/v1/registration/competitionSeasonDivision`,
    {
      params: {
        competitionuid: args.competitionUID,
        seasonuid: args.seasonUID,
      },
    }
  )
  return response.data.data as iltypes.CompetitionSeasonDivision[]
}

type PlayerSearchResultExpandedRegistration =
  & iltypes.WithDefinite<iltypes.Registration, "teamAssignments">
  & {
    teamAssignments: iltypes.WithDefinite<iltypes.TeamAssignment, "team">
  }

/**
 * ad hoc player object with expandables.
 * some expandables are present or not based on request shape but not as a result of specifically requesting "fieldX",
 * rather it's "ah you requested fieldY, therefore we will implicitly provide fieldX"
 */
export interface PlayerSearchResult {
  AYSOID: string,
  birthCertificateFile: string,
  birthCertificate: number,
  blockFromRegistration: number,
  childID: string,
  childNum: number,
  clientID: string,
  dateCreated: string,
  dateModified: string,
  familyID: string,
  modifiedBy: string,
  /**
   * present IFF seasonID was not provided as a parameter (i.e. searching across "ALL" seasons)
   */
  mostRecentRegistrationID?: iltypes.Guid,
  parent1Email: string,
  parent1FirstName: string,
  parent1ID: string,
  parent1LastName: string,
  parent1Phone: string,
  playerBirthDate: string,
  playerFirstName: string,
  playerGender: string,
  playerLastName: string,
  provisionalAYSOID: number,
  stackSID: string
  /**
   * If seasonID is provided, this will be expanded.
   * This is conceptually singular (despite the name 'registrations') because there is at most one primary registration per child per season,
   * though it fits well with trying to be a type related to `Player` in general where `registrations` in the unconstrained case can contain many registrations.
   * For our purposes here, we say this is undefined (wasn't expanded), or an array of length exactly either zero or one.
   */
  registrations?:
    | []
    | [PlayerSearchResultExpandedRegistration],
  /**
   * This is the team assignments for the player for "the current season", where "current season" is not parameterizeable in the request.
   * This is expanded IFF seasonID is NOT provided (i.e. searching across "all" seasons)
   */
  teamAssignmentsCurrent?: iltypes.TeamAssignment[],
}

/**
 * note: teamAssignmentsCurrent always expands to "current team assignments" (for some notion of the 'current season'), irrespective of the provided seasonID.
 * Not providing `divIDs` (or sending an empty array) means "any divID", though the backend will constrain as per permissions if the user doesn't have "all divs" permission.
 */
export async function playerSearch(
  axios: AxiosInstance,
  args: {
    nameQuery: string,
    seasonID?: iltypes.Integerlike,
    divIDs?: Guid[],
    expand?: ("teamAssignmentsCurrent")[]
},
): Promise<PlayerSearchResult[]> {
  const params : Record<string, string | string[] | undefined> = {
    search: args.nameQuery,
    seasonid: args.seasonID?.toString() || undefined,
    divIDs: args.divIDs ?? [],
    expand: args.expand?.length ? args.expand?.join(",") : undefined
  }
  const response = await axios.get(`v1/players/search`, {params});
  return response.data.data
}

interface _AddCompetitionEligibility_Base {
  type: iltypes.CompetitionEligibility['type']
  competitionUID: Guid
  seasonUID: Guid
}
interface _AddCompetitionEligibility_ChildID
  extends _AddCompetitionEligibility_Base {
  type: 'childID'
  childID: Guid
}
interface _AddCompetitionEligibility_NameDOB
  extends _AddCompetitionEligibility_Base {
  type: 'nameDOB'
  lastName: string
  DOB: iltypes.Datelike
}

export async function addCompetitionEligibility(
  axios: AxiosInstance,
  args: _AddCompetitionEligibility_ChildID
): Promise<iltypes.CompetitionEligibleChild>
export async function addCompetitionEligibility(
  axios: AxiosInstance,
  args: _AddCompetitionEligibility_NameDOB
): Promise<iltypes.CompetitionEligibleNameAndDOB>
export async function addCompetitionEligibility(
  axios: AxiosInstance,
  args: _AddCompetitionEligibility_ChildID | _AddCompetitionEligibility_NameDOB
): Promise<
  iltypes.CompetitionEligibleChild | iltypes.CompetitionEligibleNameAndDOB
> {
  // `type` is not submitted to the backend
  const { type, competitionUID, ...putData } = args
  const response = await axios.put(
    `v1/competitionEligibility/${competitionUID}`,
    putData
  )
  return response.data.data
}

/**
 * Change the competition for some active competition registration.
 * Will recalculate the assigned division, which may or may not result in a different division assignment for this competition registration.
 */
export async function updateCompetitionRegistrationCompetition(
  axios: AxiosInstance,
  args: {
    registrationID: Guid
    competitionRegistrationID: iltypes.Integerlike,
    newCompetitionUID: Guid
  }
): Promise<iltypes.CompetitionRegistration> {
  const response = await axios.put(
    `v1/registration/${args.registrationID}/${args.competitionRegistrationID}/competition/${args.newCompetitionUID}`
  )
  return response.data.data
}

export async function createCompetitionRegistrationInvoice(
  axios: AxiosInstance,
  args: {
    registrationID: Guid,
    competitionRegistrationIDs: Integerlike[],
  }
): Promise<iltypes.Invoice[]> {
  const response = await axios.post(
    `/v1/registration/${args.registrationID}/invoice`,
    {
      competitionRegistrationIDs: args.competitionRegistrationIDs,
      versionTag: 2
    }
  )
  return response.data.data
}

export async function getCompetitionRegistrations(
  axios: AxiosInstance,
  args: { playerID: Guid; seasonUID: Guid }
): Promise<iltypes.CompetitionRegistration[]> {
  const response = await axios.get(
    `/v1/competitionRegistration/player/${args.playerID}/season/${args.seasonUID}`
  )
  return response.data.data
}

/**
 * Undocument endpoint to support client understanding when it shouldn't hit the API;
 * We can still try during these times, but we'll get 503 status responses (or similar)
 *
 * Returns full ISO8601 date/time strings, but they represent time information only, and so their date portion is 1/1/1970
 *
 * Returned date/time strings are in UTC
 */
export async function getVolunteerRegistrationUnavailabilityListing(
  axios: AxiosInstance
): Promise<{ from: iltypes.Datelike; to: iltypes.Datelike }> {
  const response = await axios.get(`/v1/volunteer/unavailabilityListing`)
  return response.data.data
}

export async function getAllChildrenOfFamily(
  axios: AxiosInstance,
  args: { familyID: Guid }
): Promise<iltypes.Child[]> {
  const response = await axios.get(`/v1/family/${args.familyID}/players`)
  return response.data.data
}

export async function getAllUsersOfFamily(
  axios: AxiosInstance,
  args: { familyID: Guid }
): Promise<iltypes.User[]> {
  const response = await axios.get(`/v1/family/${args.familyID}/users`)
  return response.data.data
}

export async function findVolunteers(
  axios: AxiosInstance,
  args: { search: string, localOnly?: boolean }
): Promise<iltypes.VolunteerSearchResult[]> {
  const response = await axios.get(`v1/volunteers/search`, {
    params: {
      search: args.search,
      // the server defaults it like this too --- if it is not provided, then assume "local only"
      localOnly: args.localOnly ?? true
    },
  })
  return response.data.data
}

export async function findPlayers(
  axios: AxiosInstance,
  args: { search: string }
): Promise<iltypes.PlayerSearchResult[]> {
  const response = await axios.get(`/v1/players/search`, {
    params: { search: args.search },
  })
  return response.data.data
}

export async function cancelCompetitionRegistration(
  axios: AxiosInstance,
  args: {
    registrationID: Guid
    competitionRegistrationID: iltypes.Integerlike
    notifyDivisionDirectorAndHeadCoaches: boolean
    notifyCompetitionManagers: boolean
    markPlayerAsNeverPlayed: boolean
  }
): Promise<iltypes.CompetitionRegistration> {
  const response = await axios.post(
    `/v1/registration/${args.registrationID}/${args.competitionRegistrationID}/cancel`,
    {
      notifyDivisionDirectorAndHeadCoaches:
        args.notifyDivisionDirectorAndHeadCoaches,
      notifyCompetitionManagers: args.notifyCompetitionManagers,
      markPlayerAsNeverPlayed: args.markPlayerAsNeverPlayed,
    }
  )
  return response.data.data
}

export async function forceActivateCompetitionRegistration(axios: AxiosInstance, args: {competitionRegistrationID: iltypes.Integerlike}) : Promise<iltypes.CompetitionRegistration> {
  const response = await axios.post(`v1/competitionRegistration/${args.competitionRegistrationID}/forceActivate`);
  return response.data.data;
}

export async function getRelationshipTypes(
  axios: AxiosInstance
): Promise<iltypes.RelationshipType[]> {
  const response = await axios.get('v1/family/userPlayerRelationshipTypes')
  return response.data.data
}

export interface SetSingleUserPlayerRelationshipArgs {
  userID: Guid,
  childID: Guid,
  relationship_type_id: iltypes.Integerlike,
}

/**
 * Set a single user player relationship
 * Relationships are uniquely identified by (user, child, type)
 * If the relationship identified by the supplied (user, child, type) already exists, this does nothing,
 * otherwise, it creates a record representing this relationship
 */
export async function setSingleUserPlayerRelationship(axios: AxiosInstance, args: SetSingleUserPlayerRelationshipArgs) : Promise<void> {
  await axios.post(`v1/family/userPlayerRelationship`, args);
}

export async function getPendingRegistrations(
  axios: AxiosInstance,
  args: { which: 'today' } | { which: 'season'; seasonUID: Guid }
): Promise<iltypes.WithDefinite<iltypes.Registration, 'competitions'>[]> {
  if (args.which === 'today') {
    const response = await axios.get('v1/registrations/pending/today')
    return response.data.data
  } else if (args.which === 'season') {
    const response = await axios.get(
      `v1/registrations/pending/season/${args.seasonUID}`
    )
    return response.data.data
  } else {
    throw 'unreachable'
  }
}

export async function activateCompetitionRegistrationWithOutOfBandPayment(
  axios: AxiosInstance,
  args: {
    registrationID: Guid
    competitionRegistrationID: iltypes.Integerlike,
    paymentType: 'cash' | 'check'
    paymentAmount: number
    emailConfirmation: boolean
    paymentID: string
    comments: string
  }
): Promise<iltypes.CompetitionRegistration> {
  const { registrationID, competitionRegistrationID, ...params } = args
  const response = await axios.post(
    `v1/registration/${registrationID}/${competitionRegistrationID}/pay-with-cash-or-check`,
    params
  )
  return response.data.data
}

export interface GetDivisionStatsArgs {
  divID: string,
  /**
   * undefined means "all"
   */
  seasonUID: string | undefined,
  /**
   * undefined means "all"
   */
  competitionUID: string | undefined
}

export async function getDivisionStats(
  axios: AxiosInstance,
  args: GetDivisionStatsArgs
  ): Promise<iltypes.DivisionStatsPortlet> {
    const response = await axios.get(`v1/divisions/statistics`, {
      params: args,
    })
    return response.data.data
  }

export async function updateCompetitionRegistrationWaitlistStatus(
  axios: AxiosInstance,
  args: {competitionRegistrationID: iltypes.Integerlike, freshWaitlistValue: boolean}
) : Promise<iltypes.CompetitionRegistration> {
  const asBitLike = args.freshWaitlistValue ? 1 : 0;
  const response = await axios.put(`v1/competitionRegistration/${args.competitionRegistrationID}/waitlist/${asBitLike}`);
  return response.data.data;
}

/**
 * `getActiveSeasons` returns an array of objects which are subsets of a normal season object
 */
export type ActiveSeasonListingEntry = Pick<iltypes.Season, "seasonUID" | "seasonID" | "seasonName" | "seasonYear" | "registrationYear">
export async function getActiveSeasons(axios: AxiosInstance, args?: {openingWithin?: iltypes.Integerlike}) : Promise<ActiveSeasonListingEntry[]> {
  const params : Record<string, any> = {};
  if (args?.openingWithin !== undefined) {
    params.openingWithin = args.openingWithin;
  }

  const response = await axios.get(`v1/seasons/active`, {params});
  return response.data.data;
}

/**
 * getOpenSeasons returns an array of objects which are subsets of a normal season obejct; as getActiveSeasons but excludes 'active' in favor only of 'open for registration' (now or soon)
 */

export async function getOpenSeasons(axios: AxiosInstance, args?: {openingWithin?: iltypes.Integerlike}) : Promise<ActiveSeasonListingEntry[]> {
  const params : Record<string, any> = {};
  if (args?.openingWithin !== undefined) {
    params.openingWithin = args.openingWithin;
  }

  const response = await axios.get(`v1/seasons/openForPlayerRegistration`, {params});
  return response.data.data;
}


export async function getSeasons(axios: AxiosInstance) : Promise<iltypes.Season[]> {
  const response = await axios.get('v1/seasons')
  return response.data.data;
}

/**
 * override the division for some (registration, competitionRegistration), where competitionRegistration is implied by the argument pair (registration + competitionUID)
 * removeTeams=true will remove all team assignments from the registration, regardless of the team assignment's competition
 */
export async function overrideDivision(axios: AxiosInstance, args: {registrationID: Guid, competitionUID: Guid, freshDivID: Guid, removeTeams: boolean, updateBaseDivision: boolean}) : Promise<iltypes.Division> {
  const response = await axios.put(
    `/v1/registration/${args.registrationID}/competition/${args.competitionUID}/division/${args.freshDivID}`,
    {removeTeams: args.removeTeams, updateBaseDivision: args.updateBaseDivision}
  );
  return response.data.data
}

export async function getDivisions(axios: AxiosInstance) : Promise<iltypes.Division[]> {
  const response = await axios.get('v1/divisions');
  return response.data.data;
}

export async function getDivision(axios: AxiosInstance, args: {divID: Guid}) : Promise<iltypes.Division> {
  const response = await axios.get('v1/divisions2/getDivision', {params: {divID: args.divID}});
  return response.data.data;
}

export async function getRefereeAssignments(axios: AxiosInstance, args: {divID: Guid}) : Promise<iltypes.RefDetails[]> {
  const response = await axios.get(`v1/refereeAssignments/${args.divID}`);
  return response.data.data;
}

interface Expandables {
  registration:
    | "currentEsignature"
    | "teamAssignments"
    | "registrationAnswers"
    | "competitions"
    | "competitions.competitionSeason"
    | "parent1ID"
    | "parent2ID"
    | "leagueComment"
    | "player"
    | "player.permLeagueComment"
    | "transactions"
    | "lastModifiedByUser"
    | "submittedByUser"
    | "contactName" // aliases for parent1Name / parent1PrimaryPhone & same for parent 2
    | "contactPhone"
    | "contact2Name"
    | "contact2Phone"
    | "photoURL"
}

export async function getRegistration(axios: AxiosInstance, args: {registrationID: Guid, expand?: Expandables["registration"][]}) : Promise<iltypes.Registration>{
  const params : Record<string, any> = {};
  if (args.expand?.length) {
    params.expand = args.expand;
  }
  const response = await axios.get(`/v1/registration/${args.registrationID}`, { params })
  return response.data.data;
}

export interface CreateOrUpdateRegistrationArgs {
  childID: Guid,
  seasonUID: Guid,
  competitionUIDs: Guid[],
  coreQuestionAnswers: Partial<iltypes.Registration>,
  customQuestionAnswers: {[questionID: Guid]: string | number | boolean}
}

/**
 * The actual response at runtime is a subtype of this, but we currently define only what we need to read out of it.
 * Conceptually this returns "a registration with the expanded competition registrations",
 * but there's no provision for "registration, expanded with a singular compreg object rather than an array of zero-or-more compreg objects"
 * anywhere else in the program, so we get an adhoc property `targetedCompetitionRegistration` which is the competition registration that was created
 * or edited as a result of this call.
 */
export interface CreateOrUpdateRegistrationResponse {
  registrationID: Guid,
  competitions: {
    competitionRegistrationID: Guid,
    competitionUID: Guid
  }[]
}

export async function createOrUpdateRegistration(axios: AxiosInstance, args: CreateOrUpdateRegistrationArgs) : Promise<CreateOrUpdateRegistrationResponse> {
  const response = await axios.post('/v1/registration', args)
  return response.data.data;
}

type MutatedByResetVolunteerAysoInfo = Pick<iltypes.User_Privileged,
  | "stackRecordKey"
  | "stackSID"
  | "RiskStatus"
  | "RiskStatusExpiration"
  | "LicenseLevel"
  | "LicenseLevelExpiration"
  | "RefereeGrade"
  | "RefereeGradeExpiration"
>

export async function resetVolunteerAysoInfo(
  axios: AxiosInstance,
  args: {userID: Guid}
) : Promise<{full: iltypes.User, mutated: MutatedByResetVolunteerAysoInfo}> {
  const response = await axios.post(`v1/volunteer/${args.userID}/reset`);
  const freshUser : iltypes.User_Privileged = response.data.data;
  return {
    full: freshUser,
    mutated: {
      stackRecordKey: freshUser.stackRecordKey,
      stackSID: freshUser.stackSID,
      RiskStatus: freshUser.RiskStatus,
      RiskStatusExpiration: freshUser.RiskStatusExpiration,
      LicenseLevel: freshUser.LicenseLevel,
      LicenseLevelExpiration: freshUser.LicenseLevelExpiration,
      RefereeGrade: freshUser.RefereeGrade,
      RefereeGradeExpiration: freshUser.RefereeGradeExpiration,
    }
  }
}

interface PlayerMergeResult {
  /**
   * html result of dumping objects to tables (or similar)
   * safe to dump to page, should be xss escaped by virtue of having passed through cf writedump
   */
  html: {
    before: {[playerID: string]: string},
    after: string
  },
}

export async function mergePlayers(axios: AxiosInstance, args: {keepID: Guid, dupID: Guid, dryRun?: boolean}) : Promise<PlayerMergeResult> {
  const data : Record<string, any> = {dupID: args.dupID};
  if (args.dryRun) {
    data.dryRun = 1;
  }
  const response = await axios.post(`v1/player/${args.keepID}/merge`, data);
  return response.data.data;
}

export interface CreateCustomQuestionArgs {
  label: string,
  shortLabel: string,
  type: iltypes.QuestionType,
  isRequired: boolean,
  isEditable: boolean,
  isDisabled: boolean,
  gateFunctionName: string,
  /**
   * Order is optional, if not provided, the backend will auto generate "the next" ordering value
   */
  order?: number
};

/**
 * @deprecated use createCustomRegistrationQuestion
 */
export async function createCustomQuestion(axios: AxiosInstance, args: CreateCustomQuestionArgs) : Promise<iltypes.RegistrationPageItem_Question> {
  const response = await axios.post(`/v1/registration/customQuestion`, args);
  return response.data.data;
}

export async function createCustomRegistrationQuestionPageItem(axios: AxiosInstance, args: CreateCustomQuestionArgs) {
  return createCustomQuestion(axios, args);
}

export async function deleteCustomeQuestion(axios: AxiosInstance, pageItemId: Guid) : Promise<void> {
  await axios.delete(`/v1/registration/customQuestion/${pageItemId}`)
}

export interface UpdateGameRefereeAssignmentsPayload {
  /**
   * fixme: unify disparate `slotsArray` shapes across the application
   */
  slotsArray: {
        slotNum: 1 | 2 | 3 | 4
        /** A locked slot cannot be altered anymore until unlocked */
        locked: boolean
        /** The userID of the ref to assign to this slot */
        assignID: Guid
        /** `true` to create a confirmed assignment, `false` to create a pending request" */
        confirmed: boolean,
        /** Whether to email notify a newly assigned referee */
        sendEmail: boolean,
        /** Whether to remove the existing assignment or request */
        removeRef: boolean,
        /** You may supply a reason for removing the ref assignment */
        removeReason: string,
        /** Whether to email notify the ref whose assignment you are canceling */
        sendCancelEmail: boolean,
  }[],
  /** Text to add to assignment and cancelation emails */
  emailText: string
  /** Update the game's ref scheduler comment */
  refComment: string,
  /** Numeric seasonID the game belongs to; n.b. NOT seasonUID */
  seasonID: iltypes.Integerlike
}

export async function updateGameRefereeAssignments(axios: AxiosInstance, args: {gameID: Guid, payload: UpdateGameRefereeAssignmentsPayload}) : Promise</* ??? */ any>{
  const response = await axios.put(`v1/game/${args.gameID}/refereeAssignments`, args.payload);
  return response.data.data;
}

export async function getConversation(axios: AxiosInstance, args: {conversationID: Guid}) : Promise<iltypes.MessageThread> {
  const response = await axios.get(`v1/teamMessage/conversation/${args.conversationID}`)
  return response.data.data;
}

interface Expandables {
  game:
    | "homeTeam"
    | "visitorTeam"
}

export async function getGamesWhereUserHasPermissionToEditScores(
  axios: AxiosInstance,
  args: {
    competitionUID?: Guid,
    divID?: Guid,
    lookBackToThisDate?: iltypes.Iso8601String,
    expand?: (Expandables["game"])[]
  }
) : Promise<(
    & WithDefinite<Game, "homeTeam" | "visitorTeam">
    & GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries
  )[]> {
  let params : Record<string, any>;
  if (args.expand) {
    // stringify `expand`, everything else is sent as is
    params = {
      ...args,
      expand: args.expand.length > 0
        ? args.expand.join(",")
        : undefined
    };
  }
  else {
    // no transform needed
    params = args;
  }

  const response = await axios.get(`v1/game/whereUserHasPermissionToEditScores`, {params});
  return response.data.data;
}

export async function getAvailableTeamMessageTeamOptions(axios: AxiosInstance) : Promise<TeamChooserMenu.Menu> {
  const response = await axios.get(`v1/teamMessage/availableTeamOptions`);
  return response.data.data;
}

export async function getPaymentGateways(axios: AxiosInstance) : Promise<iltypes.PaymentGateway[]> {
  const response = await axios.get(`v1/payments/gateways`)
  return response.data.data;
}

export async function getStackSportsPlayLevels(axios: AxiosInstance): Promise<iltypes.StackPlayLevelListing> {
  const playLevelListing = (
    await axios.get<{ data: iltypes.StackPlayLevelListing }>(
      `/v1/competitions/playLevels`
    )
  ).data.data
  // canonicalize guids to support comparisons
  for (const key of Object.keys(playLevelListing)) {
    playLevelListing[key].Key = playLevelListing[key].Key.toUpperCase()
  }
  return playLevelListing
}

export async function updateCompetition(axios: AxiosInstance, args: {competition: iltypes.Competition}) : Promise<void> {
  // don't write into the caller's object
  const shallowCopyArgs = {...args.competition}

  // it will come over the wire as a string, and we need to send it out over the wire as a string, too.
  shallowCopyArgs.coreQuestion_coedAge_limitedToAges = Array.isArray(shallowCopyArgs.coreQuestion_coedAge_limitedToAges)
    ? shallowCopyArgs.coreQuestion_coedAge_limitedToAges.join(",")
    : shallowCopyArgs.coreQuestion_coedAge_limitedToAges;

  await axios.put(`/v1/competition/${args.competition.competitionUID}`, shallowCopyArgs);
}

/**
 * args.seasonUIDs -- "all" serves as an alias for an empty array; "no explicit season links" implies allow a connection to all seasons
 */
export async function updatePageItemSeasonLinks(axios: AxiosInstance, args: {pageitemID: Guid, seasonUIDs: "all" | Guid[]}) : Promise<void> {
  await axios.put(`/v1/registration/pageItem/${args.pageitemID}/seasons`, {seasons: args.seasonUIDs === "all" ? [] : args.seasonUIDs});
}

/**
 * empty array means "no links"
 */
export async function updatePageItemCompetitionLinks(axios: AxiosInstance, args: {pageItemID: Guid, competitionUIDs: Guid[]}) : Promise<void> {
  await axios.put(`/v1/registration/pageItem/${args.pageItemID}/competitions`, {competitions: args.competitionUIDs })
}

export interface FIXME_CreateQuestionOptionResponse {
  id: Guid,
  [key: string]: any
}

export async function createCustomQuestionOption(axios: AxiosInstance, args: {optionValue: string, optionText: string, questionID: Guid, order?: number}) : Promise<FIXME_CreateQuestionOptionResponse> {
  const response = await axios.post(`/v1/registration/customQuestionOption`, args)
  return response.data.data;
}

/**
 * deletes all "current links", and then creates links for all the requested seasonUIDs
 */
export async function replaceQuestionOptionSeasonLinks(axios: AxiosInstance, args: {questionOptionID: Guid, seasonUIDs: Guid[]}) : Promise<void> {
  await axios.put(`/v1/registration/customQuestionOption/${args.questionOptionID}/seasons`, {seasons: args.seasonUIDs});
}

export type InvoiceExpandable =
  | "transactions"
  | "adminAccess"
  | "lineItems.adminAccess"
  | "lineItems.entity"

export async function getInvoice(axios: AxiosInstance, args: {instanceID: iltypes.Integerlike, expand?: InvoiceExpandable[]}) : Promise<iltypes.Invoice> {
  const params = args.expand?.length ? {expand: args.expand.join(",")} : undefined;
  const response = await axios.get(`v1/invoice/${args.instanceID}`, {params});
  return response.data.data;
}

export async function getActiveCompRegsThatAreOrWereBlocked(
  axios: AxiosInstance,
  args:
    | {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid | "ALL", divID: iltypes.Guid | "ALL"}
    | {competitionRegistrationIDs: iltypes.Integerlike[]}
    | {registrationID: iltypes.Guid}
) : Promise<
    (
      // a compreg, with some adhoc joins
      & iltypes.CompetitionRegistration
      & {
        playerFirstName: string,
        playerLastName: string,
        playerDisplayName: string,
        seasonName: string,
        competitionName: string
        divisionDisplayName_primary: string,
        /**
         * fallback for if primary is falsy
         */
        divisionDisplayName_secondary: string,
        submitterFirstName: string,
        submitterLastName: string,
        invoiceLineItem_amount: "" | iltypes.Numeric,
        invoiceLineItem_finalAmount: "" | iltypes.Numeric,
        invoice_instanceID: iltypes.Integerlike,
        invoice_paid: iltypes.Numbool,
        invoice_closeDate: iltypes.DateTimelike | "",
        invoice_lastStatusDate: iltypes.DateTimelike | "",
        invoice_datetimeOfLastUserNotification: iltypes.DateTimelike | "",
        invoice_dateCreated: DateTimelike,
        /**
         * may be falsy, if there is not yet an associated payment method id
         * which means "user got all the way to 'add a payment method'" and then stopped.
         * If falsy, we should not expose UI to "try to pay this" because it has nothing with which to be paid;
         * but, we can invite the user to add a method, or cancel the whole thing.
         */
        invoice_stripe_paymentMethodID: string,
        invoice_lastStatus: LastStatus_t,
        invoice_lastStatusDetail: string,
      }
    )[]
> {
  const params = (() => {
    if ("competitionRegistrationIDs" in args) {
      // need a little munging of array->list
      return {competitionRegistrationIDs: args.competitionRegistrationIDs.join(",")};
    }
    else {
      // other types can be sent literally (all 1-deep structures, values are strings)
      return args;
    }
  })();
  const response = await axios.get(`/v1/competitionRegistrations/blocked`, {params});
  return response.data.data;
}

export interface CompDivOptionsBySeason_for_ActiveCompRegsThatAreOrWereBlocked {
  /**
   * the pairs are all unique such that no (x,y) appears twice
   * however, individual x and y may be dupes across elements, e.g. (a,b), (a,c) is valid
   */
  pairs: {
    competitionUID: iltypes.Guid,
    divID: iltypes.Guid,
  }[],
  /**
   * all competitionUIDs present in pairs should be present here
   */
  competitionDetail: {competitionUID: iltypes.Guid, displayName: string}[],
  /**
   * all divIDs present in pairs should be present here
   */
  divisionDetail: {divID: iltypes.Guid, displayName: string}[]
}

export async function get_compDivOptionsBySeason_for_ActiveCompRegsThatAreOrWereBlocked(axios: AxiosInstance, seasonUID: iltypes.Guid) : Promise<CompDivOptionsBySeason_for_ActiveCompRegsThatAreOrWereBlocked> {
  const response = await axios.get(`v1/competitionRegistrations/blocked/compDivOptionsBySeason`, {params: {seasonUID: seasonUID}});
  return response.data.data;
}

export async function bulkPayInvoicesHavingAttachedStripePaymentMethods(
  axios: AxiosInstance,
  args: {invoiceInstanceID:  iltypes.Integerlike}
) : Promise<
  {
    [invoiceInstanceID: iltypes.Integerlike]:
      | {ok: true}
      | {ok: false, type: "stripe", detail: /*is there a stripe type for this?*/ {request_log_url: string, message: string}}
      | {ok: false, type: "inLeague", detail: string}
  }
> {
  const response = await axios.post(`v1/invoice/bulk/pay`, {instanceID: args.invoiceInstanceID})
  return response.data.data;
}

export async function bulkNotifyCustomersHavingAttachedStripePaymentMethods(axios: AxiosInstance, args: {invoiceInstanceID: iltypes.Integerlike}) : Promise<{[invoiceInstanceID: iltypes.Integerlike]: {ok: true} | {ok: false, detail: string}}> {
  const response = await axios.post(`v1/invoice/bulk/notifyCustomer`, {instanceID: args.invoiceInstanceID})
  return response.data.data;
}

export async function getInstanceConfig(axios: AxiosInstance) : Promise<iltypes.InstanceConfig> {
  const response = await axios.get(`/public/instanceConfig`);
  return response.data.data;
};

export async function payInvoice(axios: AxiosInstance, inArgs: {invoiceID: iltypes.Integerlike, paymentMethodID: string, discardCard: boolean, idempotencyKey: Guid}) : Promise<any> {
  const {invoiceID, ...outArgs} = inArgs;
  const response = await axios.post(`v1/invoice/${invoiceID}`, outArgs);
  return response.data.data;
}

export async function voidInvoice(ax: AxiosInstance, args: {invoiceInstanceID: Integerlike}) : Promise<void> {
  await ax.delete(`v1/invoice/${args.invoiceInstanceID}`)
}

export async function bulkCancelCompetitionRegistrations(axios: AxiosInstance, args: {
  competitionRegistrationID: iltypes.Integerlike,
  sendCancellationNotificationEmails?: {doSend: true, userSuppliedText: string}
}) : Promise<{[compRegID: iltypes.Integerlike]: {ok: true} | {ok: false, detail: string}}> {
  const response = await axios.post(`v1/competitionRegistrations/cancel`, {
    competitionRegistrationID: args.competitionRegistrationID,
    sendCancellationNotificationEmail: args.sendCancellationNotificationEmails?.doSend ?? false,
    notificationEmailText: args.sendCancellationNotificationEmails?.userSuppliedText ?? ""
  });
  return response.data.data;
}

export async function oops(axios: AxiosInstance, args: {breadcrumb: string, level: "info" | "warning" | "error", detail: any}) : Promise<void> {
  await axios.post(`v1/oops`, args);
}

type FIXME_NewUserArgs = Record<string, any>;
export async function createNewUser(axios: AxiosInstance, args: FIXME_NewUserArgs) : Promise<void /*maybe User? nothing consumes it though*/> {
  await axios.post('v1/user', args);
}

export async function createInvoiceFromEventSignups(axios: AxiosInstance, args: {eventSignupIDs: iltypes.Guid[]}) : Promise<iltypes.Invoice> {
  const response = await axios.post('v1/invoice', args);
  return response.data.data;
}

export interface GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries {
  competition_competitionID: number,
  competition_hideScores: number | boolean,
  competition_useScores: number | boolean,
  hasSomePoolForCompSeasonDivision: number | boolean,
  competition_season_seasonWeeks: number,
  competition_season_seasonStart: iltypes.Datelike,
}

interface GameScheduleLayoutDetailBase extends GameBase, GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries {
  /**
   * synthetic frontend property, the backend does not generate this
   * This should be generated during post-processing of endpoint data
   */
  kind: "loggedIn" | "loggedOut"
  //
  // {homeTeam, visitorTeam} maybe part of `GameBase`?
  //
  homeTeam: HomeTeam | null
  visitorTeam: VisitorTeam | null
  ref1: RefInfo | UserID | "",
  ref2: RefInfo | UserID | "",
  ref3: RefInfo | UserID | "",
  ref4: RefInfo | UserID | "",
  ref1Vol: RefInfo | UserID | "",
  ref2Vol: RefInfo | UserID | "",
  ref3Vol: RefInfo | UserID | "",
  ref4Vol: RefInfo | UserID | "",
  bracketInfo?: Game2["bracketInfo"],
}

/**
 * Ideally the backend would send over the 'kind' property, but for now we tag it on the frontend.
 * The relevant endpoints return almost the same info but not quite, and so the resulting shapes need to be distinguished.
 */
function mungeGameScheduleLayoutDetail(rawApiFromJSON: any, kind: "loggedIn" | "loggedOut") : any {
  return {
    kind,
    ...rawApiFromJSON,
    homeTeam: emptyObjectAsNull(rawApiFromJSON.homeTeam),
    visitorTeam: emptyObjectAsNull(rawApiFromJSON.visitorTeam)
  }

  // some things come across the wire as `{}`, effectively meaning null
  function emptyObjectAsNull(v: any) {
    if (typeof v === "object" && v !== null) {
      return Object.keys(v).length > 0 ? v : null;
    }
    return v;
  }
}

/**
 * This is more like  "publicGameScheduleGamelike"
 * clarify: this is only used from the "get public game schedules" endpoint?
 */
export interface LoggedOutGame extends GameScheduleLayoutDetailBase {
  kind: "loggedOut",
  comment: string,
  division: string,
  competitionID: Integerlike,
  fieldAbbrev: string,
  fieldCity: string,
  fieldName: string,
  fieldStreet: string,
  fieldZip: string,
  gameDate: string,
  gameEnd: string,
  gameNum: number,
  gameStart: string,
  genderNeutral: number,
  homeCoaches: HomeCoach[],
  home: string,
  playoff: number,
  doPointsCount: iltypes.Numbool,
  visitorCoaches: VisitorCoach[],
  visitor: string,
  homeTeam: (HomeTeam & {region: Integerlike}) | null
  visitorTeam: (VisitorTeam & {region: Integerlike}) | null
}

/**
 * more like "getGamesForPublicView" ?
 * @param args.showUnpublished - will be an error if user does not have sufficient permissions
 */
export async function getGamesForPublicView(axios: AxiosInstance, args: {
  competitionID: iltypes.Integerlike,
  divID?: string,
  onOrAfter?: iltypes.Datelike,
  showUnpublished?: boolean,
  expand?: ("refereeDetails")[]
}) : Promise<LoggedOutGame[]> {
  const response = await axios.get(`v1/gameSchedule/${args.competitionID}`, {params: args});
  return response.data.data.schedule.map((e: any) => mungeGameScheduleLayoutDetail(e, "loggedOut"));
}

/**
 * The response shape for `getGamesForLoggedInUser`
 * fixme -- probably more of this can be put into GameBase?
 */
export interface LoggedInUserGame extends GameScheduleLayoutDetailBase {
  kind: "loggedIn",
  assignedRefs: iltypes.RefDetails[],
  comment: string,
  competitionID: number,
  division: string,
  divNum: number,
  doPointsCount: number,
  fieldAbbreviation: string,
  fieldCity: string,
  fieldID: number,
  fieldName: string,
  fieldState: string,
  fieldStreet: string,
  fieldZip: string,
  gameEnd: string,
  gameNum: number,
  gameStart: string,
  home: string,
  homeCoaches: HomeCoach[],
  isBye: number,
  visitor: string,
  homeTeam: (HomeTeam & {region: Integerlike}) | null
  visitorCoaches: VisitorCoach[],
  visitorTeam: (VisitorTeam & {region: Integerlike}) | null,
}

export async function getGamesForLoggedInUser(axios: AxiosInstance, args?: {expand?: ("refereeDetails")[]}) : Promise<LoggedInUserGame[]> {
  const response = await axios.get('v1/games', {params: args})
  return response.data.data.map((e:any) => mungeGameScheduleLayoutDetail(e, "loggedIn"));
}

export interface FIXME_CreateNewPlayerArgs {
  [key: string]: any
}
export type FIXME_CreateNewPlayerResult = /*Child?*/any;
export async function createNewPlayer(
  axios: AxiosInstance,
  args: FIXME_CreateNewPlayerArgs
) : Promise<{type: "created", value: FIXME_CreateNewPlayerResult} | {type: "duplicate-warning"}> {
  try {
    const response = await axios.post('v1/player', args);
    return {type: "created", value: response.data.data};
  }
  catch (err: any) {
    if (err instanceof AxiosErrorWrapper) {
      if (err.unwrap().response?.status === 409) {
        return {type: "duplicate-warning"}
      }
    }
    throw err;
  }
}

/**
 * almost a supertype of Child, but key case is wrong
 */
interface GetMyChildrenResult {
  childID: Guid,
  familyID: Guid,
  AYSOID: string,
  PlayerFirstName: string,
  PlayerLastName: string,
  PlayerGender: string,
  PlayerBirthDate: string,
  birthCertificate: string
}

export async function getMyChildren(axios: AxiosInstance) : Promise<GetMyChildrenResult[]> {
  const response = await axios.get('v1/myChildren')
  return response.data.data
}

export async function updateUser(axios: AxiosInstance, args: {userID: Guid, toUpdate: Partial<iltypes.User_Standard | iltypes.User_Privileged>}) : Promise<void> {
  await axios.put(`/v1/user/${args.userID}/general`, args.toUpdate)
}

export async function getRegistrationPageItem(axios: AxiosInstance, args: {pageItemID: string}) : Promise<iltypes.RegistrationPageItem> {
  const response = await axios.get(`/v1/registration/pageItem`, {params: {pageitemid: args.pageItemID}});
  return response.data.data;
}

export interface Notification {
  buttonURL: string;
  text: string;
  timestamp: string;
  fontAwesome: string;
  buttonText: string;
  dismissible: boolean;
  notificationID: string;
  seasonID: number;
}

export async function getNotifications(axios: AxiosInstance) : Promise<Notification[]> {
  const response = await axios.get(`v1/notifications`)
  return response.data.data;
}

/**
 * See backend `getOnAppMountErrorMessage`
 */
export async function getOnAppMountErrorMessage(ax: AxiosInstance, errorMessageJwt: string) : Promise<string> {
  try {
    const response = await ax.get(`v1/notifications/error`, {params: {errorMessageJwt}})
    return response.data.data;
  }
  catch (err) {
    return "";
  }
}

export interface Field {
  fieldCity: string,
  fieldAbbrev: string,
  fieldUID: Guid,
  clientID: Guid,
  fieldLat: string,
  dateUpdated: "" | DateTimelike,
  fieldLon: string,
  fieldState: string,
  fieldName: string,
  fieldID: Integerlike,
  fieldZip: string,
  active: Numbool,
  fieldStreet: string,
  fieldStatus: Integerlike,
}

export async function getPlayingFields(ax: AxiosInstance) : Promise<Field[]> {
  const response = await ax.get('v1/playingFields')
  return response.data.data;
}
