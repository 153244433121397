import { AxiosInstance } from "axios"
import { UiOption } from "src/helpers/utils"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1"

export type Selectable = {
  name: string,
  label: string,
  /**
   * Group and displayGroup are related to where the selectable option itself appears in the UI;
   * e.g. the checkbox for the selectable is grouped with other checkboxes of a similar sort.
   *
   * Unrelated but similar-seeming is `columnGroup` and `columnGroupPosition`
   *
   * Group currently serves as both group name and groupID
   * e.g. a group of "Some group name" has a UI label of "Some group name" and is uniquely keyed on "Some group name"
   * A falsy `group` property means "default" group.
   */
  group?: string,
  /**
   * Nested, ordinal indexed groupings within the "parent" grouping
   * If undefined, we treat it as if it were 1 ("the first grouping in the parent group")
   */
  displayGroup?: number,
  colDataType: "string" | "booleanish"
  selected?: boolean,
  argsDef?: Selectable_ArgDef[],
  /**
   * temporarily, this may be true, under the following conditions:
   *  - backend does not yet have a v4 resolver for a v3 target
   */
  __dev__disabled?: boolean,
  /**
   * columnGroup and columnGroupPosition are the "default" indices for resulting selects for this selectable.
   * This _should_ be defined for every selectable, but if it isn't, then code should default it to -1.
   */
  columnGroup?: Integerlike,
  columnGroupPosition?: Integerlike,
}

// it's likely that "functionlike" is the only thing we'll ever see on the frontend and we can tidy this up.
// The backend might now about "boolean filterables", but it will expand them into the appropriate
// "functionlike" before sending them over the wire to us.
export type Filterable =
  | Filterable_Functionlike
  | Filterable_BinopImplicitLHS
  | Filterable_Boolean

interface Filterable_Base {
  /**
   * temporarily, this may be true, under the following conditions:
   *  - backend does not yet have a v4 resolver for a v3 target
   */
  __dev__disabled?: boolean,
  tooltip?: string,
}

export interface Filterable_Functionlike extends Filterable_Base {
  type: "functionlike",
  name: string,
  label: string,
  argsDef: Filterable_ArgDef<"number" | "select" | "text" | "date">[],
  /**
   * A javascript snippet used to drive a "toString" operation for rendering out a informational nodes in the UI.
   * Code is always inleague controlled; this is not, and must not be, driven by user input;
   * it always comes from our trusted backend.
   */
  jsToStringExpr?: string,
  /**
   * If `jsToStringExpr` is present, this is the executable version of that.
   * n.b. not an override of `toString`
   */
  getAsString?: (filterable: Filterable_Functionlike, args: Record<string, UiOption<any>>) => string
}

export type Binop = "eq" | "contains"

export interface Filterable_BinopImplicitLHS extends Filterable_Base {
  type: "binop-implicit-lhs",
  /**
   * synthetic frontend-only property (TODO: fix this on the backend (array of objs rather than just a keyed thing))
   */
  name: string,
  label: string,
  opDef: {
    ops: Binop[],
    rhs: Filterable_ArgDef
  }
}

/**
 * "values" here are implicitly either "0" or "1" (or more broadly, Integerlike).
 * If the stored value is not an Integerlike the meaning of the stored value is undefined
 * (in the sense of "there is no defined meaning, though probably just the js truthiness value").
 */
export interface Filterable_Boolean extends Filterable_Base {
  type: "boolean",
  /**
   * synthetic frontend-only property (TODO: fix this on the backend (array of objs rather than just a keyed thing))
   */
  name: string,
  label: string,
}

export type Selectable_ArgDef = {
  name: string,
  label: string,
  type: "select-many",
  // present for "select"
  options?: ArgDefOptions
}

export type ArgDefOptions =
  | {type: "shared", name: string}
  | {label: string, value: string}[]

type FilterableDef_ArgDefType =
  | "number"
  | "select"
  | "text"
  | "date"

export type Filterable_ArgDef<T extends FilterableDef_ArgDefType = "number" | "select" | "text"> = {
  name: string,
  label: string,
  type: T,
  // present for "select"
  options?: ArgDefOptions,
  /**
   * A javascript snippet used to drive visibility.
   * Code is always inleague controlled; this is not, and must not be, driven by user input;
   * it always comes from our trusted backend.
   */
  jsIfExpr?: string,
  /**
   * If `jsif` was present, this is the executable version of the same.
   */
  if?: (args: Record<string, UiOption<any>>) => boolean,
}

export interface Schema {
  sharedOptions: Record<string, {label: string, value: string}[]>,
  selectables: Selectable[],
  filterables: Filterable[],
}

export interface ReportLabelDef {
  label: string,
  colDataType: "string" | "date",
  columnGroupPosition: Integerlike,
  columnGroup: Integerlike,
}

export type ColumnToLabelMapping = {[resultColName: string]: ReportLabelDef};

export async function getSchemaRoots(axios: AxiosInstance) : Promise<{value: string, label: string}[]> {
  const response = await axios.get(`v1/reportBuilder/schemaRoots`)
  return response.data.data;
}

export async function getSchema(axios: AxiosInstance, queryRoot: "user" | (string & {})) : Promise<Schema> {
  const response = await axios.get(`v1/reportBuilder/schema`, {params: {queryRoot}})
  const schema = response.data.data as Schema;
  schema.filterables.forEach(filterable => {
    if (filterable.type === "functionlike") {
      if (filterable.jsToStringExpr) {
        try {
          const f : Filterable_Functionlike["getAsString"] = new Function("filterable", "args", `"use strict"; return ${filterable.jsToStringExpr}`) as any;
          if (process.env.NODE_ENV === "development") {
            filterable.getAsString = (filterable: any, args: any) => {
              try {
                return f!(filterable, args)
              }
              catch (e) {
                debugger;
                throw e;
              }
            }
          }
          else {
            filterable.getAsString = f;
          }
        }
        catch {
          if (process.env.NODE_ENV === "development") {
            throw Error(`filterable for target '${filterable.name}' failed to parse jsToStringExpr '${filterable.jsToStringExpr}'`)
          }
        }
      }
      filterable.argsDef.forEach(argDef => {
        if (argDef.jsIfExpr) {
          try {
            const f : Filterable_ArgDef["if"] = new Function("args", `"use strict"; return ${argDef.jsIfExpr}`) as any;
            if (process.env.NODE_ENV === "development") {
              argDef.if = (args: any) => {
                try {
                  return f!(args)
                }
                catch (e) {
                  debugger;
                  throw e;
                }
              }
            }
            else {
              argDef.if = f
            }
          }
          catch {
            if (process.env.NODE_ENV === "development") {
              throw Error(`filterable for target '${filterable.name}' failed to parse jsIfExpr '${argDef.jsIfExpr}'`)
            }
          }
        }
      })
    }
    else {
      // no-op
    }
  })

  return schema;
}

export interface QueryResult {
  queryResult: Record<string, string>[],
  labels: ColumnToLabelMapping,
}

export async function runQuery(axios: AxiosInstance, query: SerializedQueryRequest) : Promise<QueryResult> {
  const response = await axios.post(`v1/reportBuilder/runQuery`, {query})
  return response.data.data;
}

export async function listQueries(ax: AxiosInstance, args: {coreEntity: string, userID: Guid | "ALL"}) : Promise<{queryID: Guid, queryName: string}[]> {
  const response = await ax.get(`V1/reportBuilder/queries`, {params: {coreEntity: args.coreEntity, userID: args.userID}});
  return response.data.data;
}

export async function getQuery(ax: AxiosInstance, args: {queryID: Guid}) : Promise<{query: SerializedQueryRequest}> {
  const response = await ax.get(`V1/reportBuilder/query`, {params: {queryID: args.queryID}});
  return response.data.data;
}

export type SerializedQueryRequest = {
  coreEntity: string,
  select: {[name: string]: SerializedSelection},
  where: WhereClause,
}

export interface SerializedSelection {
  args: {
    [argName: string]: string | string[]
  },
  columnGroup: Integerlike,
  columnGroupPosition: Integerlike,
}

export type WhereClause =
  | {or: (WhereClause | FunctionlikeWhereArgs)[]}
  | {and: (WhereClause | FunctionlikeWhereArgs)[]}

/**
 * It is required that each "FunctionlikeWhereArgs" object has exactly 1 top-level key,
 * representing the name of the functionlike it is invoking
 */
export type FunctionlikeWhereArgs = {
  [name: string]: Record<string, string | number>
}
