<template lang="pug">
FormKit.space-y-8.divide-y.divide-gray-200.border-gray-200(@submit='updateCompetitionDetails', type='form', :actions='false', v-if='ready' data-test="SeasonalSettings")
  .space-y-8.divide-y.divide-gray-200(class='sm:space-y-5')
    .max-w-2xl.mx-auto
      div
        h3.text-lg.leading-6.font-medium.text-gray-900
          | Competition Season Dates
      .mt-6.space-y-6(class='sm:mt-5 sm:space-y-5')
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Season Start*
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='date',
              placeholder='Season Start*',
              name='form.seasonStart',
              v-model='form.seasonStart',
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-6 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Season Weeks*
            .block.font-normal.mt-2.text-gray-500.mr-2 The number of weeks the competition lasts for this season. Used to determine relevant dates for game and referee scheduling.
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              placeholder='Season Weeks*',
              name='form.seasonWeeks',
              v-model='form.seasonWeeks',
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Registration Opens
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='datetime-local',
              placeholder='Registration Opens',
              name='form.registrationStart',
              v-model='form.registrationStart'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Registration Closes
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='datetime-local',
              placeholder='Registration Closes',
              name='form.registrationEnd',
              v-model='form.registrationEnd'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-6 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Division Director Email Date
            .block.font-normal.mt-2.text-gray-500.mr-2 Date after which division heads are CC'd on registrations in their division
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='date',
              placeholder='Divison director email date',
              name='form.ddEmailDate',
              v-model='form.ddEmailDate',
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-6 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Profile Lock Date
            .block.font-normal.mt-2.text-gray-500.mr-2 Date after which registration profile is locked
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='date',
              placeholder='Profile lock date',
              name='form.profileLockDate',
              v-model='form.profileLockDate',
            )
        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          h3.text-lg.leading-6.font-medium.text-gray-900
            | Competition Season Registration Requirements
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Ignore Dates if Eligible*
            .block.font-normal.mt-2.text-gray-500 If true, any players explicitly eligible via one of the registration eligibility mechanisms will be able to register regardless of the competition's registration dates.
          .mt-1.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              label='Ignore Dates if Eligible*',
              name='form.ignoreDatesIfEligible',
              v-model='form.ignoreDatesIfEligible',
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5',
          v-if='Object.keys(eligibilityRulesSelect).length'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Family Eligibility Rule
            .block.font-normal.mt-2.text-gray-500 Family (user-based) eligibility rule that determines whether a family may register a player in this competition.
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='select',
              name='form.eligibilityRuleID',
              v-model='form.eligibilityRuleID',
              :options='eligibilityRulesSelect'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Volunteer Requirement
            .block.font-normal.mt-2.text-gray-500 The number of volunteer roles per family required before a player may be registered in this competition.
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              name='form.volRequirement',
              email,
              min,
              v-model='form.volRequirement'
            )
        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          h3.text-lg.leading-6.font-medium.text-gray-900
            | Competition Season Eligibility Rules
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Fee Override
            .block.font-normal.mt-2.text-gray-500 If specified, users may specify their own registration fee for this competition no lower than this value. Leave blank for automatic fee calculation only.
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              name='form.feeOverride',
              v-model='form.feeOverride'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Default Refund Holdback
            .block.font-normal.mt-2.text-gray-500 The amount to automatically deduct from a full refund for this competition. Specify 0 for no holdback.
          .mt-1.pl-4(class='sm:mt-0')
            FormKit(
              type='text',
              name='form.refundHoldback',
              v-model='form.refundHoldback',
              numberType='float'
            )
        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          h3.text-lg.leading-6.font-medium.text-gray-900
            | Competition Season Waitlist
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Wait List Date
            .block.font-normal.mt-2.text-gray-500 All players registered for this competition on or after this date will be flagged as waitlisted. If empty, wait-list will be determined by division caps only rather than date.
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='date',
              placeholder='Wait List Date',
              name='form.waitListDate',
              v-model='form.waitListDate'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
          data-test="waitlistIsFreeGroup"
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Waitlist Mode
            .block.font-normal.mt-2.text-gray-500 By default, waitlisted players are charged the registration fee when they register, and may be refunded if they are not placed. Alternatively, waitlist registrations can hold a credit card securely in reserve on the waitlist manager, and either charge it or invite the submitter to pay upon activation. The legacy 'waitlist is free' method of simply not charging waitlist players anything is no longer recommended, but still supported.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type="radio"
              name="waitlistIsFree"
              fieldset-class="$reset"
              v-model='form.waitListIsFree',
              :options="[{label: '(Classic Mode) Waitlisted players are charged at registration', value: WaitlistIsFree_t.WAITLIST_IS_NOT_FREE, attrs:{'data-test': `waitlistIsFree=${WaitlistIsFree_t.WAITLIST_IS_NOT_FREE}`}}]"
            )
            FormKit(
              type="radio"
              name="waitlistIsFree"
              fieldset-class="$reset"
              v-model='form.waitListIsFree',
              :options="[{label: '(Waitlist Manager) Waitlist requires a payment method, to be charged upon activation', value: WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT, attrs:{'data-test': `waitlistIsFree=${WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT}`}}]"
            )
            FormKit(
              type="radio"
              name="waitlistIsFree"
              fieldset-class="$reset"
              v-model='form.waitListIsFree',
              :options="[{label: '(Waitlist is free) Waitlisted players are never charged', value: WaitlistIsFree_t.WAITLIST_IS_FREE, attrs:{'data-test': `waitlistIsFree=${WaitlistIsFree_t.WAITLIST_IS_FREE}`}}]"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            class='sm:mt-px sm:pt-2'
          )
            | Conditionally enforced waitlisted registrations
            .block.font-normal.mt-2.text-gray-500
              | (Waitlist Manager Only) Whenever any registrations in a division are waitlisted pending payment,
              | all new, incoming registrations will also be waitlisted, even if they otherwise might not be due
              | to division caps, drops, or waitlist dates.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type="checkbox"
              fieldset-class="$reset"
              data-test="ifHasAnyWaitlistedButUnpaidCompRegThenWaitlistComputeYieldsWaitlistedButUnpaid"
              v-model='form.ifHasAnyWaitlistedButUnpaidCompRegThenWaitlistComputeYieldsWaitlistedButUnpaid',
            )
        div(
          class='sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-sm.font-medium.text-gray-700(
            for='username',
            class='sm:mt-px sm:pt-2'
          )
            | Email content when inviting users to complete payment for program registration with pending payment
            .block.font-normal.mt-2.text-gray-500 When a waitlist requires a payment method to be charged upon activation, the associated users can be later invited to complete the payment. This content will be included in the generated invitation email.
          div.mt-2
            quill-editor(class="bg-white" v-model:value='form.invitationToCompletePendingPaymentEmailHtml')

      TBtn(
        class="mt-4"
        label='Update Competition Details',
        type='submit'
        data-test="submit"
      )
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { formatForDateInput } from 'src/helpers/formatDate'
import { createSelect } from 'src/helpers/schemaService'

import { defineComponent, ref, onMounted, watch, getCurrentInstance } from 'vue'
import { CompetitionSeason, isCfNull, WaitlistIsFree_t } from 'src/interfaces/InleagueApiV1'
import * as ilapi from "src/composables/InleagueApiV1"

import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'

export default defineComponent({
  name: 'SeasonalSettings',
  components: {
    QuillEditor
  },
  props: {
    competitionUID: {
      type: String,
      required: true,
    },
    seasonUID: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const form = ref ({} as CompetitionSeason)
    const ready = ref(false)
    const eligibilityRulesSelect = ref<Record<string, any>>({})
    const localInstance = getCurrentInstance()
    const $toast = localInstance?.appContext.config.globalProperties.$toast

    const ddEmailDate = ref('ddEmailDate')

    const updateCompetitionDetails = async (values: any) : Promise<void> => {
      try {
        await ilapi.updateCompetitionSeason(axiosInstance, form.value);
        $toast.success({
          message: `Seasonal settings have been succesfully updated.`,
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const getEligibilityRule = async () => {
        const response = await axiosInstance.get(`v1/eligibilityRules`, {
          params: { type: 'userID' },
        })
        return response.data.data
    }

    const initValues = async () => {
      form.value = inplacePrepForForm(
        await ilapi.getCompetitionSeason(axiosInstance, props.competitionUID, props.seasonUID)
      );
      ready.value = true
    }

    watch(
      () => [props.competitionUID, props.seasonUID],
      async () => {
        await initValues()
      }
    )

    onMounted(async () => {
      const eligibilityRules = await getEligibilityRule()
      eligibilityRulesSelect.value = createSelect(
        eligibilityRules,
        'ruleID',
        'groupLabel'
      )
      eligibilityRulesSelect.value[''] = '-- No Rule Assigned --'

      await initValues()
    })

    return {
      form,
      ready,
      eligibilityRulesSelect,
      ddEmailDate,
      updateCompetitionDetails,
      WaitlistIsFree_t,
    }
  },
})

/**
 * Post-process a CompetitionSeason object with intent to make it usable in the form.
 * Operates in-place (mutates the passed in value) and for convenience returns the passed in value
 */
function inplacePrepForForm(value: CompetitionSeason) {
  value['seasonStart'] = formatForDateInput(value['seasonStart'])
  value.ddEmailDate = formatForDateInput(value.ddEmailDate as string)
  value['registrationStart'] = dayjs(value['registrationStart']).format(
    'YYYY-MM-DDTHH:mm'
  )
  value['registrationEnd'] = dayjs(value['registrationEnd']).format(
    'YYYY-MM-DDTHH:mm'
  )
  value['waitListDate'] = formatForDateInput(value['waitListDate'])
  value.profileLockDate = isCfNull(value.profileLockDate) ? "" : formatForDateInput(value.profileLockDate);

  // force to boolean for use with formkit checkbox
  value.ifHasAnyWaitlistedButUnpaidCompRegThenWaitlistComputeYieldsWaitlistedButUnpaid =
    !!value.ifHasAnyWaitlistedButUnpaidCompRegThenWaitlistComputeYieldsWaitlistedButUnpaid;
  return value
}

</script>