import * as ExcelJS from "exceljs";

/**
 * aoa === array of arrays
 *
 * assumes first row is "header" row
 * assumes colWidths length is same as column count
 * assumes header + rows have same column count
 * always applies "bold" font style to first row
 */
function xlsxFromAOA(colWidths: number[], rows: (string | number)[][]) : Promise<ArrayBuffer> {
  return multiSheetXlsxFromSheetDefs([{
    sheetName: "Sheet 1",
    colWidths,
    rows
  }])
}

export interface SimpleSheetDef {
  sheetName: string,
  colWidths: number[],
  rows: (string | number)[][]
}

export function xlsxBufferFromSheetDef(sheetDef: SimpleSheetDef) : Promise<ArrayBuffer> {
  return multiSheetXlsxFromSheetDefs([sheetDef])
}

export function multiSheetXlsxFromSheetDefs(sheetDefs: SimpleSheetDef[]) : Promise<ArrayBuffer> {
  const workbook = new ExcelJS.Workbook();

  for (const sheetDef of sheetDefs) {
    const {sheetName, colWidths, rows} = sheetDef;
    const sheet = workbook.addWorksheet(sheetName);

    sheet.columns = colWidths.map(width => ({width}))
    sheet.addRows(rows);

    const colCount = rows[0].length;
    const headerRow = sheet.getRow(1);
    for (let i = 1; i <= colCount; ++i) {
      const cell = headerRow.getCell(i);
      // default font name is always "Calibri", but setting it to bold seems to change it to something else
      cell.font = {name: "Calibri", bold: true};
    }
  }

  return workbook.xlsx.writeBuffer()
}

/**
 * This tries to set column widths appropriately, using the naive appropach of "max chars per column"
 */
export function builderWithKludgyAutoWidths(headers: string[], maxWidth = 100) {
  const defaultWidth = 12;

  const colWidths : number[] = []
  for (let i = 0; i < headers.length; ++i) {
    colWidths.push(defaultWidth);
  }

  const rows : (string | number)[][] = [];

  // We're using "count of characters" to set column width, but it's not too precise a source of info.
  // Really we want "autoFit" but that's not a thing with this library, it seems.
  // So we expand the count of characters by some amount of slop, to account for differing character widths.
  const slopMultiplier = 1.075;

  const self = {
    build: () : Promise<ArrayBuffer> => xlsxFromAOA(colWidths, rows),
    pushRow: (row: (string | number | undefined | null)[]) => {
      for (let i = 0; i < headers.length; ++i) {
        const v = row[i]
        const width = ((typeof v === "number" ? v.toString() : v)?.length ?? 0) * slopMultiplier;
        const freshWidth = Math.max(colWidths[i], width)
        colWidths[i] = Math.min(freshWidth, maxWidth);
      }
      rows.push(row.map(s => s ?? ""));
    },
    pushRows: (rows: (string | undefined | null)[][]) => {
      rows.forEach(row => self.pushRow(row))
    },
    getAsSimpleSheetDef(sheetName = "Sheet 1") : SimpleSheetDef {
      return {
        sheetName,
        colWidths,
        rows,
      }
    }
  }

  self.pushRow(headers);

  return self;
}
