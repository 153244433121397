import type { AxiosInstance } from "axios";
import type * as iltypes from "src/interfaces/InleagueApiV1"
import { Integerlike } from "src/interfaces/InleagueApiV1";
import type { Invoice } from "src/interfaces/Store/checkout"

export type { Invoice }

export async function getMyOutstandingPayableInvoices(axios: AxiosInstance) : Promise<Invoice[]> {
  const response = await axios.get(`v1/invoices/payable/me`)
  return response.data.data;
}

export interface PayInvoiceArgs {
  instanceID: Integerlike,
  idempotencyKey: string,
  paymentMethodID: string | undefined,
  discardCard: boolean | undefined,
}

// callers had been consuming: .error
type PayInvoiceResponse = unknown;

export async function payInvoice(ax: AxiosInstance, args: PayInvoiceArgs) : Promise<PayInvoiceResponse> {
  const response = await ax.post(`v1/invoice/${args.instanceID}`, args);
  return response.data.data;
}

export interface AttachTentativeStripePaymentMethodToInvoiceArgs {
  instanceID: iltypes.Integerlike,
  paymentMethodID: string
}

export async function attachTentativeStripePaymentMethodToInvoice(axios: AxiosInstance, args: AttachTentativeStripePaymentMethodToInvoiceArgs) : Promise<void> {
  const response = await axios.post(`v1/invoice/${args.instanceID}/stripePaymentMethod`, {paymentMethodID: args.paymentMethodID});
  return response.data.data;
}

export async function deleteInvoice(ax: AxiosInstance, args: {invoiceInstanceID: Integerlike}) : Promise<void> {
  await ax.delete(`v1/invoice/${args.invoiceInstanceID}`);
}

export async function getPaymentScheduleBlurb(ax: AxiosInstance, args: {invoiceInstanceID: Integerlike}) : Promise<string | undefined> {
  const response = await ax.get(`v1/invoice/paymentScheduleBlurb`, {params: {invoiceInstanceID: args.invoiceInstanceID}})
  // api will say `""`, meaning "nothing" here; we'll convert that to undefined here
  return response.data.data || undefined;
}
