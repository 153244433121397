import axios, { type AxiosInstance } from "axios";
import * as iltypes from "src/interfaces/InleagueApiV1";
import { isInleagueApiError2 } from "./InleagueApiV1";
import * as AuthCommon from "./InLeagueApiV1.Authenticate.Common"

export * from "./InLeagueApiV1.Authenticate.Common"
export * as public_ from "./InleagueApiV1.Authenticate.Public"

/**
 * While this notionally supports lookup for "any user", backend permissions checks require that the requesting user and the target user be the same.
 */
export async function getMfaDetailsForSomeUser(axios: AxiosInstance, args: {userID: iltypes.Guid}) : Promise<AuthCommon.MfaDetails> {
  const response = await axios.get(`v1/authenticate/${args.userID}/mfa`);
  return response.data.data;
}

export async function deleteAuthNProvider(axios: AxiosInstance, args: {userID: iltypes.Guid, provider: AuthCommon.SupportedOauthProvider}) : Promise<AuthCommon.MfaDetails> {
  const response = await axios.delete(`v1/authenticate/${args.userID}/third-party-authn-providers`, {params:args});
  return response.data.data;
}

/**
 * Notify the backend that some user wants to link their account to inleague.
 * We receive back a URL that the browser can navigate to in order to do the oauth thing with the requested provider.
 */
export async function notifyOfIntentToInit3rdPartyOauthConnectFlow(axios: AxiosInstance, args: {userID: iltypes.Guid, provider: AuthCommon.SupportedOauthProvider, onSuccessURL: string}) : Promise<string> {
  const response = await axios.post(`/v1/authenticate/${args.userID}/third-party-authn-providers`, {provider: args.provider, onSuccessURL: args.onSuccessURL});
  return response.data.data;
}

/**
 * the phoneNumber MUST be a 10 digit string matching the pattern /^\d{10}$/
 */
export async function getFreshSmsInitCode(axiosInstance: AxiosInstance, args: {userID: iltypes.Guid}) : Promise<{ok: true} | {ok: false, detail: AuthCommon.SmsFailureReason}> {
  try {
    await axiosInstance.post(`v1/authenticate/${args.userID}/mfa/sms/reset`);
    return {ok:true}
  }
  catch (err) {
    if (axios.isAxiosError(err) && isInleagueApiError2(err)) {
      const detail = err.response.data.messages[0] as AuthCommon.SmsFailureReason
      return {ok: false, detail}
    }
    throw err;
  }
}

export async function validateFreshSmsInitCode(
  axiosInstance: AxiosInstance,
  args: {userID: iltypes.Guid, code: string}
) : Promise<
  | {ok: true, detail: AuthCommon.MfaDetails}
  | {ok: false, detail: "too-old" | "invalid-code"}
> {
  try {
    const response = await axiosInstance.post(`v1/authenticate/${args.userID}/mfa/sms/validate-fresh`, {code: args.code});
    return {ok: true, detail: response.data.data}
  }
  catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400 && isInleagueApiError2(e)) {
      const message = e.response.data.messages[0];
      if (message === "too-old" || message === "invalid-code") {
        return {ok: false, detail: message}
      }
    }
    // otherwise, unhandled
    throw e;
  }
}

export async function getFreshTotpInitSecret(axios: AxiosInstance, args: {userID: iltypes.Guid}) : Promise<AuthCommon.TotpSetupKeyDetail> {
  const response = await axios.post(`v1/authenticate/${args.userID}/mfa/totp/reset`);
  return response.data.data;
}

export async function validateFreshTotpInitSecret(
  axiosInstance: AxiosInstance,
  args: {userID: iltypes.Guid, passcode: string}
) : Promise<
  | {ok: true, detail: AuthCommon.MfaDetails}
  | {ok: false, detail: "too-old" | "invalid-code"}
> {
  try {
    const response = await axiosInstance.post(`v1/authenticate/${args.userID}/mfa/totp/validate-fresh`, {passcode: args.passcode});
    return {
      ok: true,
      detail: response.data.data
    }
  }
  catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400 && isInleagueApiError2(e)) {
      const message = e.response.data.messages[0];
      if (message === "too-old" || message === "invalid-code") {
        return {ok: false, detail: message}
      }
    }
    // otherwise, unhandled
    throw e;
  }
}

export async function deleteSmsForUser(axios: AxiosInstance, args: {userID: iltypes.Guid}) : Promise<AuthCommon.MfaDetails> {
  const response = await axios.delete(`v1/authenticate/${args.userID}/mfa/sms`);
  return response.data.data;
}

export async function deleteTotpForUser(axios: AxiosInstance, args: {userID: iltypes.Guid}) : Promise<AuthCommon.MfaDetails> {
  const response = await axios.delete(`v1/authenticate/${args.userID}/mfa/totp`);
  return response.data.data;
}

/**
 * the common suffix here is "/public/authenticate/loginWithGoogle/oauth", path prefix and origin
 * changes based on build variant
 */
function googleOauthRedirectURL() {
  if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
    // testinleague.localtest.me (not testinleague.localtest.me:8070)
    // inleague.localtest.me (not inleague.localtest.me:8070)
    // might get borked on capacitor:// schemes
    return "https://" + window.location.hostname + "/api/public/authenticate/loginWithGoogle/oauth";
  }
  else {
    return process.env.GOOGLE_OAUTH_LOGIN_REDIRECT_URL
  }
}

// TODO: how does origin/redirect_uri work for mobile, with multiple user matches ? ...
export function buildGoogleLoginOauthURL(clientID_or_unknown: iltypes.Guid | "?", frontend_host: string) {
  const redirect_uri = googleOauthRedirectURL();
  const state = btoa(JSON.stringify({which: "login", frontend_host, clientID_or_unknown, redirect_uri}));

  const scopes = encodeURIComponent(
    [
      "email",
      "openid",
      "profile",
      GooglePeopleApiV1.scopes.PRIMARY_EMAIL_READONLY,
      GooglePeopleApiV1.scopes.ADDRESSES_READONLY,
      GooglePeopleApiV1.scopes.BIRTHDAY_READONLY,
      GooglePeopleApiV1.scopes.PHONENUMBERS_READONLY
    ].join(" ")
  );

  return [
    `https://accounts.google.com/o/oauth2/auth?`,
    `scope=${scopes}&state=${state}&`,
    'response_type=code&',
    `redirect_uri=${encodeURI(redirect_uri)}&`,
    `client_id=${process.env.GOOGLE_OAUTH_CLIENT_ID}&`,
    `approval_prompt=auto&access_type=online`
  ].join("");
}

const GooglePeopleApiV1 = {
  scopes: {
    CONTACTS: "https://www.googleapis.com/auth/contacts",//	See, edit, download, and permanently delete your contacts
    CONTACTS_OTHER_READONLY: "https://www.googleapis.com/auth/contacts.other.readonly", // See and download contact info automatically saved in your "Other contacts"
    CONTACTS_READONLY: "https://www.googleapis.com/auth/contacts.readonly", // See and download your contacts
    DIRECTORY_READONLY: "https://www.googleapis.com/auth/directory.readonly", // See and download your organization's GSuite directory
    ADDRESSES_READONLY: "https://www.googleapis.com/auth/user.addresses.read", // View your street addresses
    BIRTHDAY_READONLY: "https://www.googleapis.com/auth/user.birthday.read", // See and download your exact date of birth
    EMAILS_READONLY: "https://www.googleapis.com/auth/user.emails.read", // See and download all of your Google Account email addresses
    GENDER_READONLY: "https://www.googleapis.com/auth/user.genders.read", // See your gender
    ORGANIZATION_READONLY: "https://www.googleapis.com/auth/user.organization.read", // See your education, work history and org info
    PHONENUMBERS_READONLY: "https://www.googleapis.com/auth/user.phonenumbers.read", // See and download your personal phone numbers
    PRIMARY_EMAIL_READONLY: "https://www.googleapis.com/auth/userinfo.email", // See your primary Google Account email address
    PROFILE_READONLY: "https://www.googleapis.com/auth/userinfo.profile", //	See your personal info, including any personal info you've made publicly available
  }
} as const;

export async function loginUsingExistingJWT(axios: AxiosInstance, args: {jwt: string}) : Promise<AuthCommon.AuthenticateResponse_Complete> {
  const response = await axios.get('v1/authenticate', {headers: {Authorization: `Bearer ${args.jwt}`}})
  const result = response.data.data as AuthCommon.AuthenticateResponse_Complete;

  // This api endpoint returns "bearer <token>" instead of "<token>"
  // We should probably fix it the endpoint, not a huge deal though
  // Oct/19/2023 -- backend fixed up via a7991b333ede0ea7d93c3ecea84d6f922f49148d, this can be removed in a week or so after that patch sticks
  if (/^bearer /i.test(result.jwt)) {
    // Get just the token part of the security token
    // We expect to get `"Bearer some.jwt.token.with.no.spaces.in.it.here"`
    //                    ^^^^^^^ we want everything past this
    result.jwt = result.jwt.split(" ")[1]
  }

  return result
}

export async function impersonate(axios: AxiosInstance, args: {becomeThisUserID: iltypes.Guid}) : Promise<AuthCommon.AuthenticateResponse_Complete> {
  const impersonatedUser = await axios.put(`v1/authenticate/${args.becomeThisUserID}`)
  return impersonatedUser.data.data;
}

/**
 * Restore the current user's credentials to their own.
 * Calling this function is an error if the current user is not currently impersonating another user.
 */
export async function unimpersonate(axios: AxiosInstance) : Promise<AuthCommon.AuthenticateResponse_Complete> {
  const response = await axios.delete(`v1/authenticate`)
  return response.data.data;
}
