import { AxiosInstance } from "axios";
import { parseIntOr, sortBy } from "src/helpers/utils";
import { CompetitionUID, DivID, Guid, Integerlike, SeasonUID, TeamID } from "src/interfaces/InleagueApiV1";

/**
 * Most presets don't require arguments -- in those cases, the preset name must map to `undefined`.
 * Presets that require arguments should map to a Record<string, any> of argument types.
 * Related backend - TeamChooserDataService
 */
interface PresetTypesAndArgs {
  "team-assignment-email-blast": undefined,
  "division-lineup-cards": undefined,
  "team-rosters": undefined,
  "dd-moveup-report": undefined,
  "team-pools-and-division-heads": undefined,
  "check-requests": undefined,
  "uniforms": undefined,
  "assigned-coaches-report": undefined,
  "team-colors-and-names": undefined,
  "team-announcements": undefined,
  "team-assignments-divisions-only": undefined,
  "coach-feedback-seasons-only": undefined,
  "create-event/team-event-team-listing/registrar": {seasonUIDs: Guid[]},
  "create-event/team-event-team-listing/coach": {seasonUIDs: Guid[]},
  // Contract here is the menu has one root season ("the current season"),
  // and all the relevant (comp, div) entries, but no team leaf levels.
  "team-crud-view-by-comp-div": undefined,
  "team-pools/crud-1": undefined,
}

export type Preset = keyof PresetTypesAndArgs
type PresetArgs<K extends Preset> = PresetTypesAndArgs[K]

// TODO: need a top-level root node, or we have nowhere to put metadata as we do on all the other levels
type TeamChooserMenuRaw = {[seasonUID: Guid]: SeasonItem};

// we synthesize the top-level root node here; "first level" items are always seasons
export type TeamChooserMenu = {
  orderedChildren: Map<SeasonUID, SeasonItem>
}

export interface SeasonItem {
  name: string,
  ID: Integerlike,
  orderedChildren: Map<CompetitionUID, CompetitionItem>,
  /**
   * present from backend, but app shouldn't use it;
   * strictly for converting into an ordered sequence
   */
  competitions?: {[competitionUID: Guid]: CompetitionItem}
  /**
   * same comments as `competitions`
   */
  sortedCompetitions?: CompetitionUID[],
}

export interface CompetitionItem {
  name: string,
  ID: Integerlike,
  orderedChildren: Map<DivID, DivisionItem>,
  /**
   * present from backend, but app shouldn't use it;
   * strictly for converting into an ordered sequence
   */
  divisions?: {[divID: Guid]: DivisionItem},
  /**
   * same comments as `divisions`
   */
  sortedDivisions?: DivID[],
}

export interface DivisionItem {
  name: string,
  shortName: string,
  gender: string,
  orderedChildren: Map<TeamID, TeamItem>,
  /**
   * present from backend, but app shouldn't use it;
   * strictly for converting into an ordered sequence
   */
  teams?: {[teamID: Guid]: TeamItem},
  /**
   * same comments as `teams`
   */
  sortedTeams?: TeamID[]
}

export interface TeamItem {
  letter: string,
  name: string,
  /**
   * optional, depending on preset / config
   */
  headCoaches?: string
}

async function getTeamChooserMenuWorker<P extends Preset>(axios: AxiosInstance, preset: P, args?: PresetArgs<P>) : Promise<TeamChooserMenu> {
  const response = await axios.get(
    `v1/teams/teamChooser`,
    {
      params: {
        preset: preset,
        presetArgs: args
      }
    }
  );

  const menuDefRaw : TeamChooserMenuRaw = response.data.data;

  return mungeMenu(menuDefRaw);
}

function mungeMenu(menuDefRaw: TeamChooserMenuRaw) {
  const debugPath : string[] = [];

  const withDebugPath = (v: string, f: () => void) => {
    debugPath.push(v);
    f();
    debugPath.pop();
  }

  const menuDef : TeamChooserMenu = {
    orderedChildren: new Map(Object.entries(menuDefRaw).sort(sortBy(_ => parseIntOr(_[1].ID, -1), "desc")))
  }

  for (const [seasonUID, seasonItem] of menuDef.orderedChildren.entries()) {
    withDebugPath(seasonUID, () => {
      seasonItem.orderedChildren = new Map(seasonItem.sortedCompetitions!.map(compUID => [compUID, getOrFail(seasonItem.competitions!, compUID)]));
      for (const [compUID, compItem] of seasonItem.orderedChildren.entries()) {
        withDebugPath(compUID, () => {
          compItem.orderedChildren = new Map(compItem.sortedDivisions!.map(divID => [divID, getOrFail(compItem.divisions!, divID)]));
          for (const [divID, divItem] of compItem.orderedChildren.entries()) {
            withDebugPath(divID, () => {
              divItem.orderedChildren = new Map(divItem.sortedTeams!.map(teamID => [teamID, getOrFail(divItem.teams!, teamID)]))
            })
          }
        })
      }
    })
  }

  return menuDef;

  function getOrFail<T extends Record<string, any>, K extends (keyof T) & string>(record: T, key: K) : T[K] {
    const v = record[key]
    if (!v) {
      throw Error(`Unexpected key lookup failure, key='${key}', path=${debugPath.join(",")}`);
    }
    return v;
  }
}

// This is conceptually an overload set, where it may or may not take presetArgs in the last position.
// Not sure how to write an actual overload set that changes based on undefindedness of some type.
type GetMenuArgs<P extends Preset> = PresetArgs<P> extends undefined
  ? [axios: AxiosInstance, preset: P] // function sig for when no args are required
  : [axios: AxiosInstance, preset: P, args: PresetArgs<P>]; // function sig when args are required

export const getTeamChooserMenu : (<P extends Preset>(...args: GetMenuArgs<P>) => Promise<TeamChooserMenu>) = getTeamChooserMenuWorker;

export const testExports = {
  mungeMenu,
}
