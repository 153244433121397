import { NavigationGuardWithThis, RouteLocationRaw } from "vue-router"
import * as MaintenanceNavigationGuard from "src/router/MaintenanceNavigationGuard"
import { Guid } from "src/interfaces/InleagueApiV1";
import { exhaustiveCaseGuard } from "src/helpers/utils";

//
// (flow step name -> route name)
//
export enum FlowStepRouteName {
  user = 'volunteer-registration-flow.user',
  season = 'volunteer-registration-flow.season',
  contactInfo = 'volunteer-registration-flow.contactInfo',
  volunteerRoles = 'volunteer-registration-flow.volunteerRoles',
  hasNoStackKeyNeedsNameAndDobEdit = "volunteer-registration-flow.hasNoStackKeyNeedsNameAndDobEdit",
  elas = 'volunteer-registration-flow.elas',
  done = 'volunteer-registration-flow.done',
  entry = season,
  unavailable = "volunteer-registration-flow.unavailable"
}

export interface HasNoStackKeyNeedsNameAndDobEdit {
  firstName: string,
  middleName: string,
  lastName: string,
  gender: "M" | "F" | "",
  dob: string
}

export const maintenanceNavigationGuard: NavigationGuardWithThis<undefined> = async (_to, _from, next) => {
  const availability = await MaintenanceNavigationGuard.volunteerRegistrationAvailability();
  if (availability === MaintenanceNavigationGuard.Availability.AVAILABLE) {
    next()
  }
  else {
    next({ name: FlowStepRouteName.unavailable, params: {} })
  }
}


// this isn't fully fleshed out for all subroutes, just the ones we need to nav to from across the app
// eventually we should handle all the route names here
export function routeDetailToRouteLocation(detail: {name: FlowStepRouteName.volunteerRoles, userID: Guid, seasonUID: Guid}) : RouteLocationRaw {
  switch(detail.name) {
    case FlowStepRouteName.volunteerRoles:
      return {name: detail.name, params: {seasonUID: detail.seasonUID, volunteerID: detail.userID}};
    default:
      exhaustiveCaseGuard(detail.name);
  }
}
