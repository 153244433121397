import { boot } from 'quasar/wrappers'
import { Capacitor, Plugins } from '@capacitor/core'
const { Browser } = Plugins
import { updateApiUrl } from 'src/boot/axios'
import { maybeParseJSON } from 'src/helpers/utils'
import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default boot(async (config) => {
  const app = config.app;
  const router = config.router;
  let params: any = {}
  // console.log('auth.ts boot')
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // Always update local storage JWT from GA_IL cookie if such cookie exists
  // This is in particular used to pass credentials "from old app to new app"
  // It is expected that, if present, this cookie is "at least as recent"
  // as the existing stored JWT token (if one exists)
  const cookieValue = findRawCookie("GA_IL");
  if (cookieValue) {
    localStorage.setItem("jwt", cookieValue);
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // Restore Multi Client URL / League data preferences from previous session
  const clientLeagueJSON = localStorage.getItem('clientLeague') as string
  const savedClientLeague =
    clientLeagueJSON && clientLeagueJSON.length
      ? maybeParseJSON(clientLeagueJSON)
      : false
  if (savedClientLeague) {
    System.directCommit_setClientUrl(savedClientLeague.appDomain)
    await updateApiUrl(`https://${savedClientLeague.appDomain}/api`)
    System.directCommit_setClientLeague(savedClientLeague.regionName)

    // Client.directCommit_setClientID(id)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // Restore Session from JWT
  await User.maybeRestoreSessionFromLocalStorageJWT()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // Setup Route login check
  router.beforeEach((to, from, next) => {
    next()
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // OTHER SETUP
  if (Object.keys(Client.value.instanceConfig).length === 0 && !process.env.isMobile) {
    const instanceConfig = await Client.getInstanceConfig()
    Client.directCommit_setInstanceConfig(instanceConfig)
  }
  // console.log('after Object.keys')
  await Client.maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // Setup legacy link helper
  app.config.globalProperties.$openLegacyLink = (
    urlTarget: string,
    eventTarget: string
  ) => {
    const legacyLink = buildLegacyLink(Client.value.instanceConfig.appdomain, urlTarget, eventTarget);

    if (process.env.isMobile) {
      app.config.globalProperties.$toast.question({
        overlay: true,
        toastOnce: true,
        id: 'question',
        title: '',
        color: 'green', // blue, red, green, yellow
        timeout: 60000,
        message:
          'This function is not yet available within inLeague Mobile, but will open in a browser window where you may continue your session.',
        position: 'center',
        buttons: [
          [
            '<button><b>Continue</b></button>',
            (instance: any, toast: any) => {
              // Open Mobile Browser if in mobile mode
              if (Capacitor.isNative) {
                Browser.open({
                  url: legacyLink,
                })
                // Do a standard browser redirect
              } else {
                window.open(legacyLink, '_blank')
              }
              instance.hide({ transitionOut: 'fadeOut' }, toast)
            },
            true,
          ],
          [
            '<button>Cancel</button>',
            (instance: any, toast: any) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast)
            },
          ],
        ],
      })
    } else {
      window.open(legacyLink, '_blank')
    }

  }
})

function findRawCookie(name: string): string | undefined {
  const cookies = document.cookie.split("; ");
  const target = cookies.find((rawCookie) => rawCookie.startsWith(name + "="));

  if (target === undefined) {
    return undefined;
  }

  const idx = target.indexOf("=");
  return target.slice(idx + 1);
}

/**
 * Either `urlTarget` or `eventTarget` must be non-empty;
 *   - it is not valid for both to be non-empty
 *   - it is not valid for both to be empty
 *
 * `appDomain` is always required (generally pulled of the store at the callsite)
 */
export function buildLegacyLink(appdomain: string, urlTarget: string, eventTarget: string) {
    let legacyLink = `https://${appdomain}/Main/in?queryString`
    if (urlTarget?.length)
      legacyLink += '&urlTarget=' + encodeURIComponent(urlTarget)
    if (eventTarget?.length) legacyLink += '&eventTarget=' + eventTarget
    if (localStorage.getItem('jwt')?.length) {
      legacyLink += '&ga_il=' + localStorage.getItem('jwt')
    }
    return legacyLink;
}
