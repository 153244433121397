import { PropType, computed, defineComponent } from "vue";
import QuillWrapper from "./quill-wrapper.vue"
import { exhaustiveCaseGuard } from "src/helpers/utils";

/**
 * Wraps "quill wrapper 1", with the sole intent to give it more standardized v-model semantics,
 * instead of needing the `v-model:value`, and to do so without making any changes to code that
 * depends on the behavior of the underlying QuillWrapper.
 */
export const QuillWrapper2 = defineComponent({
  props: {
    modelValue: {
      // this is the v-model, it is required but can't really by typechecked
      required: false,
    },
    /**
     * "email" seems to be the only reasonable choice; there are many options which don't seem to render properly
     * outside of the Quill object, like block quotes, numnbered lists, etc. Basically quill generates HTML
     * using classes which we won't have available at runtime elsewhere when we render out the html.
     */
    configureFor: {
      required: false,
      type: String as PropType<"email" | "content-chunk">
    }
  },
  setup(props, {emit}) {
    const indirectBinding = computed({
      get() { return props.modelValue; },
      set(v: any) { emit("update:modelValue", v) }
    });

    const forceOptions : Record<string, any> | undefined = (() => {
      switch (props.configureFor) {
        case undefined:
          return undefined;
        case "content-chunk":
          return {
            theme: 'snow',
            boundary: document.body,
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'],
                ['link']
              ]
            },
            placeholder: 'Insert content here ...',
            readOnly: false
          }
        case "email":
          return {
            theme: 'snow',
            boundary: document.body,
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
              ]
            },
            placeholder: 'Insert content here ...',
            readOnly: false
          }
        default: exhaustiveCaseGuard(props.configureFor);
      }
    })();

    return () => <QuillWrapper
      forceOptions={forceOptions}
      v-model:value={indirectBinding.value}
    />
  }
})
