import { ExtractPropTypes, PropType } from "vue";

import { type ContentChunkName } from "src/interfaces/Store/contentChunks";
import { type ContentChunk } from 'src/interfaces/InleagueApiV1'

export const propsDef = {
  id: {
    required: true,
    type: [Number, String] as PropType<number | /*for some autocomplete only*/ContentChunkName | /*but any string is valid*/(string & {})>
  },
  /**
   * always interpolate on display
   * this requires that callers know what args they need to interpolate particular chunks
   */
  interpolationArgs: {
    required: false,
    type: Object as PropType<Record<string, unknown>>,
    default: () => { return {} }
  },
  classes: {
    required: false,
    type: String as PropType<string>,
    default: 'text-base mb-1 text-gray-600'
  },
  contentChunkObj: {
    required: false,
    type: Object as PropType<ContentChunk>
  },
  showPencil: {
    required: false,
    type: null as any as PropType<boolean>,
    default: true,
  }
} as const;

//
// a `default` property stomps the `required` property,
// which sort of makes sense; from the **use** site (as in, inside the component the props are for)
// the prop will be definitely-truthy, and hence conceptually required.
// But, at callsites, we don't want it to be required, since it will be filled in
// with the default value, if not provided.
//
// So we try to be clear that the prop type extracted here is only relevant for purposes of invoking the component,
// rather than for use inside the component.
//
type DropDefaultForRequiredFalseToBeRespected<T extends object> = {[P in keyof T]: Omit<T[P], "default">}
export type PropsForCaller = ExtractPropTypes<DropDefaultForRequiredFalseToBeRespected<typeof propsDef>>;
