import { defineComponent, ref, watch , onMounted, PropType, computed } from 'vue'
import { PageItemType, RegistrationPageItem, RegistrationPageItem_Question, type Registration, type RegistrationAnswer, Guid, Competition } from 'src/interfaces/InleagueApiV1'
import CustomQuestions from 'src/composables/customQuestions'
import authService from 'src/helpers/authService'
import * as iltypes from "src/interfaces/InleagueApiV1"
import { UiOption, assertNonNull, exhaustiveCaseGuard, vOptT } from 'src/helpers/utils'
import { User } from 'src/store/User'
import { FormKit, FormKitSchema } from '@formkit/vue'
import { getCompetitionsOrFail } from 'src/store/Competitions'

export default defineComponent({
  props: {
    seasonUID: vOptT<Guid>(),
    competitionUIDs: vOptT<Guid[]>(),
    playerID: vOptT<Guid>(),
    /**
     * Any answers to custom registration questions, if they exist
     */
    registrationAnswers: {
      type: Array as PropType<RegistrationAnswer[]>,
      default: () => []
    },
    triggerSave: vOptT<boolean>(),
    registrationPreview: vOptT<boolean>(),
    /**
     * fixme: make required, this is to grandfather in a new requirement; how many callers are there?
     * once required, we can remove the registrationAnswers props, since it is contained within the existingRegistration (if such a registration exists)
     */
    maybeExistingRegistration: vOptT<Registration | null>(),
  },
  emits: ['updateCustomQuestions', 'customError'],
  setup(props, {emit}) {
    const { setBuiltInFunctions, getCustomQuestions, processCustomQuestionsSchema, processAnswers } = CustomQuestions()

    type MungedPageItems = ReturnType<typeof processCustomQuestionsSchema>

    const customQuestionsForm = ref({})
    const ready = ref(false);

    // we might get away with "just" setting this to false here; does a registrar ever go through the registrarion flow
    // (on behalf of another user, with expectations that they have elevated permissions)?
    // Also, if they do, are they doing so to sign their own children up, in which case we don't want to treat them as a super user?
    const isSuperUser = computed(() => authService(User.value.roles, "registrar"));

    const partitionedMungedPageItems = ref<CompetitionPartition<MungedPageItems, {competition: Competition} & MungedPageItems>>({
      sharedOrLoose: {schema: [], collectedOptionsByQuestionID: {}},
      specificToComp: {}
    });

    watch(() => [props.seasonUID, props.competitionUIDs], async () => {
      if(props.seasonUID && props.competitionUIDs?.length) {
        // we might prefer to require parent to key on season/comp
        ready.value = false;
        await doInit();
        ready.value = true;
      }
    }, {deep:true})

    watch(customQuestionsForm, (val) => {
      if(val) {
        emit('updateCustomQuestions', val)
      }
    }, {deep: true})

    const mungeOnePageItemListing = (pageItems: RegistrationPageItem[]) : MungedPageItems => {
      return processCustomQuestionsSchema(
        /*data*/pageItems,
        /*registrationPreview*/!!props.registrationPreview,
        /*hasRegistrationRecord*/!!props.maybeExistingRegistration?.competitions.some((v) => !!v.paid && !v.canceled),
        /*discardOutOfDateContentChunks*/true,
        /*currentAnswers*/ props.maybeExistingRegistration?.registrationAnswers ?? [],
        /*callerIsSuperUser*/isSuperUser.value
      );
    }

    const doInit = async () : Promise<void> => {
      const getCompetitionOrFail = await (async () => {
        const competitions = (await getCompetitionsOrFail()).value;
        return (competitionUID: Guid) : Competition => {
          const v = competitions.find(v => v.competitionUID === competitionUID);
          assertNonNull(v);
          return v;
        }
      })();

      // it's not clear if (props.competitionUID && props.seasonUID) implies props.registrationAnswers.length > 0
      // we only get custom questions if (comp && season), but we need them during the block guarded by registrationAnswers length
      // an empty array in that case should be OK, if we didn't trigger the first (props.competitionUID && props.seasonUID) block
      let customQuestions : RegistrationPageItem[] = [];
      let optionsByQuestionID : {[questionID: iltypes.Guid]: undefined | UiOption[]} = {}

      if (props.competitionUIDs && props.seasonUID) {
        customQuestions = await getCustomQuestions(props.playerID as string, props.seasonUID, props.competitionUIDs, props.registrationPreview)

        const partitionedPageItems = partitionPageItemsByCompLinkage(props.competitionUIDs, customQuestions);

        partitionedMungedPageItems.value = {
          sharedOrLoose: mungeOnePageItemListing(partitionedPageItems.sharedOrLoose),
          specificToComp: Object.fromEntries(
            Object
              .entries(partitionedPageItems.specificToComp)
              .map(([compUID, pageItems]) => [compUID, {competition: getCompetitionOrFail(compUID), ...mungeOnePageItemListing(pageItems)}])
          )
        }

        optionsByQuestionID = {
          ...partitionedMungedPageItems.value.sharedOrLoose.collectedOptionsByQuestionID,
          ...(() => {
            const result : MungedPageItems["collectedOptionsByQuestionID"] = {}
            for (const perComp of Object.values(partitionedMungedPageItems.value.specificToComp)) {
              for (const [questionID, answer] of Object.entries(perComp.collectedOptionsByQuestionID)) {
                result[questionID] = answer;
              }
            }
            return result;
          })()
        }
      }

      if (props.registrationAnswers.length > 0) {
        const explicitlyExactlyCustomQuestions = customQuestions.filter((v) : v is RegistrationPageItem_Question => v.type === PageItemType.QUESTION);
        customQuestionsForm.value = processAnswers(explicitlyExactlyCustomQuestions, props.registrationAnswers, optionsByQuestionID)
      }
    }

    onMounted(async ()=> {
      await setBuiltInFunctions()
      await doInit();
      ready.value = true;
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div class="w-full border-t-4 border-gray-500 mt-8" data-test="CustomQuestions">
          <div class="mt-8"></div>
          {
            partitionedMungedPageItems.value.sharedOrLoose.schema.length > 0
              ? (
                <div class="my-4 shadow-md rounded-md bg-white">
                  <div class="rounded-t-md p-2 bg-gray-200">
                    {
                      Object.keys(partitionedMungedPageItems.value.specificToComp).length > 0
                        ? <span>Questions &ndash; Seasonal</span>
                        : null
                    }
                  </div>
                  <div class="p-2">
                    <FormKit v-model={customQuestionsForm.value} type="group" id={`customQuestionsForm/sharedOrLoose`}>
                      <FormKitSchema schema={partitionedMungedPageItems.value.sharedOrLoose.schema} data={customQuestionsForm}/>
                    </FormKit>
                  </div>
                </div>
              )
              : null
          }
          {
            Object
              .entries(partitionedMungedPageItems.value.specificToComp)
              .map(([compUID, mungedPartition]) => {
                if (mungedPartition.schema.length === 0) {
                  return null;
                }
                return (
                  <div key={compUID} class="my-4 shadow-md rounded-md bg-white">
                    <div class="rounded-t-md p-2 bg-gray-200">
                      Questions &ndash; {mungedPartition.competition.competition}
                    </div>
                    <div class="p-2">
                      <FormKit v-model={customQuestionsForm.value} type="group" id={`customQuestionsForm/${compUID}`}>
                        <FormKitSchema schema={mungedPartition.schema} data={customQuestionsForm}/>
                      </FormKit>
                    </div>
                  </div>
                )
              })
          }
        </div>
      )
    }
  },
})

/**
 * "loose" and "shared" are sort of the same for our purposes,
 * in that a loose pageItem is not bound to any specific competition,
 * and a shared pageItem is bound to two-or-more competitions. Because answers to questions
 * are per-season, the loose and the shared are displayed together, and then any questions
 * that are particular to only exactly one target competition are displayed together.
 */
type LinkageToCompStatus =
  | {type: "loose" | "shared"}
  | {type: "specific", competitionUID: Guid}

type CompetitionPartition<T, U=T> = {
  sharedOrLoose: T,
  specificToComp: {[competitionUID: Guid]: U}
}

function partitionPageItemsByCompLinkage(allTargetComps: Guid[], pageItems: RegistrationPageItem[]) {
  const result : CompetitionPartition<RegistrationPageItem[]> = {
    sharedOrLoose: [],
    specificToComp: {}
  }

  for (const pageItem of pageItems) {
    const status = getLinkageToCompStatus(pageItem);
    switch (status.type) {
      case "loose":
        // fallthrough
      case "shared":
        result.sharedOrLoose.push(pageItem);
        continue;
      case "specific":
        result.specificToComp[status.competitionUID] ??= []
        result.specificToComp[status.competitionUID].push(pageItem)
        continue;
      default:
        exhaustiveCaseGuard(status);
    }
  }

  return result;

  function getLinkageToCompStatus(q: RegistrationPageItem) : LinkageToCompStatus {
    if (q.itemCompetitions.length === 0) {
      return {type: "loose"};
    }
    else {
      var matches = q
        .itemCompetitions
        .filter(v => allTargetComps.includes(v.competitionUID));

      if (matches.length === 1) {
        return {type: "specific", competitionUID: matches[0].competitionUID}
      }
      else {
        return {type: "shared"};
      }
    }
  }
}
