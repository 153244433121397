import { computed, defineComponent, ExtractPropTypes, PropType } from "vue";


import { buildLegacyLink } from "src/boot/auth";

import { ExpandedEvent, EventRosterEmailHelper } from "./R_EventRosterImpl.ilx";
import { Client } from "src/store/Client";

const EventRosterEmailLegacyFormLinkProps = {
  event: {
    required: true,
    type: Object as PropType<ExpandedEvent>
  }
} as const;

export type EventRosterEmailLegacyFormLinkProps = ExtractPropTypes<typeof EventRosterEmailLegacyFormLinkProps>;

export const EventRosterEmailLegacyFormLink = defineComponent({
  props: EventRosterEmailLegacyFormLinkProps,
  setup(props) {


    const actionURL = computed(() => buildLegacyLink(Client.value.instanceConfig.appdomain, "/emailManager/email.cfm", ""));
    const initialSubject = computed(() => EventRosterEmailHelper.initialEmailSubject(props.event));
    const initialContent = computed(() => EventRosterEmailHelper.initialEmailContent(props.event));

    const targetEmailAddrs = computed(() => {
      const result : string[] = [];
      const seenTheseEmails = new Set<string>();
      const maybePushEmail = (email: string) : void => {
        const canonicalEmail = email.toLowerCase().trim()
        if (email && !seenTheseEmails.has(canonicalEmail)) {
          result.push(email);
          seenTheseEmails.add(canonicalEmail);
        }
      }

      for (const signup of props.event.signups) {
        if (!signup.paid || signup.canceled) {
          continue;
        }

        if (signup.userID) {
          maybePushEmail(props.event.rosterLayoutDetail.users[signup.userID].email);
        }
        else if (signup.childID) {
          maybePushEmail(props.event.rosterLayoutDetail.children[signup.childID].parent1Email);
          maybePushEmail(props.event.rosterLayoutDetail.children[signup.childID].parent2Email);
        }
        else {
          // unreachable
        }
      }
      return result;
    })

    return () => (
      <form method="post" target="_blank" action={actionURL.value}>
        <input type="hidden" name="emailGroup" value={props.event.contactEmail}/>
	      <input type="hidden" name="emailSubject" value={initialSubject.value}/>
	      <input type="hidden" name="emailHTML" value={initialContent.value}/>
        {
          targetEmailAddrs.value.map(emailAddr => <input type="hidden" name="emailList" value={emailAddr} />)
        }
        <t-btn type="submit">
          <div>Email participants</div>
        </t-btn>
      </form>
    );
  }
})
