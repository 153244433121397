<template lang="pug">
.p-4.bg-gray-100.rounded-md
  p AYSO asks that each family specify two family members as 'Parent 1' and 'Parent 2'. This assists us with our record-keeping and helps us know the family members with primary responsibility for a player. Parent 1 and Parent 2 have equal access.

  .mt-4
    FormKit(
      type='form'
      v-model='parentOrdinals'
      submit-label="Update Parent 1/Parent 2"
      @submit="updateParents"
    )
      .flex.flex-col.w-96(class='md:flex-row md:justify-between')
        FormKit(
          type='select'
          name='parent1'
          :options='parentOptions.noNilOption'
          label='Parent 1'
        )
        FormKit(
          type='select'
          name='parent2'
          :options='parentOptions.withNilOption'
          label='Parent 2'
        )
</template>

<script lang="ts" setup>
import type { FamilyData } from 'src/composables/InleagueApiV1.Family'
import { ref} from 'vue'
import type { PropType } from 'vue'
import * as ilapi from 'src/composables/InleagueApiV1'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { type WithDefinite, type User } from "src/interfaces/InleagueApiV1"
import { type FormKitOptionsList } from "@formkit/inputs"

const props = defineProps({
  family: {
    type: Object as PropType<WithDefinite<FamilyData, "parents">>,
    required: true
  }
})

const emit = defineEmits(['parentOrdinalsUpdated'])

const parentOptions = ref({
  withNilOption: parentOptionsList(props.family.parents, /*includeNilOption*/ true),
  noNilOption: parentOptionsList(props.family.parents, /*includeNilOption*/ false)
})
const parentOrdinals = ref({
  parent1: props.family.parent1ID,
  parent2: props.family.parent2ID
})

const updateParents = async () => {
  try {
    await ilapi.setParentOrdinals(axiosInstance, props.family.familyID, parentOrdinals.value.parent1, parentOrdinals.value.parent2)
    emit('parentOrdinalsUpdated')
  } catch(error) {
    AxiosErrorWrapper.rethrowIfNotAxiosError(error)
  }
}

function parentOptionsList(parentListing: User[], includeNilOption: boolean) : FormKitOptionsList {
  const result : FormKitOptionsList = [];
  if (includeNilOption) {
    result.push({value: null, label: "--none--"})
  }
  for (const user of parentListing) {
    result.push({
      value: user.ID,
      label: `${user.firstName} ${user.lastName}`
    })
  }
  return result;
}
</script>
