import { LocationQuery } from "vue-router";

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { queryGuid, queryIntegerlike } from "src/boot/TypeBoxSetup"
import { parseIntOr } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";
import authService from "src/helpers/authService";

// "has some route permission" rules are the same between builder route and listing route
import { hasSomeRoutePermission as listing_hasSomeRoutePermission } from "./R_BracketListing.route"

export const RouteName = "bracket-builder"

export function mungeQueryParams(q: LocationQuery) {
  const shape = t.Object({
    competitionUID: t.Optional(queryGuid()),
    seasonUID: t.Optional(queryGuid()),
    divID: t.Optional(queryGuid()),
    bracketID: t.Optional(queryIntegerlike())
  })

  if (Value.Check(shape, q)) {
    const {competitionUID, seasonUID, divID, bracketID} = q;
    return {
      competitionUID,
      seasonUID,
      divID,
      bracketID: parseIntOr(bracketID, undefined),
    }
  }
  else {
    return undefined
  }
}

export type QueryParams = Partial<ReturnType<typeof mungeQueryParams>>

export function hasSomeRoutePermission() : boolean {
  return listing_hasSomeRoutePermission()
}

export function hasRoutePermission(args: {competitionUID: Guid}) : boolean {
  const isSuperUser = authService(User.userData?.roles ?? [], "webmaster", "gameScheduler")
  const isSuperCompAdmin = !!User.userData?.competitionsMemento.find(v => v.competitionUID === args.competitionUID && v.isSuperAdmin)
  return isSuperUser || isSuperCompAdmin
}
