import { FormKit, FormKitMessages } from "@formkit/vue"
import { AxiosInstance } from "axios"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { axiosInstance, axiosAuthBackgroundInstance } from "src/boot/AxiosInstances"
import { vReqT, FK_nodeRef, UiOption, flowCapture, useIziToast, arrayFindOrFail, exhaustiveCaseGuard } from "src/helpers/utils"
import { Client } from "src/store/Client"
import { defineComponent, PropType, ref, reactive, Ref, watch, onMounted } from "vue"
import { SoccerBall } from "../SVGs"
import { DefaultModalController, AutoModal, DefaultTinySoccerballBusyOverlay, DefaultModalController_r } from "../UserInterface/Modal"

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faCircle, faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX, type RefMutablesFormData, TextWithMaxLen, RefMutablesForm, highestArOptions, highestCrOptions, STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX, getRefBadgeInfo } from "./TournamentTeamConfigurator.shared"

import { reset } from "@formkit/core"
import { Public } from "src/store/Public"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { TournamentTeam, TournamentTeamOfficial } from "src/composables/InleagueApiV1.Tournament"
import { RegionDef } from "src/composables/InleagueApiV1.Public"
import { Btn2, btn2_redEnabledClasses } from "../UserInterface/Btn2"

export type CandidateSearchResult =
  | "no-search-yet"
  | "nothing-found"
  | iltournament.TournamentTeamOfficialCandidate

export const RefereeRosterImpl = defineComponent({
  props: {
    shouldDisplayAddRef: vReqT<boolean>(),
    tournTeamOrUndefinedIfUnaffiliatedOrMultiple: vReqT<TournamentTeam | undefined>(),
    mostRecentCandidateSearchResult: vReqT<CandidateSearchResult>(),
    mut_existingRefs: {
      required: true,
      type: Array as PropType<readonly iltournament.TournamentTeamOfficial[]>
    },
    canDelete: vReqT<boolean>(),
    doCandidacyLookupByStackSid: vReqT<(axios: AxiosInstance, _: {stackSID: string, lastName: string}) => Promise<void>>(),
    doAddRef: vReqT<(
      axios: AxiosInstance,
      stackInfo: {stackSID: string, lastName: string},
      form: RefMutablesFormData,
      selectedUserIdIfMatchingInleagueUser: undefined | iltypes.Guid,
      selectedRegionIfWillBeGeneratingUser: undefined | iltypes.Integerlike
    ) => Promise<{ok: boolean}>>(),
    doRemoveRef: vReqT<(axios: AxiosInstance, _: iltournament.TournamentTeamOfficial) => Promise<void>>(),
    doUpdateRef: vReqT<(axios: AxiosInstance, target: iltournament.TournamentTeamOfficial, form: RefMutablesFormData) => Promise<{ok: boolean}>>(),
  },
  emits: {
  },
  setup(props) {
    const iziToast = useIziToast();
    const stackLookupForm = ref({stackSID: "", lastName: ""})
    const fkFormID_stackSidLookup = "FK-RefereeRosterImpl-stackSidLookup"

    const doCandidacyLookupByLookupForm = async () : Promise<void> => {
      try {
        await props.doCandidacyLookupByStackSid(axiosInstance, stackLookupForm.value)
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddRefByCurrentCandidate = async (
      addRefForm: RefMutablesFormData,
      selectedUserIdIfMatchingInleagueUser: undefined | Guid,
      selectedRegionIfWillBeGeneratingUser: undefined | Integerlike,
    ) : Promise<void> => {
      if (typeof props.mostRecentCandidateSearchResult !== "object") {
        throw Error("illegal state")
      }

      const maybeOK = await props.doAddRef(
        axiosInstance,
        props.mostRecentCandidateSearchResult,
        addRefForm,
        selectedUserIdIfMatchingInleagueUser,
        selectedRegionIfWillBeGeneratingUser
      )

      if (maybeOK.ok) {
        stackLookupForm.value = {stackSID: "", lastName: ""}
        reset(fkFormID_stackSidLookup)
        iziToast.success({message: "Referee added."})
      }
      else {
        // nothing
      }
    }

    const deleteRefConfirmationModalController = reactive((() => {
      const busy = ref(false);

      const doDelete = async (official: iltournament.TournamentTeamOfficial) => {
        try {
          try {
            busy.value = true;
            await props.doRemoveRef(axiosAuthBackgroundInstance, official)
          }
          finally {
            busy.value = false;
          }
          deleteRefConfirmationModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      };

      const doCancel = () => deleteRefConfirmationModalController.close()

      // don't allow the modal to be closed while we're busy
      const onCloseCB = (doClose : () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          doClose();
        }
      }

      return DefaultModalController<iltournament.TournamentTeamOfficial>({
        title: () => (
          <>
            <div>Remove referee confirmation</div>
            <div class="my-1 border-b border-slate-200"/>
          </>
        ),
        content: official => {
          if (!official) {
            return null;
          }
          return (
            <>
              <div>Remove {official.firstName} {official.lastName}?</div>
              <MaybeTeamDesignation class="text-sm" official={official}/>
              <div class="flex gap-4 mt-4">
                <t-btn data-test="yes" type="button" onClick={() => doDelete(official)}>Yes</t-btn>
                <t-btn data-test="no" type="button" onClick={() => doCancel()} color="red">No</t-btn>
              </div>
              {
                busy.value
                  ? (
                    <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
                      <div class="w-full h-full" style="background-color: rgba(255,255,255,0.5)">&nbsp;</div>
                      <div style="position: absolute; top: 0; left: 0; margin-left:4px; margin-top:4px">
                        <SoccerBall color={Client.value.clientTheme.color} width=".2in" height=".2in" timeForOneRotation="1.25s"/>
                      </div>
                    </div>
                  )
                  : null
              }
            </>
          )
        }
      }, {onCloseCB})
    })())

    const editRefModalController = (() => {
      const busy = ref(false);

      // initialized when modal opens
      const form = ref<null | RefMutablesFormData>(null)

      const onOpenCB = (obj: iltournament.TournamentTeamOfficial) => {
        form.value = {
          comments: TextWithMaxLen(obj.comments, STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX),
          maxAR: obj.ref_maxAR,
          maxCR: obj.ref_maxCR,
          ref_badgeLevel: TextWithMaxLen(obj.ref_badgeLevel, STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX)
        }
      }

      const doUpdate = async (official: iltournament.TournamentTeamOfficial, form: RefMutablesFormData) => {
        let ok = false;

        try {
          busy.value = true;
          ok = (await props.doUpdateRef(axiosAuthBackgroundInstance, official, form)).ok
        }
        finally {
          busy.value = false;
        }

        if (ok) {
          editRefModalController.close()
        }
      };

      const doCancel = () => editRefModalController.close()

      // don't allow the modal to be closed while we're busy
      const onCloseCB = (doClose : () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          doClose();
          // would be nice to do this but, but we need to do it in the "onCloseComplete" handler after the close animation is done (do we have a hook for that?)
          // form.value = null;
        }
      }

      return DefaultModalController_r<iltournament.TournamentTeamOfficial>({
        title: () => (
          <>
            <div>Edit ref details</div>
            <div class="my-1 border-b border-slate-200"/>
          </>
        ),
        content: official => {
          if (!official) {
            return null;
          }
          if (form.value === null) {
            throw Error("form was not initialized?");
          }
          const definiteForm = form.value;
          return (
            <>
              <div class="mb-2">
                <div>{official.firstName} {official.lastName}</div>
                <MaybeTeamDesignation class="text-sm" official={official}/>
              </div>
              <RefMutablesForm
                highestCrOptions={highestCrOptions}
                highestArOptions={highestArOptions}
                refForm={definiteForm}
              />
              <div class="flex gap-4">
                <Btn2 class="px-2 py-1" data-test="yes" type="button" onClick={() => doUpdate(official, definiteForm)}>Save</Btn2>
                <Btn2 class="px-2 py-1" data-test="no" type="button" onClick={() => doCancel()} enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
              </div>
              {
                busy.value
                  ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                  : null
              }
            </>
          )
        }
      }, {onCloseCB, onOpenCB})
    })();

    const ExistingRefListing = () : JSX.Element => {
      return (
        <div>
          <div class="font-medium">Current referees</div>
          {
            props.mut_existingRefs.length === 0
              ? <div class="flex flex-col items-center justify-center p-2 text-sm">
                  <div>No referees have been submitted with this team registration.</div>
                  {
                    props.shouldDisplayAddRef
                      ? <div>Attach referees to this team by entering their AYSOID and last name above.</div>
                      : null
                  }
                </div>
              : null
          }
          {props.canDelete
            ? null
            : <>
              <div class="text-sm">Referees cannot be deleted at this time.</div>
              <div class="border-b my-1"/>
            </>
          }
          <div>
            {
              props.mut_existingRefs.map((ref, i, a) => {
                const isLast = i === a.length - 1;
                const refBadgeInfo = getRefBadgeInfo({
                  ref_badgeLevel_fromUserRecord: ref.ref_badgeLevel_fromUserRecord,
                  ref_badgeLevel_fromTournTeamOfficalRecord: ref.ref_badgeLevel
                })
                return (
                  <>
                    <div class="my-2 flex" data-test={`stackSID=${ref.stackSID}`}>
                      <div>
                        <div>{ref.firstName} {ref.lastName}</div>
                        <MaybeTeamDesignation official={ref}/>
                        <div>AYSO ID: {ref.stackSID}</div>
                        <div class="text-xs">Has inLeague account: {ref.userID ? (ref.user_unclaimed ? "Yes (invite sent, but unclaimed)" : "Yes") : "No"}</div>
                        <div class="text-xs">{ref.tournamentID ? "User is linked via tournament rather than particular team" : ""}</div>
                      </div>
                      <div class="ml-auto">
                        <div>
                          <button
                            type="button"
                            data-test="edit"
                            class={[`p-1 rounded-md cursor-pointer`, "hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)]"]}
                            onClick={() => editRefModalController.open(ref)}
                          >
                            <FontAwesomeIcon icon={faPencil}/>
                            <span class="ml-2">Edit</span>
                          </button>
                          <button
                            type="button"
                            data-test="remove"
                            disabled={!props.canDelete}
                            class={[`p-1 rounded-md cursor-pointer`, props.canDelete ? "hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)]" : "invisible"]}
                            onClick={() => deleteRefConfirmationModalController.open(ref)}
                          >
                            <FontAwesomeIcon icon={faTrash}/>
                            <span class="ml-2">Remove</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <table class="border-collapse">
                      <tr>
                        <th class="text-left pr-2 align-top">Max&nbsp;CR</th>
                        <th class="text-left px-2 align-top">Max&nbsp;AR</th>
                        <th class="text-left px-2 align-top">Badge</th>
                        <th class="text-left px-2 align-top">Comments</th>
                      </tr>
                      <tr>
                        <td class="pr-2 align-top">{ref.ref_maxCR === "" ? "N/A" : ref.ref_maxCR}</td>
                        <td class="px-2 align-top">{ref.ref_maxAR === "" ? "N/A" : ref.ref_maxAR}</td>
                        <td class="px-2 align-top whitespace-break-spaces">
                          <div>{refBadgeInfo.value}</div>
                          <div class="text-xs">
                            {refBadgeInfo.source === "stack" ? "Source: Stack"
                              : refBadgeInfo.source === "coach" ? "Source: Team"
                              : refBadgeInfo.source === "not-available" ? "<no value>"
                              : exhaustiveCaseGuard(refBadgeInfo)}
                          </div>
                        </td>
                        <td class="px-2 align-top">
                          <div>{ref.comments.trim() === "" ? "N/A" : ref.comments}</div>
                        </td>
                      </tr>
                    </table>
                    {
                      isLast
                        ? null
                        : <div class="border-b border-slate-200 my-2"/>
                    }
                  </>
                )
              })
            }
          </div>
        </div>
      )
    }

    const aysoIDLookupFormNodeRef = FK_nodeRef();

    return () => (
      <div data-test="RefereeRoster">
        <AutoModal data-test="ConfirmDeleteModal" controller={deleteRefConfirmationModalController}/>
        <AutoModal data-test="EditRefModal" controller={editRefModalController}/>
        {
          props.shouldDisplayAddRef
            ? (
              <div>
                <FormKit id={fkFormID_stackSidLookup} type="form" actions={false} onSubmit={() => doCandidacyLookupByLookupForm()} ref={aysoIDLookupFormNodeRef}>
                  <FormKit type="text" v-model={stackLookupForm.value.stackSID} label="AYSOID" validation={[["required"]]} data-test="stackSID"/>
                  <FormKit type="text" v-model={stackLookupForm.value.lastName} label="Last name" validation={[["required"]]} data-test="lastName"/>
                  <Btn2 class="px-2 py-1" data-test="submit" type="submit">Find referee by AYSO ID</Btn2>
                  <FormKitMessages class="hidden" node={aysoIDLookupFormNodeRef.value?.node}/>
                </FormKit>
                <div data-test="lookupResults">
                  {
                    props.mostRecentCandidateSearchResult === "no-search-yet"
                      ? null
                      : props.mostRecentCandidateSearchResult === "nothing-found"
                      ? (
                        <>
                          <div class="border-b border-slate-200 my-2"/>
                          <div>No results.</div>
                        </>
                      )
                      : (
                        <>
                          <div class="border-b border-slate-200 my-2"/>
                          <CandidateSearchResult
                            tournTeamOrUndefinedIfUnaffiliatedOrMultiple={props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple}
                            candidateSearchResult={props.mostRecentCandidateSearchResult}
                            doAddRefByCurrentCandidate={doAddRefByCurrentCandidate}
                            highestCrOptions={highestCrOptions}
                            highestArOptions={highestArOptions}
                          />
                        </>
                      )
                  }
                </div>
                <div class="border-b-2 border-gray-200 my-2"></div>
              </div>
            )
            : null
        }
        <ExistingRefListing/>
      </div>
    )
  }
})

const CandidateSearchResult = defineComponent({
  props: {
    tournTeamOrUndefinedIfUnaffiliatedOrMultiple: vReqT<TournamentTeam | undefined>(),
    teamRegion: vReqT<Integerlike | undefined>,
    candidateSearchResult: vReqT<iltournament.TournamentTeamOfficialCandidate>(),
    doAddRefByCurrentCandidate: vReqT<(addRefForm: RefMutablesFormData, selectedUserIdIfMatchingInleagueUser: undefined | iltypes.Guid, selectedRegionIfWillBeGeneratingUser: undefined | Integerlike) => Promise<void>>(),
    highestCrOptions: vReqT<UiOption[]>(),
    highestArOptions: vReqT<UiOption[]>(),
  },
  setup(props) {
    const addRefForm = ref<RefMutablesFormData>({
      comments: TextWithMaxLen("", STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX),
      maxAR: "",
      maxCR: "",
      ref_badgeLevel: TextWithMaxLen("", STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX)
    })

    const selectedUserIdIfMatchingInleagueUsersExisted = ref<"" | Guid>("")
    const selectedRegionIfWillBeGeneratingUser = ref<"" | Integerlike>("")

    const regions = ref<{ready: true, regions: RegionDef[]} | {ready: false}>({ready: false})

    onMounted(async () => {
      regions.value = {
        ready: true,
        regions: await Public.getMasterRegionList()
      }
    })

    const doSubmit = async () => {
      const userID = selectedUserIdIfMatchingInleagueUsersExisted.value || undefined;
      const region = selectedRegionIfWillBeGeneratingUser.value || undefined;
      props.doAddRefByCurrentCandidate(addRefForm.value, userID, region);
    }

    return () => {
      if (!regions.value.ready) {
        return null;
      }

      if (!props.candidateSearchResult.candidacy.isCandidate) {
        return <div class="text-sm p-2 text-red-600" data-test="refereeRosterError">{props.candidateSearchResult.candidacy.error}</div>
      }
      return (
        <>
          {
            props.candidateSearchResult.candidacy.warning
              ? (
                <div data-test="refereeRosterWarning">
                  <div>Warning</div>
                  <div class="text-sm">{props.candidateSearchResult.candidacy.warning}</div>
                  <div class="text-sm">Your permissions allow you to override this warning</div>
                </div>
              )
              : null
          }
          <div>Referee name: {props.candidateSearchResult.firstName} {props.candidateSearchResult.lastName} ({props.candidateSearchResult.stackSID})</div>
          <FormKit type="form" onSubmit={() => doSubmit()} actions={false}>
            <CandidateUserSelector
              tournTeamOrUndefinedIfUnaffiliatedOrMultiple={props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple}
              regions={regions.value.regions}
              candidateSearchResult={props.candidateSearchResult}
              formData={{selectedUserIdIfMatchingInleagueUsersExisted, selectedRegionIfWillBeGeneratingUser}}
            />
            <RefMutablesForm highestCrOptions={props.highestCrOptions} highestArOptions={props.highestArOptions} refForm={addRefForm.value}/>
            <div class="flex">
              <div class="ml-auto">
                <t-btn type="submit" data-test="commitToRoster">Submit</t-btn>
              </div>
            </div>
          </FormKit>
        </>
      )
    }

  }

})

const CandidateUserSelector = defineComponent({
  props: {
    tournTeamOrUndefinedIfUnaffiliatedOrMultiple: vReqT<TournamentTeam | undefined>(),
    regions: vReqT<RegionDef[]>(),
    candidateSearchResult: vReqT<iltournament.TournamentTeamOfficialCandidate>(),
    // would be nice to make this formdata a smarter variant type
    formData: vReqT<{
      selectedRegionIfWillBeGeneratingUser: Ref<"" | Integerlike>,
      selectedUserIdIfMatchingInleagueUsersExisted: Ref<"" | Guid>,
    }>(),
  },
  setup(props) {
    const regionOptions = ref<UiOption[]>([])

    const initGeneratingUserState = () : void => {
      props.formData.selectedRegionIfWillBeGeneratingUser.value = "";
      props.formData.selectedUserIdIfMatchingInleagueUsersExisted.value = "";

      if (props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple === undefined) {
        // unaffilliated team selection -- user MUST choose the generated user's region
        props.formData.selectedRegionIfWillBeGeneratingUser.value = "";
        regionOptions.value = Public.buildStandardRegionOptions(props.regions, {includeNilOption: true});
      }
      else {
        // some affiliated team selection -- the user can choose the generated user's region, but it is defaulted to "the tourn team's" region
        const region = flowCapture(props.tournTeamOrUndefinedIfUnaffiliatedOrMultiple.region)
        props.formData.selectedRegionIfWillBeGeneratingUser.value = region;
        const options = Public.buildStandardRegionOptions(props.regions, {includeNilOption: false});
        arrayFindOrFail(options, v => v.value === region.toString()).label += " (tournament team home region)";
        regionOptions.value = options;
      }
    }

    const initMatchingUserState = () => {
      props.formData.selectedRegionIfWillBeGeneratingUser.value = "";
      props.formData.selectedUserIdIfMatchingInleagueUsersExisted.value = "";
      // no other work to do
    }

    const initLocalState = () => {
      if (!props.candidateSearchResult.matchingInLeagueUsers) {
        initGeneratingUserState();
      }
      else {
        initMatchingUserState();
      }
    }
    watch(() => props.candidateSearchResult, () => initLocalState(), {immediate: true});

    return () => {
      if (!props.candidateSearchResult.matchingInLeagueUsers) {
        return (
          <div>
            <div class="mt-2">This doesn't match any existing inLeague user. When you add them, the email address on the AYSO record will be used to create a placeholder user account and invite them to claim it.</div>
            <>
              <div>{props.formData.selectedRegionIfWillBeGeneratingUser.value}</div>
              <RequiredLabel class="text-sm font-medium my-1" isSatisfied={props.formData.selectedRegionIfWillBeGeneratingUser.value !== ""}>
                <span>User's home region:</span>
              </RequiredLabel>
              <FormKit type="select" name="Region"
                options={regionOptions.value}
                v-model={props.formData.selectedRegionIfWillBeGeneratingUser.value} validation={[["required"]]} data-test="region"
              />
            </>
          </div>
        );
      }
      if (props.candidateSearchResult.matchingInLeagueUsers.length === 0) {
        throw Error("if present, this list must be non-empty") // backend bug?
      }
      else if (props.candidateSearchResult.matchingInLeagueUsers.length === 1) {
        const match = props.candidateSearchResult.matchingInLeagueUsers[0]
        props.formData.selectedUserIdIfMatchingInleagueUsersExisted.value = match.userID
        return (
          <div>
            <span>This matches inLeague user {match.firstName} {match.lastName} ({match.email})</span>
          </div>
        )
      }
      else {
        const options = props.candidateSearchResult.matchingInLeagueUsers.map(v => ({label: `${v.firstName} ${v.lastName} (${v.email})`, value: v.userID}))
        return (
          <div style="--fk-border:none;">
            <div>This matched multiple inLeague users, please choose one:</div>
            <FormKit type="radio" name="Matching inLeague user" v-model={props.formData.selectedUserIdIfMatchingInleagueUsersExisted.value} options={options} validation={[["required"]]}/>
          </div>
        )
      }
    }
  }
});

const RequiredLabel = defineComponent({
  props: {
    isSatisfied: vReqT<boolean>(),
  },
  setup(props, {slots}) {
    return () => (
      <div>
        <div class="flex items-center">
          <span class={`mr-2 ${props.isSatisfied ? "text-green-500" : "text-yellow-500"}`} style="font-size:.5em;">
            <FontAwesomeIcon icon={faCircle}/>
          </span>
          {slots.default?.()}
        </div>
      </div>
    )
  }
})

const MaybeTeamDesignation = defineComponent({
  props: {
    official: vReqT<TournamentTeamOfficial>(),
  },
  setup(props) {
    return () => {
      if (props.official.teamDesignationComponents) {
        // We're abusing existence of this property to mean "caller probably wants to display it"
        // but it should be probably some configurable prop (e.g. __if__ we have it, should we show it?)
        return <div>
          {props.official.teamDesignationComponents.divGender}
          {props.official.teamDesignationComponents.divNum}-
          {props.official.teamDesignationComponents.teamRegion}-
          {props.official.teamDesignationComponents.teamNumber}
        </div>
      }
      else {
        return null;
      }
    }
  }
})
