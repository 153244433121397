<template lang="pug">
template(v-if="ready")
  h2 Volunteer registration is unavailable between {{ unavailability.from }} &ndash; {{ unavailability.to }} due to AYSO scheduled maintenance.
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import * as MaintenanceNavigationGuard from "src/router/MaintenanceNavigationGuard"
import dayjs from "dayjs"

export default defineComponent({
  props: {},
  setup(_props, _context) {
    const ready = ref(false);
    const unavailability = ref<{from: string, to: string}>(/*definitely assigned in onMounted*/ null as any);

    onMounted(async () => {
      const listing = await MaintenanceNavigationGuard.getVolunteerRegistrationUnavailabilityListing();

      const from = dayjs(listing.from);
      const to = dayjs(listing.to);

      unavailability.value = {
        from: from.format("h:mma"),
        to: to.format("h:mma"),
      }

      ready.value = true;
    });

    return {
      ready,
      unavailability
    };
  },
})
</script>
