<template lang="pug">
div
  label.font-medium.t-label.block.mb-2.mt-5(v-if='label') {{ label }}
  .font-medium.t-label.block.mb-2.mt-5.text-red(v-if='subLabel') {{ subLabel }}
  select.mt-1.block.w-full.pl-3.pr-10.py-2.text-base.rounded-md(
    class="focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
    :class='hasValidationErrors ? "border-red-500" : ""',
    :name='name',
    :id='name',
    :disabled='disabled',
    :required='required',
    v-model='selectValue'
    ref='selectDisplay'
  )
    option(hidden disabled, selected, value) {{ placeholder }}
    option(
      v-if='Array.isArray(options)',
      v-for='(option, i) in options',
      :key='option.value',
      :value='option.value'
    ) {{ option.label }}
    option(
      v-else,
      v-for='(option, optionID) in options',
      :key='optionID',
      :value='optionID'
    ) {{ option, optionID }}
  div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>


<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
export default defineComponent({
  name: 'TSelect',
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '-- select an option --'
    },
    subLabel: {
      type: String,
      required: false
    },
    autoComplete: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const selectDisplay = ref(null) as unknown as HTMLSelectElement

    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })

    const selectValue = computed({
      get() {
        return props.modelValue
      },
      set(newValue) {
        emit('update:modelValue', newValue)
      }
    })

    watch(() => props.options, (newVal:( Array<any> | Record<string, any>)) => {
      // console.log('in watch', props.label, newVal)
      if(Array.isArray(newVal) && newVal.length === 1) {
        // console.log('in array if')
        emit('update:modelValue', newVal[0])
      } else if(Object.keys(newVal as Record<string, any>).length===1) {
        // console.log('object', newVal)
        // console.log('key', Object.keys(newVal as Record<string, any>)[0])
        // console.log('what is returned', (newVal as Record<string, any>)[Object.keys(newVal as Record<string, any>)[0]])
        emit('update:modelValue', Object.keys(newVal as Record<string, any>)[0])
      }
    }, {immediate: true})

    return {
      hasValidationErrors,
      selectValue,
      selectDisplay,
    }
  },
})
</script>
