<template lang="pug">
//-fixme: `hasDuplicate` is being used (also as, or exactly as?) "show duplicate child error modal"
DuplicatePlayer(
  v-if='hasDuplicate',
  :playerName='`${form.playerFirstName} ${form.playerLastName}`',
  @closePopup='() => { addPlayer(true); /*close modal*/ hasDuplicate = false; }',
  @register='toRegistration'
)
AdditionalPlayer(
  v-if='addMorePlayers',
  @closePopup='clearForm',
  @register='toRegistration'
)
MultipleFamilies(
  v-if='multipleFamilies',
  :families='familyOptions',
  @familyIDSelected='setFamilyID',
  selectionReason='addPlayer'
)

.t-page.flex.flex-col(class='md:mx-2')
  h1.ml-2.text-2xl.font-medium(class='md:text-4xl')
    font-awesome-icon.mr-2(:icon='["fas", "child"]')
    | Add Player

div(v-show='formState === FormState.edit' data-test="AddPlayer-form-container")
  ContentChunkDisplay(
    class="mb-6"
    id="addPlayerInstructions",
    :interpolationArgs="{leagueName:leagueName}"
  )

  FormKit(
    type='form',
    @submit='toggleEdit',
    submit-label='Add Player',
    v-model='form'
  )
    FormKitSchema(:schema='schema', :data='form')
div(v-show="formState === FormState.confirm" data-test="AddPlayer-confirm-container")
  h2.my-3.italic Please Confirm Your Player's Information:
  .flex.flex-col.max-w-md
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            tbody.bg-white.divide-y.divide-gray-200
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | First Name:
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{ form.playerFirstName }}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | Last Name:
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{ form.playerLastName }}
              tr(v-if='form.playerNickName')
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | Nickname:
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{ form.playerNickName }}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900
                  | Gender:
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  | {{ form.playerGender === "B" ? "Male" : "Female" }}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-bold.text-gray-900
                  | Birthdate:
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.font-bold
                  | {{ dayjs(form.playerBirthDate).format("MMMM D, YYYY") }}
              tr
                td.px-6.py-4.whitespace-nowrap.text-sm.font-bold.text-gray-900 Family Members
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500
                  div(v-for='(relationship, userID) in form.userRelationships') {{ getName(userID) }}: {{ relationshipTypes[relationship] }}

    .flex.mt-2.max-w-md.justify-between
      TBtn(@click='toggleEdit', color='red', width='w-24', label='Edit' data-test="edit")
      TBtn(
        type='submit',
        width='w-24',
        @click='addPlayer(false)',
        label='Submit'
        data-test="really-submit"
      )
</template>

<script lang="ts">
import DuplicatePlayer from 'src/components/Registration/NewPlayer/DuplicatePlayer.vue'
import AdditionalPlayer from 'src/components/Registration/NewPlayer/AdditionalPlayer.vue'
import MultipleFamilies from 'src/components/Registration/NewPlayer/multipleFamilies.vue'
import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { formatDateWithoutTime } from 'src/helpers/formatDate'
import { defineComponent, ref, onMounted, Ref, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { AxiosErrorWrapper, axiosInstance, defaultLoggedInErrorResponseHandler, defaultSetGlobalSingletonLoadingStateFlagInterceptors, freshAxiosInstance } from 'src/boot/axios'
import { NewPlayerForm } from 'src/interfaces/newPlayer'
import { UserData } from 'src/interfaces/Store/user'
import { date } from 'quasar'
import { createSelect } from 'src/helpers/schemaService'
import relatedUserFunctions, { type PartialRelatedUser } from 'src/composables/relatedUsers'
import { EventAugmentedUser } from 'src/interfaces/Store/events' // fixme: we're using Event* entities here?
import {FormKitSchemaNode} from '@formkit/core'
import { assertNonNull, exhaustiveCaseGuard, useIziToast } from 'src/helpers/utils'
import dayjs from "dayjs"
import { AxiosError } from 'axios'

import { Guid, RelatedUser } from 'src/interfaces/InleagueApiV1'
import * as ilapi from "src/composables/InleagueApiV1"

import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'Add Player',
  components: {
    ContentChunkDisplay,
    DuplicatePlayer,
    AdditionalPlayer,
    MultipleFamilies,
  },
  setup() {
    /**
     * This is written into by FormKit; if the FormKit DOM node is unmounted, FormKit will set this to undefined
     */
    const form = ref({}) as Ref<NewPlayerForm>
    const iziToast = useIziToast();
    const leagueName = computed(() => Client.value.instanceConfig.shortname);

    // multiple family properties
    const multipleFamilies = ref(false)
    const familyID = ref('')
    const familyOptions = ref({})

    /**
     * Listing of relationship type ids -> ui names
     * Suitable for use as options for a <select>
     */
    const relationshipTypes = ref<{[k: /* relationship type id */ number]: /* relationship name */ string}>({})

    const processedUsers = ref({})
    const users = ref<Omit<RelatedUser, "relationshipTypename">[]>(
      [] as {
        userID: string
        firstName: string
        lastName: string
        relationship_type_id: number
      }[]
    )

    // Display Toggles
    const addMorePlayers = ref(false)
    const hasDuplicate = ref(false)

    const router = useRouter()
    const route = useRoute()


    const { getRelationshipTypes, processRelatedUsers } = relatedUserFunctions()

    const schema = ref<FormKitSchemaNode[]>([
      {
        $formkit: 'text',
        name: 'playerFirstName',
        label: 'First Name*',
        validation: 'required|length:2,50',
        autocomplete: 'no',
        refs: 'firstName',
      },
      {
        $formkit: 'text',
        name: 'playerLastName',
        label: 'Last Name*',
        validation: 'required|length:2,50',
        autocomplete: 'no',
        refs: 'lastName',
      },
      {
        $formkit: 'text',
        name: 'playerNickName',
        label: 'Nickname',
        autocomplete: 'no',
        refs: 'nickname',
      },
      {
        $formkit: 'radio',
        name: 'playerGender',
        label: 'Gender*',
        validation: 'required',
        options: { B: 'Male', G: 'Female' },
      },
      {
        $formkit: 'date',
        name: 'playerBirthDate',
        label: 'Birth Date*',
        validation: 'required|date_format:YYYY-MM-DD',
      },
      {
        $formkit: 'date',
        name: 'playerBirthDate_confirm',
        label: 'Confirm Birth Date*',
        validation: 'required|date_format:YYYY-MM-DD|confirm:playerBirthDate',
      },
    ])

    /**
     * Converts the {[userID]: integer-like} map pulled out of the form into the shape the API requires
     * The map contains "integer-like" values, which will be strings from the form, with "undefined"/missing/empty values generally as empty-string
     * If a value doesn't parse as an integer, we discard it on assumption that it represents "no value" (probably an empty <select> in the form)
     */
    const formatRelationshipsForApiSubmit = (relationships: { [userID: Guid]: /* relationship typeID */ string }) => {
      const formattedRelationships: {
        userID: string
        relationship_type_id: number
      }[] = []
      for (const userID in relationships) {
        const relationship_type_id = parseInt(relationships[userID]);

        if (isNaN(relationship_type_id)) {
          continue;
        }

        formattedRelationships.push({
          userID,
          relationship_type_id
        })
      }

      return formattedRelationships
    }

    const getName = (userID: string) => {
      for (let i = 0; i < users.value.length; i++) {
        if (users.value[i].userID === userID) {
          return `${users.value[i].firstName} ${users.value[i].lastName}`
        }
      }
      return ''
    }



    const addPlayer = async (duplicateOverride = false) => {
      const createNewPlayerRequestArgs = {
        ...form.value,
        familyID: familyID.value,
        relatedUsers: formatRelationshipsForApiSubmit(
          // should this ever be undefined? Note that it is optional as per its interface def.
          form.value.userRelationships ?? {}
        ),
      }

      delete createNewPlayerRequestArgs.userRelationships
      delete createNewPlayerRequestArgs.playerBirthDate_confirm

      if (duplicateOverride) {
        createNewPlayerRequestArgs.duplicateOverride = true
      }

      // we can't use any existing axios instance because they are all hardwired
      // to show toasts on errors (i.e. any response that is not 2xx).
      // But we can get a 409 here, for attempts to create players with duplicate names; and
      // we handle that locally, without the use of toasts. We don't want to show a modal and an error toast,
      // just the modal.
      const controlledToastAxios = freshAxiosInstance({
        useCurrentBearerToken: true,
        requestInterceptors: [defaultSetGlobalSingletonLoadingStateFlagInterceptors.request],
        responseInterceptors: [
          {
            ok: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseOK,
            error: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseError,
          },
          {error: async error_ => {
            const error : AxiosError = error_ instanceof AxiosErrorWrapper ? error_.unwrap() : error_;
            if (error.response?.status === 409) {
              // do nothing other than reject with a "standard" axios error wrapper.
              throw new AxiosErrorWrapper(error);
            }
            else {
              // otherwise we can call the "default" maybe-toast-generating handler
              return await defaultLoggedInErrorResponseHandler(iziToast)(error);
            }
          }}
        ]
      });

      try {
        const result = await ilapi.createNewPlayer(controlledToastAxios, createNewPlayerRequestArgs);
        switch (result.type) {
          case "created": {
            await User.directCommit_setChildren(result.value)
            addMorePlayers.value = true
            break;
          }
          case "duplicate-warning": {
            hasDuplicate.value = true;
            break;
          }
          default: exhaustiveCaseGuard(result);
        }
      } catch (err: any) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const toRegistration = async () => {
      await router.push({ name: 'select-season', params: { role: 'player' } })
    }

    const getFamilies = async () => {
      try {
        const response = await axiosInstance.get('v1/family/users')
        return response.data.data
      } catch (error) {
        // console.log('got families error', error)
      }
    }

    const sortFamilies = (
      families: {
        userID: string
        relationship_type_id: number
        firstName: string
        lastName: string
      }[]
    ) => {
      // console.log(families)
      const sortedFamilies = {} as { [key: string]: EventAugmentedUser[] } // fixme: we're using Event* entities here?

      const addToSortedFamilies = (member: EventAugmentedUser, familyID: string) => {
        // console.log('addToSortedFamilies', member, familyID)
        if (sortedFamilies[familyID]) {
          sortedFamilies[familyID].push(member)
        } else {
          sortedFamilies[familyID] = [member]
        }
      }

      const filterFamilies = (member: any) => {
        // console.log('member', member)
        for (let i = 0; i < member.familyIDs.length; i++) {
          addToSortedFamilies(member, member.familyIDs[i])
        }
      }

      // console.log('before for loop', families.length)
      for (let i = 0; i < families.length; i++) {
        // console.log('filterFamilies', families[i])
        filterFamilies(families[i])
      }

      familyOptions.value = sortedFamilies
    }

    const setFamilyID = (ID: string) => {
      familyID.value = ID
      multipleFamilies.value = false
    }

    enum FormState { edit = 1, confirm }
    const formState = ref(FormState.edit)

    /**
     * transition between "edit values" and "confirm values" state
     */
    const toggleEdit = () => {
      formState.value = formState.value === FormState.edit
        ? FormState.confirm
        : FormState.edit;
    }

    const clearForm = () => {
      form.value = {} as NewPlayerForm
      addMorePlayers.value = false
      formState.value = FormState.edit
    }

    const addUsersToSchema = () => {
      form.value['userRelationships'] = processedUsers.value
      // console.log('processedUser.value', processedUsers.value)
      const userRelationshipSchema = {
        label: 'User Relationship Assignment',
        $formkit: 'group',
        children: [] as {
          label: string
          $formkit: string
          name: string
          options: { [key: string]: string }
          placeholder: string
        }[],
        name: 'userRelationships',
      }

      for (const userID in processedUsers.value) {
        userRelationshipSchema.children.push({
          label: getName(userID),
          $formkit: 'select',
          name: userID,
          options: relationshipTypes.value,
          placeholder: 'Select User Relationship',
        })
      }

      schema.value.push(
        { $el: 'h2', children: 'Family Member Relationships to This Player' },
        userRelationshipSchema
      )
    }

    const getFamilyMembers = async () => {
      try {
        const response = await axiosInstance.get(
          `v1/family/${familyID.value}?expand=parent1,parent2`
        )
        return response.data.data
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    watch(familyID, async (familyID: string) => {
      if (familyID) {
        const familyDetails = await getFamilyMembers()
        const parentData: PartialRelatedUser[] = []
        if (familyDetails.parent1ID) {
          const parent : PartialRelatedUser = {
            userID: familyDetails.parent1ID,
            relationship_type_id: familyDetails.parent1.gender === 'F' ? 6 : 2,
          }
          parentData.push(parent)
        }

        if (familyDetails.parent2ID) {
          const parent : PartialRelatedUser = {
            userID: familyDetails.parent2ID,
            relationship_type_id: familyDetails.parent2.gender === 'F' ? 6 : 2,
          }
          parentData.push(parent)
        }
        processedUsers.value = processRelatedUsers(
          parentData,
          users.value.map(v => v.userID),
        )
        addUsersToSchema()
      }
    })

    onMounted(async () => {
      relationshipTypes.value = createSelect(
        await getRelationshipTypes(),
        'typeID',
        'typename'
      )
      users.value = (await getFamilies()).users

      if (route.params.familyID) {
        familyID.value = route.params.familyID as string
      } else if ((User.value.userData as UserData).familyIDs.length > 1) {
        sortFamilies(users.value)
        multipleFamilies.value = true
      } else {
        familyID.value = (User.value.userData as UserData).familyIDs[0]
      }
    })

    return {
      leagueName,
      schema,
      form,
      addPlayer,
      toRegistration,
      hasDuplicate,
      toggleEdit,
      formatDateWithoutTime,
      clearForm,
      addMorePlayers,
      familyID,
      multipleFamilies,
      familyOptions,
      setFamilyID,
      date,
      dayjs,
      relationshipTypes,
      processedUsers,
      users,
      getName,
      formState,
      FormState,
    }
  },
})
</script>
