<template lang="pug">
div(v-if="ready")
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.border-b.border-gray-200.w-full(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="4")
                  | Custom Registration Questions
            .grid.grid-cols-3.divide-y.divide-gray-200(v-if="Object.keys(playerDetails).length>0" class='lg:grid-cols-6')
                template(v-for="schemaDef in customQuestionsSchema")
                  div.p-2.text-sm.font-medium.text-gray-900
                    div.flex.justify-end.break-spaces(v-html="schemaDef.label")
                  div.p-2.text-sm.bg-stone-200.col-span-2(v-if="customQuestionEditPermissionMap[schemaDef.underlyingPageItemID]")
                    div
                      FormKit(
                        :type='schemaDef.$formkit',
                        :name="schemaDef.name"
                        v-model='customQuestionsForm[schemaDef.name]',
                        :options='schemaDef.options',
                        :noMargins='true',
                        :data-test="schemaDef['data-test']"
                        fieldset-class="bg-white"
                        wrapper-class="bg-white"
                      )
                  div.p-2.text-sm.text-gray-500.bg-stone-200.col-span-2(v-else :data-test="schemaDef.underlyingPageItemID")
                    | {{ customQuestionsForm[schemaDef.name] }}

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { type RegistrationQuestion } from 'src/interfaces/InleagueApiV1'

import { configure, propsDef, emitsDef } from "./CustomQuestions.ilx"

export default defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const ready = ref(false);

    const customQuestionsForm = ref({})
    const customQuestionsSchema = ref({})
    const customQuestionEditPermissionMap = reactive<Record<RegistrationQuestion["id"], boolean>>({});

    watch(customQuestionsForm, () => {
      emit('updateCustomQuestions', customQuestionsForm.value)
    }, {deep:true})

    onMounted(async ()=> {
      const config = await configure(props);

      customQuestionsSchema.value = config.schema;
      customQuestionsForm.value = config.formData;

      const isChildRelated = config.isChildRelated;

      for (const pageItem of config.questions) {
        const underlyingPageItem = pageItem.pageItem;
        const isEditable = props.granularEditPermissions["customQuestions/*"] || (!props.lockedDueToSomeSeasonCompetitionLockDate && isChildRelated && !!underlyingPageItem.isEditable);
        customQuestionEditPermissionMap[underlyingPageItem.id] = isEditable;
      }

      ready.value = true;
    })

    return {
      ready,
      customQuestionsForm,
      customQuestionsSchema,
      customQuestionEditPermissionMap,
    }
  },
})

</script>
