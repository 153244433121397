<template lang="pug">
div(class='modal-wrap fixed bottom-0 inset-x-0 px-4 pb-6 inset-0 p-0 flex items-center justify-center')
  .fixed.inset-0.transition-opacity
    .absolute.inset-0.bg-gray-500.opacity-75
  //- verbose ternaries in class attrs support tailwind regex scanning of 'dynamic' classes
  div.relative.overflow-y-auto(
    class='max-h-[90vh] bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all  sm:w-full sm:p-6'
    :class="mdMaxWidth === 'lg' ? 'md:max-w-lg' : mdMaxWidth === 'xl' ? 'md:max-w-xl' : mdMaxWidth === '4xl' ? 'md:max-w-4xl' : 'md:max-w-md'"
    role='dialog', aria-modal='true', aria-labelledby='modal-headline'
  )

    div.absolute.p-1.w-full.flex.justify-end(style="top:0;left:0" v-if="closeDialog" @click="emitClose" data-test="t-dialog-close")
      div.x
        div.x-inner
    div
      div(class='mt-3 text-center sm:mt-5')
        h3#modal-headline.text-lg.leading-6.font-medium.text-gray-900
          slot
</template>

<script lang="ts">
import { defineComponent, PropType, ref, SetupContext } from 'vue'
import { emitWrapper } from 'src/helpers/emitWrapper'
export default defineComponent({
  name: 'TDialog',
  props: {
    /**
     * We need to adjust this sometimes, and it would be nice to dynamic css.
     * But doing 'pure' dynamic css doesn't get picked up by tailwind's lexical scanner.
     * This supports a ternary expr in the component where tailwind will
     * statically find the classes we want it to find.
     */
    mdMaxWidth: {
      type: String as PropType<"md" | "lg" | "xl" | "4xl">,
      default: "md"
    },
    val: String,
    close: Boolean
  },
  emits: ['close'],
  setup(props, { emit }) {
    const closeDialog = ref(props.close || false)

    const emitClose = () => {
      // legacy? emit wrapper emits "update:${event-name}"
      emitWrapper(props, emit as SetupContext["emit"], 'close')
      // which the above is sort of weird for consumers, were they'd get "update:close"
      // so we'll also emit an actual close event, too
      emit("close");
    }

    return {
      closeDialog,
      emitClose
    }
  }
})
</script>

<style>
.modal-wrap {
  z-index: 1000;
}
</style>

<style scoped>
  .x {
    position:relative;
    width:1rem;
    height:1rem;
    overflow: visible;
  }
  .x-inner::before {
    position:absolute;
    background-color: black;
    top:0;
    left:50%;
    width:.125rem;
    height:1rem;
    transform: translateX(-50%) rotate(45deg);
    content: 'x';
    color: rgba(0,0,0,0)
  }
  .x-inner::after {
    position:absolute;
    background-color: black;
    top:0;
    left:50%;
    width:.125rem;
    height:1rem;
    transform: translateX(-50%) rotate(-45deg);
    content: 'x';
    color: rgba(0,0,0,0)
  }
  .x:hover {
    background-color: rgba(0,0,0,.125);
    cursor:pointer;
  }
  .x:active {
    background-color: rgba(0,0,0,.25);
    cursor:pointer;
  }
</style>