import { watch, ref, computed, reactive, onUnmounted, Ref } from "vue";
import { Reflike, unsafe_objectKeys } from "src/helpers/utils";
import { EscapedRegExp } from "src/helpers/utils-backend";
import { TournamentTeam } from "src/composables/InleagueApiV1.Tournament";

import { Props as SelectManyProps, OnEmits as SelectManyEmits } from "../RefereeSchedule/SelectManyPane";

export interface TournTeamDisplayFilter {
  name: string,
  status: Record<StatusFilterKey, boolean>
}

enum StatusFilterKey {
  // these 3 "pending*" are intended to be orthogonal
  PENDING = "pending",
  PENDING_HAS_INVOICE = "peding-has-invoice",
  PENDING_HAS_INTENT_TO_PAY_BY_CHECK = "pending-has-intent-to-pay-by-check",
  APPROVED = "approved",
  PAID_AWAITING_APPROVAL = "paid",
  DROPPED = "dropped",
}

export function TournTeamDisplayFilter() : TournTeamDisplayFilter {
  return {
    name: "",
    status: {
      [StatusFilterKey.PENDING]: true,
      [StatusFilterKey.PENDING_HAS_INVOICE]: true,
      [StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK]: true,
      [StatusFilterKey.APPROVED]: true,
      [StatusFilterKey.PAID_AWAITING_APPROVAL]: true,
      [StatusFilterKey.DROPPED]: false,
    }
  }
}

export function computeTournTeamStatusForFiltering(v: TournamentTeam) : StatusFilterKey | null {
  if (v.status === "PENDING" && !v.invoiceInstanceID_registration) {
    return StatusFilterKey.PENDING
  }
  else if (v.status === "PENDING" && v.invoiceInstanceID_registration && !v.hasDeclaredIntentToPayByCheck) {
    return StatusFilterKey.PENDING_HAS_INVOICE
  }
  else if (v.status === "PENDING" && v.invoiceInstanceID_registration && v.hasDeclaredIntentToPayByCheck) {
    return StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK
  }
  else if (v.status === "PAID_AWAITING_APPROVAL") {
    return StatusFilterKey.PAID_AWAITING_APPROVAL
  }
  else if (v.status === "APPROVED") {
    return StatusFilterKey.APPROVED
  }
  else if (v.status === "DROPPED_BY_HOST" || v.status === "DROPPED_BY_SUBMITTER") {
    return StatusFilterKey.DROPPED
  }
  else {
    // should have been exhaustive but can't really be proved with the arbitrary `and`s
    return null;
  }
}

export function FilterManager<T extends TournamentTeam>(filterForm: Reflike<TournTeamDisplayFilter>, tournamentTeams: Reflike<readonly T[]>) {
  const appliedFilters = ref({...filterForm.value});

  watch(() => filterForm.value.name, () => {
    // we intended to support some indirection between the form and the committed filters
    // as in, you can type and then press enter to commit the filter. But we don't currently do that, and just
    // directly apply the forms changes to the applied filters.
    appliedFilters.value = {
      name: filterForm.value.name,
      status: appliedFilters.value.status
    }
  })

  const filteredTournTeams = computed(() => {
    return tournamentTeams
      .value
      .filter(filterName())
      .filter(filterStatus())

    function filterName() {
      const pattern = appliedFilters.value.name.trim() === "" ? null : EscapedRegExp(appliedFilters.value.name.trim(), "i");
      return !pattern
        ? (_: T) => true // no pattern means no filter, everything passes
        : (v: T) => pattern.test(v.areaTeam?.teamname || "") // this is nonsense if areaTeam isn't expanded on the provided tournteams
    }

    function filterStatus() {
      return (v: T) => {
        const status = computeTournTeamStatusForFiltering(v)

        if (status === null) {
          // shouldn't really happen
          return false;
        }

        return appliedFilters.value.status[status]
      }
    }
  })

  const selectManyPropsAndHandlers : SelectManyProps & SelectManyEmits = reactive((() => {
    return {
      offerAllOption: true,
      selectedKeys: computed<string[]>(() => {
        const result : string[] = []
        for (const k of unsafe_objectKeys(appliedFilters.value.status)) {
          if (appliedFilters.value.status[k]) {
            result.push(k)
          }
        }
        return result
      }),
      options: [
          {label: "Incomplete", value: StatusFilterKey.PENDING},
          {label: "Complete but not yet paid", value: StatusFilterKey.PENDING_HAS_INVOICE},
          {label: "Complete but not yet paid (with intent to pay by check)", value: StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK},
          {label: "Paid (awaiting approval)", value: StatusFilterKey.PAID_AWAITING_APPROVAL},
          {label: "Approved", value: StatusFilterKey.APPROVED},
          {label: "Dropped", value: StatusFilterKey.DROPPED}
      ],
      onCheckedAll: isChecked => {
        unsafe_objectKeys(appliedFilters.value.status)
          .forEach(k => appliedFilters.value.status[k] = isChecked)
      },
      onCheckedOne: (key, isChecked) => {
        const uncheckedCast_key = key as StatusFilterKey
        appliedFilters.value.status[uncheckedCast_key] = isChecked
      }
    }
  })());

  return {
    get filteredTournTeams() { return filteredTournTeams.value },
    selectManyPropsAndHandlers,
    get statusOptionsMessage() {
      if (selectManyPropsAndHandlers.selectedKeys.length === 1) {
        return "Status options (1 option selected)"
      }
      else {
        return `Status options (${selectManyPropsAndHandlers.selectedKeys.length} options selected)`
      }
    }
  }
}

/**
 * We have here a unfortunate workaround for overflow-x/overflow-y not playing nice together inside a div with particular overflow configuration
 * Basically we want:
 *  - a div container D with some max width, with overflow-x:auto (scroll when there's overflow)
 *  - a table T inside D that might overflow the container and trigger scrolling
 *  - a menu inside each row of T that is absolute with respect to it's <td> and that:
 *    - is positioned visibily on top of D, overflowing D if necessary (not causing scroll)
 *
 * But setting D to overflow-x:auto seems to force overflow-y:scroll? Or something like that.
 * So as a workaround we observe relevant box dim changes and manually update as necessary. The end result is not quite
 * as pretty as a "purely hovering menu", but it's better than the menu appearing in a position that requires D to be scrolled
 * to make it visible.
 *
 * maybe related: https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
 *
 * @containerRef -- a vue ref to the target container
 * @isExpandedSelector -- a selector that is used to test for "isExpanded", e.g., if document.querySelector(isExpandedSelector) is non-null, we assume the "interactable" is expanded
 */
export function useKludge_expandContainerToFitExpandedInteractable(containerRef: Ref<HTMLElement | undefined>, isExpandedSelector: string) {
  let observer : MutationObserver | null = null;
  onUnmounted(() => observer?.disconnect())
  watch (() => containerRef.value, () => {
    const targetNode = containerRef.value;
    if (!targetNode) {
      return;
    }

    // disconnect the old observer if it exists
    observer?.disconnect();
    observer = null;

    const config : MutationObserverInit = { attributes: true, childList: true, subtree: true };
    let lastKnownHeight : string | null = null

    const callback : MutationCallback = () => {
      if (!containerRef.value) {
        // should be defined by this point
        return;
      }

      const interactableIsExpanded = !!targetNode.querySelector(isExpandedSelector)

      if (interactableIsExpanded) {
        if (lastKnownHeight === containerRef.value.style.height) {
          // break the cycle of infintely triggering mutation observer callbacks on changing the height,
          // where each iteration adds bottom padding
          return;
        }
        if (containerRef.value.scrollHeight === containerRef.value.clientHeight) {
          // no need to adjust it, it's already the appropriate size
          // (is this enough to make the `lastKnownHeight` check redundant?)
          return;
        }

        const scrollHeightPx = containerRef.value.scrollHeight + "px"
        containerRef.value.style.height = `calc(2em + ${scrollHeightPx})`;
        lastKnownHeight = containerRef.value.style.height;
      }
      else {
        containerRef.value.style.height = "";
        lastKnownHeight = null;
      }
    };

    observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  })
}