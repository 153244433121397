import { FormKit } from "@formkit/vue";
import { vReqT, UiOption, exhaustiveCaseGuard, assertTruthy, FK_validation_strlen } from "src/helpers/utils";
import { defineComponent, computed, ref } from "vue";
import { ClientRating } from "./ClientRatingsConfigurator.io";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { Btn2 } from "../UserInterface/Btn2";
import { X } from "../SVGs";
import { Client } from "src/store/Client";

export const SingleFormRow_Nothing = defineComponent({
  props: {
    oi_index: vReqT<number>(),
    ratingTypeOptions: vReqT<UiOption<RatingType | "">[]>(),
  },
  emits: {
    changeType: (_: RatingType | "") => true
  },
  inheritAttrs: true,
  setup(props, ctx) {
    return () => {
      return <>
        <tr {...ctx.attrs}>
          <td>{props.oi_index}.</td>
          <td>
            <label for="rating1Type">Rating Type:</label>
          </td>
          <td>
            <FormKit
              type="select"
              options={props.ratingTypeOptions}
              onInput={(value: any) => {
                ctx.emit("changeType", value);
              }}
              data-test="type"
            />
          </td>
          <td>
            <label for="rating1">Rating Text:</label>
          </td>
          <td>
            <FormKit type="text" disabled={true}/>
          </td>
        </tr>
      </>
    }
  }
});

export const SingleFormRow_Numeric = defineComponent({
  props: {
    oi_index: vReqT<number>(),
    isSpecialCoreRating: vReqT<boolean>(),
    hasSomeExistingCoreRating: vReqT<boolean>(),
    ratingTypeOptions: vReqT<UiOption<"" | RatingType>[]>(),
    data: vReqT<NumericRatingForm>(),
  },
  emits: {
    changeType: (_: RatingType | "") => true
  },
  inheritAttrs: true,
  setup(props, ctx) {
    return () => {
      return <>
        <tr {...ctx.attrs}>
          <td>{props.oi_index}.</td>
          <td>
            <label for="rating1Type">Rating Type:</label>
          </td>
          <td>
            <FormKit
              type="select"
              disabled={props.isSpecialCoreRating}
              options={props.ratingTypeOptions}
              v-model={props.data.type}
              onInput={(value: any) => {
                if (value === props.data.type) {
                  return;
                }
                ctx.emit("changeType", value);
              }}
              data-test="type"
            />
          </td>
          <td>
            <label for="rating1">Rating Text:</label>
          </td>
          <td>
            <FormKit
              type="text"
              disabled={props.isSpecialCoreRating}
              v-model={props.data.ratingText}
              validation={props.isSpecialCoreRating ? [] : [["required"]]}
              validationLabel="Rating Text"
              data-test="ratingText"
            />
          </td>
          <td rowspan="2">
            {Client.value.instanceConfig.autocalcoverallrating && !props.isSpecialCoreRating
              ? <div class="flex items-center">
                <FormKit
                  type="checkbox"
                  v-model={props.data.overallComponent}
                  data-test="overallComponent"
                />
                <div class="text-xs">Component of overall rating</div>
              </div>
              : null
            }
          </td>
        </tr>
        <tr {...ctx.attrs}>
          <td></td>
          <td>
            <label for="rating1Best">Best Number Rating:</label>
          </td>
          <td>
            <FormKit
              type="number"
              disabled={props.isSpecialCoreRating && props.hasSomeExistingCoreRating}
              v-model={props.data.rangeHigh}
              validation={[["required"]]}
              validationLabel="Best Number Rating"
              data-test="rangeHigh"
            />
          </td>
          <td>
            <label for="rating1Best">Worst Number Rating:</label>
          </td>
          <td>
            <FormKit
              type="number"
              disabled={props.isSpecialCoreRating && props.hasSomeExistingCoreRating}
              v-model={props.data.rangeLow}
              validation={[["required"]]}
              validationLabel="Worst Number Rating"
              data-test="rangeLow"
            />
          </td>
        </tr>
      </>
    }
  }
})

const MAX_TEXT_ITEM_STRLEN = 25;

export const SingleFormRow_Text = defineComponent({
  props: {
    oi_index: vReqT<number>(),
    ratingTypeOptions: vReqT<UiOption<"" | RatingType>[]>(),
    data: vReqT<TextRatingForm>(),
  },
  emits: {
    changeType: (_: RatingType | "") => true
  },
  inheritAttrs: true,
  setup(props, ctx) {
    const oneNoneRequiredTextItem = (zi_n: number) => {
      assertTruthy(zi_n >= 2, "first 2 elems (0,1) are always required");
      return <>
        <div>{zi_n + 1}.</div>
        <FormKit outer-class="grow" type="text" v-model={props.data.textOpts[zi_n]} validation={[FK_validation_strlen(0, MAX_TEXT_ITEM_STRLEN)]} validationLabel={`Item ${zi_n+1}`}/>
        <button type="button" class="p-1 rounded-md hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]" style="justify-self:start;"
          onClick={() => {
            props.data.textOpts.splice(zi_n, 1)
          }}
        >
          <X width="1em" height="1em"/>
        </button>
      </>
    }

    return () => {
      return <>
        <tr {...ctx.attrs}>
          <td>{props.oi_index}.</td>
          <td>
            <label for="rating1Type">Rating Type:</label>
          </td>
          <td>
            <FormKit
              type="select"
              options={props.ratingTypeOptions}
              v-model={props.data.type}
              onInput={(value: any) => {
                if (value === props.data.type) {
                  return;
                }
                ctx.emit("changeType", value);
              }}
            />
          </td>
          <td>
            <label for="rating1">Rating Text:</label>
          </td>
          <td>
            <FormKit type="text" v-model={props.data.ratingText} validation={[["required"]]} validationLabel={"Rating Text"}/>
          </td>
        </tr>
        <tr {...ctx.attrs}>
          <td></td>
          <td colspan="999">
              <div>Ratings options:</div>
              <div style="display:grid; grid-template-columns: max-content 1fr 2em; align-items:center; gap:.5em; max-width:400px;">
                <div>1.</div>
                <div><FormKit type="text" v-model={props.data.textOpts[0]} validation={[["required", "trim"], FK_validation_strlen(1, MAX_TEXT_ITEM_STRLEN)]} validationLabel="Item 1"/></div>
                <div></div>
                <div>2.</div>
                <div><FormKit type="text" v-model={props.data.textOpts[1]} validation={[["required", "trim"], FK_validation_strlen(1, MAX_TEXT_ITEM_STRLEN)]} validationLabel="Item 2"/></div>
                <div></div>
                {props.data.textOpts.length >= 3 ? oneNoneRequiredTextItem(2) : null}
                {props.data.textOpts.length >= 4 ? oneNoneRequiredTextItem(3) : null}
                {props.data.textOpts.length >= 5 ? oneNoneRequiredTextItem(4) : null}
                {props.data.textOpts.length >= 6 ? oneNoneRequiredTextItem(5) : null}
                {props.data.textOpts.length >= 7 ? oneNoneRequiredTextItem(6) : null}
                {props.data.textOpts.length === 8 ? oneNoneRequiredTextItem(7) : null}
                {props.data.textOpts.length !== 8
                  ? <Btn2
                      class="text-xl"
                      style="grid-column: -1/1; padding: .3em .25em; line-height:.5em; position:relative; width:1em; height:1em;"
                      onClick={() => {
                        props.data.textOpts.push("")
                      }}
                    >
                      <span style="position:absolute; top:50%; left:50%; transform: translateX(-50%) translateY(-60%);">+</span>
                  </Btn2>
                  : null
                }
              </div>
          </td>
        </tr>
      </>
    }
  }
})

export enum RatingType {
  numeric = "numeric",
  text = "text"
}

export type ClientRatingForm = NumericRatingForm | TextRatingForm

export type NumericRatingForm = {
  type: "numeric"
  ratingID: Guid | undefined,
  ratingText: string,
  rangeLow: "" | Integerlike,
  rangeHigh: "" | Integerlike,
  overallComponent: boolean,
}

export type TextRatingForm = {
  type: "text",
  ratingID: Guid | undefined,
  ratingText: string,
  textOpts: string[],
}

export function ClientRatingForm(source: ClientRating) : ClientRatingForm {
  if (source.type === "numeric") {
    return {
      ratingID: source.ratingID,
      type: "numeric",
      ratingText: source.ratingText,
      overallComponent: !!source.overallComponent,
      rangeHigh: source.rangeHigh,
      rangeLow: source.rangeLow,
    } satisfies NumericRatingForm
  }
  else if (source.type === "text") {
    return {
      ratingID: source.ratingID,
      type: "text",
      ratingText: source.ratingText,
      textOpts: [
        // first 2 elems are always required
        source.textItems[0]?.text || "",
        source.textItems[1]?.text || "",
        ...source.textItems.slice(2).filter(v => !!v).map(v => v.text || "")
      ]
    } satisfies TextRatingForm
  }
  else {
    exhaustiveCaseGuard(source.type);
  }
}

/**
 * TODO: unify with ClientRatingForm constructor (would be an overload set)
 */
export const freshRatingConfigForm = (args: {ratingID: undefined | Guid, ratingType: RatingType}) : ClientRatingForm => {
  switch (args.ratingType) {
    case RatingType.numeric: {
      return {
        ratingID: args.ratingID,
        type: "numeric",
        ratingText: "",
        rangeLow: "",
        rangeHigh: "",
        overallComponent: !!Client.value.instanceConfig.autocalcoverallrating,
      }
    }
    case RatingType.text: {
      return {
        ratingID: args.ratingID,
        type: "text",
        ratingText: "",
        // first 2 elements are always required
        textOpts: ["", ""]
      }
    }
    default: exhaustiveCaseGuard(args.ratingType)
  }
}
