import { defineComponent, ExtractPropTypes, PropType, ref } from 'vue'
import { ExtractEmitsHandlers } from 'src/helpers/utils'
import * as Family from "src/composables/InleagueApiV1.Family"

/**
 * We require only a small subset of general family information
 */
interface FamilySubset {
  familyID: string,
  parent1ID: string,
  parent2ID: string,
  familyParents: {userID: string, firstName: string, lastName: string}[],
  familyChildren: {playerFirstName: string, playerLastName: string}[]
}

export function propsDef() {
  return {
    families: {
      required: true as const,
      type: Array as PropType<FamilySubset[]>
    }
  }
}

export function emitsDef() {
  return {
    familyIdSelected: (_familyID: string) => true
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
