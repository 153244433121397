import { QuestionType } from "src/interfaces/InleagueApiV1"
import { PageItem_ambiguously_QuestionOrContentChunk } from "src/interfaces/Store/registration"

export interface QuestionFormErrors_QuestionOptionErrors {
  valueHasIllegalComma: boolean,
  valueIsDuplicate: boolean,
  valueIsEmpty: boolean,
  valueInvalidLength: boolean,
  valueInvalidLengthMsg: string,
}

// we {should, could} probably convince formkit to do this, but although we use formkit for actual input elements (mostly!?),
// we're not using its event model, mostly due to the "triggerSave" approach of broadcasting a notification to "save the question tree"
// (e.g. question save! --(formkit-uninvolved-broadcast)--> question options save! --(formkit-uninvolved-broadcast)--> associated gate functions and season/comp links save!)
export interface QuestionFormErrors {
  missingLabel: boolean,
  missingShortLabel: boolean,
  /**
   * `null` in text/textarea cases, which have no associated options
   */
  questionOptions: null | {
    /**
     * form should usually prevent from entering the `hasNoneButNeedsOne` state (e.g. delete the last option, just insert a new placeholder option
     * that the user needs to fill out)
     */
    hasNoneButNeedsOne: boolean
    /**
     * per option; each[i] should always be the errors for the target form's option[i] option,
     * and each.length should always exactly match the target form's options array length.
     */
    each: QuestionFormErrors_QuestionOptionErrors[]
  }
}

export function questionformErrorsHasAtLeastOneSingleError(v: QuestionFormErrors) {
  return v.missingLabel
    || v.missingShortLabel
    || v.questionOptions?.hasNoneButNeedsOne
    || !!v.questionOptions?.each.find(e => e.valueHasIllegalComma || e.valueIsDuplicate || e.valueIsEmpty || e.valueInvalidLength)
}

/**
 * we don't currently have any to track for this
 */
export interface ContentChunkFormErrors {
  __empty__: true
}

/**
 * todo: we have better types in interfaces/InleagueApiV1 (PageItem_Question vs. PageItem_ContentChunk) but this syncs with what existing callers are using.
 * Callers should transition to using the tighter types.
 */
export function questionFormErrorsFromQuestion(pageItem: PageItem_ambiguously_QuestionOrContentChunk) : QuestionFormErrors {
  const targetRequiresHasQuestionOptions = pageItem.type === QuestionType.CHECKBOX
    || pageItem.type === QuestionType.RADIO
    || pageItem.type === QuestionType.SELECT;

  return {
    missingLabel: pageItem.label.trim() === "",
    missingShortLabel: pageItem.shortLabel.trim() === "",
    questionOptions: targetRequiresHasQuestionOptions
      ? {
        hasNoneButNeedsOne: !pageItem.questionOptions?.length,
          each: pageItem.questionOptions?.map((option, i, allOptions) => {
            const optionValueLengthOK = 0 <= option.optionValue.length && option.optionValue.length <= 50
            return {
              valueHasIllegalComma: pageItem.type === QuestionType.CHECKBOX && /,/.test(option.optionValue),
              // fixme: consider CF conversion rules here (the backend will, so we'll get out of sync errors in cases like 1 == "yes")
              valueIsDuplicate: !!allOptions.find((e,innerI) => i !== innerI && e.optionValue.toLowerCase() == option.optionValue.toLowerCase()),
              valueIsEmpty: option.optionValue.trim() === "",
              valueInvalidLength: !optionValueLengthOK,
              valueInvalidLengthMsg: optionValueLengthOK ? "" : "Option value should be less than 50 characters.",
            }
          }) ?? /* shouldn't happen, fix types to make unrepresentable */ []
      }
      : null,
  }
}
