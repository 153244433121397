import { arrayFindIndexOrFail, assertTruthy, max, requireNonNull, sortBy, sortByMany } from "src/helpers/utils"

export type {
  LayoutNode,
  LayoutNodeRoot,
}

export {
  deleteFromTreeRetainingChildren,
  untreeify,
  treeify,
}

interface LayoutNodeBase<T> {
  /**
   * type tag, where null means "is LayoutNodeRoot", and non-null means "is LayoutNode"
   */
  parent: null | LayoutNodeRoot<T> | LayoutNode<T>
}

interface LayoutNodeRoot<T> extends LayoutNodeBase<T> {
  parent: null,
  children: LayoutNode<T>[],
}

interface LayoutNode<T> extends LayoutNodeBase<T> {
  parent: LayoutNodeRoot<T> | LayoutNode<T>,
  children: LayoutNode<T>[],
  /**
   * unix timestamp, doesn't matter if it is seconds or milliseconds,
   * but it must be in the same units as `end` (i.e. both must be either seconds, or milliseconds).
   * All nodes in one tree must also share the same unit (i.e. a tree is either all seconds, or all milliseconds).
   */
  start: number,
  /**
   * @see start
   */
  end: number,
  /**
   * Precedence drives which layoutnodes "own" overlapping nodes.
   * If all precedences for all nodes in a tree are equal, then the "first" node (sorted by time asc) owns nodes that overlap it but that come after.
   * Precedence values for a single tree should be sequential from zero - that is, the set of precedences in a tree should be like {0}, or {0,1}, or {0,1,2}, ...
   * "Highest" precedence is zero, with precedences decreasing as numbers increase.
   */
  precedence: number,
  data: T,
}

/**
 * Convert a list of LayoutNodes into a tree and return its root.
 *
 * This generates an entirely new list of nodes, nodes are copied so that old refs do not remain in the resulting tree,
 * i.e. existing nodes will never have parent pointers into the new tree.
 * The `.data` contained within the nodes is _not_ copied and remain live refs to their original data.
 *
 * This is a rough attempt at emulating the Google calendar layout algorithm.
 * Roughly, their algo seems like it tries hard to show the entire left edge
 * of every event on the calendar, no matter how overlapping they are; we are ... mostly
 * succesful with that. Draw order is something like "longest events in the back
 * (below everything) with shorter and shorter events stacking on top; non-overlapping
 * events begin new blocks at the next hour or minute section, but events that overlap
 * are drawn from left to right on top of the thing they overlap, sorted by start-time
 * asc, and recursively for additionally nested events."
 *
 * Currently this:
 *  - given a list L of elements, sort them by start time
 *  - Collect into a list G the first element of L, and all elements of L that do not intersect with any other element in G
 *  - For each element E(n) in G, collect into a list L'(n) each element that satisfies:
 *    - is not in any prior L'(n-1,n-2,...)
 *    - overlaps with E(n), or recursively overlaps with something that overlaps with an element in E(n)
 *  - for each generated list L'(n), reapply this procedure
 */
function treeify<T>(sources: readonly LayoutNode<T>[]) : LayoutNodeRoot<T> {
  return treeify_worker1([...sources])
}

/**
 * Flatten a tree into a list of its constituent LayoutNodes
 */
function untreeify<T>(root: LayoutNodeRoot<T>) : LayoutNode<T>[] {
  const collector : LayoutNode<T>[] = []

  worker(root.children)

  return collector

  function worker(nodes: LayoutNode<T>[]) {
    for (const node of nodes) {
      collector.push(node)
    }
    for (const node of nodes) {
      worker(node.children)
    }
  }
}

/**
 * Delete a node from it's parent's list of children.
 *
 * Retain the node's children in tree, by shifting them up into the level where
 * the source originated. This is, layoutwise, probably nonsense; but, the goal is to just
 * retain a tree that "contains the nodes we want, no matter the order", and then later we
 * will resort the tree appropriately. As long as the correct nodes are present, they
 * will be shuffled into the right place.
 */
function deleteFromTreeRetainingChildren(node: LayoutNode<unknown>) : void {
  const parent = node.parent
  const idx = arrayFindIndexOrFail(parent.children, c => c === node);
  parent.children.splice(idx, 1)


  for (const child of node.children) {
    child.parent = parent
    parent.children.push(child)
  }
}

function treeify_worker1<T>(source: LayoutNode<T>[]) : LayoutNodeRoot<T> {
  // We definitely need the sort asc by start.
  // The subsequent sort desc by start time is probably not necessary
  // but will have the effect of keeping the longest running items closer
  // to the start of whatever list they end up in.
  source.sort(
    sortByMany(
      sortBy(_ => _.start, "asc"),
      sortBy(_ => _.end, "desc"),
    )
  );

  const root : LayoutNodeRoot<T> = {
    parent: null,
    children: [],
  };

  treeify_worker2(root, source, max(source.map(v => v.precedence)))

  return root;
}

function treeify_worker2<T>(node: LayoutNodeRoot<T> | LayoutNode<T>, source: LayoutNode<T>[], maxPrecedenceInclusive: number) : void {
  while (source.length) {
    const nexts = treeify_consumeNextNonOverlappingSubsequence(source, maxPrecedenceInclusive)
    for (const next of nexts) {
      const freshNode : LayoutNode<T> = {
        parent: node,
        start: next.start,
        end: next.end,
        children: [],
        precedence: next.precedence,
        data: next.data
      }

      treeify_worker2(freshNode, treeify_consumeNextOverlappingElements(next, source), maxPrecedenceInclusive)

      node.children.push(freshNode)
    }
  }
}

/**
 * Precondition: `source` is sorted in order of "startTime asc"
 *
 * Extract from `source`
 *  - elements Es0 that intersect with `node`
 *  - elements Es1 that intersect with an element in Es0
 *  - ...
 *  - elements Es(n) that intersect with an element in Es(n-1)
 *
 * Returns zero-or-more elements.
 *
 * `source` is mutated to remove the retrieved elements.
 */
function treeify_consumeNextOverlappingElements<T>(node: LayoutNode<T>, source: LayoutNode<T>[]) : LayoutNode<T>[] {
  if (source.length === 0) {
    return []
  }

  const result : LayoutNode<T>[] = []

  for (let i = 0; i < source.length; i++) {
    const v = source[i]

    const intersectsAnyCurrentMatches = timeSpansIntersectUnix(node.start, node.end, v.start, v.end)
      || result.some(m => timeSpansIntersectUnix(m.start, m.end, v.start, v.end))

    if (intersectsAnyCurrentMatches) {
      result.push(v)
      source.splice(i, 1)
      i -= 1;
      continue;
    }
    else {
      // Breaking here is OK -- right?
      // If we didn't intersect with anything, we're never going to intersect with any already-matched elements again, right?
      // Assuming -- the input list is sorted by start time.
      break;
    }
  }

  return result;
}

/**
 * Precondition: `source` is sorted in order of "startTime asc"
 *
 * Pulls out the subsequence of elements E0...En from `source` where none of the elements in [E0..En] intersect.
 *
 * If `source` is non-empty, always returns at least 1 element (E0, mentioned above; the "first" element of `source`).
 * If `source` is empty returns an empty list.
 *
 * `source` is mutated to remove the retrieved elements.
 */
function treeify_consumeNextNonOverlappingSubsequence<T>(source: LayoutNode<T>[], maxPrecedenceInclusive: number) : LayoutNode<T>[] {
  if (source.length === 0) {
    return []
  }

  const resultBuilder : LayoutNode<T>[][] = []

  for (let precendece = 0; precendece <= maxPrecedenceInclusive; precendece++) {
    resultBuilder.push([])
    for (let i = 0; i < source.length; i++) {
      const v = source[i]
      if (v.precedence !== precendece) {
        continue;
      }

      let intersectsAnyCurrentMatches : boolean = false;
      outer: for (const grp of resultBuilder) {
        for (let gElemIdx = 0; gElemIdx < grp.length; gElemIdx++) {
          const gElem = grp[gElemIdx]

          if (gElem.start >= v.end) {
            // If the startTime of `gElem` is on-or-after the endTime of `v`,
            // then there is no intersection between `gElem` and `v`, and no subsequent elements
            // of `gElem`'s owning group can possibly intersect with `v`, either.
            // (all subsequent elements of `gElem`'s owning group start on-or-after `gElem`)
            break;
          }

          if (timeSpansIntersectUnix(gElem.start, gElem.end, v.start, v.end)) {
            intersectsAnyCurrentMatches = true;
            break outer;
          }
          else {
            continue;
          }
        }
      }

      if (intersectsAnyCurrentMatches) {
        continue;
      }
      else {
        resultBuilder[precendece].push(v)
        source.splice(i, 1);
        i -= 1;
        continue;
      }
    }
  }

  // Have to re-sort, because while each subgroup is sorted, they will merge potentially unsorted,
  // e.g.
  // [[1,2,3], [2,3]].flat      === [1,2,3,2,3]
  // [[1,2,3], [2,3]].flat.sort === [1,2,2,3,3]
  const result = resultBuilder.flat().sort(sortBy(_ => _.start))

  assertTruthy(result.length > 0);

  return result;
}

function timeSpansIntersectUnix(left_lo: number, left_hi: number, right_lo: number, right_hi: number) {
  if (left_lo > left_hi || right_lo > right_hi) {
    throw Error("Assertion Failure")
  }

  // left intersects start of right (but not abuts, like 8:30-9:30 does not intersect 9:30-10:30)
  if (left_lo <= right_lo && left_hi > right_lo) {
    return true;
  }

  // left intersects end of right (but not abuts, 8:30-9:30 does not intersect 7:30-8:30)
  if (left_lo < right_hi && left_hi >= right_hi) {
    return true;
  }

  // left fully within right
  if (left_lo >= right_lo && left_lo <= right_hi && left_hi >= right_lo && left_hi <= right_hi) {
    return true;
  }

  // left fully contains right
  if (left_lo <= right_lo && left_hi >= right_hi) {
    return true;
  }

  return false;
}
