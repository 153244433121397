import { ExtractPropTypes, defineComponent } from "vue";

export const propsDef = {
  familyID: {
    required: true,
    type: String,
  },
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export type jsx_sfc_shim__AdminPlayers = ReturnType<typeof defineComponent<Props>>
