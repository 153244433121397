<template lang="pug">
template(v-if="sideBySideState")
  // This example requires Tailwind CSS v2.0+
  .mb-6.bg-white.shadow.overflow-hidden(class='sm:rounded-md')
    .bg-white.px-4.py-5.border-b.border-gray-200(class='sm:px-6')
      h3.text-lg.leading-6.font-medium.text-gray-900 Current Program Relationships
    template(v-if="sideBySideState.isSideBySideWith.length === 0")
      div.p-4.flex.items-center.justify-center None
    template(v-else)
      ul.divide-y.divide-gray-200(role='list')
        li(v-for="clientMutableSideBySide in sideBySideState.isSideBySideWith" :key="clientMutableSideBySide.__key")
          div
            .px-4.py-4(class='sm:px-2 sm:px-2')
              div.text-sm.font-medium.grid(style="grid-template-columns: .66fr 1fr min-content;")
                div.break-all
                  div {{ clientMutableSideBySide.competition2.competition }}
                div.px-2
                    div Registration impact based on  {{ clientMutableSideBySide.competition1.competition }} Status:
                    .flex.justify-start
                      div.divide-y
                        .form-check.py-2.flex.items-center
                          input#flexRadioDefault1.form-check-input.appearance-none.rounded-full.h-4.w-4.border.border-gray-300.bg-white.transition.duration-200.align-top.bg-no-repeat.bg-center.bg-contain.float-left.mr-2.cursor-pointer(
                            class='checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                            v-model="clientMutableSideBySide.exclusions.value"
                            :value="ExclusionType.excludeIfEligible"
                            type='radio' :name='`exclude-include-radio-${clientMutableSideBySide.__key}`' :checked='clientMutableSideBySide.exclusions.value === ExclusionType.excludeIfEligible'
                          )
                          label.form-check-label.inline-block.text-gray-800(for='flexRadioDefault1')
                            | Players cannot register for  {{ clientMutableSideBySide.competition2.competition }} if eligible for  {{ clientMutableSideBySide.competition1.competition }}
                        .form-check.py-2.flex.items-center
                          input#flexRadioDefault1.form-check-input.appearance-none.rounded-full.h-4.w-4.border.border-gray-300.bg-white.transition.duration-200.align-top.bg-no-repeat.bg-center.bg-contain.float-left.mr-2.cursor-pointer(
                            class='checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                            v-model="clientMutableSideBySide.exclusions.value"
                            :value="ExclusionType.excludeIfRegistered"
                            type='radio' :name='`exclude-include-radio-${clientMutableSideBySide.__key}`' :checked='clientMutableSideBySide.exclusions.value === ExclusionType.excludeIfRegistered'
                          )
                          label.form-check-label.inline-block.text-gray-800(for='flexRadioDefault2')
                            | Players cannot register for  {{ clientMutableSideBySide.competition2.competition }} if registered for  {{ clientMutableSideBySide.competition1.competition }}
                        .form-check.py-2.flex.items-center
                          input#flexRadioDefault1.form-check-input.appearance-none.rounded-full.h-4.w-4.border.border-gray-300.bg-white.transition.duration-200.align-top.bg-no-repeat.bg-center.bg-contain.float-left.mr-2.cursor-pointer(
                            class='checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                            v-model="clientMutableSideBySide.exclusions.value"
                            :value="ExclusionType.both"
                            type='radio' :name='`exclude-include-radio-${clientMutableSideBySide.__key}`' :checked='clientMutableSideBySide.exclusions.value === ExclusionType.both'
                          )
                          label.form-check-label.inline-block.text-gray-800(for='flexRadioDefault2')
                            | Players cannot register for  {{ clientMutableSideBySide.competition2.competition }} if eligible or registered for  {{ clientMutableSideBySide.competition1.competition }}

                        //-
                        //- inclusions
                        //-
                        .form-check.py-2.flex.items-center
                          input#flexRadioDefault1.form-check-input.appearance-none.rounded-full.h-4.w-4.border.border-gray-300.bg-white.transition.duration-200.align-top.bg-no-repeat.bg-center.bg-contain.float-left.mr-2.cursor-pointer(
                            class='checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                            v-model="clientMutableSideBySide.inclusions.value"
                            :value="InclusionType.includeIfRegistered"
                            type='radio' :name='`exclude-include-radio-${clientMutableSideBySide.__key}`' :checked='clientMutableSideBySide.inclusions.value === InclusionType.includeIfRegistered'
                          )
                          label.form-check-label.inline-block.text-gray-800(for='flexRadioDefault2')
                            | Players become eligible for  {{ clientMutableSideBySide.competition2.competition }} once registered for  {{ clientMutableSideBySide.competition1.competition }}
                        .form-check.py-2.flex.items-center
                          input#flexRadioDefault1.form-check-input.appearance-none.rounded-full.h-4.w-4.border.border-gray-300.bg-white.transition.duration-200.align-top.bg-no-repeat.bg-center.bg-contain.float-left.mr-2.cursor-pointer(
                            class='checked:bg-blue-600 checked:border-blue-600 focus:outline-none'
                            v-model="clientMutableSideBySide.inclusions.value"
                            :value="InclusionType.onlyIfRegistered"
                            type='radio' :name='`exclude-include-radio-${clientMutableSideBySide.__key}`' :checked='clientMutableSideBySide.inclusions.value === InclusionType.onlyIfRegistered'
                          )
                          label.form-check-label.inline-block.text-gray-800(for='flexRadioDefault2')
                            | Players can register for {{ clientMutableSideBySide.competition2.competition }} simultaneously while registering for {{ clientMutableSideBySide.competition1.competition }}
                div.ml-2.flex-shrink-0.flex.items-center
                  button.bg-green-700.text-white.font-bold.py-2.px-2.rounded-full(class='hover:bg-green-900' @click="deleteCompetitionSideBySideExclusion(clientMutableSideBySide)")
                    | Remove

  .bg-white.shadow.overflow-hidden(class='sm:rounded-md')
    .bg-white.px-4.py-5.border-b.border-gray-200(class='sm:px-6')
      h3.text-lg.leading-6.font-medium.text-gray-900 Add Program Relationship
    template(v-if="sideBySideState.isNotSideBySideWith.length === 0")
      div.p-4.flex.items-center.justify-center None
    template(v-else)
      ul.divide-y.divide-gray-200(role='list')
        li(v-for="competition in sideBySideState.isNotSideBySideWith")
          div
            .px-4.py-4(class='sm:px-6')
              .flex.items-center.justify-between
                p.text-sm.font-medium
                  div {{ competition.competition }}
                //  div.font-light.text-xs (competition id {{ competition.competitionID }})
                .ml-2.flex-shrink-0.flex
                  button.bg-green-700.text-white.font-bold.py-2.px-4.rounded-full(class='hover:bg-green-900' @click="createCompetitionSideBySideExclusion(competition)")
                    | Add
</template>

<script lang="ts">
import { Competition, CompetitionSideBySide, CompetitionUID, Guid } from "src/interfaces/InleagueApiV1"
import { defineComponent, onMounted, PropType, reactive, ref, watch, markRaw } from "vue"
import * as ilapi from "src/composables/InleagueApiV1"
import { axiosInstance, axiosAuthBackgroundInstance } from 'boot/axios'
import { assertNonNull } from "src/helpers/utils"
import { Switch } from "@headlessui/vue"

enum ExclusionType {
  excludeIfEligible = "ex-ifEligible",
  excludeIfRegistered = "ex-ifRegistered",
  both = "ex-both",
  none = "ex-none"
}

enum InclusionType {
  includeIfRegistered = "inc-ifRegistered",
  onlyIfRegistered = "inc-onlyIfRegistered",
  none = "inc-none"
}

export default defineComponent({
  props: {
    competitionUID: {
      required: true,
      type: String as PropType<Guid>
    }
  },
  components: {
    Switch
  },
  inheritAttrs: false,
  setup(props, context) {

    interface WithSideBySide { sideBySide: CompetitionSideBySide[] }
    // main component state
    interface SideBySideState {
      targetCompetition: Competition,
      isSideBySideWith: ClientMutableSideBySide[],
      isNotSideBySideWith: Competition[]
    }

    // definitely assigned in onMounted
    // note this is not reactive; just a place to put cached and locally updated http response info
    // the intent being that this component will be mounted once and the parent will cycle through competitionUIDs,
    // so we'd like to not make repeated http calls on competitionUID change if we can avoid it
    let competitionsWithSideBySideApiTracker: Record<CompetitionUID, Competition & WithSideBySide> | undefined;

    // main component state, definitely init'd in onMounted; reinit'd as per watchers
    const mutableSideBySideState = ref<SideBySideState | undefined>(undefined);
    // try to not get into weird states with multiple requests in flight
    let busy = false;

    onMounted(async () => {
      const competitions = await ilapi.getCompetitions(axiosInstance, {includeDisabled: true, expand: ["sideBySide"]});
      competitionsWithSideBySideApiTracker = (() => {
        const result: Record<CompetitionUID, Competition & WithSideBySide> = {};
        for (const competition of competitions) {
          assertNonNull(competition.sideBySide, "sideBySide via expandable");
          // spread helps assignability here
          result[competition.competitionUID] = { ...competition, sideBySide: competition.sideBySide };
        }
        return result;
      })()

      mutableSideBySideState.value = generateInitialMutableSideBySideState(competitionsWithSideBySideApiTracker);
    });

    /**
     * target competition changed, rebuild state as per the new value
     */
    watch(() => props.competitionUID, () => {
      assertNonNull(competitionsWithSideBySideApiTracker, "assigned in onmounted");
      mutableSideBySideState.value = generateInitialMutableSideBySideState(competitionsWithSideBySideApiTracker);
    });

    const getCurrentCachedCompetitionResponse = () : Competition & WithSideBySide => {
      assertNonNull(competitionsWithSideBySideApiTracker, "assigned in onmounted");
      return competitionsWithSideBySideApiTracker[props.competitionUID];
    }

    /**
     * arbitrary but visually consistent
     */
    const competitionSort = (l: Competition, r: Competition) => {
      return l.competitionID < r.competitionID ? -1 : 1;
    }
    const clientMutableSideBySideSort = (l: ClientMutableSideBySide, r: ClientMutableSideBySide) => {
      // competition1 is "this" competition; the display is per "other" competition, so we sort on `competition2`
      return l.competition2.competitionID < r.competition2.competitionID ? -1 : 1;
    }

    // we mutate watch targets during that watch's callback; set a flag to not recurse
    let inCbHandler = false;
    const clientMutableSideBySideUpdateCallback = async ({field, obj}: {field: Exclusions | Inclusions, obj: ClientMutableSideBySide}) => {
      if (inCbHandler) {
        return;
      }

      const savedIncludesExcludes = {
        exclusions: {...obj.exclusions},
        inclusions: {...obj.inclusions}
      };

      try {
        inCbHandler = true;
        if (field.type === "exclusions") {
          obj.inclusions.value = InclusionType.none;
        }
        else {
          obj.exclusions.value = ExclusionType.none;
        }

        await updateCompetitionSideBySideExclusion(obj);
      }
      catch {
        // rollback local changes, maybe show an error like "sorry it didn't save"
        obj.exclusions = savedIncludesExcludes.exclusions;
        obj.inclusions = savedIncludesExcludes.inclusions;
      }
      finally {
        inCbHandler = false;
      }
    }

    const generateInitialMutableSideBySideState = (
      competitionMapWithSideBySide: Record<CompetitionUID, Competition & WithSideBySide>
    ) : SideBySideState => {
      const targetCompetition = competitionMapWithSideBySide[props.competitionUID];
      const builder = {
        isSideBySideWith: new Map<CompetitionUID, Competition & WithSideBySide>(),
        isNotSideBySideWith: (() => {
          const v = new Map<CompetitionUID, Competition & WithSideBySide>(Object.entries(competitionMapWithSideBySide))
          // remove ourselves from this list
          v.delete(targetCompetition.competitionUID);
          return v;
        })()
      }

      // map of (comp2UID -> SideBySide) where comp2UID is the OTHER (i.e. not the current target competition) competition UID
      const sideBySideMap = new Map<CompetitionUID, CompetitionSideBySide>(targetCompetition.sideBySide.map(sideBySide => [sideBySide.comp2UID, sideBySide]));
      sideBySideMap.forEach(sideBySide => {
        builder.isSideBySideWith.set(sideBySide.comp2UID, competitionMapWithSideBySide[sideBySide.comp2UID]);
        builder.isNotSideBySideWith.delete(sideBySide.comp2UID);
      });

      const isSideBySideWith = (() => {
        const result: ClientMutableSideBySide[] = [];
        builder.isSideBySideWith.forEach(competition => result.push(
          ClientMutableSideBySide(
            targetCompetition,
            competition,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sideBySideMap.get(competition.competitionUID)!,
            clientMutableSideBySideUpdateCallback
          )
        ))
        result.sort(clientMutableSideBySideSort);
        return result;
      })();

      const isNotSideBySideWith = (() => {
        const result: Competition[] = [];
        builder.isNotSideBySideWith.forEach(competition => result.push(competition));
        result.sort(competitionSort)
        return result;
      })()

      return {
        targetCompetition,
        isSideBySideWith,
        isNotSideBySideWith
      }
    }

    /**
     * create a competition side-by-side exclusion
     * mutates component state by changing our list of "is side by side with" and "is not side by side with"
     */
    const createCompetitionSideBySideExclusion = async (otherCompetition: Competition) : Promise<void> => {
      if (busy) {
        return;
      }

      assertNonNull(mutableSideBySideState.value, "assign in onmounted");
      assertNonNull(competitionsWithSideBySideApiTracker, "assigned in onmounted");

      try {
        busy = true;
        const thisCompetition = getCurrentCachedCompetitionResponse();
        const freshSideBySide = await detail.createCompetitionSideBySideExclusion(
          thisCompetition.competitionUID,
          otherCompetition.competitionUID,
          axiosInstance // use regular axios instance w/ spinner
        );

        const existingIndex = mutableSideBySideState.value.isNotSideBySideWith.indexOf(otherCompetition);
        if (existingIndex === -1) {
          // no match? weird bug, silent failure, nothing we can do here
        }
        else {
          mutableSideBySideState.value.isNotSideBySideWith.splice(existingIndex, 1);
        }

        mutableSideBySideState.value.isSideBySideWith.push(ClientMutableSideBySide(
          mutableSideBySideState.value.targetCompetition,
          otherCompetition,
          freshSideBySide,
          clientMutableSideBySideUpdateCallback
        ));

        mutableSideBySideState.value.isSideBySideWith.sort(clientMutableSideBySideSort);

        thisCompetition.sideBySide.push(freshSideBySide);
      }
      finally {
        busy = false;
      }
    }

    const updateCompetitionSideBySideExclusion = async (data: ClientMutableSideBySide) : Promise<void> => {
      if (busy) {
        return;
      }

      assertNonNull(mutableSideBySideState.value, "assigned in onmounted");

      try {
        const thisCompetition = getCurrentCachedCompetitionResponse();
        busy = true;
        const freshSideBySide = await detail.updateCompetitionSideBySideExclusion(data, axiosAuthBackgroundInstance);

        // we don't directly update `mutableSideBySideState`, since ClientMutableSideBySide state represents the changes that already occured by the form mutating it
        // we *could* store them but it'd be effectively a no-op

        // but, we do update the cached response
        const index = thisCompetition.sideBySide.findIndex(v => v.comp2UID === data.competition2.competitionUID);
        if (index !== -1) {
          thisCompetition.sideBySide[index] = freshSideBySide;
        }
      }
      finally {
        busy = false;
      }
    }

    const deleteCompetitionSideBySideExclusion = async (data: ClientMutableSideBySide) => {
      if (busy) {
        return;
      }

      assertNonNull(mutableSideBySideState.value, "assigned in onmounted");

      try {
        const thisCompetition = getCurrentCachedCompetitionResponse();
        busy = true;
        await detail.deleteCompetitionSideBySideExclusion(data, axiosInstance);

        mutableSideBySideState.value.isSideBySideWith = mutableSideBySideState.value.isSideBySideWith.filter(v => v !== data);
        thisCompetition.sideBySide = thisCompetition.sideBySide.filter(v => v.comp2UID !== data.competition2.competitionUID);

        mutableSideBySideState.value.isNotSideBySideWith.push(data.competition2)

        mutableSideBySideState.value.isSideBySideWith.sort(clientMutableSideBySideSort);
        mutableSideBySideState.value.isNotSideBySideWith.sort(competitionSort);
      }
      finally {
        busy = false;
      }
    }

    return {
      busy,
      sideBySideState: mutableSideBySideState,
      createCompetitionSideBySideExclusion,
      updateCompetitionSideBySideExclusion,
      deleteCompetitionSideBySideExclusion,
      InclusionType,
      ExclusionType,
    }
  }
})

interface Exclusions {
  readonly type: "exclusions",
  value: ExclusionType
}

interface Inclusions {
  readonly type: "inclusions",
  value: InclusionType
}

interface ClientMutableSideBySide {
  readonly __key: string,
  readonly competition1 : Competition,
  readonly competition2 : Competition,
  // for round-tripping unchanged fields
  // readonly isn't deep, but we should treat this this obj is 100% immutable
  readonly source: CompetitionSideBySide,
  // currently these are mutually exclusive (either there are exclusions or inclusions)
  exclusions: Exclusions,
  inclusions: Inclusions,
}

function ClientMutableSideBySide(
  competition1: Competition,
  competition2: Competition,
  source: CompetitionSideBySide,
  cb: (args: {field: Exclusions | Inclusions, obj: ClientMutableSideBySide}) => Promise<unknown>
) : ClientMutableSideBySide {
  const result : ClientMutableSideBySide = (() => {
    const result : ClientMutableSideBySide = {
      __key: `${competition1.competitionUID},${competition2.competitionUID}`,
      competition1,
      competition2,
      source: source,
      exclusions: {
        type: "exclusions",
        value:
          source.applicability_exclude_if_eligible && source.applicability_exclude_if_registered ? ExclusionType.both
          : source.applicability_exclude_if_eligible ? ExclusionType.excludeIfEligible
          : source.applicability_exclude_if_registered ? ExclusionType.excludeIfRegistered
          : ExclusionType.none
      },
      inclusions: {
        type: "inclusions",
        value: source.applicability_autoeligible_if_registered
          ? InclusionType.includeIfRegistered
          : source.applicability_only_if_registered
          ? InclusionType.onlyIfRegistered
          : InclusionType.none
      }
    }

    // "identity-hazard" w/ caller-supplied value and marked-raw version?
    markRaw(result.competition1);
    markRaw(result.competition2);

    // make reactive *then* register watchers
    return reactive(result);
  })()

  watch(() => result.exclusions, () => cb({field: result.exclusions, obj: result}), {deep: true});
  watch(() => result.inclusions, () => cb({field: result.inclusions, obj: result}), {deep: true});

  return result;
}

/**
 * workers to transform "component" state into api-tier shapes
 */
const detail = {
  async createCompetitionSideBySideExclusion(competitionUID: Guid, otherCompetitionUID: Guid, axios = axiosAuthBackgroundInstance) : Promise<CompetitionSideBySide> {
    return await ilapi.createOrUpdateCompetitionSideBySideExclusion(
      axios, {
      competitionUID: competitionUID,
      otherCompetitionUID: otherCompetitionUID,
      creditFeeForExchange: false,
      canExchange: false,
      applicability_exclude_if_eligible: true,
      applicability_exclude_if_registered: false,
      applicability_autoeligible_if_registered: false,
      applicability_only_if_registered: false,
    });
  },
  async updateCompetitionSideBySideExclusion(data: ClientMutableSideBySide, axios = axiosAuthBackgroundInstance) : Promise<CompetitionSideBySide> {
    // Dirty checks should happen in the form/template, so we shouldn't get here if the record is not dirty
    // It is not an error to ask to update an existing record and "change no fields", but there's also no reason to do so
    return await ilapi.createOrUpdateCompetitionSideBySideExclusion(
      axios, {
      competitionUID: data.competition1.competitionUID,
      otherCompetitionUID: data.competition2.competitionUID,
      creditFeeForExchange: data.source.creditFeeForExchange,
      canExchange: data.source.canExchange,
      applicability_exclude_if_eligible: data.exclusions.value === ExclusionType.both || data.exclusions.value === ExclusionType.excludeIfEligible,
      applicability_exclude_if_registered: data.exclusions.value === ExclusionType.both || data.exclusions.value === ExclusionType.excludeIfRegistered,
      applicability_autoeligible_if_registered: data.inclusions.value === InclusionType.includeIfRegistered,
      applicability_only_if_registered: data.inclusions.value === InclusionType.onlyIfRegistered,

    });
  },
  async deleteCompetitionSideBySideExclusion(data: ClientMutableSideBySide, axios = axiosAuthBackgroundInstance) : Promise<void> {
    await ilapi.deleteCompetitionSideBySideExclusion(axios, {competitionUID: data.competition1.competitionUID, otherCompetitionUID: data.competition2.competitionUID});
  }
}

</script>
