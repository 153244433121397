import { HomeTeam, VisitorTeam } from 'src/composables/InleagueApiV1.Game'
import { Datelike, DateTimelike, Guid, RefDetails } from "./InleagueApiV1"
import * as ilgame from "src/composables/InleagueApiV1.Game"
import { assertTruthy, checkedObjectEntries } from 'src/helpers/utils'

export interface GameScore {
  gameID: string
  gameNum: number
  gameDate: string
  gameStart: string
  gameEnd: string
  fieldName: string
  fieldAbbrev: string
  division: string
  playoff: number
  comment: string
  /**
   * number representing boolean
   */
  doPointsCount: number
  genderNeutral: number
  home: string
  visitor: string
  homeGoals: string
  visitorGoals: string
  homeTeam: HomeTeam
  visitorTeam: VisitorTeam
  homeCoaches: Coach[]
  visitorCoaches: Coach[]
}

export interface Player {
  registrationID: string
  uniform: string
  playerFirstName: string
  playerLastName: string
  aysoID: string
  childID: string
}

export interface Coach {
  firstname: string
  lastname: string
  email: string
  title: string
  userID: string
  teamID: string
  isHeadCoach: string
}

export interface ScoreVisitorTeam {
  team: string
  teamName: string
  goalsHalftime: string
  goals: string
  players: Player[]
  ID: string
}

export interface ScoreHomeTeam {
  team: string
  teamName: string
  goalsHalftime: string
  goals: string
  players: Player[]
  ID: string
}

export interface TransactionType {
  typeID: string
  label: string
}

export interface Transaction {
  transactionType: string;
  points: number
  teamName: string
  /**
   * Is this really optional, or does it represent a confluence of concerns,
   * attempting to represent that the backend type has a transactionID, and the
   * frontend might not if it is tentative. This really should try to model only
   * the backend type.
   */
  transactionID?: string
  playerName: string
  /**
   * Really optional? Probably this is `"" | DateTimelike`
   */
  dateEntered?: string
  comment: string
  label: string
  teamID: string
  registrationID: string
}

export interface Emails {
  AR2: string
  CR: string
  AR1: string
}

export interface ScoreForm {
  homeGoals: number
  homeGoalsHalftime: number
  visitorGoals: number
  visitorGoalsHalftime: number
  scoreComment: string
}

export interface ScoreTransactionForm {
  /**
   * __key is same as transactionID if it exists, otherwise it is an opaque value unique from all other possible transactionIDs
   * for this browser session (that is, it is NOT a GUID, so as to not conflict with transactions on the backend).
   * When an element is committed to the backend (i.e. "persisted"), this property and transactionID should be updated to reflect the new ID
   * generated on the backend.
   */
  __key: string,
  /**
   * Empty string if not yet persisted to the DB
   */
  transactionID: "" | Guid,
  teamID: string
  transactionType: string
  /**
   * Empty string if there is no associated player (transitively by way of their registration)
   */
  registrationID: "" | Guid,
  comment: string
}

export function ScoreTransactionForm() : ScoreTransactionForm
export function ScoreTransactionForm(source: Transaction) : ScoreTransactionForm
export function ScoreTransactionForm(source?: Transaction) : ScoreTransactionForm {
  if (!source) {
    return {
      __key: ScoreTransactionForm.__nextLocalTransactionID(),
      transactionID: "",
      teamID: '',
      registrationID: '',
      comment: '',
      transactionType: '',
    }
  }
  else {
    assertTruthy(source.transactionID)
    return {
      __key: source.transactionID,
      transactionID: source.transactionID,
      teamID: source.teamID,
      registrationID: source.registrationID,
      comment: source.comment,
      transactionType: source.transactionType,
    }
  }
}

/**
 * This is effectively namespaced within the only function that will every call it
 */
ScoreTransactionForm.__nextLocalTransactionID = (() => {
  let __v = -1;
  return () => `${__v--}`;
})();

export function scoreTransactionFormIsTentative(v: ScoreTransactionForm) {
  return !v.transactionID;
}

export function scoreTransactionFormIsDirty(l: ScoreTransactionForm, r: ScoreTransactionForm) {
  const consideredForDirty : Record<keyof ScoreTransactionForm, boolean> = {
    __key: false,
    transactionID: false,
    teamID: true,
    transactionType: true,
    registrationID: true,
    comment: true,
  }

  const ks : (keyof ScoreTransactionForm)[] = checkedObjectEntries(consideredForDirty)
    .filter(([_, bool] : [keyof ScoreTransactionForm, boolean]) => bool)
    .map(([key, _] : [keyof ScoreTransactionForm, boolean]) => key);

  for (const k of ks) {
    if (l[k] !== r[k]) {
      return true;
    }
  }

  return false;
}

export interface Team {
  team:          string;
  goals:         number;
  goalsHalfTime: number;
  players:       Player[];
  fullName:      string;
  teamName:      string;
  ID:            string;
}

/**
 * Response type of game/:gameID{36}/gameScores/
 * Returned from anywhere else?
 * todo: this endpoint returns 2 separate types, depending on `theGame.userIsAuthorizedForMatchReport(currentUser)`
 * The above authz check is opaque to us, so we don't know ahead of time which we'll get (and that logic is not guaranteed to remain constant)
 *  - are the return types disjoint or maintain a subtype relation?
 *  - can the backend be updated to bring them into a subtype relation; or if not, tag the result type appropriately (e.g. `type: "score/as-match-report"` or etc.)?
 */
export interface GameScoreDetails {
  visitorTeam:                  Team;
  division:                     string;
  scoreTransactions:            Transaction[];
  userAuthorizedForMatchReport: boolean;
  gameNum:                      number;
  scoreUserName:                string;
  assignedRefs:                 RefDetails[];
  scoreComment:                 string;
  gameID:                       string;
  fieldName:                    string;

  gameDate: Datelike;
  gameStart: DateTimelike;
  gameEnd: DateTimelike;

  scoreDate:                    string;
  homeTeam:                     Team;
  teamVersus:                   string;
  adhocCoachInfo: ilgame.AdhocCoachInfo[]
}
