import { defineComponent,ref, onMounted, computed } from 'vue'
import { Client } from 'src/store/Client'
import { UiOption } from 'src/helpers/utils'
import { FormKit } from '@formkit/vue';

export default defineComponent({
  props: {
    /**
     * This component must be supplied a v-model="..." attribute
     */
    modelValue: {
      type: String,
      required: true
    },
    displayLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    // runs before props.P.validator, but is that guaranteed behavior?
    // (and, in the validator, it may have been updated already; so we get a string in the validator,
    // but FormKit got undefined when mounted, and then FormKit cryptically stops tracking updates)
    if (process.env.NODE_ENV === "development") {
      if (props.modelValue === undefined) {
        throw [
          "`undefined` is not supported here.",
          "FormKit will opt-out of v-modeling bindings with an initial value of `undefined`.",
          "This will manifest as non-reactivity even after the prop is updated to a non-undefined value."
        ].join(" ");
      }
    }

    const seasonOptions = ref<UiOption[]>([])
    const ready = ref(false)

    const selectedSeasonUID = computed({
      get() : string {
        return props.modelValue
      },
      set(value: string) {
        emit('update:modelValue', value)
      }
    })

    onMounted(async () => {
      seasonOptions.value = (await Client.loadSeasons()).map(v => {
        return {
          label: v.seasonName,
          value: v.seasonUID,
        }
      })
      ready.value = true;
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <FormKit
          v-model={selectedSeasonUID.value}
          options={seasonOptions.value}
          type='select'
          label={props.displayLabel ? 'Seasons' : ''}
          //
          // `data-test` is "rootlike and select" because FormKit is going to expand this into some tree
          // where the data-test is assigned to a leaf level <select> element
          // so in code it's rootlike, at runtime it will be on the select element
          //
          data-test='seasons-rootlike-and-select'
          inner-class='bg-white'
          wrapper-class='mt-6'
          {...{placeholder:'Select a Season'}}
        />
      )
    }
  }
})
