import { computed, defineComponent, ref, watch } from "vue"

import Portlet from "src/components/Portlets/Portlet.vue"

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilportlet from 'src/composables/InleagueApiV1.Portlet'

import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import authService from 'src/helpers/authService'
import { freshAxiosInstance } from 'src/boot/axios'

import { getLogger, maybeLog } from "src/modules/LoggerService"
import { LoggedinLogWriter } from "src/modules/Loggers"
import { arraySum, exhaustiveCaseGuard, parseFloatOr, parseIntOr, parseIntOrFail, sortBy, sortByMany, useAbortController } from "src/helpers/utils"
import { SoccerBall } from "../SVGs"
import { DAYJS_FORMAT_IL_FULL_1, dayjsFormatOr } from "src/helpers/formatDate"
import { MenuTree, freshSelectionsFirstOfEach } from "../UserInterface/MenuTree"
import { User } from "src/store/User"
import { Client } from "src/store/Client"
import { RouterLink } from "vue-router"
import * as R_AdminPage from "src/components/Admin/SeasonCompetitions/pages/R_AdminPage.route"
import dayjs from "dayjs"

export const DashboardRegistrationReport = defineComponent({
  name: `DashboardRegistrationReport`,
  setup() {

    const abortController = useAbortController()

    const reportGetter = ReactiveReifiedPromise<ilportlet.DashboardRegistrationReport>(
      undefined,
      {
        onError: ({error}) => {
          if (User.isLoggedIn) {
            maybeLog(getLogger(LoggedinLogWriter), "warning", "DashboardRegistrationReport", error);
          }
        }
      }
    )

    watch(() => User.value.userID, () => {
      const isLoggedIn = User.isLoggedIn;
      const isRegistrar = authService(User.value.roles, "registrar")
      const managesSomeCompetition = typeof User.value.userData === "object" && User.value.userData.competitionsMemento.length > 0;

      if (isLoggedIn && (isRegistrar || managesSomeCompetition)) {
        const loggedInNoToastAxios = freshAxiosInstance({useCurrentBearerToken: true, signal: abortController.signal, requestInterceptors: [], responseInterceptors: []});

        // typically this resolves instantly
        // but it can sit and wait until the backend initializes a cache,
        // and that cache might be being initialized by someone else, and it is slow to init
        const pollUntilResolvedOrUnmounted = async () => {
          while (true) {
            const result = await ilportlet.getDashboardRegistrationReport(loggedInNoToastAxios)
            if (result === "EBUSY") {
              await new Promise(resolve => setTimeout(resolve, 15_000))
            }
            else {
              return result;
            }
          }
        }

        reportGetter.run(pollUntilResolvedOrUnmounted);
      }
      else {
        // the most common case
        reportGetter.forceReject("lookup doesn't need to happen for a user having such permissions");
      }
    }, {immediate: true})

    type LocalState =
      | {readonly status: "loading"}
      | {readonly status: "unneeded-due-to-user-perms"}
      | {readonly status: "resolved", readonly data: ilportlet.DashboardRegistrationReport}

    const localState = computed<LocalState>(() => {
      return reportGetter.underlying.status === "resolved"
        ? {status: "resolved", data: reportGetter.underlying.data}
        : reportGetter.underlying.status === "pending"
        ? {status: "loading"}
        : {status: "unneeded-due-to-user-perms"}; // this is ambiguous with other possible error states, but its good enough
    })

    const menuSelections = ref({
      competitionUID: "",
      seasonUID: "",
    })

    watch(() => localState.value, () => {
      if (localState.value.status === "resolved") {
        menuSelections.value = freshSelectionsFirstOfEach(localState.value.data.menuDef);
      }
      else {
        menuSelections.value = {
          competitionUID: "",
          seasonUID: ""
        }
      }
    }, {immediate: true})

    const selectedReportEntry = computed(() => {
      if (localState.value.status === "resolved") {
        return localState.value.data.stats.find(v => v.competitionUID === menuSelections.value.competitionUID && v.seasonUID === menuSelections.value.seasonUID)
      }
      else {
        return undefined;
      }
    })

    const selectedFeeDetails = computed(() => {
      if (localState.value.status === "resolved") {
        const feesByDiv = localState.value.data.feesByDivBySeasonByComp[menuSelections.value.competitionUID]?.[menuSelections.value.seasonUID]

        if (!feesByDiv) {
          return undefined;
        }

        return Object
          .values(feesByDiv)
          // drop optionality; typing artifact, shouldn't be strictly necessary
          .filter((v) : v is ilportlet.DashboardRegistrationReportFeeInfo_One => !!v)
          .sort(sortByMany(
            sortBy(_ => parseIntOr(_.divNum, -1), "asc"),
            sortBy(_ => _.gender)
          ))
          // Keep only the ones where we were able to compute at least one fee.
          // Typically, it's "all or nothing", where the backend either was able to compute, per (comp,season,div), a fee for all "ifRegisteringOn" dates,
          // or was unable to compute a fee for all "ifRegisteringOn" dates.
          // But the data structure is able to represent "some were computed, some weren't"
          .filter(v => v.fees.filter(_ => parseFloatOr(_.fee, null) !== null).length > 0)
      }
      else {
        return undefined;
      }
    })

    const openOrSoonOpenCompSeasonText = (v: ilportlet.DashboardRegistrationReport["openOrSoonOpenCompSeasons"][number]) : string => {
      const regStart = dayjs(v.registrationStart)
      if (!regStart.isValid()) {
        return `${v.seasonName} - ${v.competition}`
      }

      const now = dayjs()
      const regStartFormatted =  regStart.format(DAYJS_FORMAT_IL_FULL_1)

      if (now.isAfter(regStart)) {
        return `${v.competition} - ${v.seasonName} (opened ${regStartFormatted})`
      }
      else {
        return `${v.competition} - ${v.seasonName} (opens ${regStartFormatted})`
      }
    }

    const portletLabel = "Registration Overview"
    const portletIcon = "clipboard-list"

    return () => {
      if (localState.value.status === "unneeded-due-to-user-perms") {
        return null;
      }
      else if (localState.value.status === "loading") {
        return (
          <Portlet prefix="fas" icon={portletIcon} label={portletLabel}>
            <slot>
              <div class="p-2 flex gap-2 justify-center items-center">
                <SoccerBall width="1.25em" height="1.25em" color={Client.value.clientTheme.color}/>
                <span class="text-sm">Loading</span>
              </div>
            </slot>
          </Portlet>
        )
      }
      else if (localState.value.status === "resolved") {
        if (localState.value.data.stats.length === 0) {
          // This is probably a mismatch between our local permissions checks and backend permissions filtering.
          // Could also possibly be legitimately no data, even for a well permissioned user, but such a case is probably very rare?
          return null;
        }
        return (
          <Portlet prefix="fas" icon={portletIcon} label={portletLabel}>
            <slot>
              <div>
                <div class="px-2 pb-2 max-h-32 overflow-y-auto">
                  <div>Programs open (or soon to be open) for registration:</div>
                  <div class="text-sm">
                    {
                      localState.value.data.openOrSoonOpenCompSeasons.length === 0
                        ? <div>None</div>
                        : localState.value.data.openOrSoonOpenCompSeasons.map(v => {
                            return (
                              <div>
                                {
                                  (() => {
                                    if (R_AdminPage.hasSpecificRoutePermission({competitionUID: v.competitionUID})) {
                                      const route = R_AdminPage.routeDetailToRouteLocation("season-competition-admin.seasonal",
                                        {
                                          competitionUID: v.competitionUID,
                                          seasonUID: v.seasonUID
                                        });
                                      return <RouterLink class="il-link" to={route}>{openOrSoonOpenCompSeasonText(v)}</RouterLink>
                                    }
                                    else {
                                      return <span>{openOrSoonOpenCompSeasonText(v)}</span>
                                    }
                                  })()
                                }
                              </div>
                            )
                          })
                    }
                  </div>
                </div>
                <div class="border-b border-gray-200"/>
              </div>
              {/*TODO: max height, the "card" layout should be grid based ... all the cards should have the same height ... per row ...*/}
              <div class="p-2 overflow-y-auto h-full" style="max-height:40em;">
                <MenuTree menuDef={localState.value.data.menuDef} mut_selections={menuSelections.value}/>
                {
                  selectedReportEntry.value
                    ? (
                      <div style="display: grid; grid-template-columns: max-content 1fr">
                        <Separator/>

                        <div class="mr-2">Registration start:</div>
                        <div>{dayjsFormatOr(selectedReportEntry.value.registrationStart, "MMM/DD/YYYY hh:mm a")}</div>
                        <Separator/>

                        <div class="mr-2">Registration end:</div>
                        <div>{dayjsFormatOr(selectedReportEntry.value.registrationEnd, "MMM/DD/YYYY hh:mm a")}</div>
                        <Separator/>

                        <div class="mr-2">Waitlist date:</div>
                        <div>{dayjsFormatOr(selectedReportEntry.value.waitlistDate, "MMM/DD/YYYY", "N/A")}</div>
                        <Separator/>

                        <div class="mr-2">Waitlist style:</div>
                        <div>{waitlistIsFreeUiString(selectedReportEntry.value.waitlistIsFree)}</div>
                        <Separator/>

                        <div class="mr-2">
                          <div>Players registered:</div>
                          <div class="text-xs">not waitlisted</div>
                        </div>
                        <div>{selectedReportEntry.value.activeRegistrationCount}</div>
                        <Separator/>

                        <div class="mr-2">
                          <div>Players registered:</div>
                          <div class="text-xs">waitlisted</div>
                        </div>
                        <div>{selectedReportEntry.value.waitlistedRegistrationCount}</div>
                        <Separator/>

                        <VolunteerDetails label="Head coach volunteers" vs={selectedReportEntry.value.headCoachVolCountsBySeasonDiv}/>
                        <Separator/>

                        <VolunteerDetails label="Asst coach volunteers" vs={selectedReportEntry.value.asstCoachVolCountsBySeasonDiv}/>
                        <Separator/>

                        <VolunteerDetails label="Referee volunteers" vs={selectedReportEntry.value.refVolCountsBySeasonDiv}/>
                        <Separator/>

                        <FeeDetails vs={selectedFeeDetails.value ?? []}/>
                        <Separator/>

                        {/* <div style="grid-column: 1/3" class="text-xs">
                          <div>{selectedCompetitionUID.value}</div>
                          <div>{selectedSeasonUID.value}</div>
                          <pre>{JSON.stringify(localState.value.data.fees, null, 2)}</pre>
                        </div> */}

                        <div style="grid-column: 1/3" class="mt-2 text-xs">As of: {dayjsFormatOr(localState.value.data.generatedOn, "MMM/DD/YYYY hh:mm a")}</div>
                        <div style="grid-column: 1/3" class="mt-2 text-xs">Note: Volunteer assignments are by season+division, and are not linked to a particular program.</div>
                      </div>
                    )
                    : null
                }
              </div>
            </slot>
          </Portlet>
        );

        function Separator() { return <div style="grid-column: 1 / 3" class="border-b border-gray-200 my-1"/> }

        function VolunteerDetails({label, vs}: {label: string, vs: ilportlet.SeasonDivVolunteerCount}) {
          return vs.countsByDiv.length === 0
              ? (
                <>
                  <div class="mr-2">{label}:</div>
                  <div>None</div>
                </>
              )
              : (
                  <>
                    <div class="mr-2">{label}:</div>
                    <div>&nbsp;</div>
                    {
                      vs.countsByDiv.map(v =>
                        <>
                          <div class="mr-2">{v.displayName}</div>
                          <div>{v.count}</div>
                        </>
                      )
                    }
                    <div class="mr-2 font-medium">Total volunteer signups:</div>
                    <div>{arraySum(vs.countsByDiv.map(_ => parseIntOrFail(_.count)))}</div>
                    <div class="mr-2 font-medium">Unique volunteers across all signups:</div>
                    <div>{vs.uniqueRegistrants}</div>
                  </>
              )
        }

        function FeeDetails({vs}: {vs: ilportlet.DashboardRegistrationReportFeeInfo_One[]}) {
          return vs.length === 0
              ? (
                <>
                  <div class="mr-2">Registration fees:</div>
                  <div>No fee info was retrieved</div>
                </>
              )
              : (
                  <>
                    <div class="mr-2">Registration fees:</div>
                    <div>&nbsp;</div>
                    {
                      vs.map((v,i) => {
                        // `allFeesSame` also serves to prove that fees.length > 0
                        const allFeesSame = new Set(v.fees.map(feeInfo => dollarFormatOr(feeInfo.fee, "???"))).size === 1
                        const bgClass = i % 2 ? "bg-gray-100" : "";
                        return (
                          <>
                            <div class={[bgClass, "pr-2"]}>{v.displayName}</div>
                            <div class={[bgClass]}>
                              {
                                allFeesSame // if all fees are the same, just show it once
                                  ? <div>{dollarFormatOr(v.fees[0].fee, "???")}</div>
                                  : ( // each fee is potentially different, so show each (and the associated "this fee begins as of" date)
                                    v.fees.map(({fee, ifRegisteringOn}, i, a) =>
                                      <>
                                        <div>{dollarFormatOr(fee, "???")}</div>
                                        <div class="text-xs">Registration date {dayjsFormatOr(ifRegisteringOn, "MMM/DD/YY")}</div>
                                        {
                                          isLast(i, a)
                                            ? null
                                            : <Separator/>
                                        }
                                      </>
                                    )
                                  )
                              }
                            </div>
                          </>
                        )
                      }
                      )
                    }
                  </>
              );
          function isLast(i: number, a: unknown[]) {
            return i === a.length - 1;
          }
          function dollarFormatOr(v: number | string, fallback: string) : string {
            const r = parseFloatOr(v, undefined)?.toFixed(2);
            return r === undefined
              ? fallback
              : `$${r}`
          }
        }
      }
      else {
        exhaustiveCaseGuard(localState.value);
      }
    }
  }
})

function waitlistIsFreeUiString(v: iltypes.WaitlistIsFree_t["int"]) {
  switch (v) {
    case iltypes.WaitlistIsFree_t.WAITLIST_IS_FREE: return "Waitlist is free"
    case iltypes.WaitlistIsFree_t.WAITLIST_IS_NOT_FREE: return "Waitlist is not free"
    case iltypes.WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT: return "Waitlist requires tentative payment"
    default: exhaustiveCaseGuard(v)
  }
}
