import { type AxiosInstance } from "axios"
import { Datelike, Guid, Integerlike, Numbool, Season, SeasonTriple } from "src/interfaces/InleagueApiV1";

export interface GetRegistrationQuestionAnswersResponse {
  [playerID: Guid]:
    | {questionID: Guid, answer: string}[]
    | undefined // no-unchecked
}

export async function getRegistrationQuestionAnswers(ax: AxiosInstance, args: {seasonUID: Guid, playerIDs: Guid[]}) : Promise<GetRegistrationQuestionAnswersResponse> {
  const response = await ax.get(`v1/registration/questionAnswers`, {params: args})
  return response.data.data;
}

/**
 * API value for a "methodID" that represents "one time payment"
 */
export const defaultImplicitlyOneTimePaymentPaymentMethodID = -1;

export interface MaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData {
  playerFirstName: string,
  playerLastName: string,
  seasonName: string,
  perCompetitionRegistration: {
      competitionRegistrationID: Integerlike,
      canChoosePaymentMethods: boolean,
      hasVoidableInvoice: boolean,
      invoiceInstanceID: "" | Integerlike,
      competition: string,
      competitionID: Integerlike,
      competitionUID: Guid,
      currentPaymentMethodID: "" | Integerlike,
      paymentMethods: {
        methodID: Integerlike,
        label: string,
        totalAmount: number,
      }[]
  }[]
}

/**
 * Assumes that each of `(playerID, seasonUID, {...competitionUIDs})` represents an existing compreg
 */
export async function getMaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData(ax: AxiosInstance, args: {playerID: Guid, seasonUID: Guid, competitionUIDs: Guid[]}) : Promise<MaybeAdjustRegistrationInvoiceTemplatePaymentMethodsViewData> {
  const response = await ax.get(`v1/registrations/invoiceTemplatePaymentMethods`, {params: args})
  return response.data.data;
}

export type UpdateCompregPaymentSchedulesRequest = {
  paymentMethodID: "" | Integerlike,
  competitionRegistrationID: Integerlike
}[]

export async function updateCompregPaymentSchedules(ax: AxiosInstance, args: UpdateCompregPaymentSchedulesRequest) : Promise<void> {
  const response = await ax.post(`v1/registrations/invoiceTemplatePaymentMethods`, {each: args})
  return response.data.data;
}
