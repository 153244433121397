import type { AxiosInstance } from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Guid } from "src/interfaces/InleagueApiV1";

export interface CalculatedAgeAndDivisionInfo {
  calcAge: number,
  // The only time this would be empty is when speculative=true, and
  // even then, it should almost never be empty; it means we couldn't match this child to a division?
  //
  // NOTE: some endpoints may choose to not calculate a speculative division assignment if they are asked to
  // do so against large lists of players. See particular use sites for details. In such cases this list
  // will likely be empty.
  //
  // It may contain multiple divisions if the player has multiple registrations for some season.
  //
  divisions: iltypes.Division[],
  speculative: boolean
}

export async function getCalcAgeAndPossiblySpeculativeDivisionsForSomeChildSeason(
  axios: AxiosInstance,
  args: {childID: iltypes.Guid, seasonUID: iltypes.Guid}
) : Promise<CalculatedAgeAndDivisionInfo> {
  const response = await axios.get(`v1/child/${args.childID}/calcAgeAndDivisionsSomeSeason`, {params: {seasonUID: args.seasonUID}});
  return response.data.data;
}

export type RegisteredSeasonsForChildrenResponse = {
  [childID: Guid]: undefined | {
    seasonUID: Guid,
    seasonName: string,
    registrationID: Guid,
  }[]
}

export async function getRegisteredSeasonsForChildren(axios: AxiosInstance, args: {childIDs: Guid[]}) : Promise<RegisteredSeasonsForChildrenResponse> {
  const response = await axios.get(`v1/children/registeredSeasons`, {params: {childIDs: args.childIDs.join(",")}});
  return response.data.data;
}

/**
 * Add a birth certificate to some child.
 * If the target child already has a birth certificate:
 *  - if the cert is already validated, an error is returned and no changes are persisted on the server
 *  - if the cert is not yet validated,
 */
export async function addBirthCertificateToChild(
  axios: AxiosInstance,
  args: { childID: Guid; data: Blob }
): Promise<void> {
  const options = new FormData()
  options.append('uploadDocument', args.data)
  await axios.post(`/v1/child/${args.childID}/birthCertificate`, options)
}

export async function validateBirthCertificate(
  axios: AxiosInstance,
  args: { childID: Guid }
): Promise<void> {
  await axios.post(`/v1/child/${args.childID}/birthCertificate/validate`)
}

export async function deleteBirthCertificate(
  axios: AxiosInstance,
  args: { childID: Guid }
): Promise<void> {
  await axios.delete(`/v1/child/${args.childID}/birthCertificate`)
}
