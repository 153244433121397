<template lang="pug">
div
  h1.mt-4.text-center My Referee Assignments
  .flex.justify-center.w-full
    .q-pa-md.quasar-style-wrap.mt-8(
      v-if='games.length',
      data-cy='fieldKeys',
    )
      q-table(
        :rows='keys',
        :columns='keyColumns',
        row-key='fieldID',
        table-class='content',
        @row-click='toMap',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-fieldID='props')
          q-td(:auto-width='true')
            div {{ props.row.fieldAbbrev }}
        template(v-slot:body-cell-fieldName='props')
          q-td.row.full-width(:props='props')
            .col-auto.q-pr-sm
              font-awesome-icon(:icon='["fas", "map-marked-alt"]')
            .col {{ props.row.fieldName }}

  .flex.justify-center.w-full
    .quasar-style-wrap.mt-8(
      v-if='games.length',
      data-cy='userRefAssignmentsTable',
    )
      .q-pa-md
        q-table(
          :rows='games',
          :columns='columns',
          row-key='gameID',
          :rows-per-page-options='[0]',
          hide-pagination,
          dense
        )
          template(v-slot:body-cell-gameStart='props')
            q-td(:props='props')
              .row
                .col-xs-12 {{ formatDateAsNums(props.value) }}
                .col-xs-12 {{ formatTime(props.value) }}
    div(v-else-if='isDataLoaded', data-cy='noUserRefs')
      h4.text-center.italic.mt-2 Sorry, no games matched your query
</template>

<script lang="ts">
import { RefDetails, RefFieldDetails } from 'src/interfaces/InleagueApiV1'


import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { defineComponent, Ref, ref, computed, onMounted } from 'vue'
import { axiosInstance } from 'src/boot/axios'
import { User } from "src/store/User"

export default defineComponent({
  name: 'refAssignments',
  setup() {
    const games = ref([]) as Ref<RefDetails[]>
    const keys = ref([]) as Ref<RefFieldDetails[]>
    const isDataLoaded = ref(false)

    const columns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (game: RefDetails) => game.game.field.fieldAbbrev,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px;',
        headerStyle: 'font-size: 16px',
      },
      {
        name: 'gameStart',
        required: false,
        label: 'Date',
        align: 'left',
        sortable: true,
        field: (game: RefDetails) => {
          return game.game.gameStart
        },
        style: 'vertical-align: top; font-size: 16px;',
        headerStyle: 'font-size: 16px',
      },
      {
        name: 'Teams',
        align: 'center',
        label: 'Teams',
        field: (game: RefDetails) => {
          return game.game.teamVersus
        },
        style: 'vertical-align: top; font-size: 16px;',
        headerStyle: 'font-size: 16px',
        sortable: true,
      },
    ])

    const keyColumns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefFieldDetails) => ref.fieldAbbrev,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        sortable: true,
        field: (ref: RefFieldDetails) => {
          return ref.fieldName
        },
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
    ])



    const userAddress = computed(() => {
      return User.value.userAddress
    })

    const createKeys = () => {
      // console.log('in createKeys')
      const gameKeys: { [key: number]: RefFieldDetails } = {}
      for (let i = 0; i < games.value.length; i++) {
        // console.log('ran for loop')
        const game = games.value[i].game
        gameKeys[game.fieldID] = game.field
      }
      keys.value = []
      for (const key in gameKeys) {
        keys.value.push(gameKeys[key])
      }
    }

    const getGames = async () => {
      try {
        const response = await axiosInstance.get('v1/refereeAssignments')
        games.value = response.data.data as RefDetails[]
        createKeys()
        isDataLoaded.value = true
      } catch (err) {
        // console.log('caught an error', err)
      }
    }

    const toMap = (evt: Event, row: RefFieldDetails) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldState} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }

    onMounted(async () => {
      await getGames()
    })

    return {
      games,
      keys,
      isDataLoaded,
      columns,
      keyColumns,
      getGames,
      createKeys,
      toMap,
      userAddress,
      formatDateAsNums,
      formatTime,
    }
  },
})
</script>
