import { uid } from 'quasar'

export default {

    logEvent (category, action, label, value) {
      // The data layer is an object used by Google Tag Manager and gtag.js to pass
      // information to tags. Events or variables can be passed via the data layer,
      // and triggers can be set up based on the values of variables.

      //the issue is that we may have window but window has no dataLayer applied
        dataLayer.push({
          'event': 'customEvent',
          'category': category,
          'action': action,
          'label': label,
          'value': value,
          'cid': this.getCid()
        });
    },

    logPage (path) {
        // Here you can preprocess the path, if needed
        dataLayer.push({
          'event': 'customPageView',
          'path': path,
          'cid': this.getCid()
        });

    },

    getCid () {
        // We need an unique identifier for this session
        // We store it in a localStorage, but you may use cookies, too
        if (!localStorage.cid) {
            localStorage.cid = uid();
        }
        return localStorage.cid;
    },

}