<template lang="pug">
div(:data-test="`entityEventSignup/eventID=${event.eventID}/entityID=${entity.id}`")
  //- ConfirmEventSignupCancellationModal(
  //-   v-if="confirmEventSignupCancellationModalController.visible"
  //-   v-bind="confirmEventSignupCancellationModalController.props"
  //-   v-on="confirmEventSignupCancellationModalController.handlers"
  //- )
  .mt-8.flex.items-start.grid.grid-cols-8.gap-x-4.py-2(
    data-cy='registrationEntity',
    class='sm:mt-0 sm:border-t sm:border-gray-200 md:items-center'
  )
    .col-span-2.flex.justify-start.text-sm.mt-2(
      class='md:mt-0 md:text-lg, md:col-span-2'
    )
      p(v-if='!entity.isEligible' data-test="not-eligible") Not eligible

      //-
      //- has no existing pending or active signup; this is their first signup
      //- (or, at least the first since their last cancelation for this event)
      //-
      template(v-else-if="!entity.isEnrolled")
        //- inline style shouldn't be necessary but it seemed like something was overriding a tailwind m-0 class
        //- we want this vertically centered
        t-btn(
          type="button"
          style="margin:0 !important;"
          v-if='!selected'
          @click = "handleDoSignup"
          data-test="do-signup"
        )
          div Signup
        div(v-else)
          div Signing up...
          span.text-xs.underline.text-blue-700.cursor-pointer(
            data-test="cancel-tentative-signup"
            class="hover:bg-gray-100 active:bg-gray-300 py-1 pr-1"
            @click="cancelTentativeSignup"
          )
            | undo

      //-
      //- has existing pending signup that does not yet have an invoice and the relevant fee is non-zero
      //-
      template(v-else-if="maybeExistingSignup && !maybeExistingSignup.paid && !maybeExistingSignup.lineItemID && ((!entity.isChild && maybeExistingSignup.feeUser) || (entity.isChild && maybeExistingSignup.feePlayer))")
        div
          div Edit existing pending sign up...
          span.text-xs.underline.text-blue-700.cursor-pointer(
            data-test='cancel-pending-signup'
            class="hover:bg-gray-100 active:bg-gray-300 py-1 pr-1"
            @click="cancelPendingOrZeroFeeSignup"
          )
            | cancel this signup

      //-
      //- has existing pending signup that already has an invoice, but that invoice is not yet paid
      //- (could payment be in-flight here? e.g. paid but {gateway,stripe} webhook hasn't hit yet?)
      //-
      template(
        v-else-if='maybeExistingSignup && !maybeExistingSignup.paid && maybeExistingSignup.lineItemID'
        data-test='has-existing-pending-signup-with-non-paid-invoice'
      )
        div
          div Edit existing pending signup...
          div.border-2.border-dashed.border-gray-300.mt-1.p-1
            span.text-xs There is an existing pending transaction for this, which you can
            span.text-xs.underline.text-blue-700.cursor-pointer(
              class="hover:bg-gray-100 active:bg-gray-300 p-1"
              @click="voidInvoice"
              data-test="void-invoice"
            )
              | void

      //-
      //- has a pending or active signup that has no fee
      //-
      ul(
        v-else-if='maybeExistingSignup && entity.isEnrolled && !maybeExistingSignup.fee',
      )
        li Enrolled
        li.text-red-600.text-xs.cursor-pointer(
          data-test='cancel-existing-zero-fee-signup'
          class="hover:bg-gray-100"
          @click='cancelPendingOrZeroFeeSignup()',
        ) (Cancel Enrollment)

      //-
      //- already has a fully paid eventSignup
      //- there's no direct way to cancel this for a typical user, since they have to request a refund and users cannot give themselves refunds
      //-
      ul(v-else-if='maybeExistingSignup && entity.isEnrolled')
        li Enrolled
        a.text-red-600.text-xs(
          :href='emailLink',
          data-test='cancel-active-signup'
        ) (Request Unenrollment)

    //-
    //- user name / division
    //-
    .col-span-4.grid.grid-cols-2(class='md:col-span-5')
      .font-medium.text-lg.col-span-2(class='md:col-span-1') {{ entity.firstName }} {{ entity.lastName }}
      .font-normal.text-sm.col-span-2(v-if='entity.divisionName', class='md:col-span-1') ({{ entity.divisionName }})

    p.col-span-1.text-right(class='md:col-span-1 md:text-left') {{ uiCost }}
    .col-span-1.hidden(class='md:flex')
      |
    .col-span-7(v-if='selected', class='md:col-span-5 md:col-start-3')
      FormKit(type="group" :name="entity.id")
        template(v-if="formkitEventQuestionsSchema.length > 0")
          FormKitSchema(
            :schema="formkitEventQuestionsSchema"
          )
        FormKit(type="textarea" name="comments" label="Comments" maxlength=1000)
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  getCurrentInstance,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'


import { emitsDef, propsDef, } from './EntityEventSignup.ilx'

import C_ConfirmEventSignupCancellationModal from "./ConfirmEventSignupCancellationModal.vue"
import * as M_ConfirmEventSignupCancellationModal from "./ConfirmEventSignupCancellationModal.ilx"
import { useIziToast } from 'src/helpers/utils'

export default defineComponent({
  name: 'EntityEventSignup',
  components: {
    ConfirmEventSignupCancellationModal: C_ConfirmEventSignupCancellationModal
  },
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    const selected = ref(false)
    const checked = ref(true)
    const eligible = ref(true)
    const uiCost = computed(() => {
      props.event
      if (props.entity.isChild) {
        if (props.event.feePlayer) {
          return props.event.feePlayer.toFixed(2)
        } else {
          return "FREE"
        }
      } else {
        if (props.event.feeUser) {
          return "$" + props.event.feeUser.toFixed(2);
        } else {
          return "FREE";
        }
      }
    })

    const emailLink = ref('')


    const router = useRouter()
    const route = useRoute()
    const localInstance = getCurrentInstance()

    // @rmme
    // const cancelEventSignup = async () => {
    //   try {
    //     const cancelEventSignup = await axiosInstance.delete(
    //       `/v1/event/${route.params.eventID}/signup?${
    //         props.entity.isChild ? 'childid' : 'userid'
    //       }=${props.entity.id}`
    //     )
    //     if (cancelEventSignup.status === 200) {
    //       emit('enrollmentCanceled', {entity: props.entity})

    //       checked.value = false
    //       selected.value = false

    //       localInstance?.appContext.config.globalProperties.$toast.info({
    //         message: `Pending signup for ${props.entity.firstName} ${props.entity.lastName} canceled.`,
    //       })
    //     }
    //     selected.value = false
    //   } catch (err) {
    //     AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    //   }
    // }

    const voidInvoice = async () => {
      await router.push({
        name: 'confirm-invoice-void',
        params: {
          invoiceID: props.maybeExistingSignup?.invoiceInstanceID as string,
        },
      })
    }

    // @rmme
    // const emitSelected = async (event: any) => {
    //   const isSelected = !selected.value;
    //   selected.value = isSelected;
    //   if (
    //     !isSelected &&
    //     props.entity.isEnrolled &&
    //     !props.maybeExistingSignup?.paid &&
    //     !props.maybeExistingSignup?.invoiceNo
    //   ) {
    //     await cancelEventSignup()
    //   }
    //   emit('selected', {
    //     entity: props.entity,
    //     isSelected: event.target.checked,
    //   })
    // }

    // @rmme
    // const emitBlur = (event: any) => {
    //   emit('blur', event.target.checked)
    // }

    // @rmme
    // const confirmEventSignupCancellationModalController = ref(M_ConfirmEventSignupCancellationModal.DefaultController({
    //   handlers: {
    //     confirmCancellation: async () => {
    //       try {
    //         await cancelPendingOrZeroFeeSignup();
    //         confirmEventSignupCancellationModalController.value.hide();
    //       }
    //       catch (err) {
    //         AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    //       }
    //     },
    //     doNotCancel: () => {
    //       // being in the modal implies `selected.value === true`,
    //       // but sometimes we use a checkbox click event to trigger the modal, which means we need to undo
    //       // the click by setting it back to true.
    //       selected.value = true;
    //       confirmEventSignupCancellationModalController.value.hide();
    //     }
    //   }
    // }))

    onMounted(async () => {
      const subject = `Cancellation request for ${props.entity.firstName} ${props.entity.lastName} for ${props.event.eventName}`
      emailLink.value = `mailto:${
        props.event.contactEmail
      }?subject=${encodeURI(subject)}`
      if (
        props.entity.isEnrolled &&
        !props.maybeExistingSignup?.paid &&
        !props.maybeExistingSignup?.invoiceNo
      ) {
        selected.value = true
      }
    })

    const handleDoSignup = () => {
      selected.value = true;
      emit('selected', {
        entity: props.entity,
        isSelected: true
      })
    }

    /**
     * a tentative signup is a signup that "exists" only locally; it has not been pushed to the server yet
     */
    const cancelTentativeSignup = () => {
      selected.value = false;
      emit('selected', {
        entity: props.entity,
        isSelected: false
      })
    }

    const iziToast = useIziToast();

    /**
     * a pending signup is a signup exists on the server, and this is neither canceled nor active.
     * It may or may not have an existing invoice, but a pending signup with an invoice should not be canceled in this way
     * (should have its invoice voided, and then cancel the signup).
     *
     * A "zero fee" signup is, by virtue of requiring no payment, always active (and so cannot be considered pending)
     */
    const cancelPendingOrZeroFeeSignup = async () => {
      await axiosInstance.delete(
        `/v1/event/${route.params.eventID}/signup?${
          props.entity.isChild ? 'childid' : 'userid'
        }=${props.entity.id}`
      )

      emit('enrollmentCanceled', {entity: props.entity})

      checked.value = false
      selected.value = false

      iziToast.info({
        message: `Signup for ${props.entity.firstName} ${props.entity.lastName} canceled.`,
      })

      selected.value = false
    }


    return {
      selected,
      checked,
      eligible,
      uiCost,
      emailLink,
      router,
      route,
      localInstance,
      // @rmme cancelEventSignup,
      voidInvoice,
      // @rmme emitSelected,
      // @rmme emitBlur,
      // @rmme confirmEventSignupCancellationModalController,
      handleDoSignup,
      cancelTentativeSignup,
      cancelPendingOrZeroFeeSignup,
    }
  },
})
</script>
