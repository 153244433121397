/**
 * things that are essentially "customQuestions.ts", but that file has deps that cannot be imported into tests
 */

import * as iltypes from "src/interfaces/InleagueApiV1"

export type ClientSideRegistrationQuestionAnswerMap = {[question: iltypes.Guid]: string[] | string | number | boolean | null}

/**
 * @param answersAsOfLastReceiptPriorToAnyMutation -- if provided, will be used to check if answers were changed or not, in order to
 * to produce a more accurate result, i.e. one that DOES NOT include a mapping for a (question->answer) if that question answer didn't change.
 * Currently "didn't change" means only "started with no answer, and continues to have no answer"
 */
export function answerMappingToApiV1SubmittableAnswerMapping(
  answersByQuestionID: Readonly<ClientSideRegistrationQuestionAnswerMap>,
  answersAsOfLastReceiptPriorToAnyMutation?: iltypes.RegistrationAnswer[]
) : {[questionID: string]: string | number | boolean} {
  const pristineAnswerLookup = answersAsOfLastReceiptPriorToAnyMutation
    ? new Map(answersAsOfLastReceiptPriorToAnyMutation.map(a => [a.questionID, a]))
    : undefined;
  const shouldCheckNullishnessAgainstOldAnswers = !!pristineAnswerLookup

  const result : {[questionID: string]: string | number | boolean} = {}
  for (const questionID of Object.keys(answersByQuestionID)) {
    const value = answersByQuestionID[questionID]
    if (value === null || value === undefined) {
      continue;
    }
    else if (Array.isArray(value)) {
      const asList = value.join(",")
      // roundtripping "null" ("no answer, was never interacted with") takes some additional work here:
      // -- initially null, but, in the checkbox case, null will be converted to `[]` by FormKit
      // -- so, if if there was no answer to start with, and the answer is currently `[]`, then it is "effectively null"
      if (shouldCheckNullishnessAgainstOldAnswers && asList === "" && !pristineAnswerLookup.get(questionID)) {
        continue;
      }
      else {
        result[questionID] = asList;
      }
    }
    else {
      result[questionID] = value;
    }
  }
  return result;
}
