import { boot } from 'quasar/wrappers'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'
import {IziToastPosition} from 'izitoast/types/index'

const defaultOptionsObject = {
  imageWidth: 50,
  layout: 1,
  balloon: false,
  close: true,
  closeOnEscape: false,
  closeOnClick: false,
  displayMode: 0,
  targetFirst: true,
  timeout: 5000,
  rtl: false,
  animateInside: true,
  drag: true,
  pauseOnHover: true,
  resetOnHover: false,
  progressBar: false,
  overlay: false,
  overlayClose: false,
  messageSize: '16px',
  position: 'center' as IziToastPosition,
  zindex: 999999
}
iziToast.settings(defaultOptionsObject)
const iziToastAlias = iziToast
export default iziToastAlias
