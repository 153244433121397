import { axiosAuthBackgroundInstance, axiosInstance } from 'src/boot/axios'
import { dayjsFormatOr } from 'src/helpers/formatDate'
import { ComponentPublicInstance, computed, defineComponent, onMounted, PropType, ref } from 'vue'
import * as iltypes from "src/interfaces/InleagueApiV1"

import * as ilgame from "src/composables/InleagueApiV1.Game"
import { NavigationGuardNext, RouteLocationNormalized, useRouter } from 'vue-router'
import { Modal, Slots } from '../UserInterface/Modal'
import * as RouterScrollBehavior from "src/router/RouterScrollBehavior"
import { SoccerBall } from '../SVGs'

import { AxiosInstance } from 'axios'
import * as EventuallyPinia from "src/store/EventuallyPinia"
import { Client } from 'src/store/Client'

import * as ClearOnLogout from "src/store/ClearOnLogout"

const MatchReportResolved = defineComponent({
  name: 'MatchReportResolved',
  props: {
    matchReport: {
      required: true,
      type: Object as PropType<ilgame.MatchReport>
    }
  },
  setup(props) {
    const halftimeScoreIsTBD = computed(() => props.matchReport.visitorTeam.halftimeScore === "" || props.matchReport.homeTeam.halftimeScore === "")
    const finalScoreIsTBD = computed(() => props.matchReport.visitorTeam.score === "" || props.matchReport.homeTeam.score === "")

    const possiblyTBDScores = {
      home: {
        halftime: computed<string>(() => halftimeScoreIsTBD.value ? "TBD" : props.matchReport.homeTeam.halftimeScore.toString()),
        final: computed<string>(() => finalScoreIsTBD.value ? "TBD" : props.matchReport.homeTeam.score.toString()),
      },
      visitor: {
        halftime: computed<string>(() => halftimeScoreIsTBD.value ? "TBD" : props.matchReport.visitorTeam.halftimeScore.toString()),
        final: computed<string>(() => finalScoreIsTBD.value ? "TBD" : props.matchReport.visitorTeam.score.toString()),
      }
    }

    const RowSeparator = function() {
      return <div class="border-b border-slate-200 border-dashed my-1 sm:col-span-2"/>
    }

    return () => (
      <div class="bg-white overflow-hidden sm:rounded-lg">
        <div>
          <div class="text-lg leading-6 font-medium text-gray-900">Match Report</div>
          <div class="border-b my-1 border-slate-200"/>
        </div>
        <div>
          <div class="sm:grid sm:grid-cols-[min-content_1fr]">
            <div class="font-medium text-gray-500 mr-4">Field</div>
            <div>{ props.matchReport.fieldName || "TBD" }</div>
            <RowSeparator/>

            <div class="font-medium text-gray-500 mr-4">Date</div>
            <div>{ dayjsFormatOr(props.matchReport.gameStart, "MMM/DD/YYYY @ h:mm a") }</div>
            <RowSeparator/>


            <div class="font-medium text-gray-500 mr-4">Teams</div>
            <div>{ props.matchReport.homeTeam.name || "TBD" } v. { props.matchReport.visitorTeam.name || "TBD" }</div>
            <RowSeparator/>

            {
              props.matchReport.referees.map(ref => {
                return (
                  <>
                    <div class="font-medium text-gray-500 mr-4">
                      <div>{ ref.posName }</div>
                    </div>
                    <div>
                      {
                        ref.detail
                          ? (
                            <div>
                              <div>{ref.detail.firstName} {ref.detail.lastName}</div>
                              <div class="font-light text-sm">{ref.detail.email}</div>
                            </div>
                          )
                          : <div>Unassigned</div>
                      }
                    </div>
                    <RowSeparator/>
                  </>
                )
              })
            }

            <div class="font-medium text-gray-500 mr-4">Final Score</div>
            <div>
              <div>{props.matchReport.homeTeam.name || "TBD"}: {possiblyTBDScores.home.final.value}</div>
              <div>{props.matchReport.visitorTeam.name || "TBD"}: {possiblyTBDScores.visitor.final.value}</div>
            </div>
            <RowSeparator/>


            <div class="font-medium text-gray-500 mr-4">Halftime Score</div>
            <div>
              <div>{props.matchReport.homeTeam.name || "TBD"}: {possiblyTBDScores.home.halftime.value}</div>
              <div>{props.matchReport.visitorTeam.name || "TBD"}: {possiblyTBDScores.visitor.halftime.value}</div>
            </div>
            <RowSeparator/>

            <div class="font-medium text-gray-500 mr-4">Cards</div>
            <div>
              {
                props.matchReport.cards.length > 0
                  ? (
                    <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {
                        props.matchReport.cards.map(card => {
                          return (
                            <li>
                              <div>
                                <div>{ card.label }</div>
                                <div>
                                  <span class="semibold">{card.teamName}</span>
                                  <span class="italic">{card.playerFirstName} {card.playerLastName}</span>
                                </div>
                              </div>
                              <div>
                                <div class="font-medium text-green-600 hover:text-indigo-500">{card.pointValue}</div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  )
                  : <div>N/A</div>
              }
            </div>
            <RowSeparator/>

            <div class="font-medium text-gray-500 mr-4">Comments</div>
            <div>{ props.matchReport.comment || "N/A" }</div>
          </div>
        </div>
      </div>
    );
  },
})

interface OnMountWeArrivedFrom {
  arrivedFromRouteName: string | undefined | null | symbol
}

interface NavAction {
  shouldForceReplace: boolean
}

const histStackByComponent = new Map<ComponentPublicInstance, OnMountWeArrivedFrom[]>();

function pushRouterCameFromHistoryForComponent(vueInstance: ComponentPublicInstance, histEntry: OnMountWeArrivedFrom) {
  const histStack = histStackByComponent.get(vueInstance) ?? []
  histStack.push(histEntry);
}

function popComponentNavFrameAndComputeNavAction(vueInstance: ComponentPublicInstance, to: RouteLocationNormalized) : NavAction {
  const stack = histStackByComponent.get(vueInstance);
  const histEntry = stack?.pop();

  if (stack?.length === 0) {
    histStackByComponent.delete(vueInstance);
  }

  if (!histEntry) {
    return {
      shouldForceReplace: false
    }
  }
  else {
    return {
      shouldForceReplace: histEntry.arrivedFromRouteName === to.name
    }
  }
};

const ephemeralMatchReportCache = (() => {
  let storage : {[gameID: iltypes.Guid]: ilgame.MatchReport} = {};

  const get = async(axios: AxiosInstance, gameID: iltypes.Guid) => {
    const existing = storage[gameID];
    if (existing) {
      return existing;
    }
    else {
      const fresh = await ilgame.getMatchReport(axios, {gameID});
      storage[gameID] = fresh;
      return fresh;
    }
  }

  const clear = () => { storage = {} }

  return {
    get,
    clear
  }
})();

ClearOnLogout.register(ephemeralMatchReportCache);

/**
 * Child route entry point for the match report modal.
 * This probably doesn't really need to be a whole route. Knowing how to make it a route seems valuable though.
 */
const R_MatchReportModal = defineComponent({
  name: 'R_MatchReportModal',
  beforeRouteEnter(to, from, next) {
    const fromName = from.name;
    next(vueInstance => pushRouterCameFromHistoryForComponent(vueInstance, {arrivedFromRouteName: fromName}));
  },
  beforeRouteLeave(to, from, next) {
    const navAction = popComponentNavFrameAndComputeNavAction(this, to);
    if (navAction.shouldForceReplace) {
      next({...to, replace: true});
    }
    else {
      next()
    }
  },
  props: {
    gameID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    }
  },
  setup(props) {
    const router = useRouter();


    const isOpen = ref(true);
    const matchReport = ref<ilgame.MatchReport | null>(null)

    onMounted(async () => {
      matchReport.value = await ephemeralMatchReportCache.get(axiosAuthBackgroundInstance, props.gameID);
    })

    const slots : Slots = {
      content: () => (
        matchReport.value
          ? <MatchReportResolved matchReport={matchReport.value} />
          : (
            <div class="flex items-center justify-center relative">
              <div style="position:absolute;left:0;" class="flex justify-center items-center">
                <SoccerBall color={Client.value.clientTheme.color} height=".375in" width=".375in" timeForOneRotation="1.8s"/>
              </div>
              <div class="ml-2">Loading match report</div>
            </div>
          )
      )
    }

    /**
     * We expect to be mounted as a router view, and navigating away is a hard "unmount ourself" action,
     * which means leave animations won't play. This needs to be invoked in response to "close animations have completed",
     * rather than in response to "user clicked close".
     */
    const finalizeClose = () => {
      RouterScrollBehavior.withNoScroll(async () => {
        await router.replace({name: "referee-scheduler"})
      });
    }

    return () => (
        <Modal
          data-test="R_MatchReport"
          isOpen={isOpen.value}
          onClose={() => {
            isOpen.value = false;
          }}
          onOnAfterLeave={() => finalizeClose()}
        >
          {{...slots}}
        </Modal>
    )
  },
})

// for use w/ vue router
export default R_MatchReportModal
