import { computed, defineComponent, onMounted, ref } from "vue";
import { UiOption, sortBy } from "src/helpers/utils";
import { FormKit } from "@formkit/vue";
import dayjs from "dayjs";

import * as iltypes from "src/interfaces/InleagueApiV1"

import { TournamentHostingManagerImpl } from "./TournamentHostingManager.misc"
import authService from "src/helpers/authService";
import { User } from "src/store/User";
import { getCompetitionsOrFail } from "src/store/Competitions"
import { Client } from "src/store/Client";

export default defineComponent({
  setup() {


    type AsyncResolved =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly seasons: iltypes.Season[],
        readonly competitions: iltypes.Competition[]
      }

    const asyncResolver = ref<AsyncResolved>({ready: false})
    const selectedSeasonUID = ref("");
    const selectedCompetitionUID = ref("");

    const seasonOptions = computed<UiOption[]>(() => {
      if (!asyncResolver.value.ready) {
        return [];
      }
      return [
        {label: "", value: ""},
        ...asyncResolver.value.seasons.map(season => {
          return {
            label: season.seasonName,
            value: season.seasonUID,
          }
        })
      ]
    })

    const competitionOptions = computed<{disabled: boolean, options: UiOption[]}>(() => {
      if (!asyncResolver.value.ready) {
        return {disabled: false, options: []} // won't be visible anyway
      }
      if (asyncResolver.value.competitions.length === 0) {
        return {
          disabled: true,
          options: [{label: "No available programs", value: ""}]
        }
      }
      return {
        disabled: false,
        options: [
          {label: "", value: ""},
          ...asyncResolver.value.competitions.map(comp => {
            return {
              label: comp.competition,
              value: comp.competitionUID,
            }
          })
        ]
      }
    })

    onMounted(async () => {
      // FIXME: endpoint for this specifically for this component, or better local api using existing remote api or something
      // there's no getSeasons() like getCompetitions()...?
      const seasons = await (async () => {
        const result : iltypes.Season[] = []
        const seasonsMap = await Client.getSeasonsMap();
        for (const seasonUID of Object.keys(seasonsMap)) {
          result.push(seasonsMap[seasonUID])
        }
        const year = dayjs().year();
        return result.sort(sortBy(_ => _.seasonID, "desc"))
      })();

      const competitions = await (async () => {
        const comps = [...(await getCompetitionsOrFail()).value].sort(sortBy(_ => _.competitionID, "asc"));
        if (!authService(User.value.roles, "registrar")) {
          if (typeof User.value.userData !== "object") {
            // not logged in? shouldn't happen
            return [];
          }
          const userManagesTheseComps = new Set<iltypes.Guid>(
            User
              .value
              .userData
              .competitionsMemento
              .map(v => v.competitionUID)
          );
          return comps.filter(comp => userManagesTheseComps.has(comp.competitionUID));
        }
        else {
          return comps;
        }
      })();

      selectedCompetitionUID.value = (competitions.length > 0 ? competitions[0].competitionUID : undefined) // "core program" (by virtue of sort)
        ?? ""; // or nothing ('nothing' shouldn't happen though)

      selectedSeasonUID.value = seasons
        .find(v => v.seasonUID === Client.value.instanceConfig.currentseasonuid)?.seasonUID // "current season"
        ?? (seasons.length > 0 ? seasons[0].seasonUID : undefined) // "the most recent season" by virtue of sort
        ?? ""; // shouldn't happen, but don't crash

      asyncResolver.value = {
        ready: true,
        seasons,
        competitions
      }
    })

    return () => {
      if (!asyncResolver.value.ready) {
        return null;
      }
      return (
        <div data-test="R_TournamentHostingManager">
          <FormKit type="form" actions={false}>
            <FormKit type="select" label="Program" disabled={competitionOptions.value.disabled} options={competitionOptions.value.options} v-model={selectedCompetitionUID.value} data-test="competitionUID"/>
            <FormKit type="select" label="Season" options={seasonOptions.value} v-model={selectedSeasonUID.value} data-test="seasonUID"/>
          </FormKit>

          {
            selectedSeasonUID.value && selectedCompetitionUID.value
              ? <TournamentHostingManagerImpl
                  seasonUID={selectedSeasonUID.value}
                  competitionUID={selectedCompetitionUID.value}
                  key={`${selectedSeasonUID.value}/${selectedCompetitionUID.value}`}
                />
              : null
          }
        </div>
      )
    }
  }
})
