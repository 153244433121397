import { defineComponent } from "vue";
import { GoogleLogo } from "./SVGs"


import * as iltypes from "src/interfaces/InleagueApiV1"
import { buildGoogleLoginOauthURL } from "src/composables/InleagueApiV1.Authenticate";
import { System } from "src/store/System";
import { Client } from "src/store/Client";

export const SigninWithGoogle = defineComponent({
  setup() {


    const clientID_is_definitely_selected = (process.env.MODE === "spa") // non-mobile build means we are focused on some web domain which is exactly for a singular league
      || (process.env.MODE === "capacitor" && !!System.value.clientUrl); // see vuex client store actions for details of why this means what it does

    const clientID_for_oauthPurposes : iltypes.Guid | "?" = clientID_is_definitely_selected
      ? Client.value.instanceConfig.clientid
      : "?"; // there's not really backend support for this but it seems like something we will want to do ?....

    const googleOauthURL = buildGoogleLoginOauthURL(
      clientID_for_oauthPurposes,
      window.location.host
    );

    //
    // We use the web's oauth endpoint, which google blocks from android webviews, so we disable this Android.
    // Seems to still work on iOS devices -- but, we need to figure out how to jump from "on success, redirect with some url back into the app from a webview"
    //
    const isEnabled = process.env.MODE === "spa";

    return () => (
      isEnabled
        ? (
          <div>
            <form action={googleOauthURL} method="post" data-test="login-with-google">
              <button
                type="submit"
                class="text-gray-800 flex items-center border border-slate-300 bg-white hover:bg-gray-100 active:bg-gray-200 cursor-pointer rounded-sm py-1 px-2 transition-colors"
                style="transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);"
              >
                <GoogleLogo/>
                <div class="ml-2">Continue with Google</div>
              </button>
            </form>
          </div>
        ) : <div></div>
    )
  }
})
