import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { GetActiveOrRegSeasonVolunteerPrefsResponse } from "src/composables/InleagueApiV1.User";
import { useWindowSize, vReqT } from "src/helpers/utils";
import { defineComponent } from "vue";
import { RouterLink } from "vue-router";
import * as R_VolunteerRegistrationFlow from "src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route"
import { faFolderOpen } from "@fortawesome/pro-regular-svg-icons";
import { authZ_editVolunteerPrefs } from "./R_UserEditor.route";

export const VolunteerPrefsImpl = defineComponent({
  name: "VolunteerPrefsImpl",
  props: {
    data: vReqT<GetActiveOrRegSeasonVolunteerPrefsResponse>(),
  },
  setup(props) {
    const windowSize = useWindowSize();
    return () => {
      return (
        <div data-test="VolunteerPrefs">
          <div style="display: grid; grid-template-columns: min-content 1fr;">
            <div style="margin-top: 1px;" class="px-2">
              <FontAwesomeIcon icon={faFolderOpen} size="lg"/>
            </div>
            <h2>{props.data.user.firstName} {props.data.user.lastName}: Volunteer Preferences</h2>
            <div>{/*grid cell placeholder*/}</div>
            <div class="ml-1 pl-2 border-l border-gray-300">
              <div>Risk status - {props.data.user.riskStatus || "None"} -- Expires {props.data.user.formattedRiskStatusExpiration || "None"}</div>
              <div></div>
            </div>
          </div>
          {
            props.data.volunteerPrefsSeasonalViewData.length === 0
              ? (
                <div class="flex items-center justify-center m-4">
                  Nothing found.
                </div>
              )
              : null
          }
          {
            props.data.volunteerPrefsSeasonalViewData.map((seasonalPrefs) => {
              return (
                <div class="rounded-md border shadow-md my-6 bg-white" data-test={`seasonUID=${seasonalPrefs.seasonUID}`}>
                  <div class="flex gap-2 mb-1 bg-gray-300 rounded-t-md font-semibold" style="padding: .25em .33em;">
                    <div>{seasonalPrefs.seasonName}</div>
                    {
                      authZ_editVolunteerPrefs()
                        ? (
                          <RouterLink to={R_VolunteerRegistrationFlow.routeDetailToRouteLocation({
                            name: R_VolunteerRegistrationFlow.FlowStepRouteName.volunteerRoles,
                            seasonUID: seasonalPrefs.seasonUID,
                            userID: props.data.user.userID
                          })}>
                            <div class="text-sm hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md" style="margin-top: -2px;">
                              <FontAwesomeIcon icon={faPencil}/>
                            </div>
                          </RouterLink>
                        )
                        : null
                    }
                  </div>
                  <div class="m-2">
                    <div style={
                      windowSize.width <= 640
                        ? "display:grid; grid-template-columns: 1fr; grid-gap:1px;"
                        : "display:grid; grid-template-columns: .5fr .5fr; grid-gap:1px;"
                    }>
                      <div class="p-1 outline outline-1 outline-gray-200">
                        <div>
                          {/*n.b. intentional trailing spaces on the first <span>*/}
                          <span>Head coach: <LocallyFormattedYesNo bool={seasonalPrefs.isHeadCoach}/> </span>
                          {
                            seasonalPrefs.isHeadCoach
                              ? <span>({divPrefsListOrNone(seasonalPrefs.headCoachVolunteerPrefsForSeason)})</span>
                              : null
                          }
                        </div>
                      </div>
                      <div class="p-1 outline outline-1 outline-gray-200">
                        <div>
                          <span>Asst coach: <LocallyFormattedYesNo bool={seasonalPrefs.isAsstCoach}/> </span>
                          {
                            seasonalPrefs.isAsstCoach
                              ? <span>({divPrefsListOrNone(seasonalPrefs.asstCoachVolunteerPrefsForSeason)})</span>
                              : null
                          }
                        </div>
                      </div>
                      <div class="p-1 outline outline-1 outline-gray-200">
                        <div>
                          <span>Ref: <LocallyFormattedYesNo bool={seasonalPrefs.isRef}/> </span>
                          {
                            seasonalPrefs.isRef
                              ? <span>({divPrefsListOrNone(seasonalPrefs.refVolunteerPrefsForSeason)})</span>
                              : null
                          }
                        </div>
                      </div>
                      {
                        seasonalPrefs.flexCodes.length > 0
                          ? (
                            <div class="p-1 outline outline-1 outline-gray-200">
                              <div>Additional volunteer codes:</div>
                              <div class="pl-2">
                                {
                                  seasonalPrefs.flexCodes.map(flexCode => <div>{flexCode.code}</div>)
                                }
                              </div>
                            </div>
                          )
                          : null
                      }
                    </div>
                  </div>
                  <div class="m-2">
                    <span class="font-medium">Comments: </span>
                    <span>{seasonalPrefs.coachComments || "N/A"}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    }

    function divPrefsListOrNone(preferences: {division: string}[]) {
      return preferences.length === 0
        ? "No division preferences"
        : preferences.map(v => v.division).join(", ");
    }

    function LocallyFormattedYesNo({bool}: {bool: boolean}) {
      return bool
        ? <span class="font-medium" style="color: rgb(0,100,0)">Yes</span>
        : <span>No</span>
    }
  }
})
