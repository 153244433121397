import { ref, computed, PropType, ExtractPropTypes } from "vue";
import { type AxiosInstance } from "axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";

import { dayjsFormatOr } from "src/helpers/formatDate";
import { vReqT, VueNeverUnwrappable } from "src/helpers/utils";

import * as ilapi from "src/composables/InleagueApiV1";
import * as iltypes from "src/interfaces/InleagueApiV1";

export type ExpandedEvent = iltypes.WithDefinite<ilapi.event.GetEventResult, "signups" | "rosterLayoutDetail">;

export const propsDef = {
  includeCanceled: vReqT<boolean>(),
  expandedEvent: vReqT<ExpandedEvent>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

declare global {
  // pollutes global namespace, oh well.
  function EventRosterImpl(props: Props) : JSX.Element;
}

export const EventRosterEmailHelper = (() => {
  /**
   * like "Wednesday, December 21st, 2022"
   */
  const formatDate = (datelike: string | null) => dayjsFormatOr(datelike, "dddd, MMMM Do, YYYY");

  function initialEmailContent(event: ilapi.event.Event) {
    const heading = `<h3>${event.eventName.trim()} -- ${formatDate(event.eventStart)}</h3>`
    return heading + (event.emailText || "")
  }

  function initialEmailSubject(event: ilapi.event.Event) {
    return event.eventName.trim() + ` (${formatDate(event.eventStart)})`;
  }

  return {
    initialEmailContent,
    initialEmailSubject
  } as const;
})();

/**
 * given an ExpandedEvent, forms a vue ref to it, and offers support for updating it reactively
 * (esp. its `signups` listing)
 */
export function ExpandedEventTrackerState(axios: AxiosInstance, v: ExpandedEvent) {
  const expandedEvent = ref(v);

  /**
   * Tracking an additional signup, requires that we pull in some additional data for UI purposes.
   */
  const trackSignup = async (freshSignup: ilapi.event.EventSignup) : Promise<void> => {
    if (freshSignup.childID) {
      expandedEvent.value.rosterLayoutDetail.children[freshSignup.childID] = (await ilapi
        .getPlayer(
          axios, {
            childID: freshSignup.childID,
            expand: ["parent2Email", "parent2Phone", "parent2FirstName", "parent2LastName"]
          })) as iltypes.WithDefinite<iltypes.Child, "parent2Email" | "parent2Phone" | "parent2FirstName" | "parent2LastName">;

      expandedEvent.value.rosterLayoutDetail.childrenComputedDetail[freshSignup.childID] = await ilapi
        .child
        .getCalcAgeAndPossiblySpeculativeDivisionsForSomeChildSeason(
          axios, {
            childID: freshSignup.childID,
            seasonUID: freshSignup.seasonUID
          });
    }
    else {
      const r = await ilapi.getUser(axios, freshSignup.userID);
      if (r.objectType === "private-profile") {
        // shouldn't ever be "private" here
        throw "unexpected private-profile `getUser` result"
      }
      expandedEvent.value.rosterLayoutDetail.users[freshSignup.userID] = r;
    }

    // n.b. do last after all `await` on dependencies are complete
    expandedEvent.value.signups.push(freshSignup);
  }

  /**
   * Remove some signup from the list of signups we are aware of
   * Generally we should perform this in response to deleting or moving a signup on the server side
   *
   * n.b. not exported, actions which would want to call this should probably be made member functions here
   */
  const locallyUntrackSignup = (signup: ilapi.event.EventSignup) : void => {
    // drop signup from local listing
    const idx = expandedEvent.value.signups.findIndex(v => v.eventSignupID === signup.eventSignupID);
    if (idx !== -1) {
      expandedEvent.value.signups.splice(idx, 1);
    }
    else {
      // we should 100% be able to find it, but we didn't
      return;
    }
  }

  const forceCreateActiveUnpaidSignup = async (entityID: iltypes.Guid, entityType: "user" | "player") : Promise<void> => {
    try {
      const freshSignup = await forceCreateActiveUnpaidSignupWorker(axios, v.eventID, entityID, entityType)
      await trackSignup(freshSignup);
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    }
  }

  const cancelEventSignup = async (signup: ilapi.event.EventSignup) : Promise<void> => {
    try {
      const args = signup.childID
        ? {eventID: signup.eventID, childID: signup.childID}
        : {eventID: signup.eventID, userID: signup.userID}

      await ilapi.event.cancelEventSignup(axios, args);

      locallyUntrackSignup(signup);
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    }
  }

  /**
   * returns an indicator of succes. If HTTP throws (e.g. on 400/500), then we currently assume that the provided axios instance
   * has handlers to issue a toast containg some error message; but, we don't let such Axios errors escape and in case of an HTTP
   * error, assume the axios handler has done its toast thing and then we return ok=false. In the truly exceptional non-axios case
   * we do let such exceptions escape.
   */
  const moveEventSignup = async (args: {eventSignup: ilapi.event.EventSignup, moveToThisEventID: iltypes.Guid, comments: string}) : Promise<{ok: boolean}> => {
    try {
      await ilapi.event.moveEventSignup(axios, {
        eventSignupID: args.eventSignup.eventSignupID,
        moveToThisEventID: args.moveToThisEventID,
        comments: args.comments
      });
      locallyUntrackSignup(args.eventSignup);
      return {ok: true}
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      return {ok: false}
    }
  }

  const usersHavingActiveSignups = computed(() => new Set(expandedEvent.value.signups.filter(v => !v.canceled && v.paid).map(signup => signup.userID)));
  const playersHavingActiveSignups = computed(() => new Set(expandedEvent.value.signups.filter(v => !v.canceled && v.paid).map(signup => signup.childID)));

  return VueNeverUnwrappable({
    expandedEvent,
    forceCreateActiveUnpaidSignup,
    cancelEventSignup,
    usersHavingActiveSignups: usersHavingActiveSignups,
    playersHavingActiveSignups: playersHavingActiveSignups,
    moveEventSignup,
  })
}
export type ExpandedEventTrackerState = ReturnType<typeof ExpandedEventTrackerState>

/**
 * This function assumes the "current user" is a registrar, so whatever UI elements end up calling this should be appropriately guarded.
 * If the current user is not a registrar, this will 403.
 */
async function forceCreateActiveUnpaidSignupWorker(axios: AxiosInstance, eventID: iltypes.Guid, entityID: iltypes.Guid, entityType: "user" | "player") : Promise<ilapi.event.EventSignup> {
  const request : ilapi.event.SignupRequest = entityType === "user"
    ? {userID: entityID, isChild: false, isAttending: true, customQuestions: undefined, comments: ""}
    : {childID: entityID, isChild: true, isAttending: true, customQuestions: undefined, comments: ""};

  const signups = await ilapi.event.signup(
    axios, {
      eventID: eventID,
      signupRequests: [request],
      forceActivate: true,
    },
  );

  return signups[0];
}

export class EventRosterListingEventBus {
  // TODO: if callbacks return promises, we should await them here
  // or maybe we just don't need to support more than 1 callback at a time per event
  private callbacks = {
    onCancelSignup: new Set<(_: ilapi.event.EventSignup) => void>(),
    onMoveSignup: new Set<(_: ilapi.event.EventSignup) => void>(),
    onUpdateComment: new Set<(_: ilapi.event.EventSignup, comment: string) => void>(),
  };

  constructor() {}

  // returns a function that can be used to unregister the callback
  onCancelSignup(f: (_: ilapi.event.EventSignup) => void) : (() => void) {
    if (!this.callbacks.onCancelSignup.has(f)) {
      this.callbacks.onCancelSignup.add(f);
    }
    return () => this.callbacks.onCancelSignup.delete(f);
  }

  onMoveSignup(f: (_: ilapi.event.EventSignup) => void) : (() => void) {
    if (!this.callbacks.onMoveSignup.has(f)) {
      this.callbacks.onMoveSignup.add(f);
    }
    return () => this.callbacks.onMoveSignup.delete(f);
  }

  onUpdateComment(f: (signup: ilapi.event.EventSignup, comment: string) => void) : (() => void) {
    if (!this.callbacks.onUpdateComment.has(f)) {
      this.callbacks.onUpdateComment.add(f);
    }
    return () => this.callbacks.onUpdateComment.delete(f);
  }

  triggerCancelSignup(eventSignup: ilapi.event.EventSignup) {
    for (const cb of this.callbacks.onCancelSignup) {
      cb(eventSignup);
    }
  }

  triggerMoveSignup(eventSignup: ilapi.event.EventSignup) {
    for (const cb of this.callbacks.onMoveSignup) {
      cb(eventSignup);
    }
  }

  triggerUpdateComment(eventSignup: ilapi.event.EventSignup, freshComment: string) {
    for (const cb of this.callbacks.onUpdateComment) {
      // hm, probably the first callback will update-in-place the eventSignup object itself;
      // we really only ever expect to have 1 callback, though we support pushing more than 1
      // if there's more than 1 callback here, this could get weird. Probably we don't need more than
      // 1 registered callback for this event type (or really, any of these event types...)?
      cb(eventSignup, freshComment);
    }
  }
}
