import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { exhaustiveCaseGuard } from "src/helpers/utils";
import { ExtractPropTypes, PropType } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<PropsVariant>
  }
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export const RouteNames = {
  main: "tournament-page-items-listing.main",
  preview: "tournament-page-items-listing.preview",
} as const;

type RouteName = (typeof RouteNames)[keyof typeof RouteNames]
interface RoutePropsBase {
  name: RouteName
}

export type PropsVariant =
  | Main
  | Preview

export interface Main extends RoutePropsBase {
  name: typeof RouteNames.main,
}

export interface Preview extends RoutePropsBase {
  name: typeof RouteNames.preview,
  tournamentID: iltypes.Integerlike,
}

export function routeDetailToRouteLocation(v: PropsVariant) : RouteLocationRaw {
  switch (v.name) {
    case RouteNames.main:
      return {name: v.name};
    case RouteNames.preview:
      return {name: v.name, params: {tournamentID: v.tournamentID}}
    default: exhaustiveCaseGuard(v);
  }
}

export function routeLocationToProps(route: RouteLocationNormalized) : Props {
  const name = route.name as RouteName;
  switch (name) {
    case RouteNames.main:
      return {detail: {name}}
    case RouteNames.preview:
      return {detail: {name, tournamentID: route.params.tournamentID as iltypes.Integerlike}}
    default: exhaustiveCaseGuard(name)
  }
}
