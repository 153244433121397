<template lang="pug">
div(v-if="ready")
  DeleteEventQuestionConfirmationModal(
    v-if="deleteEventQuestionConfirmationModalController.visible"
    v-bind="deleteEventQuestionConfirmationModalController.props"
    v-on="deleteEventQuestionConfirmationModalController.handlers"
  )
  t-btn(type="button" v-if="!creatingNewQuestionState.isCreatingNewQuestion" @click="startCreatingNewQuestion()")
    div Create new question
  template(v-if="creatingNewQuestionState.isCreatingNewQuestion")
    div.w-full.shadow-md.p-4
      EventCustomQuestionForm(
        v-bind="creatingNewQuestionState.eventCustomQuestionFormProps"
        v-on="eventCustomQuestionFormHandlers"
      )
  .flex.flex-col
      .overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .overflow-hidden
            table.min-w-full
              thead.bg-white.border-b
                tr
                  th.text-sm.font-medium.text-gray-900.px-6.py-4.text-left(scope='col')
                    | Label
                  th.text-sm.font-medium.text-gray-900.px-6.py-4.text-left(scope='col')
                    | Type
                  th.text-sm.font-medium.text-gray-900.px-6.py-4.text-left(scope='col')
                    | Sort order
              tbody
                template(v-if="eventQuestions.length === 0")
                  tr
                    td(colspan="9999")
                      div.p-4 No questions yet!
                template(v-for="(eventQuestion, index) in eventQuestions" :key="eventQuestion.questionID")
                  tr.bg-gray-100.border-b(:data-questionID="eventQuestion.questionID")
                    td.text-sm.text-gray-900.font-light.px-6.py-4.whitespace-nowrap
                      div {{ eventQuestion.shortLabel }}
                      div.text-xs
                        |  {{ eventQuestion.events.length }}
                        |  associated event{{ eventQuestion.events.length === 1 ? "" : "s" }}{{ eventQuestion.events.length > 0 ? ":" : "" }}
                      template(v-if="eventQuestion.events.length > 0")
                        ul
                          template(v-for="associatedEvent_partial in eventQuestion.events" :key="associatedEvent_partial.eventID")
                            router-link(:to="{name: 'event-roster', params: {eventID: associatedEvent_partial.eventID}}" target="_blank")
                              li.text-blue-700.underline.pl-2.text-xs {{ associatedEvent_partial.eventName}}
                    td.text-sm.text-gray-900.font-light.px-6.py-4.whitespace-nowrap
                      | {{ eventQuestion.type }}
                    td.text-sm.text-gray-900.font-light.px-6.py-4.whitespace-nowrap
                      div {{ eventQuestion.sortOrder || "n/a" }}
                    td.text-sm.text-gray-900.font-light.px-6.py-4.whitespace-nowrap
                      div.flex.justify-between
                        div
                          t-btn(
                            v-if="!editsInProgress[eventQuestion.questionID]"
                            @click="setIsEditingExistingQuestion(eventQuestion)"
                          )
                            div Edit
                          t-btn(@click="deleteEventQuestionConfirmationModalController.show(eventQuestion.questionID)")
                            div Delete
                        div
                          //- if an element has a sortorder (so it makes sense to nudge it), we can offer to nudge it
                          //- if an element is not first, and isn't the first-after-the-last-element-of-the-not-having-a-sort-order-group, we can nudge it up
                          div.cursor-pointer(
                            :style="{visibility: (eventQuestion.sortOrder && index !== 0 && eventQuestions[index-1].sortOrder) ? '' : 'hidden'}"
                            @click="nudgeEventQuestion(eventQuestion, 'up')"
                          )
                            font-awesome-icon(:icon='["fas", "arrow-up"]')

                          //- if an element is not last, we can nudge it down
                          div.cursor-pointer(
                            :style="{visibility: (eventQuestion.sortOrder && index !== eventQuestions.length - 1) ? '' : 'hidden'}"
                            @click="nudgeEventQuestion(eventQuestion, 'down')"
                          )
                            font-awesome-icon(:icon='["fas", "arrow-down"]')
                  tr(v-if="!!editsInProgress[eventQuestion.questionID]")
                    td(colspan="9999")
                      div.w-full.p-4.mb-8.shadow-md
                        EventCustomQuestionForm(
                          v-bind="editsInProgress[eventQuestion.questionID].eventCustomQuestionFormProps"
                          v-on="eventCustomQuestionFormHandlers"
                        )

</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, getCurrentInstance, nextTick, watch, reactive } from 'vue'
import { useRouter } from 'vue-router'


import { datePickerFormat } from 'src/helpers/formatDate'

import C_EventCustomQuestionForm from "./EventCustomQuestionForm.vue";
import * as M_EventCustomQuestionForm from "./EventCustomQuestionForm.ilx";

import C_DeleteEventQuestionConfirmationModal from "./DeleteEventQuestionConfirmationModal.vue"
import * as M_DeleteEventQuestionModal from "./DeleteEventQuestionConfirmationModal.ilx"

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios';
import { copyViaJsonRoundTrip, useIziToast, parseIntOr, assertNonNull } from 'src/helpers/utils';
import { AxiosInstance } from 'axios';

type ExpandedEventQuestion = iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions" | "events">;

export default defineComponent({
  components: {
    EventCustomQuestionForm: C_EventCustomQuestionForm,
    DeleteEventQuestionConfirmationModal: C_DeleteEventQuestionConfirmationModal
  },
  setup() {
    const iziToast = useIziToast();
    const eventQuestions = ref<ExpandedEventQuestion[]>([]);

    type CreatingNewQuestionState =
      | {isCreatingNewQuestion: false}
      | {isCreatingNewQuestion: true, eventCustomQuestionFormProps: M_EventCustomQuestionForm.Props};
    interface EditingSomeQuestionState {
      eventCustomQuestionFormProps: M_EventCustomQuestionForm.Props,
      oldData: ExpandedEventQuestion,
      cancelFormMutationWatcher: () => void
    }

    const creatingNewQuestionState = ref<CreatingNewQuestionState>({isCreatingNewQuestion: false});

    /**
     * We say the mapping is possibly undefined here to be clear that not all questionIDs will be present
     * A question that is not in edit mode will not be present here, and a question no longer being edited should be deleted from the mapping
     */
    const editsInProgress = ref<{[questionID: iltypes.Guid]: undefined | EditingSomeQuestionState}>({});

    const ATTRNAME_SAVED_BACKGROUND_COLOR = "data-il-saved-backgroundColor";
    const deleteEventQuestionConfirmationModalController = M_DeleteEventQuestionModal.DefaultController({
      //
      // before we show the modal, highlight the target row
      //
      preShow: (props) => {
        const row = getRowElementByQuestionIdOrFail(props.questionID);
        const savedBackgroundColor = row.style.backgroundColor;
        row.setAttribute(ATTRNAME_SAVED_BACKGROUND_COLOR, savedBackgroundColor);
        row.style.backgroundColor = "yellow";
      },
      //
      // before we hide the modal, unhighlight the target row (if we didn't delete it)
      //
      preHide: (props) => {
        const row = maybeGetRowElementByQuestionID(props.questionID);
        if (!row) {
          // could be null here, since maybe we deleted the target row
          return;
        }

        const savedBackgroundColor = row.getAttribute(ATTRNAME_SAVED_BACKGROUND_COLOR);
        row.style.backgroundColor = savedBackgroundColor || "";
        if (savedBackgroundColor) {
          row.setAttribute(ATTRNAME_SAVED_BACKGROUND_COLOR, savedBackgroundColor);
        }
        else {
          row.removeAttribute(ATTRNAME_SAVED_BACKGROUND_COLOR);
        }
      },
      handlers: {
        doPerformDelete: async (questionID) => {
          await deleteEventQuestion(questionID);
          deleteEventQuestionConfirmationModalController.hide();
        },
        doNotDelete: () => {
          deleteEventQuestionConfirmationModalController.hide();
        }
      }
    })

    const ready = ref(false);

    onMounted(async () => {
      eventQuestions.value = await listEventQuestions(axiosInstance);
      sortEventQuestionsInPlace(eventQuestions.value);
      ready.value = true;
    })

    const eventQuestionFormFieldMutatedWatcher = (formData: M_EventCustomQuestionForm.QuestionFormEntry) => {
      const target = eventQuestions.value.find(v => v.questionID === formData.questionID);
      if (!target) {
        // error case: should have found it
        return;
      }

      // this is the only thing we really care about watching for changes, to update a small
      // amount of data live
      target.shortLabel = formData.shortLabel;
    }

    const setIsEditingExistingQuestion = (question: ExpandedEventQuestion) : void => {
      const formData = reactive(M_EventCustomQuestionForm.makeQuestionFormEntry(question))
      const watchCancellation = watch(formData, eventQuestionFormFieldMutatedWatcher, {deep:true});
      editsInProgress.value[question.questionID] = {
        eventCustomQuestionFormProps: {
          v: {
            type: "update-existing",
            formData
          }
        },
        oldData: copyViaJsonRoundTrip(question),
        cancelFormMutationWatcher: watchCancellation
      };
    }

    const nudgeEventQuestion = async (v: ExpandedEventQuestion, dir: "up" | "down") : Promise<void> => {
      try {
        // would maybe be cool to use the axiosBackgroundInstance here, to not show a big spinner just to nudge a thing up or down
        // but it's kind of hit or miss; we might take 50ms and be done, or there could be an indeterminate network delay
        // If this does complete quickly (as is generally expected), then we get a jarring "spinner visible! (50ms later) bam! spinner not visible!"
        const response = await ilapi.event.nudgeEventQuestion(axiosInstance, v.questionID, dir);
        updateSortOrdersInPlace(response);
        sortEventQuestionsInPlace(eventQuestions.value);
        const row = getRowElementByQuestionIdOrFail(v.questionID)
        flashElementOnce(row, 500);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const startCreatingNewQuestion = () => {
      creatingNewQuestionState.value = {
        isCreatingNewQuestion: true,
        eventCustomQuestionFormProps: {
          v: {
            type: "make-new",
            formData: M_EventCustomQuestionForm.makeQuestionFormEntry("new")
          }
        }
      }
    }

    const dropEdit = (args: {questionID: iltypes.Guid, restorePreEditData: boolean}) => {
      const editInProgress = editsInProgress.value[args.questionID];

      if (!editInProgress) {
        return;
      }

      editInProgress.cancelFormMutationWatcher();

      if (args.restorePreEditData) {
        const idx = eventQuestions.value.findIndex(v => v.questionID === args.questionID);
        if (idx !== -1) {
          eventQuestions.value[idx] = editInProgress.oldData;
        }
      }

      delete editsInProgress.value[args.questionID];
    }

    const eventCustomQuestionFormHandlers : M_EventCustomQuestionForm.Emits = {
      doCancel: (questionID) => {
        if (!questionID) {
          creatingNewQuestionState.value = {isCreatingNewQuestion: false};
          return;
        }
        else {
          dropEdit({questionID, restorePreEditData: true});
        }
      },
      doSave: async (formEntry) => {
        try {
          // we know this if we're updating, but if it's a new question we won't know until after we create it
          let questionID : iltypes.Guid;

          if (formEntry.isNewQuestion) {
            const createResult = await createEventQuestion(axiosInstance, M_EventCustomQuestionForm.questionFormToEndpointArgs(formEntry));
            createResult.eventQuestion.events = [];
            eventQuestions.value.unshift(createResult.eventQuestion); // to front of list
            creatingNewQuestionState.value = {isCreatingNewQuestion: false};
            iziToast.success({message: "Question created."})
            updateSortOrdersInPlace(createResult.revisedSortOrders);
            questionID = createResult.eventQuestion.questionID;
          }
          else {
            const updateResult = await updateEventQuestion(axiosInstance, M_EventCustomQuestionForm.questionFormToEndpointArgs(formEntry));

            dropEdit({questionID: formEntry.questionID, restorePreEditData: false});

            const idx = eventQuestions.value.findIndex(v => v.questionID === formEntry.questionID);
            if (idx !== -1) {
              eventQuestions.value[idx] = updateResult.eventQuestion;
            }

            iziToast.success({message: "Question updated."})
            updateSortOrdersInPlace(updateResult.revisedSortOrders);

            questionID = formEntry.questionID;
          }

          sortEventQuestionsInPlace(eventQuestions.value);

          // redraw based on list changes
          await nextTick();

          // orient the user's eyes against the row we just (jarringly) collapsed
          const element = getRowElementByQuestionIdOrFail(questionID);
          element.scrollIntoView({block: "center", behavior: "smooth"});
          flashElementOnce(element);
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
    }

    /**
     * After pushing a create/update, the server will reply with updated sort orders for all questions
     * This accepts that payload and applies the changes.
     *
     * Does not resort the list, that is the caller's job.
     *
     * This assumes the `revisedSortOrders` have already been persisted to disk on the backend
     * (where it then returned the RevisedSortOrder[] listing to use here)
     */
    const updateSortOrdersInPlace = (revisedSortOrders: null | ilapi.event.RevisedSortOrder[]) : void => {
      if (!revisedSortOrders) {
        // no work to do
        return;
      }

      const lookup = new Map(revisedSortOrders.map(v => [v.questionID, v.sortOrder]));
      for (const eventQuestion of eventQuestions.value) {
        const freshSortOrder = lookup.get(eventQuestion.questionID);
        if (!freshSortOrder) {
          // sort-of-an-error case -- we should have got a match.
          // If calling this function in response to a question deletion,
          // remove the target question from the listing first.
          continue;
        }
        eventQuestion.sortOrder = freshSortOrder;

        const editInProgress = editsInProgress.value[eventQuestion.questionID];
        if (editInProgress) {
          // if we're actively editing this question we need to update the form state, too
          editInProgress.eventCustomQuestionFormProps.v.formData.sortOrder = freshSortOrder.toString();
          // and the "old data", because the new order value is assumed to have already been saved on the backend
          editInProgress.oldData.sortOrder = freshSortOrder;
        }
      }
    }

    const deleteEventQuestion = async (questionID: iltypes.Guid) : Promise<void> => {
      try {
        const deleteResponse = await ilapi.event.deleteEventQuestion(axiosInstance, questionID);

        const maybeEditInProgress = editsInProgress.value[questionID];
        if (maybeEditInProgress) {
          dropEdit({questionID: questionID, restorePreEditData: false});
        }

        // For deletion we don't need to resort the list, just drop the target element
        const idx = eventQuestions.value.findIndex(v => v.questionID === questionID);
        if (idx !== -1) {
          eventQuestions.value.splice(idx, 1);
        }

        // We don't need to resort, but we do need to register the updated sortOrder values
        // because every question __after__ this one was updated (barring those with a null sortOrder).
        updateSortOrdersInPlace(deleteResponse.revisedSortOrders);

        iziToast.success({message: "Question was deleted."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const maybeGetRowElementByQuestionID = (questionID: iltypes.Guid) : HTMLElement | null => {
      return document.querySelector<HTMLElement>(`[data-questionID="${questionID}"]`);
    }

    const getRowElementByQuestionIdOrFail = (questionID: iltypes.Guid) : HTMLElement => {
      const v = maybeGetRowElementByQuestionID(questionID);
      assertNonNull(v, `Unexpected failure to find row for questionID ${questionID}`);
      return v;
    }

    return {
      creatingNewQuestionState,
      startCreatingNewQuestion,
      eventQuestions,
      ready,
      editsInProgress,
      setIsEditingExistingQuestion,
      eventCustomQuestionFormHandlers,
      nudgeEventQuestion,
      deleteEventQuestionConfirmationModalController
    }
  },
})

/**
 * todo: css support for fade-in/fade-out?
 * fixme: this needs to be vue-based, otherwise clicking things rapidly we get out of sync with `element`
 * vue may have totally replaced the element by the time we get to the setTimeout "unhighlight" part,
 * and then we aren't able to reset the highlighting
 */
function flashElementOnce(element: HTMLElement, timeoutMs = 1500) {
  const savedColor = element.style.backgroundColor;
  element.style.backgroundColor = "yellow";
  setTimeout(() => {
    element.style.backgroundColor = savedColor;
  }, timeoutMs);
}

function sortEventQuestionsInPlace(eventQuestions: ilapi.event.EventQuestion[]) : void {
  eventQuestions.sort((l,r) => {
    const lOrder = parseIntOr(l.sortOrder, -2);
    const rOrder = parseIntOr(r.sortOrder, -1);
    return lOrder < rOrder ? -1 : lOrder === rOrder ? 0 : 1;
  })
}

async function listEventQuestions(axios: AxiosInstance) : Promise<ExpandedEventQuestion[]> {
  return await ilapi.event.listEventQuestions(axiosInstance, ["events"]) as ExpandedEventQuestion[]
}

/**
 * light wrapper around createEventQuestion to match shapes up
 * (alternatively, create/update endpoints could take expandable args? seems kinda wasteful)
 */
async function createEventQuestion(
  axios: AxiosInstance,
  args: ilapi.event.CreateEventQuestionArgs
) : Promise<{revisedSortOrders: null | ilapi.event.RevisedSortOrder[], eventQuestion: ExpandedEventQuestion}> {
  const ret = await ilapi.event.createEventQuestion(axios, args);
  const extra = {events: []}
  return {
    revisedSortOrders: ret.revisedSortOrders,
    eventQuestion: {...ret.eventQuestion, ...extra}
  }
}

/**
 * light wrapper around updateEventQuestion to match shapes up
 */
async function updateEventQuestion(
  axios: AxiosInstance,
  args: ilapi.event.UpdateEventQuestionArgs
) : Promise<{revisedSortOrders: null | ilapi.event.RevisedSortOrder[], eventQuestion: ExpandedEventQuestion}> {
  const ret = await ilapi.event.updateEventQuestion(axios, args);
  const extra = {events: []}
  return {
    revisedSortOrders: ret.revisedSortOrders,
    eventQuestion: {...ret.eventQuestion, ...extra}
  }
}

</script>
