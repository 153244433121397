import authService from "src/helpers/authService";
import { CoreQuestion, Registration } from "src/interfaces/InleagueApiV1";

export function considerCoreQuestionDisabledForPrimaryRegistration(reg: Registration, question: CoreQuestion, options?: {checkAgainstUserRoles: string[]}) : boolean {
  const DISABLED = true;
  const NOT_DISABLED = false;

  if (options?.checkAgainstUserRoles) {
    // if we're also considering user roles, a particular set of roles always allows edits
    if (authService(options.checkAgainstUserRoles, "registrar", "webmaster", "playerAdmin")) {
      return NOT_DISABLED;
    }
  }

  if (question.disabled) {
    return DISABLED;
  }

  const questionAlwaysAllowsUpdates = !!question.update;
  const hasSomeActiveCompReg = reg.competitions.some(v => !!v.paid && !v.canceled);
  return (hasSomeActiveCompReg && !questionAlwaysAllowsUpdates) ? DISABLED : NOT_DISABLED;
}
