<template lang="pug">
div
  .smx-auto.w-full.flex.items-end(class='md:mx-2')
    h1.ml-2.mr-4.text-2xl.self-start.font-medium(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "search"]')
      | Review Contact Information - {{volunteerDetails.firstName}} {{volunteerDetails.lastName}}
.-my-2.mt-6.mb-2.max-w-3xl
  .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
    UserContactVerificationImpl(
      v-if="ready"
      @confirmed="$emit('confirmed')"
      @user-contact-verification-impl-goto-user-editor="$emit('user-contact-verification-impl-goto-user-editor')"
      :volunteerDetails="volunteerDetails")
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import type {VolunteerDetails} from 'src/interfaces/volunteer'
import { axiosInstance } from "src/boot/axios"
import { useRoute, useRouter } from 'vue-router'
import { datePickerFormat, dayJSDate } from 'src/helpers/formatDate'
import UserContactVerificationImpl from "./UserContactVerificationImpl.vue"

const components = {
  UserContactVerificationImpl: UserContactVerificationImpl
}

const props = defineProps<{
  volunteerID: string
  seasonUID: string
}>();

const emit = defineEmits(["confirmed", "user-contact-verification-impl-goto-user-editor"]);

const ready = ref(false);
const volunteerDetails = ref({} as VolunteerDetails)

const route = useRoute()
const router = useRouter()

const goToVolunteerEditor = () => {
  // console.log('goToVolunteerEditor')
}

const getVolunteerDetails = async () => {
    const response = await axiosInstance.get(`/v1/user/${props.volunteerID}/season/${props.seasonUID}`)
    // console.log('response', response.data.data.firstName)
    return response.data.data
}

const updateDetails = async () => {
  const options= {dob: volunteerDetails.value.dob, firstName: volunteerDetails.value.firstName, lastName: volunteerDetails.value.lastName, middleName: volunteerDetails.value.middleName}

  try {
    const response = await axiosInstance.put(`v1/user/${props.volunteerID}/nameAndDob`, options)
    return response.data.data
  } catch (error) {
    // console.log('updateDetails error', error)
    throw error
  }
}

// @dwr may-01-2022 unused?
// const handleConfirmed = () => {
//   emit("updated-volunteer-details", volunteerDetails);
// }

onMounted(async () => {
  const volDeets = await getVolunteerDetails()
  volDeets.dob = datePickerFormat(volDeets.dob)
  volunteerDetails.value = volDeets
  ready.value = true;
})

</script>

<style>
.redBackground {
  background-color: rgba(239 68 68) !important
}
</style>
