<!--
  unused? (there's a route that refs it but that route is unreachable / replaced by VolunteerRegistrationFlow and associated child routes?)
  prefer SelectVolunteer2.vue
-->
<template lang="pug">
div
  .smx-auto.w-full.max-w-md(class='md:mx-2' @submit.prevent="search")
    h1.ml-2.text-2xl.self-end.font-medium(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "search"]')
      | Volunteer Selection
.q-pa-md.quasar-style-wrap.mt-8.m-2.max-w-md(
    v-if='familyUsers.length',
    data-cy='familyUsersTable',
  )
    q-table(
      :rows='familyUsers',View
      :columns='cols',
      :header='false',
      row-key='userID',
      :rows-per-page-options='[0]',
      hide-pagination
    )
      template(v-slot:body-cell-User='props')
        q-td.cursor-pointer.full-width(:props='props' @click='toUserRegistration(props.row.userID)')
          .font-medium
            | {{ props.row.firstName }} {{ props.row.lastName }}
          .font-normal {{ props.row.email}}
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { axiosInstance } from "src/boot/axios"
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const cols = ref([
  {
    name: 'User',
    label: 'Users',
    required: true,
    align: 'left',
    field: (user: {
      userID: string;
      lastName: string;
      familyIDs: string[];
      firstName: string;
      email: string;
  }) => user,
    sortable: true,
    classes: 'q-table',
  },
])



const familyUsers = ref({} as {
      userID: string;
      lastName: string;
      familyIDs: string[];
      firstName: string;
      email: string;
  }[])

const getFamilyUsers = async () => {
  try {
    const response = await axiosInstance.get(`/v1/family/users`)
    return response.data.data
  } catch (error) {
    // console.log('error getting family users', error)
  }
}

const toUserRegistration = async (volunteerID: string) => {
  await router.push({name: 'volunteer-verification', params: {volunteerID: volunteerID, seasonUID: route.params.seasonUID}})
}

onMounted(async () => {
  familyUsers.value = (await getFamilyUsers()).users
})
</script>

