<template lang="pug">
FormKit(
  v-model='divisionID',
  :options='divisions',
  type='select',
  label=`${displayLabel ? 'Divisions' : ''}`,
  placeholder='Select a division',
  v-if='Object.keys(divisions).length > 0',
  inner-class='bg-white',
  wrapper-class='mt-6'
  data-test="Divisions"
)
</template>

<script lang="ts">
import { defineComponent,ref, onMounted, computed } from 'vue'
import { createSelect } from 'src/helpers/schemaService'

import { Client } from 'src/store/Client'

export default defineComponent({
  name: "Divisions",
  props: {
    modelValue: {
      type: String,
      required: false
    },
    selectKey: {
      type: String,
      default: 'divID'
    },
    displayLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const divisions = ref({})



    const divisionID = computed({
      // these are tied to props.modelValue, which is `string | undefined`, because it is not a required prop
      get() : string | undefined {
        return props.modelValue
      },
      set(value: string | undefined) {
        emit('update:modelValue', value)
      }
    })

    const getDivisions = async () => {
      const divs = await Client.getDivisions()
      const divisionsSelectFormat = createSelect(
        divs,
        props.selectKey,
        'division'
      )
      divisions.value = divisionsSelectFormat
    }

    onMounted(async () => {
      await getDivisions()
      if(props.modelValue) {
        divisionID.value=props.modelValue
      }
    })

    return {
      divisionID,
      divisions
    }
  },
})
</script>

