import { defineComponent, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { UserData } from 'src/interfaces/Store/user'
import { getLoginModalDismissed, setLoginModalDismissed } from 'src/store/Misc'

import LoginModal from 'src/components/User/LoginModal.vue'

import { System } from 'src/store/System'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'Login',
  beforeRouteEnter: (_to, from, next) => {
    switch (from.path) {
      case "/":
      case "/login": {
        // routes we DO NOT want to route back to on a successful login
        break;
      }
      default: {
        if (!System.getRedirectOnLogin()) {
          // if there's not already a redirectOnSuccessfulLogin value,
          // we want to set it to nav back to where we came from.
          System.setRedirectOnLogin({type: "vue-router-route", value: from});
        }
      }
    }

    setLoginModalDismissed(false);

    next();
  },
  beforeRouteUpdate: (_to, _from, next) => {
    // necessary? would be on nav from /login -> /login, so, ... probably not?
    setLoginModalDismissed(false);
    next();
  },
  components: {
    LoginModal
  },
  setup() {
    const router = useRouter()
    const route = useRoute()


    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const isImpersonating = computed(() => {
      return (User.value.userData as UserData).isImpersonating
    })

    watch([isLoggedIn, isImpersonating], async (newValues: boolean[]) => {
      if (newValues[0] && route.path.includes('redirect')) {
        await router.push({ path: route.path })
      } else if (newValues[0]) {
        await router.push({ path: '/' })
      }
    })

    onMounted(async () => {
      if (isLoggedIn.value && route.path.includes('redirect')) {
        await router.push({ path: route.path })
      } else if (isLoggedIn.value) {
        await router.push({ path: '/' })
      }
    })

    return () => (
      <div>
        {
          isLoggedIn.value || getLoginModalDismissed()
            ? null
            : <LoginModal/>
        }
      </div>
    )
  },
})
