import { isGuidUpper, isObject } from "src/helpers/utils"
import { Guid, PlayerID, TeamID } from "src/interfaces/InleagueApiV1"
import { ref } from "vue"

type LocalStorage_KeyArgs = {userID: Guid, seasonUID: Guid, competitionUID: Guid, divID: Guid}
type TeamPlayer = {teamID: Guid, playerID: Guid}
type LocalStorage_StoredAs = TeamPlayer[]

function RosterExcludedPlayerIDs_localStorage_getKey(args: LocalStorage_KeyArgs) : string {
  return `rosterExcludedPlayerIDs/userID=${args.userID}/seasonUID=${args.seasonUID}/competitionUID=${args.competitionUID}/divID=${args.divID}`
}

function RosterExcludedPlayerIDs_localStorage_load(keyArgs: LocalStorage_KeyArgs) : LocalStorage_StoredAs {
  try {
    const raw = localStorage.getItem(RosterExcludedPlayerIDs_localStorage_getKey(keyArgs))
    if (!raw) {
      return []
    }

    const vs = JSON.parse(raw)

    if (Array.isArray(vs) && vs.every(v => isObject(v) && isGuidUpper(v.teamID) && isGuidUpper(v.playerID))) {
      return vs;
    }
    else {
      return []
    }
  }
  catch {
    return []
  }
}

export function RosterExcludedPlayerIDs_initFromLocalStorage(key: LocalStorage_KeyArgs & {teamIDs: Guid[]}) {
  const v = RosterExcludedPlayerIDs({key, data: RosterExcludedPlayerIDs_localStorage_load(key)})
  v.retainJustSomeTeams(key.teamIDs);

  // It sort of makes sense to persist here.
  // If some values were dropped by way of the team filter, then we'd like to update that, yeah?
  // If no values were dropped this will write X back onto X (i.e. a no-op).
  v.persistToLocalStorage();

  return v;
}

export function RosterExcludedPlayerIDs(args: {key: LocalStorage_KeyArgs, data: TeamPlayer[]}) {
  const key = {...args.key}
  /**
   * We're primarily interested in playerID, so it's the key here; but we map the playerID to
   * it's associated teamID (of which we expect to see exactly zero-or-one per playerID).
   */
  const teamIDByPlayerID = ref(new Map<PlayerID, TeamID>(args.data.map(v => [v.playerID, v.teamID])))

  return {
    add(args: TeamPlayer) {
      teamIDByPlayerID.value.set(args.playerID, args.teamID)
    },
    remove(playerID: Guid) {
      teamIDByPlayerID.value.delete(playerID)
    },
    toggle(args: TeamPlayer) {
      if (teamIDByPlayerID.value.has(args.playerID)) {
        teamIDByPlayerID.value.delete(args.playerID)
      }
      else {
        teamIDByPlayerID.value.set(args.playerID, args.teamID)
      }
    },
    clear() {
      teamIDByPlayerID.value = new Map()
    },
    has(playerID: Guid) {
      return teamIDByPlayerID.value.has(playerID)
    },
    get length() {
      return teamIDByPlayerID.value.size;
    },
    asQueryParamList() : string {
      return [...teamIDByPlayerID.value.keys()].join(",")
    },
    persistToLocalStorage() {
      const val : LocalStorage_StoredAs = [...teamIDByPlayerID.value.entries()].map(([playerID, teamID]) => ({playerID, teamID}))
      localStorage.setItem(RosterExcludedPlayerIDs_localStorage_getKey(key), JSON.stringify(val))
    },
    /**
     * we're keyed on (season, comp, div) but we can have many teams;
     * if we change the teams selection, we drop players on teams we're not focused on.
     */
    retainJustSomeTeams(teamIDs: Guid[]) {
      const teamIDsToKeep = new Set(teamIDs)
      const result = new Map<PlayerID, TeamID>()
      for (const [playerID, teamID] of teamIDByPlayerID.value.entries()) {
        if (teamIDsToKeep.has(teamID)) {
          result.set(playerID, teamID)
        }
      }

      teamIDByPlayerID.value = result
    }
  }
}

export type RosterExcludedPlayerIDs = ReturnType<typeof RosterExcludedPlayerIDs>
