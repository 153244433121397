<template lang="pug">
div
  label.font-medium.text-sm.t-label.block.mb-2.mt-5(v-if='label', :for='name') {{ label }}
  .w-32
    input.rounded-md.shadow-sm.text-black.block.w-full(
      class='focus:ring-green-500 focus:border-green-500 sm:text-sm',
      :class='hasValidationErrors ? "border-red-500 " + name : name',
      :name='name',
      :type='type',
      :disabled='disabled',
      :required='required',
      v-model='localModelValue',
      :placeholder='placeholder',
      @input='update($event.target.value)',
      :step='step'
    )
  div(:class='hasValidationErrors ? "text-red-500 text-xs italic" : ""')
    | {{ validation.errorMessage }}
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'TNumber',
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    'input-class': {
      type: String,
      default: '',
    },
    autoComplete: {
      type: Boolean,
      default: true
    },
    numberType: {
      type: String,
      default: 'int'
    },
    step: {
      type: String,
      default: '1'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const localModelValue = computed({
      get() {
        return props.modelValue
      },
      set(value: string | number | boolean | Record<string, any>) {
        emit('update:modelValue', value)
      },
    })

    const pattern = computed(() => {
      return props.numberType === 'int' ? '[0-9]*' : ''
    })

    const hasValidationErrors = computed(() => {
      return props.validation.errors?.length
    })

    const update = (value: any) => {
      emit('update:modelValue', value)
    }
    return {
      hasValidationErrors,
      update,
      localModelValue,
      pattern
    }
  },
})
</script>
