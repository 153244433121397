import { computed, defineComponent } from "vue"

import ContentChunkDisplay from "src/components/Admin/ContentChunks/ContentChunkDisplay"
import * as M_ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay.ilx'
import { assertNonNull, vOptT, vReqT } from "src/helpers/utils";
import { LastStatus_t } from 'src/interfaces/Store/checkout'
import { ExpandedInvoice } from "./RegistrationComplete.shared";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFileInvoice } from "@fortawesome/pro-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

export const ListCompletitionStatuses = defineComponent({
  props: {
    playerID: vReqT<Guid>(),
    seasonUID: vReqT<Guid>(),
    competitionUIDs: vReqT<Guid[]>(),
    invoiceInstanceIDs: vOptT<Integerlike[]>(),
    /**
     * expected to always succeed for the invoiceInstanceIDs provided
     */
    getInvoiceByInstanceIdOrFail: vReqT<(instanceID: Integerlike) => ExpandedInvoice>(),
    wasDeferredForCashOrCheckPayment: vReqT<boolean>(),
  },
  setup(props) {
    return () => {
      if (!props.invoiceInstanceIDs || props.invoiceInstanceIDs.length === 0) {
        return <PerItemCompletion
          playerID={props.playerID}
          seasonUID={props.seasonUID}
          competitionUIDs={props.competitionUIDs}
          invoice={undefined}
          wasDeferredForCashOrCheckPayment={props.wasDeferredForCashOrCheckPayment}
        />
      }
      return (
        <div>
          {
            props.invoiceInstanceIDs.map(instanceID => {
              return <PerItemCompletion
                playerID={props.playerID}
                seasonUID={props.seasonUID}
                competitionUIDs={props.competitionUIDs}
                invoice={props.getInvoiceByInstanceIdOrFail(instanceID)}
                wasDeferredForCashOrCheckPayment={props.wasDeferredForCashOrCheckPayment}
              />
            })
          }
        </div>
      )
    }
  }
})

const PerItemCompletion = defineComponent({
  props: {
    playerID: vReqT<Guid>(),
    seasonUID: vReqT<Guid>(),
    competitionUIDs: vReqT<Guid[]>(),
    /**
     * There may not be an invoice
     */
    invoice: vOptT<ExpandedInvoice>(),
    wasDeferredForCashOrCheckPayment: vReqT<boolean>(),
  },
  setup(props) {
    const completionType = computed(() => !props.invoice
      ? getPaymentCompletionType()
      : getPaymentCompletionType({
        invoice: props.invoice,
        playerID: props.playerID,
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs
      }));

    return () => {
      return (
        <div>
          {
            completionType.value === CompletionType.waitlistedWithBlockedPaymentIntent
              ? (
                <>
                  <h1 class="text-3xl font-medium flex flex-row items-center"><font-awesome-icon class="mr-2"
                      icon="[&quot;fas&quot;, &quot;check-square&quot;]"></font-awesome-icon>
                    <div>Waitlisted registration complete.</div>
                  </h1>
                  <div class="my-2">
                    <ContentChunkDisplay class="p-2" {...waitlistedWithBlockedPaymentIntentContentChunkProps}/>
                  </div>
                </>
              )
              : props.invoice?.lastStatus == LastStatus_t.IN_FLIGHT
              ? (
                <>
                  <div class="flex justify-between mx-2 md:mx-6">
                    <h1 class="text-3xl font-medium flex flex-row items-center">
                      <FontAwesomeIcon icon={faFileInvoice}/>
                      <span class="ml-2">Payment Pending</span>
                    </h1>
                  </div>
                  <h3 class="italic text-center">
                    Your payment was accepted, but we are temporarily unable to process the invoice.
                    Please contact support if you have not received payment confirmation within an hour.
                  </h3>
                </>
              )
              : props.wasDeferredForCashOrCheckPayment || props.invoice?.lastStatus === LastStatus_t.PAID_AND_PROCESSED
              ? (
                <div>
                  <h1 class="text-3xl font-medium flex flex-row items-center flex mb-6">
                    <div>
                      <div class="flex gap-2 items-center">
                        <FontAwesomeIcon icon={faCheckSquare}/>
                        <div>Registration Complete</div>
                      </div>
                      <div>
                        {
                          props.wasDeferredForCashOrCheckPayment
                            ? <div class="text-xs" v-if="wasDeferredForCashOrCheckPayment">(pending in person payment)</div>
                            : null
                        }
                      </div>
                    </div>
                  </h1>
                </div>
              )
              : null
          }
          {

          }
        </div>
      )
    }
  }
})

const CompletionType = {
  waitlistedWithBlockedPaymentIntent: "waitlisted-with-blocked-payment-intent",
  paid: "paid",
  paymentInFlight: "payment-in-flight",
} as const;

type CompletionType = (typeof CompletionType)[keyof typeof CompletionType];

function getPaymentCompletionType() : null;
function getPaymentCompletionType(args: {invoice: ExpandedInvoice, playerID: Guid, seasonUID: Guid, competitionUIDs: Guid[]}) : CompletionType;
function getPaymentCompletionType(args?: {invoice: ExpandedInvoice, playerID: Guid, seasonUID: Guid, competitionUIDs: Guid[]}) : CompletionType | null {
  if (!args) {
    return null;
  }

  assertNonNull(args, "as per overload")

  // all compreg line items are expected to be for the same single (child, season),
  // and we just pick any one of the line items (for any of the valid competitionUIDs) because an invoice has
  // a "completition type", rather than "each line item has a unique completion type".
  const compRegLineItem = (() => {
    for (const lineItem of args.invoice.lineItems) {
      switch (lineItem.entity_type) {
        case "qCompetitionRegistration": {
          const {childID, seasonUID, competitionUID} = lineItem.entity;
          if (args.playerID === childID && args.seasonUID === seasonUID && args.competitionUIDs.includes(competitionUID)) {
            return lineItem;
          }
        }
        default: continue;
      }
    }

    return null;
  })();

  if (!compRegLineItem) {
    // this is probably a bug (we should have an invoice line item)
    return null;
  }
  else if (compRegLineItem.paymentBlock_isBlocked) {
    return CompletionType.waitlistedWithBlockedPaymentIntent;
  }
  else if (compRegLineItem.entity.paid) {
    return CompletionType.paid;
  }
  else if (args.invoice.lastStatus === LastStatus_t.IN_FLIGHT) {
    return CompletionType.paymentInFlight;
  }
  else {
    // would say "unreachable" but we haven't really proven exhaustivity
    return null;
  }
}

const waitlistedWithBlockedPaymentIntentContentChunkProps : M_ContentChunkDisplay.PropsForCaller = {
  id: "WaitlistPaymentComplete",
  showPencil: false,
} as const;
