/**
 * all these are candidates for Pinia after we enable Pinia
 */

import { ref } from "vue";
import { RouteLocationNormalized } from "vue-router";

/**
 * we keep some small list of history of vue router navigations, mostly to aid
 * debugging "how did we get to some route" in error logs
 *
 * The list is [newest, ..., oldest], where the oldest values are dropped when the list grows beyond some max size.
 */
export const RouterHistoryTracker = (() => {
  const maxSize = 10;
  const history = ref<RouteLocationNormalized[]>([]);

  const push = (route: RouteLocationNormalized) : void => {
    history.value.unshift(route);
    if (history.value.length > maxSize) {
      history.value.pop();
    }
  }

  return {
    getHistory: () : readonly RouteLocationNormalized[] => history.value,
    push,
    getMaxHistorySize: () => maxSize,
  }
})()
