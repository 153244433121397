import { PropType, ExtractPropTypes } from "vue"
import { CompetitionUID, CompetitionRegistration, Competition, Guid } from "src/interfaces/InleagueApiV1"
import { PlayerDetailsI } from "src/interfaces/Store/registration";
import { ExtractEmitsHandlers } from "src/helpers/utils";


export function propsDef() {
  return {
    availableCompetitionReassignmentTargetsMap: {
      required: true as const,
      type: Object as PropType<Record<CompetitionUID, Competition>>
    },
    target: {
      required: true as const,
      type: Object as PropType<{
        player: PlayerDetailsI,
        competitionRegistration: CompetitionRegistration,
        competition: Competition
      }>
    },
  }
}

export function emitsDef() {
  return {
    save: (_args: {targetCompetitionRegistration: CompetitionRegistration, newCompetitionUID: Guid}) => true,
    close: () => true,
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
