<!---
  This component is a route entry point and is route aware (i.e. we are OK w/ imports of {useRoute, useRouter})
  It should destroy/remount the family profile on route change
--->
<template lang="pug">
FamilyProfileImpl(
  v-bind="forwardedProps"
  :key="key"
)
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import * as FamilyProfile from "./FamilyProfile.ilx";
import C_FamilyProfileImpl from "./FamilyProfileImpl.vue"
import { useRoute } from "vue-router"

export default defineComponent({
  name: "FamilyProfile",
  components: {
    FamilyProfileImpl: C_FamilyProfileImpl
  },
  props: FamilyProfile.propsDef,
  setup(props) {
    const forwardedProps : FamilyProfile.Props = props;
    const route = useRoute();

    const key = computed<string>(() => {
      return route.fullPath;
    })

    return {
      forwardedProps,
      key
    }
  }
});
</script>
