import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { vReqT } from "src/helpers/utils"
import { defineComponent, ref } from "vue"
import { Btn2 } from "../UserInterface/Btn2"

export const LineupCardsModal = defineComponent({
  props: {
    lineUpCardsRosterLink: vReqT<(_: {mode: number, includeBackPage: boolean}) => string>()
  },
  setup(props) {
    const includeGameAndIncidentReportOnReverseSide = ref(false)
    const lineupCardModes : {mode: number, label: string}[] = [
      {mode: 0, label: `Official AYSO Line-Up Cards (4x/page, 18 players)`},
      {mode: 1, label: `Classic AYSO Line-Up Cards (2x/page, quarters, 18 players)`},
      {mode: 2, label: `Classic AYSO Line-Up Cards (2x/page, 8ths, 22 players)`},
    ]

    return () => {
      return (
        <div>
          <div class="flex items-center gap-2 border rounded-md py-1 px-2">
            <input style="margin-top:-16px" type="checkbox" v-model={includeGameAndIncidentReportOnReverseSide.value}/>
            <div class="text-sm">
              <div>Include Game Report and Incident Report</div>
              <div>on reverse side</div>
            </div>
          </div>
          <div>
            {lineupCardModes.map(v =>
              <a class="flex flex-col items-start mt-2" href={props.lineUpCardsRosterLink({mode: v.mode, includeBackPage: includeGameAndIncidentReportOnReverseSide.value})} target="_blank">
                <Btn2 class="px-2 py-1 w-full">
                  <div class="flex w-full">
                    <div class="flex flex-col items-start">
                      <div>Lineup Cards</div>
                      <div class="text-xs">{v.label}</div>
                    </div>
                    <span v-tooltip={{content: "Opens in another window"}} class="ml-auto">
                      <FontAwesomeIcon icon={faArrowCircleRight} class="-rotate-45 transform"/>
                    </span>
                  </div>
                </Btn2>
              </a>
            )}
          </div>
        </div>
      )
    }
  }
})
