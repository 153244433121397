//
// This lives in its own file for HMR considerations.
//

import axios from "axios"

// server should throw a 500 before we timeout, we'll get better stack traces that way
export const DEFAULT_AXIOS_TIMEOUT_MS = 10 * 60 * 1000; // 10 minutes, almost infinite for http purposes.
const UNINITIALIZED_API_URL = "<<UNINITIALIZED/MAKING-AN-HTTP-CALL-WITHOUT-INITIALIZING-THIS-IS-A-BUG>>"

// handler for requests that don't need an Authorization header or 401 handling:
export const axiosNoAuthInstance = axios.create({
  baseURL: UNINITIALIZED_API_URL,
  timeout: DEFAULT_AXIOS_TIMEOUT_MS,
})

// handler for background requests, no spinner displayed
// does not add Authorization header by default
export const axiosBackgroundInstance = axios.create({
  baseURL: UNINITIALIZED_API_URL,
  timeout: DEFAULT_AXIOS_TIMEOUT_MS,
})

// handler for background requests that require auth
export const axiosAuthBackgroundInstance = axios.create({
  baseURL: UNINITIALIZED_API_URL,
  timeout: DEFAULT_AXIOS_TIMEOUT_MS,
})

// handler for everything else, handles expired security tokens and inspects responses for token renewal
export const axiosInstance = axios.create({
  baseURL: UNINITIALIZED_API_URL,
  timeout: DEFAULT_AXIOS_TIMEOUT_MS,
})

/**
 * Used as the API URL for fresh axios instances
 * Should not be writeable from anywhere but a setter in this module.
 */
let mutable_global_apiURL = UNINITIALIZED_API_URL;

/**
 * Used as the API URL for fresh axios instances
 */
export function getGlobalApiURL() {
  return mutable_global_apiURL;
}

/**
 * This updates all "statically known" axios instances, and updates the global api URL value from which
 * dynamically generated axios instances can populate their url.
 *
 * Long ago, this was an arrow function that returned a Promise<string>,
 * it's not clear that any callers actually use the result, but it's also
 * not clear that all use sites were strongly typed, so patching this to return `void` (not Promise<void>)
 * is not entirely trivial.
 *
 * TLDR: returning anything other than exactly `void` is probably unnecessary, but proving that is more than a 10 second job.
 */
export async function updateApiUrl(url: string) : Promise<string> {
  axiosNoAuthInstance.defaults.baseURL = url
  axiosBackgroundInstance.defaults.baseURL = url
  axiosAuthBackgroundInstance.defaults.baseURL = url
  axiosInstance.defaults.baseURL = url

  mutable_global_apiURL = url;

  return url;
}

export const X_TOKEN_RENEWAL = "x-token-renewal"
