<template lang="pug">
FormKit(v-if="Object.keys(form).length > 0" @submit="createPageItem" type='form' :actions="false")

  div
    //- is there a better way to keep our container's max width to formkit's opinionated max width?
    div(class="p-2 mb-4 border border-dashed border-slate-200 shadow-md" style="max-width:var(--fk-max-width-input);")
      div(class="text-sm font-normal") Preview
      PageItem_Question_PreviewRender(:inputType='form.type', :label="form.label", :selectOptions="form.questionOptions")
  .max-w-md
    div(class="mb-2")
      .flex.flex-row
        FormKit(
          outer-class="$reset"
          label="Label"
          type="text"
          name='label',
          v-model='form.label',
          validations='required',
        )
        button.ml-1.cursor-pointer(
          v-tooltip='{content: `Question label displayed on the registration form.`}'
        )
          font-awesome-icon.mr-2.text-gray-400(
            :icon='["fas", "info-circle"]'
          )
      div(v-if="errors.missingLabel" class="text-red-700 text-sm") Label is required
    div(class="mb-2")
      .flex.flex-row
        FormKit(
          outer-class="$reset"
          label='Short Label',
          type="text"
          name='shortLabel',
          v-model='form.shortLabel',
          validations='required',
        )
        button.ml-1.cursor-pointer(
          v-tooltip='{content: `Shorthand reference to the registration field used in administration and reporting.`}'
        )
          font-awesome-icon.mr-2.text-gray-400(
            :icon='["fas", "info-circle"]'
          )
      div(v-if="errors.missingShortLabel" class="text-red-700 text-sm") Short label is required

    .flex.flex-wrap.pt-5
      FormKit.flex-auto.gap-4.h-full(
        type="checkbox"
        name='isEditable',
        v-model='form.isEditable',
        validations='required',
        data-cy='isEditable'
      )
      label.font-medium.text-smt-label.ml-5 User May Edit Answer
        button.ml-1.cursor-pointer(
            v-tooltip='{content: `If true, the value of this field may be edited by a user after the registration is submitted.`}'
          )
          font-awesome-icon.mr-2.text-gray-400(
            :icon='["fas", "info-circle"]'
          )
    .flex.flex-wrap.pt-5
      FormKit.m-2(
        type="checkbox"
        name='isRequired',
        v-model='form.isRequired',
        validations='required',
        data-cy='isRequired'
      )
      label.font-medium.text-smt-label.ml-5 Response Required
        button.ml-3.cursor-pointer(
            v-tooltip='{content: `If true, a response is required for players who have this question in their form.`}'
          )
          font-awesome-icon.mr-2.text-gray-400(
            :icon='["fas", "info-circle"]'
          )
  div(v-if="form.type === QuestionType.CHECKBOX || form.type === QuestionType.RADIO || form.type === QuestionType.SELECT")
    label.font-medium.text-smt-label.block.mt-6.ml-2.mb-4 Question Options
    div(v-if="errors.questionOptions.hasNoneButNeedsOne" class="text-red-700") This question type requires at least one question option.
    Options.pt-4(
      :questionID="questionID"
      :options="form.questionOptions"
      :triggerSave="triggerSave"
      :errors="errors.questionOptions.each"
      mode="new"
      @apiError="apiError=true"
    )
  GateFunctionEditor(@apiError="apiError=true" labelItem="Registration Item" :item="form", :gateQuestionType='1', v-model:gateFunction="form.gateFunctionName" :triggerSave="triggerSave" :itemID="questionID" @updateStatus="status=>updateStatus(status, 'gateFunction')")
  SeasonSelector(@apiError="apiError=true" labelItem="Registration Item" :selectedSeasonsObjects='seasonUIDs' @update:seasonUIDs="(val)=>seasonUIDs=val" :triggerSave="triggerSave" :pageItemID="pageItemID" @updateStatus="status=>updateStatus(status, 'season')")
  CompetitionSelector(
    data-test="CompetitionSelector"
    @apiError="apiError=true" labelItem="Registration Item"
    @update:competitionUIDs="val=>competitionUIDs=val"
    @updateStatus="status=>updateStatus(status, 'competition')"
    :selectedCompetitionsObjects="competitionUIDs"
    :triggerSave="triggerSave"
    :pageItemID="pageItemID"
  )
  t-btn(:class="`${hasSomeSubmitBlockingError ? 'bg-gray-300' : ''}`" :disable="hasSomeSubmitBlockingError")
    div Save Custom Question
  div(v-if="hasSomeSubmitBlockingError" class="text-sm text-red-700") Sorry, not all fields are filled out correctly.
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'

import { useRouter } from 'vue-router'
import { PageItem_ambiguously_QuestionOrContentChunk } from 'src/interfaces/Store/registration'
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, ref, Ref, watch } from 'vue'
import SeasonSelector from 'src/components/Registration/admin/SeasonSelector.vue'
import CompetitionSelector from 'src/components/Registration/admin/CompetitionSelector.vue'
import GateFunctionEditor from 'src/components/Registration/admin/GateFunctionEditor.vue'
import Options from 'src/components/Registration/admin/Options.vue'

import { PageItem_Question_PreviewRender } from 'src/components/Registration/admin/PageItem_Question_PreviewRender'
import { QuestionFormErrors, questionformErrorsHasAtLeastOneSingleError } from 'src/components/Registration/admin/pageItem/PageItem'

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { useIziToast } from 'src/helpers/utils'

import { RegistrationStore } from "src/store/Registration"

export default defineComponent({
  components: {
    SeasonSelector,
    GateFunctionEditor,
    Options,
    CompetitionSelector,
    PageItem_Question_PreviewRender,
  },
  props: {
    item: {
      type: Object as PropType<iltypes.RegistrationQuestion>,
      required: true
    },
    errors: {
      required: true,
      type: Object as PropType<QuestionFormErrors>
    }
  },
  setup(props) {
    const form = ref<iltypes.RegistrationQuestion>(props.item)
    const seasonUIDs = ref<iltypes.Guid[]>([])
    const competitionUIDs = ref<iltypes.Guid[]>([])
    const triggerSave = ref(false)
    const pageItemID = ref('')
    const questionID = ref('')
    const apiError = ref(false)

    // TODO: what is this? "question statuses" -- what kind of status, error, validity, answers, etc. keys represent ??? values represent ???
    const questionStati = ref({}) as Ref<{[key:string]: string}>


    const router = useRouter()

    const setCustomFields = async () => {
      const customFields = await RegistrationStore.getCustomFields()
      RegistrationStore.directCommit_setCustomFields(customFields)
      await RegistrationStore.createCustomFieldsOptionFormat(customFields)
    }

    const hasSomeSubmitBlockingError = computed(() => questionformErrorsHasAtLeastOneSingleError(props.errors))

    const createPageItem = async () : Promise<void> => {
      if (hasSomeSubmitBlockingError.value) {
        return;
      }

      try {
        apiError.value = false

        // if we saved the item, but failed while recursing through questions/questionOptions/gateFunctions persistence (which are all separate calls)
        // this will create an entirely separate question.
        const createdPageItem = await ilapi.createCustomQuestion(axiosInstance, questionToSubmittableCreateQuestionArgs(form.value));

        pageItemID.value = createdPageItem.id
        questionID.value = createdPageItem.questionID
        triggerSave.value = true
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        apiError.value = true
      }
    }

    const updateStatus = (status: string, item: string) => {
      questionStati.value[item]=status
    }

    watch(form, (val, prevVal) => {
      if(!prevVal) questionStati.value.question='pending'
    }, {deep:true})

    watch([triggerSave, questionStati], (val: [boolean, {[key: string]: string}])=> {
      if(val[0]) {
        let allPropertiesStatus = 'saved'
        for(const status in val[1]) {
          if(val[1][status]!="saved") {
            allPropertiesStatus = 'pending'
          }
        }
        if(allPropertiesStatus==='saved') {
          if(!apiError.value) {
            router.push({name: 'registration-admin'}).catch((err) => {
              // console.log('err', err)
            })
          }
        }
      }
    }, {deep:true})

    watch(apiError, (val)=> {
      if(apiError.value) {
        triggerSave.value = false
      }
    })

    onMounted(async () => {
      await setCustomFields()
    })

    return {
      form,
      triggerSave,
      createPageItem,
      seasonUIDs,
      pageItemID,
      competitionUIDs,
      questionID,
      apiError,
      updateStatus,
      questionStati,
      QuestionType: iltypes.QuestionType,
      hasSomeSubmitBlockingError
    }
  },
})

function questionToSubmittableCreateQuestionArgs(q: iltypes.RegistrationQuestion) : ilapi.CreateCustomQuestionArgs {
  return {
    label: q.label,
    shortLabel: q.shortLabel,
    type: q.type,
    isRequired: !!q.isRequired,
    isEditable: !!q.isEditable,
    isDisabled: !!q.isDisabled,
    gateFunctionName: iltypes.cfNullGetOr(q.gateFunctionName, "")
  }
}
</script>
