import { Role } from "src/interfaces/Store/user";

export const atLeastOneMatchingRoleMeansUserIsAuthorizedForFeature = {
  "permissions": ['webmaster','registrar','scholarshipAdmin'],
  "coach-data": ['volunteerAdmin', 'registrar', 'Board', 'Division Director', 'webmaster'],
  "merge-user": ['webmaster','registrar'],
  "scholarships": ['registrar', 'webmaster', 'scholarshipAdmin'],
  "volunteer-points": ['volunteerAdmin', 'registrar', 'webmaster', 'refScheduler'],
  "custom-fields": ['registrar', 'webmaster', 'Division Coordinator'],
} as const satisfies Record<string, Role[]>;
