<template lang="pug">
.grid.grid-cols-1.gap-4(v-if="ready")
  div
    Volunteers(:seasonUID="seasonUID" :family='family' @parentOrdinalsUpdated="emit('parentOrdinalsUpdated')" :key="familySeasonKey")
  div
    Players(:seasonUID="seasonUID" :family='family', :childRegistrationMapping="childRegistrationMapping" :key="familySeasonKey")
</template>

<script lang="ts" setup>
import Players from 'src/components/FamilyProfile/Players.vue'
import { onMounted, computed, ref } from 'vue'
import { axiosInstance } from 'src/boot/axios'
import Volunteers from 'src/components/FamilyProfile/Volunteers.vue'
import type { FamilyData } from 'src/composables/InleagueApiV1.Family'
import type {PropType} from 'vue'
import type { Guid, Registration } from 'src/interfaces/InleagueApiV1'

import { Client } from 'src/store/Client'

const props=defineProps({
  seasonUID: {
    required: true,
    type: String
  },
  family: {
    required: true,
    type: Object as PropType<FamilyData>
  },
  childRegistrationMapping: {
    required: true,
    type: Object as PropType<{[childID: Guid]: Registration | null}>
  }
})

const emit = defineEmits(['parentOrdinalsUpdated'])



const familySeasonKey = computed(() => {
  return `${props.family.familyID}/${props.seasonUID}`
})

const ready = ref(false)

onMounted(async () => {
  await Client.loadSeasons()
  ready.value = true
})
</script>
