import * as iltypes from "src/interfaces/InleagueApiV1"

/**
 * enum for all possible statuses of a competition registration
 */
const CompetitionRegistrationStatus_t = {
  REGISTERED: "REGISTERED",
  WAITLISTED_PENDING_PAYMENT: "WAITLISTED_PENDING_PAYMENT",
  WAITLISTED: "WAITLISTED",
  COMPLETED_THEN_CANCELED: "COMPLETED_THEN_CANCELED",
  INCOMPLETE: "INCOMPLETE",
  CANCELED: "CANCELED",
  // Kludgy case to represent that we might not catch 'em all, because we
  // compute the status in terms of arbitrary logic which can't (easily?) be proven exhaustive.
  // It would be good to prove to ourselves that this never possible.
  "???": "???",
} as const;
type CompetitionRegistrationStatus_t = keyof typeof CompetitionRegistrationStatus_t;
const CompetitionRegistrationStatusUiString : Record<CompetitionRegistrationStatus_t, string> = {
  REGISTERED: "Registered",
  WAITLISTED_PENDING_PAYMENT: "Waitlisted (Activation Pending Payment)",
  WAITLISTED: "Waitlisted",
  COMPLETED_THEN_CANCELED: "Dropped",
  INCOMPLETE: "Incomplete",
  CANCELED: "Canceled",
  "???": "???"
}

/**
 * map a competition registration to its "current state"
 */
function getCompRegStatus(compReg: iltypes.CompetitionRegistration) : CompetitionRegistrationStatus_t {
  if (compReg.paid && !compReg.canceled && !compReg.waitlist) {
    return CompetitionRegistrationStatus_t.REGISTERED;
  }
  else if (compReg.isWaitlistedButUnpaid) {
    return CompetitionRegistrationStatus_t.WAITLISTED_PENDING_PAYMENT;
  }
  else if (!compReg.canceled && compReg.waitlist && compReg.paid) {
    return CompetitionRegistrationStatus_t.WAITLISTED;
  }
  else if (compReg.paid && compReg.canceled) {
    return CompetitionRegistrationStatus_t.COMPLETED_THEN_CANCELED;
  }
  else if (!compReg.paid && !compReg.canceled) {
    return CompetitionRegistrationStatus_t.INCOMPLETE;
  }
  else if (compReg.canceled) {
    return CompetitionRegistrationStatus_t.CANCELED;
  }
  else {
    // unexpected case; shouldn't happen, but let's not crash on it
    return CompetitionRegistrationStatus_t["???"];
  }
}

export function getCompRegStatusUiString(compReg: iltypes.CompetitionRegistration) : string {
  return CompetitionRegistrationStatusUiString[getCompRegStatus(compReg)];
}

/**
 * Given many competition registrations (with the assumption that they are all for the same owning registration, i.e. for the same child/season),
 * answer the question: what is the "overall" status of the owning registration?
 * e.g. if there is one fully registered and 2 incomplete, the overall status would be "REGISTERED"
 */
export function getAggregateRegistrationStatusUiString(compRegs: iltypes.CompetitionRegistration[]) : string {
  if (compRegs.length === 0) {
    return "";
  }
  else {
    return CompetitionRegistrationStatusUiString[getTotallyOrderedStatuses()[0]];
  }

  function getTotallyOrderedStatuses() {
    const ordering : Record<CompetitionRegistrationStatus_t, number> = {
      REGISTERED: 0,
      WAITLISTED: 1,
      WAITLISTED_PENDING_PAYMENT: 2,
      COMPLETED_THEN_CANCELED: 3,
      INCOMPLETE: 4,
      CANCELED: 5,
      "???": 6,
    }
    return compRegs
      .map(compReg => {
        const status = getCompRegStatus(compReg);
        return [ordering[status], status] as const;
      })
      .sort((l, r) => l[0] < r[0] ? -1 : l[0] === r[0] ? 0 : 1)
      .map(v => v[1])
  }
}
