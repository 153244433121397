/**
 * This file exists as a bridge for a component that is a vue SFC file that really strongly wants to be a tsx file
 * We have:
 *  - R_EventRosterImpl.ilx.tsx
 *  - R_EventRosterImpl.iltsx.tsx
 *  - R_EventRosterImpl.vue
 *
 * If we refactor the vue SFC file to be a TSX based component, the `iltsx.tsx` and `.vue` files could be merged.
 * The remaining `ilx.tsx` file is an artifact of a design prior to using JSX, where all vue files would need
 * "codebehind" files to share typeinfo; migrating to jsx based vue components probably removes the need for
 * such files, too.
 *
 * There may also be some cross-platform (node vs. browser) dependency issues from misc. (transitive) imports, but that should
 * shake out as part of the above refactoring.
 */
import { computed, defineComponent, ExtractPropTypes, PropType, ref, Ref, watch } from "vue"
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import dayjs from "dayjs"
import { RouteLocationRaw, RouterLink } from "vue-router";

import { buildLegacyLink } from "src/boot/auth";

import { assertTruthy, downloadFromObjectURL, isObject, maybePrettifyUSPhoneNumber, UnionToIntersection, unsafe_objectKeys, vReqT } from "src/helpers/utils";
import * as XlsxUtils from "src/modules/XlsxUtils"

import { ExpandedEventTrackerState, EventRosterListingEventBus, ExpandedEvent } from "./R_EventRosterImpl.ilx";

import * as M_EventSignupCancellationButton from "./EventSignupCancellationButton";
import { EventRosterLayoutDetail } from "src/composables/InleagueApiV1.Event";
import { Client } from "src/store/Client";
import { Guid } from "src/interfaces/InleagueApiV1";

/**
 * This saves some minor work of not having to wrap things in <span> from cellValue functions,
 * which means we can reuse the generated value (a string) directly as xlsx cell values.
 *
 * We must declare dependencies on the results of calls cellvalue(...), which seems to indicate
 * this design could be improved.
 *
 * usage is like
 * component(:is="SignupColumnRenderer" :colDef="colDef" :signup="signup")
 */
export const SignupColumnRenderer = defineComponent({
  components: {
    EventSignupCancellationButton: M_EventSignupCancellationButton.component
  },
  props: {
    colDef: {
      required: true,
      type: Object as PropType<ColDef>,
    },
    signup: {
      required: true,
      type: Object as PropType<ilapi.event.EventSignup>
    }
  },
  render() {
    const value = this.colDef.html.cellValue(this.signup);
    return typeof value === "string"
      ? <span>{value}</span>
      : value;
  }
});

interface ColDef {
  [key: string]: any,
  name: string,
  label: string,
  html: {
    cellValue: (_: ilapi.event.EventSignup) => JSX.Element | string,
    if?: () => boolean,
  }
  xlsx?:
    | "never"
    | {
        type: "one-cell"
        cellValue: (_: ilapi.event.EventSignup) => string
        if?: () => boolean
      }
    | {
      /**
       * One column definition may emit many xlsx cells.
       * `labels.length` must be exactly the same as the length of the array resulting from invoking `cellValues()`
       */
      type: "many-cells",
      labels: string[]
      cellValues: (_: ilapi.event.EventSignup) => string[],
      if?: () => boolean
    }
}

export function EventSignupColDefs(eventBus: EventRosterListingEventBus, eventTracker: Ref<ExpandedEventTrackerState>) {
  const event = computed(() => eventTracker.value.expandedEvent.value);
  const getters = eventBoundSignupGetters(eventTracker);
  const signupMetadata = computed(() => getSignupMetadata(event.value.signups))
  return [
    {
      name: "name",
      label: "Name",
      html: {
        cellValue: (signup) => {
          const name = signup.childID
            ? `${signup.childFirstName} ${signup.childLastName}`
            : `${signup.userFirstName} ${signup.userLastName}`;
          if (signup.paid) {
            return <div>
              {name}
              {signup.canceled
                ? <div class="text-xs font-bold text-blue-900">(canceled)</div>
                : null
              }
            </div>
          }
          else if (signup.canceled) {
            return (
              <>
                <div class="text-xs font-bold text-blue-900">(canceled)</div>
                <div>{name}</div>
              </>
            )
          }
          else {
            return (
              <>
                <div class="text-xs font-bold text-blue-900">(Not yet paid)</div>
                <div>{name}</div>
              </>
            )
          }
        },
      },
      xlsx: {
        type: "many-cells",
        labels: ["First Name", "Last Name"],
        cellValues: signup => {
          return [
            signup.childID ? signup.childFirstName : signup.userFirstName,
            signup.childID ? signup.childLastName : signup.userLastName
          ]
        }
      }
    },
    {
      name: "birthdate",
      label: "Player birthdate",
      html: {
        cellValue: signup => getters.getBirthdateUiString(signup),
        if: () => signupMetadata.value.hasPlayerSignup
      }
    },
    {
      name: "playerAge",
      label: "Player age",
      html: {
        cellValue: signup => getters.getCalcAgeUiString(signup),
        if: () => signupMetadata.value.hasPlayerSignup
      }
    },
    {
      name: "divisions",
      label: "Div",
      html: {
        cellValue: signup => getters.getPlayerDivisionUiString(signup),
        if: () => signupMetadata.value.hasPlayerSignup
      }
    },
    {
      name: "gender",
      label: "Gender",
      html: {
        cellValue: signup => signup.childID ? event.value.rosterLayoutDetail.children[signup.childID].playerGender : "",
        if: () => signupMetadata.value.hasPlayerSignup
      }
    },
    {
      name: "type",
      label: "Type",
      html: {
        cellValue: signup => signup.childID ? "Player" : "Adult",
        if: () => signupMetadata.value.hasPlayerSignup && signupMetadata.value.hasUserSignup
      }
    },
    {
      name: "aysoID",
      label: "AYSO ID",
      html: {
        cellValue: signup => getters.getAysoID(signup)
      }
    },
    {
      name: "region",
      label: "Region",
      html: {
        cellValue: signup => getters.getRegionUiString(signup),
      }
    },
    {
      name: "signupDate",
      label: "Signup Date",
      html: {
        cellValue: signup => signup.signupDate ? dayjs(signup.signupDate).format("MMM/DD/YYYY") : ""
      }
    },
    {
      name: "isPaid",
      label: "Fully paid (or was zero-fee)",
      html: {
        // here we want this output only into xlsx, we need to make html an optional property
        cellValue: () => "",
        if: () => false
      },
      xlsx: {
        type: "one-cell",
        cellValue: signup => signup.paid ? "yes" : "no"
      }
    },
    {
      name: "invoice",
      label: "Invoice",
      html: {
        cellValue: signup => {
          if (signup.invoiceInstanceID) {
            const routeLocation = getters.getInvoiceRouteLocation(signup);
            if (routeLocation) {
              return (
                <RouterLink to={routeLocation}>
                  <span>{signup.invoiceInstanceID}</span>
                </RouterLink>
              );
            }
            else {
              return "";
            }
          }
          else if (signup.invoiceNo) {
            return (
              <div>
                <div class="text-xs">legacy</div>
                <a href={getters.legacyEventSignupRefundURL(signup)} target="_blank">{signup.invoiceNo}</a>
              </div>
            )
          }
          else {
            return "";
          }
        }
      },
      xlsx: {
        type: "one-cell",
        cellValue: signup => signup.invoiceInstanceID?.toString() || ""
      }
    },
    {
      name: "email",
      label: "Email",
      html: {
        cellValue: signup => {
          const email = getters.getEmailDetailsForHTML(signup);
          return (
            <div>
              <div>{email.primary}</div>
              <div>{email.alternate}</div>
            </div>
          )
        }
      },
      xlsx: {
        type: "one-cell",
        // TODO: use "mult-cell" for the possibly 2 emails, parent1/parent2
        cellValue: signup => {
          return signup.userID
            ? event.value.rosterLayoutDetail.users[signup.userID]?.email || ""
            : event.value.rosterLayoutDetail.children[signup.childID]?.parent1Email || ""
        }
      }
    },
    {
      name: "phone",
      label: "Phone",
      html: {
        cellValue: signup => {
          const phone = getters.getPhoneDetails(signup);
          return (
            <div>
              <div>{maybePrettifyUSPhoneNumber(phone.primary)}</div>
              <div>{maybePrettifyUSPhoneNumber(phone.alternate)}</div>
            </div>
          )
        }
      },
      xlsx: {
        type: "one-cell",
        cellValue: signup => {
          return signup.userID
            ? maybePrettifyUSPhoneNumber(event.value.rosterLayoutDetail.users[signup.userID]?.primaryPhone || "")
            : maybePrettifyUSPhoneNumber(event.value.rosterLayoutDetail.children[signup.childID]?.parent1Phone || "")
        }
      }
    },
    {
      name: "comments",
      label: "Comments",
      html: {
        cellValue: signup => <CommentEditor comment={signup.comments} onDoUpdate={freshComment => eventBus.triggerUpdateComment(signup, freshComment)} class="mb-1"/>
      },
      xlsx: {
        type: "one-cell",
        cellValue: signup => signup.comments || ""
      }
    },
    {
      name: "move",
      label: "Move",
      html: {
        cellValue: signup => {
          // if we're here, it's because the user has permission to view this (same with the cancellation button)
          return signup.canBeMoved
            ? (
              <t-btn margin={false} class="text-xs" data-test="move-signup" style="padding:.5em;" onClick={() => eventBus.triggerMoveSignup(signup)}>Move signup to another event</t-btn>
            )
            : ""
        }
      },
      xlsx: "never"
    },
    {
      name: "cancel",
      label: "Cancel",
      html: {
        cellValue: signup => {
          return (
            <EventSignupCancellationButton
              signup={signup}
              eventBoundSignupGetters={getters}
              eventRosterListingEventBus={eventBus}
            />
          );
        }
      },
      xlsx: "never"
    }
  ] as const satisfies readonly ColDef[]
}

const CommentEditor = defineComponent({
  name: "CommentEditor",
  props: {
    comment: {
      required: true,
      type: String
    }
  },
  emits: {
    doUpdate: (_freshComment: string) => true,
  },
  setup(props, {emit}) {
    const MAXLEN = 1000; // TODO: pull from server or local source of truth

    const localComment = ref(props.comment);

    const isDirty = computed(() => localComment.value !== props.comment);
    const canSubmit = computed(() => isDirty.value);

    watch(() => props.comment, () => {
      localComment.value = props.comment;
    })

    return () => (
      <div data-test="CommentEditor">
        <div>
          <textarea class="w-full" v-model={localComment.value} maxlength={MAXLEN}/>
        </div>
        <div class="flex justify-end">
          <t-btn
            style="padding:.5em;"
            data-test="submit"
            margin={false}
            disable={!canSubmit.value}
            class={`${canSubmit.value ? '' : 'bg-gray-300'} text-xs`}
            onClick={()=>{emit("doUpdate", localComment.value)}}
          >Save comment changes</t-btn>
        </div>
      </div>
    )
  }
})
export function colDefsAsMap<T extends ColDef>(colDefs: readonly T[]) : UnionToIntersection<T extends any ? {[P in T["name"]]: T} : never> {
  const result : Record<string, ColDef> = {};
  for (const colDef of colDefs) {
    result[colDef.name] = colDef;
  }
  return result as any;
}

export function getSignupMetadata(signups: ilapi.event.EventSignup[]) {
  let hasPlayerSignup = false;
  let hasUserSignup = false;
  for (const signup of signups) {
    hasPlayerSignup = hasPlayerSignup || !!signup.childID;
    hasUserSignup = hasUserSignup || !!signup.userID;
  }
  return {
    hasPlayerSignup,
    hasUserSignup,
  }
}
export type SignupMetadata = ReturnType<typeof getSignupMetadata>;

function eventBoundSignupGetters(eventTracker: Ref<ExpandedEventTrackerState>) {
  // alias, cuts down on property navigations; computed should (!?) be robust against top level rebindings
  // but caller should throw this whole object away when they get an entire new tracker state
  const event = computed(() => eventTracker.value.expandedEvent.value);

  const getPlayerDivisionUiString = (signup: ilapi.event.EventSignup) : string => {
    if (signup.childID) {
      return event
        .value
        .rosterLayoutDetail
        .childrenComputedDetail[signup.childID]
        .divisions
        .map(division => division.displayName)
        .join(", ") || "?";
    }
    else {
      // adult, there's no division to display
      return "";
    }
  }

  const getAysoID = (signup: ilapi.event.EventSignup) : string => {
    return signup.childID
      ? event.value.rosterLayoutDetail.children[signup.childID].AYSOID
      : event.value.rosterLayoutDetail.users[signup.userID].AYSOID;
  }

  const getRegionUiString = (_signup: ilapi.event.EventSignup) : string => {
    // does this change per signup?
    return Client.value.instanceConfig.regionname;
  }

  const getEmailDetailsForHTML = (signup: ilapi.event.EventSignup) : {primary: string, alternate: string} => {
    if (signup.userID) {
      return {
        primary: event.value.rosterLayoutDetail.users[signup.userID].email || "",
        alternate: ""
      }
    }
    else {
      const child = event.value.rosterLayoutDetail.children[signup.childID];

      const primary = child.parent1Email;
      const alternate = child.parent2Email;
      const parent1Name = `${child.parent1FirstName} ${child.parent1LastName}`;
      const parent2Name = `${child.parent2FirstName} ${child.parent2LastName}`;

      return {
        primary: primary ? `${parent1Name}: ${primary}` : "",
        alternate: alternate ? `${parent2Name}: ${alternate}` : "",
      }
    }
  }

  const getPhoneDetails = (signup: ilapi.event.EventSignup) : {primary: string, alternate: string} => {
    if (signup.userID) {
      return {
        primary: event.value.rosterLayoutDetail.users[signup.userID].primaryPhone || "",
        alternate: ""
      }
    }
    else {
      const child = event.value.rosterLayoutDetail.children[signup.childID];

      const primary = child.parent1Phone
      const parent1Name = `${child.parent1FirstName} ${child.parent1LastName}`;
      const alternate = child.parent2Phone
      const parent2Name = `${child.parent2FirstName} ${child.parent2LastName}`;

      return {
        primary: primary ? `${parent1Name}: ${primary}` : "",
        alternate: alternate ? `${parent2Name}: ${alternate}` : ""
      }
    }
  }

  const getCalcAgeUiString = (signup: ilapi.event.EventSignup) => {
    return signup.childID
      ? event.value.rosterLayoutDetail.childrenComputedDetail[signup.childID].calcAge.toString()
      : ""
  }

  const getBirthdateUiString = (signup: ilapi.event.EventSignup) => {
    if (signup.childID) {
      const rawDate = event.value.rosterLayoutDetail.children[signup.childID].playerBirthDate;
      const parsedDate = dayjs(rawDate);
      return parsedDate.isValid() ? parsedDate.format("MMM-D-YYYY") : rawDate;
    }
    else {
      // we don't show a birthdate for non-players?
      return "";
    }
  }

  const getInvoiceRouteLocation = (signup: ilapi.event.EventSignup) : RouteLocationRaw | null => {
    if (signup.invoiceInstanceID || signup.canceledInvoiceInstanceID) {
      return {name: "master-invoice", params: {invoiceID: signup.invoiceInstanceID || signup.canceledInvoiceInstanceID} };
    }
    else {
      return null;
    }
  }

  const legacyEventSignupRefundURL = (signup: ilapi.event.EventSignup) => {
    const url = `/events/event-refund.cfm?invoiceNo=${signup.invoiceNo}&&eventID=${signup.eventID}`;
    return buildLegacyLink(Client.value.instanceConfig.appdomain, /*urlTarget*/url, /*eventTarget*/"");
  }

  return {
    getPlayerDivisionUiString,
    getAysoID,
    getRegionUiString,
    getPhoneDetails,
    getEmailDetailsForHTML,
    getCalcAgeUiString,
    getBirthdateUiString,
    getInvoiceRouteLocation,
    legacyEventSignupRefundURL
  }
}
export type EventBoundSignupGetters = ReturnType<typeof eventBoundSignupGetters>;

export async function downloadEventSignupsAsXLSX(colDefs: readonly ColDef[], signups: ilapi.event.EventSignup[], rosterLayoutDetail: ilapi.event.EventRosterLayoutDetail) : Promise<void> {
  // we could name this externally but right now we only need it here
  type MaybeAdhocAnswerDetail = EventRosterLayoutDetail["questionAnswers"][string];

  const cols = colDefs
    .filter((colDef) => {
      if (colDef.xlsx === "never") {
        // never includeable
        return false;
      }
      if (colDef.xlsx) {
        // there's an xlsx def, if it has an if, run it, otherwise, it defaults to to true
        return colDef.xlsx.if?.() ?? true;
      }
      // no xlsx def, but it wasn't explicitly discluded. fallback to html value.
      return colDef.html.if?.() ?? true;
    });

  const trailingAnswerCols = getQuestionColumnDefs();
  const builder = XlsxUtils.builderWithKludgyAutoWidths([
    ...cols.flatMap(colDef => {
      return isObject(colDef.xlsx) && colDef.xlsx.type === "many-cells"
        ? colDef.xlsx.labels
        : colDef.label
    }),
    ...trailingAnswerCols.map(col => col.shortLabel)
  ]);

  for (const signup of signups) {
    const primaryRow = cols.flatMap(col => {
      if (col.xlsx === "never") {
        // shouldn't happen, we ought to have filtered this away by here
        return "";
      }
      else if (col.xlsx) {
        if (col.xlsx.type === "many-cells") {
          const values = col.xlsx.cellValues(signup)
          assertTruthy(values.length === col.xlsx.labels.length, "resulting value count must be same as label count")
          return values;
        }
        else {
          return col.xlsx.cellValue(signup);
        }
      }
      else {
        // It's possible the cellValue returns a JSX element, which we can't do anything with here.
        // If we've reached this arm but we're not getting a string out of html.cellValue, it's a bug (probably in the coldef).
        const shouldBeString = col.html.cellValue(signup);
        return typeof shouldBeString === "string" ? shouldBeString : "";
      }
    });

    const answers = trailingAnswerCols.map(({questionID}) => getAnswer(rosterLayoutDetail.questionAnswers[signup.eventSignupID], questionID));

    builder.pushRow([...primaryRow, ...answers]);
  }

  downloadFromObjectURL(await builder.build(), "Event Roster.xlsx");

  /**
   * the contract is that if an answer listing exists for some eventSignup, then it contains an
   * array of (answer-or-maybe-some-string-indicating-no-answer) + (some question metadata) for every
   * question on this event. Thus, we only to need to find the first truthy thing and can return all the labels for
   * all the questions inside.
   */
  function getQuestionColumnDefs() {
    for (const eventSignupID of unsafe_objectKeys(rosterLayoutDetail.questionAnswers)) {
      const answerListingForEventSignup = rosterLayoutDetail.questionAnswers[eventSignupID];
      if (!answerListingForEventSignup) {
        continue;
      }
      return answerListingForEventSignup;
    }
    return [];
  }

  function getAnswer(answerDetail: MaybeAdhocAnswerDetail, targetQuestionID: iltypes.Guid) : string {
    return answerDetail?.find(v => v.questionID === targetQuestionID)?.answer ?? "";
  }
}

const C_TinySignupsListing_propsDef = {
  colDefs: vReqT<ReturnType<typeof EventSignupColDefs>>(),
  signups: vReqT<ilapi.event.EventSignup[]>(),
  highlighted: vReqT<{[eventSignupID: iltypes.Guid]: boolean}>(),
  eventData: vReqT<ExpandedEvent>(),
} as const;
export type C_TinySignupsListing_Props = ExtractPropTypes<typeof C_TinySignupsListing_propsDef>;

export const C_TinySignupsListing = defineComponent({
  components: {
    EventSignupCancellationButton: M_EventSignupCancellationButton.component,
  },
  props: C_TinySignupsListing_propsDef,
  setup(props) {
    const coldefsLookup = computed(() => colDefsAsMap(props.colDefs));
    return {
      coldefsLookup
    }
  },
  render() {
    return (
      <div>
        {
          this.signups.length === 0
          ? <div>No signups found</div>
          : this.signups.map((signup, idx) => (
              // data-test here should jive with the "non-tiny" version from R_EventRosterImpl.vue (but note, there it's rooted on a <tr/>, here we're a <section/>)
              <section data-test={`entityID=${signup.childID || signup.userID}`} class={`p-2 shadow-md rounded-lg border border-gray-200 mb-2 divide-y ${this.highlighted[signup.eventSignupID] ? "bg-yellow-100" : ""}`}>
                <div class="grid grid-cols-2 pb-1">
                  <div>
                    <div class="flex">
                      <span class="text-xs font-bold">{this.coldefsLookup.name.label}</span>
                      <span class={["text-xs", "ml-1", signup.childID ? "text-gray-200" : "text-green-700"]}>Adult</span>
                      <span class={["text-xs", "ml-1", signup.childID ? "text-green-700" : "text-gray-200"]}>Player</span>
                    </div>
                    <div>
                      <span>{this.coldefsLookup.name.html.cellValue(signup)}</span>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex">
                      <span class="text-xs font-bold">{this.coldefsLookup.signupDate.label}</span>
                    </div>
                    <div>
                      {this.coldefsLookup.signupDate.html.cellValue(signup)}
                    </div>
                  </div>
                </div>
                {
                  signup.childID
                  ? ([
                    <div class="grid grid-cols-3 pb-1">
                      <div>
                        <div class="text-xs font-bold">{this.coldefsLookup.birthdate.label}</div>
                        <div>{this.coldefsLookup.birthdate.html.cellValue(signup)}</div>
                      </div>
                      <div>
                        <div class="text-xs font-bold">{this.coldefsLookup.playerAge.label}</div>
                        <div>{this.coldefsLookup.playerAge.html.cellValue(signup)}</div>
                      </div>
                      <div>
                        <div class="text-xs font-bold">{this.coldefsLookup.gender.label}</div>
                        <div>{this.coldefsLookup.gender.html.cellValue(signup)}</div>
                      </div>
                    </div>,
                    <div>
                      <div class="text-xs font-bold">{this.coldefsLookup.divisions.label}</div>
                      <div>{this.coldefsLookup.divisions.html.cellValue(signup)}</div>
                    </div>,
                  ])
                  : null
                }
                <div class="grid grid-cols-2 pb-1">
                  <div>
                    <div class="text-xs font-bold">{this.coldefsLookup.aysoID.label}</div>
                    <div>{this.coldefsLookup.aysoID.html.cellValue(signup) || <span>&nbsp;</span>}</div>
                  </div>
                  <div>
                    <div class="text-xs font-bold">{this.coldefsLookup.region.label}</div>
                    <div>{this.coldefsLookup.region.html.cellValue(signup)}</div>
                  </div>
                </div>
                <div>
                  <div class="text-xs font-bold">{this.coldefsLookup.email.label}</div>
                  <div>{this.coldefsLookup.email.html.cellValue(signup) || <span>&nbsp;</span>}</div>
                </div>
                <div>
                  <div class="text-xs font-bold">{this.coldefsLookup.phone.label}</div>
                  <div>{this.coldefsLookup.phone.html.cellValue(signup) || <span>&nbsp;</span>}</div>
                </div>
                <div>
                  <QuestionAnswersListing eventData={this.eventData} eventSignupID={signup.eventSignupID}>
                    {{
                      header: () => <div class="text-xs font-bold">Question Answers</div>
                    }}
                  </QuestionAnswersListing>
                </div>
                <div>
                  <div>
                    <div class="text-xs font-bold">{this.coldefsLookup.comments.label}</div>
                    <div>{this.coldefsLookup.comments.html.cellValue(signup)}</div>
                  </div>
                </div>
                <div class="flex items-center pt-1">
                    <div>{this.coldefsLookup.move.html.cellValue(signup)}</div>
                    <div class="ml-auto">{this.coldefsLookup.cancel.html.cellValue(signup)}</div>
                </div>
              </section>
            ))}
      </div>
    )
  }
})

export const QuestionAnswersListing = defineComponent({
  props: {
    eventSignupID: vReqT<Guid>(),
    eventData: vReqT<ExpandedEvent>(),
  },
  setup(props, {slots}) {
    const answerListing = computed(() => props.eventData.rosterLayoutDetail.questionAnswers[props.eventSignupID]);

    return () => {
      if (!answerListing.value?.length) {
        return null;
      }

      return (
        <div>
          {slots.header?.()}
          {
            answerListing.value.map(questionAnswer => {
              return (
                <div>
                  {questionAnswer.shortLabel}: {questionAnswer.answer}
                </div>
              )
            })
          }
        </div>
      )
    }
  }
})

export function maybeGetVolunteerPointsLink(eventData: ExpandedEvent) {
  const userIDs = eventData.signups.filter(v => !!v.userID).map(v => v.userID);
  if (userIDs.length === 0) {
    return null
  }

  return {
    label: `Send users to Volunteer Points Assignment page`,
    url: buildLegacyLink(Client.value.instanceConfig.appdomain, `/volunteers/points-assignment.cfm?userList=${userIDs.join(",")}&eventID=${eventData.eventID}`, ""),
  }
}
