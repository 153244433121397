import { rpc } from "src/composables/Rpc";
import { Division, Guid, Integerlike, MenuTreeDef, Numbool, Registration, Season } from "src/interfaces/InleagueApiV1";
import { BasicAutoTableDef } from "src/modules/BasicAutoTable";

export enum RatingsMode {
  coach = "coach",
  regional = "regional",
}

export type RegWithRatings =
  & Registration
  & {
    ratings: {
      core: {
        comment: string,
        rating: "" | Integerlike,
      },
      custom: PlayerRating[]
    }
  }

export type RatingsMenu = MenuTreeDef<"seasonUID" | "divID" | "teamID">;
export const listPlayersForRatingsInput = rpc<{teamID: Guid, seasonUID: Guid, mode: RatingsMode}, RegWithRatings[]>("get", "v1/playerRatings/listPlayersForRatingsInput");
export const getPlayerRatingsAuthZ = rpc<
  void,
  {
    hasCoachMode: boolean,
    hasRegionalMode: boolean,
    authZMenu: RatingsMenu,
  }
>("get", "v1/playerRatings/getPlayerRatingsAuthZMenu");
export const updatePlayerRatings = rpc<
  UpdatePlayerRatingsRequest,
  void
>("post", "v1/playerRatings/updatePlayerRatings");

export interface UpdatePlayerRatingsRequest {
  seasonUID: Guid,
  teamID: Guid,
  mode: RatingsMode,
  each: {
    registrationID: Guid,
    core: {
      ratingValue: string,
      comment: string
    },
    custom: {
      ratingID: Guid,
      ratingValue: string
    }[]
  }[]
}

export interface PlayerRating {
  id: Guid,
	ratingID: Guid,
	clientID: Guid,
	value: string,
	isCoach: Numbool, // isCoach mutually exclusive with isRegion
	isRegion: Numbool, // isRegion mutually exclusive with isCoach
	registrationID: Guid,
	childID: Guid,
}

export const getBasicPlayerRatingsReport = rpc<{divID: Guid, rosterYear_seasonUID: Guid, ratingsYear_seasonUID: Guid}, BasicAutoTableDef>("get", "v1/playerRatings/getBasicPlayerRatingsReport");

export const getBasicPlayerRatingsReportOptions = rpc<void, {seasons: Season[], divisions: Division[]}>("get", "v1/playerRatings/getBasicPlayerRatingsReportOptions");
