<template lang="pug">
.flex.flex-col.items-center(data-test="userGames")
  h1.mt-4.text-center My Games
  .q-pa-md.quasar-style-wrap.mt-8(
    v-if='games.length',
    data-cy='fieldKeys',
  )
    q-table(
      :rows='oneRandomGamePerField',
      :columns='fieldColumns',
      row-key='fieldID',
      table-class='content',
      @row-click='toMap',
      :rows-per-page-options='[0]',
      hide-pagination,
      dense
    )
      template(v-slot:body-cell-fieldID='props')
        q-td(:auto-width='true')
          div {{ props.row.fieldAbbreviation }}
      template(v-slot:body-cell-fieldName='props')
        q-td.row.full-width(:props='props')
          .col-auto.q-pr-sm
            font-awesome-icon(:icon='["fas", "map-marked-alt"]')
          .col {{ props.row.fieldName }}

  .quasar-style-wrap(
    v-if='games.length',
    data-cy='userGamesTable',
    class="w-full"
  )
    GamelikeScheduleTable(:columns="columns" :rows="games")

  div(v-else-if='isDataLoaded', data-cy='noUserGames')
    h4.text-center.italic.mt-2 Sorry, no games matched your query
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, onMounted } from 'vue'
import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'


import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import * as ilapi from "src/composables/InleagueApiV1"
import { AugmentedGamelikeForSchedule, augmentGamelikesForSchedule, GamelikeColDefs, getAreaCoaches, JsxQuasarColumnRenderShim } from './page/schedules.ilx'

import GamelikeScheduleTable from './GamelikeScheduleTable.vue'
import { User } from 'src/store/User'
import { areaCoachCamelToLower } from 'src/composables/InleagueApiV1.Game'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { initInOnMountedRef } from './utils'
import { Client, RefSlotConfigLookup } from 'src/store/Client'

export default defineComponent({
  name: 'userGames',
  components: {
    GamelikeScheduleTable
  },
  setup() {
    const games = ref([]) as Ref<AugmentedGamelikeForSchedule<ilapi.LoggedInUserGame>[]>
    const oneRandomGamePerField = ref([]) as Ref<ilapi.LoggedInUserGame[]>
    const isDataLoaded = ref(false)

    const columns = ref(GamelikeColDefs());
    const refSlotConfigLookup = initInOnMountedRef<RefSlotConfigLookup>()

    const fieldColumns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (game: ilapi.LoggedInUserGame) => game.fieldAbbreviation,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        sortable: true,
        field: (game: ilapi.LoggedInUserGame) => {
          return game.fieldName
        },
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
    ])

    const userAddress = computed(() => {
      return User.value.userAddress
    })

    const getGames = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          games.value = await (async () => {
            debugger;
            const games = await ilapi.getGamesForLoggedInUser(axiosInstance, {expand: ["refereeDetails"]})
            const areaCoaches = (await getAreaCoaches(axiosInstance, games))?.map(areaCoachCamelToLower) ?? [];
            return await augmentGamelikesForSchedule(games, areaCoaches, refSlotConfigLookup.value);
          })()

          oneRandomGamePerField.value = getOneRandomGamePerFieldID(games.value)

          isDataLoaded.value = true
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      });

      function getOneRandomGamePerFieldID(games: ilapi.LoggedInUserGame[]) {
        const anyOneGameByItsFieldID: { [fieldID: number]: ilapi.LoggedInUserGame } = {}
        for (const game of games) {
          anyOneGameByItsFieldID[game.fieldID] = game
        }

        const result : ilapi.LoggedInUserGame[] = []
        for (const fieldID in anyOneGameByItsFieldID) {
          result.push(anyOneGameByItsFieldID[fieldID])
        }

        return result;
      }
    }

    const toMap = (_evt: Event, row: ilapi.LoggedInUserGame) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldState} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        refSlotConfigLookup.value = await Client.getRefSlotConfigLookup()
        await getGames()
      })
    })

    return {
      formatDateAsNums,
      formatTime,
      games,
      oneRandomGamePerField,
      isDataLoaded,
      fieldColumns,
      getGames,
      toMap,
      userAddress,
      JsxQuasarColumnRenderShim,
      columns,
    }
  },
})
</script>
