<template lang="pug">
div
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="4")
                  | Parent {{ parentNumber }} - {{ parentData.firstName }} {{ parentData.lastName }}
                  span(v-if='canEdit')
                    router-link(:to="{name: 'user-editor', params: {userID: parentID}}")
                      font-awesome-icon.mx-2(:icon='["fas", "edit"]')
                        | Edit
              tr.grid.grid-cols-6.divide-y.divide-gray-200(v-if="Object.keys(parentData).length>0" class="lg:grid-cols-6")
                td.px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | First Name:
                td.px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  div {{ parentData.firstName }}
                td.px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Last Name:
                td.px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  div {{ parentData.lastName }}
                td.px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Primary Email Address:
                td.px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  div {{ parentData.email }}
                td.px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | 2nd Email Address:
                td.px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  div {{ parentData.email2 }}
                td(v-show='!isOpen').px-6.py-4.text-sm.text-gray-500.col-span-6.cursor-pointer
                  .space-y-2(@click='toggleModal')
                    .w-8.bg-gray-600(class='h-0.5')
                    .w-8.bg-gray-600(class='h-0.5')
                    .w-8.bg-gray-600(class='h-0.5')
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | 3rd Email Address:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.email3 }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | AYSO ID:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.AYSOID }}
                  td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-2
                  | {{ parentData.AYSOID }}

                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Password:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4.italic.cursor-pointer
                  template(v-if="parentData.ID === loggedInUserID")
                    TLegacyAppLink(url="change-password.cfm")
                      span.font-bold.underline.cursor-pointer Reset Password
                  template(v-else)
                    template(v-if='authService(userRoles, "Webmaster", "registrar")')
                      TLegacyAppLink(:url="`admin/admin-password.cfm?userID=${parentData.ID}`")
                        span.font-bold.underline.cursor-pointer Reset Password
                    template(v-else)
                      //- no-op
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Last Login:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.lastLogin }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Street Address:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.street }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | City:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.city }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Occupation:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.occupation }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Employer:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.businessEmployer }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Home Phone:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.homePhone }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Cell Phone:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.primaryPhone }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Work Phone:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.workPhone }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2
                  | Work Extension:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.workPhoneExt }}
                td(v-show='isOpen').px-6.py-4.text-sm.font-medium.text-gray-900.text-right.col-span-2.whitespace-wrap
                  | Business/Work Zip:
                td(v-show='isOpen').px-6.py-4.text-sm.text-gray-500.bg-stone-200.col-span-4
                  | {{ parentData.workZip }}
                td(v-show='isOpen' @click='toggleModal').px-6.py-4.text-sm.text-gray-500.col-span-6.text-center.cursor-pointer
                  font-awesome-icon.mr-2(:icon='["fas", "angle-double-up"]', style='font-size: 16px')
</template>
<script lang="ts">
import { axiosInstance } from 'src/boot/axios'
import { UserData } from 'src/interfaces/Store/user'
import { defineComponent, ref, onMounted, Ref, computed } from 'vue'
import authService from 'src/helpers/authService'

import { User } from 'src/store/User'
export default defineComponent({
  props: {
    parentID: {
      type: String,
      required: true
    },
    parentNumber: {
      type: Number,
      required: true
    },
    canEdit: {
      required: true,
      type: Boolean
    }
  },
  setup(props) {
    const isOpen = ref <boolean>(false)
    const parentData = ref({}) as Ref<UserData>

    const loggedInUserID = User.value.userID;
    const toggleModal = () => {
      isOpen.value = !isOpen.value
    }
    const userRoles = computed(()=> {
      return User.value.roles
    })
    const getParentInfo = async () => {
      try {
        const response = await axiosInstance.get(`v1/user/${props.parentID}?expand=coachAssignmentsForActiveSeason`)
        parentData.value = response.data.data
      } catch (error) {
        // console.log('error in getParentInfo', error)
      }
    }
    onMounted(async ()=> {
      await getParentInfo()
    })
    return {
      isOpen,
      parentData,
      userRoles,
      loggedInUserID,
      authService,
      toggleModal
    }
  },
})
</script>

