<template lang="pug">
.t-page.flex.flex-center
  .d-pa-md.d-gutter-sm
    h1.text-4xl.self-end.font-medium
      i.mr-2.fas.fa-id-card
      | {{ Client.value.instanceConfig.regionname }} Player &amp; Volunteer Registration

    .grid.gap-4(class="lg:grid-cols-2")
      .border.border-gray-800.border-box.p-4.border-2.rounded-md.mt-4
        h1.text-2xl.self-end.font-medium Returning Players &amp; Families
        ContentChunk(id="NewUserSplashReturningInstructions")
        .mt-2
          .my-6.flex.w-full.justify-center
            FormKit(
              type='button'
              @click='goToFamilyProfile'
              label='LOG IN TO MY FAMILY PROFILE'
              outer-class='noMargin'
            )

          .text-2xl.font-medium.mb-2 Forgotten Password and Other Login Problems
          .flex.flex-row.mt-2
            i.mr-2.fas.fa-circle-question
            p.inline-block If you don't know your password, you may reset it from our
              router-link.underline.mx-1(:to="{ name: 'forgot-password' }" target="_blank") Password Reset page
              | (this will open in a new window that you may close once you have completed the form).
          .flex.flex-row.mt-2
            i.mr-2.fas.fa-circle-question
            p.inline-block If you registered previously under an old email address to which you no longer have access and you do not know your password, please contact our registrar by emailing
              span.mx-1.underline(:href="`mailto:${Client.value.instanceConfig.registraremail}`") {{ Client.value.instanceConfig.registraremail }}.
              | Provide your contact information and your new email address, and we will update our records, after which you may log in and register.
      .border.border-gray-800.border-box.p-4.border-2.rounded-md.mt-4
        h1.text-2xl.self-end.font-medium New Players &amp; Families
        .mt-2
          ContentChunk(id="NewUserInstructions")

          //- validation visibility on root form element should be "submit", but is buggy?
          //- also can we change the color of the message or etc?
          FormKit(
            type="form"
            @submit="checkDuplicate"
            :validation-rules="{ emailsAreTheSame }"
            validation="emailsAreTheSame"
            :validation-messages="{ emailsAreTheSame: 'Both email addresses should be the same.' }"
            validation-visibility="dirty"
            data-test="duplicate-email-check-form"
          )
            div
              FormKit(
                :wrapper-class="{ 'formkit-wrapper': false }"
                type="email",
                name="email_1",
                validation="required|email"
                validation-visibility="blur"
                placeholder="email"
                :validation-messages = "{ email: 'Please enter a valid email address.', required: 'Please enter a valid email address.' }"
              )
            div
              FormKit(
                :wrapper-class="{ 'formkit-wrapper': false }"
                type="email",
                name="email_2"
                validation="required|email"
                validation-visibility="blur"
                placeholder="confirm email"
                :validation-messages = "{ email: 'Please enter a valid email address.', required: 'Please enter a valid email address.' }"
              )
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, Ref } from 'vue'
import { axiosAuthBackgroundInstance, axiosNoAuthInstance } from 'boot/axios'
import ContentChunk from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import iziToast from 'izitoast'

import { useRouter, useRoute } from 'vue-router'
import { isAxiosErrorLike } from 'src/composables/InleagueApiV1'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { getLogger } from 'src/modules/LoggerService'
import { PublicLogWriter } from 'src/modules/Loggers'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'NewUserDuplicateCheck',
  components: { ContentChunk },
  setup() {
    // Data
    const localInstance = getCurrentInstance()
    const $toast = localInstance?.appContext.config.globalProperties.$toast as typeof iziToast
    const $router = useRouter()
    const route = useRoute()
    const emailToCheck = ref('')
    const errors = ref(false)
    const status = ref(0)

    const goToFamilyProfile = async () => {
      await $router.push(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default}));
    }

    // API calls
    const checkDuplicate = async (form: { email_1: string | undefined, email_2: string | undefined }) => {
      const emailToCheck = form.email_1?.trim().toLowerCase();

      // form should have caught this and issued an error message, we can't do anything here
      if (emailToCheck === "" || emailToCheck === undefined) {
        return;
      }

      User.directCommit_updateUserEmail(emailToCheck)

      try {
        const response = await axiosNoAuthInstance.get(`/public/user/${encodeURIComponent(emailToCheck)}/exists`)
        if (response.status === 200) {
          $toast.success({
            message:
              'There is an account under this e-mail account already. Please recover your password.',
          })
        }
      }
      catch (error: unknown) {
        if (isAxiosErrorLike(error)) {
          if (error.response.status === 404) {
            await $router.push({ name: 'new-user', params: { email: encodeURIComponent(emailToCheck) }, query: { familyID: route.query.familyID } })
            return;
          }
        }

        // hm, 500? 405 on empty email address?
        // Getting here is a bug
        $toast.error({ message: 'Sorry, something went wrong.' })

        getLogger(PublicLogWriter).log("warning", "/new-user#duplicate-check", error);
      }
    }

    /**
     * validator for form
     * note this returns true if both are undefined or otherwise equally invalid email addresses
     * other checks should alert but such conditions
     */
    const emailsAreTheSame = (node: { value: { email_1: string | undefined, email_2: string | undefined } }) => {
      const l = node.value.email_1?.trim().toLowerCase();
      const r = node.value.email_2?.trim().toLowerCase();
      return l === r;
    }

    return {
      emailToCheck,
      errors,
      status,
      checkDuplicate,
      goToFamilyProfile,
      emailsAreTheSame,
      Client,
    }
  },
})
</script>
