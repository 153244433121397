<template lang="pug">
div(data-test="ProgramRegistrations")
  DivisionOverride(
    v-if="divisionOverrideModalData.visible",
    :competitionRegistration='divisionOverrideModalData.compReg'
    :newDivID='divisionOverrideModalData.targetDivIdOnOpen'
    @cancel="handleDivisionOverrideCanceled"
    @committed="handleDivisionOverrideCommitted"
  )
  ChangeCompetitionModal(
    v-if="changeCompetitionModalController.visible",
    v-bind="changeCompetitionModalController.props"
    v-on="changeCompetitionModalController.handlers"
  )
  CancelCompetitionRegistrationModal(
    v-if="cancelCompetitionRegistrationModalController.visible",
    v-bind="cancelCompetitionRegistrationModalController.props"
    v-on="cancelCompetitionRegistrationModalController.handlers"
  )
  ToggleCompetitionRegistrationWaitlistModal(
    v-if="toggleCompetitionRegistrationWaitlistModalController.visible",
    v-bind="toggleCompetitionRegistrationWaitlistModalController.props"
    v-on="toggleCompetitionRegistrationWaitlistModalController.handlers"
  )
  ForceActivateCompetitionRegistrationModal(
    v-if="forceActivateCompetitionRegistrationModalController.visible",
    v-bind="forceActivateCompetitionRegistrationModalController.props"
    v-on="forceActivateCompetitionRegistrationModalController.handlers"
  )
  div
    .flex.flex-col
      .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
            table.min-w-full.divide-y.divide-gray-200
              thead.bg-gray-50
                tr
                  th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="5")
                    | Program Registrations
                tr
                  th.align-top.px-6.py-3.text-sm.font-medium.text-gray-700.uppercase.tracking-wider.bg-gray-200.text-left(class="w-1/5") Program
                  th.align-top.px-6.py-3.text-sm.font-medium.text-gray-700.uppercase.tracking-wider.bg-gray-200.text-left(class="w-1/5") Division
                  th.align-top.px-6.py-3.text-sm.font-medium.text-gray-700.uppercase.tracking-wider.bg-gray-200.text-left(class="w-1/5")
                    div Registration Status
                    div(v-if="maybe_routerLink_to_paymentManager_forRegistration")
                      RouterLink(:to="maybe_routerLink_to_paymentManager_forRegistration" target="_blank" style="font-size: .75em;" class="cursor-pointer underline text-blue-700")
                        | Payment manager
                  th.align-top.px-6.py-3.text-sm.font-medium.text-gray-700.uppercase.tracking-wider.bg-gray-200.text-left(class="w-1/5") Waitlist
                  th.align-top.px-6.py-3.text-sm.font-medium.text-gray-700.uppercase.tracking-wider.bg-gray-200.text-left(class="w-1/5") Fee
              tbody.divide-y.divide-gray-200
                tr(v-for="competitionRegistration in playerDetails.competitions" :data-test="`competitionRegistrationID=${competitionRegistration.competitionRegistrationID}`")
                  td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200(class='w-1/5')
                    template(v-if="!competitionRegistration.canceled && changeCompetitionModalController.hasPermission")
                      div.flex.justify-between
                        span.cursor-pointer.underline(@click="changeCompetitionModalController.show(competitionRegistration)")
                          | {{ competitionRegistration.competitionName }}
                        font-awesome-icon.ml-4(:icon='["far", "circle-question"]' v-tooltip='{ content: `Click competition name to override` }')
                    template(v-else)
                      div {{ competitionRegistration.competitionName }}
                  td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900.w-80(class='w-1/5')
                    Divisions(
                      :key="divisionOverrideModalData.fixme_forceReRenderDivListingSignal"
                      v-if="hasSomeSuperEditPermission && !competitionRegistration.canceled && (competitionRegistration.paid || competitionRegistration.isWaitlistedButUnpaid)"
                      :modelValue='competitionRegistration.divID',
                      @update:modelValue="(divID)=>triggerDivisionOverride(divID, competitionRegistration)"
                    )
                    div(v-else)
                      | {{ divisionNameForCompetitionRegistration(competitionRegistration) }}
                  td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900.w-80(class='w-1/5' data-test="status")
                    //-
                    //- ui to force-cancel a compreg
                    //- only makes sense to show/do this against a compreg that is exactly (paid and not canceled)
                    //-
                    template(v-if="competitionRegistration.paid && !competitionRegistration.canceled && cancelCompetitionRegistrationModalController.hasPermission")
                      div.flex.justify-between
                        span.underline.cursor-pointer(@click="cancelCompetitionRegistrationModalController.show(competitionRegistration)" data-test="cancel")
                          | {{ getRegistrationStatus(competitionRegistration) }}
                        font-awesome-icon.ml-4(:icon='["far", "circle-question"]' v-tooltip='{ content: `Click on the registration status (registered or waitlisted) to drop the registration` }')
                      div.font-normal.italic {{ competitionRegistration.registrationDate ? `(${dayJSDate(competitionRegistration.registrationDate)})` : ''}}
                    //-
                    //- ui to force-activate a compreg
                    //-
                    template(v-else-if="forceActivateCompetitionRegistrationModalController.hasPermission && forceActivateOfferingsByCompRegID[competitionRegistration.competitionRegistrationID]")
                      div.flex.justify-between
                        span.underline.cursor-pointer(@click="forceActivateCompetitionRegistrationModalController.show(competitionRegistration)" data-test="force-activate")
                          | {{ getRegistrationStatus(competitionRegistration) }}
                        font-awesome-icon.ml-4(:icon='["far", "circle-question"]' v-tooltip='{ content: `Click on the registration status (registered or waitlisted) to force activate the registration` }')
                      div.font-normal.italic {{ competitionRegistration.registrationDate ? `(${dayJSDate(competitionRegistration.registrationDate)})` : ''}}
                    //-
                    //- default
                    //-
                    template(v-else)
                      div(data-test='no-compreg-actions-available')
                        span {{ getRegistrationStatus(competitionRegistration) }}
                        span.font-normal.italic.ml-2 {{ competitionRegistration.registrationDate ? `(${dayJSDate(competitionRegistration.registrationDate)})` : ''}}
                    div.italic(v-if="competitionRegistration.canceled")
                      div Canceled By: {{ competitionRegistration.canceledByFullName }}
                      div {{ competitionRegistration.canceledDate }}
                  td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200(class='w-1/5')
                    template(v-if="!competitionRegistration.canceled")
                      //- if it's waitlisted but pending payment, we can't allow users to force set the waitlist bit
                      //- they _can_ force activate, during which we inform them that "hey you're skipping payment here, if that's really intended go ahead..."
                      template(v-if="toggleCompetitionRegistrationWaitlistModalController.hasPermission && !competitionRegistration.isWaitlistedButUnpaid")
                        div.flex.justify-between
                          div.underline.cursor-pointer(@click="toggleCompetitionRegistrationWaitlistModalController.show(competitionRegistration)")
                            | {{ competitionRegistration.waitlist ? 'On Waitlist' : 'Not On Waitlist'}}
                          font-awesome-icon.ml-4(:icon='["far", "circle-question"]' v-tooltip='{ content: `Click on the waitlist status to change the waitlist status` }')
                      template(v-else)
                        div {{ competitionRegistration.waitlist ? 'On Waitlist' : 'Not On Waitlist'}}
                      div(v-if="competitionRegistration.waitlistRemoveBy")
                        div Removed By: {{ competitionRegistration.waitlistRemoveByFullName}}
                        div {{ competitionRegistration.waitlistRemoveDate }}
                  td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200(class='w-1/5')
                    div {{ maybeGetFeeUiString(competitionRegistration) }}

</template>

<script lang="ts">
import { defineComponent, PropType, watch, ref, onMounted, reactive, computed, Ref, ComputedRef } from 'vue'
import { dayJSDate } from 'src/helpers/formatDate'
import Divisions from 'src/components/Selectors/Divisions.vue'
import DivisionOverride from 'src/components/PlayerEditor/RegistrationData/DivisionOverride.vue'
import { PlayerDetailsI } from 'src/interfaces/Store/registration'
import { Competition, CompetitionRegistration, CompetitionUID, Division, DivisionID, Guid, Integerlike } from 'src/interfaces/InleagueApiV1'


import C_ChangeCompetitionModal from './ChangeCompetitionModal.vue'
import * as ChangeCompetitionModal from "./ChangeCompetitionModal"

import C_CancelCompetitionRegistrationModal from "./CancelCompetitionRegistrationModal.vue"
import * as CancelCompetitionRegistrationModal from "./CancelCompetitionRegistrationModal"

import C_ToggleCompetitionRegistrationWaitlistModal from "./ToggleCompetitionRegistrationWaitlistModal.vue"
import * as ToggleCompetitionRegistrationWaitlistModal from "./ToggleCompetitionRegistrationWaitlistModal"

import C_ForceActivateCompetitionRegistrationModal from "./ForceActivateCompetitionRegistrationModal.vue"
import * as ForceActivateCompetitionRegistrationModal from "./ForceActivateCompetitionRegistrationModal.ilx"

import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import * as ilapi from "src/composables/InleagueApiV1";
import { assertNonNull, parseFloatOr } from 'src/helpers/utils'
import authService from 'src/helpers/authService'
import { getCompRegStatusUiString } from './RegistrationUtils'
import * as R_CompRegsWithBlockedPaymentIntents from 'src/components/Admin/R_CompRegsWithBlockedPaymentIntents.route'
import { type RouteLocationRaw } from 'vue-router'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'

export default defineComponent({
  props: {
    playerDetails: {
      type: Object as PropType<PlayerDetailsI>,
      required: true
    },
    hasSomeSuperEditPermission: {
      required: true,
      type: Boolean,
    }
  },
  components: {
    Divisions,
    DivisionOverride,
    ChangeCompetitionModal: C_ChangeCompetitionModal,
    CancelCompetitionRegistrationModal: C_CancelCompetitionRegistrationModal,
    ToggleCompetitionRegistrationWaitlistModal: C_ToggleCompetitionRegistrationWaitlistModal,
    ForceActivateCompetitionRegistrationModal: C_ForceActivateCompetitionRegistrationModal,
},
  emits: ['update:modelValue:playerDetails'],
  setup(props, {emit}) {
    /**
     * `fixme_forceReRenderDivListingSignal` is a kludge to force-redraw a <select> element when that <select> element:
     *  - changed in DOM in response to user input and that change triggered the modal but DID NOT get written into the target compreg (yet)
     *  - the modal is canceled, and the change will NEVER be written into the target compreg, so DOM will be left out-of-sync where:
     *     - the DOM has "the new selection" visible, but the compreg's value is actually "the old" value
     *     - trying to set the compreg "back to" the "old value" is a no-op (change-detection-wise), and the DOM does not update, so it's left with the out-of-sync "new value"
     */
    type DivOverrideModalData =
      | {
        visible: false,
        fixme_forceReRenderDivListingSignal: number
      }
      | {
        visible: true,
        fixme_forceReRenderDivListingSignal: number,
        compReg: CompetitionRegistration,
        targetDivIdOnOpen: string
      }

    const divisionOverrideModalData = ref<Readonly<DivOverrideModalData>>({visible:false, fixme_forceReRenderDivListingSignal: 0});



    // Controllers fit the mold of "default init'd thing of the right shape but can't really be used until it's fully initialized in onMounted"
    const changeCompetitionModalController = ref(ChangeCompetitionModalController());
    const cancelCompetitionRegistrationModalController = ref(CancelCompetitionRegistrationModalController());
    const toggleCompetitionRegistrationWaitlistModalController = ref(ToggleCompetitionRegistrationWaitlistModalController());
    const forceActivateCompetitionRegistrationModalController = ref(ForceActivateCompetitionRegistrationModalController());

    const divisions_localLookup = ref<Record<DivisionID, Division>>({});

    const getRegistrationStatus = (compReg: CompetitionRegistration) => {
      return getCompRegStatusUiString(compReg);
    }

    /**
     * Which compregs can be force activated, taking into consideration that there can be only one non-canceled compreg
     * at a time per primary registration, and similarly that there can be only one active compreg at a time
     */
    const forceActivateOfferingsByCompRegID = computed(() => {
      // we want tuples (child, season, competition); an alternative key here would be (registrationID, competitionUID)
      const getCompRegGroupingTupleKey = (compReg: CompetitionRegistration) : string => `${compReg.childID}/${compReg.seasonUID}/${compReg.competitionUID}`;

      const hasSomeActiveCompReg : {[groupKey: string]: boolean} = {};
      const hasSomeNonCanceledCompReg : {[groupKey: string]: boolean} = {}

      for (const compReg of props.playerDetails.competitions) {
        const key = getCompRegGroupingTupleKey(compReg);
        hasSomeActiveCompReg[key] ||= /*is paid*/ !!compReg.paid && /*not is canceled*/ !compReg.canceled;
        hasSomeNonCanceledCompReg[key] ||= /*not is canceled*/ !compReg.canceled;
      }

      const canForceActivate : {[competitionRegistrationID: Integerlike]: boolean} = {};
      for (const compReg of props.playerDetails.competitions) {
        const key = getCompRegGroupingTupleKey(compReg);
        if (hasSomeActiveCompReg[key]) {
          //
          // (reg)
          //   |--> cr0(comp=X, paid=1, canceled=0) <-- this exists
          //   |--> cr1(comp=X, paid=0, canceled=1)
          //   |--> cr2(comp=X, paid=1, canceled=1)
          //
          // There is already an active compreg for this group, no compreg for this group can be force activated
          //
          // no member of this group is force activatable
          //
          canForceActivate[compReg.competitionRegistrationID] = false;
          continue;
        }
        else {
          const allCompRegsThisGroupAreCancelled = !hasSomeNonCanceledCompReg[key];
          if (allCompRegsThisGroupAreCancelled) {
            //
            // (reg)
            //   |--> cr0(comp=X, paid=0, canceled=1)
            //   |--> cr1(comp=X, paid=0, canceled=1)
            //   |--> cr2(comp=X, paid=1, canceled=1)
            //
            // Any single one of the members of this group are force activatable
            //
            canForceActivate[compReg.competitionRegistrationID] = true;
          }
          else {
            if (compReg.canceled) {
              //
              // (reg)
              //   |--> cr0(comp=X, paid=0, canceled=0)  <--- this is the only force activatable member of this group
              //   |--> cr1(comp=X, paid=0, canceled=1)  <--- we are this
              //   |--> cr2(comp=X, paid=1, canceled=1)  <--- or possibly this
              //
              // There is some non-active non-canceled compreg for this group, and this isn't it (this compreg is canceled)
              // it cannot be force activated
              //
              canForceActivate[compReg.competitionRegistrationID] = false;
            }
            else {
              //
              // (reg)
              //   |--> cr0(comp=X, paid=0, canceled=0)  <--- we are this
              //   |--> cr1(comp=X, paid=0, canceled=1)  <--- this might exist but is irrelevant
              //   |--> cr2(comp=X, paid=1, canceled=1)  <--- this might exist but is irrelevant
              //
              // there is some non-active non-canceled compreg for this group, and this is it (this compreg is NOT canceled)
              // so this compreg can be force activated
              //
              canForceActivate[compReg.competitionRegistrationID] = true;
            }
          }
        }
      }
      return canForceActivate;
    })

    /**
     * we've performed some action in the UI that should make the "override division" modal appear
     * update state accordingly
     */
    const triggerDivisionOverride = (selectedDivID: string, compReg: CompetitionRegistration) : void => {
      if (selectedDivID === compReg.divID) {
        // the selected division is not different from the taret compReg's current division
        // this is generally in response to having reset the form (modal was canceled, or etc.)
        return;
      }

      divisionOverrideModalData.value = {
        visible: true,
        // signal is left unmodified
        fixme_forceReRenderDivListingSignal: divisionOverrideModalData.value.fixme_forceReRenderDivListingSignal,
        compReg,
        targetDivIdOnOpen: selectedDivID
      };
    }

    const handleDivisionOverrideCanceled = () : void => {
      if (!divisionOverrideModalData.value.visible) {
        // no work to do, shouldn't happen
        return;
      }

      divisionOverrideModalData.value = {
        visible: false,
        // implementation detail -- force redraw the <select> element which change triggered the modal popup,
        // but where that change hasn't been committed
        fixme_forceReRenderDivListingSignal: divisionOverrideModalData.value.fixme_forceReRenderDivListingSignal + 1,
      }
    }

    const handleDivisionOverrideCommitted = (payload: {divID: string}) : void => {
      if (!divisionOverrideModalData.value.visible) {
        // shouldn't happen
        return;
      }

      //
      // We could probably get away with just updating divisionOverrideModalData.value.compReg.divID = "<newval>" here
      // Though there may be some callers who expect to do things with this emitted `playerDetails` update
      //
      for (let i = 0; i < props.playerDetails.competitions.length; i++) {
        if (props.playerDetails.competitions[i].competitionRegistrationID.toString() === divisionOverrideModalData.value.compReg.competitionRegistrationID.toString()) {
          // shallow copy
          const localPlayerDetails = {...props.playerDetails}
          // mutates shallow copy source (localPlayerDetails.competitions === props.playerDetails.competitions)
          localPlayerDetails.competitions[i].divID = payload.divID
          emit('update:modelValue:playerDetails', localPlayerDetails)
          break;
        }
      }


      divisionOverrideModalData.value = {
        visible: false,
        // bumping this is probably only necessary in the "modal was canceled" case, but it doesn't really hurt to do it here, either
        fixme_forceReRenderDivListingSignal: divisionOverrideModalData.value.fixme_forceReRenderDivListingSignal + 1
      }
    }

    onMounted(async () => {
      const competitionMap = await (async () => {
        const competitionsListing = (await getCompetitionsOrFail()).value;
        const result : Record<CompetitionUID, Competition> = {};
        for (const competition of competitionsListing) {
          result[competition.competitionUID] = competition;
        }
        return result;
      })();

      changeCompetitionModalController.value = ChangeCompetitionModalController({
        hasPermission: authService(User.value.roles, "registrar"),
        competitionMap,
        player: props.playerDetails,
        saveCallback: async (targetCompetitionRegistration: CompetitionRegistration, newCompetitionUID: Guid) => {
          try {
            const updatedCompetitionRegistration = await ilapi.updateCompetitionRegistrationCompetition(axiosInstance, {
              registrationID: targetCompetitionRegistration.registrationID,
              competitionRegistrationID: targetCompetitionRegistration.competitionRegistrationID,
              newCompetitionUID: newCompetitionUID
            })
            __FIXME__mutatePlayerCompetitionRegistrationInPlace(props.playerDetails, updatedCompetitionRegistration);
            changeCompetitionModalController.value.hide();
          }
          catch(err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          }
        }
      });

      cancelCompetitionRegistrationModalController.value = CancelCompetitionRegistrationModalController({
        hasPermission: authService(User.value.roles, "registrar", "webmaster", "playerAdmin"),
        competitionMap,
        player: props.playerDetails,
        handlers: {
          confirmCancellation: async (event: CancelCompetitionRegistrationModal.CompetitionRegistrationCancellationEvent) => {
            try {
              const updatedCompetitionRegistration = await ilapi.cancelCompetitionRegistration(
                axiosInstance,
                {
                  registrationID: event.competitionRegistration.registrationID,
                  competitionRegistrationID: event.competitionRegistration.competitionRegistrationID,
                  markPlayerAsNeverPlayed: event.markPlayerAsNeverPlayed,
                  notifyCompetitionManagers: event.notifyCompetitionManagers,
                  notifyDivisionDirectorAndHeadCoaches: event.notifyDivisionDirectorAndHeadCoaches
                });
              __FIXME__mutatePlayerCompetitionRegistrationInPlace(props.playerDetails, updatedCompetitionRegistration);
              cancelCompetitionRegistrationModalController.value.hide();
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err);
            }
          },
          doNotCancel: () => {
            cancelCompetitionRegistrationModalController.value.hide();
          }
        }
      })

      toggleCompetitionRegistrationWaitlistModalController.value = ToggleCompetitionRegistrationWaitlistModalController({
        hasPermission: authService(User.value.roles, "registrar", "webmaster", "playerAdmin"),
        competitionMap,
        player: props.playerDetails,
        handlers: {
          cancel: () => {
            toggleCompetitionRegistrationWaitlistModalController.value.hide();
          },
          commitToggle: async ({competitionRegistration, freshWaitlistValue}) => {
            if ((!!competitionRegistration.waitlist) === freshWaitlistValue) {
              // The existing value already equals the fresh value?
              // Nothing to do? this is mostly a place to put a sanity check
              // The event shouldn't have been emitted, but here we are
              return;
            }

            try {
              const updatedCompReg = await ilapi.updateCompetitionRegistrationWaitlistStatus(
                axiosInstance,
                {
                  competitionRegistrationID: competitionRegistration.competitionRegistrationID,
                  freshWaitlistValue: freshWaitlistValue
                }
              )

              __FIXME__mutatePlayerCompetitionRegistrationInPlace(props.playerDetails, updatedCompReg);

              toggleCompetitionRegistrationWaitlistModalController.value.hide();
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err);
            }
          }
        }
      })

      forceActivateCompetitionRegistrationModalController.value = ForceActivateCompetitionRegistrationModalController({
        hasPermission: authService(User.value.roles, "registrar", "webmaster", "playerAdmin"),
        competitionMap,
        player: props.playerDetails,
        handlers: {
          doNotActivate: () => {
            forceActivateCompetitionRegistrationModalController.value.hide();
          },
          confirmActivation: async ({competitionRegistration}) => {
            try {
              const freshCompReg = await ilapi.forceActivateCompetitionRegistration(axiosInstance, {competitionRegistrationID: competitionRegistration.competitionRegistrationID});
              __FIXME__mutatePlayerCompetitionRegistrationInPlace(props.playerDetails, freshCompReg);
              forceActivateCompetitionRegistrationModalController.value.hide();
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err);
            }
          }
        }
      })

      divisions_localLookup.value = await (async () => {
        const result : Record<DivisionID, Division> = {};
        const divs : Division[] = await Client.getDivisions()
        for (const div of divs) {
          result[div.divID] = div;
        }
        return result;
      })()
    })

    const divisionNameForCompetitionRegistration = (competitionRegistration: CompetitionRegistration) => {
      const divID = competitionRegistration.divID;
      const division = divisions_localLookup.value[divID];
      return division?.displayName
        || division?.division
        || ""; // error case, no match; shouldn't happen, but nothing we can do here
    }

    return {
      getRegistrationStatus,
      dayJSDate,
      divisionOverrideModalData,
      triggerDivisionOverride,
      handleDivisionOverrideCanceled,
      handleDivisionOverrideCommitted,
      divisionNameForCompetitionRegistration,
      changeCompetitionModalController,
      cancelCompetitionRegistrationModalController,
      toggleCompetitionRegistrationWaitlistModalController,
      forceActivateCompetitionRegistrationModalController,
      forceActivateOfferingsByCompRegID,
      /**
       * offer a router link to the payment manager for appropriately permissioned users when the listing of compregs makes sense to do so
       */
      maybe_routerLink_to_paymentManager_forRegistration: computed<RouteLocationRaw | undefined>(() => {
        if (typeof User.value.userData === "string") {
          // typeguard / shouldn't happen (for a logged in user, type is "object")
          return undefined;
        }

        if (props.playerDetails.competitions.some(compReg => !!compReg.isWaitlistedButUnpaid)) {
          // at least one compreg is in the waitlisted-but-unpaid state
          if (R_CompRegsWithBlockedPaymentIntents.hasSomeRelevantAccessPermission(User.value.userData, props.playerDetails.competitions.map(v => v.competitionUID))) {
            // and the user has appropriate permissions
            return R_CompRegsWithBlockedPaymentIntents.routeDetailToRoutePath({
              name: R_CompRegsWithBlockedPaymentIntents.RouteName.main,
              query: {
                registrationID: props.playerDetails.registrationID
              }
            })
          }
        }

        return undefined;
      }),
      maybeGetFeeUiString: (v: CompetitionRegistration) : string => {
        const str = parseFloatOr(v.fee, null)?.toFixed(2);
        return str ? `$${str}` : ""
      }
    }
  },
})

function ChangeCompetitionModalController(args?: {
  hasPermission: boolean,
  competitionMap: Record<CompetitionUID, Competition>,
  player: PlayerDetailsI
  saveCallback: (targetCompetitionRegistration: CompetitionRegistration, newCompetitionUID: Guid) => Promise<void> | void
}) {
  const private_ = {
    visible: ref(false),
    props: ref<ChangeCompetitionModal.Props | undefined>(undefined)
  }

  const visible = computed(() => private_.visible.value);
  const show = (targetCompetitionRegistration: CompetitionRegistration) => {
    assertNonNull(args, "args is expected to be defined here");

    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    private_.props.value = {
      availableCompetitionReassignmentTargetsMap: buildAvailableCompetitionReassignmentTargetsMap(args.player, args.competitionMap, targetCompetitionRegistration),
      target: {
        player: args.player,
        competition: args.competitionMap[targetCompetitionRegistration.competitionUID],
        competitionRegistration: targetCompetitionRegistration
      },
    }

    private_.visible.value = true;
  }

  const hide = () => {
    private_.props.value = undefined;
    private_.visible.value = false;
  }

  const props = computed(() => private_.props.value)

  const handlers : ChangeCompetitionModal.Emits = {
    save: async ({targetCompetitionRegistration, newCompetitionUID}) => {
      assertNonNull(args, "args is expected to be defined here");
      await args.saveCallback(targetCompetitionRegistration, newCompetitionUID);
    },
    close: () => {
      hide();
    }
  }

  return reactive({
    hasPermission: args?.hasPermission ?? false,
    visible,
    show,
    hide,
    props,
    handlers
  });
}

function CancelCompetitionRegistrationModalController(args?: {
  hasPermission: boolean,
  player: PlayerDetailsI,
  competitionMap: Record<CompetitionUID, Competition>
  handlers: CancelCompetitionRegistrationModal.Emits
}) {
  const private_ = {
    visible: ref(false),
    props: ref<CancelCompetitionRegistrationModal.Props | undefined>(undefined)
  }

  const show = (targetCompetitionRegistration: CompetitionRegistration) => {
    assertNonNull(args, "args must be defined here");

    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    const competition = args.competitionMap[targetCompetitionRegistration.competitionUID];
    assertNonNull(competition, "fully expect competitionMap to contain the target competition here");

    private_.props.value = {
      target: {
        player: args.player,
        competition: competition,
        competitionRegistration: targetCompetitionRegistration
      },
    }

    private_.visible.value = true;
  }

  const handlers : CancelCompetitionRegistrationModal.Emits = {
    confirmCancellation: async (event) => {
      assertNonNull(args, "args must be defined here");
      await args.handlers.confirmCancellation(event);
    },
    doNotCancel: async () => {
      assertNonNull(args, "args must be defined here");
      await args.handlers.doNotCancel();
    }
  }

  return reactive({
    hasPermission: args?.hasPermission ?? false,
    visible: computed(() => private_.visible.value),
    show,
    hide: () => private_.visible.value = false,
    // the following must not be accessed when visible is false
    // calling `show` must update these to appropriate values for passing to the target component
    props: computed(() => private_.props.value),
    handlers
  })
}

function ForceActivateCompetitionRegistrationModalController(args?: {
  hasPermission: boolean,
  player: PlayerDetailsI,
  competitionMap: Record<CompetitionUID, Competition>
  handlers: ForceActivateCompetitionRegistrationModal.Emits
}) {
  const private_ = {
    visible: ref(false),
    props: ref<ForceActivateCompetitionRegistrationModal.Props | undefined>(undefined)
  }

  const show = (targetCompetitionRegistration: CompetitionRegistration) => {
    assertNonNull(args, "args must be defined here");

    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    const competition = args.competitionMap[targetCompetitionRegistration.competitionUID];
    assertNonNull(competition, "fully expect competitionMap to contain the target competition here");

    private_.props.value = {
      target: {
        player: args.player,
        competition: competition,
        competitionRegistration: targetCompetitionRegistration,
        registrationID: args.player.registrationID
      },
    }

    private_.visible.value = true;
  }

  const handlers : ForceActivateCompetitionRegistrationModal.Emits = {
    confirmActivation: async (event) => {
      assertNonNull(args, "args must be defined here");
      await args.handlers.confirmActivation(event);
    },
    doNotActivate: async () => {
      assertNonNull(args, "args must be defined here");
      await args.handlers.doNotActivate();
    }
  }

  return reactive({
    hasPermission: args?.hasPermission ?? false,
    visible: computed(() => private_.visible.value),
    show,
    hide: () : void => { private_.visible.value = false },
    // the following must not be accessed when visible is false
    // calling `show` must update these to appropriate values for passing to the target component
    props: computed(() => private_.props.value),
    handlers
  })
}

function buildAvailableCompetitionReassignmentTargetsMap(
  player: PlayerDetailsI,
  competitionMap: Record<CompetitionUID, Competition>,
  targetCompetitionRegistration: CompetitionRegistration
) {
  // For all this player's competition reg, is it active, pending, canceled
  // For every competition, add it to available based on considering status,
  // as well as discard the competitionUID for the current targetCompetitionRegistration
  const statusThisPlayer = {
    active: new Set<CompetitionUID>(),
    pending: new Set<CompetitionUID>(),
    canceled: new Set<CompetitionUID>(),
  };

  for (const compReg of player.competitions) {
    const isPaid = !!compReg.paid;
    const isCanceled = !!compReg.canceled;

    if (!isPaid && !isCanceled) {
      statusThisPlayer.pending.add(compReg.competitionUID);
    }
    else if (!isPaid && isCanceled) {
      statusThisPlayer.canceled.add(compReg.competitionUID);
    }
    else if (isPaid && !isCanceled) {
      statusThisPlayer.active.add(compReg.competitionUID);
    }
    else if (isPaid && isCanceled) {
      statusThisPlayer.canceled.add(compReg.competitionUID);
    }
    else {
      throw "unreachable";
    }
  }

  const result : Record<CompetitionUID, Competition> = {};

  for (const competitionUID of Object.keys(competitionMap)) {
    if (competitionUID === targetCompetitionRegistration.competitionUID) {
      // Can't "reassign" to the competition that this compreg is already bound to.
      // Hence, this option is not available
      continue;
    }

    const compIsAcceptingRegistrations = !!competitionMap[competitionUID].hasActiveRegistration;
    const alreadyHasActiveCompregForThisComp = statusThisPlayer.active.has(competitionUID);

    if (compIsAcceptingRegistrations && !alreadyHasActiveCompregForThisComp) {
      result[competitionUID] = competitionMap[competitionUID];
    }
  }

  return result;
}

function ToggleCompetitionRegistrationWaitlistModalController(
  args?: {
    hasPermission: boolean,
    player: PlayerDetailsI,
    competitionMap: Record<CompetitionUID, Competition>
    handlers: ToggleCompetitionRegistrationWaitlistModal.Emits
  }
) {
  const private_ = {
    visible: ref(false),
    props: ref<ToggleCompetitionRegistrationWaitlistModal.Props | null>(null)
  }

  const show = (targetCompetitionRegistration: CompetitionRegistration) => {
    assertNonNull(args, "args must be defined here");

    if (private_.visible.value) {
      // shouldn't get here; already visible but caller is asking to show again?
      return;
    }

    const competition = args.competitionMap[targetCompetitionRegistration.competitionUID];
    assertNonNull(competition, "fully expect competitionMap to contain the target competition here");

    private_.props.value = {
      target: {
        player: args.player,
        competition: competition,
        competitionRegistration: targetCompetitionRegistration
      },
    }

    private_.visible.value = true;
  }

  const handlers : ToggleCompetitionRegistrationWaitlistModal.Emits = {
    cancel: () => {
      assertNonNull(args, "args must be defined here");
      args.handlers.cancel();
    },
    commitToggle: (namedArgs) => {
      assertNonNull(args, "args must be defined here");
      args.handlers.commitToggle(namedArgs);
    }
  }

  const hide = () => {
    private_.visible.value = false;
    private_.props.value = null;
  }

  return reactive({
    // in default-init mode, caller passes no args, and so implicitly there is no permission
    hasPermission: args?.hasPermission ?? false,
    visible: computed(() => private_.visible.value),
    show,
    hide,
    props: computed(() => private_.props.value),
    handlers
  });
}

/**
 * fixme
 *
 * Need to bubble some event to parent, but it's like 4 components away
 * it's tedious to write each event handler, but the bigger problem is that it's {typo, handler-omission}-error prone
 * can use the store, or some kind of event bus
 * This is intended to (and does) mutate props directly, but (for better or worse) the compiler can't see through to this fact so there is no warning about it
 */
function __FIXME__mutatePlayerCompetitionRegistrationInPlace(playerDetails: PlayerDetailsI, updatedCompetitionRegistration: CompetitionRegistration) : void {
  for (let i = 0; i < playerDetails.competitions.length; ++i) {
    const compReg = playerDetails.competitions[i];
    if (compReg.competitionRegistrationID === updatedCompetitionRegistration.competitionRegistrationID) {
      playerDetails.competitions[i] = updatedCompetitionRegistration;
    }
  }
}
</script>
