import { FormKit } from "@formkit/vue"
import { Filterable, Binop, Filterable_Functionlike, ArgDefOptions } from "src/composables/InleagueApiV1.ReportBuilder"
import { UiOption, copyViaJsonRoundTrip, exhaustiveCaseGuard, forceCheckedIndexedAccess, nonThrowingExhaustiveCaseGuard, vReqT } from "src/helpers/utils"
import { computed, defineComponent, ref, watch } from "vue"
import { parseIntOr } from "src/helpers/utils"
import { UiQNode, NodeType } from "./UiQNode"
import { FormkitSpreadBugUnnecessaryGroup, defaultQueryFormNameAndValidation } from "./ReportBuilder.impl"
import { FilterableFormData, FormValue, containsAnyRedundantDescendantNodes, fixupAllRedundantNodes } from "./QueryData"
import { User } from "src/store/User"
import { Btn2 } from "../UserInterface/Btn2"

const propsDef = {
  node: vReqT<UiQNode>(),
  getters: vReqT<{
    getFilterablesOptions: () => UiOption[],
    getFilterableDefOrFail: (id: string) => Filterable,
    getSharedOptionsByName: (name: string) => UiOption[]
  }>(),
} as const;

const debugPredicateValues = ref(false);

/**
 * This export wraps the impl with the sole intent to use the correct render keys
 */
export const QueryNodeElement = defineComponent({
  props: propsDef,
  setup(props) {
    // renderKey is by (parent.nodeID, nodeID) because nodes can shift across parents, and when doing so should re-render
    const renderKey = computed(() => `${props.node.parent?.nodeID ?? "<root>"}/${props.node.nodeID}`)
    return () => <QueryNodeElementImpl key={renderKey.value} {...props}/>
  }
});

const QueryNodeElementImpl = defineComponent({
  props: propsDef,
  setup(props) {
    const maybeGetCurrentlySelectedFilterableID = () : string => {
      if (props.node.type === NodeType.predicate) {
        if (props.node.data === null) {
          return ""
        }
        return props.node.data.filterableDef.name;
      }
      else {
        return "";
      }
    }

    const selectedFilterableID = ref(maybeGetCurrentlySelectedFilterableID())
    const leafRenderKey = ref(0)

    //
    // We are responsible here for initializing a node's data based on the `selectedFilterableID` in response to form changes.
    // Note that `selectedFilterableID` is a local ref initialized __from__ the node's data on first mount.
    // This is not an immediate watch -- if node data was initialized at time of mount, then that's fine, we use that,
    // and we'll have initialized to the correct `selectedFilterableID`. When `selectedFilterableID` changes, we update the node's
    // data. If we end up remounting (node parent changes or etc.) then the data comes along for the ride and we repeat.
    //
    watch(() => selectedFilterableID.value, () => {
      if (props.node.type !== NodeType.predicate) {
        // It does not make sense to do this for other node types.
        // UI for changing `selectedFilterableID` should only be exposed for predicate node types.
        throw Error("illegal state")
      }

      leafRenderKey.value += 1;

      if (selectedFilterableID.value === "") {
        props.node.data = null;
      }
      else {
        // on change of selected filterable, update the node's values to be reasonable defaults,
        const filterableDef = props.getters.getFilterableDefOrFail(selectedFilterableID.value)
        props.node.data = FilterableFormData(filterableDef, null, props.getters.getSharedOptionsByName);
      }
    })

    return () => {
      if (props.node.type === NodeType.predicate) {
        const node = props.node;
        return (
          <div style="--fk-margin-outer: none;">
            <FormKit type="select" options={props.getters.getFilterablesOptions()} v-model={selectedFilterableID.value} name="Field" validation={[["required"]]}/>
            {
              node.data
                ? (
                  <FormKit type="group">
                    {
                      debugPredicateValues.value
                        ? <pre class="text-xs my-2 shadow-md p-2 rounded-md bg-black" style="color: rgb(255,225,0);">{JSON.stringify(node.data.values, null, 2)}</pre>
                        : null
                    }
                    <FilterableFormElement
                      key={leafRenderKey.value}
                      filterableDef={node.data.filterableDef}
                      mut_input={node.data.values}
                      getSharedOptionsByName={props.getters.getSharedOptionsByName}
                    />
                  </FormKit>
                )
                : null
            }
            <div class="my-2 flex gap-2 items-start">
              <Btn2 class="px-2 py-1" onClick={() => {
                node.deleteSelfFromParentChildrenList();

                //
                // climb node tree and delete all elements that have become empty due this deletion
                //
                let workingNode = node.parent;

                while (true) {
                  if (workingNode.children.length > 0) {
                    // has children, we're done
                    break;
                  }
                  else {
                    if (workingNode.parent) {
                      workingNode.deleteSelfFromParentChildrenList();
                      workingNode = workingNode.parent;
                    }
                    else {
                      // no parent to ascend to, workingNode has become root, we're done
                      break;
                    }
                  }
                }

                // if we ascended to root, we might have deleted the last child from root;
                // in which case, we should push a fresh child so the UI is not totally empty.
                if (workingNode.parent === null) {
                  const treeRoot = workingNode
                  if (treeRoot.children.length === 0) {
                    treeRoot.pushFreshPredicate();
                  }
                }
              }}>Delete</Btn2>
            </div>
          </div>
        )
      }
      else {
        const node = props.node;
        const isRootNode = node.parent === null;
        return (
          <div>
            <div class="border rounded-md shadow-md" style={`background-color: ${node.which === "and" ? 'white' : `rgb(245,255,255)`}`}>
              <div class="p-1 mb-2 text-xs bg-green-700 text-black rounded-t-md flex items-center">
                <select class="py-1 pl-1 text-xs" v-model={props.node.which}>
                  <option value="and">And</option>
                  <option value="or">Or</option>
                </select>
                <div class="ml-auto">
                  {
                    // if we're root, and we contain any redundant nodes at all, offer to clean them all up
                    // This can take 100s of ms for vue to rebuild the dom for typical sized queries, we might want to look into that.
                    // But also we don't expect to do it too often.
                    isRootNode && containsAnyRedundantDescendantNodes(node)
                      ? <div class="text-white underline cursor-pointer" onClick={() => fixupAllRedundantNodes(node)}>Cleanup all redundant ands/ors</div>
                      : null
                  }
                  {
                    isRootNode && User.isInleague
                      ? <div class="flex text-xs gap-2 text-white my-1">
                          <input type="checkbox" v-model={debugPredicateValues.value}/>
                          Debug predicate values
                        </div>
                      : null
                  }
                  {
                    node.parent?.which === node.which
                      ? <div class="text-white underline cursor-pointer" onClick={() => node.mergeWithParentIfRedundant()}>Merge with outer '{node.parent.which}'</div>
                      : node.parent && node.children.length <= 1
                      ? <div class="text-white underline cursor-pointer" onClick={() => node.mergeWithParentIfRedundant()}>Merge redundant single node with outer '{node.parent.which}'</div>
                      : null
                  }
                </div>
              </div>
              {
                props
                  .node
                  .children
                  .map((child, i, a) => {
                    const isLast = i === a.length - 1
                    return (
                      <div class="border-black">
                        <div class="mx-3" style="--fk-bg-input: white;">
                          <FormKit type="group">
                            <QueryNodeElement
                              node={child}
                              getters={props.getters}
                            />
                          </FormKit>
                          {
                            // if we're not the last element in our parent's list, show how we will
                            // connect to the next element in the parent's list
                            isLast
                              ? null
                              : (
                                <div class="flex items-center my-1 gap-2">
                                  <span style="flex-grow: 1;">
                                    <div class="border-b border-gray-300" style="height:50%; width:100%; top:0;left:0;"/>
                                  </span>
                                  <span class="">{child.parent?.which}</span>
                                  <span style="flex-grow: 20;">
                                    <div class="border-b border-gray-300" style="height:50%; width:100%; top:0;left:0;"/>
                                  </span>
                                </div>
                              )
                          }
                        </div>
                        {
                          isLast
                            ? (
                              <div class="my-2 border-t border-dashed border-gray-300 flex">
                                <div class="pl-2 pb-0 pt-2 flex gap-2 items-start">
                                  <t-btn type="button" style="padding: .35em .45em;" margin={false} onClick={() => node.pushFreshPredicate()}>
                                    {
                                      node.which === "and"
                                        ? "+And"
                                        : "+Or"
                                    }
                                  </t-btn>
                                </div>
                                <div class="px-2 pb-0 pt-2 flex gap-2 items-start">
                                  <t-btn type="button" style="padding: .35em .45em;" margin={false} onClick={() => {
                                    const type = node.which === "and" ? "or" : "and";
                                    const c = node.pushFreshConnective(type)
                                    c.pushFreshPredicate();
                                    c.pushFreshPredicate();
                                  }}>
                                    <div class="flex items-center gap-1">
                                      <span>{node.which === "or" ? "+And" : "+Or"}</span>
                                      <span class="text-xs">(subclause)</span>
                                    </div>
                                  </t-btn>
                                </div>
                              </div>
                            )
                            : null
                        }
                      </div>
                    )
                  })
                }
            </div>
          </div>
        )
      }
    }
  }
})

const next_FilterableFormElement_radioGroupName = (() => {
  let id = 0;
  return () => `FilterableFormElement-${id++}`
})()

function checkedFormInput_isStringFormValue(v: FormValue) : string | number {
  if (typeof v !== "string" && typeof v !== "number") {
    throw Error("assertion failure")
  }
  return v;
}

export const FilterableFormElement = defineComponent({
  props: {
    filterableDef: vReqT<Filterable>(),
    mut_input: vReqT<{name: string, value: FormValue}[]>(),
    getSharedOptionsByName: vReqT<(name: string) => UiOption[]>(),
  },
  setup(props) {
    return () => {
      if (props.filterableDef.type === "functionlike") {
        const argsMapping = mappifyFunctionLikeArgs(props.filterableDef, props.mut_input, props.getSharedOptionsByName);
        return (
          <div>
            <div class="ml-1 text-sm my-2">{props.filterableDef.tooltip}</div>
            {
              props.filterableDef.argsDef.map((def,i) => {
                if (def.if && !def.if(argsMapping)) {
                  return null;
                }

                return <div class="my-2">{formElement()}</div>

                function formElement() {
                  switch (def.type) {
                    case "text":
                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="text" label={def.label} v-model={props.mut_input[i].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    case "select":
                      if (!def.options) {
                        throw Error("no options for select")
                      }

                      // copy is formkit nonsense, as they will write into our options objects with "__mask_" garbage
                      const options = copyViaJsonRoundTrip(maybeGetOptionsFromArgDefOptions(def.options, props.getSharedOptionsByName));

                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="select" options={options} label={def.label} v-model={props.mut_input[i].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    case "number":
                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="number" label={def.label} v-model={props.mut_input[i].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    case "date": {
                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="date" label={def.label} v-model={props.mut_input[i].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    }
                    default: exhaustiveCaseGuard(def.type)
                  }
                }
              })
            }
          </div>
        )
      }
      // Moving towards deprecated, most everything comes over the wire as a "functionlike", and the boolean case
      // would be handled by something like a functionlike with a single arg of type "select" with 2 options
      // See also: subsequent notes on "boolean" type
      else if (props.filterableDef.type === "binop-implicit-lhs") {
        const opOptions = props.filterableDef.opDef.ops.map((op) : UiOption => {
          switch (op) {
            case "contains":
              return {label: "Contains", value: op}
            case "eq":
              return {label: "Is", value: op}
            default: exhaustiveCaseGuard(op)
          }
        })
        return (
          <div>
            {
              opOptions.length === 1
                ? <div class="mb-2">{opOptions[0].label}</div>
                : (
                  <FormkitSpreadBugUnnecessaryGroup>
                    <FormKit type="select" options={opOptions} v-model={props.mut_input[0].value}/>
                  </FormkitSpreadBugUnnecessaryGroup>
                )
            }

            <FormkitSpreadBugUnnecessaryGroup>
              {
                // maybe common with functionlike since this is a v4_Filterable_ArgDef
                (() => {
                  const def = props.filterableDef.opDef.rhs
                  switch (def.type) {
                    case "text":
                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="text" label={def.label} v-model={props.mut_input[1].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    case "select": {
                      if (!def.options) {
                        throw Error("no options for select")
                      }

                      const options = maybeGetOptionsFromArgDefOptions(def.options, props.getSharedOptionsByName)
                      if (options.length === 1) {
                        return <div>{options[0].label}</div>
                      }
                      else {
                        return <FormkitSpreadBugUnnecessaryGroup>
                          <FormKit type="select" options={options} label={def.label} v-model={props.mut_input[1].value} {...defaultQueryFormNameAndValidation}/>
                        </FormkitSpreadBugUnnecessaryGroup>
                      }
                    }
                    case "number":
                      return <FormkitSpreadBugUnnecessaryGroup>
                        <FormKit type="number" label={def.label} v-model={props.mut_input[1].value} {...defaultQueryFormNameAndValidation}/>
                      </FormkitSpreadBugUnnecessaryGroup>
                    default: exhaustiveCaseGuard(def.type)
                  }
                })()
              }
            </FormkitSpreadBugUnnecessaryGroup>
          </div>
        )
      }
      // moving towards deprecated, most everything comes over the wire as a "functionlike", and the boolean case
      // would be handled by something like a functionlike with a single arg of type "select" with 2 options
      else if (props.filterableDef.type === "boolean") {
        const groupName = next_FilterableFormElement_radioGroupName();
        return (
          <>
            <div class="flex gap-2 items-center mb-2">
              <input type="radio" name={groupName} v-model={props.mut_input[0].value} value="1"/>
              <span>Yes</span>
              <input type="radio" name={groupName} v-model={props.mut_input[0].value} value="0"/>
              <span>No</span>
            </div>
          </>
        );
      }
      else {
        exhaustiveCaseGuard(props.filterableDef)
      }
    }
  }
})

/**
 * debug helper, to turn cyclic `parent` refs into just their nodeIDs
 */
export function stringifyNode(v: UiQNode) : string {
  return JSON.stringify(decycle(v), null, 2)

  function decycle(v: UiQNode) : object {
    if (v.type === NodeType.connective) {
      return {
        ...v,
        parent: v.parent?.nodeID ?? null,
        children: v.children.map(decycle)
      }
    }
    else {
      return {
        ...v,
        parent: v.parent?.nodeID ?? null
      }
    }
  }
}

/**
 * similar to `stringifyQueryForUI` but returns an HTML tree instead of a string
 */
export const QueryAsDomTree = defineComponent({
  props: {
    node: vReqT<UiQNode>(),
    getSharedOptionsByName: vReqT<(name: string) => UiOption[]>(),
  },
  setup(props) {
    const hasMouseFocus = ref(false);
    return () => {
      if (props.node.type === NodeType.predicate) {
        if (props.node.data === null) {
          return <div>?</div>
        }

        //
        // probably want "binop" for better stringification
        //
        if (props.node.data.filterableDef.type === "functionlike") {
          return <div>{interpolateRenderTemplate(props.node.data.filterableDef, props.node.data.values, props.getSharedOptionsByName) ?? "?"}</div>
        }
        else if (props.node.data.filterableDef.type === "binop-implicit-lhs") {
          const label = props.node.data.filterableDef.label;
          const op = safeBinopToInfixLabel(forceCheckedIndexedAccess(props.node.data.values, 0)?.value);
          const value = forceCheckedIndexedAccess(props.node.data.values, 1)?.value ?? "<value>";
          return <div>{label} {op} {value}</div>
        }
        else if (props.node.data.filterableDef.type === "boolean") {
          const label = props.node.data.filterableDef.label;
          const yesNo = parseIntOr(props.node.data.values[0].value, 0) ? "Yes" : "No";
          return <div>{label} - {yesNo}</div>
        }
        else {
          exhaustiveCaseGuard(props.node.data.filterableDef)
        }
      }
      else if (props.node.type === NodeType.connective) {
        const which = props.node.which;
        return (
          <div>
            <div style="display:grid; grid-template-columns: 12px 1fr; grid-auto-flow: column;">
              <div style="position:relative;">
                <div
                  style="height:100%; width:50%; top:0; right:0; position:absolute;"
                  class={`border-l ${hasMouseFocus.value ? `border-slate-600 bg-gray-100` : `border-slate-200 bg-white`}`}
                />
              </div>
              <div
                onMouseout={(e) => { e.stopPropagation(); hasMouseFocus.value = false; }}
                onMouseover={(e) => { e.stopPropagation(); hasMouseFocus.value = true; }}
                class={`${hasMouseFocus.value ? "bg-gray-100" : "bg-white"} p-1`}
              >
                {
                  props.node.children.map((child, i, a) => {
                    const isLast = i === a.length - 1;
                    return (
                      <>
                        <QueryAsDomTree node={child} getSharedOptionsByName={props.getSharedOptionsByName}/>
                        {
                          isLast
                            ? null
                            : which
                        }
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
      }
      else {
        exhaustiveCaseGuard(props.node)
      }
    }
  }
});

/**
 * render template format is like
 * "text {argName.label} {argName.value} {argName2.value} and so on"
 * where the interpolations always have the form '\w+.(label|value)' and where "label" only makes sense for arguments fed by an options list.
 */
function interpolateRenderTemplate(
  filterableDef: Filterable_Functionlike,
  values: {name: string, value: FormValue}[],
  getSharedOptionsByName: (name: string) => UiOption[]
) : string | null {
  if (!filterableDef.getAsString) {
    // provision for "default" template, not the most informative but it's something
    return filterableDef.label
  }
  else if (filterableDef.type === "functionlike") {
    const args = mappifyFunctionLikeArgs(filterableDef, values, getSharedOptionsByName)
    return filterableDef.getAsString(filterableDef, args);
  }
  else {
    // punt
    return filterableDef.name
  }
}

/**
 * On no match, returns an empty list options.
 */
export function maybeGetOptionsFromArgDefOptions(v: ArgDefOptions | undefined, lookup: (name: string) => UiOption[]) {
  if (!v) {
    return []
  }
  if (Array.isArray(v)) {
    return v;
  }
  else {
    return lookup(v.name);
  }
}

function safeBinopToInfixLabel(op: any) {
  const unchecked_binop = op as Binop;
  switch (unchecked_binop) {
    case "contains": return "contains";
    case "eq": return "is";
    default:
      nonThrowingExhaustiveCaseGuard(unchecked_binop);
      return "<op>";
  }
}

/**
 * really this should be a computed on FilterableFormData, in order to not re-run it every time we need it.
 * But also it's probably not a bottleneck. Haven't measured it.
 */
const mappifyFunctionLikeArgs = (filterable: Filterable_Functionlike, values: {name: string, value: any}[], optionsLookup: (name: string) => UiOption[]) : Record<string, UiOption<any>> => {
  if (filterable.argsDef.length !== values.length) {
    throw Error("out of sync filterDef and values")
  }

  const result : Record<string, UiOption<any>> = {};

  for (let i = 0; i < values.length; i++) {
    const def = filterable.argsDef[i];
    const arg = values[i]
    const value = arg.value;
    const label = maybeGetOptionsFromArgDefOptions(def.options, optionsLookup).find(v => v.value /*not strict*/ == value)?.label ?? ""

    result[arg.name] = {
      label,
      value,
    }
  }

  return result;
}
