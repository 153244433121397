import { axiosInstance } from 'src/boot/axios'

import { computed, ref, Ref, watch } from 'vue'
import * as ilapi from 'src/composables/InleagueApiV1'
import { isCfNull, PageItemType, QuestionOption, QuestionType, RegistrationPageItem, RegistrationAnswer, RegistrationPageItem_Question, Guid, RegistrationQuestion } from 'src/interfaces/InleagueApiV1'
import dayjs from 'dayjs'
import { isCfFalsy, isCfTruthy, UiOption } from 'src/helpers/utils'

import * as iltypes from "src/interfaces/InleagueApiV1"

import { type ClientSideRegistrationQuestionAnswerMap } from "./customQuestions.addendum"

export { type ClientSideRegistrationQuestionAnswerMap, answerMappingToApiV1SubmittableAnswerMapping } from "./customQuestions.addendum"
import { RegistrationStore } from "src/store/Registration"

export default () => {
    const customQuestionIDs = ref([]) as Ref<string[]>
    const types = ref({
      1:'text',
      2:'radio',
      3:'select',
      4:'checkbox',
      5:'textarea'
    }) as Ref<{[key:number]: string}>

    const builtInFunctions = computed(()=> {
      return RegistrationStore.value.gateFunctionsOptionFormat
    })

    /**
     * this is only valid for radio/select/checkbox questions, but that is not enforced
     */
    const processOptions = (data: {optionValue: string, optionText:string}[], question: RegistrationQuestion, currentAnswerForTargetQuestion: RegistrationAnswer | undefined) => {
      const options : UiOption[] = []
      for(let i=0; i<data.length; i++) {
        options.push({
          value: data[i].optionValue,
          label:data[i].optionText,
          // why wouldn't we have a questionID ?
          ...(question.id ? {attrs:{"data-test": question.id}} : {})
        })
      }

      if (currentAnswerForTargetQuestion) {
        // checkbox can have multiple answers, stored as a comma-delimited list
        // unchecking a checkbox leaves an empty string answer on the backend, which we should interpret as "no answer"
        // radio/select should only have one answer
        const oneOrMoreAnswers = question.type === QuestionType.CHECKBOX
          ? currentAnswerForTargetQuestion.answer.split(",").filter(s => s.trim() !== "")
          : [currentAnswerForTargetQuestion.answer];

        for (const answer of oneOrMoreAnswers) {
          const currentAnswerAlreadyPresentInOptions = options.find(v => v.value === answer
              || (isCfTruthy(v.value) && isCfTruthy(answer))
              || (isCfFalsy(v.value) && isCfFalsy(answer))
          );
          if (!currentAnswerAlreadyPresentInOptions) {
            // we want the "original" question option label for this answer; but that information is not available;
            // Instead we use the "current answer" literal string value, which should be reasonably informative.
            options.unshift({label: `${answer} (no longer a valid answer)`, value: answer})
          }
        }
      }

      return options
    }

    const getGateFunction = (gateFunctionName: string) => {
      // return user friendly function label
      return builtInFunctions.value[gateFunctionName]
    }

    const getCustomGateFunction = (gateFunction: any) => {
      const targetValue: number | boolean = gateFunction.matchType == 4 ? !!gateFunction.targetValue : gateFunction.targetValue
      // return gateFunction
      return `${gateFunction.customField.name as string} ${gateFunction.matchType===1? 'equals': 'does not equal'} ${targetValue as number}`
    }

    const getSeasons = (seasons: any[]) => {
      // seasons
      const seasonNames: string[] = []
      for(let i =0; i<seasons.length; i++) {
        seasonNames.push(seasons[i].seasonName)
      }
      return seasonNames
    }

    /**
     * fixme/docs: what conditions does this verify and why?
     */
    const verifyConditions = (questionOptions: QuestionOption[]) => {
      for(let i = 0; i<questionOptions.length; i++) {
        if(questionOptions[i].gateFunctionID) {
          return true
        } else if (questionOptions[i].gateFunctionName) {
          return true
        } else if (questionOptions[i].seasons) {
          return true
        }
      }
      return false
    }

    const getCompetitions = (competitions: any[]) => {
      //competitions
      const compNames: string[] = []
      for(let i=0;i < competitions.length; i++) {
        compNames.push(competitions[i].competition)
      }
      return compNames
    }

    const getConditions = (item: any) => {
      let conditions: string[] = []
      if(item.pageItem?.gateFunctionName) conditions.push(getGateFunction(item.pageItem.gateFunctionName))
      if(Object.keys(item.pageItem?.gateFunction).length) conditions.push(getCustomGateFunction(item.pageItem.gateFunction))
      if(item.itemSeasons.length) conditions = [...conditions, ...getSeasons(item.itemSeasons)]
      if(item.itemCompetitions.length) conditions = [...conditions, ...getCompetitions(item.itemCompetitions)]
      return conditions.join(',  ')
    }

    /**
     * @param hasSomeExistingActiveCompetitionRegistration -- should be renamed to something like "has some existing active competition registration for this registration"
     */
    const processCustomQuestionsSchema = (
      data: RegistrationPageItem[],
      registrationPreview: boolean,
      hasSomeExistingActiveCompetitionRegistration: boolean,
      discardOutOfDateContentChunks: boolean,
      currentAnswers: RegistrationAnswer[],
      callerIsSuperUser: boolean,
    ) => {
      const result = {
        schema: [] as any[],
        formData: {} as Record<string, any>,
      }

      const today = dayjs();

      // collect these and return "out of band", to aid callers who would otherwise need
      // to traverse the result to gather them up. This has no bearing on the generated schema,
      // but does reflect the resulting schema.
      const collectedOptionsByQuestionID : {[questionID: iltypes.Guid]: undefined | UiOption<string>[]} = {};

      data.forEach((q: RegistrationPageItem) => {
        if (q.type === PageItemType.CONTENT_CHUNK && discardOutOfDateContentChunks) {
          // a nullish date value means "no cutoff for this property"
          const isOutOfRange_before = isCfNull(q.pageItem.startDate) ? false : today.isBefore(q.pageItem.startDate, "d");
          const isOutOfRange_after = isCfNull(q.pageItem.endDate) ? false : today.isAfter(q.pageItem.endDate, "d");
          if (isOutOfRange_before || isOutOfRange_after) {
            return; // early bail from for-each iter
          }
        }

        result.formData[q.pageItem.id] = ''

        const field: any = {
          // the ID of the underlying question or content chunk, converted to a string
          // conceptually very similar to the `name` property, but semantically different, in that:
          //  - contractually guaranteed to be a string
          //  - does not represent a name in a form but an ID of some entity
          underlyingPageItemID: q.pageItem.id.toString(),
          name: q.pageItem.id.toString(),
          'input-has-errors-class': 'border-red-500',
          validation: (q.type === PageItemType.QUESTION && q.pageItem.isRequired) ? 'required' : '',
          validationLabel: q.pageItem.shortLabel,
          attrs: {},
          "data-test": q.id, // not q.pageItem.id ? tests expect this as such
        }
        if(q.type === PageItemType.QUESTION) {
          if(q.pageItem.isRequired && q.pageItem.type != QuestionType.RADIO) {
            field.label = `*${q.pageItem.label}`
          } else if (q.pageItem.isRequired) {
            field.label = `*${q.pageItem.label}`
          } else {
            field.label = q.pageItem.label
          }
          field.$formkit = types.value[q.pageItem.type as number]
        } else {
          field.$formkit='tvhtml'
          field.customHTML=q.pageItem.defaultText
          field.classes = { inner: 'removeShadow my-2'}
        }
        if (q.type === PageItemType.CONTENT_CHUNK && q.pageItem.defaultText) field.children = q.pageItem.defaultText
        if (q.type === PageItemType.QUESTION) {
          if (q.isDisabled) {
            field.disabled = true
          }
          else {
            if (hasExistingAnswerForQuestion(q.questionID)) {
              // if we already have an answer, and there already exists some other registration record on file,
              // and the question is marked as "cannot be edited".
              // We check for "already has an answer" because users might add new questions (and non-editable ones!) during some season,
              // where creating a new compreg for that season requires answering the question.
              if (!callerIsSuperUser && hasSomeExistingActiveCompetitionRegistration && !q.pageItem.isEditable) {
                field.disabled = true
              }
            }
          }
        }
        if (q.type === PageItemType.QUESTION && q.pageItem.questionOptions && q.pageItem.questionOptions.length) {
          const options = processOptions(q.pageItem.questionOptions, q.pageItem, maybeGetExistingAnswerForQuestion(q.questionID));
          field.options = options
          collectedOptionsByQuestionID[q.questionID] = options;
        }

        if (q.type === PageItemType.QUESTION) {
          customQuestionIDs.value.push(field.name)
        }

        if(q.type === PageItemType.QUESTION && q.pageItem.type === QuestionType.SELECT) {
          field.placeholder="Select an Option"
        }
        if(q.type === PageItemType.QUESTION && q.pageItem.type === QuestionType.CHECKBOX && q.pageItem.isRequired) {
          field.validation='required'
        }
        if(q.type === PageItemType.QUESTION && (q.pageItem.type === QuestionType.TEXT || q.pageItem.type === QuestionType.TEXTAREA)) {
          field.validation = field.validation.length>0? `${field.validation}|length:1,250` : 'length:1,250'
        }

        //
        // The following is an attempt to make formkit perform the following
        //
        // +----<raw html emitted here>-----------+
        // |                                      |
        // | <the input element>                  |
        // |                                      |
        // +--------------------------------------+
        //
        // Where "raw html emitted here" is the label element which FormKit would typically just put text and
        // requires the following shenanigans to coerce into doing the right thing. It's not clear if we event want or need
        // to do this anymore, as of jun/13/2023, the following sql
        //
        // `select * from client_registration_questions where label like '%<%' or shortlabel like '<%'`
        // (e.g. show me the questions that have even a slightly html-looking label)
        //
        // gets 1 hit, and that 1 hit is on the demo/R9695 client.
        //

        // handle html in labels of radio buttons
        // if(q.type === PageItemType.QUESTION && q.pageItem.type === QuestionType.RADIO) {
        //   field['__raw__sectionsSchema']= {
        //     label: {
        //       $el: null, // Kill the standard label wrapper
        //       children: [
        //         {
        //           $cmp: tvHtml,
        //           props: {
        //             customHTML: '$option.label'
        //           }
        //         }
        //       ],
        //     }
        //   }
        // } else {
        //   // handle html in all labels
        //   if (q.type === PageItemType.QUESTION && q.pageItem.type === QuestionType.CHECKBOX) {
        //     //
        //     // no-op, this is broken in the checkbox case, generating bad checkboxes with weird labels
        //     // see earlier comment, we probably don't want to do this for _any_ of the question types.
        //     //
        //   }
        //   else {
        //     field['__raw__sectionsSchema']= {
        //       label: {
        //         $el: 'div',
        //         children: [
        //           {
        //             $cmp: tvHtml,
        //             props: {
        //               customHTML: '$label'
        //             },
        //           }
        //         ],
        //       }
        //     }
        //   }
        // }


        // When displaying preview, need to display conditions for question to be displayed to users
        let displayCondition = {}
        if(registrationPreview) {
          displayCondition = {
            $formkit: 'tvhtml',
            attrs: {
              label: q.pageItem.label,
              class: 'text-red-700',
            },
            classes: {
              inner: 'removeShadow my-2'
            },
            customHTML: (() => {
              const shouldShow_questionOptionsDetailBlurb = q.type === PageItemType.QUESTION && q.pageItem.questionOptions.length > 0 ? verifyConditions(q.pageItem.questionOptions) : false;
              const questionOptionsDetailBlurb = shouldShow_questionOptionsDetailBlurb
                ? '<p class="text-red-700">* certain question options only appear under selected circumstances</p>'
                : '';
              return `<span class="text-red-700 whitespace-pre-wrap" ><span class="font-bold">Display Conditions: </span> ${getConditions(q)} </span>${questionOptionsDetailBlurb}`
            })()
          }
        }

        result.schema.push(field)

        if(registrationPreview) {
          result.schema.push(displayCondition)
        }
      })

      return {
        schema: result.schema,
        collectedOptionsByQuestionID
      }

      function hasExistingAnswerForQuestion(questionID: Guid) : boolean {
        return !!maybeGetExistingAnswerForQuestion(questionID);
      }
      function maybeGetExistingAnswerForQuestion(questionID: Guid) : RegistrationAnswer | undefined {
        return currentAnswers.find(v => v.questionID === questionID);
      }
    }

    /**
     * an empty `competitionUIDs` array is not supported; if it is empty, pass undefined
     * @see ilapi.getCustomQuestions
     * is this really `getRegistrationPageItems`
     * i.e. we want both `PageItem<Content>` and `PageItem<Question>`
     */
    const getCustomQuestions = async (playerID: string, seasonUID: string, competitionUIDs: string[] | undefined, registrationPreview?: boolean) => {
      return await ilapi.getPlayerRegistrationPageItemsForChildSeason(
        axiosInstance,
        registrationPreview ? ilapi.nilGuid : playerID,
        seasonUID,
        /*withGates*/ !registrationPreview,
        /*competitionUIDs*/ registrationPreview ? undefined : competitionUIDs
      );
    }

    const setBuiltInFunctions = async () => {
      const builtInFunctions = await RegistrationStore.getGateFunctions()
      RegistrationStore.directCommit_setGateFunctions(builtInFunctions)
      await RegistrationStore.createGateFunctionsOptionFormat(builtInFunctions)
    }

    const processAnswers = (questions: RegistrationPageItem_Question[],  answers: RegistrationAnswer[], optionsByQuestionID: {[questionID: Guid]: undefined | UiOption[]}) : ClientSideRegistrationQuestionAnswerMap => {
      const questionLookup = (() => {
        const result : {[questionID: Guid]: RegistrationQuestion} = {};
        for (const q of questions) {
          result[q.questionID] = q.pageItem;
        }
        return result;
      })();

      const customAnswers : ClientSideRegistrationQuestionAnswerMap = {}

      // All questions start out tracked as "no answer".
      // This is an unfortunate and unintuitive FormKit behavior where a value that is `undefined`
      // (either explicity via an assignment to undefined or implicitly via a read of a missing key)
      // isn't tracked by formkit, so a transition from "undefined to some value" is ignored.
      // It does respect null in this way. But now Formkit has appropriated undefined, and so we're
      // using null to represent undefined, and as a consequence we have no ability to actually represent null
      // (i.e. "this value is literally null").
      for (const q of questions) {
        customAnswers[q.questionID] = null;
      }

      // if there is an answer, overwrite the null from above
      for(let i = 0; i<answers.length; i++) {
        if(customQuestionIDs.value.includes(answers[i].questionID)){
          const questionID = answers[i].questionID
          const answer = answers[i].answer
          const question = questionLookup[questionID];

          // question lookup should always succeed, but it might not for any number of bug reasons;
          // we'd prefer to not crash in those situations
          if (question) {
            if (question.type === QuestionType.CHECKBOX) {
              // we need a string[] for checkbox answers, to represent multiple checkboxes
              const answers = answer.trim() === "" ? [] : answer.split(",");
              const options = optionsByQuestionID[questionID] ?? [];

              // kludgy hack for cf-booleanish conversions:
              // if there is one option, and there is already an answer, and both are cf-truthy,
              // both need to be exactly equal for comparison purposes on js clients
              // n.b. this needs to sync with logic in `processOptions` which does a similar thing
              // Limiting to "exactly one option" is intended to prevent breakage where there is 2 options like [value=1,value=2],
              // where both values are cftruthy. We might want the backend to move to doing strict string equality rather than allow implicit
              // bool conversions.
              if (answers.length === 1 && options.length === 1) {
                if (isCfTruthy(answers[0]) && isCfTruthy(options[0].value)) {
                  answers[0] = options[0].value; // question and answer are exact
                }
              }

              customAnswers[questionID] = answers
              continue;
            }
          }

          // default behavior is to treat the answer as an opaque string
          customAnswers[questionID] = answer
        }
      }
      return customAnswers
    }

    return {
      customQuestionIDs,
      types,
      builtInFunctions,
      processOptions,
      getGateFunction,
      getSeasons,
      verifyConditions,
      getCompetitions,
      getConditions,
      processCustomQuestionsSchema,
      getCustomQuestions,
      setBuiltInFunctions,
      processAnswers
    }
}

/**
 * Convert a stringified representation of a checkbox answer to boolean
 *
 * The following is all distinct registration question answers,
 * for answers to questions of type CHECKBOX (i.e. 4) as of sep/12/22
 *
 * answer	   | first_appearance        | last_appearance
 * "on"	     | 2014-02-06 21:42:51.657 | 2016-12-01 13:50:45.000
 * "Yes,Yes" | 2020-11-15 09:05:22.007 | 2020-11-15 09:05:58.350
 * "Yes"	   | 2016-05-24 09:44:22.000 | 2022-04-25 10:10:31.620
 * "false"   | 2022-05-07 11:17:13.843 | 2022-09-10 17:53:49.420
 * "true"    | 2022-03-30 17:09:06.447 | 2022-09-12 12:48:35.327
 */
function stringlyQuestionAnswerToBool(answer: string) : boolean {
  const lcase = answer.toLowerCase();
  return lcase === "yes"
    || lcase === "true"
    || lcase === "on"
    || lcase === "yes,yes";
}
