import { exhaustiveCaseGuard, guidOr, vReqT } from "src/helpers/utils";
import { ExtractPropTypes } from "vue";
import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import authService from "src/helpers/authService";
import { User } from "src/store/User";
import { Guid } from "src/interfaces/InleagueApiV1";

export const propsDef = {
  detail: vReqT<RouteDetail>(),
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;

export const RouteName = {
  main: "user-editor",
  security: "user-editor.security",
  certs: "user-editor.certs",
  log: "user-editor.log",
  volunteerPrefs: "user-editor.volunteer-prefs",
} as const;

type RouteName = (typeof RouteName)[keyof typeof RouteName];

export type RouteDetail =
  | R_Main
  | R_Security
  | R_Certs
  | R_UserLog
  | R_UserVolunteerPrefs

interface R_Base {
  name: RouteName
}
export interface R_Main extends R_Base {
  name: "user-editor"
  userID: Guid,
}

export interface R_Security extends R_Base {
  name: "user-editor.security",
  userID: Guid
}

export interface R_Certs extends R_Base {
  name: "user-editor.certs",
  userID: Guid
}

export interface R_UserLog extends R_Base {
  name: "user-editor.log",
  userID: Guid
}

export interface R_UserVolunteerPrefs extends R_Base {
  name: "user-editor.volunteer-prefs",
  userID: Guid,
  // primarily a testing provision
  seasonUID: undefined | Guid
}

export function routeDetailToRouteLocation(detail: RouteDetail) : RouteLocationRaw {
  switch(detail.name) {
    case RouteName.main:
      return {name: detail.name, params: {userID: detail.userID}};
    case RouteName.security:
      return {name: detail.name, params: {userID: detail.userID}};
    case RouteName.certs:
      return {name: detail.name, params: {userID: detail.userID}};
    case RouteName.log:
      return {name: detail.name, params: {userID: detail.userID}};
    case RouteName.volunteerPrefs:
      return {name: detail.name, params: {userID: detail.userID}, query: {seasonUID: detail.seasonUID}};
    default:
      exhaustiveCaseGuard(detail);
  }
}

export function routeLocationToRouteProps(route: RouteLocationNormalized) : Props {
  const unsafe_routeName = route.name as any as RouteName
  switch (unsafe_routeName) {
    case RouteName.main:
    case RouteName.security:
    case RouteName.certs:
    case RouteName.log:
      return {detail: {name: unsafe_routeName, userID: route.params.userID as string}};
    case RouteName.volunteerPrefs:
      return {detail: {
        name: unsafe_routeName,
        userID: route.params.userID as string,
        seasonUID: guidOr(route.query.seasonUID, undefined),
      }};
    default:
      exhaustiveCaseGuard(unsafe_routeName);
  }
}

export function authZ_editVolunteerCerts() : boolean {
  return authService(User.value.roles, "volunteerAdmin", "registrar", "webmaster", "inLeague");
}

export function authZ_viewVolunteerCerts() : boolean {
  return authService(User.value.roles, "volunteerAdmin", "registrar", "Board", "DD", "webmaster", "inLeague", "refScheduler", "ChiefRef", "compManager");
}

export function authZ_viewUserLog() : boolean {
  return authService(User.value.roles, "webmaster", "registrar", "inLeague");
}

export function authZ_viewVolunteerPrefs() : boolean {
  return authService(User.value.roles, "volunteerAdmin", "DD", "Board", "registrar", "refScheduler", "webmaster", "inLeague", "ChiefRef");
}

export function authZ_editVolunteerPrefs() : boolean {
  return authService(User.value.roles, "volunteerAdmin", "webmaster", "registrar", "inLeague");
}
