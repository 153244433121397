import { defineComponent, onMounted, ref, watch } from "vue";
import { TeamSelectionButtons, useTeamSelectionButtonsPaneSizeWatcher } from "src/components/UserInterface/TeamChooser/TeamChooserSelectButtons";
import { teamChooserSelection, TeamChooserSelection, BasicTeamChooserSelectionManager, longestValidMenuPath } from "src/components/UserInterface/TeamChooser/TeamChooserUtils";
import { TeamChooserMenu, getTeamChooserMenu } from "src/composables/InleagueApiV1.TeamChooser2";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { Guid } from "src/interfaces/InleagueApiV1";
import { TeamForTeamPoolEditor, TeamPool, TeamPoolForTeamPoolEditor, addTeamsToPool, createTeamPool, deleteTeamPool, getTeamPoolsForEditTeamPoolsView, removeTeamsFromPool, updatePoolTeams, updateTeamPool } from "src/composables/InleagueApiV1.Teams";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { PoolData, NewPoolForm, ResolvedSelection, TeamPoolUiDataCollection } from "./Shared";
import { SetEx, arrayFindOrFail, assertTruthy, isGuidUpper, parseBaseTeamName, requireNonNull, sortBy, sortByCachedMap, sortByMany } from "src/helpers/utils";
import { useRouter } from "vue-router";
import { QueryParams } from "./R_TeamPools.route";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, modal_defaultTitleClasses, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { Client } from "src/store/Client";
import { PoolListingElement, PoolNameEditorModal } from "./PoolEditorElements";

export default defineComponent({
  setup() {
    const router = useRouter()
    const ready = ref(false)

    const menuSelection = ref<TeamChooserSelection>(teamChooserSelection())
    const menu = ref<TeamChooserMenu>(/*definitely assigned in onMounted*/ null as any)
    const menuSelectionManager = ref<BasicTeamChooserSelectionManager>(/*definitely assigned in onMounted*/ null as any)
    const teamChooserButtonContainer = useTeamSelectionButtonsPaneSizeWatcher()

    const resolvedMenuSelection = ref<ResolvedSelection | null>(null)
    const newPoolForm = ref(NewPoolForm())

    watch(() => menuSelection.value, async () => {
      const {seasonUID, competitionUID, divID} = menuSelection.value
      if (!seasonUID || !competitionUID || !divID) {
        resolvedMenuSelection.value = null
        return
      }

      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        updateQueryParams({seasonUID, competitionUID, divID})
        const poolData = await getFreshPoolData2({
          seasonUID,
          competitionUID,
          divID,
          createdThisSessionPoolUIDs: null,
          selectedAddTheseTeamIDs: null,
          selectedRemoveTheseTeamIDs: null,
        })
        resolvedMenuSelection.value = {
          seasonUID,
          competitionUID,
          divID,
          poolData,
        }
      })
    });

    const updateQueryParams = async (args: {competitionUID: Guid, seasonUID: Guid, divID: Guid}) : Promise<void> => {
      await router.push({
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          [QueryParams.seasonUID]: args.seasonUID,
          [QueryParams.competitionUID]: args.competitionUID,
          [QueryParams.divID]: args.divID,
        }
      })
    }

    const tryForceSelectionFromQueryParams = async () : Promise<void> => {
      const {seasonUID, competitionUID, divID} = {
        seasonUID: router.currentRoute.value.query[QueryParams.seasonUID],
        competitionUID: router.currentRoute.value.query[QueryParams.competitionUID],
        divID: router.currentRoute.value.query[QueryParams.divID],
      }

      if (!isGuidUpper(seasonUID) || !isGuidUpper(competitionUID) || !isGuidUpper(divID)) {
        return;
      }

      const v = longestValidMenuPath(menu.value, {seasonUID, competitionUID, divID, teamIDs: null})

      if (v.seasonUID && v.competitionUID && v.divID) {
        menuSelection.value = v
      }
    }

    watch([
      () => router.currentRoute.value.query[QueryParams.seasonUID],
      () => router.currentRoute.value.query[QueryParams.competitionUID],
      () => router.currentRoute.value.query[QueryParams.divID],
    ], () => {
      tryForceSelectionFromQueryParams()
    })

    const doAddTeamsToPool = async (args: {poolUID: Guid}) : Promise<void> => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const currentSelection = requireNonNull(resolvedMenuSelection.value)

          const poolUID = args.poolUID
          const teamIDs = [...currentSelection.poolData.selectedAddTheseTeamIDs]

          if (teamIDs.length === 0) {
            // shouldn't happen
            return;
          }

          await addTeamsToPool(axiosInstance, {poolUID, poolTeams: teamIDs.map(v => ({teamID: v}))})
          currentSelection.poolData = await getFreshPoolData1(currentSelection)
          currentSelection.poolData.selectedAddTheseTeamIDs.clear()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doRemoveTeamsFromPool = async (args: {poolUID: Guid}) : Promise<void> => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const currentSelection = requireNonNull(resolvedMenuSelection.value)

          const poolUID = args.poolUID
          const teamIDs = [...currentSelection.poolData.selectedRemoveTheseTeamIDs[poolUID] ?? []]

          if (teamIDs.length === 0) {
            // shouldn't happen
            return;
          }

          await removeTeamsFromPool(axiosInstance, {poolUID, teamIDs})
          currentSelection.poolData = await getFreshPoolData1(currentSelection)
          currentSelection.poolData.selectedRemoveTheseTeamIDs[poolUID]?.clear()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doCreateTeamPool = async () : Promise<void> => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const currentSelection = requireNonNull(resolvedMenuSelection.value)
        const {seasonUID, competitionUID, divID} = menuSelection.value;
        if (!seasonUID || !competitionUID || !divID) {
          return;
        }

        try {
          const pool = await createTeamPool(axiosInstance, {seasonUID, competitionUID, divID, name: newPoolForm.value.name})
          currentSelection.poolData.createdThisSessionPoolUIDs.unshift(pool.poolUID)
          currentSelection.poolData = await getFreshPoolData1(currentSelection)
          newPoolForm.value = NewPoolForm()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const deleteTeamPoolModalController = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      const doDeleteTeamPool = async (args: {poolUID: Guid}) : Promise<void> => {
        try {
          const currentSelection = requireNonNull(resolvedMenuSelection.value)
          const {poolUID} = args

          try {
            busy.value = true
            await deleteTeamPool(axiosAuthBackgroundInstance, {poolUID})
            currentSelection.poolData.createdThisSessionPoolUIDs = currentSelection.poolData.createdThisSessionPoolUIDs.filter(v => v !== poolUID)
            currentSelection.poolData = await getFreshPoolData1(currentSelection)
            delete currentSelection.poolData.selectedRemoveTheseTeamIDs[poolUID]
          }
          finally {
            busy.value = false
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController_r<TeamPool>({
        content: pool => {
          if (!pool) {
            return null;
          }
          return (
            <div>
              <div class={modal_defaultTitleClasses}>Delete team pool "{pool.poolName}"?</div>
              <div class="mb-4 border-b border-gray-200"/>
              <div class="flex gap-2">
                <Btn2 class="p-2" data-test="yes" onClick={async () => {
                  await doDeleteTeamPool(pool)
                  deleteTeamPoolModalController.close()
                }}>Yes, delete</Btn2>
                <Btn2 data-test="no" enabledClasses={btn2_redEnabledClasses} class="p-2" onClick={() => deleteTeamPoolModalController.close()}>No, cancel</Btn2>
              </div>
              {
                busy.value
                  ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                  : null
              }
            </div>
          )
        }
      }, {onCloseCB})
    })();

    const editTeamPoolNameModalController = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      const doUpdate = async ({poolUID, newName}: {poolUID: Guid, newName: string}) : Promise<void> => {
        const sel = requireNonNull(resolvedMenuSelection.value)
        const targetPool = arrayFindOrFail(sel.poolData.apiData.pools, v => v.poolUID === poolUID);
        try {
          try {
            busy.value = true
            await updateTeamPool(axiosAuthBackgroundInstance, {poolUID, name: newName})
            targetPool.poolName = newName
          }
          finally {
            busy.value = false
          }
          editTeamPoolNameModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController_r<TeamPool>({
        content: pool => {
          if (!pool) {
            return null;
          }
          return (
            <div>
              <div class={modal_defaultTitleClasses}>Edit Team Pool Name</div>
              <div class="mb-4 border-b border-gray-200"/>
              <PoolNameEditorModal
                initialValue={pool.poolName}
                onCommit={(newName) => doUpdate({poolUID: pool.poolUID, newName})}
                onCancel={() => editTeamPoolNameModalController.close()}
              />
              <DefaultTinySoccerballBusyOverlay if={busy.value}/>
            </div>
          )
        }
      }, {onCloseCB})
    })()

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        menu.value = await getTeamChooserMenu(axiosInstance, "team-pools/crud-1")
        menuSelectionManager.value = BasicTeamChooserSelectionManager({mut_selection: menuSelection, menu: menu.value})
        tryForceSelectionFromQueryParams()
        ready.value = true;
      })
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div ref={teamChooserButtonContainer.rootRef} data-test="R_TeamPools">
          <h2>Team Pools</h2>
          <p>Teams may be assigned to pools to sub-divide large divisions for match-ups or bracket play.</p>
          <AutoModal controller={deleteTeamPoolModalController} data-test="deleteTeamPoolModal"/>
          <AutoModal controller={editTeamPoolNameModalController}/>
          <TeamSelectionButtons
            teamSelectMode="single"
            menu={menu.value}
            selectionManager={menuSelectionManager.value}
            levels={{
              season: true,
              competition: true,
              division: true
            }}
            mut_openAbsolutePosPanes={teamChooserButtonContainer.openAbsolutePosPanes}
          />
          {
            resolvedMenuSelection.value
              ? <PoolListingElement
                class="my-4"
                newPoolForm={newPoolForm.value}
                poolData={resolvedMenuSelection.value.poolData}
                onCreateNewPool={() => doCreateTeamPool()}
                onAddSelectedTeamsToPool={pool => doAddTeamsToPool(pool)}
                onRemoveSelectedTeamsFromPool={pool => doRemoveTeamsFromPool(pool)}
                onDeleteTeamPool={pool => deleteTeamPoolModalController.open(pool)}
                onEditTeamPoolName={pool => editTeamPoolNameModalController.open(pool)}
                onSaveSeedChanges={async data => {
                  // TODO: mark POOL as loading, use background axiosInstance
                  await updatePoolTeams(axiosInstance, {poolUID: data.pool.poolUID, poolTeams: data.updates})
                  const freshData = await getTeamPoolsForEditTeamPoolsView(axiosInstance, {poolUIDs: [data.pool.poolUID]})

                  assertTruthy(freshData.pools.length === 1)
                  const freshPool = freshData.pools[0]

                  const v = resolvedMenuSelection.value?.poolData

                  if (!v) {
                    return
                  }
                  const idx = v.apiData.pools.findIndex(v => v.poolUID === data.pool.poolUID)
                  if (idx !== -1) {
                    v.apiData.pools[idx] = freshPool
                  }

                  v.uiData.resetFrom(freshPool)
                }}
              />
              : null
          }
        </div>
      )
    }
  }
})

async function getFreshPoolData1(currentSelection: ResolvedSelection) : Promise<PoolData> {
  return await getFreshPoolData2({
    seasonUID: currentSelection.seasonUID,
    competitionUID: currentSelection.competitionUID,
    divID: currentSelection.divID,
    createdThisSessionPoolUIDs: currentSelection.poolData.createdThisSessionPoolUIDs,
    selectedAddTheseTeamIDs: currentSelection.poolData.selectedAddTheseTeamIDs,
    selectedRemoveTheseTeamIDs: currentSelection.poolData.selectedRemoveTheseTeamIDs
  })
}

/**
 * Though we call the result "fresh", we preserve existing UI state values if they exist.
 */
async function getFreshPoolData2(args: {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  createdThisSessionPoolUIDs: null | Guid[],
  selectedAddTheseTeamIDs: null | SetEx<Guid>,
  selectedRemoveTheseTeamIDs: null | {[poolUID: Guid]: undefined | SetEx<Guid>}
}) : Promise<PoolData> {
  const apiData = await getTeamPoolsForEditTeamPoolsView(axiosInstance, args);

  const selectedRemoveTheseTeamIDs = (() => {
    const v : PoolData["selectedRemoveTheseTeamIDs"] = {}
    apiData.pools.forEach(pool => v[pool.poolUID] = args.selectedRemoveTheseTeamIDs?.[pool.poolUID] ?? new SetEx())
    return v
  })()

  apiData.pools = sortPools(apiData.pools, args.createdThisSessionPoolUIDs ?? [])
  apiData.availableTeams.sort(teamSort())

  return {
    apiData,
    uiData: TeamPoolUiDataCollection.create(apiData.pools),
    selectedAddTheseTeamIDs: args.selectedAddTheseTeamIDs ?? new SetEx(),
    selectedRemoveTheseTeamIDs,
    createdThisSessionPoolUIDs: args.createdThisSessionPoolUIDs ?? []
  }
}

/**
 * sort data with "created this session" things first
 */
function sortPools(pools: readonly TeamPoolForTeamPoolEditor[], createdThisSessionPoolUIDs: Guid[]) : TeamPoolForTeamPoolEditor[] {
  return pools
    .map((e,idx) => ({e, idx}))
    .sort(sortBy(v => {
      const thisSessionIdx = createdThisSessionPoolUIDs.indexOf(v.e.poolUID)
      if (thisSessionIdx !== -1) {
        return thisSessionIdx;
      }
      else {
        // use initial sort order, but always past the last "created this session" element
        return v.idx + createdThisSessionPoolUIDs.length
      }
    }))
    .map(v => {
      // we've sorted the pools; we also want to sort each pool's teams list.
      v.e.teams.sort(teamSort())
      return v.e
    })
}

const teamSort = () => sortByCachedMap(
  (v: TeamForTeamPoolEditor) => parseBaseTeamName(v.team),
  sortByMany(
    sortBy(_ => _.genderSpecifier),
    sortBy(_ => _.ageBracket),
    sortBy(_ => _.rest)
  )
)
