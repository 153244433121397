import { PropType, computed, defineComponent, getCurrentInstance, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { FormKit } from "@formkit/vue";
import type { FormKitNode } from "@formkit/core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { axiosInstance } from "src/boot/axios";
import { UiOption, exhaustiveCaseGuard, useIziToast } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { PageItemType, QuestionType } from "src/interfaces/InleagueApiV1"

import { X } from "src/components/SVGs"
import { PageItem_Question_PreviewRender } from "../Registration/admin/PageItem_Question_PreviewRender";

import * as R_Self from "./R_TournamentTeamRegPageItemEditor.route"
import { tournTeamRegPageItemStore } from "./Store/TournTeamRegPageItemStore";
import * as R_TournamentTeamRegPageItemListing from "./R_TournamentTeamRegPageItemListing.route";
import { QuillWrapper2 } from "../UserInterface/QuillWrapper2";

import { Client } from "src/store/Client";

type UnderlyingType =
  | "Q_TEXT"
  | "Q_TEXTAREA"
  | "Q_RADIO"
  | "Q_SELECT"
  | "Q_CHECKBOX"
  | "C_CONTENT_CHUNK"

// We don't ever write to this, but FormKit **might**, see comments on UiOption.
const fk_underlyingTypeOptions : UiOption[] = [
  {value: "Q_TEXT", label: "Text"} as const,
  {value: "Q_TEXTAREA", label: "Text area"},
  {value: "Q_RADIO", label: "Radio button"},
  {value: "Q_SELECT", label: "Select (drop-down)"},
  {value: "Q_CHECKBOX", label: "Checkbox"},
  {value: "C_CONTENT_CHUNK", label: "HTML content blurb"},
]

function freshTournTeamRegForm_tentativeForUnderlyingType(tournamentID: iltypes.Integerlike, v: UnderlyingType) : TournTeamRegForm_PageItem {
  if (v === "C_CONTENT_CHUNK") {
    return freshTournTeamRegForm_PageItem_ContentChunk(tournamentID);
  }
  else {
    return freshTournTeamRegForm_PageItem_Question(tournamentID, questionTypeFromUnderlyingType(v))
  }
}

function freshTournTeamRegForm_fromExisting(pageItem: iltournament.TournTeamRegPageItem) : TournTeamRegForm_PageItem {
  if (pageItem.type === PageItemType.CONTENT_CHUNK) {
    return freshTournTeamRegForm_PageItem_ContentChunk_fromExisting(pageItem);
  }
  else {
    return freshTournTeamRegForm_PageItem_Question_fromExisting(pageItem)
  }
}

/**
 * n.b. This mapping does not exist for a content chunk.
 */
function questionTypeFromUnderlyingType(v: Exclude<UnderlyingType, "C_CONTENT_CHUNK">) {
  switch (v) {
    case "Q_CHECKBOX": return QuestionType.CHECKBOX
    case "Q_RADIO": return QuestionType.RADIO
    case "Q_SELECT": return QuestionType.SELECT
    case "Q_TEXT": return QuestionType.TEXT
    case "Q_TEXTAREA": return QuestionType.TEXTAREA
    default: exhaustiveCaseGuard(v)
  }
}

function underlyingTypeFromPageItem(v: iltournament.TournTeamRegPageItem) : UnderlyingType {
  if (v.type === PageItemType.CONTENT_CHUNK) {
    return "C_CONTENT_CHUNK";
  }
  else {
    switch (v.containedItem.type) {
      case QuestionType.CHECKBOX: return "Q_CHECKBOX"
      case QuestionType.RADIO: return "Q_RADIO"
      case QuestionType.SELECT: return "Q_SELECT"
      case QuestionType.TEXT: return "Q_TEXT"
      case QuestionType.TEXTAREA: return "Q_TEXTAREA"
      default: exhaustiveCaseGuard(v.containedItem.type)
    }
  }
}

interface TournTeamRegForm_PageItem_Base {
  mode: "tentative" | "persisted",
  id: string,
  tournamentID: iltypes.Integerlike,
  pageItemType: PageItemType,
  containedItem: TournTeamRegForm_Question | TournTeamRegForm_ContentChunk,
  order: iltypes.Integerlike,
}

type TournTeamRegForm_PageItem = TournTeamRegForm_PageItem_Question | TournTeamRegForm_PageItem_ContentChunk

interface TournTeamRegForm_PageItem_Question extends TournTeamRegForm_PageItem_Base {
  pageItemType: PageItemType.QUESTION,
  containedItem: TournTeamRegForm_Question
}

interface TournTeamRegForm_PageItem_ContentChunk extends TournTeamRegForm_PageItem_Base {
  pageItemType: PageItemType.CONTENT_CHUNK,
  containedItem: TournTeamRegForm_ContentChunk
}

interface TournTeamRegForm_Question {
  id: string,
  type: QuestionType,
  label: string,
  shortLabel: string,
  isEditable: boolean,
  isRequired: boolean,
  /**
   * null meaning no question options for question types TEXT and TEXTAREA,
   * otherwise, an array of at least one option
   */
  questionOptions: null | TournTeamRegForm_QuestionOption[]
}

interface TournTeamRegForm_ContentChunk {
  label: string,
  shortLabel: string,
  defaultText: string,
}

interface TournTeamRegForm_QuestionOption {
  mode: "tentative" | "persisted",
  id: string, // todo: clarify, guid in the persisted case?
  optionValue: string,
  optionText: string,
  points: iltypes.Integerlike,
  meta: {
    markedForDelete: boolean,
    ui: {
      expanded: boolean
    }
  }
}

const nextTentativePageItemID = (() => {
  let v = 0;
  return () => `tentativePageItemID/${v++}`
})()

const nextTentativeQuestionID = (() => {
  let v = 0;
  return () => `tentativeQuestionID/${v++}`
})()

const nextTentativeQuestionOptionID = (() => {
  let v = 0;
  return () => `tentativeQuestionOptionID/${v++}`
})()

function questionTypeRequiresAssociatedOptions(type: QuestionType) : boolean {
  switch (type) {
    case QuestionType.CHECKBOX: return true;
    case QuestionType.RADIO: return true;
    case QuestionType.SELECT: return true;
    case QuestionType.TEXT: return false;
    case QuestionType.TEXTAREA: return false;
    default: exhaustiveCaseGuard(type);
  }
}

function freshTournTeamRegForm_PageItem_ContentChunk(tournamentID: iltypes.Integerlike) : TournTeamRegForm_PageItem_ContentChunk {
  return {
    mode: "tentative",
    pageItemType: PageItemType.CONTENT_CHUNK,
    id: nextTentativePageItemID(),
    tournamentID,
    order: -1,
    containedItem: {
      label: "",
      shortLabel: "",
      defaultText: "",
    }
  }
}

function freshTournTeamRegForm_PageItem_ContentChunk_fromExisting(pageItem: iltournament.TournTeamRegPageItem_ContentChunk) : TournTeamRegForm_PageItem_ContentChunk {
  return {
    mode: "persisted",
    pageItemType: PageItemType.CONTENT_CHUNK,
    tournamentID: pageItem.tournamentID,
    id: pageItem.id.toString(),
    order: pageItem.order,
    containedItem: {
      label: pageItem.containedItem.label,
      shortLabel: pageItem.containedItem.shortLabel,
      defaultText: pageItem.containedItem.defaultText,
    }
  }
}

function freshTournTeamRegForm_PageItem_Question(tournamentID: iltypes.Integerlike, type: QuestionType) : TournTeamRegForm_PageItem_Question {
  return {
    mode: "tentative",
    tournamentID,
    pageItemType: PageItemType.QUESTION,
    id: nextTentativePageItemID(),
    order: -1,
    containedItem: {
      id: nextTentativeQuestionID(),
      type,
      label: "",
      shortLabel: "",
      isEditable: false,
      isRequired: false,
      questionOptions: questionTypeRequiresAssociatedOptions(type)
        ? [freshTournTeamRegForm_QuestionOption()]
        : null,
    }
  }
}

function freshTournTeamRegForm_PageItem_Question_fromExisting(v: iltournament.TournTeamRegPageItem_Question) : TournTeamRegForm_PageItem_Question {
  return {
    mode: "persisted",
    tournamentID: v.tournamentID,
    pageItemType: PageItemType.QUESTION,
    id: v.id.toString(),
    order: v.order,
    containedItem: {
      id: v.containedItem.id,
      type: v.containedItem.type,
      label: v.containedItem.label,
      shortLabel: v.containedItem.shortLabel,
      isEditable: !!v.containedItem.isEditable,
      isRequired: !!v.containedItem.isRequired,
      questionOptions: questionTypeRequiresAssociatedOptions(v.containedItem.type)
        ? v.containedItem.questionOptions.map(freshTournTeamRegForm_QuestionOption_fromExisting)
        : null,
    }
  }
}

function freshTournTeamRegForm_QuestionOption() : TournTeamRegForm_QuestionOption {
  return {
    mode: "tentative",
    id: nextTentativeQuestionOptionID(),
    optionValue: "",
    optionText: "",
    points: 0,
    meta: {
      markedForDelete: false,
      ui: {
        expanded: false,
      }
    }
  }
}

function freshTournTeamRegForm_QuestionOption_fromExisting(v: iltypes.QuestionOption) : TournTeamRegForm_QuestionOption {
  return {
    mode: "persisted",
    id: v.id,
    optionValue: v.optionValue,
    optionText: v.optionText,
    // maybe we want to loop `""` through?
    points: v.tournamentTeamReg_points === "" ? 0 : v.tournamentTeamReg_points,
    meta: {
      markedForDelete: false,
      ui: {
        expanded: false,
      }
    }
  }
}

function tournTeamRegForm_PageItem_Question_toSubmittable_create(v: TournTeamRegForm_PageItem_Question) : iltournament.CreateTournTeamRegQuestionPageItemArgs {
  if (v.mode !== "tentative") {
    throw Error("calling create on an already persisted page item? (did you mean to call update?)")
  }
  return {
    tournamentID: v.tournamentID,
    type: v.containedItem.type,
    label: v.containedItem.label,
    shortLabel: v.containedItem.shortLabel,
    isEditable: v.containedItem.isEditable,
    isRequired: v.containedItem.isRequired,
    questionOptions: v.containedItem.questionOptions?.map(_ => ({optionValue: _.optionValue, optionText: _.optionText, points: _.points})),
  }
}

function tournTeamRegForm_PageItem_Question_toSubmittable_update(v: TournTeamRegForm_PageItem_Question) : iltournament.UpdateTournTeamRegQuestionPageItemArgs {
  if (v.mode !== "persisted") {
    throw Error("updating a not-already persisted page item? (did you mean to call create?)")
  }
  return {
    pageItemID: v.id as iltypes.Integerlike,
    isDisabled: false, // ??? are we tracking this ???
    containedItem: {
      label: v.containedItem.label,
      shortLabel: v.containedItem.shortLabel,
      isEditable: v.containedItem.isEditable,
      isRequired: v.containedItem.isRequired,
      questionOptions: v
        .containedItem
        .questionOptions
        ?.filter(v => !v.meta.markedForDelete) // keep items NOT marked for delete
        .map(_ => ({optionValue: _.optionValue, optionText: _.optionText, points: _.points})),
    }
  }
}

function tournTeamRegForm_PageItem_ContentChunk_toSubmittable_create(v: TournTeamRegForm_PageItem_ContentChunk) : iltournament.CreateTournTeamRegContentPageItemArgs {
  return {
    tournamentID: v.tournamentID,
    label: v.containedItem.label,
    shortLabel: v.containedItem.shortLabel,
    defaultText: v.containedItem.defaultText,
  };
}

function tournTeamRegForm_PageItem_ContentChunk_toSubmittable_update(v: TournTeamRegForm_PageItem_ContentChunk) : iltournament.UpdateTournTeamRegContentPageItemArgs {
  return {
    pageItemID: v.id as iltypes.Integerlike,
    label: v.containedItem.label,
    shortLabel: v.containedItem.shortLabel,
    defaultText: v.containedItem.defaultText,
    order: v.order,
  };
}

const TournTeamPageItemQuestionForm = defineComponent({
  name: "TournTeamPageItemQuestionForm",
  props: {
    mut_form: {
      required: true,
      type: Object as PropType<TournTeamRegForm_PageItem_Question>
    }
  },
  emits: {
    submit: () => true,
  },
  setup(props, {emit}) {
    return () => (
      <div data-test="TournTeamPageItemQuestionForm">
        <FormKit type="form" onSubmit={() => emit("submit")} actions={false}>
          <div class="p-2 mb-4 border border border-slate-100 shadow-md" style="max-width:var(--fk-max-width-input);">
            <div class="mb-2">
              <div class="flex flex-row items-start">
                <FormKit
                  type="text" v-model={props.mut_form.containedItem.label}
                  outer-class="$reset"
                  label="Label"
                  name="label"
                  validation={[["required"]]}
                  {...{autocomplete: "off"}}
                />
                <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `Question label displayed on the registration form.`}} tabindex="-1">
                  <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]} />
                </button>
              </div>
            </div>
            <div class="mb-2">
              <div class="flex flex-row items-start">
                <FormKit
                  type="text" v-model={props.mut_form.containedItem.shortLabel}
                  outer-class="$reset"
                  label="Short Label"
                  name="shortLabel"
                  validation={[["required"]]}
                  {...{autocomplete: "off"}}
                />
                <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `Shorthand reference to the registration field used in administration and reporting.`}} tabindex="-1">
                  <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]}/>
                </button>
              </div>
            </div>
            <div class="flex flex-wrap pt-5">
              <FormKit
                type="checkbox" v-model={props.mut_form.containedItem.isEditable}
                class="flex-auto gap-4 h-full" name="isEditable"
                validation={[["required"]]}
              />
                <label class="font-medium text-smt-label ml-5">
                  User may edit answer
                  <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `If true, the value of this field may be edited by a tournament team admin after the associated registration is submitted.`}} tabindex="-1">
                    <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]} />
                  </button>
                </label>
            </div>
            <div class="flex flex-wrap pt-5">
              <FormKit
                type="checkbox" v-model={props.mut_form.containedItem.isRequired}
                class="m-2"
                name="isRequired"
                validation={[["required"]]}
              />
                <label class="font-medium text-smt-label ml-5">
                  Response Required
                  <button type="button" class="ml-3 cursor-pointer" v-tooltip={{content: `If checked, a registration may not be submitted unless this question is answered.`}} tabindex="-1">
                    <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]} />
                  </button>
                </label>
            </div>
          </div>
          <div>
            <div class="p-2 mb-4 border border border-slate-200 shadow-md" style="max-width:var(--fk-max-width-input);">
              <div class="text-sm font-normal">Preview</div>
              <PageItem_Question_PreviewRender
                inputType={props.mut_form.containedItem.type}
                label={props.mut_form.containedItem.label}
                selectOptions={props.mut_form.containedItem.questionOptions ?? []}
              />
            </div>
          </div>
          {
            props.mut_form.containedItem.questionOptions
              ? (
                <div class="shadow-md border border-slate-200 max-w-3xl">
                  <div class="p-2 font-medium">Question Options</div>
                  <Options form={props.mut_form} questionOptions={props.mut_form.containedItem.questionOptions} />
                </div>
              )
              : null
          }
          {
            /*
            <GateFunctionEditor onapiError="apiError=true" labelItem="Registration Item" item="form" gateQuestionType="1"
              v-modelgateFunction="form.gateFunctionName" triggerSave="triggerSave" itemID="questionID"
              onupdateStatus="status=>updateStatus(status, 'gateFunction')"></GateFunctionEditor>
            <SeasonSelector onapiError="apiError=true" labelItem="Registration Item" selectedSeasonsObjects="seasonUIDs"
              onupdateseasonUIDs="(val)=>seasonUIDs=val" triggerSave="triggerSave" pageItemID="pageItemID"
              onupdateStatus="status=>updateStatus(status, 'season')"></SeasonSelector>
            <CompetitionSelector data-test="CompetitionSelector" onapiError="apiError=true" labelItem="Registration Item"
              onupdatecompetitionUIDs="val=>competitionUIDs=val" onupdateStatus="status=>updateStatus(status, 'competition')"
              selectedCompetitionsObjects="competitionUIDs" triggerSave="triggerSave" pageItemID="pageItemID">
            </CompetitionSelector><t-btn class="`${hasSomeSubmitBlockingError ? 'bg-gray-300' : ''}`"
              disable="hasSomeSubmitBlockingError">
              <div>Save Custom Question</div>
            </t-btn>
            <div class="text-sm text-red-700" v-if="hasSomeSubmitBlockingError">Sorry, not all fields are filled out correctly.
            </div>
            */
          }
          <t-btn type="submit" class="my-4" data-test="submit">
            {
              props.mut_form.mode === "tentative"
                ? "Save new question"
                : "Save changes"
            }
          </t-btn>
        </FormKit>
      </div>
    )
  }
})

const TournTeamPageItemContentForm = defineComponent({
  name: "TournTeamPageItemContentForm",
  props: {
    mut_form: {
      required: true,
      type: Object as PropType<TournTeamRegForm_PageItem_ContentChunk>
    }
  },
  emits: {
    submit: () => true,
  },
  setup(props, {emit}) {
    const MAX_TEXT_LENGTH = 1000;
    return () => (
      <div>
        <div class="p-2 mb-4 border border border-slate-100 shadow-md" style="max-width:var(--fk-max-width-input);">
          <div class="mb-2">
            <div class="flex flex-row">
              <FormKit
                type="text" v-model={props.mut_form.containedItem.label}
                outer-class="$reset"
                label="Label"
                name="label"
                validation={[["required"]]}
                {...{autocomplete: "off"}}
              />
              <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `Question label displayed on the registration form.`}} tabindex="-1">
                <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]} />
              </button>
            </div>
          </div>
          <div class="mb-2">
            <div class="flex flex-row">
              <FormKit
                type="text" v-model={props.mut_form.containedItem.shortLabel}
                outer-class="$reset"
                label="Short Label"
                name="shortLabel"
                validation={[["required"]]}
                {...{autocomplete: "off"}}
              />
              <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `Shorthand reference to the registration field used in administration and reporting.`}} tabindex="-1">
                <FontAwesomeIcon class="mr-2 text-gray-400" icon={["fas", "info-circle"]}/>
              </button>
            </div>
          </div>
        </div>
        <QuillWrapper2 v-model={props.mut_form.containedItem.defaultText} configureFor="content-chunk"/>

        <div class="flex flex-col items-end mt-1">
          <div class={`${props.mut_form.containedItem.defaultText.length > MAX_TEXT_LENGTH ? "text-red-600" : ""} text-xs`}>
            {props.mut_form.containedItem.defaultText.length}/{MAX_TEXT_LENGTH}
          </div>
          <div class="text-xs">
            Limit includes HTML characters introduced for formatting
          </div>
        </div>

        <t-btn type="button" class="my-4" onClick={() => emit("submit")}>
          {
            props.mut_form.mode === "tentative"
              ? "Save new question"
              : "Save changes"
          }
        </t-btn>
      </div>
    )
  }
})

const Options = defineComponent({
  name: "Options",
  props: {
    form: {
      required: true,
      type: Object as PropType<TournTeamRegForm_PageItem_Question>
    },
    /**
     * Generally, props.form.underlying.questionOptions === props.questionOptions
     * This helps us narrow away the null case; in this context, props.questionOptions is definitely truthy
     */
    questionOptions: {
      required: true,
      type: Array as PropType<TournTeamRegForm_QuestionOption[]>
    }
  },
  setup(props) {
    const pushFreshOption = () => {
      const freshOption = freshTournTeamRegForm_QuestionOption()
      freshOption.meta.ui.expanded = true;
      props.questionOptions.push(freshOption);
    }

    const deleteOption = (option: TournTeamRegForm_QuestionOption) => {
      const targetIdx = props.questionOptions.findIndex(v => v === option)
      if (targetIdx === -1) {
        // shouldn't happen, couldn't find it
        return;
      }

      if (option.mode === "tentative") {
        props.questionOptions.splice(targetIdx, 1)
        if (props.questionOptions.length === 0) {
          pushFreshOption()
        }
      }
      else if (option.mode === "persisted") {
        option.meta.markedForDelete = true;
      }
      else {
        exhaustiveCaseGuard(option.mode)
      }
    }


    const moveOptionTowardsHeadOfList = (option: TournTeamRegForm_QuestionOption) => {
      const targetIdx = props.questionOptions.findIndex(v => v === option)
      if (targetIdx === -1) {
        // shouldn't happen
        return;
      }

      if (targetIdx === 0) {
        return
      }
      else {
        const saved = props.questionOptions[targetIdx - 1]
        props.questionOptions[targetIdx - 1] = option
        props.questionOptions[targetIdx] = saved
      }
    }

    const moveOptionTowardsTailOfList = (option: TournTeamRegForm_QuestionOption) => {
      const targetIdx = props.questionOptions.findIndex(v => v === option)
      if (targetIdx === -1) {
        // shouldn't happen
        return;
      }

      if (targetIdx === props.questionOptions.length - 1) {
        return
      }
      else {
        const saved = props.questionOptions[targetIdx + 1]
        props.questionOptions[targetIdx + 1] = option
        props.questionOptions[targetIdx] = saved
      }
    }

    const FK_VALID = true;
    const FK_INVALID = false;
    // "indeterminate" is considered valid; it means we don't have enough info
    // to know if some rule has been violated, so we defer to "is valid" to avoid unnecessary error messages
    const FK_INDETERMINATE = FK_VALID;

    function assertNodeTypeIsString(v: any) : asserts v is string {
      if (typeof v !== "string") {
        throw "expected node to have value of type string";
      }
    }

    const ilfkrule_checkboxQuestioncontainsIllegalComma = (node: FormKitNode) : boolean => {
      assertNodeTypeIsString(node.value)
      if (props.form.containedItem.type === QuestionType.CHECKBOX && /,/.test(node.value)) {
        return FK_INVALID
      }
      return FK_VALID
    }

    const ilfkrule_isDuplicateOptionValue = (_: FormKitNode, option: TournTeamRegForm_QuestionOption) : boolean => {
      if (option.optionValue.trim() === "") {
        return FK_INDETERMINATE;
      }

      const someOtherOptionExistsWithThisValue = !!props
        .questionOptions
        .find(otherOption => {
          return otherOption.id !== option.id
            && otherOption.optionValue.toLowerCase().trim() === option.optionValue.toLowerCase().trim()
        })

      if (someOtherOptionExistsWithThisValue) {
        return FK_INVALID
      }
      else {
        return FK_VALID
      }
    }

    const ilfkrule_cannotBeAllWhitespace = (node: FormKitNode) : boolean => {
      assertNodeTypeIsString(node.value)
      if (node.value.trim() === "") {
        return FK_INVALID
      }
      else {
        return FK_VALID
      }
    }

    /**
     * formkit's own typedef for their `validationRules` prop is wrong, this constrains some validator function as
     * it actually should be constrained, and then returns that validator cast as any. This has no runtime effect, it only
     * patches up some type issues.
     */
    function FK_BUGWORKAROUND_VALIDATOR_OF_ANY_NODETYPE_OR_ARITY<F extends (node: FormKitNode<any>, ...args: any[]) => any>(f: F) : any {
      return f;
    }

    return () => (
      <div>
        <div>
          {
            props.questionOptions.map((option, idx) => {
              return (
                <>
                <div class={`w-full ${idx % 2 ? "bg-slate-100" : ""}`} key={option.id} data-test={`questionOptionID=${option.id}`}>
                  <div class="flex p-2">
                    <div class="ml-2 mr-4 flex items-start justify-center">
                      <div class="flex items-center justify-center border border-slate-400 bg-white" style="width:2em;height:2em;border-radius:50%">{idx+1}</div>
                    </div>
                    <div>
                      <div class="flex items-start">
                        <FormKit
                          label="Option Text"
                          type="text"
                          style="--fk-bg-input: white;"
                          v-model={option.optionText}
                          validation={[
                            ["required"],
                            ["ilfkrule_cannotBeAllWhitespace"]
                          ]}
                          validationRules={{ilfkrule_cannotBeAllWhitespace}}
                          validationMessages={{ilfkrule_cannotBeAllWhitespace: "Value may not be entirely composed of spaces."}}
                          data-test="optionText"
                          {...{autocomplete: "off"}}
                        />
                        <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `The label displayed for the individual option.`}} tabindex="-1">
                          <FontAwesomeIcon class="text-gray-400" icon={["fas", "info-circle"]} />
                        </button>
                      </div>
                      <div class="flex items-start">
                        <FormKit
                          type="text"
                          label="Option Value"
                          v-model={option.optionValue}
                          style="--fk-bg-input: white;"
                          validation={[
                            ["required"],
                            ["ilfkrule_checkboxQuestioncontainsIllegalComma"],
                            ["ilfkrule_isDuplicateOptionValue", option],
                            ["ilfkrule_cannotBeAllWhitespace"]
                          ]}
                          validationRules={{
                            ilfkrule_checkboxQuestioncontainsIllegalComma,
                            ilfkrule_isDuplicateOptionValue: FK_BUGWORKAROUND_VALIDATOR_OF_ANY_NODETYPE_OR_ARITY(ilfkrule_isDuplicateOptionValue),
                            ilfkrule_cannotBeAllWhitespace
                          }}
                          validationMessages={{
                            ilfkrule_checkboxQuestioncontainsIllegalComma: "Option value for a checkbox cannot contain a comma character.",
                            ilfkrule_isDuplicateOptionValue: "This value is a duplicate with another value.",
                            ilfkrule_cannotBeAllWhitespace: "Value may not be entirely composed of spaces."
                          }}
                          data-test="optionValue"
                          {...{autocomplete: "off"}}
                        />
                        <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `The value when this option is selected.`}} tabindex="-1">
                          <FontAwesomeIcon class="text-gray-400" icon={["fas", "info-circle"]} />
                        </button>
                      </div>
                      <div class="flex items-start">
                        <FormKit
                          label="Option point value"
                          type="number"
                          step="1"
                          style="--fk-bg-input: white;"
                          v-model={option.points}
                          validation={[
                            ["required"],
                          ]}
                          data-test="optionPoints"
                          {...{autocomplete: "off"}}
                        />
                        <button type="button" class="ml-1 cursor-pointer" v-tooltip={{content: `Points value associated with this option.`}} tabindex="-1">
                          <FontAwesomeIcon class="text-gray-400" icon={["fas", "info-circle"]} />
                        </button>
                      </div>
                    </div>
                    <div style="margin-left: auto; display:grid; grid-template-rows: max-content max-content; grid-template-columns: max-content max-content; align-items:center;">
                      <div
                        class="mx-2 p-2 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] transition-all rounded-sm"
                        onClick={() => moveOptionTowardsHeadOfList(option)}
                      >
                        <FontAwesomeIcon icon={["fas", "arrow-up"]} />
                      </div>
                      <div
                        class="mx-2 p-2 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] transition-all rounded-sm"
                        onClick={() => deleteOption(option)}
                      >
                        <X width="1em" height="1em" penColor="rgb(200,50,50)"/>
                      </div>
                      <div
                        class="mx-2 p-2 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] transition-all rounded-sm"
                        onClick={() => moveOptionTowardsTailOfList(option)}
                      >
                        <FontAwesomeIcon icon={["fas", "arrow-up"]} class={`transform rotate-180`}/>
                      </div>
                      <div class="m-2 p-2">{/*cell placeholder*/}</div>
                    </div>
                  </div>
                </div>
                </>
              )
            })
          }
        </div>
        <div class="m-2">
          <t-btn type="button" onClick={pushFreshOption}>
            <FontAwesomeIcon class="" icon={["fas", "plus-square"]} />
            <div class="ml-2 col-span-7" data-test="addOption">Add Option</div>
          </t-btn>
        </div>
      </div>
    )
  }
})

const Impl = defineComponent({
  name: "R_TournamentAdminPageItemEditor.Impl",
  props: R_Self.propsDef,
  setup(props) {
    const iziToast = useIziToast();
    const router = useRouter();
    const targetUnderlyingType = ref<UnderlyingType | "">("")

    // can be null due to lifecyle
    const form = ref<TournTeamRegForm_PageItem | null>(null)
    const ready = ref<"pending" | "load-failure" | "ready">("pending");

    onMounted(async () => {
      switch (props.detail.name) {
        case R_Self.RouteNames.new: {
          targetUnderlyingType.value = "Q_SELECT";

          watch(() => targetUnderlyingType.value, () => {
            if (targetUnderlyingType.value === "") {
              // chose the 'nil' option
              return;
            }

            form.value = freshTournTeamRegForm_tentativeForUnderlyingType(props.detail.tournamentID, targetUnderlyingType.value);
          }, {immediate: true})

          ready.value = "ready";
          return;
        }
        case R_Self.RouteNames.edit: {
          // get the page item
          const pageItem = await tournTeamRegPageItemStore.getByTournamentPageItemIdOrNull(
            axiosInstance, {
              tournamentID: props.detail.tournamentID,
              pageItemID: props.detail.pageItemID
            });

          if (pageItem === null) {
            // shouldn't happen, but, this is parameterized by route url, and the user can type in garbage
            // (and, of course, network failures and etc., too)
            ready.value = "load-failure";
            return;
          }

          // set targetUnderlyingType just for UI purposes
          targetUnderlyingType.value = underlyingTypeFromPageItem(pageItem);

          // build the form from existing
          form.value = freshTournTeamRegForm_fromExisting(pageItem);
          ready.value = "ready";
        }
      }
    })

    const doSave = async () : Promise<void> => {
      if (!form.value) {
        // shouldn't happen
        return;
      }

      if (form.value.mode === "tentative") {
        if (form.value.pageItemType === PageItemType.QUESTION) {
          await tournTeamRegPageItemStore.createQuestionPageItem(axiosInstance, tournTeamRegForm_PageItem_Question_toSubmittable_create(form.value));
          iziToast.success({message: "Item created."})
          router.push(R_TournamentTeamRegPageItemListing.routeDetailToRouteLocation({name: R_TournamentTeamRegPageItemListing.RouteNames.main}))
        }
        else if (form.value.pageItemType === PageItemType.CONTENT_CHUNK) {
          await tournTeamRegPageItemStore.createContentPageItem(axiosInstance, tournTeamRegForm_PageItem_ContentChunk_toSubmittable_create(form.value));
          iziToast.success({message: "Item created."})
          router.push(R_TournamentTeamRegPageItemListing.routeDetailToRouteLocation({name: R_TournamentTeamRegPageItemListing.RouteNames.main}))
        }
        else {
          exhaustiveCaseGuard(form.value)
        }
      }
      else if (form.value.mode === "persisted") {
        if (form.value.pageItemType === PageItemType.QUESTION) {
          await tournTeamRegPageItemStore.updateQuestionPageItem(axiosInstance, tournTeamRegForm_PageItem_Question_toSubmittable_update(form.value));
          iziToast.success({message: "Item updated."})
          router.push(R_TournamentTeamRegPageItemListing.routeDetailToRouteLocation({name: R_TournamentTeamRegPageItemListing.RouteNames.main}))
        }
        else if (form.value.pageItemType === PageItemType.CONTENT_CHUNK) {
          await tournTeamRegPageItemStore.updateContentPageItem(axiosInstance, tournTeamRegForm_PageItem_ContentChunk_toSubmittable_update(form.value));
          iziToast.success({message: "Item updated."})
          router.push(R_TournamentTeamRegPageItemListing.routeDetailToRouteLocation({name: R_TournamentTeamRegPageItemListing.RouteNames.main}))
        }
        else {
          exhaustiveCaseGuard(form.value)
        }
      }
      else {
        exhaustiveCaseGuard(form.value.mode)
      }
    }

    return () => {
      if (ready.value === "pending") {
        return null;
      }
      else if (ready.value === "load-failure") {
        return <div>Sorry, we couldn't find that page item.</div>
      }
      else {
        return (
          <div>
            <h1 class="text-4xl font-medium mb-5">
              <FontAwesomeIcon icon={["fas", "plus-circle"]} />
              {
                props.detail.name === R_Self.RouteNames.new
                  ? <span class="ml-2">New tournament team registration page item</span>
                  : <span class="ml-2">Edit tournament team registration page item</span>
              }
            </h1>
            <div class="max-w-md">
              <FormKit
                type="select"
                v-model={targetUnderlyingType.value}
                label="Registration item type"
                options={[{label: "", value: ""}, ...fk_underlyingTypeOptions]}
                disabled={props.detail.name === R_Self.RouteNames.edit}
              />
            </div>

            {
              form.value?.pageItemType === PageItemType.QUESTION
                ? <TournTeamPageItemQuestionForm onSubmit={doSave} mut_form={form.value} key={form.value.id}/>
                : form.value?.pageItemType === PageItemType.CONTENT_CHUNK
                ? <TournTeamPageItemContentForm onSubmit={doSave} mut_form={form.value} key={form.value.id}/>
                : null
            }
          </div>
        )
      }
    }
  }
})

export default defineComponent({
  name: "R_TournamentAdminPageItemEditor",
  props: R_Self.propsDef,
  setup(props) {
    const key = computed(() => {
      switch (props.detail.name) {
        case R_Self.RouteNames.new: return props.detail.tournamentID
        case R_Self.RouteNames.edit: return `${props.detail.tournamentID}/${props.detail.pageItemID}`
        default: return exhaustiveCaseGuard(props.detail)
      }
    })

    return () => <Impl {...props} key={key.value}/>
  }
});
