/**
 * This module exists only to break a cycle in store->router->store
 * Generally, using the router should be done via `useRouter()` inside a vue component.
 * Sometimes we're not in a vue component but we still need the router (inside a store),
 * using this is a replacement for a direct import of `router` from `./index`.
 * The caller must know that the router has been initialized (which is generally true for almost
 * the entire application, only a few parts before/during app boot will see an undefined router_ from
 * a call to getRouter() here)
 */
import type { Router } from "vue-router"

// @no-export
let router_! : Router;

/**
 * The returns a "definitely initialized" (i.e. NonNull) router; the caller is expected to understand
 * where they are in the app lifecycle. During app boot (prior to anything importing `./index`)
 * this may return undefined.
 */
export const getRouter = () => router_;

/**
 * Do not use this anywhere except from within `./index`.
 */
export const __setters__ = {
  setRouter: (v: Router) => { router_ = v; }
} as const;
