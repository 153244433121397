import { ExtractPropTypes, PropType, defineComponent, onMounted, ref } from "vue";

import * as ilgame from "src/composables/InleagueApiV1.Game"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Modal, Slots } from "../UserInterface/Modal";
import { ExtractEmitsHandlers } from "src/helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dayjsFormatOr } from "src/helpers/formatDate";

interface PropsDetail {
  gameStart: iltypes.DateTimelike,
  homeTeamDetail: {descriptor: string}
  visitorTeamDetail: {descriptor: string}
  homeTeamCoachInfo: ilgame.AdhocCoachInfo[]
  visitorTeamCoachInfo: ilgame.AdhocCoachInfo[]
}

const propsDef = {
  isOpen: {
    required: true,
    type: Boolean,
  },
  detail: {
    required: true,
    type: null as any as PropType<PropsDetail | null>
  }
} as const;

const emitsDef = {
  close: () => true,
  afterLeave: () => true,
} as const;

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export const CoachListingByGameModal = defineComponent({
  name: 'CoachListingByGameModal',
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const coachInfoAsElement = (v: ilgame.AdhocCoachInfo) => {
      return (
        <li style="display:grid; grid-template-columns: min-content 1fr;" class="ml-1">
          <div style="font-size:.0625in; align-self:center;">
            <FontAwesomeIcon icon={["fa-solid", "fa-circle"]}/>
          </div>
          <div class="ml-1">{v.title} {v.firstName} {v.lastName}</div>
          <div>{/*cell placeholder*/}</div>
          <div class="ml-1 text-sm">{v.email}</div>
        </li>
      )
    }

    const slots : Slots = {
      title: () => (
        props.detail
          ? (
            <>
              <div>Coach detail</div>
              <div class="text-xs font-normal text-gray-800 my-1">
                <div>{props.detail.homeTeamDetail.descriptor || "TBD"} v. {props.detail.visitorTeamDetail.descriptor || "TBD"}</div>
                <div>{dayjsFormatOr(props.detail.gameStart, "MMM/DD/YYYY @ h:mm a")}</div>
              </div>
              <div class="border-b border-slate-300 mb-2" />
            </>
          )
          : <div></div>
      ),
      content: () => {
        if (!props.detail) {
          return <div></div>
        }
        else {
          return (
            <div>
              <div>{props.detail.homeTeamDetail.descriptor || "TBD (home)"}</div>
              {
                props.detail.homeTeamCoachInfo.length === 0
                  ? <div class="pl-2">N/A</div>
                  : <ul>{props.detail.homeTeamCoachInfo.map(coachInfoAsElement)}</ul>
              }
              <div class="border-b border-slate-200 border-dashed my-1"/>
              <div>{props.detail.visitorTeamDetail.descriptor || "TBD (visitor)"}</div>
              {
                props.detail.visitorTeamCoachInfo.length === 0
                  ? <div class="pl-2">N/A</div>
                  : <ul>{props.detail.visitorTeamCoachInfo.map(coachInfoAsElement)}</ul>
              }
            </div>
          )
        }
      }
    }

    return () => (
        <Modal
          data-test="CoachListingByGameModal"
          isOpen={props.isOpen}
          onClose={() => emit("close")}
          onOnAfterLeave={() => emit("afterLeave")}
        >
          {{...slots}}
        </Modal>
    )
  },
})
