import { GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries } from "src/composables/InleagueApiV1";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Game, WithDefinite } from "src/interfaces/InleagueApiV1";

/**
 * The type of a game object, expanded with entities required for this component
 */
export type ExpandedGame =
  & WithDefinite<Game, "homeTeam" | "visitorTeam">
  & GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries

export interface AllScoresComponentState {
  selectedCompetitionUID: "" | iltypes.Guid,
  selectedDivID: "" | iltypes.Guid,
  selectedDateRange: string // e.g. "week" | "month" or similar 'range descriptor'
  /**
   * we might want this to be some kind of global state ("The" cache of games on the client)
   * but it gets tough with "some are expanded in certain ways and others are expanded (or not expanded at all) in other ways"
   * We could probably be smarter about this (selective updates, based on existing expansions ... ) but it's not necessary right now
   */
  gamesListing: ExpandedGame[]
  filterState: FilterState
}

export enum DoPointsCountVisibility{
  ONLY_SHOW_WHERE_POINTS_COUNT = "ONLY_SHOW_WHERE_POINTS_COUNT",
  SHOW_ALL = "SHOW_ALL",
}

export interface FilterState {
  doPointsCountVisibility: DoPointsCountVisibility
}