<template lang="pug">
t-dialog(v-if='show' data-test="DuplicatePlayer")
  p.text-red-600.font-bold.uppercase.text-xl Attention:
  p.text-lg.mt-4.italic More than one player with the exact name and birth date you entered exists already in our database.

  p.mt-4 If you are absolutely certain that neither you nor any other family member have added your player to our online system or registered him or her for a past season, please add player. Otherwise, continue with registration by viewing your family members.

  .flex.mt-4.w-full.justify-between
    t-btn(
      @click='$emit("close-popup")',
      label='Add Player',
      data-test='addPlayer'
    )
    t-btn(
      @click='$emit("register")',
      data-cy='proceedToRegistration'
      color='red'
      label='View Family Members'
    )
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'


export default defineComponent({
  props: {
    playerName: String,
  },

  emits: ['closePopup', 'register'],
  setup() {
    // Data
    const $router = useRouter()
    const show = true

    // Methods
    const forgotPassword = async () => {
      await $router.push('forgot-password')
    }
    return {
      show,
      forgotPassword,
    }
  },
})
</script>
