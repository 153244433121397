<template lang="pug">
.t-page.flex.flex-center
  div
    h1.text-4xl.self-end.font-medium
      i.mr-2.fas.fa-user-lock
      | Forgot Password?
    .d-pa-md.d-gutter-sm(@keyup.enter='requestNewPwd')
      p Please enter your email address and last name. A link to reset your password will be set if your account exists.
      TAlert(
        v-if='errorMessage',
        title='Error:',
        :message='errorMessage',
        type='danger'
      )
      TAlert(
        v-if='successMessage',
        title='',
        :message='successMessage',
        type='success'
      )
      FormKit(
        v-model='userEmail',
        name='email',
        label='E-mail',
        validations='required|email'
      )
      FormKit(
        v-model='lastName',
        name='lastName',
        label='Last Name',
        validations='required'
      )
      FormKit(
        type='submit',
        label='Request New Password',
        data-cy='submitPwdRequest',
        @click='requestNewPwd'
      )
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { axiosNoAuthInstance } from 'boot/axios'



import TAlert from 'components/UserInterface/t-alert.vue'

import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"
import { User } from 'src/store/User'

export default defineComponent({
  name: 'RequestNewPwd',
  components: { TAlert },
  setup() {


    const userEmail = ref('')
    const lastName = ref('')
    const successMessage = ref('')
    const errorMessage = ref('')

    onMounted(() => {
      if (User.value.userEmail) {
        userEmail.value = User.value.userEmail
      }
    })

    const setAPIErrors = (errorMsgs: Array<string>): void => {
      errorMessage.value = errorMsgs.join(' ')
    }

    const requestNewPwd = async () : Promise<void> => {
      const worker = () : Promise<void> => axiosNoAuthInstance
        .get(
          `/public/user/${encodeURIComponent(
            userEmail.value
          )}/passwordReset/?lastName=${lastName.value}`
        )
        .then(() => {
          successMessage.value =
        'Request successful! Please check your e-mail for the link to reset your password.'
        })
        .catch((error: any) => {
          setAPIErrors(error.response.data.messages)
        })

      try {
        GlobalInteractionBlockingRequestsInFlight.increment();
        await worker();
      }
      finally {
        GlobalInteractionBlockingRequestsInFlight.decrement();
      }
    }

    return {
      userEmail,
      lastName,
      successMessage,
      errorMessage,
      requestNewPwd,
    }
  },
})
</script>
