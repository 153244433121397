<template lang="pug">
.flex.flex-col.items-center
  h1.mt-4.text-center All Referee Assignments
  div(class='sm:mx-auto sm:w-full sm:max-w-md')
    FormKit.m-2(
      v-model='competition',
      :options='competitions',
      type='select',
      label='Competition',
      placeholder='Select a Competition',
      v-if='Object.keys(competitions).length > 0',
      data-cy='refCompetitions'
    )
    FormKit.m-2(
      v-model='division',
      :options='divisions',
      type='select',
      placeholder='Select a Division',
      label='Division',
      data-cy='refDivisions'
      :disabled='!competition',
    )
  .flex.justify-center.w-full
    .q-pa-md.quasar-style-wrap.mt-8(
      v-if='games.length',
      data-cy='fieldKeys',
    )
      q-table(
        :rows='keys',
        :columns='keyColumns',
        row-key='fieldID',
        @row-click='toMap',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-fieldID='props')
          q-td(:auto-width='true')
            div {{ props.row.fieldAbbrev }}
        template(v-slot:body-cell-fieldName='props')
          q-td.row.full-width(:props='props')
            .col-auto.q-pr-sm
              font-awesome-icon(:icon='["fas", "map-marked-alt"]')
            .col {{ props.row.fieldName }}

  .quasar-style-wrap.mt-8(
    v-if='games.length',
    data-cy='allRefAssignmentsTable',
  )
    .q-pa-md
      q-table(
        :rows='games',
        :columns='columns',
        row-key='gameID',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-gameStart='props')
          q-td(:props='props')
            .row
              .col-xs-12 {{ formatDateAsNums(props.value) }}
              .col-xs-12 {{ formatTime(props.value) }}
  .m-2(v-else-if='isDataLoaded', data-cy='noRefs')
    h4.text-center.italic.mt-2 Sorry, no referee assignments matched your query
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted, computed, watch } from 'vue'

import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { createSelect } from 'src/helpers/schemaService'
import { RefDetails, RefFieldDetails } from 'src/interfaces/InleagueApiV1'
import { AxiosErrorWrapper, axiosInstance, axiosNoAuthInstance } from 'src/boot/axios'
import * as ilapi from "src/composables/InleagueApiV1"
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'allRefAssignments',
  setup() {
    const competition = ref('')
    const competitions = ref({})
    const division = ref('')
    const divisions = ref({})
    const games = ref([]) as Ref<RefDetails[]>
    const keys = ref([]) as Ref<RefFieldDetails[]>
    const isDataLoaded = ref(false)

    const columns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefDetails) => ref.game.field.fieldAbbrev,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
      },
      {
        name: 'gameStart',
        required: false,
        label: 'Date',
        align: 'left',
        sortable: true,
        field: (ref: RefDetails) => {
          return ref.game.gameStart
        },
        style: 'vertical-align: top; font-size: 16px;',
        headerStyle: 'font-size: 16px',
      },
      {
        name: 'Teams',
        align: 'left',
        label: 'Teams',
        field: (ref: RefDetails) => {
          return ref.game.teamVersus
        },
        style: 'vertical-align: top; font-size: 16px;',
        sortable: true,
      },
      {
        name: 'Referee',
        align: 'left',
        label: 'Referee',
        field: (ref: RefDetails) => ref.lastName,
        style: 'vertical-align: top; font-size: 16px;',
        sortable: true,
      },
    ])

    const keyColumns = ref([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefFieldDetails) => ref.fieldAbbrev,
        sortable: true,
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        sortable: true,
        field: (ref: RefFieldDetails) => {
          return ref.fieldName
        },
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
      },
    ])



    const userAddress = computed(() => {
      return User.value.userAddress
    })

    const getOptions = async () => {
      const comps = (await getCompetitionsOrFail()).value;
      const competitionsSelectFormat = createSelect(
        comps,
        'competitionID',
        'competition'
      )
      competitions.value = competitionsSelectFormat

      const divs = await Client.loadDivisions();
      const divisionsSelectFormat = createSelect(divs, 'divID', 'displayName')
      divisionsSelectFormat['ALL'] = 'All Divisions'
      divisions.value = divisionsSelectFormat
    }

    const createKeys = () => {
      const gameKeys: { [key: number]: RefFieldDetails } = {}
      for (let i = 0; i < games.value.length; i++) {
        const game = games.value[i].game
        gameKeys[game.fieldID] = game.field
      }
      keys.value = []
      for (const key in gameKeys) {
        keys.value.push(gameKeys[key])
      }
    }

    const toMap = (evt: Event, row: RefFieldDetails) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldState} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }
    watch(competition, () => {
      if (Object.keys(division.value).length > 1) {
        division.value = ''
        games.value = []
      }
    })
    watch(division, async () => {
      if (division.value) {
        try {
          games.value = await ilapi.getRefereeAssignments(axiosInstance, {divID: division.value});
          isDataLoaded.value = true
          createKeys()
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
    })
    onMounted(async () => {
      await getOptions()
    })
    return {
      formatDateAsNums,
      formatTime,
      competition,
      competitions,
      division,
      divisions,
      games,
      keys,
      isDataLoaded,
      columns,
      keyColumns,
      getOptions,
      createKeys,
      toMap,
      userAddress,
    }
  },
})
</script>
