import { ExtractPropTypes, PropType } from "vue";
import { ExtractEmitsHandlers } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";

export function propsDef() {
  return {
    userID: {
      required: true as const,
      type: String as PropType<Guid>
    },
    userName: {
      required: true as const,
      type: String
    }
  };
}

export function emitsDef() {
  return {
    closeModalPerformingNoAction: () => true,
    doClearAysoInfo: (_v: {userID: Guid, userName: string}) => true
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;
