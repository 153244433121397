<!-- this would be jsx if we could type q-table -->
<!-- this is "Gamelike" because we have a few slight variants of a Game object that we are dealing with, depending on endpoint we pull from. -->
<template lang="pug">
div
  template(v-if="useMobileLayout")
    GamelikeScheduleTableSmall(
      class="w-full"
      :columns="columns"
      :rows="rows"
    )
  template(v-else)
    div(class="q-pa-md")
      q-table(
        class="text-lg"
        :columns='columns'
        :rows='rows'
        row-key='gameID'
        :rows-per-page-options='[0]'
        hide-pagination
        dense
      )
        template(v-slot:body="props")
          q-tr(:data-test="`gameID=${props.row.gameID}`")
            //-
            //- field info
            //-
            q-td(valign="top", :style="[{'font-size': '1em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div(class="text-sm")
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[0].value")

            //-
            //- field info
            //-
            q-td(valign="top", :style="[{'font-size': '1em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[1].value")

            //-
            //- game time info
            //-
            q-td(valign="top", :style="[{'font-size': '1em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div.row
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[2].value" class="col-xs-12")

            //-
            //- home team info
            //-
            q-td(valign="top", :style="[{'font-size': '1em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[3].value")

            //-
            //- visitor team info
            //-
            q-td(valign="top", :style="[{'font-size': '1em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[4].value")

            //- refs
            q-td(valign="top", :style="[{'font-size': '.75em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[5].value")

            //- comment
            q-td(valign="top", :style="[{'font-size': '.75em'}, props.row.shouldDisplayScoreInfo ? {'padding-bottom': 0, 'border-bottom':0} : {}]")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[6].value")

          //- score info and division standings links as necessary
          q-tr(v-if="props.row.shouldDisplayScoreInfo")
            q-td(valign="top" colspan="3" style="padding-top:0;")
              //- blank cell spacing
            q-td(valign="top" colspan="999" style="font-size:1em; padding-top:0;")
              div
                component(:is="JsxQuasarColumnRenderShim" :fieldResult="props.cols[7].value")
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { JsxQuasarColumnRenderShim, AugmentedGamelikeForSchedule, ColDef } from "./page/schedules.ilx"
import { TailwindBreakpoint, useWindowSize } from 'src/helpers/utils'
import { GamelikeScheduleTableSmall } from "./GamelikeScheduleTableSmall"

export default defineComponent({
  name: 'GamelikeScheduleTable',
  components: {
    GamelikeScheduleTableSmall
  },
  props: {
    columns: {
      required: true,
      type: Array as PropType<ColDef[]>
    },
    rows: {
      required: true,
      type: Array as PropType<AugmentedGamelikeForSchedule[]>
    }
  },
  setup() {
    const windowSize = useWindowSize();
    const useMobileLayout = computed(() => windowSize.width <= TailwindBreakpoint.xl);
    return {
      JsxQuasarColumnRenderShim,
      useMobileLayout,
    }
  }
})
</script>

<style scoped>
@media (min-width: 640px) {
  .tableWidth {
    width: 75vw !important;
  }
}

@media (min-width: 768px) {
  .tableWidth {
    width: 50vw !important;
  }
}

@media (min-width: 1024px) {
  .tableWidth {
    width: 600px !important;
  }
}
</style>
