<template lang="pug">
div(class="mb-6")
  RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")
.max-w-xl(v-if="ready")
  .flex.flex-col.items-end.mb-5(class="md:flex-row")
    div(style="display:grid; grid-template-columns: min-content auto;")
      h1.text-4xl.font-medium.mb-4(style="margin-bottom:0;")
        font-awesome-icon.mx-4.mt-2.cursor-pointer(:icon='["fas", "child"]', style="margin-top:0;")
      h1.text-4xl.font-medium.mb-4(style="margin-bottom:0;")
        | Registration:
        div.text-2xl {{seasonName}} - {{competitionNames}}
        div.text-2xl {{ playerDetails.playerFirstName }} {{ playerDetails.playerLastName}}
      div
        // empty grid cell placeholder

div.FORCE_DEFAULT_BROWSER_ANCHOR_STYLE_IN_ALL_DESCENDANTS.max-w-xl(v-if="ready")
  FormKit(
    type='form'
    @submit="saveRegistration"
    validation-visibility="live"
    incomplete-message='Please review the registration form for missing or invalid fields.'
    :actions="false"
  )
    RegistrationCore(
      v-bind="coreProps"
      @updateCoreQuestionAnswers="(val)=>updateCoreQuestionAnswers(val)"
      @notifyCoreQuestionsReceipt="notifyCoreQuestionsReceipt"
      @formErrors="(val)=>coreValidationErrors = val"
    )
    RegistrationCustomQuestions(
      :seasonUID="seasonUID",
      :competitionUIDs="competitionUIDs"
      :playerID="playerID"
      :maybeExistingRegistration="maybeExistingRegistration"
      :registrationAnswers="maybeExistingRegistration?.registrationAnswers ?? []"
      @formErrors="(val)=>customValidationErrors=val"
      @updateCustomQuestions="(val)=>updateCustomQuestions(val)"
    )
    t-btn(type="submit" data-test="submit")
      | Submit
</template>

<script lang="ts">
import iziToast from 'izitoast'
import { defineComponent, onMounted, ref, watch, Ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import RegistrationCustomQuestions from 'src/components/Registration/registrationForm/CustomQuestions'

import RegistrationCore from 'src/components/Registration/registrationForm/core.vue'
import * as M_RegistrationCore from "src/components/Registration/registrationForm/core.ilx"

import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'


import { copyViaJsonRoundTrip, sortBy } from 'src/helpers/utils'
import { Child, CoreQuestion, Guid, Registration, RegistrationAnswer } from 'src/interfaces/InleagueApiV1'
import { considerCoreQuestionDisabledForPrimaryRegistration } from './CoreQuestions'

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { answerMappingToApiV1SubmittableAnswerMapping, ClientSideRegistrationQuestionAnswerMap } from 'src/composables/customQuestions'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { propsDef } from "./R_PlayerRegistration.route"
import * as R_SelectPaymentOption from '../R_SelectPaymentOption.route'
import * as RegistrationJourneyBreadcrumb from "src/components/Registration/RegistrationJourneyBreadcrumb"
import { kVirtualQuestionAnswerPrefix } from 'src/components/Registration/registrationForm/core.ilx'

export default defineComponent({
  name: 'Player Registration',
  components: {
    RegistrationCustomQuestions,
    RegistrationCore,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
  },
  props: propsDef,
  setup(props) {
    const seasonName = ref("")
    const competitionNames = ref("")
    const playerDetails = ref<Child>(/*definitely assigned in onMounted*/{} as any)

    /**
     * A registration __is__ a "core question answers", because "core questions" are just properties on a registration.
     * This means that coreQuestionAnswers is a supertype of Registration.
     *
     * Note that `existingRegistration` and `formData.coreQuestionAnswers` MUST NOT alias the same object in memory
     */
    const formData = ref({}) as Ref<{coreQuestionAnswers: Partial<Registration>, customQuestionAnswers: ClientSideRegistrationQuestionAnswerMap}>
    /**
     * The "existing registration", if that exists.
     * This may be the first time a user is here for some (child, season), and in that case there won't be a registration yet
     */
    const maybeExistingRegistration = ref<Registration | null>(null)
    /**
     * Props for the core questions component
     * Definitely assigned in onMounted, prior to assigning ready=true
     */
    const coreProps = ref<M_RegistrationCore.Props>(null as any)

    const ready = ref(false)
    const save = ref(false)
    const coreSaved = ref(false)
    const customSaved = ref(false)
    const coreValidationErrors = ref({})
    const customValidationErrors = ref({})
    const validationErrors = ref({})

    const route = useRoute()
    const router = useRouter()


    /**
     * We should probably pull this from the API, from a request we perform in this component;
     * Currently this is populated after the core question component mounts and hits the api, and then bubbles it to us.
     * The referant is deeply readonly here, we only have a view into it.
     */
    const coreQuestions = ref<readonly Readonly<CoreQuestion>[]>([]);

    const getRegistrationHistory = async () : Promise<Registration | null> => {
      try {
        const response = await axiosInstance.get(`/v1/registration/player/${props.playerID}/season/${props.seasonUID}?expand=registrationAnswers,competitions`)
        if(response.data.data) {
          return response.data.data
        } else {
          return null;
        }
      } catch (error: any) {
        if(error.response?.status===404) {
          // we should use an axiosInstance that does not issue error toasts on 404s here
          // automatic error toast must be destroyed
          setTimeout(()=>iziToast.destroy(), 100)
          return null;
        }
      }

      return null;
    }

    const nextJourneyRoute = async (args: {registrationID: Guid}) : Promise<void> => {
      await router.push(R_SelectPaymentOption.routeDetailToRouteLocation({
        routeName: "select-payment-option.main",
        registrationID: args.registrationID,
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs,
        playerID: props.playerID
      }))
    }

    const saveRegistration = async () : Promise<void> => {
      const options = {
        childID: props.playerID,
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs,
        // We have to do some transforms, but we do not want to mutate actual form state. Hence, make deep copies of source objects.
        // Callees should also make their own copies as necessary, but deep-const isn't a really enforceable thing, and callees could possibly perform writes.
        // So, it's possible these copies are redundant, but also possible that they're not; doing so is not a blocker perf-wise,
        // and gives some defense in depth against accidental mutation, so copies seem justified here.
        coreQuestionAnswers: cleanCoreQuestionAnswers(copyViaJsonRoundTrip(formData.value.coreQuestionAnswers)),
        customQuestionAnswers: cleanCustomQuestionAnswers(copyViaJsonRoundTrip(formData.value.customQuestionAnswers)),
      } as const;

      try {
        const response = await ilapi.createOrUpdateRegistration(axiosInstance, options);
        await nextJourneyRoute(response);
      } catch (error: any) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }

      /**
       * Clean up junk that has seeped in from formkit
       *   - We get "slots" and "__init" keys pushed into the form, sometimes?
       *     - investigate: we bind formData to FormKit, and watch for changes and emit change events (in core.vue)
       *       but formkit assumes the submit event is where we will look at "changed form data"
       *       It is during the submit event that FormKit would perform the "cleaning",
       *       but we're opting out of that FormKit flow by using our own events.
       *   - The backend wants a custom questions answermap of exactly {[k: Guid]: string}
       */
      function cleanCustomQuestionAnswers(answersByQuestionID: Readonly<ClientSideRegistrationQuestionAnswerMap>) : {[questionID: iltypes.Guid]: string | number | boolean} {
        const localCopy = copyViaJsonRoundTrip(answersByQuestionID) as ClientSideRegistrationQuestionAnswerMap;
        for (const questionID in localCopy) {
          if (questionID === 'slots' || questionID === '__init') {
            delete localCopy[questionID]
          }
        }
        return answerMappingToApiV1SubmittableAnswerMapping(localCopy);
      }

      /**
       * Drop core questions that we shouldn't send
       */
      function cleanCoreQuestionAnswers(__in_coreQuestionAnswers: Readonly<Partial<Registration>>) : Partial<Registration> {
        const shallowCopy_coreQuestionAnswers = {...__in_coreQuestionAnswers}
        const existingRegistrationOnFileAtPageMount : Registration | null = maybeExistingRegistration.value;

        for (const k of Object.keys(shallowCopy_coreQuestionAnswers).filter(k => k.startsWith(kVirtualQuestionAnswerPrefix))) {
          // "virtual" values need not be pushed over the wire; there should be a non-virtual version of it that we do want to retain
          delete shallowCopy_coreQuestionAnswers[k as keyof typeof shallowCopy_coreQuestionAnswers]
        }

        // nothing to clean if there was no existing registration
        if (!existingRegistrationOnFileAtPageMount) {
          return shallowCopy_coreQuestionAnswers;
        }

        // todo: clarify this; it's been here for ages so it must do the right thing
        // sep/1/2022 -- this stmt is probably unnecessary, the `coed` question may be "required", but it also might be
        // "gated away" on receipt on the server if gates say to drop it; "gating away" occurs on the server before
        // the "required" check; so if it is to be gated away, this is a no-op, and if it is required,
        // the form should have required this statement (or some equivalent) to happen before we got here.
        // Need some more tests around this before dropping this statement (or updating this comment and leaving it be).
        shallowCopy_coreQuestionAnswers.coed ||= 0;

        // lifecycle assumption: if we're here, child component has notified us of its
        // receipt of core questions, so iterating over this listing makes sense
        for (const question of coreQuestions.value) {
          // If a question is effectively disabled, do not submit it.
          // The form should have prevented changing it, too.
          if (considerCoreQuestionDisabledForPrimaryRegistration(existingRegistrationOnFileAtPageMount, question, {checkAgainstUserRoles: User.value.roles})) {
            // core question names map directly to property names of a registration
            delete shallowCopy_coreQuestionAnswers[question.name as keyof Registration];
          }
        }

        return shallowCopy_coreQuestionAnswers;
      }
    }

    const updateValidationErrors = (errors: {[key:string]: string}) => {
      validationErrors.value={...validationErrors.value, ...errors}
    }

    const handleError = () => {
      coreSaved.value=false
      save.value=false
      customSaved.value=false
    }

    const updateCustomQuestions = (val: any) => {
      formData.value.customQuestionAnswers = val
    }

    const updateCoreQuestionAnswers = (val: any) => {
      formData.value.coreQuestionAnswers = val
    }

    const setRegistrationHistory = async () => {
      const registration =  await getRegistrationHistory();
      if (registration === null) {
        // there was not an existing registration
        maybeExistingRegistration.value = null;
        formData.value = {
          coreQuestionAnswers: {},
          customQuestionAnswers: {}
        }
      }
      else {
        // there WAS an existing registration
        maybeExistingRegistration.value = registration
        formData.value = {
          // n.b. MUST NOT alias w/ maybeRegistration.value
          coreQuestionAnswers: copyViaJsonRoundTrip(registration),
          // existing answers are present on existingRegistration.value.registrationAnswers,
          // and will bubble into form data from the custom question child component
          customQuestionAnswers: {}
        }
      }
    }

    /**
     * todo: callers assume this succeeds, and probably won't handle it gracefully if we get a 404 or etc.
     * If this fails, the page is effectively useless (who are we registering?), but it shouldn't become unresponsive.
     */
    const getPlayerDetails = async () => {
      try {
        playerDetails.value = await ilapi.getPlayer(axiosInstance, {childID: props.playerID as string});
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error)
      }
    }

    watch([coreValidationErrors, customValidationErrors], (vals) => {
      validationErrors.value = {...vals[0], ...vals[1]}
    })

    const notifyCoreQuestionsReceipt : M_RegistrationCore.Emits["notifyCoreQuestionsReceipt"] = (v: CoreQuestion[]) => {
      coreQuestions.value = v;
    }

    onMounted(async ()=> {
      // fixme: pass target season in as prop, so we don't need to wait on it?
      const targetSeason = await Client.getSeasonByUID(props.seasonUID);

      // Season and comps should all be definitely found.
      // But, handle misses (user supplied junk in url?) in a non-crashing way
      seasonName.value = targetSeason?.seasonName || "";

      competitionNames.value = await Promise.all(
        props
          .competitionUIDs
          .map(compUID => Client.getCompetitionByUID(compUID))
      ).then(comps => comps
        .sort(sortBy(_ => _?.competitionID ?? -1))
        .map(comp => comp?.competition || "")
        .join(", ")
      )

      await setRegistrationHistory()

      await getPlayerDetails()

      coreProps.value = {
        variant: {
          type: "actual-form",
          maybeExistingRegistration: maybeExistingRegistration.value,
          playerDetails: playerDetails.value,
          seasonUID: props.seasonUID,
          competitionUIDs: props.competitionUIDs
        }
      }

      ready.value=true
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.registrationForm,
          seasonUID: props.seasonUID,
          playerID: props.playerID,
          competitionUIDs: props.competitionUIDs
        }
      }
    })

    return {
      seasonName,
      ready,
      saveRegistration,
      save,
      coreSaved,
      customSaved,
      validationErrors,
      updateValidationErrors,
      coreValidationErrors,
      customValidationErrors,
      handleError,
      maybeExistingRegistration,
      updateCustomQuestions,
      updateCoreQuestionAnswers,
      playerDetails,
      notifyCoreQuestionsReceipt,
      coreProps,
      registrationJourneyBreadcrumbProps,
      competitionNames,
    }
  },
})
</script>
