<template lang="pug">
Portlet(
  label='Upcoming Events',
  :icon='upcomingEvents?.fontAwesome || "calendar"',
  prefix='fas',
  @expand='toggleExpand'
)
  div(
    v-if='Object.keys(events).length > 0 && ((isMobile && show) || !isMobile)'
  )
    .flex.flex-col
      .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
        .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
          .overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
            .font-bold(v-for='entity in events')
              .px-6.w-full.bg-gray-100 {{ entity[0].userFirstName ? `${entity[0].userFirstName} ${entity[0].userLastName}` : `${entity[0].childFirstName} ${entity[0].childLastName}` }}
              table.min-w-full.divide-y.divide-gray-200.font-normal.text-sm.border-b.border-t
                tbody.bg-white.divide-y.divide-gray-200
                  tr.cursor-pointer(
                    v-for='event in entity',
                    @click='toEventDetails(event.eventID)'
                  )
                    td.px-1.py-4.whitespace-nowrap
                      div {{ formatDateAsNums(event.eventStart) }}, {{ formatTime(event.eventStart) }}
                    td.px-1.py-4.whitespace-nowrap
                      | {{ event.eventName }}
                    td.px-1.py-4
                      | {{ event.eventVenueName }}
                    td.px-1.py-4.whitespace-nowrap
                      font-awesome-icon(:icon='["fas", "angle-double-right"]')
</template>


<script lang="ts">
import { defineComponent, ref, Ref, onMounted, computed } from 'vue'
import Portlet from 'src/components/Portlets/Portlet.vue'
import { useRouter } from 'vue-router'

import { getCurrentInstance } from 'vue'

import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { System } from 'src/store/System'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'UpcomingEvents',
  components: {
    Portlet,
  },
  props: {
    showContent: {
      type: Boolean,
      default: false,
    },
    upcomingEvents: Object,
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = ref({}) as Ref<{ [key: string]: any[] }>
    const testData = ref(123)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registeredEvents = ref([]) as Ref<any[]>
    const show = ref(props.showContent)
    const localInstance = getCurrentInstance()

    const router = useRouter()


    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const clientUrl = computed(() => {
      return Client.value.instanceConfig.appdomain
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortEvents = (unsortedEvents: any) => {
      for (let i = 0; i < unsortedEvents.signups.length; i++) {
        const ID = unsortedEvents.signups[i].userID
          ? unsortedEvents.signups[i].userID
          : unsortedEvents.signups[i].childID
        if (events.value[ID]) {
          events.value[ID].push(unsortedEvents.signups[i])
        } else {
          events.value[ID] = [unsortedEvents.signups[i]]
        }
      }
    }

    const toEventDetails = (id: string) => {
      // console.log('toEventDetails', id)
      // router.push({
      //   name: 'event-signup',
      //   params: { eventID: id },
      // })
      if (isMobile.value) {
        localInstance?.appContext.config.globalProperties.$openLegacyLink(
          `https://${clientUrl.value}/events/event-detail.cfm?eventID=${id}`,
          '_blank'
        )
      } else {
        localInstance?.appContext.config.globalProperties.$openLegacyLink(
          `https://${clientUrl.value}/events/event-detail.cfm?eventID=${id}`
        )
      }
      // `https://${clientUrl}/events/event-detail.cfm?eventID=${id}`
      // client>instanceConfig>appdomain
    }

    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    onMounted(() => {
      sortEvents(props.upcomingEvents)
    })

    return {
      events,
      testData,
      registeredEvents,
      show,
      isMobile,
      sortEvents,
      toEventDetails,
      toggleExpand,
      formatDateAsNums,
      formatTime,
      clientUrl,
    }
  },
})
</script>
