import { computed } from 'vue'
import { SetupContext } from 'vue'
// Vue types props as any, and then uses typescript to extract information from the component regarding more specific information
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function emitWrapper(props: any, emit: SetupContext["emit"], name = 'modelValue') {
  return computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value)
  })
}
