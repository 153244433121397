<template lang="pug">
div.m-2
  FormKit(type="form" :actions="false" @submit="doSave")
    div.grid.grid-cols-2
      div Question type:
      div
        template(v-if="question.isNewQuestion")
          FormKit(type="select" v-model="question.type" :options="questionTypeOptions" data-test="type")
        template(v-else)
          div {{ questionTypeUiLabel[question.type] }}

      div Short description:
      div
        FormKit(type="text" autocomplete="off" name="Short description" v-model="question.shortLabel" data-test="shortLabel" :validation="formValidationRules.shortLabel")

      div Text users will see for this question:
      div
        FormKit(type="text" autocomplete="off" name="Long description" v-model="question.label" data-test="label" :validation="formValidationRules.label")

      div This field is required
      div
        FormKit(type="checkbox" v-model="question.isRequired" data-test="isRequired")

      div
        //- this should be a drag-and-drop on the index page?
        div Sort order
        div.text-xs
          |  On the event registration page, custom questions will be sorted based on this number.
          |  Questions without an assigned order will be displayed first.
      div
        FormKit(type="number" name="Sort order" v-model="question.sortOrder" data-test="sortOrder" :validation="formValidationRules.sortOrder")

      div.col-span-2(v-if="shouldShowQuestionOptions")
        div Question options
        div.mx-4.mb-2
          div.grid.items-center(style="grid-template-columns: 1fr 1fr min-content;" class="[&>div]:px-4")
            div.my-2
              t-btn(type="button" @click="addOption" data-test="addQuestionOption")
                div.text-xs Add option
            div
            div
              //- we always want this grid cell, but its contents are conditionally rendered
              div(v-if="question.questionOptions.length > 0") Delete
            template(v-for="(option, index) in question.questionOptions" :key="option.__vueKey")
              div
                FormKit(
                  type="text" autocomplete="off"
                  v-model="option.optionText"
                  :name="`Option ${index + 1}`"
                  outer-class="$reset my-1" :data-test="`questionOption-label-${index}`"
                  :validation="formValidationRules.questionOption.label"
                )
              div
                FormKit(
                  type="text" autocomplete="off"
                  v-model="option.optionValue"
                  :name="`Label ${index + 1}`"
                  outer-class="$reset my-1" :data-test="`questionOption-value-${index}`"
                  :validation="formValidationRules.questionOption.value"
                )
              div.flex.justify-center
                template(v-if="option.isNew")
                  t-btn(type="button" @click="removeNewOption(option)" :data-test="`questionOption-delete-${index}`")
                    div.text-xs Remove
                template(v-else)
                  FormKit(type="checkbox" v-model="option.markedForDelete" outer-class="$reset" :data-test="`questionOption-delete-${index}`")

      div.grid-span-2
        t-btn(type="submit" data-test="save")
          div Save changes
        t-btn(@click="doCancel" color="red")
          div Cancel
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { propsDef, emitsDef, QuestionOptionFormEntry, makeQuestionFormEntry, makeQuestionOptionFormEntry, QuestionFormEntry } from "./EventCustomQuestionForm.ilx";
import * as ilapi from "src/composables/InleagueApiV1"

const questionTypeUiLabel : Record<ilapi.event.EventQuestionType["string"], string> = {
  "textarea": "Textarea",
  "checkbox": "Checkbox",
  "radio": "Radio",
  "text": "Text",
  "select": "Select",
}

const questionTypeOptions : {label: string, value: string}[] = (() => {
  const result : {label: string, value: string}[] = [];
  for (const key of Object.keys(questionTypeUiLabel)) {
    result.push({value: key, label: (questionTypeUiLabel as any)[key]})
  }
  return result;
})();

const formValidationRules = {
  label: [["required"], ["length", 2, 500]],
  shortLabel: [["required"], ["length", 2, 90]],
  sortOrder: [["min", 1], ["max", 255]],
  questionOption: {
    label: [["required"], ["length", 2, 200]],
    value: [["required"], ["length", 2, 200]],
  }
}

export default defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    // This component directly edits form data, supplied by the parent.
    const question = ref(props.v.formData);

    const shouldShowQuestionOptions = computed(() => {
      return question.value.type === "select" || question.value.type === "radio";
    });

    const addOption = () : void => {
      question.value.questionOptions.push(makeQuestionOptionFormEntry("new"));
    }

    const removeNewOption = (target: QuestionOptionFormEntry) : void => {
      const targetIndex = question.value.questionOptions.indexOf(target);
      if (targetIndex === -1) {
        // couldn't find it?
        return;
      }
      question.value.questionOptions.splice(targetIndex, 1);
    }

    return {
      question,
      shouldShowQuestionOptions,
      questionTypeOptions,
      questionTypeUiLabel,
      addOption,
      removeNewOption,
      doSave: () : void => emit("doSave", question.value),
      doCancel: () : void => emit("doCancel", question.value.questionID),
      formValidationRules,
    }
  }
})

</script>