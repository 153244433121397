import { ref } from "vue";
import { System } from "./System";
import { User } from "./User"

const loginModalDismissed = ref(false);

export const getLoginModalDismissed = () => loginModalDismissed.value
export const setLoginModalDismissed = (v: boolean) => {
  if (v) {
    // if we just dismissed the login modal, we probably want to discard the "go here when login is successful" value,
    System.setRedirectOnLogin(null)

    // also clear the current login flow state; maybe we'd like to preserve it? but we have
    // to do the right thing on next login modal mount
    switch (User.loginState.state) {
      case "logged-in":
         // fallthrough
      case "mfa-init-flow-complete":
        // weird, shouldn't be here (can we dismiss the modal from mfa-init-flow-complete state?)
        // nothing to do
        break;
      case "logged-out":
         // fallthrough
      case "mfa-challenge-flow":
        // fallthrough
      case "mfa-init-flow":
        // fallthrough
      case "oauth-ack-multiple-leagues": {
        User.loginState = {state: "logged-out"};
      }
    }
  }

  loginModalDismissed.value = v;
}
