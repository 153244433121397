import authService from "src/helpers/authService"
import { User } from "src/store/User"

export const RouteName = "bracket-listing"

export function hasSomeRoutePermission() : boolean {
  const isSuperUser = authService(User.userData?.roles ?? [], "registrar", "webmaster", "gameScheduler")
  const isSomeSuperCompAdmin = !!User.userData?.competitionsMemento.find(v => v.isSuperAdmin)
  return isSuperUser || isSomeSuperCompAdmin
}
