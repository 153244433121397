import { boot } from 'quasar/wrappers'
import VTooltipPlugin from 'v-tooltip'

export default boot(({ app }) => {
  const delay = { show: 200, hide: 0 }
  const options = {
    defaultTrigger: "hover focus click", // Maybe we only want 'click' on a mobile build.
    themes: {
      tooltip: {
        delay: delay
      }
    }
  }
  app.use(VTooltipPlugin, options)
})
